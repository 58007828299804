export const BASE_SOUND = "data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV";

export const TRACKS = [
  {
    "song_id": "3OeX4IyrnpwkElW5iS8leo",
    "name": "All Shook Up",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/428fd286cb5e9f6b49f1fde71e5f81944a33cf1f",
    "artist_name": "Elvis Presley",
    "image_url": "https://i.scdn.co/image/98128f5c15aee1d481fb7722dbe71e8268ffbdcd",
    "artist_id": "43ZHCT0cAZBISjO8DG9PnE",
    "year": 1957
  },
  {
    "song_id": "4fHDlIntTsRGSyTg5UYZYC",
    "name": "Banana Boat Song (Day-O)",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/809b16d06be442c7e53b0b76f282da59e1c15d8c",
    "artist_name": "Harry Belafonte",
    "image_url": "https://i.scdn.co/image/acb15ab80e2e99981eec1b389ce3c32335e00cd3",
    "artist_id": "6Tw1ktF4xMmzaLLbe98I2z",
    "year": 1957
  },
  {
    "song_id": "4chlGZQX5uPYpRLp3NezmX",
    "name": "Diana",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/adce010b3b8fb12686bad87639e15d6a2fba852f",
    "artist_name": "Paul Anka",
    "image_url": "https://i.scdn.co/image/5e5bfbbf197406bccf687b8023484efd4ce7a3b5",
    "artist_id": "7ceUfdWq2t5nbatS6ollHh",
    "year": 1957
  },
  {
    "song_id": "2eg3CoqVGI21N8115BgdQA",
    "name": "Chances Are",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/143575119c85367ec147d4084d1aef8557c7ec32",
    "artist_name": "Johnny Mathis",
    "image_url": "https://i.scdn.co/image/db7f8fd609f371c497abae3606b9859658302dee",
    "artist_id": "21LGsW7bziR4Ledx7WZ1Wf",
    "year": 1957
  },
  {
    "song_id": "25CTJ7xIrrWTHNtA7mq8Y6",
    "name": "Blueberry Hill",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/00c16bfa0e9cfa66aca5e6ed3a406ae762669b8b",
    "artist_name": "Fats Domino",
    "image_url": "https://i.scdn.co/image/8c1521884220d616bc46dbecd4346d5ce25ac1cc",
    "artist_id": "09C0xjtosNAIXP36wTnWxd",
    "year": 1957
  },
  {
    "song_id": "4MO4IrAvsZu1aSlAVnYxWb",
    "name": "Jailhouse Rock",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/9f7a36da1a1ffb44a71c8f4b05b1219fdfffbcc2",
    "artist_name": "Elvis Presley",
    "image_url": "https://i.scdn.co/image/98128f5c15aee1d481fb7722dbe71e8268ffbdcd",
    "artist_id": "43ZHCT0cAZBISjO8DG9PnE",
    "year": 1957
  },
  {
    "song_id": "65qEelojK51h5xe1MnyL0A",
    "name": "Blue Monday - 2002 Digital Remaster",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/20a676bd3110e048d2bd7bd428f526818b05e4c0",
    "artist_name": "Fats Domino",
    "image_url": "https://i.scdn.co/image/2291961946393cd8090fb2ba6886dda8cf2712f4",
    "artist_id": "09C0xjtosNAIXP36wTnWxd",
    "year": 1957
  },
  {
    "song_id": "0LzsNy1SqlM0qkRGL2RJfv",
    "name": "It's Not For Me To Say",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/4c7c872a6fe740956a09dd3a35b47139754bf4be",
    "artist_name": "Johnny Mathis",
    "image_url": "https://i.scdn.co/image/db7f8fd609f371c497abae3606b9859658302dee",
    "artist_id": "21LGsW7bziR4Ledx7WZ1Wf",
    "year": 1957
  },
  {
    "song_id": "28J4KJN2NFhdHGKpz7CWGu",
    "name": "You Send Me",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/2ac45921e08862d0a7cab905e4f4b6bd5ffcef48",
    "artist_name": "Sam Cooke",
    "image_url": "https://i.scdn.co/image/d5d31bfbfe9e41c9b36872619c63169c02badbc0",
    "artist_id": "6hnWRPzGGKiapVX1UCdEAC",
    "year": 1957
  },
  {
    "song_id": "5DCqNn752dBJbjM6LEtQJU",
    "name": "Searchin'",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/e94e4104bd55b747d9b7574ad820771aa531d483",
    "artist_name": "The Coasters",
    "image_url": "https://i.scdn.co/image/4aba4b7264e9b66592ab15654120347fe01b35ad",
    "artist_id": "3QZKZBEmr54lAVI5XvmjnM",
    "year": 1957
  },
  {
    "song_id": "7ow0KcdNRitEedvwQNTd6S",
    "name": "Young Love",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/04dddc70e8e63d48e9ce4975525d958177d02a54",
    "artist_name": "Sonny James",
    "image_url": "https://i.scdn.co/image/c89389ef481f6b2e2e26744849e4e9890b90a18e",
    "artist_id": "0c08N8UlFjDaup9TNDaWxy",
    "year": 1957
  },
  {
    "song_id": "6zLGmxUA1ClhzCByaxtkNK",
    "name": "Mr. Lee",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/d3a2434d985147f872a21392df98bd5b9ac05631",
    "artist_name": "The Bobbettes",
    "image_url": "https://i.scdn.co/image/4f3fc7c24375193b2918076e673077dc32bb4f1a",
    "artist_id": "5TesVKMq16peTfJC46PbYw",
    "year": 1957
  },
  {
    "song_id": "15fjUKIxAT7gwUkJJEYYuH",
    "name": "Whole Lot Of Shakin' Going On",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/3f4ff291bac0acbb2b0abe7eb6a3da4d092424fb",
    "artist_name": "Jerry Lee Lewis",
    "image_url": "https://i.scdn.co/image/b02b466ba530d01116ab4718247835e64fea5dfd",
    "artist_id": "2zyz0VJqrDXeFDIyrfVXSo",
    "year": 1957
  },
  {
    "song_id": "1UsG5X1iDRw17C5mIAgqqA",
    "name": "Round And Round",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/78e76397f95552c43b695c2a73ca3dfb51c4f6db",
    "artist_name": "Perry Como",
    "image_url": "https://i.scdn.co/image/d541f41f2e5d34a405d2ea7879dd95a8b04a2346",
    "artist_id": "5v8jlSmAQfrkTjAlpUfWtu",
    "year": 1957
  },
  {
    "song_id": "1x70VF9nu0CVFRvk70xOlR",
    "name": "Send For Me",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/79fa40f51aa0446cf50f1cda729ae0be9a38850b",
    "artist_name": "Nat King Cole",
    "image_url": "https://i.scdn.co/image/8ba73ca18711853b2ba28e6837d238bfa9fc5fb4",
    "artist_id": "7v4imS0moSyGdXyLgVTIV7",
    "year": 1957
  },
  {
    "song_id": "0BuCJeuqKkoCgUC4CY8CCq",
    "name": "Love Letters In The Sand - Single Version",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/9fa0d3e1e9cd17b93a8475ddf5edace34ed45df7",
    "artist_name": "Pat Boone",
    "image_url": "https://i.scdn.co/image/ec3c1e155aa62e45ef39556e30f2ccdbb1530d79",
    "artist_id": "7fmKtIgmxqNEKjATioVNsu",
    "year": 1957
  },
  {
    "song_id": "1kJQFjin0XptiEgR1eY7Tc",
    "name": "Silhouettes",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/f6bbf25e3c34a559adc970eaa93a357b5f9da62b",
    "artist_name": "The Rays",
    "image_url": "https://i.scdn.co/image/c14d19a2919eb1eb68ba82b6aa95844155f69ad2",
    "artist_id": "3owdLGoMpuTwee9KIh1Ibv",
    "year": 1957
  },
  {
    "song_id": "3r6xRO3NFoxuKQBC8Fj75C",
    "name": "I’m Walkin’",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/272c83d2a0753b365a96f1b9bb4f5b1b5ad1eb41",
    "artist_name": "Fats Domino",
    "image_url": "https://i.scdn.co/image/8c1521884220d616bc46dbecd4346d5ce25ac1cc",
    "artist_id": "09C0xjtosNAIXP36wTnWxd",
    "year": 1957
  },
  {
    "song_id": "5Uo1rkgn1ijO9yiKpvoKSl",
    "name": "Be-Bop Baby - 2001 Digital Remaster",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/301064d284e47b364d303df2a121156de99a59d7",
    "artist_name": "Ricky Nelson",
    "image_url": "https://i.scdn.co/image/1bb844233309d6f6cdc48c3a687a913228709fee",
    "artist_id": "73sSFVlM6pkweLXE8qw1OS",
    "year": 1957
  },
  {
    "song_id": "6vL6hy8VSjDSORz5qCM0cr",
    "name": "Don't Forbid Me - Single Version",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/b3eb77b0efe4c14817c52b1f75a3a87a9d0dcafe",
    "artist_name": "Pat Boone",
    "image_url": "https://i.scdn.co/image/ec3c1e155aa62e45ef39556e30f2ccdbb1530d79",
    "artist_id": "7fmKtIgmxqNEKjATioVNsu",
    "year": 1957
  },
  {
    "song_id": "3YGwG7l0FnMd0YXKA6nhr0",
    "name": "Old Cape Cod",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/9ef0cba18ae5fdcac382538bc31fd7b52301a344",
    "artist_name": "Patti Page",
    "image_url": "https://i.scdn.co/image/ff75f269711a774607d4b2a005e82efe4cbf0dea",
    "artist_id": "4nZN9kln8toEzOifhWG2uF",
    "year": 1957
  },
  {
    "song_id": "4TJzyZeuzg79J63SUsbttf",
    "name": "Butterfly",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/e03e477da3b4309ff79dd2ae4d1e201c07adf9fb",
    "artist_name": "Andy Williams",
    "image_url": "https://i.scdn.co/image/21d71e18f672e97d289bc650e2ec46871de2578a",
    "artist_id": "4sj6D0zlMOl25nprDJBiU9",
    "year": 1957
  },
  {
    "song_id": "6rRAqrA1RjyWmH87SFmBwj",
    "name": "The Green Door",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/98ec6110286730745885deb834edfa47c3145378",
    "artist_name": "Jim Lowe",
    "image_url": "https://i.scdn.co/image/d851072a7c8781343b9d5dbf4a833e4bc0cbcee4",
    "artist_id": "0xvvT0LCwIpHCxqfygwdwN",
    "year": 1957
  },
  {
    "song_id": "6u1Of00jUvgew9KTJ5AyZS",
    "name": "Young Love",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/349e2552f2936a3b9fb64395774dd71905a6251a",
    "artist_name": "Tab Hunter",
    "image_url": "https://i.scdn.co/image/8c8ff815ad971db2fd11eb42173625aa634b0329",
    "artist_id": "0pVnnK639XgJiMuv10JLRe",
    "year": 1957
  },
  {
    "song_id": "2cHaSH7Lpw492XEakGYjCF",
    "name": "Butterfly",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/a3b5cd1c447dc078c63eab48e1b313ef87e4e0b9",
    "artist_name": "Charlie Gracie",
    "image_url": "https://i.scdn.co/image/0acc7e4cd89ff04dae027150f72efb992f681678",
    "artist_id": "7mC80cCjo7JkyaD9ZssKzN",
    "year": 1957
  },
  {
    "song_id": "2hqPpzGmfrU19GDtlvnJSQ",
    "name": "Teen-Age Crush",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/f173b72296284f413b6f7ecc08449317e067eab5",
    "artist_name": "Tommy Sands",
    "image_url": "https://i.scdn.co/image/8c8ff815ad971db2fd11eb42173625aa634b0329",
    "artist_id": "651IBnnG9nIIXO4N40bR6c",
    "year": 1957
  },
  {
    "song_id": "5tJwdzLVDXhMQBikYu2j9i",
    "name": "Gone",
    "popularity": 5,
    "preview_url": "https://p.scdn.co/mp3-preview/860268d7cd228ebb5f97f4003f9f2f6ff13856ae",
    "artist_name": "Ferlin Husky",
    "image_url": "https://i.scdn.co/image/0c8eedd0df2b66d3eac16f159874e7032739c311",
    "artist_id": "0szj7Sxtyluyjc2Arj0njB",
    "year": 1957
  },
  {
    "song_id": "7ntowUZaFwE2TwAzCwhMlo",
    "name": "Short Fat Fannie",
    "popularity": 3,
    "preview_url": "https://p.scdn.co/mp3-preview/43f50191c4111a42cb790eb5481eae62c2b3ec87",
    "artist_name": "Larry Williams",
    "image_url": "https://i.scdn.co/image/08ad3b3f34dba3a48cc99792186c59c034f9cc20",
    "artist_id": "5NBY8xM51dP3UGR7bwjLD6",
    "year": 1957
  },
  {
    "song_id": "72XDOlh7pOun2yfaHAcBev",
    "name": "So Rare",
    "popularity": 2,
    "preview_url": "https://p.scdn.co/mp3-preview/ada11ac027fbcf9f85d49bab3f3c423ea6509b0c",
    "artist_name": "Jimmy Dorsey Orchestra",
    "image_url": "https://i.scdn.co/image/d381802f733c4b5b5b7f15db33a81071b07032d6",
    "artist_id": "1GHzYeKPDNqZKyOWOL9zzY",
    "year": 1957
  },
  {
    "song_id": "2uKwiCeI7T17Fw7B7TU2Ko",
    "name": "Marianne",
    "popularity": 2,
    "preview_url": "https://p.scdn.co/mp3-preview/f306c7b9ef40504f9056d297ca944d71aff79a32",
    "artist_name": "Terry Gilkyson & The Easy Riders",
    "image_url": "https://i.scdn.co/image/f00bdcc10bd3c2d82c2a8be833843cc386e09b55",
    "artist_id": "7AqrcgusfOHpFmDIftvb5U",
    "year": 1957
  },
  {
    "song_id": "7jHR8mbacMoFWPIsSPI3Wk",
    "name": "A Teenager's Romance",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/45e2acac8aa4cbb374830f58f37d1e19d9e7161e",
    "artist_name": "Ricky Nelson",
    "image_url": "https://i.scdn.co/image/b0d6b152b5a7b6edf6084e3be2b8ae5a3b90716d",
    "artist_id": "73sSFVlM6pkweLXE8qw1OS",
    "year": 1957
  },
  {
    "song_id": "3mQVOJbXbdN0WHi3rxQoYy",
    "name": "The Banana Boat Song",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/267f5982369be3d66be428f54222e1e40ff6af3b",
    "artist_name": "The Tarriers",
    "image_url": "https://i.scdn.co/image/3e49fb67a2ef0b45f36eefec2de327f2629b5370",
    "artist_id": "44ByB5FFQnNnFK3nYTZX7w",
    "year": 1957
  },
  {
    "song_id": "568S1JuPjiSJ681RSYSdQj",
    "name": "I'm Gonna Sit Right Down & Write Myself a Letter",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/8046370944bc179bfc244a2f070c7fe74b2f1ae0",
    "artist_name": "Billy Williams",
    "image_url": "https://i.scdn.co/image/09e90524d8feef472a7f85b64daba4a82c27b355",
    "artist_id": "7ceeHyhYS3BEfprSJX9WwT",
    "year": 1957
  },
  {
    "song_id": "6GmL39a9OazWtyMkAbJz7v",
    "name": "Monster Mash (Re-Recording)",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/0ed9cee17509f27ae1a16497fc0eebb613891b7e",
    "artist_name": "Bobby \"Boris\" Pickett",
    "image_url": "https://i.scdn.co/image/5e9b214dc55227456a05e6cfb40db249c50c5e8d",
    "artist_id": "42MRYPhQfcEXqb18dl5ERX",
    "year": 1958
  },
  {
    "song_id": "5fNDP3NTdE69szQHUl4fFr",
    "name": "Please Mr. Postman",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/fb0a6e3ac0a15cc21d99e3b67403efc8ab51f456",
    "artist_name": "The Marvelettes",
    "image_url": "https://i.scdn.co/image/f2b95f18c50eb7d54d2348bc3f7432869292b6c9",
    "artist_id": "0MponVSpW81oLvJZ53vYZH",
    "year": 1958
  },
  {
    "song_id": "0NpvdCO506uO58D4AbKzki",
    "name": "Sherry",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/84e02e0aefc8afde0be0a5e8e0feaa70d9c5f9e5",
    "artist_name": "Frankie Valli & The Four Seasons",
    "image_url": "https://i.scdn.co/image/f4430f809fc003be28cf0d0f4710646c66c93fad",
    "artist_id": "6mcrZQmgzFGRWf7C0SObou",
    "year": 1958
  },
  {
    "song_id": "6xi2kRGOrdLJNo4wFyaCis",
    "name": "Stay",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/647bb0eaa767dbe15695130211db723db1742b4d",
    "artist_name": "Maurice Williams & The Zodiacs",
    "image_url": "https://i.scdn.co/image/1166faed41598593e460de5382ca2f0c07e43108",
    "artist_id": "43BgumF6B7s0W2mhVkmVO4",
    "year": 1958
  },
  {
    "song_id": "14bUqUj1EUnQabWeAqnj39",
    "name": "Tequila (Original)",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/ae20cd1601c21ce76bcd9bd4244e4d75cc7d4780",
    "artist_name": "The Champs",
    "image_url": "https://i.scdn.co/image/920c7f731cc55b4037eb560d91255c971068d7a0",
    "artist_id": "3yt4IVDHz0luREG9Uf9xrp",
    "year": 1958
  },
  {
    "song_id": "02NKMA9cIkq6VuBNu9q9Wf",
    "name": "The Chipmunk Song (Christmas Don't Be Late)",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/27248c4a521c7c668e1cb7f576a89debb73963d9",
    "artist_name": "Alvin & The Chipmunks",
    "image_url": "https://i.scdn.co/image/2257fcc6bf36966537ee7a4ba95ac89422732af8",
    "artist_id": "1SCWcYnoEh1978ycTDhZoW",
    "year": 1958
  },
  {
    "song_id": "3k0Ow4RdqhAi9Xi4hymx2j",
    "name": "Will You Love Me Tomorrow?",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/54705beada8541c94d327432487cedcd2734aafc",
    "artist_name": "The Shirelles",
    "image_url": "https://i.scdn.co/image/6662bb400e0aecac3f8907b17ecdc861c7fdf9d7",
    "artist_id": "0x83OBqixqdCHnStP5VMcn",
    "year": 1958
  },
  {
    "song_id": "6AL6Wbn1xA3R86vAvuiho5",
    "name": "Runaround Sue - 1991 - Remaster",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/8401c4f2df3b7328f95e95289d48756cdb098212",
    "artist_name": "Dion",
    "image_url": "https://i.scdn.co/image/611906bcea3e57d67c74dbbab134a90b9aabd343",
    "artist_id": "15FyiY3ChN0QRspHIQYq0W",
    "year": 1958
  },
  {
    "song_id": "6I49RDTrOe54naukI7dYYF",
    "name": "The Purple People Eater",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/2f61451a94fb245beb4b189fddf15e0d765ca87e",
    "artist_name": "Sheb Wooley",
    "image_url": "https://i.scdn.co/image/72e5bb18118de3cb334360ef1b82b67309eeefca",
    "artist_id": "2dOTS74R4yPISc7AVU9QzD",
    "year": 1958
  },
  {
    "song_id": "4f8hBeMXMvssn6HtFAtblo",
    "name": "El Paso",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/628b8ac21130da787ebd4506f930edc2e2d8ef96",
    "artist_name": "Marty Robbins",
    "image_url": "https://i.scdn.co/image/3d633aaf6ac8e62f6b77a40e62403609d660e724",
    "artist_id": "0Xi59sEw38vRvwleSAVqoo",
    "year": 1958
  },
  {
    "song_id": "0qMujfRhXLalSaDtN0PPMS",
    "name": "Battle of New Orleans",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/446b0fde2af1da121f90650f3c6c66f705aca336",
    "artist_name": "Johnny Horton",
    "image_url": "https://i.scdn.co/image/16ddc419a8cd6cad0ee9b0156a59a046fbefe190",
    "artist_id": "1bBZcz4jP7CoPlqpCFh4gz",
    "year": 1958
  },
  {
    "song_id": "1CX9WKs56Ur7r1NDPmuowt",
    "name": "Duke Of Earl",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/165da96e81e6f3c899822a8e3bc1ccd63c4cf868",
    "artist_name": "Gene Chandler",
    "image_url": "https://i.scdn.co/image/b2acce5abf314e036ccf1f9ee785ed6e7f5bc094",
    "artist_id": "52uMkSFt2RVO6XxTEt5VeW",
    "year": 1958
  },
  {
    "song_id": "33FPsMEl3UwpytDuyf9VYq",
    "name": "Poor Little Fool - 2001 Digital Remaster",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/eaa33c97cca844daa17b770ae691b894efea32ea",
    "artist_name": "Ricky Nelson",
    "image_url": "https://i.scdn.co/image/1bb844233309d6f6cdc48c3a687a913228709fee",
    "artist_id": "73sSFVlM6pkweLXE8qw1OS",
    "year": 1958
  },
  {
    "song_id": "603N4XGJUTbK760GLCvIIs",
    "name": "Come Softly To Me - 1993 Digital Remaster",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/87d2771cd839d7355052ef845afdf44eb052689b",
    "artist_name": "The Fleetwoods",
    "image_url": "https://i.scdn.co/image/ca82f18bf33f3bbc4ad7f947a0d708d02505c7c8",
    "artist_id": "673J686eeEXMYJorgQ70lX",
    "year": 1958
  },
  {
    "song_id": "4kh2S9hRpPvoF9JkwZcYGN",
    "name": "Lonely Boy",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/ad781c9aa22678e8b94f4008d962c7f56bdbcc97",
    "artist_name": "Paul Anka",
    "image_url": "https://i.scdn.co/image/5e5bfbbf197406bccf687b8023484efd4ce7a3b5",
    "artist_id": "7ceUfdWq2t5nbatS6ollHh",
    "year": 1958
  },
  {
    "song_id": "21NhrNOfIoakyWISXUMkFN",
    "name": "Get a Job",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/dad7790b05a03b850a92c1cd66c8c56ca4c97383",
    "artist_name": "The Silhouettes",
    "image_url": "https://i.scdn.co/image/6fab27ecfa61b269decf530166d05e4a66a8dac9",
    "artist_id": "5LmehwqsJa7a4Ya5SaqXpx",
    "year": 1958
  },
  {
    "song_id": "0qYok0f8O5DE8yJSo146dn",
    "name": "Roses Are Red (My Love) - Single Version",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/351246e01f30d2357f6e372e9cf29d4ee57277e2",
    "artist_name": "Bobby Vinton",
    "image_url": "https://i.scdn.co/image/0001270788af82d1b630b33323917f8af070711d",
    "artist_id": "6bOYtKnpLPQSfMpS2ilotK",
    "year": 1958
  },
  {
    "song_id": "4WhT2YADjcU58IUgroWZQZ",
    "name": "Walk Right In",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/aea458f8d6e0dceac6bd07673ec4b8b5d85e8ce5",
    "artist_name": "THE ROOFTOP SINGERS",
    "image_url": "https://i.scdn.co/image/224f25fdaedd239e7b04eaad58141af34b502db4",
    "artist_id": "6Gb08eT3LlkGynDscl9vbz",
    "year": 1958
  },
  {
    "song_id": "54ySJT9fmYElKHSTuZfOa4",
    "name": "Big Girls Don't Cry",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/42be43e8b740e32a844a35da674661bd0e153ade",
    "artist_name": "Frankie Valli & The Four Seasons",
    "image_url": "https://i.scdn.co/image/5db5f00f6da7146cedd95aa1f6d06121074c54f5",
    "artist_id": "6mcrZQmgzFGRWf7C0SObou",
    "year": 1958
  },
  {
    "song_id": "39gvhionjcw3DDynncAtXu",
    "name": "Hey Paula",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/a06e7d6c4a1f12ec180eea7039dc54c8329293fc",
    "artist_name": "Paul & Paula",
    "image_url": "https://i.scdn.co/image/58d231a1f7dac8fdd934fb76c319300e60e7c429",
    "artist_id": "2pycVt1qR9u8bKdb4oLI98",
    "year": 1958
  },
  {
    "song_id": "3Yey43damnXJRuWdlzW9jJ",
    "name": "Soldier Boy",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/0a53d68d23e424a93f674bee77199ce1736a6755",
    "artist_name": "The Shirelles",
    "image_url": "https://i.scdn.co/image/6662bb400e0aecac3f8907b17ecdc861c7fdf9d7",
    "artist_id": "0x83OBqixqdCHnStP5VMcn",
    "year": 1958
  },
  {
    "song_id": "19ZAI2LNUDj8TVpA6GwVWS",
    "name": "Take Good Care Of My Baby",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/e8e34170382b3e450a1623cc310698d0be156001",
    "artist_name": "Bobby Vee",
    "image_url": "https://i.scdn.co/image/09321e273aaf355399784165d93527c5b4625ac7",
    "artist_id": "5MX2l6ewjOaeWn1lYNhzlO",
    "year": 1958
  },
  {
    "song_id": "1mspRB1JIDtaMPXewN8XOs",
    "name": "I Will Follow Him",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/ebd16c0c51107949eb835e1ae85cac4eef346a2f",
    "artist_name": "Little Peggy March",
    "image_url": "https://i.scdn.co/image/5aa3da2bcc17ae55b5c4d318a639bae92c276b24",
    "artist_id": "09vvlnqwFaimZwGAvpXgqy",
    "year": 1958
  },
  {
    "song_id": "5YjNeVkJ8i6mgdVhZITAlI",
    "name": "Mack The Knife",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/91671d9f57b4c79bd039ac40158b0bc8d546f7c1",
    "artist_name": "Bobby Darin",
    "image_url": "https://i.scdn.co/image/b89186001ee7b0d1e5451146c85b79b56ef91b2a",
    "artist_id": "0EodhzA6yW1bIdD5B4tcmJ",
    "year": 1958
  },
  {
    "song_id": "3CrbWMJbTlI9T4pB7p5J35",
    "name": "Stagger Lee",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/7bf3463a704fdcfacfa100d594a3c658a06d228f",
    "artist_name": "Lloyd Price",
    "image_url": "https://i.scdn.co/image/2fa0d8a3c96a80c3eefaf03f9c66e5a3ab62c0e9",
    "artist_id": "3iOE5ItEv5xr9fmKi7GNh2",
    "year": 1958
  },
  {
    "song_id": "6bnFjTW3EnMG4BwreDnSHK",
    "name": "Mr. Blue",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/e95ba081cba89e71b1c2e8fb5ba3f47a251e1626",
    "artist_name": "The Fleetwoods",
    "image_url": "https://i.scdn.co/image/ca82f18bf33f3bbc4ad7f947a0d708d02505c7c8",
    "artist_id": "673J686eeEXMYJorgQ70lX",
    "year": 1958
  },
  {
    "song_id": "2l4fBmSX9cOeDq6MMQtAEV",
    "name": "Mother In Law",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/66222ea839e2110c4493ac1e48f16800432a7521",
    "artist_name": "Ernie K-Doe",
    "image_url": "https://i.scdn.co/image/eb3b656c50aab87c4adbc2a4a27b79ffd46ccd66",
    "artist_id": "3ybBNyjii40yY104IZkcly",
    "year": 1958
  },
  {
    "song_id": "3Aug2RunJbjGiz2CTtWu5l",
    "name": "Itsy Bitsy Teenie Weenie Yellow Polka-Dot Bikini",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/7b6d19f30fea39fde8ef65e6a98c050552263b96",
    "artist_name": "Brian Hyland",
    "image_url": "https://i.scdn.co/image/add84e88232cac41aa1f5565d7396e20d42f98ed",
    "artist_id": "6YROFUbu5zRCHi2xkir5pk",
    "year": 1958
  },
  {
    "song_id": "6sgLfltWA6Ss4ZxSLFMyd0",
    "name": "Michael Row The Boat Ashore",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/3acad8e9166cfc0b5adf0999ffca121a7f226b18",
    "artist_name": "The Highwaymen",
    "image_url": "https://i.scdn.co/image/0a13af13e14d4abfc42ccfd3671dd6e680a95614",
    "artist_id": "6e7QpHYqEiyJGiM98IysLa",
    "year": 1958
  },
  {
    "song_id": "3c7KT5CN8uYRaK3xThhdYt",
    "name": "Little Star",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/2c1dededcb2ce9d6dd844e09f53f9053462541ea",
    "artist_name": "The Elegants",
    "image_url": "https://i.scdn.co/image/efa1860ffc51f259aad30f25384919d1fb91f1b7",
    "artist_id": "7bNoMfBqbaLJrfH3Vw1q6L",
    "year": 1958
  },
  {
    "song_id": "7zo3Osrn2r9bKedXhayJtd",
    "name": "Cathys Clown",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/396d534925aaea2620db242ee9bb798b4f4c1a40",
    "artist_name": "The Everly Brothers",
    "image_url": "https://i.scdn.co/image/bfeaa0a439d39ca2dd96b46f9b962800b0a11965",
    "artist_id": "4ACplpEqD6JIVgKrafauzs",
    "year": 1958
  },
  {
    "song_id": "2XNoOrbIlv70V1siXeBokl",
    "name": "Alley Oop",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/d94a51bac1bec745a31c6bd7139aa342a8854e45",
    "artist_name": "Hollywood Argyles",
    "image_url": "https://i.scdn.co/image/77aa879f5f52ffb31b863d501e2617222fce0d68",
    "artist_id": "6af1KGKyTV3xTilHXaOuZA",
    "year": 1958
  },
  {
    "song_id": "3qromN7ug1Y8JQBTZQsLSl",
    "name": "Smoke Gets In Your Eyes - Single Version",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/b64a8e38a90c45bb7c45592f18a3edb859969d30",
    "artist_name": "The Platters",
    "image_url": "https://i.scdn.co/image/194c62b10017e6f1f93977d5785b7d2bd23c3b96",
    "artist_id": "6KWcxMWVNVIYbdOQyJtsSy",
    "year": 1958
  },
  {
    "song_id": "6eQCOABK6G41PBTaHXeR4Y",
    "name": "Pony Time",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/a4507822d97590294fead6fb793ead02f28f1baa",
    "artist_name": "Chubby Checker",
    "image_url": "https://i.scdn.co/image/060930391d3b42d8b2f75c8033d465d97615e6db",
    "artist_id": "7qQJQ3YtcGlqaLg5tcypN2",
    "year": 1958
  },
  {
    "song_id": "3vwqUuBytMCDYn0MTqVMF4",
    "name": "To Know Him Is To Love Him",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/0646f7e9e37c020927b72ba26979d4d2db45014e",
    "artist_name": "The Teddy Bears",
    "image_url": "https://i.scdn.co/image/e99390c79db17b42440eef8e04b7ac9bbf0d0fb9",
    "artist_id": "2qfze6v2h0uc0e2ABp7z3a",
    "year": 1958
  },
  {
    "song_id": "2kKL5kk4On8YRSKWDgXWMX",
    "name": "The Theme From \"A Summer Place\" - Single Version",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/79c6382ec80c9c525d0b712b48d7db90eda5e0df",
    "artist_name": "Percy Faith & His Orchestra",
    "image_url": "https://i.scdn.co/image/9e484dd49f72b5d34bbc0422c1dc77a90c9636bb",
    "artist_id": "24DQLSng7bKZD4GXLIaQbv",
    "year": 1958
  },
  {
    "song_id": "00vH2PsEQTGRyJYhyIyDbr",
    "name": "The Stripper",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/dd1a21b9b2accdde95158297998e90d2a47af5cc",
    "artist_name": "David Rose",
    "image_url": "https://i.scdn.co/image/49cb944007c233e511955d005f67b8ab5a305dc2",
    "artist_id": "2nv8pUrGHqpVzYvQCyiKjk",
    "year": 1958
  },
  {
    "song_id": "09eOoyCzjnX66nOAYtLxY5",
    "name": "Twilight Time",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/e930ab09c8a2da829607b1604dd8dbdd12927155",
    "artist_name": "The Platters",
    "image_url": "https://i.scdn.co/image/cbbfe511aed0586845dc5fee3c3a04647149e086",
    "artist_id": "6KWcxMWVNVIYbdOQyJtsSy",
    "year": 1958
  },
  {
    "song_id": "60DbxCJXFG9ZryHHvUPTXG",
    "name": "Running Bear",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/b2f1fde9def598a1d21375303ab46d7e6fb84a8b",
    "artist_name": "Johnny Preston",
    "image_url": "https://i.scdn.co/image/325542787774d9f1d18faa4b6b84d02682e75408",
    "artist_id": "1B8n8vtEeexQ5NKehHTkeo",
    "year": 1958
  },
  {
    "song_id": "6G43xPTXl0zVgTajZMhW0y",
    "name": "Calcutta",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/f061d9607222abc7663245554f7294ffc7657c7a",
    "artist_name": "Lawrence Welk",
    "image_url": "https://i.scdn.co/image/0a13af13e14d4abfc42ccfd3671dd6e680a95614",
    "artist_id": "2GMH35k5oLCjzYpn5HbaD8",
    "year": 1958
  },
  {
    "song_id": "6sbYrzvV5EDVWzBr6dvnMZ",
    "name": "Our Day Will Come",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/5d71e3ff11f692ff9a9251f2af480e8a69fb79a6",
    "artist_name": "Ruby And The Romantics",
    "image_url": "https://i.scdn.co/image/8bfbe875e0babbabc2d6dcf22ab0c94883359f98",
    "artist_id": "7y0ngZzIllkP8ZOqgTKQFc",
    "year": 1958
  },
  {
    "song_id": "73UL6KmadE2JGoYOCtiNwF",
    "name": "Walk Like A Man",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/f7166c5ff783ac756a1aade27ddbf922c040bdbc",
    "artist_name": "Frankie Valli & The Four Seasons",
    "image_url": "https://i.scdn.co/image/5db5f00f6da7146cedd95aa1f6d06121074c54f5",
    "artist_id": "6mcrZQmgzFGRWf7C0SObou",
    "year": 1958
  },
  {
    "song_id": "3YmvOmGnoctVCiqg0qL8sm",
    "name": "Everybody's Somebopdy's Fool",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/a0aebdd14317f3fd4241a876fa6f13365c6bb242",
    "artist_name": "Connie Francis",
    "image_url": "https://i.scdn.co/image/31b05e185c1714600d1d6d30d80099e7230c02e1",
    "artist_id": "3EY5DxGdy7x4GelivOjS2Q",
    "year": 1958
  },
  {
    "song_id": "6yj3zwjgUptj3LL0Q9PdX5",
    "name": "I Want To Be Wanted",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/922f252d75c9715c575f0988c9cba979c430ca27",
    "artist_name": "Brenda Lee",
    "image_url": "https://i.scdn.co/image/029b09ae667ca730dc1e9e596b5f9f570fb64751",
    "artist_id": "4cPHsZM98sKzmV26wlwD2W",
    "year": 1958
  },
  {
    "song_id": "046Xsqy2GQGdbuHJGlMcxR",
    "name": "Breaking up Is Hard to Do",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/2d6823294260df7398d94d586d9467efbc52a019",
    "artist_name": "Neil Sedaka",
    "image_url": "https://i.scdn.co/image/c44e438f829b0ecd42b649f1192a0ee9930ec210",
    "artist_id": "5N6GwJzOcOY5kv8p0NjhYL",
    "year": 1958
  },
  {
    "song_id": "7KSvIpX8h9mTuCB7QxacLL",
    "name": "It's Only Make Believe",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/914e4f35338eaa19d30dfaf60a7816c86f2f8bee",
    "artist_name": "Conway Twitty",
    "image_url": "https://i.scdn.co/image/d5fee7f7a810cde51f979c3958600ea30677efc8",
    "artist_id": "7gi3jmwpUpNWdswT8eEprF",
    "year": 1958
  },
  {
    "song_id": "4xOq9yg6BOobb5zJHVoEoj",
    "name": "My Heart Has A Mind Of Its Own",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/2a5deaf405d0a5027c13852ff626fba7e1b0c342",
    "artist_name": "Connie Francis",
    "image_url": "https://i.scdn.co/image/943d15e1f5ff75773bb256116484279b63c30558",
    "artist_id": "3EY5DxGdy7x4GelivOjS2Q",
    "year": 1958
  },
  {
    "song_id": "03YauU2fzVzmBOAaOVlZVY",
    "name": "Moody River",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/595a4016466878274e9789917bb42522727ae1f1",
    "artist_name": "Pat Boone",
    "image_url": "https://i.scdn.co/image/f406b197090108132dc12fb02015158fe362f7c2",
    "artist_id": "7fmKtIgmxqNEKjATioVNsu",
    "year": 1958
  },
  {
    "song_id": "52jXpaOLXL5GSZ3pdVsEj2",
    "name": "The Happy Organ",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/f627c7e0fae25aa212cf9333b7667056196433a4",
    "artist_name": "Dave \"Baby\" Cortez",
    "image_url": "https://i.scdn.co/image/c0adadc07d9c853b62f75dc5c8081f4f0e761ccc",
    "artist_id": "7fGPj0sGEcZACQTUVbz3NY",
    "year": 1958
  },
  {
    "song_id": "5tcnuiQOkWSGgNWmH3jzGX",
    "name": "Patricia",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/7b20f79a03712b0c7ed55e5214a7384b7207f630",
    "artist_name": "Perez Prado",
    "image_url": "https://i.scdn.co/image/df658bfe3ba5427f4fe76d85d6871ce5fcda6a09",
    "artist_id": "1ZKhPkCXXgtiGgALn4OYtT",
    "year": 1958
  },
  {
    "song_id": "6wJSzHbbnwNsoq9RFplJpo",
    "name": "Quarter To Three",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/25524aab3cea186d54c64fcc8967c5803c257e79",
    "artist_name": "Gary U.S. Bonds",
    "image_url": "https://i.scdn.co/image/db730d9a523b9c77143d644251f7b57da75ac4ce",
    "artist_id": "1Qw8MHpjYxm9Xf0O1ZfPiX",
    "year": 1958
  },
  {
    "song_id": "738eJvRxkaGktztBCsx9G7",
    "name": "Volare \" Nel blu di pinto di blu\"",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/9fe214e1d170bfd9e571a1fb291e1877396cbf97",
    "artist_name": "Domenico Modugno",
    "image_url": "https://i.scdn.co/image/d40c0f957843d889c81a09be74ff6c70cd57087a",
    "artist_id": "4llklDtTTyMYMY2LfFOkTI",
    "year": 1958
  },
  {
    "song_id": "04uiK7a3CvkUSgZ7CttZ6a",
    "name": "Running Scared",
    "popularity": 0,
    "preview_url": "https://p.scdn.co/mp3-preview/035ca893ae1527201b98c0db103723fef1a011a7",
    "artist_name": "Roy Orbison",
    "image_url": "https://i.scdn.co/image/b248a724ae9a91548bb2cbd7d6eda0b8ecaf431e",
    "artist_id": "0JDkhL4rjiPNEp92jAgJnS",
    "year": 1958
  },
  {
    "song_id": "2EIaO5knwUA3F2qnf9LV3S",
    "name": "Beyond The Sea",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/2ae47768f75d8ea310857c19c3ac9f2f3354e277",
    "artist_name": "Bobby Darin",
    "image_url": "https://i.scdn.co/image/6f5d8e16578d6def7f08d3797f915be64699875c",
    "artist_id": "0EodhzA6yW1bIdD5B4tcmJ",
    "year": 1960
  },
  {
    "song_id": "1sK10Me7qS9em2QTx6dYRe",
    "name": "Chain Gang",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/56fe08c8d182bc561b50814074de0155b38f1b56",
    "artist_name": "Sam Cooke",
    "image_url": "https://i.scdn.co/image/80ec327eb6033adae5ffe41ee72b6086fbcc6ee6",
    "artist_id": "6hnWRPzGGKiapVX1UCdEAC",
    "year": 1960
  },
  {
    "song_id": "2M3OVvigcmanSnRq1sdlVE",
    "name": "Crying",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/9c31b108505522972bb8596ed8a3d32d08f2e0b8",
    "artist_name": "Roy Orbison",
    "image_url": "https://i.scdn.co/image/ad13fc5e74e24d41f6be99d6d9dd213bd5ae98b8",
    "artist_id": "0JDkhL4rjiPNEp92jAgJnS",
    "year": 1960
  },
  {
    "song_id": "4f8hBeMXMvssn6HtFAtblo",
    "name": "El Paso",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/628b8ac21130da787ebd4506f930edc2e2d8ef96",
    "artist_name": "Marty Robbins",
    "image_url": "https://i.scdn.co/image/3d633aaf6ac8e62f6b77a40e62403609d660e724",
    "artist_id": "0Xi59sEw38vRvwleSAVqoo",
    "year": 1960
  },
  {
    "song_id": "7DH5dXVuQaqcKf9oTy9oUN",
    "name": "He'll Have to Go",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/971a606b48616f2c061f86aee154beb0341d9000",
    "artist_name": "Jim Reeves",
    "image_url": "https://i.scdn.co/image/40c378d2db0627fcd2290c353d6f5fc4d299c4f4",
    "artist_id": "2Ev0e8GUIX4u7d9etNLTXg",
    "year": 1960
  },
  {
    "song_id": "5U2uhwJseYO7eH5nEwPb8z",
    "name": "I Fall To Pieces",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/0a10f9d84317e4573bc50f82f0e29c58c02e471c",
    "artist_name": "Patsy Cline",
    "image_url": "https://i.scdn.co/image/3367d1761cf5560e4972b10ac9f33b16e7c02fb4",
    "artist_id": "7dNsHhGeGU5MV01r06O8gK",
    "year": 1960
  },
  {
    "song_id": "1du8zXd6f3hz8cifaPL1t0",
    "name": "Wonderful World",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/bf52dc378c8eda231b8901772bfd6a9285459791",
    "artist_name": "Sam Cooke",
    "image_url": "https://i.scdn.co/image/d5d31bfbfe9e41c9b36872619c63169c02badbc0",
    "artist_id": "6hnWRPzGGKiapVX1UCdEAC",
    "year": 1960
  },
  {
    "song_id": "4vsXBH9u5EH3lRycXHOcII",
    "name": "Sweet Nothin's - Single Version",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/f8a65e28b6c97f1a1faecce999c9d31fcc987757",
    "artist_name": "Brenda Lee",
    "image_url": "https://i.scdn.co/image/a80427592f5340e899c99cf8e80c0ef80bbe63d7",
    "artist_id": "4cPHsZM98sKzmV26wlwD2W",
    "year": 1960
  },
  {
    "song_id": "0QMFMQF3HoSk5WvMyx8aMT",
    "name": "Money (That's What I Want) - Single Version / Mono",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/d43384bed05e9216ac4a241e876a80c75383c227",
    "artist_name": "Barrett Strong",
    "image_url": "https://i.scdn.co/image/7cd03e787ef14816a8523741f56fd1c3c7bfc548",
    "artist_id": "3MKwHkhEjcvzva2rasZeWD",
    "year": 1960
  },
  {
    "song_id": "5VyBNCSb8VXoSxD281PRod",
    "name": "I'm Sorry - Single Version",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/89c0901530bc02c40ea24a3d82326938414c187f",
    "artist_name": "Brenda Lee",
    "image_url": "https://i.scdn.co/image/a80427592f5340e899c99cf8e80c0ef80bbe63d7",
    "artist_id": "4cPHsZM98sKzmV26wlwD2W",
    "year": 1960
  },
  {
    "song_id": "4o9fGL5BfhAjBDxvziWRt2",
    "name": "Please Help Me I'm Falling",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/30e7fd23c9f48ae3fbbd68f6d637e3cdd201408f",
    "artist_name": "Hank Locklin",
    "image_url": "https://i.scdn.co/image/6f8ff0e56ea89b1e75b7d2f50227bf23d3bc08e8",
    "artist_id": "46Unp6DY3Zmy7QS1Fx47yq",
    "year": 1960
  },
  {
    "song_id": "7F3R0rKXq5eYgPug8vqOdu",
    "name": "Greenfields",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/73f583cba34357fcf3b27d5c80d3b9102a8ffc5d",
    "artist_name": "The Brothers Four",
    "image_url": "https://i.scdn.co/image/f45aaab1abc361e633ae5814ec4dc3bc16fa966f",
    "artist_id": "0nd3tsqHVtnAWBCQgwtbIz",
    "year": 1960
  },
  {
    "song_id": "0OLIIwpl8vDQWXYZa7becp",
    "name": "Where Or When",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/e68cd649548ad1cabe12f1e04230a2a8ee1b362a",
    "artist_name": "Dion & The Belmonts",
    "image_url": "https://i.scdn.co/image/611906bcea3e57d67c74dbbab134a90b9aabd343",
    "artist_id": "2loYllWFfoWpoxC5YrJKc4",
    "year": 1960
  },
  {
    "song_id": "38mt8WfEdB6S6yB7WUu4iR",
    "name": "Finger Poppin Time",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/11e5f9a165ac72cad0f7a7982e3668bbf4972b23",
    "artist_name": "Hank Ballard",
    "image_url": "https://i.scdn.co/image/0d3cccffce743be6eb825a1ee6fbd3cce7540879",
    "artist_id": "3R0XvRGDJEL5cGXVLiAbDB",
    "year": 1960
  },
  {
    "song_id": "2SBQBV1atDy4tbEfGicT7u",
    "name": "Heartaches By The Number",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/de4f88728fa792b6c0ae053798d1900b6baa848f",
    "artist_name": "Guy Mitchell",
    "image_url": "https://i.scdn.co/image/832af6a1574c11497b8dd26297412c77f563b8f5",
    "artist_id": "1YAtBHWaWzZnkaoSFHGTBV",
    "year": 1960
  },
  {
    "song_id": "3Aug2RunJbjGiz2CTtWu5l",
    "name": "Itsy Bitsy Teenie Weenie Yellow Polka-Dot Bikini",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/7b6d19f30fea39fde8ef65e6a98c050552263b96",
    "artist_name": "Brian Hyland",
    "image_url": "https://i.scdn.co/image/add84e88232cac41aa1f5565d7396e20d42f98ed",
    "artist_id": "6YROFUbu5zRCHi2xkir5pk",
    "year": 1960
  },
  {
    "song_id": "51PgB7XHoLwTRA4hUaqrqU",
    "name": "Stairway To Heaven - 2001 Remastered",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/8d2b0971591a1404b856a5027400d18ace97ebcc",
    "artist_name": "Neil Sedaka",
    "image_url": "https://i.scdn.co/image/fa302c664b40190f3fb5958147c016747521c425",
    "artist_id": "5N6GwJzOcOY5kv8p0NjhYL",
    "year": 1960
  },
  {
    "song_id": "2XNoOrbIlv70V1siXeBokl",
    "name": "Alley Oop",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/d94a51bac1bec745a31c6bd7139aa342a8854e45",
    "artist_name": "Hollywood Argyles",
    "image_url": "https://i.scdn.co/image/77aa879f5f52ffb31b863d501e2617222fce0d68",
    "artist_id": "6af1KGKyTV3xTilHXaOuZA",
    "year": 1960
  },
  {
    "song_id": "33igcthkkskYSoeL6YmVs6",
    "name": "Good Timin'",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/16a90509093a156e43eb159b60889c98b5cf35d8",
    "artist_name": "Jimmy Jones",
    "image_url": "https://i.scdn.co/image/714b2c7c61821dea66bd506a0f05fa6f5b44be0c",
    "artist_id": "7ydcRbgt0yM9etADb1Ackp",
    "year": 1960
  },
  {
    "song_id": "4F7T8NnGKbhLMVtAMycDQg",
    "name": "Sink The Bismarck",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/b7e7b746998bcf74921b5b81b594dd9933543152",
    "artist_name": "Johnny Horton",
    "image_url": "https://i.scdn.co/image/e47e8101ca49bee430038f9767926ad3a2983341",
    "artist_id": "1bBZcz4jP7CoPlqpCFh4gz",
    "year": 1960
  },
  {
    "song_id": "6jdONXtmF2ZmLyRgZfwDRj",
    "name": "Everybody's Somebody's Fool",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/ab913103d7159540cfc37bc6c9910ac902e22f64",
    "artist_name": "Connie Francis",
    "image_url": "https://i.scdn.co/image/943d15e1f5ff75773bb256116484279b63c30558",
    "artist_id": "3EY5DxGdy7x4GelivOjS2Q",
    "year": 1960
  },
  {
    "song_id": "0jAxhRtKl0vsnRABatOMQ4",
    "name": "Time To Cry",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/0cf284fe299fe6edad2fdf0d5ecfe684c674cb7d",
    "artist_name": "Paul Anka",
    "image_url": "https://i.scdn.co/image/5e5bfbbf197406bccf687b8023484efd4ce7a3b5",
    "artist_id": "7ceUfdWq2t5nbatS6ollHh",
    "year": 1960
  },
  {
    "song_id": "75wzEkDy6NnSj692dA99ip",
    "name": "My Home Town",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/7178ec462e375af11af3cc067a48b0ab5da26053",
    "artist_name": "Paul Anka",
    "image_url": "https://i.scdn.co/image/5e5bfbbf197406bccf687b8023484efd4ce7a3b5",
    "artist_id": "7ceUfdWq2t5nbatS6ollHh",
    "year": 1960
  },
  {
    "song_id": "1mEGgk5PexHpJdPJco5M3A",
    "name": "Happy-Go-Lucky-Me",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/4f8abadf870cd628af28ae8c71309c3b06ca5f4f",
    "artist_name": "Paul Evans",
    "image_url": "https://i.scdn.co/image/a52dda95903ce18eb1deb7777264956092804971",
    "artist_id": "1VCjlBPsXgs5YMMFDWw6nA",
    "year": 1960
  },
  {
    "song_id": "0VcE7se5KgHevmsaCs2bqF",
    "name": "Lonely Weekends",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/8989c9efe3c647a8dd69fbec3c1730c2d35ff7ae",
    "artist_name": "Charlie Rich",
    "image_url": "https://i.scdn.co/image/2a3125b320cf0dde6ba00ca670d563d1c96e3257",
    "artist_id": "218kRJZ7FJs0hWIk8Ynzhz",
    "year": 1960
  },
  {
    "song_id": "2kKL5kk4On8YRSKWDgXWMX",
    "name": "The Theme From \"A Summer Place\" - Single Version",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/79c6382ec80c9c525d0b712b48d7db90eda5e0df",
    "artist_name": "Percy Faith & His Orchestra",
    "image_url": "https://i.scdn.co/image/9e484dd49f72b5d34bbc0422c1dc77a90c9636bb",
    "artist_id": "24DQLSng7bKZD4GXLIaQbv",
    "year": 1960
  },
  {
    "song_id": "51jyAgrsTNvOyZPW949qZR",
    "name": "Burning Bridges - Original Hit Version",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/f21b5319ff96dee00ef0466194f07f68e07a3424",
    "artist_name": "Jack Scott",
    "image_url": "https://i.scdn.co/image/ddd6f3ebd8717af7e77b2bc03710c563b57fbb02",
    "artist_id": "4ucP0bNegd7Q4ewdOKIBfz",
    "year": 1960
  },
  {
    "song_id": "3hvakqVpwaz4L7zN5HfTCY",
    "name": "Why",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/e85294d0672a95ef2598014a0ac9480e81b1fb89",
    "artist_name": "Frankie Avalon",
    "image_url": "https://i.scdn.co/image/9a7568f3a9b76712e14c8026a5ce19d425817149",
    "artist_id": "5zNOI87gG4RttFmYAZWaxQ",
    "year": 1960
  },
  {
    "song_id": "6H8M8oMiheCp9ewSKVHNvM",
    "name": "A Rockin' Good Way (To Mess Around And Fall In Love)",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/bcd9af303f2359695c772eca1caaa9ca70508453",
    "artist_name": "Dinah Washington",
    "image_url": "https://i.scdn.co/image/31f04c75afdce6594d02b8bb98d870daa885b17b",
    "artist_id": "32LHRiof0sa4taYew9i3Fa",
    "year": 1960
  },
  {
    "song_id": "60DbxCJXFG9ZryHHvUPTXG",
    "name": "Running Bear",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/b2f1fde9def598a1d21375303ab46d7e6fb84a8b",
    "artist_name": "Johnny Preston",
    "image_url": "https://i.scdn.co/image/325542787774d9f1d18faa4b6b84d02682e75408",
    "artist_id": "1B8n8vtEeexQ5NKehHTkeo",
    "year": 1960
  },
  {
    "song_id": "54cJ6nIVHYwa7VuI7oau43",
    "name": "Young Emotions - Digitally Remastered 05",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/5803bee86caaaa3729e3249cdb624d0942e0d8f4",
    "artist_name": "Ricky Nelson",
    "image_url": "https://i.scdn.co/image/59cc40aff8a29ccf55b9b74a53b7f978480f805c",
    "artist_id": "73sSFVlM6pkweLXE8qw1OS",
    "year": 1960
  },
  {
    "song_id": "37UxQONhYhMJ2F9SX9fhHW",
    "name": "Theme From \"The Apartment\"",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/dc493a8e572b4020f9ffb2c7434f640cefeb0d87",
    "artist_name": "Ferrante & Teicher",
    "image_url": "https://i.scdn.co/image/34e8dc935dd3cfe164edf13abbbb2f0cf7f6f218",
    "artist_id": "17rSwcIT9qu1OybU1lReJB",
    "year": 1960
  },
  {
    "song_id": "0nehTUuaN88UMQwrJrD53T",
    "name": "So Sad (To Watch Good Love Go Bad)",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/00bc69a9146a77a7e6df2e1188eb7ba7752cb228",
    "artist_name": "The Everly Brothers",
    "image_url": "https://i.scdn.co/image/4584f230c8c2fd3d7d4ce4a05f61828f78e6f84e",
    "artist_id": "4ACplpEqD6JIVgKrafauzs",
    "year": 1960
  },
  {
    "song_id": "3R7hiVF84AZq8BcYM470Sp",
    "name": "I Want To Be Wanted (Per Tutta La Vita) - Remake Version",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/989498a8b0dba56078fd13683af42d948d3349f7",
    "artist_name": "Brenda Lee",
    "image_url": "https://i.scdn.co/image/e1d88487989ff5514c46b27016e589ad2a685892",
    "artist_id": "4cPHsZM98sKzmV26wlwD2W",
    "year": 1960
  },
  {
    "song_id": "35JY4nPc4o9FjtM8gNP0LP",
    "name": "Doggin Around",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/9eed043bb7fda8486ffca7459c31e28de3997237",
    "artist_name": "Jackie Wilson",
    "image_url": "https://i.scdn.co/image/a9382dda88cd0615a405a8f2431b56a3b1a3e870",
    "artist_id": "4VnomLtKTm9Ahe1tZfmZju",
    "year": 1960
  },
  {
    "song_id": "78hr1LxCs9x51GtLU82708",
    "name": "Image Of A Girl",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/204e3743f43a9bb8c2822919972c604a813631c4",
    "artist_name": "The Safaris",
    "image_url": "https://i.scdn.co/image/f33e20c4274aae932715f0797b72e526efba9dff",
    "artist_id": "4LYmQXFj0BsQMrcigsdWll",
    "year": 1960
  },
  {
    "song_id": "2OcqUKB9Fx9IN7nd9YW25n",
    "name": "Beatnik Fly",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/cd1f14ee3fc561f5e842eebb130e328e7ec1e029",
    "artist_name": "Johnny & The Hurricanes",
    "image_url": "https://i.scdn.co/image/27e257a4d22bf1a1895249fb0f895415eb831fc8",
    "artist_id": "5sgpmIi6mJNrm3fs3swCa7",
    "year": 1960
  },
  {
    "song_id": "0ZIdgRonT70YtgXBPFt1bm",
    "name": "What In The World's Come Over You",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/d96a09d2f789705942ce1df1bd1c12da567738ef",
    "artist_name": "Jack Scott",
    "image_url": "https://i.scdn.co/image/cd85e462de6143e89495ae6bc7c26c3b3819c8a1",
    "artist_id": "4ucP0bNegd7Q4ewdOKIBfz",
    "year": 1960
  },
  {
    "song_id": "4xOq9yg6BOobb5zJHVoEoj",
    "name": "My Heart Has A Mind Of Its Own",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/2a5deaf405d0a5027c13852ff626fba7e1b0c342",
    "artist_name": "Connie Francis",
    "image_url": "https://i.scdn.co/image/943d15e1f5ff75773bb256116484279b63c30558",
    "artist_id": "3EY5DxGdy7x4GelivOjS2Q",
    "year": 1960
  },
  {
    "song_id": "1kwLmeXUH7SLuEus82e2lj",
    "name": "Let It Be Me",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/b06cc3a31a55fb6302859a21574215794077e1b6",
    "artist_name": "The Everly Brothers",
    "image_url": "https://i.scdn.co/image/7fcc94c514c40a31621df71b6ebe454553342420",
    "artist_id": "4ACplpEqD6JIVgKrafauzs",
    "year": 1960
  },
  {
    "song_id": "0RTkpGULCWBGHFbJptu3RQ",
    "name": "Among My Souvenirs",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/7904c2cfb60888068fcbad4b51e6a195b0917cc9",
    "artist_name": "Connie Francis",
    "image_url": "https://i.scdn.co/image/943d15e1f5ff75773bb256116484279b63c30558",
    "artist_id": "3EY5DxGdy7x4GelivOjS2Q",
    "year": 1960
  },
  {
    "song_id": "0HLPxlV5d2iBY126Q2LoxI",
    "name": "Step By Step",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/be5d586585781d2ed92a15b4291bf5e58bd98362",
    "artist_name": "The Crests",
    "image_url": "https://i.scdn.co/image/d404a2b90ae55b5b192a3b804e95590dbce431f4",
    "artist_id": "64vw6q9ZBTop3Tf2ol1x4U",
    "year": 1960
  },
  {
    "song_id": "6ocPNo99zEKqbJalKuMyxO",
    "name": "Harbor Lights",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/ecf12d49f6c45a6906f3187858bfb55972b14cea",
    "artist_name": "The Platters",
    "image_url": "https://i.scdn.co/image/d404a2b90ae55b5b192a3b804e95590dbce431f4",
    "artist_id": "6KWcxMWVNVIYbdOQyJtsSy",
    "year": 1960
  },
  {
    "song_id": "4OgCE8bmGO7eatG3OuBEju",
    "name": "He'll Have To Stay - Original Hit Version",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/721f29f6cdc6889dbc1d9c69c1611371a7b3a74b",
    "artist_name": "Jeanne Black",
    "image_url": "https://i.scdn.co/image/ddd6f3ebd8717af7e77b2bc03710c563b57fbb02",
    "artist_id": "4f2WSqFHOISjWlyl9mYJJw",
    "year": 1960
  },
  {
    "song_id": "44zS9qRw8FSk752N6vYXHT",
    "name": "Tell Laura I Love Her",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/26aef35b513256b5e3966e861f5ed3ad6ab8e975",
    "artist_name": "Ray Peterson",
    "image_url": "https://i.scdn.co/image/18ee2c328ee7cdd5d82eab7d6dcfdfa60aa3ef7e",
    "artist_id": "7BDI9Iqt24gl4RGdS6hWs9",
    "year": 1960
  },
  {
    "song_id": "3t9WLlI46YJsbjhB0Na76s",
    "name": "Sandy",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/3278c5f2425b02ff7256c14849b7ffe9cc42aced",
    "artist_name": "Larry Hall",
    "image_url": "https://i.scdn.co/image/9657d075962eea17d5a76daf359bddce77b784f3",
    "artist_id": "17nxEt3oZte3eUuVio6W55",
    "year": 1960
  },
  {
    "song_id": "3lkD4dzxIUCOC0j7MmnQ2D",
    "name": "Dreamin'",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/329deb6d87c42997c4dfa48599a63dae40caf302",
    "artist_name": "Johnny Burnette",
    "image_url": "https://i.scdn.co/image/a9c5b06f4dcf5b386f59346f2143fca12b902587",
    "artist_id": "3PrFCybHMqrcPls31JxoIl",
    "year": 1960
  },
  {
    "song_id": "624RgBQtIm3147GMl9FNCl",
    "name": "Michael",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/1276c7afebf9a2108e66ccd6b5b7e2ae458bd508",
    "artist_name": "Michael",
    "image_url": "https://i.scdn.co/image/ff7a335081c547b5258082780e530a2e529e939a",
    "artist_id": "1ognVtITaGryRLo0lGgKY9",
    "year": 1960
  },
  {
    "song_id": "52gdxdolPNwBJZLAZDG1Md",
    "name": "Night",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/5c4be97caa962b1946c414f46114b8275859d702",
    "artist_name": "Jackie Wilson",
    "image_url": "https://i.scdn.co/image/a9382dda88cd0615a405a8f2431b56a3b1a3e870",
    "artist_id": "4VnomLtKTm9Ahe1tZfmZju",
    "year": 1960
  },
  {
    "song_id": "4SwKYzkpIiyPP65xte4eHs",
    "name": "Cherry Pie - Artist: Skip & Flip",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/3047cb1ddaeb43ef251c189585ebcb6373184b41",
    "artist_name": "Various Artists",
    "image_url": "https://i.scdn.co/image/e11664699423f606698b16733f54d7435b043594",
    "artist_id": "0LyfQWJT6nXafLPZqxe9Of",
    "year": 1960
  },
  {
    "song_id": "0W3nMTF5gSgzVwddfUU9Ve",
    "name": "The Old Lamplighter",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/03093ec88e5ff1d9dc1961e990c79dae3830e854",
    "artist_name": "The Browns",
    "image_url": "https://i.scdn.co/image/16a6afb95f876f2d8e448568f2fc3ebc77e83a58",
    "artist_id": "6GDSMFwAQBfnrrZf981Yji",
    "year": 1960
  },
  {
    "song_id": "1vsi4F7CDORvi2vXEazHYS",
    "name": "Tracy's Theme",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/7c902d6842dccb9d3a6d12a876757996c8c99d4d",
    "artist_name": "Spencer Ross",
    "image_url": "https://i.scdn.co/image/67276d404d4c0c508c87c5d3c9c1d677caa475bc",
    "artist_id": "3RMm7nqy4t1VU1O3ffDuYP",
    "year": 1960
  },
  {
    "song_id": "0ZqhPLdc7yEv6iADB7no3F",
    "name": "Little Bitty Girl",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/95733c29308b66dcf133104acdefbedddf54576c",
    "artist_name": "Bobby Rydell",
    "image_url": "https://i.scdn.co/image/736d1f8a21f06ab8073f640ca5c1baaa7f759a2c",
    "artist_id": "4hJTgr1adnhIkhmD9jLzd6",
    "year": 1960
  },
  {
    "song_id": "0LUWi58l0cfc9Gm2A8Wegw",
    "name": "In My Little Corner Of The World",
    "popularity": 7,
    "preview_url": "https://p.scdn.co/mp3-preview/e7a3cae40eaf30b1d6a43e67d6ebd8e9461accc9",
    "artist_name": "Anita Bryant",
    "image_url": "https://i.scdn.co/image/d70b700264c2bb0d7d1834b92963bb54c514691d",
    "artist_id": "2M93P87MDGek6uzg7Jn7he",
    "year": 1960
  },
  {
    "song_id": "2MWxpIwgwY4wc4tFs6NyhS",
    "name": "Lady Luck",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/c168e6150f6f3cb81008e30337aedc3c50db4b94",
    "artist_name": "Lloyd Price",
    "image_url": "https://i.scdn.co/image/39b7d225a5abd68e50c7eddf10b69843385ec32f",
    "artist_id": "3iOE5ItEv5xr9fmKi7GNh2",
    "year": 1960
  },
  {
    "song_id": "1wkIJldiqV38nKjuHlZN5A",
    "name": "Puppy Love",
    "popularity": 5,
    "preview_url": "https://p.scdn.co/mp3-preview/c3328b4871b1efdf8801299d96c7e37ef5528f90",
    "artist_name": "Paul Anka",
    "image_url": "https://i.scdn.co/image/93db6b85db17a3e8bea41993d6a1a73faf7e6ee9",
    "artist_id": "7ceUfdWq2t5nbatS6ollHh",
    "year": 1960
  },
  {
    "song_id": "1VimmYjhyPMrRE6r9qC6xb",
    "name": "Let's Think About Living",
    "popularity": 5,
    "preview_url": "https://p.scdn.co/mp3-preview/8fed5c2002db6f47611121991c91b5c66e8db508",
    "artist_name": "Bob Luman",
    "image_url": "https://i.scdn.co/image/fe9c51111de367a139490135a26ecd56d1fe8d65",
    "artist_id": "7GHomDn4a8u8k0AlUTwBWQ",
    "year": 1960
  },
  {
    "song_id": "1lNeiSfvFV8T9liehovsoL",
    "name": "Go, Jimmy, Go",
    "popularity": 4,
    "preview_url": "https://p.scdn.co/mp3-preview/69cf9bf1fb54b7dee7c4d3f41e045e665ba33c1c",
    "artist_name": "Jimmy Clanton",
    "image_url": "https://i.scdn.co/image/8346502e496c1bff0363845b349976376ba7a001",
    "artist_id": "2XZXvrqedRMiKv6UWjAT4B",
    "year": 1960
  },
  {
    "song_id": "4XuDYDtQwn4cEcSeB7xQuL",
    "name": "Way Down Yonder In New Orleans",
    "popularity": 4,
    "preview_url": "https://p.scdn.co/mp3-preview/841e2d17379dfbd6ff9d9487c62f961e32c16bfe",
    "artist_name": "Freddy Cannon",
    "image_url": "https://i.scdn.co/image/bfd494a3215755845c34a5b9b150c40726e8ec2e",
    "artist_id": "1ffNa7yLg0ncUpBm5P03pm",
    "year": 1960
  },
  {
    "song_id": "4sN4OrS1dWZBo18IxzoKbN",
    "name": "Forever",
    "popularity": 4,
    "preview_url": "https://p.scdn.co/mp3-preview/e930c54445b8bc39bbe08398dccf56c06b0eca6f",
    "artist_name": "Little Dippers",
    "image_url": "https://i.scdn.co/image/8e43b50abb4341e9475674748ec1cfa5c4b6acb4",
    "artist_id": "44AC1SdKcsFB1gaE05lqvv",
    "year": 1960
  },
  {
    "song_id": "1kQ3aC8JDEFAecd3H0igYA",
    "name": "Footsteps",
    "popularity": 4,
    "preview_url": "https://p.scdn.co/mp3-preview/1922901cbdf57ebe038c5034cf7b44206b3a0bd6",
    "artist_name": "Steve Lawrence",
    "image_url": "https://i.scdn.co/image/18ee2c328ee7cdd5d82eab7d6dcfdfa60aa3ef7e",
    "artist_id": "271pvVqDFiREx6PqzwOX8p",
    "year": 1960
  },
  {
    "song_id": "4hXgqlhIInOYiOzBK2S1sN",
    "name": "White Silver Sands",
    "popularity": 2,
    "preview_url": "https://p.scdn.co/mp3-preview/802fce7d0ec6a9bc7bb8a564b8fc66a0a6410d53",
    "artist_name": "Bill Black's Combo",
    "image_url": "https://i.scdn.co/image/58556e21dbbeba79c9ac9523540f053848c5ab44",
    "artist_id": "45iwrW9MTdauua4etsP41U",
    "year": 1960
  },
  {
    "song_id": "2L3EQNvy6BbXaUrVsoxWzz",
    "name": "Paper Roses",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/791b10d9fda8a3f2d43615e4485db8e8569af4bd",
    "artist_name": "Anita Bryant",
    "image_url": "https://i.scdn.co/image/d70b700264c2bb0d7d1834b92963bb54c514691d",
    "artist_id": "2M93P87MDGek6uzg7Jn7he",
    "year": 1960
  },
  {
    "song_id": "0LvVSeRtApthJ4mUOFsHMt",
    "name": "Let the Little Girl Dance",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/9e2e2e324210e915a27fe8faee64f6354ed6bfb4",
    "artist_name": "Billy Bland",
    "image_url": "https://i.scdn.co/image/b5ca5e471cc2762ac37dcae85dd197d8f31bfb3b",
    "artist_id": "4mDEpwlcBham5q1dmykSG0",
    "year": 1960
  },
  {
    "song_id": "2G2YzndIA6jeWFPBXhUjh5",
    "name": "Be My Baby",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/9fed578f80a96230a0aafa3868b1ad96e54419d5",
    "artist_name": "The Ronettes",
    "image_url": "https://i.scdn.co/image/109f9370b54e1193028296cab0081c77aec8b4de",
    "artist_id": "7CyeXFnOrfC1N6z4naIpgo",
    "year": 1963
  },
  {
    "song_id": "1p9rtS9FiIFUP1BrkKheST",
    "name": "Walk Like A Man",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/f7166c5ff783ac756a1aade27ddbf922c040bdbc",
    "artist_name": "Frankie Valli & The Four Seasons",
    "image_url": "https://i.scdn.co/image/f4430f809fc003be28cf0d0f4710646c66c93fad",
    "artist_id": "6mcrZQmgzFGRWf7C0SObou",
    "year": 1963
  },
  {
    "song_id": "5h0b1G7VLOqTiiVgVKywFG",
    "name": "In Dreams",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/8071ddcfa574ec8bf18411525fb28b8e5307ef29",
    "artist_name": "Roy Orbison",
    "image_url": "https://i.scdn.co/image/c04d9466110de69cad81e599209450947881fcc8",
    "artist_id": "0JDkhL4rjiPNEp92jAgJnS",
    "year": 1963
  },
  {
    "song_id": "5DTOOkooKFUvWj1XQTFa09",
    "name": "The End Of The World",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/16c0a930b298c36c99863ce81bf2a45d0a148de1",
    "artist_name": "Skeeter Davis",
    "image_url": "https://i.scdn.co/image/e8b2c602b85c81999ac960e259e03578e6ac6da1",
    "artist_id": "5b2OzvLaL6nyxw5pbVbSdy",
    "year": 1963
  },
  {
    "song_id": "4QelFzhVgLomeQhvKrwM1S",
    "name": "Blue Velvet - Single Version",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/cbc0ae402b02e30709b543274a8eca2372a7f391",
    "artist_name": "Bobby Vinton",
    "image_url": "https://i.scdn.co/image/0001270788af82d1b630b33323917f8af070711d",
    "artist_id": "6bOYtKnpLPQSfMpS2ilotK",
    "year": 1963
  },
  {
    "song_id": "7raciFPVU5VuHmqVbw2c1h",
    "name": "Puff, The Magic Dragon - Remastered",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/5c8e7f3576f5c1c8594c4b3f87bb094b0f114ed2",
    "artist_name": "Peter, Paul and Mary",
    "image_url": "https://i.scdn.co/image/ff05c2ea2aa9cc72696084714e0c8520d795fde2",
    "artist_id": "6yrBBtqX2gKCHCrZOYBDrB",
    "year": 1963
  },
  {
    "song_id": "7GHf8dQ2wA71wYOuRsQCZP",
    "name": "Rhythm Of The Rain",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/888d7851ce6c0b82281c60f75eb26355397a7919",
    "artist_name": "The Cascades",
    "image_url": "https://i.scdn.co/image/9b17e8152ad7c6369850f97ceaa1d892abc21dcd",
    "artist_id": "6qF0CJP412Gd50Rn1sFg2o",
    "year": 1963
  },
  {
    "song_id": "4Iy6HzMy5aA1Jjq5GMgpMW",
    "name": "Another Saturday Night",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/ae9d1d20b6740ba839ac63d061e4c445daf1bcab",
    "artist_name": "Sam Cooke",
    "image_url": "https://i.scdn.co/image/493c4d3720ac831748acaf569edce1a15b40900e",
    "artist_id": "6hnWRPzGGKiapVX1UCdEAC",
    "year": 1963
  },
  {
    "song_id": "71gllRzHoMKgU7b2y0OfM7",
    "name": "500 Miles Away from Home",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/05638888f21a55e89e6d8250ff845eea6b0e5c5c",
    "artist_name": "Bobby Bare",
    "image_url": "https://i.scdn.co/image/aa5a54ea42a56173a2c6eb03b907d277c3072252",
    "artist_id": "69wzuykaVXlRS5KVygESvd",
    "year": 1963
  },
  {
    "song_id": "41zpWL98BsYKP5nvtdTM5z",
    "name": "Can't Get Used to Losing You - Single Version",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/fcb194353f0f5e7f4ec250379180bf6fe553268d",
    "artist_name": "Andy Williams",
    "image_url": "https://i.scdn.co/image/540257c9f458d3357960ab0124c3663da3572800",
    "artist_id": "4sj6D0zlMOl25nprDJBiU9",
    "year": 1963
  },
  {
    "song_id": "0XIe81wYNtdvIttRAUEau4",
    "name": "Blowin' in the Wind - Remastered LP Version",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/482837e6dcfc2c13f5ea8a4b853f99b69f392c32",
    "artist_name": "Peter, Paul and Mary",
    "image_url": "https://i.scdn.co/image/ff05c2ea2aa9cc72696084714e0c8520d795fde2",
    "artist_id": "6yrBBtqX2gKCHCrZOYBDrB",
    "year": 1963
  },
  {
    "song_id": "4KFCyusUzxTL8PwE0AH02f",
    "name": "Wipeout",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/46f01c559f0b3dd3a8b54152c222793b0e610a71",
    "artist_name": "The Surfaris",
    "image_url": "https://i.scdn.co/image/c9463d9e1040085d662a1e756815b583a5474234",
    "artist_id": "6gZVflqhSHhG3MjYrf1dOv",
    "year": 1963
  },
  {
    "song_id": "39jAJtQgmUwLJdt02MylN4",
    "name": "If You Wanna Be Happy",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/514a64ee0f9ebe31b43efac10497fec9589cd7cd",
    "artist_name": "Jimmy Soul",
    "image_url": "https://i.scdn.co/image/35e186d14a14036022088c173eb5b513448dfc71",
    "artist_id": "7k4MYPtpz3fAViMuuLi9ry",
    "year": 1963
  },
  {
    "song_id": "141K1Yezgm4SglALjqbjA4",
    "name": "You've Really Got A Hold On Me",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/1100e9ee547fd1f32c3cfe32d7815b9f69096849",
    "artist_name": "The Miracles",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "6TqQLejnHXMGr7KcegxUND",
    "year": 1963
  },
  {
    "song_id": "6meIeOX3DHdaCnaNw67abE",
    "name": "Sukiyaki - Original Hit Version",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/1a20045fbeac758ecc0432f46ec19dccf48a80e6",
    "artist_name": "Kyu Sakamoto",
    "image_url": "https://i.scdn.co/image/ddd6f3ebd8717af7e77b2bc03710c563b57fbb02",
    "artist_id": "3WKkjW8B7x5UZmfC9pnDmN",
    "year": 1963
  },
  {
    "song_id": "7sVxvaLH9Bx9YZyBjdRpdQ",
    "name": "Surfin' U.S.A. - 2001 - Remaster",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/4435d5789d1c6f1fd844e7680eb8a36186484291",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/ae836f6d817f3f8432425e56be5f18c17c916b0e",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1963
  },
  {
    "song_id": "5NiFSI8iIDtVm1NCwdYrHS",
    "name": "My Boyfriend's Back",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/d25956c4f35d872b918cd6d911300567afdda981",
    "artist_name": "The Angels",
    "image_url": "https://i.scdn.co/image/ce429d6e0bc3e055f7a4b9888b0efc990babd495",
    "artist_id": "2PeqTZKroEc2oDwTfmB2al",
    "year": 1963
  },
  {
    "song_id": "4WhT2YADjcU58IUgroWZQZ",
    "name": "Walk Right In",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/aea458f8d6e0dceac6bd07673ec4b8b5d85e8ce5",
    "artist_name": "THE ROOFTOP SINGERS",
    "image_url": "https://i.scdn.co/image/224f25fdaedd239e7b04eaad58141af34b502db4",
    "artist_id": "6Gb08eT3LlkGynDscl9vbz",
    "year": 1963
  },
  {
    "song_id": "4AFLFG6pyvhBLC37FbDwc2",
    "name": "Candy Girl",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/2dbbf47e40bbeebab94fa9f33f56012aac84fd66",
    "artist_name": "Frankie Valli & The Four Seasons",
    "image_url": "https://i.scdn.co/image/f4430f809fc003be28cf0d0f4710646c66c93fad",
    "artist_id": "6mcrZQmgzFGRWf7C0SObou",
    "year": 1963
  },
  {
    "song_id": "39gvhionjcw3DDynncAtXu",
    "name": "Hey Paula",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/a06e7d6c4a1f12ec180eea7039dc54c8329293fc",
    "artist_name": "Paul & Paula",
    "image_url": "https://i.scdn.co/image/58d231a1f7dac8fdd934fb76c319300e60e7c429",
    "artist_id": "2pycVt1qR9u8bKdb4oLI98",
    "year": 1963
  },
  {
    "song_id": "0JKW3OcKyuAKBkqRwphTXj",
    "name": "Just One Look",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/ce799aff60bc77d8f1687c6074263fe5d7dfb280",
    "artist_name": "Doris Troy",
    "image_url": "https://i.scdn.co/image/ed46aed5ed8896bfb1652ae656a72d2a8fdfa068",
    "artist_id": "3jiBDfjnWIdLQEEOdKqZex",
    "year": 1963
  },
  {
    "song_id": "3Y6xbMmySNUWJTwmLwXSki",
    "name": "(You're The) Devil in Disguise",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/19457e566427d451f20d23c20dafd988665c294c",
    "artist_name": "Elvis Presley",
    "image_url": "https://i.scdn.co/image/98128f5c15aee1d481fb7722dbe71e8268ffbdcd",
    "artist_id": "43ZHCT0cAZBISjO8DG9PnE",
    "year": 1963
  },
  {
    "song_id": "1mspRB1JIDtaMPXewN8XOs",
    "name": "I Will Follow Him",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/ebd16c0c51107949eb835e1ae85cac4eef346a2f",
    "artist_name": "Little Peggy March",
    "image_url": "https://i.scdn.co/image/5aa3da2bcc17ae55b5c4d318a639bae92c276b24",
    "artist_id": "09vvlnqwFaimZwGAvpXgqy",
    "year": 1963
  },
  {
    "song_id": "44Btht5msS0IQ7o2DZuFdu",
    "name": "If I Had A Hammer",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/bfecc9f89db15d24eda60463b0ab32b86c701ae3",
    "artist_name": "Trini Lopez",
    "image_url": "https://i.scdn.co/image/53cc976c84598fd7195b54fd0a453dc54fdd9f45",
    "artist_id": "5FlTKgucbhHvlJVf0pnvOv",
    "year": 1963
  },
  {
    "song_id": "6iFYsQckCCtGsabW0rXEPn",
    "name": "Walking The Dog",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/ca595495cd2f64a48fced911737b9e0b1b329ffc",
    "artist_name": "Rufus Thomas",
    "image_url": "https://i.scdn.co/image/6103b271f08ba194fcc4a749e476cd6d24856dea",
    "artist_id": "3iRSHS3b4NUdjNbnw3Opg8",
    "year": 1963
  },
  {
    "song_id": "60OPjeS1eJmQeHINSCGDs3",
    "name": "And Then He Kissed Me",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/536505b466a5e93c41e0b06956d357ce058ba966",
    "artist_name": "The Crystals",
    "image_url": "https://i.scdn.co/image/e449846a5a55ecfac79336dbb3138204d7f093c5",
    "artist_id": "7rewR1TVjhisjI6gauUamf",
    "year": 1963
  },
  {
    "song_id": "6BiQOxPWVZvum3BZ2Bd4a7",
    "name": "Cry Baby",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/5f80d8137c51c7cb4b711c1a93784691b357a268",
    "artist_name": "Garnet Mimms & The Enchanters",
    "image_url": "https://i.scdn.co/image/e2d2aa99c57756ae050b649e0815407f33f61c7d",
    "artist_id": "1ktvF9yU9iWgY0aGlKfTS4",
    "year": 1963
  },
  {
    "song_id": "0AxfDLUdMf5yFCmcZCtOdS",
    "name": "Surfer Girl - 2001 - Remaster",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/358aa89ac19c16b7b4ad4807d8f5280bb9ae4ea5",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/56ed59852eb9cb45c63152cbdddabb06d0abdbec",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1963
  },
  {
    "song_id": "5d75IQ8avLzit0XeeddeUe",
    "name": "Donna the Prima Donna",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/b0fe7d89e8e3cd29257ff9b4768c5d1faa9ce7c1",
    "artist_name": "Dion",
    "image_url": "https://i.scdn.co/image/565ad2748469e8d666cad115db62606b9f7d6681",
    "artist_id": "15FyiY3ChN0QRspHIQYq0W",
    "year": 1963
  },
  {
    "song_id": "7teHkhuaqXUFq2tyZ8YO4G",
    "name": "Tie Me Kangaroo Down Sport",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/a3bd311ef8081f4a4ed852fc95ed5e5ffc78ce10",
    "artist_name": "Rolf Harris",
    "image_url": "https://i.scdn.co/image/f4cb3f0f8be199d4978afebbf23c8aa69629c8b7",
    "artist_id": "50KLYZQtZLrR2gGa5oHJEF",
    "year": 1963
  },
  {
    "song_id": "7ntoFNKLPggMM1E13GdffZ",
    "name": "That Sunday, That Summer - 1999 Digital Remaster",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/10785a968b37b3f4828a4c5243fb67403bdf5280",
    "artist_name": "Nat King Cole",
    "image_url": "https://i.scdn.co/image/17348108c7386b6209f2cc54850c55491eefb5d3",
    "artist_id": "7v4imS0moSyGdXyLgVTIV7",
    "year": 1963
  },
  {
    "song_id": "0omhdZrHNm5HqKfOJF7pfY",
    "name": "Pipeline",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/9ef2f242446d6b1dd66dae84a1f7c22c2f81d619",
    "artist_name": "The Chantays",
    "image_url": "https://i.scdn.co/image/c9463d9e1040085d662a1e756815b583a5474234",
    "artist_id": "4x6kNCpQ9veqQ17vllEJUR",
    "year": 1963
  },
  {
    "song_id": "2Y6btCoxL2RThLo9G77ORZ",
    "name": "Blame It On The Bossa Nova",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/b1b3e15ff3b38676d888917ba808d32267ddd4d5",
    "artist_name": "Eydie Gorme",
    "image_url": "https://i.scdn.co/image/d87d0e9115b6eaf1a0fc948bc2de9080bdd9c4bf",
    "artist_id": "6HnHBbeScFiQKXt3sUQA3Z",
    "year": 1963
  },
  {
    "song_id": "2uyTuHLAB3unDK1vkjViH5",
    "name": "Denise - Original Hit Version",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/7ea7bd02febfd87002b1647cefd7cb30cc424987",
    "artist_name": "Randy & The Rainbows",
    "image_url": "https://i.scdn.co/image/ddd6f3ebd8717af7e77b2bc03710c563b57fbb02",
    "artist_id": "2V0ryJRGIdudWKl27c806m",
    "year": 1963
  },
  {
    "song_id": "0VtvetMMdBu88MbUeM5YnL",
    "name": "The Monkey Time",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/3531515bd20a2015154073b3eaa864a0d1dd74fd",
    "artist_name": "Major Lance",
    "image_url": "https://i.scdn.co/image/8c3143e502634961ed644654508156c93c79b524",
    "artist_id": "7onp6ew3LGoQImTt1I78gt",
    "year": 1963
  },
  {
    "song_id": "0f7SDy8sC5Kw4bhN1u4yWX",
    "name": "Fools Rush In (Where Angels Fear To Tread)",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/da3aa426db98d87325ea0bb607c6ede2b190d189",
    "artist_name": "Ricky Nelson",
    "image_url": "https://i.scdn.co/image/a2ddad142967808ae8c92d9dbdcf0db05e4a8a59",
    "artist_id": "73sSFVlM6pkweLXE8qw1OS",
    "year": 1963
  },
  {
    "song_id": "0ItlbY5mHDSYQd3SOgAlZu",
    "name": "Those Lazy, Hazy, Crazy Days Of Summer - 1990 Digital Remaster",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/967f71dc699f0a2dfbab86e4ba77dccf6bad3a78",
    "artist_name": "Nat King Cole",
    "image_url": "https://i.scdn.co/image/ef14164feb45dff6e6e4494876cd24bc58e3b75a",
    "artist_id": "7v4imS0moSyGdXyLgVTIV7",
    "year": 1963
  },
  {
    "song_id": "1EhMUZc4oAtepRdcuI6Uni",
    "name": "Shut Down - 2003 Digital Remaster",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/8c62490fca986321ace12ab27969604a06fe2857",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/ae836f6d817f3f8432425e56be5f18c17c916b0e",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1963
  },
  {
    "song_id": "7v8YDCoM60xCPuSbXerImD",
    "name": "Hello Stranger",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/a8e4b576841c487a9db1068d0001da4e3e55dd90",
    "artist_name": "Barbara Lewis",
    "image_url": "https://i.scdn.co/image/acd1c0de67736c7d79c594651153db5d3d8bfa7a",
    "artist_id": "2UocIcNiHj5n4tj1CnBzRq",
    "year": 1963
  },
  {
    "song_id": "2v7c2fC0U8VBrBiKahq6uV",
    "name": "Washington Square",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/eae8542394c77eb06a45e11da2f7c2ec48acfafa",
    "artist_name": "The Village Stompers",
    "image_url": "https://i.scdn.co/image/d6d040b8299a7bab0462ba086d63d9a2155cb900",
    "artist_id": "052sD9jNgGqNAKlYmCAiD8",
    "year": 1963
  },
  {
    "song_id": "5SlnifhSwzhn2S40O45Tl9",
    "name": "Little Red Rooster",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/3bffacbc077912bee3ec6183777eee18490f09ae",
    "artist_name": "Sam Cooke",
    "image_url": "https://i.scdn.co/image/80ec327eb6033adae5ffe41ee72b6086fbcc6ee6",
    "artist_id": "6hnWRPzGGKiapVX1UCdEAC",
    "year": 1963
  },
  {
    "song_id": "5KwUdGcezcmz9sTFDRhpMf",
    "name": "Easier Said Than Done",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/26ca964710cd092481ec80e88527af39dd5d57fd",
    "artist_name": "The Essex",
    "image_url": "https://i.scdn.co/image/a8ff54b0446318af69319e9f5eb5a5165683b44c",
    "artist_id": "1MRFkxDxTMqNTZJSWgW7VL",
    "year": 1963
  },
  {
    "song_id": "4hlaDxMw1DkKKVSkuKVebK",
    "name": "Can't We Be Sweethearts",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/2230a2e98a3bd0714f92a488b2749e1c0b0e62b6",
    "artist_name": "Dion",
    "image_url": "https://i.scdn.co/image/565ad2748469e8d666cad115db62606b9f7d6681",
    "artist_id": "15FyiY3ChN0QRspHIQYq0W",
    "year": 1963
  },
  {
    "song_id": "2sWRC12vYVTHjdTD2WOE4v",
    "name": "Days Of Wine And Roses - REMASTERED",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/e3dc9c5003ec0355e704ff5c6e75f859c473a748",
    "artist_name": "Henry Mancini",
    "image_url": "https://i.scdn.co/image/532e10f093fabe93a960c629fc4ab1b1caca1050",
    "artist_id": "2EExdpjU4SK3xnJHO5paJf",
    "year": 1963
  },
  {
    "song_id": "4H5SDZUHWIOmZ517yhZ9zv",
    "name": "Judy's Turn To Cry",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/bb8040578b3eb8b8dca94494952b6b36ea46818a",
    "artist_name": "Lesley Gore",
    "image_url": "https://i.scdn.co/image/fb928b4f9fc101091c7ffbde9290729306fa45e6",
    "artist_id": "08b2PA6eFyugsWAk41eQKZ",
    "year": 1963
  },
  {
    "song_id": "5CUlXjt8AAc9wulPCYUMw7",
    "name": "Blue On Blue",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/a39e4b3c20e2887981bfd08f0e4b52200de4c1b4",
    "artist_name": "Bobby Vinton",
    "image_url": "https://i.scdn.co/image/8adb7379b20be37a15291dc07c2faa776d4e1f7c",
    "artist_id": "6bOYtKnpLPQSfMpS2ilotK",
    "year": 1963
  },
  {
    "song_id": "1t7XM85zHaDGDjkYlxmPjv",
    "name": "Talk To Me",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/9da7a00e405f0ae12ce5545fe2f1be646c6ddc1d",
    "artist_name": "Sunny & The Sunglows",
    "image_url": "https://i.scdn.co/image/e62c91e6e9ea88636c56123af5766c3e8b9edb4e",
    "artist_id": "0s4lAMIrmAdSrf8TPfU13O",
    "year": 1963
  },
  {
    "song_id": "3wba431TDDOE9MR2Lzcmmu",
    "name": "Fingertips Pts. 1 & 2 - Live At The Regal Theater, Chicago/1962",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/f158c2dbdbb99910babb2b6299a16fdebf04dbd1",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/a4f6af092440a5f3dfa85feb329027bc9f21a149",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1963
  },
  {
    "song_id": "13f9I6uHwq4OunoeV84EyV",
    "name": "She's A Fool",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/414196163733ed3583ab4e880e5db0ea9f0b802f",
    "artist_name": "Lesley Gore",
    "image_url": "https://i.scdn.co/image/fb928b4f9fc101091c7ffbde9290729306fa45e6",
    "artist_id": "08b2PA6eFyugsWAk41eQKZ",
    "year": 1963
  },
  {
    "song_id": "2jQZPaGEGr3xwrOAbfkv66",
    "name": "Bossa Nova Baby - 2003 Sony Remaster",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/40948a49cfb0b56d673756c7052ba868e3670b29",
    "artist_name": "Elvis Presley",
    "image_url": "https://i.scdn.co/image/abc1c46cde6afb1a643dc24244f48c94969f7f6a",
    "artist_id": "43ZHCT0cAZBISjO8DG9PnE",
    "year": 1963
  },
  {
    "song_id": "6zYmF5WIfsj6wU8l1cOFYH",
    "name": "What Will My Mary Say",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/b39f1cf9ba96e6c30d1df1e756a72e2e1cc8f526",
    "artist_name": "Johnny Mathis",
    "image_url": "https://i.scdn.co/image/db7f8fd609f371c497abae3606b9859658302dee",
    "artist_id": "21LGsW7bziR4Ledx7WZ1Wf",
    "year": 1963
  },
  {
    "song_id": "6sbYrzvV5EDVWzBr6dvnMZ",
    "name": "Our Day Will Come",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/5d71e3ff11f692ff9a9251f2af480e8a69fb79a6",
    "artist_name": "Ruby And The Romantics",
    "image_url": "https://i.scdn.co/image/8bfbe875e0babbabc2d6dcf22ab0c94883359f98",
    "artist_id": "7y0ngZzIllkP8ZOqgTKQFc",
    "year": 1963
  },
  {
    "song_id": "4xQfTDewWaXXCMXlwugeGh",
    "name": "I Love You Because",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/536b5c8e4ea298f5697614083f841385241a1ce7",
    "artist_name": "Al Martino",
    "image_url": "https://i.scdn.co/image/ab5913c961688ab0c51258fc4ae0d27e85702f5c",
    "artist_id": "7egNqIGRldMzifHoh8pib6",
    "year": 1963
  },
  {
    "song_id": "2l4N0mVGsWIPBt3se9CTG5",
    "name": "Our Winter Love",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/194ec26047625c0b1ca8b7230aaa76103dc6db2d",
    "artist_name": "Bill Pursell",
    "image_url": "https://i.scdn.co/image/2bb51f676d193f1f25a0c166cd2ffb056bdd2095",
    "artist_id": "1kuS9H3D0hmnPHIa96VAS9",
    "year": 1963
  },
  {
    "song_id": "4l4M7fkNR9JiVhJlH4yfTa",
    "name": "Losing You - Single Version",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/2c811de9fac0fa3aab71d256aa17f8c31508f709",
    "artist_name": "Brenda Lee",
    "image_url": "https://i.scdn.co/image/a80427592f5340e899c99cf8e80c0ef80bbe63d7",
    "artist_id": "4cPHsZM98sKzmV26wlwD2W",
    "year": 1963
  },
  {
    "song_id": "2CXwbKwy2z1TPkCGATplDZ",
    "name": "Mockingbird",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/a423100c414cdb4e602acdf81a8df1e01df07f9c",
    "artist_name": "Inez Foxx And Charlie Foxx",
    "image_url": "https://i.scdn.co/image/cbb0b8c67d2ba2e766f62f69709827c0f455f115",
    "artist_id": "5otyegLiLv0Gvv0lmq6cuO",
    "year": 1963
  },
  {
    "song_id": "2Vo28PCuSInCfdZ2WMHVPL",
    "name": "Hello Muddah, Hello Faddah - A Letter From Camp Remastered",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/0514b1a03aaa11104cda692a77ef60b75194d7f1",
    "artist_name": "Allan Sherman",
    "image_url": "https://i.scdn.co/image/5478e7641b1ed31788d3dbbc8d3d9d450ba8fb58",
    "artist_id": "1enLi8njBlk1YlDg44MtJm",
    "year": 1963
  },
  {
    "song_id": "1aPdVWDcTG2Qn0ziCoHkaG",
    "name": "Wild Weekend",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/99c0148aba3245b1eb5ab864d9091724bd36fdd2",
    "artist_name": "Rockin' Rebels",
    "image_url": "https://i.scdn.co/image/0df3fd54812a4b8a156ba006e2934629ea9cc1c1",
    "artist_id": "1SEqE5T5lCofjXJw9YGCfD",
    "year": 1963
  },
  {
    "song_id": "4ElU2P6Q0TwTyNUjGQ7Fkw",
    "name": "Maria Elena",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/53d9219dc287b875e2ae1e9906415317216912f4",
    "artist_name": "Los Indios Tabajaras",
    "image_url": "https://i.scdn.co/image/ad925e62ebc1d35649553aded4c68889c7e9b9fd",
    "artist_id": "1TKifb1r4wnGbY5CYIwjGb",
    "year": 1963
  },
  {
    "song_id": "7L8LgOrURx16HotW1ROUk4",
    "name": "Still - Single Version",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/be70419dd120c5fbf2da1800d8b1d01756c70cb0",
    "artist_name": "Bill Anderson",
    "image_url": "https://i.scdn.co/image/93fc97a8aa7325e7bda8073ce61af66cb9cadf76",
    "artist_id": "0gWNSMYCSHF4wxIs1XTSDh",
    "year": 1963
  },
  {
    "song_id": "6AMKVd0lAHITQcm5JntRzv",
    "name": "You're The Reason I'm Living - 2004 Digital Remaster",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/10abd7ca6815acae29b7e46f964e71f89744e672",
    "artist_name": "Bobby Darin",
    "image_url": "https://i.scdn.co/image/842623af0df6ab86a317ab20c7a00c3cb12b7104",
    "artist_id": "0EodhzA6yW1bIdD5B4tcmJ",
    "year": 1963
  },
  {
    "song_id": "2raeINlZEF6cRCfB4CPulB",
    "name": "Pride And Joy",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/b8265591cf6a54e07b753222dbd6f883c1d53781",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/0eaa4b444a406c3c50aff8c7f6578ae0d0e35a3c",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1963
  },
  {
    "song_id": "2DsxvRs4dy4hqivGK2MR56",
    "name": "(Down At) Papa Joe's",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/32ecbfc6b357ce0defa137a199c9445929799d96",
    "artist_name": "Dixie Belles",
    "image_url": "https://i.scdn.co/image/af380d5946e2e3bc3e817ab58c2ff0edef5dbaf6",
    "artist_id": "4hJS67dIMP9WRprPjQTIaC",
    "year": 1963
  },
  {
    "song_id": "62PQXBFeAgS3iUseNRVPpF",
    "name": "It's All Right - Single Version",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/b928455eda4bdc05e15b1df0dda46acd685410a8",
    "artist_name": "The Impressions",
    "image_url": "https://i.scdn.co/image/dfe9aa4f88cada32d82ba028b28f0afc324dc807",
    "artist_id": "1b1N51wmSK0ckxFAMPSSHO",
    "year": 1963
  },
  {
    "song_id": "2SnzwEu9uB9VGCx8Faj97j",
    "name": "Baby Workout",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/7e08cc87c5d15670870b8d20c4acad331320f188",
    "artist_name": "Jackie Wilson",
    "image_url": "https://i.scdn.co/image/2ebfdc08c8a78cd9366389ee4541669b28bb6368",
    "artist_id": "4VnomLtKTm9Ahe1tZfmZju",
    "year": 1963
  },
  {
    "song_id": "3qU6yadfp65in9Jnl36qMr",
    "name": "South Street",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/ef46e7218bb5b25f35d23919cb01d2526ada8fca",
    "artist_name": "The Orlons",
    "image_url": "https://i.scdn.co/image/3fbcc64eadf17d789e9b875dd2b108303073755e",
    "artist_id": "7JSIM5U7TZym5M3Q1AFG80",
    "year": 1963
  },
  {
    "song_id": "5f4LwMkyw5ldc4TCrvDoy6",
    "name": "Painted, Tainted Rose - Digitally Remastered 91",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/5f4e6dca78b13c9ee1059bdb06f7cd57ae37c71e",
    "artist_name": "Al Martino",
    "image_url": "https://i.scdn.co/image/90e2229589288f581932ac49e0bdcfbbb7ba50d2",
    "artist_id": "7egNqIGRldMzifHoh8pib6",
    "year": 1963
  },
  {
    "song_id": "36cFHXj4VzAkYR2APscofi",
    "name": "Do The Bird",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/a2cbfc4034ea6be4ee246361410f417526c5537e",
    "artist_name": "Dee Dee Sharp",
    "image_url": "https://i.scdn.co/image/04ba3aaf11c7b28bab4cba76c2170521f4afbca9",
    "artist_id": "2NtGOVTuHBMDfR5PMNPBGT",
    "year": 1963
  },
  {
    "song_id": "1T0fsAAVDVChwLMaf6hTt7",
    "name": "Deep Purple",
    "popularity": 5,
    "preview_url": "https://p.scdn.co/mp3-preview/7ea7e1e1cfcc22103f0172d00c561cfc692e79d6",
    "artist_name": "Nino Tempo, April Stevens",
    "image_url": "https://i.scdn.co/image/45786e1853f8e64a1c72593e44fa27312139cf68",
    "artist_id": "6LGo5Ot9JxythltxKmazwD",
    "year": 1963
  },
  {
    "song_id": "54sxFj01a5BELIOKHKx5Tm",
    "name": "You Can't Sit Down",
    "popularity": 4,
    "preview_url": "https://p.scdn.co/mp3-preview/03189dd02d5a57e37daa7361c15b9a732580a8c5",
    "artist_name": "The Dovells",
    "image_url": "https://i.scdn.co/image/60b1e61f22541ac916d9cb0fac10959c946426a8",
    "artist_id": "6LaraO4gcDKWw0fwIOTodm",
    "year": 1963
  },
  {
    "song_id": "7xT7qENxPQRhKP5kKJNSDN",
    "name": "I Can't Stay Mad At You",
    "popularity": 3,
    "preview_url": "https://p.scdn.co/mp3-preview/f495ca8cdfc43d349abe941ec3fa50a42565d236",
    "artist_name": "Skeeter Davis",
    "image_url": "https://i.scdn.co/image/0fdb3307b26d1edf64a12336fe174b133c06c874",
    "artist_id": "5b2OzvLaL6nyxw5pbVbSdy",
    "year": 1963
  },
  {
    "song_id": "786o2JYufcSlMb6LnFYGJg",
    "name": "Come And Get These Memories - Single Version (Mono)",
    "popularity": 0,
    "preview_url": "https://p.scdn.co/mp3-preview/73902c24c000cb7339d6c0a06fff8393ea63816a",
    "artist_name": "Martha Reeves & The Vandellas",
    "image_url": "https://i.scdn.co/image/4dfd507c5006126f405e44b385cc4428fc52e6a3",
    "artist_id": "1Pe5hlKMCTULjosqZ6KanP",
    "year": 1963
  },
  {
    "song_id": "61Q9oJNd9hJQFhSDh6Qlap",
    "name": "House Of The Rising Sun",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/8f75ed56574c2550c6f3bed31d88e2aed1828be5",
    "artist_name": "The Animals",
    "image_url": "https://i.scdn.co/image/879ad5b721c58f9e6e5b145c89dac40b07e8b1b0",
    "artist_id": "3ICflSq6ZgYAIrm2CTkfVP",
    "year": 1964
  },
  {
    "song_id": "65jrjEhWfAvysKfnojk1i0",
    "name": "Under The Boardwalk",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/85f70b45b3eba4897af9152c18257e424b1bbeb3",
    "artist_name": "The Drifters",
    "image_url": "https://i.scdn.co/image/409ebeedd947168f004a05edfd6172d7814c5ce8",
    "artist_id": "1FqqOl9itIUpXr4jZPIVoT",
    "year": 1964
  },
  {
    "song_id": "5uES1C2NgkdrNHiCwf9jRr",
    "name": "Baby Love",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/1259009e04a74873d601e3f3fe5b587fd0ca6664",
    "artist_name": "The Supremes",
    "image_url": "https://i.scdn.co/image/1064f9d51283ec943f50d2c62393e4db4aa62609",
    "artist_id": "57bUPid8xztkieZfS7OlEV",
    "year": 1964
  },
  {
    "song_id": "39FS8GMCTRPAgHOyaNnUSl",
    "name": "Um, Um, Um, Um, Um, Um",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/d6c7df88bb1dec0710d53ecf80ec6bf143dd8b53",
    "artist_name": "Major Lance",
    "image_url": "https://i.scdn.co/image/8c3143e502634961ed644654508156c93c79b524",
    "artist_id": "7onp6ew3LGoQImTt1I78gt",
    "year": 1964
  },
  {
    "song_id": "7EjyzZcbLxW7PaaLua9Ksb",
    "name": "Surfin' Bird",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/c1a3f50a46ad126ec97442119bf912fc4017c2a5",
    "artist_name": "The Trashmen",
    "image_url": "https://i.scdn.co/image/42f1407919f09c53af5cac7290f5224cc098b6cc",
    "artist_id": "5QEA3sofVt5QckQA6QX2nN",
    "year": 1964
  },
  {
    "song_id": "18ltaDrNPGhcJkzzXvJoeM",
    "name": "Where Did Our Love Go",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/13b0d0ca55b3114d0de9e7b14fd84fc093dbabf1",
    "artist_name": "The Supremes",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "57bUPid8xztkieZfS7OlEV",
    "year": 1964
  },
  {
    "song_id": "7pVCDLSCUjVUYHp3ssV3uM",
    "name": "Do Wah Diddy Diddy - 2007 Remastered Version",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/76fd033c7c79fe7a01a5531366b99758c9e694c4",
    "artist_name": "Manfred Mann",
    "image_url": "https://i.scdn.co/image/a71fb2a9c54c4648d54a05bcad738e093267302d",
    "artist_id": "5670CyWrIOA7702gNjsGMZ",
    "year": 1964
  },
  {
    "song_id": "59uVL70mVMC70500N90m0c",
    "name": "I Get Around - 2001 - Remaster",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/1652a067a717558ba9048f823362b29f94736505",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/ae836f6d817f3f8432425e56be5f18c17c916b0e",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1964
  },
  {
    "song_id": "1A0j6qANKHgk7VZ6uvYO06",
    "name": "A World Without Love",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/c788f07b89b760df82a1cf72627cc4d7cf9cd6a5",
    "artist_name": "Peter And Gordon",
    "image_url": "https://i.scdn.co/image/481d917ede191a9a4cc74c6ea698724c5a04fdd9",
    "artist_id": "6lHC2EQMEMZiEmSfFloarn",
    "year": 1964
  },
  {
    "song_id": "6EGpxNYXyIbjLJKLvWTNTL",
    "name": "The Way You Do The Things You Do",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/19628a96d364c09ebb96db38bc225b90f2b9c302",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1964
  },
  {
    "song_id": "6kAROIu6CLVjulTqSPYmAP",
    "name": "Rag Doll",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/8e250c5a3400aa26a243709c0105ec1dd381c083",
    "artist_name": "Frankie Valli & The Four Seasons",
    "image_url": "https://i.scdn.co/image/f4430f809fc003be28cf0d0f4710646c66c93fad",
    "artist_id": "6mcrZQmgzFGRWf7C0SObou",
    "year": 1964
  },
  {
    "song_id": "252YuUdUaC5OojaBU0H1CP",
    "name": "Come A Little Bit Closer",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/a40ee464aaa554a2b1dac541219c7583d601ed6e",
    "artist_name": "Jay & The Americans",
    "image_url": "https://i.scdn.co/image/49857960f7195ec174601b009231aab07ce22790",
    "artist_id": "0DAqhikcMKLo2lPADVz2fs",
    "year": 1964
  },
  {
    "song_id": "7tAwfqQWDA1dLKiLvn8EgH",
    "name": "Dawn - Go Away",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/aa0d0d2275a889b49ec037f1b52ff208dfd26c3d",
    "artist_name": "Frankie Valli & The Four Seasons",
    "image_url": "https://i.scdn.co/image/f4430f809fc003be28cf0d0f4710646c66c93fad",
    "artist_id": "6mcrZQmgzFGRWf7C0SObou",
    "year": 1964
  },
  {
    "song_id": "1IGb9DyW0T3xrPM7iwG6Up",
    "name": "I'm So Proud - Single Version",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/9e24db9e45f8ce42c1d8c1a772c4a25dc3e4e8a4",
    "artist_name": "The Impressions",
    "image_url": "https://i.scdn.co/image/dec4ff841a1316b8005d6d195918f9618064c0f0",
    "artist_id": "1b1N51wmSK0ckxFAMPSSHO",
    "year": 1964
  },
  {
    "song_id": "48O2UZ18WRwodgj7nCzAC1",
    "name": "A Summer Song",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/0d16098d63e990c074bca652a63fe5a8d489b0bd",
    "artist_name": "Chad & Jeremy",
    "image_url": "https://i.scdn.co/image/2a895ebf63cfd126a4da5f001938a0902d14dbe2",
    "artist_id": "2UQwwYBLBvtpGHTMYzTgHi",
    "year": 1964
  },
  {
    "song_id": "3nJsiJCloBZafMl8KzxCF5",
    "name": "Chug-A-Lug - Single Version",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/0f2f76c2567cd185ec1145c4ea62eb40bbb3c9e9",
    "artist_name": "Roger Miller",
    "image_url": "https://i.scdn.co/image/d56e11947de9fd0a2c7aceb9e06371c94aea3706",
    "artist_id": "1RP2UpEaRzkF0Id3JigqD8",
    "year": 1964
  },
  {
    "song_id": "0kfbQsz5j5ifJmtIn9LCeH",
    "name": "Ronnie",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/a5040284226d932fa663ea332c640b6dfb277f11",
    "artist_name": "Frankie Valli & The Four Seasons",
    "image_url": "https://i.scdn.co/image/f4430f809fc003be28cf0d0f4710646c66c93fad",
    "artist_id": "6mcrZQmgzFGRWf7C0SObou",
    "year": 1964
  },
  {
    "song_id": "5b8Vr5l7C2mhKXBhZWuiHj",
    "name": "Dancing In The Street",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/32cc6e3f5050adfb04bad5f878e92a01b2a825e3",
    "artist_name": "Martha Reeves & The Vandellas",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "1Pe5hlKMCTULjosqZ6KanP",
    "year": 1964
  },
  {
    "song_id": "33kviElUoS7ScNB40GPD8t",
    "name": "Needles And Pins",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/7ea8bbf137ae0abe028f38fd123a83ce72b79be4",
    "artist_name": "The Searchers",
    "image_url": "https://i.scdn.co/image/d2d9e554460644bb5252776a49201de8b2f6d04d",
    "artist_id": "4QmkLL9JOqM9dusHS1Hghe",
    "year": 1964
  },
  {
    "song_id": "1tG3zCppK6MN013nd36B3E",
    "name": "Steal Away",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/3e117d628625f3b4a64a45445e08e7e96ca8b382",
    "artist_name": "Jimmy Hughes",
    "image_url": "https://i.scdn.co/image/c94f5d337c676f4f5ee5dbd5f6a82b1d244094bc",
    "artist_id": "0Ac3Vb2xO8UGlSHlll7RFE",
    "year": 1964
  },
  {
    "song_id": "5VVazEf2gvIzjF5NAUr8Q9",
    "name": "Java",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/1dd6e18900d87c5d11d6cb77f91229bb427b03bd",
    "artist_name": "Al Hirt",
    "image_url": "https://i.scdn.co/image/fa6f193eb117b7674d85d0dc89525e3c5e7d156a",
    "artist_id": "0TGsWvnbtExsbGUSWtXENE",
    "year": 1964
  },
  {
    "song_id": "5eQDErH4pyfb9ZhbyV53ly",
    "name": "My Guy",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/77d2568d0a0ba68cbee011117b9a17a1d9d59439",
    "artist_name": "Mary Wells",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "1cjZk1xXn3YCToNg3uJpA7",
    "year": 1964
  },
  {
    "song_id": "4DkbIdVwz0zU5SnOObUjxf",
    "name": "Little Honda",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/d927dbeeaf5a8d1645bd9b200a90a958174411e7",
    "artist_name": "The Hondells",
    "image_url": "https://i.scdn.co/image/b0721deaf68025bb2c94b3aa23b2a437065e024a",
    "artist_id": "0iiY1woiw7gL0gbd1Isc17",
    "year": 1964
  },
  {
    "song_id": "5YzzqZxDintLqLyBVXSuUM",
    "name": "Keep On Pushing - Single Version",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/be445c8830935d0aa05f263ad432fae8bcb45e65",
    "artist_name": "The Impressions",
    "image_url": "https://i.scdn.co/image/4a2b1f3308ad193203cd3ee22c41d510a9652762",
    "artist_id": "1b1N51wmSK0ckxFAMPSSHO",
    "year": 1964
  },
  {
    "song_id": "2BPEPkeifa5LoOg2Cq9bkx",
    "name": "Dead Man's Curve",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/d60b9274760bed5a8891083b5c0b0c214aab513d",
    "artist_name": "Jan & Dean",
    "image_url": "https://i.scdn.co/image/c81cb669ce637c18c113e7ae9fd81596f5feae25",
    "artist_id": "0pqGj6vO9YHsXuZmaJaP2Y",
    "year": 1964
  },
  {
    "song_id": "17YtDVU9bUluyihdOKWfxu",
    "name": "Suspicion",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/25e5f3eac40b90d74debabb3738d49d139b6f09c",
    "artist_name": "Terry Stafford",
    "image_url": "https://i.scdn.co/image/dff25b317168d5de3b72ee84a6a621b7e2746d27",
    "artist_id": "4kmoIUly5v8RsaKDhevAjT",
    "year": 1964
  },
  {
    "song_id": "3uSVoyGmhCVD9rFDFGipw0",
    "name": "Little Children",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/c88a56bd7a2e8def942022e441d632d68509fbb7",
    "artist_name": "Billy J. Kramer",
    "image_url": "https://i.scdn.co/image/425ddd02cb27450413a7d2685f976c270fea2cfd",
    "artist_id": "3qL4moFMePG5zA87tLcCOM",
    "year": 1964
  },
  {
    "song_id": "4F2w3xgfaVJdCSAqDtJULv",
    "name": "The Little Old Lady (From Pasadena)",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/d2a2b563921c8204373fc4c2370e870dd4f61eb3",
    "artist_name": "Jan & Dean",
    "image_url": "https://i.scdn.co/image/14072bd16bc48c94329b07a95217ae694eb79fe6",
    "artist_id": "0pqGj6vO9YHsXuZmaJaP2Y",
    "year": 1964
  },
  {
    "song_id": "0CEub0SDaTBT2jwcyANDh8",
    "name": "Anyone Who Had A Heart",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/038b5812fb2719a31d7bdc17e76749164f7db8ac",
    "artist_name": "Dionne Warwick",
    "image_url": "https://i.scdn.co/image/826750d63d751057de6904dab81a7a68a15de346",
    "artist_id": "2JSjCHK79gdaiPWdKiNUNp",
    "year": 1964
  },
  {
    "song_id": "1ObCOHQETeRcVNmHKq0Z6W",
    "name": "The Girl From Ipanema",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/1f2e24d7d1d7d4886a4ccacb089461948cb6f44b",
    "artist_name": "Stan Getz",
    "image_url": "https://i.scdn.co/image/aa5ab8ace3a209db2f9118f71d61f8e82875acda",
    "artist_id": "0FMucZsEnCxs5pqBjHjIc8",
    "year": 1964
  },
  {
    "song_id": "12GwRmhyjCrufwBpwdUWN7",
    "name": "You Really Got Me - Live at The Playhouse Theatre, September 1964",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/f3f9f0193850a2130d8815dfea9d9a3b5240c11e",
    "artist_name": "The Kinks",
    "image_url": "https://i.scdn.co/image/68b0d0bfc910c5be486cec533ffc3b8c0b835d2a",
    "artist_id": "1SQRv42e4PjEYfPhS0Tk9E",
    "year": 1964
  },
  {
    "song_id": "5GvVxxwieOVIlZaCU9bG0R",
    "name": "G.T.O.",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/e1c1e3799f25c3030f0c012c0050c24bd002b00a",
    "artist_name": "Ronny & The Daytonas",
    "image_url": "https://i.scdn.co/image/5d5ee0a695475974f9c9b8ac14035f3fd9f68421",
    "artist_id": "0RPvz5Xb4n2NKXD4GYH0yn",
    "year": 1964
  },
  {
    "song_id": "3PI6lPLeWLPy8KWSeFrAmE",
    "name": "What Kind Of Fool (Do You Think I Am)",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/381b161229c2c6ac5a0600fc49af2e8478a890f2",
    "artist_name": "The Tams",
    "image_url": "https://i.scdn.co/image/bee8fd7017cb4c2e10c7414b066817e28d208c4f",
    "artist_id": "4FSD5qtm70EBsOiLMN2eEe",
    "year": 1964
  },
  {
    "song_id": "4hVq510iJvsoPsVscFC2LJ",
    "name": "Baby I Need Your Loving - Single Version (Mono)",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/16fe2ccc7ab9b41f3daae2dd9f834ff781944838",
    "artist_name": "Four Tops",
    "image_url": "https://i.scdn.co/image/604ce455072dab4a08b7b3ad15ec8a85eca801f2",
    "artist_id": "7fIvjotigTGWqjIz6EP1i4",
    "year": 1964
  },
  {
    "song_id": "4T8FNA3rvsUdw0vFrXvk68",
    "name": "Bad to Me",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/552bca97dc97dd47aaffd0a81bb70e125ffb191b",
    "artist_name": "Billy J. Kramer",
    "image_url": "https://i.scdn.co/image/425ddd02cb27450413a7d2685f976c270fea2cfd",
    "artist_id": "3qL4moFMePG5zA87tLcCOM",
    "year": 1964
  },
  {
    "song_id": "0moP20UIIcThWKFipNAcZ6",
    "name": "Don't Let the Sun Catch You Crying",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/dae59c08208d283a2d79c87e580d55f751fcded9",
    "artist_name": "Gerry & The Pacemakers",
    "image_url": "https://i.scdn.co/image/b9fbb3e17d7be7886bb57180e5c21222a51bab7a",
    "artist_id": "3UmBeGyNwr4iDWi1vTxWi8",
    "year": 1964
  },
  {
    "song_id": "2nrmuzVjALLOLBfF2DN4Dm",
    "name": "High Heel Sneakers",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/4200ed6c52f7ddbad0a32570d5a3dbb5c35dd3cc",
    "artist_name": "Tommy Tucker",
    "image_url": "https://i.scdn.co/image/4cfe1d1d14d215b7b9f2531f6355ba9e00919f3f",
    "artist_id": "3Hu7yAx1kVULnXjSznYZRf",
    "year": 1964
  },
  {
    "song_id": "7IcJWRpvZXU0YOGEOphlfN",
    "name": "Memphis - Live",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/c3e0ca1ea62d6f4d134f2da3d91a7d8ef4bd8896",
    "artist_name": "Johnny Rivers",
    "image_url": "https://i.scdn.co/image/17f49bf99d1978fb5c5ac4e9925ab5b305d7c92d",
    "artist_id": "3TiISqKS6ESlMQ4WFfZJw2",
    "year": 1964
  },
  {
    "song_id": "7zR3W9i6KR65Y6C0e7Ysj5",
    "name": "Dang Me - Single Version",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/ae8c1c564c7dcf2093326ab8dba3f0b53521b058",
    "artist_name": "Roger Miller",
    "image_url": "https://i.scdn.co/image/61d779a66c816df37daa11af4768febec2a7ceec",
    "artist_id": "1RP2UpEaRzkF0Id3JigqD8",
    "year": 1964
  },
  {
    "song_id": "45WsonDrEYoMw4l9LM73aA",
    "name": "Cotton Candy",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/c2290fb87e0f222639eee898f25a9de34f28e0a9",
    "artist_name": "Al Hirt",
    "image_url": "https://i.scdn.co/image/555a247765a78fbb54bc20e6875bdc346204ea55",
    "artist_id": "0TGsWvnbtExsbGUSWtXENE",
    "year": 1964
  },
  {
    "song_id": "7HcM9JRSMGONIP3ra9XZFU",
    "name": "Hey Little Cobra - Single Version",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/cc4e9cc505e63424f7868e66b509de71d60a999d",
    "artist_name": "The Rip Chords",
    "image_url": "https://i.scdn.co/image/b1a343b11669a0aa4ef4818939e7e1c009290166",
    "artist_id": "0WnxcAN2X6IKcdaZpi5X1K",
    "year": 1964
  },
  {
    "song_id": "6WrSjCCJyjaRCncpQiN4R8",
    "name": "Oh, Pretty Woman",
    "popularity": 3,
    "preview_url": "https://p.scdn.co/mp3-preview/9d5a4aab07fe540624a8c69d8b02f14092800c1d",
    "artist_name": "Roy Orbison",
    "image_url": "https://i.scdn.co/image/b248a724ae9a91548bb2cbd7d6eda0b8ecaf431e",
    "artist_id": "0JDkhL4rjiPNEp92jAgJnS",
    "year": 1964
  },
  {
    "song_id": "1jFhnVoJkcB4lf9tT0rSZS",
    "name": "Unchained Melody",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/617d747f6ef1d7dbb0b6e8bcc96acfef7b080e88",
    "artist_name": "The Righteous Brothers",
    "image_url": "https://i.scdn.co/image/9421d651aaf247046ddfd18bd8ca681aea5f4c0d",
    "artist_id": "4b0WsB47XCa9F83BmwQ7WX",
    "year": 1965
  },
  {
    "song_id": "6TD08uig72h348vwgry0kq",
    "name": "All Day And All Of The Night",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/d218038fd6286f4bbd96465a260cbc2007634064",
    "artist_name": "The Kinks",
    "image_url": "https://i.scdn.co/image/a504b070b2f9672261c49618256ad5745b34ca82",
    "artist_id": "1SQRv42e4PjEYfPhS0Tk9E",
    "year": 1965
  },
  {
    "song_id": "2wz9T22pzkpKNuaWLBnY28",
    "name": "My Girl",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/2f79d76602d091412fcea676b05d90504cfc6c05",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1965
  },
  {
    "song_id": "2SWBfqj1FrS8t8z56G55rP",
    "name": "I Got You Babe - Single Version",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/b95f8a1274e13935c645cc42715fde92f541b2cd",
    "artist_name": "Sonny & Cher",
    "image_url": "https://i.scdn.co/image/a0501ffc7a0fe7b6d97f4c306b36d43a587f98f9",
    "artist_id": "71lGEtP9qYXDsSXjfexTqO",
    "year": 1965
  },
  {
    "song_id": "7FwkoTz8Ic8C5YaE9XXGIU",
    "name": "The Tracks Of My Tears",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/9c55d361ce232b0d004505d2ad827e22ea366116",
    "artist_name": "Smokey Robinson & The Miracles",
    "image_url": "https://i.scdn.co/image/9439eec3690144bf4a8e2892c795106e2a074c08",
    "artist_id": "6TKOZZDd5uV5KnyC5G4MUt",
    "year": 1965
  },
  {
    "song_id": "5Fwif6oyL2EjXAFTGL909U",
    "name": "It's Not Unusual",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/b50bbe58b2e0d1a6ee814c53098a3e308dcc1ab9",
    "artist_name": "Tom Jones",
    "image_url": "https://i.scdn.co/image/49edcc833f44dc373aa88a97ea593904c7774145",
    "artist_id": "1T0wRBO0CK0vK8ouUMqEl5",
    "year": 1965
  },
  {
    "song_id": "5GMw6X6BbCDGO9gApQYRSa",
    "name": "You've Lost That Lovin' Feelin'",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/2241df7d3fab25d49a30e10ba88c4eeca0262391",
    "artist_name": "The Righteous Brothers",
    "image_url": "https://i.scdn.co/image/9421d651aaf247046ddfd18bd8ca681aea5f4c0d",
    "artist_id": "4b0WsB47XCa9F83BmwQ7WX",
    "year": 1965
  },
  {
    "song_id": "2cPo7nyPXjwAPiwKkKAGm7",
    "name": "Heart Full Of Soul",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/c0a751440825d462f82c4ebd505106e4cbae0e81",
    "artist_name": "The Yardbirds",
    "image_url": "https://i.scdn.co/image/2e62c3710f5350f7d29d75c84a296ae010dc3bbf",
    "artist_id": "2lxX1ivRYp26soIavdG9bX",
    "year": 1965
  },
  {
    "song_id": "2HCaIYjkvWSZzaSKUoOh3d",
    "name": "Mr. Tambourine Man",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/4e7a8cb94be931cce079ce0402e2ba70e97d5eae",
    "artist_name": "The Byrds",
    "image_url": "https://i.scdn.co/image/9e5756a8a4ea58cd818a88e25d2fecdb1623b33a",
    "artist_id": "1PCZpxHJz7WAMF8EEq8bfc",
    "year": 1965
  },
  {
    "song_id": "5yfrc6CGOXxDWx3nGUDcNj",
    "name": "Stop! In The Name Of Love",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/bdd98beda99407ba0137cc54dfed4e829a9c86a7",
    "artist_name": "The Supremes",
    "image_url": "https://i.scdn.co/image/d573a4741ba83184bfc8a96f459d4fa4a57533ca",
    "artist_id": "57bUPid8xztkieZfS7OlEV",
    "year": 1965
  },
  {
    "song_id": "5td7WsBur5opModo1lB1X3",
    "name": "What The World Needs Now Is Love - 2002 - Remaster",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/0e0042e8b42495f320b861547efefa52256124c8",
    "artist_name": "Jackie DeShannon",
    "image_url": "https://i.scdn.co/image/72bcefc9fd32af86c70dfdd19f7935659f8ee314",
    "artist_id": "0iVed2Qu7dmL0pIYCj1Xw8",
    "year": 1965
  },
  {
    "song_id": "6Ln9F9lZNMgV5Ht87z9Bd0",
    "name": "I'll Never Find Another You",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/298105896014851ec80165031a5e5a653c6ceade",
    "artist_name": "The Seekers",
    "image_url": "https://i.scdn.co/image/46ef1ac331636a219d5e0fda85dd1ffed8690949",
    "artist_id": "7dedWAqd0IKTdCiqiadUrV",
    "year": 1965
  },
  {
    "song_id": "0i7O5MtSTXvR4BEY7stpjF",
    "name": "Hang On Sloopy - Single Version",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/c428b7f9f3f3fa521732e5e3d250f0a11d1660a0",
    "artist_name": "The McCoys",
    "image_url": "https://i.scdn.co/image/b062ed74feba5cb96345f384bc9d30d5b31743db",
    "artist_id": "6etIM3JbzGPxTdfNWWfsVH",
    "year": 1965
  },
  {
    "song_id": "4QiomftBnHkmi9a9VtAWpH",
    "name": "I Can't Help Myself ( Sugar Pie Honey Bunch)",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/f45e477d1e0f33901103e86ea150cc554a445b28",
    "artist_name": "Four Tops",
    "image_url": "https://i.scdn.co/image/b4c247791187b2c7265d8607addc87dd4b2eb435",
    "artist_id": "7fIvjotigTGWqjIz6EP1i4",
    "year": 1965
  },
  {
    "song_id": "6v4gV9s0pnwKbmF1yk5knO",
    "name": "Papa's Got A Brand New Bag",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/e7d9e8b8abe30c9053304750097224c29442f7a4",
    "artist_name": "James Brown",
    "image_url": "https://i.scdn.co/image/50e21b669ceef79744e89a4f0ecbf5129406fc64",
    "artist_id": "7GaxyUddsPok8BuhxN6OUW",
    "year": 1965
  },
  {
    "song_id": "0hgWgToJgb7O1tT12YWRCz",
    "name": "Downtown",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/9d8ce7e01909cf8045cdb77c8c90dec9ec7149b0",
    "artist_name": "Petula Clark",
    "image_url": "https://i.scdn.co/image/94156611cf0d13b33486dd11d3a08e99e96214ea",
    "artist_id": "6nKqt1nbSBEq3iUXD1Xgz8",
    "year": 1965
  },
  {
    "song_id": "5XPwkZ7vNxeidpNLAkwtFp",
    "name": "Hold Me, Thrill Me, Kiss Me",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/c215c045732809ad0231eccd5fc7d1eb87265100",
    "artist_name": "Mel Carter",
    "image_url": "https://i.scdn.co/image/e0c0d7e99bff7c9752d972ca8ec9922fe5193683",
    "artist_id": "3CgsVtt7tP8iduzkTCfel9",
    "year": 1965
  },
  {
    "song_id": "7LEt9cfytcYeP7NWy0XWfT",
    "name": "Ticket to Ride",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/1402cb50f87796e790b28bf3c506050f45908e86",
    "artist_name": "The Yesteryears",
    "image_url": "https://i.scdn.co/image/e32f5dc1f94c728fbdd6acd82de2ce73977de626",
    "artist_id": "7vfFFI4s6twLs3L5POUURF",
    "year": 1965
  },
  {
    "song_id": "2iO18Hn7raYltnUmpJXlun",
    "name": "Name Game",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/a926e37e72061a4492f583d955c4613318057f73",
    "artist_name": "Shirley Ellis",
    "image_url": "https://i.scdn.co/image/a8ff54b0446318af69319e9f5eb5a5165683b44c",
    "artist_id": "4GO3ufZJ7YsEnQ3PiML2Dg",
    "year": 1965
  },
  {
    "song_id": "5Vy8PKv7gS5WDR1EUuk0Bf",
    "name": "It Ain't Me Babe",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/ffc1b4b3f1e070729ceb269613bf2675b4350192",
    "artist_name": "The Turtles",
    "image_url": "https://i.scdn.co/image/4c72f0597c83294bcf57b255d5b61260a88b33a2",
    "artist_id": "2VIoWte1HPDbZ2WqHd2La7",
    "year": 1965
  },
  {
    "song_id": "3WnvXncCC2nERn5myOmKdE",
    "name": "Do You Believe In Magic",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/6c06f82197593cddb7acc51ab54a6c144307f7d0",
    "artist_name": "The Lovin' Spoonful",
    "image_url": "https://i.scdn.co/image/7e7a1a7622c148ff1e035becbb0c56a93cc6b083",
    "artist_id": "7CCn4PFRRRZF127jtCBAUe",
    "year": 1965
  },
  {
    "song_id": "5VB2fb8NTAkMJzqCIdB4vC",
    "name": "Count Me In",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/02543193a50911bb0208ab10ca42fe599ac12296",
    "artist_name": "Gary Lewis & The Playboys",
    "image_url": "https://i.scdn.co/image/d88db78f5b56051e3f7714b91d54dba605ac6880",
    "artist_id": "0XTxadWXVyfB8eTIYOjMYj",
    "year": 1965
  },
  {
    "song_id": "2UOjqy9WRwMmBm6M6h6Jrm",
    "name": "(I Can't Get No) Satisfaction - Mono Version",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/30274963e0eafa9e6e98a25b258a468ea78efa4c",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/f4ef5e437ae2daebf8c95f6bf9298f79abf79586",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1965
  },
  {
    "song_id": "25L7DvNU798tfl5SgKgnt1",
    "name": "We Gotta Get Out Of This Place (US Version)",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/f90b2c075bdd713e70ff169380431ff36ab63d87",
    "artist_name": "The Animals",
    "image_url": "https://i.scdn.co/image/879ad5b721c58f9e6e5b145c89dac40b07e8b1b0",
    "artist_id": "3ICflSq6ZgYAIrm2CTkfVP",
    "year": 1965
  },
  {
    "song_id": "1x95pWB3KeK3evKa1VrW6e",
    "name": "Eve Of Destruction",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/91a4c251950acc3f784abb8bf80f42c460e4673a",
    "artist_name": "Barry McGuire",
    "image_url": "https://i.scdn.co/image/7172a15a551a691cc27415b8a2992ffe5ecb29e6",
    "artist_id": "6xdkfNRMtBzeu7t4wyum2X",
    "year": 1965
  },
  {
    "song_id": "5GInJChhrHyXmwb4tQFJJG",
    "name": "Baby, I'm Yours",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/3bf23fbccff588f200923966db6f8b33edae770b",
    "artist_name": "Barbara Lewis",
    "image_url": "https://i.scdn.co/image/30bc96941f44abded1317f7f5bf5329ca888e832",
    "artist_id": "2UocIcNiHj5n4tj1CnBzRq",
    "year": 1965
  },
  {
    "song_id": "2yi1HjKqErtxTjK2v5TtjW",
    "name": "I Go To Pieces",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/6a879fa6d7b23e06d8991283fac3f15cdd406beb",
    "artist_name": "Peter And Gordon",
    "image_url": "https://i.scdn.co/image/481d917ede191a9a4cc74c6ea698724c5a04fdd9",
    "artist_id": "6lHC2EQMEMZiEmSfFloarn",
    "year": 1965
  },
  {
    "song_id": "1X452OCjrWCbwFdEAWQPMB",
    "name": "You've Got Your Troubles",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/adffaad22b6bbfd97556ffa5e6442e3162bd26b4",
    "artist_name": "The Fortunes",
    "image_url": "https://i.scdn.co/image/d8e273898ad2f12ef4056a0d3a525da4a290ad08",
    "artist_id": "4GpIeE34rBNFppvYsWle9c",
    "year": 1965
  },
  {
    "song_id": "7n9i7x2fXDmgCsGExP5CrW",
    "name": "This Diamond Ring",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/17d65b624cf25e5d7f481d45beea39976ef2bdf3",
    "artist_name": "Gary Lewis & The Playboys",
    "image_url": "https://i.scdn.co/image/d88db78f5b56051e3f7714b91d54dba605ac6880",
    "artist_id": "0XTxadWXVyfB8eTIYOjMYj",
    "year": 1965
  },
  {
    "song_id": "7JoPxqrnT3eq02AnflBSiN",
    "name": "I Want Candy - Single Version",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/db47abcc1a789930f51e45e105540aa294b3e807",
    "artist_name": "The Strangeloves",
    "image_url": "https://i.scdn.co/image/f3476cedc3d01d95d31e082f80dc5868544b0544",
    "artist_id": "4NMkJqM1SsQP3gk81cqk6K",
    "year": 1965
  },
  {
    "song_id": "0DIvlGtdeLS51h7gnud6Zw",
    "name": "Crying in the Chapel",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/4f75d24b7b5f8afcb5e4431998b088a63d0b8194",
    "artist_name": "Elvis Presley",
    "image_url": "https://i.scdn.co/image/53dbf319c9ac818edd89a5beadf81e39fb147099",
    "artist_id": "43ZHCT0cAZBISjO8DG9PnE",
    "year": 1965
  },
  {
    "song_id": "7aVyHzsDK1yJf6CBXNKWud",
    "name": "How Sweet It Is (To Be Loved By You)",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/b0dbaccaef1c2c7329d458858086fca34d7c2fed",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/f1acca2d639c3bdb7341a73d7ca552cca622f756",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1965
  },
  {
    "song_id": "36ckFm0oicmvX8bWEErIHd",
    "name": "You Were On My Mind",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/35a8fa2bf1f3b87ef884addcb69f6649fb4feb46",
    "artist_name": "We Five",
    "image_url": "https://i.scdn.co/image/af062c29655e43bf244c4a3f0051d0d1741813d5",
    "artist_id": "1XICntAIOLc3OPozkykv39",
    "year": 1965
  },
  {
    "song_id": "6T9ew09IfXx4fddKyvebCz",
    "name": "The Birds And The Bees",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/44815a64c0745b9c8f8f9b1e8122e10882ed77bd",
    "artist_name": "Jewel Akens",
    "image_url": "https://i.scdn.co/image/4d9b242950f3c59ff432930ee70124a2a54f197a",
    "artist_id": "1gVvCzqQy5epTJcFNwWJ37",
    "year": 1965
  },
  {
    "song_id": "7dQP6ESUYUMYUAnGkdyjXi",
    "name": "I Know a Place",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/40230a1321de24784becfa8a349ba19957a3c009",
    "artist_name": "Petula Clark",
    "image_url": "https://i.scdn.co/image/8eca09fcf8c72330c7b9945765f5e70affbc48ab",
    "artist_id": "6nKqt1nbSBEq3iUXD1Xgz8",
    "year": 1965
  },
  {
    "song_id": "40Fs8G1qws6fP8Zz0Tm1u3",
    "name": "She's About A Mover",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/ea93663e59c45ee1b1291995ad4d224195fb3fdf",
    "artist_name": "Sir Douglas Quintet",
    "image_url": "https://i.scdn.co/image/fd4326904e76950cc22476bda474c7c2a171ae81",
    "artist_id": "3A2UXqQgMDJ3YNvLPyX5NW",
    "year": 1965
  },
  {
    "song_id": "3VuPkjOfLnzGjRNdAgbl7B",
    "name": "It's The Same Old Song",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/f9e39912c9cc9063cbeb48302eef380cb558dcd8",
    "artist_name": "Four Tops",
    "image_url": "https://i.scdn.co/image/b75383ab89a498e72d9695e0f0470bd2cf91fba9",
    "artist_id": "7fIvjotigTGWqjIz6EP1i4",
    "year": 1965
  },
  {
    "song_id": "4pYa4YioWl14uEAMgXw7IS",
    "name": "The Game Of Love",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/e3b6c7ad49174baa426de7970f0493085196c1aa",
    "artist_name": "Wayne Fontana & The Mindbenders",
    "image_url": "https://i.scdn.co/image/ed2bb3eae8d6e5c2bd4c01eae6480a3dcb141088",
    "artist_id": "2VkASgJ3IjpMHG5aYXx4t2",
    "year": 1965
  },
  {
    "song_id": "1DM97JJjBJShdMuWI0B5L9",
    "name": "Baby Don't Go",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/a638339caf156cc3987dbc9e69712974b382bf1a",
    "artist_name": "Sonny & Cher",
    "image_url": "https://i.scdn.co/image/7bd79c015ddc2b4e97f834872acedc147ffaad1b",
    "artist_id": "71lGEtP9qYXDsSXjfexTqO",
    "year": 1965
  },
  {
    "song_id": "2fGe1krTxovW7xsgiaHkrN",
    "name": "True Love Ways",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/64a2275856076bb6d035aea45b0c0001a4941e28",
    "artist_name": "Peter And Gordon",
    "image_url": "https://i.scdn.co/image/481d917ede191a9a4cc74c6ea698724c5a04fdd9",
    "artist_id": "6lHC2EQMEMZiEmSfFloarn",
    "year": 1965
  },
  {
    "song_id": "5AlVKEK9sr5an4MCkGlI1N",
    "name": "Yes, I'm Ready",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/106eaae405a48a7c8c5f7279d8379fab15b28efc",
    "artist_name": "Barbara Mason",
    "image_url": "https://i.scdn.co/image/ed2361b4a8324faae661bdd8fc999705cd10bf65",
    "artist_id": "5PmZd7YOaUgQwhOja44fmZ",
    "year": 1965
  },
  {
    "song_id": "1uO8sRnU1CepB2yq626AxD",
    "name": "Love Potion No. 9",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/d11b3551cad08cc44b179ca779a7a816570d274b",
    "artist_name": "Mike Pender's Searchers",
    "image_url": "https://i.scdn.co/image/d404a2b90ae55b5b192a3b804e95590dbce431f4",
    "artist_id": "232h5Y6TyOOTFtxJ3SuGnx",
    "year": 1965
  },
  {
    "song_id": "0k5QrNdfkXQwMxTuCSAYXR",
    "name": "All I Really Want To Do",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/36e9cc5eeac0544461cedcbc1e474f68171f8970",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/28a07576b2db58dea48aa1690ee69d9892a2b18a",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1965
  },
  {
    "song_id": "2tUP1fuo8EdOVz3Bw9r7yu",
    "name": "Can't You Hear My Heartbeat",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/6931fc6319a275abccbb2e70b7ea646ddb4aeadd",
    "artist_name": "Herman's Hermits",
    "image_url": "https://i.scdn.co/image/3412d3b7734629bf6bfe2e9cfe111278d6467029",
    "artist_id": "48YxSlb23RAaCd4RyHcV9V",
    "year": 1965
  },
  {
    "song_id": "3NFScsiDPfCzKgQQUL0674",
    "name": "Ferry Cross The Mersey - 1997 Remastered Version",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/6817ac32d515e51df8aae533a0742586f4b7ba55",
    "artist_name": "Gerry & The Pacemakers",
    "image_url": "https://i.scdn.co/image/2cecb47681a50a33a8a623844dbfddef6d6588cf",
    "artist_id": "3UmBeGyNwr4iDWi1vTxWi8",
    "year": 1965
  },
  {
    "song_id": "4PzJ00OTUA2ekBGmxaMFg3",
    "name": "Ooo Baby Baby",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/db9f60a928bd3ac641a61fae8dbb1046a443f2b6",
    "artist_name": "Smokey Robinson & The Miracles",
    "image_url": "https://i.scdn.co/image/2e67591e7783ef56c158eb14fe19fcff67105f89",
    "artist_id": "6TKOZZDd5uV5KnyC5G4MUt",
    "year": 1965
  },
  {
    "song_id": "7LOmc0S7XIuMyHJy9F2sPP",
    "name": "California Girls",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/af7f51db417375eb48a1f5b6a7206874856772d0",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/57fd17f16b8cce28666ea303c488659b71fa887e",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1965
  },
  {
    "song_id": "13I6upRLWTDvw65uESvNhz",
    "name": "Just Once In My Life - Single Version",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/c48b14d65421ccc342a4cc377b8c99995fb1a3d4",
    "artist_name": "The Righteous Brothers",
    "image_url": "https://i.scdn.co/image/4d4729b4928c134a733248e0159e51e0db1668fe",
    "artist_id": "4b0WsB47XCa9F83BmwQ7WX",
    "year": 1965
  },
  {
    "song_id": "0au0w3wdUbGAd8Pgir1AoY",
    "name": "Red Roses For A Blue Lady",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/97fa6de97affaf7684eaf6d271294fa37192573a",
    "artist_name": "Bert Kaempfert And His Orchestra",
    "image_url": "https://i.scdn.co/image/f9e7f7a05a0060735fdbfb3e59ec4b0bf4a85ecd",
    "artist_id": "3e1LjDTAPp3DrngkQ8jMno",
    "year": 1965
  },
  {
    "song_id": "74a5tu4qTDIEDG8H8HNbO3",
    "name": "I'm Henry VIII, I Am",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/00bdfe6bb5f7d557beb65c604d239bbf339d71e8",
    "artist_name": "Herman's Hermits",
    "image_url": "https://i.scdn.co/image/58c0590436f687eb032cba747d655eea9a605e59",
    "artist_id": "48YxSlb23RAaCd4RyHcV9V",
    "year": 1965
  },
  {
    "song_id": "4PXJjX7PQGzCh4G5WR4Pli",
    "name": "Just A Little",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/2d95923b3873e19293f97c632318284a4fa96b3b",
    "artist_name": "The Beau Brummels",
    "image_url": "https://i.scdn.co/image/53a0f86a96ecd6edb5073689c681a7688d7436ac",
    "artist_id": "4DQj1q0unpi9KF8jO1Wqks",
    "year": 1965
  },
  {
    "song_id": "2SxKdqNnuKpaJE8g0zqzXr",
    "name": "Shake",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/870c3f72556bd81f22da97fcbe958e53d05e6b0a",
    "artist_name": "Sam Cooke",
    "image_url": "https://i.scdn.co/image/493c4d3720ac831748acaf569edce1a15b40900e",
    "artist_id": "6hnWRPzGGKiapVX1UCdEAC",
    "year": 1965
  },
  {
    "song_id": "1YhNCQ3XOdTCZgubfX8PgB",
    "name": "Don't Just Stand There - Original Hit Version",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/cdc00f52234aa15d8f503cea81e8add5164a154e",
    "artist_name": "Patty Duke",
    "image_url": "https://i.scdn.co/image/ddd6f3ebd8717af7e77b2bc03710c563b57fbb02",
    "artist_id": "6VcPriC5JFrXJdtZ7OQjc4",
    "year": 1965
  },
  {
    "song_id": "3fdhxP7nPfe7LdCyqhg15Y",
    "name": "The Last Time",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/e09c7e746f39820cbf83aebe00e190e0526feac0",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/b8fb2b11de8301b5dd7293e3bcc28effeb4a3185",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1965
  },
  {
    "song_id": "5rPn0SUFXMbUDcQRSX3NTP",
    "name": "What's New Pussycat?",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/d49943d2e1ce23a8a16ded6e5d8af36015cf4783",
    "artist_name": "Tom Jones",
    "image_url": "https://i.scdn.co/image/c1d1ff3e4ba36c099447d5cd8bfbcc6e60714176",
    "artist_id": "1T0wRBO0CK0vK8ouUMqEl5",
    "year": 1965
  },
  {
    "song_id": "1WGAn0768Mf7yyVpwZJryG",
    "name": "Hold What You've Got",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/4d056eb1a458dfe7785bfd3343843920d3507ca4",
    "artist_name": "Joe Tex",
    "image_url": "https://i.scdn.co/image/0b55c4e7cc7aeff7d951926334d4f3cd69ac601d",
    "artist_id": "5TbXjzD8tYgMD5JU2g2F8q",
    "year": 1965
  },
  {
    "song_id": "1TdmzgmdjrKQjJWk4jTGlO",
    "name": "Laugh At Me - Single Version",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/ec87e001fa031dcba8b832c2d73b5d09939b6012",
    "artist_name": "Sonny Bono",
    "image_url": "https://i.scdn.co/image/80a81baf004629ca1db34c646457e9bf3a8640cb",
    "artist_id": "4ui9JwXE5MHylLzpsoiEhr",
    "year": 1965
  },
  {
    "song_id": "2dq2J24oIF67Uvttut4dv7",
    "name": "Treat Her Right",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/c38ffdf5a853f4e468e756c414f335eb890b6635",
    "artist_name": "Roy Head & The Traits",
    "image_url": "https://i.scdn.co/image/863bd31848df1b717834030169c07b9e3060beaa",
    "artist_id": "30rTY7YT6tAoAUoGJXQBqQ",
    "year": 1965
  },
  {
    "song_id": "11y44ybqKO0N1aCqtemxKo",
    "name": "Help Me, Rhonda",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/64b203c61dc2dae13c18ffe4698d3e3acf29d59d",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/231c12b0f7b765f9d8ba5ab492c48da61e5c10e6",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1965
  },
  {
    "song_id": "7hyMuOvk2rpt1MomgeKtUk",
    "name": "Little Things - 1991 Digital Remaster",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/ca2756333e89df94a070094c4fe79943adf500ce",
    "artist_name": "Bobby Goldsboro",
    "image_url": "https://i.scdn.co/image/2357e23690cb713cbd50ef75b3eacaa0ec5e0b61",
    "artist_id": "5gPEo032lzARtzuVqJIm9o",
    "year": 1965
  },
  {
    "song_id": "17NJSwMZbGgkiE03ZPCb5b",
    "name": "I'll Be Doggone",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/913134d2dda38b9770e4112e5ef2c4670eedd930",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/02cd52018a20359cdd6e4b062f702407e6e1b044",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1965
  },
  {
    "song_id": "2WjW8DjQru6Ic4KvYDqLys",
    "name": "Back In My Arms Again",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/1471e2548c5b6f1fd276ae7058e50338cdb85d6c",
    "artist_name": "The Supremes",
    "image_url": "https://i.scdn.co/image/baa01225ef39c0fc2cb6094c105ad4a73c4977ad",
    "artist_id": "57bUPid8xztkieZfS7OlEV",
    "year": 1965
  },
  {
    "song_id": "201Z8gdTg4q2QXOgFw58jh",
    "name": "Baby The Rain Must Fall",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/627799fcffffddbee8bc7cc4988250a827fabfc1",
    "artist_name": "Glenn Yarbrough",
    "image_url": "https://i.scdn.co/image/e7d769a12732a3829ec015fbec449afdbe7847f4",
    "artist_id": "4GxVmha4ELBwUE4bPyChzS",
    "year": 1965
  },
  {
    "song_id": "2FG2dxcTU0v0h4M2WVkJJc",
    "name": "Catch Us If You Can (In the Style of Dave Clark Five) [Karaoke Version]",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/8354bdf095fe59e3876af3ce73de1d21f18c527c",
    "artist_name": "The Karaoke Channel",
    "image_url": "https://i.scdn.co/image/d2283d639d394d15b78c049f92571fcb5a6678e0",
    "artist_id": "5UloVKzUNJcjORzhhTWWiJ",
    "year": 1965
  },
  {
    "song_id": "54eajp10qPgjUCiD2Ds9jk",
    "name": "I'm A Fool",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/b820d812b08ba758aac6550f47702a549673bbfc",
    "artist_name": "Dino, Desi & Billy",
    "image_url": "https://i.scdn.co/image/bcf64bdf484105bf0b86c756793476dc04533fb4",
    "artist_id": "3lcfZLW21dTfmTLBmae1eR",
    "year": 1965
  },
  {
    "song_id": "634iAqzTNdKZqAj9v3vqwi",
    "name": "The Young Ones - 2005 Remastered Version",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/bcd7619ab0b139ae3098524afb6fb2b13645f70f",
    "artist_name": "Freddie & The Dreamers",
    "image_url": "https://i.scdn.co/image/28122ea7baa1252d53f4515e79f5afec8b6e9956",
    "artist_id": "1wCc2Wgga1H8C7u57pcqYk",
    "year": 1965
  },
  {
    "song_id": "0rq0x5y3VmNyAn4kHAJ8B5",
    "name": "Save Your Heart For Me - 1990 Digital Remaster",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/30569d87d7192c59961652a26112424520307735",
    "artist_name": "Gary Lewis & The Playboys",
    "image_url": "https://i.scdn.co/image/10238a91e4b107415ae13348f0f0474873c44e54",
    "artist_id": "0XTxadWXVyfB8eTIYOjMYj",
    "year": 1965
  },
  {
    "song_id": "0czBff3xbpcCglcpPgFnt1",
    "name": "The \"In\" Crowd - Single Version",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/c95f6dfdccaf396b47028d9d753f53f4dec0c02e",
    "artist_name": "Ramsey Lewis",
    "image_url": "https://i.scdn.co/image/6d17b99d5ef19a7b1883d13dc34d42ce85a90931",
    "artist_id": "7Kfrmups2Z3ncDQmNS5jRc",
    "year": 1965
  },
  {
    "song_id": "4IbwvZBWZ8dIKafyimuMci",
    "name": "Eight Days a Week",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/316813d87dcccc28d6886263d3fc69bbb8f4a345",
    "artist_name": "The Silver Beetles",
    "image_url": "https://i.scdn.co/image/dcc94938ae2384edbba6d756ffd4c950c65edd7f",
    "artist_id": "1MLXQOUzVRfjYAu5xyEntV",
    "year": 1965
  },
  {
    "song_id": "2HL6ZkvsVwh1FMN0XUdvN6",
    "name": "Down In The Boondocks",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/e1b05b12e4764f299e34ef18eb156846e776e625",
    "artist_name": "Billy Joe Royal",
    "image_url": "https://i.scdn.co/image/7c3d767f04842c19f39c499ce49c5f99e2515c94",
    "artist_id": "4WFTfNjxQYskBioYk39r9n",
    "year": 1965
  },
  {
    "song_id": "7ItUzE7YUqBiyzpwyDX06X",
    "name": "You Turn Me On (Turn On Song)",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/cd05a125b9550589ee59eaba989a622232b24e02",
    "artist_name": "Ian Whitcomb",
    "image_url": "https://i.scdn.co/image/a8b91d460a24ad2ccb9fe606d186b31e1aef9f55",
    "artist_id": "7GYdSOyUNp70L6kXepVAzJ",
    "year": 1965
  },
  {
    "song_id": "1BBECEQbfq6cWaJTNUVHnA",
    "name": "Wonderful World",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/c7742f8ef855eddcfecbd52901b2dba148f19818",
    "artist_name": "Herman's Hermits",
    "image_url": "https://i.scdn.co/image/58c0590436f687eb032cba747d655eea9a605e59",
    "artist_id": "48YxSlb23RAaCd4RyHcV9V",
    "year": 1965
  },
  {
    "song_id": "1XwmbycOt2JhoLnr1WdK4G",
    "name": "Silhouettes (Rerecorded Version)",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/e321385935d48b139fc23c6a7fec1c4d8ff6859f",
    "artist_name": "Herman's Hermits",
    "image_url": "https://i.scdn.co/image/16986bd2e3a17149f2ce1e92e49b7a9c89e21e6e",
    "artist_id": "48YxSlb23RAaCd4RyHcV9V",
    "year": 1965
  },
  {
    "song_id": "1WAlj3mrmeZAkJ0eaWkBjL",
    "name": "Tired of Waiting for You",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/ed02a5a52335ef1eab07192a700d9fb0e5afbb14",
    "artist_name": "The Kinx",
    "image_url": "https://i.scdn.co/image/7b9087a2077da4ecb9dcf08e5bd8f7e975933225",
    "artist_id": "3kczujcl520ZB7IJHkV2f3",
    "year": 1965
  },
  {
    "song_id": "45gPT19TumyrZAAeyYkzOM",
    "name": "Laurie (Strange Things Happen)",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/82d567b2ddc9267b63d1ab6ae7f2a5e8402d826b",
    "artist_name": "Dickey Lee",
    "image_url": "https://i.scdn.co/image/0dd1f42e2a91dcdd355a8e0093e1a5b69f3b5101",
    "artist_id": "34Ym7wEA9olyXIQ5eRAZjq",
    "year": 1965
  },
  {
    "song_id": "18KOfFqQuVlcXhu0JRpUfN",
    "name": "Shotgun",
    "popularity": 5,
    "preview_url": "https://p.scdn.co/mp3-preview/4e40c03760e1822d5d7cd38a9d092dbcc64a6772",
    "artist_name": "Jr. Walker & The All Stars",
    "image_url": "https://i.scdn.co/image/f8e6ba5ebc49d62964d74fe6b380d858f1b96933",
    "artist_id": "1rHh0AI30JhKrbzKIFjFNd",
    "year": 1965
  },
  {
    "song_id": "5WLtb3NeYi2H6IkSdhcZWs",
    "name": "The Race Is On",
    "popularity": 4,
    "preview_url": "https://p.scdn.co/mp3-preview/6eaf371dc9a4a4f05aefb0296766e05c9b452051",
    "artist_name": "Jack Jones",
    "image_url": "https://i.scdn.co/image/1747bef632fc68abadc64827fd9d04aace9b6108",
    "artist_id": "6eVCRmRG2HcfL4yx77A9WK",
    "year": 1965
  },
  {
    "song_id": "3jYVFKtWiQiMVFuZFsuHee",
    "name": "I Like It Like That - Karaoke Demonstration With Lead Vocal",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/5d8038b398894b24c1893f41486934d1d581bb8f",
    "artist_name": "Stingray Music (Karaoke)",
    "image_url": "https://i.scdn.co/image/d8f1ead6874ef7cfa00aa444b3f22d4dce13d0c4",
    "artist_id": "4vKhoHnEBN6Fq8tsMVpXaN",
    "year": 1965
  },
  {
    "song_id": "51FpzuGkRYXFgsE2zXt9av",
    "name": "When A Man Loves A Woman",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/e82a9760337f5871b7aeddad00489e0e26ca7ff2",
    "artist_name": "Percy Sledge",
    "image_url": "https://i.scdn.co/image/e4a6ce951fec0903f9e9b957dbb0305f88c976fa",
    "artist_id": "3rRmDmzPcAFwcUDvG5gBqO",
    "year": 1966
  },
  {
    "song_id": "2ULL3VZf4WwBKO4vjwT7Bg",
    "name": "Sloop John B - 1996 - Remaster",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/13af0e6d7d2daa2f7cd4a879b3174af7678ad741",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/cb5a561ee1e68fb56e747a84534c23767e61af1e",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1966
  },
  {
    "song_id": "30gUUK7Vwn9W5sWViA80bb",
    "name": "I Am a Rock",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/39a23c6c8d51ebf8d9ce7a01e9f60d0b16080776",
    "artist_name": "Simon & Garfunkel",
    "image_url": "https://i.scdn.co/image/314041c14c76e6d486a09d3cbc1d70850c3b5c31",
    "artist_id": "70cRZdQywnSFp9pnc2WTCE",
    "year": 1966
  },
  {
    "song_id": "3dgyDSKfzaNBtlZuUxc8IW",
    "name": "Last Train To Clarksville",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/74e271026c8c58488c30c415aeb0746eebe29545",
    "artist_name": "The Monkees",
    "image_url": "https://i.scdn.co/image/f8631084d851c3e21b1ee3275bc52c0e80768fc1",
    "artist_id": "320EPCSEezHt1rtbfwH6Ck",
    "year": 1966
  },
  {
    "song_id": "6aU6a9tdn2vHhnPGlboFZX",
    "name": "Good Vibrations",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/466c4f3de8a7048d558a2500e06f40269dbe74de",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/d26d1d9c3ff9becc7b1dd6fb0172fcf5e025a2cd",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1966
  },
  {
    "song_id": "0K3i1MJwU4v0Aq0VdYZnPg",
    "name": "Bus Stop - 1997 Remastered Version",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/30b104dc51dcfad7921c956a607140f21baa3f6b",
    "artist_name": "The Hollies",
    "image_url": "https://i.scdn.co/image/c1452f171f62bf90db175f5b193256ed87ccda12",
    "artist_id": "6waa8mKu91GjzD4NlONlNJ",
    "year": 1966
  },
  {
    "song_id": "4RGxUjaCmVtD30pIhtEi7w",
    "name": "Sunshine Superman",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/6d8d111512efe36e44c330b9a03d02ed2f860136",
    "artist_name": "Donovan",
    "image_url": "https://i.scdn.co/image/f400970ee4204fefdc621942dbb50b3a1b810e7a",
    "artist_id": "6vLlQYujOujIrm7zAKzEdG",
    "year": 1966
  },
  {
    "song_id": "4j2W1eM03mz5MVN5LvR7VH",
    "name": "These Boots Are Made For Walkin'",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/72995730393ac35553c0dc6746feabb507957c93",
    "artist_name": "Nancy Sinatra",
    "image_url": "https://i.scdn.co/image/42f1407919f09c53af5cac7290f5224cc098b6cc",
    "artist_id": "3IZrrNonYELubLPJmqOci2",
    "year": 1966
  },
  {
    "song_id": "4a01ClusxuZiloOK5UgLUn",
    "name": "You Keep Me Hangin' On",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/4c90c78a51d670663c460d4565189a6847b608e1",
    "artist_name": "The Supremes",
    "image_url": "https://i.scdn.co/image/2c2beec3c811a01ccb4c5ad3bd6e458cbe840442",
    "artist_id": "57bUPid8xztkieZfS7OlEV",
    "year": 1966
  },
  {
    "song_id": "6Um3XCAxl9YfFmAnGl93xc",
    "name": "I Fought The Law - Single Version",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/fd08b6a077c391bd47b1db5794747be67d723561",
    "artist_name": "The Bobby Fuller Four",
    "image_url": "https://i.scdn.co/image/71d1b2a57bd412167fe3acec99f37dbc5ed93ca1",
    "artist_id": "5XoM6cP8fQykllfuK5V5TR",
    "year": 1966
  },
  {
    "song_id": "2pw36KAhXcFPmYaPHmuMNd",
    "name": "Barbara Ann - 2001 - Remaster",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/0d70836df94f58660c5204bf828a06dd962eae5f",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/ae836f6d817f3f8432425e56be5f18c17c916b0e",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1966
  },
  {
    "song_id": "6H8WMHCov3QGaPLbpOMpcJ",
    "name": "I'm Your Puppet",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/fbe4fc56bff82c9bce5ad918d30325ac8d33a52e",
    "artist_name": "James & Bobby Purify",
    "image_url": "https://i.scdn.co/image/b918774ba1f3c5cc5296eca1251a88b4ea17faec",
    "artist_id": "1qO2mRQM4zbksO586oM0W7",
    "year": 1966
  },
  {
    "song_id": "0tmaLDb6R98E2NgtBbUC4L",
    "name": "Daydream",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/a38b17a30998faee0774b01e80db1a294478e19c",
    "artist_name": "The Lovin' Spoonful",
    "image_url": "https://i.scdn.co/image/7e7a1a7622c148ff1e035becbb0c56a93cc6b083",
    "artist_id": "7CCn4PFRRRZF127jtCBAUe",
    "year": 1966
  },
  {
    "song_id": "3r2yyf4jeSTBmioUJqJM7x",
    "name": "You Can't Hurry Love",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/0c4ae4eac5334d09e882bcd9ead8b5515f7757c5",
    "artist_name": "The Supremes",
    "image_url": "https://i.scdn.co/image/41bab349692c69239298d886d38220748859697f",
    "artist_id": "57bUPid8xztkieZfS7OlEV",
    "year": 1966
  },
  {
    "song_id": "4xPn6dfF79vt4E65T8Ttph",
    "name": "We Can Work It Out",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/31e5008d3ff243f7f7944e8001a729289ec811b9",
    "artist_name": "The Yesteryears",
    "image_url": "https://i.scdn.co/image/e32f5dc1f94c728fbdd6acd82de2ce73977de626",
    "artist_id": "7vfFFI4s6twLs3L5POUURF",
    "year": 1966
  },
  {
    "song_id": "1CQHMHRCDs1qioeseVTbaR",
    "name": "Cool Jerk",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/36e79d22fb1c84e72ce07fabe23bf3909272c658",
    "artist_name": "The Capitols",
    "image_url": "https://i.scdn.co/image/4f2178fb17adca2237e0f54d9321420410131e9b",
    "artist_id": "2AYmrUFn1ATSfFSx3B4tvb",
    "year": 1966
  },
  {
    "song_id": "2oIqAImwgiiIhuQYFkzvS4",
    "name": "634-5789 - Remastered Single Version",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/738526ee5d1b6fad6af6009f58c1598cb172f44e",
    "artist_name": "Wilson Pickett",
    "image_url": "https://i.scdn.co/image/eebe31ba91e47d2335502923b7ef1bea259fa4f2",
    "artist_id": "0N5PyKJzS3M1XNlaCL7bbE",
    "year": 1966
  },
  {
    "song_id": "7yso4IDOXI6yLI3n3VnQP4",
    "name": "Summer in the City",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/77465f70cbc31ad9cd95c683ce83558637831e40",
    "artist_name": "The Lovin' Spoonful",
    "image_url": "https://i.scdn.co/image/0864be6c01ded3b106f2ae3f84a25fcd25d6535f",
    "artist_id": "7CCn4PFRRRZF127jtCBAUe",
    "year": 1966
  },
  {
    "song_id": "0XnhQGfqfi5CnZXcONJuI5",
    "name": "Good Lovin' - Single Version",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/bc7237892087e20f65beea4c35c65ccb0272c1fd",
    "artist_name": "The Young Rascals",
    "image_url": "https://i.scdn.co/image/ae10f3f1c1773c8362947bb87455f48682e821be",
    "artist_id": "5X3TuTi9OIsJXMGxPwTKM2",
    "year": 1966
  },
  {
    "song_id": "23vvgYCDvRsD5LS2si61C4",
    "name": "Beauty Is Only Skin Deep",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/a52e306bd01b2a6d5049b18a24d5c505e95f60ee",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1966
  },
  {
    "song_id": "5GR1Jj5ahZtoR6WqyM5LP4",
    "name": "Working in the Coal Mine",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/912009c54d6a66d05770f3ab1f9b651845dfd192",
    "artist_name": "Lee Dorsey",
    "image_url": "https://i.scdn.co/image/6b192f7f714d4080ae0068da02595f8412c68afe",
    "artist_id": "0En4EEcDMJ5kaUCf1aZ9js",
    "year": 1966
  },
  {
    "song_id": "4HYhDDv5p3jikIrZV1HxFI",
    "name": "Five O'Clock World",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/655fcf349dc0b84917b65ee7d724f99ebc701944",
    "artist_name": "The Vogues",
    "image_url": "https://i.scdn.co/image/fd2592a58ec0ae18adee4daa46908abf0aa4dac7",
    "artist_id": "0Ma8KxtvoIDSU5fHtuTS9F",
    "year": 1966
  },
  {
    "song_id": "3xYMN0fbQkyJfofjsufO7b",
    "name": "Shapes Of Things",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/bea6ba701cd98655f780021239c436011293856c",
    "artist_name": "The Yardbirds",
    "image_url": "https://i.scdn.co/image/196f1a1477a4c376f0280d43de25da78800b1289",
    "artist_id": "2lxX1ivRYp26soIavdG9bX",
    "year": 1966
  },
  {
    "song_id": "3eNKEytzDbpJ3gehbeAAyK",
    "name": "Ain't Too Proud To Beg",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/a55a8883f47f9301c8a6eb0bbf0b8573878670e3",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1966
  },
  {
    "song_id": "26Y0BO1IsZVctCrGRIcip1",
    "name": "Did You Ever Have To Make Up Your Mind?",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/cd8adca7fba54ae6eae4af5773ad7c4bc1d7df85",
    "artist_name": "The Lovin' Spoonful",
    "image_url": "https://i.scdn.co/image/7e7a1a7622c148ff1e035becbb0c56a93cc6b083",
    "artist_id": "7CCn4PFRRRZF127jtCBAUe",
    "year": 1966
  },
  {
    "song_id": "0dkGA66q2JHqHMYzFa8WbZ",
    "name": "Nowhere Man",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/df05e9242b48fc01e05630d2597b1efc26385023",
    "artist_name": "The Beatles Tribute Project",
    "image_url": "https://i.scdn.co/image/d94ee63d6c211f38c5a0ef2c5aadfa8f1514c9a9",
    "artist_id": "7EzNTMzMN70jRxBWbxeshd",
    "year": 1966
  },
  {
    "song_id": "6DuLN8tFW0DkH9wsbTHZsX",
    "name": "Red Rubber Ball",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/db0f3809c7d679e86d7194249073c466eeb5a249",
    "artist_name": "The Cyrkle",
    "image_url": "https://i.scdn.co/image/c17786a24d4b997d71bfaac15f93863773122892",
    "artist_id": "4qnb4cn5aykCACoyuCirGg",
    "year": 1966
  },
  {
    "song_id": "4wfK7KMFTFMNDDfKDe1aWi",
    "name": "Walk Away Renee",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/f0404e051a7bce05789732155d3d197839071bed",
    "artist_name": "The Left Banke",
    "image_url": "https://i.scdn.co/image/e220e6fa8c6dcc09403e0d433b8585a353362de1",
    "artist_id": "382aCtCMEd2Rg2DmcU9xw9",
    "year": 1966
  },
  {
    "song_id": "11sGMdEqalkocgfOKFfQdI",
    "name": "Dirty Water",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/6cbc9cd02125d3b11728c5a1d9b7ab1501b95950",
    "artist_name": "The Standells",
    "image_url": "https://i.scdn.co/image/7f7e613c460b08ed1082e690c385c4dc3f577229",
    "artist_id": "4BM6qze88exQPVILQpcYpj",
    "year": 1966
  },
  {
    "song_id": "6LLK7hZgXFYi5Jk4oRQvAl",
    "name": "Bang Bang (My Baby Shot Me Down) [1990 - Remastered]",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/7424e1d75e1af2dc9e0713970bafd164cd22c17d",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/cb334b906d93238ac20424071bd11a3b0b8184e0",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1966
  },
  {
    "song_id": "63eURrp8fgcxe5FwkWfoFO",
    "name": "If I Were a Carpenter",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/98789e0a7f45ae1ef22dbb4ee2fd043f78e16c9a",
    "artist_name": "Bobby Darin",
    "image_url": "https://i.scdn.co/image/f75fc3458d9a11145d350ee73d5faf3c8cedec89",
    "artist_id": "0EodhzA6yW1bIdD5B4tcmJ",
    "year": 1966
  },
  {
    "song_id": "6o2FF4ArSHzLaLNxWxUndK",
    "name": "You Baby",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/f62c7cbad4ddc82937e3bbbceea8f0db93c0d24e",
    "artist_name": "The Turtles",
    "image_url": "https://i.scdn.co/image/4c72f0597c83294bcf57b255d5b61260a88b33a2",
    "artist_id": "2VIoWte1HPDbZ2WqHd2La7",
    "year": 1966
  },
  {
    "song_id": "7EpTJ0PuN49TO6Lko6c9zR",
    "name": "What Becomes Of The Brokenhearted",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/914447bb5642759a0a33e5936b6cd688085c5c4a",
    "artist_name": "Jimmy Ruffin",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "0hF0PwB04hnXfYMiZWfJzy",
    "year": 1966
  },
  {
    "song_id": "73SPjNZV2wVy8imEmckGYw",
    "name": "Paint It, Black - (Original Single Mono Version)",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/daee81f6abe32da54b17f64d8c75b7eeb4a92a5a",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/24af3101b887b0d9dbe33ebed073c742985cc3a6",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1966
  },
  {
    "song_id": "2kt0TS6sYxz25uze84l5Oq",
    "name": "The Poor Side Of Town",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/730df15f6a5be222e5103cc1adfab5bacff41f49",
    "artist_name": "Johnny Rivers",
    "image_url": "https://i.scdn.co/image/17f49bf99d1978fb5c5ac4e9925ab5b305d7c92d",
    "artist_id": "3TiISqKS6ESlMQ4WFfZJw2",
    "year": 1966
  },
  {
    "song_id": "226EXK5tPVsqaZ2y9RACR0",
    "name": "Elusive Butterfly",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/a80187014c15762fa9baefacb6fd1b4bee23141c",
    "artist_name": "Bob Lind",
    "image_url": "https://i.scdn.co/image/f1bd27c899fe5d6b2ea0a3bb1fa9ef5a375905a5",
    "artist_id": "5W6gs1V54kOPUgBxBLkY1z",
    "year": 1966
  },
  {
    "song_id": "5WNcnqDpO5asMaDIthIcu1",
    "name": "Gloria",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/3bee6648c4309f68ae064453a02e7f210d377723",
    "artist_name": "The Shadows Of Knight",
    "image_url": "https://i.scdn.co/image/a229d88059c3069fb66fa8ed5ccbbc7aad8ccc60",
    "artist_id": "0NRN3tnDVDspHMCfyu2ZwT",
    "year": 1966
  },
  {
    "song_id": "5pIMHKpaQJYhShMJ0mOdn5",
    "name": "Land Of 1000 Dances",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/f503e129da2fe645233a32f2d9d5b83439c108fb",
    "artist_name": "Wilson Pickett",
    "image_url": "https://i.scdn.co/image/d8a4fea696cdf9ddd07b692e92795a5d387f3df1",
    "artist_id": "0N5PyKJzS3M1XNlaCL7bbE",
    "year": 1966
  },
  {
    "song_id": "6zPQghjSi2EiBX75FJDjBY",
    "name": "Hanky Panky - Single Version",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/c61ea2d500a86d2bc791cd2dfc4f884422b18abb",
    "artist_name": "Tommy James",
    "image_url": "https://i.scdn.co/image/3bc2062a865e215b1d87caf80c938f5b96424db1",
    "artist_id": "7eRORqxEbt7ftbaaWP0k5v",
    "year": 1966
  },
  {
    "song_id": "14hKozniqwV7Fad3ZI9H2C",
    "name": "Li'l Red Riding Hood",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/ac24e8259586d25294805001a21a955a3ecd6c6a",
    "artist_name": "Sam The Sham & The Pharaohs",
    "image_url": "https://i.scdn.co/image/636a66f7e4174001a077daf03550c20bf2470a3b",
    "artist_id": "05sRO4JdAtJGyZQOTb6kSL",
    "year": 1966
  },
  {
    "song_id": "1nneC7m6oPIo1JuCXPZyHa",
    "name": "Lady Godiva",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/e1dcbf0e37e6c40154a9480bb9c05a37eee2de13",
    "artist_name": "Peter And Gordon",
    "image_url": "https://i.scdn.co/image/481d917ede191a9a4cc74c6ea698724c5a04fdd9",
    "artist_id": "6lHC2EQMEMZiEmSfFloarn",
    "year": 1966
  },
  {
    "song_id": "64HTTmNuE6Pf6dSbM6nlcs",
    "name": "Love Is A Hurtin' Thing - 2006 Digital Remaster",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/8ff0df0c0f17167b800809f675d590694d7dcb42",
    "artist_name": "Lou Rawls",
    "image_url": "https://i.scdn.co/image/78ae48d3496e4a6280cbf43285f72f4d59348199",
    "artist_id": "1zJBFCev9UwOMcrZsLi2od",
    "year": 1966
  },
  {
    "song_id": "4H9WC6c7RdLfpvC2xV4mY4",
    "name": "Guantanamera",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/98579bb5e8ea8ed8234a9c7ff2f2bad9cd2686f1",
    "artist_name": "The Sandpipers",
    "image_url": "https://i.scdn.co/image/715ecc87c4eb3fbd8354942debdbfbd2c61d31f3",
    "artist_id": "3iBuBivHItWMpdiVmLT0uB",
    "year": 1966
  },
  {
    "song_id": "61Qh466Q63M4uckeYrYeal",
    "name": "She's Just My Style",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/8d2180a91ef22ee633acd752ac9b5ac02011f333",
    "artist_name": "Gary Lewis & The Playboys",
    "image_url": "https://i.scdn.co/image/d88db78f5b56051e3f7714b91d54dba605ac6880",
    "artist_id": "0XTxadWXVyfB8eTIYOjMYj",
    "year": 1966
  },
  {
    "song_id": "1qg0e1Tol7d9HGH8eu7evB",
    "name": "Somewhere, My Love (Lara's Theme from \"Doctor Zhivago\")",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/256cb4f79182b2a6132c89e1e560bd095cd550f2",
    "artist_name": "The Ray Conniff Singers",
    "image_url": "https://i.scdn.co/image/c89db4a4822c017335ed2f5d3944f0f84b2a1ce8",
    "artist_id": "6WtWmLPWn1imbcisSfmBvy",
    "year": 1966
  },
  {
    "song_id": "7zd7QV2lCDGGmwOxMiULVY",
    "name": "Woman",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/ed3a35f7d427fd05d817b0c74bd822931bb5f687",
    "artist_name": "Peter And Gordon",
    "image_url": "https://i.scdn.co/image/481d917ede191a9a4cc74c6ea698724c5a04fdd9",
    "artist_id": "6lHC2EQMEMZiEmSfFloarn",
    "year": 1966
  },
  {
    "song_id": "2j1GzI1DwpEado5Rjw0wxk",
    "name": "My Love",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/b6558a7448f9de2e6f299e26faa61e6bd53a002c",
    "artist_name": "Petula Clark",
    "image_url": "https://i.scdn.co/image/da908b6e552d0a8d8fca4258c517884d7a84835c",
    "artist_id": "6nKqt1nbSBEq3iUXD1Xgz8",
    "year": 1966
  },
  {
    "song_id": "0q8nQ0H4ad4KYcZdrTZPux",
    "name": "You Don't Have To Say You Love Me",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/2ff52ab174c541c66d2e044275f7c5f017652481",
    "artist_name": "Dusty Springfield",
    "image_url": "https://i.scdn.co/image/c2f31ad060969a7c0a984548dbf2c08012fb376b",
    "artist_id": "5zaXYwewAXedKNCff45U5l",
    "year": 1966
  },
  {
    "song_id": "55A0PyG5oDUiJU4EKrfd6Q",
    "name": "Homeward Bound",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/83dad96054f56a027aa53400f3524d0db129d34e",
    "artist_name": "Simon & Garfunkel",
    "image_url": "https://i.scdn.co/image/0406e1457d13fbd1a843cf91e648403d6eabb71d",
    "artist_id": "70cRZdQywnSFp9pnc2WTCE",
    "year": 1966
  },
  {
    "song_id": "5KXsnREBcJd4brJuDH5crr",
    "name": "Uptight (Everything's Alright) - Single Version",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/e9e34fe61547dcde9cb79456e6d253b13f5526d0",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/fd21705e01ea1232643242ec0306a79e3e9fbe85",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1966
  },
  {
    "song_id": "3LJUiTwAFRy3t8RxxTZXot",
    "name": "Baby Scratch My Back - Single Version",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/dff0f31764410d60e00b53330ea71fbba4a697b8",
    "artist_name": "Slim Harpo",
    "image_url": "https://i.scdn.co/image/02cf5116a4ddc0d85777f46f6df6a262657f90b0",
    "artist_id": "36hwOoNPgnsKnhoMBYpJrJ",
    "year": 1966
  },
  {
    "song_id": "7ggOcfcCbyX8IWIWPEdT1x",
    "name": "Barefootin'",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/8f39e0d96ed8fad23ff3ece77f8260d103706c28",
    "artist_name": "Robert Parker",
    "image_url": "https://i.scdn.co/image/019b3e309e6b7be9012f67710bed7a98dbe3aa8f",
    "artist_id": "7FlUCXHUxNEOk2exJUiqp1",
    "year": 1966
  },
  {
    "song_id": "7s4G0mWaZzSbnTtir9vXHy",
    "name": "California Dreamin'",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/bef9bc62e0f6a09343d11347716e21d14ac4b68a",
    "artist_name": "The Mamas & The Papas",
    "image_url": "https://i.scdn.co/image/94ac19cc4f84fcd0bc1a4a2ee8ab8481190e31da",
    "artist_id": "1bs7HoMkSyQwcobCpE9KpN",
    "year": 1966
  },
  {
    "song_id": "6huU7kH11qu59yPeKwERsL",
    "name": "Sweet Pea",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/d1494ff111f4761c1087fc339244939921d70c47",
    "artist_name": "Tommy Roe",
    "image_url": "https://i.scdn.co/image/099f5721318ba6c4836a718872f0125ce4795a50",
    "artist_id": "7t4XHvWfj0XtEB8SNFeALw",
    "year": 1966
  },
  {
    "song_id": "1NjcsA6SyWXqGKF02EzAmJ",
    "name": "Don't Mess With Bill",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/4646cb57a92e3aed5011e9da7a769037a7a076a9",
    "artist_name": "The Marvelettes",
    "image_url": "https://i.scdn.co/image/64aac7e707ffdc8517aecc89e75b7bdd20c7fa02",
    "artist_id": "0MponVSpW81oLvJZ53vYZH",
    "year": 1966
  },
  {
    "song_id": "4dp3AbYKyxOJNXC8Wq4PpJ",
    "name": "Oh How Happy",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/3f36770c0cd47e9e204a71ed30682c2d55fc1a9b",
    "artist_name": "Shades Of Blue",
    "image_url": "https://i.scdn.co/image/365b4c5353a3c1463210e720af329f645a12d714",
    "artist_id": "3mrPUMTaGsRrW8JA66x7oi",
    "year": 1966
  },
  {
    "song_id": "6bwXe6aABtWzLmFIHixx1r",
    "name": "(You're My) Soul And Inspiration",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/84c59ad5e2bf1264559d209d7ef29c06a9005299",
    "artist_name": "The Righteous Brothers",
    "image_url": "https://i.scdn.co/image/a8d99395a6e5d6c4841d46b2becfaa0a83dc4a52",
    "artist_id": "4b0WsB47XCa9F83BmwQ7WX",
    "year": 1966
  },
  {
    "song_id": "5evs3QKlY56G3OY7cRzJmI",
    "name": "Ballad of the Green Berets (Re-recorded)",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/9e36c135c7e721a057f3c2d55f232bb691d33e15",
    "artist_name": "Sgt. Barry Sadler",
    "image_url": "https://i.scdn.co/image/ab90253af2d88c235ee9f0207a32fc7f208d9375",
    "artist_id": "5r0O4LLn9wHEuYxKmGzUB5",
    "year": 1966
  },
  {
    "song_id": "5DkgAX62bM7gwYpOGtOGfK",
    "name": "No Matter What Shape (Your Stomach's In) - Original Hit Version",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/5270b5129a904e72f8e9255938cd420dd282587f",
    "artist_name": "The T-Bones",
    "image_url": "https://i.scdn.co/image/ddd6f3ebd8717af7e77b2bc03710c563b57fbb02",
    "artist_id": "4wyE7hg8TRoAcqOiwPUvK1",
    "year": 1966
  },
  {
    "song_id": "3BXAg0OZ7nAu6AVY0cvdL7",
    "name": "Almost Persuaded",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/f8db7d5ded681104230db1ea6c752cbd893416a1",
    "artist_name": "David Houston",
    "image_url": "https://i.scdn.co/image/1543e925dba17302605bc2deb036808614fa3c5a",
    "artist_id": "7tIs7rxqGuBvWMhIIigunf",
    "year": 1966
  },
  {
    "song_id": "3lp6cU73sVRZ1TULR51YFv",
    "name": "Reach Out, I'll Be There",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/333e3a347da5235052ee4e808fb56a1937a10dc4",
    "artist_name": "Four Tops",
    "image_url": "https://i.scdn.co/image/64aac7e707ffdc8517aecc89e75b7bdd20c7fa02",
    "artist_id": "7fIvjotigTGWqjIz6EP1i4",
    "year": 1966
  },
  {
    "song_id": "51LYfjuH1xcAeXRL6DlcuO",
    "name": "Message To Michael",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/79163f54a8ce2ea7a81d423def96cfebf2dcb70c",
    "artist_name": "Dionne Warwick",
    "image_url": "https://i.scdn.co/image/311abd93522be91c7a234e9cb0593bd4c334c418",
    "artist_id": "2JSjCHK79gdaiPWdKiNUNp",
    "year": 1966
  },
  {
    "song_id": "2xzkdWhWEm9r4laA0mbxtA",
    "name": "A Groovy Kind Of Love",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/bfcce43a024871723ac96ee1bab5d38170a30074",
    "artist_name": "Mindbenders",
    "image_url": "https://i.scdn.co/image/f98e5a68b103e3b8edbd805a4111837039fdc5e8",
    "artist_id": "2RIc5FCuPOhR7D1alVCOUz",
    "year": 1966
  },
  {
    "song_id": "0Uu7V0elZArHAzMJTFVcGL",
    "name": "My World Is Empty Without You",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/8178b17efe943ec9f38955faf2d641a8bd708156",
    "artist_name": "The Supremes",
    "image_url": "https://i.scdn.co/image/1616013fac773b94b3d1b4724028c298f7026aee",
    "artist_id": "57bUPid8xztkieZfS7OlEV",
    "year": 1966
  },
  {
    "song_id": "1C0932MrLNcbw6nmu8wyfl",
    "name": "Born Free",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/dabf5bf9efad74e47742f48bf5b8cff24760406d",
    "artist_name": "Roger Williams",
    "image_url": "https://i.scdn.co/image/7802443c997b91f94836aed2c16870845e915f27",
    "artist_id": "55CCS64lPz88twUDVquU1u",
    "year": 1966
  },
  {
    "song_id": "41IOFD9RMoMGOliWjUYJdG",
    "name": "Hooray For Hazel",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/55edfaf010b993644d83244d2ed7eeb4752cc911",
    "artist_name": "Tommy Roe",
    "image_url": "https://i.scdn.co/image/099f5721318ba6c4836a718872f0125ce4795a50",
    "artist_id": "7t4XHvWfj0XtEB8SNFeALw",
    "year": 1966
  },
  {
    "song_id": "0ahLkA13iF0L1N4DPzpGZg",
    "name": "Just Like Me",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/7eb44721153cd667766d183126a7958feb482993",
    "artist_name": "Paul Revere & The Raiders",
    "image_url": "https://i.scdn.co/image/66adefc76eefca75f59b2b656c91c3af94914e0c",
    "artist_id": "32HPpJAhgnGPT3V7UOggzi",
    "year": 1966
  },
  {
    "song_id": "3sT6hMpzRZyGjppZYMlaSq",
    "name": "Rainy Day Women #12 & 35",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/9821e3bd733cb16eefe1d5c25d2bf41102ceea98",
    "artist_name": "Rock n Roll Soldiers",
    "image_url": "https://i.scdn.co/image/4d8c65fe1b02bb9191a5755a38424d2effd76f48",
    "artist_id": "6oEva7PVTpHbNx9JOzNRdv",
    "year": 1966
  },
  {
    "song_id": "4zwTezH2t5OAezRwbbj5bk",
    "name": "Dandy",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/d109c9740e55d4bcb57aa3d114f6e26383f49b50",
    "artist_name": "Herman's Hermits",
    "image_url": "https://i.scdn.co/image/58c0590436f687eb032cba747d655eea9a605e59",
    "artist_id": "48YxSlb23RAaCd4RyHcV9V",
    "year": 1966
  },
  {
    "song_id": "77qDsWbIHIF6qtxCd3TcH7",
    "name": "Hungry",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/06983fc8cc8d66211a7e393fc56ef5d086b307f2",
    "artist_name": "Paul Revere & The Raiders",
    "image_url": "https://i.scdn.co/image/66adefc76eefca75f59b2b656c91c3af94914e0c",
    "artist_id": "32HPpJAhgnGPT3V7UOggzi",
    "year": 1966
  },
  {
    "song_id": "5mTkC4GQu1q5GuOk57rJaK",
    "name": "Paperback Writer",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/5dcb2f7bd00f37add437668cb2971ad0d9896d2d",
    "artist_name": "The Silver Beetles",
    "image_url": "https://i.scdn.co/image/dcc94938ae2384edbba6d756ffd4c950c65edd7f",
    "artist_id": "1MLXQOUzVRfjYAu5xyEntV",
    "year": 1966
  },
  {
    "song_id": "1qRA5BS78u3gME0loMl9AA",
    "name": "For What It's Worth",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/6758abf29a3103f200d4ff18b47c3af1edde1620",
    "artist_name": "Buffalo Springfield",
    "image_url": "https://i.scdn.co/image/a33e90cc56405494b6beb988653babbe4a2a423e",
    "artist_id": "3eskO5m0H4yiF64vRySBjr",
    "year": 1967
  },
  {
    "song_id": "1JO1xLtVc8mWhIoE3YaCL0",
    "name": "Happy Together",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/9d785da2ec8be42ede932b42675af2ca2caa8778",
    "artist_name": "The Turtles",
    "image_url": "https://i.scdn.co/image/38ff832b2337382c9488a882ccaef5300cf5b25b",
    "artist_id": "2VIoWte1HPDbZ2WqHd2La7",
    "year": 1967
  },
  {
    "song_id": "28wGX1hoLCRbBZbEqITvE3",
    "name": "Ain't No Mountain High Enough - Stereo Version",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/5de3c4a01324548dd2755d6f97f9f7e1a5908d5c",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/f1acca2d639c3bdb7341a73d7ca552cca622f756",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1967
  },
  {
    "song_id": "5AoTuHE5P5bvC7BBppYnja",
    "name": "Respect - Remastered",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/a8ce671c57b5d025221a8a17ed86268d1f1996a8",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/eebe31ba91e47d2335502923b7ef1bea259fa4f2",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1967
  },
  {
    "song_id": "30g3R8mv8qyLKv1A0cAFil",
    "name": "Brown Eyed Girl",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/8f185a54da3658bc9ae29cf624198a52a42e53c6",
    "artist_name": "Van Morrison",
    "image_url": "https://i.scdn.co/image/3505b19b1cb38419b60a11b740f7c1bc9d4cceff",
    "artist_id": "44NX2ffIYHr6D4n7RaZF7A",
    "year": 1967
  },
  {
    "song_id": "6RJK553YhstRzyKA4mug09",
    "name": "The Letter",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/0543dab52fc273f5f40b5df16cee220cb20073d4",
    "artist_name": "The Box Tops",
    "image_url": "https://i.scdn.co/image/ae95004334f6629585ba3802c49f66b27dc59188",
    "artist_id": "3KGQvnOoqUHi3KxKQMZtXr",
    "year": 1967
  },
  {
    "song_id": "66ujRcQB3xjilVohGyvTKM",
    "name": "San Francisco (Be Sure to Wear Flowers in Your Hair)",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/7bd5eab309d087dcc7aa7e7724697ca001d69e2e",
    "artist_name": "Scott McKenzie",
    "image_url": "https://i.scdn.co/image/b2da96cd01c61738b93d6e39bbc42e86b3d603cd",
    "artist_id": "7d7AZ3CQC457bFhK0wHpSO",
    "year": 1967
  },
  {
    "song_id": "5q3qYTQHiWceu6zZNZR01I",
    "name": "Somebody to Love",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/933bd77801019d972bfa51eb07c82edbcb2c4c56",
    "artist_name": "Jefferson Airplane",
    "image_url": "https://i.scdn.co/image/8b15e9c7f09b7b2e531f2f84bc3c100f5bd85f29",
    "artist_id": "2qFr8w5sWUITRlzZ9kZotF",
    "year": 1967
  },
  {
    "song_id": "4vpeKl0vMGdAXpZiQB2Dtd",
    "name": "White Rabbit",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/edb9c04ef6ea5d6cd6d8efc06fc8fbf63f16fd95",
    "artist_name": "Jefferson Airplane",
    "image_url": "https://i.scdn.co/image/cca72254d6884450ac4dd999ed9bf42aad7220b7",
    "artist_id": "2qFr8w5sWUITRlzZ9kZotF",
    "year": 1967
  },
  {
    "song_id": "1LTgbCS6YUFi5ge1ctZZc0",
    "name": "Daydream Believer",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/b07ae89939dc9d30879443ee5a99c77ecf70ed79",
    "artist_name": "The Monkees",
    "image_url": "https://i.scdn.co/image/f8631084d851c3e21b1ee3275bc52c0e80768fc1",
    "artist_id": "320EPCSEezHt1rtbfwH6Ck",
    "year": 1967
  },
  {
    "song_id": "4TBBPZks71c60whhq0PgdP",
    "name": "(Your Love Keeps Lifting Me) Higher & Higher",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/09c5e4f47b5c45d71544f7efdff937279c6afe96",
    "artist_name": "Jackie Wilson",
    "image_url": "https://i.scdn.co/image/29a1d1e67fd23fe766acd38867dc654ff34ba67e",
    "artist_id": "4VnomLtKTm9Ahe1tZfmZju",
    "year": 1967
  },
  {
    "song_id": "7aghcqWLL9hGMIb0VFdbyD",
    "name": "Light My Fire (45 Version)",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/3688423c274668d99cd814b67ff89b0659ec86e1",
    "artist_name": "The Doors",
    "image_url": "https://i.scdn.co/image/9490b7180740ca37d62e511d1d610447d9355080",
    "artist_id": "22WZ7M8sxp5THdruNY3gXt",
    "year": 1967
  },
  {
    "song_id": "1hES47PrbU8GPTI5A9lsr0",
    "name": "I Never Loved a Man [The Way I Love You]",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/d261244adba1bb9b387c13f52ee2836697d4c6a3",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/c52e77215add79d076c291b0c7a53ef35b45cc96",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1967
  },
  {
    "song_id": "6AEaiHXFPOncWxkoKmb5dB",
    "name": "Uptight (Everything's Alright)",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/5a0b026cec21faa96354f02b0be635ae93d2d386",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/6476d80646a3ac86c61b9e2263ead819b2b6194b",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1967
  },
  {
    "song_id": "4FdDorlbJTVHcH3djLbIfn",
    "name": "Ode to Billie Joe",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/e5622c1d428b8dc70b3093ae1e59a2e84ffbc967",
    "artist_name": "Bobbie Gentry",
    "image_url": "https://i.scdn.co/image/e9f09dfe8a2a836cf1a8f88db1486fd89c8bb7d8",
    "artist_id": "4E9w0bms6HcEppFlWjeW2d",
    "year": 1967
  },
  {
    "song_id": "6aTVmTpgPmsg7aqs402iHj",
    "name": "I Was Made To Love Her",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/7944d34fba7667263fa36c7d59212d9967f6e617",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/6476d80646a3ac86c61b9e2263ead819b2b6194b",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1967
  },
  {
    "song_id": "3G7tRC24Uh09Hmp1KZ7LQ2",
    "name": "I'm a Believer - 2006 Remastered Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/31439cbba555b625c56760e8365912a95972e27d",
    "artist_name": "The Monkees",
    "image_url": "https://i.scdn.co/image/f067ea7690925c7b47bfbed7e0a30880b964c6b9",
    "artist_id": "320EPCSEezHt1rtbfwH6Ck",
    "year": 1967
  },
  {
    "song_id": "7xy8vPQB2t2kDgJwqk71Tv",
    "name": "Silence Is Golden",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/4e46f2e687f2c7717febf9217c45da7db18d4c42",
    "artist_name": "The Tremeloes",
    "image_url": "https://i.scdn.co/image/308a35fcc950e79a7ab111d0846084e1336ad96a",
    "artist_id": "2VL8sLEJ6lHEwocjc1pN9w",
    "year": 1967
  },
  {
    "song_id": "36v8IF4VJv37hDMasPxX6g",
    "name": "Baby, I Love You",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/91dd8cd417fc90d35e73f063e07006cece1f062a",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/601cae92a8af4f54708735ce524bf07bdaaaadfd",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1967
  },
  {
    "song_id": "7Ko8eQ58gmHF8m3drHGUZT",
    "name": "She'd Rather Be With Me",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/a042465fedcb6256b2a87a816e4a9d08d190ea26",
    "artist_name": "The Turtles",
    "image_url": "https://i.scdn.co/image/38ff832b2337382c9488a882ccaef5300cf5b25b",
    "artist_id": "2VIoWte1HPDbZ2WqHd2La7",
    "year": 1967
  },
  {
    "song_id": "0aebD8Phxpok5MG7pbwey5",
    "name": "Soul Man - LP/Single Version",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/8989d19ea19c323e387f9759606cdba805046bff",
    "artist_name": "Sam & Dave",
    "image_url": "https://i.scdn.co/image/eebe31ba91e47d2335502923b7ef1bea259fa4f2",
    "artist_id": "2BVYdY4PyfCF9z4NrkhEB2",
    "year": 1967
  },
  {
    "song_id": "0pvA063uNyGQFp7DNtmk0P",
    "name": "Sweet Soul Music",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/a7ef82b99515e4bad02361fc356708185762d891",
    "artist_name": "Arthur Conley",
    "image_url": "https://i.scdn.co/image/15d7820d4d1106bce43b0b55e3e741a4d90e8fea",
    "artist_id": "10TSIJnyUcowWhpjj59gHB",
    "year": 1967
  },
  {
    "song_id": "4hupcimlg3UBbW1kAQ6vrT",
    "name": "Ruby Tuesday",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/8beb25dcf9c55c8efc7589eb241b421a31ee7186",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/b357001cb638771b8538463dad10e15edda003fe",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1967
  },
  {
    "song_id": "3eOcCcZWqgR6nMZ54jnTH8",
    "name": "A Whiter Shade Of Pale",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/45760a84f72239175351a5937722e5decd6e6b76",
    "artist_name": "Procol Harum",
    "image_url": "https://i.scdn.co/image/ed2bb3eae8d6e5c2bd4c01eae6480a3dcb141088",
    "artist_id": "0GbqW5TJr7n4is453VOY4C",
    "year": 1967
  },
  {
    "song_id": "7GSRH3L0HAM3StkAkYVamC",
    "name": "Tell It Like It Is",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/3efb01b2317abb0c7b5561be86abb6295c70f069",
    "artist_name": "Aaron Neville",
    "image_url": "https://i.scdn.co/image/b736865312bb7de39805f634c1e151530bd3d681",
    "artist_id": "57ALvbCBaCkNlgTOSiUPdT",
    "year": 1967
  },
  {
    "song_id": "6DkzoFo7JVBlyynYlB3DDw",
    "name": "Friday On My Mind",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/d3487dad55456c3ac9b95a574e174a46995bf986",
    "artist_name": "The Easybeats",
    "image_url": "https://i.scdn.co/image/cd4961a5a1e325c7ce1e0394324fe237ca7eb34d",
    "artist_id": "1pJEZXU2hJApJW3rM7LmMu",
    "year": 1967
  },
  {
    "song_id": "2tuWxZctCHB4ETgPE8rb34",
    "name": "The Beat Goes On",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/9accb8f8f98834615644402ea15e09f8dbda1e14",
    "artist_name": "Sonny & Cher",
    "image_url": "https://i.scdn.co/image/f8e5b486e4a83cce15ae2816c00ab5bfb2de0c95",
    "artist_id": "71lGEtP9qYXDsSXjfexTqO",
    "year": 1967
  },
  {
    "song_id": "5Mtfpo81RKlw5fAvWXFPlT",
    "name": "Gimme Little Sign",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/4fd727030e1be70d23f6626d50c844f711878ce6",
    "artist_name": "Brenton Wood",
    "image_url": "https://i.scdn.co/image/66b7f929c12b4c13630b1afd4890583ac45e50fe",
    "artist_id": "6ohvzFHYmLd05kyYhLui5K",
    "year": 1967
  },
  {
    "song_id": "3ynGqAYRSrWURuQhFuPW7C",
    "name": "Dedicated To The One I Love",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/719eae7c7c01b8dd456710a7215e61e8ecf5b9bd",
    "artist_name": "The Mamas & The Papas",
    "image_url": "https://i.scdn.co/image/04560d4ce4da7d76ac58c89ca3504a45c9fa501b",
    "artist_id": "1bs7HoMkSyQwcobCpE9KpN",
    "year": 1967
  },
  {
    "song_id": "1bhuvevmcDLS2fyLjIqSDW",
    "name": "Here We Go Again",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/372994ccbf3ecd1a7cb2ee65997751b5c903f348",
    "artist_name": "Ray Charles",
    "image_url": "https://i.scdn.co/image/ad59567e738dcf33158ad5dbbd8d71f74c95b3a7",
    "artist_id": "1eYhYunlNJlDoQhtYBvPsi",
    "year": 1967
  },
  {
    "song_id": "0jfOA16bjZxrkIzibPcl6U",
    "name": "I Think We're Alone Now - Single/",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/ad143a7e5128eabd6bd01abbdfc42681388de3f0",
    "artist_name": "Tommy James",
    "image_url": "https://i.scdn.co/image/3bc2062a865e215b1d87caf80c938f5b96424db1",
    "artist_id": "7eRORqxEbt7ftbaaWP0k5v",
    "year": 1967
  },
  {
    "song_id": "1x4cWY0XlKELUqGjVYGn6V",
    "name": "Somethin' Stupid (with Frank Sinatra)",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/4daeea0d15b09ad63e9bbda08f083a69c2e52184",
    "artist_name": "Nancy Sinatra",
    "image_url": "https://i.scdn.co/image/9b491f064c5027d58ce3f67bebc6951a1509a3a0",
    "artist_id": "3IZrrNonYELubLPJmqOci2",
    "year": 1967
  },
  {
    "song_id": "67eIyDIjpzIU1zf2ukgZcc",
    "name": "Your Precious Love - Stereo Version",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/9be465a28d6e414cad163b52283a42d3b706817f",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/f1acca2d639c3bdb7341a73d7ca552cca622f756",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1967
  },
  {
    "song_id": "7CeFfltoIpKKWyEro2gaRe",
    "name": "Gimme Some Lovin'",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/5a440e4891a23d3034db963bb51067da6075deb6",
    "artist_name": "The Spencer Davis Group",
    "image_url": "https://i.scdn.co/image/2f0d510ff8f04c81d24473852110e7123feb365c",
    "artist_id": "3i9hP422d2KMjaupTzBNVS",
    "year": 1967
  },
  {
    "song_id": "2IobDyMywYgrJAdLpRvqvT",
    "name": "Let's Live For Today",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/11b59010ea6d30df45bbf233b8b28580a6032959",
    "artist_name": "The Grass Roots",
    "image_url": "https://i.scdn.co/image/8c663c7bff8f20c04723c51b70d4912e6ed12fac",
    "artist_id": "2IU5UBup85LmAXh5U36DeC",
    "year": 1967
  },
  {
    "song_id": "2z8uZHoLhb16fIFwdeUgQm",
    "name": "Can't Take My Eyes Off You - 2007 Remastered",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/9f1afac02a829cea226703121e3641bc9268be21",
    "artist_name": "Frankie Valli",
    "image_url": "https://i.scdn.co/image/5943fb1a1cc3cd83e4656946b592238b3085050f",
    "artist_id": "3CDKmzJu6uwEGnPLLZffpD",
    "year": 1967
  },
  {
    "song_id": "7N3UAu6FkwdBg9J7OONBBP",
    "name": "Jimmy Mack",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/80435e2de09af5be4107d0c632042aca1264c658",
    "artist_name": "Martha Reeves & The Vandellas",
    "image_url": "https://i.scdn.co/image/ed5d946ed29cad20bf0ac141341462cb83a7eded",
    "artist_id": "1Pe5hlKMCTULjosqZ6KanP",
    "year": 1967
  },
  {
    "song_id": "0LQhKGlQZrR6PRWLUFZfII",
    "name": "A Little Bit Me, A Little Bit You - Single/",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/1a054af9c03806bb77286a846cc69b6d5b81b2b9",
    "artist_name": "The Monkees",
    "image_url": "https://i.scdn.co/image/f8631084d851c3e21b1ee3275bc52c0e80768fc1",
    "artist_id": "320EPCSEezHt1rtbfwH6Ck",
    "year": 1967
  },
  {
    "song_id": "7JDSbEyUqPxlmRPyP9O86F",
    "name": "Carrie Anne - 1998 - Remaster",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/06fd39938e80309a3ebea06551ff612d0fa1cda0",
    "artist_name": "The Hollies",
    "image_url": "https://i.scdn.co/image/352c236eff90eede4c3aa50fdb6bc0586d485828",
    "artist_id": "6waa8mKu91GjzD4NlONlNJ",
    "year": 1967
  },
  {
    "song_id": "4Kj7BJGxHXqNiAGXVD2xAH",
    "name": "Funky Broadway",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/7fb757b0da270f7e4c568ce3ea705f3e82075df5",
    "artist_name": "Wilson Pickett",
    "image_url": "https://i.scdn.co/image/09aafd113a517c8832d15dfce84b20a336cb7676",
    "artist_id": "0N5PyKJzS3M1XNlaCL7bbE",
    "year": 1967
  },
  {
    "song_id": "0gBFr7N2D1GfhkXIIepIGG",
    "name": "To Sir With Love",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/325f10de7d4289f403e38208dd93dbb983e35040",
    "artist_name": "Lulu",
    "image_url": "https://i.scdn.co/image/9301c04002c8f9b3a0588f046f32e9fb186edc85",
    "artist_id": "0jYKX08u1XxmHrl5TdM2QZ",
    "year": 1967
  },
  {
    "song_id": "73XQBWHSVLL1939hb8cQTm",
    "name": "Georgy Girl",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/ae508549d1d5d427a342cf98c1aaec55e195bbef",
    "artist_name": "The Seekers",
    "image_url": "https://i.scdn.co/image/9ecf5449f3d734f6e8239d517bbc3f1b56046d44",
    "artist_id": "7dedWAqd0IKTdCiqiadUrV",
    "year": 1967
  },
  {
    "song_id": "3UB6yOPeImCCUuQzaggho1",
    "name": "Groovin'",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/8552a584756f96c472f01b8e340e5a86d06a9982",
    "artist_name": "The Young Rascals",
    "image_url": "https://i.scdn.co/image/c1fc9742a0832b71cc98ca5fea3ab432c8aaac8b",
    "artist_id": "5X3TuTi9OIsJXMGxPwTKM2",
    "year": 1967
  },
  {
    "song_id": "20MBLB1TCm3tQK3XZlHXTK",
    "name": "Incense And Peppermint",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/fb74a2d47b2da3d67e305d6396dfd3b235428819",
    "artist_name": "Strawberry Alarm Clock",
    "image_url": "https://i.scdn.co/image/c5d07a94d8cd96b1df8980368cee4dae71e5cf7d",
    "artist_id": "1nyQBzKgZ2hBLr7PnyV7cI",
    "year": 1967
  },
  {
    "song_id": "0sOoGrE5PY5EBDQnR7Yysu",
    "name": "The Rain, The Park & Other Things - Single Version",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/8965b983b8ea09d3775baef15a9cd48a39db8ca2",
    "artist_name": "The Cowsills",
    "image_url": "https://i.scdn.co/image/8dd4ea4400a2223fedbac25440d2f33455d45df4",
    "artist_id": "4ZSzroBNV7HzBDO9aohuF1",
    "year": 1967
  },
  {
    "song_id": "7Fz4uqfZNhhtqItTVGibiZ",
    "name": "I Had Too Much To Dream (Last Night)",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/e5f8aaef38d4f49106ed283dbb00d6fca6bd613e",
    "artist_name": "The Electric Prunes",
    "image_url": "https://i.scdn.co/image/0077db21d147fb0c88da69d8e0eee064576dd8d6",
    "artist_id": "1kkpNi8Ii5MhBMxmUhN5g9",
    "year": 1967
  },
  {
    "song_id": "6gjS093LxGZdyBsDtREich",
    "name": "Then You Can Tell Me Goodbye",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/b43491c224d312cdaef4c0257c0d6ab0ca9624df",
    "artist_name": "The Casinos",
    "image_url": "https://i.scdn.co/image/af0a28cb91b50784bb66652e826d5c1da511e589",
    "artist_id": "2oEalcjHCHizuXF2ao47F6",
    "year": 1967
  },
  {
    "song_id": "36vB8cJZTJHhscxp0YklnW",
    "name": "Green Green Grass Of Home",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/8ef5b598a058d4fb16d1d4a71cd3e06805263d78",
    "artist_name": "Tom Jones",
    "image_url": "https://i.scdn.co/image/49edcc833f44dc373aa88a97ea593904c7774145",
    "artist_id": "1T0wRBO0CK0vK8ouUMqEl5",
    "year": 1967
  },
  {
    "song_id": "4j7Ehav8SUUgSePBptGxTi",
    "name": "Bernadette - Album Version / Stereo",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/251548a58f129f691c88d30854559e751ecec1b4",
    "artist_name": "Four Tops",
    "image_url": "https://i.scdn.co/image/bf06ba96ff61b11c80b2a4375bd5ec51aaeabb5f",
    "artist_id": "7fIvjotigTGWqjIz6EP1i4",
    "year": 1967
  },
  {
    "song_id": "3JdToGxgvH9SnO1a605Ztr",
    "name": "Up, Up and Away",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/21864b7454302fcf8e4e1224b4e3365e9ecb4c3d",
    "artist_name": "The 5th Dimension",
    "image_url": "https://i.scdn.co/image/f99d911f457eaf1dca978b1c2a163a22507ad958",
    "artist_id": "1UUYAQ9LiRsZF0ZukQNWXM",
    "year": 1967
  },
  {
    "song_id": "6XVpKaD3oxpA2ohiLyTGPC",
    "name": "It Must Be Him",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/f8e586d4a38fc0cdf9086c5d658b6d02e001c41f",
    "artist_name": "Vikki Carr",
    "image_url": "https://i.scdn.co/image/f153b4d0f8b25387a5674d1e8c53a32707ea84ba",
    "artist_id": "3tM3DTmG7ahfACzwqtazLD",
    "year": 1967
  },
  {
    "song_id": "5HZSDXEb70PqLwLDhAOMM9",
    "name": "Love Is Here And Now You're Gone",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/864a3e825ecc9b11c06e4b84856f3503e1c2afd3",
    "artist_name": "Diana Ross & The Supremes",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "0rXI0q8Cahq6numvPlloaq",
    "year": 1967
  },
  {
    "song_id": "5NfMMASaRz01fltWjbYoLu",
    "name": "Kind Of A Drag",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/0383e19c4b059eeb92e4fde3185c52f13144af83",
    "artist_name": "The Buckinghams",
    "image_url": "https://i.scdn.co/image/316053572757114db637949bca05324dbf21e701",
    "artist_id": "4zmxet3ZPdSmL3Xyv1Wegb",
    "year": 1967
  },
  {
    "song_id": "0zV8gHHcSVDy9czQWrztC3",
    "name": "On a Carousel",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/db65c8d798b77685da9bbce3472025960b641e6e",
    "artist_name": "The Hollies",
    "image_url": "https://i.scdn.co/image/c1452f171f62bf90db175f5b193256ed87ccda12",
    "artist_id": "6waa8mKu91GjzD4NlONlNJ",
    "year": 1967
  },
  {
    "song_id": "5eeZr5k6eprE1NToNTZuxs",
    "name": "Alfie",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/d0fc052a53f2c53c2b47c9f1a41c9f2a4feb8c83",
    "artist_name": "Dionne Warwick",
    "image_url": "https://i.scdn.co/image/826750d63d751057de6904dab81a7a68a15de346",
    "artist_id": "2JSjCHK79gdaiPWdKiNUNp",
    "year": 1967
  },
  {
    "song_id": "2HqB3VSdiYmDkTTCPzVHOf",
    "name": "Pleasant Valley Sunday - 2007 Remastered Version",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/c9bdd28c3e02e5197eee7e85e11275244f70b4e2",
    "artist_name": "The Monkees",
    "image_url": "https://i.scdn.co/image/b1591cdd12c8aaded1399107d5d292ac398f5711",
    "artist_id": "320EPCSEezHt1rtbfwH6Ck",
    "year": 1967
  },
  {
    "song_id": "5pDM1xfBg3IL1yMvroLW88",
    "name": "I Dig Rock And Roll Music - Remastered",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/d86d316e6e9f16f8c0a630f31d42087ed6e372f9",
    "artist_name": "Peter, Paul and Mary",
    "image_url": "https://i.scdn.co/image/ff05c2ea2aa9cc72696084714e0c8520d795fde2",
    "artist_id": "6yrBBtqX2gKCHCrZOYBDrB",
    "year": 1967
  },
  {
    "song_id": "1ljijVo7rDHSuXIhydPpNl",
    "name": "Don't Sleep in the Subway",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/b5da2f94d817af9867705f6cd3b98ca9115d7114",
    "artist_name": "Petula Clark",
    "image_url": "https://i.scdn.co/image/da908b6e552d0a8d8fca4258c517884d7a84835c",
    "artist_id": "6nKqt1nbSBEq3iUXD1Xgz8",
    "year": 1967
  },
  {
    "song_id": "4KckMIRe06kjCjqomf3WzF",
    "name": "Let It Out (Let It All Hang Out)",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/66b94519b50edb8b8061f73da9ffae1aa548e430",
    "artist_name": "The Hombres",
    "image_url": "https://i.scdn.co/image/a68006d0c4c53545ae9d96e6c94c3dbd248de640",
    "artist_id": "5Kd7EaN14eunBIyNux2c3z",
    "year": 1967
  },
  {
    "song_id": "1vUWTSUbvLThJLvje6X04c",
    "name": "Snoopy Vs. The Red Baron - 1995 - Remastered",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/7f093e7b38e8f2f405d5259c15fed91e5b313934",
    "artist_name": "The Royal Guardsmen",
    "image_url": "https://i.scdn.co/image/a5f7ce6af8f1f6889e2005090490ddd33c9c16f0",
    "artist_id": "2ltpcCK86foogli20z9raq",
    "year": 1967
  },
  {
    "song_id": "4Cl6BCh0RGp9puOTMkZlMM",
    "name": "Little Bit O' Soul - 2002 - Remaster",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/0c3cee1ab53195eea82e2d36f2fc4f51de966c66",
    "artist_name": "Music Explosion",
    "image_url": "https://i.scdn.co/image/a0f57ce6873d0e7ecfe42f42271614d67f6dba68",
    "artist_id": "1DVs21DjifVH0pgsjPlokN",
    "year": 1967
  },
  {
    "song_id": "0eEEZOwmDAYoc6H0opwJUI",
    "name": "The Happening",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/293cfde57eeb94b8f005b73c289a09a1d4344da2",
    "artist_name": "The Supremes",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "57bUPid8xztkieZfS7OlEV",
    "year": 1967
  },
  {
    "song_id": "64pOedAGgE4tD8dQcEMDa7",
    "name": "How Can I Be Sure - Single Version",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/bda13e9909e1dbd41caf9eb9aa94af1a262db184",
    "artist_name": "The Young Rascals",
    "image_url": "https://i.scdn.co/image/c1fc9742a0832b71cc98ca5fea3ab432c8aaac8b",
    "artist_id": "5X3TuTi9OIsJXMGxPwTKM2",
    "year": 1967
  },
  {
    "song_id": "64TjpXaotJoYlWQvf1AdSF",
    "name": "Cold Sweat - Part 1",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/5b1166093aeb3f25ae1b9ca3e1c077f83dc58007",
    "artist_name": "James Brown & The Famous Flames",
    "image_url": "https://i.scdn.co/image/a32094c304ee332440cd674c19e29bc9cc8e3a1a",
    "artist_id": "32r72WOqqRO1DtSznId7Lr",
    "year": 1967
  },
  {
    "song_id": "4YNKYvRIvrIqcrJNv1u4v0",
    "name": "Please Love Me Forever - Single Version",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/85701617a30e4c8be26b78948e081d81f4981780",
    "artist_name": "Bobby Vinton",
    "image_url": "https://i.scdn.co/image/0001270788af82d1b630b33323917f8af070711d",
    "artist_id": "6bOYtKnpLPQSfMpS2ilotK",
    "year": 1967
  },
  {
    "song_id": "1sobMQ41ROngxwTCp1qNXv",
    "name": "You're My Everything",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/392f6d2b655c1bfc0e3781cfe2bf85c2a4a8605d",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/bee5511a869a6d1a993197058aaa3cb83dbbf211",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1967
  },
  {
    "song_id": "0d9rN4NbUgOtuPyQa2286E",
    "name": "Close Your Eyes",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/6f8ad63ff34913f6787aedbcfce37d13ebd93d0e",
    "artist_name": "Peaches & Herb",
    "image_url": "https://i.scdn.co/image/31c06f2f8b47fb2a25b48ccd6f77cf2b417821ec",
    "artist_id": "6qI4LTzMRpTxRzMZPvv2C6",
    "year": 1967
  },
  {
    "song_id": "1OdFAYq591cuGvEu5wSPIA",
    "name": "Baby, I Need Your Lovin'",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/bf6662a50f5749430776816502df866584f171cb",
    "artist_name": "Johnny Rivers",
    "image_url": "https://i.scdn.co/image/17f49bf99d1978fb5c5ac4e9925ab5b305d7c92d",
    "artist_id": "3TiISqKS6ESlMQ4WFfZJw2",
    "year": 1967
  },
  {
    "song_id": "38XYQYKfwZNMmBEcLZ4Zvk",
    "name": "I've Been Lonely Too Long - Single Version",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/639ba7a9da746b268a8ccbbdafc254c6537f63a8",
    "artist_name": "The Young Rascals",
    "image_url": "https://i.scdn.co/image/8cc030355b00f419ec0f9dc19834ec97946d9317",
    "artist_id": "5X3TuTi9OIsJXMGxPwTKM2",
    "year": 1967
  },
  {
    "song_id": "3soVrZz1moOkxcQRBuYfxz",
    "name": "Mirage",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/1b3009ea16b3432d481fbc06e5234f863c7a51c3",
    "artist_name": "Tommy James & The Shondells",
    "image_url": "https://i.scdn.co/image/1994b15bffc459501331cc743b4944af8da07d25",
    "artist_id": "01hRNr3yF5bYnPq4wZ88iI",
    "year": 1967
  },
  {
    "song_id": "6WPH4hbTPOeeWSihqan6DU",
    "name": "Don't You Care",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/010506989b661536deb1369447412dac61ac8a47",
    "artist_name": "The Buckinghams",
    "image_url": "https://i.scdn.co/image/dcb59141ffe974269fbbe42bdae212c79e86ea68",
    "artist_id": "4zmxet3ZPdSmL3Xyv1Wegb",
    "year": 1967
  },
  {
    "song_id": "0KwRZYa4OBQnMY8JfW0vmf",
    "name": "98.6",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/b0451757ac25049eb2a7abe741018108755a428f",
    "artist_name": "Keith",
    "image_url": "https://i.scdn.co/image/207ac82b5d60c4363faac119b3e07d965f44fac8",
    "artist_id": "43RL9NZGZ9hsL1c1i1Ny9Q",
    "year": 1967
  },
  {
    "song_id": "40BvPdq2rwYIoioUgjKHoK",
    "name": "My Cup Runneth Over",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/00c2841907c97ca0c8d9e25373f430457152a963",
    "artist_name": "Ed Ames",
    "image_url": "https://i.scdn.co/image/f23fabe6f5fbab0130e858e0f5a68ea354e091b3",
    "artist_id": "1iBpJEBrSFXCtPOc5OUZiY",
    "year": 1967
  },
  {
    "song_id": "4ygQFe0kTwEQYUc0SjMr58",
    "name": "Society's Child (Baby I've Been Thinking) - Single Version",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/4a53b8cd2cba689de0861a96f58de421db403900",
    "artist_name": "Janis Ian",
    "image_url": "https://i.scdn.co/image/09a85ea38cd0057d8acf2dd59c43cf5ac9f4cffb",
    "artist_id": "5c9uFWpZY2MTlk7Rft0tgp",
    "year": 1967
  },
  {
    "song_id": "5avIhFaXUBGk5Hoeq7fZag",
    "name": "Come On Down To My Boat",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/89ea395f99c44d87df5150f73de99850d66a235d",
    "artist_name": "Every Mother's Son",
    "image_url": "https://i.scdn.co/image/fd4087bc731206cee5f7c4358e7abf9dd730c157",
    "artist_id": "44RnRzSdbvlthfaspNyQld",
    "year": 1967
  },
  {
    "song_id": "1MiJfOXSXATS1cEEZA1wgj",
    "name": "Mercy, Mercy, Mercy - Single Version",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/719aed74edb5550624960662143f44859d20fdb0",
    "artist_name": "The Buckinghams",
    "image_url": "https://i.scdn.co/image/22daa7572c933c3ed187a2c33c199135de8ae4e4",
    "artist_id": "4zmxet3ZPdSmL3Xyv1Wegb",
    "year": 1967
  },
  {
    "song_id": "3ZJMhWsMQw6QaCLFsySB2q",
    "name": "California Nights",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/b6051da1184427fc2b99e0c7915b0012c911fea9",
    "artist_name": "Lesley Gore",
    "image_url": "https://i.scdn.co/image/eb5d8086e59e488d3397717e4d2a8022b57fdf97",
    "artist_id": "08b2PA6eFyugsWAk41eQKZ",
    "year": 1967
  },
  {
    "song_id": "1kC14sT05mwE8NHTKNDHh0",
    "name": "(We Ain't Got) Nothing Yet",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/3410f0b5dba1a99b73d35315248e7a7f9e6bec34",
    "artist_name": "The Blues Magoos",
    "image_url": "https://i.scdn.co/image/ada640c544f0d83b62cbcde66f5fc34c62cca71b",
    "artist_id": "7peASGSN6FkIFQ3XjLfBtB",
    "year": 1967
  },
  {
    "song_id": "2WxVGu4Z7nd8w3iJh08jbe",
    "name": "Groovin'",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/8083702fbc962de53afe6760e69d0bb7d095cb38",
    "artist_name": "Booker T. & The MG's",
    "image_url": "https://i.scdn.co/image/d0776574678e049fabd83a4e98fa64f1b766564a",
    "artist_id": "2vDV0T8sxx2ENnKXds75e5",
    "year": 1967
  },
  {
    "song_id": "2nRLTSdnWdHZGtoqZASlez",
    "name": "Western Union",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/1cf4554b55be8d9970dd58cefbb374a49a2ae982",
    "artist_name": "The Five Americans",
    "image_url": "https://i.scdn.co/image/e3aeb1903da9ac1e3c8716241537208cc8f85856",
    "artist_id": "4B7Kwlv6J5QpJ44vB6STUF",
    "year": 1967
  },
  {
    "song_id": "4QNviFJLZ8SrjhkTkDbvyU",
    "name": "Reflections",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/01e9318c8a66f7483657aba02b5762cb2e468722",
    "artist_name": "Diana Ross & The Supremes",
    "image_url": "https://i.scdn.co/image/41e63aaaa2731fd444fb5e11ec787ca1bbc28b08",
    "artist_id": "0rXI0q8Cahq6numvPlloaq",
    "year": 1967
  },
  {
    "song_id": "5OpP6qc7ujDVcpMTBzzIdg",
    "name": "There's A Kind Of Hush",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/d170f362e883c65a34e2d8bd8e1d11ca40985b32",
    "artist_name": "Herman's Hermits",
    "image_url": "https://i.scdn.co/image/0697a3e7a01ed56a5c2f32cfa725d9ac851bf718",
    "artist_id": "48YxSlb23RAaCd4RyHcV9V",
    "year": 1967
  },
  {
    "song_id": "0KHLV5TPrJBlOjBWdFwZYB",
    "name": "I Take It Back",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/512eeb21580ffd2d152feae542a3d695b5026fb4",
    "artist_name": "Sandy Posey",
    "image_url": "https://i.scdn.co/image/3aa25d5d7004c62b3e0ad6153a5f00fe12198ef3",
    "artist_id": "5Xh0e6sCvMXJUNVa4oynvx",
    "year": 1967
  },
  {
    "song_id": "7iW0eYY7TqRr2gnsuIFxI3",
    "name": "Soul Finger - 2006 Remastered Version",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/1555c34aba56c8130d54d647d3353874d4d97825",
    "artist_name": "The Bar-Kays",
    "image_url": "https://i.scdn.co/image/bd630b8f0e130eb2033cc7b66818f091d41ab918",
    "artist_id": "0Z4CzYz9ieK8q9XiVMPkW5",
    "year": 1967
  },
  {
    "song_id": "3ttX48lagRWMp4OmCi5FCK",
    "name": "Release Me",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/1bf2502993a1bff656383697fccd44642c7142b6",
    "artist_name": "Engelbert Humperdinck",
    "image_url": "https://i.scdn.co/image/5dba991e0aca4584b999d2db991725fb73282dd9",
    "artist_id": "17XXKfRBMCWvLrqGoNkJXm",
    "year": 1967
  },
  {
    "song_id": "6FRwDxXsvSasw0y2eDArsz",
    "name": "Sunshine Of Your Love",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/1976cc43bf9eec1495b5250cb3c94d84efc2eb06",
    "artist_name": "Cream",
    "image_url": "https://i.scdn.co/image/cfe1d4e4bbf661fb6756a18bb5288ea9c6b62963",
    "artist_id": "74oJ4qxwOZvX6oSsu1DGnw",
    "year": 1968
  },
  {
    "song_id": "1nzcKrYRTWZJEwWzyAU8XM",
    "name": "Born To Be Wild - Single Version",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/eb595d9809429baa84e199a3cddb5e3cdf308370",
    "artist_name": "Steppenwolf",
    "image_url": "https://i.scdn.co/image/abef78d6530346ee49cb2d6276d4936f4984bb55",
    "artist_id": "1WRM9i067hd2ujxxi8FI3m",
    "year": 1968
  },
  {
    "song_id": "1MQWtVcs0PKsY4PA6ZvLiy",
    "name": "Dance to the Music",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/da0efe3f42cfc920d4632ed792208db8ac301b95",
    "artist_name": "Sly & The Family Stone",
    "image_url": "https://i.scdn.co/image/0fa9033dd195aabadfb2dcc098d8bc7846af9ae1",
    "artist_id": "5m8H6zSadhu1j9Yi04VLqD",
    "year": 1968
  },
  {
    "song_id": "3TfJFglXfKlh0UIh813ilq",
    "name": "Mrs. Robinson",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/aa41d4f51489cd8d349135bfe45c6124b66630cc",
    "artist_name": "Simon & Garfunkel",
    "image_url": "https://i.scdn.co/image/e99f9521d13795907bf4108ecce058ce1bca2552",
    "artist_id": "70cRZdQywnSFp9pnc2WTCE",
    "year": 1968
  },
  {
    "song_id": "4DWFSrNnZXow1aB96gByho",
    "name": "Elenore",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/bae44e0a382804ef6ecc89f8415e97b17b615dca",
    "artist_name": "The Turtles",
    "image_url": "https://i.scdn.co/image/872a71e65ac3457360187382eca73e7b769b348b",
    "artist_id": "2VIoWte1HPDbZ2WqHd2La7",
    "year": 1968
  },
  {
    "song_id": "7AL6qzIDHIX7pEUWrzNiIs",
    "name": "Hurdy Gurdy Man",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/6e0aaf5a9b8ec9d410cc827cf6a868e0623995b7",
    "artist_name": "Donovan",
    "image_url": "https://i.scdn.co/image/54eae4ca6ca07e8087fef681900a30a29e9415f9",
    "artist_id": "6vLlQYujOujIrm7zAKzEdG",
    "year": 1968
  },
  {
    "song_id": "4ynIxU1wlEQ4nSlu2QunbX",
    "name": "Scarborough Fair / Canticle",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/5a7860d911ed48f740b7596366e8e51ecf4ff63f",
    "artist_name": "Simon & Garfunkel",
    "image_url": "https://i.scdn.co/image/e2b439839e06c8efc8e7580c7768b7b90d9537bd",
    "artist_id": "70cRZdQywnSFp9pnc2WTCE",
    "year": 1968
  },
  {
    "song_id": "3bWGaqVeYKMlLss40mPgNn",
    "name": "Hello, I Love You",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/55c0f1a08ccceb88ae5a8c61f98921889d51230d",
    "artist_name": "The Doors",
    "image_url": "https://i.scdn.co/image/19120c59f6f7fea7ce9fe39a03f7cb4c6e351262",
    "artist_id": "22WZ7M8sxp5THdruNY3gXt",
    "year": 1968
  },
  {
    "song_id": "3F64pIPqvcujnwvcVLVZ64",
    "name": "Young Girl",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/787569d6f102461d96f14843ef58ea73146679a0",
    "artist_name": "Gary Puckett & The Union Gap",
    "image_url": "https://i.scdn.co/image/a73b5c036bed9ecf7060d2a3c81f904400bd477c",
    "artist_id": "4asCC4oxQcDzFXhCth2SgQ",
    "year": 1968
  },
  {
    "song_id": "1xKQbqQtQWrtQS47fUJBtl",
    "name": "Piece of My Heart",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/42a2b1becedba9ff74bebfd4e68b98a35b02cf49",
    "artist_name": "Big Brother & The Holding Company",
    "image_url": "https://i.scdn.co/image/e7e2a998bf29072bdefc9151935fe90cf531a73c",
    "artist_id": "4J69yWrKwWJgjv3DKTZcGo",
    "year": 1968
  },
  {
    "song_id": "3NfxSdJnVdon1axzloJgba",
    "name": "I Say a Little Prayer",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/0112cc3c71bf0d2ed1e3b6699d72680e5e26c009",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/6bba3930b3c0eefc9cc3fbec4837581fd4e59d81",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1968
  },
  {
    "song_id": "3ADQ9jGCJ6MfSbIZqtbVj2",
    "name": "Do You Know The Way To San Jose",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/28b2b44104fa54d0bb4c038a18a0f408cc88257c",
    "artist_name": "Dionne Warwick",
    "image_url": "https://i.scdn.co/image/826750d63d751057de6904dab81a7a68a15de346",
    "artist_id": "2JSjCHK79gdaiPWdKiNUNp",
    "year": 1968
  },
  {
    "song_id": "4yQw7FR9lcvL6RHtegbJBh",
    "name": "Think",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/ac3988e6697d000cce985d3eaf5fec137d14053d",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/6bba3930b3c0eefc9cc3fbec4837581fd4e59d81",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1968
  },
  {
    "song_id": "7g6j2SGvsvcUi6OLD9Qe1o",
    "name": "White Room",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/9945f8cdf8cb0030125ed0f32c88abe07354ae84",
    "artist_name": "Cream",
    "image_url": "https://i.scdn.co/image/10790e8f5f476857b8ad5ebdda77110cc52e6925",
    "artist_id": "74oJ4qxwOZvX6oSsu1DGnw",
    "year": 1968
  },
  {
    "song_id": "4QkvD53COA5qLA7rQ1Pa7D",
    "name": "Nobody But Me",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/b60035cb03ebaa8b6ee852bdc619c05b32560390",
    "artist_name": "The Human Beinz",
    "image_url": "https://i.scdn.co/image/d7e0604305e43a0fc316f313a2972effdfbe0566",
    "artist_id": "7KdxWrkwsSjIxyHL0HNtiv",
    "year": 1968
  },
  {
    "song_id": "48oT10cTda4pZUlYfqxlPR",
    "name": "Classical Gas",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/305b6f7d04b68482e66e53bf06d3a0e88368d103",
    "artist_name": "Mason Williams",
    "image_url": "https://i.scdn.co/image/249fd3a79abb641531616e86d71925efcb9bc8df",
    "artist_id": "1XgVoPT2Va5L64LFzskVBW",
    "year": 1968
  },
  {
    "song_id": "5qA1ZrjjVLh1cL9GZOFdNm",
    "name": "Time Has Come Today",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/54c3ac07208a685e46a016c321f1edd28b1c8775",
    "artist_name": "The Chambers Brothers",
    "image_url": "https://i.scdn.co/image/d4869955977515552a22d3250fd07642f8bbd6cf",
    "artist_id": "0OwaxYpG77PWKSLtj1QzG8",
    "year": 1968
  },
  {
    "song_id": "4C2CQXBiYK0batoD10uExB",
    "name": "Suzie Q",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/d9532122aadd991c10cc2c695f343422ac416801",
    "artist_name": "Creedence Clearwater Revival",
    "image_url": "https://i.scdn.co/image/b2d8b29286c231f0a335f434145acf151543ef76",
    "artist_id": "3IYUhFvPQItj6xySrBmZkd",
    "year": 1968
  },
  {
    "song_id": "5z4iT44mMHyZozsTFy4A51",
    "name": "Spooky",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/22d12ff2c64353b04f3b6002e6db8d7111632bbb",
    "artist_name": "Classics IV",
    "image_url": "https://i.scdn.co/image/f4ede3826d69f930ee77c8728ccb7221a23e27f6",
    "artist_id": "6XGJvNG372fPJ2TV2OoN0Z",
    "year": 1968
  },
  {
    "song_id": "70jnaGaj1rtpyABfUURpvr",
    "name": "Green Tambourine",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/84b485946f49d41d66aafd0a20a80551e3d64f60",
    "artist_name": "The Lemon Pipers",
    "image_url": "https://i.scdn.co/image/d0c6cd019909eaac32896574913979e4a12903ea",
    "artist_id": "2rUmEpDSHKMhamwClclHs5",
    "year": 1968
  },
  {
    "song_id": "3ZnlYvqCnjJ7OeZhVKkWPg",
    "name": "Honey - 1991 - Remaster",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/a57315c6ead25a504664e9c969fa1b16ccb9ab4e",
    "artist_name": "Bobby Goldsboro",
    "image_url": "https://i.scdn.co/image/2357e23690cb713cbd50ef75b3eacaa0ec5e0b61",
    "artist_id": "5gPEo032lzARtzuVqJIm9o",
    "year": 1968
  },
  {
    "song_id": "0cWPr2PvYTQERq88b4jGXw",
    "name": "The Mighty Quinn",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/ba48c488646e5ba32d668fdcbb9a6784f82415bb",
    "artist_name": "Manfred Mann",
    "image_url": "https://i.scdn.co/image/802f66b48ecf41f9b4b553eba49b596390ce6db8",
    "artist_id": "5670CyWrIOA7702gNjsGMZ",
    "year": 1968
  },
  {
    "song_id": "6kni0aRaJX8NNu63sfKTmr",
    "name": "Woman, Woman",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/964ea57bdb7d69d1b1f4450303eecbf8fdbae562",
    "artist_name": "Gary Puckett & The Union Gap",
    "image_url": "https://i.scdn.co/image/a73b5c036bed9ecf7060d2a3c81f904400bd477c",
    "artist_id": "4asCC4oxQcDzFXhCth2SgQ",
    "year": 1968
  },
  {
    "song_id": "059LGQd42y87hQMHQyJGSU",
    "name": "You Keep Me Hangin' On - Single Version",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/9ad2e5e47cd5a248e99494c89e712cc77c955b33",
    "artist_name": "Vanilla Fudge",
    "image_url": "https://i.scdn.co/image/cdd449140fd9840d657a01631d49d8adf074b413",
    "artist_id": "0vIMq3W8V63uR4Ymgm2pF1",
    "year": 1968
  },
  {
    "song_id": "5c3SBfvWYFtf5FQiNkg6FV",
    "name": "The Good, The Bad and The Ugly",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/9d317dccee5ae941080f7f3659b5112dbe45d9f0",
    "artist_name": "Hugo Montenegro & His Orchestra",
    "image_url": "https://i.scdn.co/image/dc0ee0dce54b48011193baee0f243b0f2f2c6246",
    "artist_id": "5g64xp3hpxoCXRJkNd5tmE",
    "year": 1968
  },
  {
    "song_id": "5FkaovzbJZA4mAXwDDantN",
    "name": "Bend Me, Shape Me",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/ff99b2742a0d8d01c5e3728562e2cff2217810a6",
    "artist_name": "American Breed",
    "image_url": "https://i.scdn.co/image/30e48f2796128f2951292e0ffd526bb903aa6e2c",
    "artist_id": "14Qw6EvhlsP3nA5AGl6Buy",
    "year": 1968
  },
  {
    "song_id": "6WvbMOjKf5y3CAHn4ubwlp",
    "name": "Yummy, Yummy, Yummy",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/0795c33db2636ed1856da1b15da2584379886727",
    "artist_name": "Ohio Express",
    "image_url": "https://i.scdn.co/image/9c78082ba7a2f6b62e0f2e57f4c4898cc9440d61",
    "artist_id": "6EHdKd4m8DzNf9NhVeJSVI",
    "year": 1968
  },
  {
    "song_id": "6IIcvtmuGpWIasqOpyGlyY",
    "name": "Tighten Up - Pt. 1",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/089d8449e7c63d642fe02b4e521707429af9c651",
    "artist_name": "Archie Bell & The Drells",
    "image_url": "https://i.scdn.co/image/90962bb32835ee7fa63b1678878247bf4730c5e2",
    "artist_id": "1kupwLFpHALpmhp5qol8xH",
    "year": 1968
  },
  {
    "song_id": "4RQ2HbvUPTgCXSOa7O9FQj",
    "name": "[Sittin' On] The Dock Of The Bay (45 Version)",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/1fec7a79e804159dbce7f06109b6ad1d513a930e",
    "artist_name": "Otis Redding",
    "image_url": "https://i.scdn.co/image/cf2585f48eac24dfbd17b60733975b66e1b150af",
    "artist_id": "60df5JBRRPcnSpsIMxxwQm",
    "year": 1968
  },
  {
    "song_id": "4XYHEuDwsKuxSpiIylcMnB",
    "name": "Over You",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/db493240e4f3eba89d80bf8ef3668b18d1582259",
    "artist_name": "Gary Puckett & The Union Gap",
    "image_url": "https://i.scdn.co/image/a73b5c036bed9ecf7060d2a3c81f904400bd477c",
    "artist_id": "4asCC4oxQcDzFXhCth2SgQ",
    "year": 1968
  },
  {
    "song_id": "6ljvK0SmaKjIBsPddKqaTo",
    "name": "Midnight Confessions - Single Version",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/269d2332c77e4a12c084299873331c49c057a1e8",
    "artist_name": "The Grass Roots",
    "image_url": "https://i.scdn.co/image/8c663c7bff8f20c04723c51b70d4912e6ed12fac",
    "artist_id": "2IU5UBup85LmAXh5U36DeC",
    "year": 1968
  },
  {
    "song_id": "6e5dOtgCiKkITJxtWSuLBE",
    "name": "Cry Like a Baby",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/25ab902b1c8446ba0f1b0aac44175b59a033488e",
    "artist_name": "The Box Tops",
    "image_url": "https://i.scdn.co/image/f3b8fbf7131f47b1c1093559acce0365c86bb0be",
    "artist_id": "3KGQvnOoqUHi3KxKQMZtXr",
    "year": 1968
  },
  {
    "song_id": "2sZIclzhHlH8tzf7wry9hK",
    "name": "Harper Valley P.T.A.",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/55812385054500b10f84517b21a53d55f91a95a4",
    "artist_name": "Jeannie C. Riley",
    "image_url": "https://i.scdn.co/image/8a6c0b6a200fc495ea29d723dab525626bdf870a",
    "artist_id": "3MIwMeYV8vmgQFbbRiJTp2",
    "year": 1968
  },
  {
    "song_id": "27QxL30kLR6RyIjLXCMFdU",
    "name": "Angel Of The Morning",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/9bc0fd0e1cbec4de64e53fd8af09c86ec4b78d6d",
    "artist_name": "Merrilee Rush",
    "image_url": "https://i.scdn.co/image/4b42be6d35a6a433e780eba64a07eb27bf13edda",
    "artist_id": "5Ao1vYvPBQUjsfPbPHQPhZ",
    "year": 1968
  },
  {
    "song_id": "23xk9Rf7oIHVUU1JvmXYFn",
    "name": "Mony Mony",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/d3d7b4278a886564edda124e381712d4057794f3",
    "artist_name": "Tommy James",
    "image_url": "https://i.scdn.co/image/48f24c1f816af1f9dc251e9d4741f24e42f2cc71",
    "artist_id": "7eRORqxEbt7ftbaaWP0k5v",
    "year": 1968
  },
  {
    "song_id": "3CgaLJdsv9llwyqAuXKKvU",
    "name": "Take Time To Know Her",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/c6c06ab383e18050b965e618f71f7695d2503061",
    "artist_name": "Percy Sledge",
    "image_url": "https://i.scdn.co/image/7d1527b2d072643131bb01abc823a15bac556f97",
    "artist_id": "3rRmDmzPcAFwcUDvG5gBqO",
    "year": 1968
  },
  {
    "song_id": "5VKdlRg4PXPh8H1cpGo16C",
    "name": "Little Green Apples",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/49d9834ad7aa798004cb2799ece648349485b837",
    "artist_name": "O.C. Smith",
    "image_url": "https://i.scdn.co/image/316053572757114db637949bca05324dbf21e701",
    "artist_id": "7qVAJPCHuOiMxlJ7h9xEAA",
    "year": 1968
  },
  {
    "song_id": "1sYRkVKdT2ize1HSDCwbEF",
    "name": "Say It Loud - I'm Black And I'm Proud",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/45f6886b64aef38e5dc3a8a84b486ae1ce94e6e2",
    "artist_name": "James Brown",
    "image_url": "https://i.scdn.co/image/50e21b669ceef79744e89a4f0ecbf5129406fc64",
    "artist_id": "7GaxyUddsPok8BuhxN6OUW",
    "year": 1968
  },
  {
    "song_id": "4H7N678Trhik3ITWn3wn2q",
    "name": "Ain't Nothing Like The Real Thing",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/b9fd358064c3980bad7948c1afbfc792e987bf5a",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1968
  },
  {
    "song_id": "1TLXzBr9b2FHDCnVzkyCyC",
    "name": "Jumpin' Jack Flash - (Original Single Mono Version)",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/1cd4b3f5dc740f9a231cc29a1f50b24d63b684a3",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/24af3101b887b0d9dbe33ebed073c742985cc3a6",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1968
  },
  {
    "song_id": "4nKrPxu5jE3t55x5rtM55y",
    "name": "Cowboys to Girls",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/f6581b70a58a9557caae84846a0eba3a3816e2d7",
    "artist_name": "The Intruders",
    "image_url": "https://i.scdn.co/image/397fe15e174b75d1c9043713d2a61c4333ab28da",
    "artist_id": "30q5mqJmdYPaKabPMytzvv",
    "year": 1968
  },
  {
    "song_id": "00fAPNgtf7QBQacuLbX9NB",
    "name": "You're All I Need To Get By",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/11020615525a7f592705a2c904acc3b46af5dcbc",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1968
  },
  {
    "song_id": "3Cqr3l72XDpazdFiQQpFHh",
    "name": "Shoo-Be-Doo-Be-Doo-Da-Day",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/6943f544502cb4aa6eb34d0cacb899d3a8599c5f",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/fa14913949db5530c43fc514678d509de682d15b",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1968
  },
  {
    "song_id": "5DBEFajBEaHgbbwe7oN0KP",
    "name": "MacArthur Park",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/6561d3964bea04faa0de7606e5279fe373df212c",
    "artist_name": "Richard Harris",
    "image_url": "https://i.scdn.co/image/0eccae07fe8e7f244d8fc7c34c599fc977ddcce3",
    "artist_id": "30Qh39o424P9qgdHNVouvo",
    "year": 1968
  },
  {
    "song_id": "3UbLA9KRShoSsl8hfEfrFm",
    "name": "Fire",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/f3f53a6005b62155767f59cd253efa78282b975f",
    "artist_name": "The Crazy World Of Arthur Brown",
    "image_url": "https://i.scdn.co/image/fd63a7908b5ce538af0fabb3b365d16ae49118b9",
    "artist_id": "2LpdhgwN0mYKMjnzrBQU8H",
    "year": 1968
  },
  {
    "song_id": "0QvMXCVPXSSWx2NB9ewx6O",
    "name": "Judy In Disguise (With Glasses)",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/c5117807570de3c7c3800e4cba0e5bf06ed054bf",
    "artist_name": "John Fred & His Playboy Band",
    "image_url": "https://i.scdn.co/image/a504b070b2f9672261c49618256ad5745b34ca82",
    "artist_id": "2woGum3OHHSQl0YK54jQKL",
    "year": 1968
  },
  {
    "song_id": "5R8wmxrkKO6Kzd8HKiZYfb",
    "name": "La-La Means I Love You - Remastered",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/8ab205b4b3faf777403d82a9b8f44f5f5dc0a4d4",
    "artist_name": "The Delfonics",
    "image_url": "https://i.scdn.co/image/1ba98427d690311c5ace752b2839622f17f0d0d9",
    "artist_id": "6YPRXu1dazGYcSZv4HJEH4",
    "year": 1968
  },
  {
    "song_id": "021O8Fvrww2EJB8220tgXo",
    "name": "Valley Of The Dolls [Theme From]",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/8c45f2c3b9e9b777ed07e2612c37b4082b33dcf2",
    "artist_name": "Dionne Warwick",
    "image_url": "https://i.scdn.co/image/9454c74e2f3dbde8a1931d29dd341542f8e7e935",
    "artist_id": "2JSjCHK79gdaiPWdKiNUNp",
    "year": 1968
  },
  {
    "song_id": "6w8Pnj2aUSJysc0b5FSJyr",
    "name": "Turn Around And Look At Me",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/d23bb1124b7f9b5d45a4196d46bbb5f4b754061e",
    "artist_name": "The Vogues",
    "image_url": "https://i.scdn.co/image/34491b711cd8c0080607c372e7384eb2970599a8",
    "artist_id": "0Ma8KxtvoIDSU5fHtuTS9F",
    "year": 1968
  },
  {
    "song_id": "6FUoJcJUk9s70ReCcrcgPB",
    "name": "The Horse",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/fb262684c2ca3f2ac42ba310aea34dcbf308395b",
    "artist_name": "Cliff Nobles",
    "image_url": "https://i.scdn.co/image/779f4b2f607d53797cfe4c1e0e8ae708f03da113",
    "artist_id": "0i0ELbteoVYQDbSNCU3Spz",
    "year": 1968
  },
  {
    "song_id": "49oQUbmhXqjGb850ERxtfb",
    "name": "People Got To Be Free",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/e492299a8f676bd7af719a4101e1fd5d6b446b9e",
    "artist_name": "The Rascals",
    "image_url": "https://i.scdn.co/image/8cc030355b00f419ec0f9dc19834ec97946d9317",
    "artist_id": "5g2n2H9ndqvejERtwZ1rHh",
    "year": 1968
  },
  {
    "song_id": "3pKWkyMFrQ6lcuGvMRdAzM",
    "name": "Never Give You Up",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/6c53bd59b53dc7e14d222554da13f60d2e218e02",
    "artist_name": "Jerry Butler",
    "image_url": "https://i.scdn.co/image/a9055906743a66fa577d4887a924b4ed0a098acd",
    "artist_id": "0U2mUJuJkthantMGmsJygP",
    "year": 1968
  },
  {
    "song_id": "0wegjSmMmGyIt8hr0KCu9u",
    "name": "Bottle Of Wine",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/c9232f170613f3b514ed9dce0fa19a08ee7c6a22",
    "artist_name": "The Fireballs",
    "image_url": "https://i.scdn.co/image/d52bb35e5ef4967587421aeb5556a2565a74c442",
    "artist_id": "6Rboxwjnvu3wLcwzhlnaSO",
    "year": 1968
  },
  {
    "song_id": "7J8A3XOuGFnGheoSsHUXC8",
    "name": "Summertime Blues",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/11f047f44689bd88013a2e77cee4b14ed4f14194",
    "artist_name": "Blue Cheer",
    "image_url": "https://i.scdn.co/image/ec413dae277fab0242be101fa4a07f1560f55c3b",
    "artist_id": "4PI5IHRvANLL76O5gRmGKL",
    "year": 1968
  },
  {
    "song_id": "4H9lE5mGRGOIAjgqQURUZ8",
    "name": "Love Is All Around",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/d5d073e6564665702c8a57f7cd9461e86f235576",
    "artist_name": "The Troggs",
    "image_url": "https://i.scdn.co/image/ea66116de7054f9439c1c506e1d166d9e84d87d5",
    "artist_id": "57xdnSVt4ahJCIXYLieQ25",
    "year": 1968
  },
  {
    "song_id": "3Wi99hBzoortggCY1LNlBu",
    "name": "Love Is Blue - Instrumental",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/73cbb79bdec4c98631c8fee272d338b4d56485ed",
    "artist_name": "Paul Mauriat",
    "image_url": "https://i.scdn.co/image/9139e6500af5ff1f5d42af4016614f4dddef820e",
    "artist_id": "5Oj26LEf8jdgR2woWEjlhi",
    "year": 1968
  },
  {
    "song_id": "2RwX6KYnLvfvgZ2gn8rhnc",
    "name": "Sweet Inspiration",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/533ffd6d0f3c7e46ff987a2da8958f75f202a454",
    "artist_name": "The Sweet Inspirations",
    "image_url": "https://i.scdn.co/image/2055b7c4a264b662aac565e91b8a8942f655914a",
    "artist_id": "1wonEkTZkBGYq7J4w56yZl",
    "year": 1968
  },
  {
    "song_id": "2ChhVPnOgHV9UmSuso8S9v",
    "name": "I Wonder What She's Doing Tonight?",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/de98372989d59371cc42620b096c85ba9ae4a914",
    "artist_name": "Tommy Boyce",
    "image_url": "https://i.scdn.co/image/f233c08e0abf1b985ac14d5c02d3b0b40a558016",
    "artist_id": "6V92xGMFMr4619y6ZvEUFR",
    "year": 1968
  },
  {
    "song_id": "4c4jJJoaiY21t2TyRZgdWS",
    "name": "Magic Carpet Ride",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/e7be586e842f063c0a98762e5430400db1724896",
    "artist_name": "Steppenwolf",
    "image_url": "https://i.scdn.co/image/917decc9601a7317c88dc866ab885273ba8e3da0",
    "artist_id": "1WRM9i067hd2ujxxi8FI3m",
    "year": 1968
  },
  {
    "song_id": "1dR5c8v7k3Lo5Ns9nQ2ZJX",
    "name": "I've Gotta Get A Message To You (Remastered",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/88192308f95c8d08bcb77406c75c574af2d0ab07",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/7aaae245cdc7776d2e6cbe1296f81233a58f454f",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1968
  },
  {
    "song_id": "2guNE1sLf9pqpJbAg0LdxI",
    "name": "Goin' Out Of My Head / Can't Take My Eyes Off You",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/864523be2ba5847cc725c924545b8327d09676d5",
    "artist_name": "The Lettermen",
    "image_url": "https://i.scdn.co/image/ef861157b35c47da8c9f5794efe9b3830eecb8dd",
    "artist_id": "3lzlUccNrekC1oFPCjNIOX",
    "year": 1968
  },
  {
    "song_id": "6EpmEI4a3uRmA8jgBIPH6o",
    "name": "Indian Lake",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/9fdbbe91acd32f7d1864a9f1140804a1317c99d5",
    "artist_name": "The Cowsills",
    "image_url": "https://i.scdn.co/image/229c95f0c22b39865aa7a1381ec1ab43dfd28e31",
    "artist_id": "4ZSzroBNV7HzBDO9aohuF1",
    "year": 1968
  },
  {
    "song_id": "3lrSnngQqNWgvQmcFbCJCx",
    "name": "Since You've Been Gone [Sweet Sweet Baby]",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/2c0d36d7e5c60929435835b920cf5fa84aaf5313",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/35622ad48bd90dd79567bdb8211e5c635602b621",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1968
  },
  {
    "song_id": "0E6J02Ryj90JyAizw9BKUo",
    "name": "Hold Me Tight",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/639ca2214169bc52b4ec640452d3e41e121db505",
    "artist_name": "Johnny Nash",
    "image_url": "https://i.scdn.co/image/9cabeee1d4f633530c54739f35c641fcc8c6f70f",
    "artist_id": "0nAVvgfE9yI4DwvMiYwk8a",
    "year": 1968
  },
  {
    "song_id": "3UPWHgwMFfpE7dIWubNIXr",
    "name": "I Got The Feelin'",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/95ca7d172ff8668e1844fd91adc2ee6e4e6b63b1",
    "artist_name": "James Brown",
    "image_url": "https://i.scdn.co/image/fa109b29941ee03823807eba4da33f61b376ee56",
    "artist_id": "7GaxyUddsPok8BuhxN6OUW",
    "year": 1968
  },
  {
    "song_id": "6QeXqr6JQInV8Q8WDndmBn",
    "name": "The Look Of Love",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/aacb73be3f9b3a38867248fc31d054e3332843bc",
    "artist_name": "Sergio Mendes & Brasil '66",
    "image_url": "https://i.scdn.co/image/9aef20852712e49811ec320b343b4e380655f600",
    "artist_id": "6hCsqVHnBo1BVQWuIjRMkL",
    "year": 1968
  },
  {
    "song_id": "0LR9CiGD7i23YSAKWH81Iw",
    "name": "A Beautiful Morning",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/4a1adb04a0a36198b5f41375cd928fcd6592b344",
    "artist_name": "The Rascals",
    "image_url": "https://i.scdn.co/image/8cc030355b00f419ec0f9dc19834ec97946d9317",
    "artist_id": "5g2n2H9ndqvejERtwZ1rHh",
    "year": 1968
  },
  {
    "song_id": "4CESHPybC1vvkHODxRGRu8",
    "name": "Stay In My Corner - 1969 Version",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/5e0d5cb4b6f288c4c61a5e0c887222307a4a4183",
    "artist_name": "The Dells",
    "image_url": "https://i.scdn.co/image/02b72fdd5b94bc0439829b48171a8f91a810fd8e",
    "artist_id": "0wbrRIw2po5DV4LeaHw55S",
    "year": 1968
  },
  {
    "song_id": "6HvRHu2HtWgE720gG5v3wE",
    "name": "The Unicorn",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/cad96c2ad289885419d87abb7d54f4996d6bcf18",
    "artist_name": "The Irish Rovers",
    "image_url": "https://i.scdn.co/image/daae9b7a1b05c40274f33428376015a1b3b15093",
    "artist_id": "0tkKwWigaADLYB9HdFCjYo",
    "year": 1968
  },
  {
    "song_id": "23OBcAyQonpaJ2XswR0k1I",
    "name": "Stoned Soul Picnic - Remastered 2000",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/baed2865a5a05bd85eb497c179047d7cbefee50f",
    "artist_name": "The 5th Dimension",
    "image_url": "https://i.scdn.co/image/3472d9e1785e959fe32276708f1bf29d06596d11",
    "artist_id": "1UUYAQ9LiRsZF0ZukQNWXM",
    "year": 1968
  },
  {
    "song_id": "6ltEROUPq7bgFLdivUcr5v",
    "name": "Grazing In The Grass",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/e4c76c2a2eeb9d16abfa4703dcfae73fff31e254",
    "artist_name": "Hugh Masekela",
    "image_url": "https://i.scdn.co/image/867ec1d2dafaf933a5def712793523360d069f3b",
    "artist_id": "1b3F5FI7TX4IWTNA4P1kWp",
    "year": 1968
  },
  {
    "song_id": "6wMAYq5Lq08tN084lOKWge",
    "name": "Cab Driver - Single Version",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/7a5d46e42e1a28afb472e2594bf5f847264c47e6",
    "artist_name": "The Mills Brothers",
    "image_url": "https://i.scdn.co/image/6fab8c74221e071a7181a98c52e19c810f118455",
    "artist_id": "5v8bwWRbu7AI3YFhzoM6Ha",
    "year": 1968
  },
  {
    "song_id": "6iKEGqAbhkoi5ZwECKQ0nG",
    "name": "(I'm A) Girl Watcher",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/fbafed91f6418e8d360cbfae9b9cea4fd87abbac",
    "artist_name": "The O'Kaysions",
    "image_url": "https://i.scdn.co/image/2cf906b38416879f757fde86105d6a78b738db73",
    "artist_id": "1LewRrFZKW8ktXwW01njJL",
    "year": 1968
  },
  {
    "song_id": "470j7INFa1OlpSABpxwYxE",
    "name": "The Fool On The Hill",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/06542dd7b4a5dd0bc5fc784c24d163dcbd23c1c7",
    "artist_name": "Sergio Mendes & Brasil '66",
    "image_url": "https://i.scdn.co/image/dc8a2c9d225a9e02da53c7b1baaf6120c3ff21c6",
    "artist_id": "6hCsqVHnBo1BVQWuIjRMkL",
    "year": 1968
  },
  {
    "song_id": "19OJmK6gkOsRvB9Ei9SqEm",
    "name": "I Wish It Would Rain - Single Version (Stereo)",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/614043f0f262b35f43fabb61101e5f5f09cd7123",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/58b73d698540869a8e16c2d5d568fa627967cb7f",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1968
  },
  {
    "song_id": "0oN8WHFUJhYITm56B41zJZ",
    "name": "Baby, Now That I've Found You",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/7ae05011969191e578ca697ead235051e992d332",
    "artist_name": "The Foundations",
    "image_url": "https://i.scdn.co/image/d88a4ca3400c30580b648e0888625b2c38ee1395",
    "artist_id": "4GITZM5LCR2KcdlgEOrNLD",
    "year": 1968
  },
  {
    "song_id": "60dJjWzpfa3G52kKCM0j03",
    "name": "Slip Away",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/c068eeb1b1bf7d9c17dad8f802e4941d410da5f9",
    "artist_name": "Clarence Carter",
    "image_url": "https://i.scdn.co/image/8b737b0aadeaa363d84176a0f43ce5f306f1233b",
    "artist_id": "7lffJlv0nRl0sIsHDmo0SB",
    "year": 1968
  },
  {
    "song_id": "24jf8jCLHcr4xgjPCHhy5c",
    "name": "Simon Says - Buddha Remastered 2001",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/0337315515115a0b825ace817c53b1164574d73a",
    "artist_name": "1910 Fruitgum Company",
    "image_url": "https://i.scdn.co/image/9eaf22b573c24b9407d23632a45a4d1237dcddef",
    "artist_id": "17vIaBTHJOVxnU4v7t1DiY",
    "year": 1968
  },
  {
    "song_id": "4H9mGMb8W64u2IkcomqXwx",
    "name": "Pictures Of Matchstick Men",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/eafca7c1f79f98a775ee30bc045a4b794588680d",
    "artist_name": "Status Quo",
    "image_url": "https://i.scdn.co/image/78a41c65a2040b6c9f670fa4081229134b2a4c1e",
    "artist_id": "4gIdjgLlvgEOz7MexDZzpM",
    "year": 1968
  },
  {
    "song_id": "23yIke04RDR2pSYN5AYeub",
    "name": "Sky Pilot",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/713b2481e47a26eee3d22b3ac74c92dbeeaa0397",
    "artist_name": "Eric Burdon & the Animals",
    "image_url": "https://i.scdn.co/image/928056ee0873e62529f5d0a0beab75994432b40c",
    "artist_id": "70tMW5bZT3TJUa1CWtfVkq",
    "year": 1968
  },
  {
    "song_id": "6o5RmKNHq4mxrAbe7nniyB",
    "name": "Light My Fire",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/c47c0948e6d09e0edded9a3a0c816c95de747efc",
    "artist_name": "Jose Feliciano",
    "image_url": "https://i.scdn.co/image/d6ed80cc6c6d33dac233161588d72e203e901166",
    "artist_id": "7K78lVZ8XzkjfRSI7570FF",
    "year": 1968
  },
  {
    "song_id": "2fZH4MHglje9uoS8NrG5ox",
    "name": "The Ballad Of Bonnie & Clyde",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/65fe843999dde844d59f30872c92b423f91f36d2",
    "artist_name": "Georgie Fame",
    "image_url": "https://i.scdn.co/image/991e9646e293d5c273f8bb7b6bf943a6aef75b61",
    "artist_id": "5rWKAmlxinr3muqedXVIHa",
    "year": 1968
  },
  {
    "song_id": "5yuRHQKaFodVrsnZu6M4wX",
    "name": "Hey, Western Union Man",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/93909a8b46ce4b2ceaf8a63d5d33fe855d4b23fa",
    "artist_name": "Jerry Butler",
    "image_url": "https://i.scdn.co/image/a9055906743a66fa577d4887a924b4ed0a098acd",
    "artist_id": "0U2mUJuJkthantMGmsJygP",
    "year": 1968
  },
  {
    "song_id": "11DtxbS95J9CiVLSZhrYK1",
    "name": "Here Comes The Judge",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/316d58db47cfb8b96fdb5c0532a926ca7fa24e09",
    "artist_name": "Shorty Long",
    "image_url": "https://i.scdn.co/image/f53979f6be994a07265587e6d8918883c503c0fa",
    "artist_id": "25ekfEqwias2rDScydpjYF",
    "year": 1968
  },
  {
    "song_id": "5DWWchCTeaZD14wKkDJelG",
    "name": "I Thank You - LP/Single Version",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/98640f2dbfa8782dfc23e5d9345111ee2dbd4b54",
    "artist_name": "Sam & Dave",
    "image_url": "https://i.scdn.co/image/9157e38f3fe99e3291710b426d1e25611e378929",
    "artist_id": "2BVYdY4PyfCF9z4NrkhEB2",
    "year": 1968
  },
  {
    "song_id": "6QLyAYLZLLbgczRU744rFe",
    "name": "Lady Willpower",
    "popularity": 7,
    "preview_url": "https://p.scdn.co/mp3-preview/e358084abb9f0da44586f590ca96e5ecb43c5c3a",
    "artist_name": "Gary Puckett & The Union Gap",
    "image_url": "https://i.scdn.co/image/20cc05fc60001b2f0bb59d4b09767c03fdad792d",
    "artist_id": "4asCC4oxQcDzFXhCth2SgQ",
    "year": 1968
  },
  {
    "song_id": "7wxEkW7e9cP94WQMnvUBQ7",
    "name": "Sealed With A Kiss - 2004 Digital Remaster",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/b0d27de51e5d68438081f6def047ed7eae25f22b",
    "artist_name": "Gary Lewis & The Playboys",
    "image_url": "https://i.scdn.co/image/bc4886ff937c7607b3fc678d5949e2f554424d57",
    "artist_id": "0XTxadWXVyfB8eTIYOjMYj",
    "year": 1968
  },
  {
    "song_id": "5Q9KlUe0QBKu4o3jMmreCW",
    "name": "Playboy",
    "popularity": 3,
    "preview_url": "https://p.scdn.co/mp3-preview/e0845dfde22f4aa86fda3f90ed97caa4ab3e386a",
    "artist_name": "Debbe Neville",
    "image_url": "https://i.scdn.co/image/7786fb2898c62a2237553ec615d674713999ebcc",
    "artist_id": "41Pa9pZzonAEAjBtsP8LRW",
    "year": 1968
  },
  {
    "song_id": "0pBsfWKhJcw8Zuquanq9kT",
    "name": "Love Child - Single Version (Mono)",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/a8afa2163991ccc48e49ecba178f3a1c372de3a7",
    "artist_name": "Diana Ross & The Supremes",
    "image_url": "https://i.scdn.co/image/3109de8c4972ab33a982a66282675abb7c25a71e",
    "artist_id": "0rXI0q8Cahq6numvPlloaq",
    "year": 1968
  },
  {
    "song_id": "4ICNEM8rDwadCWaANHVdYq",
    "name": "If You Can Want",
    "popularity": 0,
    "preview_url": "https://p.scdn.co/mp3-preview/fe6a6bf46a5865351cd1c92f0ad9a1f04fd838d6",
    "artist_name": "Smokey Robinson & The Miracles",
    "image_url": "https://i.scdn.co/image/73fb68f6eec3e875f6d13c40adf7e15f6d0360b4",
    "artist_id": "6TKOZZDd5uV5KnyC5G4MUt",
    "year": 1968
  },
  {
    "song_id": "4uYwlMp841PLJmj1gJJwIq",
    "name": "Lay, Lady, Lay",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/eeb2c90b8fb59f657a400761b062016848a44dab",
    "artist_name": "Bob Dylan",
    "image_url": "https://i.scdn.co/image/d3eb4c3b4e7a297755772ecccbb4a1157d3187de",
    "artist_id": "74ASZWbe4lXaubB36ztrGX",
    "year": 1969
  },
  {
    "song_id": "4GAI8BxeMgrvhZNEU59UfR",
    "name": "Sugar, Sugar",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/4af7ff6cbb2a5cf236ec70d20f391d092bb6f7b2",
    "artist_name": "The Archies",
    "image_url": "https://i.scdn.co/image/a9e481c53f18f914730052912b00d48af3876625",
    "artist_id": "33QmoCkSqADuQEtMCysYLh",
    "year": 1969
  },
  {
    "song_id": "3RZMzCvYsmJ0u2ioKTOsmJ",
    "name": "Honky Tonk Women",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/ff90eeb285afa89da5b8a53175c0b262c935beba",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/d9c974ac4ea17f450023c262938dbb41e07d97dd",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1969
  },
  {
    "song_id": "6ZSTinOwx5dKFYc6iYyGDn",
    "name": "Leaving On A Jet Plane",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/47cbe621ab1c10b5cf59a1d79111981ae9e553ef",
    "artist_name": "Peter, Paul and Mary",
    "image_url": "https://i.scdn.co/image/8c8987c3dd285e750b605027aa300abc5d075f7f",
    "artist_id": "6yrBBtqX2gKCHCrZOYBDrB",
    "year": 1969
  },
  {
    "song_id": "5akBpqWmFWEHoBGRIrR5aK",
    "name": "Sweet Caroline",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/e146e5c77e4cce9615820c6b6606aa1c54985b58",
    "artist_name": "Neil Diamond",
    "image_url": "https://i.scdn.co/image/4479f1db678aa5a4bbab7709bea0b1c71de4581c",
    "artist_id": "7mEIug7XUlQHikrFxjTWes",
    "year": 1969
  },
  {
    "song_id": "3iwujDVtiJQwqbvjVS6LZV",
    "name": "Build Me Up Buttercup",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/410a9618061dfe14592eb197ea490b933642ea84",
    "artist_name": "The Foundations",
    "image_url": "https://i.scdn.co/image/199adfe393ab78769035e81e0f0cbf965f29271e",
    "artist_id": "4GITZM5LCR2KcdlgEOrNLD",
    "year": 1969
  },
  {
    "song_id": "4BVE5tIIpYabZcYCbephNW",
    "name": "Touch Me",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/444ffe6fb25e35d9726dec9457fd7c2a2286679a",
    "artist_name": "The Doors",
    "image_url": "https://i.scdn.co/image/c973bf9bfd2c4dee218780e6585fe0ab9655ebdb",
    "artist_id": "22WZ7M8sxp5THdruNY3gXt",
    "year": 1969
  },
  {
    "song_id": "1ADAOmHYyKwwBbXYINniwa",
    "name": "The Boxer",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/ef618d09eafb4d2fbf239d26386a6bdcab24137f",
    "artist_name": "Simon & Garfunkel",
    "image_url": "https://i.scdn.co/image/314041c14c76e6d486a09d3cbc1d70850c3b5c31",
    "artist_id": "70cRZdQywnSFp9pnc2WTCE",
    "year": 1969
  },
  {
    "song_id": "0HZlND4giwzgolBpaNIRGV",
    "name": "Aquarius/Let The Sunshine In (The Flesh Failures) - (from the American Tribal Love Rock Musical \"Hair\") - Remastered 2000",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/666c864923051e5dbcf0500905d17aaf874232c3",
    "artist_name": "The 5th Dimension",
    "image_url": "https://i.scdn.co/image/037cf365f875016fd01ae7a68f6c0e124981bed0",
    "artist_id": "1UUYAQ9LiRsZF0ZukQNWXM",
    "year": 1969
  },
  {
    "song_id": "4ZVZBc5xvMyV3WzWktn8i7",
    "name": "Everyday People - Single Version",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/142b246c04255d613d695f8a86c09ce41a0b7a02",
    "artist_name": "Sly & The Family Stone",
    "image_url": "https://i.scdn.co/image/178278f98ca9e59338bcbab3620ce8755ca78ec5",
    "artist_id": "5m8H6zSadhu1j9Yi04VLqD",
    "year": 1969
  },
  {
    "song_id": "6V9VCm1zOY2lGR80RehJ9i",
    "name": "Wichita Lineman - 2001 Digital Remaster",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/081a83a2e00c15ef72764950a1e22c5e7f65b9a7",
    "artist_name": "Glen Campbell",
    "image_url": "https://i.scdn.co/image/b584aa9a23a9ccc49179bdeb278e011cb287d6c6",
    "artist_id": "59hLmB5DrdihCYtNeFeW1U",
    "year": 1969
  },
  {
    "song_id": "6DBdd6oYkrNFXAuZwXXBQo",
    "name": "Baby It's You",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/ecc5add96c8bd64ad977f9c1d90e20801517338a",
    "artist_name": "Smith",
    "image_url": "https://i.scdn.co/image/c292ce3256e5512f2f372f83763b997d7075b74c",
    "artist_id": "5Wcc9ojw03wJUXhgkToSD6",
    "year": 1969
  },
  {
    "song_id": "4qxsM71CUQmCD5eRIJIqpy",
    "name": "Get Together",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/bc27c9cc6edd04fc136867358972b3d2cb9a9f2e",
    "artist_name": "The Youngbloods",
    "image_url": "https://i.scdn.co/image/fc4045d5d97d5c3e23b8c4779ddd8be7ea492731",
    "artist_id": "5I6MzhNEMk27cZsCqGAIYo",
    "year": 1969
  },
  {
    "song_id": "6oXkUmLOdmsEQS4AlEIKZ8",
    "name": "Galveston",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/396acb29128f69d81859470d1da5142985531181",
    "artist_name": "Glen Campbell",
    "image_url": "https://i.scdn.co/image/1710079df4e28eef5f991ccbefbabf6330c55841",
    "artist_id": "59hLmB5DrdihCYtNeFeW1U",
    "year": 1969
  },
  {
    "song_id": "5ZoDwIP1ntHwciLjydJ8X2",
    "name": "My Cherie Amour",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/f7c09692db27d3043aa2e9866ae6ac1b4ef4e498",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/a1d374a511849696e38439dd89d92b19bc0aa854",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1969
  },
  {
    "song_id": "0l9RGwc48deJ6j61KXAAvs",
    "name": "Yester-Me, Yester-You, Yesterday",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/ea1ca4d1ad5ee8256eeee5759be8f0b8873a79cc",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/a1d374a511849696e38439dd89d92b19bc0aa854",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1969
  },
  {
    "song_id": "4t6NQkpUmvpvLDCkl3XuwA",
    "name": "Hot Fun in the Summertime - single master",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/01d7958f4c3c5fda620debdaa7055b03826160ef",
    "artist_name": "Sly & The Family Stone",
    "image_url": "https://i.scdn.co/image/92856482290683a68b4550b5fae0624bb36c1654",
    "artist_id": "5m8H6zSadhu1j9Yi04VLqD",
    "year": 1969
  },
  {
    "song_id": "1VL7DLieQaRCjAEj0Kdzju",
    "name": "You've Made Me so Very Happy",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/354147822084280427965a3162c5de617ea0481d",
    "artist_name": "Blood, Sweat & Tears",
    "image_url": "https://i.scdn.co/image/e7bbf7143d358012474d0fc7e12ec982731fd7e5",
    "artist_id": "24GaH9tRBgZjlvOhpFuKi2",
    "year": 1969
  },
  {
    "song_id": "4RDpsuQe1PVYHKcRPbDdNE",
    "name": "In The Year 2525 (Exordium & Terminus)",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/c5bec05c429a57ca3f129e8a95fa271035598c49",
    "artist_name": "Zager & Evans",
    "image_url": "https://i.scdn.co/image/9eded5340b82877bc10e0ffcf476a1a11accf0cd",
    "artist_id": "55IyYO6fmYpYw6Nd4YF7bw",
    "year": 1969
  },
  {
    "song_id": "1mrSHPNTDdMkXpR4BiSiD7",
    "name": "Na Na Hey Hey Kiss Him Goodbye",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/30a0626ba3e0d04e6e75b5afb9d067a7be664826",
    "artist_name": "Steam",
    "image_url": "https://i.scdn.co/image/517849070de1b127323c7b920a9b4bd321507135",
    "artist_id": "5OQw3l31WXt1A3QihyjMmO",
    "year": 1969
  },
  {
    "song_id": "0bzNvVatHKKtAudmRvar8J",
    "name": "Wedding Bell Blues - Remastered 2000",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/53e99d188102e923946b0b4a10f4e875cf6a2e1a",
    "artist_name": "The 5th Dimension",
    "image_url": "https://i.scdn.co/image/037cf365f875016fd01ae7a68f6c0e124981bed0",
    "artist_id": "1UUYAQ9LiRsZF0ZukQNWXM",
    "year": 1969
  },
  {
    "song_id": "5I0LjHnBauh4v2wA65hheV",
    "name": "(It Looks Like) I'll Never Fall In Love Again",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/91ccd93dbe1917511a496e5fe9059fce11ce4374",
    "artist_name": "Tom Jones",
    "image_url": "https://i.scdn.co/image/49edcc833f44dc373aa88a97ea593904c7774145",
    "artist_id": "1T0wRBO0CK0vK8ouUMqEl5",
    "year": 1969
  },
  {
    "song_id": "5kMqaxZtS8G34laCJBaoRA",
    "name": "And When I Die",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/1c0d3e280e7a06c7b2962a39b11a6c68b974b80e",
    "artist_name": "Blood, Sweat & Tears",
    "image_url": "https://i.scdn.co/image/e7bbf7143d358012474d0fc7e12ec982731fd7e5",
    "artist_id": "24GaH9tRBgZjlvOhpFuKi2",
    "year": 1969
  },
  {
    "song_id": "6V0CTPutuWYboT5DrAoaK5",
    "name": "In the Ghetto",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/f89a0e00d49d6a131cdcfc5ae4c9f9b818495035",
    "artist_name": "Elvis Presley",
    "image_url": "https://i.scdn.co/image/704f1b6267e5f2eaaabca8fb31df87c3183b4d73",
    "artist_id": "43ZHCT0cAZBISjO8DG9PnE",
    "year": 1969
  },
  {
    "song_id": "75CoJdGKHEK30cxTwRNTu3",
    "name": "Backfield In Motion",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/9f1666150f20d7ca5ff7d4471d88e0fca9ab24e3",
    "artist_name": "Mel & Tim",
    "image_url": "https://i.scdn.co/image/9b0f95aae1d7eb36c399934d1b694885638444a8",
    "artist_id": "2ubLClBEuddw29m7QRx4IL",
    "year": 1969
  },
  {
    "song_id": "70V9otkdkxxiRjrdiHk6CO",
    "name": "Take A Letter, Maria",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/a9bc9ad75967713483931c7c818b5f936a32c8a1",
    "artist_name": "R.B. Greaves",
    "image_url": "https://i.scdn.co/image/1771745eff84dfb29e2787eac0105b1114a5ec55",
    "artist_id": "1BdNzKPyPDOhHdFhvwytQt",
    "year": 1969
  },
  {
    "song_id": "2XZEAYYRMBvkY7c2Z8YGox",
    "name": "Easy To Be Hard",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/6919b798f26a8e0aa088b6ef9959d594bd52a1ce",
    "artist_name": "Three Dog Night",
    "image_url": "https://i.scdn.co/image/eb3ae545dd7525deac3c562a339c8b45dead39ea",
    "artist_id": "4FAEZeJcsYYBkNq2D3KGTV",
    "year": 1969
  },
  {
    "song_id": "6O7Sx0AWKrwi3SYvPJxMif",
    "name": "Runaway Child, Running Wild",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/a321ba4f03127c5731505bc8115a085a4dc4a12d",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1969
  },
  {
    "song_id": "1A8gqfrso63AC4XVW6DTTw",
    "name": "Cloud Nine",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/d17774262cefc8a5c2ad386267b5f2038db3fc96",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/90d38673d061e0da68aca3fc68f86059a0aaff45",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1969
  },
  {
    "song_id": "2qdOF2Sr8fo8katboDtK1K",
    "name": "Hooked On A Feeling",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/5b9157b5c06e702e3f3d8bebab3de0552caaa35f",
    "artist_name": "B.J. Thomas",
    "image_url": "https://i.scdn.co/image/133041b653c9a0b6ca8af3b017da711f4f3f3bba",
    "artist_id": "0uUNzXylqsZdmFDwdxaP1V",
    "year": 1969
  },
  {
    "song_id": "0FYprG7MWPHLnmxfhWRH90",
    "name": "Atlantis",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/a04596111ea3980778d305ac69b141bf88e2d689",
    "artist_name": "Donovan",
    "image_url": "https://i.scdn.co/image/30825e8bac50442d21572852b76e4c790e3c45c1",
    "artist_id": "6vLlQYujOujIrm7zAKzEdG",
    "year": 1969
  },
  {
    "song_id": "4FmTzEfNlGfeFfPQND2ezc",
    "name": "This Girl's In Love With You",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/0bb364687d4b4ee07f1985f882eb00da66d33314",
    "artist_name": "Dionne Warwick",
    "image_url": "https://i.scdn.co/image/ba0b87366483c6e680a42b947076307c5304bc53",
    "artist_id": "2JSjCHK79gdaiPWdKiNUNp",
    "year": 1969
  },
  {
    "song_id": "3MVr7KeWF6kf4znJMwiEnx",
    "name": "This Magic Moment",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/0333fe42f3663b60da154c4f2d272496420cf3bb",
    "artist_name": "Jay & The Americans",
    "image_url": "https://i.scdn.co/image/49857960f7195ec174601b009231aab07ce22790",
    "artist_id": "0DAqhikcMKLo2lPADVz2fs",
    "year": 1969
  },
  {
    "song_id": "2AhGWwkn71UUTDaQmqqxyw",
    "name": "Spinning Wheel",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/dc3c4f5b2b094273238416cdb2c96c0f727e0ea6",
    "artist_name": "Blood, Sweat & Tears",
    "image_url": "https://i.scdn.co/image/e7bbf7143d358012474d0fc7e12ec982731fd7e5",
    "artist_id": "24GaH9tRBgZjlvOhpFuKi2",
    "year": 1969
  },
  {
    "song_id": "5dQNUHOpwvbByWP2NrkLOT",
    "name": "Baby I'm For Real",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/4f8d037d72bce93fd6a7de825259b6d8e16b8b8d",
    "artist_name": "Originals",
    "image_url": "https://i.scdn.co/image/7f2e466894ed4cd05aa750e55f45695a561a814d",
    "artist_id": "7IS8q1fS0bQC9ptsR83sg3",
    "year": 1969
  },
  {
    "song_id": "6hGB6Qz1l44cmtxguWMATs",
    "name": "Color Him Father",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/c4f8df36cbef5f819073fcba87b6e1bd9aed9cbc",
    "artist_name": "The Winstons",
    "image_url": "https://i.scdn.co/image/1a0a109ce35617d1e00cb84e7ff03b044dfa27b4",
    "artist_id": "1VePrTOSgCLcaBecBs7i2q",
    "year": 1969
  },
  {
    "song_id": "5RlTopKNMnGMgFrvREcKgL",
    "name": "Love Can Make You Happy",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/891bafec9e4d5d599a7693d283e81172047c4b34",
    "artist_name": "Mercy",
    "image_url": "https://i.scdn.co/image/84790cfc69ce5ebee4476c4f34d0881df4d47504",
    "artist_id": "4958loju8eTBhBFO32oWQ6",
    "year": 1969
  },
  {
    "song_id": "0geNZHATvMePbsyGJiFYp1",
    "name": "Gitarzan",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/be828b5fb449f9cc8476942baf3db12beb76d45e",
    "artist_name": "Ray Stevens",
    "image_url": "https://i.scdn.co/image/ca96f4f6c935329b90757d965dfa794336cf4321",
    "artist_id": "7MpUvihmfilIxyN20kXwQj",
    "year": 1969
  },
  {
    "song_id": "1A3Kj72tcsAb18AuuqXnt7",
    "name": "Grazing in the Grass",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/e05e48b704c850ffb4329da131d760da8b8734ff",
    "artist_name": "The Friends Of Distinction",
    "image_url": "https://i.scdn.co/image/e8c941bf7dc180bd5bbefffe55f747db802cfd7e",
    "artist_id": "2KK4fR5VwOjGzJxjbGxMIu",
    "year": 1969
  },
  {
    "song_id": "31oeQLsLKmZq7X7aeHpTqO",
    "name": "Indian Giver",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/fb656e50015c4da0ff06523790f96a08dfcba3e2",
    "artist_name": "1910 Fruitgum Company",
    "image_url": "https://i.scdn.co/image/960cadb189e7aed5891062b597edcd9009c2061d",
    "artist_id": "17vIaBTHJOVxnU4v7t1DiY",
    "year": 1969
  },
  {
    "song_id": "3nctXo8vzOUYtAlDn5iVEZ",
    "name": "Baby, Baby Don't Cry - Album Version (Stereo)",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/4289ce206bb3e213354cb960b9ea307aa35ff524",
    "artist_name": "Smokey Robinson & The Miracles",
    "image_url": "https://i.scdn.co/image/e045806c7c8db267bbbfa65586c228ee68856b43",
    "artist_id": "6TKOZZDd5uV5KnyC5G4MUt",
    "year": 1969
  },
  {
    "song_id": "6NWD6siQErobvmydHcmhCw",
    "name": "Crystal Blue Persuasion",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/9305ab12cc30a27a8a618d28629b96a3a667db89",
    "artist_name": "Tommy James",
    "image_url": "https://i.scdn.co/image/1bb8e7ce5c5dd474a2407c63fe3468d66cc46513",
    "artist_id": "7eRORqxEbt7ftbaaWP0k5v",
    "year": 1969
  },
  {
    "song_id": "0gxJWxSu6k1zrMezMf5Hdw",
    "name": "Going In Circles",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/517f2869eea12f6864f204376903d93c33d7433a",
    "artist_name": "The Friends Of Distinction",
    "image_url": "https://i.scdn.co/image/e8c941bf7dc180bd5bbefffe55f747db802cfd7e",
    "artist_id": "2KK4fR5VwOjGzJxjbGxMIu",
    "year": 1969
  },
  {
    "song_id": "5cS39Jh4zJeTTqwjtAcLfc",
    "name": "I Can't Get Next To You - Single Version (Mono)",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/b60764b71547ea4fbf65798fe82ab019ee085b52",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/4d6a0c34c35dc6c7ee631832b9ce22485628caea",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1969
  },
  {
    "song_id": "1qTfHUVPsZTnrmvwQrAQdW",
    "name": "Crimson And Clover [Mono Version]",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/c1f8ca8be06ad1cd851b60f6fd1e4ad8d8c9186c",
    "artist_name": "Tommy James",
    "image_url": "https://i.scdn.co/image/6b91ba59c48784947319dfde322a3db5296602f1",
    "artist_id": "7eRORqxEbt7ftbaaWP0k5v",
    "year": 1969
  },
  {
    "song_id": "18RsLGSUkyAasCEvsUELlH",
    "name": "One - Single Version",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/65a460bbb422ca9aaaa385c8d49927522cf1a722",
    "artist_name": "Three Dog Night",
    "image_url": "https://i.scdn.co/image/2f51ab7199f6983b2ea4c977190ae42d8d119abf",
    "artist_id": "4FAEZeJcsYYBkNq2D3KGTV",
    "year": 1969
  },
  {
    "song_id": "5pjlEqTAOq1snQlcSMgF14",
    "name": "Hair",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/2acc05987963236451316e30fd5324c9362cf3b5",
    "artist_name": "The Cowsills",
    "image_url": "https://i.scdn.co/image/8dd4ea4400a2223fedbac25440d2f33455d45df4",
    "artist_id": "4ZSzroBNV7HzBDO9aohuF1",
    "year": 1969
  },
  {
    "song_id": "6SVK5E2ZoKcQFdm4BKfjzM",
    "name": "These Eyes - Remastered",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/85d790d282b625a1aff678d1dc34dd6f96d3ebec",
    "artist_name": "The Guess Who",
    "image_url": "https://i.scdn.co/image/f83b8419752ba42239e625dd724ff3b3861f4bf3",
    "artist_id": "0cQuYRSzlItquYxsQKDvVc",
    "year": 1969
  },
  {
    "song_id": "1RakbRUziomAMl9M07EOci",
    "name": "Put A Little Love In Your Heart - 2002 - Remaster",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/274760f4ef042a5afacd9c9d20dcf5c16524dfe6",
    "artist_name": "Jackie DeShannon",
    "image_url": "https://i.scdn.co/image/72bcefc9fd32af86c70dfdd19f7935659f8ee314",
    "artist_id": "0iVed2Qu7dmL0pIYCj1Xw8",
    "year": 1969
  },
  {
    "song_id": "5jW53RqO3Xp7eLl9cwsypt",
    "name": "Eli's Coming - Single Version",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/8a4513fa1627ff93553e908128beaff1337541d6",
    "artist_name": "Three Dog Night",
    "image_url": "https://i.scdn.co/image/2f51ab7199f6983b2ea4c977190ae42d8d119abf",
    "artist_id": "4FAEZeJcsYYBkNq2D3KGTV",
    "year": 1969
  },
  {
    "song_id": "4hwWmVwchgYTTfMKeCK0W9",
    "name": "I Hear You Say (I Love You Baby)",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/64fa24246a9cdd18c667572022230967fb8180ab",
    "artist_name": "Andy Kim",
    "image_url": "https://i.scdn.co/image/38f37c2d1500995c906dd8697177e3206a8fc434",
    "artist_id": "5MYBNUKoFf9LAg30ByaBli",
    "year": 1969
  },
  {
    "song_id": "2lRbamu5OMYaVvBKK1Soad",
    "name": "Someday We'll Be Together",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/4e7914f2ef737338dd8f451ff3fda4952beaaeb8",
    "artist_name": "Diana Ross & The Supremes",
    "image_url": "https://i.scdn.co/image/3109de8c4972ab33a982a66282675abb7c25a71e",
    "artist_id": "0rXI0q8Cahq6numvPlloaq",
    "year": 1969
  },
  {
    "song_id": "31lMo7rfiyQ7ZjV9qv0lch",
    "name": "It's Your Thing - Single Version",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/0fa162f50633220da80f04bf45c4f0e4a1dd82cd",
    "artist_name": "The Isley Brothers",
    "image_url": "https://i.scdn.co/image/03880047c4f77501b4c6d3591146b224738a42a0",
    "artist_id": "53QzNeFpzAaXYnrDBbDrIp",
    "year": 1969
  },
  {
    "song_id": "2INKkAJCkQg6Iuu4X2QYfd",
    "name": "Dizzy - Re-Recording",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/f8c0630a4f2e0aee9cd8015c3769c10bffcceb76",
    "artist_name": "Tommy Roe",
    "image_url": "https://i.scdn.co/image/71252332f9ac2da2314b887df2745c16d004c478",
    "artist_id": "7t4XHvWfj0XtEB8SNFeALw",
    "year": 1969
  },
  {
    "song_id": "2JS6EF7b5l7YUflV0HY9hi",
    "name": "Too Busy Thinking About My Baby - Single Version / Mono",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/9565f3b05bbcd2081cfca57d9f95ce7536db2572",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/34b08f690f7268c35e7042c7b74ef3040210ae41",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1969
  },
  {
    "song_id": "1Ri1kqxApQKQLVW5YMv6AQ",
    "name": "Only The Strong Survive",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/96dbc75524bc053115dfd92de99812430cf834c2",
    "artist_name": "Jerry Butler",
    "image_url": "https://i.scdn.co/image/5c126a7e330070ec36a14b7ae05cdef9ab8103e8",
    "artist_id": "0U2mUJuJkthantMGmsJygP",
    "year": 1969
  },
  {
    "song_id": "67fxH6TVvnxp9HNK8TuJCd",
    "name": "Love Theme From Romeo And Juliet",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/6a6a926e19c87b14b380e9669ca77ed18589fd17",
    "artist_name": "Henry Mancini & His Orchestra And Chorus",
    "image_url": "https://i.scdn.co/image/cdb34b844ac1adf29ee4db30a2eba1a54a85b83f",
    "artist_id": "483J0u5it5DOZgzNcdpqzs",
    "year": 1969
  },
  {
    "song_id": "5SmTefR1sVu5EoTTO71fss",
    "name": "I'd Wait A Million Years (Re-Recorded / Remastered)",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/c779221ed93ab0ebfaac73f9c444c5495c1279fc",
    "artist_name": "The Grass Roots",
    "image_url": "https://i.scdn.co/image/a4265ef74bdd2a51b9e5d3a3e0de9db22673c726",
    "artist_id": "2IU5UBup85LmAXh5U36DeC",
    "year": 1969
  },
  {
    "song_id": "3HnYevn5u936rEQkQTaB00",
    "name": "Little Woman",
    "popularity": 7,
    "preview_url": "https://p.scdn.co/mp3-preview/cefb6718bc34822ba156f98faf8938f907e251c1",
    "artist_name": "Bobby Sherman",
    "image_url": "https://i.scdn.co/image/481c682fcbefd1cb536d93193e2cbbcfc3a9d827",
    "artist_id": "5Rsz3E1aovbqsmLQOxgK2y",
    "year": 1969
  },
  {
    "song_id": "2ceezfioX5ePPlZqUzV4El",
    "name": "Traces",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/5113209b80a455f2551ddde69168f5203662a3c9",
    "artist_name": "Classics IV",
    "image_url": "https://i.scdn.co/image/259c55ee3162486dde0b5d1177f9238abdefd633",
    "artist_id": "6XGJvNG372fPJ2TV2OoN0Z",
    "year": 1969
  },
  {
    "song_id": "1dQlli2S6UYwaDMIzbytqO",
    "name": "Mother Popcorn - Part 1",
    "popularity": 3,
    "preview_url": "https://p.scdn.co/mp3-preview/dd45601c1cd4bb49c3170e45e3312d2c28ee1de7",
    "artist_name": "James Brown",
    "image_url": "https://i.scdn.co/image/d9d65b4a277b6a68ae16b8368740e3f7af63888f",
    "artist_id": "7GaxyUddsPok8BuhxN6OUW",
    "year": 1969
  },
  {
    "song_id": "24x4HS4znEJIbtVVp5q1ST",
    "name": "I'm Gonna Make You Love Me - Single Version (Mono)",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/75ea5874d86b6b392524b1985a55d51ccca3fc21",
    "artist_name": "Diana Ross & The Supremes",
    "image_url": "https://i.scdn.co/image/3109de8c4972ab33a982a66282675abb7c25a71e",
    "artist_id": "0rXI0q8Cahq6numvPlloaq",
    "year": 1969
  },
  {
    "song_id": "0Y2SrByf4G3kbq2nBEHQRn",
    "name": "Spirit In The Sky",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/1e12651f0d4685c70dfbc924e81736cc25d9732e",
    "artist_name": "Norman Greenbaum",
    "image_url": "https://i.scdn.co/image/3d94e21bc2e050b13d9479b793656a46b18057d4",
    "artist_id": "7f8LNBVXN0h35veHrpxQFL",
    "year": 1970
  },
  {
    "song_id": "6nzhhT57iQYrbqumru9NNP",
    "name": "ABC",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/1de69d8c315233648a4e277036d1988fdc5b468f",
    "artist_name": "Jackson 5",
    "image_url": "https://i.scdn.co/image/89762b3be6b829dfad412524e416ca2f46120c41",
    "artist_id": "2iE18Oxc8YSumAU232n4rW",
    "year": 1970
  },
  {
    "song_id": "6fOXeZLwVZ1J48d3xz6JnY",
    "name": "In The Summertime",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/368da7b8e61358ba0622b9a83f4703247bc44d68",
    "artist_name": "Mungo Jerry",
    "image_url": "https://i.scdn.co/image/30b5fe275261d6e1f6697a83630fc26ed8488c38",
    "artist_id": "2mbvqMGpwLsakeH45p1Jrb",
    "year": 1970
  },
  {
    "song_id": "5LFtkeNjLpZAey3Arj3h4M",
    "name": "Bridge over Troubled Water",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/b0aa95e0affcd1deab771c24d0d33dc237f13e09",
    "artist_name": "Simon & Garfunkel",
    "image_url": "https://i.scdn.co/image/314041c14c76e6d486a09d3cbc1d70850c3b5c31",
    "artist_id": "70cRZdQywnSFp9pnc2WTCE",
    "year": 1970
  },
  {
    "song_id": "5YhcTlesFVSpTgXXnUBkiU",
    "name": "I Want You Back",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/b84af624e21128581ba1b18edfea1ea08df45eed",
    "artist_name": "Jackson 5",
    "image_url": "https://i.scdn.co/image/89762b3be6b829dfad412524e416ca2f46120c41",
    "artist_id": "2iE18Oxc8YSumAU232n4rW",
    "year": 1970
  },
  {
    "song_id": "5Ny6yVg3LRfz3jicFcIMlm",
    "name": "Mississippi Queen",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/a0f9d4308f8e26e35f753a249eea1a82f6712010",
    "artist_name": "Mountain",
    "image_url": "https://i.scdn.co/image/ca1fa79ad4599cb1273bad12cb8b8630ea09a893",
    "artist_id": "7LCp4MN0SOIVWlssid9KyE",
    "year": 1970
  },
  {
    "song_id": "11gfsr13S8qsfN48IILcHU",
    "name": "Make It With You",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/f835dc9e3ec0585da3af104b4642299e5b58576c",
    "artist_name": "Bread",
    "image_url": "https://i.scdn.co/image/cc7876ada08faac728001d62f158721106776624",
    "artist_id": "70ZTdbPEcEugBNay4MvxfL",
    "year": 1970
  },
  {
    "song_id": "6f5sSC3ORfpUMYtE4J06VW",
    "name": "He Ain't Heavy He's My Brother",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/f6a33b79e3bb2966a28d81432e21badd32ee893e",
    "artist_name": "The Hollies",
    "image_url": "https://i.scdn.co/image/611e7c91415915d3ffa865f53a9e7a2cad54ae91",
    "artist_id": "6waa8mKu91GjzD4NlONlNJ",
    "year": 1970
  },
  {
    "song_id": "6gQZKkphKIMxZgca5r7ImA",
    "name": "Express Yourself (Remastered Version)",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/34f84304742c4cfae767f6be8c3c422abc6430ef",
    "artist_name": "Charles Wright",
    "image_url": "https://i.scdn.co/image/1af6b6c917be233bd1d511b3091d0fd5c43676b5",
    "artist_id": "6fN2KrVTKQmaJPNWfIiIuh",
    "year": 1970
  },
  {
    "song_id": "1DiP1KYlzeNysRN5JHLbfx",
    "name": "(They Long To Be) Close To You",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/81d4374ac5d3c5a7ca5f41e3df7de33425472f79",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/3c47eda2fe7f2f5e2b21ecf0df6ad73f7f97ad71",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1970
  },
  {
    "song_id": "3CCDNNt89mosrAkSseS2GQ",
    "name": "Aint No Mountain High Enough",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/b933d995db6ef3064d0ae6fe10954311c3b4b11a",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/8a69bca942b6a79da7d2086d936d3e524b9b9ff5",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1970
  },
  {
    "song_id": "4dA2bVaf9MDqwWNwBGx1QE",
    "name": "We've Only Just Begun",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/5aa31835295d2fc42994f6640cf592c74aa32866",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/3c47eda2fe7f2f5e2b21ecf0df6ad73f7f97ad71",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1970
  },
  {
    "song_id": "5ELbcZndp49XTIblVA1yaV",
    "name": "Lola",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/e803aae1c3503bb67352a159438b7ba2f221c109",
    "artist_name": "The Kinks",
    "image_url": "https://i.scdn.co/image/d624376384ff98fc6d3b91b562c8bbe621d7f16d",
    "artist_id": "1SQRv42e4PjEYfPhS0Tk9E",
    "year": 1970
  },
  {
    "song_id": "1BmNPbw8QYr4XKOYAGA568",
    "name": "It's A Shame",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/6610d03421404898bb784bc7e18fe595d91e256b",
    "artist_name": "The Spinners",
    "image_url": "https://i.scdn.co/image/3fc624908e37babe4489ca6fb2dd08241a73276a",
    "artist_id": "5fbhwqYYh4YwUoEs582mq5",
    "year": 1970
  },
  {
    "song_id": "5tASX9SKT8vFTQpfm5UVJT",
    "name": "No Sugar Tonight",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/7598db8fff1fa92c10f7a685776e9cfc52a61333",
    "artist_name": "The Guess Who",
    "image_url": "https://i.scdn.co/image/81aa269962bf824a343ec97974b2adc2af444198",
    "artist_id": "0cQuYRSzlItquYxsQKDvVc",
    "year": 1970
  },
  {
    "song_id": "2tAYoHaFbx6e7QOkOkAhrX",
    "name": "Spill The Wine",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/f7e1b1e156b1dec8b2c899617a4316a8ea0651f6",
    "artist_name": "Eric Burdon",
    "image_url": "https://i.scdn.co/image/b3d77e4631cd8b23c5980c1408e0dd234fa46024",
    "artist_id": "3miNucraVWk4hdVsIxn7id",
    "year": 1970
  },
  {
    "song_id": "7evYrcU6PhEsca4XDsuyII",
    "name": "Cracklin' Rosie - Single Version",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/21218e166cddccbcb9614b98bfa390142781b44a",
    "artist_name": "Neil Diamond",
    "image_url": "https://i.scdn.co/image/afb71daedb1332758afab1112c1992cfcc3becc2",
    "artist_id": "7mEIug7XUlQHikrFxjTWes",
    "year": 1970
  },
  {
    "song_id": "0jQR1pn8FOJ5dC02AvNgTS",
    "name": "Long As I Can See The Light",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/f2f98a1800f6fcdc875ceb9c90336945c4007f7e",
    "artist_name": "Creedence Clearwater Revival",
    "image_url": "https://i.scdn.co/image/4a684b40f3989a1f9bcfb11ec8928a7f2d909cfc",
    "artist_id": "3IYUhFvPQItj6xySrBmZkd",
    "year": 1970
  },
  {
    "song_id": "1XS08WCbVDzodcKZ4KSqyr",
    "name": "Cecilia",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/4ad8dc4cf6ad4da5ec251c3cece32552924f2efa",
    "artist_name": "Simon & Garfunkel",
    "image_url": "https://i.scdn.co/image/66b09cff08b537319ddc72d9d8abb72a7f6657fc",
    "artist_id": "70cRZdQywnSFp9pnc2WTCE",
    "year": 1970
  },
  {
    "song_id": "5vk9PL1tTyWrQY3TqRq2Rg",
    "name": "Patches",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/eff4a1224ef792a309723aa67ea8b1d6a126767a",
    "artist_name": "Clarence Carter",
    "image_url": "https://i.scdn.co/image/c6d6df4151e0fb01785a48e8e2fc7d6cc4a7c18b",
    "artist_id": "7lffJlv0nRl0sIsHDmo0SB",
    "year": 1970
  },
  {
    "song_id": "2gcAF4Gp9aSOzolpN9cGZK",
    "name": "Love Grows (Where My Rosemary Goes)",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/3afac3294eafd975a64a6bec88cf97077cf2720e",
    "artist_name": "Edison Lighthouse",
    "image_url": "https://i.scdn.co/image/e5d0b3007c8105255faec8c1fc8a54944acfb464",
    "artist_id": "1NRzxuPpdGushT8YmF5NAa",
    "year": 1970
  },
  {
    "song_id": "19bR4aKlr6fD1eAHDCmr2l",
    "name": "No Time",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/23e721f197d5cdff01aa1db631559bc6598a3c15",
    "artist_name": "The Guess Who",
    "image_url": "https://i.scdn.co/image/81aa269962bf824a343ec97974b2adc2af444198",
    "artist_id": "0cQuYRSzlItquYxsQKDvVc",
    "year": 1970
  },
  {
    "song_id": "1kvbxvZzSSWGaHGBY7r7C1",
    "name": "The Wonder Of You",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/9f76df9bbcd259082fe8ced5585da30936564b35",
    "artist_name": "Elvis Presley",
    "image_url": "https://i.scdn.co/image/4ab662302ae7e790b4dd7c381bc3a158852cd1e0",
    "artist_id": "43ZHCT0cAZBISjO8DG9PnE",
    "year": 1970
  },
  {
    "song_id": "3gKk0KYBayVp07RmlXOT6e",
    "name": "Up Around The Bend",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/520262661202bad59a632a391acffc8ec32e9c13",
    "artist_name": "Creedence Clearwater Revival",
    "image_url": "https://i.scdn.co/image/4a684b40f3989a1f9bcfb11ec8928a7f2d909cfc",
    "artist_id": "3IYUhFvPQItj6xySrBmZkd",
    "year": 1970
  },
  {
    "song_id": "2ik5qJAcmrjbpON5QPxmRr",
    "name": "Snowbird",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/5608309594661a74a7cc23d500026244bbd6becb",
    "artist_name": "Anne Murray",
    "image_url": "https://i.scdn.co/image/1c0a178e071eb566309498136d7f149e331628a2",
    "artist_id": "7d7q5Y1p2QWS4QRAhTQR5E",
    "year": 1970
  },
  {
    "song_id": "6hnyQ0YpiO35rutiLLHLmr",
    "name": "Vehicle",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/b5d8cf0a546d3f71e5bb476f0cb3583466088895",
    "artist_name": "The Ides Of March",
    "image_url": "https://i.scdn.co/image/950686b0f4baeec5f8f147a3782d2e40150fd4a4",
    "artist_id": "1Csjn2SQV7R9szDhhR7Aji",
    "year": 1970
  },
  {
    "song_id": "0vMBYFm71ISJ3LbJTq9uIF",
    "name": "Travelin' Band",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/8a5612f45b5baae8a9ac25ac9614a533bf3264ef",
    "artist_name": "Creedence Clearwater Revival",
    "image_url": "https://i.scdn.co/image/4a684b40f3989a1f9bcfb11ec8928a7f2d909cfc",
    "artist_id": "3IYUhFvPQItj6xySrBmZkd",
    "year": 1970
  },
  {
    "song_id": "3IuNNnc5rCdyPnYc5cSXjO",
    "name": "The Love You Save",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/8cafdb283805ae42f31a5a2e6e31013b5f40e5f3",
    "artist_name": "Jackson 5",
    "image_url": "https://i.scdn.co/image/89762b3be6b829dfad412524e416ca2f46120c41",
    "artist_id": "2iE18Oxc8YSumAU232n4rW",
    "year": 1970
  },
  {
    "song_id": "2B9i8K60LxOChRDUnmx2Fk",
    "name": "Run Through The Jungle",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/dd9a83e42a7d577e893ed7b92bcd666beadec00a",
    "artist_name": "Creedence Clearwater Revival",
    "image_url": "https://i.scdn.co/image/4a684b40f3989a1f9bcfb11ec8928a7f2d909cfc",
    "artist_id": "3IYUhFvPQItj6xySrBmZkd",
    "year": 1970
  },
  {
    "song_id": "5s2b6aZj3xTzBQ1G4Q3W3g",
    "name": "Yellow River",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/5d7bfab0951f4de0bd679df1ea69a59cb312bc9a",
    "artist_name": "Christie",
    "image_url": "https://i.scdn.co/image/296f2225f749bb09f7bcd45276fb2bff4c18490d",
    "artist_id": "0HoeFrrKutiio4HJtXN0Sn",
    "year": 1970
  },
  {
    "song_id": "7cYv8b5cU7Fy3wsaxy7NKO",
    "name": "Candida",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/116f66115b79dad525980516ef2d9f0372086841",
    "artist_name": "Tony Orlando & Dawn",
    "image_url": "https://i.scdn.co/image/9d3596ca0c156740d76064642f599bf22869ebc3",
    "artist_id": "72NXpYBIaTfEeAAsxXLs0P",
    "year": 1970
  },
  {
    "song_id": "7s2orxqJ8qE0aG3Ngvct3C",
    "name": "Green-Eyed Lady",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/2d94e105356f6e8ecb797c63bff7dc2de8eb2c49",
    "artist_name": "Sugarloaf",
    "image_url": "https://i.scdn.co/image/4aa078fefc4827cd6ee8b7ab5da61f2a30f94211",
    "artist_id": "1T3RPiMH711kvoXymKUTYH",
    "year": 1970
  },
  {
    "song_id": "7k0UY4Kabh7SUHXowyfKj7",
    "name": "Make Me Smile",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/9058482dbccb01612a2b80808194fa4f287f4892",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/3879e5319c5ece1464b21bd1d2761d34e07cc658",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1970
  },
  {
    "song_id": "238ZQCM8DafVF6dY2VzRIU",
    "name": "The Long and Winding Road",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/1153d891a8f3b7daff46d9c41edde184fb8edda5",
    "artist_name": "The Yesteryears",
    "image_url": "https://i.scdn.co/image/e32f5dc1f94c728fbdd6acd82de2ce73977de626",
    "artist_id": "7vfFFI4s6twLs3L5POUURF",
    "year": 1970
  },
  {
    "song_id": "7Bp0pbyAMnoDhHbocCbm3w",
    "name": "Reflections Of My Life",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/6641b617f30a35dc8bc9dfa061e4f5c7dc7bc7a3",
    "artist_name": "Marmalade",
    "image_url": "https://i.scdn.co/image/a19cf801804008ad497f5fb6018d3aa77f145cd2",
    "artist_id": "1tttI1iC4FsS6BM5l38oe5",
    "year": 1970
  },
  {
    "song_id": "6Ad84xnZh6YdQD7fhcVx1p",
    "name": "The Letter",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/1ecadfe97a3b5b418264bbda22dfcf1bf308b0c8",
    "artist_name": "Joe Cocker",
    "image_url": "https://i.scdn.co/image/c583f82cda1baacd321e670bec39768e0a87a2c9",
    "artist_id": "3pFCERyEiP5xeN2EsPXhjI",
    "year": 1970
  },
  {
    "song_id": "2lM3TSK9VROcDRWvtnIP6J",
    "name": "Get Ready",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/a00624f830edfd5833f50d25d1fcc64a05968142",
    "artist_name": "Rare Earth",
    "image_url": "https://i.scdn.co/image/30901a3ff9a9d3fc7fb83e1323ea08213c1c0552",
    "artist_id": "6eEsIl4wosvhuWI0mfXxAF",
    "year": 1970
  },
  {
    "song_id": "71GAvH2NGQsjbTmddu2ffy",
    "name": "Mama Told Me (Not To Come) - Single Version",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/9d7239eb5c314ffba4cb4a464cf16f35740dc051",
    "artist_name": "Three Dog Night",
    "image_url": "https://i.scdn.co/image/737ae46d25a360f4c7f701e4ed5d5bc957f8be15",
    "artist_id": "4FAEZeJcsYYBkNq2D3KGTV",
    "year": 1970
  },
  {
    "song_id": "3bJu7Ybs96JAFt3gEmgas2",
    "name": "The Rapper",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/fd1f7f8220728770de25e1b59128992679b9cca3",
    "artist_name": "The Jaggerz",
    "image_url": "https://i.scdn.co/image/e5d0b3007c8105255faec8c1fc8a54944acfb464",
    "artist_id": "05aFikcWbrmnDKsCHaH0Ce",
    "year": 1970
  },
  {
    "song_id": "6S0TkUP6XuZOL7kRZwynG5",
    "name": "American Woman",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/6794083de1268411818a84d4d311bf3013c550e1",
    "artist_name": "The Guess Who",
    "image_url": "https://i.scdn.co/image/296f2225f749bb09f7bcd45276fb2bff4c18490d",
    "artist_id": "0cQuYRSzlItquYxsQKDvVc",
    "year": 1970
  },
  {
    "song_id": "1E3N0QEjPYWOxlyVScZtbW",
    "name": "Rainy Night In Georgia",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/0b12c766c38642ed07567ddbd4f0964d6034f6ed",
    "artist_name": "Brook Benton",
    "image_url": "https://i.scdn.co/image/202ac7961fd8d4941dacffae5858d42dec61432c",
    "artist_id": "2ttm3uT0N1RN7vwKv1pQgh",
    "year": 1970
  },
  {
    "song_id": "1tURLJqgjF5FyvZyRq1wAR",
    "name": "Come And Get It",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/162c207bffaaa4352c7fed42e6d2530c3790f73a",
    "artist_name": "Badfinger",
    "image_url": "https://i.scdn.co/image/6d7213c442c3aa35d5d1bd1f51649483ae5770b0",
    "artist_id": "4pJCawaKSZ40EnxN0YEYw3",
    "year": 1970
  },
  {
    "song_id": "2WIJDjBIQvHJc7REEwNkUX",
    "name": "Didn't I (Blow Your Mind This Time)",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/0d3ccd873dee827de184c9527f2cefb3ebf8d1f1",
    "artist_name": "The Delfonics",
    "image_url": "https://i.scdn.co/image/f55584765a558925c310a1107bb213df54d06878",
    "artist_id": "6YPRXu1dazGYcSZv4HJEH4",
    "year": 1970
  },
  {
    "song_id": "7rZ0G6zdogbEySD0iZluBm",
    "name": "Without Love",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/468ad1798dbcf3e93427b87cacf208eddafd0205",
    "artist_name": "Tom Jones",
    "image_url": "https://i.scdn.co/image/d83afe445173027ae453fb7e66916a1862e69ac0",
    "artist_id": "1T0wRBO0CK0vK8ouUMqEl5",
    "year": 1970
  },
  {
    "song_id": "63efsmQuATznBX8OeiBiZp",
    "name": "Are You Ready? - Single Version",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/5f9700dcfaa87561b8682793c16fff5b26cbcb44",
    "artist_name": "Pacific Gas & Electric",
    "image_url": "https://i.scdn.co/image/c3aedf83492b204112092f64e3650dbae93431ab",
    "artist_id": "0WNQHJ6HeMlIxyjPQ2PrF5",
    "year": 1970
  },
  {
    "song_id": "4NbBlUmh84j9tFRWdkPJTt",
    "name": "War",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/5fc878a8c3156db51c205e578cbd6afb6349e2e0",
    "artist_name": "Edwin Starr",
    "image_url": "https://i.scdn.co/image/e5d0b3007c8105255faec8c1fc8a54944acfb464",
    "artist_id": "1B8AXU6gIIafpyLEpbcv1u",
    "year": 1970
  },
  {
    "song_id": "74yt57iOVeQZWXScUQu0pn",
    "name": "I'll Be There",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/b0677b58bf1a12fcbab25217c618cd83df740bd6",
    "artist_name": "Jackson 5",
    "image_url": "https://i.scdn.co/image/c8f808d8df9241e2352c31f2e31dd506d05a0107",
    "artist_id": "2iE18Oxc8YSumAU232n4rW",
    "year": 1970
  },
  {
    "song_id": "3Seff9L1Z3mOGDdK98jsgx",
    "name": "House Of The Rising Sun",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/726e5961ec8c536267b67942a935de864c1f925c",
    "artist_name": "Frijid Pink",
    "image_url": "https://i.scdn.co/image/8119e1f7eb3deaab6626544b78eebfde235f40c3",
    "artist_id": "0K24Tk5QTxx6tkcCdnY3Od",
    "year": 1970
  },
  {
    "song_id": "3NPLZd8hmUbVY0tcQnSwmp",
    "name": "I Just Can't Help Believing",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/be42884523243fa3c92d74e2c10697a4c3651137",
    "artist_name": "B.J. Thomas",
    "image_url": "https://i.scdn.co/image/f44305e0409e5e9adb76a26cc8677f604621ade5",
    "artist_id": "0uUNzXylqsZdmFDwdxaP1V",
    "year": 1970
  },
  {
    "song_id": "2nz7kYnsKl3j4qu71bWHPX",
    "name": "Let It Be",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/3cf83f80fc7278acab93c64a05c20a16891cb848",
    "artist_name": "The Silver Beetles",
    "image_url": "https://i.scdn.co/image/dcc94938ae2384edbba6d756ffd4c950c65edd7f",
    "artist_id": "1MLXQOUzVRfjYAu5xyEntV",
    "year": 1970
  },
  {
    "song_id": "6MTZPsmPQiGfylHzBpcKOF",
    "name": "I Want To Take You Higher",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/dfa76eee32b1b4184c5ad9bce5cfd7ffb23c902d",
    "artist_name": "Ike & Tina Turner",
    "image_url": "https://i.scdn.co/image/dece4281e4507b0c768d146e5564777609db3123",
    "artist_id": "1ZikppG9dPedbIgMfnfx8k",
    "year": 1970
  },
  {
    "song_id": "0jyEmGRHhZjH1MG46M8QKf",
    "name": "Hitchin a Ride",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/a7cc9bab615f89a23d6246d497bb6852270d9270",
    "artist_name": "Vanity Fare",
    "image_url": "https://i.scdn.co/image/235bbd18fda522149ed180a4bb86d2206caaaa2f",
    "artist_id": "0Wt8hmgHtXUgwsLg5NpMZv",
    "year": 1970
  },
  {
    "song_id": "029hGpRh2NnaMLptRUaJE4",
    "name": "Ball Of Confusion (That's What The World Is Today) - Stereo Version",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/6f499f98fbf4c0f109fac4beb992cd5b90d455c0",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/eec1c0ca19bc4664632a0a05af840adda54e6b58",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1970
  },
  {
    "song_id": "5D8deqILdj7VxTNKRcntge",
    "name": "Hey There Lonely Girl",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/a8625140b9e1d5ba09de660ca87373ae0473fcd8",
    "artist_name": "Eddie Holman",
    "image_url": "https://i.scdn.co/image/ca55c199815e076e0dd480214a257307af17008b",
    "artist_id": "4hLuzWVCPicO3nNHfFvB32",
    "year": 1970
  },
  {
    "song_id": "326emknRTzEzejbRLvH5Xs",
    "name": "Instant Karma! (We All Shine On) (Made Famous by John Lennon)",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/9ea971737a06730c2d5a8f67015bf851c0bd50b0",
    "artist_name": "Working Class Heroes",
    "image_url": "https://i.scdn.co/image/7a9df7593c83c171c949c41abdd33e3d45057b9a",
    "artist_id": "4hcwotMmR9V1CtJpRri5K3",
    "year": 1970
  },
  {
    "song_id": "7MSpE0g7Dg0R9yx1qs0rA4",
    "name": "Easy Come, Easy Go",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/27a5bd7c0ecbe4d96cc149c74e07c8ccdc58f1db",
    "artist_name": "Bobby Sherman",
    "image_url": "https://i.scdn.co/image/c74d0411764b3a716f5f2d5a3ee116207854da19",
    "artist_id": "5Rsz3E1aovbqsmLQOxgK2y",
    "year": 1970
  },
  {
    "song_id": "5Mzzbi0jAAnOLUwj1rR4XV",
    "name": "Somebody's Been Sleeping In My Bed",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/fb4bcdac9329d3f5e38dabe0a383045894621d62",
    "artist_name": "100 Proof Aged In Soul",
    "image_url": "https://i.scdn.co/image/605b1852b32a9c749d8be9a4476dc9e20d204c0a",
    "artist_id": "10i8e4CH1K0Nf4XECvAcTd",
    "year": 1970
  },
  {
    "song_id": "0TMcqT0k2hsz0R8xdFWta5",
    "name": "Gimme Dat Ding",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/db8aabfcf1133ce90e636b0ec987af02cec302ff",
    "artist_name": "The Pipkins",
    "image_url": "https://i.scdn.co/image/0335616423c691bac3ca36d939d04fa8e1c849df",
    "artist_id": "7b8blpDsdFfs5DMMMLBuX0",
    "year": 1970
  },
  {
    "song_id": "1i8EWJLSD7LG0LFD6fogpg",
    "name": "Love On A Two Way Street",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/2607923b7069074b9bc5928d7ed54bb99667455d",
    "artist_name": "The Moments",
    "image_url": "https://i.scdn.co/image/79228869ea131ca4b4c20d96d4ef4c6085bd49c7",
    "artist_id": "6avGAzQTmqu8mfSmZrN7gT",
    "year": 1970
  },
  {
    "song_id": "2N3lVY7iPbiR297r5EvMeo",
    "name": "Love Land",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/1d7f170d539afddffdc1d5195c6dca97a65f5a7e",
    "artist_name": "Charles Wright",
    "image_url": "https://i.scdn.co/image/611b07d9ace5e94fdb1502f35a971aef8d0658c8",
    "artist_id": "6fN2KrVTKQmaJPNWfIiIuh",
    "year": 1970
  },
  {
    "song_id": "3cDGJlTvUT79tw2y3wTXti",
    "name": "(I Know) I'm Losing You - Single Version",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/d9ff58da7d861f16d12533da65f00906770f190d",
    "artist_name": "Rare Earth",
    "image_url": "https://i.scdn.co/image/7ddc4860ffc38eba85cbdd439fdc777210747455",
    "artist_id": "6eEsIl4wosvhuWI0mfXxAF",
    "year": 1970
  },
  {
    "song_id": "7zprGKxSOueSzoB4qjgM4U",
    "name": "United We Stand",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/798b69b40a3246c2346c84f9cdc2ac232e71ad40",
    "artist_name": "Brotherhood of Man",
    "image_url": "https://i.scdn.co/image/b1c8fac47c1e08355841b7ed66d90ce448878693",
    "artist_id": "4Cyr5aqgXza16isOrQNOvo",
    "year": 1970
  },
  {
    "song_id": "3IhZEfDURJjZetiaJ40Sxo",
    "name": "Band Of Gold",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/2bc3e6628d7f381b1ff5a102f5a3e8e23f4c3b20",
    "artist_name": "Freda Payne",
    "image_url": "https://i.scdn.co/image/e85c55deb7826d29be24f26039c47f2f39c8b025",
    "artist_id": "0701Axu6yvUIoctaKMbDIZ",
    "year": 1970
  },
  {
    "song_id": "48ndCToiqUTtr1BNWxlyz3",
    "name": "If You Let Me Make Love To You (Then Why Can't I Touch You)",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/e336732c5e03ae8e67428b7c85ed73d29d7b3174",
    "artist_name": "Ronnie Dyson",
    "image_url": "https://i.scdn.co/image/f3d89446f83e5168e16add59cb4e02f5b93b8aa8",
    "artist_id": "3UxJwjLkGvAlRi7NbYekGI",
    "year": 1970
  },
  {
    "song_id": "1qBc4ZjI8dZPWkhp64N7CY",
    "name": "Ma Belle Amie - Single Version",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/6d6290c234d081b52e4216e6ca956c1ce2bbaab0",
    "artist_name": "Tee-Set",
    "image_url": "https://i.scdn.co/image/40a9e1d113f4f7b586972a2d134981325dc639a1",
    "artist_id": "0uBrmlGaTI6nSg4JpVC1VR",
    "year": 1970
  },
  {
    "song_id": "5Ztr4ByiW3NHhFL8kK0lkf",
    "name": "Mama Liked the Roses",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/b0719e98a11aa022d59472e1ad5094d0502dad4d",
    "artist_name": "Elvis Presley",
    "image_url": "https://i.scdn.co/image/704f1b6267e5f2eaaabca8fb31df87c3183b4d73",
    "artist_id": "43ZHCT0cAZBISjO8DG9PnE",
    "year": 1970
  },
  {
    "song_id": "60uo2N8udBgkYt2dHGB0qp",
    "name": "My Baby Loves Lovin'",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/4c76f6f5b6e509ddfa21a2d3cdf4d4ba304e20b7",
    "artist_name": "White Plains",
    "image_url": "https://i.scdn.co/image/58e188afa65958604a83fe57b01cb3e76e5283a9",
    "artist_id": "0xWTQw2TL6j7mHGOTltsEW",
    "year": 1970
  },
  {
    "song_id": "3LE6HYhvU6FxiDVOYo0yUd",
    "name": "Still Water (Love)",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/1043ce92d3dcb15254f5a7a8b6426328d0240697",
    "artist_name": "Four Tops",
    "image_url": "https://i.scdn.co/image/604ce455072dab4a08b7b3ad15ec8a85eca801f2",
    "artist_id": "7fIvjotigTGWqjIz6EP1i4",
    "year": 1970
  },
  {
    "song_id": "53I7OFDZodtv9vRsv4ur2N",
    "name": "Lay A Little Lovin' On Me",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/4a66fddd2df52688fff96d0fa33433089693870f",
    "artist_name": "Robin McNamara",
    "image_url": "https://i.scdn.co/image/b7881b1c22b11c9835d38c264a63068f6d9f4d62",
    "artist_id": "5OKNo1RB42IASDuQQ6Fkeo",
    "year": 1970
  },
  {
    "song_id": "6MN442jV9b56J3n0EY3JHw",
    "name": "Come Saturday Morning",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/ea8d0d0cb36869247be3619302c626ba1ed594ec",
    "artist_name": "The Sandpipers",
    "image_url": "https://i.scdn.co/image/3fdc3f500a3533ef48049c98703b2b2a7795de2c",
    "artist_id": "3iBuBivHItWMpdiVmLT0uB",
    "year": 1970
  },
  {
    "song_id": "0cNbfJ9Hwx0V0CX9JQgNEV",
    "name": "Which Way You Goin' Billy? - DO NOT USE",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/c32195c914c69ee11e24ff0569ccef5b5465f240",
    "artist_name": "The Poppy Family",
    "image_url": "https://i.scdn.co/image/7e6dd64bb8b55967c7b8594e06837aac024f2a9d",
    "artist_id": "0XawOhZ28ajmIWwPpRJuDA",
    "year": 1970
  },
  {
    "song_id": "4dzly5Fuyz7HXE2EuW4cYH",
    "name": "I Found That Girl",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/df54f9dd2eca45a671ef1acb1ae276befad64591",
    "artist_name": "Jackson 5",
    "image_url": "https://i.scdn.co/image/5e75bf81ce9dbf20746dd85eba51542fe6c10b65",
    "artist_id": "2iE18Oxc8YSumAU232n4rW",
    "year": 1970
  },
  {
    "song_id": "4F8N0PqtYoCsHo9suxI0ph",
    "name": "Lay Down - (Candles in the Rain)",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/16cbd7625e38eb292a006bd51470edee90d30991",
    "artist_name": "Melanie",
    "image_url": "https://i.scdn.co/image/27a310d399b08c29a95b80b2ea3e4d6ad28618a5",
    "artist_id": "6sOP8RUFR0q0nBOBOXGdBK",
    "year": 1970
  },
  {
    "song_id": "0gTnzyccvhj1FaQqTHEi8q",
    "name": "Thank You (Falettinme Be Mice Elf Agin)",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/1d68e1edb4efc12e051be360829f3bd6d56a673d",
    "artist_name": "Sly & The Family Stone",
    "image_url": "https://i.scdn.co/image/ac5c80327f7d0f5d585e325e9ebe49a8757452cf",
    "artist_id": "5m8H6zSadhu1j9Yi04VLqD",
    "year": 1970
  },
  {
    "song_id": "68gGCRgsGRP7bYvsbWb4RW",
    "name": "Tighter, Tighter",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/d1488bee395b1db5d549b181e3e07b52d83ffc8b",
    "artist_name": "Alive and Kicking",
    "image_url": "https://i.scdn.co/image/eb25c8900cdb093a93b4ec1341f0cc23a891c953",
    "artist_id": "4Xfrrm7WP8E4cnbailblfS",
    "year": 1970
  },
  {
    "song_id": "5UoxyubhXqnoyCiehtJgSV",
    "name": "Love Or Let Me Be Lonely",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/6d9d242b1aa9871e24aded3c6c9b7748ed6e439c",
    "artist_name": "The Friends Of Distinction",
    "image_url": "https://i.scdn.co/image/0416c8d9f5a10d00cd053603ee744acf7b0943a3",
    "artist_id": "2KK4fR5VwOjGzJxjbGxMIu",
    "year": 1970
  },
  {
    "song_id": "2atF8L9VWe52tpBoQ8pVPl",
    "name": "Up The Ladder To The Roof",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/133f66641a2e4a6a94ddf35f0d323cb5f4fd4f62",
    "artist_name": "The Supremes",
    "image_url": "https://i.scdn.co/image/374cef52d372699d389830377fdd9459d59609cb",
    "artist_id": "57bUPid8xztkieZfS7OlEV",
    "year": 1970
  },
  {
    "song_id": "4deHufVWooFQ1ty71FqM3F",
    "name": "Indiana Wants Me",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/2ff30e682f036edf3ec8e78d02380d7713307d95",
    "artist_name": "R. Dean Taylor",
    "image_url": "https://i.scdn.co/image/7d07b88ea02ea715d88eff9f123e51441cadb582",
    "artist_id": "4Yg38CnYMoN09sMLf6KDdW",
    "year": 1970
  },
  {
    "song_id": "3Wxd4KkAyObPgCQt8PdIDM",
    "name": "Everybody Is A Star",
    "popularity": 0,
    "preview_url": "https://p.scdn.co/mp3-preview/f9ca50f0fde2fbda82f8502d7f9195d7bc85aff2",
    "artist_name": "Sly & The Family Stone",
    "image_url": "https://i.scdn.co/image/ac5c80327f7d0f5d585e325e9ebe49a8757452cf",
    "artist_id": "5m8H6zSadhu1j9Yi04VLqD",
    "year": 1970
  },
  {
    "song_id": "2xYlyywNgefLCRDG8hlxZq",
    "name": "Take Me Home, Country Roads",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/8e718f0683b7ac6bb08f0becabe59b73c78409e3",
    "artist_name": "John Denver",
    "image_url": "https://i.scdn.co/image/8bb11284a87cdac37ffa8d29cdf4a52742a81d30",
    "artist_id": "7EK1bQADBoqbYXnT4Cqv9w",
    "year": 1971
  },
  {
    "song_id": "6d9rNfRFOQ0XmsUwJ8C5J0",
    "name": "Tired Of Being Alone",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/c6211fd6c3fa2a59dd1881ac06f22dbcc095bbac",
    "artist_name": "Al Green",
    "image_url": "https://i.scdn.co/image/1e207267ef75a559461d18bcc7b4d45de9197faa",
    "artist_id": "3dkbV4qihUeMsqN4vBGg93",
    "year": 1971
  },
  {
    "song_id": "7u8LNgkrmUXGT6JEwiy8Ia",
    "name": "Ain't No Sunshine",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/5c0191eb64c68b77a9da717cd88de3dbf704af31",
    "artist_name": "Bill Withers",
    "image_url": "https://i.scdn.co/image/6d7ee34e5ee8a6051e8957ffe4c8eb0829ba77b7",
    "artist_id": "1ThoqLcyIYvZn7iWbj8fsj",
    "year": 1971
  },
  {
    "song_id": "1r3kj1yMff7ilIsR7hpvUo",
    "name": "Maggie May (Remastered Album Version)",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/517eceabeac87914842e1d53131a5cb63b7f41ff",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/79fa6c9588f7bec80db5db5d06bae424a118e57b",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1971
  },
  {
    "song_id": "0QAZgZkHYERH1rYN3u8Gci",
    "name": "If You Could Read My Mind",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/837e1e8269a5f88d0607fd35260560ebc1ff0964",
    "artist_name": "Gordon Lightfoot",
    "image_url": "https://i.scdn.co/image/ee6c3cef46239eb1044d38ae451ceb7ca2e9dde5",
    "artist_id": "23rleGXVOVVgTk3xgtmfE4",
    "year": 1971
  },
  {
    "song_id": "5YI7v4VBa23fbEt9wuUmkg",
    "name": "Proud Mary",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/5fda29f559d636e71fa0486855c9478c050932b4",
    "artist_name": "Ike & Tina Turner",
    "image_url": "https://i.scdn.co/image/10541ff1a8f8cbc92f0854aeba5dfafb7a36ab62",
    "artist_id": "1ZikppG9dPedbIgMfnfx8k",
    "year": 1971
  },
  {
    "song_id": "2ymeOsYijJz09LfKw3yM2x",
    "name": "Joy To The World",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/72ed7faccb5efcf3deae5bb5da5c164d5b07a10f",
    "artist_name": "Three Dog Night",
    "image_url": "https://i.scdn.co/image/7d5dc0094de23e5ee269b51219d820ac5edce775",
    "artist_id": "4FAEZeJcsYYBkNq2D3KGTV",
    "year": 1971
  },
  {
    "song_id": "1Fu3gq55GH3P6OF352rEIc",
    "name": "If",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/a1013220e72db861e175d3c9b3fe5f702e729985",
    "artist_name": "Bread",
    "image_url": "https://i.scdn.co/image/cc7876ada08faac728001d62f158721106776624",
    "artist_id": "70ZTdbPEcEugBNay4MvxfL",
    "year": 1971
  },
  {
    "song_id": "3Vms3ZKJF7CXWppSejBPxL",
    "name": "The Night They Drove Old Dixie Down",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/d0d961d48cdb1f2e1a920541794d3c9f6a0e45f9",
    "artist_name": "Joan Baez",
    "image_url": "https://i.scdn.co/image/386828391420fa2ec072a611f6ebf5f454d38068",
    "artist_id": "1EevBGfUh3RSQSGpluxgBm",
    "year": 1971
  },
  {
    "song_id": "5mJwUOv7NUUxRRRfd43BWI",
    "name": "Rose Garden",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/a3574d73cc1716446742fb4038c1242d2d477047",
    "artist_name": "Lynn Anderson",
    "image_url": "https://i.scdn.co/image/763efb1c7568cd9707aadef3a7b2feba97868ef0",
    "artist_id": "502FY8XQYRxClWgolQ3Hf2",
    "year": 1971
  },
  {
    "song_id": "5YDy9r7gC44qwT3kaWRBI0",
    "name": "How Can You Mend A Broken Heart (Remastered Album Version)",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/81049491fb9fefd4495fbf4eabc5155cf28a2b6b",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/9ba9a3a360c2f681818fa251fcc4a5800c01b1e3",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1971
  },
  {
    "song_id": "0GWKqusjrUtxWZexPvDHhJ",
    "name": "She's A Lady",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/7935ec747cefc2e34a1ecc996ab77a1bc7c9d78e",
    "artist_name": "Tom Jones",
    "image_url": "https://i.scdn.co/image/e54611425a8389516b614460bb28607790e0a4eb",
    "artist_id": "1T0wRBO0CK0vK8ouUMqEl5",
    "year": 1971
  },
  {
    "song_id": "1929eIMhIFVN00MVzejNne",
    "name": "Rainy Days And Mondays",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/1a7d8a7a378b30094ca0f1794250101eb983caa1",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/e78c073ff39c02004a3fb8ec3ce0336ccd4e8d60",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1971
  },
  {
    "song_id": "3wRiwV9HdarzkktRH7If46",
    "name": "Me And You And A Dog Named Boo",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/b6fdfa8a015c91323755c9b135eef6719b2d3be0",
    "artist_name": "Lobo",
    "image_url": "https://i.scdn.co/image/ca2fa4121a329ec8f005b12eb6be47a73b517f2d",
    "artist_id": "1sldhz8tzC100cRAdfnMht",
    "year": 1971
  },
  {
    "song_id": "1lfVeoMZZimh0jf8rYUeJU",
    "name": "Amos Moses",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/fafad951e162dcda1c7e51361242bffb7eade784",
    "artist_name": "Jerry Reed",
    "image_url": "https://i.scdn.co/image/42462a424b4461f74e56811fb6a537b0481d1dbc",
    "artist_id": "3kvy8do7n0qVqvlNFS4yOF",
    "year": 1971
  },
  {
    "song_id": "7EwMs0qyJ90NVQjRN84DVj",
    "name": "Theme From \"Shaft\"",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/3fc92dcaf6b46c9a507cbb9702e8fc8d27abf501",
    "artist_name": "Isaac Hayes",
    "image_url": "https://i.scdn.co/image/d33a17fc75eaf79c5685f2fc4d751183a18d2fae",
    "artist_id": "3IKV7o6WPphDB7cCWXaG3E",
    "year": 1971
  },
  {
    "song_id": "3dtPq8REcLz2LszvzU79i6",
    "name": "Love Her Madly - New Stereo Mix Advanced Resolution",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/02641359aa5dc8a138c37763fca10d87e680be03",
    "artist_name": "The Doors",
    "image_url": "https://i.scdn.co/image/281cded67d3830cb5c18bc3d60f2509dea2e14fb",
    "artist_id": "22WZ7M8sxp5THdruNY3gXt",
    "year": 1971
  },
  {
    "song_id": "6gvfXd7pEUSPuNEMWwOJUH",
    "name": "Knock Three Times - Digitally Remastered 1998",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/acee9f242ceaa55ff49e987954f721e70dfd3e6a",
    "artist_name": "Tony Orlando & Dawn",
    "image_url": "https://i.scdn.co/image/92051dc6fe819b969cb2b6086e3ed97e0f048218",
    "artist_id": "72NXpYBIaTfEeAAsxXLs0P",
    "year": 1971
  },
  {
    "song_id": "49JLml1RaJugNsjUX4VaCn",
    "name": "Smiling Faces Sometimes",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/eff581554d6d95ccdf8e285732c8bff4de791593",
    "artist_name": "The Undisputed Truth",
    "image_url": "https://i.scdn.co/image/10b72338ccc0126f86c973eb2c00387dffcef407",
    "artist_id": "2tkwWmcZOQPNbbIdknfBsw",
    "year": 1971
  },
  {
    "song_id": "2Bls0igCm79XQQ95IoUbhq",
    "name": "Mr. Big Stuff",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/a14aaa086a45c8b219bf28104f69652e8e26e3b0",
    "artist_name": "Jean Knight",
    "image_url": "https://i.scdn.co/image/95ecc317bd38f4cfe53baac9fc4a42e22b0d7f55",
    "artist_id": "2Tk0pWxTuQgnE5nsUexrLr",
    "year": 1971
  },
  {
    "song_id": "45FLu3nL1iMYXYPzhqLdko",
    "name": "Where Do I Begin - Love Theme from \"Love Story\"",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/84344763e96f12e3f7b59571fe689b4ae888983e",
    "artist_name": "Andy Williams",
    "image_url": "https://i.scdn.co/image/e654ecccff4c3354edb9646b62847a9416720e26",
    "artist_id": "4sj6D0zlMOl25nprDJBiU9",
    "year": 1971
  },
  {
    "song_id": "52ZaEEScAA86ecqxDcHKm4",
    "name": "I Hear You Knocking",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/8d5cc930379182ce420c51b4f730a01515d9e027",
    "artist_name": "Dave Edmunds",
    "image_url": "https://i.scdn.co/image/66cb6e2b15bf17dee7f5760f413425baf9d825d1",
    "artist_id": "65Gh3BfK84aTIugiRCgLBA",
    "year": 1971
  },
  {
    "song_id": "5jvwnpJunnIVm1gLLMviGr",
    "name": "Uncle Albert / Admiral Halsey - 2012 Remaster",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/fb8c6ca7f734855a10dd745015f393b414cb9d90",
    "artist_name": "Paul McCartney",
    "image_url": "https://i.scdn.co/image/59a06934210ac15b9384a1170273cb3b944d06c3",
    "artist_id": "4STHEaNw4mPZ2tzheohgXB",
    "year": 1971
  },
  {
    "song_id": "6DSq5IghuzKhH3AYatSb6h",
    "name": "I Don't Know How To Love Him",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/003f64abca8732c034235796d6f80fd2d3f2402c",
    "artist_name": "Helen Reddy",
    "image_url": "https://i.scdn.co/image/774ce712379e12b4d8b3807f2ca2b7f7ff3cc11d",
    "artist_id": "0Sq7oGrYEe0BDmb13wgjOO",
    "year": 1971
  },
  {
    "song_id": "3DlrvYMG2VRxjX0tQIzv2b",
    "name": "Help Me Make It Through The Night",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/31364b913840055b6ad9df972b3311654425634a",
    "artist_name": "Sammi Smith",
    "image_url": "https://i.scdn.co/image/2b0beea2e7e1512e796c2be91787143433a6f648",
    "artist_id": "5kZDCAD5zLelf8NYHoRSHS",
    "year": 1971
  },
  {
    "song_id": "4jkn5PRfAJoKfXF0OPL7n7",
    "name": "That's The Way I've Always Heard It Should Be",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/f86ac0f5a80d40551da01ae416beed78d4e189f4",
    "artist_name": "Carly Simon",
    "image_url": "https://i.scdn.co/image/16b495fed5129a44aa058a222ac5759e111dd1bb",
    "artist_id": "4FtSnMlCVxCswABUmdhwpm",
    "year": 1971
  },
  {
    "song_id": "4tMBeRRtM16o957kB45t26",
    "name": "One Toke Over The Line",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/dafce1cf9449ddee54c497e3a59f82f57bbc0206",
    "artist_name": "Brewer & Shipley",
    "image_url": "https://i.scdn.co/image/646df3abddbec625c64f6b3bab0292e1db965933",
    "artist_id": "4bx9rfUR9C0H26TEiVCwdi",
    "year": 1971
  },
  {
    "song_id": "2H3YWZ3Q1PNtUA8z0mPpGQ",
    "name": "Indian Reservation",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/b2599b9d76eec149150a52936c7b9d64de185686",
    "artist_name": "Paul Revere & The Raiders",
    "image_url": "https://i.scdn.co/image/30b5fe275261d6e1f6697a83630fc26ed8488c38",
    "artist_id": "32HPpJAhgnGPT3V7UOggzi",
    "year": 1971
  },
  {
    "song_id": "6sykijVtS1m8vxLnkKXrJ5",
    "name": "When You're Hot, You're Hot",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/36f006c746aa2567b5e6001d0ff3cd6fb2664950",
    "artist_name": "Jerry Reed",
    "image_url": "https://i.scdn.co/image/42462a424b4461f74e56811fb6a537b0481d1dbc",
    "artist_id": "3kvy8do7n0qVqvlNFS4yOF",
    "year": 1971
  },
  {
    "song_id": "2SK465W0wbu56rmVNHwVnk",
    "name": "I Am...I Said - Single Version",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/3b5f2a9e665547467ad4f070b33498d1218a9555",
    "artist_name": "Neil Diamond",
    "image_url": "https://i.scdn.co/image/b522484113679a381de3d9767d9b8a53eae06c11",
    "artist_id": "7mEIug7XUlQHikrFxjTWes",
    "year": 1971
  },
  {
    "song_id": "0AcuXI6KTCmLBoMQz3WKuS",
    "name": "Don't Pull Your Love",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/d4eaeb45a54325146989990a8503e854542d53a2",
    "artist_name": "Hamilton, Joe Frank & Reynolds",
    "image_url": "https://i.scdn.co/image/abe18d48fe88b7ffb2502351e1982a8df21a805c",
    "artist_id": "7vpRSadEAzLs0ikY7gAESe",
    "year": 1971
  },
  {
    "song_id": "002S8hJ6TUBmiSA2pSDtS6",
    "name": "Lonely Days (Remastered Album Version)",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/c9834093036ba3fd3f50aa3dc9842cfb2108778c",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/9ba9a3a360c2f681818fa251fcc4a5800c01b1e3",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1971
  },
  {
    "song_id": "75t6tcHkZDFKLKJwjXFMtq",
    "name": "Treat Her Like A Lady",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/2eb437f5929121ebfc5034bbfb655f1d1f03c2b0",
    "artist_name": "Cornelius Brothers & Sister Rose",
    "image_url": "https://i.scdn.co/image/e5fb54f842ee7a46ca1597a29bd74ea6a1ef2e8f",
    "artist_id": "2GFB1Lc67mbqFXTPCM9XzT",
    "year": 1971
  },
  {
    "song_id": "4bSz90jEZFKHUK7pwTFBes",
    "name": "Superstar",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/a6ed3da05b050a09e630ead007a033b34bbce30b",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/e78c073ff39c02004a3fb8ec3ce0336ccd4e8d60",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1971
  },
  {
    "song_id": "03DQO1cC5RzTWRxJLzJCgy",
    "name": "I Love You for All Seasons",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/36312d6f3a641e29d77fffecab478612b40148ae",
    "artist_name": "The Fuzz",
    "image_url": "https://i.scdn.co/image/01c1598d3568196acd119ce0f45fbc6138e9d556",
    "artist_id": "052NpRSiz4SQTsFnvwMP6t",
    "year": 1971
  },
  {
    "song_id": "31IBsxTgHHKRGSEjgYEUdp",
    "name": "Beginnings",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/f259fa65ef09071d9818abb7918737c10331e90c",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/3879e5319c5ece1464b21bd1d2761d34e07cc658",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1971
  },
  {
    "song_id": "4CeXXn71DG2X3i3bq3PK53",
    "name": "Another Day - 2012 Remaster",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/c3ae77319306bfc12e5eee7e524c00a3f64e44cc",
    "artist_name": "Paul McCartney",
    "image_url": "https://i.scdn.co/image/59a06934210ac15b9384a1170273cb3b944d06c3",
    "artist_id": "4STHEaNw4mPZ2tzheohgXB",
    "year": 1971
  },
  {
    "song_id": "5766QQQ0ssrdVAe7VGE2BM",
    "name": "One Less Bell to Answer",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/2ad36db3fa7fec0873a5d60a988fc2c272e62d0e",
    "artist_name": "The 5th Dimension",
    "image_url": "https://i.scdn.co/image/438d2cbf7246f7092eacaf46e60bf4e96feca11f",
    "artist_id": "1UUYAQ9LiRsZF0ZukQNWXM",
    "year": 1971
  },
  {
    "song_id": "5KWXi2eCYsC6ofIILjvG4V",
    "name": "Signs",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/959e1b5cb976f5cb175dc5a1ba3b488953652519",
    "artist_name": "Five Man Electrical Band",
    "image_url": "https://i.scdn.co/image/db0b924243209488d1bed0bd818cd5d44b30bd88",
    "artist_id": "1GjVNyMzPjdBuip6Xanllu",
    "year": 1971
  },
  {
    "song_id": "4Zibbwj1STIuxLT3WdEY67",
    "name": "Wedding Song (There Is Love) - Remastered",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/23fe851865a66d4dea959e443a890c64549b05d6",
    "artist_name": "Noel Paul Stookey",
    "image_url": "https://i.scdn.co/image/ff05c2ea2aa9cc72696084714e0c8520d795fde2",
    "artist_id": "0jOImUkQkjgfBfDeCNQWyh",
    "year": 1971
  },
  {
    "song_id": "0I7ppAteWHCbfkEfOAbtIf",
    "name": "Do You Know What I Mean",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/7aeab46a2951befa6bc13074ef8cbb75b86aec17",
    "artist_name": "Lee Michaels",
    "image_url": "https://i.scdn.co/image/c0374dc270bf82f3c563b97ee99d8afba95fde8f",
    "artist_id": "6f07NU6NNMLQYXIABUv7z3",
    "year": 1971
  },
  {
    "song_id": "2FNgQqDw0bO4DHRmY55lQA",
    "name": "Mercy Mercy Me (The Ecology) - Single Version (Mono)",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/977ed8d03fe3f53cb2b9b66b6ff6104001b14d05",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/8987671c180685a51e60dc93a6b4951d10b160dc",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1971
  },
  {
    "song_id": "3zzhwZsn0nUDgSnGA3QPe0",
    "name": "Trapped By A Thing Called Love",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/1faa3950f34ff9c651a73c14ff399c85918cb3ad",
    "artist_name": "Denise LaSalle",
    "image_url": "https://i.scdn.co/image/1063c412db18b6a255907c498e7bc6f5bbc6ccbc",
    "artist_id": "0GVO1kqJHVxOarPgex9K15",
    "year": 1971
  },
  {
    "song_id": "3cq6mwsjgygbwRIi9wVPGv",
    "name": "Mr. Bojangles - 2001 Digital Remaster",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/db967eff3a7a18019d5f9c7ceacd643a84e4cadf",
    "artist_name": "Nitty Gritty Dirt Band",
    "image_url": "https://i.scdn.co/image/1abde4d9eaa49c5a8ec636e96d13715b093e9ff7",
    "artist_id": "7y70dch6JuuuNnwlsOQvwW",
    "year": 1971
  },
  {
    "song_id": "5sj71X3Em004zkOXXQpUcG",
    "name": "I Just Want To Celebrate",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/82c2ef250994d1f687ae588445657a533174abad",
    "artist_name": "Rare Earth",
    "image_url": "https://i.scdn.co/image/43f49d27b16af1fa43c62ffdf3db9e4ae1136a8d",
    "artist_id": "6eEsIl4wosvhuWI0mfXxAF",
    "year": 1971
  },
  {
    "song_id": "0cJPLFrlV7TTCyPLupHzcH",
    "name": "Won't Get Fooled Again - Original Album Version",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/ba53ccbfe13f3d1c2114cfd88ae3fe9bd55dae72",
    "artist_name": "The Who",
    "image_url": "https://i.scdn.co/image/c22e5297f8dcb45774503563523201552bb1f53e",
    "artist_id": "67ea9eGLXYMsO2eYQRui3w",
    "year": 1971
  },
  {
    "song_id": "7vHKtFcrgJPukoK9Uk31jf",
    "name": "It's Impossible",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/0fec4818aa49a1fd7bc137c9068734c02f6283bb",
    "artist_name": "Perry Como",
    "image_url": "https://i.scdn.co/image/d541f41f2e5d34a405d2ea7879dd95a8b04a2346",
    "artist_id": "5v8jlSmAQfrkTjAlpUfWtu",
    "year": 1971
  },
  {
    "song_id": "1LbGvQCflPySYbKYehX3xh",
    "name": "Draggin' The Line - Single Version",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/583df43942a5ea4fa0c6d2b477cb919dcccd2450",
    "artist_name": "Tommy James",
    "image_url": "https://i.scdn.co/image/ce7af47719174a73a11b655c0de6adf9b6cba10f",
    "artist_id": "7eRORqxEbt7ftbaaWP0k5v",
    "year": 1971
  },
  {
    "song_id": "1BphrvqrOj0y8C0umSSrCP",
    "name": "One Bad Apple",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/add16504d83ab0ab81158605fdd19f714765521f",
    "artist_name": "The Osmonds",
    "image_url": "https://i.scdn.co/image/5424cb6c7bf9f867f2264229930d3605a4f7cc56",
    "artist_id": "5fU6lODhpw3GEGGJuaDprR",
    "year": 1971
  },
  {
    "song_id": "4kjOWauuYptQVSnuac4dsr",
    "name": "Go Away Little Girl",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/1c8901c193dbd490577a55c9f1ebfa3588179074",
    "artist_name": "Donny Osmond",
    "image_url": "https://i.scdn.co/image/9d904ee14b131d0cdab01e88d974299bf8f395c9",
    "artist_id": "5ZEAzHE2TzAwUcOj6jMIgf",
    "year": 1971
  },
  {
    "song_id": "5HTJCB3DvUYWTs8FLaxCBe",
    "name": "Never Ending Song Of Love",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/fe438d514d083ea2d11693560f1d64b892ee63d3",
    "artist_name": "Delaney & Bonnie",
    "image_url": "https://i.scdn.co/image/98bef8cb2f796e981dd0cccd7ad5639249e243e5",
    "artist_id": "6dTCMsjfH2odCFlNb36SCG",
    "year": 1971
  },
  {
    "song_id": "7Mu6XXQ6PHvMeVEMGN78Oi",
    "name": "What's Going On",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/cd4f3896d35c68ba4c803eb414a8dd1eef2030ba",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/141107a353ef807ffa870216218ad0f9d22ab482",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1971
  },
  {
    "song_id": "0SIQhekRTk2d66HEmxcRmn",
    "name": "Here Comes That Rainy Day Feeling Again",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/3c457f4c764965a2112d56cee1485fbd7d2d0288",
    "artist_name": "The Fortunes",
    "image_url": "https://i.scdn.co/image/d8e273898ad2f12ef4056a0d3a525da4a290ad08",
    "artist_id": "4GpIeE34rBNFppvYsWle9c",
    "year": 1971
  },
  {
    "song_id": "3BrUr1rwAYJbf6sLlDf7WN",
    "name": "If I Were Your Woman",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/0a25ff06828863ef0ffb6d2f28df5a467277c7e1",
    "artist_name": "Gladys Knight & The Pips",
    "image_url": "https://i.scdn.co/image/0a4cbdb758e83db5719c55adcebb99af4da82375",
    "artist_id": "0TF2NxkJZPQoX1H53rEFM1",
    "year": 1971
  },
  {
    "song_id": "4VD9bzVQXXv0K6fIgX3WRX",
    "name": "For All We Know",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/c7eb81c08f8b0849478bcb4d2ff5c22165180c71",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/10eb93b78a035364bb9f4c18d10d0ce7536266aa",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1971
  },
  {
    "song_id": "4CPBiIefLqqQtw1yeFTNF2",
    "name": "Doesn't Somebody Want to Be Wanted",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/59aede8a35b06f70a802fbc197b03f429317b325",
    "artist_name": "The Partridge Family",
    "image_url": "https://i.scdn.co/image/0e91ee2ea7ba55d59b389eb4292566124c311a51",
    "artist_id": "7u1mqP3ykglpCB2c1p1p5I",
    "year": 1971
  },
  {
    "song_id": "5MuS4AgkzzTKmAfq4hs6yb",
    "name": "Gypsies, Tramps And Thieves",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/8da428ae583d386c459e034ee158dc5c97ca0b6f",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/00d4d675801208b6194a3600c510e51bb1e99c8a",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1971
  },
  {
    "song_id": "0HdxcIyqnsmJ3ZmWallpgS",
    "name": "Right on the Tip of My Tongue",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/ddb7bbb9422b3220eeeb12d601cc515727637f19",
    "artist_name": "Brenda",
    "image_url": "https://i.scdn.co/image/553b31f364c8bd45b9ec648af6d87893b1e71ede",
    "artist_id": "4LPbxs7t0LiJT9FPylZH8r",
    "year": 1971
  },
  {
    "song_id": "6yj4nR2aM6Oh0bfQiPPKXc",
    "name": "Stay Awhile",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/14cd0d8192b4deb4ff676fb36ba6b8ea0b89d38e",
    "artist_name": "The Bells",
    "image_url": "https://i.scdn.co/image/cdf1480435ffba1d087d7fc316475bd5895593cd",
    "artist_id": "66akX7UszLtTZqTTdcFvBL",
    "year": 1971
  },
  {
    "song_id": "2xbaxLRmG4w3PKIa73fFF0",
    "name": "Just My Imagination (Running Away With Me)",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/d7616aa8daeb30886ec0d47273afdcc3ca2e0bc6",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/d64928134a082211af026349b4460d88817e7b41",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1971
  },
  {
    "song_id": "74yAp1bdvCL5FBrt6OGb4J",
    "name": "Bridge Over Troubled Water",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/4021bd0a506c519d03b2fefd3d19e27ba8941272",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/a61373ca38b7a51fa062e28e9b87e5fb727e9a31",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1971
  },
  {
    "song_id": "2ubaaf6R6wQFKjRknXK68B",
    "name": "Wild World",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/277e4f57cf5cc58e05da7a29391044fce6c5a378",
    "artist_name": "Cat Stevens",
    "image_url": "https://i.scdn.co/image/c66e8ff4fa55fe6f1505e42d459c699a26559b22",
    "artist_id": "08F3Y3SctIlsOEmKd6dnH8",
    "year": 1971
  },
  {
    "song_id": "2rVSn1QuJJOWJ9k8mfpLMf",
    "name": "Funky Nassau - , Part 1",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/2513cd5937893008094f2a7747cfa2bb2ae8889c",
    "artist_name": "The Beginning Of The End",
    "image_url": "https://i.scdn.co/image/4ecd3117c86208931e02fb1ffd9034f2a5c09b89",
    "artist_id": "5dS6VBacLLPyLDAfK3B0OR",
    "year": 1971
  },
  {
    "song_id": "7cyLwgSVf3AnKXetNRWiTa",
    "name": "Don't Knock My Love, Pt. 1",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/daf8eac7b359dd40a1b9cccc0cd25ca256f9d332",
    "artist_name": "Wilson Pickett",
    "image_url": "https://i.scdn.co/image/17a91a40ff125b36b918b1835a35f52cec7cf9a2",
    "artist_id": "0N5PyKJzS3M1XNlaCL7bbE",
    "year": 1971
  },
  {
    "song_id": "6J7uQ9vLtBqNikEhlKUOnu",
    "name": "Whatcha See Is Whatcha Get - Single Version",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/862f6c17cd95867fea48c413c7c550316a3e04da",
    "artist_name": "The Dramatics",
    "image_url": "https://i.scdn.co/image/900374e4dc7d23bf7689ddbccb1553349f7db000",
    "artist_id": "2W8UTum7bU7ue6m0r14H97",
    "year": 1971
  },
  {
    "song_id": "3MFRnzKcYY7nhOSOnataxD",
    "name": "Brown Sugar - (Original Single Mono Version)",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/90b5254c7d597953bbdd40fb0f1f88232f413611",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/24af3101b887b0d9dbe33ebed073c742985cc3a6",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1971
  },
  {
    "song_id": "1XboUmE129wpnR0oP3Zd97",
    "name": "Bring The Boys Home",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/f920a1f2027bbf5fad0e529feb320c39302ceafb",
    "artist_name": "Freda Payne",
    "image_url": "https://i.scdn.co/image/e85c55deb7826d29be24f26039c47f2f39c8b025",
    "artist_id": "0701Axu6yvUIoctaKMbDIZ",
    "year": 1971
  },
  {
    "song_id": "3SwDEzQaz19TRWaX5NTWLh",
    "name": "Superstar",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/e29f24643cb42fb1a8668568d0577f9f3ac388c6",
    "artist_name": "Andrew Lloyd Webber",
    "image_url": "https://i.scdn.co/image/9d02741a621063ef5aa4053e26211292c672c562",
    "artist_id": "4aP1lp10BRYZO658B2NwkG",
    "year": 1971
  },
  {
    "song_id": "4ZrSMM8bAeWOJcxNmL2CIX",
    "name": "Temptation Eyes",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/a35fc1eecbfc1b640e6a72bfdd3a41c524cde233",
    "artist_name": "Grass Roots",
    "image_url": "https://i.scdn.co/image/dbf77e93866da3cb402cbc9b9ad4972cbe18830d",
    "artist_id": "4rOdPSldBfGRCEKASB2Sje",
    "year": 1971
  },
  {
    "song_id": "4NLKJfFJl19CnJaEGMGjhl",
    "name": "Liar",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/daecc025780dddcc8794994968d94bc49920e9de",
    "artist_name": "Three Dog Night",
    "image_url": "https://i.scdn.co/image/7d5dc0094de23e5ee269b51219d820ac5edce775",
    "artist_id": "4FAEZeJcsYYBkNq2D3KGTV",
    "year": 1971
  },
  {
    "song_id": "2yIRiDZN7jpo9E1PPRcCXH",
    "name": "Watchin' Scotty Grow - 1991 Digital Remaster",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/5beaf29ceb6731924c67ec1decb9f4b052b2202e",
    "artist_name": "Bobby Goldsboro",
    "image_url": "https://i.scdn.co/image/2357e23690cb713cbd50ef75b3eacaa0ec5e0b61",
    "artist_id": "5gPEo032lzARtzuVqJIm9o",
    "year": 1971
  },
  {
    "song_id": "2lKFPBTIB5CbCNQn8hm3pi",
    "name": "I Woke Up in Love This Morning",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/c8991454b4fd21284863bbed1be7be8bc785d86c",
    "artist_name": "The Partridge Family",
    "image_url": "https://i.scdn.co/image/50072c652ff4d1ffbe97e11b0fa2aa72534355bd",
    "artist_id": "7u1mqP3ykglpCB2c1p1p5I",
    "year": 1971
  },
  {
    "song_id": "2jRClKXhUTfensB80qBU3J",
    "name": "Spanish Harlem",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/ba08b22cae232d85eefa8fcaec856035a46c4317",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/f07df3f531a09d30d0fa1a2a3b6573fe5d91c1b0",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1971
  },
  {
    "song_id": "4o36OjTixw5gsMvwgbdIzv",
    "name": "Yo-Yo",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/252eac91a4348c8e31c17c54e9709befa471ffe1",
    "artist_name": "The Osmonds",
    "image_url": "https://i.scdn.co/image/5424cb6c7bf9f867f2264229930d3605a4f7cc56",
    "artist_id": "5fU6lODhpw3GEGGJuaDprR",
    "year": 1971
  },
  {
    "song_id": "4kFbQJ5yMf19mXe4cLlp3j",
    "name": "Sweet And Innocent",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/732debedd6fd5143c33b3d1818a3ab13e2203314",
    "artist_name": "Donny Osmond",
    "image_url": "https://i.scdn.co/image/9d904ee14b131d0cdab01e88d974299bf8f395c9",
    "artist_id": "5ZEAzHE2TzAwUcOj6jMIgf",
    "year": 1971
  },
  {
    "song_id": "3Dt9y5mIOZt07NfAPCrxlU",
    "name": "Amazing Grace",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/3a4db681ce55c5352c2dc0a6df4c778afd79b82d",
    "artist_name": "Judy Collins",
    "image_url": "https://i.scdn.co/image/ae074a761617c9b5ecda881e36c74794861a8b86",
    "artist_id": "5yzE49FicYiSxN61oaxkNn",
    "year": 1971
  },
  {
    "song_id": "0QnYZ13p5sInUJbJHrcvI3",
    "name": "If You Really Love Me",
    "popularity": 2,
    "preview_url": "https://p.scdn.co/mp3-preview/d7977501ea38d63dc3436ca4f99faf4b6690c93b",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/d00b954b5e12ec3bed49c080cda1b1d3d3e293a2",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1971
  },
  {
    "song_id": "3UuQxwXCIYMhPONWA9HRfy",
    "name": "If Not For You",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/bd91ee785016c547c014f7a4f28a59aacff43dba",
    "artist_name": "Olivia Newton-John",
    "image_url": "https://i.scdn.co/image/c773435fa3c66178e352424a80e9ab0d685a7a0b",
    "artist_id": "4BoRxUdrcgbbq1rxJvvhg9",
    "year": 1971
  },
  {
    "song_id": "54eZmuggBFJbV7k248bTTt",
    "name": "A Horse with No Name",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/3febeb9f3906f277bd8d2c174763cc4fea43fd69",
    "artist_name": "America",
    "image_url": "https://i.scdn.co/image/cdedc350d69f8bbf44d6ee32334e49d7aabccd6c",
    "artist_id": "35U9lQaRWSQISxQAB94Meo",
    "year": 1972
  },
  {
    "song_id": "7kWhdmRYv8CqbWNqfojqVd",
    "name": "Let's Stay Together",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/8e28031cc4084755814f91ef4c26809f44a3d951",
    "artist_name": "Al Green",
    "image_url": "https://i.scdn.co/image/3d57b9d3779740384b15cc5e8c1c24efc2bfaf8d",
    "artist_id": "3dkbV4qihUeMsqN4vBGg93",
    "year": 1972
  },
  {
    "song_id": "0DcrhZ12WcCqruCs8ibXSf",
    "name": "I Can See Clearly Now - Edit",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/b696ed62311a4bdf1ceabbbdca6f72f6c8e292a8",
    "artist_name": "Johnny Nash",
    "image_url": "https://i.scdn.co/image/4d5e672bf29a5eccebf7906cab428f97f32336c8",
    "artist_id": "0nAVvgfE9yI4DwvMiYwk8a",
    "year": 1972
  },
  {
    "song_id": "1QOjPpGoGfzUUjD87ltJzs",
    "name": "Vincent",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/5a49b2ee8e3b1074f31b69f14c8b641ec165f265",
    "artist_name": "Don McLean",
    "image_url": "https://i.scdn.co/image/7e50d9cd22c51f7496071ca1b346d157e7dc89e2",
    "artist_id": "1gRNBaI4yn6wCCTvRhGWh8",
    "year": 1972
  },
  {
    "song_id": "5UwbnHhjnbinJH8TefuQfN",
    "name": "Long Cool Woman (In A Black Dress) - 1999 - Remaster",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/2739e5ed9219d5d51c034724e342fa4aba150c18",
    "artist_name": "The Hollies",
    "image_url": "https://i.scdn.co/image/23c8713ade88c10c585bed30e4d831b2e14e9d15",
    "artist_id": "6waa8mKu91GjzD4NlONlNJ",
    "year": 1972
  },
  {
    "song_id": "0SxFyA4FqmEQqZVuAlg8lf",
    "name": "The First Time Ever I Saw Your Face",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/8d51449461c0cacbb678c4e8f44d9521065638f5",
    "artist_name": "Roberta Flack",
    "image_url": "https://i.scdn.co/image/064db04cd4497903e88a0bbf5eca83a333550883",
    "artist_id": "0W498bDDNlJIrYMKXdpLHA",
    "year": 1972
  },
  {
    "song_id": "3gdewACMIVMEWVbyb8O9sY",
    "name": "Rocket Man (I Think It's Going To Be A Long Long Time)",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/4c983e1dde4c0a36a4821936c100385202d75c44",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/6cb80b7c78d8e71dff62de45f0688e88eb4c3237",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1972
  },
  {
    "song_id": "3ex1wHTtyUV4GyIy80k9AY",
    "name": "Nights In White Satin",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/a8589cbd22e28fe7c4035a8f7fef128852b9e116",
    "artist_name": "The Moody Blues",
    "image_url": "https://i.scdn.co/image/41bab349692c69239298d886d38220748859697f",
    "artist_id": "5BcZ22XONcRoLhTbZRuME1",
    "year": 1972
  },
  {
    "song_id": "2BY7ALEWdloFHgQZG6VMLA",
    "name": "BRANDY",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/b083cab80bfb1eebbb7c5aa9adb5e69a8d2edf99",
    "artist_name": "Looking Glass",
    "image_url": "https://i.scdn.co/image/be4c8b63bf9a517ef84ef6a779502f354f0c8ff9",
    "artist_id": "5jJN1nmKXzRjodMl1THQeI",
    "year": 1972
  },
  {
    "song_id": "1fDsrQ23eTAVFElUMaf38X",
    "name": "American Pie",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/8f1a89d5cfa7c26d308c15f63fbb0f9385e9aa74",
    "artist_name": "Don McLean",
    "image_url": "https://i.scdn.co/image/8d28530dd1cf226a09e7c4e7bddf5b1a0160b85f",
    "artist_id": "1gRNBaI4yn6wCCTvRhGWh8",
    "year": 1972
  },
  {
    "song_id": "4hLk7Bjz1XJWNDiqovvgBW",
    "name": "Sylvia's Mother",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/bd12d35daabe97d7cd8be8e04ee1427553e55eca",
    "artist_name": "Dr. Hook & The Medicine Show",
    "image_url": "https://i.scdn.co/image/9d282d3b83315a13e2d9be49d8127b6bb3afe290",
    "artist_id": "7rYRwXcGWlvYjKF6YtOikN",
    "year": 1972
  },
  {
    "song_id": "0B1zVsLqmV9ibIFdNS5tGs",
    "name": "I Saw The Light",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/281cbca6d3f921217b42a048e9f95f4d3e9ee350",
    "artist_name": "Todd Rundgren",
    "image_url": "https://i.scdn.co/image/9a129203c3ba39a30ee3bf3a8b1bf66d1ac9fa6a",
    "artist_id": "0Lpr5wXzWLtDWm1SjNbpPb",
    "year": 1972
  },
  {
    "song_id": "6uqTETWok9npbL4c9cuLVh",
    "name": "Clean Up Woman",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/3ffcac33005359c1aad3b0ee2d77c7bb7b6123bb",
    "artist_name": "Betty Wright",
    "image_url": "https://i.scdn.co/image/6abb3ab35db7f9842faa3c66f366c46ea3d1248d",
    "artist_id": "0PDqlUGugF5Jt6DZyATvfz",
    "year": 1972
  },
  {
    "song_id": "7jbb0XkDk6cuif7AVu3gdW",
    "name": "The City of New Orleans - Original Version",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/dd8c539ed5257cacdcbc059e5b01ad9ce067e0a1",
    "artist_name": "Arlo Guthrie",
    "image_url": "https://i.scdn.co/image/67a3c800d8bfbfb08b54039791253f506fd228d4",
    "artist_id": "0B6QEFtRnneEzb4iqjI0Nw",
    "year": 1972
  },
  {
    "song_id": "1Q1b8eVkUPGlpSArl8JAVw",
    "name": "Heart Of Gold - Remastered",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/097a0830e57f327e1af361ea9a365961b5e07104",
    "artist_name": "Neil Young",
    "image_url": "https://i.scdn.co/image/17829120123441b61bd559c23da0f8653994e5a5",
    "artist_id": "6v8FB84lnmJs434UJf2Mrm",
    "year": 1972
  },
  {
    "song_id": "0Z97JEXHiXXHpc4blFKQc6",
    "name": "You Don't Mess Around With Jim",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/1e34ec20bd901961de9fd1f4789649b91350ec97",
    "artist_name": "Jim Croce",
    "image_url": "https://i.scdn.co/image/60a8c842a4e57c8b64897eba897fe7ec543c2dbc",
    "artist_id": "1R6Hx1tJ2VOUyodEpC12xM",
    "year": 1972
  },
  {
    "song_id": "7tAKN3F0RnGzNCQQMQSqMR",
    "name": "I'm Still In Love With You",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/5a23e1b214bacbe673ab29b2472cf4b31ff8ab9f",
    "artist_name": "Al Green",
    "image_url": "https://i.scdn.co/image/4fa437900ccb4cc7f4781038b7338445d2c08f77",
    "artist_id": "3dkbV4qihUeMsqN4vBGg93",
    "year": 1972
  },
  {
    "song_id": "3DkqEHCCqlkknDfksHWWu0",
    "name": "Everybody Plays the Fool",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/2f4c32ced6d819ba54ab84bb1cb0c3970c8e0d3e",
    "artist_name": "The Main Ingredient",
    "image_url": "https://i.scdn.co/image/a0fa27783d5ae3f5c4c2c74316512922830959c8",
    "artist_id": "6vuD08WKtmp1yc7kQx1rBm",
    "year": 1972
  },
  {
    "song_id": "7ws9OSmWx1txRJpavsfSIm",
    "name": "Betcha By Golly, Wow",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/aa86a5235355cbe802c71214a13338464a9fc817",
    "artist_name": "The Stylistics",
    "image_url": "https://i.scdn.co/image/7e133770049e0c58206273350e73f221affbaae4",
    "artist_id": "2O0Hw1WSMbskB5tD9aWah3",
    "year": 1972
  },
  {
    "song_id": "52VIdyKqp1pJRSyUQaxKUA",
    "name": "Everything I Own",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/33d66d39119e56b03f8d27aadd130269f06333b6",
    "artist_name": "Bread",
    "image_url": "https://i.scdn.co/image/9f710cf481bea0b96207c8a6f9239ea0130df492",
    "artist_id": "70ZTdbPEcEugBNay4MvxfL",
    "year": 1972
  },
  {
    "song_id": "0ZFqB9g2FujbmMSrjqsL3j",
    "name": "Ben - Single Version",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/3c634e4ef25e19896dfeb79d24e31f0e35621f54",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/c37d3813aeccc73f95d3c0a589d94e39899a50eb",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1972
  },
  {
    "song_id": "2W5UG7uGle3SlZiyzEGxls",
    "name": "Sealed with a Kiss - Single Version",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/cd15ed91a853da522de7dd5791dbbf701062e43f",
    "artist_name": "Bobby Vinton",
    "image_url": "https://i.scdn.co/image/0001270788af82d1b630b33323917f8af070711d",
    "artist_id": "6bOYtKnpLPQSfMpS2ilotK",
    "year": 1972
  },
  {
    "song_id": "6Fac88zhkwXfePhe3tm1ZJ",
    "name": "Morning Has Broken",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/fdedfff69aa682e066e94be7ab2d873298a89535",
    "artist_name": "Cat Stevens",
    "image_url": "https://i.scdn.co/image/9136dfc8e36b34c071ba9013c5a37b6a7b808418",
    "artist_id": "08F3Y3SctIlsOEmKd6dnH8",
    "year": 1972
  },
  {
    "song_id": "4AJdWXct0Gl6HCO7g3RLip",
    "name": "I'll Take You There - Single Version",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/4326115c51d512d6bcf24f52023a78ea268a5a33",
    "artist_name": "The Staple Singers",
    "image_url": "https://i.scdn.co/image/d496ecbe926cc9762c55444917f16348f4618ca2",
    "artist_id": "7xGGqA85UIWX1GoTVM4itC",
    "year": 1972
  },
  {
    "song_id": "4z51Gf7Ppt9Lmu5zmnUIz9",
    "name": "Baby Don't Get Hooked on Me",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/b35e10aa326059b10eca4a17a0666c19054cf078",
    "artist_name": "Mac Davis",
    "image_url": "https://i.scdn.co/image/00437851bc8b2c66eed92dceebf2e752c0d65e48",
    "artist_id": "6HX8AbXUFaYRtlqKb4CCo0",
    "year": 1972
  },
  {
    "song_id": "12LygR44jtSgM5NqOcqXn7",
    "name": "Alone Again (Naturally)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/68dd93ef6f2e105c226cdfcbfe24a70f19becd96",
    "artist_name": "Gilbert O'Sullivan",
    "image_url": "https://i.scdn.co/image/3e6ff7ae8991c74875de5d72868e577520935ac8",
    "artist_id": "4HVmeVTQBgvTuvjB1JYwaf",
    "year": 1972
  },
  {
    "song_id": "0tE6F0Umv0e9E4ZXqX61jQ",
    "name": "Burning Love",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/add184be642bf6e877270973f93bd9abed7a2788",
    "artist_name": "Elvis Presley",
    "image_url": "https://i.scdn.co/image/934ed7bd093f21f579a611ff00d2f1178a074a7b",
    "artist_id": "43ZHCT0cAZBISjO8DG9PnE",
    "year": 1972
  },
  {
    "song_id": "0KpMY3D2G8253VTZbDBUmA",
    "name": "Back Stabbers",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/ceebeac25ee19d77858e0ed02de82726330b1b5b",
    "artist_name": "The O'Jays",
    "image_url": "https://i.scdn.co/image/f4b76625e5faa9e0765037097f2bc64e67126863",
    "artist_id": "38h03gA85YYPeDPd9ER9rT",
    "year": 1972
  },
  {
    "song_id": "6yXcmVKGjFofPWvW9ustQX",
    "name": "The Witch Queen of New Orleans",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/418f78af5ed57dbd2b29fd8c868aff74dd6d6371",
    "artist_name": "Redbone",
    "image_url": "https://i.scdn.co/image/4d5c461e73c6d145e04d1b2aed7f6eb88e38c8bc",
    "artist_id": "0w7HLMvZOHatWVbAKee1zF",
    "year": 1972
  },
  {
    "song_id": "5Hom7ZjELpw1mt84BVnwSp",
    "name": "Look What You Done for Me",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/0a8e037f364568b80a404599e09ab9ae39849c0f",
    "artist_name": "Al Green",
    "image_url": "https://i.scdn.co/image/4fa437900ccb4cc7f4781038b7338445d2c08f77",
    "artist_id": "3dkbV4qihUeMsqN4vBGg93",
    "year": 1972
  },
  {
    "song_id": "7IPKqsNoJAkIINb007DxWw",
    "name": "Slippin' Into Darkness",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/801e2fb05aebfbbf113480668af0cdca0ee7f6bc",
    "artist_name": "War",
    "image_url": "https://i.scdn.co/image/7479a127eac185329d7633a8e6509b5db142f101",
    "artist_id": "3ICyfoySNDZqtBVmaBT84I",
    "year": 1972
  },
  {
    "song_id": "54K7o2yNOMZYCtEDnfD6wI",
    "name": "(If Loving You Is Wrong) I Don't Want to Be Right",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/db0d1a9cffa6811e0bc3086a2939019ecc1a6e0d",
    "artist_name": "Luther Ingram",
    "image_url": "https://i.scdn.co/image/7103649904f5645b086e46ce590ff9392b5291d4",
    "artist_id": "5Pek6HAr0BoHD9P1RuA7d5",
    "year": 1972
  },
  {
    "song_id": "6jGb3Hhy6Dfhci1Eb3au0R",
    "name": "The Candy Man",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/1e5a6e644c4be5be7acf6d1c3f66a9248dc32e6d",
    "artist_name": "Sammy Davis Jr.",
    "image_url": "https://i.scdn.co/image/86d6fef42bd988b15b3551c9ee2ce040f4952311",
    "artist_id": "1NAWG3AngjBXyKbmPaz92D",
    "year": 1972
  },
  {
    "song_id": "3jRphsFOK54vz2hm5SUJxW",
    "name": "Taxi",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/74ca96654c0e095c18d72e244806d166834ce5ed",
    "artist_name": "Harry Chapin",
    "image_url": "https://i.scdn.co/image/7bf55a4293c7e22025aa5bdada4989bd6c520bcb",
    "artist_id": "42q4Ivs7tAiCZ5C7eG5q4c",
    "year": 1972
  },
  {
    "song_id": "595cpLYdeN3s7C4CqJbZus",
    "name": "Joy",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/950fb9b0b016092849dc37a663530a3baa96a495",
    "artist_name": "Apollo 100",
    "image_url": "https://i.scdn.co/image/70ac72fac01554d8f6f9112face1e59a6871db5f",
    "artist_id": "6v0kvTi9pOI2ZFrWGQhdsc",
    "year": 1972
  },
  {
    "song_id": "2OyaNAq8BcGstyzueloqpE",
    "name": "Too Late To Turn Back Now",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/2f2b51142d0c9cb446b787b6245ef33ee76a68ab",
    "artist_name": "Cornelius Brothers & Sister Rose",
    "image_url": "https://i.scdn.co/image/e5fb54f842ee7a46ca1597a29bd74ea6a1ef2e8f",
    "artist_id": "2GFB1Lc67mbqFXTPCM9XzT",
    "year": 1972
  },
  {
    "song_id": "6FsQrRpBLgsrFeAeiQqytm",
    "name": "Bang A Gong [Get It On] (Remastered Single/LP Version)",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/f6f4d1181e46f516e1fcbd618252f424b523361e",
    "artist_name": "T. Rex",
    "image_url": "https://i.scdn.co/image/2c40274c29c98355b0f11dd0c8bdb82e2399cd01",
    "artist_id": "3dBVyJ7JuOMt4GE9607Qin",
    "year": 1972
  },
  {
    "song_id": "1FKgm0A4uSofGd6l1wKID0",
    "name": "Rockin' Robin",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/db3e73d2db27e78ae6e1bc9bd60c85ed3a984b78",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/4aa6cad52e9f21cb29b0bb1a60dcbf2c8f1dd4bd",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1972
  },
  {
    "song_id": "3sZlKnnY9H5VnHkEWQVpJg",
    "name": "Song Sung Blue - Single Version",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/2af66d79ed92cb07ad086dabba1196e7df8b01e3",
    "artist_name": "Neil Diamond",
    "image_url": "https://i.scdn.co/image/a1cfb8836321e7991002fa8e9db38ab4c9cc9d65",
    "artist_id": "7mEIug7XUlQHikrFxjTWes",
    "year": 1972
  },
  {
    "song_id": "7J5tyfg3OYVNR97KH66ovw",
    "name": "Garden Party",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/bd43808a74cd9d6a7fbe77d84a7dc0b3874ed6c8",
    "artist_name": "Ricky Nelson",
    "image_url": "https://i.scdn.co/image/314ca2f8c110ab3571f56e89261e79dc11709cbc",
    "artist_id": "73sSFVlM6pkweLXE8qw1OS",
    "year": 1972
  },
  {
    "song_id": "3Xd39NB1rifow2RDNVgSFp",
    "name": "Sunshine (Go Away Today) - Single/",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/d6283a34a5df1f4ca6581d6f0280fb1b07e5ea71",
    "artist_name": "Jonathan Edwards",
    "image_url": "https://i.scdn.co/image/276332ef785a3d1a24f6291de2b9ee2393f89ab6",
    "artist_id": "7gyVuJLsatWofXCAw1SGxM",
    "year": 1972
  },
  {
    "song_id": "4hHsYsooA25tfpurRILNou",
    "name": "Precious And Few",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/bcc22e4aeadbad9377f4b4f804186e392f97f4d6",
    "artist_name": "Climax",
    "image_url": "https://i.scdn.co/image/19d1731ed59e88393d0bfcfa7167ed11b36b4876",
    "artist_id": "1OzPxKCB5R3MLxvkdGN03X",
    "year": 1972
  },
  {
    "song_id": "3Vb4zEfLhZokSOYBSZIWpk",
    "name": "Down By The Lazy River",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/c51fe49c9e1ffc8c0331567e217daa9c281df8c3",
    "artist_name": "The Osmonds",
    "image_url": "https://i.scdn.co/image/5424cb6c7bf9f867f2264229930d3605a4f7cc56",
    "artist_id": "5fU6lODhpw3GEGGJuaDprR",
    "year": 1972
  },
  {
    "song_id": "5fbbE7GmFRfXcaqUK4Pecl",
    "name": "I Gotcha",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/d9c3c669a6f70cb611f8554f01024343ff3a109a",
    "artist_name": "Joe Tex",
    "image_url": "https://i.scdn.co/image/3ec66c13844b454fb9c8be7d66af7b2269b2ab2a",
    "artist_id": "5TbXjzD8tYgMD5JU2g2F8q",
    "year": 1972
  },
  {
    "song_id": "7kFRgT3KKxU1y7USF8ZzIX",
    "name": "Starting All Over Again",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/ea94a3468a14e809a85551af06df525470b13ef4",
    "artist_name": "Mel & Tim",
    "image_url": "https://i.scdn.co/image/82015c87d7eb442092ff13c5107b6270d94683f5",
    "artist_id": "2ubLClBEuddw29m7QRx4IL",
    "year": 1972
  },
  {
    "song_id": "02gV5Zc9ctbZxD1uTNIok5",
    "name": "Without You - Remastered",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/047cd3bdbddaa2d8f15cda3bf428e63f0979c2a2",
    "artist_name": "Harry Nilsson",
    "image_url": "https://i.scdn.co/image/a5768aaae5953d5781289eee81cef85ac36f161f",
    "artist_id": "3RTzAwFprBqiskp550eSJX",
    "year": 1972
  },
  {
    "song_id": "1AXZomLoe9Ngojed3H3oTR",
    "name": "Black And White",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/efc6ad704faca50d74adb9e4e04b4df3320ef777",
    "artist_name": "Three Dog Night",
    "image_url": "https://i.scdn.co/image/3daba9372e65ab455d58f5fd4e806b0176b2af0e",
    "artist_id": "4FAEZeJcsYYBkNq2D3KGTV",
    "year": 1972
  },
  {
    "song_id": "4eW5I5uh4VNkhrHEFtHryZ",
    "name": "Go All The Way - 1999 Digital Remaster",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/b785df0872181c6fc29bf69a0057bb1acf08acc5",
    "artist_name": "The Raspberries",
    "image_url": "https://i.scdn.co/image/4054d74daeac9c72773c4cc81a7c742bcc9f6866",
    "artist_id": "7Kkx4dACo6kFSeT9wjfVA5",
    "year": 1972
  },
  {
    "song_id": "7L4G39PVgMfaeHRyi1ML7y",
    "name": "Day Dreaming",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/17207360d767800653658bacc337327dca08e704",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/c561de487772a5abec773d53d05c7710bf5e4e73",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1972
  },
  {
    "song_id": "1ljRPpImL8vWrF3kA1H8V0",
    "name": "Ain't Understanding Mellow",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/84e5d0ff1c9c409bbfa6c064362a3a8b783b0fd0",
    "artist_name": "Jerry Butler",
    "image_url": "https://i.scdn.co/image/a9055906743a66fa577d4887a924b4ed0a098acd",
    "artist_id": "0U2mUJuJkthantMGmsJygP",
    "year": 1972
  },
  {
    "song_id": "3P3mJ3ljphdb52qc1O6PrD",
    "name": "Saturday In The Park - Remastered Version 2007",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/658420a4df0304db39b13a2db34176291606456b",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/d2ef90e565b84fa7f129cf1121de287f8e144a6d",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1972
  },
  {
    "song_id": "0rLRrCTqWVMgv1DpMBSezw",
    "name": "Anticipation",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/44a8c57c4b9d1cafbcc210c2cd9c8f0784491231",
    "artist_name": "Carly Simon",
    "image_url": "https://i.scdn.co/image/4a4598feeb605274a6c501a3406f277f393ed54b",
    "artist_id": "4FtSnMlCVxCswABUmdhwpm",
    "year": 1972
  },
  {
    "song_id": "65ADTP6Q8mratfOIeKHp7e",
    "name": "Where Is The Love",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/d7b40f68a5cba6236c4a6790fb1507e57a208e9e",
    "artist_name": "Roberta Flack",
    "image_url": "https://i.scdn.co/image/1ac2eb86a509676f44de0e24d959a2e65912b776",
    "artist_id": "0W498bDDNlJIrYMKXdpLHA",
    "year": 1972
  },
  {
    "song_id": "3tL9JSTyWRPZnFsswGBOxS",
    "name": "Day By Day",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/d8b9a21aed0913d875661cc33ca9f2b2dbdd81e7",
    "artist_name": "Robin Lamont",
    "image_url": "https://i.scdn.co/image/5a2f57d8d4e3564996c9d380bdb183e3a25f0630",
    "artist_id": "2T7FnxM9ClRkpId7WJ23wr",
    "year": 1972
  },
  {
    "song_id": "53Uge4bp2SiCXZLuhDJYLB",
    "name": "Hot Rod Lincoln",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/c2392299e4b33de663293cc5b0128e19da16a51e",
    "artist_name": "Commander Cody And His Lost Planet Airmen",
    "image_url": "https://i.scdn.co/image/9c00f05e4e6055e95fdb377641321e0588d1598b",
    "artist_id": "4KtaHyqLFlKKb7aghnWBfE",
    "year": 1972
  },
  {
    "song_id": "4OcTjNItuEI4wjl9LOPoIm",
    "name": "Layla - 40th Anniversary / 2010 Remastered",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/ef1f4652eabe1a66515d4727c486a218fdbb03b0",
    "artist_name": "Derek & The Dominos",
    "image_url": "https://i.scdn.co/image/b33cd7de1250fc8641bae3195c345e72edabb977",
    "artist_id": "2rc78XDH9zuJP6bm78lU8Z",
    "year": 1972
  },
  {
    "song_id": "36jM1NDDgpb68KHJyLzzCD",
    "name": "Walkin' In The Rain With The One I Love",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/d91383b8557658c8b2412831eca03c4674ef14f3",
    "artist_name": "Love Unlimited",
    "image_url": "https://i.scdn.co/image/a6b93e77ff146a73a40f9622bf92403ee62a0821",
    "artist_id": "2M4KXzgedR4EKG4qgTeDKa",
    "year": 1972
  },
  {
    "song_id": "7mN6CJxlqQc5t63Ifj9ieQ",
    "name": "The Happiest Girl In The Whole U.S.A.",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/5e7052fff25073e1b03039fbe2f8c51fdb173909",
    "artist_name": "Donna Fargo",
    "image_url": "https://i.scdn.co/image/144b7c7f70493b08f9fd29c6d6275c0e2b0b4f84",
    "artist_id": "4tIQ6BeFRvYApoAyJmaeVC",
    "year": 1972
  },
  {
    "song_id": "4Q0X9NoEcDTAs9dvdvR6r3",
    "name": "Baby Let Me Take You (In My Arms)",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/dafa2cdb560b1a7e49694777bd948a62a30b9c33",
    "artist_name": "The Detroit Emeralds",
    "image_url": "https://i.scdn.co/image/4d947082588a0023366fabc739cbd44743d9c853",
    "artist_id": "51hRQLOySCpDBsBL6C03IZ",
    "year": 1972
  },
  {
    "song_id": "3X1a60iOm7XAJ1ZT1w2TVq",
    "name": "A Cowboy's Work Is Never Done",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/d52678ac099921e07441fec9063678d7d3484f61",
    "artist_name": "Sonny & Cher",
    "image_url": "https://i.scdn.co/image/efa0ebfe4fac02cd98b144fbe2ec93942c76898f",
    "artist_id": "71lGEtP9qYXDsSXjfexTqO",
    "year": 1972
  },
  {
    "song_id": "1XIob9NqnOxBiR76Z6wC5o",
    "name": "Pop That Thang",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/d3d5345d2efcbc5fe17b741e6a7b94983a1d6770",
    "artist_name": "The Isley Brothers",
    "image_url": "https://i.scdn.co/image/1aa7487e3d5a055c33fcf205004ca61010d2a445",
    "artist_id": "53QzNeFpzAaXYnrDBbDrIp",
    "year": 1972
  },
  {
    "song_id": "2eGgMgJj68xiqpxSaatQbR",
    "name": "Jungle Fever",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/a1eb0c0bfa13743d69535e64c5a2c84b95215bc7",
    "artist_name": "Chakachas",
    "image_url": "https://i.scdn.co/image/f34a3d178431076801e0df1308e4297564050933",
    "artist_id": "4V60zkjNyflg40X4uxOTNj",
    "year": 1972
  },
  {
    "song_id": "79oydkSJLNgBVw2m5dGLVP",
    "name": "Hold Your Head Up - Single Version",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/b9ce03f7bd8afdd728a24dbe3f0bb9f7ec335d69",
    "artist_name": "Argent",
    "image_url": "https://i.scdn.co/image/a77f2eefcac0ece15dba4655fa8113ad181e9998",
    "artist_id": "46VosWAvtZsBl7rvxufsWG",
    "year": 1972
  },
  {
    "song_id": "1OW6112tyOP7PuWY4xb6MY",
    "name": "Get On The Good Foot - Pt. 1",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/ac04ae27b368ec0ea46abf25524ae728c09aadc6",
    "artist_name": "James Brown",
    "image_url": "https://i.scdn.co/image/24af2f181959a76a0a521ae7af70a8c84a4bbdbc",
    "artist_id": "7GaxyUddsPok8BuhxN6OUW",
    "year": 1972
  },
  {
    "song_id": "3eA6WNRUSli1tKbey3B4wE",
    "name": "Freddie's Dead (Theme From 'Superfly') - Single Mix Version",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/9f1a71acbdd9522db0a96a8fae154bf6ca2c9cce",
    "artist_name": "Curtis Mayfield",
    "image_url": "https://i.scdn.co/image/888536ad067b9eb8f568a1c5f63df233e4e552f6",
    "artist_id": "2AV6XDIs32ofIJhkkDevjm",
    "year": 1972
  },
  {
    "song_id": "2paLtmNawlMZGNhppBl6yK",
    "name": "Nice To Be With You",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/c5e9a1d2770a713ef51ca3579fc1341635328662",
    "artist_name": "Gallery",
    "image_url": "https://i.scdn.co/image/03da7a7706145f329f303872917f7358d4c9db15",
    "artist_id": "3dplAkEBq2ujPOBDQFpvEp",
    "year": 1972
  },
  {
    "song_id": "4eZVKeTFygxWPj0L7sgP7W",
    "name": "Family Affair - Single Version",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/7b367b4dfe16ec461366723045c9696d9ec01350",
    "artist_name": "Sly & The Family Stone",
    "image_url": "https://i.scdn.co/image/372bfa4dbab52239c5e7edbab2f93d733d7557c2",
    "artist_id": "5m8H6zSadhu1j9Yi04VLqD",
    "year": 1972
  },
  {
    "song_id": "1COQt0dDaBzeKLbtXsIoAe",
    "name": "Hurting Each Other",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/88e0219a083e5c10b84b7c3dcc471d56ee8c6ea0",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/0766dff0b00abda02e599eef943d1a8d2fe845cc",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1972
  },
  {
    "song_id": "0hbC2CAJidLslKqJ39Lxh4",
    "name": "I'd Like To Teach The World To Sing",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/2e6015d30074f2525f8e20e1d9bde93095390737",
    "artist_name": "The New Seekers",
    "image_url": "https://i.scdn.co/image/70d936146ad4b9605d9d688135796bc527f8a99c",
    "artist_id": "4jrTNltJtTMUfXybDdsHDn",
    "year": 1972
  },
  {
    "song_id": "3s4PJ53Qa9F6RToUqdrMfl",
    "name": "Never Been To Spain - Single Version",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/5fbbf02162c9b870df11641e5a493fa04ed08bc8",
    "artist_name": "Three Dog Night",
    "image_url": "https://i.scdn.co/image/2f51ab7199f6983b2ea4c977190ae42d8d119abf",
    "artist_id": "4FAEZeJcsYYBkNq2D3KGTV",
    "year": 1972
  },
  {
    "song_id": "7HU518KQpBrj32QJiXnKGN",
    "name": "Puppy Love",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/1ffa015f8cefca869cf0cf864f449a63c82c16d7",
    "artist_name": "Donny Osmond",
    "image_url": "https://i.scdn.co/image/9d904ee14b131d0cdab01e88d974299bf8f395c9",
    "artist_id": "5ZEAzHE2TzAwUcOj6jMIgf",
    "year": 1972
  },
  {
    "song_id": "1q1oeOO8LQJH0qWI549cqN",
    "name": "The Way Of Love",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/f7378d394876010552024c02d57c8677fe80bbd6",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/b71e8c3b6f28d779cfc60c952db684aad2018cea",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1972
  },
  {
    "song_id": "4o5GyaeGMgDsrclBsL3au7",
    "name": "Coconut",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/64a8b40e66dab2a9184dd5ae05b2a3d9b35fec3e",
    "artist_name": "Harry Nilsson",
    "image_url": "https://i.scdn.co/image/2c0424236cba5b09a183a9e8000db21aa41c156c",
    "artist_id": "3RTzAwFprBqiskp550eSJX",
    "year": 1972
  },
  {
    "song_id": "3a9dRsu3KpsXR9HHWJKXC1",
    "name": "Outa-Space - Single Version",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/c934b85e1afc141073aa37732ee1277afe86048a",
    "artist_name": "Billy Preston",
    "image_url": "https://i.scdn.co/image/eeeacadfabc001fd0e76edac484a140bdcbe802f",
    "artist_id": "0IecGJbdBeYSOVtSPRehh5",
    "year": 1972
  },
  {
    "song_id": "1TkgnYlGsO7nva2PF97tVM",
    "name": "Motorcycle Mama",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/e908ef652ba2a2b79345968ebd09e31f3d93575b",
    "artist_name": "Sailcat",
    "image_url": "https://i.scdn.co/image/2573753ecb8a36f08e1c941f55fab4dc84281b60",
    "artist_id": "5fgVxnlwlHFhYE2QxuHcWe",
    "year": 1972
  },
  {
    "song_id": "6HH6fmHF2YssfVkRmzcjf0",
    "name": "(Last Night) I Didn't Get To Sleep At All",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/4f318546636c4b5bf312207fc73e538c63742d09",
    "artist_name": "The 5th Dimension",
    "image_url": "https://i.scdn.co/image/8c366c1562e1af87008f68f1f20e0e21f3c72c3e",
    "artist_id": "1UUYAQ9LiRsZF0ZukQNWXM",
    "year": 1972
  },
  {
    "song_id": "5MP3uNKemCRNoHRjaFePLL",
    "name": "Speak To The Sky - Original Hit Version",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/02ee1d95a24cef1f8ee54231d845d428132c4952",
    "artist_name": "Rick Springfield",
    "image_url": "https://i.scdn.co/image/5fc46b00973d6fd0da0403811d5d7304a6e165bb",
    "artist_id": "6IFXsrXBpwbIqtOUOiAa3p",
    "year": 1972
  },
  {
    "song_id": "2XxqOSvhneZ8q6JOx1mPsb",
    "name": "In The Rain - Single Version",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/667388bc29f969941c8b766c018b461ea5de19b8",
    "artist_name": "The Dramatics",
    "image_url": "https://i.scdn.co/image/c89ec7c2471e22207e716626b8159a2539836327",
    "artist_id": "2W8UTum7bU7ue6m0r14H97",
    "year": 1972
  },
  {
    "song_id": "3aoDEt6zSuYQ47gzarlaVo",
    "name": "Money - 2011 - Remaster",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/7345b09a764f8d02763fd7e68b52983adfe09b54",
    "artist_name": "Pink Floyd",
    "image_url": "https://i.scdn.co/image/cee9e4e565ff57f338222d8cbf05b7d19080b6e8",
    "artist_id": "0k17h0D3J5VfsdmQ1iZtE9",
    "year": 1973
  },
  {
    "song_id": "2DnJjbjNTV9Nd5NOa1KGba",
    "name": "You're So Vain",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/8739b74866b74d2ca535a0dc39ba7763d5869b62",
    "artist_name": "Carly Simon",
    "image_url": "https://i.scdn.co/image/dbbdd5aacf5cdb96017d31b3a99d1e375002f163",
    "artist_id": "4FtSnMlCVxCswABUmdhwpm",
    "year": 1973
  },
  {
    "song_id": "4nXkbcTj3nyww1cHkw5RAP",
    "name": "Long Train Runnin'",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/e825a5d5faf7bd4a01d47fd7664aa412ca079cfa",
    "artist_name": "The Doobie Brothers",
    "image_url": "https://i.scdn.co/image/018ef1c22bc7024fa505917c4521f4de2e1bc9ad",
    "artist_id": "39T6qqI0jDtSWWioX8eGJz",
    "year": 1973
  },
  {
    "song_id": "2BydLQAh7CUIFvSEqAMc4x",
    "name": "If You Want Me To Stay",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/7c355544cbd8e524409c9a5e4b73db749d716286",
    "artist_name": "Sly & The Family Stone",
    "image_url": "https://i.scdn.co/image/b518c69d30b51e80eb201b0e442492d44b566ce6",
    "artist_id": "5m8H6zSadhu1j9Yi04VLqD",
    "year": 1973
  },
  {
    "song_id": "7ufDF5ePKsnxYgP0DAZoZE",
    "name": "Dancing In the Moonlight - Original Recording",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/388f6ab4bc04909e9c2a4732f5eb80b7d79ce76e",
    "artist_name": "King Harvest",
    "image_url": "https://i.scdn.co/image/c9b1445e27521c273adda234291c1c2932ee4fdc",
    "artist_id": "5FHwr1FymaS5kutIEK6e2y",
    "year": 1973
  },
  {
    "song_id": "1b7LMtXCXGc2EwOIplI35z",
    "name": "Stuck In The Middle With You",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/d24bf53ba2dfb7aedc729a2175e9391636a33a4e",
    "artist_name": "Stealers Wheel",
    "image_url": "https://i.scdn.co/image/5dce84ef7f7aa1c80a0ceea96225f9171c03d8e2",
    "artist_id": "7bPU7cvfoD20ixGD9Qnqki",
    "year": 1973
  },
  {
    "song_id": "4DhbiXEuV7JxSR0wuqetTa",
    "name": "Free Ride",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/1732c88f0627e47acade61dc23a79083aba2d784",
    "artist_name": "The Edgar Winter Group",
    "image_url": "https://i.scdn.co/image/085e9dfde98063e87d02b758d9b718ec84a9f7de",
    "artist_id": "7j9PMegEgVN1fNp8NZXNCI",
    "year": 1973
  },
  {
    "song_id": "1GcVa4jFySlun4jLSuMhiq",
    "name": "Angie - 2009 Re-Mastered Digital Version",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/8f0993c26c19148caeab7cc1db7364fc8093565d",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/582e4da943f9fb5a000b90175c8d1e5c262e8fcd",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1973
  },
  {
    "song_id": "0I2ELF6uHlL4ABu9aFiou7",
    "name": "Right Place Wrong Time",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/11074f9d82fb5b6dd5c77c29a77413355c57dd14",
    "artist_name": "Dr. John",
    "image_url": "https://i.scdn.co/image/e72414961efdbda5cb70cfd8091dc5e1a6be7d40",
    "artist_id": "320TrJub4arztwXRm7kqVO",
    "year": 1973
  },
  {
    "song_id": "3f0U5NaD1bCk8nmKpn2ZJY",
    "name": "Kodachrome",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/ebf72cf92a5013acf5b58cc5a8d6b1ad1c455761",
    "artist_name": "Paul Simon",
    "image_url": "https://i.scdn.co/image/09fc8fa58ff3eaa1fe901d030bb9a7599cba5481",
    "artist_id": "2CvCyf1gEVhI0mX6aFXmVI",
    "year": 1973
  },
  {
    "song_id": "3XcjIvaZVUFAIdIYZqY9bd",
    "name": "We're an American Band - 2002 - Remastered",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/9f0ac8862a10daf4927e8ca69edfc2f96b763ab0",
    "artist_name": "Grand Funk Railroad",
    "image_url": "https://i.scdn.co/image/c74926229fe9d545c2a8825a11c4e0510ab3d772",
    "artist_id": "0qEcf3SFlpRcb3lK3f2GZI",
    "year": 1973
  },
  {
    "song_id": "6WCeFNVAXUtNczb7lqLiZU",
    "name": "Crocodile Rock",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/560492c1d38fa96f68023c0b71b146d4cab29c07",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/12e733d9b762208e758a368d93b0e663da4e3bbb",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1973
  },
  {
    "song_id": "12UzVR0M7asW2MHAZRJk0I",
    "name": "Tie A Yellow Ribbon Round The Ole Oak Tree - Digitally Remastered 1998",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/80d49e8fb66aa87d465396884069e2c1deb3061e",
    "artist_name": "Dawn",
    "image_url": "https://i.scdn.co/image/0652ef9550294127ae31831948e466a2e8a53ed5",
    "artist_id": "4sloBbHoVZwuXD9XuPVHUp",
    "year": 1973
  },
  {
    "song_id": "4xxn8GDqs7RUwgZTNznXNp",
    "name": "Dueling Banjos",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/46bae21289d00129b9bb73ca4ac7e045825b6a1b",
    "artist_name": "Eric Weissberg & Deliverance",
    "image_url": "https://i.scdn.co/image/4205de33ca32b9461485ec444d6d5e4b1c8e4c7f",
    "artist_id": "53sQEwIpwnPbGWuR1hkh2x",
    "year": 1973
  },
  {
    "song_id": "6tunhVGD8C05MZNjSVIsjw",
    "name": "It Never Rains in Southern California",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/219f5a13d2417cc8514499f02c24f5deaa3f8b51",
    "artist_name": "Albert Hammond",
    "image_url": "https://i.scdn.co/image/6f798c899e054faf9a3775570630c3b61877a173",
    "artist_id": "34E3csCxpXunPGEkOVVX2g",
    "year": 1973
  },
  {
    "song_id": "4X8IS5VQYmbnthia8GWlPA",
    "name": "Here I Am (Come And Take Me)",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/05e642c0536fa589bf52e05c072c4cec07e7e2f8",
    "artist_name": "Al Green",
    "image_url": "https://i.scdn.co/image/974165c927fedbf1c27154941e5a97c5092c3c28",
    "artist_id": "3dkbV4qihUeMsqN4vBGg93",
    "year": 1973
  },
  {
    "song_id": "1Wjf1DUearGU14h4Ij0NLv",
    "name": "That Lady (Pt. 1 & 2)",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/253200516feeab3ca6445f8ebe7bfb57aee29531",
    "artist_name": "The Isley Brothers",
    "image_url": "https://i.scdn.co/image/c496a86988ee76520188de5d75a9037bd9fa2574",
    "artist_id": "53QzNeFpzAaXYnrDBbDrIp",
    "year": 1973
  },
  {
    "song_id": "1F3n7rwgq0v4d2iW6IyV74",
    "name": "Break Up to Make Up",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/7f306bd9d65c23b39d2818253af6e0c1a44bb7d9",
    "artist_name": "The Stylistics",
    "image_url": "https://i.scdn.co/image/7e133770049e0c58206273350e73f221affbaae4",
    "artist_id": "2O0Hw1WSMbskB5tD9aWah3",
    "year": 1973
  },
  {
    "song_id": "2FdPOkoiwyV3CuihliyNTF",
    "name": "Yesterday Once More",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/78c66db7251f1cdb0c570e43d40c1d8bb83ebf69",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/10eb93b78a035364bb9f4c18d10d0ce7536266aa",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1973
  },
  {
    "song_id": "76Gcy0ltMutGaK8oizgdrF",
    "name": "Neither One Of Us (Wants To Be The First To Say Goodbye)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/c184de21f42fa6a4f24aa3d2eed821012b0c5769",
    "artist_name": "Gladys Knight & The Pips",
    "image_url": "https://i.scdn.co/image/6fe240b0d09716841e2e84e8039f0343912775bd",
    "artist_id": "0TF2NxkJZPQoX1H53rEFM1",
    "year": 1973
  },
  {
    "song_id": "2BstRQGodshjGpeDGQiNgo",
    "name": "Do It Again",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/723638b74c4208aa5a122226312c51419e4ca744",
    "artist_name": "Steely Dan",
    "image_url": "https://i.scdn.co/image/14af27f9d5654dd8a511c6b35cb9d03e2e540b37",
    "artist_id": "6P7H3ai06vU1sGvdpBwDmE",
    "year": 1973
  },
  {
    "song_id": "7CQQBWqFfROA5RWyY04ifv",
    "name": "Diamond Girl",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/6543908affaccc7795f7279cfd1be9c9229c0c56",
    "artist_name": "Seals and Crofts",
    "image_url": "https://i.scdn.co/image/6c027b8cd3d4c19c697c5c8a72dd62c96006ff3b",
    "artist_id": "6jdObwsrIjSRnBbMw6lPBj",
    "year": 1973
  },
  {
    "song_id": "14nBn3kUcfhDnGyKRUrVHr",
    "name": "Bad, Bad Leroy Brown",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/3a7d744ebb8fb121d5fe0b23bfc525b166452dc0",
    "artist_name": "Jim Croce",
    "image_url": "https://i.scdn.co/image/422f1b1861969d41c70fd2846252c7a071c076dc",
    "artist_id": "1R6Hx1tJ2VOUyodEpC12xM",
    "year": 1973
  },
  {
    "song_id": "55iDwZfpaU7ytsadT7BDVE",
    "name": "So Very Hard To Go",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/27027c0b14f098561d36bd531063d16bae7fe623",
    "artist_name": "Tower Of Power",
    "image_url": "https://i.scdn.co/image/bea7f79a774d6e44075fcf42c350d67496e27ee4",
    "artist_id": "0JCxGVxsISZzJHJPUOtceB",
    "year": 1973
  },
  {
    "song_id": "1I7zHEdDx8Ny5RxzYPqsU2",
    "name": "Reelin' In The Years",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/f4b6c3a6586d6d1d22f53230fb25f3bedf53623d",
    "artist_name": "Steely Dan",
    "image_url": "https://i.scdn.co/image/14af27f9d5654dd8a511c6b35cb9d03e2e540b37",
    "artist_id": "6P7H3ai06vU1sGvdpBwDmE",
    "year": 1973
  },
  {
    "song_id": "7nzX4yuVPpx1n5fy20vFyU",
    "name": "Midnight Train to Georgia",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/a788a9da74f0855f9634ab0c2a5a5896bd59a75d",
    "artist_name": "Gladys Knight & The Pips",
    "image_url": "https://i.scdn.co/image/07b85b97936188de61a54dbd1a07e4183bd8b385",
    "artist_id": "0TF2NxkJZPQoX1H53rEFM1",
    "year": 1973
  },
  {
    "song_id": "5lQxMlCMTzCj3j4vyzdl8T",
    "name": "Danny's Song",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/06f9ef73b14429f1f54007165891da1725d1df74",
    "artist_name": "Anne Murray",
    "image_url": "https://i.scdn.co/image/2eb15f763ad3107143f7f7a13af3c2a174d3cca0",
    "artist_id": "7d7q5Y1p2QWS4QRAhTQR5E",
    "year": 1973
  },
  {
    "song_id": "3X9MydsGl2B8LbY8rpANJT",
    "name": "Funky Worm",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/16748a3fb4c25ec0a12d0801722329e2b6741b40",
    "artist_name": "Ohio Players",
    "image_url": "https://i.scdn.co/image/fb6bcc222bd774bb8d81d866617e102fab786e53",
    "artist_id": "6m30rs1IQqnWqV5nKMpU7U",
    "year": 1973
  },
  {
    "song_id": "36oetAab3pKoxcKuFhvSVo",
    "name": "Your Mama Don't Dance",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/bd3fdcf10114fb65f938205d868fefd62dee233e",
    "artist_name": "Kenny Loggins",
    "image_url": "https://i.scdn.co/image/88b7309f27204a4fd1f82e5e69196f03afa59f3d",
    "artist_id": "3Y3xIwWyq5wnNHPp5gPjOW",
    "year": 1973
  },
  {
    "song_id": "1BvJmtaXsqtH438BcDPeBb",
    "name": "Daniel",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/6fa5043aded1ca775226ae4e87c6d7180b5e2df9",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/12e733d9b762208e758a368d93b0e663da4e3bbb",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1973
  },
  {
    "song_id": "0dMd4rilfd6gPbXaLpNYhu",
    "name": "Higher Ground",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/0ec89fa3e44696deac84a8951d81302f22183749",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/3bc429d4de962802b907042674970ac1f7f819ca",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1973
  },
  {
    "song_id": "7MuMALGZV3KhVA9AS9TFup",
    "name": "Boogie Woogie Bugle Boy",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/55bd9646e41c0f128a19b8006c0103a321e90063",
    "artist_name": "Bette Midler",
    "image_url": "https://i.scdn.co/image/aba638b2967fbf441b2f4e0405fdae3ff926f2c0",
    "artist_id": "13y0kncDD4J9wxCyfKr10W",
    "year": 1973
  },
  {
    "song_id": "7a3FSn0j9aPPiOdLSGDPH5",
    "name": "Monster Mash",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/f3dbba34160d4c810a41f35ec5e864d487d16e04",
    "artist_name": "Bobby \"Boris\" Pickett",
    "image_url": "https://i.scdn.co/image/f4f07846ec7f44e94ab6d079552fb0e65bd273cb",
    "artist_id": "42MRYPhQfcEXqb18dl5ERX",
    "year": 1973
  },
  {
    "song_id": "1AAZOa85URJa2BHs4VBRGF",
    "name": "Superstition - Single Version",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/902a554bcd8e16f3fffa5c324db2a1d0314acd14",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/fd21705e01ea1232643242ec0306a79e3e9fbe85",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1973
  },
  {
    "song_id": "1ISpRMqSGcX3d4L3aETPT9",
    "name": "My Maria",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/87f49d7bbdcd76812871398d6205d6d9524acd73",
    "artist_name": "BW Stevenson",
    "image_url": "https://i.scdn.co/image/d46eefe6d2d41f9dd0423cdec16c97c5a9ef313e",
    "artist_id": "2Jem8aBWeCRXUog23b2a0i",
    "year": 1973
  },
  {
    "song_id": "4NRFiS0pHjTFDjOm1LWTZj",
    "name": "Behind Closed Doors",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/d6f1edbe7b26b5b3c8fc29366135cac9997365bb",
    "artist_name": "Charlie Rich",
    "image_url": "https://i.scdn.co/image/343f44031039f391172668842757a80908f93720",
    "artist_id": "218kRJZ7FJs0hWIk8Ynzhz",
    "year": 1973
  },
  {
    "song_id": "2l0Z9fY1E8Bep8opmhmDhq",
    "name": "Frankenstein",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/08399f199d3d394a8e610c30ad9d746c6cc03e9d",
    "artist_name": "The Edgar Winter Group",
    "image_url": "https://i.scdn.co/image/085e9dfde98063e87d02b758d9b718ec84a9f7de",
    "artist_id": "7j9PMegEgVN1fNp8NZXNCI",
    "year": 1973
  },
  {
    "song_id": "2TtUTMm65xZeqmF721Txfk",
    "name": "Don't Expect Me To Be Your Friend",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/9cbf91c5f3d671fb7d15a4124d64d781f9e6986b",
    "artist_name": "Lobo",
    "image_url": "https://i.scdn.co/image/ca2fa4121a329ec8f005b12eb6be47a73b517f2d",
    "artist_id": "1sldhz8tzC100cRAdfnMht",
    "year": 1973
  },
  {
    "song_id": "5F4ksIKmuXEtgBf6SfEQRr",
    "name": "Loves Me Like a Rock",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/e49c511fc81e59433f10e07d40efd3d035b41b81",
    "artist_name": "Paul Simon",
    "image_url": "https://i.scdn.co/image/09fc8fa58ff3eaa1fe901d030bb9a7599cba5481",
    "artist_id": "2CvCyf1gEVhI0mX6aFXmVI",
    "year": 1973
  },
  {
    "song_id": "0Y2L2bd7T8JFlvB602sMIw",
    "name": "Superfly",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/bff8cac03358a0c7beaa0f8321b85118e1c44372",
    "artist_name": "Curtis Mayfield",
    "image_url": "https://i.scdn.co/image/888536ad067b9eb8f568a1c5f63df233e4e552f6",
    "artist_id": "2AV6XDIs32ofIJhkkDevjm",
    "year": 1973
  },
  {
    "song_id": "4E5tZYJujvISFewOvXN3Ph",
    "name": "Delta Dawn",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/bcddcf6c59d53de4abb2a1ab97fdb405afdd3704",
    "artist_name": "Helen Reddy",
    "image_url": "https://i.scdn.co/image/ba5219764143f4935e6e6085d6d2d6050f826d64",
    "artist_id": "0Sq7oGrYEe0BDmb13wgjOO",
    "year": 1973
  },
  {
    "song_id": "73etijhz7pV4Wx7GTANLpq",
    "name": "Natural High - Single Version",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/7637c0da6ea9d58d920129c979171247ddf89754",
    "artist_name": "Bloodstone",
    "image_url": "https://i.scdn.co/image/2b1e3987948cf06fc41e2d1f89eb21013e2fb7a3",
    "artist_id": "7z1zaOeWiHpl4SD2N9ZBgk",
    "year": 1973
  },
  {
    "song_id": "5SAUIWdZ04OxYfJFDchC7S",
    "name": "Smoke On The Water",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/ab88b8135eeed87413360375374e5232895cebd0",
    "artist_name": "Deep Purple",
    "image_url": "https://i.scdn.co/image/598a16a4bf12cafb89c894509b8126b2ef738e16",
    "artist_id": "568ZhdwyaiCyOGJRtNYhWf",
    "year": 1973
  },
  {
    "song_id": "6HKEdxemkgjaoPVwYYv80F",
    "name": "You Are The Sunshine Of My Life - Single Version With Horns",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/deefc8a7ca5ce445f8ec8276dbeef36367fbbf40",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/fd21705e01ea1232643242ec0306a79e3e9fbe85",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1973
  },
  {
    "song_id": "10Zbcuxvlig5qUDmuGgEcL",
    "name": "Also Sprach Zarathustra",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/b2106e4d3f13aabc6668f0fe6ebe2e24b6a65955",
    "artist_name": "Deodato",
    "image_url": "https://i.scdn.co/image/d71782db1b547825991e52ba87d34c7defd53ec0",
    "artist_id": "0hE3uA2w3guHeKvuc7BpRr",
    "year": 1973
  },
  {
    "song_id": "1W0eyV8mC2IaGkYEbeUpJy",
    "name": "Sing",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/3bcc1efeb827fb259fdfdf569e41618fe4c7da6f",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/eb26a9f14b968b4adf72bd135e66d63a6cc97c07",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1973
  },
  {
    "song_id": "2BEeIp5vfO4rR2Qs5vkTLL",
    "name": "Pillow Talk",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/5f025a5f05b77ccdf6a86d40a01659314bb11d1f",
    "artist_name": "Sylvia",
    "image_url": "https://i.scdn.co/image/3f8a990bedbbff1bdae2b90edf19eab168bce56e",
    "artist_id": "4MTyRJuQSGVdZQNUI4xRiR",
    "year": 1973
  },
  {
    "song_id": "4UvXfxg1ommzERwd6JVIHv",
    "name": "Stir It Up",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/5482b885c21bc487437ce099c57bff2e8743b632",
    "artist_name": "Johnny Nash",
    "image_url": "https://i.scdn.co/image/4d5e672bf29a5eccebf7906cab428f97f32336c8",
    "artist_id": "0nAVvgfE9yI4DwvMiYwk8a",
    "year": 1973
  },
  {
    "song_id": "722E6CPY8biq4sUnf40u6Y",
    "name": "Why Can't We Live Together",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/25ccd6bf671dd361d076f153b36a9a5b5ba97b51",
    "artist_name": "Timmy Thomas",
    "image_url": "https://i.scdn.co/image/b0aa3b6fd35a6eec96453c4869023b31f59055ca",
    "artist_id": "7JLwBH0X2G8tgHceqvOu5B",
    "year": 1973
  },
  {
    "song_id": "1z0etghLnvdBptJMlzvbFA",
    "name": "Wildflower - Original Hit Version",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/742a0987542917d40a9a463b77275ae4013994ae",
    "artist_name": "Skylark",
    "image_url": "https://i.scdn.co/image/5fc46b00973d6fd0da0403811d5d7304a6e165bb",
    "artist_id": "3j9kYXgECWgIelCSWRVulK",
    "year": 1973
  },
  {
    "song_id": "5l7WMtIUfX4D2XsHYOp4zV",
    "name": "Little Willy",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/dc5f569e067fab22626400dadbb631d55291bc05",
    "artist_name": "Sweet",
    "image_url": "https://i.scdn.co/image/31d2726acd629b7d10498fe44d0dd62089e97ee4",
    "artist_id": "3JaAGmSTpJK35DqWrDUzBz",
    "year": 1973
  },
  {
    "song_id": "5YLVhkyMsAuYKOKihHJo21",
    "name": "Will It Go Round In Circles",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/0fa728a032053eb383d36569bb6ade464d68eaff",
    "artist_name": "Billy Preston",
    "image_url": "https://i.scdn.co/image/a7c8bcca3bd59f9ca491cf8db9d35f20a1159ec8",
    "artist_id": "0IecGJbdBeYSOVtSPRehh5",
    "year": 1973
  },
  {
    "song_id": "2aqb3er36PfC0orJvMUBZU",
    "name": "The World Is A Ghetto",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/11ee89a5e4579799b0219d674dbe2a6b2c4aabcb",
    "artist_name": "War",
    "image_url": "https://i.scdn.co/image/7479a127eac185329d7633a8e6509b5db142f101",
    "artist_id": "3ICyfoySNDZqtBVmaBT84I",
    "year": 1973
  },
  {
    "song_id": "4TZ3fwoNWEKT8btm59eKS0",
    "name": "Rockin' Pneumonia And The Boogie Woogie Flu",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/9d23e6b8924107cb8af1219858c0f0f2900e2ef4",
    "artist_name": "Johnny Rivers",
    "image_url": "https://i.scdn.co/image/17f49bf99d1978fb5c5ac4e9925ab5b305d7c92d",
    "artist_id": "3TiISqKS6ESlMQ4WFfZJw2",
    "year": 1973
  },
  {
    "song_id": "2Hyis7pyTBDyR3zq4GNZCu",
    "name": "Do You Want To Dance",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/7a3dad01e7a51f5278e45e835f19f9559d14bdda",
    "artist_name": "Bette Midler",
    "image_url": "https://i.scdn.co/image/aba638b2967fbf441b2f4e0405fdae3ff926f2c0",
    "artist_id": "13y0kncDD4J9wxCyfKr10W",
    "year": 1973
  },
  {
    "song_id": "62QRbVm7DqR3eoqgT7pEfs",
    "name": "The Morning After - Single Version",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/545680cde62d30b57dd7e33cde19ea00a21b0d22",
    "artist_name": "Maureen McGovern",
    "image_url": "https://i.scdn.co/image/0bfb306839f2db336441dea12be9e3fbf9b62483",
    "artist_id": "3bnf5pPVQFWv762IiavilV",
    "year": 1973
  },
  {
    "song_id": "5kbSslE6zBMZnULSrE4SdZ",
    "name": "Brother Louie",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/be535a0a6fc7bae84b5e86bafd1ccb210635a869",
    "artist_name": "Stories",
    "image_url": "https://i.scdn.co/image/54940218f4f1694655c45b3ba05fdcda5165fc9b",
    "artist_id": "31U8n9SSMOKaUcfi4KnYVT",
    "year": 1973
  },
  {
    "song_id": "1RzoAcFBUKGln5Ljh3JRc9",
    "name": "The Cisco Kid",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/323ca2cb5aac9b52163e08af40cee01b5d125a94",
    "artist_name": "War",
    "image_url": "https://i.scdn.co/image/7479a127eac185329d7633a8e6509b5db142f101",
    "artist_id": "3ICyfoySNDZqtBVmaBT84I",
    "year": 1973
  },
  {
    "song_id": "70MH7MTkVLApy8szKF3T7A",
    "name": "Me and Mrs. Jones",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/d8066705ef4f54426a6d5e065b385f9446766b44",
    "artist_name": "Billy Paul",
    "image_url": "https://i.scdn.co/image/468457180c9008019e1de215354580f162343a15",
    "artist_id": "187xgSpsFH8mMbAcoCW0zE",
    "year": 1973
  },
  {
    "song_id": "57pG9UVXGG8JcIdAVBXYAM",
    "name": "Touch Me In The Morning - First Pressing Single Version",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/9bc4e848c7001eff546212b37253f7ba1c660948",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/36e368ca70e9d9365b59700ec46daa1a64c241b5",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1973
  },
  {
    "song_id": "6iLgu4iVWW9HC7ujcBalvZ",
    "name": "Hocus Pocus - Extended Version",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/2c8db49713cacc8cde54a969b8ac9442c23d2c14",
    "artist_name": "Focus",
    "image_url": "https://i.scdn.co/image/bdc60d8964d63ed3a35ca231d368c722e5a12d04",
    "artist_id": "34my5PBbL8TiTcfJc2YoPD",
    "year": 1973
  },
  {
    "song_id": "4ggEsQIzmiP5cWdOpwmy0A",
    "name": "Oh, Babe What Would You Say - Original Hit Version",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/bb5832990eb50f471e83270d4a18213046d09e3e",
    "artist_name": "Hurricane Smith",
    "image_url": "https://i.scdn.co/image/5fc46b00973d6fd0da0403811d5d7304a6e165bb",
    "artist_id": "2RJ0iPw6LNjlxmbUiB3cfc",
    "year": 1973
  },
  {
    "song_id": "2tux0ui8n2qdHdGpBnkhol",
    "name": "Daddy's Home",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/a720989899aed8c5d0876b5efe6fc12fec88cdb4",
    "artist_name": "Jermaine Jackson",
    "image_url": "https://i.scdn.co/image/941541687a94e7046119c572a51d37986f212db8",
    "artist_id": "1VDurPMbOKrNU8mcMP4X1P",
    "year": 1973
  },
  {
    "song_id": "0pMAjqYq1LGKg0Xe6hxneI",
    "name": "Love Train",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/f5cf028834039e66bd5d4eff8c74f51465ab1966",
    "artist_name": "The O'Jays",
    "image_url": "https://i.scdn.co/image/37dcc870cbe64c3c82344cef844633467564a536",
    "artist_id": "38h03gA85YYPeDPd9ER9rT",
    "year": 1973
  },
  {
    "song_id": "5hQmEgRaLv8H4vADovLgXr",
    "name": "Rocky Mountain High",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/082184a8091cce8bee00f7543ad713703dfdc093",
    "artist_name": "John Denver",
    "image_url": "https://i.scdn.co/image/b966b165710c9c32610bc4f8bd927e72a3f536ca",
    "artist_id": "7EK1bQADBoqbYXnT4Cqv9w",
    "year": 1973
  },
  {
    "song_id": "6wAxiqfZzbxPUuvVan1ZuU",
    "name": "Clair",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/5740fe8be00d2138f56902fa639572def6ba7a48",
    "artist_name": "Gilbert O'Sullivan",
    "image_url": "https://i.scdn.co/image/3e6ff7ae8991c74875de5d72868e577520935ac8",
    "artist_id": "4HVmeVTQBgvTuvjB1JYwaf",
    "year": 1973
  },
  {
    "song_id": "5CNdxdDDEjZGmf0ho9oMDb",
    "name": "Last Song - Original Hit Version",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/a3d0a8e822b3d87d2f8da8b200fad6e096915f54",
    "artist_name": "Edward Bear",
    "image_url": "https://i.scdn.co/image/5fc46b00973d6fd0da0403811d5d7304a6e165bb",
    "artist_id": "5IVNNR05uQhrkBIogJtQD8",
    "year": 1973
  },
  {
    "song_id": "32fJSX7QeEKDoKE87eKD1m",
    "name": "I'm Gonna Love You Just A Little More Baby",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/ee84c2704d091f769b431fe35d447dde9ed8cba5",
    "artist_name": "Barry White",
    "image_url": "https://i.scdn.co/image/7cdc0397df53abf8056f2f93f9b9ca994ce249aa",
    "artist_id": "3rfgbfpPSfXY40lzRK7Syt",
    "year": 1973
  },
  {
    "song_id": "7KLjpW7G2HjBsqSpSyctHo",
    "name": "Papa Was A Rollin' Stone - Single Version",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/0281125bd44769ee597d90f4504973a8a44e7bd5",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/d64928134a082211af026349b4460d88817e7b41",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1973
  },
  {
    "song_id": "0I1MKpbM3y2d4ZglG9Fmwt",
    "name": "Feelin' Stronger Every Day - Remastered Version 2007",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/b1eb5fee334c5688efb56d49bb21542fb2dc9656",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/d2ef90e565b84fa7f129cf1121de287f8e144a6d",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1973
  },
  {
    "song_id": "1IbElKiARztcn0HvvKP28Z",
    "name": "Say, Has Anybody Seen My Sweet Gypsy Rose - Digitally Remastered 1998",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/6bc9262528d4c6c90efdcae98d29cc0e8feea92c",
    "artist_name": "Dawn",
    "image_url": "https://i.scdn.co/image/27537f6e034c80c455ee31ada5ab122e2dd38466",
    "artist_id": "4sloBbHoVZwuXD9XuPVHUp",
    "year": 1973
  },
  {
    "song_id": "2NiNfBpGG1eniniHQXfKJV",
    "name": "Gypsy Man - Single Version",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/0dc9c71b64b6227e3e17f712b96b159bc7e602a4",
    "artist_name": "War",
    "image_url": "https://i.scdn.co/image/7479a127eac185329d7633a8e6509b5db142f101",
    "artist_id": "3ICyfoySNDZqtBVmaBT84I",
    "year": 1973
  },
  {
    "song_id": "3MLlzphYla1cO36zjtMMpA",
    "name": "Could It Be I'm Falling In Love - Remastered",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/bf0a4c7ddac0b2b765f256a8a7fef68735857dec",
    "artist_name": "The Spinners",
    "image_url": "https://i.scdn.co/image/a6110314629c4880ad95f45261072a338bcec54b",
    "artist_id": "5fbhwqYYh4YwUoEs582mq5",
    "year": 1973
  },
  {
    "song_id": "5aiFbDyQ8neq7iubd7CFf1",
    "name": "Half-Breed",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/6d21b507611d769c4efae80db22a957d908062c4",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/00d4d675801208b6194a3600c510e51bb1e99c8a",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1973
  },
  {
    "song_id": "4WSzF3uHt2AVWdzSD73sMf",
    "name": "Get Down",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/6a69b87ac3fb02a0070a66a29537027007eb2769",
    "artist_name": "Gilbert O'Sullivan",
    "image_url": "https://i.scdn.co/image/3e6ff7ae8991c74875de5d72868e577520935ac8",
    "artist_id": "4HVmeVTQBgvTuvjB1JYwaf",
    "year": 1973
  },
  {
    "song_id": "3YkzaQ7YtkUVBTF2pT7MQq",
    "name": "I'm Doin' Fine Now",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/254c769c3b7e52e2a2e1477177a1a0089624eb35",
    "artist_name": "New York City",
    "image_url": "https://i.scdn.co/image/ef15c49cbe78e91985cee5ab6a314db756ef8674",
    "artist_id": "349LPiGLpKGaxeqIuLZTQp",
    "year": 1973
  },
  {
    "song_id": "5cfCiy2vEqsInb0m2SCJd9",
    "name": "Yes We Can Can",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/ab4db1c67cd6b6d542a468243f9a06534a8ab244",
    "artist_name": "The Pointer Sisters",
    "image_url": "https://i.scdn.co/image/ddf6baa17dc9f619694d5241595452f2800df6c5",
    "artist_id": "2kreKea2n96dXjcyAU9j5N",
    "year": 1973
  },
  {
    "song_id": "2HP90Q78CUyj5nqRQszVBQ",
    "name": "One Of A Kind (Love Affair) - Remastered Censored Version",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/24ec80dc161d4effd2918d38afe683513dea5296",
    "artist_name": "The Spinners",
    "image_url": "https://i.scdn.co/image/a6110314629c4880ad95f45261072a338bcec54b",
    "artist_id": "5fbhwqYYh4YwUoEs582mq5",
    "year": 1973
  },
  {
    "song_id": "2yMhu0b2S5vr0Om3HEd823",
    "name": "Keep On Truckin' (Part 1)",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/5c7aceb897d63a1b1126c7918ea9ff26c42bc4a0",
    "artist_name": "Eddie Kendricks",
    "image_url": "https://i.scdn.co/image/b47c3d1f2e5ef0f65a6fb22021d7bd7fd32d7734",
    "artist_id": "2Uuon75BhnuuxdKLYn4wHn",
    "year": 1973
  },
  {
    "song_id": "1NnGaw2yonmeVBkqqqI7lZ",
    "name": "Shambala - Single Version",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/cfebd04fac0e0c3aa02f6ce76a0973bbaee2d614",
    "artist_name": "Three Dog Night",
    "image_url": "https://i.scdn.co/image/2f51ab7199f6983b2ea4c977190ae42d8d119abf",
    "artist_id": "4FAEZeJcsYYBkNq2D3KGTV",
    "year": 1973
  },
  {
    "song_id": "5X6p7cX8TefrNzb3xHH9gD",
    "name": "Why Me",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/1c7611b2ec4ea2007ed031a29353814b76e14615",
    "artist_name": "Kris Kristofferson",
    "image_url": "https://i.scdn.co/image/c362aff7c9a3bdb3a72a9421c7e93e9fdb1343d9",
    "artist_id": "0vYQRW5LIDeYQOccTviQNX",
    "year": 1973
  },
  {
    "song_id": "1YNCi9tPJffDJHNc6at129",
    "name": "Peaceful",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/53f1f399f64bf11afd92e4b3a756f63e2b12140d",
    "artist_name": "Helen Reddy",
    "image_url": "https://i.scdn.co/image/02ec30a4e943cbd158e5740eb43ae89d767f8b1f",
    "artist_id": "0Sq7oGrYEe0BDmb13wgjOO",
    "year": 1973
  },
  {
    "song_id": "5l9Pnq0ntlY54qTr86VriR",
    "name": "Masterpiece - Single Version",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/1a75cddf576d17044b1d8f4fed53ec4e61285431",
    "artist_name": "The Temptations",
    "image_url": "https://i.scdn.co/image/603beef2495a75948b057e6e1fd7b4f65e0dfbd8",
    "artist_id": "3RwQ26hR2tJtA8F9p2n7jG",
    "year": 1973
  },
  {
    "song_id": "7z9miPRbTAKWqp0TK4Zy67",
    "name": "The Twelfth Of Never",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/dc9d0e628f768248ac7a78a003c97d21e3a52dd7",
    "artist_name": "Donny Osmond",
    "image_url": "https://i.scdn.co/image/c0e1c052576fc82142772421b82bc3d2350b1923",
    "artist_id": "5ZEAzHE2TzAwUcOj6jMIgf",
    "year": 1973
  },
  {
    "song_id": "1JR8brv7k5HyFiJakYnkiJ",
    "name": "Space Oddity - US Stereo Single Edit;2009 Digital Remaster",
    "popularity": 3,
    "preview_url": "https://p.scdn.co/mp3-preview/7edfe8f496c6c2db3e99fc9091a6e359672951f8",
    "artist_name": "David Bowie",
    "image_url": "https://i.scdn.co/image/e53d8018b332e9d787de4c24843818406132a8a3",
    "artist_id": "0oSGxfWSnnOXhD2fKuz2Gy",
    "year": 1973
  },
  {
    "song_id": "102fSLbkMbe5MD7pMkcP4t",
    "name": "I Believe In You (You Believe In Me)",
    "popularity": 0,
    "preview_url": "https://p.scdn.co/mp3-preview/5df2900acf6e5c06102906de4de0b9e157378f58",
    "artist_name": "Johnnie Taylor",
    "image_url": "https://i.scdn.co/image/280d606031c13b74aa08bbf1551745180e8c7ada",
    "artist_id": "4OGuNAnRFWZOgOA2d51taz",
    "year": 1973
  },
  {
    "song_id": "6Ac4NVYYl2U73QiTt11ZKd",
    "name": "Hooked on a Feeling",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/164e196e10d951c5ddde08210eb146c9c51f0906",
    "artist_name": "Blue Swede",
    "image_url": "https://i.scdn.co/image/346c978088536731fee1391149c0df2a0a18487a",
    "artist_id": "0UpuH5U4nZ3UGGUJi0Zfbp",
    "year": 1974
  },
  {
    "song_id": "6yLIqXX9edg1x0HZS7cZEv",
    "name": "The Air That I Breathe - 2008 Digital Remaster",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/2437075d1f79f25df59d934231498582c139895e",
    "artist_name": "The Hollies",
    "image_url": "https://i.scdn.co/image/3b082e1b5ea4a02fb7a436fe82eb0da32513ea19",
    "artist_id": "6waa8mKu91GjzD4NlONlNJ",
    "year": 1974
  },
  {
    "song_id": "583YTL8Fl6pCWtZAi2GvVZ",
    "name": "Living For The City",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/1a84fe121411b7c9d652df7238b0026c93f6d38b",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/6476d80646a3ac86c61b9e2263ead819b2b6194b",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1974
  },
  {
    "song_id": "0SjnBEHZVXgCKvOrpvzL2k",
    "name": "Sundown",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/bc8baef83244e4c8aed36eb893106cab3e185f7e",
    "artist_name": "Gordon Lightfoot",
    "image_url": "https://i.scdn.co/image/18ea582093b72c41bf3ba8050778d320fcf1e067",
    "artist_id": "23rleGXVOVVgTk3xgtmfE4",
    "year": 1974
  },
  {
    "song_id": "35CsqcashydwdRlL27kRBt",
    "name": "Sunshine on My Shoulders",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/4e9b3a2cfd3877b35bb9c4c7b4a75b38deee0279",
    "artist_name": "John Denver",
    "image_url": "https://i.scdn.co/image/070f61a3cff15c68b24b724384f5523fe9521168",
    "artist_id": "7EK1bQADBoqbYXnT4Cqv9w",
    "year": 1974
  },
  {
    "song_id": "0X3d9gx7UDYgcwwkgTeNLR",
    "name": "Seasons in the Sun",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/9f47135b379b9d392372872a6ca6268304ee1f68",
    "artist_name": "Terry Jacks",
    "image_url": "https://i.scdn.co/image/d46eefe6d2d41f9dd0423cdec16c97c5a9ef313e",
    "artist_id": "09dDdtfi4mWLdC2BHOrIrl",
    "year": 1974
  },
  {
    "song_id": "0hOj0uriFk7uW1I8n5p7U7",
    "name": "Be Thankful for What You Got",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/ae9a70174228b5927a090bf675aa750b7c1842a1",
    "artist_name": "William DeVaughn",
    "image_url": "https://i.scdn.co/image/1d48290b30083bc6170fd0f3ebd991bc3bfe4328",
    "artist_id": "1l51Sc9HBh8fLXUDEjFhxU",
    "year": 1974
  },
  {
    "song_id": "4orQuuVtPFt6jB3pimxPNg",
    "name": "Top Of The World - Single Mix",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/b20cecbe0b281c427b99c8419ee4bc1953d40085",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/10eb93b78a035364bb9f4c18d10d0ce7536266aa",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1974
  },
  {
    "song_id": "3J9PGiX9DjHuNyQirnqUGw",
    "name": "Bennie And The Jets",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/beaf277efa810239577071cc8900650dc0a902ff",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/90104614b4f215c8acd203e9d8d82d6a1c1ab8cf",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1974
  },
  {
    "song_id": "0tVzXGFyNPusa1VkHmYDLd",
    "name": "Help Me",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/a6215c51b2a3cdeec9fbc0ecfb03ec3ead899a36",
    "artist_name": "Joni Mitchell",
    "image_url": "https://i.scdn.co/image/34c0135e46266d207bf11c5bc10d215f8a07aff2",
    "artist_id": "5hW4L92KnC6dX9t7tYM4Ve",
    "year": 1974
  },
  {
    "song_id": "4jWoqT4YPGgsUSPC83pd8h",
    "name": "Rock the Boat",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/030cb5746415390e4246c523c9ab3a9a403ac58a",
    "artist_name": "Hues Corporation",
    "image_url": "https://i.scdn.co/image/b10a5aa6202d5f099cbaac5801350ba0dca1e594",
    "artist_id": "6PpGPIrFf3LM7Q77eR4Bts",
    "year": 1974
  },
  {
    "song_id": "0xqb2Euq1PAxKHBA36NUnx",
    "name": "Dancing Machine",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/2b0f380ae90412153b3b9572920e03f2bb8cff02",
    "artist_name": "Jackson 5",
    "image_url": "https://i.scdn.co/image/d9b85346f46058abfef75e3a3db6f463701a1bc2",
    "artist_id": "2iE18Oxc8YSumAU232n4rW",
    "year": 1974
  },
  {
    "song_id": "1Wmuc11xACBpOpni7ECC45",
    "name": "Radar Love - Original Single Edit 1973",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/d00629b224c5b51cadcc6c9350ee9f26382e80a2",
    "artist_name": "Golden Earring",
    "image_url": "https://i.scdn.co/image/991f21375c73aa63dd0e796646cfe26c00eaa933",
    "artist_id": "1iTlOqIrZy8DlvCPJY2sjS",
    "year": 1974
  },
  {
    "song_id": "5n9QhVJS3XSjSc9kNJNEIU",
    "name": "Nothing From Nothing",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/5cda0b350e2f0e1a23f07433da85e20a2421d7bf",
    "artist_name": "Billy Preston",
    "image_url": "https://i.scdn.co/image/a7c8bcca3bd59f9ca491cf8db9d35f20a1159ec8",
    "artist_id": "0IecGJbdBeYSOVtSPRehh5",
    "year": 1974
  },
  {
    "song_id": "7aMLQavs9eIki5fllOn4sx",
    "name": "Rock On",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/5f4510d4ba26eb5da7a601c74d9ab742dfbb85ff",
    "artist_name": "David Essex",
    "image_url": "https://i.scdn.co/image/3133f33da777c3cdbc92fe01e727b3d7f2ee6034",
    "artist_id": "46n0cAhBmsRJZiX6GSFmbf",
    "year": 1974
  },
  {
    "song_id": "7kllQQPam6HumbUCIPSvHJ",
    "name": "T.S.O.P. (The Sound of Philadelphia)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/70e9d7583b2b5bab791d622d299775942bbfe760",
    "artist_name": "MFSB",
    "image_url": "https://i.scdn.co/image/baa161e555a0e75389fec3e10c2171feb5ac10ef",
    "artist_id": "2mknvtcck8i82nKxDPDibv",
    "year": 1974
  },
  {
    "song_id": "2qh2VGAOYFXLsMbdATRf6w",
    "name": "Rock Me Gently",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/4276f101f589d0bf48190a646c3ac098523cb1a4",
    "artist_name": "Andy Kim",
    "image_url": "https://i.scdn.co/image/57a115eaf91fca78abe1fb90a170b5292602244b",
    "artist_id": "5MYBNUKoFf9LAg30ByaBli",
    "year": 1974
  },
  {
    "song_id": "0RzhMHIsFMbOGh0oWDvNNK",
    "name": "Waterloo",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/73e1cb3eb77349837087deee49bb7663f72c6b11",
    "artist_name": "ABBA",
    "image_url": "https://i.scdn.co/image/afb0cb11bb6b2e6af21711b722ba2ebd6557da4b",
    "artist_id": "0LcJLqbBmaGUft1e9Mm8HV",
    "year": 1974
  },
  {
    "song_id": "2vhW1OmaZDYi63Da9d8R5o",
    "name": "Show and Tell",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/ad7e1b65bdb4157c342b1ce2d500d42285d14ba8",
    "artist_name": "Al Wilson",
    "image_url": "https://i.scdn.co/image/80a69b422351e5d490bed84e40f058c5e1104ced",
    "artist_id": "2RwV62DIxFGEn8aWO3iaMM",
    "year": 1974
  },
  {
    "song_id": "0PxYWaGiwWtcuL1VIMiEW4",
    "name": "The Loco-Motion - 2002 - Remastered",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/ba2a60430c3242005fddda2a02f3b0e94748fccc",
    "artist_name": "Grand Funk",
    "image_url": "https://i.scdn.co/image/d1e055aa2831c3a471a630333847f12bf547673e",
    "artist_id": "2tJ6csNN21bhubevSjTfjS",
    "year": 1974
  },
  {
    "song_id": "5PuAFxKmqG72uKyoo11zpQ",
    "name": "(You're) Having My Baby - feat. Odia Coates",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/4f294af3e249859cf7e9c68836498599dda7dfc0",
    "artist_name": "Paul Anka",
    "image_url": "https://i.scdn.co/image/a07b2e0a5b469eed3e9a5d36eb613a92b9045559",
    "artist_id": "7ceUfdWq2t5nbatS6ollHh",
    "year": 1974
  },
  {
    "song_id": "0fopVbPW7N5BK0sOybQCq2",
    "name": "Sideshow",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/f0ed07479e5a0870b1cd8920a9be30200fbe2114",
    "artist_name": "Blue Magic",
    "image_url": "https://i.scdn.co/image/f5bf6baa7a223ca6372bb6e6135ebfc692ca5575",
    "artist_id": "7tLLSEaHMMJPbsYAz8MkEw",
    "year": 1974
  },
  {
    "song_id": "1QvWxgZvTU0w8rlPRE5Zrv",
    "name": "Don't You Worry 'Bout A Thing",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/030783b4a0f1323a611871871d82ce6d5b59dbb3",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/3bc429d4de962802b907042674970ac1f7f819ca",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1974
  },
  {
    "song_id": "63Nj3NsQkLQs2MKgWk1Di6",
    "name": "Midnight At The Oasis",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/e1169ada210eb05dce8afa37da0f6645fff2cc68",
    "artist_name": "Maria Muldaur",
    "image_url": "https://i.scdn.co/image/8ba9a369420b583b953781dc1ce200f44a045cb4",
    "artist_id": "2VUiF0VFkXzB0DLg9AzrqT",
    "year": 1974
  },
  {
    "song_id": "3gHFKiDanj4d2rqgHlRFFc",
    "name": "Hello It's Me",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/13f9909f90ec6647a74a60d7d679dcc961a25ce6",
    "artist_name": "Todd Rundgren",
    "image_url": "https://i.scdn.co/image/9a129203c3ba39a30ee3bf3a8b1bf66d1ac9fa6a",
    "artist_id": "0Lpr5wXzWLtDWm1SjNbpPb",
    "year": 1974
  },
  {
    "song_id": "0Nm4rh5Y6NSypsOYox52uJ",
    "name": "The Most Beautiful Girl",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/5e641a1833680b4a4a4ab27fabf2879e4cdc6553",
    "artist_name": "Charlie Rich",
    "image_url": "https://i.scdn.co/image/343f44031039f391172668842757a80908f93720",
    "artist_id": "218kRJZ7FJs0hWIk8Ynzhz",
    "year": 1974
  },
  {
    "song_id": "18a9EGG4xhRELv7bgAw5hb",
    "name": "Rikki Don't Lose That Number",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/ab604acc312f08126ca67090d849b5f46e254598",
    "artist_name": "Steely Dan",
    "image_url": "https://i.scdn.co/image/9202528df3627d0cb0a302fb89ec9409a3373516",
    "artist_id": "6P7H3ai06vU1sGvdpBwDmE",
    "year": 1974
  },
  {
    "song_id": "2zZuxnCPpUCfJ5STnZC19l",
    "name": "Lookin' For A Love",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/f15c9eeaaa30139056efc67fcea248993ec4ff27",
    "artist_name": "Bobby Womack",
    "image_url": "https://i.scdn.co/image/e9587d0d2c85c8bc0c133f743755c2df68c6d995",
    "artist_id": "0vqkz1b2qBkoYrGMj2CUWq",
    "year": 1974
  },
  {
    "song_id": "11FcfHd3SOmmrWJPGe7Y30",
    "name": "Don't Let The Sun Go Down On Me",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/3886ee4594465a3299439a90151e24e9aa34f3a1",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/7e1edb14420823e8b79a6ca8c1784ee9f50bf093",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1974
  },
  {
    "song_id": "7K05XIE1yclQHVj9u2mXEy",
    "name": "Takin' Care Of Business",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/483fb50acac4568b7e34550fbf99357d9906af25",
    "artist_name": "Bachman-Turner Overdrive",
    "image_url": "https://i.scdn.co/image/1facebb08903a3a628b4c9f08192be24b6aec63c",
    "artist_id": "5q4AzEtCoYJyXjMMoEkSU5",
    "year": 1974
  },
  {
    "song_id": "1eY2jybA84eDUEee1lqaNy",
    "name": "Jungle Boogie",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/339f0712c72a8846e8e6743ca0b9e478d4770952",
    "artist_name": "Kool & The Gang",
    "image_url": "https://i.scdn.co/image/b4fb37f838a056886f0b8123949ec1234b85deb2",
    "artist_id": "3VNITwohbvU5Wuy5PC6dsI",
    "year": 1974
  },
  {
    "song_id": "160WtfT9YFSzZVNQKwrvte",
    "name": "Rock And Roll Heaven",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/c9bcbf00763b69b84ac67016ca56448e6ca788a5",
    "artist_name": "The Righteous Brothers",
    "image_url": "https://i.scdn.co/image/4d4729b4928c134a733248e0159e51e0db1668fe",
    "artist_id": "4b0WsB47XCa9F83BmwQ7WX",
    "year": 1974
  },
  {
    "song_id": "1OO2Fp9PsnaayiekeXuGJX",
    "name": "Tell Me Something Good",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/3203bfe5db840b3f8257176119dab8455ce48096",
    "artist_name": "Rufus",
    "image_url": "https://i.scdn.co/image/1cc38443a56f9b468cf5afe62947b83ac81301a2",
    "artist_id": "3MCjwjfIor5FkK3h639qkW",
    "year": 1974
  },
  {
    "song_id": "5MDgUvbBUi1nu1yt11mzH6",
    "name": "Goodbye Yellow Brick Road",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/601d1eaadfd3606d04536eca38ca885e57a8e1ef",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/73c3e15af10720e76df8745cfa7953a67410672a",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1974
  },
  {
    "song_id": "1pmYAWY7Kwaj84U3xZxLML",
    "name": "Time In A Bottle",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/f10505b09ca17aba82c55b819e3f8e8e74bb9986",
    "artist_name": "Jim Croce",
    "image_url": "https://i.scdn.co/image/60a8c842a4e57c8b64897eba897fe7ec543c2dbc",
    "artist_id": "1R6Hx1tJ2VOUyodEpC12xM",
    "year": 1974
  },
  {
    "song_id": "55KKzF53NXeY7neYbd8wz5",
    "name": "I'll Have To Say I Love You In A Song",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/703080feeeb839d8dc7a9c7ad7d406f90b7faadf",
    "artist_name": "Jim Croce",
    "image_url": "https://i.scdn.co/image/45090d9690b48d527cca5506dc473fc1bf6918b4",
    "artist_id": "1R6Hx1tJ2VOUyodEpC12xM",
    "year": 1974
  },
  {
    "song_id": "1TvCh7rs00BamgwB98rXzA",
    "name": "A Love Song",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/acac12d5f2078b34c9071d56cecfbc6c24f5b8e8",
    "artist_name": "Anne Murray",
    "image_url": "https://i.scdn.co/image/54a5f782ec4049eb5f11de438f01b7bc4293404d",
    "artist_id": "7d7q5Y1p2QWS4QRAhTQR5E",
    "year": 1974
  },
  {
    "song_id": "3zVjX2rAPykGZrwQx2BCex",
    "name": "You Won't See Me",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/573dd6c8501faba640642543dd28c221783f907f",
    "artist_name": "Anne Murray",
    "image_url": "https://i.scdn.co/image/54a5f782ec4049eb5f11de438f01b7bc4293404d",
    "artist_id": "7d7q5Y1p2QWS4QRAhTQR5E",
    "year": 1974
  },
  {
    "song_id": "0OrnKmKKeMXhv11lJQJqpf",
    "name": "Just Don't Want to Be Lonely - Remastered",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/3886748b58c9395a2d8d8902370f4089ba4217ce",
    "artist_name": "The Main Ingredient",
    "image_url": "https://i.scdn.co/image/bb0a6e16443a1714ee20dcfc1893b51fb3b53eac",
    "artist_id": "6vuD08WKtmp1yc7kQx1rBm",
    "year": 1974
  },
  {
    "song_id": "3p1JoOEhVkEnTaa4JzTMSk",
    "name": "For the Love of Money",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/d356dd05e25ea825aed8a6dc94afc52237ff5185",
    "artist_name": "The O'Jays",
    "image_url": "https://i.scdn.co/image/1d3f08d79c758a5f3890a22a45bc2217610d7687",
    "artist_id": "38h03gA85YYPeDPd9ER9rT",
    "year": 1974
  },
  {
    "song_id": "7DCav3rL0B3A5Lp7CXcatN",
    "name": "Mighty Love",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/ac7c940ce8c5456dc0cc12f8f7df4f98e9c1c63e",
    "artist_name": "The Spinners",
    "image_url": "https://i.scdn.co/image/f5be25a1def5fa111f53dbac3cdf6dab3fc68481",
    "artist_id": "5fbhwqYYh4YwUoEs582mq5",
    "year": 1974
  },
  {
    "song_id": "7gD8EozVUMLdyO21MNkoSF",
    "name": "My Mistake (Was To Love You)",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/4904f7583a681f11851b7aa5c9574dc1da479f9d",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/7d1c59c2c5e646ffc1774eaf6ddd94061fba4f7c",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1974
  },
  {
    "song_id": "1pfSKTDigM2xnWG2Y0AquA",
    "name": "Please Come To Boston - Single Version",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/d4657c03516801a6c498651b6ed32e7889e12b38",
    "artist_name": "Dave Loggins",
    "image_url": "https://i.scdn.co/image/35e038d88f20a9a73d4fd18d0cdda4571a9109fc",
    "artist_id": "7qBnCBxjX9xHgepd3ayVc5",
    "year": 1974
  },
  {
    "song_id": "2yGQNGWpU6dUgswcPZtEaw",
    "name": "Billy, Don't Be A Hero",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/7c15201371fbf3e7fd0c10daa533018664b727d0",
    "artist_name": "Bo Donaldson & The Heywoods",
    "image_url": "https://i.scdn.co/image/be4271b5bb0e2bde251576a1765b2c911dd4f7d2",
    "artist_id": "6hpEpmvfhm26T0Mc8x65ox",
    "year": 1974
  },
  {
    "song_id": "4ncBBiLD7cUfVDiS2H59zE",
    "name": "Spiders & Snakes",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/ddba6524cd2431584209ee047a6e984c86dd7995",
    "artist_name": "Jim Stafford",
    "image_url": "https://i.scdn.co/image/391f675be54acace4936aad811368bcfb30748dd",
    "artist_id": "0CIdEoAG4j0tnC6IZsDr0q",
    "year": 1974
  },
  {
    "song_id": "3aC5DsVQXyXXXepi8GNFmL",
    "name": "Me And Baby Brother",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/5a0d02eedec5617898eb651df0f745fd21548628",
    "artist_name": "War",
    "image_url": "https://i.scdn.co/image/7479a127eac185329d7633a8e6509b5db142f101",
    "artist_id": "3ICyfoySNDZqtBVmaBT84I",
    "year": 1974
  },
  {
    "song_id": "1vZTgn4JXWMahR8r99ug5H",
    "name": "The Way We Were",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/a9aad8ebd2caf96495e2c6c687f7e7bb570c2c00",
    "artist_name": "Barbra Streisand",
    "image_url": "https://i.scdn.co/image/7f13b2201931fe6db605b8800d950d003a677486",
    "artist_id": "7jmTilWYlKOuavFfmQAcu6",
    "year": 1974
  },
  {
    "song_id": "4zjbkFSkoKVWwmeLUMnGz4",
    "name": "Smokin' In The Boy's Room (Single/LP Version)",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/37fd5c4d0b7b5dd1fbbe5da81839a031a80389cf",
    "artist_name": "Brownsville Station",
    "image_url": "https://i.scdn.co/image/e04df18f4d0736e12ef26592700c162ad03f7cc6",
    "artist_id": "67SxctqWYOGe8lthWjTDwu",
    "year": 1974
  },
  {
    "song_id": "7eH0OzyJIoT1k8jbEPXYc7",
    "name": "The Show Must Go On - Single Version",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/8d1cf841cb4ac79979107d7df229325af4ee4810",
    "artist_name": "Three Dog Night",
    "image_url": "https://i.scdn.co/image/f8992fc8a189360dfdf702a7c6d64c64b74a1cb9",
    "artist_id": "4FAEZeJcsYYBkNq2D3KGTV",
    "year": 1974
  },
  {
    "song_id": "5hHfO70XvjCezL0YNtonNk",
    "name": "You Make Me Feel Brand New",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/54894de20692e564d8b3ff1909701fee4c071edf",
    "artist_name": "The Stylistics",
    "image_url": "https://i.scdn.co/image/dec6c9c6f790afb1bc8ca44d1d374fc17ab7e3cf",
    "artist_id": "2O0Hw1WSMbskB5tD9aWah3",
    "year": 1974
  },
  {
    "song_id": "6P2wbyKTobLX532FA16rjD",
    "name": "Call On Me - Remastered Version 2007",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/0db1a82d622948310d31945733ccd0f96c7341d3",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/d2ef90e565b84fa7f129cf1121de287f8e144a6d",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1974
  },
  {
    "song_id": "3xNdA7bpfYw7SuMEyQybz7",
    "name": "(I've Been) Searchin' So Long - Remastered Version 2007",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/b45b4324668343e404aa1895880623cce6226615",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/d2ef90e565b84fa7f129cf1121de287f8e144a6d",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1974
  },
  {
    "song_id": "15q1ikYmVRhDT5o6pailtt",
    "name": "Hang On In There Baby",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/5c216442c363190248b3294ffe2598c84c46e444",
    "artist_name": "Johnny Bristol",
    "image_url": "https://i.scdn.co/image/99becf6fa5dbd8735ee3fcb56077254605e1e766",
    "artist_id": "0F4yfY9manxJrgsfwbXdZa",
    "year": 1974
  },
  {
    "song_id": "57ejnSQjEEYn7bmxZxdXLA",
    "name": "Hollywood Swinging - Single Version",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/f7a05272dbc103c450c6d3e837826862fa2bb93c",
    "artist_name": "Kool & The Gang",
    "image_url": "https://i.scdn.co/image/b4fb37f838a056886f0b8123949ec1234b85deb2",
    "artist_id": "3VNITwohbvU5Wuy5PC6dsI",
    "year": 1974
  },
  {
    "song_id": "7bIrLCQmwnCDKEPDxSxWLR",
    "name": "Then Came You",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/f18fa6aa6bfeb7a4be6e7d816e92ec2c88527b63",
    "artist_name": "Spinners & Dionne Warwick",
    "image_url": "https://i.scdn.co/image/942dce6797cd5743eb9d470cdc04c39b03105f2c",
    "artist_id": "31OHLI5VTBqEuvV4QYDrn9",
    "year": 1974
  },
  {
    "song_id": "2W0xRSFj5R8uTnyEQa4O6Z",
    "name": "Dark Lady",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/084649e1176b76d51ade1d033e5aa3e2a8542bfc",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/00d4d675801208b6194a3600c510e51bb1e99c8a",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1974
  },
  {
    "song_id": "5QW0cC96jmWE95DkrFd29w",
    "name": "Boogie Down",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/b575b74d679870d37a6cdb8f6bbc76e854a28463",
    "artist_name": "Eddie Kendricks",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "2Uuon75BhnuuxdKLYn4wHn",
    "year": 1974
  },
  {
    "song_id": "39astBFQzSeXtISGKHsDK6",
    "name": "On And On",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/80ac5d39bf332d81735419a222e863e3a4cce12d",
    "artist_name": "Gladys Knight & The Pips",
    "image_url": "https://i.scdn.co/image/afb38007120371d66046515b6bbdbf946475057c",
    "artist_id": "0TF2NxkJZPQoX1H53rEFM1",
    "year": 1974
  },
  {
    "song_id": "5jAak0XOwjEPfa3DlqNGVu",
    "name": "I've Got to Use My Imagination",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/028b76667b02de21effbc93a0cb5778025ff15a0",
    "artist_name": "Gladys Knight & The Pips",
    "image_url": "https://i.scdn.co/image/07b85b97936188de61a54dbd1a07e4183bd8b385",
    "artist_id": "0TF2NxkJZPQoX1H53rEFM1",
    "year": 1974
  },
  {
    "song_id": "6sHrxsewgTMPs2Di4x636C",
    "name": "Keep On Smilin'",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/63a17cec793e512bfd0f1c67baafd9820be41b91",
    "artist_name": "Wet Willie",
    "image_url": "https://i.scdn.co/image/d002711fa746b2f75808c9a37716bd95d14e8a8e",
    "artist_id": "6fzgnTv8gVkfFhA6V8Qdsh",
    "year": 1974
  },
  {
    "song_id": "74UWXHeikO5C3ScEREk42E",
    "name": "Mockingbird",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/f4b0e5d5eca9757658a49d3770992989d0b57a7c",
    "artist_name": "Carly Simon & James Taylor",
    "image_url": "https://i.scdn.co/image/47268fb15ddb26cc955555787cdcd878e82c45fc",
    "artist_id": "6xgKHtNMHH4yFFjBtRfYHg",
    "year": 1974
  },
  {
    "song_id": "5qxFudz7A6PGptviHsz4Yd",
    "name": "Annie's Song",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/c605a8e96fd47ad7d7466300cc7a3872583d6ddd",
    "artist_name": "John Denver",
    "image_url": "https://i.scdn.co/image/111aa52a74f54bcb06f98d28debee7ef4443531e",
    "artist_id": "7EK1bQADBoqbYXnT4Cqv9w",
    "year": 1974
  },
  {
    "song_id": "7Eq6uD4xcTs4Ddmyv6Ttjs",
    "name": "One Hell Of A Woman",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/f8b5206eb64d6d81d800686ae7718ee8ae90482b",
    "artist_name": "Mac Davis",
    "image_url": "https://i.scdn.co/image/3a2a0cb7fdb9a7504fb6964f3bcb1b0e5ca7bd45",
    "artist_id": "6HX8AbXUFaYRtlqKb4CCo0",
    "year": 1974
  },
  {
    "song_id": "6QQIawc8vdSjXHZIjuUJG7",
    "name": "The Entertainer - The Sting/Soundtrack Version (Piano Version)",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/0ccc8b853e5562928ab5227c3b1fb2b436a67764",
    "artist_name": "Marvin Hamlisch",
    "image_url": "https://i.scdn.co/image/5d48d239990f98f92e3194ea5f31ff733326c9ea",
    "artist_id": "1VN38ZSdtQnHLa8PfTTKZD",
    "year": 1974
  },
  {
    "song_id": "1yqJ8PE0im6ibJqjRm5YQS",
    "name": "Best Thing That Ever Happened to Me",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/43eecff998ce9958664d5480d012b6c2dbd2d9f4",
    "artist_name": "Gladys Knight & The Pips",
    "image_url": "https://i.scdn.co/image/07b85b97936188de61a54dbd1a07e4183bd8b385",
    "artist_id": "0TF2NxkJZPQoX1H53rEFM1",
    "year": 1974
  },
  {
    "song_id": "5FFaUuv02cSOcqtlzPnHee",
    "name": "Love's Theme",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/4c78c56ffe376630037795d7544e374b99997eba",
    "artist_name": "The Love Unlimited Orchestra",
    "image_url": "https://i.scdn.co/image/8682801477a65c6ccae964d4751b255ae22c88af",
    "artist_id": "457yGSZecENoIuNWelRHhH",
    "year": 1974
  },
  {
    "song_id": "5p3JQeBDQinH0a8FTQnm2C",
    "name": "Rockin' Roll Baby",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/b1560bfa3780a032ac073c2a5c500c6eb74be66e",
    "artist_name": "The Stylistics",
    "image_url": "https://i.scdn.co/image/dec6c9c6f790afb1bc8ca44d1d374fc17ab7e3cf",
    "artist_id": "2O0Hw1WSMbskB5tD9aWah3",
    "year": 1974
  },
  {
    "song_id": "6oFlMcO7Bbb5KPa8t4ck8f",
    "name": "I Love",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/bef873038591890bcfbb8c9e0356df0c3a54cdca",
    "artist_name": "Tom T. Hall",
    "image_url": "https://i.scdn.co/image/a2ac8166b11ec31acebec41e1e0028f724591d39",
    "artist_id": "1SvG3y1gYAFOsv9785Xu9T",
    "year": 1974
  },
  {
    "song_id": "57yGqjGKJUIzgKWdIZo9aR",
    "name": "Put Your Hands Together",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/343a3f04df99cfa7fe0e0423d36a746787eed5ae",
    "artist_name": "The O'Jays",
    "image_url": "https://i.scdn.co/image/1d3f08d79c758a5f3890a22a45bc2217610d7687",
    "artist_id": "38h03gA85YYPeDPd9ER9rT",
    "year": 1974
  },
  {
    "song_id": "3iX0BMs9dX9CsgrHhiB173",
    "name": "Until You Come Back To Me [That's What I Am Going To Do]",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/b241eb7cba4f6bcda79899010780ea572bcdeaad",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/d2f4f929efe684744ddc40ea3a04d4f1eebba583",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1974
  },
  {
    "song_id": "6YLWRvxvC4VbFTuNRHeaDU",
    "name": "Clap For The Wolfman - 2003 Remastered",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/573054cd5cf7aed8571cb9f8e35e3431593d6925",
    "artist_name": "The Guess Who",
    "image_url": "https://i.scdn.co/image/9897916ad4c928863bc9aa4c9029bc3759afa381",
    "artist_id": "0cQuYRSzlItquYxsQKDvVc",
    "year": 1974
  },
  {
    "song_id": "1Ll6yKXJ96uTIlaatVQxtC",
    "name": "Rock Your Baby (Radio Mix)",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/a312fff4f3477a7d4c79cb824e6e6d9e30d111e1",
    "artist_name": "George McCrae",
    "image_url": "https://i.scdn.co/image/a1b3c58006df79a4b883baf87d96edcab5fe534e",
    "artist_id": "6oV3BNm1Gj2GGgpYknc5TN",
    "year": 1974
  },
  {
    "song_id": "4MbYZqKFC3XaCqer9wUTnm",
    "name": "A Very Special Love Song",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/dbf0fcd6bdd85d0719fbd785de88e75b10dd4ef4",
    "artist_name": "Charlie Rich",
    "image_url": "https://i.scdn.co/image/0e02d40aab7ffa4354410968e9a2e73544d49494",
    "artist_id": "218kRJZ7FJs0hWIk8Ynzhz",
    "year": 1974
  },
  {
    "song_id": "3ctFUcpIG7eZgOOWBUt0rJ",
    "name": "I'm Leaving It (All) Up To You",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/421d7cf2c7f055961856d8093789271ecd9ad289",
    "artist_name": "Donny Osmond",
    "image_url": "https://i.scdn.co/image/c0e1c052576fc82142772421b82bc3d2350b1923",
    "artist_id": "5ZEAzHE2TzAwUcOj6jMIgf",
    "year": 1974
  },
  {
    "song_id": "1Am9G7F2qJq4EtD65ALTEf",
    "name": "Leave Me Alone (Ruby Red Dress)",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/5d6cf5be7552a991decc37872bb128ae6a10e385",
    "artist_name": "Helen Reddy",
    "image_url": "https://i.scdn.co/image/47aab66744aa5aaa65955a1c69d565e5314ce781",
    "artist_id": "0Sq7oGrYEe0BDmb13wgjOO",
    "year": 1974
  },
  {
    "song_id": "4YnMQa8eznnFtYPfmbY1UP",
    "name": "You And Me Against The World",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/e7ee4ac0f9a992652b203cdadb9ba0b9f9a3b8a3",
    "artist_name": "Helen Reddy",
    "image_url": "https://i.scdn.co/image/be6fc5dac7977e95f09fd363e9fa3bc30573a437",
    "artist_id": "0Sq7oGrYEe0BDmb13wgjOO",
    "year": 1974
  },
  {
    "song_id": "3cIkOkt5bv9GlsqSapK1tJ",
    "name": "Trying To Hold On To My Woman",
    "popularity": 7,
    "preview_url": "https://p.scdn.co/mp3-preview/51f2aeb38b4977ff48f5e6157badd41112976646",
    "artist_name": "Lamont Dozier",
    "image_url": "https://i.scdn.co/image/625b7596b2bdedde2e2c19d1904c5975eeaad8fe",
    "artist_id": "6Dpx8fRtNa9CEORHIiBQTG",
    "year": 1974
  },
  {
    "song_id": "3PbJjso7KugjaEA8nkUPfk",
    "name": "I Shot The Sheriff",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/ec6a5de33e9f59001e0474b754ca9453c0721c44",
    "artist_name": "Eric Clapton",
    "image_url": "https://i.scdn.co/image/5eac824a20546e0af169ada60423f46d9cac0872",
    "artist_id": "6PAt558ZEZl0DmdXlnjMgD",
    "year": 1974
  },
  {
    "song_id": "1A6Kwtsg3JWKU2KWM2udpM",
    "name": "I'm Not In Love",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/a897a10f1fd0217dd9518bd11e2f8a15ceb1f010",
    "artist_name": "10cc",
    "image_url": "https://i.scdn.co/image/b847a10ee74908454d4e84ac21c299c95880cb5a",
    "artist_id": "6i6WlGzQtXtz7GcC5H5st5",
    "year": 1975
  },
  {
    "song_id": "4BCmdMShfg9uz1WeyXaFae",
    "name": "Sister Golden Hair",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/ecb280f92c4937ec041cf338398d93d9cc93bca2",
    "artist_name": "America",
    "image_url": "https://i.scdn.co/image/2438217275cb2c23d7a558d113b5107b84174be9",
    "artist_id": "35U9lQaRWSQISxQAB94Meo",
    "year": 1975
  },
  {
    "song_id": "26VrotMHCu4m9I1rbHB1GN",
    "name": "Shining Star",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/1995430656534f52aed8d648120545f33b116387",
    "artist_name": "Earth, Wind & Fire",
    "image_url": "https://i.scdn.co/image/33f703a93a98c0492edf64591d5f5896e77a6b75",
    "artist_id": "4QQgXkCYTt3BlENzhyNETg",
    "year": 1975
  },
  {
    "song_id": "2oBvncOkOTaxmTbOkmgGGw",
    "name": "Killer Queen",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/5f8e66073b403de1fa45679b36dd24509076d458",
    "artist_name": "Queen",
    "image_url": "https://i.scdn.co/image/5e2d4c9fce0dfa3689bd0a20754de696c69a4658",
    "artist_id": "1dfeR4HaWDbWqFHLkxsg1d",
    "year": 1975
  },
  {
    "song_id": "7ufDF5ePKsnxYgP0DAZoZE",
    "name": "Dancing In the Moonlight - Original Recording",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/388f6ab4bc04909e9c2a4732f5eb80b7d79ce76e",
    "artist_name": "King Harvest",
    "image_url": "https://i.scdn.co/image/c9b1445e27521c273adda234291c1c2932ee4fdc",
    "artist_id": "5FHwr1FymaS5kutIEK6e2y",
    "year": 1975
  },
  {
    "song_id": "0bvHudcdNOCcA0vEJfeadH",
    "name": "Rhinestone Cowboy",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/17b63c8d0ff0c323f40b0fd4822419dd34ff3e49",
    "artist_name": "Glen Campbell",
    "image_url": "https://i.scdn.co/image/9f743061fb0a7a11b7a2066413214ddbf7178814",
    "artist_id": "59hLmB5DrdihCYtNeFeW1U",
    "year": 1975
  },
  {
    "song_id": "3Pu3IChaAoa5EjgUBv5F6x",
    "name": "Ballroom Blitz",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/9eb012a2f9e426605f46e3f48b4f73ac4d8ace8b",
    "artist_name": "Sweet",
    "image_url": "https://i.scdn.co/image/ca5ae2f879305c09e588728a24e5f8cf891a0584",
    "artist_id": "3JaAGmSTpJK35DqWrDUzBz",
    "year": 1975
  },
  {
    "song_id": "500h8jAdr7LvzzXlm1qxtK",
    "name": "Magic",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/fbe6dbaae6dfdbbdb5d20a06988355449c4b44d5",
    "artist_name": "Pilot",
    "image_url": "https://i.scdn.co/image/5d9a2dc9ed1c58893e9f8a7fa8f47af20998b67a",
    "artist_id": "6PwcexHTG0qJWQQwp05Bpm",
    "year": 1975
  },
  {
    "song_id": "62YYgRJArxcg2Gylt2nIvz",
    "name": "You Are So Beautiful",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/45252077ae9e1975c01ba6691ebd2ef4d32c737e",
    "artist_name": "Joe Cocker",
    "image_url": "https://i.scdn.co/image/4ca2969fa9e7897823a2fc245e486accc051fdf6",
    "artist_id": "3pFCERyEiP5xeN2EsPXhjI",
    "year": 1975
  },
  {
    "song_id": "6n7bcDnJOuKj3Zc5FbnaWs",
    "name": "Thank God I'm a Country Boy",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/481935e32c3117b70b34f4c251f4bcc470decdce",
    "artist_name": "John Denver",
    "image_url": "https://i.scdn.co/image/4672a4c5e98f9179e18f729d284165b4d7089ba0",
    "artist_id": "7EK1bQADBoqbYXnT4Cqv9w",
    "year": 1975
  },
  {
    "song_id": "5bKeVLylKwjv8KrAejxGOK",
    "name": "Jive Talkin' (Remastered Album Version)",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/9484945815b6d01a824c031790315add06d58ec4",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/9ba9a3a360c2f681818fa251fcc4a5800c01b1e3",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1975
  },
  {
    "song_id": "6x8C7SBqQukeaeh9etWdZY",
    "name": "The Best Of My Love",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/1f74e8c41c7e1d1823382ab78aece316c24f3bad",
    "artist_name": "Eagles",
    "image_url": "https://i.scdn.co/image/585e44abfa890e510c3e1405217ce29ec53f5d13",
    "artist_id": "0ECwFtbIWEVNwjlrfc6xoL",
    "year": 1975
  },
  {
    "song_id": "4msPRe3NEDVL6dsBcE7AhL",
    "name": "Get Down Tonight",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/c1d383e5f9660d5a75d11da3796b1953e660df04",
    "artist_name": "KC & The Sunshine Band",
    "image_url": "https://i.scdn.co/image/e04c2abb9c42c04ecf2e24f30fed9f1620afad81",
    "artist_id": "3mQBpAOMWYqAZyxtyeo4Lo",
    "year": 1975
  },
  {
    "song_id": "2cqVnbTiYgdun186IbDRwm",
    "name": "My Eyes Adored You",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/5822fc3db39bf443ca22614d47456da4a3392371",
    "artist_name": "Frankie Valli",
    "image_url": "https://i.scdn.co/image/f4430f809fc003be28cf0d0f4710646c66c93fad",
    "artist_id": "3CDKmzJu6uwEGnPLLZffpD",
    "year": 1975
  },
  {
    "song_id": "608xszaAxVh4m7NcKJiAbF",
    "name": "One Of These Nights",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/81f4e5bec01cc5b1f1eeed0bbaa7557e444ebdeb",
    "artist_name": "Eagles",
    "image_url": "https://i.scdn.co/image/b31a0d0d882d266ba3a5e44113d12a4e8104d37c",
    "artist_id": "0ECwFtbIWEVNwjlrfc6xoL",
    "year": 1975
  },
  {
    "song_id": "4oV4dVj0evVFhjPLvUz8w8",
    "name": "Mandy",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/49c60d4d23301e7ffa6f0eeead3ec5381c855b1b",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/5bbf9f0e6f87ad5fd094c2d0ee382e9c5ee2e535",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1975
  },
  {
    "song_id": "2obblQ6tcePeOEVJV6nEGD",
    "name": "Cat's In The Cradle",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/56d6be4e75a010e75f5b7a2d912ed66509ae3d33",
    "artist_name": "Harry Chapin",
    "image_url": "https://i.scdn.co/image/b988559b7de991bb0adf9a0dc2dc23543de89b60",
    "artist_id": "42q4Ivs7tAiCZ5C7eG5q4c",
    "year": 1975
  },
  {
    "song_id": "23DZLSxCK6kM8FF2RlzKDl",
    "name": "You're No Good",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/9ceded15a59f4ac4fbc07b0c1b0635f928fb5a5d",
    "artist_name": "Linda Ronstadt",
    "image_url": "https://i.scdn.co/image/06be11077a82495c42a3467851e8e054d2325a41",
    "artist_id": "1sXbwvCQLGZnaH0Jp2HTVc",
    "year": 1975
  },
  {
    "song_id": "1f4iVGDitHYMVBaoc9GXO2",
    "name": "Love Will Keep Us Together",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/f3ddb5a20208b0b333352f4ef260e29a410a23af",
    "artist_name": "Captain & Tennille",
    "image_url": "https://i.scdn.co/image/03f9eb7c17c054b15d7f827056148aad69b13523",
    "artist_id": "7BEfMxbaqx6dOpbtlEqScm",
    "year": 1975
  },
  {
    "song_id": "5b1QulLpP5qvPpZ94sFYP9",
    "name": "Lovin' You",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/b2b0362a5af0c70121d8957d648368427be73c5f",
    "artist_name": "Minnie Riperton",
    "image_url": "https://i.scdn.co/image/9c0e7516f021e0a7bf778ba89eba56452566d671",
    "artist_id": "2i1IdHG5w0wiSmJGoqAGlj",
    "year": 1975
  },
  {
    "song_id": "2k3b3uzuEQJLmSV9BlSZTM",
    "name": "When Will I See You Again",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/eed82b3fc8e4d7e7ce030a349fe3f9035a317504",
    "artist_name": "The Three Degrees",
    "image_url": "https://i.scdn.co/image/8d426154f6e31eef15a8a10e6c76c46b81dedc0d",
    "artist_id": "2zpFG5cvw00QmrYTUsjApa",
    "year": 1975
  },
  {
    "song_id": "1ElrrSBvvhuWTvpi8aroh2",
    "name": "Lonely People",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/68dbf1ee709e0e5e3ab82ec53432621a39e03625",
    "artist_name": "America",
    "image_url": "https://i.scdn.co/image/2438217275cb2c23d7a558d113b5107b84174be9",
    "artist_id": "35U9lQaRWSQISxQAB94Meo",
    "year": 1975
  },
  {
    "song_id": "2x1LQq8lsUzAA2wNj8yjC9",
    "name": "Pick Up The Pieces",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/9906592a3abec9b00c4f6eed4f8b4599c94d9059",
    "artist_name": "Average White Band",
    "image_url": "https://i.scdn.co/image/8ec85291380421ae486f32404279dcd5a55261f8",
    "artist_id": "3tx8fyu3c4OBP5nejYtUOb",
    "year": 1975
  },
  {
    "song_id": "413T0g8pNNXYX6t5RqHvx7",
    "name": "Why Can't We Be Friends?",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/167379a820c7bf94e2da0519d453192151de39c6",
    "artist_name": "War",
    "image_url": "https://i.scdn.co/image/9a2afcc84537d1098d2b2cd6b450105f9306a0f0",
    "artist_id": "3ICyfoySNDZqtBVmaBT84I",
    "year": 1975
  },
  {
    "song_id": "7qVOasZcHa4faF1TqvPEKF",
    "name": "Fame (2007 Remastered)",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/700774cb853720d22b90274841f00add6e98d449",
    "artist_name": "David Bowie",
    "image_url": "https://i.scdn.co/image/7c3ca47b333cf0c0fcce0412e6d841d90a8a1f9b",
    "artist_id": "0oSGxfWSnnOXhD2fKuz2Gy",
    "year": 1975
  },
  {
    "song_id": "7umUzOggE00eZPdO8dJ5pc",
    "name": "Misty",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/a2ed2a059ccf6e2cbeb54ba791c0a17d6bf46566",
    "artist_name": "Johnny Mathis",
    "image_url": "https://i.scdn.co/image/fabff0d4aa3091efcdf3247989302e0caa4697d2",
    "artist_id": "21LGsW7bziR4Ledx7WZ1Wf",
    "year": 1975
  },
  {
    "song_id": "4IbO6Y9VBWvNaiRrQPHuIW",
    "name": "Boogie On Reggae Woman",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/d38dba80c670d541d68afe957242863c27ea533d",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/6476d80646a3ac86c61b9e2263ead819b2b6194b",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1975
  },
  {
    "song_id": "7Dqd58ucnNHklEfovimCrj",
    "name": "Feel Like Makin' Love",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/e84f5bc941a87ec961ab152dec17f652f9b0ed57",
    "artist_name": "Bad Company",
    "image_url": "https://i.scdn.co/image/32925c38441310311f5e64dd4cf8acfc508bb33f",
    "artist_id": "5AEG63ajney2BoDXi0Vb84",
    "year": 1975
  },
  {
    "song_id": "2GORop0i2cyx8C0A3JzL44",
    "name": "Dance With Me",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/bbbf6bdf80fa42372c8c027144ab6f004eb747f4",
    "artist_name": "Orleans",
    "image_url": "https://i.scdn.co/image/ee0d8e62a78d89b036e9c46f7dc2daa3674080b8",
    "artist_id": "1667U2YoucgG5Pdvm2M59c",
    "year": 1975
  },
  {
    "song_id": "5i7z7K3YuFCGbYIn6OzrpA",
    "name": "Someone Saved My Life Tonight",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/693f1bfbd88ccbad413499d873c99bc7cb9d82cf",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/020bd22f85b638d30d785e1f913e97d64dfed7e1",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1975
  },
  {
    "song_id": "5J9DSJl3OLV8AqYm4rPa2x",
    "name": "I Can Help - Single Version",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/002372ba91e02086c064d3948f9c9f2ea1fee818",
    "artist_name": "Billy Swan",
    "image_url": "https://i.scdn.co/image/a62c9cf113c78581c0eac6a86a9208fd693d2794",
    "artist_id": "6nNkKMkPl1qBCEW3Al9eVV",
    "year": 1975
  },
  {
    "song_id": "0DDgwlJDTQ6U3LPzUyvLu5",
    "name": "Never Can Say Goodbye",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/88dd2230480452dff4c15a705d7911cbad49c764",
    "artist_name": "Gloria Gaynor",
    "image_url": "https://i.scdn.co/image/9a47dd152865d210511b4a9eef2cabb1ea2777c7",
    "artist_id": "6V6WCgi7waF55bJmylC4H5",
    "year": 1975
  },
  {
    "song_id": "375ntCG12wzCyfzclwYFwG",
    "name": "Theme From \"Greatest American Hero\" (Believe It Or Not)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/e84020621bec478b50e34294f48b635d5ffa0faa",
    "artist_name": "Joey Scarbury",
    "image_url": "https://i.scdn.co/image/aebc5b28243b4c4e3b70868a8c9fed438ea75615",
    "artist_id": "5V9pNqcN25JXuH88g7lq2o",
    "year": 1975
  },
  {
    "song_id": "6FDrn47S3Q4s9rhLHm4um0",
    "name": "Could It Be Magic",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/e173471772a1948b939095e56ba8d7d5b0abfac7",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/a6b2bf21b184b54bcd4bfcce50b9c8e48cd46597",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1975
  },
  {
    "song_id": "1oxUiD6kR2UWYnIutS33pF",
    "name": "You're The First, The Last, My Everything",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/97884ddfd77a4759bc9768fd15a764c63ea4b73c",
    "artist_name": "Barry White",
    "image_url": "https://i.scdn.co/image/a35b15cfa442191ff85f9dc83d04cd66294f54a6",
    "artist_id": "3rfgbfpPSfXY40lzRK7Syt",
    "year": 1975
  },
  {
    "song_id": "4SpqztcLDLleGXOBz3lFT9",
    "name": "Some Kind Of Wonderful",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/9306d2d5dc8d939617a45ca0a0ab15c2ec963b12",
    "artist_name": "Grand Funk",
    "image_url": "https://i.scdn.co/image/8854deb7851553c5e98c4acc6cae4d611009e16f",
    "artist_id": "2tJ6csNN21bhubevSjTfjS",
    "year": 1975
  },
  {
    "song_id": "2RwFdFIN9JAyVUzevwkzez",
    "name": "How Sweet It Is [To Be Loved By You]",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/2312a3f3d441f9ba23e2d642a40481784dbd8084",
    "artist_name": "James Taylor",
    "image_url": "https://i.scdn.co/image/99ef9e7d4a1765a66a2e767d7590fb91282120ab",
    "artist_id": "0vn7UBvSQECKJm2817Yf1P",
    "year": 1975
  },
  {
    "song_id": "2wqF1ipfXE204LdA1QmoXa",
    "name": "Sha-La-La (Make Me Happy)",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/9b577c63a8f21108fa3e706b5efeb94263684d88",
    "artist_name": "Al Green",
    "image_url": "https://i.scdn.co/image/8e17346ffa0df5f75004a6dc2ec5481168d0687d",
    "artist_id": "3dkbV4qihUeMsqN4vBGg93",
    "year": 1975
  },
  {
    "song_id": "5DEy8wVNCdJdAGNsOWxmVQ",
    "name": "It Only Takes A Minute - 2004 Digital Remaster",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/4ab95f5b77f1e9aeaac274e583df880595e09f5b",
    "artist_name": "Tavares",
    "image_url": "https://i.scdn.co/image/7fa43e7c05c8f098470fada926e5f792969e278f",
    "artist_id": "3LfO03nEZMdWNHG2tLpMa0",
    "year": 1975
  },
  {
    "song_id": "1zIhupZdjLENZuzEkWPy44",
    "name": "You Ain't Seen Nothing Yet - Single Version",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/ac8befb142d59b3be1d1bd1522d79a596b8e3253",
    "artist_name": "Bachman-Turner Overdrive",
    "image_url": "https://i.scdn.co/image/04722d026fb4f3b919c0f48a229f5257cce20abf",
    "artist_id": "5q4AzEtCoYJyXjMMoEkSU5",
    "year": 1975
  },
  {
    "song_id": "0Ao3qWJKueyfs3iqwGN30F",
    "name": "Wasted Days And Wasted Nights",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/5592cd727bd4b3092474a733ff62d0f76b64e67d",
    "artist_name": "Freddy Fender",
    "image_url": "https://i.scdn.co/image/657561d238ba038b561e9c2b3227b59d968ee982",
    "artist_id": "0SNdq9iJyup4XY6JbNHbt6",
    "year": 1975
  },
  {
    "song_id": "5hTyIlRYTc0sNHk5wDk6Xl",
    "name": "That Lady - Pt. 1 & 2",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/9b8c8da457f856aa7436dff9b34a8e7275ff894d",
    "artist_name": "The Isley Brothers",
    "image_url": "https://i.scdn.co/image/c20d09237f5f7b787c023996b5c2896bd0e03501",
    "artist_id": "53QzNeFpzAaXYnrDBbDrIp",
    "year": 1975
  },
  {
    "song_id": "5ehVOC0zSdwWqyZlhomJSi",
    "name": "Have You Never Been Mellow",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/629c1192cbcd4403c7257ac93cc17edf6f77932f",
    "artist_name": "Olivia Newton-John",
    "image_url": "https://i.scdn.co/image/3e72ea436c383eaccb73d47a0f4a5d31e38bd432",
    "artist_id": "4BoRxUdrcgbbq1rxJvvhg9",
    "year": 1975
  },
  {
    "song_id": "2R4zFg4zs7IedThPY3CyAI",
    "name": "That's the Way of the World",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/eb226923b18e674f3fcdfd9241511db23f1e36ec",
    "artist_name": "Earth, Wind & Fire",
    "image_url": "https://i.scdn.co/image/33f703a93a98c0492edf64591d5f5896e77a6b75",
    "artist_id": "4QQgXkCYTt3BlENzhyNETg",
    "year": 1975
  },
  {
    "song_id": "6YA1LnVI98LXUmtMKs3fIk",
    "name": "Bungle In The Jungle",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/351995925692cf2b5ad2422e6e131a17362dd505",
    "artist_name": "Jethro Tull",
    "image_url": "https://i.scdn.co/image/f98511708b807a79d4779722619c058593a663a5",
    "artist_id": "6w6z8m4WXX7Tub4Rb6Lu7R",
    "year": 1975
  },
  {
    "song_id": "3bRcSxW2FnSSwrzoYT1knV",
    "name": "At Seventeen",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/a028d9618d2fb887e16023cc1254886d4c57d5fa",
    "artist_name": "Janis Ian",
    "image_url": "https://i.scdn.co/image/4b18b8703dac6d4abc171f01a1e5d37e4961ee28",
    "artist_id": "5c9uFWpZY2MTlk7Rft0tgp",
    "year": 1975
  },
  {
    "song_id": "20aZlN6QJxnLzhZbO7auv0",
    "name": "Lady Marmalade",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/5d0d678412dce631d27c565949f7632e77d17684",
    "artist_name": "LaBelle",
    "image_url": "https://i.scdn.co/image/258b5916b49b26cb5165738e1762bf4f95f66863",
    "artist_id": "3DznKagEU8yMQZR9z33Da5",
    "year": 1975
  },
  {
    "song_id": "2R4T2Nxu8dZD4KmECfVliG",
    "name": "Fire",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/58839a2353edf5895886422ecd33b7a81b077c3a",
    "artist_name": "Ohio Players",
    "image_url": "https://i.scdn.co/image/b81c7ca9b3ad1aa30db757457f7e68dd7a926573",
    "artist_id": "6m30rs1IQqnWqV5nKMpU7U",
    "year": 1975
  },
  {
    "song_id": "6RMo3zv9VjmcGi4V5O3cOL",
    "name": "Please Mr. Postman",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/42a3acbf4eb1c22370ad3466679aa33dc2212a74",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/a46be8e9d28f1e2c05b475194114d604a2f87836",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1975
  },
  {
    "song_id": "5h2XR7VWvJ7jPhbvCyuYee",
    "name": "Bad Time - 2002 Digital Remaster",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/128494355cf0511b18403ac5a873020faef917c7",
    "artist_name": "Grand Funk",
    "image_url": "https://i.scdn.co/image/5c2d98eab52dad4e8976df335d24e149fd02b126",
    "artist_id": "2tJ6csNN21bhubevSjTfjS",
    "year": 1975
  },
  {
    "song_id": "5kK5QHkx544IrrXBngWct3",
    "name": "Laughter In The Rain",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/dafd7a707ce3424fe8b350785142b2068553b4e8",
    "artist_name": "Neil Sedaka",
    "image_url": "https://i.scdn.co/image/3ef92fac61dedbd5999ab4b3f8fa64d07cf2a813",
    "artist_id": "5N6GwJzOcOY5kv8p0NjhYL",
    "year": 1975
  },
  {
    "song_id": "737c89v3Zdit8EUyNJ7KeS",
    "name": "Wildfire",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/5a216512dcc2013c490277ba807f040daa14ce6d",
    "artist_name": "Michael Martin Murphey",
    "image_url": "https://i.scdn.co/image/5a6981071581af277ddb912d33be21b74e8cfa8d",
    "artist_id": "4jL1usPiGDK8TD46zJP4gu",
    "year": 1975
  },
  {
    "song_id": "7Jx87mZSCjUWlVDCLiRFK6",
    "name": "Angie Baby",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/9af5d0a83064dacecfbe9d7d475c459817e8e947",
    "artist_name": "Helen Reddy",
    "image_url": "https://i.scdn.co/image/ba5219764143f4935e6e6085d6d2d6050f826d64",
    "artist_id": "0Sq7oGrYEe0BDmb13wgjOO",
    "year": 1975
  },
  {
    "song_id": "5jPPjNMIi1rD6BvQqxhJh5",
    "name": "When Will I Be Loved",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/44a97ca51a77b3db9a79f9f5997786bf35ab3968",
    "artist_name": "Linda Ronstadt",
    "image_url": "https://i.scdn.co/image/06be11077a82495c42a3467851e8e054d2325a41",
    "artist_id": "1sXbwvCQLGZnaH0Jp2HTVc",
    "year": 1975
  },
  {
    "song_id": "1lL1jDnZTH60djVb6vKIQj",
    "name": "Please Mr Please",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/a479f26998997ad4116b65c81175b722a6f5dd41",
    "artist_name": "Olivia Newton-John",
    "image_url": "https://i.scdn.co/image/3e72ea436c383eaccb73d47a0f4a5d31e38bd432",
    "artist_id": "4BoRxUdrcgbbq1rxJvvhg9",
    "year": 1975
  },
  {
    "song_id": "2ers9ULb9Nhycgf9QejbIU",
    "name": "Before The Next Teardrop Falls",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/2e1dfd1beb1dd75aff6d5d48a0bbb4560e1dd0d7",
    "artist_name": "Freddy Fender",
    "image_url": "https://i.scdn.co/image/77a8f3a37f099984fe4548879f90b028938d7567",
    "artist_id": "0SNdq9iJyup4XY6JbNHbt6",
    "year": 1975
  },
  {
    "song_id": "65vssm44ghOVf4tBZMY2AE",
    "name": "Walking In Rhythm",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/fe308f42cacf74166361e15898dd79d62dd1b180",
    "artist_name": "Blackbyrds",
    "image_url": "https://i.scdn.co/image/b5244b37ba1510c00a9a284d81d1a202bfce3c63",
    "artist_id": "7uykqYYhDUugFJgKZWXIWq",
    "year": 1975
  },
  {
    "song_id": "3GwmyDOlD87LMHrB5timMi",
    "name": "Cut The Cake",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/597bc8f3929cfe628412a74ca5fcd8cd7e9347e0",
    "artist_name": "Average White Band",
    "image_url": "https://i.scdn.co/image/00fe0d2ef4427e10d9802392d06fafdc45b44928",
    "artist_id": "3tx8fyu3c4OBP5nejYtUOb",
    "year": 1975
  },
  {
    "song_id": "6OADGew7LIa4msMBoFAPpm",
    "name": "Philadelphia Freedom",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/3ec525478ee1ee8b00d7c188ef3eb458720a3567",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/7eb5366b71f2a1987cbabbae5b2525c974503c21",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1975
  },
  {
    "song_id": "3axR3m0Obsx0SPPl9vEO72",
    "name": "Lucy In The Sky With Diamonds",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/933e47c4414ad6ce56b6ac2ea97e682c6a3aeb08",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/7eb5366b71f2a1987cbabbae5b2525c974503c21",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1975
  },
  {
    "song_id": "2xPrkm4K7mIMiOarGQZUod",
    "name": "Calypso",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/5d9e4ef45bbab72da4cf70e732ac672f64a53716",
    "artist_name": "John Denver",
    "image_url": "https://i.scdn.co/image/4672a4c5e98f9179e18f729d284165b4d7089ba0",
    "artist_id": "7EK1bQADBoqbYXnT4Cqv9w",
    "year": 1975
  },
  {
    "song_id": "1R9EHsvzpXwOvlGkgDmE55",
    "name": "Jackie Blue",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/273cb3d24143eab93454c1b3e0e8f848e79cfc8b",
    "artist_name": "The Ozark Mountain Daredevils",
    "image_url": "https://i.scdn.co/image/3babdd112a5a5e62ddf90a77d31847f0ea0fb701",
    "artist_id": "7jM2XoJBKhdL8oNnDr4re9",
    "year": 1975
  },
  {
    "song_id": "3O3HTZ2O88k9zlC913mQzL",
    "name": "Chevy Van",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/b4fef7fe1b7a1e302c3615f294b346c94e42720c",
    "artist_name": "Sammy Johns",
    "image_url": "https://i.scdn.co/image/212fd20293e07938cdcedd5f9107ec73588f901c",
    "artist_id": "5L2UqkzpfZD5OpdA6fSfVH",
    "year": 1975
  },
  {
    "song_id": "0N3qcAXZM06zaTKm7JCtIh",
    "name": "Midnight Blue",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/35b239a83231fa49fcc10970462bec820a44b0b2",
    "artist_name": "Melissa Manchester",
    "image_url": "https://i.scdn.co/image/6793912b5fdff6990277232c2162c9cec1631578",
    "artist_id": "78udmCDNaDu5jqfVnBqwia",
    "year": 1975
  },
  {
    "song_id": "1wpRglLhQ092N1rftlDMD3",
    "name": "Lady",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/8b0de2b45d613cee69c98c07e6450f55b2b432b1",
    "artist_name": "Styx",
    "image_url": "https://i.scdn.co/image/600e3639d8289a43317a43cb19d355a6a55941df",
    "artist_id": "4salDzkGmfycRqNUbyBphh",
    "year": 1975
  },
  {
    "song_id": "39YO9m1p8gX1OXs46FIjL4",
    "name": "Do It ('Til You're Satisfied)",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/9baadfa7b8f266c7abd3b037b67b435be5c175c6",
    "artist_name": "B.T. Express",
    "image_url": "https://i.scdn.co/image/d0a363a3a3e6317456d24d5cf91ae475dd2e1262",
    "artist_id": "67PmHOmt1tveILUtasiYAf",
    "year": 1975
  },
  {
    "song_id": "2G9HPwBaHica41sMtQ7QvX",
    "name": "I'm Not Lisa",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/7819715b719108ce079e298c243fc6c93282bdb4",
    "artist_name": "Jessi Colter",
    "image_url": "https://i.scdn.co/image/d3142b2aa166c90facd82676c679d927b3d006c5",
    "artist_id": "43HpW8B5gYRnYbSzm37n5v",
    "year": 1975
  },
  {
    "song_id": "5uhULfoDgTvz7BeWlqrV4a",
    "name": "Poetry Man",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/2320c9980a5fc6275c1148966032b4ba459c2912",
    "artist_name": "Phoebe Snow",
    "image_url": "https://i.scdn.co/image/6a9b7bc5b041fa84317d67ca866c7d7f7f5c2547",
    "artist_id": "3iuobQyYhofKRr5W6HZoKt",
    "year": 1975
  },
  {
    "song_id": "136yoWt0QLFmhHxjFiud2g",
    "name": "Doctor's Orders",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/bc5c1042dacaa15fae8e7835498769cc2f69e537",
    "artist_name": "Carol Douglas",
    "image_url": "https://i.scdn.co/image/abce11a8cb963e1140c44783a7f6a7d432ecd4a6",
    "artist_id": "7xcXR8rEj6eRPVMFjUNtBe",
    "year": 1975
  },
  {
    "song_id": "7FkCscwzYo9l3mzYIH8Odh",
    "name": "The Hustle - Super Hustle Mix",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/1aa2da3742eb4bae636eb5a907b27a497f10431c",
    "artist_name": "Van McCoy",
    "image_url": "https://i.scdn.co/image/7c590c4ef6ad0ea73f868efa55bb67ea4afb63fd",
    "artist_id": "0bShb58TO0fM5jOjXhB1WT",
    "year": 1975
  },
  {
    "song_id": "0VaPY3XlVYUGXB9hR1fj1G",
    "name": "Junior's Farm - DJ Edit",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/efa726dbea52369e92706e06dda48117bea1f464",
    "artist_name": "Paul McCartney",
    "image_url": "https://i.scdn.co/image/a5367d4c896e254d70fce0fa3c5777f3729fd9d1",
    "artist_id": "4STHEaNw4mPZ2tzheohgXB",
    "year": 1975
  },
  {
    "song_id": "2r0WrsNwiq9ABNxY4tKqwg",
    "name": "Fallin’ In Love",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/6c46ad6074189e0f0947cb9b23707a60376684a5",
    "artist_name": "Hamilton, Joe Frank & Reynolds",
    "image_url": "https://i.scdn.co/image/9a7568f3a9b76712e14c8026a5ce19d425817149",
    "artist_id": "7vpRSadEAzLs0ikY7gAESe",
    "year": 1975
  },
  {
    "song_id": "6f8lvWy8uLCQRCC4WMNRGn",
    "name": "I'm Sorry",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/6d03b7b8d474e36c6e8b374aa9464df2f67d3d38",
    "artist_name": "John Denver",
    "image_url": "https://i.scdn.co/image/4672a4c5e98f9179e18f729d284165b4d7089ba0",
    "artist_id": "7EK1bQADBoqbYXnT4Cqv9w",
    "year": 1975
  },
  {
    "song_id": "4uAp4og2YfupdRwfyX6CPp",
    "name": "Only Yesterday",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/714f391c9eeb834df5a873ae3a158179cce3e0d3",
    "artist_name": "Carpenters",
    "image_url": "https://i.scdn.co/image/d5c661d1c86a3751d075bb9add4874ce50aa5f9d",
    "artist_id": "1eEfMU2AhEo7XnKgL7c304",
    "year": 1975
  },
  {
    "song_id": "3DrZgJwGlkWUtgwfuLTx06",
    "name": "Listen To What The Man Said - Remastered",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/ed2f578d11d816dda917619b3a4ed31443f01d58",
    "artist_name": "Paul McCartney",
    "image_url": "https://i.scdn.co/image/522621b3d0394459a37c4bdd84061b75ea08cc92",
    "artist_id": "4STHEaNw4mPZ2tzheohgXB",
    "year": 1975
  },
  {
    "song_id": "3z1wso55PIBeYKdkjFtSro",
    "name": "You Got The Love",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/d262386367297236217e95c1621fe6dad1cabc47",
    "artist_name": "Rufus",
    "image_url": "https://i.scdn.co/image/735e6425e669f104fe46bf73402939fab3faeb11",
    "artist_id": "3MCjwjfIor5FkK3h639qkW",
    "year": 1975
  },
  {
    "song_id": "0YZwwIvn3rE5eQnKkaLtpq",
    "name": "Another Somebody Done Somebody Wrong Song",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/167c14c541c9f3504103545b4c32a21eca5d7efa",
    "artist_name": "B.J. Thomas",
    "image_url": "https://i.scdn.co/image/f44305e0409e5e9adb76a26cc8677f604621ade5",
    "artist_id": "0uUNzXylqsZdmFDwdxaP1V",
    "year": 1975
  },
  {
    "song_id": "0XaaoZUWQFzcFiQbXKhABU",
    "name": "He Don't Love You [Like I Love You]",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/a79b4f1b8de2e04b23f7c62c52ad3846afb0ab14",
    "artist_name": "Tony Orlando & Dawn",
    "image_url": "https://i.scdn.co/image/791454041623fcf812f6fe6c5578b40c481be8d9",
    "artist_id": "72NXpYBIaTfEeAAsxXLs0P",
    "year": 1975
  },
  {
    "song_id": "218AiunmmYDSPVKgCTa4Q7",
    "name": "One Man Woman/One Woman Man - feat. Odia Coates",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/4a6574821869cdee1a86a9c074636a59946b7fb5",
    "artist_name": "Paul Anka",
    "image_url": "https://i.scdn.co/image/a07b2e0a5b469eed3e9a5d36eb613a92b9045559",
    "artist_id": "7ceUfdWq2t5nbatS6ollHh",
    "year": 1975
  },
  {
    "song_id": "4etVLoQK3znrv8bVC8grsE",
    "name": "I'm On Fire - 1996 - Remaster",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/f04adcfcfbbe49d909cdef1260aa2bf374e666f1",
    "artist_name": "Dwight Twilley Band",
    "image_url": "https://i.scdn.co/image/85bcaee54891fc3161ba32c812c869249bd2559d",
    "artist_id": "6IpcPRU1kV0J58kd2w8vSC",
    "year": 1975
  },
  {
    "song_id": "1oRvLqwJowH9JU0uO1iVzA",
    "name": "Third Rate Romance - Remastered",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/e518b20c8574d57ad631bce5dba59e5ae836963c",
    "artist_name": "Amazing Rhythm Aces",
    "image_url": "https://i.scdn.co/image/6464279d86c8ce82dfd3fc1e28d1aed10b98da15",
    "artist_id": "5wpS6LUdQYMoAreKITVtrH",
    "year": 1975
  },
  {
    "song_id": "5bUciNnWtYtwmVhWn1K1um",
    "name": "Get Dancin'",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/39394e4ecec4a4d83a2684423bf027554849dab0",
    "artist_name": "Disco Tex & The Sex-O-Lettes",
    "image_url": "https://i.scdn.co/image/d235f543be0c3f62fca9dca31688bcc560e5c446",
    "artist_id": "1ZxFDRFB5f4B64eMjOL7Wl",
    "year": 1975
  },
  {
    "song_id": "2q3XgRHza6bTNdESW1Yk1B",
    "name": "Express",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/6c94bdc70841ac298e6680c530e1bf81f1876cbb",
    "artist_name": "B.T. Express",
    "image_url": "https://i.scdn.co/image/d0a363a3a3e6317456d24d5cf91ae475dd2e1262",
    "artist_id": "67PmHOmt1tveILUtasiYAf",
    "year": 1975
  },
  {
    "song_id": "0kgjL2oRFsV6GSwVNCcQS6",
    "name": "I Don't Like to Sleep Alone - feat. Odia Coates",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/f0400020047b3743600415f1aa114975def44104",
    "artist_name": "Paul Anka",
    "image_url": "https://i.scdn.co/image/018408f8ce0691635b7fc59e6374aa446b15866d",
    "artist_id": "7ceUfdWq2t5nbatS6ollHh",
    "year": 1975
  },
  {
    "song_id": "3iSWyyxrJwnSoXhoRmLaXt",
    "name": "Long Tall Glasses - I Can Dance Remastered",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/d951274d05e5a6a04d41d58922dec56dafa35b55",
    "artist_name": "Leo Sayer",
    "image_url": "https://i.scdn.co/image/e7edb5d05540cb496b2d59b3e565ba78b279b270",
    "artist_id": "04LIHk1SobiQwt2tlupoAV",
    "year": 1975
  },
  {
    "song_id": "558PfZpodTRLOfMZ6jD0oU",
    "name": "Swearin' To God - 2007 Remastered Single Version",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/b18dfd80c9fe567f9e03bb54d22e9387b595c43a",
    "artist_name": "Frankie Valli",
    "image_url": "https://i.scdn.co/image/5943fb1a1cc3cd83e4656946b592238b3085050f",
    "artist_id": "3CDKmzJu6uwEGnPLLZffpD",
    "year": 1975
  },
  {
    "song_id": "3wVQC6hQQ3G41f8VWSmdwC",
    "name": "The Way We Were / Try to Remember",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/bc5a8cfa9705bfe0d4ab89700b5602c7f385a980",
    "artist_name": "Gladys Knight & The Pips",
    "image_url": "https://i.scdn.co/image/afb38007120371d66046515b6bbdbf946475057c",
    "artist_id": "0TF2NxkJZPQoX1H53rEFM1",
    "year": 1975
  },
  {
    "song_id": "1PjSt7LYndfudAOgVA7vbn",
    "name": "Shoeshine Boy",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/32f78b56b4a54a5b14fcdf10e0641099012ea594",
    "artist_name": "Eddie Kendricks",
    "image_url": "https://i.scdn.co/image/5d1bdbf43a9d652946c19f8560f701ad0a186899",
    "artist_id": "2Uuon75BhnuuxdKLYn4wHn",
    "year": 1975
  },
  {
    "song_id": "4uDOdEFrKhQ9qfh2C1mW0Y",
    "name": "The No-No Song - Live",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/09408cf36a5bbddd5700ea2f39719c120e0ddd23",
    "artist_name": "Ringo Starr And His All Starr Band",
    "image_url": "https://i.scdn.co/image/d5099163a3dfdcb3fdfa0d8f4c615368b357a8fe",
    "artist_id": "1aNVGYkng3tCXI2vPVANq7",
    "year": 1975
  },
  {
    "song_id": "5ZX5pIdGQaeHxtHHe7v6ku",
    "name": "Theme From The Rockford Files",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/fc5c96f33ac85fb3b1658686ba17600c79620f21",
    "artist_name": "Mike Post & Larry Carlton",
    "image_url": "https://i.scdn.co/image/6bcbe3b7c7332c22c1afa1d2f84d94d1be1c5cb5",
    "artist_id": "3D3ZXceMKK78mcGcsroslL",
    "year": 1975
  },
  {
    "song_id": "58xaG4P5HeG7K2rgu0WPCl",
    "name": "Feelings",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/3aaa4e66e60f0a5fe98175436f4ede78c0f6de87",
    "artist_name": "Morris Albert",
    "image_url": "https://i.scdn.co/image/75ff9cd8d8271a4e93508a47e49bef3484aa9fa2",
    "artist_id": "4sFsq7NFJ0zz0uN3Zb6QJ9",
    "year": 1975
  },
  {
    "song_id": "03U0dRNeV2CoG1ict93ZKU",
    "name": "Bad Blood",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/17434f0b5266e0c5aa82d7937841a7a601c93104",
    "artist_name": "Neil Sedaka",
    "image_url": "https://i.scdn.co/image/cb050205450ef501b7873d357fb1612039059309",
    "artist_id": "5N6GwJzOcOY5kv8p0NjhYL",
    "year": 1975
  },
  {
    "song_id": "2mZ4TDha5FgkGC3l2winZk",
    "name": "Morning Side Of The Mountain",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/be4963ba25dac86bdc5c033c1a9fb30d436a9a92",
    "artist_name": "Donny Osmond",
    "image_url": "https://i.scdn.co/image/e65641290b16bfd4e9c42e907590ff29ed2c5311",
    "artist_id": "5ZEAzHE2TzAwUcOj6jMIgf",
    "year": 1975
  },
  {
    "song_id": "2Fieg2chYnkmZJprylHSr9",
    "name": "Don't Call Us, We'll Call You",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/a2ba261633d7ac9a704ea773476dc20645c21cc5",
    "artist_name": "Sugarloaf",
    "image_url": "https://i.scdn.co/image/52601d97ca91d1c528acbe5cac561da9c0688731",
    "artist_id": "1T3RPiMH711kvoXymKUTYH",
    "year": 1975
  },
  {
    "song_id": "5QrZYnlrfY7LkJrn7DlKgF",
    "name": "Get Down, Get Down, Get On The Floor",
    "popularity": 5,
    "preview_url": "https://p.scdn.co/mp3-preview/0656267db88f461963ba73f774f29cd92d08286b",
    "artist_name": "Joe Simon",
    "image_url": "https://i.scdn.co/image/06dd85f051f311fa98a3e8f5b37329630fcb6f0a",
    "artist_id": "5HRSYURV6LFFRCjAtiK0py",
    "year": 1975
  },
  {
    "song_id": "07KHJvlYBeQVqrmifTEqEp",
    "name": "Dream On",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/da2d8c022a7f43f62ab506ef3310878ee2f0c126",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/e38642e10a9e9d9b009aa168a4f02a4c90e3aa86",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1976
  },
  {
    "song_id": "714hERk9U1W8FMYkoC83CO",
    "name": "You Sexy Thing",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/122f2bf8ced60bae8be3cbf10fb094019c542f89",
    "artist_name": "Hot Chocolate",
    "image_url": "https://i.scdn.co/image/f6eeb28637f19af2ebc88e0ad6a75c3e053299e7",
    "artist_id": "72VzFto8DYvKHocaHYNWSi",
    "year": 1976
  },
  {
    "song_id": "0KMGxYKeUzK9wc5DZCt3HT",
    "name": "If You Leave Me Now - Remastered",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/a6217355565e57eaaaa4519f6c558df299fe7e09",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/730e9ea3b8bd5b5d2590216346aa95a360ea7f6d",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1976
  },
  {
    "song_id": "4vEXJ6NvEwS8ptynkNswaa",
    "name": "You're My Best Friend - 2011 Remaster",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/f4d52bc27619bd62f531529827e277bd11f7fa38",
    "artist_name": "Queen",
    "image_url": "https://i.scdn.co/image/5e2d4c9fce0dfa3689bd0a20754de696c69a4658",
    "artist_id": "1dfeR4HaWDbWqFHLkxsg1d",
    "year": 1976
  },
  {
    "song_id": "7bdDULyOUqNyWu6XPeAnSO",
    "name": "50 Ways to Leave Your Lover",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/6bb069178dae048b234e5a614fe64efc59d27312",
    "artist_name": "Paul Simon",
    "image_url": "https://i.scdn.co/image/3b5eaaf3d4800724828fa8537f3b8ceac0e75fed",
    "artist_id": "2CvCyf1gEVhI0mX6aFXmVI",
    "year": 1976
  },
  {
    "song_id": "5TWPfkeN07aERO0Yn2D2t2",
    "name": "Rhiannon",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/8b8d017c8ddca52bf2cab52863c555987e0b1737",
    "artist_name": "Fleetwood Mac",
    "image_url": "https://i.scdn.co/image/dfee97010f228516e88d1caded5dfbb32a1702d7",
    "artist_id": "08GQAI4eElDnROBrJRGE0X",
    "year": 1976
  },
  {
    "song_id": "4MYb7NWLwXNDB7bYs3HeX8",
    "name": "Slow Ride (45 Version)",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/cd3091a640b4830717e3b4fa5707ee5a5f98e3ab",
    "artist_name": "Foghat",
    "image_url": "https://i.scdn.co/image/d2f9ad405035d1c0df0b6006d72d35a5134f0232",
    "artist_id": "6x33CmZWo2Ve4hxYl2Craq",
    "year": 1976
  },
  {
    "song_id": "43DeSV93pJPT4lCZaWZ6b1",
    "name": "The Boys Are Back In Town",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/c503daa674dd14caca9b2ececdb24f5a264527c4",
    "artist_name": "Thin Lizzy",
    "image_url": "https://i.scdn.co/image/3c956794ee412e25807b016826d7607745fb58e6",
    "artist_id": "6biWAmrHyiMkX49LkycGqQ",
    "year": 1976
  },
  {
    "song_id": "2Zg1sMyklsgALBBTI3C812",
    "name": "Got to Get You into My Life",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/14e1ec4e890961fca9537f1bdf2abf87b93f74ef",
    "artist_name": "Earth, Wind & Fire",
    "image_url": "https://i.scdn.co/image/33f703a93a98c0492edf64591d5f5896e77a6b75",
    "artist_id": "4QQgXkCYTt3BlENzhyNETg",
    "year": 1976
  },
  {
    "song_id": "7IoKA8yRibsK8Ryy4SH2NL",
    "name": "I Love Music",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/3f29ffbc189155d2de7bb303dbdf71a05fc3aaa9",
    "artist_name": "The O'Jays",
    "image_url": "https://i.scdn.co/image/dea359bf75d53e7bf37254542e470737e90a70f5",
    "artist_id": "38h03gA85YYPeDPd9ER9rT",
    "year": 1976
  },
  {
    "song_id": "74DrA5fFoGSy4xgkZarZtP",
    "name": "Dream Weaver",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/9e96528823570c716edd644fb4009ac3276b4493",
    "artist_name": "Gary Wright",
    "image_url": "https://i.scdn.co/image/663c378096c2458be534a3b4e9a8da5f4614b810",
    "artist_id": "3z6Uf5IZkN4TogRpRpgD84",
    "year": 1976
  },
  {
    "song_id": "0GmRPoU0PrO1crMm7mB7zg",
    "name": "Afternoon Delight",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/1a285485c93d192f03e7ed91c8c346b77b32a005",
    "artist_name": "Starland Vocal Band",
    "image_url": "https://i.scdn.co/image/cbeaa2ef3334a7362da1fe2190af86171233e5a5",
    "artist_id": "66QeIFuFJDNCImNHlFzrY1",
    "year": 1976
  },
  {
    "song_id": "6roaUdrIQUlTcujSFLtYKI",
    "name": "Kiss and Say Goodbye",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/68952a1c13ce5dac54ade35d7f7448799e1b3385",
    "artist_name": "The Manhattans",
    "image_url": "https://i.scdn.co/image/b1edf6d454c958f30d81a020406b0b8aaad0c54f",
    "artist_id": "1DpIDwg8FGD50N9Tfunfsf",
    "year": 1976
  },
  {
    "song_id": "2kOOxUIHZvz4TsYE6kHYjb",
    "name": "Say You Love Me",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/71886e947137a94c16f7f465eab4708c980c845c",
    "artist_name": "Fleetwood Mac",
    "image_url": "https://i.scdn.co/image/dfee97010f228516e88d1caded5dfbb32a1702d7",
    "artist_id": "08GQAI4eElDnROBrJRGE0X",
    "year": 1976
  },
  {
    "song_id": "4bEtg70srLrCqQp3Nm2T5R",
    "name": "You'll Never Find Another Love Like Mine",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/b22e5cceab0448b4d2a10c10ef1d49f737d9f600",
    "artist_name": "Lou Rawls",
    "image_url": "https://i.scdn.co/image/78ae48d3496e4a6280cbf43285f72f4d59348199",
    "artist_id": "1zJBFCev9UwOMcrZsLi2od",
    "year": 1976
  },
  {
    "song_id": "5LyzPhFwF7MgUY3aQD9TAG",
    "name": "A Fifth Of Beethoven - 2007 Remastered Saturday Night Fever",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/caeb9716124538d9bce7651e318547e2ef989995",
    "artist_name": "Walter Murphy",
    "image_url": "https://i.scdn.co/image/c4b4399d9b7c6f61b6a6ee70c616bc1a985c7ab8",
    "artist_id": "73JBR5s2PVvUjGu6tIqjqx",
    "year": 1976
  },
  {
    "song_id": "6OBeM4d4RGzxcMphQVSgED",
    "name": "Love Rollercoaster",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/ba7e2ebc162a967afa02767c3f3afdc16777b276",
    "artist_name": "Ohio Players",
    "image_url": "https://i.scdn.co/image/4986f741e8be28f27ff9ab4e958b795874613c4b",
    "artist_id": "6m30rs1IQqnWqV5nKMpU7U",
    "year": 1976
  },
  {
    "song_id": "1ksayCHVLH4eKFm43GFokU",
    "name": "Baby, I Love Your Way - Live",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/6bb27b06f6dd781aff654acbbf8c676c3af25176",
    "artist_name": "Peter Frampton",
    "image_url": "https://i.scdn.co/image/fa22934f52e40e87b276bcad1ccdd8186f492755",
    "artist_id": "0543y7yrvny4KymoaneT4W",
    "year": 1976
  },
  {
    "song_id": "3Hp66WUQ6yu69jMrirhSRs",
    "name": "Sing a Song",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/cde9c1ff7665e760fb9e49a8ba14c0a9a251a923",
    "artist_name": "Earth, Wind & Fire",
    "image_url": "https://i.scdn.co/image/33f703a93a98c0492edf64591d5f5896e77a6b75",
    "artist_id": "4QQgXkCYTt3BlENzhyNETg",
    "year": 1976
  },
  {
    "song_id": "0Nn3zTnQW0vpWedAMlImui",
    "name": "You Should Be Dancing - 2007 Remastered Saturday Night Fever",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/9b2616de8cab98018516e145323ff130510c1a35",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/c4b4399d9b7c6f61b6a6ee70c616bc1a985c7ab8",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1976
  },
  {
    "song_id": "1i5oEx2vFczQ2sCY3c6Kn5",
    "name": "Love Hurts",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/a91ffce5749e3b16eff014d363080f88b4f4ca64",
    "artist_name": "Nazareth",
    "image_url": "https://i.scdn.co/image/a7a41a007591af6289a24336445f4bcdca644a29",
    "artist_id": "6fvN9GmMCVKb5LY0WsnjFP",
    "year": 1976
  },
  {
    "song_id": "6vLaKD0HUJ5UtIADG61Fa9",
    "name": "Bohemian Rhapsody - Remastered",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/fcc95e6579fd24512413cae107265ee82dbdef0c",
    "artist_name": "Queen",
    "image_url": "https://i.scdn.co/image/f427034ed40cf5265c1a7b67c777be4695338bb8",
    "artist_id": "1dfeR4HaWDbWqFHLkxsg1d",
    "year": 1976
  },
  {
    "song_id": "4TI306LIWdII9L3OitUeJY",
    "name": "Sara Smile - Remastered 2003",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/395f314d0972b8985beb3c53efcf94d313010107",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/82216e16eff844df7916f5024dd7041403b6c82a",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1976
  },
  {
    "song_id": "6dGWq08LXuIw6T2oUeHECh",
    "name": "Convoy",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/0e91982927222572a3d16f4f2de90088d9918be8",
    "artist_name": "C.W. McCall",
    "image_url": "https://i.scdn.co/image/93919c95b3bfbb5f964a59ebdbce6b50111ded3f",
    "artist_id": "0iTkPxRldzi5lmS6qZ70JV",
    "year": 1976
  },
  {
    "song_id": "0bqZnsPz4klg5TuWGcKb34",
    "name": "Sweet Thing",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/ddcfa20318b5bef04a2cce320edf3d3d8cf742f8",
    "artist_name": "Rufus",
    "image_url": "https://i.scdn.co/image/0be3bd5b7b4ac245fa06d4d4774de1f1be882f5c",
    "artist_id": "3MCjwjfIor5FkK3h639qkW",
    "year": 1976
  },
  {
    "song_id": "7c52LVpkxq3dzMEkDWiQyw",
    "name": "Only Sixteen",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/72d640a1be8dc3ff7912983f335be2af03187065",
    "artist_name": "Dr. Hook",
    "image_url": "https://i.scdn.co/image/452a37ffd6f07386f63f8447e08ec5db48402eea",
    "artist_id": "2Mhi3jfuRSdbVZPdjqsnnN",
    "year": 1976
  },
  {
    "song_id": "1hlG7OWNQYhY01ZOd7JL6S",
    "name": "Golden Years - 1999 Digital Remaster",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/1fdc68f5278bbeb08bb214fc2590f06fd2c7ddaa",
    "artist_name": "David Bowie",
    "image_url": "https://i.scdn.co/image/13047e89fb6bd4bcb4e3ad65f241fe85ca74eb4f",
    "artist_id": "0oSGxfWSnnOXhD2fKuz2Gy",
    "year": 1976
  },
  {
    "song_id": "2lBilUJAUX9jCNbbpPRQDD",
    "name": "Misty Blue",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/eb2c7138503fbd5fc88b8749269a41ce9ed71d71",
    "artist_name": "Dorothy Moore",
    "image_url": "https://i.scdn.co/image/498e11912b561ff8f9ad62013f0fc36e2110c21a",
    "artist_id": "3KQOgtRIeQVHdYFcNHKavs",
    "year": 1976
  },
  {
    "song_id": "7K09L0XlSGoRM8v8V6aXZ9",
    "name": "Give Up The Funk (Tear The Roof Off The Sucker)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/d4eb6260964b0c29e7860086ee50e793f40a0a97",
    "artist_name": "Parliament",
    "image_url": "https://i.scdn.co/image/053925df0c710e3b38996486f5dcd5ba87282aba",
    "artist_id": "5SMVzTJyKFJ7TUb46DglcH",
    "year": 1976
  },
  {
    "song_id": "51WE0PFRB0hCSp9mDC5p8u",
    "name": "I'd Really Love To See You Tonight",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/6c1c8a0e7398b99e14ad1339065566c56cc2610b",
    "artist_name": "England Dan Seals",
    "image_url": "https://i.scdn.co/image/ba92434d10a03c651fbebaea47272d5f95769c31",
    "artist_id": "1sFmYEcBTHjGELXH5vxSi9",
    "year": 1976
  },
  {
    "song_id": "3gbnQ7TJEx1EI9chRZXVXl",
    "name": "Love Hangover - Single Version",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/2dd1b3bf07c069dc909253a370aa173631132d64",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/ddc3f648459c27919fdd9cca82640045ad99b9b2",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1976
  },
  {
    "song_id": "5uS8HGp7VfuU5fDarEbNi7",
    "name": "Show Me The Way - Live",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/28f6b7b4a67ca8184f66d59890989ab39d1797f3",
    "artist_name": "Peter Frampton",
    "image_url": "https://i.scdn.co/image/fa22934f52e40e87b276bcad1ccdd8186f492755",
    "artist_id": "0543y7yrvny4KymoaneT4W",
    "year": 1976
  },
  {
    "song_id": "77PCWchIQ9Dopdp5sahDEc",
    "name": "Fanny [Be Tender With My Love] (Remastered Album Version)",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/495d9d98f1184d6303249ade90f57231361ee254",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/9ba9a3a360c2f681818fa251fcc4a5800c01b1e3",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1976
  },
  {
    "song_id": "7EQGvEps8g0ozPbRPsfdXZ",
    "name": "Love Machine - Pt. 1",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/86238b52cbf9b33419558c72014505d69ae90352",
    "artist_name": "The Miracles",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "6TqQLejnHXMGr7KcegxUND",
    "year": 1976
  },
  {
    "song_id": "2skIFujR9zTTAEXj8tfrw0",
    "name": "This Masquerade",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/037c8ab16be3742f2daeacd4581149cccf0e6004",
    "artist_name": "George Benson",
    "image_url": "https://i.scdn.co/image/8dd4927e9daf3258372b392db4821e7e16a47e09",
    "artist_id": "4N8BwYTEC6XqykGvXXlmfv",
    "year": 1976
  },
  {
    "song_id": "0RQTbMBgSq7xgdZSHFZg4R",
    "name": "That's The Way (I Like It)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/1d6b87dc1ad7133afffb6e23e1a3bdf9c8a44f53",
    "artist_name": "KC & The Sunshine Band",
    "image_url": "https://i.scdn.co/image/e04c2abb9c42c04ecf2e24f30fed9f1620afad81",
    "artist_id": "3mQBpAOMWYqAZyxtyeo4Lo",
    "year": 1976
  },
  {
    "song_id": "3qZuggj5VKuhntEdXsz1SK",
    "name": "A Little Bit More",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/b0384b5c70d0dde37646664fe257ca468cd994a6",
    "artist_name": "Dr. Hook",
    "image_url": "https://i.scdn.co/image/452a37ffd6f07386f63f8447e08ec5db48402eea",
    "artist_id": "2Mhi3jfuRSdbVZPdjqsnnN",
    "year": 1976
  },
  {
    "song_id": "2TziLOnBMT8kDVo2LA1X6t",
    "name": "Turn the Beat Around",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/e05fd02a1c835fee10c2dac6ca9b6bdc8aaa20e8",
    "artist_name": "Vicki Sue Robinson",
    "image_url": "https://i.scdn.co/image/d46eefe6d2d41f9dd0423cdec16c97c5a9ef313e",
    "artist_id": "25pwh5JrKHzLv2FTPSvoqa",
    "year": 1976
  },
  {
    "song_id": "5vVuiXoHyRGxJeCaHUpgae",
    "name": "Love Is Alive",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/9b37ed0a68dfb1de40c8fd5caffe891c66f088c2",
    "artist_name": "Gary Wright",
    "image_url": "https://i.scdn.co/image/663c378096c2458be534a3b4e9a8da5f4614b810",
    "artist_id": "3z6Uf5IZkN4TogRpRpgD84",
    "year": 1976
  },
  {
    "song_id": "3ad1MVf3CUFCWW1EyMc9kk",
    "name": "Heaven Must Be Missing An Angel",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/886c16e2c75a56e7ec1dff93f8364f1638f4c626",
    "artist_name": "Tavares",
    "image_url": "https://i.scdn.co/image/7fa43e7c05c8f098470fada926e5f792969e278f",
    "artist_id": "3LfO03nEZMdWNHG2tLpMa0",
    "year": 1976
  },
  {
    "song_id": "0vE1E6ZNJGlCHPe95z5Orl",
    "name": "Boogie Fever",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/3f45767e569bb3f53f1c12018612b6558e1f0888",
    "artist_name": "The Sylvers",
    "image_url": "https://i.scdn.co/image/dabd7fe305bac09f9ed5f30f47dce6c1e8c88761",
    "artist_id": "0efkWb9xKhT6H0E7kgSjFK",
    "year": 1976
  },
  {
    "song_id": "4hepyFFOO9I7oZCNn3T2sz",
    "name": "More, More, More",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/997b44b25de611be8a23451da77cf814a47d7847",
    "artist_name": "Andrea True Connection",
    "image_url": "https://i.scdn.co/image/7236e4475a891bdb26c9eab262df6a9a5ce7dc26",
    "artist_id": "3GHovBcEWpbnwCZDPF9GpM",
    "year": 1976
  },
  {
    "song_id": "4Wr14AISsCuBGdkA6rids4",
    "name": "Fly Robin Fly",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/fa1d53ddac211466997385c8c2627252c16334f3",
    "artist_name": "SILVER CONVENTION",
    "image_url": "https://i.scdn.co/image/75d6cc3b6835835ebcdd3bc7ecdeb76352030996",
    "artist_id": "5QHvbEwccF3WANUD5lEIuA",
    "year": 1976
  },
  {
    "song_id": "3XmIxdK4YX8m94XWmwKheA",
    "name": "I Write the Songs - Single Edit",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/3c765df6622f07f8fd4f2d856f75ae3537b6b157",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/8184f45937baed4ec717f3558e391e99b0c5df30",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1976
  },
  {
    "song_id": "4MvypG63QYhgFml4PbkycS",
    "name": "Get Closer",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/f065f4b91730b41731826a95a388c30371b99595",
    "artist_name": "Seals and Crofts",
    "image_url": "https://i.scdn.co/image/b82f1adf9144a46dc0ddae7a8bd3e1afa2d49a94",
    "artist_id": "6jdObwsrIjSRnBbMw6lPBj",
    "year": 1976
  },
  {
    "song_id": "66gG8RzSA2sVQwME8e43wX",
    "name": "Fox On The Run",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/40f9921440bb9d2259c28959d6e749b4e8ae6b5d",
    "artist_name": "Sweet",
    "image_url": "https://i.scdn.co/image/31d2726acd629b7d10498fe44d0dd62089e97ee4",
    "artist_id": "3JaAGmSTpJK35DqWrDUzBz",
    "year": 1976
  },
  {
    "song_id": "4CUxUwbWODl4g3EaroGj3C",
    "name": "I'll Be Good To You",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/e338c46204afda7a960c9110c6f799a5b3105a8c",
    "artist_name": "The Brothers Johnson",
    "image_url": "https://i.scdn.co/image/f5fef41ce65657b9d2f8a24a3a3e763ec28cb51d",
    "artist_id": "6h3rSZ8VLK7a5vXjEmhfuD",
    "year": 1976
  },
  {
    "song_id": "2xAacCD0MW3UBFugKVHbJc",
    "name": "Let 'Em In - 1993 Digital Remaster",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/ca6bb46359c812c2390ad07b7810bd5f36660e9d",
    "artist_name": "Wings",
    "image_url": "https://i.scdn.co/image/f6581616b14b2d74e4a1db16d5fa0c8fefa73119",
    "artist_id": "3sFhA6G1N0gG1pszb6kk1m",
    "year": 1976
  },
  {
    "song_id": "19EZOxSTVHJcjuJTpxKZox",
    "name": "Last Child",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/43cd7c509ea9811ba34e9c02cd92e482e851ddf0",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/e38642e10a9e9d9b009aa168a4f02a4c90e3aa86",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1976
  },
  {
    "song_id": "0WeJT7rCshvzK5BOBiAjBn",
    "name": "Country Boy (You Got Your Feet In L.A.)",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/2f9a3cf9fc49656f2f1531177c55f48114fdea7e",
    "artist_name": "Glen Campbell",
    "image_url": "https://i.scdn.co/image/1710079df4e28eef5f991ccbefbabf6330c55841",
    "artist_id": "59hLmB5DrdihCYtNeFeW1U",
    "year": 1976
  },
  {
    "song_id": "08B1GlqMkG7nFXUZpc4C5P",
    "name": "Island Girl",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/287e6ffc9db0167f1ea67f224c7ada63b05fb427",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/7097c8ee80ffbf9751cd0894ec468511975638f0",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1976
  },
  {
    "song_id": "5lSvRiMiLnG5HIpcQH4MAz",
    "name": "Welcome Back [Theme from Welcome Back, Kotter]",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/54f954308c742ba48a1ef76c29cba825624962d0",
    "artist_name": "John Sebastian",
    "image_url": "https://i.scdn.co/image/c40771baa926fb8c9cd52f8d1fa2ae3283ee40ff",
    "artist_id": "3FPF6tyBTP79pCCAJDcPft",
    "year": 1976
  },
  {
    "song_id": "10VbGCpgC4FtmYSwBLvIDX",
    "name": "Theme From Mahogany (Do You Know Where You're Going To)",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/3bdaa4c7773c791a809efdb895a7ac574971726f",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/ddc3f648459c27919fdd9cca82640045ad99b9b2",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1976
  },
  {
    "song_id": "0UHMAa700hzpQVmHlDGE7e",
    "name": "Summer",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/993c97d2c1a012c5635ab556eded5bebac8467df",
    "artist_name": "War",
    "image_url": "https://i.scdn.co/image/7479a127eac185329d7633a8e6509b5db142f101",
    "artist_id": "3ICyfoySNDZqtBVmaBT84I",
    "year": 1976
  },
  {
    "song_id": "0nlZjTPzW6S9JIESuGfImT",
    "name": "Lowdown - Edit",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/c5d3797c1ea6f880b85a8a70cbfa1da30cf650e3",
    "artist_name": "Boz Scaggs",
    "image_url": "https://i.scdn.co/image/bc1d0f2e6e1158cfbaaa192101de19e656e7359e",
    "artist_id": "46njgd2Rq9tZc4ZjeQMgbh",
    "year": 1976
  },
  {
    "song_id": "7cNq0FTl3GYjLc3g232hdA",
    "name": "Moonlight Feels Right",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/62a346192723e2a16c2f7b6af544a23dcca0f777",
    "artist_name": "Starbuck",
    "image_url": "https://i.scdn.co/image/7e8468b3b33f8434813de51e8f49edb56aaf8a13",
    "artist_id": "0P8YAnEwTGZBkm0IudCZxl",
    "year": 1976
  },
  {
    "song_id": "7zqguUyS3LQxAdL8rRgXx2",
    "name": "Sweet Love",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/12772ae5ed62c6801e261259615bdb4f02aec748",
    "artist_name": "Commodores",
    "image_url": "https://i.scdn.co/image/0b642a2140ca77b67b1bfa12a2405896953df6b3",
    "artist_id": "6twIAGnYuIT1pncMAsXnEm",
    "year": 1976
  },
  {
    "song_id": "6G3DBTj1s8fuKMAvzTK5JI",
    "name": "All by Myself - Remastered",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/8c483d1c8f97276dbb20fdaff0de9a611b5eb214",
    "artist_name": "Eric Carmen",
    "image_url": "https://i.scdn.co/image/4f3e995fdc6206633ffb572ec853b3d747f27a09",
    "artist_id": "2ekjTXgjxbWwBX5lTAj4DU",
    "year": 1976
  },
  {
    "song_id": "66wkmlfx3H0Oqg1frvJjhu",
    "name": "Get Up and Boogie - That's Right",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/fb2f2adf620155c6ed7349ba58ef58daed99c5fc",
    "artist_name": "SILVER CONVENTION",
    "image_url": "https://i.scdn.co/image/75d6cc3b6835835ebcdd3bc7ecdeb76352030996",
    "artist_id": "5QHvbEwccF3WANUD5lEIuA",
    "year": 1976
  },
  {
    "song_id": "3oyNzDC9uT8u0yjfBBZa2y",
    "name": "With Your Love",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/7269f561a8884bc8baebbc82da554fedbe1b69cd",
    "artist_name": "Jefferson Starship",
    "image_url": "https://i.scdn.co/image/63635ba17c9cf1fe6c455d978d33d5db0718ceb2",
    "artist_id": "3HC7NcxQx2Yk0fWoRKJ0xF",
    "year": 1976
  },
  {
    "song_id": "3IBQzAK4iqnqjklEWg5eJ6",
    "name": "Tryin' to Get the Feeling Again",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/80bdfe04c56636836bb64e9eea5bbf49e80f7630",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/8184f45937baed4ec717f3558e391e99b0c5df30",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1976
  },
  {
    "song_id": "6elKfVvcYhjGnhBQ6suZfx",
    "name": "Walk Away From Love",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/4de163ae3b09ddf75e1627b621fd88dc21e3d54f",
    "artist_name": "David Ruffin",
    "image_url": "https://i.scdn.co/image/3b8ca0095b6326a62308b4132271553845576bae",
    "artist_id": "3FZn8Z2B7mfaSOU8NE62KR",
    "year": 1976
  },
  {
    "song_id": "5av2sOP0wLIg3rZOhwUMHV",
    "name": "(Shake, Shake, Shake) Shake Your Booty",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/4640d73b15a0e32e85e5080bc09e3e09a7ace7ae",
    "artist_name": "KC & The Sunshine Band",
    "image_url": "https://i.scdn.co/image/50fb28d3c50d434a699369e08930f219468f65a3",
    "artist_id": "3mQBpAOMWYqAZyxtyeo4Lo",
    "year": 1976
  },
  {
    "song_id": "19PKMOoh2Rra8T50wrkq1X",
    "name": "Another Rainy Day In New York City - Remastered",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/d98ca055bed87435665ec35f6e6e80d3640f1621",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/730e9ea3b8bd5b5d2590216346aa95a360ea7f6d",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1976
  },
  {
    "song_id": "6F5cYcpgvBNOG6587ur76V",
    "name": "Still The One (45 Version)",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/3b2f7efa194c74eaf9084ef5f108032cfed83ba7",
    "artist_name": "Orleans",
    "image_url": "https://i.scdn.co/image/9986316df9a598bdd17b27e5e534615ad47963b6",
    "artist_id": "1667U2YoucgG5Pdvm2M59c",
    "year": 1976
  },
  {
    "song_id": "3qLEJWPDvlgJM2eOXGBPbw",
    "name": "Times of Your Life",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/b4800103c0eb7646e8d910d445f836ebd452f6eb",
    "artist_name": "Paul Anka",
    "image_url": "https://i.scdn.co/image/de687ef3e63cd220cc69ed720b3460a7d0e6c798",
    "artist_id": "7ceUfdWq2t5nbatS6ollHh",
    "year": 1976
  },
  {
    "song_id": "0qeeOUZe6W5RJLrhaG79cU",
    "name": "Love To Love You Baby - Single Version",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/6c9c9a8d6085b6f89bd4e25b99ec0dcd12a88c64",
    "artist_name": "Donna Summer",
    "image_url": "https://i.scdn.co/image/af5df6fca4826ae8faf52f3a63a88258298d96fc",
    "artist_id": "2eogQKWWoohI3BSnoG7E2U",
    "year": 1976
  },
  {
    "song_id": "4RQLeiolnEGtcFwHxjtF7H",
    "name": "Rock And Roll All Nite",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/7a20629d88a0bf1d7ac4520989663e9b35437159",
    "artist_name": "KISS",
    "image_url": "https://i.scdn.co/image/c0b894a1ab0d888593557be99e9687117a4e18cb",
    "artist_id": "07XSN3sPlIlB2L2XNcTwJw",
    "year": 1976
  },
  {
    "song_id": "1MBCgbqS8pler2aTQa1qqc",
    "name": "Rock and Roll Music",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/14fc70e0c1310020adbebf899a5832a4b1fc253a",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/18e196791343e0653d0ee4706743b0250595e11e",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1976
  },
  {
    "song_id": "4UN5z3IkTamNxngTsS3Qrm",
    "name": "I'm Easy",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/80b84acb76fc23729c85750699fb7efd8fadab25",
    "artist_name": "Keith Carradine",
    "image_url": "https://i.scdn.co/image/03c30cb95e812fa646ffebe01157c1114f28c74a",
    "artist_id": "7FZCrgUVWK0ho9XoWhv4vY",
    "year": 1976
  },
  {
    "song_id": "3dL9csmqWTevXSy07aZXa5",
    "name": "Shop Around",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/4c93e31c9522577f74c66335b4811a558ccb6d50",
    "artist_name": "Captain & Tennille",
    "image_url": "https://i.scdn.co/image/774089276b234d9f847f8bb72ccf33cdfa2ccb87",
    "artist_id": "7BEfMxbaqx6dOpbtlEqScm",
    "year": 1976
  },
  {
    "song_id": "3hSy2AUoElgIk6fjhYnRH3",
    "name": "Fooled Around And Fell In Love",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/e5c3d5ede7a9655dc7832941527531c9eeae80db",
    "artist_name": "Elvin Bishop",
    "image_url": "https://i.scdn.co/image/965c604f5dd2240fdc9b4b001357a00cac089de0",
    "artist_id": "2G1yVp387GlUf9yvLk6V11",
    "year": 1976
  },
  {
    "song_id": "1kuFlMyndKP47bzilO2IGy",
    "name": "Sophisticated Lady (She's A Different Lady) - Digitally Remastered 02",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/8672b8fc57926e6e163a64b75538d3bcfa28379a",
    "artist_name": "Natalie Cole",
    "image_url": "https://i.scdn.co/image/2bd5d223743567a7b1645a03c8f4483ef18bb23a",
    "artist_id": "5tTsrGPwQRWUsHR2Xf7Ke9",
    "year": 1976
  },
  {
    "song_id": "2GmhltmqCpTdjchwyzgdK7",
    "name": "Saturday Night",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/ef6bcfda2a5fc70d56ea504068ab26c13a2960f6",
    "artist_name": "Bay City Rollers",
    "image_url": "https://i.scdn.co/image/32b1d7ad24b43dabb65abadb43372d3eae2b8f37",
    "artist_id": "3r9TXuXfOUxXjgYgAR0fP8",
    "year": 1976
  },
  {
    "song_id": "4Pj59KfuR7zBxwfAwsrLsa",
    "name": "Today's The Day",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/593d426a4ce1c5c99280fe9bd27971475a0d2acf",
    "artist_name": "America",
    "image_url": "https://i.scdn.co/image/786346448701f7b983ff971753ece822e93844fb",
    "artist_id": "35U9lQaRWSQISxQAB94Meo",
    "year": 1976
  },
  {
    "song_id": "29YcYtqUNLWaYvGVGdYtZy",
    "name": "Devil Woman - 2001 Digital Remaster",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/665d9d712325f5d38eac02c7a67afbfd56c047ce",
    "artist_name": "Cliff Richard",
    "image_url": "https://i.scdn.co/image/bae048d5743a8d509c13c5b4a7ad178539406714",
    "artist_id": "2nvKpWcP8etYTq4JrRiUiy",
    "year": 1976
  },
  {
    "song_id": "40wZA98oWWsxtfgDlnIvOj",
    "name": "Lonely Night (Angel Face)",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/199e73b61f1b5d34013fb7f3f57876e0ca62e972",
    "artist_name": "Captain & Tennille",
    "image_url": "https://i.scdn.co/image/774089276b234d9f847f8bb72ccf33cdfa2ccb87",
    "artist_id": "7BEfMxbaqx6dOpbtlEqScm",
    "year": 1976
  },
  {
    "song_id": "1MQNDLsNRkFSPdJZxGWayD",
    "name": "Theme From S.W.A.T.",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/c3c715ef7bd5bc5fa4d784c794ca3777a603a654",
    "artist_name": "Ray Conniff",
    "image_url": "https://i.scdn.co/image/a57a9903f58bc72730fe3f2b39f51d28ee794a90",
    "artist_id": "7LfRnzGM0wRSyS5mmAwJbv",
    "year": 1976
  },
  {
    "song_id": "0XaKKWRNWX2TDQ8KgqvVfK",
    "name": "Who'd She Coo?",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/9bf6001462e84fc35cba581c400be351c36777a8",
    "artist_name": "Ohio Players",
    "image_url": "https://i.scdn.co/image/cbf5295c4e8e395d28a074e3dfdf7d2b2d29f6a4",
    "artist_id": "6m30rs1IQqnWqV5nKMpU7U",
    "year": 1976
  },
  {
    "song_id": "4xlz9mvlXko4Ccnb4gLjWJ",
    "name": "Shannon",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/3cdf9be25622b854913398341900391659dfa8aa",
    "artist_name": "Henry Gross",
    "image_url": "https://i.scdn.co/image/efec9dd9d295ef3c855542dde7cebac22ba6e21c",
    "artist_id": "3u2E9OhKS2vkxi08itc25a",
    "year": 1976
  },
  {
    "song_id": "5q0CwoceMsA5pOdptSAtdO",
    "name": "Deep Purple",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/f24a55ea7c370beacc22a7d20d264da913f1675e",
    "artist_name": "Donny Osmond",
    "image_url": "https://i.scdn.co/image/e65641290b16bfd4e9c42e907590ff29ed2c5311",
    "artist_id": "5ZEAzHE2TzAwUcOj6jMIgf",
    "year": 1976
  },
  {
    "song_id": "4FLjGxLu4f3M4v9gpCH96t",
    "name": "Something He Can Feel",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/6ae51cdb522d517abefd9bc1fcdeb347d3ba4358",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/191d6c8370917e0430121ba3f663c73dab8fa6dd",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1976
  },
  {
    "song_id": "2C1sBb37TwsHFHDEEP45LI",
    "name": "Let Her In",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/ab47ce10ca4dd73e516c8b672240640bd1ed7cd9",
    "artist_name": "John Travolta",
    "image_url": "https://i.scdn.co/image/6754276add738b16b1cf87e5c18ee2d2cc5654c3",
    "artist_id": "4hKkEHkaqCsyxNxXEsszVH",
    "year": 1976
  },
  {
    "song_id": "5fPgZkaDR5VfkObCsQfbLv",
    "name": "Money Honey",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/f7c22a640b1404bdf25b92bb40f3b5ecf186440b",
    "artist_name": "Bay City Rollers",
    "image_url": "https://i.scdn.co/image/9b4b32bd260c1f1f5b2e2c99b2f1cb470f93449d",
    "artist_id": "3r9TXuXfOUxXjgYgAR0fP8",
    "year": 1976
  },
  {
    "song_id": "7pr4PuJDoPWSBXf5pzLbpZ",
    "name": "Breaking Up Is Hard To Do - Remastered 1992",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/ab34de2fcffb090c34c8a11105581eaba4f47d25",
    "artist_name": "Neil Sedaka",
    "image_url": "https://i.scdn.co/image/58302dbd4beb5771621f95c901dea0f86f9aa1ad",
    "artist_id": "5N6GwJzOcOY5kv8p0NjhYL",
    "year": 1976
  },
  {
    "song_id": "5De7TZnmc7nQq1uAty5kjX",
    "name": "Wham Bam Shang-A-Lang",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/8a1cc79c4cba1cb6be8ec3ec0ac31ba2e5362a31",
    "artist_name": "Sliver",
    "image_url": "https://i.scdn.co/image/0eb2f85839db591f9206e5cc9784f6895a121ead",
    "artist_id": "3RnoG47YVoKOnwuIdX3YON",
    "year": 1976
  },
  {
    "song_id": "0IhZsyMnAzbIhAUfp32q2F",
    "name": "Young Hearts Run Free",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/b3a2a1a1c81377a5a841f324684cc82277383bc9",
    "artist_name": "Candi Staton",
    "image_url": "https://i.scdn.co/image/a14898e623e418cb32fbe07eecc1d6d49f19b28a",
    "artist_id": "3S34Unhn5yRcaH5K9aU5Et",
    "year": 1976
  },
  {
    "song_id": "07ndbAmjsrfqXTWwmgfv1e",
    "name": "Take It To The Limit (Cover Version)",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/58dd18781e349f45b61277e089f76f8211af03f9",
    "artist_name": "Various Artists - The Eagles Tribute",
    "image_url": "https://i.scdn.co/image/31f340dbadf2fcbfbd6403b2304d07b581e52e33",
    "artist_id": "4vfIoid3n0ixeR3OJb8ZO3",
    "year": 1976
  },
  {
    "song_id": "4HPCTPIFQX85mFmQF1WyP3",
    "name": "Disco Duck",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/0bc3d9c33819763a3d3b7194e52d506b1f52e20b",
    "artist_name": "The Academy Allstars",
    "image_url": "https://i.scdn.co/image/96c7e7122edb68da62200a877593f1c5435a6ca5",
    "artist_id": "0xcGrO7veUe0S5LnG4XFK0",
    "year": 1976
  },
  {
    "song_id": "2SUpC3UgKwLVOS2FtZif9N",
    "name": "Carry on Wayward Son",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/48d18a1bc76956a3f1f9bcf97d82960b8cb6eccd",
    "artist_name": "Kansas",
    "image_url": "https://i.scdn.co/image/dc29d5bf989e5a9620e45d2258f34912d8b4046a",
    "artist_id": "2hl0xAkS2AIRAu23TVMBG1",
    "year": 1977
  },
  {
    "song_id": "0FeCO85RKW8fDRytwXof2x",
    "name": "Go Your Own Way",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/78182d5d13c9613f76ceecee325a6b56482e0666",
    "artist_name": "Fleetwood Mac",
    "image_url": "https://i.scdn.co/image/31ee3e4265ba3c5098a8928c05d001a3b3033710",
    "artist_id": "08GQAI4eElDnROBrJRGE0X",
    "year": 1977
  },
  {
    "song_id": "2vX5WL7s6UdeQyweZEx7PP",
    "name": "Walk This Way",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/a204922df7eee193f875be153b22a5eaf4908ea7",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/a4654d51b72f5cb08ec7f18904d45c90022d3dc1",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1977
  },
  {
    "song_id": "5E3P9e1hvjyIOlFyqNB5eD",
    "name": "Rich Girl - Remastered 2003",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/f3c7fc29dc44598d350c822466683a5fd89aa153",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/82216e16eff844df7916f5024dd7041403b6c82a",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1977
  },
  {
    "song_id": "703BT1NQsfIwPFv8MXQ47m",
    "name": "Dreams",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/5aa88c35604411c77611b433dfc87c53076ffa9f",
    "artist_name": "Fleetwood Mac",
    "image_url": "https://i.scdn.co/image/31ee3e4265ba3c5098a8928c05d001a3b3033710",
    "artist_id": "08GQAI4eElDnROBrJRGE0X",
    "year": 1977
  },
  {
    "song_id": "4hszow3YBRAbGGA0WbbR6r",
    "name": "Gonna Fly Now (Theme From \"Rocky\") - 2006 - Remaster",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/419923f10aa8050f4e8ad219d883094c3a0e4a7d",
    "artist_name": "Bill Conti",
    "image_url": "https://i.scdn.co/image/cd997efbae6a1bf3016f91bdd9ada0a035455304",
    "artist_id": "272ygo5fv9cYsiCLcV0HV2",
    "year": 1977
  },
  {
    "song_id": "26BfLsMYnAQPh6gPDywYne",
    "name": "Somebody To Love",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/61387739568c6c77641285ab407886b200f96f69",
    "artist_name": "Queen",
    "image_url": "https://i.scdn.co/image/5e2d4c9fce0dfa3689bd0a20754de696c69a4658",
    "artist_id": "1dfeR4HaWDbWqFHLkxsg1d",
    "year": 1977
  },
  {
    "song_id": "6UjlpJhUqOQqv0hrb38IDI",
    "name": "Best of My Love",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/1a8202cd2ad5d89614b4c1b6ffe744dce5c0c726",
    "artist_name": "The Emotions",
    "image_url": "https://i.scdn.co/image/a7f7e324bb27342527eb5ec32c00109c691829bb",
    "artist_id": "64CuUOOirKmdAYLQSfaOyr",
    "year": 1977
  },
  {
    "song_id": "4NtUY5IGzHCaqfZemmAu56",
    "name": "Dancing Queen",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/8e68458fe7b76db4884e5bb680c0945005aab523",
    "artist_name": "ABBA",
    "image_url": "https://i.scdn.co/image/50c3071b308c78365b99cdfe8a931ab3057a1b06",
    "artist_id": "0LcJLqbBmaGUft1e9Mm8HV",
    "year": 1977
  },
  {
    "song_id": "6WSghLwg22eEsAAN6ffOKk",
    "name": "Margaritaville",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/df2cc7e210cfa74116c6a8399b16caa4af255cb2",
    "artist_name": "Jimmy Buffett",
    "image_url": "https://i.scdn.co/image/6e64daa02dc6448c0dcc40606533210f30499076",
    "artist_id": "28AyklUmMECPwdfo8NEsV0",
    "year": 1977
  },
  {
    "song_id": "4SXU3lUZ1eb4JWWbkQw43Y",
    "name": "Don't Stop",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/ef0dcb5c2aff31dc1f881563339016fe490d937d",
    "artist_name": "Fleetwood Mac",
    "image_url": "https://i.scdn.co/image/31ee3e4265ba3c5098a8928c05d001a3b3033710",
    "artist_id": "08GQAI4eElDnROBrJRGE0X",
    "year": 1977
  },
  {
    "song_id": "1DmdXg5mmFOvlRvHYPjRU8",
    "name": "Strawberry Letter 23",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/fb6ed04c6442fe09d7cc2450c323da0983efa19f",
    "artist_name": "The Brothers Johnson",
    "image_url": "https://i.scdn.co/image/0b29f92da2c27b3a8066166018b96f7c9bc0ec3e",
    "artist_id": "6h3rSZ8VLK7a5vXjEmhfuD",
    "year": 1977
  },
  {
    "song_id": "71gU4Kd5eQXcg5npdYheJE",
    "name": "Jet Airliner - 2003 Digital Remaster",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/a6c03d6cb366d5f68720d0c6e031e95d53b5b47d",
    "artist_name": "Steve Miller Band",
    "image_url": "https://i.scdn.co/image/c418eedd084ff09f20787b554945bad4b09ee138",
    "artist_id": "6QtGlUje9TIkLrgPZrESuk",
    "year": 1977
  },
  {
    "song_id": "20zOIoex7YchUh87d8tlvg",
    "name": "Boogie Nights",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/0fdc02b72cbcf1b1d22852ac1dc0879aabe1b16d",
    "artist_name": "Heatwave",
    "image_url": "https://i.scdn.co/image/df12fe74f36e858be1bb312a76fbd75a516523ca",
    "artist_id": "6D7h7R79IZjqJC2GM2wzyY",
    "year": 1977
  },
  {
    "song_id": "6xkLNZ1kF47qvTrhI0Kc0N",
    "name": "Telephone Line",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/15f58e454488bfa17b372f59995d0c1c5adeb617",
    "artist_name": "Electric Light Orchestra",
    "image_url": "https://i.scdn.co/image/29380ca42996ced3c89b48d72c600e0e42fcc45c",
    "artist_id": "7jefIIksOi1EazgRTfW2Pk",
    "year": 1977
  },
  {
    "song_id": "7qdqcC2OurUrqZBwlEFivG",
    "name": "I Just Want To Be Your Everything",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/ce99693b0bf6b486d26daf51cbfe4f4c65371e3b",
    "artist_name": "Andy Gibb",
    "image_url": "https://i.scdn.co/image/fadfe2865b5e6858867b9cb00fff26520a6b9ea1",
    "artist_id": "4YPqbAiLzBg5DIfsgQZ8QK",
    "year": 1977
  },
  {
    "song_id": "6gQRKtSiZ8d3zgy9Hn10py",
    "name": "Give A Little Bit",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/a71e48e69cf5598de7a444e89feb1124eab569f8",
    "artist_name": "Supertramp",
    "image_url": "https://i.scdn.co/image/8cf4e72bae5242a03b5d834580fc56a081863324",
    "artist_id": "3JsMj0DEzyWc0VDlHuy9Bx",
    "year": 1977
  },
  {
    "song_id": "76DaYoN2BXdu1dZFdw61qj",
    "name": "Don't Leave Me This Way - Single Version",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/da61963fcda118f956f3ff6a725656042d695164",
    "artist_name": "Thelma Houston",
    "image_url": "https://i.scdn.co/image/9ff33aaa410eabf6bd7b95f0c5ca59dc50bcbdbb",
    "artist_id": "3sgUnR8TF35euWEV07RPyO",
    "year": 1977
  },
  {
    "song_id": "7MTX3vevnm41xuEoPxWT3j",
    "name": "Year Of The Cat",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/28cb8f0ea7a7e792afd77200521f376b9379dd0c",
    "artist_name": "Al Stewart",
    "image_url": "https://i.scdn.co/image/cab1285e4294a5e156949b8dc3ec7e8fa742f9a0",
    "artist_id": "0DW7boyjvbaSP3OJ72sXgC",
    "year": 1977
  },
  {
    "song_id": "78DSnVdZiuakQPwV0ler7s",
    "name": "Lost Without Your Love",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/bcdda6b06b647aea9b261cd690211115b4f0f0c7",
    "artist_name": "Bread",
    "image_url": "https://i.scdn.co/image/cc7876ada08faac728001d62f158721106776624",
    "artist_id": "70ZTdbPEcEugBNay4MvxfL",
    "year": 1977
  },
  {
    "song_id": "27BiHcQDEkxVn69zy1t3Es",
    "name": "Barracuda",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/59280c38879d9265c70c1ede0227eb533997a202",
    "artist_name": "Heart",
    "image_url": "https://i.scdn.co/image/abf78e3985508d0a4fd42bd8a1443f1a97fec4b7",
    "artist_id": "34jw2BbxjoYalTp8cJFCPv",
    "year": 1977
  },
  {
    "song_id": "52YNr0Hzvx9SbKjzbq49XL",
    "name": "Tonight's The Night [Gonna Be Alright] (Remastered Album Version)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/09f58b9ff9e7bfb1cb04d5b8be0b2142bbc5e1c3",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/79fa6c9588f7bec80db5db5d06bae424a118e57b",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1977
  },
  {
    "song_id": "0DuzZxyfZ9aO5tGsETVa9o",
    "name": "Lonely Boy",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/7817937a96d3270acdb0d4b27dcc46ba0e6b0c1b",
    "artist_name": "Andrew Gold",
    "image_url": "https://i.scdn.co/image/94a050ca32ccfca4dc7fdc5714f642e37b4b2a7f",
    "artist_id": "5fmvGUlMVgmnCn45f1he7d",
    "year": 1977
  },
  {
    "song_id": "23IWhAK0cYRwnBT5EE8i3u",
    "name": "Don't give up on us",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/40d6b519e48b2b1988efa95856f2615d52e917ef",
    "artist_name": "David Soul",
    "image_url": "https://i.scdn.co/image/34595170861b6d98c82bf82eed6c1777d7ab66d3",
    "artist_id": "2eFkm34OMSYRUwP4RAtXaT",
    "year": 1977
  },
  {
    "song_id": "4c68Z9wLdHc36y3CNjwQKM",
    "name": "Southern Nights",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/5af60ed67bd6ab2eecae3d0a1d13a6e2e6e4f6d7",
    "artist_name": "Glen Campbell",
    "image_url": "https://i.scdn.co/image/1710079df4e28eef5f991ccbefbabf6330c55841",
    "artist_id": "59hLmB5DrdihCYtNeFeW1U",
    "year": 1977
  },
  {
    "song_id": "1whGY9xE7fhSZOPL2qZTQP",
    "name": "Sir Duke",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/ca52d832a590f1d07032a08d3f88416bce826f1e",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/fa0ddd446bb2ec0dfdbfc992c8d96340242df7f3",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1977
  },
  {
    "song_id": "46ou4l4zvrZMada0TgoVH9",
    "name": "Knowing Me, Knowing You",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/3583c3c3ff009749be1a9634c788ea3e4cbb4ea6",
    "artist_name": "ABBA",
    "image_url": "https://i.scdn.co/image/50c3071b308c78365b99cdfe8a931ab3057a1b06",
    "artist_id": "0LcJLqbBmaGUft1e9Mm8HV",
    "year": 1977
  },
  {
    "song_id": "1wHJ3n8ERZljmfGXc1PRbe",
    "name": "Just A Song Before I Go",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/c171677751f190bb28e5342e61a3f9fbde28a4fe",
    "artist_name": "Crosby, Stills & Nash",
    "image_url": "https://i.scdn.co/image/f896c942a7ef026cd740017946b0192de02d52a7",
    "artist_id": "2pdvghEHZJtgSXZ7cvNLou",
    "year": 1977
  },
  {
    "song_id": "7nfiM8DuaSJ61KaerHchcA",
    "name": "Blinded By The Light",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/ba5d951cdaa7609016f136774120601eb5d076a1",
    "artist_name": "Manfred Mann's Earth Band",
    "image_url": "https://i.scdn.co/image/8c21dea82b0d12c9d6275224b2db78481ebf0c3b",
    "artist_id": "2utNxkLhreF1oIfO8kQT3q",
    "year": 1977
  },
  {
    "song_id": "0HpxfAve6g1HdabSVMCZpZ",
    "name": "I Wanna Get Next To You",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/eb6c0fa8533213a8ef06191c4ae1d89ea0128a2c",
    "artist_name": "Rose Royce",
    "image_url": "https://i.scdn.co/image/b67beb24647a36bd2de5a6542a218350ac7f9a92",
    "artist_id": "1OxJzMLmR9l5zPLap9OxuO",
    "year": 1977
  },
  {
    "song_id": "6mPXHCKVEWZNXjfQewzb9M",
    "name": "Torn Between Two Lovers",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/5a1aa7e812ca07440de02a758a1bf0069a3d5092",
    "artist_name": "Mary MacGregor",
    "image_url": "https://i.scdn.co/image/2a927d2869d1b7faa9df1c84ab1f628fb826f253",
    "artist_id": "6GlDsAkQHOmNgtUZdJ4Amq",
    "year": 1977
  },
  {
    "song_id": "5SuEUz1orwSwkfqgFlJUHG",
    "name": "Love Theme From \"A Star Is Born\" (Evergreen)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/b178cdba5ba2d415aad501a694248b915932ffde",
    "artist_name": "Barbra Streisand",
    "image_url": "https://i.scdn.co/image/510b276f469468a39d0bd335516f7a6eec54c062",
    "artist_id": "7jmTilWYlKOuavFfmQAcu6",
    "year": 1977
  },
  {
    "song_id": "3Q9b9Gt7ouG04Pw6iA3rGi",
    "name": "Maybe I'm Amazed - Remastered",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/5857e244eb2a3a8b89ff6eff43065f5cb8dcc0ae",
    "artist_name": "Paul McCartney",
    "image_url": "https://i.scdn.co/image/522621b3d0394459a37c4bdd84061b75ea08cc92",
    "artist_id": "4STHEaNw4mPZ2tzheohgXB",
    "year": 1977
  },
  {
    "song_id": "2oNhvMFZaXPKP6Yjyg8gD7",
    "name": "The Rubberband Man - Single Version",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/67889f031b13fe728659f6bae4e30e404cec3e28",
    "artist_name": "The Spinners",
    "image_url": "https://i.scdn.co/image/5b73276c35372f441aeaef14c780afce485c006d",
    "artist_id": "5fbhwqYYh4YwUoEs582mq5",
    "year": 1977
  },
  {
    "song_id": "4wMDFXgdJaN1N5ewT5EMew",
    "name": "Night Moves - Bob Seger and the Silver Bullet Band Tribute",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/0b0743939742d6d85cdc225e238b17444a955ed7",
    "artist_name": "Sam Morrison and Turn The Page",
    "image_url": "https://i.scdn.co/image/1f07a602b6a9fbed23928129bad81561721edbb3",
    "artist_id": "7csps3rrXHbv6Xnom2Ntjl",
    "year": 1977
  },
  {
    "song_id": "07VYJECu4ZAWmkplVfEOA1",
    "name": "Love So Right (Remastered Album Version)",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/21a8f8cfd03631cdea3e2db0213708fd8183c357",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/9ba9a3a360c2f681818fa251fcc4a5800c01b1e3",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1977
  },
  {
    "song_id": "2FtLky5sftRTZuX2IXsD8L",
    "name": "Looks Like We Made It",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/5eec8c156f27989fb196f36677f039466e9333d0",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/8184f45937baed4ec717f3558e391e99b0c5df30",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1977
  },
  {
    "song_id": "65e06mBpNFN6HAetXnsRYh",
    "name": "Easy",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/ba86a120e36cdd6a272bf4a90d8f72d505ab754d",
    "artist_name": "Commodores",
    "image_url": "https://i.scdn.co/image/df255ff1dc3dce7c3fe502d91ecd3387631d32a0",
    "artist_id": "6twIAGnYuIT1pncMAsXnEm",
    "year": 1977
  },
  {
    "song_id": "1GBdajRQx0DdruVj8kxksv",
    "name": "Couldn't Get It Right",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/b0528e079e8f91e74b0e2d9b529622f8b4d7d5a3",
    "artist_name": "Climax Blues Band",
    "image_url": "https://i.scdn.co/image/9a023cbb503136de8cfd00cb5d1d60df3cf8eb29",
    "artist_id": "4eeRviM714mXXeILmKHxh2",
    "year": 1977
  },
  {
    "song_id": "73xjNwWxziqWI7WIlRQ2CK",
    "name": "Weekend in New England",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/0f346eccff62900703c74e034c08e7bb43f7b3f7",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/8184f45937baed4ec717f3558e391e99b0c5df30",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1977
  },
  {
    "song_id": "5DKVSmrckCdbHOmQTAFTFn",
    "name": "Handy Man",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/8c9c8c83e319a7038d7cb95b286a450da6a91dfc",
    "artist_name": "James Taylor",
    "image_url": "https://i.scdn.co/image/e789ef88ce5156416c5c36553ac53481a609355b",
    "artist_id": "0vn7UBvSQECKJm2817Yf1P",
    "year": 1977
  },
  {
    "song_id": "3DHWxeWr4uh19MklWjLpR0",
    "name": "Feels Like The First Time",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/7dd54cce8b7d7fbe22277ff34d5dcfdbaab6e866",
    "artist_name": "Foreigner",
    "image_url": "https://i.scdn.co/image/ab1ec83044b80145161a30f55b0396864ca0d50a",
    "artist_id": "6IRouO5mvvfcyxtPDKMYFN",
    "year": 1977
  },
  {
    "song_id": "0OOyH1oDhrVxrTNLUg4MZJ",
    "name": "It Was Almost Like a Song",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/db168a11c993dee664e157741443aca829cab3a0",
    "artist_name": "Ronnie Milsap",
    "image_url": "https://i.scdn.co/image/4ac5100e4c4f62217c16447738c5eabf148e753a",
    "artist_id": "6Sb8EHpZvyjSTT5KD9DjUZ",
    "year": 1977
  },
  {
    "song_id": "5mpzCj9l1pYsL4XOXiea84",
    "name": "So Into You",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/602edfcd9d3a7d599dd03bf2ca52d0687638d59d",
    "artist_name": "Atlanta Rhythm Section",
    "image_url": "https://i.scdn.co/image/646f9c5cf43896f2730800bf956aef5e403e2796",
    "artist_id": "0WgyCbru4tXnMsbTmX4mFw",
    "year": 1977
  },
  {
    "song_id": "5gINgWhf5cFkAVNTsbXhwd",
    "name": "Car Wash - 7\" Mix",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/dd58cc0c8e0956bd2d79562803260451ad97247d",
    "artist_name": "Rose Royce",
    "image_url": "https://i.scdn.co/image/01344a962110f21ce4f03c832f06ef8c25b6ef84",
    "artist_id": "1OxJzMLmR9l5zPLap9OxuO",
    "year": 1977
  },
  {
    "song_id": "0XeAf0mpuoe7KZpX9ianqu",
    "name": "I Wish",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/99f4917050e312d06c740dfd093eedfb2af68556",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/fa0ddd446bb2ec0dfdbfc992c8d96340242df7f3",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1977
  },
  {
    "song_id": "42lAZKaWTWOC2pJe7eQDGw",
    "name": "Lucille",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/329d5bd2399b56f6e52aef39f3d82b8889751961",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/1a6de2c9d0b4b4294439d5ea72fb3b72bfa18085",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1977
  },
  {
    "song_id": "24v2K0hKG9EJVJdTmAh1Dz",
    "name": "Dazz",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/b6d80179fb5891c769a470079905cb722980e8a7",
    "artist_name": "Brick",
    "image_url": "https://i.scdn.co/image/8c8536c946ab7075297801116e313051ff77a82b",
    "artist_id": "4Uv0Jg45Oq7vBXXwQNhXyf",
    "year": 1977
  },
  {
    "song_id": "7dwokc7VMOCFJDUohG8DlF",
    "name": "Sorry Seems To Be The Hardest Word",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/88ab26d1710ea1c89e4cb2f6d63c36fae71b7ac6",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/7097c8ee80ffbf9751cd0894ec468511975638f0",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1977
  },
  {
    "song_id": "5endSWellqnUqEIO2OOdzj",
    "name": "Cherchez la Femme / Se Si Bon",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/cee0b4d0798ddfb820d17a85ad412e0d8f0341e8",
    "artist_name": "Dr. Buzzard's Original Savannah Band",
    "image_url": "https://i.scdn.co/image/b15a028338c74a961c0ea2a7987bd8f648241183",
    "artist_id": "0i64fKwsnTQsPqbov8mZUh",
    "year": 1977
  },
  {
    "song_id": "40wPRNaQJiroHMqRjZFajq",
    "name": "Livin' Thing",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/fb2e2c0add1d98141428bf83dd6dbb8d6ed00284",
    "artist_name": "Electric Light Orchestra",
    "image_url": "https://i.scdn.co/image/36a24436fc4e606385fd27de9fc9e3efadc7e453",
    "artist_id": "7jefIIksOi1EazgRTfW2Pk",
    "year": 1977
  },
  {
    "song_id": "7vidktgNZFQylTgH1GEnMs",
    "name": "Cold As Ice",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/631ee4709b43d0c2f2592e03307bbbc557026a3d",
    "artist_name": "Foreigner",
    "image_url": "https://i.scdn.co/image/ab1ec83044b80145161a30f55b0396864ca0d50a",
    "artist_id": "6IRouO5mvvfcyxtPDKMYFN",
    "year": 1977
  },
  {
    "song_id": "3b930XMJSGl2qFh8t0HLeC",
    "name": "On And On",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/258d1d61073d34807340123d9f7937d60c44941a",
    "artist_name": "Stephen Bishop",
    "image_url": "https://i.scdn.co/image/5dcf3f9811ab31a2588fe9d67917da73499ac492",
    "artist_id": "3JhRHqCi8HEzkJVWt19LtN",
    "year": 1977
  },
  {
    "song_id": "6iT7rq9gVWcH0oNoOrCBAE",
    "name": "Lido Shuffle",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/300f895f38ea7060bd271b08c662a3c4ef6fa9f2",
    "artist_name": "Boz Scaggs",
    "image_url": "https://i.scdn.co/image/6f7ffdfb999439b7ee1a2568644bc05021cb7fff",
    "artist_id": "46njgd2Rq9tZc4ZjeQMgbh",
    "year": 1977
  },
  {
    "song_id": "4IS8fT7zleM6Diha46btSd",
    "name": "I've Got Love On My Mind - 2003 Digital Remaster",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/592d93624cbe6e50a3b4fbc524de5a65214d3149",
    "artist_name": "Natalie Cole",
    "image_url": "https://i.scdn.co/image/2bd5d223743567a7b1645a03c8f4483ef18bb23a",
    "artist_id": "5tTsrGPwQRWUsHR2Xf7Ke9",
    "year": 1977
  },
  {
    "song_id": "6nSx1nxPHSELFjAlNHHWSe",
    "name": "You're My World",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/9ffbb70f58a2f3f4e5cbad907ba5e91f2569455c",
    "artist_name": "Helen Reddy",
    "image_url": "https://i.scdn.co/image/ba5219764143f4935e6e6085d6d2d6050f826d64",
    "artist_id": "0Sq7oGrYEe0BDmb13wgjOO",
    "year": 1977
  },
  {
    "song_id": "2HIqNbnpN41Xws8Tbm5ied",
    "name": "Main Title from Star Wars",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/2567b2a39486caa3d21be513e672237b65d2a59e",
    "artist_name": "John Williams",
    "image_url": "https://i.scdn.co/image/dde12dafd4db7ed291d4e860caba72728b699337",
    "artist_id": "3dRfiJ2650SZu6GbydcHNb",
    "year": 1977
  },
  {
    "song_id": "4YmKTCL86mpGHUS0sEWAUV",
    "name": "Float On",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/3a1b5a00684c840859115bad856f5f7ff608897c",
    "artist_name": "The Floaters",
    "image_url": "https://i.scdn.co/image/d5127f433b590c5f330b7d0b71f9899632052751",
    "artist_id": "6GpuL6WNTOVhmMl6lZbiKS",
    "year": 1977
  },
  {
    "song_id": "14IKLtPlsqWL077svIJYey",
    "name": "Stand Tall",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/d3dda0566d1bd733c54705123bc4f1045af3bd0d",
    "artist_name": "Burton Cummings",
    "image_url": "https://i.scdn.co/image/2b0d0418c2507cf7ec631db1d1ba60eb688b4451",
    "artist_id": "5ziVRv2caoBDNG2fythJ2n",
    "year": 1977
  },
  {
    "song_id": "2JurHS80N98Yt3CdIBYz9M",
    "name": "You Don't Have to Be a Star (To Be In My Show)",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/25fab936c1e06d00db171a9fb65db21a45b73aa5",
    "artist_name": "Marilyn McCoo",
    "image_url": "https://i.scdn.co/image/db0528a53544523db8df864d409bf9c51f84281a",
    "artist_id": "2jirMwr4cHJzjtx8vDX5IO",
    "year": 1977
  },
  {
    "song_id": "1U04tEqBgmBCZVil3gJ6S7",
    "name": "Muskrat Love",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/a178280541b579385ba691f59119ae459e592207",
    "artist_name": "Captain & Tennille",
    "image_url": "https://i.scdn.co/image/9f6e031a05800027c6e1de0e11980655bb2300d3",
    "artist_id": "7BEfMxbaqx6dOpbtlEqScm",
    "year": 1977
  },
  {
    "song_id": "03zB9duFz1JqLxAIYvGdac",
    "name": "I'm In You",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/2e39d4ceb78f194771e80d696741c55c6e57b725",
    "artist_name": "Peter Frampton",
    "image_url": "https://i.scdn.co/image/4e10458bbb2865d0d6671e90b6fb35b4e7ab63bd",
    "artist_id": "0543y7yrvny4KymoaneT4W",
    "year": 1977
  },
  {
    "song_id": "51DAF8ruk6NwGb1we5QQfF",
    "name": "Right Time Of The Night",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/91373df309610fa1ce4f925157c82aef7e46efbd",
    "artist_name": "Jennifer Warnes",
    "image_url": "https://i.scdn.co/image/49e2ac56dc776bb9265fb644bb294ce3a95bea9c",
    "artist_id": "1BwHztAQKypBuy5WBEdJnG",
    "year": 1977
  },
  {
    "song_id": "1LXXuQ02OuquA8COA3Wyp7",
    "name": "Got To Give It Up (Part 1)",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/467a223930d462f5268a39a573bcae40c07a6f16",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1977
  },
  {
    "song_id": "1yQQUdV4aqcwQzet3MU63N",
    "name": "Love's Grown Deep",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/0cee6e8bbdeba3c6b1126e648421252ac13e8e55",
    "artist_name": "Kenny Nolan",
    "image_url": "https://i.scdn.co/image/d274d11dd9c2a210619a6b05c20c8d8e87e33d99",
    "artist_id": "2SdZQXwwMAWw3INoSmOzur",
    "year": 1977
  },
  {
    "song_id": "6gTrbqZnyljwXdYQTyBkSx",
    "name": "You Make Me Feel Like Dancing - Remastered Single/",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/7f855f1788fd09190f97aa0a69f7ef7ebbf9cd8e",
    "artist_name": "Leo Sayer",
    "image_url": "https://i.scdn.co/image/e186a79a2e4f73a0f58dfb0c1799e136b9927ddf",
    "artist_id": "04LIHk1SobiQwt2tlupoAV",
    "year": 1977
  },
  {
    "song_id": "7niF1luhYmc28ivQbFm3f0",
    "name": "Star Wars Theme/Cantina Band",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/c47d9184d1139f15b576dde459ce4092a6b3f9cf",
    "artist_name": "Meco",
    "image_url": "https://i.scdn.co/image/8c1e4540d3d9abba8298100071d1c993e7843a8d",
    "artist_id": "4MptlUxSUHf2QAW9JMrv4W",
    "year": 1977
  },
  {
    "song_id": "3kGQzevJz31aZAxRBzWV3P",
    "name": "Hotel California",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/7ae90a6a9a6cc2737d49ac3996fc6742a9360dee",
    "artist_name": "The Eagles Tribute Band",
    "image_url": "https://i.scdn.co/image/22a643eeb14566abf06ecac6048a2b9b00f5c707",
    "artist_id": "5l7A2c0HYfo9VrFmIhXt5g",
    "year": 1977
  },
  {
    "song_id": "2j78lPDRu9HcN3X817CmR2",
    "name": "Ariel",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/bab585e70e819d144f314feb07a1dea9e06e71f1",
    "artist_name": "Dean Friedman",
    "image_url": "https://i.scdn.co/image/13b9ce3110811223c56121dcf05525cf4c62e851",
    "artist_id": "4IT7iUyEDGX4pJDo6CfFRB",
    "year": 1977
  },
  {
    "song_id": "0y0sOfAcgfT4XvBazsnaR6",
    "name": "Keep It Comin Love",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/2cab587d9e26525e26e483d69931a1e8f309f1b1",
    "artist_name": "KC & The Sunshine Band",
    "image_url": "https://i.scdn.co/image/d15b6fdfd568febbf48b35965c1083bdbd83d302",
    "artist_id": "3mQBpAOMWYqAZyxtyeo4Lo",
    "year": 1977
  },
  {
    "song_id": "6EygVOcPHtorludKheO5O6",
    "name": "The Things We Do For Love",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/d97a6a6c4ed58ca862f8063c20ec59e03a6b67d6",
    "artist_name": "10cc",
    "image_url": "https://i.scdn.co/image/203a955a78e53bcbcb2960f8243bbd88d5fa161e",
    "artist_id": "6i6WlGzQtXtz7GcC5H5st5",
    "year": 1977
  },
  {
    "song_id": "6SrvyfmAxkLt9CULhf9a49",
    "name": "Hot Line",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/508eabfa5590813522ef8f0f5d806c588de6bd9e",
    "artist_name": "The Sylvers",
    "image_url": "https://i.scdn.co/image/dabd7fe305bac09f9ed5f30f47dce6c1e8c88761",
    "artist_id": "0efkWb9xKhT6H0E7kgSjFK",
    "year": 1977
  },
  {
    "song_id": "5y86QUB5fHQ1XVpkanUsmp",
    "name": "After the Lovin'",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/3b376d00357e75c62778fb78f4426598d11dbf1c",
    "artist_name": "Engelbert Humperdinck",
    "image_url": "https://i.scdn.co/image/c82060b4cdace120dffe3852d3bdc93581198183",
    "artist_id": "17XXKfRBMCWvLrqGoNkJXm",
    "year": 1977
  },
  {
    "song_id": "3HAMzE7Yfv8HaAnGVJvtNl",
    "name": "Jeans On",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/bef98f81219ad042b167dcad12debcccc3e97690",
    "artist_name": "Count Dee's Hit Explosion",
    "image_url": "https://i.scdn.co/image/07a8685a0388abfc0f6040c5f0379c217a911627",
    "artist_id": "6eBZxZkmQbk6pGFnaQSCyp",
    "year": 1977
  },
  {
    "song_id": "7u2FvRYl39hmNHklBlsjkm",
    "name": "My Heart Belongs To My Me",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/d59424464cb928ebfd67f91222701a1a76f9c1f5",
    "artist_name": "Barbra Streisand",
    "image_url": "https://i.scdn.co/image/b57b43437add46e05ae8665565509720c9c237a4",
    "artist_id": "7jmTilWYlKOuavFfmQAcu6",
    "year": 1977
  },
  {
    "song_id": "3iaVSKy8MXtjWy3XMIpxQM",
    "name": "I'm Your Boogie Man",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/089425df247d5694b2e15f5990af7c0be5b6d0d0",
    "artist_name": "KC & The Sunshine Band",
    "image_url": "https://i.scdn.co/image/d15b6fdfd568febbf48b35965c1083bdbd83d302",
    "artist_id": "3mQBpAOMWYqAZyxtyeo4Lo",
    "year": 1977
  },
  {
    "song_id": "6SMR0XI8a1D6gEpMTIju2y",
    "name": "Whatcha Gonna Do?",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/c3cbeccff9b010b50d06399e9735b24bbdbcfe03",
    "artist_name": "Pablo Cruise",
    "image_url": "https://i.scdn.co/image/5520ffbdce5a6201245220d528607a213e82cf49",
    "artist_id": "3Y5abCLsMxsOLaZ8rYusSR",
    "year": 1977
  },
  {
    "song_id": "0srsE6fsPj53UxuAK0LH6a",
    "name": "(Your Love Has Lifted Me) Higher And Higher",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/ffad84db39b827ea7de336df31cce22119b3f1e2",
    "artist_name": "Rita Coolidge",
    "image_url": "https://i.scdn.co/image/9d1b0816f50c153d7f85b7da11dc11dca68d2c3d",
    "artist_id": "1vnIL4DMlivP55ioM6KitW",
    "year": 1977
  },
  {
    "song_id": "2TcwEYyydQuEMJwdmSgVLD",
    "name": "When I Need You - Remastered",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/5cc387514f76cdc0feeff09875777ac19aec8f53",
    "artist_name": "Leo Sayer",
    "image_url": "https://i.scdn.co/image/e186a79a2e4f73a0f58dfb0c1799e136b9927ddf",
    "artist_id": "04LIHk1SobiQwt2tlupoAV",
    "year": 1977
  },
  {
    "song_id": "1TsekOo0xMKvIgKK7U6zaQ",
    "name": "How Much Love - Remastered",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/b882c7bf2f951584c9b0a3ad4a432df78ee29b81",
    "artist_name": "Leo Sayer",
    "image_url": "https://i.scdn.co/image/e186a79a2e4f73a0f58dfb0c1799e136b9927ddf",
    "artist_id": "04LIHk1SobiQwt2tlupoAV",
    "year": 1977
  },
  {
    "song_id": "0fuE1Q4q1efyBOlp74s04w",
    "name": "High School Dance",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/880bed71b8ee5de51c6d3b8406eb0d7561ad5161",
    "artist_name": "The Sylvers",
    "image_url": "https://i.scdn.co/image/6dc591ad3a42818fa340d8185e615450983f2f60",
    "artist_id": "0efkWb9xKhT6H0E7kgSjFK",
    "year": 1977
  },
  {
    "song_id": "0pduyDEr4r3KCXHEtxVhZ3",
    "name": "I Like Dreamin'",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/35f3f53c12d7b70eac6d41316c2441fb47a62ea9",
    "artist_name": "Kink Harris",
    "image_url": "https://i.scdn.co/image/d274d11dd9c2a210619a6b05c20c8d8e87e33d99",
    "artist_id": "4cvVrEqfOHOKieNU3OJl07",
    "year": 1977
  },
  {
    "song_id": "6RBK4wOuI4nw3pYT3MSTjm",
    "name": "Dancin' Man",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/8eff18145cfa853eba1007e8beacd653076fa97f",
    "artist_name": "Q",
    "image_url": "https://i.scdn.co/image/342bfc621621decbc63009f3bec4d0886c33a1df",
    "artist_id": "0XhXKcC9UFJLTZeAty7VX3",
    "year": 1977
  },
  {
    "song_id": "70g7vKEnaSOkyWQbA7VFhG",
    "name": "You Made Me Believe in Magic",
    "popularity": 7,
    "preview_url": "https://p.scdn.co/mp3-preview/0f81dc5e4a7a4e7a6be113bafccc574cf7884899",
    "artist_name": "Bay City Rollers",
    "image_url": "https://i.scdn.co/image/0cada7ed2ead013bc13bd53a8e449ee8dd1d0ee1",
    "artist_id": "3r9TXuXfOUxXjgYgAR0fP8",
    "year": 1977
  },
  {
    "song_id": "436vyFSvFZoj60lK9ANH09",
    "name": "Smoke From A Distant Fire (Karaoke Lead Vocal Demo)[In the style of Sanford Townsend Band]",
    "popularity": 7,
    "preview_url": "https://p.scdn.co/mp3-preview/500cfcf389e5d2cc5050d4f073ec65ca06160001",
    "artist_name": "ProSound Karaoke Band",
    "image_url": "https://i.scdn.co/image/069b579a81a9bf59a9bc9ca24c34e902db3aca0a",
    "artist_id": "0FQnTKF71zmqOzMB1ulput",
    "year": 1977
  },
  {
    "song_id": "7wbltr7eTAZOWglM7qCy6x",
    "name": "Angel (in Your Arms)",
    "popularity": 3,
    "preview_url": "https://p.scdn.co/mp3-preview/d200fe002acc67e9436c685f425b6be3f9663ad4",
    "artist_name": "Red Hurley",
    "image_url": "https://i.scdn.co/image/7a23fa1aab2921c366c347112be4ac81e535b0ff",
    "artist_id": "4C0yuzB3XYhL1SuoOUHB27",
    "year": 1977
  },
  {
    "song_id": "3LmpQiFNgFCnvAnhhvKUyI",
    "name": "Stayin' Alive (Remastered Album Version)",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/48995c0af9fd239515b62047aeca0ce21cb03f17",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/f8be5e54fb651f86bb6c9e328a805c34e5527d48",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1978
  },
  {
    "song_id": "4E0lSJ6c5unoku9UIZoJSl",
    "name": "Dust In The Wind",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/c7956974db28090acaa5b50d48259c7520b5c9cc",
    "artist_name": "Kansas",
    "image_url": "https://i.scdn.co/image/f56ade2cd2851458c0aa8c0080d746a700df80d6",
    "artist_id": "2hl0xAkS2AIRAu23TVMBG1",
    "year": 1978
  },
  {
    "song_id": "1gIKd24bixkgYzuxse32oR",
    "name": "How Deep Is Your Love (2007 Remastered Saturday Night Fever LP Version)",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/e07d8a0f28356784339be61bbfe57c4fc2577e96",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/c4b4399d9b7c6f61b6a6ee70c616bc1a985c7ab8",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1978
  },
  {
    "song_id": "0ZFJpY6hELA21Y5d5YmdlR",
    "name": "Night Fever (Remastered Album Version)",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/9a8772384a7050db6699bee3f15c9abc0748ebab",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/9ba9a3a360c2f681818fa251fcc4a5800c01b1e3",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1978
  },
  {
    "song_id": "5gOd6zDC8vhlYjqbQdJVWP",
    "name": "Baker Street",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/e8a74dd71990d80bd0c18e003dbda2ee04b6464c",
    "artist_name": "Gerry Rafferty",
    "image_url": "https://i.scdn.co/image/069bcd4c58e5de6c154950da2c56edf25b82d2ce",
    "artist_id": "7tjbDPvrdvDshcpEMXKRVb",
    "year": 1978
  },
  {
    "song_id": "2wvMC5EyaaYQwBfiwwY2xE",
    "name": "Life's Been Good",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/169168b04c64e31d945b0a3c3d9adea34a084af7",
    "artist_name": "Joe Walsh",
    "image_url": "https://i.scdn.co/image/8c26ae894a808cda86d1e65388e7f89c594bec85",
    "artist_id": "5bDxAyJiTYBat1YnFJhvEK",
    "year": 1978
  },
  {
    "song_id": "11G0yprQtMU53BLPMSr093",
    "name": "Come Sail Away",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/d8f82694f4c6905ce405fe1c2cbcd86b6e95037c",
    "artist_name": "Styx",
    "image_url": "https://i.scdn.co/image/fbaa93c80441950bc77d2344fff83db911b8e3ab",
    "artist_id": "4salDzkGmfycRqNUbyBphh",
    "year": 1978
  },
  {
    "song_id": "74t6ZmO6Vzq553l2zRgdhs",
    "name": "Grease",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/e31d52d7215b33bb3b214a9e0d696d9dfca615dd",
    "artist_name": "Frankie Valli",
    "image_url": "https://i.scdn.co/image/9142fb404b509c6671daed8b4d71b200ba262686",
    "artist_id": "3CDKmzJu6uwEGnPLLZffpD",
    "year": 1978
  },
  {
    "song_id": "19guNngQSHXAqoZ1iLFKZ0",
    "name": "Miss You",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/48464393ce1350adfa75234cc7be78dc8feccfef",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/6e4eed337cef4856ab2a07dfb272acfedae24e2b",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1978
  },
  {
    "song_id": "0lIoY4ZQsdn5QzhraM9o9u",
    "name": "Because the Night",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/3269eb856c16cefb9e3e1ca070528362d3a08f9d",
    "artist_name": "Patti Smith",
    "image_url": "https://i.scdn.co/image/ab09aa7e61871381b9ac0daf0cabe36081beb7df",
    "artist_id": "0vYkHhJ48Bs3jWcvZXvOrP",
    "year": 1978
  },
  {
    "song_id": "2oFxITGDiTZ1JRwP8yinRt",
    "name": "On Broadway",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/2a3ded130de58d17b52aac02d13f62ca0474d565",
    "artist_name": "George Benson",
    "image_url": "https://i.scdn.co/image/8dd4927e9daf3258372b392db4821e7e16a47e09",
    "artist_id": "4N8BwYTEC6XqykGvXXlmfv",
    "year": 1978
  },
  {
    "song_id": "2bwYGPDCC31QTdFca1mQRh",
    "name": "Baby Come Back",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/ad0d1e2f4dd968f9e0a487988d0017de7cb17e7c",
    "artist_name": "Player",
    "image_url": "https://i.scdn.co/image/53ac682ecc8965cf972b7a94808827fceb47d4c4",
    "artist_id": "0fgtHVpOPfXb07S8Jx443z",
    "year": 1978
  },
  {
    "song_id": "0zb2kpEQMnqJPiLACKMiFM",
    "name": "Slip Slidin' Away",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/fa3c86f985fbc5788a93d06699b563c75d09dde0",
    "artist_name": "Paul Simon",
    "image_url": "https://i.scdn.co/image/3b5eaaf3d4800724828fa8537f3b8ceac0e75fed",
    "artist_id": "2CvCyf1gEVhI0mX6aFXmVI",
    "year": 1978
  },
  {
    "song_id": "5jUA1njy3h6ynHLvPdEVHt",
    "name": "Disco Inferno - LP/12\" Version",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/24048485a10c6d80bb1a386ebe8e1dfcb55430b0",
    "artist_name": "The Trammps",
    "image_url": "https://i.scdn.co/image/79a3ba9e1fe98f5183b2a84ff0da4f1ea0a33dec",
    "artist_id": "1zgNpeHQe8GulzfVkYP2VK",
    "year": 1978
  },
  {
    "song_id": "28XbqAYJMf4oT4MkBP91nT",
    "name": "Peg",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/cbe85ea882a69735f79976d0d8660498db1ca0ee",
    "artist_name": "Steely Dan",
    "image_url": "https://i.scdn.co/image/a3d43bc0056ab85b18603dc42e12a618ed712493",
    "artist_id": "6P7H3ai06vU1sGvdpBwDmE",
    "year": 1978
  },
  {
    "song_id": "0fKDnkXdfJwk0DEW9W83VY",
    "name": "Always And Forever",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/f8bd941339830715c39d236f5ab20971311dc86d",
    "artist_name": "Heatwave",
    "image_url": "https://i.scdn.co/image/fb0c73d7975cdc27f7f4e17e4f39149fe7d2ab70",
    "artist_id": "6D7h7R79IZjqJC2GM2wzyY",
    "year": 1978
  },
  {
    "song_id": "7yjAL13BeIaT9BKJZBy8b1",
    "name": "You Light Up My Life",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/7bcc2369ad2d5f308ffbc7226dd117cb45abc5d8",
    "artist_name": "Debby Boone",
    "image_url": "https://i.scdn.co/image/1512e310c34690034df5c536fb7e4d90f727c1b1",
    "artist_id": "1UkHCANZmdkKyVNvTLMrcJ",
    "year": 1978
  },
  {
    "song_id": "0QX4u1gl2VZ6HKShy7BWEn",
    "name": "The Groove Line",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/c64980e15f74f71d48cfab107a1c4bccef22fa47",
    "artist_name": "Heatwave",
    "image_url": "https://i.scdn.co/image/44bf04e680a2d6156d63aa01f1ea70a952d976b3",
    "artist_id": "6D7h7R79IZjqJC2GM2wzyY",
    "year": 1978
  },
  {
    "song_id": "4NoLnnJvVK5tpOdlNQI6RG",
    "name": "Reminiscing - 2002 Digital Remaster",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/eebefa6b80ddd46ced26a38bfb92ac3fb300b3f8",
    "artist_name": "Little River Band",
    "image_url": "https://i.scdn.co/image/877352214329cf8836ac8a020bb1fe0d0f75c5f0",
    "artist_id": "6clbbhnIqpHnqxwtOWcilg",
    "year": 1978
  },
  {
    "song_id": "39E9wHDDmHgKJt8yWWgRjT",
    "name": "Can't Smile Without You",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/590a0bc04e1b9dfb9a37ab9f83c650ed18d34305",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/8184f45937baed4ec717f3558e391e99b0c5df30",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1978
  },
  {
    "song_id": "0K0I8IUxoUQAdHFwCgjMEc",
    "name": "Two Out Of Three Ain't Bad",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/b3ff3d32e1958c50b21a6c3dc4a57e359730338d",
    "artist_name": "Meat Loaf",
    "image_url": "https://i.scdn.co/image/2b28227fe77294596a3e294cd766a1a397268639",
    "artist_id": "7dnB1wSxbYa8CejeVg98hz",
    "year": 1978
  },
  {
    "song_id": "1TyuusIyvlm93bl50KEsmd",
    "name": "Deacon Blues",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/a874f0538b5e6c4c93c3057bc01aa1393a2befc8",
    "artist_name": "Steely Dan",
    "image_url": "https://i.scdn.co/image/a3d43bc0056ab85b18603dc42e12a618ed712493",
    "artist_id": "6P7H3ai06vU1sGvdpBwDmE",
    "year": 1978
  },
  {
    "song_id": "6KlnQC66NiJTdO88aL62SF",
    "name": "Shame",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/791efb8883aec7ec931394fbb5eb375f7380426c",
    "artist_name": "Evelyn \"Champagne\" King",
    "image_url": "https://i.scdn.co/image/db3ea943e1833876f9fc26fcba77bfb5692bba57",
    "artist_id": "4JCt4xrbbBB9blkKwNlcJ7",
    "year": 1978
  },
  {
    "song_id": "3VgVhmZkA99quKw6O5NLNh",
    "name": "Copacabana - Remastered",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/61b1e279a782fed4a2f799fe133e3ed78aa68b96",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/4e5b5e410afce355fae6c38fc06a28f81e0e3a15",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1978
  },
  {
    "song_id": "6hR3R7WPW5l639XAYCNpJe",
    "name": "Whenever I Call You \"Friend\"",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/45ec62fb11c465c6ffc71d9c76b70841f9f4e472",
    "artist_name": "Kenny Loggins",
    "image_url": "https://i.scdn.co/image/d985d2b51571bba1d65247ee06450d621bbefc58",
    "artist_id": "3Y3xIwWyq5wnNHPp5gPjOW",
    "year": 1978
  },
  {
    "song_id": "3c6vdiLeV9DUbTMWk5H3se",
    "name": "Goodbye Girl",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/d80bed70fd987285b51ca0695aa5750d116d38a5",
    "artist_name": "David Gates",
    "image_url": "https://i.scdn.co/image/3341ff05a6e52ab9335a2c0fc4c47aca8052c4a5",
    "artist_id": "5BmXbfqAgfkuTkPlVQDKOW",
    "year": 1978
  },
  {
    "song_id": "0d8U9eJajtUmiJVXbIO624",
    "name": "Don't It Make My Brown Eyes Blue - 2001 - Remaster",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/aabbecf8ea813ce489a38fe00c897443accb9dbe",
    "artist_name": "Crystal Gayle",
    "image_url": "https://i.scdn.co/image/721efeb4562ca38099b661598c71937318f99efe",
    "artist_id": "6OheJTrDFGiyZ67F1BBLhc",
    "year": 1978
  },
  {
    "song_id": "69eihm0ByPDKtOnZ2B9mA7",
    "name": "Hot Child In The City - Single Version",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/5e8bc097e864714bab0b8be1e76a6a745e92d6a3",
    "artist_name": "Nick Gilder",
    "image_url": "https://i.scdn.co/image/19672aaf6bd661113ac7b42389bd368f09ed21cc",
    "artist_id": "2kzcxYyQIRbwtkwVtj48mG",
    "year": 1978
  },
  {
    "song_id": "6ilhA8FpQSQIUqBNVQBQiE",
    "name": "Baby Hold On",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/53c3833267be57f9fb488df9dddad6d441b05408",
    "artist_name": "Eddie Money",
    "image_url": "https://i.scdn.co/image/2845ecb9bdf80dd89dd46bf137aa0eb02812dfb6",
    "artist_id": "4Tw2N3wdvJPGEU7JqMxFfE",
    "year": 1978
  },
  {
    "song_id": "44jdxE3S8EX1lOAxV9vQbZ",
    "name": "The Closer I Get To You - Single/",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/c7a90042a1286a08cbfd75ab50fb4d8758eeb280",
    "artist_name": "Roberta Flack",
    "image_url": "https://i.scdn.co/image/8f5220f2d68242648ea994dec82b60a9a60b1c62",
    "artist_id": "0W498bDDNlJIrYMKXdpLHA",
    "year": 1978
  },
  {
    "song_id": "31Q84a7UanVaTSrnFsFypj",
    "name": "Blue Bayou (Remastered LP Version)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/0cc98e348436bc3a42bd091ca831fdc68892ad51",
    "artist_name": "Linda Ronstadt",
    "image_url": "https://i.scdn.co/image/f6c8c914b56d40d2acd202800544adc65ea8e240",
    "artist_id": "1sXbwvCQLGZnaH0Jp2HTVc",
    "year": 1978
  },
  {
    "song_id": "4ffryesggmTUgxvsD90msA",
    "name": "Hopelessly Devoted To You",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/fdb52910ae8cac8e22bf93f2ece282b8f706dfac",
    "artist_name": "Olivia Newton-John",
    "image_url": "https://i.scdn.co/image/85d008cb40e7c2ab432d43ae25059c6db5e5c0e9",
    "artist_id": "4BoRxUdrcgbbq1rxJvvhg9",
    "year": 1978
  },
  {
    "song_id": "7EjdrZbFpBP2lIKT5zL7gT",
    "name": "Use ta Be My Girl",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/040ee8d82ad4ee052c5595a0eb5b6e7d688e183a",
    "artist_name": "The O'Jays",
    "image_url": "https://i.scdn.co/image/86c631a157d28317fb9bd884c213c446079eb125",
    "artist_id": "38h03gA85YYPeDPd9ER9rT",
    "year": 1978
  },
  {
    "song_id": "3pErM4EiWKf3k8KU3jjbXn",
    "name": "You Belong To Me",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/f9b70b0eed0d6222a55f4198ff2d7124cfe95220",
    "artist_name": "Carly Simon",
    "image_url": "https://i.scdn.co/image/180e2ff010f1a34056de2e8336b542d7cca7a978",
    "artist_id": "4FtSnMlCVxCswABUmdhwpm",
    "year": 1978
  },
  {
    "song_id": "0vGUCq60dVguFmDHE6IT5B",
    "name": "You Needed Me - 1994 - Remaster",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/46afc7e65128ee74259f3e9b3e0c297a329f95f9",
    "artist_name": "Anne Murray",
    "image_url": "https://i.scdn.co/image/ce64b199bd2cca016ccfa1104e336048b02ef88b",
    "artist_id": "7d7q5Y1p2QWS4QRAhTQR5E",
    "year": 1978
  },
  {
    "song_id": "67LH3UcL2wwKon7miFFoZf",
    "name": "Count on Me",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/16f6c03c2338452d90fe3d4787cab3812e37b7fb",
    "artist_name": "Jefferson Starship",
    "image_url": "https://i.scdn.co/image/c11d3bc87785d1c4b4a2b6143a7e7fca3a229eb0",
    "artist_id": "3HC7NcxQx2Yk0fWoRKJ0xF",
    "year": 1978
  },
  {
    "song_id": "4U4vEbJx5ObAdqyyX0rvJE",
    "name": "Sentimental Lady",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/83595a2a60dbb9abf25e63b49e676278c6eaa922",
    "artist_name": "Bob Welch",
    "image_url": "https://i.scdn.co/image/852376709c73f9bdbf69bccc305eae1df9120965",
    "artist_id": "0reZZVbAPxgX1Rqj6XbWj3",
    "year": 1978
  },
  {
    "song_id": "3FkQN4NyCFlfn7fWzBkWCj",
    "name": "Thunder Island",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/93428a2af059c4530d0b576457f3e32b0737b4d7",
    "artist_name": "Jay Ferguson",
    "image_url": "https://i.scdn.co/image/e15c949fa9c164bbbd67ab40f2d6176e2f315b6d",
    "artist_id": "3aahGWIXwvG1fxduXjimtl",
    "year": 1978
  },
  {
    "song_id": "7c8tlriQMBusKSsXMg8fHm",
    "name": "Too Much, Too Little, Too Late",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/2849d00fd64058875ac70061053b9e7617a12da0",
    "artist_name": "Johnny Mathis",
    "image_url": "https://i.scdn.co/image/db7f8fd609f371c497abae3606b9859658302dee",
    "artist_id": "21LGsW7bziR4Ledx7WZ1Wf",
    "year": 1978
  },
  {
    "song_id": "0z4ZkIPi5VXJksMtd18seX",
    "name": "We Will Rock You",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/d89187b1c142396af4a8562103b2d4ea149595bb",
    "artist_name": "Queen",
    "image_url": "https://i.scdn.co/image/350c87dc4f74abc3e54517310e4c9221e5595317",
    "artist_id": "1dfeR4HaWDbWqFHLkxsg1d",
    "year": 1978
  },
  {
    "song_id": "6xMHglHoafdDFGXS6qfwSH",
    "name": "Hot Blooded",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/56e641fc388d31e29790b3e990a30f7066a9851a",
    "artist_name": "Foreigner",
    "image_url": "https://i.scdn.co/image/4d0b28f8087e4a52e5d3d8c33dc9ea8dabb21f3c",
    "artist_id": "6IRouO5mvvfcyxtPDKMYFN",
    "year": 1978
  },
  {
    "song_id": "1lsAJKTZGQrKBhNVMZhZtq",
    "name": "You're In My Heart (The Final Acclaim)",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/8b70580bf83fcfa63e31b8b09b89b3fb68aa7d0f",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/8fad8361da0d9ea764240351802d6f8d9d4d8495",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1978
  },
  {
    "song_id": "1aS6ab6EJRJXctFnt55Jkb",
    "name": "Imaginary Lover",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/e3f827721b62784a3c591eb25d7b356e330e7557",
    "artist_name": "Atlanta Rhythm Section",
    "image_url": "https://i.scdn.co/image/2994b1bf4f79a0e46a3f2189f38a0eef62b37dac",
    "artist_id": "0WgyCbru4tXnMsbTmX4mFw",
    "year": 1978
  },
  {
    "song_id": "0qb4XjVxUKGLhAxVw17Bsu",
    "name": "Bluer Than Blue - 2001 - Remaster",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/c05ae705a7672161b93870845a03f436a259757b",
    "artist_name": "Michael Johnson",
    "image_url": "https://i.scdn.co/image/917c160d646f3aa6782ecd61eb20cca19ca93f8b",
    "artist_id": "2XKBOnP3qXHP2FpzKplAYh",
    "year": 1978
  },
  {
    "song_id": "6c0G8K6G2LlIfJTsRLJjfs",
    "name": "I Love The Nightlife (Disco 'Round)",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/c92ac837396ab9d2ce64cfb599dcbb606f05811a",
    "artist_name": "Alicia Bridges",
    "image_url": "https://i.scdn.co/image/e27cd7a42931ada73eac4898643b835244e173ae",
    "artist_id": "1UY4oIFpjCKe5qIhKDcloe",
    "year": 1978
  },
  {
    "song_id": "4OLRw2NSxCyNkfCs8Uwvwg",
    "name": "Ebony Eyes",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/2d8db57049dc8f9590ebb7ca5ece3a357ec2d904",
    "artist_name": "Bob Welch",
    "image_url": "https://i.scdn.co/image/21f3d5fb3912b59abca99bea9b28a75cefdbdeb6",
    "artist_id": "0reZZVbAPxgX1Rqj6XbWj3",
    "year": 1978
  },
  {
    "song_id": "0z0gqCUMdkydEttZmKze1h",
    "name": "We Are The Champions",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/c9ba25e24abc12f7ea13b95fd1749c6e1c25e78d",
    "artist_name": "Queen",
    "image_url": "https://i.scdn.co/image/350c87dc4f74abc3e54517310e4c9221e5595317",
    "artist_id": "1dfeR4HaWDbWqFHLkxsg1d",
    "year": 1978
  },
  {
    "song_id": "2t8KVbAZLVybye9wZJusTp",
    "name": "Shadow Dancing",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/8b33dc7f5e6f192cc62beb86578fc4c745992082",
    "artist_name": "Andy Gibb",
    "image_url": "https://i.scdn.co/image/819282643cee858ebaf531f01fa591490e4eab8f",
    "artist_id": "4YPqbAiLzBg5DIfsgQZ8QK",
    "year": 1978
  },
  {
    "song_id": "7h7NbGtEGRg1auT9ye7mXg",
    "name": "[Love Is] Thicker Than Water",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/588e47004a5aec98c58e5f714257bb573853d83e",
    "artist_name": "Andy Gibb",
    "image_url": "https://i.scdn.co/image/fadfe2865b5e6858867b9cb00fff26520a6b9ea1",
    "artist_id": "4YPqbAiLzBg5DIfsgQZ8QK",
    "year": 1978
  },
  {
    "song_id": "0xBUip8pf80321XF6jDYqx",
    "name": "Last Dance - Single Version",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/02df197bcb8baa3d36aab199a4da8d343c7e3f29",
    "artist_name": "Donna Summer",
    "image_url": "https://i.scdn.co/image/2683638d681ef6b3182be87cc6bc33c556f68fba",
    "artist_id": "2eogQKWWoohI3BSnoG7E2U",
    "year": 1978
  },
  {
    "song_id": "5k611PjxiTgNqt4FVr033f",
    "name": "You're The One That I Want",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/a92e2ad3f8b81ca5ae6f8e28e704236508e35580",
    "artist_name": "John Travolta",
    "image_url": "https://i.scdn.co/image/85d008cb40e7c2ab432d43ae25059c6db5e5c0e9",
    "artist_id": "4hKkEHkaqCsyxNxXEsszVH",
    "year": 1978
  },
  {
    "song_id": "5wivDKfpKvohlwWjxAuAOn",
    "name": "Feels So Good",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/f9f3cfe172906c75d5abf38d4b7e1e253f27b794",
    "artist_name": "Chuck Mangione",
    "image_url": "https://i.scdn.co/image/de6b71e037fd553d4079270bbe3a37a18a62da61",
    "artist_id": "2MMaIlHd8UvDW0mVRAncD6",
    "year": 1978
  },
  {
    "song_id": "5KDT5BDSmomPMe2AXuGInR",
    "name": "Jack and Jill",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/1e5d59a3a2827abdf1b1f45ee89e60ab3bd63683",
    "artist_name": "Raydio",
    "image_url": "https://i.scdn.co/image/2295019c13bba5c3abae0602374728e364dc8aa3",
    "artist_id": "1VQ7baxc9Okx2YuRnpKMMR",
    "year": 1978
  },
  {
    "song_id": "6emIUUgOV0lPgUYbJ1RZ7I",
    "name": "Short People",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/ff5348e4eb9d38eb850bf576f99b7ece63d722c8",
    "artist_name": "Randy Newman",
    "image_url": "https://i.scdn.co/image/42644b643856a9a79be6426bc2a6beefce1434d2",
    "artist_id": "3HQyFCFFfJO3KKBlUfZsyW",
    "year": 1978
  },
  {
    "song_id": "2u02gyZqCqOvemiSk8DNyk",
    "name": "Magnet and Steel",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/ed1a61db64d95b1629bf84611496afe05ba78a9b",
    "artist_name": "Walter Egan",
    "image_url": "https://i.scdn.co/image/d46eefe6d2d41f9dd0423cdec16c97c5a9ef313e",
    "artist_id": "3jaS8HCylkZ3YKhn5t20DU",
    "year": 1978
  },
  {
    "song_id": "5qZNMsjtZwB02g4oQnHXWf",
    "name": "I Go Crazy",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/446eec056bee5ac1a5107e8b836eab07db00730a",
    "artist_name": "Paul Davis",
    "image_url": "https://i.scdn.co/image/baacfabb9e7990243739b0cb8c2de238dd4d8f58",
    "artist_id": "6EJmqnuK0r6qiAevFFiNNR",
    "year": 1978
  },
  {
    "song_id": "73JqQIGZUZxCVfY54RSpCH",
    "name": "With A Little Luck - 1993 Digital Remaster",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/d71b517b605a732fc2bceb6893c8c53c8a66e995",
    "artist_name": "Wings",
    "image_url": "https://i.scdn.co/image/fb0d9a6b3caeee463ff0839dd8c9d8283c55a810",
    "artist_id": "3sFhA6G1N0gG1pszb6kk1m",
    "year": 1978
  },
  {
    "song_id": "1SwyDb5qUcLjukFqSCRqxy",
    "name": "Dance, Dance, Dance - Yowsah, Yowsah, Yowsah",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/f19815ceb782f8468e482e575ad4ff379b70e10f",
    "artist_name": "Chic",
    "image_url": "https://i.scdn.co/image/1af2fce27ba9e0f72ea910a0c01c1d40349d8bb3",
    "artist_id": "0Xf8oDAJYd2D0k3NLI19OV",
    "year": 1978
  },
  {
    "song_id": "1AyQOZw0n6yzQZl1kHZl5j",
    "name": "Every Kinda People",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/3e05cde808462175674695f7730ae0e4a795db46",
    "artist_name": "Robert Palmer",
    "image_url": "https://i.scdn.co/image/e7636c7198d68ac31a22ac5e3878690b3fec97ef",
    "artist_id": "530Sdm7eqqzWBdDmILMgnu",
    "year": 1978
  },
  {
    "song_id": "07NrCEN3egNvw8td2LxqJO",
    "name": "Boogie Oogie Oogie - 2004 - Remastered",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/4de068e4da33b5f683d144a477ac4410aba0926f",
    "artist_name": "A Taste Of Honey",
    "image_url": "https://i.scdn.co/image/d3597576c6822e78fab304818e6f463d0b0b2970",
    "artist_id": "1ii6e2pv8VIRwnTER71rMl",
    "year": 1978
  },
  {
    "song_id": "23TXZuQEb3U0nlHrSG8G14",
    "name": "(Every Time I Turn Around) Back In Love Again",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/718a5edb8cc3d8fe03bb41ae4429126b344f5e8d",
    "artist_name": "L.T.D.",
    "image_url": "https://i.scdn.co/image/ff9bf51d8b7ec59c913c72e2bb3ee158a48b71d2",
    "artist_id": "5P53Exo01miUunItQyR2La",
    "year": 1978
  },
  {
    "song_id": "4VysuZmoPFPQuym9vYK3vM",
    "name": "Emotion",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/2c9fba82b0b082b7bc746d0ff7dbafff204149b2",
    "artist_name": "Samantha Sang",
    "image_url": "https://i.scdn.co/image/209730725e6ae4ca51f813e1b39ae5cf2cad4d5f",
    "artist_id": "1IvJtmPLbzpxDxfrw4rMFa",
    "year": 1978
  },
  {
    "song_id": "4W6NUHZbRYc8etCGKGNLz3",
    "name": "Love Will Find A Way",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/9383f7d4a14761057529d825dbd9c62cc1856e66",
    "artist_name": "Pablo Cruise",
    "image_url": "https://i.scdn.co/image/3422352efc1c25f1234ef693439522bfa84d0f43",
    "artist_id": "3Y5abCLsMxsOLaZ8rYusSR",
    "year": 1978
  },
  {
    "song_id": "5FAVxC38zkQI0hHt98dvRj",
    "name": "This Time I'm In It For Love",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/31ef5bb963ba7bb3a9baaa8c1267da971acefa2b",
    "artist_name": "Player",
    "image_url": "https://i.scdn.co/image/53ac682ecc8965cf972b7a94808827fceb47d4c4",
    "artist_id": "0fgtHVpOPfXb07S8Jx443z",
    "year": 1978
  },
  {
    "song_id": "0vWyvJEU20cIlFzUTlGZZc",
    "name": "An Everlasting Love",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/96124bc27a3f8bc80b86122cc54a4981c5aae931",
    "artist_name": "Andy Gibb",
    "image_url": "https://i.scdn.co/image/819282643cee858ebaf531f01fa591490e4eab8f",
    "artist_id": "4YPqbAiLzBg5DIfsgQZ8QK",
    "year": 1978
  },
  {
    "song_id": "1eXZp3eQ0IYeDNYDKvqezu",
    "name": "Flash Light",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/efa8853386b378f804d5c0a4b1677b5cfd9cbab2",
    "artist_name": "Parliament",
    "image_url": "https://i.scdn.co/image/9b53b69256dd0bd4d6615812ce156610392aa24a",
    "artist_id": "5SMVzTJyKFJ7TUb46DglcH",
    "year": 1978
  },
  {
    "song_id": "1DpOHHcm8SMFgyiUYE59FF",
    "name": "You Can't Turn Me Off (In The Middle Of Turning Me On)",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/6331c0e685acd28783bb9b2f0a2440e333058796",
    "artist_name": "High Inergy",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "7C2CwbJufEVMLvEvaadNVp",
    "year": 1978
  },
  {
    "song_id": "50FjL3RIcCDbX3U8XYO6uW",
    "name": "Here You Come Again",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/d67693296dc0ef1d0aa3a3f2504a26565cc5b8a6",
    "artist_name": "Dolly Parton",
    "image_url": "https://i.scdn.co/image/1e6126b3998418f59b9aeb3f4a8b56c05e02d455",
    "artist_id": "32vWCbZh0xZ4o9gkz4PsEU",
    "year": 1978
  },
  {
    "song_id": "0QjPiq9Wka9I1b4uzWETYI",
    "name": "If I Can't Have You",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/1da1caa14c6b5b2e399684605ff3fea4d5d39447",
    "artist_name": "Yvonne Elliman",
    "image_url": "https://i.scdn.co/image/d2d7cb999d672ddb2d549c07b859b6deb3c5f510",
    "artist_id": "2d6JU9LvNhZR7AAtu4x2rS",
    "year": 1978
  },
  {
    "song_id": "0PVgef4kvggErf7OS2oktc",
    "name": "Native New Yorker",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/8899419f583c1166070051f890d2a6ac9ab74da5",
    "artist_name": "Odyssey",
    "image_url": "https://i.scdn.co/image/cf1d0c3b2a447a53eb429f6ecce016a821eef167",
    "artist_id": "5b4SvpTqll0LSqJWPpXya5",
    "year": 1978
  },
  {
    "song_id": "6KyGeBEPvXbZ8tPRSkRdOY",
    "name": "Our Love",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/677cb9d63948715082117df380c1d8139595f6e9",
    "artist_name": "Natalie Cole",
    "image_url": "https://i.scdn.co/image/29f465dd3dea9d100f3af5628d0f0e137d1ce02e",
    "artist_id": "5tTsrGPwQRWUsHR2Xf7Ke9",
    "year": 1978
  },
  {
    "song_id": "57HoeEaC7CFEva9CHMePnN",
    "name": "Summer Nights",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/92e93740466f063902430b2f02bf64e1df01a63a",
    "artist_name": "John Travolta",
    "image_url": "https://i.scdn.co/image/85d008cb40e7c2ab432d43ae25059c6db5e5c0e9",
    "artist_id": "4hKkEHkaqCsyxNxXEsszVH",
    "year": 1978
  },
  {
    "song_id": "3WdBse8fYMCCy1IB3dbgCQ",
    "name": "Get Off",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/30f775942e3210dab7c8f3a2b55171b88dcae7fc",
    "artist_name": "Foxy",
    "image_url": "https://i.scdn.co/image/c6b5b70f72f01e880ac03ba96c69319754225297",
    "artist_id": "6OWX8vOXHDmO2UxsfaJnfw",
    "year": 1978
  },
  {
    "song_id": "28nc7vm4Jjm4szqunMZnMc",
    "name": "It's So Easy",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/89b5c66b0c7df0cc6be8564ab0dacd0031e3a933",
    "artist_name": "Linda Ronstadt",
    "image_url": "https://i.scdn.co/image/f6c8c914b56d40d2acd202800544adc65ea8e240",
    "artist_id": "1sXbwvCQLGZnaH0Jp2HTVc",
    "year": 1978
  },
  {
    "song_id": "2mKJOxhHIcQjr14YpmW2aj",
    "name": "Dance With Me",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/b8ee08f6333aad067fca65d6c0ff2ab18e39862c",
    "artist_name": "Betty Wright & Peter Brown",
    "image_url": "https://i.scdn.co/image/af96e8885f9df612c073234e9dffcd61723839bf",
    "artist_id": "4cRKPKk2z5Lzobgz1GKcIV",
    "year": 1978
  },
  {
    "song_id": "6QdEjUUKxXf6jWGEQUaq8i",
    "name": "Three Times A Lady",
    "popularity": 2,
    "preview_url": "https://p.scdn.co/mp3-preview/14f64fec87cf938d31834627a769810204fbc935",
    "artist_name": "Commodores",
    "image_url": "https://i.scdn.co/image/aeecfa5e27de74da65f91ba49191cc6ac62ce444",
    "artist_id": "6twIAGnYuIT1pncMAsXnEm",
    "year": 1978
  },
  {
    "song_id": "4aVuWgvD0X63hcOCnZtNFA",
    "name": "Hold the Line",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/6e3511090a0db2b3ebea55cafc10dea604525521",
    "artist_name": "Toto",
    "image_url": "https://i.scdn.co/image/1d43a2ef005b67b2a15878d7e4fbf3bef3e67e9b",
    "artist_id": "0PFtn5NtBbbUNbU9EAmIWF",
    "year": 1979
  },
  {
    "song_id": "5KqldkCunQ2rWxruMEtGh0",
    "name": "The Gambler",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/827e2ae9a59f4649bb977766230683bb81deeec8",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/58f30cb6545fe5ecba1153844617e5ad79fe2ab1",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1979
  },
  {
    "song_id": "6cr6UDpkjEaMQ80OjWqEBQ",
    "name": "Sultans Of Swing",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/629a998a25494f42c64ca7acadcc5b3a9d2221c1",
    "artist_name": "Dire Straits",
    "image_url": "https://i.scdn.co/image/66d6f661c3d1e1445cebe35e4efe3ded54a6d8da",
    "artist_id": "0WwSkZ7LtFUFjGjMZBMt6T",
    "year": 1979
  },
  {
    "song_id": "0G3fbPbE1vGeABDEZF0jeG",
    "name": "Good Times",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/3215c44c57fec8e31f730608cef11cea15150311",
    "artist_name": "Chic",
    "image_url": "https://i.scdn.co/image/56e924d7b465ea1f06cd9cee85b4f695ce52f844",
    "artist_id": "0Xf8oDAJYd2D0k3NLI19OV",
    "year": 1979
  },
  {
    "song_id": "28NBmftocOzTPEb6OYA9fW",
    "name": "Le Freak",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/250a2b83c8223bc8673575934094b0242c62a399",
    "artist_name": "Chic",
    "image_url": "https://i.scdn.co/image/38b794e3f4be8ed394f2bcbe2f6e5170b761d5f1",
    "artist_id": "0Xf8oDAJYd2D0k3NLI19OV",
    "year": 1979
  },
  {
    "song_id": "3XIEWK1V9n25PS9Vb6axj5",
    "name": "Born to Be Alive - Original Mix 79",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/da99a3f9fae6089f6e33b822dd9c882e02db0ccc",
    "artist_name": "Patrick Hernandez",
    "image_url": "https://i.scdn.co/image/0a530588b9a6907e5aa0bf52b669530594b60bb9",
    "artist_id": "1CcEgi464SWZsKY5579u7z",
    "year": 1979
  },
  {
    "song_id": "3XIEWK1V9n25PS9Vb6axj5",
    "name": "Born to Be Alive - Original Mix 79",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/da99a3f9fae6089f6e33b822dd9c882e02db0ccc",
    "artist_name": "Patrick Hernandez",
    "image_url": "https://i.scdn.co/image/0a530588b9a6907e5aa0bf52b669530594b60bb9",
    "artist_id": "1CcEgi464SWZsKY5579u7z",
    "year": 1979
  },
  {
    "song_id": "1AOmpykwJdf2NOkxwZFIjP",
    "name": "Tragedy",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/8e88a3f75cbc4ed69241dbe152270d3c50e210bd",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/a1c43af78ba7e3c6d2527e25b64693d059c8a73d",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1979
  },
  {
    "song_id": "5MuNxNox3zTanAFIO5KcTl",
    "name": "He's The Greatest Dancer",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/b5393224f12f5185638a7df6285d81d818ef7309",
    "artist_name": "Sister Sledge",
    "image_url": "https://i.scdn.co/image/d71ac1c2c13e92162c8cf83436f162f975c9dab7",
    "artist_id": "6gkWznnJkdkwRPVcmnrays",
    "year": 1979
  },
  {
    "song_id": "0Gql9hTkMSka2wbWxworri",
    "name": "Dance The Night Away",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/161b22f180a3cd5ac3ac4d9a5e2fe6f31fbc5194",
    "artist_name": "Van Halen",
    "image_url": "https://i.scdn.co/image/339f05edc05645019290e6c2480fc9ad4b3cfa02",
    "artist_id": "2cnMpRsOVqtPMfq7YiFE6K",
    "year": 1979
  },
  {
    "song_id": "07q0QVgO56EorrSGHC48y3",
    "name": "I Was Made For Lovin' You",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/7df55d8ca83351399071954210c4b69cb2199906",
    "artist_name": "KISS",
    "image_url": "https://i.scdn.co/image/c25d30c3d44501c3435246b83b1f2e43d34c2b3d",
    "artist_id": "07XSN3sPlIlB2L2XNcTwJw",
    "year": 1979
  },
  {
    "song_id": "6Ep2XErOa5SCBGzARBpCr4",
    "name": "My Life",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/679be736b9736c1f7796a4f177e6ee6dde87bffb",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/dc82b8076e92803f3ff7b469015f2b4540478d5c",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1979
  },
  {
    "song_id": "5hZEoJNMf6VGaKQ177GQKv",
    "name": "When You're In Love With A Beautiful Woman",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/6ab2d3e303af029a0b962a86b15eb690a2103992",
    "artist_name": "Dr. Hook",
    "image_url": "https://i.scdn.co/image/452a37ffd6f07386f63f8447e08ec5db48402eea",
    "artist_id": "2Mhi3jfuRSdbVZPdjqsnnN",
    "year": 1979
  },
  {
    "song_id": "1wRHNlEjTmpaXv99BFAGnV",
    "name": "Got To Be Real - Single Version",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/4af4c53580e68a1ced209ade91803bf0a61670dc",
    "artist_name": "Cheryl Lynn",
    "image_url": "https://i.scdn.co/image/b1edf6d454c958f30d81a020406b0b8aaad0c54f",
    "artist_id": "6UfoTQXaV3DuqtDVjZIxwZ",
    "year": 1979
  },
  {
    "song_id": "0bsdLG0Z1TedLfJGKPpv64",
    "name": "What You Won't Do For Love",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/7f998bc3fff984b3e6875e280c817d28acda1783",
    "artist_name": "Bobby Caldwell",
    "image_url": "https://i.scdn.co/image/9885b99d62ce9bc55b0412d2391aa1f00bb08f14",
    "artist_id": "4V4Z3qMCwYofWHtip6ePF6",
    "year": 1979
  },
  {
    "song_id": "6EOdY7I7Xm1vPP1cyaGbWZ",
    "name": "Ain't No Stoppin' Us Now",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/23bc774297ed94747fad795246cb04e5c4617a64",
    "artist_name": "McFadden & Whitehead",
    "image_url": "https://i.scdn.co/image/b3d77e4631cd8b23c5980c1408e0dd234fa46024",
    "artist_id": "3iQM78Xg0wJnGZhgVNLPmY",
    "year": 1979
  },
  {
    "song_id": "3GEQKzK6WCsLKaLYeVCH88",
    "name": "Sharing The Night Together",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/7ec789b5162c85aec389e7f925f03b04ac92cffa",
    "artist_name": "Dr. Hook",
    "image_url": "https://i.scdn.co/image/452a37ffd6f07386f63f8447e08ec5db48402eea",
    "artist_id": "2Mhi3jfuRSdbVZPdjqsnnN",
    "year": 1979
  },
  {
    "song_id": "3OZ40egQbNWeTe0BnR2QKa",
    "name": "Lonesome Loser - 2010 Digital Remaster",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/766da220b3f5600df0e2aac3e56f8a4a44105cbc",
    "artist_name": "Little River Band",
    "image_url": "https://i.scdn.co/image/f88a8957e9675a0641db35024a1f97dcf738cf39",
    "artist_id": "6clbbhnIqpHnqxwtOWcilg",
    "year": 1979
  },
  {
    "song_id": "5MXXbGYNmRHR7ULMvZYo5R",
    "name": "Every 1's A Winner - Single Version",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/dcf81d66eee68fa25fc60b6ee6bed9401fdf5e70",
    "artist_name": "Hot Chocolate",
    "image_url": "https://i.scdn.co/image/aa0f2769100c9d3e38e5e98a3d66c3b6dbea969f",
    "artist_id": "72VzFto8DYvKHocaHYNWSi",
    "year": 1979
  },
  {
    "song_id": "30OYvco4YZRz3wF2SP2VZC",
    "name": "How Much I Feel (Remastered Version)",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/f15b36f043ee54ee8c276308436660f328296e38",
    "artist_name": "Ambrosia",
    "image_url": "https://i.scdn.co/image/e2a5463ea9916a0f5d41ede0bb07766411e6ac93",
    "artist_id": "0J2Ej2rMQyqaUX3G6MUISw",
    "year": 1979
  },
  {
    "song_id": "6L1Ay5OhRH2Yz38Aa4QZio",
    "name": "Stumblin' In",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/67acc7a460985e89f55ef8014df49d582b5f2822",
    "artist_name": "Suzi Quatro & Chris Norman",
    "image_url": "https://i.scdn.co/image/9968616db007646c2acb2216b787133bd3a53611",
    "artist_id": "2LpVCnQyTrgeCZSEmAvBzZ",
    "year": 1979
  },
  {
    "song_id": "081prZUECLXcWgmg98FxDS",
    "name": "Knock On Wood",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/f8e69dd3ddb829fc1ab8d008aa56e029c7dcc206",
    "artist_name": "Amii Stewart",
    "image_url": "https://i.scdn.co/image/424976fe0bd4d65afbbfd4d6173c89414496272c",
    "artist_id": "7GPNaPWw3STF8NYp39pd8G",
    "year": 1979
  },
  {
    "song_id": "3x5sj5JncCFaS5Qux6eKyX",
    "name": "Y.M.C.A. - Single Version",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/8cf2640ec4dcb8e9d383e091d571ef8e636c8711",
    "artist_name": "Village People",
    "image_url": "https://i.scdn.co/image/9769c9fb686af4ab33587deaa8d6c20a17f8989f",
    "artist_id": "0dCKce6tJJdHvlWnDMwzPW",
    "year": 1979
  },
  {
    "song_id": "4Ym8RapJbNTvnnzlghWtKH",
    "name": "Chuck E's In Love",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/0646288442dd563f225e62cf7d1e02d0f6c4435e",
    "artist_name": "Rickie Lee Jones",
    "image_url": "https://i.scdn.co/image/e25ea21dc9fa859ea7587333749b9bf811aced30",
    "artist_id": "0dYkMe3wK29DulSa0uR8Rq",
    "year": 1979
  },
  {
    "song_id": "1BvpeiApX8qhof8Pmi3YlH",
    "name": "New York Groove",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/b842df69572525755f19d3d822687bff1fe3b310",
    "artist_name": "Ace Frehley",
    "image_url": "https://i.scdn.co/image/04e34f6245cf698cff12308abdf29b2c9daa8d0b",
    "artist_id": "62olK5zZHSgFUXGDykgBL8",
    "year": 1979
  },
  {
    "song_id": "7ro41pqd6RjNe03XEhb6q0",
    "name": "Boogie Wonderland",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/93c8c8d7d4b2bf9d1dd6a47da880f4906376676a",
    "artist_name": "Earth, Wind & Fire",
    "image_url": "https://i.scdn.co/image/33f703a93a98c0492edf64591d5f5896e77a6b75",
    "artist_id": "4QQgXkCYTt3BlENzhyNETg",
    "year": 1979
  },
  {
    "song_id": "2yBVeksU2EtrPJbTu4ZslK",
    "name": "What A Fool Believes",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/bc56246aa68bc9558cb45b7a21f4a0446094c7fe",
    "artist_name": "The Doobie Brothers",
    "image_url": "https://i.scdn.co/image/146831a3091d9c2d8742243e82347a3128b4bd05",
    "artist_id": "39T6qqI0jDtSWWioX8eGJz",
    "year": 1979
  },
  {
    "song_id": "5z2F9FkKdhd9HOSTI1GxRq",
    "name": "Soul Man - Live Version",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/bfab27989028ae8475fb61532229c3f75877a853",
    "artist_name": "The Blues Brothers",
    "image_url": "https://i.scdn.co/image/1b0ff4971b3de339a5e1b50471efd4f6a4aa4777",
    "artist_id": "1cHFz6lrt7KAsBV8j2Ny1g",
    "year": 1979
  },
  {
    "song_id": "5ggOAMsJCAYDHnyoqJ0xAD",
    "name": "She Believes in Me",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/2da02673d622372eeb143f0b42484ad929f94f67",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/58f30cb6545fe5ecba1153844617e5ad79fe2ab1",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1979
  },
  {
    "song_id": "7cv28LXcjAC3GsXbUvXKbX",
    "name": "I Will Survive",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/bcf7b41f5c1c34018593198ea3ed16ba7e0fcdf6",
    "artist_name": "Gloria Gaynor",
    "image_url": "https://i.scdn.co/image/36e213e0807146645b523101df653cdbac433478",
    "artist_id": "6V6WCgi7waF55bJmylC4H5",
    "year": 1979
  },
  {
    "song_id": "10Y6m8je9xsUKfhkSo3fhA",
    "name": "Lady",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/9e6f1a4445002bd54fa23bf95cc63d709e0a03f8",
    "artist_name": "Little River Band",
    "image_url": "https://i.scdn.co/image/852376709c73f9bdbf69bccc305eae1df9120965",
    "artist_id": "6clbbhnIqpHnqxwtOWcilg",
    "year": 1979
  },
  {
    "song_id": "1CQqupcyMg7176PPmIVmSj",
    "name": "Renegade",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/becfe45f582af716f7863df35021f5762b31a4ad",
    "artist_name": "Styx",
    "image_url": "https://i.scdn.co/image/6401dab8ff5d48714e5d4941f85c1df8e1221c95",
    "artist_id": "4salDzkGmfycRqNUbyBphh",
    "year": 1979
  },
  {
    "song_id": "2bS2tETCzUmjALnXEvhOGo",
    "name": "Lotta Love",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/ab6e91199ccb2557962a2cc8dc229515e62ca1cf",
    "artist_name": "Nicolette Larson",
    "image_url": "https://i.scdn.co/image/94970f140807ac0e1e7b5b9565db74473c87656d",
    "artist_id": "0NCXrh1XOnHNp4mM0JUFJw",
    "year": 1979
  },
  {
    "song_id": "2IOeOJyiuzwF8BRAK9jJyj",
    "name": "The Logical Song",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/cc23cf3fbfdd0f482a70d1d67802c696d774ca4d",
    "artist_name": "Supertramp",
    "image_url": "https://i.scdn.co/image/6b40b0c2f21705b078490d521a518193929cf7d8",
    "artist_id": "3JsMj0DEzyWc0VDlHuy9Bx",
    "year": 1979
  },
  {
    "song_id": "0H12Tb13XgOBIBVRamZ5qM",
    "name": "Rubber Biscuit - Live Version",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/05bdc0e94e5d401c9020be1b360233d2325a4e3e",
    "artist_name": "The Blues Brothers",
    "image_url": "https://i.scdn.co/image/1b0ff4971b3de339a5e1b50471efd4f6a4aa4777",
    "artist_id": "1cHFz6lrt7KAsBV8j2Ny1g",
    "year": 1979
  },
  {
    "song_id": "5CItBrmamL7qkVBxQLuOUX",
    "name": "Shine a Little Love",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/82b3328afa4a9ee644488cd9f09bc36a684558fe",
    "artist_name": "Electric Light Orchestra",
    "image_url": "https://i.scdn.co/image/51740f72188def866bbc29ea6cbba8ba936f8894",
    "artist_id": "7jefIIksOi1EazgRTfW2Pk",
    "year": 1979
  },
  {
    "song_id": "55wl56WlKI85UBNZoFxe9P",
    "name": "Just When I Needed You Most",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/2d0878b0c95cf2313dee49b459b00dea24dd1d06",
    "artist_name": "Randy VanWarmer",
    "image_url": "https://i.scdn.co/image/fa8146613d5b912a968ee1ba98b5c64169fb549e",
    "artist_id": "6IRQiyNmNGjGlrmEl0iArm",
    "year": 1979
  },
  {
    "song_id": "5XJ1J9QPxaOzdpkGxKU4lA",
    "name": "Heart Of Glass [24-Bit Digitally Remastered 01]",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/e3bf87af65170e7c1c9b7fc87e651d04ff0c3c1c",
    "artist_name": "Blondie",
    "image_url": "https://i.scdn.co/image/a773acb3dba27c6451e5d081b25bbab3ad637415",
    "artist_id": "4tpUmLEVLCGFr93o8hFFIB",
    "year": 1979
  },
  {
    "song_id": "2YkMr3ooNqTvWtyeZYvd6s",
    "name": "Too Much Heaven",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/578a1fad3a3e5bea2f09f866f4dd16c3d5834ad4",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/a1c43af78ba7e3c6d2527e25b64693d059c8a73d",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1979
  },
  {
    "song_id": "736lDYZHxo9s3ngtHxX49K",
    "name": "Sad Eyes",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/9ea3a59add2f1f950f005cc586c5131bbd7496f2",
    "artist_name": "Robert John",
    "image_url": "https://i.scdn.co/image/852376709c73f9bdbf69bccc305eae1df9120965",
    "artist_id": "3pbKylceBTiUa0fZk4J4sJ",
    "year": 1979
  },
  {
    "song_id": "5VeF4PK0yDxB9wwZTOqe3t",
    "name": "Everytime I Think Of You",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/15493ce3704a428ad2078326d3fd4c6a8b43c675",
    "artist_name": "The Babys",
    "image_url": "https://i.scdn.co/image/8174dabd54eed5076fca7e68400eb6ea91db6185",
    "artist_id": "6z7wrwE2QW7Keb9ozoF0rg",
    "year": 1979
  },
  {
    "song_id": "7hzY0LHz8KdEr1PowHhbdu",
    "name": "Shake Your Groove Thing",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/eb944e9c07b4d73fa0c70ca00f1aa114b0f578f2",
    "artist_name": "Peaches & Herb",
    "image_url": "https://i.scdn.co/image/ae20645b686792a9d1dd7a81ac9b90bee0f08c1b",
    "artist_id": "6qI4LTzMRpTxRzMZPvv2C6",
    "year": 1979
  },
  {
    "song_id": "5zVKisBri6JH1fooBhUaVq",
    "name": "Time Passages",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/e9f91d733a7fd0e3b5f5e8011ebc6bfee1f6f094",
    "artist_name": "Al Stewart",
    "image_url": "https://i.scdn.co/image/043fbb91087ffbbadd51d546624764c89fcec5f3",
    "artist_id": "0DW7boyjvbaSP3OJ72sXgC",
    "year": 1979
  },
  {
    "song_id": "7KMa16GMB7qrvaYHVOZWg8",
    "name": "The Boss",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/24d598b7fe28f9b683933968e352084e26378bd9",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/cc78a79f6b0e12169eff709aa98cae9bbe7cac6b",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1979
  },
  {
    "song_id": "14pIl60LfvAB2XMdsDEaqj",
    "name": "I Just Wanna Stop",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/4e2d74c23614ac6e58056a68b767414632f9549a",
    "artist_name": "Gino Vannelli",
    "image_url": "https://i.scdn.co/image/31e1726a9b2a72c4317427613954709f01e7ba7a",
    "artist_id": "6ltcwvni6HdZAJaWhmIvNR",
    "year": 1979
  },
  {
    "song_id": "6c0G8K6G2LlIfJTsRLJjfs",
    "name": "I Love The Nightlife (Disco 'Round)",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/c92ac837396ab9d2ce64cfb599dcbb606f05811a",
    "artist_name": "Alicia Bridges",
    "image_url": "https://i.scdn.co/image/e27cd7a42931ada73eac4898643b835244e173ae",
    "artist_id": "1UY4oIFpjCKe5qIhKDcloe",
    "year": 1979
  },
  {
    "song_id": "7i9baaWTq7HXkp3cSJ6chL",
    "name": "[Our Love] Don't Throw It All Away",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/7b88f367849d38729f456c55e26727e6f3e8681d",
    "artist_name": "Andy Gibb",
    "image_url": "https://i.scdn.co/image/20afb5c08ab646946717e57a358cf25ebb558e2a",
    "artist_id": "4YPqbAiLzBg5DIfsgQZ8QK",
    "year": 1979
  },
  {
    "song_id": "65lHwG8JFJs67PnOUhCYPq",
    "name": "Fire",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/710884109fb2c43e06b5cdab08edf09aa5ffb3fb",
    "artist_name": "The Pointer Sisters",
    "image_url": "https://i.scdn.co/image/6ecd29d3303ed00a40c885837a3a05c73b8c96ee",
    "artist_id": "2kreKea2n96dXjcyAU9j5N",
    "year": 1979
  },
  {
    "song_id": "6bePbQJkwe3MFJF2rpNNMl",
    "name": "Bad Case Of Loving You (Doctor, Doctor)",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/900953cf7e7dc125c31ccfcdcbded648708d5f89",
    "artist_name": "Robert Palmer",
    "image_url": "https://i.scdn.co/image/e7636c7198d68ac31a22ac5e3878690b3fec97ef",
    "artist_id": "530Sdm7eqqzWBdDmILMgnu",
    "year": 1979
  },
  {
    "song_id": "5ECpIfjbsWQsVa0kr8yPVE",
    "name": "I Want Your Love - Edit Version",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/4434113a6140991ba1d830f2e8405fd3781eea19",
    "artist_name": "Chic",
    "image_url": "https://i.scdn.co/image/4f6d2148ba0471dc7757a23d180d7ee7bb9b69dd",
    "artist_id": "0Xf8oDAJYd2D0k3NLI19OV",
    "year": 1979
  },
  {
    "song_id": "6phjC8JVoUxeRcuPEGrbYq",
    "name": "Rock 'n' Roll Fantasy (Remastered Album Version)",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/d27f63178c822b1e60a60cfacb6682ea5139e83c",
    "artist_name": "Bad Company",
    "image_url": "https://i.scdn.co/image/689f4fb313574a61e3cbbfddcf618ede1c923d5a",
    "artist_id": "5AEG63ajney2BoDXi0Vb84",
    "year": 1979
  },
  {
    "song_id": "0m6bu7QJn8HJJUEfqYY5nI",
    "name": "Don't Cry Out Loud",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/4e841d1ebbd5e9637d0155538043c2b45ff08bd5",
    "artist_name": "Melissa Manchester",
    "image_url": "https://i.scdn.co/image/91992667296616502ccc53021b1c50ab4e54ce34",
    "artist_id": "78udmCDNaDu5jqfVnBqwia",
    "year": 1979
  },
  {
    "song_id": "0fsj3HxzBHev6EJ7OLFE3r",
    "name": "Double Vision",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/4e48d6f82075b7b71211e3252f9c4c9d134b663a",
    "artist_name": "Foreigner",
    "image_url": "https://i.scdn.co/image/4d0b28f8087e4a52e5d3d8c33dc9ea8dabb21f3c",
    "artist_id": "6IRouO5mvvfcyxtPDKMYFN",
    "year": 1979
  },
  {
    "song_id": "2jto2mk883akXynLqkdung",
    "name": "Love Is The Answer",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/3fdeaba47bbf34d1a745b7b4261f89b84b6939ad",
    "artist_name": "England Dan Seals",
    "image_url": "https://i.scdn.co/image/f1bbf1f2e67e790dc39af4276b455c92447091d0",
    "artist_id": "1sFmYEcBTHjGELXH5vxSi9",
    "year": 1979
  },
  {
    "song_id": "5AD7iwvIzbiABVsMndAmez",
    "name": "Goodnight Tonight",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/a915c03e488e47960fea8b1ec1bf7ea1d493c104",
    "artist_name": "Wings",
    "image_url": "https://i.scdn.co/image/a5367d4c896e254d70fce0fa3c5777f3729fd9d1",
    "artist_id": "3sFhA6G1N0gG1pszb6kk1m",
    "year": 1979
  },
  {
    "song_id": "5b8uuQcsSo1OCCiuGZLZBQ",
    "name": "Gold",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/77c1bf81822408c41000c4ce14eca4bba7879f6f",
    "artist_name": "John Stewart",
    "image_url": "https://i.scdn.co/image/99b4929e16c27d31a6de424b2aca89f98de0630a",
    "artist_id": "1IEICAjQ2utMbLHi7zoc5f",
    "year": 1979
  },
  {
    "song_id": "4zvcdfyrGUGgJRsgiCJlVX",
    "name": "Music Box Dancer",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/ba84b87b37eb75edafcbe640543556ad64d8d163",
    "artist_name": "Frank Mills",
    "image_url": "https://i.scdn.co/image/eadcc7e4d00a59c14961d726fde4ac427c4095e3",
    "artist_id": "1rlNSO7W5SWGspO6fgrHPS",
    "year": 1979
  },
  {
    "song_id": "7p4Yw9WPiAuJaN51H4CRqo",
    "name": "Heaven Must Have Sent You - 12\" Version",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/a95c2d90d18bd27afe548ea9f0e9b83fe42684e9",
    "artist_name": "Bonnie Pointer",
    "image_url": "https://i.scdn.co/image/9769c9fb686af4ab33587deaa8d6c20a17f8989f",
    "artist_id": "3ywBpweKQY8MosUnAuPAgc",
    "year": 1979
  },
  {
    "song_id": "0B63PCQ1gOm2YhJiVUQK5Z",
    "name": "Love You Inside Out",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/600fe5a3b6cbeb38578450477102ab68fe97df98",
    "artist_name": "Bee Gees",
    "image_url": "https://i.scdn.co/image/a1c43af78ba7e3c6d2527e25b64693d059c8a73d",
    "artist_id": "1LZEQNv7sE11VDY3SdxQeN",
    "year": 1979
  },
  {
    "song_id": "3kovBaZ1LGLH1PL31qG7cL",
    "name": "I Was Made for Dancin'",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/591f3b6c206db026026f091bb11449894eeefa77",
    "artist_name": "Leif Garrett",
    "image_url": "https://i.scdn.co/image/4f2870df1c1ad820732bbf6faaf8f67b0281b58d",
    "artist_id": "2MyNAoQL07EABerr6yhoT4",
    "year": 1979
  },
  {
    "song_id": "2g5jMKl1csCP8Ufp1rUnqC",
    "name": "The Main Event/Fight",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/d38cc455e50f9c3dca9ffec3fae203d7015d650d",
    "artist_name": "Barbra Streisand",
    "image_url": "https://i.scdn.co/image/510b276f469468a39d0bd335516f7a6eec54c062",
    "artist_id": "7jmTilWYlKOuavFfmQAcu6",
    "year": 1979
  },
  {
    "song_id": "46TMaizWBKWYbWsuOsVehZ",
    "name": "Somewhere In the Night",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/ecfac36b019a34e3d0d8f514e0ec6f530eadfc53",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/8184f45937baed4ec717f3558e391e99b0c5df30",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1979
  },
  {
    "song_id": "4FLEKG82bHeT2olTM8E2Fy",
    "name": "Ooh Baby, Baby",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/7ac7db8252f736eec52081872a79633a40ecf9f7",
    "artist_name": "Linda Ronstadt",
    "image_url": "https://i.scdn.co/image/96e925f65a6de6f3db3a053396ee9b3365ac8ea0",
    "artist_id": "1sXbwvCQLGZnaH0Jp2HTVc",
    "year": 1979
  },
  {
    "song_id": "34YDWNAnbMUmnun2KWLEMJ",
    "name": "I'll Never Love This Way Again - Digitally Remastered: 1999",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/4a308c816abe1288d912de96cb2045179b89239e",
    "artist_name": "Dionne Warwick",
    "image_url": "https://i.scdn.co/image/745d56894a4de74512a7c73f82d46c31f84c299e",
    "artist_id": "2JSjCHK79gdaiPWdKiNUNp",
    "year": 1979
  },
  {
    "song_id": "22esOORsU4VaAMkThygXgm",
    "name": "A Little More Love",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/0a6d8846def4c2e692ec95ba664039ba557cd150",
    "artist_name": "Olivia Newton-John",
    "image_url": "https://i.scdn.co/image/c773435fa3c66178e352424a80e9ab0d685a7a0b",
    "artist_id": "4BoRxUdrcgbbq1rxJvvhg9",
    "year": 1979
  },
  {
    "song_id": "0YHxKYIpwFwKF1IyFABLCz",
    "name": "Promises",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/d840c74efbe0b07b40aa41e36aa09d46b2f6a2b4",
    "artist_name": "Eric Clapton",
    "image_url": "https://i.scdn.co/image/2a3a0bee8240b0797facbdd925c377f40724c704",
    "artist_id": "6PAt558ZEZl0DmdXlnjMgD",
    "year": 1979
  },
  {
    "song_id": "32ri4BrESV97bBNfCvWkna",
    "name": "I Want You to Want Me - Live At Budokan: The Complete Concert",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/f4ee512c30abe5a4e68df9c7bb3eeaf0e0af392c",
    "artist_name": "Cheap Trick",
    "image_url": "https://i.scdn.co/image/fbf4af880055aa08765391755960f661ea893edd",
    "artist_id": "1LB8qB5BPb3MHQrfkvifXU",
    "year": 1979
  },
  {
    "song_id": "6ixpB9xuMwMj4skSaF0Pay",
    "name": "You Take My Breath Away",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/596e3fbeca0f7cab88df798aaf6bf99afdc32ccb",
    "artist_name": "Rex Smith",
    "image_url": "https://i.scdn.co/image/7655e48c48c312c051bff9d5ead30aa1a3ac8693",
    "artist_id": "0UUErweJscAbuxMmVo8MON",
    "year": 1979
  },
  {
    "song_id": "2RgosPJAIbwCgA7XpTEf4A",
    "name": "Lead Me On",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/cd32bf9d026f4cb36fc5db086353ccb0c76cf6ab",
    "artist_name": "Maxine Nightingale",
    "image_url": "https://i.scdn.co/image/4b2c19710048454cf1ef786d4418a3cba0333b5d",
    "artist_id": "6jQ9UtYXqNfVnPkrH1Xxwi",
    "year": 1979
  },
  {
    "song_id": "7gfh3jwjaSmGlB40uTMSeE",
    "name": "Reunited",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/57cda6ebe87de878012f4ed3811c99dcdf3093ee",
    "artist_name": "Peaches & Herb",
    "image_url": "https://i.scdn.co/image/ae20645b686792a9d1dd7a81ac9b90bee0f08c1b",
    "artist_id": "6qI4LTzMRpTxRzMZPvv2C6",
    "year": 1979
  },
  {
    "song_id": "3vHJKkAW1UB6tKDb5GRSlC",
    "name": "Sail On",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/55353fd3039f7e32168e171f1ef6c3131e7ce3d2",
    "artist_name": "Commodores",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "6twIAGnYuIT1pncMAsXnEm",
    "year": 1979
  },
  {
    "song_id": "3sGIwMgana3sZUWxJr8wfq",
    "name": "MacArthur Park - Single Version",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/3142c84a8c51baa1b7130e143627b0f6ecdef465",
    "artist_name": "Donna Summer",
    "image_url": "https://i.scdn.co/image/af5df6fca4826ae8faf52f3a63a88258298d96fc",
    "artist_id": "2eogQKWWoohI3BSnoG7E2U",
    "year": 1979
  },
  {
    "song_id": "5vIvHVCP0n6EXTMH0fOpAp",
    "name": "You Can't Change That",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/30c239e4a714e1068de4117a7caec8bc00db0291",
    "artist_name": "Raydio",
    "image_url": "https://i.scdn.co/image/b4c8f4efb1d0dcd38d8521df81dd6e228194dcdd",
    "artist_id": "1VQ7baxc9Okx2YuRnpKMMR",
    "year": 1979
  },
  {
    "song_id": "6aBiU9lFd3BhtggI7aeqBI",
    "name": "I Just Fall In Love Again",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/1039e01a37b5c85dbc91cbef1cc4bd36d4b898a7",
    "artist_name": "Anne Murray",
    "image_url": "https://i.scdn.co/image/9db5a53b5f34f2e6f427ecad033dcb34d86ea80a",
    "artist_id": "7d7q5Y1p2QWS4QRAhTQR5E",
    "year": 1979
  },
  {
    "song_id": "7KeEL92L4FCtfzjOOSYk4y",
    "name": "Take Me Home",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/28b91039be9e1b382210278afbaa1412632aa827",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/52a11b18400811dc256e01cc86298208774c7f86",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1979
  },
  {
    "song_id": "6Cv0X4bwyU3lGjjdSCaItZ",
    "name": "Disco Nights (Rock Freak)",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/3086696ec63f4c596904f508ce39293ce399b37d",
    "artist_name": "G.Q.",
    "image_url": "https://i.scdn.co/image/e9de422ea716a5d5b7ed9b2f582325b0e3fdb07f",
    "artist_id": "18YelxQioKKPXK1PE5hh1n",
    "year": 1979
  },
  {
    "song_id": "5qARq72xV4UjaAlGpF4Egr",
    "name": "Shake Your Body (Down to the Ground) - Single Version",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/ee5682a41077e33020c63a0bf008c686c5b52cb0",
    "artist_name": "The Jacksons",
    "image_url": "https://i.scdn.co/image/c0971f0a54d1b3d45f5b40a604faaa05b571f743",
    "artist_id": "2yrbLiuBmc9j81lTX3XUuI",
    "year": 1979
  },
  {
    "song_id": "4lFO4X6ef61SR6M1KXkSRN",
    "name": "Get Used To It",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/180778fee890742f7a81182e1d1aa21b55fde93d",
    "artist_name": "Roger Voudouris",
    "image_url": "https://i.scdn.co/image/6cda3a1281be795629fdcbf71ce44304b757b144",
    "artist_id": "0AfDBW6x1y3cJ7U8wSasVM",
    "year": 1979
  },
  {
    "song_id": "6xQgB8a19MYznsChgo2eco",
    "name": "Hot Summer Nights",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/77fc055c5211b72c63490d61acd183838baefaa3",
    "artist_name": "Night",
    "image_url": "https://i.scdn.co/image/f9eabe27b2e89e6f9ac88937c05cdd87974b44da",
    "artist_id": "6TLWHRxxE45qfTA39kH2U0",
    "year": 1979
  },
  {
    "song_id": "01e5xhsaOdGkBCeXfIZuSd",
    "name": "Strange Way",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/9b261b4b6a574400a4cdb85cb652ae2d81f839ed",
    "artist_name": "Firefall",
    "image_url": "https://i.scdn.co/image/f91761e1b7316af4995993aba0a23bcb1f4403c1",
    "artist_id": "1tfXh4XxILEuMU2qZupFn8",
    "year": 1979
  },
  {
    "song_id": "1rtAl6N5enZ2oQCM5i4d18",
    "name": "The Devil Went Down To Georgia",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/886ea4126dd9c14efe605ea6345933997c25aee4",
    "artist_name": "Charlie Daniels",
    "image_url": "https://i.scdn.co/image/e0b982cf7891b2c909774bdb521e436cc805873a",
    "artist_id": "6UpFUXmXvDV7Qj1SPymamh",
    "year": 1979
  },
  {
    "song_id": "4rEeySGarIeV14bJThGqmG",
    "name": "We've Got Tonight",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/0a57e1dcd5b0e06382ddab4f9caa0e777326f20d",
    "artist_name": "Sam Morrison and Turn The Page",
    "image_url": "https://i.scdn.co/image/853966bb7be58b12d25603ec6538ce5d5146fd45",
    "artist_id": "7csps3rrXHbv6Xnom2Ntjl",
    "year": 1979
  },
  {
    "song_id": "5Bm1Ev6NNfq1iiS04YZCPZ",
    "name": "In the Navy",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/d1a5f4da3b4843ec57e84e2b074401d1837d779f",
    "artist_name": "Studio 99",
    "image_url": "https://i.scdn.co/image/3f8ebbe8d7a766dbd1e8d10e2688eb788828169a",
    "artist_id": "2HcpPno2Aejd2XpN0nDxV9",
    "year": 1979
  },
  {
    "song_id": "2jf08ibVe8JxH6lhnOPO52",
    "name": "Makin' It [In the Style of David Naughton] {Karaoke Lead Vocal Version}",
    "popularity": 4,
    "preview_url": "https://p.scdn.co/mp3-preview/4f8eeec3034a254c6d5fbf005d5d289231341537",
    "artist_name": "The Karaoke Channel",
    "image_url": "https://i.scdn.co/image/d2283d639d394d15b78c049f92571fcb5a6678e0",
    "artist_id": "5UloVKzUNJcjORzhhTWWiJ",
    "year": 1979
  },
  {
    "song_id": "5IMtdHjJ1OtkxbGe4zfUxQ",
    "name": "Escape (The Pina Colada Song)",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/cf3165be5831c71fa31b9e356519badc1a94c3a0",
    "artist_name": "Rupert Holmes",
    "image_url": "https://i.scdn.co/image/1de2d16e21a613b1e7c75e0e99e374e8a4e65fd7",
    "artist_id": "0TqIPD4IS1w4e30R38B3vj",
    "year": 1980
  },
  {
    "song_id": "0vLwL4xuJ3s7SeaCdvMqkY",
    "name": "Call Me",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/80d587a489b29a8c4002c8807d60a31526bb8a42",
    "artist_name": "Blondie",
    "image_url": "https://i.scdn.co/image/0557aa97dce492250a742afb38cf977374fe11ec",
    "artist_id": "4tpUmLEVLCGFr93o8hFFIB",
    "year": 1980
  },
  {
    "song_id": "3CKLPDN1AIOYa7QPpy6oDH",
    "name": "Cars",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/a3c508a209f6708e5306d2bacf4d0a4b6868b390",
    "artist_name": "Gary Numan",
    "image_url": "https://i.scdn.co/image/f737027b007704ed46c748664384e918b8bb393e",
    "artist_id": "5KQMtyPE8DCQNUzoNqlEsE",
    "year": 1980
  },
  {
    "song_id": "1uxzFavoQSzR6NhzeSbHdM",
    "name": "I Wanna Be Your Lover [Single Version]",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/7d3ac31358a388f8e137b28c691ff2dbe3f5ac9a",
    "artist_name": "Prince",
    "image_url": "https://i.scdn.co/image/49db086c1d635a44cc1662c5ca9fe99a5ed3d1f7",
    "artist_id": "5a2EaR3hamoenG9rDuVn8j",
    "year": 1980
  },
  {
    "song_id": "7LbOQt7isddrw3Bs3Czl7E",
    "name": "It's Still Rock and Roll to Me",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/2d6cbe26b8ab17d227866c2101dfaa0f0089c06f",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/dc82b8076e92803f3ff7b469015f2b4540478d5c",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1980
  },
  {
    "song_id": "7Crfr7SSFmcxNXi64fKEaB",
    "name": "Heartbreaker",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/d76dd233313ed151a50cdb9448d0daac1647956d",
    "artist_name": "Pat Benatar",
    "image_url": "https://i.scdn.co/image/8116d31266c47b59a0ee6b9c116584944e73bc57",
    "artist_id": "43mhFhQ4JAknA7Ik1bOZuV",
    "year": 1980
  },
  {
    "song_id": "62GYoGszQfROZswLee6W3O",
    "name": "Give Me The Night",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/382db8a359b4c29d40517d11dd356b305d011af7",
    "artist_name": "George Benson",
    "image_url": "https://i.scdn.co/image/8dd4927e9daf3258372b392db4821e7e16a47e09",
    "artist_id": "4N8BwYTEC6XqykGvXXlmfv",
    "year": 1980
  },
  {
    "song_id": "5Ii750a5kcwanAaBfrOlZM",
    "name": "Sailing",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/339f06d4615193527495c8b7443cfab4674c464d",
    "artist_name": "Christopher Cross",
    "image_url": "https://i.scdn.co/image/a403b39d806923e3c2642fa81faa28812bdeb148",
    "artist_id": "695W5F2Ih8dYahLdjVOIoH",
    "year": 1980
  },
  {
    "song_id": "3zYpRGnnoegSpt3SguSo3W",
    "name": "Off the Wall",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/a918dbf573ac4eee31cf7721e4f67ac1d293990f",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/4e883b352604514f02bb47d45e87322bd4376115",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1980
  },
  {
    "song_id": "62leJoS03BiNzdoQmb9ByN",
    "name": "Ride Like The Wind",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/c9db65eb7d0f75d18f54964c0b3ec7f008758070",
    "artist_name": "Christopher Cross",
    "image_url": "https://i.scdn.co/image/a403b39d806923e3c2642fa81faa28812bdeb148",
    "artist_id": "695W5F2Ih8dYahLdjVOIoH",
    "year": 1980
  },
  {
    "song_id": "6MhK1gPrgtgRedEjSUz18I",
    "name": "Lost in Love",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/685b068ae8d4727b6b1f803b3c92be99043233a3",
    "artist_name": "Air Supply",
    "image_url": "https://i.scdn.co/image/0617de5b1fe4ca6d66239b7bbae0d430d5a6fbf9",
    "artist_id": "4xXCRXOfQKQ2gjWxNhNzYW",
    "year": 1980
  },
  {
    "song_id": "0q1exbzIDRxqJ6VUKH3seg",
    "name": "Sara",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/90ad12fe639deea930e3530e2fefc4e774782116",
    "artist_name": "Fleetwood Mac",
    "image_url": "https://i.scdn.co/image/dfee97010f228516e88d1caded5dfbb32a1702d7",
    "artist_id": "08GQAI4eElDnROBrJRGE0X",
    "year": 1980
  },
  {
    "song_id": "56vXknIYSyvrt1ydAfA1lZ",
    "name": "Tusk",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/69e5c9468bbc32fd0f2fa5aa669d259d53c2afb3",
    "artist_name": "Fleetwood Mac",
    "image_url": "https://i.scdn.co/image/4370f1a099f4a55cd83a7655cd92ba8eb09b91f7",
    "artist_id": "08GQAI4eElDnROBrJRGE0X",
    "year": 1980
  },
  {
    "song_id": "36eWbpGbM3DxpErvZImHgD",
    "name": "Rock with You - Single Version",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/e1ee8363bec1618d1556d8a79c7c6bdc56b6ea1c",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1980
  },
  {
    "song_id": "4qB3RW5ZifZpAHNXAn3JSf",
    "name": "You May Be Right",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/41a16a43cdda15948e946d4940bb28f2a3b32ad5",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/dc82b8076e92803f3ff7b469015f2b4540478d5c",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1980
  },
  {
    "song_id": "6uxGAruWvj6O2hVBBTNITa",
    "name": "Sexy Eyes - Single Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/f81e9b74f7f56fe8acdb3cade4d945020cb2e8f3",
    "artist_name": "Dr. Hook",
    "image_url": "https://i.scdn.co/image/452a37ffd6f07386f63f8447e08ec5db48402eea",
    "artist_id": "2Mhi3jfuRSdbVZPdjqsnnN",
    "year": 1980
  },
  {
    "song_id": "0CWD6Vq8az9trL6L5IyrIj",
    "name": "Biggest Part Of Me",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/0df370d27cf4a8332c4cd25f4c449895116fbef6",
    "artist_name": "Ambrosia",
    "image_url": "https://i.scdn.co/image/716d8376f0bbc8f9baf7b4dbe46a347546c5398c",
    "artist_id": "0J2Ej2rMQyqaUX3G6MUISw",
    "year": 1980
  },
  {
    "song_id": "6JmI8SpUHoQ4yveHLjTrko",
    "name": "Take Your Time (Do It Right)",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/1092ef73312415e779319bbcd63b32c4dd3d57c2",
    "artist_name": "The S.O.S Band",
    "image_url": "https://i.scdn.co/image/c052469293f95e40256d1ef527db90f6a7094118",
    "artist_id": "6pXCjxMOBcWtvULYkFPVW6",
    "year": 1980
  },
  {
    "song_id": "3m9viZzUHLDXp6XizY2NZq",
    "name": "Babe",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/751354126a2b1c79cc73b0a3459a04d4656434f9",
    "artist_name": "Styx",
    "image_url": "https://i.scdn.co/image/fbaa93c80441950bc77d2344fff83db911b8e3ab",
    "artist_id": "4salDzkGmfycRqNUbyBphh",
    "year": 1980
  },
  {
    "song_id": "4VO88hvgij1NzgmGpV1MPV",
    "name": "Shining Star",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/2f7ef7cb162fda9822bb9dd1542011622cf52e4c",
    "artist_name": "The Manhattans",
    "image_url": "https://i.scdn.co/image/95ed61a280a5f85da7e28ebf08609723a2ed5c64",
    "artist_id": "1DpIDwg8FGD50N9Tfunfsf",
    "year": 1980
  },
  {
    "song_id": "6OKrPnWUC8FccKiLVpxQWQ",
    "name": "Longer",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/592766b8dfb430df84018387afc8cbb757d9b0fb",
    "artist_name": "Dan Fogelberg",
    "image_url": "https://i.scdn.co/image/067a00eb7a047a7acd5bf52c05c96d429e9a7646",
    "artist_id": "0cA5Tg15TwARIRZeiNT1RO",
    "year": 1980
  },
  {
    "song_id": "0YO8KsprTrEUEvJevpI7Na",
    "name": "This Is It",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/fffb6d949cc60a5e060460f8b1eae80cef46df12",
    "artist_name": "Kenny Loggins",
    "image_url": "https://i.scdn.co/image/7b77bb68c869dfac7655097b3e0ed8263f669444",
    "artist_id": "3Y3xIwWyq5wnNHPp5gPjOW",
    "year": 1980
  },
  {
    "song_id": "5iUzEn2QzBW0KEWNQO1KQC",
    "name": "The Rose (Remastered Album Version)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/2095dd4eeccc0392d406cefd0f1a73d2c2549d3e",
    "artist_name": "Bette Midler",
    "image_url": "https://i.scdn.co/image/9f7662807ff4f98ea76fdf5caa72e544622830e8",
    "artist_id": "13y0kncDD4J9wxCyfKr10W",
    "year": 1980
  },
  {
    "song_id": "1wSPm3y4Lk6ABkVxCmwkZx",
    "name": "No More Tears (Enough Is Enough) (Duet with Donna Summer)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/55472e55b3103311ab09ba6b11094f2ddca20d3c",
    "artist_name": "Barbra Streisand",
    "image_url": "https://i.scdn.co/image/510b276f469468a39d0bd335516f7a6eec54c062",
    "artist_id": "7jmTilWYlKOuavFfmQAcu6",
    "year": 1980
  },
  {
    "song_id": "3MshXF4YQRoFw82plcsUge",
    "name": "Steal Away",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/6feddd9c3e8e4df74fb71ff206d6727230f063ba",
    "artist_name": "Robbie Dupree",
    "image_url": "https://i.scdn.co/image/bb9987208944dc33c8a384898e57405c2fdfaf06",
    "artist_id": "3jrgftS3TYbNxcPt5itKhz",
    "year": 1980
  },
  {
    "song_id": "4mcZYzoGwPRDdhWVlygiHf",
    "name": "Refugee",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/58e2702fc6245254966d5d5e941e3aaca7e94da4",
    "artist_name": "Tom Petty And The Heartbreakers",
    "image_url": "https://i.scdn.co/image/a02719a67dfe3dd9fbc45f2a0156dcb9558d8aea",
    "artist_id": "4tX2TplrkIP4v05BNC903e",
    "year": 1980
  },
  {
    "song_id": "46TLlKsmMNRQc53ukyFCtM",
    "name": "Take The Long Way Home",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/878e81c94f719e2c4b9895d0014cb8544e9a35da",
    "artist_name": "Supertramp",
    "image_url": "https://i.scdn.co/image/975a71b0686874befb9020144698605d82673651",
    "artist_id": "3JsMj0DEzyWc0VDlHuy9Bx",
    "year": 1980
  },
  {
    "song_id": "56BSwBKWHYldQOgYLHiehs",
    "name": "Magic",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/bca73394861c296ac0e6421a1fc96f470b61d887",
    "artist_name": "Olivia Newton-John",
    "image_url": "https://i.scdn.co/image/03540bcd5987f93eba0e6886727fcb1a49984850",
    "artist_id": "4BoRxUdrcgbbq1rxJvvhg9",
    "year": 1980
  },
  {
    "song_id": "5AzpZ5ADn1AFPxcEBd2Ugf",
    "name": "Don't Do Me Like That",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/79061a8028e792aa04f6f4d914ef5d025b525cdd",
    "artist_name": "Tom Petty And The Heartbreakers",
    "image_url": "https://i.scdn.co/image/a02719a67dfe3dd9fbc45f2a0156dcb9558d8aea",
    "artist_id": "4tX2TplrkIP4v05BNC903e",
    "year": 1980
  },
  {
    "song_id": "2gFnSurc2aUja58m4Jq41f",
    "name": "Do That To Me One More Time",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/ae01d21b0340209f86095200b6ad864d813d0605",
    "artist_name": "Captain & Tennille",
    "image_url": "https://i.scdn.co/image/774089276b234d9f847f8bb72ccf33cdfa2ccb87",
    "artist_id": "7BEfMxbaqx6dOpbtlEqScm",
    "year": 1980
  },
  {
    "song_id": "1mie3LbIrkousFz6jwbsaZ",
    "name": "Fame",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/67b224c38b3aeca38015b597bd020f3d942460fa",
    "artist_name": "The London Theatre Orchestra and Cast",
    "image_url": "https://i.scdn.co/image/428c31f271a608394e9733c3869bea0b5baf61a4",
    "artist_id": "4y1tSFOJMWz0abAzcds9NO",
    "year": 1980
  },
  {
    "song_id": "0qzEkwri1hE0rgxVqdOBAf",
    "name": "You're Only Lonely",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/a3681c47a9d56a1a4db419679fb6daee665c3f68",
    "artist_name": "JD Souther",
    "image_url": "https://i.scdn.co/image/99915f20d2d8c1b691f719703c7b61ab0405f979",
    "artist_id": "0I7UnRLIdCD310ZBgeuqh5",
    "year": 1980
  },
  {
    "song_id": "3Fejkexvwptt5wM7jqHj2a",
    "name": "You Decorated My Life",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/cfa79286bd91961491ebc4067da6ba29979454e8",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/82a3399b74525fc7c053488d8cd049e968c4a90f",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1980
  },
  {
    "song_id": "6eCWvDQXTITW534IANR5n1",
    "name": "Romeo's Tune",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/68aada14e03a859755927fdec956745012079773",
    "artist_name": "Steve Forbert",
    "image_url": "https://i.scdn.co/image/ed4b70a8fa6f142d86a44d46a8691b557041c042",
    "artist_id": "3oAbjWINfcTSAuVUlXXMty",
    "year": 1980
  },
  {
    "song_id": "3pbtBomO4Zt5gGiqsYeiBH",
    "name": "Upside Down",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/a5858b699f5b80559712431c0815afb2068b99bf",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/785b70218568cca1178f6412073fa1332eb68eaa",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1980
  },
  {
    "song_id": "5Huuwj6mEuAtyrH5bg6mqM",
    "name": "Tired of Toein' the Line",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/31700eda3c4c61c071b0b4e854ec9ec7dce00076",
    "artist_name": "Rocky Burnette",
    "image_url": "https://i.scdn.co/image/568382f10e2ff406193da4fd4cb032bc85f4f2ee",
    "artist_id": "6JGUkLiRAWMNYaHdUCIUvC",
    "year": 1980
  },
  {
    "song_id": "3ULXwvE0v0G5eEcSAe5h3h",
    "name": "Emotional Rescue",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/73f8694dcddcd3b67393e3133c09c0e3fbe2138f",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/6e4eed337cef4856ab2a07dfb272acfedae24e2b",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1980
  },
  {
    "song_id": "5U496ACd6O24ZGjnCHd2wQ",
    "name": "Stand By Me",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/66a30cc7262249e11adbbb5478df7c6532b8381c",
    "artist_name": "Mickey Gilley",
    "image_url": "https://i.scdn.co/image/9c83ad6c6e7f749d07a9718f8275b18580652df2",
    "artist_id": "5kFY4Bv5VRSBsM6CUYBoTC",
    "year": 1980
  },
  {
    "song_id": "4mGDtUyNxu4ZDqwMAwHh1Q",
    "name": "JoJo",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/20fcbed3f1f61cb06412668121f8697763b4a031",
    "artist_name": "Boz Scaggs",
    "image_url": "https://i.scdn.co/image/26a206f620dd2bb9ccc037ca915b55268f0e51fc",
    "artist_id": "46njgd2Rq9tZc4ZjeQMgbh",
    "year": 1980
  },
  {
    "song_id": "4hNKm844QktyaKubGM8Ggk",
    "name": "Crazy Little Thing Called Love - Remastered",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/027405edad0264af4600de806fdc3acb058689a1",
    "artist_name": "Queen",
    "image_url": "https://i.scdn.co/image/f427034ed40cf5265c1a7b67c777be4695338bb8",
    "artist_id": "1dfeR4HaWDbWqFHLkxsg1d",
    "year": 1980
  },
  {
    "song_id": "0YgmjGr4XDahHhiVlX2Gya",
    "name": "Don't Fall In Love With A Dreamer - feat. Kim Carnes",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/ded9038de89d47402c1de40950336bdd84cd4e33",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/82a3399b74525fc7c053488d8cd049e968c4a90f",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1980
  },
  {
    "song_id": "2RwLs2dPoY8Tt1FumMvimx",
    "name": "Better Love Next Time",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/da15da22dfc9f11190e796ef52894d803b940fdb",
    "artist_name": "Dr. Hook",
    "image_url": "https://i.scdn.co/image/452a37ffd6f07386f63f8447e08ec5db48402eea",
    "artist_id": "2Mhi3jfuRSdbVZPdjqsnnN",
    "year": 1980
  },
  {
    "song_id": "7wlPnBfcywEctiVWwCdxvn",
    "name": "With You I'm Born Again",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/7c3558b9c30d17f3f1db8651a51d7e61dde2fc43",
    "artist_name": "Billy Preston",
    "image_url": "https://i.scdn.co/image/a7c8bcca3bd59f9ca491cf8db9d35f20a1159ec8",
    "artist_id": "0IecGJbdBeYSOVtSPRehh5",
    "year": 1980
  },
  {
    "song_id": "5CoYwKhisukni6UEDXc2Pr",
    "name": "She's out of My Life - Single Version",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/4dba613b07daf8edb5c466dc3ba1ca8373a7d1a1",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1980
  },
  {
    "song_id": "1p2qCWx9dM74mPIv8Vnm2f",
    "name": "Coming Up - 2011 Remaster",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/47b5af5e489f0fe8e94774a4792cf304d2c67e17",
    "artist_name": "Paul McCartney",
    "image_url": "https://i.scdn.co/image/c3e74569edecaaa1191db5ba457dd853c0c8652d",
    "artist_id": "4STHEaNw4mPZ2tzheohgXB",
    "year": 1980
  },
  {
    "song_id": "5eLACghlJo5ncOG7Ym7L5j",
    "name": "Coward Of The County - 2006 Digital Remaster",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/712400a26c67c74a91125fd02f3022ff4c4746c1",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/636c74debc3869696ab55d91041a0c88f2ac29e4",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1980
  },
  {
    "song_id": "5rMthFHPxRDi4Uxg06iK6H",
    "name": "Him",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/9e88f8ed363c63198b5b51eea0c9bd0ec4968fe2",
    "artist_name": "Rupert Holmes",
    "image_url": "https://i.scdn.co/image/1de2d16e21a613b1e7c75e0e99e374e8a4e65fd7",
    "artist_id": "0TqIPD4IS1w4e30R38B3vj",
    "year": 1980
  },
  {
    "song_id": "4lFUpqyXFvKL3jkNNnAte2",
    "name": "Cool Change",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/a409d0aad38b2f22eeddff85b0ee72e27d824cbf",
    "artist_name": "Little River Band",
    "image_url": "https://i.scdn.co/image/8c510f91c903e0af53d99a3a7f5640a99bf56fc4",
    "artist_id": "6clbbhnIqpHnqxwtOWcilg",
    "year": 1980
  },
  {
    "song_id": "5n9zTTk1SwXD4jMIAHiDTI",
    "name": "In America",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/0794f2c13550ad12a4aa09dbb796b5301bab1b4f",
    "artist_name": "Charlie Daniels",
    "image_url": "https://i.scdn.co/image/2045e0d6ed6a4e1aaececa7663de4ebc545a9204",
    "artist_id": "6UpFUXmXvDV7Qj1SPymamh",
    "year": 1980
  },
  {
    "song_id": "6g8WWi9euV5uIjzsOfHRAo",
    "name": "Wait For Me - Remastered",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/ef7ae5f3963d2e499f9932403f7a70a674b884ea",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/acf37eb470cb91b144527bbf0192699f91610f72",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1980
  },
  {
    "song_id": "12bmwuAgbMVSmLPGJt10Ki",
    "name": "Cruisin'",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/42d4a228ab31ae7f635b6226266f5205e713fcb3",
    "artist_name": "Smokey Robinson",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "0h9smro0z3HqUbD94jotU8",
    "year": 1980
  },
  {
    "song_id": "570KJytRkVHTIocq1qomWF",
    "name": "Special Lady",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/6335ad62229f6bab99461d1fe1da3a53181ec01b",
    "artist_name": "Ray, Goodman & Brown",
    "image_url": "https://i.scdn.co/image/8c41b858a91e98d5aeabd5c5261a538fde5f73ff",
    "artist_id": "58U0OwuIViimy39WEbRbXh",
    "year": 1980
  },
  {
    "song_id": "6vOMU2u38Z9Wru3CqPxcKs",
    "name": "Let Me Love You Tonight",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/e0c7688e1526f300b4a59c32ee018fe963464939",
    "artist_name": "Pure Prairie League",
    "image_url": "https://i.scdn.co/image/e1f0ba77491066dc6bddcaa9f67ee24afcaf45ba",
    "artist_id": "1MXwwmS2JpmsHZhdMeOL2s",
    "year": 1980
  },
  {
    "song_id": "1wouF9mtyiwZDYiq5YElaq",
    "name": "Working My Way Back To You/Forgive Me, Girl",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/c73215fafabbe587957e21833f71526038fbfcb9",
    "artist_name": "The Spinners",
    "image_url": "https://i.scdn.co/image/5b73276c35372f441aeaef14c780afce485c006d",
    "artist_id": "5fbhwqYYh4YwUoEs582mq5",
    "year": 1980
  },
  {
    "song_id": "4SRAf6doXKEuMJxrHB8ILC",
    "name": "More Love",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/b967c728555c8d843648c2518d7c6a60d8cbe4f5",
    "artist_name": "Kim Carnes",
    "image_url": "https://i.scdn.co/image/0642074c9d786b6be747c7116ce821d3415a3b75",
    "artist_id": "5PN2aHIvLEM98XIorsPMhE",
    "year": 1980
  },
  {
    "song_id": "2JPWiYKyPM9ST26TpKAeYd",
    "name": "Misunderstanding - 2007 Remastered",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/74b08544903ac210b6baf8fda05caf28dacbbbfc",
    "artist_name": "Genesis",
    "image_url": "https://i.scdn.co/image/13c35a3a9c014cb4a2d3d5cac9e59ee605ca11f6",
    "artist_id": "3CkvROUTQ6nRi9yQOcsB50",
    "year": 1980
  },
  {
    "song_id": "2z6xZrvwnxDzmARpDlvbyt",
    "name": "Hurt So Bad",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/27dc42713e6bf727260b6a6eaaff090ca7838644",
    "artist_name": "Linda Ronstadt",
    "image_url": "https://i.scdn.co/image/b7d36da0d14bb551c7b371298e29c3e7b19c1ea1",
    "artist_id": "1sXbwvCQLGZnaH0Jp2HTVc",
    "year": 1980
  },
  {
    "song_id": "2dA6KnSEgtUZO5lZa1QsIy",
    "name": "Send One Your Love",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/b828d2a592c5f1974fe5a204580be70ad78533ff",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/2e39ec0785de1df6619e3fae8c41ec67c416a993",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1980
  },
  {
    "song_id": "5CQCYZ0SKfpVZHKL5HVixK",
    "name": "Desire",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/f3ae433c158917419b65ed844c006c8e26f1c0c6",
    "artist_name": "Andy Gibb",
    "image_url": "https://i.scdn.co/image/fadfe2865b5e6858867b9cb00fff26520a6b9ea1",
    "artist_id": "4YPqbAiLzBg5DIfsgQZ8QK",
    "year": 1980
  },
  {
    "song_id": "39NR6IvhKs7JXOucucP19u",
    "name": "I Pledge My Love",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/fbf878e9dd2918558b124bc437c3ada977f780ab",
    "artist_name": "Peaches & Herb",
    "image_url": "https://i.scdn.co/image/36a3b1dd6ae4167305fdb8866b49736ca9b7f293",
    "artist_id": "6qI4LTzMRpTxRzMZPvv2C6",
    "year": 1980
  },
  {
    "song_id": "6e3z6ytfhTEUMRxdCYlgAV",
    "name": "Deja Vu",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/f6321977e7260517949bae4dc0c6aab7ffc201cc",
    "artist_name": "Dionne Warwick",
    "image_url": "https://i.scdn.co/image/745d56894a4de74512a7c73f82d46c31f84c299e",
    "artist_id": "2JSjCHK79gdaiPWdKiNUNp",
    "year": 1980
  },
  {
    "song_id": "4vT4qrrHHuDJO9E0AT4sgv",
    "name": "Ships",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/9f11db5f8a7acde9370903e8622e607707312733",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/8184f45937baed4ec717f3558e391e99b0c5df30",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1980
  },
  {
    "song_id": "5FvXMfGmOvqbkEYRtcKUzE",
    "name": "Breakdown Dead Ahead",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/893582ccdffeb482916a4f4c478c1137bb3a8e59",
    "artist_name": "Boz Scaggs",
    "image_url": "https://i.scdn.co/image/dc1a42c2e175a9c3d7897006015e25c60a70f360",
    "artist_id": "46njgd2Rq9tZc4ZjeQMgbh",
    "year": 1980
  },
  {
    "song_id": "2jBHUDhBLIDKHZZhwl1ymh",
    "name": "Funkytown - Single Version",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/b07ab5766c91a3efc4f2eb1de7ef41ba3ffecbf4",
    "artist_name": "Lipps Inc.",
    "image_url": "https://i.scdn.co/image/9769c9fb686af4ab33587deaa8d6c20a17f8989f",
    "artist_id": "0lwRI7lvmlRY5DiA5Xa6wQ",
    "year": 1980
  },
  {
    "song_id": "2pjbLGZLKcaf1nc78KfbWH",
    "name": "We Don't Talk Anymore - 1987 Digital Remaster",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/4006d65eaad4480a0ff05334ea3c0b4de209144d",
    "artist_name": "Cliff Richard",
    "image_url": "https://i.scdn.co/image/1de813a0f94b53f323d429d608c7a80cff4804c5",
    "artist_id": "2nvKpWcP8etYTq4JrRiUiy",
    "year": 1980
  },
  {
    "song_id": "60K5cjp5Kv2MRJw1IisoMh",
    "name": "Still",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/33cdf05abaf84fe0b6881556d93da7954f65e0ae",
    "artist_name": "Commodores",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "6twIAGnYuIT1pncMAsXnEm",
    "year": 1980
  },
  {
    "song_id": "2WX2HSBKkcKVvqTukHZS22",
    "name": "How Do I Make You",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/f22baf9e007cff4d4423d6597027b27211228f9d",
    "artist_name": "Linda Ronstadt",
    "image_url": "https://i.scdn.co/image/9e9e0b16dfc671ab58a58cff8c76100fa7bfec8e",
    "artist_id": "1sXbwvCQLGZnaH0Jp2HTVc",
    "year": 1980
  },
  {
    "song_id": "3csommQJ7ozkkPlmuYSFnV",
    "name": "Don't Let Go",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/840b24989a6a914126192a9e95e8232d61d79341",
    "artist_name": "Isaac Hayes",
    "image_url": "https://i.scdn.co/image/b4917c485b500be5cd748b458eb3597cd90d0137",
    "artist_id": "3IKV7o6WPphDB7cCWXaG3E",
    "year": 1980
  },
  {
    "song_id": "1TPjE0SvG3y7Xz3VNzDFXq",
    "name": "An American Dream - 2001 - Remaster",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/8c5c5e4c537797b22e38cf55188f275710a4cfd6",
    "artist_name": "Nitty Gritty Dirt Band",
    "image_url": "https://i.scdn.co/image/1abde4d9eaa49c5a8ec636e96d13715b093e9ff7",
    "artist_id": "7y70dch6JuuuNnwlsOQvwW",
    "year": 1980
  },
  {
    "song_id": "19bw9o5MbljyyOm6tXKt8L",
    "name": "Should've Never Let You Go (With Dara Sedaka)",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/49d280a995be291c3b5aa44d5bf73b4fb170a81e",
    "artist_name": "Neil Sedaka",
    "image_url": "https://i.scdn.co/image/3ef92fac61dedbd5999ab4b3f8fa64d07cf2a813",
    "artist_id": "5N6GwJzOcOY5kv8p0NjhYL",
    "year": 1980
  },
  {
    "song_id": "7MHboIoaWgIknMRRJylLzP",
    "name": "Pilot Of The Airwaves (Re-Recorded / Remastered)",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/34eaa3508f75cbdace88127a6dc2756b056bb6b8",
    "artist_name": "Charlie Dore",
    "image_url": "https://i.scdn.co/image/3aface3b0aeb5d20e27a653ad18a52044dcf1c87",
    "artist_id": "5rWIUjXLlUngm23tFkNs7Z",
    "year": 1980
  },
  {
    "song_id": "5z3LawThM0b0jiH5RNuDml",
    "name": "Cupid/I've Loved You For A Long Time",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/7f770de42e43f5f7e9856b30b0b1b7b44506ff2a",
    "artist_name": "The Spinners",
    "image_url": "https://i.scdn.co/image/5b73276c35372f441aeaef14c780afce485c006d",
    "artist_id": "5fbhwqYYh4YwUoEs582mq5",
    "year": 1980
  },
  {
    "song_id": "5lN4eoE9AKy2cdw25P1tKg",
    "name": "Dim All The Lights",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/fa16dd7f951e44de64c70e38eed05ce1393a0ef5",
    "artist_name": "Donna Summer",
    "image_url": "https://i.scdn.co/image/af5df6fca4826ae8faf52f3a63a88258298d96fc",
    "artist_id": "2eogQKWWoohI3BSnoG7E2U",
    "year": 1980
  },
  {
    "song_id": "2qYbfFYuCavdNuLZrn0PWh",
    "name": "On The Radio",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/367669bd468628d2150823f3e0e7150dc635d02a",
    "artist_name": "Donna Summer",
    "image_url": "https://i.scdn.co/image/2683638d681ef6b3182be87cc6bc33c556f68fba",
    "artist_id": "2eogQKWWoohI3BSnoG7E2U",
    "year": 1980
  },
  {
    "song_id": "6Qyd8TE9edKso0Sb2yl0qp",
    "name": "Yes/ I'm Ready",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/d0f402c2612a0f72f38bb3ecdfc71464eb5a5246",
    "artist_name": "The Hit Crew",
    "image_url": "https://i.scdn.co/image/be4b745e38ea871587ef39816a221e2cf4583da2",
    "artist_id": "76PJKS3IQsf4sSayx2taE0",
    "year": 1980
  },
  {
    "song_id": "0Jk265z1cOt9V5ba8nw4hQ",
    "name": "Brass In Pocket",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/f150a6b2615fdde7971bcc0fe810e49ee06bc69f",
    "artist_name": "Pop Feast",
    "image_url": "https://i.scdn.co/image/f879efbca2219c9a67443e32ed77d82d5cc5d25a",
    "artist_id": "3sXAMi1qC42XowegNum212",
    "year": 1980
  },
  {
    "song_id": "57JVGBtBLCfHw2muk5416J",
    "name": "Another One Bites The Dust",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/1f1960c52c6418aa8565b3ca3968c7354fcd2e89",
    "artist_name": "Queen",
    "image_url": "https://i.scdn.co/image/42edb5d6155a64452b31a905e905620fb40702c6",
    "artist_id": "1dfeR4HaWDbWqFHLkxsg1d",
    "year": 1981
  },
  {
    "song_id": "1sgfjgLRCqSfndpofU8T2C",
    "name": "Jessie's Girl",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/8d838de2b6a22a65a5d804896b859aadf1376d17",
    "artist_name": "Rick Springfield",
    "image_url": "https://i.scdn.co/image/cbdedb36f7ba3f8a0be56e4bb367337ee7e4a6d1",
    "artist_id": "6IFXsrXBpwbIqtOUOiAa3p",
    "year": 1981
  },
  {
    "song_id": "0kPeymTUiaidv48eRrMISu",
    "name": "Bette Davis Eyes",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/539ef367a50e6e71836835015e2c0ecc6f635528",
    "artist_name": "Kim Carnes",
    "image_url": "https://i.scdn.co/image/ae68adbf74357c4dd367f137f3133ea9dfaba1c8",
    "artist_id": "5PN2aHIvLEM98XIorsPMhE",
    "year": 1981
  },
  {
    "song_id": "4Osk9Et3K5CmdobjJ30ycE",
    "name": "Hungry Heart",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/7430a8278eccb2c7a2deb7294e8455d1589d60d7",
    "artist_name": "Bruce Springsteen",
    "image_url": "https://i.scdn.co/image/5736647ed520730ef1de5a59b74caa55e382ab0e",
    "artist_id": "3eqjTLE0HfPfh78zjh6TqT",
    "year": 1981
  },
  {
    "song_id": "7nrtHjt4Vk4Z5jMHOwaS8F",
    "name": "Hit Me With Your Best Shot - 1999 Digital Remaster",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/9ebb47ac82d90a6c559cddcfff6cd01c7eb85fdd",
    "artist_name": "Pat Benatar",
    "image_url": "https://i.scdn.co/image/4d5c3dce3fe247d8c1417d97273a41eea8529cc3",
    "artist_id": "43mhFhQ4JAknA7Ik1bOZuV",
    "year": 1981
  },
  {
    "song_id": "4GBPZmnLHEMdthyo2RDolF",
    "name": "Endless Love",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/b543d9c78ba7a9f6559dbbd4b2559efd771f7d3a",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/44d34f31486f1176f22ca596614f68b5150e255e",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1981
  },
  {
    "song_id": "2m5xo4HEFHgOQpqqrxCS5u",
    "name": "Woman In Love",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/aa8b2c8d8b400edb1bac639a98a60af092b60daa",
    "artist_name": "Barbra Streisand",
    "image_url": "https://i.scdn.co/image/510b276f469468a39d0bd335516f7a6eec54c062",
    "artist_id": "7jmTilWYlKOuavFfmQAcu6",
    "year": 1981
  },
  {
    "song_id": "4sscDOZCkbLSlDqcCgUJnX",
    "name": "Whip It",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/817d9378f2d2f1774f0947913300755c7c9f7708",
    "artist_name": "Devo",
    "image_url": "https://i.scdn.co/image/3b4bc818598ded1f80020692da35684a15653ed0",
    "artist_id": "0UKfenbZb15sqhfPC6zbt3",
    "year": 1981
  },
  {
    "song_id": "5fnOrhQ4KgT5irGCjIccGH",
    "name": "Arthur's Theme (Best That You Can Do) - Remastered",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/8d4083df1f0af7ed0767eeb6e570dd23d71a6a14",
    "artist_name": "Christopher Cross",
    "image_url": "https://i.scdn.co/image/f26d8aca4564634972866056c02728493ed720f4",
    "artist_id": "695W5F2Ih8dYahLdjVOIoH",
    "year": 1981
  },
  {
    "song_id": "6CEKntwndUlmWhSRflWA5X",
    "name": "Morning Train (Nine to Five)",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/a1b0130b9e3644b0aa4cc30e5827299f29eb0320",
    "artist_name": "Sheena Easton",
    "image_url": "https://i.scdn.co/image/5c3e8b2eabdbbae43c3de9da7d490d407212e465",
    "artist_id": "5dcOK4stT4JDkP6Dqhbz5s",
    "year": 1981
  },
  {
    "song_id": "0OSNeMv5ik3W4NP401qRzz",
    "name": "Don't Stand So Close To Me - 2003 Stereo Remastered Version",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/13ae3ce5c1e1bab02820ae60fd237154cf383d3a",
    "artist_name": "The Police",
    "image_url": "https://i.scdn.co/image/cf51cebf7800df26591f31de32b19926cb8e9523",
    "artist_id": "5NGO30tJxFlKixkPSgXcFE",
    "year": 1981
  },
  {
    "song_id": "4Wljbzc4Zg0Vxv4QAM3tYB",
    "name": "The Tide Is High",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/f6ea0367e70fccd7421225ef467c7ef031f9e5d5",
    "artist_name": "Blondie",
    "image_url": "https://i.scdn.co/image/046eea53d5bddfba0786743f875b10ffec32a4e5",
    "artist_id": "4tpUmLEVLCGFr93o8hFFIB",
    "year": 1981
  },
  {
    "song_id": "0zvNHIL7ci0ZzIoWU6RO2u",
    "name": "I Love A Rainy Night",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/e241158b8121aa3c01b7c8b4aec0b2be3bf969c1",
    "artist_name": "Eddie Rabbitt",
    "image_url": "https://i.scdn.co/image/c521be470b35c2a47c65427b3da2c3d11d40610c",
    "artist_id": "0jgAONnsHxrwAlhkMUVS78",
    "year": 1981
  },
  {
    "song_id": "11Uatd4av1y0YD2x0mQvao",
    "name": "Who's Crying Now",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/9cbc87091db68c772c6b1b79fa9e2118ea6557fb",
    "artist_name": "Journey",
    "image_url": "https://i.scdn.co/image/63e47b9e1420e2effd4d4537c464c27e6e598f2e",
    "artist_id": "0rvjqX7ttXeg3mTy8Xscbt",
    "year": 1981
  },
  {
    "song_id": "3mI9Z256VhQI6VAHRxFqUf",
    "name": "Every Woman in the World",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/aa21aec0a5b332095266b1a14d886201662e14af",
    "artist_name": "Air Supply",
    "image_url": "https://i.scdn.co/image/0617de5b1fe4ca6d66239b7bbae0d430d5a6fbf9",
    "artist_id": "4xXCRXOfQKQ2gjWxNhNzYW",
    "year": 1981
  },
  {
    "song_id": "4w3tQBXhn5345eUXDGBWZG",
    "name": "9 to 5",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/3a728e19abc28bc88d4b100f7316695364bc0cb2",
    "artist_name": "Dolly Parton",
    "image_url": "https://i.scdn.co/image/a06c4cc06d9ca95a88f74c892ff23467dca36c0f",
    "artist_id": "32vWCbZh0xZ4o9gkz4PsEU",
    "year": 1981
  },
  {
    "song_id": "7uMo3OexImQML2f1XwcypW",
    "name": "Kiss on My List - Remastered",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/d9f06f78b27b69264e29ef0b84839adf7389b631",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/82216e16eff844df7916f5024dd7041403b6c82a",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1981
  },
  {
    "song_id": "5dNDiy7xE4Qa2sJDoEXPbH",
    "name": "Hey Nineteen",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/fa672e486e7caf46c5ebeaaf20743a7003d1ad1b",
    "artist_name": "Steely Dan",
    "image_url": "https://i.scdn.co/image/a3d43bc0056ab85b18603dc42e12a618ed712493",
    "artist_id": "6P7H3ai06vU1sGvdpBwDmE",
    "year": 1981
  },
  {
    "song_id": "395Wu0KoZvSFdEKbFAq1mf",
    "name": "Too Much Time On My Hands",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/bfceab0ce307e7a29d926150239d2534858cff7d",
    "artist_name": "Styx",
    "image_url": "https://i.scdn.co/image/fbaa93c80441950bc77d2344fff83db911b8e3ab",
    "artist_id": "4salDzkGmfycRqNUbyBphh",
    "year": 1981
  },
  {
    "song_id": "0woCEywARpK9rZvyaEDimi",
    "name": "Slow Hand",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/2a73c85597740d812507b362e460981d0eb38ba1",
    "artist_name": "The Pointer Sisters",
    "image_url": "https://i.scdn.co/image/fe2b16ccd01db6f2235edb7e99965507ea727a58",
    "artist_id": "2kreKea2n96dXjcyAU9j5N",
    "year": 1981
  },
  {
    "song_id": "2dlEdDEmuQsrcXaAL3Znzi",
    "name": "You Make My Dreams - Remastered",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/a0f66d889988cb29f956d4007aba26e3af980451",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/82216e16eff844df7916f5024dd7041403b6c82a",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1981
  },
  {
    "song_id": "716N93ncOc2IeLpOzctGPj",
    "name": "Master Blaster (Jammin')",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/373fcb6e65d3cd900b4d23ee3577c5a0161c6717",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/6476d80646a3ac86c61b9e2263ead819b2b6194b",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1981
  },
  {
    "song_id": "3LlWpVyPuoC8DIEDOZ2G72",
    "name": "The One That You Love",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/584434a33749c82581c54ac63cf046ee6c238194",
    "artist_name": "Air Supply",
    "image_url": "https://i.scdn.co/image/0617de5b1fe4ca6d66239b7bbae0d430d5a6fbf9",
    "artist_id": "4xXCRXOfQKQ2gjWxNhNzYW",
    "year": 1981
  },
  {
    "song_id": "6F2vo4sxRNQ58VYe3pdiaL",
    "name": "Rapture",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/0baabf83a7695b0174eab3032eb2cc2826d60e04",
    "artist_name": "Blondie",
    "image_url": "https://i.scdn.co/image/046eea53d5bddfba0786743f875b10ffec32a4e5",
    "artist_id": "4tpUmLEVLCGFr93o8hFFIB",
    "year": 1981
  },
  {
    "song_id": "4Vl4sf0EljY8GxAyjqJD6p",
    "name": "Same Old Lang Syne",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/74e34c6f6b573cdf0cf6a1d956c3109564392209",
    "artist_name": "Dan Fogelberg",
    "image_url": "https://i.scdn.co/image/067a00eb7a047a7acd5bf52c05c96d429e9a7646",
    "artist_id": "0cA5Tg15TwARIRZeiNT1RO",
    "year": 1981
  },
  {
    "song_id": "3Tu6LdL6AhwJFxSuXFMLIt",
    "name": "De Do Do Do, De Da Da Da - 2003 Stereo Remastered Version",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/43312b0b6d8bd4001857d158eb92e979bf9f6127",
    "artist_name": "The Police",
    "image_url": "https://i.scdn.co/image/cf51cebf7800df26591f31de32b19926cb8e9523",
    "artist_id": "5NGO30tJxFlKixkPSgXcFE",
    "year": 1981
  },
  {
    "song_id": "3cyAoKvbHmoOjp0Ww8mlB1",
    "name": "The Winner Takes It All",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/5d557dc81ceffed4684590ea77fc61062134f1bb",
    "artist_name": "ABBA",
    "image_url": "https://i.scdn.co/image/95407d06a9c68da67a07ae1c884199b8da2a36a2",
    "artist_id": "0LcJLqbBmaGUft1e9Mm8HV",
    "year": 1981
  },
  {
    "song_id": "2c2DZHcSLqfCfGEkRMVt1T",
    "name": "Queen Of Hearts",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/8ed39b1d5b4dfee1872d7945591bf1103d303d5a",
    "artist_name": "Juice Newton",
    "image_url": "https://i.scdn.co/image/624dcf2c03691e8dea6689552fc9a794bce89ffa",
    "artist_id": "4L1z1IcfK7lbqx8izGHaw5",
    "year": 1981
  },
  {
    "song_id": "0Jtp6dLT78aWIdnoteCSxv",
    "name": "Smoky Mountain Rain",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/d23b6a9c6e759a416c998a9aabeaf44eba957206",
    "artist_name": "Ronnie Milsap",
    "image_url": "https://i.scdn.co/image/4ac5100e4c4f62217c16447738c5eabf148e753a",
    "artist_id": "6Sb8EHpZvyjSTT5KD9DjUZ",
    "year": 1981
  },
  {
    "song_id": "0mG66FLhO0iZ6XCAGdjpax",
    "name": "Elvira",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/2c1dcb96f173576c3637d48f40e39901bf8fef5e",
    "artist_name": "The Oak Ridge Boys",
    "image_url": "https://i.scdn.co/image/55422adb4f08ea50ce1ed8f0010e2f6ce55d07e2",
    "artist_id": "3XnO697XIus1M0cMuxZjos",
    "year": 1981
  },
  {
    "song_id": "60mGckXEG1EzR4VmjYLfEW",
    "name": "Guilty (Duet with Barry Gibb)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/01db4c45bd86d7c49fa1e54202a05bc8ce6ec8f3",
    "artist_name": "Barbra Streisand",
    "image_url": "https://i.scdn.co/image/311eef499f07d4fa1d4016f8cda3425e421da73c",
    "artist_id": "7jmTilWYlKOuavFfmQAcu6",
    "year": 1981
  },
  {
    "song_id": "375ntCG12wzCyfzclwYFwG",
    "name": "Theme From \"Greatest American Hero\" (Believe It Or Not)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/e84020621bec478b50e34294f48b635d5ffa0faa",
    "artist_name": "Joey Scarbury",
    "image_url": "https://i.scdn.co/image/aebc5b28243b4c4e3b70868a8c9fed438ea75615",
    "artist_id": "5V9pNqcN25JXuH88g7lq2o",
    "year": 1981
  },
  {
    "song_id": "2Go3GgCcMjtZVdLUo2CI63",
    "name": "This Little Girl",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/0a4b8da9637ddbe59162d5ba5f81a6ad8837569b",
    "artist_name": "Gary U.S. Bonds",
    "image_url": "https://i.scdn.co/image/f71ff41b22b4f3ca899f70dc2348b435ebdc3401",
    "artist_id": "1Qw8MHpjYxm9Xf0O1ZfPiX",
    "year": 1981
  },
  {
    "song_id": "3oL9W9aHlMfCyd1dEBh87L",
    "name": "Crying",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/568f60c7faaacca838ada7b08bae056ad6c284d7",
    "artist_name": "Don McLean",
    "image_url": "https://i.scdn.co/image/7e50d9cd22c51f7496071ca1b346d157e7dc89e2",
    "artist_id": "1gRNBaI4yn6wCCTvRhGWh8",
    "year": 1981
  },
  {
    "song_id": "2eo9jNvb17mk9ppeb2CcmS",
    "name": "The Best Of Times",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/277d3d16960a266e08df6789e315882079ca8693",
    "artist_name": "Styx",
    "image_url": "https://i.scdn.co/image/fbaa93c80441950bc77d2344fff83db911b8e3ab",
    "artist_id": "4salDzkGmfycRqNUbyBphh",
    "year": 1981
  },
  {
    "song_id": "2gQsUHVFY4tpXc3AFU2mcS",
    "name": "Games People Play",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/7917491c82be005c11ab227a8507c4b71e8907ea",
    "artist_name": "The Alan Parsons Project",
    "image_url": "https://i.scdn.co/image/8488f7522b1bf76417c7807fb467819f4f13263e",
    "artist_id": "2m62cc253Xvd9qYQ8d2X3d",
    "year": 1981
  },
  {
    "song_id": "6qLfdfOdfrpD1B0V67eJpy",
    "name": "What Kind Of Fool (Duet With Barry Gibb)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/06307d2654beace9297929b84d110c0e69c15671",
    "artist_name": "Barbra Streisand",
    "image_url": "https://i.scdn.co/image/311eef499f07d4fa1d4016f8cda3425e421da73c",
    "artist_id": "7jmTilWYlKOuavFfmQAcu6",
    "year": 1981
  },
  {
    "song_id": "3VyJPsb2ucaaVptLSDpJNH",
    "name": "I Love You",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/f7a9dee4d83d6cd9867d4e439bfb0b02833f4f3d",
    "artist_name": "Climax Blues Band",
    "image_url": "https://i.scdn.co/image/f429a1271cf4010a8f3d47efcb7eaeaea3ab1bd3",
    "artist_id": "4eeRviM714mXXeILmKHxh2",
    "year": 1981
  },
  {
    "song_id": "6v0WLvjyQT398T4C0eMmKi",
    "name": "Stop Draggin' My Heart Around",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/3fcbabce2c414f410d9c4c21d6702603abf103f6",
    "artist_name": "Stevie Nicks w/ Tom Petty and the Heartbreakers",
    "image_url": "https://i.scdn.co/image/46b138cab0e9e05aea5db03d24b7889e7f39ce63",
    "artist_id": "6SX6mQf6m1ptQ7Pcwu9qta",
    "year": 1981
  },
  {
    "song_id": "6QkR93RevDDOxHJsndq2HF",
    "name": "Urgent (2008 Remastered LP Version)",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/77090ba4ccd4a014f9bf0911f404999831472625",
    "artist_name": "Foreigner",
    "image_url": "https://i.scdn.co/image/27c9e093bd5b9817deb910b41e9094388a3f2962",
    "artist_id": "6IRouO5mvvfcyxtPDKMYFN",
    "year": 1981
  },
  {
    "song_id": "1Fml6T3bfSiaVruPRojYsj",
    "name": "Ain't Even Done With The Night",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/7f56797b81122acc893e1b61eeaa74d3b0748003",
    "artist_name": "John Mellencamp",
    "image_url": "https://i.scdn.co/image/2252c94914f263d66846123192aae9ce9ade7082",
    "artist_id": "3lPQ2Fk5JOwGWAF3ORFCqH",
    "year": 1981
  },
  {
    "song_id": "17M1JFSr2eEvo5dQnCIQim",
    "name": "More Than I Can Say - Remastered",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/2e629b52f8ed0f87def2598195d04f64426c2bf0",
    "artist_name": "Leo Sayer",
    "image_url": "https://i.scdn.co/image/3656e827009764a948da3492822fea3185647096",
    "artist_id": "04LIHk1SobiQwt2tlupoAV",
    "year": 1981
  },
  {
    "song_id": "0uKQ7Z6padJdo92MFpLrOc",
    "name": "The Breakup Song (They Don't Write 'em) [In the Style of Greg Kihn Band] {Performance Track with Demonstration Vocals} - Single",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/47a85f65e9d13e8ba485a9f749396a1058f110cb",
    "artist_name": "Done Again",
    "image_url": "https://i.scdn.co/image/765b03b47779029119e635be8f1c19c1be1338f7",
    "artist_id": "0GQkTwFb7D3ePIpnxwYavf",
    "year": 1981
  },
  {
    "song_id": "5gys5nzVQIYhgHIfiOJYva",
    "name": "Take It On The Run - 2011 Remaster",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/952a15e6cf299970219222bc1076b6843dc6fb9d",
    "artist_name": "REO Speedwagon",
    "image_url": "https://i.scdn.co/image/d24ed2ead27ee6a1215ee76e010e2dc10a56f64c",
    "artist_id": "55vs7NT1KxcFjbMC4y202E",
    "year": 1981
  },
  {
    "song_id": "38705PDjZZVeWxfTRsTlJr",
    "name": "Sweet Baby",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/8be8b93a9859f91c680ba7fb3588b46f7ee06d7e",
    "artist_name": "George Duke",
    "image_url": "https://i.scdn.co/image/488e7789421d0d0a7927f1b4bd22c273dac50c98",
    "artist_id": "3EB0uKE2lGw6BB1UFJrONl",
    "year": 1981
  },
  {
    "song_id": "6xFTqbFZsFihHoghoxNi3O",
    "name": "You've Lost That Lovin' Feeling - Remastered 2003",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/55bfd4cf1775308b27fec3656b9d65f5e29e34af",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/82216e16eff844df7916f5024dd7041403b6c82a",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1981
  },
  {
    "song_id": "2o5l8jEUf7wqXClIgTqMYF",
    "name": "Hold on Tight",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/dec8387abf01d99dc574bc506f4b6294e13238b3",
    "artist_name": "Electric Light Orchestra",
    "image_url": "https://i.scdn.co/image/51740f72188def866bbc29ea6cbba8ba936f8894",
    "artist_id": "7jefIIksOi1EazgRTfW2Pk",
    "year": 1981
  },
  {
    "song_id": "1OedyDKqObLpluvjUiHaLW",
    "name": "Together",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/8b15ea94f106427c3e1a3138f3c1653fed506dfd",
    "artist_name": "Tierra",
    "image_url": "https://i.scdn.co/image/666259ef102c97e05843a918715f75079af6a1ee",
    "artist_id": "2tfiUsmHWz3xi3BSBpyz6A",
    "year": 1981
  },
  {
    "song_id": "4i6z1rYTzkLV6nkO0q54VJ",
    "name": "Boy From New York City",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/fe65e21bc0b060e9e80785094e6ff34a7de0abd0",
    "artist_name": "The Manhattan Transfer",
    "image_url": "https://i.scdn.co/image/70ddb942c3230b15e4544b488857cb781c62b8b9",
    "artist_id": "2dogRElUKV20C2khRHqjRc",
    "year": 1981
  },
  {
    "song_id": "6PNGlVBS65UVMb78YO6ABc",
    "name": "Living Inside Myself",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/36e8e6210cbf09ddc26dd198b72ed174e0d1372d",
    "artist_name": "Gino Vannelli",
    "image_url": "https://i.scdn.co/image/bad89d2850af84ae5b9220c895c6507ecb41b7da",
    "artist_id": "6ltcwvni6HdZAJaWhmIvNR",
    "year": 1981
  },
  {
    "song_id": "6x2s7WILWBqUhGc9AQ9A35",
    "name": "Angel Of The Morning",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/f99f8d23930160dff9564fb423efe9f598348f97",
    "artist_name": "Juice Newton",
    "image_url": "https://i.scdn.co/image/624dcf2c03691e8dea6689552fc9a794bce89ffa",
    "artist_id": "4L1z1IcfK7lbqx8izGHaw5",
    "year": 1981
  },
  {
    "song_id": "150LOyOS0ISsZ9IcwPf6iT",
    "name": "Hearts",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/078270126c744b652609a3ed6f32cd0c644779f6",
    "artist_name": "Marty Balin",
    "image_url": "https://i.scdn.co/image/7fe51bfc931d77b79db9b6c5932e5914d5c9a736",
    "artist_id": "1LPqJnojL09mceIxcFwXLx",
    "year": 1981
  },
  {
    "song_id": "49HfSdzgsXmzbZPMZ560Xn",
    "name": "Step By Step",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/97e33c4826d0d26f523f179d6d8b790fc085f09d",
    "artist_name": "Eddie Rabbitt",
    "image_url": "https://i.scdn.co/image/68fd9e86ec0e509fd3fc8d2bcbb45d1a3d3f92be",
    "artist_id": "0jgAONnsHxrwAlhkMUVS78",
    "year": 1981
  },
  {
    "song_id": "7zSueqHYuehoqjD2yqTeFy",
    "name": "I'm Coming Out - Single Version",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/dac1ab2d2fc003b932321da2c7a46b4667835cf9",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/ab4c29c9f7376abf9403fb4d9a40dac547beff89",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1981
  },
  {
    "song_id": "3pnRd3epmXjorMfOaG4qGd",
    "name": "Seven Year Ache",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/fa3fe03a51fe8c600fd8b441c406a08a7e029532",
    "artist_name": "Rosanne Cash",
    "image_url": "https://i.scdn.co/image/9f6067f8981f3fc1ff1c347562446794498be74a",
    "artist_id": "6lx6XS8umFqYRVbBErx9fE",
    "year": 1981
  },
  {
    "song_id": "08dOlDgNUJCyDNe7F9Dx37",
    "name": "I Can't Stand It",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/02bd1cd2173e6042c46d3833ae5e6a264a72d98c",
    "artist_name": "Eric Clapton",
    "image_url": "https://i.scdn.co/image/28636edf523cec46430b766e5e6f6f5fc85c045b",
    "artist_id": "6PAt558ZEZl0DmdXlnjMgD",
    "year": 1981
  },
  {
    "song_id": "6B71GKmm2ogxd8uhpsMr9n",
    "name": "Stars On 45 - Original Single Version",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/0995ee430616a8ebaf7c616c0384f0d111628361",
    "artist_name": "Stars On 45",
    "image_url": "https://i.scdn.co/image/bd284a00887c899300155aa9a6f1cd3adf9307f0",
    "artist_id": "4r8b3Hr0AMhzhAg75le0Gx",
    "year": 1981
  },
  {
    "song_id": "40SLZpTphbzARSTdEhMrax",
    "name": "A Woman Needs Love (Just Like You Do)",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/791b81b48d38b70c62b738db730633a60d66763d",
    "artist_name": "Ray Parker, Jr.",
    "image_url": "https://i.scdn.co/image/81c44a0338fa0326285e62ddeccd21631214e1ff",
    "artist_id": "0NyzfcGDZZ6GM25EBG9BYK",
    "year": 1981
  },
  {
    "song_id": "6XuXGAgqgTOqLZ5ZDgTuCh",
    "name": "Suddenly - Xanadu/Soundtrack Version",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/598eb86f382e3ef8170772f434385ce69b15b6d5",
    "artist_name": "Olivia Newton-John",
    "image_url": "https://i.scdn.co/image/03540bcd5987f93eba0e6886727fcb1a49984850",
    "artist_id": "4BoRxUdrcgbbq1rxJvvhg9",
    "year": 1981
  },
  {
    "song_id": "692sNw4pzbVzTtsvUGbiD1",
    "name": "Feels so Right",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/ec229275c5f5e60537b5c7297d60c9b9adef5912",
    "artist_name": "Alabama",
    "image_url": "https://i.scdn.co/image/6dae6bbe2e84e8c9c5e7effc90daa42892fa48c7",
    "artist_id": "6rJqqRce0Kvo2dJUXoHleC",
    "year": 1981
  },
  {
    "song_id": "2tH28YyKYOldxhuBHoI79M",
    "name": "Just the Two of Us",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/948879d58920cfa2e4457021c032db4b3c2cb65c",
    "artist_name": "Grover Washington, Jr.",
    "image_url": "https://i.scdn.co/image/6ae74b4ccee11a97bfc556cb0152fb811c3a53fc",
    "artist_id": "05YVYeV4HxYp5rrWalvuE1",
    "year": 1981
  },
  {
    "song_id": "2qEr7hmZ59rTsQvd6NSdo5",
    "name": "I Made It Through the Rain",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/47abf196e1b920c42dab265978909a433a88a71f",
    "artist_name": "Barry Manilow",
    "image_url": "https://i.scdn.co/image/8184f45937baed4ec717f3558e391e99b0c5df30",
    "artist_id": "3alW3LYQS8K29z8C8NSLIX",
    "year": 1981
  },
  {
    "song_id": "48yJZwYYDZX5GKFND7wDfC",
    "name": "Time",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/7ad7276644598b55fa3a2f616ab3e7028a2c889f",
    "artist_name": "The Alan Parsons Project",
    "image_url": "https://i.scdn.co/image/8488f7522b1bf76417c7807fb467819f4f13263e",
    "artist_id": "2m62cc253Xvd9qYQ8d2X3d",
    "year": 1981
  },
  {
    "song_id": "2yF4DENCsYzXKOxpppYmmx",
    "name": "Passion (Remastered Album Version)",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/8ffc744c8d10a61fe00c2ab6fa09639f11dd8ea6",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/79fa6c9588f7bec80db5db5d06bae424a118e57b",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1981
  },
  {
    "song_id": "2wDDxajtq3H74cjk1NPnvm",
    "name": "I Get Around/Little Deuce Coupe (Medley) (Stereo)",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/765d6b8d2b3411cc6c5e724bb9b48a78107dc8d8",
    "artist_name": "The Beach Boys",
    "image_url": "https://i.scdn.co/image/0f3e0ca3ff19c0fc7088590c372150e32a875dc5",
    "artist_id": "3oDbviiivRWhXwIE8hxkVV",
    "year": 1981
  },
  {
    "song_id": "1s0OQOEwJiCybX4adE63Tf",
    "name": "Treat Me Right",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/36193ebf7166a461ed0624be6eed1f1f0f8a84c7",
    "artist_name": "Pat Benatar",
    "image_url": "https://i.scdn.co/image/bd98cecbcb5d09a85dd852233ae9499e50803cf9",
    "artist_id": "43mhFhQ4JAknA7Ik1bOZuV",
    "year": 1981
  },
  {
    "song_id": "4MUzQz6bRj9VVd4amyTBHj",
    "name": "Time Is Time",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/112a1442499810bd5df51efc346d2bf114e34da0",
    "artist_name": "Andy Gibb",
    "image_url": "https://i.scdn.co/image/20afb5c08ab646946717e57a358cf25ebb558e2a",
    "artist_id": "4YPqbAiLzBg5DIfsgQZ8QK",
    "year": 1981
  },
  {
    "song_id": "3YHrZ9zt7uvXbw6IR03SPq",
    "name": "Miss Sun",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/2784fe11bd079e8657f9aff1316d66d61b0dbb52",
    "artist_name": "Boz Scaggs",
    "image_url": "https://i.scdn.co/image/26a206f620dd2bb9ccc037ca915b55268f0e51fc",
    "artist_id": "46njgd2Rq9tZc4ZjeQMgbh",
    "year": 1981
  },
  {
    "song_id": "6e8gz4dofdA308hILBvV73",
    "name": "Tell It Like It Is",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/e8eaed02a0b882b15d79caf7db1da81ed7372f4e",
    "artist_name": "Heart",
    "image_url": "https://i.scdn.co/image/3ca5a984761b80d1f8cb88604c7d3e31f45a93c9",
    "artist_id": "34jw2BbxjoYalTp8cJFCPv",
    "year": 1981
  },
  {
    "song_id": "5Pj5zrq2nbKcAekH1AxbrO",
    "name": "Lady - 1991 - Remaster",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/900ae899574bc5afa0e3628a6ca0b75190cfa037",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/1a6de2c9d0b4b4294439d5ea72fb3b72bfa18085",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1981
  },
  {
    "song_id": "672L8kwlwJu5w8D6MWyvDC",
    "name": "Really Want To Know You - Remastered Version",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/2dbdf2fd18a287b1c2a84a7a6c6dfefa09e3d592",
    "artist_name": "Gary Wright",
    "image_url": "https://i.scdn.co/image/d7b9db5be76a34e9e8d3ce4b3fdc88ef0d6c118e",
    "artist_id": "3z6Uf5IZkN4TogRpRpgD84",
    "year": 1981
  },
  {
    "song_id": "0xLqQHCRrQUxqarMGsUoTM",
    "name": "What Are We Doin' In Love - 2006 Digital Remaster",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/aef17860ab68273f38005ae93dbd757c008b6692",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/636c74debc3869696ab55d91041a0c88f2ac29e4",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1981
  },
  {
    "song_id": "5lHCW9ULjtgmgB1LmLBfCy",
    "name": "Sweetheart",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/712d09a7e5d2e130a40993e61f49247130839717",
    "artist_name": "Franke & The Knockouts",
    "image_url": "https://i.scdn.co/image/6aba7f42421b440abb1268068e4a3606d5d2c548",
    "artist_id": "5l6cEOynnkfX7PS7zCMRU0",
    "year": 1981
  },
  {
    "song_id": "4fWoYEJ9fnW58qhk6aXYJ2",
    "name": "How 'Bout Us - Single Version",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/ea5efec40b6b86668e8ab1e1a42c52b8242b4445",
    "artist_name": "Champaign",
    "image_url": "https://i.scdn.co/image/c324f134249fce88f76083da135244974cbe431c",
    "artist_id": "2gF5ozMajrL8gjPlA4Ej5x",
    "year": 1981
  },
  {
    "song_id": "36BuvbZJHxCZorNYVongtf",
    "name": "It's My Turn - Single Version",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/2630fde3c1c92e5b1ed97ed2987922d42a409841",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/1b0d6cfe55b30f122d2b77c0d13c2f0a876553ea",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1981
  },
  {
    "song_id": "3FD0RyHoYObYJkBIDGra4W",
    "name": "Sukiyaki - 2002 Digital Remaster",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/b83f8a2c882c356db163e38b512bc7a395de8133",
    "artist_name": "A Taste Of Honey",
    "image_url": "https://i.scdn.co/image/2a035f4ac6435ed5cbde9aaf105b9690fe813298",
    "artist_id": "1ii6e2pv8VIRwnTER71rMl",
    "year": 1981
  },
  {
    "song_id": "6hy4ygv98RX0tvD2Uzg0RK",
    "name": "I Don't Need You",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/8e1d0f510a2c48d89443aae4b290cde34254fa86",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/ea212c64aa207763c5d951f72adb6df79a0e4a2a",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1981
  },
  {
    "song_id": "7k1fu2zeQ3S47mT4lhJWcL",
    "name": "Modern Girl",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/8d6e8bc19f19966fb6319682464a70dc853156f3",
    "artist_name": "Sheena Easton",
    "image_url": "https://i.scdn.co/image/20dbd85077913dfbb27f3378b6f3b6bff37cb2ec",
    "artist_id": "5dcOK4stT4JDkP6Dqhbz5s",
    "year": 1981
  },
  {
    "song_id": "6a3boRtCOmooCqOZfTAF4e",
    "name": "While You See A Chance",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/21520992a0de1467dc9e3adda921196a68b79348",
    "artist_name": "Steve Winwood",
    "image_url": "https://i.scdn.co/image/71d3280a37d59ac4eaff56db65bc4a49e108f36c",
    "artist_id": "5gxynDEKwNDgxGJmJjZyte",
    "year": 1981
  },
  {
    "song_id": "7bvL5jBJx2x2kZJ4JGo643",
    "name": "Cool Love",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/33a8313789ce1cbd1a157045b9d3e37a012a5e97",
    "artist_name": "Pablo Cruise",
    "image_url": "https://i.scdn.co/image/582970e87bc83fe3cb8b68071321e8925282bc5a",
    "artist_id": "3Y5abCLsMxsOLaZ8rYusSR",
    "year": 1981
  },
  {
    "song_id": "70iCKwzktaY3UkpoNm1Tsj",
    "name": "Woman (Made Famous by John Lennon)",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/b4f22999197767bd0dae3d6262d13e9f64e2cf6d",
    "artist_name": "Working Class Heroes",
    "image_url": "https://i.scdn.co/image/7a9df7593c83c171c949c41abdd33e3d45057b9a",
    "artist_id": "4hcwotMmR9V1CtJpRri5K3",
    "year": 1981
  },
  {
    "song_id": "6l6hiVkntilDYCAWU954gV",
    "name": "A Little In Love - 2001 Digital Remaster",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/e7a7dfda7a021b4a96c0437d01432d716a923d33",
    "artist_name": "Cliff Richard",
    "image_url": "https://i.scdn.co/image/73e0730a0df75239ae883c2443031725abc3c0f1",
    "artist_id": "2nvKpWcP8etYTq4JrRiUiy",
    "year": 1981
  },
  {
    "song_id": "5aVekolFPgCwzVrCe1EVJy",
    "name": "Lady (You Bring Me Up) - Single Version",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/0089e799ba552e80953f0ab4f0cc6b251b2bce75",
    "artist_name": "Commodores",
    "image_url": "https://i.scdn.co/image/c4ff4911d56ccdb4b0f6490c0bb8eefc0426581e",
    "artist_id": "6twIAGnYuIT1pncMAsXnEm",
    "year": 1981
  },
  {
    "song_id": "46CznAEG4dbI5SbVQC3wPE",
    "name": "Celebration",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/8752d7e6268a926a2af2c79e877dfa6d792da904",
    "artist_name": "Kool & The Gang",
    "image_url": "https://i.scdn.co/image/e22f7179e67f7e1e34db7f713a60ffbec9af8e3e",
    "artist_id": "3VNITwohbvU5Wuy5PC6dsI",
    "year": 1981
  },
  {
    "song_id": "6a4wBNZcmWd7wKYwn06gXS",
    "name": "All Those Years Ago",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/7f462cd4b7b3a6b91fc74b5f4778d7fb766d13d9",
    "artist_name": "Alain Lerox",
    "image_url": "https://i.scdn.co/image/4dea1bde858e3bf9cf22c26e9f0787193957f4bd",
    "artist_id": "2PX3aJRsp5PPQFF9KNO7gD",
    "year": 1981
  },
  {
    "song_id": "2q9XJIS14rgkgM3q5IP8Rp",
    "name": "(Just Like) Starting Over (Demonstration Lead Vocal Version) (In The Style Of John Lennon)",
    "popularity": 3,
    "preview_url": "https://p.scdn.co/mp3-preview/cd7c7d1e96a2a3c6c9a558c12e5d7b16b02def7a",
    "artist_name": "The Karaoke Channel",
    "image_url": "https://i.scdn.co/image/d2283d639d394d15b78c049f92571fcb5a6678e0",
    "artist_id": "5UloVKzUNJcjORzhhTWWiJ",
    "year": 1981
  },
  {
    "song_id": "4eT4ZKSKezU19MZbPTXSFl",
    "name": "Being With You - Single Version",
    "popularity": 2,
    "preview_url": "https://p.scdn.co/mp3-preview/6fb0374ccb843ff5ff2ce1e3f64afc2425aad21f",
    "artist_name": "Smokey Robinson",
    "image_url": "https://i.scdn.co/image/927ca51376a1b304acfa68d5dcba0e2607995389",
    "artist_id": "0h9smro0z3HqUbD94jotU8",
    "year": 1981
  },
  {
    "song_id": "77NNZQSqzLNqh2A9JhLRkg",
    "name": "Don't Stop Believin'",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/62c8990eb8fa9ed5ef46e38fa56d43f55afcf30b",
    "artist_name": "Journey",
    "image_url": "https://i.scdn.co/image/63e47b9e1420e2effd4d4537c464c27e6e598f2e",
    "artist_id": "0rvjqX7ttXeg3mTy8Xscbt",
    "year": 1982
  },
  {
    "song_id": "2KH16WveTQWT6KOG9Rg6e2",
    "name": "Eye of the Tiger",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/5b9b4cd1368f5aa9ef8d6602dcc6a9d2491fbd55",
    "artist_name": "Survivor",
    "image_url": "https://i.scdn.co/image/bfcba0de791c1464f5d3e2372e85b4eed1f7f1cf",
    "artist_id": "26bcq2nyj5GB7uRr558iQg",
    "year": 1982
  },
  {
    "song_id": "37BTh5g05cxBIRYMbw8g2T",
    "name": "Rosanna",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/9f0708ab87afa79718150c39d7254a113b5803a9",
    "artist_name": "Toto",
    "image_url": "https://i.scdn.co/image/a6bfdaece72a3a8bae75e9fa24c29319cc57e7c2",
    "artist_id": "0PFtn5NtBbbUNbU9EAmIWF",
    "year": 1982
  },
  {
    "song_id": "1ynmMEK1fkyiZ6Z6F3ThEt",
    "name": "Centerfold",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/99ff461144b80b7538dfb7215f099ac84f2d0618",
    "artist_name": "The J. Geils Band",
    "image_url": "https://i.scdn.co/image/68d2d98e80941b0d17c5bd53ef9efb0b3e42abe4",
    "artist_id": "69Mj3u4FTUrpyeGNSIaU6F",
    "year": 1982
  },
  {
    "song_id": "2bkVT50D8b4xrIKjeo6f8H",
    "name": "Let's Groove",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/181185d54dd8d7ac68a64bdec1ba1eb37a71079d",
    "artist_name": "Earth, Wind & Fire",
    "image_url": "https://i.scdn.co/image/33f703a93a98c0492edf64591d5f5896e77a6b75",
    "artist_id": "4QQgXkCYTt3BlENzhyNETg",
    "year": 1982
  },
  {
    "song_id": "1m2INxep6LfNa25OEg5jZl",
    "name": "Open Arms",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/5d61e4814c5ef13386f66aed51a9d56fac2bd318",
    "artist_name": "Journey",
    "image_url": "https://i.scdn.co/image/63e47b9e1420e2effd4d4537c464c27e6e598f2e",
    "artist_id": "0rvjqX7ttXeg3mTy8Xscbt",
    "year": 1982
  },
  {
    "song_id": "2xYQTU2bbg6WVAmpY1eae4",
    "name": "Always on My Mind",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/7a78d404a80e45968f9eae2b64d137b8865d6bf2",
    "artist_name": "Willie Nelson",
    "image_url": "https://i.scdn.co/image/0ae1bdb0a351cbc2bf30ec74ced990a15e4488a8",
    "artist_id": "5W5bDNCqJ1jbCgTxDD0Cb3",
    "year": 1982
  },
  {
    "song_id": "0q4xWbV08szzUpGPhVsrcD",
    "name": "Kids In America",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/e3b10a70ad8e478da8d1eef6575daf4fce7c7bb3",
    "artist_name": "Kim Wilde",
    "image_url": "https://i.scdn.co/image/258d136bfbec45f3764f75167dfbdfe097829479",
    "artist_id": "73a6pNH4YtLNgDbPQwXveo",
    "year": 1982
  },
  {
    "song_id": "2Fj2oah3ab05Oju8cY3Wj8",
    "name": "Every Little Thing She Does Is Magic",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/76b941f09ef98fe002dc4351b65bd62dc787c1ae",
    "artist_name": "The Police",
    "image_url": "https://i.scdn.co/image/3ea672725b664fd1dae501120204ef688d17d1fa",
    "artist_id": "5NGO30tJxFlKixkPSgXcFE",
    "year": 1982
  },
  {
    "song_id": "0Ij05JKffxZRYky7FGfFyI",
    "name": "Get Down On It",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/993b5f5c0492cc51c26a8d0d344aedb2d0167e63",
    "artist_name": "Kool & The Gang",
    "image_url": "https://i.scdn.co/image/e21cc6d898f52feb17656b93c63378a2969e9e09",
    "artist_id": "3VNITwohbvU5Wuy5PC6dsI",
    "year": 1982
  },
  {
    "song_id": "5fnOrhQ4KgT5irGCjIccGH",
    "name": "Arthur's Theme (Best That You Can Do) - Remastered",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/8d4083df1f0af7ed0767eeb6e570dd23d71a6a14",
    "artist_name": "Christopher Cross",
    "image_url": "https://i.scdn.co/image/f26d8aca4564634972866056c02728493ed720f4",
    "artist_id": "695W5F2Ih8dYahLdjVOIoH",
    "year": 1982
  },
  {
    "song_id": "7KA66zSwthBv5X9bNQEeX1",
    "name": "Heat Of The Moment",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/88dc56d70d53546de0539bfd8d9c0782b9919c2e",
    "artist_name": "Asia",
    "image_url": "https://i.scdn.co/image/6f446941d555a7d0e43197b111603ce0034f4c63",
    "artist_id": "1bdytLV3FPjyhfrb6BhMej",
    "year": 1982
  },
  {
    "song_id": "5GvWrvLIqoHroq7YvO260M",
    "name": "I Keep Forgettin' [Every Time You're Near]",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/81f17c96dc235462b6db8135b19379bcf3faaff5",
    "artist_name": "Michael McDonald",
    "image_url": "https://i.scdn.co/image/1243f8bc6ce27bb757e25453565e0999f1aa1edd",
    "artist_id": "24hJWbo98sH84tb0nkeaqy",
    "year": 1982
  },
  {
    "song_id": "5Du6gEDduLCB9FIm9xIE4w",
    "name": "Tainted Love",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/87d0363b0d9237a3282fe59f72cb7f5e72e4afab",
    "artist_name": "Soft Cell",
    "image_url": "https://i.scdn.co/image/db8fb58ca5e5ad08251108e5b5c59b3a6d1b3843",
    "artist_id": "6aq8T2RcspxVOGgMrTzjWc",
    "year": 1982
  },
  {
    "song_id": "28OaEH5Baa8RRloKvFGBWu",
    "name": "Young Turks (Remastered Album Version)",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/c108bf7175a86e0b5a1ffe4d9175b2dffb29d9d6",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/55c158274785deb572a10ef3c25af6dc278fade0",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1982
  },
  {
    "song_id": "2ASXXba2oQOxNGi0ttXSxY",
    "name": "I Ran (So Far Away)",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/542694830e0443724fa06ad80a07a69009216994",
    "artist_name": "A Flock Of Seagulls",
    "image_url": "https://i.scdn.co/image/922c084806af853aa633fe72fd6f121b5fea05ab",
    "artist_id": "0uAjBatvB4ubpd4kCfjmNt",
    "year": 1982
  },
  {
    "song_id": "6b4tTTEsJ5jtpCdCp2orsp",
    "name": "Working for the Weekend",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/59940d08160bea083de0c40a44ee3c072c43b725",
    "artist_name": "Loverboy",
    "image_url": "https://i.scdn.co/image/ba8a7e304caeb0ee4249749f72899928d8fdb53a",
    "artist_id": "2CLVPk9FcywjClBcTvWPkT",
    "year": 1982
  },
  {
    "song_id": "00MI0oGDVJYM1qWbyUOIhH",
    "name": "867-5309 / Jenny",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/f823f7fce3b2db08a333de2a48edfb8a8fb0f9ac",
    "artist_name": "Tommy Tutone",
    "image_url": "https://i.scdn.co/image/06f5729d63f8e81efa999b28f6a72b49bc769669",
    "artist_id": "1n2LWYgwtGp7EzDapUoniE",
    "year": 1982
  },
  {
    "song_id": "2OgVsp77En2nju8pnCieVU",
    "name": "Waiting For A Girl Like You",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/be7ba3cbe23ae6db8e0a4e24377914a79656c233",
    "artist_name": "Foreigner",
    "image_url": "https://i.scdn.co/image/507d8e036afcc784904add1108ee99b115052ae6",
    "artist_id": "6IRouO5mvvfcyxtPDKMYFN",
    "year": 1982
  },
  {
    "song_id": "5dXED6MP1v1qghkaniirb1",
    "name": "You Can Do Magic",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/5ba96e3fcf3f8a5e79723d004fe1977a7ecedbb5",
    "artist_name": "America",
    "image_url": "https://i.scdn.co/image/4704097d1ed3d971b6fff057a4b07515e7b592b1",
    "artist_id": "35U9lQaRWSQISxQAB94Meo",
    "year": 1982
  },
  {
    "song_id": "7h4fe4teWz7mc4EZVLE0bl",
    "name": "Don't You Want Me",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/8a7dbd6f2e1237a2407d754eb8d048acc85d7d6f",
    "artist_name": "The Human League",
    "image_url": "https://i.scdn.co/image/f1fbc9ee48056a98a680b24db8db4b9e0ede5215",
    "artist_id": "1aX2dmV8XoHYCOQRxjPESG",
    "year": 1982
  },
  {
    "song_id": "4SlCb8n5Jw52astU7GWPyV",
    "name": "Somebody's Baby",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/653c632008d0c3f5d16799a48a1a369d138e1553",
    "artist_name": "Jackson Browne",
    "image_url": "https://i.scdn.co/image/05d9012b96b58d0085005945fb9ba38d040801d1",
    "artist_id": "5lkiCO9UQ8B23dZ1o0UV4m",
    "year": 1982
  },
  {
    "song_id": "6vkjS0qFE5l4LVoW7hsgFd",
    "name": "Chariots Of Fire",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/fe9775e95f1d49181dc4b5c7751f7808ef232b0d",
    "artist_name": "Vangelis",
    "image_url": "https://i.scdn.co/image/73a5258c9e44933e5376df56572d2f9463eaa472",
    "artist_id": "4P70aqttdpJ9vuYFDmf7f6",
    "year": 1982
  },
  {
    "song_id": "7lPh1t9NAKuHxiUrrDbHhj",
    "name": "Even the Nights Are Better",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/02775ebe281a61b9644b28077c2beceebe08e4db",
    "artist_name": "Air Supply",
    "image_url": "https://i.scdn.co/image/0617de5b1fe4ca6d66239b7bbae0d430d5a6fbf9",
    "artist_id": "4xXCRXOfQKQ2gjWxNhNzYW",
    "year": 1982
  },
  {
    "song_id": "1vJOi8S2MSq3jZsni1gm4x",
    "name": "Vacation",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/baf8364a86665751208dbcec669a5f57b2fa153c",
    "artist_name": "The Go-Go's",
    "image_url": "https://i.scdn.co/image/f58bb8489f65e6741dc96248789f174a1312c3bc",
    "artist_id": "2mG8HHQ9S9kcbjcrb5N1FE",
    "year": 1982
  },
  {
    "song_id": "1TOP1J3oGqQHpkJZuUnCjN",
    "name": "I Love Rock N' Roll - Originally Performed By Joan Jett & the Blackhearts",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/74dc47cbc383edbc560aed945f19cd16b09f7d1b",
    "artist_name": "It's A Cover Up",
    "image_url": "https://i.scdn.co/image/137eb8155382514a738b539051ddddb3e8ba7125",
    "artist_id": "5kly36B42fm7GaSkV5y7zc",
    "year": 1982
  },
  {
    "song_id": "6a899bXlCuViXW2Qs8Rdqo",
    "name": "Turn Your Love Around",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/f0585da0dcd4d9c8d34cd5ed9f111b8825d7ea1d",
    "artist_name": "George Benson",
    "image_url": "https://i.scdn.co/image/8dd4927e9daf3258372b392db4821e7e16a47e09",
    "artist_id": "4N8BwYTEC6XqykGvXXlmfv",
    "year": 1982
  },
  {
    "song_id": "7D7eMbbTAvR58NoZm0OsKh",
    "name": "Leader of The Band",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/66c3980b0fd1631e5c503112c7207834dfe02b8b",
    "artist_name": "Dan Fogelberg",
    "image_url": "https://i.scdn.co/image/58b95630271476d3e4121dd1f2265e62853e94c3",
    "artist_id": "0cA5Tg15TwARIRZeiNT1RO",
    "year": 1982
  },
  {
    "song_id": "15ob9SMGLWrexuPuyuMjKl",
    "name": "Gloria - LP/Single Version",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/2dc0310ed464c2ac935d8fae0ab64152c88547b7",
    "artist_name": "Laura Branigan",
    "image_url": "https://i.scdn.co/image/f1e820948abe6d9b88f7dd547e171970b8ae08a8",
    "artist_id": "4463nfFMmK1cwAWBQDwT5e",
    "year": 1982
  },
  {
    "song_id": "0sXoeBI4ew8V4NyO4gyT8a",
    "name": "Here I Am (Just When I Thought I Was Over You)",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/1011467af1fb4946273af476697454d8d15eb43d",
    "artist_name": "Air Supply",
    "image_url": "https://i.scdn.co/image/0617de5b1fe4ca6d66239b7bbae0d430d5a6fbf9",
    "artist_id": "4xXCRXOfQKQ2gjWxNhNzYW",
    "year": 1982
  },
  {
    "song_id": "1Flq3q4sDw3EYqk8Z9agIe",
    "name": "Do You Believe In Love - 2006 Digital Remaster",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/5aa4d9bb940977ca7add390fe2519a670dc7c785",
    "artist_name": "Huey Lewis & The News",
    "image_url": "https://i.scdn.co/image/57f7e47cbf6c3da3e2e451221bbe4784df39d7b0",
    "artist_id": "7A9yZMTrFZcgEWAX2kBfK6",
    "year": 1982
  },
  {
    "song_id": "5TSSjhWoGjWJfkfa6lziFJ",
    "name": "Through The Years",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/8dae1b7f59ec22352b69e12a8d58cd6c5291a01e",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/82a3399b74525fc7c053488d8cd049e968c4a90f",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1982
  },
  {
    "song_id": "2tIOS5Rm07vQbsaRjdKvPC",
    "name": "(Oh) Pretty Woman",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/6c57f627be679350174922b737bac4ed26a4847d",
    "artist_name": "Van Halen",
    "image_url": "https://i.scdn.co/image/54733ace94c4d5585d841ea212d0265d56ccec54",
    "artist_id": "2cnMpRsOVqtPMfq7YiFE6K",
    "year": 1982
  },
  {
    "song_id": "0BfclbHQsrD8Lc0ASImo5m",
    "name": "Waiting On A Friend",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/a6e7eac1330a9ac723d518a92d1a4c2072472844",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/4566828fe04f6c615e0cdb490540c65b74941682",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1982
  },
  {
    "song_id": "4tkgaeMEluhNKghpKdqr0t",
    "name": "Ebony And Ivory",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/a986883a9f174a5407f3b2f5a8acb92dece0144a",
    "artist_name": "Paul McCartney",
    "image_url": "https://i.scdn.co/image/e163ab4cb18f405d4205629b2209f618a924bccd",
    "artist_id": "4STHEaNw4mPZ2tzheohgXB",
    "year": 1982
  },
  {
    "song_id": "6L5c6wFGDQswebVAS3BmUw",
    "name": "Leather And Lace",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/d1286cfefd538b20e32f12b03a5ffa6d1ef1fe79",
    "artist_name": "Stevie Nicks & Don Henley",
    "image_url": "https://i.scdn.co/image/a842801f34ac1d7db7483899e536d243cadf3cab",
    "artist_id": "6uZbaSEBOrps9wCYtK718e",
    "year": 1982
  },
  {
    "song_id": "39nNw4Q0wdS1N2185oD05x",
    "name": "Eye in the Sky",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/a531ae9d4616afd1a1e1019fca5fc560c5b69df2",
    "artist_name": "The Alan Parsons Project",
    "image_url": "https://i.scdn.co/image/7315eee029e87d8a1e9ba84867456f19b619976a",
    "artist_id": "2m62cc253Xvd9qYQ8d2X3d",
    "year": 1982
  },
  {
    "song_id": "2RvkPf7MIzhdh347w5sjTJ",
    "name": "Think I'm In Love",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/4637f065c8d0d1918258591dc6ba8db50e1ce8de",
    "artist_name": "Eddie Money",
    "image_url": "https://i.scdn.co/image/3819c99bb414183bbd2baf97cf23ce650d17e634",
    "artist_id": "4Tw2N3wdvJPGEU7JqMxFfE",
    "year": 1982
  },
  {
    "song_id": "1BZMMt9H0TheQGWQy44KmO",
    "name": "Only The Lonely - Digitally Remastered 99",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/870294759cb4d1cf473b419c56d69cb94c05fa4f",
    "artist_name": "The Motels",
    "image_url": "https://i.scdn.co/image/33690311319c856d799b895b9c945ba576dad0c9",
    "artist_id": "6scOultrkXrQsClcbGKM7e",
    "year": 1982
  },
  {
    "song_id": "5nKQtxdEN7imkXEzi0FtLk",
    "name": "That Girl",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/17ff559152f76be6594caaac117ce6d1ef2f4e18",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/a89c864c7f78ca244797a706fb48794696af27b1",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1982
  },
  {
    "song_id": "6QqK6a1CmV4L504uMQaGDH",
    "name": "Freeze-Frame",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/dc45337b036b7510d73747482dac96b4e5561a8e",
    "artist_name": "The J. Geils Band",
    "image_url": "https://i.scdn.co/image/68d2d98e80941b0d17c5bd53ef9efb0b3e42abe4",
    "artist_id": "69Mj3u4FTUrpyeGNSIaU6F",
    "year": 1982
  },
  {
    "song_id": "4XluM0ZU23YGFfAWIfWWR5",
    "name": "Don't Talk to Strangers",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/5e51a49a69e455f23de1d8d74332c4979c8b6b81",
    "artist_name": "Rick Springfield",
    "image_url": "https://i.scdn.co/image/007262dcc89250c1295c0f2b9be0b1937ee42f82",
    "artist_id": "6IFXsrXBpwbIqtOUOiAa3p",
    "year": 1982
  },
  {
    "song_id": "42TFhl9RznJZi5Xcd2PZDb",
    "name": "Wasted On The Way",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/cc6c442bc386de9760adc05a3ad5ac96baf6a088",
    "artist_name": "Crosby, Stills & Nash",
    "image_url": "https://i.scdn.co/image/f896c942a7ef026cd740017946b0192de02d52a7",
    "artist_id": "2pdvghEHZJtgSXZ7cvNLou",
    "year": 1982
  },
  {
    "song_id": "2fN6PZnjaoQRjqCQjkr8W8",
    "name": "Hold Me - Remastered",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/88ac21c60a3dfc293efe29451344e3094af46b5b",
    "artist_name": "Fleetwood Mac",
    "image_url": "https://i.scdn.co/image/442ee8d4b75164eff70ad0299a974e08d4d23dd8",
    "artist_id": "08GQAI4eElDnROBrJRGE0X",
    "year": 1982
  },
  {
    "song_id": "5HQ639Z3ms3hnZx0KfWnkp",
    "name": "Private Eyes - Remastered",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/cf1c7c1e26e8bde1d09d30f6d74a91f0538fad3f",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/7f95691c5ccf5e54b3521a3b42d7023c9cce1256",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1982
  },
  {
    "song_id": "2lZ5rQJT1AcU0xAJQozM1A",
    "name": "Blue Eyes",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/705f8e526bc9df291d9ad3dfcac545e71be04665",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/66d7967d6417b94846f114c773fa4f1033944094",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1982
  },
  {
    "song_id": "0lQBXClJb9Z0gyzFXyohVB",
    "name": "Sweet Dreams",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/db7d742a32d57c9ab5507aa224db61aa189577e6",
    "artist_name": "Air Supply",
    "image_url": "https://i.scdn.co/image/0617de5b1fe4ca6d66239b7bbae0d430d5a6fbf9",
    "artist_id": "4xXCRXOfQKQ2gjWxNhNzYW",
    "year": 1982
  },
  {
    "song_id": "2RH79y0Fid4HvtU6cYFGCs",
    "name": "Love in the First Degree",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/eb37bab3d0d3396b350a3d737ec92b090d96b0b8",
    "artist_name": "Alabama",
    "image_url": "https://i.scdn.co/image/4e511603a2f2d48ac1b07e1727765b7b069a2d94",
    "artist_id": "6rJqqRce0Kvo2dJUXoHleC",
    "year": 1982
  },
  {
    "song_id": "6AeOM7N0rK3tYOINY2k5qy",
    "name": "It's Gonna Take A Miracle",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/5e4b67b66a4ebae4fd321f489ecdfd94644be122",
    "artist_name": "Deniece Williams",
    "image_url": "https://i.scdn.co/image/7bb72d5bf5645b7dc846e219ac571040d786d2fb",
    "artist_id": "5jNGQ7VOU87x5402JjhTtd",
    "year": 1982
  },
  {
    "song_id": "3aGdlqp8i9lJAWG5nqfhUP",
    "name": "Key Largo",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/b7871778e57c3088cd95a3cc8a7ac23e47b2f164",
    "artist_name": "Bertie Higgins",
    "image_url": "https://i.scdn.co/image/8fa1c4ed92b1413a23bd67fbc333677d541ff1ff",
    "artist_id": "4qzZ6bMrjK27yHGVjs4Pvq",
    "year": 1982
  },
  {
    "song_id": "35ffrwjDSwCLzRiQrtO1DB",
    "name": "Any Day Now - Remastered",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/b0444894a7fbdf2afc5cc30b968c2463e30d150d",
    "artist_name": "Ronnie Milsap",
    "image_url": "https://i.scdn.co/image/405443af4ce7b27bf9065eca357d2c56def0badc",
    "artist_id": "6Sb8EHpZvyjSTT5KD9DjUZ",
    "year": 1982
  },
  {
    "song_id": "4w6dJIuTwjU7qKaA4YdK99",
    "name": "I Can't Go For That (No Can Do)",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/7f536ec80e7759d929e65c998069e071636423cb",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/3dc6ecd5d8736d80f2aed80490efe977265cf015",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1982
  },
  {
    "song_id": "5OWokvF4QOqMDQ8ByQsm6V",
    "name": "Shake It Up",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/4693a9ad81da2401da002907214e4288cc4199ef",
    "artist_name": "The Cars",
    "image_url": "https://i.scdn.co/image/9c0b2acf35482e6d045031ffa6a6f05e5d838283",
    "artist_id": "6DCIj8jNaNpBz8e5oKFPtp",
    "year": 1982
  },
  {
    "song_id": "2TVpUxoEaRH4GzndwGJyLY",
    "name": "Our Lips Are Sealed - 2011 - Remaster",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/3db0a0727777aa2c63d16d43c1fe472c9e93697a",
    "artist_name": "The Go-Go's",
    "image_url": "https://i.scdn.co/image/d0cb669f419462fb84a4f146a640920c3e8109e8",
    "artist_id": "2mG8HHQ9S9kcbjcrb5N1FE",
    "year": 1982
  },
  {
    "song_id": "0MHTpNtTcrdseMWAhh7Sdp",
    "name": "Hot In The City - 2001 Digital Remaster",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/8accb4ef7b4616196873d4e3b353aca9fd6d5299",
    "artist_name": "Billy Idol",
    "image_url": "https://i.scdn.co/image/6bb8a69c5de907cd33cecf3faa478550db5e54ad",
    "artist_id": "7lzordPuZEXxwt9aoVZYmG",
    "year": 1982
  },
  {
    "song_id": "3BI2g3xaStu8g05HwlnvaZ",
    "name": "We Got The Beat",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/e8b38002aae52fdc5157915599c6b56afac29a49",
    "artist_name": "The Go-Go's",
    "image_url": "https://i.scdn.co/image/50431a74bf6ed9ebdb3ccb67bc22827f5e849b5e",
    "artist_id": "2mG8HHQ9S9kcbjcrb5N1FE",
    "year": 1982
  },
  {
    "song_id": "24SKNZPNlIYuVvlLHyQCoK",
    "name": "Caught Up In You",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/81492f5459b9609b3eb789d7f4a1933cca33d29e",
    "artist_name": "38 Special",
    "image_url": "https://i.scdn.co/image/7fa06208c88b4ead0e311b9e306b6ca469a0ad7d",
    "artist_id": "3zXw2Eh96iTT51pytzHdZi",
    "year": 1982
  },
  {
    "song_id": "4y89z1oZiGD04h3LryKZDS",
    "name": "Why Do Fools Fall in Love",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/575799e9db44fba970e0b7206b812dbaf77affe7",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/8a69bca942b6a79da7d2086d936d3e524b9b9ff5",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1982
  },
  {
    "song_id": "7h8JzycDOWRSRfurjiCjGA",
    "name": "Comin' In And Out Of Your Life",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/8e60a4e45d2b7614d2582f7c38bdb9900cbe92e6",
    "artist_name": "Barbra Streisand",
    "image_url": "https://i.scdn.co/image/510b276f469468a39d0bd335516f7a6eec54c062",
    "artist_id": "7jmTilWYlKOuavFfmQAcu6",
    "year": 1982
  },
  {
    "song_id": "3EFgVWb1TAYP3CF17BB4fU",
    "name": "The Sweetest Thing (I've Ever Known)",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/e1072526c26fb3af1b00a5a5fc46b76aca52fdcc",
    "artist_name": "Juice Newton",
    "image_url": "https://i.scdn.co/image/3a1b2b431484cbe6713b26434722578f9cf3b244",
    "artist_id": "4L1z1IcfK7lbqx8izGHaw5",
    "year": 1982
  },
  {
    "song_id": "5j2yIrnbgDW51GM95ZjqDj",
    "name": "What's Forever For",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/bc070c618a3d7e43d2ca4ce66cb1b17704a1eb54",
    "artist_name": "Michael Martin Murphey",
    "image_url": "https://i.scdn.co/image/5a6981071581af277ddb912d33be21b74e8cfa8d",
    "artist_id": "4jL1usPiGDK8TD46zJP4gu",
    "year": 1982
  },
  {
    "song_id": "1BW0gUqia7oe11xBJab9at",
    "name": "Did It In A Minute - Remastered 2003",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/dc02505e5482dec36c83941619a1a2eaf989681b",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/82216e16eff844df7916f5024dd7041403b6c82a",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1982
  },
  {
    "song_id": "37zpChlZkoFuScXLoiu0WE",
    "name": "Love's Been A Little Bit Hard On Me",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/ee8c5fa22fdb0426f5770f47917364bfef7e87b6",
    "artist_name": "Juice Newton",
    "image_url": "https://i.scdn.co/image/3a1b2b431484cbe6713b26434722578f9cf3b244",
    "artist_id": "4L1z1IcfK7lbqx8izGHaw5",
    "year": 1982
  },
  {
    "song_id": "7sFcJVrWPU9irTtQb9usn0",
    "name": "Keep the Fire Burnin'",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/f7ba7d3fe3d59e5ea6dec1ea94f4ee0e57fa66b5",
    "artist_name": "REO Speedwagon",
    "image_url": "https://i.scdn.co/image/de02e63ea9654f711f4457317dca0080298dd1dd",
    "artist_id": "55vs7NT1KxcFjbMC4y202E",
    "year": 1982
  },
  {
    "song_id": "1CSzNmxOtTGZqGmvK9Pxec",
    "name": "Crimson And Clover (In the Style of Joan Jett & the Blackhearts) [Performance Track with Demonstration Vocals]",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/70b75d7448d61a5fdaea9839d90472882923b368",
    "artist_name": "Done Again",
    "image_url": "https://i.scdn.co/image/765b03b47779029119e635be8f1c19c1be1338f7",
    "artist_id": "0GQkTwFb7D3ePIpnxwYavf",
    "year": 1982
  },
  {
    "song_id": "4FDRce5rrUuAbsC5VKwSkz",
    "name": "You Should Hear How She Talks About You",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/19ea94da59baa46f544fc75e65fb6fe33d22f3ee",
    "artist_name": "Melissa Manchester",
    "image_url": "https://i.scdn.co/image/91992667296616502ccc53021b1c50ab4e54ce34",
    "artist_id": "78udmCDNaDu5jqfVnBqwia",
    "year": 1982
  },
  {
    "song_id": "5mD7sze0ClymDybOfj7byK",
    "name": "One Hundred Ways",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/b7f26fe78753c07085869d88eb104a0fd99807e8",
    "artist_name": "Quincy Jones",
    "image_url": "https://i.scdn.co/image/9a86bbb1257a9045a9cd545525f647be9a7dbc9a",
    "artist_id": "3rxIQc9kWT6Ueg4BhnOwRK",
    "year": 1982
  },
  {
    "song_id": "1M2a5TNHqmr2zXZZcB7xTe",
    "name": "Love Will Turn You Around",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/d1609e6261a1e404a2cebf1f7d0859503959698f",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/f30710f791d00fa51fa56bdc01acbe15d7314c78",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1982
  },
  {
    "song_id": "3MJolSHZn6mBsJr2SCiYjj",
    "name": "Empty Garden (Hey Hey Johnny)",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/7971ced1fd72fba573df3f2c8dfeffb18aa3efed",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/ad5d6122f312e3763d1897ad56a8e880c3e1d31b",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1982
  },
  {
    "song_id": "5p0Et9J14mQUaqZsNiFlsY",
    "name": "Take It Easy On Me - 2010 Digital Remaster",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/34a2964d3e72d293c76915fa9dd189d99c3ad25f",
    "artist_name": "Little River Band",
    "image_url": "https://i.scdn.co/image/8cf204324d1bdca2346544792ccc33fa0776810a",
    "artist_id": "6clbbhnIqpHnqxwtOWcilg",
    "year": 1982
  },
  {
    "song_id": "6H3LEPiUBVaAbR90yAd5Jp",
    "name": "Pac - Man Fever",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/bbb10883485f5a3d0fdddf802793b7fa7a0b8f59",
    "artist_name": "Buckner & Garcia",
    "image_url": "https://i.scdn.co/image/5ee577b76d429f0809656384aea1b34e80792291",
    "artist_id": "3byVCnJiYgvRU7y5VsMHJI",
    "year": 1982
  },
  {
    "song_id": "0nqQwPNem175DbPnA3yCmJ",
    "name": "Hooked On Classics (Parts 1 & 2)",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/bde31b3d587e9a541982081cd49b6f9d14cc6c48",
    "artist_name": "Royal Philharmonic Orchestra",
    "image_url": "https://i.scdn.co/image/ade4aa6dd5ee90c01f649ba47d7ba6a56b1e9f34",
    "artist_id": "0MvSBMGRQJY3mRwIbJsqF1",
    "year": 1982
  },
  {
    "song_id": "3lfLD0IaH2FxitlHKRVGTX",
    "name": "Love Is In Control (Finger On The Trigger) - Chromeo & Oliver Remix",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/37050537346c5571afd4e8115f285bf4f040e37c",
    "artist_name": "Donna Summer",
    "image_url": "https://i.scdn.co/image/8d258f82e9811fa066e3add1bdb0074bd763ac2c",
    "artist_id": "2eogQKWWoohI3BSnoG7E2U",
    "year": 1982
  },
  {
    "song_id": "3dgpLSZlacfOVPAjiXRIfS",
    "name": "'65 Love Affair - Single Version",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/f6d2105db615f1b7855e984525933d10ce8d48ab",
    "artist_name": "Paul Davis",
    "image_url": "https://i.scdn.co/image/2c3285f1ad770a7c1d134629df26c499cbe567b4",
    "artist_id": "6EJmqnuK0r6qiAevFFiNNR",
    "year": 1982
  },
  {
    "song_id": "29KyVqHekckVGfxHn8QS7m",
    "name": "Cool Night",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/3942eb2520f8d14637d54632c01ced14276832c0",
    "artist_name": "Paul Davis",
    "image_url": "https://i.scdn.co/image/9132e8dcfa8b6109cd82622085f62f1e319a3b2e",
    "artist_id": "6EJmqnuK0r6qiAevFFiNNR",
    "year": 1982
  },
  {
    "song_id": "0kihGHSj8tdUjdZ0mtM6be",
    "name": "Oh No",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/14dfd301f1fbd380bfcaef75982dcc9813381687",
    "artist_name": "Commodores",
    "image_url": "https://i.scdn.co/image/b583666c875751dbf7a367f72a67d874b1eda9d9",
    "artist_id": "6twIAGnYuIT1pncMAsXnEm",
    "year": 1982
  },
  {
    "song_id": "0JSWXx9MWCz266XslcOnlf",
    "name": "Take My Heart (You Can Have It If You Want It)",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/dee242b859a620e25d413e548dcb3926c6cf2e85",
    "artist_name": "Kool & The Gang",
    "image_url": "https://i.scdn.co/image/896c5f7591e0d23a47ffabd2b53cbfbbb490dc38",
    "artist_id": "3VNITwohbvU5Wuy5PC6dsI",
    "year": 1982
  },
  {
    "song_id": "2yT82AafNlbpU7EAZkMQbw",
    "name": "Physical - Live At The Sydney Opera House",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/fb42adbc8186b4928f9d9dc2919301dd798c3488",
    "artist_name": "Olivia Newton-John",
    "image_url": "https://i.scdn.co/image/3a742c9befc09a2afdf93d6a7cd5cafffe8c529d",
    "artist_id": "4BoRxUdrcgbbq1rxJvvhg9",
    "year": 1982
  },
  {
    "song_id": "3N5Sa2YwDSUWmoYhYVEpEM",
    "name": "The Other Woman",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/2c64b4a17090e7f89d3404eefddc1ca34ebc8ff0",
    "artist_name": "Ray Parker, Jr.",
    "image_url": "https://i.scdn.co/image/f9ca4932c8f786ca2015980d40afc9a34b387abc",
    "artist_id": "0NyzfcGDZZ6GM25EBG9BYK",
    "year": 1982
  },
  {
    "song_id": "1UpKK7U9ow2K1G6qNw9wnW",
    "name": "Making Love - LP/Single Version",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/2e1685bf4067f2c618971ea3fa69649be57eb7c7",
    "artist_name": "Roberta Flack",
    "image_url": "https://i.scdn.co/image/4a6fc88a5498cb6bacc2e9c7c6b3ac09ffae28a5",
    "artist_id": "0W498bDDNlJIrYMKXdpLHA",
    "year": 1982
  },
  {
    "song_id": "6BiuWGkLhFP2To5OWboGFQ",
    "name": "Take It Away",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/4883ac58c557be3288759211009f45cae0217c91",
    "artist_name": "Paul McCartney",
    "image_url": "https://i.scdn.co/image/e163ab4cb18f405d4205629b2209f618a924bccd",
    "artist_id": "4STHEaNw4mPZ2tzheohgXB",
    "year": 1982
  },
  {
    "song_id": "0DdrnL3gu0DH8Pz5Ls2Oba",
    "name": "Mirror Mirror",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/fd63106b45c055659ea5f23e43b3c58581d9e8fb",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/8a69bca942b6a79da7d2086d936d3e524b9b9ff5",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1982
  },
  {
    "song_id": "2FuBAo5UbUP60rFx94snKA",
    "name": "Man On Your Mind - 2002 Digital Remaster",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/0015a500d2230d8a63dbcfe7c9588ee43739c485",
    "artist_name": "Little River Band",
    "image_url": "https://i.scdn.co/image/877352214329cf8836ac8a020bb1fe0d0f75c5f0",
    "artist_id": "6clbbhnIqpHnqxwtOWcilg",
    "year": 1982
  },
  {
    "song_id": "2H9DaLWHXqS7eSmouctbrj",
    "name": "Personally",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/2f6073b9943cf93090e6848602160b0309c9571a",
    "artist_name": "Karla Bonoff",
    "image_url": "https://i.scdn.co/image/b189af70c5e5cdaf7e6bced71684e5cf63cf7f17",
    "artist_id": "1ZIMtrSe8sINPurERT4tRa",
    "year": 1982
  },
  {
    "song_id": "2EW6o93S7dEGTlZNEFmHr9",
    "name": "Jack & Diane",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/edd97bb25daeb6c71351507e013e30f5b6efd85e",
    "artist_name": "John Mellencamp",
    "image_url": "https://i.scdn.co/image/36557c83663f6240d0899e3f35de84db8bb0d0ac",
    "artist_id": "3lPQ2Fk5JOwGWAF3ORFCqH",
    "year": 1982
  },
  {
    "song_id": "6aE7lGzrdKO6faIDlfcb66",
    "name": "Should I Do It",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/5da80d14bdb714e9058270a5b77eec500d2c27cc",
    "artist_name": "The Pointer Sisters",
    "image_url": "https://i.scdn.co/image/24c09320f5f5703f52f1392e157d01cabd4f3fc4",
    "artist_id": "2kreKea2n96dXjcyAU9j5N",
    "year": 1982
  },
  {
    "song_id": "2NDti8N0UCATsq3vlzeU0l",
    "name": "Hurts So Good",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/15e06f2a1579950ac8e0805e02fb56b8099baa9b",
    "artist_name": "John Mellencamp",
    "image_url": "https://i.scdn.co/image/36557c83663f6240d0899e3f35de84db8bb0d0ac",
    "artist_id": "3lPQ2Fk5JOwGWAF3ORFCqH",
    "year": 1982
  },
  {
    "song_id": "2fr5JxDmugRhmfgsGFLJar",
    "name": "Trouble",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/ce79dc92d4ce9ec98c2bca3ee2267081b8ab0e63",
    "artist_name": "Lindsey Buckingham",
    "image_url": "https://i.scdn.co/image/8061e7dec0702524ed8744f23a2e06bdf3f424ab",
    "artist_id": "3Dzj993UEz8Z5ovxuirzFO",
    "year": 1982
  },
  {
    "song_id": "1ONz7ocoJticKL8bw6cYEf",
    "name": "You Could Have Been With Me",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/49e3924c3a1c2c7860a5f4738a600df841f2e49f",
    "artist_name": "Sheena Easton",
    "image_url": "https://i.scdn.co/image/82c589061c8dc10a831cedcc281ed59cfcb18d1e",
    "artist_id": "5dcOK4stT4JDkP6Dqhbz5s",
    "year": 1982
  },
  {
    "song_id": "37HsacjL113jzYWckhAVNv",
    "name": "Edge of Seventeen (Just Like the White Winged Dove) [In the Style of Stevie Nicks] {Performance Track with Demonstration Vocals} - Single",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/5c483dd5a99b211d78e00071e64efcd6cc11d321",
    "artist_name": "Done Again",
    "image_url": "https://i.scdn.co/image/765b03b47779029119e635be8f1c19c1be1338f7",
    "artist_id": "0GQkTwFb7D3ePIpnxwYavf",
    "year": 1982
  },
  {
    "song_id": "1fmxd7t61qzwik7fRvW9xN",
    "name": "Harden My Heart",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/fa34bc46a534130ddfd681cdc384102c03ecaa93",
    "artist_name": "Age Of Rock",
    "image_url": "https://i.scdn.co/image/65ce00fbf6540d0782e9aa2cc441f6644d32d108",
    "artist_id": "40cBiaNLVUT1yv8HFmswaz",
    "year": 1982
  },
  {
    "song_id": "2374M0fQpWi3dLnB54qaLX",
    "name": "Africa",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/20b073a921647f04247fe2e91987a483252a6745",
    "artist_name": "Toto",
    "image_url": "https://i.scdn.co/image/a6bfdaece72a3a8bae75e9fa24c29319cc57e7c2",
    "artist_id": "0PFtn5NtBbbUNbU9EAmIWF",
    "year": 1983
  },
  {
    "song_id": "5prTs2HAw2G4idHZyeFp8o",
    "name": "Total Eclipse of the Heart",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/9951fcca07249f8f985aef2199a131534d9dc7d9",
    "artist_name": "Bonnie Tyler",
    "image_url": "https://i.scdn.co/image/9829e8d5f9f5467e804367b66cf717256f31d8f4",
    "artist_id": "0SD4eZCN4Kr0wQk56hCdh2",
    "year": 1983
  },
  {
    "song_id": "1TfqLAPs4K3s2rJMoCokcS",
    "name": "Sweet Dreams (Are Made of This) - Remastered Version",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/a73b16c13483d0bd84d44c0a2f6c4d1313a4bd5e",
    "artist_name": "Eurythmics",
    "image_url": "https://i.scdn.co/image/a961b07f009826abb7412b032ec6aec875e7d50b",
    "artist_id": "0NKDgy9j66h3DLnN8qu1bB",
    "year": 1983
  },
  {
    "song_id": "4YUlGNZfYA6EUE5KDsGqpC",
    "name": "You Can't Hurry Love",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/935a5e537c2b4b9b1b825c5cee7ed58ba7f17bc3",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/341a8ff5498b2d99a7820489ca07359906dc73f2",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1983
  },
  {
    "song_id": "0F0MA0ns8oXwGw66B2BSXm",
    "name": "Let's Dance - 1999 Remastered Version",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/1bbd2c28d7963c21dc2f627899534086dea7810a",
    "artist_name": "David Bowie",
    "image_url": "https://i.scdn.co/image/5d4206acfc17050606eb740351108d04a1b88029",
    "artist_id": "0oSGxfWSnnOXhD2fKuz2Gy",
    "year": 1983
  },
  {
    "song_id": "6eDYgjKl4vUgRusxb7THDu",
    "name": "True",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/87b3603d3eb2fae74088143900ea540fb3bbea60",
    "artist_name": "Spandau Ballet",
    "image_url": "https://i.scdn.co/image/af09c1b0465ccb7ad907a5ff637b589c64835649",
    "artist_id": "2urZrEdsq72kx0UzfYN8Yv",
    "year": 1983
  },
  {
    "song_id": "6HzZhqXNyfJxuvQ9zfCc1U",
    "name": "Faithfully",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/7900fd3f57c13675c5b09f0bbf548a02c7ced5cd",
    "artist_name": "Journey",
    "image_url": "https://i.scdn.co/image/63e47b9e1420e2effd4d4537c464c27e6e598f2e",
    "artist_id": "0rvjqX7ttXeg3mTy8Xscbt",
    "year": 1983
  },
  {
    "song_id": "1uCbb7fkxRUrQAvCQgmSHo",
    "name": "Wanna Be Startin' Somethin'",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/bde3e49f51b34b17cd2b372bc7136b0b9e68b128",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/b51d252d914b4e1becfe7ae425697f170e2f769a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1983
  },
  {
    "song_id": "4aKIs5t9TqP59btlCGPrgw",
    "name": "Maneater - Remastered",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/5c83bbcfb05dd5e625e6141bd5d9042316b46914",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/137ad27bf07ad1db8fc5a70219ea0381206a8ac4",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1983
  },
  {
    "song_id": "2ORySgkn0CbUMFbEoP1wwE",
    "name": "Separate Ways (Worlds Apart)",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/87316ed2494bd638216366c4e7a299de9788854a",
    "artist_name": "Journey",
    "image_url": "https://i.scdn.co/image/63e47b9e1420e2effd4d4537c464c27e6e598f2e",
    "artist_id": "0rvjqX7ttXeg3mTy8Xscbt",
    "year": 1983
  },
  {
    "song_id": "76Gmfksb2uBzx5N72DcHT0",
    "name": "Little Red Corvette",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/f54e01d476f145341816a0e4efe7a3973748566a",
    "artist_name": "Prince",
    "image_url": "https://i.scdn.co/image/49db086c1d635a44cc1662c5ca9fe99a5ed3d1f7",
    "artist_id": "5a2EaR3hamoenG9rDuVn8j",
    "year": 1983
  },
  {
    "song_id": "2H7PHVdQ3mXqEHXcvclTB0",
    "name": "1999",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/3adc5b59cf449c2ab8d12c742e8c98c20b124789",
    "artist_name": "Prince",
    "image_url": "https://i.scdn.co/image/53cdf8a70b1d0e1a2f4efd189e4d3a0e9fe8e268",
    "artist_id": "5a2EaR3hamoenG9rDuVn8j",
    "year": 1983
  },
  {
    "song_id": "61yhNRBnlbKb7j51e8OZCJ",
    "name": "Mr. Roboto",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/76069f3d7b8c1f6818a073f5f11d57af3debe2ef",
    "artist_name": "Styx",
    "image_url": "https://i.scdn.co/image/fbaa93c80441950bc77d2344fff83db911b8e3ab",
    "artist_id": "4salDzkGmfycRqNUbyBphh",
    "year": 1983
  },
  {
    "song_id": "4cqUq7n3ad0DhJ2JCVw34v",
    "name": "Electric Avenue",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/dfe135d97c81bd6461a2e058236c42a5df583f2e",
    "artist_name": "Eddy Grant",
    "image_url": "https://i.scdn.co/image/ad2ceda1233b954fc5aa13fa17766558f54ae6ed",
    "artist_id": "6j2HWcd7z61iTLbi8eD0hA",
    "year": 1983
  },
  {
    "song_id": "3OQtbwQ9U4A0VDH1rjamZJ",
    "name": "Too Shy - 2004 Remastered Version",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/e51d6547888c6868112201adece3483e7ce71618",
    "artist_name": "Kajagoogoo",
    "image_url": "https://i.scdn.co/image/b013ebfb044018c9840af07855823d1fea64c54c",
    "artist_id": "0f3kLT4wvi2mFHlHJgV8Hl",
    "year": 1983
  },
  {
    "song_id": "082jAB8ValDguMSLaT7CZZ",
    "name": "Pass The Dutchie",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/f7c880d4564947e5d6817915efdb9468c9b9024e",
    "artist_name": "Musical Youth",
    "image_url": "https://i.scdn.co/image/633d3024bb9515e3262b30674fc71b10085fe088",
    "artist_id": "2CuzDPkRD6BJBvdWqCrt2I",
    "year": 1983
  },
  {
    "song_id": "162gk2vyorLJCuZMu43Ift",
    "name": "Mickey",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/20b251e5601de2aa8b3bbfdfb7e166883ca6a157",
    "artist_name": "Toni Basil",
    "image_url": "https://i.scdn.co/image/55a8069a26a125d75a8e297a863e615979b93c87",
    "artist_id": "59hMeIYY3k3NoX54rrVGPv",
    "year": 1983
  },
  {
    "song_id": "4cMHCRLPNoEbpnl2rz6GS9",
    "name": "Back On The Chain Gang - 2007 Remastered Version",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/1a130b43f87327cedb70b3cd0faab545b9eaf689",
    "artist_name": "Pretenders",
    "image_url": "https://i.scdn.co/image/ee6b8133cf71d4e6b83784e5040d1722530bad9a",
    "artist_id": "0GByy3DcfbQwDvXGCWmzv9",
    "year": 1983
  },
  {
    "song_id": "4RUkk4vAzPVYqBdHNW9T6i",
    "name": "Puttin' On The Ritz",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/3d0feb07000eefe28780adf63155edbb653bb63f",
    "artist_name": "Taco",
    "image_url": "https://i.scdn.co/image/271b34f294dd3d051629fb678a478cc24ce80cf1",
    "artist_id": "6Dxf1ZaJBrpbumiqTTnlIH",
    "year": 1983
  },
  {
    "song_id": "0BWravaqj9rKdOU8RWXhuf",
    "name": "She Blinded Me With Science",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/b620a8c6dc31bf5890feb59d06d3e04005a8c10c",
    "artist_name": "Thomas Dolby",
    "image_url": "https://i.scdn.co/image/d7c8488d70a7dd1e391b6db591e44100175e44d0",
    "artist_id": "2Uz58cSxlJgefDaSikxYQ7",
    "year": 1983
  },
  {
    "song_id": "4HruSQTQR65g2HU9dUy0FS",
    "name": "Sexual Healing",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/3c8e25fa39b8ad56e4c46ce3c0f453d30634f5ac",
    "artist_name": "Marvin Gaye",
    "image_url": "https://i.scdn.co/image/865021c612c7bcff2d3eb353236b313c2ad01191",
    "artist_id": "3koiLjNrgRTNbOwViDipeA",
    "year": 1983
  },
  {
    "song_id": "5L7zWiRiXDPNvTLLoXvIja",
    "name": "Always Something There To Remind Me",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/94d82734a0ff96de7ccbadacf1dd82092bc7840f",
    "artist_name": "Naked Eyes",
    "image_url": "https://i.scdn.co/image/852376709c73f9bdbf69bccc305eae1df9120965",
    "artist_id": "3C6chBmZ9wzisBhoh8G2nK",
    "year": 1983
  },
  {
    "song_id": "0vWCLHyiA1G3eqBPnY1WFW",
    "name": "Our House",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/4c3e1484662ee31da76a6870fca2704a0581b312",
    "artist_name": "Madness",
    "image_url": "https://i.scdn.co/image/fb1a4ca57e4e82b57f8a0624f7f043efc5d6e19d",
    "artist_id": "4AYkFtEBnNnGuoo8HaHErd",
    "year": 1983
  },
  {
    "song_id": "7D4EtbE7LfQiH8CWj7fQoz",
    "name": "I Won't Hold You Back",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/9db67303d962817e630c55fe599a0b9ef5634740",
    "artist_name": "Toto",
    "image_url": "https://i.scdn.co/image/a6bfdaece72a3a8bae75e9fa24c29319cc57e7c2",
    "artist_id": "0PFtn5NtBbbUNbU9EAmIWF",
    "year": 1983
  },
  {
    "song_id": "6sleEAnBH0sZMe0pOQNidG",
    "name": "Stand Back",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/9de431b134eb4cd4fd6d3060a26c37a807546eb1",
    "artist_name": "Stevie Nicks",
    "image_url": "https://i.scdn.co/image/46b138cab0e9e05aea5db03d24b7889e7f39ce63",
    "artist_id": "7crPfGd2k81ekOoSqQKWWz",
    "year": 1983
  },
  {
    "song_id": "0RfdoNWHJbZxAI3sOWS8Q7",
    "name": "Tell Her About It",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/a440bbb397721e1bc81ccc20cd6a5137a0f560fd",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/dc82b8076e92803f3ff7b469015f2b4540478d5c",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1983
  },
  {
    "song_id": "5xNmWMhsZncmM9zziHww42",
    "name": "It's a Mistake",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/7307cdcb201db981348d10ca8ffee72ae08d38ee",
    "artist_name": "Men At Work",
    "image_url": "https://i.scdn.co/image/b5c50263ba2a35c0a46691c9f4a9ee235d79a133",
    "artist_id": "0f3EsoviYnRKTkmayI3cux",
    "year": 1983
  },
  {
    "song_id": "2HuHopKnnuGQEpGb0CssCs",
    "name": "Hungry Like The Wolf",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/3371a6e41a4b87cf3998e37ccf168cab055f72e9",
    "artist_name": "Duran Duran",
    "image_url": "https://i.scdn.co/image/349e764d4602cf253c5102b78495b745cccc2424",
    "artist_id": "0lZoBs4Pzo7R89JM9lxwoT",
    "year": 1983
  },
  {
    "song_id": "2veYJl7RAW2lkveifGHYKT",
    "name": "Allentown",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/5d781d0208e398dea254f68cde25b67fd729310e",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/dc82b8076e92803f3ff7b469015f2b4540478d5c",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1983
  },
  {
    "song_id": "4gic8wdcwH3kPjfHAPITLK",
    "name": "Goody Two Shoes",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/083218efd408d87fd3dbb17c5d894aa04ff11cde",
    "artist_name": "Adam Ant",
    "image_url": "https://i.scdn.co/image/e0f2f05aafe590af6ac19326cfe803482a9e4158",
    "artist_id": "3QP0XPDwbvGivqDAaJ5f5G",
    "year": 1983
  },
  {
    "song_id": "0Co8hKbjUy273NrGliGdWZ",
    "name": "Up Where We Belong",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/eab611d1a328623dd380a61e9751bbe14f440535",
    "artist_name": "Joe Cocker",
    "image_url": "https://i.scdn.co/image/0ba16eac22d3b3da3c48439867146ccee1de4552",
    "artist_id": "3pFCERyEiP5xeN2EsPXhjI",
    "year": 1983
  },
  {
    "song_id": "4KcshFM1IjZStr8ROGhYzN",
    "name": "One On One - Remastered 2003",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/12f89c42611afc43027e162af846356733d41bae",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/82216e16eff844df7916f5024dd7041403b6c82a",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1983
  },
  {
    "song_id": "6lnP51cMlqpZ8bwmyVlgvG",
    "name": "She's A Beauty",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/cda66626d9e3871f198ae62196c108ffc2f7b0c2",
    "artist_name": "The Tubes",
    "image_url": "https://i.scdn.co/image/ebcb0b9a1bcbbf7ea01175d8b9419668a753b702",
    "artist_id": "7zfhej6FnVXN9LIXs6dcoK",
    "year": 1983
  },
  {
    "song_id": "6vA0oK0LIQtWCjPjpww4Tr",
    "name": "Human Nature",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/ae8683cb6cf3c7e720ee00c2d67c6c5b22319c0b",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1983
  },
  {
    "song_id": "4VfWY8hydsKIXtKQ2AT9oO",
    "name": "Photograph - Live",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/e77e35f760d6359ea7ef123c2bd222fb7db8ee36",
    "artist_name": "Def Leppard",
    "image_url": "https://i.scdn.co/image/cf8be16805a78c4d7cf8b3f71ea7bdef4a4b3d31",
    "artist_id": "6H1RjVyNruCmrBEWRbD0VZ",
    "year": 1983
  },
  {
    "song_id": "3FlOciKDqFlTMPeC7t92Qy",
    "name": "She Works Hard For The Money",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/9becf5e811fa9ac1b18b6c3fb224ff6f91b73512",
    "artist_name": "Donna Summer",
    "image_url": "https://i.scdn.co/image/8ecc5e306e00dd50c0b1cd392be4890f22faa1b5",
    "artist_id": "2eogQKWWoohI3BSnoG7E2U",
    "year": 1983
  },
  {
    "song_id": "5Q9MCK4NU3xApjsIkKWegw",
    "name": "We've Got Tonight - 2006 - Remastered",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/f12fe39b4250952ce5c82b3466e1f1d8c633f114",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/44d34f31486f1176f22ca596614f68b5150e255e",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1983
  },
  {
    "song_id": "556Fdf9iUVDA2aSUDzlkNV",
    "name": "All Right",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/7daf85e3d950c9dc66c17ce646c15cd02134eaf0",
    "artist_name": "Christopher Cross",
    "image_url": "https://i.scdn.co/image/a403b39d806923e3c2642fa81faa28812bdeb148",
    "artist_id": "695W5F2Ih8dYahLdjVOIoH",
    "year": 1983
  },
  {
    "song_id": "1xEpz8iZDReyXevFcP89XG",
    "name": "Beat It - Single Version",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/f49a24cc7ec09b7c2d2b1678553a39f6fa2a71cf",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1983
  },
  {
    "song_id": "0ZfZaT7NByalxa6bct5G67",
    "name": "(Keep Feeling) Fascination - 2003 - Remaster",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/aa3c62582cf1cbe6e4b57b8353095ef885098d43",
    "artist_name": "The Human League",
    "image_url": "https://i.scdn.co/image/539c962bd3db605dbde95a40513b21d135f7ef80",
    "artist_id": "1aX2dmV8XoHYCOQRxjPESG",
    "year": 1983
  },
  {
    "song_id": "1YFuzRhg3OtJiNFFHvdTgV",
    "name": "I'll Tumble 4 Ya",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/81153904df984d7f8042e8f80e13862e9d18ce5b",
    "artist_name": "Culture Club",
    "image_url": "https://i.scdn.co/image/916e3dbc9d2bfa3adf5836d622f17814a2038748",
    "artist_id": "6kz53iCdBSqhQCZ21CoLcc",
    "year": 1983
  },
  {
    "song_id": "1asbauP5iqBr21MTpWDdNS",
    "name": "It Might Be You (from \"Tootsie\")",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/b242497b3affcf9490a0636348beb76d92041767",
    "artist_name": "Stephen Bishop",
    "image_url": "https://i.scdn.co/image/337144c680b9798c63146e5545b2ea1a11a1723d",
    "artist_id": "3JhRHqCi8HEzkJVWt19LtN",
    "year": 1983
  },
  {
    "song_id": "48bBO1utRUJLOEIGiUhkiB",
    "name": "All This Love",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/f6fee99d306c680fc614702ff4429e637950ef24",
    "artist_name": "DeBarge",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "6is2U7I1jlI8PjxNZOHIMV",
    "year": 1983
  },
  {
    "song_id": "5eaK7PLGwyDXmHfTdWZZuE",
    "name": "Straight From The Heart",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/05268d6de054e6349d9c42a9eef9d75600574f80",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/00f04a8cec0527c12a5d8b2b326a6f1385bcb89c",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1983
  },
  {
    "song_id": "3sks3fxIzjffXVaTC4Dl69",
    "name": "Promises, Promises - Single Version",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/9e4de8f049899e4269bb466e3229cde6f05c4006",
    "artist_name": "Naked Eyes",
    "image_url": "https://i.scdn.co/image/c4871320802d269d4df6fc7cb1ef25caa0161d75",
    "artist_id": "3C6chBmZ9wzisBhoh8G2nK",
    "year": 1983
  },
  {
    "song_id": "2KiybjzjyrEstFl1qrZqtM",
    "name": "Heart To Heart",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/b72bbd1c64cf6b4143263e1a69aaa626d6f175d6",
    "artist_name": "Kenny Loggins",
    "image_url": "https://i.scdn.co/image/d985d2b51571bba1d65247ee06450d621bbefc58",
    "artist_id": "3Y3xIwWyq5wnNHPp5gPjOW",
    "year": 1983
  },
  {
    "song_id": "3LUrih1nLwx0vL0QihnpdI",
    "name": "Far From Over",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/2da01b2c744e5a8cdda599a124b6d859314f8168",
    "artist_name": "Frank Stallone",
    "image_url": "https://i.scdn.co/image/d311298498e15522a6375a062cbf36fc09d2b6fd",
    "artist_id": "3BucZwouCkpiGi2UxqfWn9",
    "year": 1983
  },
  {
    "song_id": "0ROtxObpdDA25o5DriPSOk",
    "name": "Der Kommissar",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/b6974da993a33174f51a68713c43d7a6f242a76d",
    "artist_name": "After The Fire",
    "image_url": "https://i.scdn.co/image/bad89d2850af84ae5b9220c895c6507ecb41b7da",
    "artist_id": "194g4SFwSSzyPtgkH7v3Cx",
    "year": 1983
  },
  {
    "song_id": "6UguIecQmrmEy1NjCHZsTV",
    "name": "Time (Clock Of The Heart)",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/2f2ba815f4ef828ec43b7eacd19f301f7c1bec70",
    "artist_name": "Culture Club",
    "image_url": "https://i.scdn.co/image/916e3dbc9d2bfa3adf5836d622f17814a2038748",
    "artist_id": "6kz53iCdBSqhQCZ21CoLcc",
    "year": 1983
  },
  {
    "song_id": "15fvDRnqFdogjUM8PUC0ml",
    "name": "The Girl Is Mine",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/c7676558da2efe3a92ab52a57aa93a0b8fb9123a",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1983
  },
  {
    "song_id": "6KgBoqgnh8i6AjU0slFeYe",
    "name": "Tonight, I Celebrate My Love - 2001 - Remaster",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/2142fc6e53ea0672335afccd5475ae91760dbfce",
    "artist_name": "Peabo Bryson",
    "image_url": "https://i.scdn.co/image/44d34f31486f1176f22ca596614f68b5150e255e",
    "artist_id": "49iKbKGqgn8OESkW5WduX0",
    "year": 1983
  },
  {
    "song_id": "55uZJFuImJzBXLeBC3x7Co",
    "name": "You And I",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/fc64b9d5be2802e35ac4592312b3782ee0194b92",
    "artist_name": "Eddie Rabbitt",
    "image_url": "https://i.scdn.co/image/721efeb4562ca38099b661598c71937318f99efe",
    "artist_id": "0jgAONnsHxrwAlhkMUVS78",
    "year": 1983
  },
  {
    "song_id": "0bBVRsBbgnzW8wmdlD7Apq",
    "name": "Overkill",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/1a82dc1208f60660c4e05f20fbac9999532e6f94",
    "artist_name": "Men At Work",
    "image_url": "https://i.scdn.co/image/8eeb2c9bee323c041d95d2340cd43683662a0cfe",
    "artist_id": "0f3EsoviYnRKTkmayI3cux",
    "year": 1983
  },
  {
    "song_id": "1HerMONdBbVd0IQJE73sgZ",
    "name": "Do You Really Want to Hurt Me - 2002 - Remaster",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/13463c19e5b69f11522ee95b45257c9ca77ceba7",
    "artist_name": "Culture Club",
    "image_url": "https://i.scdn.co/image/8f2660bb93c52372d5cf64416a4d32b5151b3c2e",
    "artist_id": "6kz53iCdBSqhQCZ21CoLcc",
    "year": 1983
  },
  {
    "song_id": "3OCNkidVblywixF3p15w9c",
    "name": "Family Man - Remastered 2003",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/1783496643aaf2ffeea04a604cb1bddb55cf8f7b",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/82216e16eff844df7916f5024dd7041403b6c82a",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1983
  },
  {
    "song_id": "767BYYVKKNdFCI2GrCk4BR",
    "name": "Breaking Us In Two",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/cf46527d495382e46a512ffeb0116b7bce5003dc",
    "artist_name": "Joe Jackson",
    "image_url": "https://i.scdn.co/image/4e098484838ecce2c99694ba9bce4178d6797321",
    "artist_id": "6KOqPxwfNAmZPkiCnDE9yT",
    "year": 1983
  },
  {
    "song_id": "1fQyiulxuPu8ywfIrOM3ft",
    "name": "I Know There's Something Going On",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/d154523715503069ef678ca635e00b3c0c8e11b5",
    "artist_name": "Frida",
    "image_url": "https://i.scdn.co/image/61f89ee83aef2167f1314e5cdb8ad1a6bcf439d9",
    "artist_id": "5uOVb4hLSQVbHbVVt27tV1",
    "year": 1983
  },
  {
    "song_id": "1w2CBsRDGGrYfcIFurKN5l",
    "name": "Making Love Out Of Nothing At All",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/995aa17fdd5463f9d002af309c02e2baa9972bbc",
    "artist_name": "Air Supply",
    "image_url": "https://i.scdn.co/image/7b82d37401f3e7dfbe7c927433bf314314da2514",
    "artist_id": "4xXCRXOfQKQ2gjWxNhNzYW",
    "year": 1983
  },
  {
    "song_id": "5x5FUXjs53WbPuTwT6WD8d",
    "name": "(She's) Sexy + 17 - 1999 Digital Remaster",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/ed5f9bac6c4b29e295c2ef42af76760701c18b84",
    "artist_name": "Stray Cats",
    "image_url": "https://i.scdn.co/image/45f965e2a7b7905cbb530877704b784b9f8216f7",
    "artist_id": "2ibPkysx2PXqWLmxFD7jSg",
    "year": 1983
  },
  {
    "song_id": "26lCNg8WekUoDbLhEbUyXZ",
    "name": "Lawyers In Love",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/adfad7908bf239fedeb5fe8d684f793d245c85c1",
    "artist_name": "Jackson Browne",
    "image_url": "https://i.scdn.co/image/999a956b98e6a30ce3cf2f4c8b98148ead83c9e4",
    "artist_id": "5lkiCO9UQ8B23dZ1o0UV4m",
    "year": 1983
  },
  {
    "song_id": "7AslBsiVLQAGrmFmi79ZeK",
    "name": "Truly",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/1d3a68bf3ba50f2d84a1e4ea19b256970b297cde",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/6dfe3e91795e797e34053e3e0852fd8042d11485",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1983
  },
  {
    "song_id": "6sQhzyoKVtjB1xohOJRt06",
    "name": "Affair of the Heart",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/397f3d3143c8b1a51aa99a01e735948e5afd7ce4",
    "artist_name": "Rick Springfield",
    "image_url": "https://i.scdn.co/image/007262dcc89250c1295c0f2b9be0b1937ee42f82",
    "artist_id": "6IFXsrXBpwbIqtOUOiAa3p",
    "year": 1983
  },
  {
    "song_id": "3OghNV9ZOUbAj62CZptOEP",
    "name": "Your Love Is Driving Me Crazy",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/c9d4d88a1f0e79a13deb6da62d86c8b25e46fd18",
    "artist_name": "Sammy Hagar",
    "image_url": "https://i.scdn.co/image/f4d1504782642113a4ff9d4440ee961dfcb095ea",
    "artist_id": "1hXjTjJzZTXDZ75AclOo6N",
    "year": 1983
  },
  {
    "song_id": "1zCGS6bgV9rHVot0hELx5S",
    "name": "China Girl - 1999 Remastered Version",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/59f54fcefc1ccb6341be868e7b540ab4d40135f0",
    "artist_name": "David Bowie",
    "image_url": "https://i.scdn.co/image/5c07142fd3d5754ebf72c8d72db0c3837c32bb25",
    "artist_id": "0oSGxfWSnnOXhD2fKuz2Gy",
    "year": 1983
  },
  {
    "song_id": "3RRRGwfes5ZzK07Cm4f6ho",
    "name": "Hot Girls In Love",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/51cea251b4e0214f74a9d59b2581c7adb100f06c",
    "artist_name": "Loverboy",
    "image_url": "https://i.scdn.co/image/f93b1661ae5554bdf52b7be2cfa0f80ca3c489f3",
    "artist_id": "2CLVPk9FcywjClBcTvWPkT",
    "year": 1983
  },
  {
    "song_id": "5Mc4cz1QdX5n2vFV0K10vM",
    "name": "You Got Lucky",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/a46f05d8f244995bc541d86ded01a80c9b306194",
    "artist_name": "Tom Petty And The Heartbreakers",
    "image_url": "https://i.scdn.co/image/98f6f5e34b9630e40e38dc8e8480227afb2e89d1",
    "artist_id": "4tX2TplrkIP4v05BNC903e",
    "year": 1983
  },
  {
    "song_id": "5qugKP8gPqN91O7KBMkW6P",
    "name": "Fall in Love with Me",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/0f7c58dbc584a8e3d66714ce092ee810526d566f",
    "artist_name": "Earth, Wind & Fire",
    "image_url": "https://i.scdn.co/image/2ccb71f367c80310e2904e783bf6a1f3391a99f4",
    "artist_id": "4QQgXkCYTt3BlENzhyNETg",
    "year": 1983
  },
  {
    "song_id": "0huaODTI1xKsfJJk8EJEkf",
    "name": "Don't Let It End",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/4d167db345af6b6aa76ccebe93bea44edcc1bd1f",
    "artist_name": "Styx",
    "image_url": "https://i.scdn.co/image/288046bc4c09cede2a984e534992a1b60f44eba4",
    "artist_id": "4salDzkGmfycRqNUbyBphh",
    "year": 1983
  },
  {
    "song_id": "79stkwByJTjWMPyuiYsOuw",
    "name": "Heartbreaker",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/786b322702f1eeccda69bea64a6d62ab0077f3b6",
    "artist_name": "Dionne Warwick",
    "image_url": "https://i.scdn.co/image/7b7bb775e63b553d63e6621213707b33a21bcad4",
    "artist_id": "2JSjCHK79gdaiPWdKiNUNp",
    "year": 1983
  },
  {
    "song_id": "0bsVmf3Ki4x4BzvdrlbYXi",
    "name": "I've Got A Rock 'N' Roll Heart",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/548dbf8d3364fff1b895e10a7006cdcc03976113",
    "artist_name": "Eric Clapton",
    "image_url": "https://i.scdn.co/image/28636edf523cec46430b766e5e6f6f5fc85c045b",
    "artist_id": "6PAt558ZEZl0DmdXlnjMgD",
    "year": 1983
  },
  {
    "song_id": "5u8bqfaYSh0qVTsgslPknf",
    "name": "Never Gonna Let You Go",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/ab383903572eb32e53fa49bc73d3058bb3f8b5ec",
    "artist_name": "Sergio Mendes",
    "image_url": "https://i.scdn.co/image/f373cff19d26698d3d762a42a84c80c25a616850",
    "artist_id": "65c5si0ePAwkOCn4M35Ho7",
    "year": 1983
  },
  {
    "song_id": "3pNEoHjqWx4JIvHM46rZsm",
    "name": "Is There Something I Should Know",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/431b7ef48e097020e3d9ae0d340c5e85e4ee9e5e",
    "artist_name": "Duran Duran",
    "image_url": "https://i.scdn.co/image/44d67b8557327632dac01a8d47f78fde50d12009",
    "artist_id": "0lZoBs4Pzo7R89JM9lxwoT",
    "year": 1983
  },
  {
    "song_id": "6l9fOwFZ2pZzGUAVnPM76l",
    "name": "My Love",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/594d0c614a47f67bdd22f378a12d9bef30a3e052",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/6dfe3e91795e797e34053e3e0852fd8042d11485",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1983
  },
  {
    "song_id": "5NHEpCtDPugmIuawJtwH2y",
    "name": "Don't Cry",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/c9c16a94574d4abd54896c79cfa8a92e534f050a",
    "artist_name": "Asia",
    "image_url": "https://i.scdn.co/image/4d1f1676a753c16849dcaeb64092cfc49ba2157d",
    "artist_id": "1bdytLV3FPjyhfrb6BhMej",
    "year": 1983
  },
  {
    "song_id": "0gX18ikvVSzGgNGx939SOe",
    "name": "Steppin' Out",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/f2e6193e7909424e1fd48c534a41c52115e114a5",
    "artist_name": "Joe Jackson",
    "image_url": "https://i.scdn.co/image/dded3499392c6358593b9c5e47ea34f9b9232ede",
    "artist_id": "6KOqPxwfNAmZPkiCnDE9yT",
    "year": 1983
  },
  {
    "song_id": "5ETbctpnDNloYA4IO6s0wn",
    "name": "Down Under",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/4f17583fdc2b3837d9c3cd3f202b6feb460a71f5",
    "artist_name": "Men At Work",
    "image_url": "https://i.scdn.co/image/5a98496c1b244d49baade63f984b98511f7bb9bf",
    "artist_id": "0f3EsoviYnRKTkmayI3cux",
    "year": 1983
  },
  {
    "song_id": "173r99cYlRApif8GLPrLqt",
    "name": "The Other Guy - 24-Bit Digitally Remastered 02",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/d656c203110c3442c9b68df77ffee09887d0d302",
    "artist_name": "Little River Band",
    "image_url": "https://i.scdn.co/image/877352214329cf8836ac8a020bb1fe0d0f75c5f0",
    "artist_id": "6clbbhnIqpHnqxwtOWcilg",
    "year": 1983
  },
  {
    "song_id": "6j0ri1fbU2QDXlDrhgkuFk",
    "name": "Twilight Zone",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/16a4ba3047f38d2769c5b8cc61047651f9d234ba",
    "artist_name": "Golden Earring",
    "image_url": "https://i.scdn.co/image/7db7e218470044f302549490716f05b4cbf512e5",
    "artist_id": "1iTlOqIrZy8DlvCPJY2sjS",
    "year": 1983
  },
  {
    "song_id": "3ZfjQOjf4jp4o6yfK5GtCB",
    "name": "Take Me To Heart",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/78bb98fb438061a485bc77b167b67fc7d9aed40e",
    "artist_name": "Quarterflash",
    "image_url": "https://i.scdn.co/image/12ec116c7d1e4a5a2795cf477047d276bcb4aead",
    "artist_id": "3VJakY5Yw5phAOF4Bada5v",
    "year": 1983
  },
  {
    "song_id": "6I8xhomIJJcXzNN43TotPv",
    "name": "You Are",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/f25497a98f5b5911c9193ac21de7d7a67af59b91",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1983
  },
  {
    "song_id": "17RxTPSIGg0WP92dAiZliC",
    "name": "Solitare",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/2830066f6b9b5a99342202560721ad8cb7154f44",
    "artist_name": "Laura Branigan",
    "image_url": "https://i.scdn.co/image/bbb8562f2e459e32fbdf7ef6b3166a3bdf75ca94",
    "artist_id": "4463nfFMmK1cwAWBQDwT5e",
    "year": 1983
  },
  {
    "song_id": "1D9KEXIrlmPUkMTdYzqgX4",
    "name": "Thriller",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/6227c005f4dfdb9504cf94964491845e66b14ab1",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/b51d252d914b4e1becfe7ae425697f170e2f769a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1984
  },
  {
    "song_id": "3CSpzkoL1XgDBZ1q9aDCUV",
    "name": "Uptown Girl",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/0164e9f247a3731e4779d9eaa0598e2b24dab7ed",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/dc82b8076e92803f3ff7b469015f2b4540478d5c",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1984
  },
  {
    "song_id": "51H2y6YrNNXcy3dfc3qSbA",
    "name": "When Doves Cry",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/d6abac6769100967d7f19feea109751b40bff8d0",
    "artist_name": "Prince",
    "image_url": "https://i.scdn.co/image/d64cdeaad56a5bba451950824595e5b007124cd9",
    "artist_id": "5a2EaR3hamoenG9rDuVn8j",
    "year": 1984
  },
  {
    "song_id": "6HA97v4wEGQ5TUClRM0XLc",
    "name": "99 Luftballons",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/4d0d800ad9cd80dfcfde5fe95af9555c00ee896d",
    "artist_name": "Nena",
    "image_url": "https://i.scdn.co/image/22bea681227b089c37ea75997cc9d5227149396f",
    "artist_id": "6Tz0QRoe083BcOo2YbG9lV",
    "year": 1984
  },
  {
    "song_id": "3wfujdbamR3Z46F4xav7LM",
    "name": "Drive",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/1365a9fa1bd574a6c335fadfecf43b0afed1a9ba",
    "artist_name": "The Cars",
    "image_url": "https://i.scdn.co/image/6fc51bbde1b27cd23ce3e244d6951d84b3570721",
    "artist_id": "6DCIj8jNaNpBz8e5oKFPtp",
    "year": 1984
  },
  {
    "song_id": "300zfRaCgTmEm5Eqe3HqZZ",
    "name": "Ghostbusters",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/3fcd53ec53797ebe7156daf018db7c8833c8bb5d",
    "artist_name": "Ray Parker, Jr.",
    "image_url": "https://i.scdn.co/image/81c44a0338fa0326285e62ddeccd21631214e1ff",
    "artist_id": "0NyzfcGDZZ6GM25EBG9BYK",
    "year": 1984
  },
  {
    "song_id": "48O0GrGJWml3DzHhC5sJ7a",
    "name": "Karma Chameleon",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/b69f9d3e274cd769d88b34aafde250a24a16ccd0",
    "artist_name": "Culture Club",
    "image_url": "https://i.scdn.co/image/916e3dbc9d2bfa3adf5836d622f17814a2038748",
    "artist_id": "6kz53iCdBSqhQCZ21CoLcc",
    "year": 1984
  },
  {
    "song_id": "0ObLFkX9bnGBZMFVeLFRN4",
    "name": "Caribbean Queen (No More Love on the Run)",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/9c41e837595721c1f883563f39bddbe949e39d8d",
    "artist_name": "Billy Ocean",
    "image_url": "https://i.scdn.co/image/793db7387a1fb3d575ae9795297bd8648595cd81",
    "artist_id": "5IDs1CK15HegSAhGEbSYXo",
    "year": 1984
  },
  {
    "song_id": "6JNJERZGJwDVgkmbohBw7u",
    "name": "Self Control",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/8f75261a1e3b9ee7a727bcc3ca48530131d6aa43",
    "artist_name": "Laura Branigan",
    "image_url": "https://i.scdn.co/image/70ff9ea8bb506fe0bda9005dc0acdc4aa353750c",
    "artist_id": "4463nfFMmK1cwAWBQDwT5e",
    "year": 1984
  },
  {
    "song_id": "1QbQL5m30YNvukitIqAnFG",
    "name": "Sunglasses At Night",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/e8cf48bc7fb707cc327dcd0cb5feafffaf81fb0a",
    "artist_name": "Corey Hart",
    "image_url": "https://i.scdn.co/image/6fb6c97436280dbefafea55c2784d7a4ad7c6535",
    "artist_id": "0smy8yDrRoI4CnhpOuthg0",
    "year": 1984
  },
  {
    "song_id": "6VAKyYoJQu1hwjWNvxQVTt",
    "name": "I Just Called To Say I Love You",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/aebb88f3f6e9f4deffeac44b10f10d28ca4cad61",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/6476d80646a3ac86c61b9e2263ead819b2b6194b",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1984
  },
  {
    "song_id": "5vYhr2kgOonJtWeJq5qZzU",
    "name": "Here Comes The Rain Again - Remastered Version",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/0614c64d69d1e25c22c1ccf255d45e16ffdb7e96",
    "artist_name": "Eurythmics",
    "image_url": "https://i.scdn.co/image/be201cd2ea4a5b23e8b466fabb2381c7b69e77f8",
    "artist_id": "0NKDgy9j66h3DLnN8qu1bB",
    "year": 1984
  },
  {
    "song_id": "3AgvZyLH4sWkII5bnU80aQ",
    "name": "I'm so Excited",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/5b808eb1a2dc408fe7dfee0f2ad98535d089a721",
    "artist_name": "The Pointer Sisters",
    "image_url": "https://i.scdn.co/image/fe2b16ccd01db6f2235edb7e99965507ea727a58",
    "artist_id": "2kreKea2n96dXjcyAU9j5N",
    "year": 1984
  },
  {
    "song_id": "7pzVqCZdYKM7oEPwaiQchB",
    "name": "Eyes Without A Face - 1999 - Remaster",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/66b36b1a84a3a4e7ff406e32670a2c972e778e89",
    "artist_name": "Billy Idol",
    "image_url": "https://i.scdn.co/image/ae4358812edea60bdecbcec4d4508e7f7e2c0da6",
    "artist_id": "7lzordPuZEXxwt9aoVZYmG",
    "year": 1984
  },
  {
    "song_id": "0SFS1LxNP5wgnoDA7nGKQu",
    "name": "Round and Round",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/a5835b950883dbfca7efa3bb1406437119705423",
    "artist_name": "Ratt",
    "image_url": "https://i.scdn.co/image/b20324cbb7c0d94cea2a2ad4c03abdfb3b6daba0",
    "artist_id": "3tufWJzpCiAGleBt5TkmTn",
    "year": 1984
  },
  {
    "song_id": "0uqOqX59mgWBqfPAdX0BDK",
    "name": "Girls Just Want to Have Fun",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/634845082b7a03ad185a3bccc9190be49134e744",
    "artist_name": "Cyndi Lauper",
    "image_url": "https://i.scdn.co/image/ff8d208d8049907f0def04e4729acc73fb319c4d",
    "artist_id": "2BTZIqw0ntH9MvilQ3ewNY",
    "year": 1984
  },
  {
    "song_id": "7t6CAWplijBj4sdl0q3z0e",
    "name": "Legs (2008 Remastered Album Edit Version)",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/b51737840dd206542d0ee61e479ac1269003987e",
    "artist_name": "ZZ Top",
    "image_url": "https://i.scdn.co/image/0ec1e7080de6ef28455e4a75ca0a9e3160141d2c",
    "artist_id": "2AM4ilv6UzW0uMRuqKtDgN",
    "year": 1984
  },
  {
    "song_id": "4k6ktr10Hczmh55NY3LfJy",
    "name": "Holiday",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/fc58e96358adcbe609955100e46f585e5e1fa45d",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1984
  },
  {
    "song_id": "3wqoAzrr5htxSZ7KzxhLHg",
    "name": "Oh Sherrie",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/b40899b4e655fd6679b3dfca5eb8b232a19bb3bc",
    "artist_name": "Steve Perry",
    "image_url": "https://i.scdn.co/image/1956eebc1618d034b0fe1e980b9d23c7d24b60d3",
    "artist_id": "5xQKoGD7Ql92fWd1uWwKkf",
    "year": 1984
  },
  {
    "song_id": "19kuZ0IExry8qYJ4lU2A0r",
    "name": "Jump - Remastered Version",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/f23df98c32ed5ee9effbb471329476413536185a",
    "artist_name": "Van Halen",
    "image_url": "https://i.scdn.co/image/4e096d95a9618c8ffa1ce9f5e4ca56b07ed156bf",
    "artist_id": "2cnMpRsOVqtPMfq7YiFE6K",
    "year": 1984
  },
  {
    "song_id": "7ozMJf8OMc9vRcZmAN8wPt",
    "name": "Somebody's Watching Me",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/3c9d85c66d54e0c14478e88af8c9b142b8851476",
    "artist_name": "Rockwell",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3E5lqme11foUMn2SoYpmzT",
    "year": 1984
  },
  {
    "song_id": "6BkX6DnUG80xPlBfb2zmrZ",
    "name": "Let's Go Crazy",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/ea6524da855e71b4e39853578b919b5b5815de40",
    "artist_name": "Prince & The Revolution",
    "image_url": "https://i.scdn.co/image/49db086c1d635a44cc1662c5ca9fe99a5ed3d1f7",
    "artist_id": "6C3ZGU59R9XhxQ1KRU5Q1O",
    "year": 1984
  },
  {
    "song_id": "2EVMlbOZkfU5htC0C3BUGa",
    "name": "Pink Houses",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/e38d27ec14fd12f5931a25e816acfeed61926e73",
    "artist_name": "John Mellencamp",
    "image_url": "https://i.scdn.co/image/2252c94914f263d66846123192aae9ce9ade7082",
    "artist_id": "3lPQ2Fk5JOwGWAF3ORFCqH",
    "year": 1984
  },
  {
    "song_id": "08JKuFJCHJVw1SgHHjwsS9",
    "name": "Talking in Your Sleep",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/6abc6756976942900b4fac0e90d0dfdda3f623ab",
    "artist_name": "The Romantics",
    "image_url": "https://i.scdn.co/image/53056b729c63b146342eb909848a79c2defce741",
    "artist_id": "3daM7asS0gCFvyLemNx2EE",
    "year": 1984
  },
  {
    "song_id": "4FMNpPI7mkhdeFCCk6fZiE",
    "name": "Borderline",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/4b4a7c686b92ae2cd2292c678291db06c71457f1",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1984
  },
  {
    "song_id": "4WrwO9Un3ji5wnNySDGHwQ",
    "name": "Islands In The Stream",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/b56a937edf3f0b0bbf9310e3a8c8528d65748afd",
    "artist_name": "Kenny Rogers",
    "image_url": "https://i.scdn.co/image/82a3399b74525fc7c053488d8cd049e968c4a90f",
    "artist_id": "4tw2Lmn9tTPUv7Gy7mVPI4",
    "year": 1984
  },
  {
    "song_id": "2h41e1ebabMfij3upGdymH",
    "name": "If This Is It - 2006 Digital Remaster",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/2a4744961154fd1e2d1b1967fc0637c433e7b335",
    "artist_name": "Huey Lewis & The News",
    "image_url": "https://i.scdn.co/image/57f7e47cbf6c3da3e2e451221bbe4784df39d7b0",
    "artist_id": "7A9yZMTrFZcgEWAX2kBfK6",
    "year": 1984
  },
  {
    "song_id": "2Wl3rABbAd3Hl3zNFM6hQ7",
    "name": "Against All Odds",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/3388b6fefff92f31681045eae992a906abb9b098",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/a669e2d6aa5c1073831a0e6fe0c3f5c507a6cbc4",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1984
  },
  {
    "song_id": "4DDgVahgRnITUk6Wnu5FWR",
    "name": "The Reflex",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/db6084d78b679968936f444c3f1b584727bbbbb1",
    "artist_name": "Duran Duran",
    "image_url": "https://i.scdn.co/image/44d67b8557327632dac01a8d47f78fde50d12009",
    "artist_id": "0lZoBs4Pzo7R89JM9lxwoT",
    "year": 1984
  },
  {
    "song_id": "4TIkesxpN5caX6XpsiirnH",
    "name": "If Ever You're In My Arms Again - Remastered Version",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/ec369a6bd62f37502112a10d7a9a9c96ee1e262d",
    "artist_name": "Peabo Bryson",
    "image_url": "https://i.scdn.co/image/fefd4e409d3aaa3f033193821e7cc9c5aeb1fa10",
    "artist_id": "49iKbKGqgn8OESkW5WduX0",
    "year": 1984
  },
  {
    "song_id": "0mZNKyrUmsrlDRoYHWsyMu",
    "name": "I Want A New Drug - 2006 Digital Remaster",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/9f54f960a20e4e5f28ef1edeeee37eeff0ec7456",
    "artist_name": "Huey Lewis & The News",
    "image_url": "https://i.scdn.co/image/57f7e47cbf6c3da3e2e451221bbe4784df39d7b0",
    "artist_id": "7A9yZMTrFZcgEWAX2kBfK6",
    "year": 1984
  },
  {
    "song_id": "7nRwpTzjrFFNFpdHT76NOz",
    "name": "Lucky Star",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/c5ce87972b380b476ca64a5fe63507bd8491e1ac",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1984
  },
  {
    "song_id": "2ZqT3GcisHUBsEaqhLGHwU",
    "name": "Break My Stride",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/f7d4f8b7694e66583089651a466c716f92798847",
    "artist_name": "Matthew Wilder",
    "image_url": "https://i.scdn.co/image/ff8d208d8049907f0def04e4729acc73fb319c4d",
    "artist_id": "3bmFPbLMiLxtR9tFrTcKcP",
    "year": 1984
  },
  {
    "song_id": "4kwFh3RBxE5n9a6FwFw2tl",
    "name": "I Guess That's Why They Call It The Blues",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/fdee251bb8d45188986ba344f624291a26b07056",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/020bd22f85b638d30d785e1f913e97d64dfed7e1",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1984
  },
  {
    "song_id": "7cgSsrrbpdgmiuyuxCUg4u",
    "name": "Love Is A Battlefield",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/74e822bf1ed332a6f183a51f812677b1defc1ec5",
    "artist_name": "Pat Benatar",
    "image_url": "https://i.scdn.co/image/77ac70a2af6e8b2a977dc95f8472c616ed70dc8b",
    "artist_id": "43mhFhQ4JAknA7Ik1bOZuV",
    "year": 1984
  },
  {
    "song_id": "2hQnLzly0jRPpLPp23sA4i",
    "name": "The Warrior",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/d5bb9d12fbbff76bb9fe6f5ba1da246bf8f6bc63",
    "artist_name": "Scandal",
    "image_url": "https://i.scdn.co/image/37a6ad9db214191b81ca061dcc9406dbc632dad0",
    "artist_id": "3RFGnJaDVOyxL9YcFaKatu",
    "year": 1984
  },
  {
    "song_id": "4x1LynYPM9qOtmB7ggoVz0",
    "name": "An Innocent Man",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/8bece4397a64363b01e6a0fc8693e8230a11dcb4",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/dc82b8076e92803f3ff7b469015f2b4540478d5c",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1984
  },
  {
    "song_id": "34kNeqnJJqO4wDiEJdQhxo",
    "name": "Cruel Summer",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/1b70de9630bca9ec14068dcbe3ef23cdc0eb7651",
    "artist_name": "Bananarama",
    "image_url": "https://i.scdn.co/image/d89e7dfcfb183146d8d4b26400238ffb99ce805e",
    "artist_id": "3sc7iUG1Wwpwx7bHeZolgx",
    "year": 1984
  },
  {
    "song_id": "3rjONa9b7zc16b8nmXAuCG",
    "name": "Head Over Heels",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/704116a6f59d6d0841c4d44077835bf0b8e7d1fa",
    "artist_name": "The Go-Go's",
    "image_url": "https://i.scdn.co/image/f58bb8489f65e6741dc96248789f174a1312c3bc",
    "artist_id": "2mG8HHQ9S9kcbjcrb5N1FE",
    "year": 1984
  },
  {
    "song_id": "6RXBjCDKM6vAGiX4ojUD1E",
    "name": "Cover Me",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/379b9cf599817d02efc5c7516713a9c11f83fcf3",
    "artist_name": "Bruce Springsteen",
    "image_url": "https://i.scdn.co/image/f802e7b4c67df157d114ec7c64232de898f9613e",
    "artist_id": "3eqjTLE0HfPfh78zjh6TqT",
    "year": 1984
  },
  {
    "song_id": "0b5eH3euyAs3pyfoebeWaZ",
    "name": "Hold Me Now",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/5a370164d8517c0e46f78f64a6e385257a371d3c",
    "artist_name": "Thompson Twins",
    "image_url": "https://i.scdn.co/image/2f15a184334389d2ce071071f26a2bb4f1fc9cbc",
    "artist_id": "5jVeqi3PNaTOajfvBa4uFn",
    "year": 1984
  },
  {
    "song_id": "179SfVFJ0ZN41toTxnpgRD",
    "name": "The Glamorous Life",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/f0ca28abe1553f4a8de7cca114870d1fe7c9f1a2",
    "artist_name": "Sheila E.",
    "image_url": "https://i.scdn.co/image/74e52937fb9f064531cf85c427f4a80831f1586e",
    "artist_id": "6OQrOpxSIfPai3cFaN4v4P",
    "year": 1984
  },
  {
    "song_id": "3DDNT4gjsx3RF7jsEf03FH",
    "name": "That's All (Platinum Collection Version)",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/5057b8d93c95b6e39b6db8ecf46c2e97412fc71b",
    "artist_name": "Genesis",
    "image_url": "https://i.scdn.co/image/cfd24b9430ed6f4d8aadc3bbfdd2ddbe108540f8",
    "artist_id": "3CkvROUTQ6nRi9yQOcsB50",
    "year": 1984
  },
  {
    "song_id": "4moXVLw4YS7tVNmjs8v60A",
    "name": "Dancing In The Dark",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/915c5ff22b859647a34ce8a1bafd387ce6dd7fd2",
    "artist_name": "Bruce Springsteen",
    "image_url": "https://i.scdn.co/image/f802e7b4c67df157d114ec7c64232de898f9613e",
    "artist_id": "3eqjTLE0HfPfh78zjh6TqT",
    "year": 1984
  },
  {
    "song_id": "1s5LDroPGxBAFkY5Bth6SK",
    "name": "Think Of Laura",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/4f73399050d5621e340bdd4d964c959929c1cec5",
    "artist_name": "Christopher Cross",
    "image_url": "https://i.scdn.co/image/a403b39d806923e3c2642fa81faa28812bdeb148",
    "artist_id": "695W5F2Ih8dYahLdjVOIoH",
    "year": 1984
  },
  {
    "song_id": "5Al4Jbso4nYdZgJ2gSCEzT",
    "name": "When You Close Your Eyes",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/6ccfaaadfa35fc00ee8a20ab19d105686196ef91",
    "artist_name": "Night Ranger",
    "image_url": "https://i.scdn.co/image/a94eb2cf4ee7ef07634028ae33b838f30eff5591",
    "artist_id": "1Ha0Fz4i0d4gu5fZbhBCtH",
    "year": 1984
  },
  {
    "song_id": "2TIKxe1afsEcYWBvyTgQt3",
    "name": "Jump (For My Love)",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/05daa97eb9a108e9d51449830a6d5af9faf5aad1",
    "artist_name": "The Pointer Sisters",
    "image_url": "https://i.scdn.co/image/3793f1521cf957302683926a021cfd2d7f90763f",
    "artist_id": "2kreKea2n96dXjcyAU9j5N",
    "year": 1984
  },
  {
    "song_id": "1vFO4WIvw4fQ0oHkyt4rEC",
    "name": "Hello",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/83164875865ab977713da2f9b59adfd1c3b433d3",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/b72157e4af3b1631917698dabffe089f53699490",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1984
  },
  {
    "song_id": "5P6Wv0hlcnczhElizTY0UE",
    "name": "Missing You",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/7ff003da3d042b4a2acae608bf3607cde5c94321",
    "artist_name": "John Waite",
    "image_url": "https://i.scdn.co/image/4f6f0ff63933066dfd9ac2d2afb985db95641de5",
    "artist_id": "2TPyCsRoh2tjeZLTQ2ojlj",
    "year": 1984
  },
  {
    "song_id": "4BNOhILWVg7NThwsNhpSs8",
    "name": "Church of the Poison Mind",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/a7d6f16a556f621b4c13ded326f2eacf1c4170fa",
    "artist_name": "Culture Club",
    "image_url": "https://i.scdn.co/image/916e3dbc9d2bfa3adf5836d622f17814a2038748",
    "artist_id": "6kz53iCdBSqhQCZ21CoLcc",
    "year": 1984
  },
  {
    "song_id": "76zNJvuD3JtuiaWH0OdrWM",
    "name": "Rock Me Tonite",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/bce83dc188fa43e6ac3816da2811a404a7217e72",
    "artist_name": "Billy Squier",
    "image_url": "https://i.scdn.co/image/13b737924885b4e7a0486f3d3054c4e6b3740271",
    "artist_id": "3Fz2GbraVXhcpXnoi2Oe1r",
    "year": 1984
  },
  {
    "song_id": "5v23eOAHQNRi67BWtzHajx",
    "name": "The Longest Time",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/641b649fb83af5b1f61863a2874c6e4569abaadd",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/f98a83cb6ecab4a8e62e3cd3fe96d5b44d866bdf",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1984
  },
  {
    "song_id": "2bZswvNGESo7MKeg7SQ9rp",
    "name": "Say It Isn't So - Remastered",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/94300321ff25b8dfbfefd296f306f3d433bb18ac",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/acf37eb470cb91b144527bbf0192699f91610f72",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1984
  },
  {
    "song_id": "4wX7kEkj1UgFDMFbZIR3kZ",
    "name": "Love Somebody",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/320dfe0a85bb25495e39f2f68201996170d1fb72",
    "artist_name": "Rick Springfield",
    "image_url": "https://i.scdn.co/image/93741d3cff61ab40cd7be0765ae47de0ec74137f",
    "artist_id": "6IFXsrXBpwbIqtOUOiAa3p",
    "year": 1984
  },
  {
    "song_id": "7ADFqIjMLrhRXyWoxeEhGf",
    "name": "Adult Education - Remastered",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/877a3a8410f46a3c6cebbec3e77d6b658eee76b5",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/acf37eb470cb91b144527bbf0192699f91610f72",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1984
  },
  {
    "song_id": "6lKuAmGY6FB0vaNOzrbQ5p",
    "name": "Time Will Reveal",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/71304558c3ca9d80793992b90a59d687929e6473",
    "artist_name": "DeBarge",
    "image_url": "https://i.scdn.co/image/c4c3e9e8c83c0251c82f50d0bfeda1669df501ad",
    "artist_id": "6is2U7I1jlI8PjxNZOHIMV",
    "year": 1984
  },
  {
    "song_id": "7z8k6KAvjHX3RtkYNhIC9B",
    "name": "Magic",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/45ae3c373393607cfb8532bc730c0201f28d9986",
    "artist_name": "The Cars",
    "image_url": "https://i.scdn.co/image/6fc51bbde1b27cd23ce3e244d6951d84b3570721",
    "artist_id": "6DCIj8jNaNpBz8e5oKFPtp",
    "year": 1984
  },
  {
    "song_id": "4InXjGfCi4hJVWlqPuY6Im",
    "name": "Say Say Say",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/f88480d4549c8328c8fb494d1aa42d4af8aacfa9",
    "artist_name": "Paul McCartney",
    "image_url": "https://i.scdn.co/image/ff08e8ea938287f942e39f7b097f9bf7fd8d8c18",
    "artist_id": "4STHEaNw4mPZ2tzheohgXB",
    "year": 1984
  },
  {
    "song_id": "40CPeIs7x2gKmSzNCvgLiU",
    "name": "Give It Up",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/3c35b074e49026ba9919e47986ba0614f41aca2b",
    "artist_name": "KC & The Sunshine Band",
    "image_url": "https://i.scdn.co/image/f87fb5309a0aa3f1bfc3e9bf4465be666c9687ac",
    "artist_id": "3mQBpAOMWYqAZyxtyeo4Lo",
    "year": 1984
  },
  {
    "song_id": "2P0COBqN70Hd2KYQj4KRjk",
    "name": "All Night Long (All Night)",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/b96619ead446ed553fa23b5c77cc92155792baf3",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1984
  },
  {
    "song_id": "1fF2dvBsbTk2wrOEaR6f5B",
    "name": "Sad Songs (Say So Much)",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/0a4ec4ba7901059b18865af143e4150b3e4da937",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/bf3efbd0d4962d1e76b1ae3ed50330356166f70b",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1984
  },
  {
    "song_id": "3pOgDNzXKISD4zCj3mQ6t9",
    "name": "Sister Christian",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/b726f9a00df13065e0fda140e9a7eeafaf7b3780",
    "artist_name": "Night Ranger",
    "image_url": "https://i.scdn.co/image/b66a1309f57ed469c9ca70e7e869fa1b9c8a6882",
    "artist_id": "1Ha0Fz4i0d4gu5fZbhBCtH",
    "year": 1984
  },
  {
    "song_id": "2iitSsbNPH0EJPyyEpvSgg",
    "name": "Union Of The Snake",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/4e1a8b28b931ace05bfb0950c01ac977855bc446",
    "artist_name": "Duran Duran",
    "image_url": "https://i.scdn.co/image/69de6c0b3ddca19bb375ecfb8ac144904122136c",
    "artist_id": "0lZoBs4Pzo7R89JM9lxwoT",
    "year": 1984
  },
  {
    "song_id": "5mooLgb601ws7PbKe2m1HQ",
    "name": "Automatic",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/2cb155c3d5ded9b54ff4d4095d9f48032137a3cd",
    "artist_name": "The Pointer Sisters",
    "image_url": "https://i.scdn.co/image/4c8e62320eb1f5f9388b7cad1b26cbb50a2cae38",
    "artist_id": "2kreKea2n96dXjcyAU9j5N",
    "year": 1984
  },
  {
    "song_id": "0SO4oJBmDNjniJwjlHaIhO",
    "name": "Infatuation (Remastered Album Version)",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/cbbb524001e4521f41d3ea98c03e580875ae873c",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/79fa6c9588f7bec80db5db5d06bae424a118e57b",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1984
  },
  {
    "song_id": "66Dcq5SlqgLArzsYcRedq1",
    "name": "Dance Hall Days",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/487c8523448876659208a6da3409bb1c49c4dd3c",
    "artist_name": "Wang Chung",
    "image_url": "https://i.scdn.co/image/ba2361a8ab49c56e14babf28a81de0c4f376d11a",
    "artist_id": "6Zh3xrWlA0SA9Fsfj9AVwm",
    "year": 1984
  },
  {
    "song_id": "2bQI01HjqtcZmqAVTjE8CA",
    "name": "Wrapped Around Your Finger",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/6645ca7dde187c3a0a3a8cf9870efb69a8e2ebf2",
    "artist_name": "The Police",
    "image_url": "https://i.scdn.co/image/85e2da9818d1d2f00aa380bc142818121178aabf",
    "artist_id": "5NGO30tJxFlKixkPSgXcFE",
    "year": 1984
  },
  {
    "song_id": "0FCgqHNdcbhGIEmU8r6ZF1",
    "name": "Got A Hold On Me",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/c4b3d1840a677f86315d08e3486db83ea3447532",
    "artist_name": "Christine McVie",
    "image_url": "https://i.scdn.co/image/5930078132dbcdce9cbc124fa0df60788b535a4a",
    "artist_id": "6RCsx4p5smZHYz2P5HLcL2",
    "year": 1984
  },
  {
    "song_id": "3L9SXYL2XOl0JJ754YgBsS",
    "name": "Stuck On You",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/d3e8148f04bdd3e9bfd4815994178e00ecfd4fd5",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/564a136f98251422b0f0507cdc3205c3700f94eb",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1984
  },
  {
    "song_id": "1ytzrpWRCpUasHmT5P4LRt",
    "name": "Twist Of Fate",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/b9a6523230052e4855682eefd612190c7e7b06e1",
    "artist_name": "Olivia Newton-John",
    "image_url": "https://i.scdn.co/image/c773435fa3c66178e352424a80e9ab0d685a7a0b",
    "artist_id": "4BoRxUdrcgbbq1rxJvvhg9",
    "year": 1984
  },
  {
    "song_id": "0xpfe8QQWplGO09WN3vdN0",
    "name": "Hard Habit To Break - Live in Chicago, Il. - 1999",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/169e49db4f1eb3fbdccbcdc0d77c2505de6eff72",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/3694d6003adc715fd04725d7ffcd0ed1b52836dc",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1984
  },
  {
    "song_id": "6ISiiE2puBueLDGhsItsuV",
    "name": "I Still Can't Get Over Loving You",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/cec39dd55d9e10e29c8b766d50dd5fa19d751a06",
    "artist_name": "Ray Parker, Jr.",
    "image_url": "https://i.scdn.co/image/81c44a0338fa0326285e62ddeccd21631214e1ff",
    "artist_id": "0NyzfcGDZZ6GM25EBG9BYK",
    "year": 1984
  },
  {
    "song_id": "4duMzlV6ZXExTL0L3o6PH9",
    "name": "Undercover Of The Night - Remastered",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/19bf87752239dae9ff6cdd02f444b32457fb2b80",
    "artist_name": "The Rolling Stones",
    "image_url": "https://i.scdn.co/image/6e4eed337cef4856ab2a07dfb272acfedae24e2b",
    "artist_id": "22bE4uQ6baNwSHPVcDxLCe",
    "year": 1984
  },
  {
    "song_id": "0ce2SIAFrEsPCiEXpv9X11",
    "name": "On the Dark Side",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/6b490897363f6dad83b9b28cc81b13095f11f509",
    "artist_name": "John Cafferty",
    "image_url": "https://i.scdn.co/image/6c995cd7837bc6c4aec4ea70b9402f08237a9101",
    "artist_id": "0fPK3TAseRcy6sAS8U8pEw",
    "year": 1984
  },
  {
    "song_id": "3IGF7wqKKovBhyPx9HaYgw",
    "name": "New Moon On Monday",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/4a44b0c717bdf0b90c5096de876d0ff29af10eca",
    "artist_name": "Duran Duran",
    "image_url": "https://i.scdn.co/image/349e764d4602cf253c5102b78495b745cccc2424",
    "artist_id": "0lZoBs4Pzo7R89JM9lxwoT",
    "year": 1984
  },
  {
    "song_id": "0uBwcaTkY1BPpIZhxuhS67",
    "name": "To All the Girls I've Loved Before",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/ea52c694dc073a5417ed0c806e4c0c4dfe23e2ec",
    "artist_name": "Julio Iglesias",
    "image_url": "https://i.scdn.co/image/9af5ed25c66e585c35545386303f51dd0248b647",
    "artist_id": "4etuCZVdP8yiNPn4xf0ie5",
    "year": 1984
  },
  {
    "song_id": "5eAenB0ByMTzoobdTGmtJN",
    "name": "Cum on Feel the Noize",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/fa40a0838ffb5c7013ee5613c1feec4adafe7d59",
    "artist_name": "Quiet Riot",
    "image_url": "https://i.scdn.co/image/5a98496c1b244d49baade63f984b98511f7bb9bf",
    "artist_id": "1dLWg6m8RRhizsdqJbhyj3",
    "year": 1984
  },
  {
    "song_id": "3WuK4KMYVkr1CMHZcZm6G6",
    "name": "Major Tom (Coming Home) [In the Style of Peter Schilling] {Karaoke Lead Vocal Version}",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/949d065ee1c205f1bd42ed427374881cb796e572",
    "artist_name": "The Karaoke Channel",
    "image_url": "https://i.scdn.co/image/d2283d639d394d15b78c049f92571fcb5a6678e0",
    "artist_id": "5UloVKzUNJcjORzhhTWWiJ",
    "year": 1984
  },
  {
    "song_id": "5DeXQ3qADuDmqsosnZAK3o",
    "name": "I Want To Know What Love Is (2008 Remastered LP Version)",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/1fdc19fdbf42298cc627db5c75ee8de92fa9ba05",
    "artist_name": "Foreigner",
    "image_url": "https://i.scdn.co/image/27c9e093bd5b9817deb910b41e9094388a3f2962",
    "artist_id": "6IRouO5mvvfcyxtPDKMYFN",
    "year": 1985
  },
  {
    "song_id": "5XsMz0YfEaHZE0MTb1aujs",
    "name": "Wake Me up Before You Go-Go",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/d28030c29852f1b235207b91e1fd0be66d0e4309",
    "artist_name": "Wham!",
    "image_url": "https://i.scdn.co/image/862a843d83bb8cdfa87d82574ac3a4bb6b70c88f",
    "artist_id": "5lpH0xAS4fVfLkACg9DAuM",
    "year": 1985
  },
  {
    "song_id": "2olVm1lHicpveMAo4AUDRB",
    "name": "The Power Of Love - 2006 Digital Remaster",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/da5effaa3c99b59e3bdc077b8f3d46a820bc8722",
    "artist_name": "Huey Lewis & The News",
    "image_url": "https://i.scdn.co/image/57f7e47cbf6c3da3e2e451221bbe4784df39d7b0",
    "artist_id": "7A9yZMTrFZcgEWAX2kBfK6",
    "year": 1985
  },
  {
    "song_id": "5WwqdeavrQrbeAMDxGawse",
    "name": "Can't Fight This Feeling",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/d0a177d8ecbf8d818af55cfe920490f548c3e1e4",
    "artist_name": "REO Speedwagon",
    "image_url": "https://i.scdn.co/image/08c18c88a7a1c35353b30de93131b55a97bed6fa",
    "artist_id": "55vs7NT1KxcFjbMC4y202E",
    "year": 1985
  },
  {
    "song_id": "5EU1mh0C4Km3k6vGvIDN2J",
    "name": "Summer Of '69",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/a19b41397257b8603d95e41e588d77ae5a5e9bf0",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/0600158bbf6cf233cf0f60a5c27911ae91850f06",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1985
  },
  {
    "song_id": "1iaIEmZHrjGzWUmJ9BaFr6",
    "name": "Like A Virgin",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/a4885223b94761b000a943671f367233af3be480",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1985
  },
  {
    "song_id": "22sLuJYcvZOSoLLRYev1s5",
    "name": "Material Girl",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/bd309c9b1d3fdbc9253cf17dc54d3457f39127dc",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1985
  },
  {
    "song_id": "1lx8ddGT5wCD6W2xmLeRKG",
    "name": "Saving All My Love for You",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/c41b0d26ef306a1af0e2fd322ff1917b60d3c50f",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/b2143ca3b5d5cef154374ac053a7b87b8b50ae09",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1985
  },
  {
    "song_id": "1A2PWRltFrX8iB8IP3CUgo",
    "name": "St. Elmos Fire [Man In Motion]",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/fc69742e3ec9d11a37a344b59ae1862eab6fbda1",
    "artist_name": "John Parr",
    "image_url": "https://i.scdn.co/image/765fc152d9732d7902652380f95d721e257ca8a9",
    "artist_id": "3Z1tibPKhUYWCno0IYicCN",
    "year": 1985
  },
  {
    "song_id": "2Z7bUp8cJKTM9eoj7hvWXG",
    "name": "You're The Inspiration",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/ed9a7fc2bcd6f4288644b0b7af7de0e732422fcf",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/b0f2e7737a58f945ceb0b883c17bf9439d96b328",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1985
  },
  {
    "song_id": "665Jxlgi1HamPKbW1vwzx4",
    "name": "We Belong",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/5874fc63d845c69a022e91da0160a607a534cb3e",
    "artist_name": "Pat Benatar",
    "image_url": "https://i.scdn.co/image/a510a2aa97d87af1f1735bd0a699d657d6d0cc73",
    "artist_id": "43mhFhQ4JAknA7Ik1bOZuV",
    "year": 1985
  },
  {
    "song_id": "6lOPbNUxDvkUkWpJLebgs7",
    "name": "Raspberry Beret",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/78781065475b12dcd0b4e684ae511300ff474566",
    "artist_name": "Prince & The Revolution",
    "image_url": "https://i.scdn.co/image/49db086c1d635a44cc1662c5ca9fe99a5ed3d1f7",
    "artist_id": "6C3ZGU59R9XhxQ1KRU5Q1O",
    "year": 1985
  },
  {
    "song_id": "7Mz9qPB9hp1rXBgXvrVAST",
    "name": "We Don't Need Another Hero (Thunderdome)",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/ca63c07e5b76f8b4cf4956ffc98b7c1a6cc639ca",
    "artist_name": "Tina Turner",
    "image_url": "https://i.scdn.co/image/a906ec1ce04456b99cb23a2344e1f392ebf635a1",
    "artist_id": "1zuJe6b1roixEKMOtyrEak",
    "year": 1985
  },
  {
    "song_id": "57Uplvs802NVw2msQIMS4P",
    "name": "Born in the U.S.A.",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/acfbcf01a323f112cab74bbfb484c2cc7573224f",
    "artist_name": "Bruce Springsteen",
    "image_url": "https://i.scdn.co/image/5736647ed520730ef1de5a59b74caa55e382ab0e",
    "artist_id": "3eqjTLE0HfPfh78zjh6TqT",
    "year": 1985
  },
  {
    "song_id": "4yqtwO7MQIIXqoiRBPHAgR",
    "name": "Money For Nothing",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/fc3874117073a350b3d637f70101496738399395",
    "artist_name": "Dire Straits",
    "image_url": "https://i.scdn.co/image/1edaee1ba42c64573d59495120acd7ca3208b7e9",
    "artist_id": "0WwSkZ7LtFUFjGjMZBMt6T",
    "year": 1985
  },
  {
    "song_id": "0GIOPJKTSxagq3rq07Ch3U",
    "name": "Heaven",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/b4d29dd091bd279550862cc7d39b9beeaeed17b8",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/a72393c371483a0f23243fefc97ded5c6780c367",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1985
  },
  {
    "song_id": "4rOuAGIlNReuhNunv0eZUp",
    "name": "Smooth Operator - Remastered",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/bfb6cd961b845d639a1a0cce94dd26a603691511",
    "artist_name": "Sade",
    "image_url": "https://i.scdn.co/image/63da82708eba3710a1ba680eaf7257a1d66f4fc8",
    "artist_id": "47zz7sob9NUcODy0BTDvKx",
    "year": 1985
  },
  {
    "song_id": "7JmsQwxDlC89imxewJcnHO",
    "name": "What About Love?",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/91869deb37ad55def4b1fb175407dbe847bf04f1",
    "artist_name": "Heart",
    "image_url": "https://i.scdn.co/image/b81ca2d1bad28d4ba222041f2ac4ab781d380b6c",
    "artist_id": "34jw2BbxjoYalTp8cJFCPv",
    "year": 1985
  },
  {
    "song_id": "0fFCTYF5QEU3K1R4HKfmiE",
    "name": "Head Over Heels",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/7064fdeab9d99dfd8d646fb5c927b5bad613e135",
    "artist_name": "Tears For Fears",
    "image_url": "https://i.scdn.co/image/752a08f143d7b1838c99f67d5c771cd8e13d7533",
    "artist_id": "4bthk9UfsYUYdcFyqxmSUU",
    "year": 1985
  },
  {
    "song_id": "26vKwxgkbm2jcKyjvmScPK",
    "name": "I Feel For You",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/97f089e55ed5daeb3fd70c30a3f0a0da42a22724",
    "artist_name": "Chaka Khan",
    "image_url": "https://i.scdn.co/image/d16dd4ebfdb102d96dd94adf6ba11b53fc9d5d4c",
    "artist_id": "6mQfAAqZGBzIfrmlZCeaYT",
    "year": 1985
  },
  {
    "song_id": "07zkNvtcmPOFlMOXbma13k",
    "name": "Sussudio",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/09c77685ad8a16bfa1516fdd64d9b514e961502d",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/f7ff5eaefc82b479890d1936b976b4fd014c236a",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1985
  },
  {
    "song_id": "0fbOMLSpsvXvBkJBircrcz",
    "name": "Don't Lose My Number",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/db0c6c9cf8874c9d3a8c813dad2c376bf58a791d",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/f7ff5eaefc82b479890d1936b976b4fd014c236a",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1985
  },
  {
    "song_id": "6B4oVElDIKRNcNUary0WG7",
    "name": "Crazy For You",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/e4e9cb2173b98e4ff4d3e9057330c9b0d52af9e4",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1985
  },
  {
    "song_id": "3KIhwcH2ZK8EFvlP8i5G0Y",
    "name": "Private Dancer - Single Edit",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/075f681dd1915f7da4ace78a7d55ed48c9c8cf9f",
    "artist_name": "Tina Turner",
    "image_url": "https://i.scdn.co/image/a906ec1ce04456b99cb23a2344e1f392ebf635a1",
    "artist_id": "1zuJe6b1roixEKMOtyrEak",
    "year": 1985
  },
  {
    "song_id": "76798uYU1DhBRVWxSo1bhY",
    "name": "Everybody Wants To Rule The World",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/bef5e8a20c62af65d29f83d13559a3d5a044aa31",
    "artist_name": "Tears For Fears",
    "image_url": "https://i.scdn.co/image/752a08f143d7b1838c99f67d5c771cd8e13d7533",
    "artist_id": "4bthk9UfsYUYdcFyqxmSUU",
    "year": 1985
  },
  {
    "song_id": "7o67roCVsFiCt7Cf0ZLOJq",
    "name": "Out of Touch - Remastered",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/3919f783f726066d9390c67f413f7f44ef477c12",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/acf37eb470cb91b144527bbf0192699f91610f72",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1985
  },
  {
    "song_id": "3lCMAtfFDcJj9nzZB3ayeB",
    "name": "Things Can Only Get Better",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/d00650e95ca298ad30b1bf635f5b2a9b9cad05d3",
    "artist_name": "Howard Jones",
    "image_url": "https://i.scdn.co/image/c053ce7fa4e2af0ba1490ab26fec4bfb84dc60d6",
    "artist_id": "6loBF9iQdE11WSX29fNKqY",
    "year": 1985
  },
  {
    "song_id": "40JVyQzjWxUzymi6pcjdWn",
    "name": "Separate Lives (Love Theme From White Nights)",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/eb9e9c17ba3481073493da59b041f5cde24e39f5",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/0589331408d694c0f2da59a5854ef3d027e3dd4a",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1985
  },
  {
    "song_id": "23vGFULTo0MgSlzEs4Fhm2",
    "name": "One Night In Bangkok - Single Version",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/edb45952f6e0997305baa23bfb6c305843d8bc00",
    "artist_name": "Murray Head",
    "image_url": "https://i.scdn.co/image/97eefa95d48d832d086ac5c7c80a313902f85a1f",
    "artist_id": "479Yp6DvyXoIaCssAxB4QR",
    "year": 1985
  },
  {
    "song_id": "5vuWELDzHEtD5K7yADIBpz",
    "name": "Voices Carry",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/5abd6b10f1e9d586c5ac79477e836f177daecd03",
    "artist_name": "'Til Tuesday",
    "image_url": "https://i.scdn.co/image/c31101ccb8addffc6eb84924c5af411f78eab1a7",
    "artist_id": "1L0y9srZMyh9XUnYGv37IP",
    "year": 1985
  },
  {
    "song_id": "3g3PQ6TTd6Dnb4zt2d8B9S",
    "name": "Everything She Wants",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/d0d4c0813a625a996b369f9ddaa3726c749ecc27",
    "artist_name": "Wham!",
    "image_url": "https://i.scdn.co/image/862a843d83bb8cdfa87d82574ac3a4bb6b70c88f",
    "artist_id": "5lpH0xAS4fVfLkACg9DAuM",
    "year": 1985
  },
  {
    "song_id": "4bSWx86yHmy6OOMsQPU60F",
    "name": "I'm On Fire",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/c0aae9557da352bae2b7841a7cd8e0ca17501866",
    "artist_name": "Bruce Springsteen",
    "image_url": "https://i.scdn.co/image/f802e7b4c67df157d114ec7c64232de898f9613e",
    "artist_id": "3eqjTLE0HfPfh78zjh6TqT",
    "year": 1985
  },
  {
    "song_id": "4B2r9HXgUqRcPwUG3BYPlc",
    "name": "Dress You Up",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/96f3389bd5fee7478b36054153f6ec72345392af",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1985
  },
  {
    "song_id": "5IlaV4UNciCTDGxWVPfMKL",
    "name": "One More Night",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/9ab369b568c2412670b8a5e94c3d9b427fd3259c",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/f7ff5eaefc82b479890d1936b976b4fd014c236a",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1985
  },
  {
    "song_id": "3SP1zxzi8drzJXahGGgdIf",
    "name": "Part-Time Lover",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/ff05b085e961efd0278f42c1348adf802f99f006",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/a4f6af092440a5f3dfa85feb329027bc9f21a149",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1985
  },
  {
    "song_id": "2fnuoul0x4g8ZYkAYNgAgZ",
    "name": "Glory Days",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/01f121151ce27d41f2b3e74d6d820c1fdcd42b8c",
    "artist_name": "Bruce Springsteen",
    "image_url": "https://i.scdn.co/image/f802e7b4c67df157d114ec7c64232de898f9613e",
    "artist_id": "3eqjTLE0HfPfh78zjh6TqT",
    "year": 1985
  },
  {
    "song_id": "6OqvA8eljqHDFTso8szjCe",
    "name": "Easy Lover",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/7958134255d460661beaf8bb3eaf49aa4e3235c1",
    "artist_name": "Philip Bailey",
    "image_url": "https://i.scdn.co/image/ff8d208d8049907f0def04e4729acc73fb319c4d",
    "artist_id": "6ZNeppgfBLPUyugks9Yn1u",
    "year": 1985
  },
  {
    "song_id": "2Vb1OqfEfmpJyCL6iyMZNO",
    "name": "Suddenly",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/ec804ac896cb3ada102a3e60c4e7104c8328a4fb",
    "artist_name": "Billy Ocean",
    "image_url": "https://i.scdn.co/image/5b6a8a2899502272dbb5012125b3f11f97a78cd1",
    "artist_id": "5IDs1CK15HegSAhGEbSYXo",
    "year": 1985
  },
  {
    "song_id": "090rX2ouW974AT3dFCxJpc",
    "name": "Axel F",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/03d92f6e63bc4c4f19759fd9d7bef1f4eadfe7aa",
    "artist_name": "Harold Faltermeyer",
    "image_url": "https://i.scdn.co/image/c588159904ab718d104039bb5690449019b10bee",
    "artist_id": "7Cf73Z3RZQTH4V69GSDxnv",
    "year": 1985
  },
  {
    "song_id": "4sr4NdrPfds9Qfors9UkiO",
    "name": "Solid",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/a387f09df005a2f3feeda527d3d3ac887cab8fe6",
    "artist_name": "Ashford & Simpson",
    "image_url": "https://i.scdn.co/image/5c1ef972dd657d26033f37fc14a1627f9b53daf8",
    "artist_id": "2EURsXo9qlt1aMWlviGCRi",
    "year": 1985
  },
  {
    "song_id": "0IpfTMKENGLJcULkMnU8Rb",
    "name": "Everytime You Go Away",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/e773f2eed752f67eecd01d5b1290d9816fe53332",
    "artist_name": "Paul Young",
    "image_url": "https://i.scdn.co/image/a4b7e08a1354c47a94951c9adea03e5597d76727",
    "artist_id": "6rqU9HQ57NYGBnBzbrY3a4",
    "year": 1985
  },
  {
    "song_id": "0T6RXLRYzXi3nXq7ZdU74y",
    "name": "Never Surrender",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/4e7928ae810d9dd2fbfba6d8470a944d23ba4104",
    "artist_name": "Corey Hart",
    "image_url": "https://i.scdn.co/image/6fb6c97436280dbefafea55c2784d7a4ad7c6535",
    "artist_id": "0smy8yDrRoI4CnhpOuthg0",
    "year": 1985
  },
  {
    "song_id": "0t6DdFmFQhQYWhmfa5FJer",
    "name": "You Give Good Love",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/f7188659f4a27b87f8edd24e8a6865379e6286f6",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/b2143ca3b5d5cef154374ac053a7b87b8b50ae09",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1985
  },
  {
    "song_id": "6YytY6PPvAEp1MluJ49nP0",
    "name": "Better Be Good To Me",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/516ff7fc65c994a52427ccbcaff8155e0c3bc989",
    "artist_name": "Tina Turner",
    "image_url": "https://i.scdn.co/image/a906ec1ce04456b99cb23a2344e1f392ebf635a1",
    "artist_id": "1zuJe6b1roixEKMOtyrEak",
    "year": 1985
  },
  {
    "song_id": "13xKh7WqwxzEFYhYCJJwHg",
    "name": "Lovergirl",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/fd655396e9e067867f609402aa9a1b5d85eea416",
    "artist_name": "Teena Marie",
    "image_url": "https://i.scdn.co/image/b1edf6d454c958f30d81a020406b0b8aaad0c54f",
    "artist_id": "61UT1Zj9dFgPAjZfwnsqsb",
    "year": 1985
  },
  {
    "song_id": "4TIZrHkVMOnEb0L3Vj8zUc",
    "name": "The Boys Of Summer",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/6ff9ac3945b51af84df265b4e332164cd4331066",
    "artist_name": "Don Henley",
    "image_url": "https://i.scdn.co/image/016706a53ba857f7d6685b98d41dcc90fc62a5e4",
    "artist_id": "5dbuFbrHa1SJlQhQX9OUJ2",
    "year": 1985
  },
  {
    "song_id": "7ov1nZ2QZc3LIuCXXERZP0",
    "name": "Angel",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/6570d2ea94546a73b985d747ade59be978385d41",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/0d8162cc6aa964fd833b393c7103333e1267d087",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1985
  },
  {
    "song_id": "41leurKH9keV9KO7bk83uz",
    "name": "Cherish - Single Version",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/fc86662ca666656bad178ba5aab3ceb3ecc964eb",
    "artist_name": "Kool & The Gang",
    "image_url": "https://i.scdn.co/image/2becb579228ace3f3ff35f05ab9e25453388a64f",
    "artist_id": "3VNITwohbvU5Wuy5PC6dsI",
    "year": 1985
  },
  {
    "song_id": "0wWNbkx0BR4CBJrTjG0sJ4",
    "name": "Shout",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/3fb816946d80eacb0a2bf6a983cb20be5e4a890b",
    "artist_name": "Tears For Fears",
    "image_url": "https://i.scdn.co/image/752a08f143d7b1838c99f67d5c771cd8e13d7533",
    "artist_id": "4bthk9UfsYUYdcFyqxmSUU",
    "year": 1985
  },
  {
    "song_id": "0VMGij4wSGBM5pSTcqjxeD",
    "name": "Sea Of Love - 2006 Remastered",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/b9ed927cb11d4325565a9afb19e4e5ea2fee1f54",
    "artist_name": "The Honeydrippers",
    "image_url": "https://i.scdn.co/image/b27c3f865c77fbc9ca39395a852904ef95e58a92",
    "artist_id": "7J7YRBSoiXpdTmcbkKZL2C",
    "year": 1985
  },
  {
    "song_id": "41th2RVnBYzhP4EQse7jAC",
    "name": "Neutron Dance - from \"Beverly Hills Cop\"",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/7a64cfa9067c7b8a7bd447eb900c1d10f983150b",
    "artist_name": "The Pointer Sisters",
    "image_url": "https://i.scdn.co/image/4c8e62320eb1f5f9388b7cad1b26cbb50a2cae38",
    "artist_id": "2kreKea2n96dXjcyAU9j5N",
    "year": 1985
  },
  {
    "song_id": "6BtlcfCWVBUEZwFaNJ0QMO",
    "name": "I Can't Hold Back",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/968ed87773e7dc60642de09c1c0880ead1b5e75c",
    "artist_name": "Survivor",
    "image_url": "https://i.scdn.co/image/d2fce5df238dd2928bd67adecba677e56743f7a4",
    "artist_id": "26bcq2nyj5GB7uRr558iQg",
    "year": 1985
  },
  {
    "song_id": "2bfXIY8f38PYFStQRDVEPS",
    "name": "Lay Your Hands On Me",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/bf3f85bb2e38646a1e8df19096ee813774d0e503",
    "artist_name": "Thompson Twins",
    "image_url": "https://i.scdn.co/image/063ca4ae5cca66d8e0b507c6349ad3c99a6bfa6f",
    "artist_id": "5jVeqi3PNaTOajfvBa4uFn",
    "year": 1985
  },
  {
    "song_id": "3i749PIamGcEauiV4s1ZN4",
    "name": "In My House",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/cfa17492ee2a372b8b552fe064526ad04e9d9446",
    "artist_name": "Mary Jane Girls",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "7vRMMs8yrKf4PKUpUllMkr",
    "year": 1985
  },
  {
    "song_id": "5BqUktDta4CQRT7fHK6c4x",
    "name": "Freedom",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/5a6fc0982679b854cc61f2e3c1e884dc7c6378f9",
    "artist_name": "Wham!",
    "image_url": "https://i.scdn.co/image/b5120637382e48dcebab77f0da40f9e69fb7aeea",
    "artist_id": "5lpH0xAS4fVfLkACg9DAuM",
    "year": 1985
  },
  {
    "song_id": "4FwiCTQiiopyzLJ14cGCsW",
    "name": "Too Late For Goodbyes",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/3d6feac1228c8300cd598c591b4d2abeec3559d7",
    "artist_name": "Julian Lennon",
    "image_url": "https://i.scdn.co/image/ace907665c2bc8cf0a677842d8e21fc17af2cc9a",
    "artist_id": "4Tvos0a5rRrBu4Oodu5f79",
    "year": 1985
  },
  {
    "song_id": "23IK8TF2MSiLnuEjaYPfVV",
    "name": "Nightshift",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/9132cfb9c132dc8c2934cd993dc84807084c0dff",
    "artist_name": "Commodores",
    "image_url": "https://i.scdn.co/image/1311b1652c4f549c4864630a9623ac1a84aacd0f",
    "artist_id": "6twIAGnYuIT1pncMAsXnEm",
    "year": 1985
  },
  {
    "song_id": "2OyhKMrcQ38esiohOixowR",
    "name": "Strut",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/5d1d6f9bfa3bb8f677129eb0ad246469aca3b16b",
    "artist_name": "Sheena Easton",
    "image_url": "https://i.scdn.co/image/5c3e8b2eabdbbae43c3de9da7d490d407212e465",
    "artist_id": "5dcOK4stT4JDkP6Dqhbz5s",
    "year": 1985
  },
  {
    "song_id": "2dRBx1qseaMrnbkfCjjVJK",
    "name": "If You Love Somebody Set Them Free",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/358df394da77b7de605afe8828e7dd1db44cf7d8",
    "artist_name": "Sting",
    "image_url": "https://i.scdn.co/image/8fc44fccbd1c5e9d91273a2bb87e8579c3152e4f",
    "artist_id": "0Ty63ceoRnnJKVEYP0VQpk",
    "year": 1985
  },
  {
    "song_id": "5fSVerSB7TpXkUF9BxTUcP",
    "name": "Some Like It Hot - 2005 Remastered Version",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/56fc22df04fb96a5b9ff78ad0d95f5328b798ac4",
    "artist_name": "The Power Station",
    "image_url": "https://i.scdn.co/image/9eb42c4cdc3a0d5e86808acac4062ba65f69c0a3",
    "artist_id": "1EemADz12kjlGwkpY4EINv",
    "year": 1985
  },
  {
    "song_id": "1lJZSsMoWEbrgaY6CxPMf8",
    "name": "Would I Lie To You? - Remastered Version",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/a461bc3e710d4210a54ef80f2cf3208a8c9159e0",
    "artist_name": "Eurythmics",
    "image_url": "https://i.scdn.co/image/c553eef9887546379c441b96fe1cbeaaad609433",
    "artist_id": "0NKDgy9j66h3DLnN8qu1bB",
    "year": 1985
  },
  {
    "song_id": "4H3vuLX59XPqdtTpIesGyS",
    "name": "California Girls",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/e30bc3f51ae973e7394acc5f9fcf3e0285274b29",
    "artist_name": "David Lee Roth",
    "image_url": "https://i.scdn.co/image/4149ac18ebe1d053c35916d594e3dc677a0ec1d2",
    "artist_id": "0KyCXNSa7ZMb5LydfKbLG3",
    "year": 1985
  },
  {
    "song_id": "5BugaNbZM9Lc7qUtd5FDir",
    "name": "Run To You",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/01880385cb4e2b714085cd6a4ef7f80e8267d6d0",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/a72393c371483a0f23243fefc97ded5c6780c367",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1985
  },
  {
    "song_id": "10b7RJ4vWHQFxpTikfsf6A",
    "name": "The Search Is Over",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/2f7469849349559d98eb07a903a2907e13b3f67f",
    "artist_name": "Survivor",
    "image_url": "https://i.scdn.co/image/d2fce5df238dd2928bd67adecba677e56743f7a4",
    "artist_id": "26bcq2nyj5GB7uRr558iQg",
    "year": 1985
  },
  {
    "song_id": "4ybDW0xfRBCPwsjwFOUzZW",
    "name": "No More Lonely Nights",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/d400bd6d142727ab1501083f55d4ade7ad444ad0",
    "artist_name": "Paul McCartney",
    "image_url": "https://i.scdn.co/image/a5367d4c896e254d70fce0fa3c5777f3729fd9d1",
    "artist_id": "4STHEaNw4mPZ2tzheohgXB",
    "year": 1985
  },
  {
    "song_id": "6hwnwvDfsowuiCVGGA6twB",
    "name": "Method of Modern Love - Remastered",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/f0d33423b2145163d6158ce6ca1b84ca65b9f8d6",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/82216e16eff844df7916f5024dd7041403b6c82a",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1985
  },
  {
    "song_id": "16HsUjdzwuZesFyO7bTi6t",
    "name": "Fresh",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/b49cc636b8981329a2d1e073b1cdf10902ebdd02",
    "artist_name": "Kool & The Gang",
    "image_url": "https://i.scdn.co/image/a626648673372224bc7266c9bd212ba7b0c78209",
    "artist_id": "3VNITwohbvU5Wuy5PC6dsI",
    "year": 1985
  },
  {
    "song_id": "6oPtHUtXhUMrMHjd05aBfC",
    "name": "Fortress Around Your Heart",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/7257db0b8892a124634375abce421e2e5f763c72",
    "artist_name": "Sting",
    "image_url": "https://i.scdn.co/image/92ccc381e02fbe3387d072b0a3830341c554794f",
    "artist_id": "0Ty63ceoRnnJKVEYP0VQpk",
    "year": 1985
  },
  {
    "song_id": "6N79OtOCGIEthvMy2AridT",
    "name": "Lonely Ol' Night",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/e0411a3e7861f568f93686c3707456b25e59123f",
    "artist_name": "John Mellencamp",
    "image_url": "https://i.scdn.co/image/2252c94914f263d66846123192aae9ce9ade7082",
    "artist_id": "3lPQ2Fk5JOwGWAF3ORFCqH",
    "year": 1985
  },
  {
    "song_id": "1noELWXA5Cv0Ci4j8crir6",
    "name": "Sugar Walls",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/78bb8fcdccfbc7bd71e6286de247ac24befd3356",
    "artist_name": "Sheena Easton",
    "image_url": "https://i.scdn.co/image/5c3e8b2eabdbbae43c3de9da7d490d407212e465",
    "artist_id": "5dcOK4stT4JDkP6Dqhbz5s",
    "year": 1985
  },
  {
    "song_id": "2lNm3PtNIj5DDZa96edje6",
    "name": "Miami Vice Theme - Miami Vice/Soundtrack Version",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/9040a6f143e7369f9c13d195933585c0c1c3046c",
    "artist_name": "Jan Hammer",
    "image_url": "https://i.scdn.co/image/dbb92af7ff0a326a0ac1cea00679e6e774ebd896",
    "artist_id": "50zRydJXfkLzGIOj9mITfy",
    "year": 1985
  },
  {
    "song_id": "5KMvHV04U7JnyS2KOnxvSv",
    "name": "Missing You",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/b12bbf9eca2f89addb7db4c97603565f8a759551",
    "artist_name": "Diana Ross",
    "image_url": "https://i.scdn.co/image/78deb485c97e72daaa6ad2577216491b0f2e8e9f",
    "artist_id": "3MdG05syQeRYPPcClLaUGl",
    "year": 1985
  },
  {
    "song_id": "6uPnJMpmcYw9f8oyQZP4XD",
    "name": "Cool It Now - Single Version",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/edc140847f07b4e528dac7ac0bfca687c80cc65c",
    "artist_name": "New Edition",
    "image_url": "https://i.scdn.co/image/94191154091f9e66af19334ad7c46902bf0289d5",
    "artist_id": "1mFX1QlezK1lNPKQJkhwWb",
    "year": 1985
  },
  {
    "song_id": "00bFaR6IDOyGSuRDpmFotJ",
    "name": "Sentimental Street",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/487788ec16cdaa27d2fb7265f67badd2196a769a",
    "artist_name": "Night Ranger",
    "image_url": "https://i.scdn.co/image/a94eb2cf4ee7ef07634028ae33b838f30eff5591",
    "artist_id": "1Ha0Fz4i0d4gu5fZbhBCtH",
    "year": 1985
  },
  {
    "song_id": "5q1cXPh7A9ir78qeLss5XL",
    "name": "We Are The World",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/8e49a2d6816be3c2723f202a61dfdf1a7575c176",
    "artist_name": "Various Artists",
    "image_url": "https://i.scdn.co/image/a28958d54818668c845df86c479ebaaac7e93747",
    "artist_id": "0LyfQWJT6nXafLPZqxe9Of",
    "year": 1985
  },
  {
    "song_id": "12N4F6pvIOmMxS7B8KM41n",
    "name": "Rhythm Of The Night",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/f8ab57f48940b057e1dfffedad7a97771f440db6",
    "artist_name": "DeBarge",
    "image_url": "https://i.scdn.co/image/c4c3e9e8c83c0251c82f50d0bfeda1669df501ad",
    "artist_id": "6is2U7I1jlI8PjxNZOHIMV",
    "year": 1985
  },
  {
    "song_id": "1TTRvU5OO7CUZQlj3ujFce",
    "name": "Misled",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/def9597c2feed2fbafb78498cfa05a3182b3b39b",
    "artist_name": "Kool & The Gang",
    "image_url": "https://i.scdn.co/image/a626648673372224bc7266c9bd212ba7b0c78209",
    "artist_id": "3VNITwohbvU5Wuy5PC6dsI",
    "year": 1985
  },
  {
    "song_id": "3r7wIvLStJVlbTZsibIL2t",
    "name": "Valotte",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/509cafe716cfaa29f3c60a427988c0a6fd2af3b0",
    "artist_name": "Julian Lennon",
    "image_url": "https://i.scdn.co/image/546ebb912de8cc5c775e3c55b0a5c6b001b04feb",
    "artist_id": "4Tvos0a5rRrBu4Oodu5f79",
    "year": 1985
  },
  {
    "song_id": "1YMQTPPik3ixVxNZ32yZGO",
    "name": "Penny Lover",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/3120d0bb3048dd0b76fdacd0d2a6de8d6477aa99",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/564a136f98251422b0f0507cdc3205c3700f94eb",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1985
  },
  {
    "song_id": "1l3fRzoUngJr6hSMIT1Hfq",
    "name": "Be Near Me",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/f3357d824281d3e3d4cac051a5c4f580a981a801",
    "artist_name": "ABC",
    "image_url": "https://i.scdn.co/image/de0103857879b352846605ad6ccd12135ffb0e29",
    "artist_id": "2s79xe5F6eUQkjwjww27Fh",
    "year": 1985
  },
  {
    "song_id": "4PTvAfCxtWIZIJoGg8t3sn",
    "name": "All She Wants To Do Is Dance",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/c46bebc03a79772517e1a6c208c0e3d743937544",
    "artist_name": "Don Henley",
    "image_url": "https://i.scdn.co/image/bded2a00fd3d17e9e73fe34700655eaa2bb3bfbd",
    "artist_id": "5dbuFbrHa1SJlQhQX9OUJ2",
    "year": 1985
  },
  {
    "song_id": "6FSTE58Y4IDxTj8vbfHQc6",
    "name": "Do What You Do",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/f019969e185ec00968dbbfca90bfb21c2c0be44c",
    "artist_name": "Jermaine Jackson",
    "image_url": "https://i.scdn.co/image/f5ddc45c57f7edfd6dd655ccbb8f383eac88666b",
    "artist_id": "1VDurPMbOKrNU8mcMP4X1P",
    "year": 1985
  },
  {
    "song_id": "5PM96PMKMfD1lLX2lryUsG",
    "name": "Oh Sheila",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/8bc58e0331a08e13c8c905a309d0abdde3b262f4",
    "artist_name": "Ready For The World",
    "image_url": "https://i.scdn.co/image/b2a585a4a27363a0821f6dc60726d7ffa2db5b04",
    "artist_id": "6h29EZDlc2NTvmCUe4yoXV",
    "year": 1985
  },
  {
    "song_id": "07hTrg8boummuVMUtVRRR0",
    "name": "The Wild Boys",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/8fdac0f981826f584eca0261341f3f69bad3f0f4",
    "artist_name": "Duran Duran",
    "image_url": "https://i.scdn.co/image/349e764d4602cf253c5102b78495b745cccc2424",
    "artist_id": "0lZoBs4Pzo7R89JM9lxwoT",
    "year": 1985
  },
  {
    "song_id": "2LfPYFstoasEmLDNK9geH0",
    "name": "Obsession",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/a8ab9d12f29ddf8dd659bc91c9e62e6c2a90839c",
    "artist_name": "Animotion",
    "image_url": "https://i.scdn.co/image/c8e88e977be753ed314a3aa31d4b6eb26eeca3b4",
    "artist_id": "6eQHjJJa52LUGjBKP5UPos",
    "year": 1985
  },
  {
    "song_id": "0HlKZzvWf9slQlXoNf6S3B",
    "name": "Loverboy",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/1e0b3a26bff334969374c44564964ba9838277d4",
    "artist_name": "Billy Ocean",
    "image_url": "https://i.scdn.co/image/2650b7370509de116e94c7dfbb2423169ebdea8a",
    "artist_id": "5IDs1CK15HegSAhGEbSYXo",
    "year": 1985
  },
  {
    "song_id": "7kmNkO4dyrGHHlAgSmgcnJ",
    "name": "Who's Holding Donna Now?",
    "popularity": 0,
    "preview_url": "https://p.scdn.co/mp3-preview/fd91e352b12e613b88f547e6a4ac822c62dfbb4d",
    "artist_name": "DeBarge",
    "image_url": "https://i.scdn.co/image/783bf43264d9e0ff25ceb473faae23d9c1a61604",
    "artist_id": "6is2U7I1jlI8PjxNZOHIMV",
    "year": 1985
  },
  {
    "song_id": "5dRQUolXAVX3BbCiIxmSsf",
    "name": "Your Love",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/66ab2cbe06451eae38c4286aa37782cf0ad8ee4e",
    "artist_name": "The Outfield",
    "image_url": "https://i.scdn.co/image/23d49381b8bf3a5daf70899dcd389db4d0a1c2b6",
    "artist_id": "1zxDewzd2j1ZdSBGaYcr0y",
    "year": 1986
  },
  {
    "song_id": "5tdKaKLnC4SgtDZ6RlWeal",
    "name": "How Will I Know",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/cae88cb74b220c7f2d59e9316e076c3fecf4e9b1",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/b2143ca3b5d5cef154374ac053a7b87b8b50ae09",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1986
  },
  {
    "song_id": "28S2K2KIvnZ9H6AyhRtenm",
    "name": "Kiss",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/99b73411a08b1546d08b9977b053e74b7d07e17b",
    "artist_name": "Prince & The Revolution",
    "image_url": "https://i.scdn.co/image/49db086c1d635a44cc1662c5ca9fe99a5ed3d1f7",
    "artist_id": "6C3ZGU59R9XhxQ1KRU5Q1O",
    "year": 1986
  },
  {
    "song_id": "6qUEOWqOzu1rLPUPQ1ECpx",
    "name": "Walk This Way",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/d04c8b3891d832921c234dc6f928d71de7969317",
    "artist_name": "RUN-DMC",
    "image_url": "https://i.scdn.co/image/c86eba53f995224caead6f565e65b10ee5ed27d6",
    "artist_id": "3CQIn7N5CuRDP8wEI7FiDA",
    "year": 1986
  },
  {
    "song_id": "2A6yzRGMgSQCUapR2ptm6A",
    "name": "True Colors",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/9115a638b9b4478dd9df1728f2e038bda3eda88b",
    "artist_name": "Cyndi Lauper",
    "image_url": "https://i.scdn.co/image/d9a6da7d9bbcc8c612e378b9334e54ad0c60c702",
    "artist_id": "2BTZIqw0ntH9MvilQ3ewNY",
    "year": 1986
  },
  {
    "song_id": "6yJxCltgtmGxIgKOS6ndnu",
    "name": "Greatest Love of All",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/30096c230d5f3577bee694d1829989f4b5e8973b",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/b2143ca3b5d5cef154374ac053a7b87b8b50ae09",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1986
  },
  {
    "song_id": "5KYoPa5akZxiCn38xelbqI",
    "name": "Manic Monday",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/4df2424e19df66bcb868fc683b137c1d52ab6f77",
    "artist_name": "The Bangles",
    "image_url": "https://i.scdn.co/image/8f034964e00b3c64d11d382b4f21c6cc5fd889ee",
    "artist_id": "51l0uqRxGaczYr4271pVIC",
    "year": 1986
  },
  {
    "song_id": "63diy8Bzm0pHMAU37By2Nh",
    "name": "Take Me Home Tonight",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/0186d4ef88822df6416abac093991b1e16f1aadb",
    "artist_name": "Eddie Money",
    "image_url": "https://i.scdn.co/image/2845ecb9bdf80dd89dd46bf137aa0eb02812dfb6",
    "artist_id": "4Tw2N3wdvJPGEU7JqMxFfE",
    "year": 1986
  },
  {
    "song_id": "2avaSeKHI5l4sLruVfLdi2",
    "name": "Burning Heart",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/7f2270477d2723f57c592b404d98ebce01a846a4",
    "artist_name": "Survivor",
    "image_url": "https://i.scdn.co/image/bfcba0de791c1464f5d3e2372e85b4eed1f7f1cf",
    "artist_id": "26bcq2nyj5GB7uRr558iQg",
    "year": 1986
  },
  {
    "song_id": "1eyq8cjUQ2daFthW2PC2GM",
    "name": "Glory Of Love [Theme From The Karate Kid Part II]",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/c5d0c378a16c62b397ab632dcc6ab8bc3c490454",
    "artist_name": "Peter Cetera",
    "image_url": "https://i.scdn.co/image/da32dcf60b2e7cc62d31e55847027d5577813bda",
    "artist_id": "5xWPOujQqd4wXyB08slZ9Z",
    "year": 1986
  },
  {
    "song_id": "1MTMedlCphum6mRcd8YzvE",
    "name": "You Give Love A Bad Name",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/f35c31d62f6ee23b74590d59d375358ec8b92962",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/efff863dfbfe40cc97f25bbb51e837c36fe4510e",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1986
  },
  {
    "song_id": "4Y5yjzs9FFw5qIgfZBd43I",
    "name": "Living in America",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/6f73ce5e5375e36dd189d3f7965bf07cd38f1e31",
    "artist_name": "James Brown",
    "image_url": "https://i.scdn.co/image/bfcba0de791c1464f5d3e2372e85b4eed1f7f1cf",
    "artist_id": "7GaxyUddsPok8BuhxN6OUW",
    "year": 1986
  },
  {
    "song_id": "1hZJcuz9iMoz6tLkFlIaUy",
    "name": "Take My Breath Away",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/e61838be60a6c6a1a22dfd170bf8ac7efd741f24",
    "artist_name": "Berlin",
    "image_url": "https://i.scdn.co/image/a6e9e5ea1b137953a6c12981950fd4a7e5ff017b",
    "artist_id": "2aS6jYh7ysTL1ZUsHneNgM",
    "year": 1986
  },
  {
    "song_id": "0Oa9Qtd0FuhcmLi3sWTF9F",
    "name": "Papa Don't Preach",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/cec511b071b1ce9c0326030c68d77d811e9634f5",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1986
  },
  {
    "song_id": "1cOj24fUMnBSXhtp7waHVW",
    "name": "That's What Friends Are For",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/c80c3aa8f976839a7dd97ca66a14e4df1a0bf2e9",
    "artist_name": "Dionne Warwick",
    "image_url": "https://i.scdn.co/image/7b7bb775e63b553d63e6621213707b33a21bcad4",
    "artist_id": "2JSjCHK79gdaiPWdKiNUNp",
    "year": 1986
  },
  {
    "song_id": "5BXj1QDRU77J1ngVavG1tI",
    "name": "Kyrie",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/f4340e9c62ef6157064724b016edf0d5929e609b",
    "artist_name": "Mr. Mister",
    "image_url": "https://i.scdn.co/image/391adf2ffa69e1016461c056454c2f1a6b8b09fb",
    "artist_id": "7Bah8E0kCETqEpAHI6CPzQ",
    "year": 1986
  },
  {
    "song_id": "5fRTUCS48zwiGai2Ku8gSg",
    "name": "These Dreams",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/efdfc3d748d3863134136ef2b9c82c281791f00c",
    "artist_name": "Heart",
    "image_url": "https://i.scdn.co/image/b81ca2d1bad28d4ba222041f2ac4ab781d380b6c",
    "artist_id": "34jw2BbxjoYalTp8cJFCPv",
    "year": 1986
  },
  {
    "song_id": "0xpBr84T3FTm9j4D1MdPtk",
    "name": "Invisible Touch - 2007 Remastered Version",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/b249f6274afd979a3c1da89b51b38f4e349ad87f",
    "artist_name": "Genesis",
    "image_url": "https://i.scdn.co/image/431343e6995d6b71cc700fd24d18f336dfcbe61a",
    "artist_id": "3CkvROUTQ6nRi9yQOcsB50",
    "year": 1986
  },
  {
    "song_id": "6N5DRCQUSXT1qQqmqsO92B",
    "name": "Party All the Time",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/8c2fc4a10b42cda6a31b8f1acd16990de71be2b4",
    "artist_name": "Eddie Murphy",
    "image_url": "https://i.scdn.co/image/289ae66a0094444b3ccfaa6deb093801412d506b",
    "artist_id": "1Zq8pfBl4ejCMrWdeAdphc",
    "year": 1986
  },
  {
    "song_id": "1vhNjOtmfgoGy4J6ijCUSS",
    "name": "Higher Love - Full Album Version",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/1e9f9f10fd25b5d086c2a69fa8c41bf773ff096c",
    "artist_name": "Steve Winwood",
    "image_url": "https://i.scdn.co/image/4f3cd3990ada54187a0cf3e0bad8e463d213628b",
    "artist_id": "5gxynDEKwNDgxGJmJjZyte",
    "year": 1986
  },
  {
    "song_id": "4kxOMXF9aVjztdBVM12gbn",
    "name": "Venus",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/cda7fb9e9afc83a0cde11fe1f116d4e8ae634334",
    "artist_name": "Bananarama",
    "image_url": "https://i.scdn.co/image/d89e7dfcfb183146d8d4b26400238ffb99ce805e",
    "artist_id": "3sc7iUG1Wwpwx7bHeZolgx",
    "year": 1986
  },
  {
    "song_id": "1xAkznu4j1yRIkPz16JpNP",
    "name": "The Sweetest Taboo - Remastered",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/ba88024acc6ef75438caa324a782873d110d7e4f",
    "artist_name": "Sade",
    "image_url": "https://i.scdn.co/image/63da82708eba3710a1ba680eaf7257a1d66f4fc8",
    "artist_id": "47zz7sob9NUcODy0BTDvKx",
    "year": 1986
  },
  {
    "song_id": "0TaT50ZZxT4ytZxuqkE3A9",
    "name": "Why Can't This Be Love",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/82099923ca72a06ee71f9b2d3f742a6e0ef46c83",
    "artist_name": "Van Halen",
    "image_url": "https://i.scdn.co/image/048f7574f55dccd56f64e240d3df4de8935122d9",
    "artist_id": "2cnMpRsOVqtPMfq7YiFE6K",
    "year": 1986
  },
  {
    "song_id": "2cFl7utlqyZjCXN1G5nRvA",
    "name": "Stuck With You",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/9f7d6d3dcd04f0a121798a4a582928f4752c7cb0",
    "artist_name": "Huey Lewis & The News",
    "image_url": "https://i.scdn.co/image/2f88f3d3e5093fbf656b65a51da6179ee9ce6035",
    "artist_id": "7A9yZMTrFZcgEWAX2kBfK6",
    "year": 1986
  },
  {
    "song_id": "6VzfdkKU7J6ideJPwVJnAN",
    "name": "If You Leave",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/e30e83f2840f35478dc353c5fed7e1ca0c102918",
    "artist_name": "Orchestral Manoeuvres In The Dark",
    "image_url": "https://i.scdn.co/image/6de3dd47b6573c118d27dbde99da2f217f8eebaf",
    "artist_id": "7wJ9NwdRWtN92NunmXuwBk",
    "year": 1986
  },
  {
    "song_id": "4oE8zFdponjE1mtTi4geXA",
    "name": "Small Town",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/04316c0219364f64d7157115854adff7dac32054",
    "artist_name": "John Mellencamp",
    "image_url": "https://i.scdn.co/image/2252c94914f263d66846123192aae9ce9ade7082",
    "artist_id": "3lPQ2Fk5JOwGWAF3ORFCqH",
    "year": 1986
  },
  {
    "song_id": "6GVbeCmR2izqUTcYpvoiX9",
    "name": "Tarzan Boy - Original Hit Version",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/5ee2e34082ff4284f55c383820aec5b9e7aa497c",
    "artist_name": "Baltimora",
    "image_url": "https://i.scdn.co/image/b6970fee648032546ee8aeedbbdab2d9a2bc6e0f",
    "artist_id": "6czfQ8MjHVmvJKA4LAnviG",
    "year": 1986
  },
  {
    "song_id": "36W7sqM1qkSJArXhlVMWAi",
    "name": "Say You, Say Me",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/dd486d533dff1d9d740b3508c5663d5c233bb1f1",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/b72157e4af3b1631917698dabffe089f53699490",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1986
  },
  {
    "song_id": "6V775dYuMO5BKjo9C3nhqL",
    "name": "Sara",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/dc18a6a6dc87750c655f9b162a30741c8eb4e0e8",
    "artist_name": "Starship",
    "image_url": "https://i.scdn.co/image/be2bcdba2a54092651caeb59180c541ebd95c380",
    "artist_id": "0kObWap02DEg9EAJ3PBxzf",
    "year": 1986
  },
  {
    "song_id": "1Hs3XZxhdpHrOyiZBB4kEK",
    "name": "Rock Me Amadeus",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/6f1617c690b1eccaa5309874e4e226c5cdb03160",
    "artist_name": "Falco",
    "image_url": "https://i.scdn.co/image/d1ec4d3d343eb07c59f9de97e73baba784c68919",
    "artist_id": "0hLd40hVpRDGENe4KGZLnW",
    "year": 1986
  },
  {
    "song_id": "7LoGfKBAaOl0nxhodJ1240",
    "name": "Word Up",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/861e9e9f41da43ad549d79e9dc8a1bf92a88ffb9",
    "artist_name": "Cameo",
    "image_url": "https://i.scdn.co/image/d9b8375b2876bb155ed9da2382606b440be3bb4a",
    "artist_id": "545idYhdloaSlLGBY8E9u2",
    "year": 1986
  },
  {
    "song_id": "4gpext9x0CbdD9NWaa4nDj",
    "name": "Amanda",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/68f494d74f12f8764643e210a5a680f884e9464d",
    "artist_name": "Boston",
    "image_url": "https://i.scdn.co/image/71198c7841f1379ba1d92c6249583b6e931e4781",
    "artist_id": "29kkCKKGXheHuoO829FxWK",
    "year": 1986
  },
  {
    "song_id": "044fQJmT2fZUYcA3BnaOL2",
    "name": "I Can't Wait",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/c314ccec1454b8a9fa432e1ac64e565adc8a859d",
    "artist_name": "Nu Shooz",
    "image_url": "https://i.scdn.co/image/2716e3e2412220ccf2b42effc04e5da0f326d9de",
    "artist_id": "2The4Ur661sLPGndcUuuLu",
    "year": 1986
  },
  {
    "song_id": "0OjKjWYMF8nrcHX8kj8D45",
    "name": "Live To Tell",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/d85eef3342134f04109af4641a1bc3eefa2971e6",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1986
  },
  {
    "song_id": "7HwFU2mPoiwAGYzL2CB5B3",
    "name": "We Don't Have To Take Our Clothes Off - Original Hit Version",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/97e5d704d7c01c83d6d796c9a9c0300ed104e518",
    "artist_name": "Jermaine Stewart",
    "image_url": "https://i.scdn.co/image/b6970fee648032546ee8aeedbbdab2d9a2bc6e0f",
    "artist_id": "7FAkzV0YOw0EIXFhmY4RE3",
    "year": 1986
  },
  {
    "song_id": "4m3OS54KWywYhP7WD7z1cg",
    "name": "Life In A Northern Town",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/352140b62599c57905b7f1927f07d9fbe10f2c62",
    "artist_name": "The Dream Academy",
    "image_url": "https://i.scdn.co/image/59019d05681e29f565d3b60b4dd227c76cdbe70c",
    "artist_id": "1JVoO5bwfU8GNuAZLpBoHe",
    "year": 1986
  },
  {
    "song_id": "4Jg1nU6FYvCWkqFw8Kr4bO",
    "name": "Never",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/a8a3e3480c143e74a5446bb0fb62ec3306ea44a6",
    "artist_name": "Heart",
    "image_url": "https://i.scdn.co/image/b81ca2d1bad28d4ba222041f2ac4ab781d380b6c",
    "artist_id": "34jw2BbxjoYalTp8cJFCPv",
    "year": 1986
  },
  {
    "song_id": "2o1dXMSuM4p6SsI2RREUL6",
    "name": "Take Me Home",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/2ac0fbf10a59b42dedeaaf25edd543cfe77e9bf9",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/f7ff5eaefc82b479890d1936b976b4fd014c236a",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1986
  },
  {
    "song_id": "2uXewDKMktNkkMfotqSxN6",
    "name": "Something About You - Single Version",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/33fbc9d6a51d721a78a3ba122a75d4826da648fb",
    "artist_name": "Level 42",
    "image_url": "https://i.scdn.co/image/11723e9c3d7c8ccae89042800d760d787953dcc6",
    "artist_id": "0L9xkvBPcEp1nrhDrodxc5",
    "year": 1986
  },
  {
    "song_id": "5ij84aZDvRN4zSHSh2L6pl",
    "name": "Walk Of Life",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/cac3c505dd20d950e945cac17be236d780c707aa",
    "artist_name": "Dire Straits",
    "image_url": "https://i.scdn.co/image/5ddca7f268285f7cb8ab5865f782b8d1214efb19",
    "artist_id": "0WwSkZ7LtFUFjGjMZBMt6T",
    "year": 1986
  },
  {
    "song_id": "0rEFyT5IzHweiULf6QZXmz",
    "name": "Tender Love",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/782c3f4f93fff370f91cb80def7d04e9728e4d62",
    "artist_name": "Force M.D.'s",
    "image_url": "https://i.scdn.co/image/f53fe2e2559bd3c919bfee276ec0943fc98a2a48",
    "artist_id": "3KpLtTMpLAko7nBDmiYg7Z",
    "year": 1986
  },
  {
    "song_id": "4xwllvf4zL43zIj8K7H6zd",
    "name": "Secret Lovers",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/edaf3b75287599ff07af5d9c258f6f5a276a1103",
    "artist_name": "Atlantic Starr",
    "image_url": "https://i.scdn.co/image/8a4d25825355d07ddae9d58d6bd6508565d0be90",
    "artist_id": "2YdVmtVBpIrv0N6WiBzSqm",
    "year": 1986
  },
  {
    "song_id": "5q04ftJMH7VjaKEbNUz18u",
    "name": "Holding Back The Years",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/a75c362d30ff4910d3475f05bc5f81ce8562ce56",
    "artist_name": "Simply Red",
    "image_url": "https://i.scdn.co/image/3b788ebaaf78cec3f26d56aaa509b39b8edf72be",
    "artist_id": "1fa0cOhromAZdq2xRA4vv8",
    "year": 1986
  },
  {
    "song_id": "5lQyKTM5MZsrObdRbIjJv4",
    "name": "On My Own",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/65edccdba7e9a3399bcf3708f3a79a393132936a",
    "artist_name": "Patti LaBelle",
    "image_url": "https://i.scdn.co/image/efd0d1ca514dbbc2dbbbbca2d255033f7e514ed6",
    "artist_id": "0ty0xha1dbprYIUAQufkFn",
    "year": 1986
  },
  {
    "song_id": "3aQ06wb25CWisjA91kqgB4",
    "name": "West End Girls - 2001 Remastered Version",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/b214218001600246a4ad5085b9d34b7a08151a85",
    "artist_name": "Pet Shop Boys",
    "image_url": "https://i.scdn.co/image/91f5755f979ef2dbc04d08ec2b5108a00d3e122c",
    "artist_id": "2ycnb8Er79LoH2AsR5ldjh",
    "year": 1986
  },
  {
    "song_id": "537yo062QIz16oQOgxmul3",
    "name": "Human",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/07795c28b58cd274ac02f6a9b93c6e930c5efb51",
    "artist_name": "The Human League",
    "image_url": "https://i.scdn.co/image/09a9c81d626247e093845b3fb27cfd5c386304e8",
    "artist_id": "1aX2dmV8XoHYCOQRxjPESG",
    "year": 1986
  },
  {
    "song_id": "2dURQIBrw3XcHyVZlfdpC1",
    "name": "Two Of Hearts",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/4d5527ef16f80fe246948aacafbf2cc7d8f7ba39",
    "artist_name": "Stacey Q",
    "image_url": "https://i.scdn.co/image/7109cdeb8fbf66842bedf0efb309938b4d035143",
    "artist_id": "3q8tRS0hCMVCylgKAoA0ya",
    "year": 1986
  },
  {
    "song_id": "07OzC5JlcaDwEkzWmvqp6y",
    "name": "Nasty",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/337d5d881fb1b304bdb5c488602fd88834e75765",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/03074d8efc8a19b674282032a737dffabb656ac6",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1986
  },
  {
    "song_id": "2phcupbmqItBvC3OnsHQLF",
    "name": "Alive And Kicking - 2002 Digital Remaster",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/5f4204db671aaf40ec6cea7686cc9536c5d00d4e",
    "artist_name": "Simple Minds",
    "image_url": "https://i.scdn.co/image/cf08b031d98ea29fd5c1a844c558476f063d27f7",
    "artist_id": "6hN9F0iuULZYWXppob22Aj",
    "year": 1986
  },
  {
    "song_id": "63nVsCAdxu9OdeRhe58wiE",
    "name": "Broken Wings",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/d2f5600413225cbcd7aaccec7d20a4fe10d7e3d7",
    "artist_name": "Mr. Mister",
    "image_url": "https://i.scdn.co/image/29ceacc0a612f8d1d0a4b3783213cc4d7875c3de",
    "artist_id": "7Bah8E0kCETqEpAHI6CPzQ",
    "year": 1986
  },
  {
    "song_id": "5R6ol4f8WCUCWNrB7Ymf99",
    "name": "A Love Bizarre",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/d4adffda56ae1c6065b1787a8155429900b12793",
    "artist_name": "Sheila E.",
    "image_url": "https://i.scdn.co/image/ee926da3bdc7b5a287381b8b581ef977cdeb8989",
    "artist_id": "6OQrOpxSIfPai3cFaN4v4P",
    "year": 1986
  },
  {
    "song_id": "1I5PbTAcWMqV66XR8B2vzK",
    "name": "Your Wildest Dreams",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/03f6ac64a084368ea7ddd68f811a427eb2360174",
    "artist_name": "The Moody Blues",
    "image_url": "https://i.scdn.co/image/e6aa3f6ad065829a366c73be97db799528b87941",
    "artist_id": "5BcZ22XONcRoLhTbZRuME1",
    "year": 1986
  },
  {
    "song_id": "2oeha2Y6cQIw1LyhUWSjjq",
    "name": "When I Think Of You",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/545e0726cd751c86ddd8627fdb3cf15f18cb0733",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/fa4a8da2629ddd8cce3ca092ddcf3b6b597a4604",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1986
  },
  {
    "song_id": "3j6oEEPaUMzzw5i7gFIGDb",
    "name": "Don't Forget Me (When I'm Gone)",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/fc64a5a92217414fe7dee5a205fba25b91d52124",
    "artist_name": "Glass Tiger",
    "image_url": "https://i.scdn.co/image/852376709c73f9bdbf69bccc305eae1df9120965",
    "artist_id": "53RaPTbZOx2mBoZD6LLWIv",
    "year": 1986
  },
  {
    "song_id": "1ESuT2y9AKu1zMdxq47qW5",
    "name": "Sleeping Bag",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/fc55b245faac68b75c4614d67f9ed6b5b534bfee",
    "artist_name": "ZZ Top",
    "image_url": "https://i.scdn.co/image/c7a8c53456d4a95a370b100e3db84eb9e2b4a7b4",
    "artist_id": "2AM4ilv6UzW0uMRuqKtDgN",
    "year": 1986
  },
  {
    "song_id": "6LWW998TfGPyxPVWuO88iR",
    "name": "Nikita",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/b6b4cdb3f788c3098ba4c89532d793b95a3ccc65",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/bf3efbd0d4962d1e76b1ae3ed50330356166f70b",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1986
  },
  {
    "song_id": "0C8lgaKDpwGiPmUU9unT33",
    "name": "There'll Be Sad Songs (To Make You Cry)",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/3f6cdf808cd982fb4374b605e7f8ac6185a7de89",
    "artist_name": "Billy Ocean",
    "image_url": "https://i.scdn.co/image/5b6a8a2899502272dbb5012125b3f11f97a78cd1",
    "artist_id": "5IDs1CK15HegSAhGEbSYXo",
    "year": 1986
  },
  {
    "song_id": "3FdHgoJbH3DXNtGLh56pFu",
    "name": "Conga - Single Version",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/dee36d4b3c4eb75c241f73731aa079cf71d746ab",
    "artist_name": "Gloria Estefan",
    "image_url": "https://i.scdn.co/image/1d7e980f69185d87800110b318ee00a855dd55ca",
    "artist_id": "5IFCkqu9J6xdWeYMk5I889",
    "year": 1986
  },
  {
    "song_id": "2sYLlZU3FuCF8SKNeIvdKA",
    "name": "Crush On You",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/b3b134ed118e1cc744af5be24a3894afa823cd0d",
    "artist_name": "The Jets",
    "image_url": "https://i.scdn.co/image/03d6540f55ce53867af00ed5bc133555006a18bc",
    "artist_id": "3AHq6rutf72JF0ul8GB6G2",
    "year": 1986
  },
  {
    "song_id": "0NFXlV3K5lZzGTPnWhalXH",
    "name": "I Miss You",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/5e02c091c167227e1340906c8d045f52e60428cb",
    "artist_name": "Klymaxx",
    "image_url": "https://i.scdn.co/image/6ced27fb89d8fb4707ebc95b5ce8c6baa6ae5ff4",
    "artist_id": "2wh5Qu35PjDbJBqAv65RGa",
    "year": 1986
  },
  {
    "song_id": "2Q0HXXMkR28ZZkyX30UmLc",
    "name": "All Cried Out",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/3bded03d09b7cfed65bcc5e762fd469514ba66da",
    "artist_name": "Lisa Lisa & Cult Jam",
    "image_url": "https://i.scdn.co/image/1c6be99cc1c21512360a66192c3e4473e228f0c6",
    "artist_id": "1vgjN6nIPNKiiQGE9PtzTT",
    "year": 1986
  },
  {
    "song_id": "4II1Ec8htD10ZryStD9oAp",
    "name": "I'm Your Man",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/c7ad6aeae6b6c2eea63134a8e3c1e41a44882dfc",
    "artist_name": "Wham!",
    "image_url": "https://i.scdn.co/image/b5120637382e48dcebab77f0da40f9e69fb7aeea",
    "artist_id": "5lpH0xAS4fVfLkACg9DAuM",
    "year": 1986
  },
  {
    "song_id": "63Ql2zSLiBryLRPihaUToK",
    "name": "Object Of My Desire",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/45731d81c30d6574fe7576c16432455cbf3504e6",
    "artist_name": "Starpoint",
    "image_url": "https://i.scdn.co/image/8414606e40b0bbec2d4e3ae403a97acebb8cb9dd",
    "artist_id": "6W9OZQ862LR0HM76QQJJY3",
    "year": 1986
  },
  {
    "song_id": "2qxrA7khrMA6icoq9cn2wY",
    "name": "Let's Go All The Way",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/1c95da1c5c01452f2378ad72c272a3a07c6d1c96",
    "artist_name": "Sly Fox",
    "image_url": "https://i.scdn.co/image/3296ec264a954c6f63f796fdde4bef92ec81c81f",
    "artist_id": "6wU099wsnfSuP0lxWc0jgt",
    "year": 1986
  },
  {
    "song_id": "5IhYnodWHS5IOrw7aOOuA9",
    "name": "Talk To Me",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/1dcd4d34847946a9df08fc8e4b7e56526ad5ecee",
    "artist_name": "Stevie Nicks",
    "image_url": "https://i.scdn.co/image/46b138cab0e9e05aea5db03d24b7889e7f39ce63",
    "artist_id": "7crPfGd2k81ekOoSqQKWWz",
    "year": 1986
  },
  {
    "song_id": "3WrOAryvCUOVsm8P8O2DoE",
    "name": "Perfect Way",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/2c7874ea509a4d0c0a7974214531561308e0545d",
    "artist_name": "Scritti Politti",
    "image_url": "https://i.scdn.co/image/dc032c0ea8bdac20748f752282b0b5701c9f2a95",
    "artist_id": "59luKpdal8UwxcuLJNoKwS",
    "year": 1986
  },
  {
    "song_id": "0snPJPxkk0MbTc0xeUvAPt",
    "name": "Dancing On The Ceiling",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/26acf407dd419f8d71e5b0c9852c7fb446c0478c",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/913b08847e425b3fb365a2fc85d51014d5f57cfb",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1986
  },
  {
    "song_id": "586bkJBqAeLkTy4n1pb8nH",
    "name": "What Have You Done For Me Lately",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/028e2173ca1c76836f479f648594ce3b9b1c8bc2",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/2f15d571d8c535ab30318e2557d1b8a5a7d86e34",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1986
  },
  {
    "song_id": "3aBgcUxqadc7PC0yeDCpU9",
    "name": "Throwing It All Away (Platinum Collection Version)",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/815746aebd3d835ed27dd0585c195cb852f17a48",
    "artist_name": "Genesis",
    "image_url": "https://i.scdn.co/image/cfd24b9430ed6f4d8aadc3bbfdd2ddbe108540f8",
    "artist_id": "3CkvROUTQ6nRi9yQOcsB50",
    "year": 1986
  },
  {
    "song_id": "7sRe0zpDlI1e2qSQsesrP7",
    "name": "King For A Day",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/0de1c65d1cec78a63823ac5470415eea0d90f75d",
    "artist_name": "Thompson Twins",
    "image_url": "https://i.scdn.co/image/063ca4ae5cca66d8e0b507c6349ad3c99a6bfa6f",
    "artist_id": "5jVeqi3PNaTOajfvBa4uFn",
    "year": 1986
  },
  {
    "song_id": "3LVRM23d9ltvRoquuZy1Yb",
    "name": "Love Touch (Remastered Album Version)",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/b02c3a13396c8cec9b0e2494a351cb3b271b6ed0",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/79fa6c9588f7bec80db5db5d06bae424a118e57b",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1986
  },
  {
    "song_id": "5AM6VOkN7ficA2O04f5JG1",
    "name": "Bad Boy",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/16173caa267a2915a90625f8f1ba0ff08b4819e5",
    "artist_name": "Miami Sound Machine",
    "image_url": "https://i.scdn.co/image/1d7e980f69185d87800110b318ee00a855dd55ca",
    "artist_id": "18xgcedCGxFbqLbIQn5R8F",
    "year": 1986
  },
  {
    "song_id": "7kZZ2Hmkg1zfwrQLsmypNE",
    "name": "Dreamtime",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/3122e9ad62c060ee03ea4595f5fabc2db20a46c8",
    "artist_name": "Daryl Hall",
    "image_url": "https://i.scdn.co/image/4cedfd45ed7f1d8e0bfb8110e1687e206a5dbdc6",
    "artist_id": "5jit9WjiAxxvdA0onOHBLd",
    "year": 1986
  },
  {
    "song_id": "5qUliRaqliPfqrG3oQrp72",
    "name": "Addicted To Love - Edit",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/f5ddf9531e28c5b060da776d114013afd0211974",
    "artist_name": "Robert Palmer",
    "image_url": "https://i.scdn.co/image/fb5376e2cb0b306bbdc5c494a19d6c424dacede8",
    "artist_id": "530Sdm7eqqzWBdDmILMgnu",
    "year": 1986
  },
  {
    "song_id": "19u5G7pWq3Nj6lQiP4W4Hp",
    "name": "Friends And Lovers",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/595793fa512279ce7b5c32c42c66c723e2dfe011",
    "artist_name": "Carl Anderson",
    "image_url": "https://i.scdn.co/image/15defff633bb6eb2aa1b851740526b9f79d7b237",
    "artist_id": "7Bp4xXabhvbSB51SK3LJim",
    "year": 1986
  },
  {
    "song_id": "7kzsIuCQDvDc56oC4aVjnE",
    "name": "Sledgehammer - (Tribute to Peter Gabriel)",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/4e74ce2d803f22cba21d4be7425e640d0aa66177",
    "artist_name": "Studio Allstars",
    "image_url": "https://i.scdn.co/image/13f04d6a364e02cc9c86c035879b59c66ea4e746",
    "artist_id": "0dJ4EXAj8j47TP3U47T4Sj",
    "year": 1986
  },
  {
    "song_id": "5xbqrbNPBTAOGDA6GD3O46",
    "name": "Words Get In The Way",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/a209b904e87d85888d2365e1b12c2271fcf58b7d",
    "artist_name": "Miami Sound Machine",
    "image_url": "https://i.scdn.co/image/1d7e980f69185d87800110b318ee00a855dd55ca",
    "artist_id": "18xgcedCGxFbqLbIQn5R8F",
    "year": 1986
  },
  {
    "song_id": "69UQxQrEABRXg1xMhvKjGC",
    "name": "Typical Male",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/8d22c1c520e358b4373d1ebd26e0d158f4bb8732",
    "artist_name": "Tina Turner",
    "image_url": "https://i.scdn.co/image/2fba697ac88a5e1b6e7e4a394eb92288d87437c6",
    "artist_id": "1zuJe6b1roixEKMOtyrEak",
    "year": 1986
  },
  {
    "song_id": "6nbi2AJ9hAi2SE8jH6mRKV",
    "name": "Love Will Conquer All",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/b68ae879ee01770e2a9db4c13a622a4c7c783965",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/913b08847e425b3fb365a2fc85d51014d5f57cfb",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1986
  },
  {
    "song_id": "51P9LDBS1grMdF7fYGpBZx",
    "name": "R.O.C.K. In The U.S.A. (A Salute To 60's Rock)",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/7c8ff05d73554ca4a55711194bde826477673512",
    "artist_name": "John Mellencamp",
    "image_url": "https://i.scdn.co/image/8e4b1fa3b092033a1791528d6c81cc837f84c84b",
    "artist_id": "3lPQ2Fk5JOwGWAF3ORFCqH",
    "year": 1986
  },
  {
    "song_id": "6v9oV4RpWKJ5qbpAZqKqtZ",
    "name": "Mad About You",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/029fe53d1e67575894fd3e39aed86c8da2c6fdf7",
    "artist_name": "Belinda Carlisle",
    "image_url": "https://i.scdn.co/image/c6f1563c4446ad22f2c1c16098614b9b7655fd55",
    "artist_id": "7xkAwz0bQTGDSbkofyQt3U",
    "year": 1986
  },
  {
    "song_id": "7DGIv9rjJSL7Bfls23QMeh",
    "name": "I Didn't Mean To Turn You On",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/68f56ba3ab311339cb22ea92d3213429cbb58cb5",
    "artist_name": "Robert Palmer",
    "image_url": "https://i.scdn.co/image/dbe218796afab0ed5d98eb8452e85b1cd176530d",
    "artist_id": "530Sdm7eqqzWBdDmILMgnu",
    "year": 1986
  },
  {
    "song_id": "0UhZkk4agmJpbMCvT3OgLG",
    "name": "Election Day",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/6c596751c6f6c73f73e045212e820cd888501d42",
    "artist_name": "Arcadia",
    "image_url": "https://i.scdn.co/image/725f2ad71130642beb282916fc476b3ce11f495a",
    "artist_id": "2HMuzFmTtwL4ur1zJNCU7f",
    "year": 1986
  },
  {
    "song_id": "6VhcdIKG4xM8sTX9amSO4n",
    "name": "Go Home",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/b33630b8ca17da8e0efd4a375cad8db4c26b33ae",
    "artist_name": "Stevie Wonder",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "7guDJrEfX3qb6FEbdPA5qi",
    "year": 1986
  },
  {
    "song_id": "6QyYTRZCrzjAUqchxGC91x",
    "name": "When the Going Gets Tough, the Tough Get Going",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/ae6ee5bddb09be6c53b8c3a3cca6047be5b35dbc",
    "artist_name": "Billy Ocean",
    "image_url": "https://i.scdn.co/image/2650b7370509de116e94c7dfbb2423169ebdea8a",
    "artist_id": "5IDs1CK15HegSAhGEbSYXo",
    "year": 1986
  },
  {
    "song_id": "4AzqtxJQWng9XeXBk0ddqU",
    "name": "Who's Johnny",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/0f7cd73cceb997bbc55a8b70b2a456b56a5c9c6d",
    "artist_name": "DeBarge",
    "image_url": "https://i.scdn.co/image/e789d2f70633a2300d9bf98b9da2487fe32ad6ce",
    "artist_id": "6is2U7I1jlI8PjxNZOHIMV",
    "year": 1986
  },
  {
    "song_id": "4iiuNJT1eoUTfZ1iQEolq9",
    "name": "Silent Running - Demonstration Version, Includes Lead Singer",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/1c2e9b7c1e087070e817a4772763dd2c35500fec",
    "artist_name": "Stingray Music (Karaoke)",
    "image_url": "https://i.scdn.co/image/86c31c1eac5d9deb852d656695f1f8b1e78f32be",
    "artist_id": "4vKhoHnEBN6Fq8tsMVpXaN",
    "year": 1986
  },
  {
    "song_id": "4rr67zXmEYf9ykLigeEsbv",
    "name": "Don't Dream It's Over",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/7efcdfa3c56ae28442d5e795c84765b2e5fc5c0a",
    "artist_name": "Crowded House",
    "image_url": "https://i.scdn.co/image/6162506c64f2864088ee16fbb5274730e0b96135",
    "artist_id": "7ohlPA8dRBtCf92zaZCaaB",
    "year": 1987
  },
  {
    "song_id": "54b8qPFqYqIndfdxiLApea",
    "name": "Alone",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/7240a89bd1f905a5d47794db6f6ca463b96570c4",
    "artist_name": "Heart",
    "image_url": "https://i.scdn.co/image/ec46970f7d37115f946d6e7e98b4b8c1c4f86695",
    "artist_id": "34jw2BbxjoYalTp8cJFCPv",
    "year": 1987
  },
  {
    "song_id": "2vEQ9zBiwbAVXzS2SOxodY",
    "name": "Nothing's Gonna Stop Us Now",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/6f1997efaabaf18ff52071f4940c1454fd39573d",
    "artist_name": "Starship",
    "image_url": "https://i.scdn.co/image/a0bab78a617f7b23dc7e19a0cfa0ed5c7bb3089a",
    "artist_id": "0kObWap02DEg9EAJ3PBxzf",
    "year": 1987
  },
  {
    "song_id": "5UqqOfFa9DYXALpCCF8VwB",
    "name": "(I've Had) The Time of My Life",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/6641c96e29f9090840b066cb0ba7f7229a9a93c9",
    "artist_name": "Bill Medley",
    "image_url": "https://i.scdn.co/image/1166faed41598593e460de5382ca2f0c07e43108",
    "artist_id": "1XE70WwxhnrXNAJYQQ9ygx",
    "year": 1987
  },
  {
    "song_id": "1Jwc3ODLQxtbnS8M9TflSP",
    "name": "Walk Like an Egyptian",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/d08fdbb0ce8886bf43dfbec04b6a40a2209caf9d",
    "artist_name": "The Bangles",
    "image_url": "https://i.scdn.co/image/d1613688a9cdce0338f7a241748c06cccd6baca8",
    "artist_id": "51l0uqRxGaczYr4271pVIC",
    "year": 1987
  },
  {
    "song_id": "0YPXkQthLWrhNGoKTbwCJ8",
    "name": "(I Just) Died in Your Arms",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/3a6624e27e8975d781dc2c3d8bdbe0a5cfd865f9",
    "artist_name": "Cutting Crew",
    "image_url": "https://i.scdn.co/image/31890637c73dec249f188e33bb69c2d9a31b0680",
    "artist_id": "3cniTumSiUysiPWXapGx1i",
    "year": 1987
  },
  {
    "song_id": "7qQnBfwXrw2tZNFG4Uf57N",
    "name": "Livin' On A Prayer",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/c3d92c1a7c81c9fb5566998417db1df89a177a1d",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/efff863dfbfe40cc97f25bbb51e837c36fe4510e",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1987
  },
  {
    "song_id": "5uw9UIOXj6R8pOwXte8F5h",
    "name": "Little Lies",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/f8beab6806d006f13c220a8952a978d3685be9e5",
    "artist_name": "Fleetwood Mac",
    "image_url": "https://i.scdn.co/image/8d39123db7959b88836ef1a18ebaf1f73fbfc790",
    "artist_id": "08GQAI4eElDnROBrJRGE0X",
    "year": 1987
  },
  {
    "song_id": "2Iib2MV3ECFJAourgP9dlY",
    "name": "La Isla Bonita",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/09edf195c6fd3d39eda21242dd885b64042e3fb3",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1987
  },
  {
    "song_id": "5NLuC70kZQv8q34QyQa1DP",
    "name": "Fight For Your Right",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/258cd1a49221477699f8a7ea48d82cd52d6ab2ab",
    "artist_name": "Beastie Boys",
    "image_url": "https://i.scdn.co/image/9965a9504db0a247542ebc22a90685a4707a7063",
    "artist_id": "03r4iKL2g2442PT9n2UKsx",
    "year": 1987
  },
  {
    "song_id": "4Zj9VM4fO1KwkU3lFaECsW",
    "name": "Wanted Dead Or Alive",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/3960ad773285517736b83d1f2c967edb24e35f82",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/efff863dfbfe40cc97f25bbb51e837c36fe4510e",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1987
  },
  {
    "song_id": "0uMMLry3hzWGn3q3loqMkm",
    "name": "La Bamba (45 Version)",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/07c7e47379f330ff912cffccb902999f19bb503d",
    "artist_name": "Los Lobos",
    "image_url": "https://i.scdn.co/image/4a18888f1de57762714d567e52f68228e94f5749",
    "artist_id": "6OWapcJm9xd55ci9CYbAuT",
    "year": 1987
  },
  {
    "song_id": "648BMGrt98kUbLo24A4vgj",
    "name": "Hip To Be Square",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/ac9a05c644317351aaa8117355427362db9056dd",
    "artist_name": "Huey Lewis & The News",
    "image_url": "https://i.scdn.co/image/2f88f3d3e5093fbf656b65a51da6179ee9ce6035",
    "artist_id": "7A9yZMTrFZcgEWAX2kBfK6",
    "year": 1987
  },
  {
    "song_id": "6V50MyHPGhEmwYu0Wdyf0t",
    "name": "The Way It Is",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/8f91644ddcae3650d01d77ea4d21b12cbcb4cd07",
    "artist_name": "Bruce Hornsby and the Range",
    "image_url": "https://i.scdn.co/image/70c8e73c2db7a3a59af98e25fad79dea9f2543da",
    "artist_id": "7CwJ4VEWs7HV7MWQt9cgyk",
    "year": 1987
  },
  {
    "song_id": "0it16By6VcfBXis67YMNbT",
    "name": "I Just Can't Stop Loving You - Remastered Version",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/40d325acde7d75a0494a8242758d934dae688ff6",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/b51d252d914b4e1becfe7ae425697f170e2f769a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1987
  },
  {
    "song_id": "29pzjF6ZkzcW3ofgOVbF0t",
    "name": "Here I Go Again",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/3667df9309b37620b1fd4ccb7711ba1e75609be3",
    "artist_name": "Whitesnake",
    "image_url": "https://i.scdn.co/image/67c0e903571f3da7771aff2dde6964ce45c6c90a",
    "artist_id": "3UbyYnvNIT5DFXU4WgiGpP",
    "year": 1987
  },
  {
    "song_id": "6Wiamk8BAAP50gAAJopsy2",
    "name": "Don't Get Me Wrong - 2007 Remastered Version",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/d9a2e6a926764c8199f2d764afaf981a941a5b3c",
    "artist_name": "Pretenders",
    "image_url": "https://i.scdn.co/image/1a820bf86712398662bd4036bb9b6e2dfb9608e3",
    "artist_id": "0GByy3DcfbQwDvXGCWmzv9",
    "year": 1987
  },
  {
    "song_id": "3GfGTJ2xzC0rqKgdjNJLOC",
    "name": "Mony Mony - 2001 - Remaster",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/75eb4ed2672d0c4280a84e08d9f7647e0e384f7b",
    "artist_name": "Billy Idol",
    "image_url": "https://i.scdn.co/image/ae4358812edea60bdecbcec4d4508e7f7e2c0da6",
    "artist_id": "7lzordPuZEXxwt9aoVZYmG",
    "year": 1987
  },
  {
    "song_id": "3kme5Qos8h04yrKxsgur79",
    "name": "Open Your Heart",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/de3a14d820ec520d7d2ab14bab523e3d92d3862d",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1987
  },
  {
    "song_id": "4npQqHOi5Zxxyw1oarSivu",
    "name": "Big Love",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/5f6855f6ff4f664543f1bb6e090dc599e0aabfc4",
    "artist_name": "Fleetwood Mac",
    "image_url": "https://i.scdn.co/image/8d39123db7959b88836ef1a18ebaf1f73fbfc790",
    "artist_id": "08GQAI4eElDnROBrJRGE0X",
    "year": 1987
  },
  {
    "song_id": "2EnZ9bpgUtLgKkRFehp8xS",
    "name": "Nothing's Gonna Change My Love for You",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/b6e98ea03f5fd245a13a42813e3ad3978ee2d7d9",
    "artist_name": "Glenn Medeiros",
    "image_url": "https://i.scdn.co/image/528724bd09c084dbd791c4b5d70a7a8c7d639e1c",
    "artist_id": "0bByarMN8ryEFQsRo6iCUN",
    "year": 1987
  },
  {
    "song_id": "6QnFHieoch6U9J8zfv6hml",
    "name": "I Want Your Sex - Remastered [Pts. 1 & 2]",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/16fc1d5521cf21d37f3befe3667fa99d6df9f99e",
    "artist_name": "George Michael",
    "image_url": "https://i.scdn.co/image/b0918e54e212d316e08952621867fbaa296ca2b3",
    "artist_id": "19ra5tSw0tWufvUp8GotLo",
    "year": 1987
  },
  {
    "song_id": "29wJiSQcze8HEmxkUQykyp",
    "name": "Carrie - Single Version",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/bcc6ba7312ad8f6629af22331348215d8a02f3d1",
    "artist_name": "Europe",
    "image_url": "https://i.scdn.co/image/5ab26908c13495c5d2332978ca46b7ecb7aa2305",
    "artist_id": "7Js6Lde8thlIHXggv2SCBz",
    "year": 1987
  },
  {
    "song_id": "4MgGdPmbySSVLUPBxzQ7mx",
    "name": "Keep Your Hands To Yourself",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/563bed4d9695d5f93ac17f7397b2242928ca90f9",
    "artist_name": "The Georgia Satellites",
    "image_url": "https://i.scdn.co/image/a5210bc3bd48bc58b8c348094b4e51f3bf777704",
    "artist_id": "2hnzQ6eCFkxUIPsVcsdj8A",
    "year": 1987
  },
  {
    "song_id": "6twIBPybEvGydyNzNz89FT",
    "name": "Land Of Confusion - 2007 Remastered Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/e015d566dca240b48f5d0a1a2aea87746b64cd52",
    "artist_name": "Genesis",
    "image_url": "https://i.scdn.co/image/431343e6995d6b71cc700fd24d18f336dfcbe61a",
    "artist_id": "3CkvROUTQ6nRi9yQOcsB50",
    "year": 1987
  },
  {
    "song_id": "3dn6qXlHYAQpOqMTWxkGOR",
    "name": "Will You Still Love Me? - Remastered Single Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/cd142f1fae99877e33cd4bf3bcc83009eeb09f1d",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/13e99c93468f6e338ac1f018d97503896c01989c",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1987
  },
  {
    "song_id": "5lHWWhuRtSBKfH2xS6YAUD",
    "name": "Sign 'O' The Times [Single Version]",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/cd1b72b9221c7a3262d9bc58941234756dbc4c11",
    "artist_name": "Prince",
    "image_url": "https://i.scdn.co/image/49db086c1d635a44cc1662c5ca9fe99a5ed3d1f7",
    "artist_id": "5a2EaR3hamoenG9rDuVn8j",
    "year": 1987
  },
  {
    "song_id": "1w2eruYH50BnPSIJ8yM8tQ",
    "name": "U Got The Look",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/b3cf4223294c2e9a00b70a8caef30f56171e3ed8",
    "artist_name": "Prince",
    "image_url": "https://i.scdn.co/image/49db086c1d635a44cc1662c5ca9fe99a5ed3d1f7",
    "artist_id": "5a2EaR3hamoenG9rDuVn8j",
    "year": 1987
  },
  {
    "song_id": "3bcYb4HwWlytUFLny0e0Q1",
    "name": "With Or Without You",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/a231e230226a9a7d0dd9f6ffcd3d11366f16831f",
    "artist_name": "U2",
    "image_url": "https://i.scdn.co/image/93cfa767f57094a3867c5ef172b6a92303723741",
    "artist_id": "51Blml2LZPmy7TTiAg47vQ",
    "year": 1987
  },
  {
    "song_id": "1stJclD9IGUbxtUnl8CzG3",
    "name": "Notorious",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/81c5f9e8d3866abb1a328f245b9a4fd7eabb0e8d",
    "artist_name": "Duran Duran",
    "image_url": "https://i.scdn.co/image/40b01f241ccec6755f8ed137a77074033aafe7f4",
    "artist_id": "0lZoBs4Pzo7R89JM9lxwoT",
    "year": 1987
  },
  {
    "song_id": "16cb7NuPz7j0BrQGXKo3zd",
    "name": "Who's That Girl",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/6037b12451d7cc227d2aa8dc252b13bc02a7e5f2",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1987
  },
  {
    "song_id": "2lzbKVKSUVeb01ytbHA8PS",
    "name": "Stand By Me - Single/LP Version",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/dc061c85228c53d2fc2951535a69cc3e7f9ba580",
    "artist_name": "Ben E. King",
    "image_url": "https://i.scdn.co/image/4f3fc7c24375193b2918076e673077dc32bb4f1a",
    "artist_id": "3plJVWt88EqjvtuB4ZDRV3",
    "year": 1987
  },
  {
    "song_id": "3smjYriN3K7zQvfikuRwnK",
    "name": "Always",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/10cfd2a88a448f204fae1edec048754c8c4b7bdd",
    "artist_name": "Atlantic Starr",
    "image_url": "https://i.scdn.co/image/1652dc062bbd72fe377ff2072fd4327d5b3b206a",
    "artist_id": "2YdVmtVBpIrv0N6WiBzSqm",
    "year": 1987
  },
  {
    "song_id": "0n1NI4TnEw0SeXSS4DNhhb",
    "name": "I Think We're Alone Now",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/8d4d1c29a043228e143dad8649ec5c896cb680ab",
    "artist_name": "Tiffany",
    "image_url": "https://i.scdn.co/image/1542ad2ec5ce508dff66a9fb0ae02c7f06e014cc",
    "artist_id": "4C3uGP8vRDzxrhJxZiOjTe",
    "year": 1987
  },
  {
    "song_id": "6bKqSvTOPxkqv9rc1tRZSx",
    "name": "Lean on Me",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/30876443bc237ea92034930b06be353df6e16321",
    "artist_name": "Club Nouveau",
    "image_url": "https://i.scdn.co/image/1ce7ffd7674ed024bf2ba413e6e70753864b9a98",
    "artist_id": "4kEAjV4pCBOkoowYYQydvO",
    "year": 1987
  },
  {
    "song_id": "4P0uyibZjcXZLVeNOHBd8Q",
    "name": "In Too Deep - 2007 Remastered Version",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/aefa59fa24af002bcf551bd0f63bc1fb87f657b0",
    "artist_name": "Genesis",
    "image_url": "https://i.scdn.co/image/431343e6995d6b71cc700fd24d18f336dfcbe61a",
    "artist_id": "3CkvROUTQ6nRi9yQOcsB50",
    "year": 1987
  },
  {
    "song_id": "3T8Ye4NB0MQhcEcxzMdAuH",
    "name": "Mandolin Rain",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/d0d0538a85cf3eff0f0565e078bf7ca894b814f3",
    "artist_name": "Bruce Hornsby and the Range",
    "image_url": "https://i.scdn.co/image/70c8e73c2db7a3a59af98e25fad79dea9f2543da",
    "artist_id": "7CwJ4VEWs7HV7MWQt9cgyk",
    "year": 1987
  },
  {
    "song_id": "6JYPFIVXYL3MkKfEfRSovu",
    "name": "Love You Down",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/3366651142031b36d0a03f6d2211e17303788ab9",
    "artist_name": "Ready For The World",
    "image_url": "https://i.scdn.co/image/470ae88a755e6cb62d93e9ad645f64ac5c0d38bb",
    "artist_id": "6h29EZDlc2NTvmCUe4yoXV",
    "year": 1987
  },
  {
    "song_id": "2WxRXUbJJFkJJkFyn8LfVj",
    "name": "Didn't We Almost Have It All",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/f09e216d0cc7b91a12e1a702f4e3ae58387218a8",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/1a45a2b6266e337b71d72d120800184ab2138657",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1987
  },
  {
    "song_id": "5MEMiHV54Y5ODV1zvEPsyp",
    "name": "I Still Haven't Found What I'm Looking For",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/899e03edfceb41dfd3f48bcb08bf2ed8b0f1d1fa",
    "artist_name": "U2",
    "image_url": "https://i.scdn.co/image/93cfa767f57094a3867c5ef172b6a92303723741",
    "artist_id": "51Blml2LZPmy7TTiAg47vQ",
    "year": 1987
  },
  {
    "song_id": "3333YXdcyths5CB8v28dZj",
    "name": "Let's Wait Awhile",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/47c81d18b842105f88d274703116b90156a50332",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/03074d8efc8a19b674282032a737dffabb656ac6",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1987
  },
  {
    "song_id": "1O7hDH3rrF8eyLxrpeoOfb",
    "name": "Casanova",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/7b3976d11378f83beba6f5791695cbcd0da3f627",
    "artist_name": "Levert",
    "image_url": "https://i.scdn.co/image/1989a1013dbee772396314a3e1c0a14afccbe33e",
    "artist_id": "0G7OYsWptjRzVFT1AxP8TS",
    "year": 1987
  },
  {
    "song_id": "5PZTuGuAEb9tavQklihOaR",
    "name": "Something So Strong",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/bdf28d2893c2717df914d377dbae4229f7471dbe",
    "artist_name": "Crowded House",
    "image_url": "https://i.scdn.co/image/6162506c64f2864088ee16fbb5274730e0b96135",
    "artist_id": "7ohlPA8dRBtCf92zaZCaaB",
    "year": 1987
  },
  {
    "song_id": "6EoAUmLaog9mUTMDtdVudr",
    "name": "Only In My Dreams",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/5cc385230fe8162d356bc250285b4224b2ac1772",
    "artist_name": "Debbie Gibson",
    "image_url": "https://i.scdn.co/image/2aee6f8bf49ef4be837935a9c82f837ec2387614",
    "artist_id": "18jZvCsW1PJ4FDQ5gEXuKp",
    "year": 1987
  },
  {
    "song_id": "7zSDDsIlks515d0tZGM64x",
    "name": "The Next Time I Fall",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/c9ccae8e3da43ac431d0bfc07fccbedf4c2ae953",
    "artist_name": "Peter Cetera with Amy Grant",
    "image_url": "https://i.scdn.co/image/da32dcf60b2e7cc62d31e55847027d5577813bda",
    "artist_id": "1WcZfOFFI5zfyTNJgsBAME",
    "year": 1987
  },
  {
    "song_id": "6302KY4zq4Bpx823hyk4Kk",
    "name": "Rhythm Is Gonna Get You",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/b56b917e4c8a9249a0e8f3117fadacf932e95eb7",
    "artist_name": "Gloria Estefan And Miami Sound Machine",
    "image_url": "https://i.scdn.co/image/26b654ecbe60b28397e6d8b5d829cebeeeb5590a",
    "artist_id": "2Gop8SbSVt5SaJzuaRFa7D",
    "year": 1987
  },
  {
    "song_id": "0x1RspO36DgmXbZ9XmxvsN",
    "name": "Can't We Try - Single Version",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/ed1024fb0288ef6b4664ef743804767416b064da",
    "artist_name": "Dan Hill",
    "image_url": "https://i.scdn.co/image/ff8d208d8049907f0def04e4729acc73fb319c4d",
    "artist_id": "5rOhfAsK4uxq9OdREiQRKa",
    "year": 1987
  },
  {
    "song_id": "5vywtoD7fO1CP0B2wtm6I6",
    "name": "Control",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/80e380f8c54566edaf82a2089e8b3a57229cf208",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/03074d8efc8a19b674282032a737dffabb656ac6",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1987
  },
  {
    "song_id": "6OtKL4zUA9WazCB7P03d6N",
    "name": "Luka",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/26528f56a420ad2f890610785327c64e75d697a2",
    "artist_name": "Suzanne Vega",
    "image_url": "https://i.scdn.co/image/34697ac06d3c97d37f2c631d3d5c32a26f020e89",
    "artist_id": "3X0tJzVYoWlfjLYI0Ridsw",
    "year": 1987
  },
  {
    "song_id": "0tW6lCSXIZgo0xCVLwCbjE",
    "name": "I Heard A Rumour",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/8ac8f8ddc98db5f012f115c8f7e160cbd4e32630",
    "artist_name": "Bananarama",
    "image_url": "https://i.scdn.co/image/d89e7dfcfb183146d8d4b26400238ffb99ce805e",
    "artist_id": "3sc7iUG1Wwpwx7bHeZolgx",
    "year": 1987
  },
  {
    "song_id": "66c7uXiQLsw3LpuhGlGNAi",
    "name": "To Be A Lover - 2001 Digital Remaster",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/cea7d29c0f22845629c8b1b55d65f7ba538944cb",
    "artist_name": "Billy Idol",
    "image_url": "https://i.scdn.co/image/ae4358812edea60bdecbcec4d4508e7f7e2c0da6",
    "artist_id": "7lzordPuZEXxwt9aoVZYmG",
    "year": 1987
  },
  {
    "song_id": "5Djof0IgvGDl5RN0TZnNgT",
    "name": "Tonight, Tonight, Tonight",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/ba8f3fe06080645e42283b2828f29b730eb9deb9",
    "artist_name": "Genesis",
    "image_url": "https://i.scdn.co/image/431343e6995d6b71cc700fd24d18f336dfcbe61a",
    "artist_id": "3CkvROUTQ6nRi9yQOcsB50",
    "year": 1987
  },
  {
    "song_id": "6Ce9ItNCpk2PkAMq5v6EEV",
    "name": "C'est La Vie",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/586c68dd29e6d293341fbeb8fb49c6046ba04124",
    "artist_name": "Robbie Nevil",
    "image_url": "https://i.scdn.co/image/e1e86d582cd4cbe1df979cb6e6ae2c8a2d84c50f",
    "artist_id": "4NkLjsRsFnuPu9B4zqzBqq",
    "year": 1987
  },
  {
    "song_id": "5dQ51PaLQPl1vkL4wFBYON",
    "name": "Bad",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/7170ba043d60a4ef7512943d7b61651cf20d472c",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1987
  },
  {
    "song_id": "0rPImSYezi40zBhwvIvTrr",
    "name": "I've Been In Love Before - Single Version",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/db15282e392631da9f09e16e988b2eac59b96ef2",
    "artist_name": "Cutting Crew",
    "image_url": "https://i.scdn.co/image/31890637c73dec249f188e33bb69c2d9a31b0680",
    "artist_id": "3cniTumSiUysiPWXapGx1i",
    "year": 1987
  },
  {
    "song_id": "3sX3c6QUPSAiKf77RZRf5B",
    "name": "Causing A Commotion - Soundtrack",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/6f168ea021218ab295d2b895d71e7a5bba27ef6c",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/bfadb543bf06d3ec767ca2a85d0a566d1ac11140",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1987
  },
  {
    "song_id": "5CMfppUYFwdvH8pZ5HRJbS",
    "name": "Come Go with Me",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/7b7133a5bc35d601cf792bca101599ec1c241d4f",
    "artist_name": "Exposé",
    "image_url": "https://i.scdn.co/image/8d9d2d252b25ae7bbd4214b42ef511cf32fddbe8",
    "artist_id": "5nPz5R1FDPTMI3Dw4JnTGb",
    "year": 1987
  },
  {
    "song_id": "2GdWNthCTVMBnuw59WyIci",
    "name": "Doing It All For My Baby - 2006 Digital Remaster",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/48c5502e15b53c1d570dc5b25feb6ebb2fb962c6",
    "artist_name": "Huey Lewis & The News",
    "image_url": "https://i.scdn.co/image/57f7e47cbf6c3da3e2e451221bbe4784df39d7b0",
    "artist_id": "7A9yZMTrFZcgEWAX2kBfK6",
    "year": 1987
  },
  {
    "song_id": "4RiagUUJVbGgQpo9S86Wn6",
    "name": "Shake You Down - Single Version",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/a513ec44f3a8116d417dae94a6f55fbb508b447d",
    "artist_name": "Gregory Abbott",
    "image_url": "https://i.scdn.co/image/ff8d208d8049907f0def04e4729acc73fb319c4d",
    "artist_id": "6zdcmro5vDQSUjeioajL2r",
    "year": 1987
  },
  {
    "song_id": "6LDRTOMCSx81hKXAk1LuHd",
    "name": "At This Moment",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/5119c9f21b2df82f36699eb48e93eb7af1db85ff",
    "artist_name": "Billy Vera & The Beaters",
    "image_url": "https://i.scdn.co/image/0b35647717680af004a35fe50c6cc73185e6bc37",
    "artist_id": "1QQBo7mfkehMjDfz1tj5Pq",
    "year": 1987
  },
  {
    "song_id": "3PMnGAts25JWoEBgc4iEYh",
    "name": "Jacob's Ladder - 2006 Digital Remaster",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/2a0c7854e035a37f2cd4f3eb711dd5fd94c9c3b6",
    "artist_name": "Huey Lewis & The News",
    "image_url": "https://i.scdn.co/image/57f7e47cbf6c3da3e2e451221bbe4784df39d7b0",
    "artist_id": "7A9yZMTrFZcgEWAX2kBfK6",
    "year": 1987
  },
  {
    "song_id": "5O1gjRyWVqZAECJOSDYUGJ",
    "name": "When Smokey Sings",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/00a361f6f70a9d54578620255aebb75150f41484",
    "artist_name": "ABC",
    "image_url": "https://i.scdn.co/image/ee92a4c3daee624666aa480e895100305a33e210",
    "artist_id": "2s79xe5F6eUQkjwjww27Fh",
    "year": 1987
  },
  {
    "song_id": "0qrOEuhMXqAyMMyfOCZ2yC",
    "name": "The Finer Things",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/ef28c14ac74cec6c222b5bb9d2b71df5284421a2",
    "artist_name": "Steve Winwood",
    "image_url": "https://i.scdn.co/image/2f0d510ff8f04c81d24473852110e7123feb365c",
    "artist_id": "5gxynDEKwNDgxGJmJjZyte",
    "year": 1987
  },
  {
    "song_id": "3t14IyH0Pwz1E7oMJZD1u5",
    "name": "Let Me Be The One",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/9ea2a14d1d8dfa3da09e9651866fea09b177c9bc",
    "artist_name": "Exposé",
    "image_url": "https://i.scdn.co/image/a6265bc20d784eabede0b83397c648e0373f1c9a",
    "artist_id": "5nPz5R1FDPTMI3Dw4JnTGb",
    "year": 1987
  },
  {
    "song_id": "2tjPr1eN1bQIYn7APY0YjR",
    "name": "You Got It All",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/3f2d3dc0dce608be960a0b5e7dd324d85e4b83b7",
    "artist_name": "The Jets",
    "image_url": "https://i.scdn.co/image/f7cbad75b02782c81b272ce4dd26c70282c81bb0",
    "artist_id": "3AHq6rutf72JF0ul8GB6G2",
    "year": 1987
  },
  {
    "song_id": "5CdX4HXMRxyldTVxXDrzSA",
    "name": "Meet Me Half Way",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/e3a95751197ad1a52213cc5cd1073f5f6d0780ec",
    "artist_name": "Kenny Loggins",
    "image_url": "https://i.scdn.co/image/9a44c49c24e0bcda5dcd36976722c04eee75272c",
    "artist_id": "3Y3xIwWyq5wnNHPp5gPjOW",
    "year": 1987
  },
  {
    "song_id": "0f6pHbsLzpWxl5CfhOUjBA",
    "name": "I Knew You Were Waiting (For Me)",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/a8aaa68af9e768e86503b426c19e526a73469cd8",
    "artist_name": "George Michael",
    "image_url": "https://i.scdn.co/image/b5a635c6b28dc8dfa9123aab6904b6e5b8ad23d5",
    "artist_id": "19ra5tSw0tWufvUp8GotLo",
    "year": 1987
  },
  {
    "song_id": "4xGyNXNjL8tHkLJEqf2BRs",
    "name": "Rock Steady",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/bb5ce4966c49fa69460b3170a5dfabbc8f8456a6",
    "artist_name": "The Whispers",
    "image_url": "https://i.scdn.co/image/1b33a1c3f6a63289fb7dc5538f82cbd32e8a097c",
    "artist_id": "2XzHxDFTNywDd5ReMhgxkg",
    "year": 1987
  },
  {
    "song_id": "7BeV8s7R68eqPelMmvp5tu",
    "name": "Looking For A New Love - Radio Edit",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/981eb92c6ded2d07118fbdeeee50504b37b9c62f",
    "artist_name": "Jody Watley",
    "image_url": "https://i.scdn.co/image/5fa631c49b10eba706352346a98147251c680303",
    "artist_id": "71aKjsWKYqASAffyIQaocZ",
    "year": 1987
  },
  {
    "song_id": "5WK0KIZmq3H0FpQ6O4Wkrz",
    "name": "Someday",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/213af91c43d40e79f3233c55444c3d03900680b1",
    "artist_name": "Glass Tiger",
    "image_url": "https://i.scdn.co/image/94065df049bbd71ecc6d8e322ed2948b2b159430",
    "artist_id": "53RaPTbZOx2mBoZD6LLWIv",
    "year": 1987
  },
  {
    "song_id": "06zAcTERy6OnugGpX21apj",
    "name": "You Keep Me Hangin' On",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/653ce0c366e735f30647bccdd6ca0c0a0775c62f",
    "artist_name": "Kim Wilde",
    "image_url": "https://i.scdn.co/image/fb5376e2cb0b306bbdc5c494a19d6c424dacede8",
    "artist_id": "73a6pNH4YtLNgDbPQwXveo",
    "year": 1987
  },
  {
    "song_id": "5FFRm8vumNUnq5AGdoMasg",
    "name": "Songbird",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/87840f6b69e3f1d76e63ea25c2b1c08a25784a65",
    "artist_name": "Kenny G",
    "image_url": "https://i.scdn.co/image/bba6f652ed2e709f5d02bcb4877871f1ead21d79",
    "artist_id": "6I3M904Y9IwgDjrQ9pANiB",
    "year": 1987
  },
  {
    "song_id": "4Kvz5bhHNYPSaGnQ7PsTBi",
    "name": "Change Of Heart",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/f2cb37535d58d023e985e21ef586e78b1b86ba94",
    "artist_name": "Cyndi Lauper",
    "image_url": "https://i.scdn.co/image/d9a6da7d9bbcc8c612e378b9334e54ad0c60c702",
    "artist_id": "2BTZIqw0ntH9MvilQ3ewNY",
    "year": 1987
  },
  {
    "song_id": "2G7ttyDosjWmunOYsNRm0C",
    "name": "Who Will You Run To",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/1f280b3de7462478656029c83a77a089b3a4091c",
    "artist_name": "Heart",
    "image_url": "https://i.scdn.co/image/ec46970f7d37115f946d6e7e98b4b8c1c4f86695",
    "artist_id": "34jw2BbxjoYalTp8cJFCPv",
    "year": 1987
  },
  {
    "song_id": "6UfLQNkw9a9tOVVqsev9vA",
    "name": "Funky Town",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/f506411ccfd45f3727ca7cf795065d9a35eedda2",
    "artist_name": "Pseudo Echo",
    "image_url": "https://i.scdn.co/image/bad89d2850af84ae5b9220c895c6507ecb41b7da",
    "artist_id": "0FDW3axHactZo8HU0OnNVy",
    "year": 1987
  },
  {
    "song_id": "4ujVRZd9HdjepbpWSddABF",
    "name": "Don't Mean Nothing",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/dbe102d566a157e714858c43b8ea323e224a8574",
    "artist_name": "Richard Marx",
    "image_url": "https://i.scdn.co/image/7e82e36df246a60db2b53204e819ac5eeab3ee30",
    "artist_id": "0grdhNhiRLFBaFVyybqsj6",
    "year": 1987
  },
  {
    "song_id": "4xu6en3eu5gt7fVlMZanzv",
    "name": "Point Of No Return - 7\" Version",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/317e71afe4831aecb50b6a6fe91610e4eb715876",
    "artist_name": "Exposé",
    "image_url": "https://i.scdn.co/image/a6265bc20d784eabede0b83397c648e0373f1c9a",
    "artist_id": "5nPz5R1FDPTMI3Dw4JnTGb",
    "year": 1987
  },
  {
    "song_id": "3UiuKZWtgXjeAfnfGqIeu0",
    "name": "Right On Track",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/ea8e469200bd792c741c6014912c80e07545ce05",
    "artist_name": "Breakfast Club",
    "image_url": "https://i.scdn.co/image/be2f405b5131eedd8ede77cd028840a8b16e7a75",
    "artist_id": "4nsuck0htFQjVl6OCKVzRb",
    "year": 1987
  },
  {
    "song_id": "7vGekIpStYxbauCP2HcXPq",
    "name": "Head To Toe",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/2a65d1607a88adfec56d359fb5ecffa9838778d0",
    "artist_name": "Lisa Lisa & Cult Jam",
    "image_url": "https://i.scdn.co/image/6ced27fb89d8fb4707ebc95b5ce8c6baa6ae5ff4",
    "artist_id": "1vgjN6nIPNKiiQGE9PtzTT",
    "year": 1987
  },
  {
    "song_id": "2fNx5nTZGjniOMT5FMcTyE",
    "name": "Ballerina Girl",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/668a9b3f70ee96fb05a9753cb390249493996a4d",
    "artist_name": "Lionel Richie",
    "image_url": "https://i.scdn.co/image/913b08847e425b3fb365a2fc85d51014d5f57cfb",
    "artist_id": "3gMaNLQm7D9MornNILzdSl",
    "year": 1987
  },
  {
    "song_id": "7fgiQqfZFQ9e65Kbe5yNBX",
    "name": "Is This Love",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/e0090bb4221a86db80c209071e8078dbcb9d6844",
    "artist_name": "Survivor",
    "image_url": "https://i.scdn.co/image/d2fce5df238dd2928bd67adecba677e56743f7a4",
    "artist_id": "26bcq2nyj5GB7uRr558iQg",
    "year": 1987
  },
  {
    "song_id": "3lXQkvrg2G22lDsrZyR8pK",
    "name": "The Lady In Red",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/95ba8f9b8936f17163005b6521fedf83e8642f2d",
    "artist_name": "Chris De Burgh",
    "image_url": "https://i.scdn.co/image/117f875389910c8c6a7de95be81bc5e448f2d76b",
    "artist_id": "2RpHsROrX075xfIwHn6B2U",
    "year": 1987
  },
  {
    "song_id": "6O5G3YcWmKqaLLMlCl0Xjj",
    "name": "Breakout",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/1addde40720b37e970cd68877ec0fd9348a9fd22",
    "artist_name": "Swing Out Sister",
    "image_url": "https://i.scdn.co/image/72d71642ea3ac11557aaf0d4aed8a52153f736e1",
    "artist_id": "4phr5rAgWhcZtWJu8w8lKv",
    "year": 1987
  },
  {
    "song_id": "2xEhTQcmrRNaxfUOvSAgXo",
    "name": "Heat Of The Night",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/f959a87380bdf1da108faa868c1afc6fd2ab0252",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/fe95b2acc8c5e39e71209cda1820463885352967",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1987
  },
  {
    "song_id": "3W5EcI4psPUHg6Wthw9JdX",
    "name": "Big Time",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/d086157b579a0f54cfd8dcca58a8bfe61170ce64",
    "artist_name": "Hardage",
    "image_url": "https://i.scdn.co/image/1c1bd33e0a0dca81e7edf3836a39ed2a0b5f3217",
    "artist_id": "23i5dMV7p0CSMcUBXk09lA",
    "year": 1987
  },
  {
    "song_id": "5GIyNhDmHO5oUI2CWyKQ2e",
    "name": "Respect Yourself",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/fcde90e4029a7f2f1a319e03dbf8ae732eb8c064",
    "artist_name": "Bruce Willis",
    "image_url": "https://i.scdn.co/image/f52a52f652510944e2b78587e4784573b8e564c4",
    "artist_id": "6UpHyjBIGRSHpna0GQ68bH",
    "year": 1987
  },
  {
    "song_id": "79FDwDeZ43rAN02oX8MynI",
    "name": "Cross My Broken Heart",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/26348464fbf119cc1493c7f438808a679baf21a7",
    "artist_name": "The Jets",
    "image_url": "https://i.scdn.co/image/1df6692be0aec0747bd23dd1b4de98b08eed944f",
    "artist_id": "3AHq6rutf72JF0ul8GB6G2",
    "year": 1987
  },
  {
    "song_id": "5nvSfsMm1MXVvZOxd5ZdBX",
    "name": "Touch Me (I Want Your Body)",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/52a87750f8d6c3ddac435df768f1c86dcebc4283",
    "artist_name": "Samantha Fox",
    "image_url": "https://i.scdn.co/image/1d4f805f7dda2b7688bb6c127bef16a51a883014",
    "artist_id": "0ym94xKp2PIOJtTZKpxbAa",
    "year": 1987
  },
  {
    "song_id": "325dZlvVCg3ckKt4oZR9ud",
    "name": "Just To See Her - Single Version",
    "popularity": 2,
    "preview_url": "https://p.scdn.co/mp3-preview/0162493f74f0881af9d9f1b0f9dfb7b5b39cb714",
    "artist_name": "Smokey Robinson",
    "image_url": "https://i.scdn.co/image/927ca51376a1b304acfa68d5dcba0e2607995389",
    "artist_id": "0h9smro0z3HqUbD94jotU8",
    "year": 1987
  },
  {
    "song_id": "2M9ro2krNb7nr7HSprkEgo",
    "name": "Fast Car",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/a5cbf30108f20c09919cb2f7d15c9c589e02ed95",
    "artist_name": "Tracy Chapman",
    "image_url": "https://i.scdn.co/image/19970404b9d849956f085f4b2175f06c73dc362c",
    "artist_id": "7oPgCQqMMXEXrNau5vxYZP",
    "year": 1988
  },
  {
    "song_id": "1nl09AjkuO7BBRF3BFAPsC",
    "name": "Man in the Mirror - Remastered Version",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/154f9c508062c873762e2a63f6342adae755246c",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/b51d252d914b4e1becfe7ae425697f170e2f769a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1988
  },
  {
    "song_id": "0HEmnAUT8PHznIAAmVXqFJ",
    "name": "Faith",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/523816eb0b952aa15867a67afdecce1186c30c92",
    "artist_name": "George Michael",
    "image_url": "https://i.scdn.co/image/b0918e54e212d316e08952621867fbaa296ca2b3",
    "artist_id": "19ra5tSw0tWufvUp8GotLo",
    "year": 1988
  },
  {
    "song_id": "1u1DBObkLFscNCpRpXbYb9",
    "name": "Sweet Child O' Mine",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/6cc96584f8dd7d821f164fe43060a94adb84a3b2",
    "artist_name": "Guns N' Roses",
    "image_url": "https://i.scdn.co/image/93ebbf2621c9264f2398bd6ea6f10be611196367",
    "artist_id": "3qm84nBOXUEQ2vnTfUTTFC",
    "year": 1988
  },
  {
    "song_id": "25BxqC9Yo7TQ175DLAyMJs",
    "name": "Hungry Eyes",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/ebea1708e1e08bd6322da11767d4effb491af822",
    "artist_name": "Dirty Dancing (Motion Picture Soundtrack)",
    "image_url": "https://i.scdn.co/image/1166faed41598593e460de5382ca2f0c07e43108",
    "artist_id": "7rm0Y0e15DI7r2jHBUQFDJ",
    "year": 1988
  },
  {
    "song_id": "2HXixVqzzm9rEUIMAWzshl",
    "name": "I Hate Myself for Loving You",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/a7537e0fb30f8631f22c53eac5bf4cc8b5b29b87",
    "artist_name": "Joan Jett",
    "image_url": "https://i.scdn.co/image/4dd319b5be04dc12435975306eba84e41bfbeba5",
    "artist_id": "6ZRWxu4ak88m5yHBAEelkQ",
    "year": 1988
  },
  {
    "song_id": "5eU8qMd0TpaLqTGDZJaLDs",
    "name": "Need You Tonight",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/1b1773b2d7a5276068ffe2bc37907b2bf90561a3",
    "artist_name": "INXS",
    "image_url": "https://i.scdn.co/image/e6dfb857845285e927e56056d84348fd9458f933",
    "artist_id": "1eClJfHLoDI4rZe5HxzBFv",
    "year": 1988
  },
  {
    "song_id": "3d4I2tQUz16S8gesU2pLSM",
    "name": "She's Like the Wind",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/d4060f5f3ebd3a07896a70f288e1437e5cf71090",
    "artist_name": "Patrick Swayze",
    "image_url": "https://i.scdn.co/image/1166faed41598593e460de5382ca2f0c07e43108",
    "artist_id": "6dH5I8Q7HhXu74cBXkP0LD",
    "year": 1988
  },
  {
    "song_id": "0L0T4tMAaGqLgIVj1MOj9t",
    "name": "Father Figure - Remastered",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/2f7ac4d20e50221caf6b0e35e37ac0ac6bbe67fb",
    "artist_name": "George Michael",
    "image_url": "https://i.scdn.co/image/b0918e54e212d316e08952621867fbaa296ca2b3",
    "artist_id": "19ra5tSw0tWufvUp8GotLo",
    "year": 1988
  },
  {
    "song_id": "4uLU6hMCjMI75M1A2tKUQC",
    "name": "Never Gonna Give You Up",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/e430df78f3661eee48dfedd75929fbd19d5228f1",
    "artist_name": "Rick Astley",
    "image_url": "https://i.scdn.co/image/0fb039c2cb59cb5703a8e33e9a998dd4031887df",
    "artist_id": "0gxyHStUsqpMadRV0Di1Qt",
    "year": 1988
  },
  {
    "song_id": "3v8vsQfMQio7ohYqFrEsaZ",
    "name": "Get Outta My Dreams, Get into My Car",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/5e2f97cd45a2c284659d072002fc579292425afa",
    "artist_name": "Billy Ocean",
    "image_url": "https://i.scdn.co/image/bc27e2cb738eef10c4d70e7cb7059b93d41697e8",
    "artist_id": "5IDs1CK15HegSAhGEbSYXo",
    "year": 1988
  },
  {
    "song_id": "1NW32mer4GFgDvDZ0idTUt",
    "name": "Nothin' But A Good Time - 2006 - Remaster",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/f193115132ebd831619e1c0d7b14c2adad013ed3",
    "artist_name": "Poison",
    "image_url": "https://i.scdn.co/image/b807ae045a04891d78ef621c0b123dd2c55ce150",
    "artist_id": "1fBCIkoPOPCDLUxGuWNvyo",
    "year": 1988
  },
  {
    "song_id": "7KFThZQCAcj8JXdPRtdrXE",
    "name": "The Way You Make Me Feel - Single Version",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/020fe2680883cc626e221a44f46550b207ecd32e",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1988
  },
  {
    "song_id": "1qQSRy9DjrGh3jtsSCU6B1",
    "name": "Never Tear Us Apart",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/9268ba914b8fdb76ad276b96e071c65769a27887",
    "artist_name": "INXS",
    "image_url": "https://i.scdn.co/image/e6dfb857845285e927e56056d84348fd9458f933",
    "artist_id": "1eClJfHLoDI4rZe5HxzBFv",
    "year": 1988
  },
  {
    "song_id": "2PoIKrT2WSEvJlJjlO6eKY",
    "name": "Is This Love",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/88e498473e46a0dd7ade9df0097efd61c3e063e0",
    "artist_name": "Whitesnake",
    "image_url": "https://i.scdn.co/image/9ea533fb709034051995a0a39bc9cf9686d01513",
    "artist_id": "3UbyYnvNIT5DFXU4WgiGpP",
    "year": 1988
  },
  {
    "song_id": "15vASgdsk0J4BrkZhVTxFG",
    "name": "Heaven Is A Place On Earth",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/87a12711c19040195ee450b4d26dd0b96e91261a",
    "artist_name": "Belinda Carlisle",
    "image_url": "https://i.scdn.co/image/c6f1563c4446ad22f2c1c16098614b9b7655fd55",
    "artist_id": "7xkAwz0bQTGDSbkofyQt3U",
    "year": 1988
  },
  {
    "song_id": "3jaUZhLiZOcgoJiGGd3hFD",
    "name": "One Moment in Time",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/85a13f6ec6fc3d94bea5395ffcedfcc7d854e279",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/1a45a2b6266e337b71d72d120800184ab2138657",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1988
  },
  {
    "song_id": "2bvzxeD1hPWEYotw40Euq9",
    "name": "Wishing Well",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/1194f93e2b520bba9d84bb2e4c998e25a58cf72d",
    "artist_name": "Terence Trent D'Arby",
    "image_url": "https://i.scdn.co/image/417c375cbd1ba148bf3ba53858615b97b639fffd",
    "artist_id": "1JpHKf8Ztyd8HXU8p6m9cc",
    "year": 1988
  },
  {
    "song_id": "709znSutWha3sQdPgdSO00",
    "name": "Where Do Broken Hearts Go",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/a84f11c254580f037b9f76f841445da8f06cc2a2",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/1a45a2b6266e337b71d72d120800184ab2138657",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1988
  },
  {
    "song_id": "0bSr5pybJSlgG5OZH441gF",
    "name": "Simply Irresistible",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/6e8c39c361c2124d6e818e3a846e672682e56e5c",
    "artist_name": "Robert Palmer",
    "image_url": "https://i.scdn.co/image/633cddcca85bcfdd55fee0d363ec2d3bd71876f5",
    "artist_id": "530Sdm7eqqzWBdDmILMgnu",
    "year": 1988
  },
  {
    "song_id": "1tOE1dIyIjsfLJWiQ4ZRJW",
    "name": "Red Red Wine - 12'' Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/4f7fa04115458701bde319053adfb013522a0c21",
    "artist_name": "UB40",
    "image_url": "https://i.scdn.co/image/e42bc0c7491ccf322281e326bc5d34455da90ed5",
    "artist_id": "69MEO1AADKg1IZrq2XLzo5",
    "year": 1988
  },
  {
    "song_id": "5huh0htdiJDatAtbHLtpfz",
    "name": "A Groovy Kind Of Love",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/54f37a08077b748b2a189534893c04d44627e2c8",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/0589331408d694c0f2da59a5854ef3d027e3dd4a",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1988
  },
  {
    "song_id": "2iXH35MhsqO5Ry8a7iptpJ",
    "name": "Endless Summer Nights",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/30666aa3e98fdacd4487ddd19f1d0c931f72c2fb",
    "artist_name": "Richard Marx",
    "image_url": "https://i.scdn.co/image/7e82e36df246a60db2b53204e819ac5eeab3ee30",
    "artist_id": "0grdhNhiRLFBaFVyybqsj6",
    "year": 1988
  },
  {
    "song_id": "0P2vAvvWni2tNXOdbH3JFk",
    "name": "Hazy Shade of Winter",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/ff651f852a323ad748a0475db44562d88c2498a4",
    "artist_name": "The Bangles",
    "image_url": "https://i.scdn.co/image/d1613688a9cdce0338f7a241748c06cccd6baca8",
    "artist_id": "51l0uqRxGaczYr4271pVIC",
    "year": 1988
  },
  {
    "song_id": "6oGPZcErzd9Hjw3E4sDBrq",
    "name": "If It Isn't Love",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/1792faf5bf31e7f83e5148e3bd91be580737a9af",
    "artist_name": "New Edition",
    "image_url": "https://i.scdn.co/image/4af9c7e49bc3b40fb1aaae8c8fdf6d9ec8d132ce",
    "artist_id": "1mFX1QlezK1lNPKQJkhwWb",
    "year": 1988
  },
  {
    "song_id": "3F2YXxSOC9dPmxXdrh6mYl",
    "name": "Hold On To The Nights",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/e60e5bef2103721ece46236c86a6dea73685c33b",
    "artist_name": "Richard Marx",
    "image_url": "https://i.scdn.co/image/7e82e36df246a60db2b53204e819ac5eeab3ee30",
    "artist_id": "0grdhNhiRLFBaFVyybqsj6",
    "year": 1988
  },
  {
    "song_id": "2QlPByrmEO9XciNBXCrawR",
    "name": "Roll With It",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/5151a1e293c9cfdf2d28c5561555ec45d89f9eeb",
    "artist_name": "Steve Winwood",
    "image_url": "https://i.scdn.co/image/e21c62616c210b162e1e363613e885e4cf9ea3b4",
    "artist_id": "5gxynDEKwNDgxGJmJjZyte",
    "year": 1988
  },
  {
    "song_id": "0ZbYgFfUB0GZH2nFEH9DVO",
    "name": "Bad Medicine",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/e08f0e13e7f541c4abdc57c286d87f986dba324c",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/efff863dfbfe40cc97f25bbb51e837c36fe4510e",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1988
  },
  {
    "song_id": "2bxY1baYlyXNzmjOkFvqId",
    "name": "New Sensation",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/d3086fbb8f55822fc7c3e4b4a1fca3ea40845ce2",
    "artist_name": "INXS",
    "image_url": "https://i.scdn.co/image/e6dfb857845285e927e56056d84348fd9458f933",
    "artist_id": "1eClJfHLoDI4rZe5HxzBFv",
    "year": 1988
  },
  {
    "song_id": "2SWn864dJI5RNyrp3F4Kp1",
    "name": "Always On My Mind - 2003 Remastered Version",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/90e8360ba1c22c3df8e4c8e160838463367e0cd8",
    "artist_name": "Pet Shop Boys",
    "image_url": "https://i.scdn.co/image/91f5755f979ef2dbc04d08ec2b5108a00d3e122c",
    "artist_id": "2ycnb8Er79LoH2AsR5ldjh",
    "year": 1988
  },
  {
    "song_id": "1acz4dYqwZTZw6TgRBmmf2",
    "name": "Nite And Day",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/e4638b712768801804e8a629a9b1eb66dd641d4e",
    "artist_name": "Al B. Sure!",
    "image_url": "https://i.scdn.co/image/ff230d87611e2111beb7039308735453f418dc08",
    "artist_id": "1fvz0vd4P0LNMkAysF1ivk",
    "year": 1988
  },
  {
    "song_id": "1NkoPsSffzDOir92bPzmML",
    "name": "Cherry Bomb",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/2951a0a3ccb60a5ede33ec51d362366f0282fde3",
    "artist_name": "John Mellencamp",
    "image_url": "https://i.scdn.co/image/2252c94914f263d66846123192aae9ce9ade7082",
    "artist_id": "3lPQ2Fk5JOwGWAF3ORFCqH",
    "year": 1988
  },
  {
    "song_id": "13wQ3arfFztwlASWhx6RBg",
    "name": "Just Like Paradise",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/fd98166ef1cc19d7c6c4c3c6464a19d8c151f243",
    "artist_name": "David Lee Roth",
    "image_url": "https://i.scdn.co/image/d46c4d4464f059aec0ddda7b182be41a45cfad73",
    "artist_id": "0KyCXNSa7ZMb5LydfKbLG3",
    "year": 1988
  },
  {
    "song_id": "1ZvS1DmW1cF0oknYGCVNK7",
    "name": "Candle In The Wind",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/dab5c538fd451a8ab566b43282db55ea2f18ff12",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/90104614b4f215c8acd203e9d8d82d6a1c1ab8cf",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1988
  },
  {
    "song_id": "4hObp5bmIJ3PP3cKA9K9GY",
    "name": "Don't Worry Be Happy",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/ee567c1a5345e1d356223b3762533c58bfd228db",
    "artist_name": "Bobby McFerrin",
    "image_url": "https://i.scdn.co/image/a01dc6a192bbdc9c5d94dddee3f0f10fc4a0a4af",
    "artist_id": "2FjkZT851ez950cyPjeYid",
    "year": 1988
  },
  {
    "song_id": "5j1zOOvkxxe4uZZ2Jgl8r4",
    "name": "Should've Known Better",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/240551f41c0eec51854a5167bcb7a110b87f70e4",
    "artist_name": "Richard Marx",
    "image_url": "https://i.scdn.co/image/7e82e36df246a60db2b53204e819ac5eeab3ee30",
    "artist_id": "0grdhNhiRLFBaFVyybqsj6",
    "year": 1988
  },
  {
    "song_id": "1cVXMYFOGZHKqoj5pKTtpi",
    "name": "Hands To Heaven - Original Hit Version",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/b5a25c597eee19122f78ccc5f0bc3aaea2fdf705",
    "artist_name": "Breathe",
    "image_url": "https://i.scdn.co/image/b6970fee648032546ee8aeedbbdab2d9a2bc6e0f",
    "artist_id": "1MNeXu0284O7mQWgF1YRc0",
    "year": 1988
  },
  {
    "song_id": "3Zzb3YPzYL1vRtLF0IlDWp",
    "name": "I'll Always Love You",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/abe5c3c00fbc10df17ac0e15fc1f8022c3c1eed1",
    "artist_name": "Taylor Dayne",
    "image_url": "https://i.scdn.co/image/55f065a07bca72bb125f5296d4a3ffff38c3c825",
    "artist_id": "32lVGr0fSRGT6okLKHiP68",
    "year": 1988
  },
  {
    "song_id": "70RPjUR5l0jmBQndfNXP0z",
    "name": "Piano In The Dark",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/8964e374b5227291f38dda706da384f57909cb1c",
    "artist_name": "Brenda Russell",
    "image_url": "https://i.scdn.co/image/0e3b3586eaedf0da867970f16ff38cc6094513a0",
    "artist_id": "2O8VlquQPITO4tT3SWs95W",
    "year": 1988
  },
  {
    "song_id": "0f24hVOA4gLM8imuvoQ0Uh",
    "name": "Seasons Change - 7\" Version",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/0d390916d7ae8c0108ad138fbe9fc28ccd372071",
    "artist_name": "Exposé",
    "image_url": "https://i.scdn.co/image/8d9d2d252b25ae7bbd4214b42ef511cf32fddbe8",
    "artist_id": "5nPz5R1FDPTMI3Dw4JnTGb",
    "year": 1988
  },
  {
    "song_id": "4Qn1QhHhUT01LfpBnC7xnf",
    "name": "Wild Wild West",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/aef216d7dc1d9dafddc5bf3053a62e6f9fc739e9",
    "artist_name": "The Escape Club",
    "image_url": "https://i.scdn.co/image/c6b4bb8b4476900a425c455550faeef7a61c8101",
    "artist_id": "6mRev5KzKOgQcZjWanwBdd",
    "year": 1988
  },
  {
    "song_id": "6oXa5pLk2L2ZfmeUPHxP35",
    "name": "Say You Will (2008 Remastered LP Version)",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/6c3ea226ca61c72d1b4672adf401e4993c8190f7",
    "artist_name": "Foreigner",
    "image_url": "https://i.scdn.co/image/27c9e093bd5b9817deb910b41e9094388a3f2962",
    "artist_id": "6IRouO5mvvfcyxtPDKMYFN",
    "year": 1988
  },
  {
    "song_id": "5932kbyNt445gDTT2chRUS",
    "name": "One More Try - Remastered",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/be352d0995822959771a3826cde5059487a52b89",
    "artist_name": "George Michael",
    "image_url": "https://i.scdn.co/image/b0918e54e212d316e08952621867fbaa296ca2b3",
    "artist_id": "19ra5tSw0tWufvUp8GotLo",
    "year": 1988
  },
  {
    "song_id": "3YdhuJjqZaJKC4xWKUUyYl",
    "name": "Pour Some Sugar On Me - Live",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/9828033f0295cc7ec5a0af27eb775bb8a7a51d35",
    "artist_name": "Def Leppard",
    "image_url": "https://i.scdn.co/image/cf8be16805a78c4d7cf8b3f71ea7bdef4a4b3d31",
    "artist_id": "6H1RjVyNruCmrBEWRbD0VZ",
    "year": 1988
  },
  {
    "song_id": "6iCCvEH7xhkcsFTXnLVLIK",
    "name": "Love Bites - Live",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/fbe49e215663f6af21f68f7fd89669915d65bc9e",
    "artist_name": "Def Leppard",
    "image_url": "https://i.scdn.co/image/cf8be16805a78c4d7cf8b3f71ea7bdef4a4b3d31",
    "artist_id": "6H1RjVyNruCmrBEWRbD0VZ",
    "year": 1988
  },
  {
    "song_id": "1N9bMjIVVq2gLMbAecDnul",
    "name": "Together Forever",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/290e2b7bc584ecefa473cfee6672921940d540c0",
    "artist_name": "Rick Astley",
    "image_url": "https://i.scdn.co/image/80806ad816cf000acc8cf43e30f48eb578a65ac8",
    "artist_id": "0gxyHStUsqpMadRV0Di1Qt",
    "year": 1988
  },
  {
    "song_id": "0s4oyRzaYgi8L1djUKlpXl",
    "name": "I Don't Want To Live Without You (2008 Remastered LP Version)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/09451294ccbd02b7c281de25aed1ad4c7b54a5c4",
    "artist_name": "Foreigner",
    "image_url": "https://i.scdn.co/image/27c9e093bd5b9817deb910b41e9094388a3f2962",
    "artist_id": "6IRouO5mvvfcyxtPDKMYFN",
    "year": 1988
  },
  {
    "song_id": "31HhcRSNcS8Rgv9ldIfh66",
    "name": "Make Me Lose Control - Digitally Remastered 1997",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/413fbf9f6f0e121ec25b1f0b7d964a80e8db5a6d",
    "artist_name": "Eric Carmen",
    "image_url": "https://i.scdn.co/image/4f3e995fdc6206633ffb572ec853b3d747f27a09",
    "artist_id": "2ekjTXgjxbWwBX5lTAj4DU",
    "year": 1988
  },
  {
    "song_id": "1k82KNC5Ds6HRUXbf6EaHE",
    "name": "What's On Your Mind - Pure Energy",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/ba15a2be649d99a44b257ff25eea51953b4a7f24",
    "artist_name": "Information Society",
    "image_url": "https://i.scdn.co/image/3acf22d3ed4ec7356d0bbab997d325ce8bb6d1f4",
    "artist_id": "6bJUzb3mLEYCkTAp7eNJgO",
    "year": 1988
  },
  {
    "song_id": "3nQuDlaVZApyrG6tdsETe0",
    "name": "Valerie",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/ccf6216f45abfb761ba9f3a2808c2afae82c2aee",
    "artist_name": "Steve Winwood",
    "image_url": "https://i.scdn.co/image/715304b2637114a19e063ebd9ad1a2c86b309a9a",
    "artist_id": "5gxynDEKwNDgxGJmJjZyte",
    "year": 1988
  },
  {
    "song_id": "1kCDxLOz1abPEbVtFBpkl1",
    "name": "Naughty Girls (Need Love Too)",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/f3629272526a00c8249b5dcc959eb9c7da09eafe",
    "artist_name": "Samantha Fox",
    "image_url": "https://i.scdn.co/image/24041691f3e25fc17b03e620965ea1f0d83dd12d",
    "artist_id": "0ym94xKp2PIOJtTZKpxbAa",
    "year": 1988
  },
  {
    "song_id": "3sasIFIs1FUbB9gp03JUts",
    "name": "Make It Real",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/0a04bf5fa7111e02a53ef4420bc8bf2767d5e927",
    "artist_name": "The Jets",
    "image_url": "https://i.scdn.co/image/1df6692be0aec0747bd23dd1b4de98b08eed944f",
    "artist_id": "3AHq6rutf72JF0ul8GB6G2",
    "year": 1988
  },
  {
    "song_id": "4Izbs7Qd0mXaDtnX7X5p7o",
    "name": "I Get Weak",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/0cb04f931ba46813d777a20b52bf5fd749e04e41",
    "artist_name": "Belinda Carlisle",
    "image_url": "https://i.scdn.co/image/c6f1563c4446ad22f2c1c16098614b9b7655fd55",
    "artist_id": "7xkAwz0bQTGDSbkofyQt3U",
    "year": 1988
  },
  {
    "song_id": "5sqPn6Z32jbyYI59O8D9lg",
    "name": "Dirty Diana",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/7e445b613ef2d399dfa0c084470fbf5dc2f5870e",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1988
  },
  {
    "song_id": "0Kpc3tRv7oC1pVPQV1ikiu",
    "name": "Angel",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/def8ffc9935da5b2c3c83f2eff04aaa55647e975",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/3208f60d9b33a29d4ffa63618b3771a018015f85",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1988
  },
  {
    "song_id": "7egxDkaA0A2vXaB6p7zJvo",
    "name": "I Don't Wanna Live Without Your Love - Remastered",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/875c1c623c289e026ff35aac25959fcb3320c251",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/13e99c93468f6e338ac1f018d97503896c01989c",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1988
  },
  {
    "song_id": "7r4ZN8F22eBQeN0aMqwzgN",
    "name": "Don't You Want Me",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/26c4b6b451b1a3b4675a617af1adc4ae8ca46138",
    "artist_name": "Jody Watley",
    "image_url": "https://i.scdn.co/image/a0723efd58c5294ccf75252284da660d370ee225",
    "artist_id": "71aKjsWKYqASAffyIQaocZ",
    "year": 1988
  },
  {
    "song_id": "6gM3uxq9TPkms83bTSlK10",
    "name": "Shake Your Love",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/4486b5640cd67aa48e440fb256f8374bf6488701",
    "artist_name": "Debbie Gibson",
    "image_url": "https://i.scdn.co/image/ca322260c8bb7bc2c3dfe327f1fc252271d454a5",
    "artist_id": "18jZvCsW1PJ4FDQ5gEXuKp",
    "year": 1988
  },
  {
    "song_id": "7ttAAvkXzs3IC1Qm3H1N3k",
    "name": "One Good Woman",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/97346e400806ae3e220835ccbade58b28c82e2d3",
    "artist_name": "Peter Cetera",
    "image_url": "https://i.scdn.co/image/7d7350b872412e577b264d1eabbd6694af388c49",
    "artist_id": "5xWPOujQqd4wXyB08slZ9Z",
    "year": 1988
  },
  {
    "song_id": "0uyoXwOQ3U5tGfNNmR5Y1p",
    "name": "Tell It to My Heart",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/71c0aa503b40afaab3f6f82a76fce2eed9cd570c",
    "artist_name": "Taylor Dayne",
    "image_url": "https://i.scdn.co/image/3737c8b02fb4e2635bcefafccabc0e43e5e7536e",
    "artist_id": "32lVGr0fSRGT6okLKHiP68",
    "year": 1988
  },
  {
    "song_id": "4D01oA1mGouaAT7fubvKRT",
    "name": "Desire",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/f67439087bd11aa8c6bfb26f292139ec0ea2c113",
    "artist_name": "U2",
    "image_url": "https://i.scdn.co/image/bc047b6dd5a135c05a6d35573b7bec3dd368c718",
    "artist_id": "51Blml2LZPmy7TTiAg47vQ",
    "year": 1988
  },
  {
    "song_id": "7gG1RvwQRrofiL3tvq8pBI",
    "name": "When It's Love",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/00fcc1e46d90a1603dd5249e576430da6b38cbf2",
    "artist_name": "Van Halen",
    "image_url": "https://i.scdn.co/image/4fa8b4a9d1a0c05a2cfc38c3c22c56faf60283b6",
    "artist_id": "2cnMpRsOVqtPMfq7YiFE6K",
    "year": 1988
  },
  {
    "song_id": "1iIJqJ2TkadCzgxaDwu2Wd",
    "name": "Don't Shed A Tear - Original Hit Version",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/721fcecd32d1dbd1b46728a8902beeb414d374df",
    "artist_name": "Paul Carrack",
    "image_url": "https://i.scdn.co/image/b6970fee648032546ee8aeedbbdab2d9a2bc6e0f",
    "artist_id": "0FFuvdY7fuiuTmHN9unYoz",
    "year": 1988
  },
  {
    "song_id": "7D8LfO2ijOq9DnaVntnc3Q",
    "name": "I Still Believe - English Version",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/d3ede9f2b66a0c54ab7a0e2bc42c2748f288216d",
    "artist_name": "Brenda K. Starr",
    "image_url": "https://i.scdn.co/image/fd12e3b226de8d292b8714ea01724b3a4aa00461",
    "artist_id": "5Ne8hQEmy0eZu9hlE3j58B",
    "year": 1988
  },
  {
    "song_id": "7cm9QkrwSWH0scegcO8XZ2",
    "name": "I Found Someone",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/76f6e3118aaf667bea5ed6437e02eb4fb8ea97ef",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/885e90394f96d7e7456ed7d39e557c74fd3e29ab",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1988
  },
  {
    "song_id": "3nyNWWK7Be6E6EUHtQrvvW",
    "name": "Foolish Beat",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/4947ecd5e78f81ee5a428bbf6c6285ed96e01e40",
    "artist_name": "Debbie Gibson",
    "image_url": "https://i.scdn.co/image/af445d2856e3c7b88c4b64de4aea804a8f9c08d0",
    "artist_id": "18jZvCsW1PJ4FDQ5gEXuKp",
    "year": 1988
  },
  {
    "song_id": "5hVGFQDpLRzVUVrZ4tKOnG",
    "name": "Don't Be Cruel - Radio Edit",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/4115844110a445706993da79a7f7f84668b820ca",
    "artist_name": "Bobby Brown",
    "image_url": "https://i.scdn.co/image/6f957badd6f1750952d1c06b5a6658601e7a500c",
    "artist_id": "62sPt3fswraiEPnKQpAbdE",
    "year": 1988
  },
  {
    "song_id": "1ci5FGk5faD2gWyYl995yw",
    "name": "Mercedes Boy",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/baf5693b6bbd48ee6244ab4602fe11a7f517a843",
    "artist_name": "Pebbles",
    "image_url": "https://i.scdn.co/image/27983b26a114aa1e13aa74eaca1cb8a04f6d9eac",
    "artist_id": "702AvACMk5mieXIYlC2Gxx",
    "year": 1988
  },
  {
    "song_id": "6zVXRnA6LkhMpHpJsyUbQv",
    "name": "Catch Me I'm Falling",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/27048af5a2a9a18cf6382899f19ff7e7e913bf26",
    "artist_name": "Pretty Poison",
    "image_url": "https://i.scdn.co/image/50431a74bf6ed9ebdb3ccb67bc22827f5e849b5e",
    "artist_id": "4Xe7UmOgczT2GMm5uK8Axu",
    "year": 1988
  },
  {
    "song_id": "1xvGuRbLxTkpb5UdUG7HME",
    "name": "Could've Been",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/aeff56609a59fdadabbe3991dba6f6ff733e7802",
    "artist_name": "Tiffany",
    "image_url": "https://i.scdn.co/image/260734d74f686bb8b9159a6720de6ff501a0c4e5",
    "artist_id": "4C3uGP8vRDzxrhJxZiOjTe",
    "year": 1988
  },
  {
    "song_id": "5KXKhCfPNS4342ly8tTf6w",
    "name": "Shattered Dreams - 2008 Digital Remaster",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/78622f7a745f0d995d2f3965bead2daf2760d3a2",
    "artist_name": "Johnny Hates Jazz",
    "image_url": "https://i.scdn.co/image/6257a5f6596b7587f68afa64a5dece0fed12c6c5",
    "artist_id": "6zpPKMhpOoG646kJgZ7RKf",
    "year": 1988
  },
  {
    "song_id": "0WBHEpJjcLd0tkuoClGboV",
    "name": "Perfect World - 2006 Digital Remaster",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/ce3cfd12d94bcaa9a651c977890c1ce244c2b1db",
    "artist_name": "Huey Lewis & The News",
    "image_url": "https://i.scdn.co/image/57f7e47cbf6c3da3e2e451221bbe4784df39d7b0",
    "artist_id": "7A9yZMTrFZcgEWAX2kBfK6",
    "year": 1988
  },
  {
    "song_id": "15Hgsooff9mLjxUhg2uXZz",
    "name": "The Valley Road",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/087bd8177e50eb382107c42ae54d52b117949a26",
    "artist_name": "Bruce Hornsby and the Range",
    "image_url": "https://i.scdn.co/image/c7cb02491c9cecbb8368b9c8624e7cff79c0dbfb",
    "artist_id": "7CwJ4VEWs7HV7MWQt9cgyk",
    "year": 1988
  },
  {
    "song_id": "2nzPzkGP9QSZ1lqvXTfyzT",
    "name": "Monkey - Remastered",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/ae2a1a9e911d394cf7848237a479cd5001a2c631",
    "artist_name": "George Michael",
    "image_url": "https://i.scdn.co/image/b0918e54e212d316e08952621867fbaa296ca2b3",
    "artist_id": "19ra5tSw0tWufvUp8GotLo",
    "year": 1988
  },
  {
    "song_id": "1sEGwuvScFU2uNzlI7Aepy",
    "name": "Kissing a Fool - Remastered",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/21a43cf6965686500484bb655c6eee4e5f69b191",
    "artist_name": "George Michael",
    "image_url": "https://i.scdn.co/image/b0918e54e212d316e08952621867fbaa296ca2b3",
    "artist_id": "19ra5tSw0tWufvUp8GotLo",
    "year": 1988
  },
  {
    "song_id": "3A0wRQczzSNVtZfK8IBOMt",
    "name": "Out Of The Blue",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/a188b96ac878fc6fc08e204a8a8f7c459a4b0787",
    "artist_name": "Debbie Gibson",
    "image_url": "https://i.scdn.co/image/ca322260c8bb7bc2c3dfe327f1fc252271d454a5",
    "artist_id": "18jZvCsW1PJ4FDQ5gEXuKp",
    "year": 1988
  },
  {
    "song_id": "3ApyzBQoR5c6vSyMlUwrwI",
    "name": "Pink Cadillac",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/80dbd4946f70c914662efc1129fb85612806ec8e",
    "artist_name": "Natalie Cole",
    "image_url": "https://i.scdn.co/image/71d7998d3ef7e96a05fd54ea2375be4441178b3b",
    "artist_id": "5tTsrGPwQRWUsHR2Xf7Ke9",
    "year": 1988
  },
  {
    "song_id": "6JfuiqeGiP8CM7WsKp9EZ4",
    "name": "Wait",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/c2ea137b470d5a4a8a71f8aedaa50a778f83ad6c",
    "artist_name": "White Lion",
    "image_url": "https://i.scdn.co/image/b66a1309f57ed469c9ca70e7e869fa1b9c8a6882",
    "artist_id": "3nLYJvqbEzs5kg2TlprxIG",
    "year": 1988
  },
  {
    "song_id": "1UfEtRArkQFUUY6gtR69sO",
    "name": "What Have I Done To Deserve This? (With Dusty Springfield) - 2001 Remastered Version",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/8d1299a6be6837a417047c3ca9f9dd892e5a48ca",
    "artist_name": "Pet Shop Boys",
    "image_url": "https://i.scdn.co/image/91f5755f979ef2dbc04d08ec2b5108a00d3e122c",
    "artist_id": "2ycnb8Er79LoH2AsR5ldjh",
    "year": 1988
  },
  {
    "song_id": "4wU74pLflJoS9UpWKUiFRN",
    "name": "Everything Your Heart Desires - Remastered 2003",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/93abd637160554adcdd3f6fd6f3badafd4cdb0e3",
    "artist_name": "Daryl Hall & John Oates",
    "image_url": "https://i.scdn.co/image/82216e16eff844df7916f5024dd7041403b6c82a",
    "artist_id": "77tT1kLj6mCWtFNqiOmP9H",
    "year": 1988
  },
  {
    "song_id": "5Pm6zNr7i5606OzSc3vs4v",
    "name": "Don't You Know What The Night Can Do?",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/32a9c22d9b70375738c6c3c6b1c01765d8041002",
    "artist_name": "Steve Winwood",
    "image_url": "https://i.scdn.co/image/e21c62616c210b162e1e363613e885e4cf9ea3b4",
    "artist_id": "5gxynDEKwNDgxGJmJjZyte",
    "year": 1988
  },
  {
    "song_id": "2UkbUBGDyf2CMXNuWi7CvQ",
    "name": "The Locomotion - Live in New York",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/0dcf1590027d81d7402a131072c60a7bf0cc3551",
    "artist_name": "Kylie Minogue",
    "image_url": "https://i.scdn.co/image/0aae00861112654b670f66c3ef03fa16dcaed71d",
    "artist_id": "4RVnAU35WRWra6OZ3CbbMA",
    "year": 1988
  },
  {
    "song_id": "7ETvGW4DrxnocSxcrJOL90",
    "name": "Girlfriend - Radio Edit",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/df1d687e1478ec2d4a3d4e60bea137ebefecb063",
    "artist_name": "Pebbles",
    "image_url": "https://i.scdn.co/image/57a2750f2626e31ddc797429c9b792a7064f771f",
    "artist_id": "702AvACMk5mieXIYlC2Gxx",
    "year": 1988
  },
  {
    "song_id": "22FshJbV8R7yYuPwYyZwM6",
    "name": "We'll Be Together",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/d063b2fbf1402a7f51a8e97b5263a81431341896",
    "artist_name": "Sting",
    "image_url": "https://i.scdn.co/image/92ccc381e02fbe3387d072b0a3830341c554794f",
    "artist_id": "0Ty63ceoRnnJKVEYP0VQpk",
    "year": 1988
  },
  {
    "song_id": "2iCTC5llCMML8tQq2jvk4a",
    "name": "Can't Stay Away from You",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/b2194fc7c7e17f87b51cc71ccb022c474306606c",
    "artist_name": "Gloria Estefan And Miami Sound Machine",
    "image_url": "https://i.scdn.co/image/26b654ecbe60b28397e6d8b5d829cebeeeb5590a",
    "artist_id": "2Gop8SbSVt5SaJzuaRFa7D",
    "year": 1988
  },
  {
    "song_id": "6bN9fDpAkPA7qkAH6LmVlW",
    "name": "Prove Your Love",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/9e4ba243f36581a5f195feda980ce87040d7a986",
    "artist_name": "Taylor Dayne",
    "image_url": "https://i.scdn.co/image/55f065a07bca72bb125f5296d4a3ffff38c3c825",
    "artist_id": "32lVGr0fSRGT6okLKHiP68",
    "year": 1988
  },
  {
    "song_id": "6dN8EgnEE9Nbr79NYrijkQ",
    "name": "Anything For You",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/14409ac49c834346ec92349b7fb58a06a2520190",
    "artist_name": "Gloria Estefan And Miami Sound Machine",
    "image_url": "https://i.scdn.co/image/c0971f0a54d1b3d45f5b40a604faaa05b571f743",
    "artist_id": "2Gop8SbSVt5SaJzuaRFa7D",
    "year": 1988
  },
  {
    "song_id": "5a32EAeh9xKF6KK5osk1jt",
    "name": "Rocket 2 U",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/a32dbb3f04411c5fda274681d561db736c36351b",
    "artist_name": "The Jets",
    "image_url": "https://i.scdn.co/image/1df6692be0aec0747bd23dd1b4de98b08eed944f",
    "artist_id": "3AHq6rutf72JF0ul8GB6G2",
    "year": 1988
  },
  {
    "song_id": "5vxfS9zboIlkCU3tHdGvB1",
    "name": "Don't Be Cruel",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/5baced7f4a119171a1b1ae2f3aeb0ef9681be006",
    "artist_name": "Cheap Trick",
    "image_url": "https://i.scdn.co/image/dc209eb2625c0c77c0baf0c0c24df02d7542c9fd",
    "artist_id": "1LB8qB5BPb3MHQrfkvifXU",
    "year": 1988
  },
  {
    "song_id": "4hiIkbV6VqAtVhaX8TijiB",
    "name": "I Don't Wanna Go On With You Like That",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/4fdc8d3cd67b59eb742c02b1191e78a3b55f94a8",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/bf3efbd0d4962d1e76b1ae3ed50330356166f70b",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1988
  },
  {
    "song_id": "7lJgjvQRJEXcdu19n3Zn5T",
    "name": "I Want To Be Your Man (Remastered Version)",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/694e22cb97b65baf60b758ededb009527f65b68a",
    "artist_name": "Roger",
    "image_url": "https://i.scdn.co/image/141bf457a0f411c982e7367184890efa03709ab9",
    "artist_id": "3GMoVpWJy4smKuxFuFTwXC",
    "year": 1988
  },
  {
    "song_id": "1z3ugFmUKoCzGsI6jdY4Ci",
    "name": "Like A Prayer",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/e3f2c751d537788f5b0c5a43b0d0b4a5aa23ae26",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1989
  },
  {
    "song_id": "5MoDQWMDMaAGDEiWsJfeCi",
    "name": "Eternal Flame",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/d378417762e46cdad2e2183e1071b300df2cc4d0",
    "artist_name": "The Bangles",
    "image_url": "https://i.scdn.co/image/d1613688a9cdce0338f7a241748c06cccd6baca8",
    "artist_id": "51l0uqRxGaczYr4271pVIC",
    "year": 1989
  },
  {
    "song_id": "4LFwNJWoj74Yd71fIr1W8x",
    "name": "Right Here Waiting",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/b068151410bb2f3b48b9163c9ff209561b56a948",
    "artist_name": "Richard Marx",
    "image_url": "https://i.scdn.co/image/e06bb99c063ccbd77a7888d9eb276dd98fe03d44",
    "artist_id": "0grdhNhiRLFBaFVyybqsj6",
    "year": 1989
  },
  {
    "song_id": "0eFvoRSTTaR2q8bSWVjwfp",
    "name": "Welcome To The Jungle",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/1ac4becbe780d6bd3c2e1d161b10746917b183b2",
    "artist_name": "Guns N' Roses",
    "image_url": "https://i.scdn.co/image/93ebbf2621c9264f2398bd6ea6f10be611196367",
    "artist_id": "3qm84nBOXUEQ2vnTfUTTFC",
    "year": 1989
  },
  {
    "song_id": "2IJzqbcbQZjcca53yGkX7t",
    "name": "Every Rose Has Its Thorn - 2003 - Remaster",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/20c6efe8f95a0a093b137627c26a3413d634a25f",
    "artist_name": "Poison",
    "image_url": "https://i.scdn.co/image/d1fec99e6c187e2f67bc28d5367d5db9664b1835",
    "artist_id": "1fBCIkoPOPCDLUxGuWNvyo",
    "year": 1989
  },
  {
    "song_id": "5Fz2wrKAjhKpWeTwvE1Ct3",
    "name": "Paradise City",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/864cf8f8a3f870ecb62ca35cbd9c5b0683853433",
    "artist_name": "Guns N' Roses",
    "image_url": "https://i.scdn.co/image/93ebbf2621c9264f2398bd6ea6f10be611196367",
    "artist_id": "3qm84nBOXUEQ2vnTfUTTFC",
    "year": 1989
  },
  {
    "song_id": "5xl5582IihbEZAnfj0xyso",
    "name": "Straight Up",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/a4f7b665eafdc670d0c00fdfba0256805945cd2b",
    "artist_name": "Paula Abdul",
    "image_url": "https://i.scdn.co/image/ccf0628d71d82b2b1e2bce83dab6044a98e632ef",
    "artist_id": "4PpmBoqphQusNFsxuVKb6j",
    "year": 1989
  },
  {
    "song_id": "2BPfKiV9U0CR1dpUgeUwuH",
    "name": "She Drives Me Crazy",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/275aa3afdf0b683ba53ef4136a63dba0b0a6c2ee",
    "artist_name": "Fine Young Cannibals",
    "image_url": "https://i.scdn.co/image/e0f282a4613b13f6a5fb4ac58fe2af1b9e5d0cd2",
    "artist_id": "20p5D2KrE8CGuOjHtxsyTp",
    "year": 1989
  },
  {
    "song_id": "64B4UbGRLtGRBtiN2m8OvF",
    "name": "Love Shack [Edit] (45 Version)",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/c7d10ea5617dc406a516bff4e9dae7398d61941d",
    "artist_name": "The B-52's",
    "image_url": "https://i.scdn.co/image/26ceba057c25dec8c20ca6e71c82960bf7902b68",
    "artist_id": "3gdbcIdNypBsYNu3iiCjtN",
    "year": 1989
  },
  {
    "song_id": "48p5E25cFPanxuwCTmTpuL",
    "name": "The Promise",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/f41fa8adfbbf0d96fa8c3f6b50bf657636a52552",
    "artist_name": "When In Rome",
    "image_url": "https://i.scdn.co/image/4a8fd0040b412cc39e5113a89c4ba4998d95acbd",
    "artist_id": "3jOO5nZ2XPmc232rNjzqJy",
    "year": 1989
  },
  {
    "song_id": "76ZnyGfjQZSM8GPfR7OqiJ",
    "name": "Good Thing",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/132926147f069322ee0c1ef1590911a708451d71",
    "artist_name": "Fine Young Cannibals",
    "image_url": "https://i.scdn.co/image/e0f282a4613b13f6a5fb4ac58fe2af1b9e5d0cd2",
    "artist_id": "20p5D2KrE8CGuOjHtxsyTp",
    "year": 1989
  },
  {
    "song_id": "2uKcU4FPX5eOjmd6RWb9OF",
    "name": "Patience",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/e60acebdbb120c1bc8d8dc02a0379e7829532c9a",
    "artist_name": "Guns N' Roses",
    "image_url": "https://i.scdn.co/image/66bc01afbadb5750fabdf01de462e89e13656c98",
    "artist_id": "3qm84nBOXUEQ2vnTfUTTFC",
    "year": 1989
  },
  {
    "song_id": "0tKjVEJlX3IZ5L1rEJc5hh",
    "name": "When I See You Smile",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/29b9970aba360377cdbc3a77d15715b40f9e003c",
    "artist_name": "Bad English",
    "image_url": "https://i.scdn.co/image/b5924bbdc8636c49cef09ff4a9514eb790cacf8d",
    "artist_id": "5fhMbh4PVSLSODF2fhWwqt",
    "year": 1989
  },
  {
    "song_id": "6ioBgySxoeQKALvAeLEmId",
    "name": "Express Yourself",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/1d03109355005557bba78fa888170596ac093687",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1989
  },
  {
    "song_id": "2GVYPY8Sv7U7cJkDFu3Ft1",
    "name": "Smooth Criminal - Radio Edit",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/c89229019fda2eb5917f9199260328b3dc081c53",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1989
  },
  {
    "song_id": "0qgrrDnUUhyxpxbBznUnzg",
    "name": "18 And Life",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/4c0a55822527f8c1c87e12c9ab7c412713e7e6c7",
    "artist_name": "Skid Row",
    "image_url": "https://i.scdn.co/image/096be4f0519a15387627bb19ec12ae363743b48b",
    "artist_id": "4opTS86dN9uO313J9CE8xg",
    "year": 1989
  },
  {
    "song_id": "1nZ2TI2hNPGDYyesyaaASn",
    "name": "Love In An Elevator",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/db0abe4de55761b17066c4384d30876f371ba138",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/3208f60d9b33a29d4ffa63618b3771a018015f85",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1989
  },
  {
    "song_id": "75nyQgntsm6IzWkb4G52bx",
    "name": "Two Hearts",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/65d6df7d827be1dc9a28130454c5108e84457c77",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/0589331408d694c0f2da59a5854ef3d027e3dd4a",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1989
  },
  {
    "song_id": "4RbgFlvnCoO8JmzBCxCTwu",
    "name": "I'll Be There For You",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/6064bb2c8af5630f903d929a8ce3fe76b4d56a97",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/efff863dfbfe40cc97f25bbb51e837c36fe4510e",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1989
  },
  {
    "song_id": "5VpJrXw5GRP0tSFXAzrRM5",
    "name": "Look Away - Remastered Single/",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/68cef4d25481fde8693a0c99aca1026ad084bb11",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/13e99c93468f6e338ac1f018d97503896c01989c",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1989
  },
  {
    "song_id": "4NMtuxWfOPR6yNjAX0AWrR",
    "name": "Keep On Movin' - feat. Caron Wheeler",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/6d3cae27f47be1fb585c3ae3d9b27fe78f975c23",
    "artist_name": "Soul II Soul",
    "image_url": "https://i.scdn.co/image/abc770023af2f820f77fe62aa86819faac919b87",
    "artist_id": "2sIx6SmAMw9IBySG3Uj0jf",
    "year": 1989
  },
  {
    "song_id": "6jrp8qBMJO6vhAeYVAsdk9",
    "name": "Cold Hearted",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/d55eed767b3e2f3f1d01230780b99f7234408707",
    "artist_name": "Paula Abdul",
    "image_url": "https://i.scdn.co/image/ccf0628d71d82b2b1e2bce83dab6044a98e632ef",
    "artist_id": "4PpmBoqphQusNFsxuVKb6j",
    "year": 1989
  },
  {
    "song_id": "1qIKynV6YQZgocodkPdCy7",
    "name": "Listen To Your Heart",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/6f0f6481588e81964f653c761a6c8b672fe2d161",
    "artist_name": "Roxette",
    "image_url": "https://i.scdn.co/image/c91440acddff1cdb29d8532670e578442297393d",
    "artist_id": "2SHhfs4BiDxGQ3oxqf0UHY",
    "year": 1989
  },
  {
    "song_id": "1iQpStZpzBb5TOuTDKJKdj",
    "name": "Poison",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/f283e4ec13e5fe7e5de35d22ae25f4822d715e2f",
    "artist_name": "Alice Cooper",
    "image_url": "https://i.scdn.co/image/5d0d0d9d4812b97216e543ae72b4ee6db9c36f28",
    "artist_id": "3EhbVgyfGd7HkpsagwL9GS",
    "year": 1989
  },
  {
    "song_id": "6amZm52SfS7d6oCEu9NDMl",
    "name": "So Alive",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/45e7554754fac85724a0255fc91646c9a559e025",
    "artist_name": "Love And Rockets",
    "image_url": "https://i.scdn.co/image/1b0001f83d295c45d929bf5ca6358d97a2e787eb",
    "artist_id": "09mvgMBvJkxarNIDGdwPWg",
    "year": 1989
  },
  {
    "song_id": "7BLKBIoijWCkDyOATEr5vW",
    "name": "Cherish",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/90b9b20525d79dafbd13230b7a1b2aff347a3ee0",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1989
  },
  {
    "song_id": "1QKyTgl3CT6iqBAmAKham7",
    "name": "Miss You Like Crazy",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/5e03c1b02ac7e7e5ba21197c77e752c5379fbebf",
    "artist_name": "Natalie Cole",
    "image_url": "https://i.scdn.co/image/71d7998d3ef7e96a05fd54ea2375be4441178b3b",
    "artist_id": "5tTsrGPwQRWUsHR2Xf7Ke9",
    "year": 1989
  },
  {
    "song_id": "6hHUiDe461VUoTHnsplRYs",
    "name": "What I Am",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/109f3cb188ff4ce7d61a01197cf488376bd10ab2",
    "artist_name": "Edie Brickell & New Bohemians",
    "image_url": "https://i.scdn.co/image/bdc2ae5229a252a5f423d99172651522f797e4a4",
    "artist_id": "4awU3JRQXIYoxabdwO92AU",
    "year": 1989
  },
  {
    "song_id": "1qT8Evm2p2jfVIq52G3wVs",
    "name": "My Prerogative",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/acd62c6adada266f9bc97553882d8b73c76946cc",
    "artist_name": "Bobby Brown",
    "image_url": "https://i.scdn.co/image/7cf753b474a3c942aa8b481d4f42bc46a82915ce",
    "artist_id": "62sPt3fswraiEPnKQpAbdE",
    "year": 1989
  },
  {
    "song_id": "0nDbYgVGoyv9seCYH0DUyo",
    "name": "When The Children Cry",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/5d19a5c4f40fec11364cb7ee34cdcd6b04630caa",
    "artist_name": "White Lion",
    "image_url": "https://i.scdn.co/image/b2fc376eed2187ed0b7f1b799cc52de41cfc16b0",
    "artist_id": "3nLYJvqbEzs5kg2TlprxIG",
    "year": 1989
  },
  {
    "song_id": "4r5VaK6H1bq0cTGPMTN97Z",
    "name": "Miss You Much",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/efa18172c47f7dbff56cc2a14b3127483a0cb5c7",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/1e663cb95fcf0004fca211b845c8f23294d9e415",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1989
  },
  {
    "song_id": "6mY8sdPCxlMTLaViH2rVo4",
    "name": "If You Don't Know Me By Now",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/fcfa42c5905c937646392fc32db464fa6bf56535",
    "artist_name": "Simply Red",
    "image_url": "https://i.scdn.co/image/fa129b292ece7328d4c91fa7a75a11d4ecd17b83",
    "artist_id": "1fa0cOhromAZdq2xRA4vv8",
    "year": 1989
  },
  {
    "song_id": "1cy5US2dffz6QGBAIzOGkq",
    "name": "I'll Be Loving You (Forever)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/b2ec18bfccd520e5374b9e3e153ffca0705b7e5d",
    "artist_name": "New Kids On The Block",
    "image_url": "https://i.scdn.co/image/e6cdd1b3aa6971b2718d964299e9f9b8d4447e59",
    "artist_id": "55qiaow2sDYtjqu1mwRua6",
    "year": 1989
  },
  {
    "song_id": "4DbUk1qwcz9KKcUY4t8f8u",
    "name": "Batdance",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/f9a0119aa743587a1fd87a9cd422c227ff57608e",
    "artist_name": "Prince",
    "image_url": "https://i.scdn.co/image/2dfc1c678c04d4c48d0de5ec233c33ba64ec4392",
    "artist_id": "5a2EaR3hamoenG9rDuVn8j",
    "year": 1989
  },
  {
    "song_id": "3gDtrR0W8CmkyS6mweesSS",
    "name": "Angel Eyes",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/b45606db1c0f5519a112e6a1bce0ad127db2e1f1",
    "artist_name": "The Jeff Healey Band",
    "image_url": "https://i.scdn.co/image/995ec298977271d759e8383ed5db35abc11b053f",
    "artist_id": "3d2hJTVTwo08F9b0ZFQukJ",
    "year": 1989
  },
  {
    "song_id": "1d4sJjJMzg4U3UmdPAHooT",
    "name": "Roni",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/f4f1ab0b00ee791918f690797b0817875a1d40a8",
    "artist_name": "Bobby Brown",
    "image_url": "https://i.scdn.co/image/0d013c5053902f89c17dc0d63628ea6e48153de7",
    "artist_id": "62sPt3fswraiEPnKQpAbdE",
    "year": 1989
  },
  {
    "song_id": "5J6dMtMyhaNCBojLxpdbzb",
    "name": "Waiting for a Star to Fall",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/b86e702c85cde1cff28241721249e0e24ed8ae29",
    "artist_name": "Boy Meets Girl",
    "image_url": "https://i.scdn.co/image/9c9d9ec1c6bb4c48d53dc85eda0c5a40c09f6c0d",
    "artist_id": "02Xpf8IlAGeelWAQZP33kQ",
    "year": 1989
  },
  {
    "song_id": "62oGUBJQGPa3emMGMejBhm",
    "name": "How Can I Fall?",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/31ddacab8d6927340ee0e7d2146a56f94efb5441",
    "artist_name": "Breathe",
    "image_url": "https://i.scdn.co/image/302ea33717250c2604d55222ed0bdf425feb3554",
    "artist_id": "1MNeXu0284O7mQWgF1YRc0",
    "year": 1989
  },
  {
    "song_id": "6htVxmiiuZdYvbnO9R0iQR",
    "name": "Born To Be My Baby",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/c8dc4006b2a174b1f0a2c7e13dfbef1ad094ffbf",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/efff863dfbfe40cc97f25bbb51e837c36fe4510e",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1989
  },
  {
    "song_id": "0RAn8LOs5QVMLy2xH30bUj",
    "name": "Second Chance",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/54d53fa8af04f85046a5a3744ef057a0bad8f827",
    "artist_name": "38 Special",
    "image_url": "https://i.scdn.co/image/c394a7ec348e101f26ca469a39a2fa22bbbc8672",
    "artist_id": "3zXw2Eh96iTT51pytzHdZi",
    "year": 1989
  },
  {
    "song_id": "0jsfrgsAk65LbxfrrSPnm5",
    "name": "In Your Room",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/4e6ccdd732f18794d22505d50807a590536cd693",
    "artist_name": "The Bangles",
    "image_url": "https://i.scdn.co/image/d1613688a9cdce0338f7a241748c06cccd6baca8",
    "artist_id": "51l0uqRxGaczYr4271pVIC",
    "year": 1989
  },
  {
    "song_id": "3RJof5CojqlbgZ5adHw50O",
    "name": "When I'm With You",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/5fa2651454a0987a0a0ce6499dddcf024b3e058d",
    "artist_name": "Sheriff",
    "image_url": "https://i.scdn.co/image/bc48839faa0deaca68cba00822800725b4fa750c",
    "artist_id": "5jllns7VtBfhF3AyjvZP8d",
    "year": 1989
  },
  {
    "song_id": "50vs7ckrWFbamxtbDc9c6v",
    "name": "You Got It (The Right Stuff)",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/7b19a427f00e70c63956763003d31bf86ca8bb87",
    "artist_name": "New Kids On The Block",
    "image_url": "https://i.scdn.co/image/4c5f6934676937b3417cf1277548657fbfc17d9d",
    "artist_id": "55qiaow2sDYtjqu1mwRua6",
    "year": 1989
  },
  {
    "song_id": "0kzSooCGpuphLwn8iTuO9h",
    "name": "Baby, I Love Your Way/Freebird",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/fd65aec9bd8404f09d9c809ac9e8560a3c578b4c",
    "artist_name": "Will To Power",
    "image_url": "https://i.scdn.co/image/4bd7e9db4220c6583beb879ec4fea03ef4bd2864",
    "artist_id": "63I04Kj1gXC1LLSVcrOzLN",
    "year": 1989
  },
  {
    "song_id": "40RrsR16maAWMtBX6iav7d",
    "name": "She Wants to Dance with Me",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/4971338791e640049b8ca0f24677651fabc8cca2",
    "artist_name": "Rick Astley",
    "image_url": "https://i.scdn.co/image/b7f0e46f7b5bf4d83fb483c2e7606b89cd407bf1",
    "artist_id": "0gxyHStUsqpMadRV0Di1Qt",
    "year": 1989
  },
  {
    "song_id": "1P1QNBZVSPXXJok2vPVZof",
    "name": "Lay Your Hands On Me",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/0cf5a597d430d5ce24ecc7f7b2b3a6d56ad6b62f",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/efff863dfbfe40cc97f25bbb51e837c36fe4510e",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1989
  },
  {
    "song_id": "0lmS0Wofcv7B7uFYssSKta",
    "name": "Forever Your Girl",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/d969af273a55560872a4c18c4211d218a2482bbe",
    "artist_name": "Paula Abdul",
    "image_url": "https://i.scdn.co/image/ccf0628d71d82b2b1e2bce83dab6044a98e632ef",
    "artist_id": "4PpmBoqphQusNFsxuVKb6j",
    "year": 1989
  },
  {
    "song_id": "3MrWnFHZd9uZiVXwoZOxqd",
    "name": "Walk On Water",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/4d8c700b161ccc6d7e2a70e6545b1e079c196b53",
    "artist_name": "Eddie Money",
    "image_url": "https://i.scdn.co/image/2845ecb9bdf80dd89dd46bf137aa0eb02812dfb6",
    "artist_id": "4Tw2N3wdvJPGEU7JqMxFfE",
    "year": 1989
  },
  {
    "song_id": "2yMZVQlOz0z8J27hxQIfN1",
    "name": "My Heart Can't Tell Me No (Remastered Album Version)",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/83821bc74e7467a2b4b20f5d38eb46f13c576f99",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/79fa6c9588f7bec80db5db5d06bae424a118e57b",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1989
  },
  {
    "song_id": "1VVzF4TT31vzjEtXP8oa7W",
    "name": "Sowing The Seeds Of Love - 7\" Version",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/b0ab1a558d7e428a8a88131ab7755911fc3e47d8",
    "artist_name": "Tears For Fears",
    "image_url": "https://i.scdn.co/image/752a08f143d7b1838c99f67d5c771cd8e13d7533",
    "artist_id": "4bthk9UfsYUYdcFyqxmSUU",
    "year": 1989
  },
  {
    "song_id": "1TtJkmAW9Rc55iVOPgZ33z",
    "name": "The Way You Love Me",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/c88d826c6ad3ceeff99dd85e5dfa38da56749b34",
    "artist_name": "Karyn White",
    "image_url": "https://i.scdn.co/image/d731ca1fb8c11e2a1d152856e0cfd9401708aba0",
    "artist_id": "5lJBrQQ88JjskJmJeVKX4F",
    "year": 1989
  },
  {
    "song_id": "1jeZTGhJ3C0txPw188XCQ8",
    "name": "Love Song",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/c64d94d0dbee2542c88f834d012a469b0db8ba16",
    "artist_name": "The Cure",
    "image_url": "https://i.scdn.co/image/8af2c73113cf95c72286abbc205b1d20d5ba5a84",
    "artist_id": "7bu3H8JO7d0UbMoVzbo70s",
    "year": 1989
  },
  {
    "song_id": "6CCYX0JVy5qYqUvWpCvHXh",
    "name": "Cover Girl",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/8e2b0c3dc3ac379d9d4132a2ef9718dc3ed7b362",
    "artist_name": "New Kids On The Block",
    "image_url": "https://i.scdn.co/image/e6cdd1b3aa6971b2718d964299e9f9b8d4447e59",
    "artist_id": "55qiaow2sDYtjqu1mwRua6",
    "year": 1989
  },
  {
    "song_id": "6reHABy1sGPaQ1JkfIwvQY",
    "name": "Stand",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/9e4dc448b90d98b8a20671c13f625ad749e12711",
    "artist_name": "R.E.M.",
    "image_url": "https://i.scdn.co/image/0df06d2f6add5874ece4857217e376051e7bda1e",
    "artist_id": "4KWTAlx2RvbpseOGMEmROg",
    "year": 1989
  },
  {
    "song_id": "0l7wDoxzSKOzimi6tTWey7",
    "name": "Dreamin'",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/e03e9242c0141bb8f3cf7f3f0679bb1e7cb02b4d",
    "artist_name": "Vanessa Williams",
    "image_url": "https://i.scdn.co/image/514f606f96965acd83c4850b24f3e9b00aee6638",
    "artist_id": "75L9s8KVrhCNtBUkZFnDFW",
    "year": 1989
  },
  {
    "song_id": "0kvbYSkCbu4NaukfKTsSh8",
    "name": "If I Could Turn Back Time",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/a23ae44e7bdc709ec5d8d8951cfe71571e0b0551",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/f3510cce8da3ed7873e759fb0d3021d35d9a88b4",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1989
  },
  {
    "song_id": "2TOh6x5pOP3a3hzCmgHx7n",
    "name": "Armageddon It - Live",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/6a7b2eb4dc51674de7ff9b2c72c4c945bb90a2b2",
    "artist_name": "Def Leppard",
    "image_url": "https://i.scdn.co/image/cf8be16805a78c4d7cf8b3f71ea7bdef4a4b3d31",
    "artist_id": "6H1RjVyNruCmrBEWRbD0VZ",
    "year": 1989
  },
  {
    "song_id": "5Ufxoce7evv4Z9LofZUKpx",
    "name": "On Our Own",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/80c321eed925c13250b0a5caceef45f5f2dcdc32",
    "artist_name": "Bobby Brown",
    "image_url": "https://i.scdn.co/image/20da1763386f27ce1efcf9731abaa49f89f9362f",
    "artist_id": "62sPt3fswraiEPnKQpAbdE",
    "year": 1989
  },
  {
    "song_id": "2zFqQ0xLiMHOVUuV5zw9Gq",
    "name": "It's No Crime",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/1529f1ca7a8b2d70b27e574d8019b17b948b326b",
    "artist_name": "Babyface",
    "image_url": "https://i.scdn.co/image/6722d32552fd8afd3f0a45ade65708b56133e174",
    "artist_id": "3aVoqlJOYx31lH1gibGDt3",
    "year": 1989
  },
  {
    "song_id": "3NWcFiL6oCEagrFiTdDr1m",
    "name": "The End Of The Innocence",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/85879f8b871ec859470c91371847aa9a38cdb2f9",
    "artist_name": "Don Henley",
    "image_url": "https://i.scdn.co/image/0a2f1b561c8d1f6e52fcc6c8caebc199eab64da6",
    "artist_id": "5dbuFbrHa1SJlQhQX9OUJ2",
    "year": 1989
  },
  {
    "song_id": "6OmknRIYvZmABONKgva2GK",
    "name": "Every Little Step",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/7e544bdd9fecc9b8f95fde515084930bf0d7bb82",
    "artist_name": "Bobby Brown",
    "image_url": "https://i.scdn.co/image/20da1763386f27ce1efcf9731abaa49f89f9362f",
    "artist_id": "62sPt3fswraiEPnKQpAbdE",
    "year": 1989
  },
  {
    "song_id": "42J9ponGUZ7A9QlZQT6csY",
    "name": "Heaven",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/cc1a6915a335a2d61a6d07831fdcce6c2c77e714",
    "artist_name": "Warrant",
    "image_url": "https://i.scdn.co/image/b66a1309f57ed469c9ca70e7e869fa1b9c8a6882",
    "artist_id": "7HLvzuM9p11k9lUQfSM4Rq",
    "year": 1989
  },
  {
    "song_id": "2yyQLWgpUZEFisUkKzHsdT",
    "name": "Real Love",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/57369a7cd348ed80df8107f4ce78d4529bc35fc3",
    "artist_name": "Jody Watley",
    "image_url": "https://i.scdn.co/image/5fa631c49b10eba706352346a98147251c680303",
    "artist_id": "71aKjsWKYqASAffyIQaocZ",
    "year": 1989
  },
  {
    "song_id": "4V87hYB94wu8DFtd901riK",
    "name": "Satisfied",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/5afde150f22fb972353e4068027debb86f619fb8",
    "artist_name": "Richard Marx",
    "image_url": "https://i.scdn.co/image/e06bb99c063ccbd77a7888d9eb276dd98fe03d44",
    "artist_id": "0grdhNhiRLFBaFVyybqsj6",
    "year": 1989
  },
  {
    "song_id": "0Mqlf5OTGoeUgoeqL1Mzew",
    "name": "Surrender To Me - 2000 Digital Remaster",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/15d50b114d9987beb1631fc3d479e81017a1aa20",
    "artist_name": "Heart",
    "image_url": "https://i.scdn.co/image/0dcae5748b949c30f563af2ae56a580f1ddcfbe1",
    "artist_id": "34jw2BbxjoYalTp8cJFCPv",
    "year": 1989
  },
  {
    "song_id": "6e3O5XkMEhMAatdKnOlZPh",
    "name": "Rock On - Ver. 09",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/79ea19f09b3511d924bf1f08525d944704fa4912",
    "artist_name": "Michael Damian",
    "image_url": "https://i.scdn.co/image/14a47c722f9f5420cff47990b842b18ad4981e3f",
    "artist_id": "1Wb1iP9BzMJyjm5nXtBUFP",
    "year": 1989
  },
  {
    "song_id": "5xskaOZUPKUCk8Vmc6yTWN",
    "name": "Once Bitten, Twice Shy",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/574d99b72dd3d6af7ebd19efedfeac9661032221",
    "artist_name": "Great White",
    "image_url": "https://i.scdn.co/image/852459bbbbd290298dfb7c8cc913d8f91f82eb2f",
    "artist_id": "6ZPbX2Lxd40e2UVkXpyxvI",
    "year": 1989
  },
  {
    "song_id": "4aYOQDyN2wTOlt2mRQ6zef",
    "name": "Secret Rendezvous",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/4820f9a7ab181b8d5745e41ad6684d83c443c1ca",
    "artist_name": "Karyn White",
    "image_url": "https://i.scdn.co/image/d731ca1fb8c11e2a1d152856e0cfd9401708aba0",
    "artist_id": "5lJBrQQ88JjskJmJeVKX4F",
    "year": 1989
  },
  {
    "song_id": "1i1Tn4xFTkUU0XWoV2lajN",
    "name": "Rock Wit'cha",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/7f5460235d951e001d809b29a6933fead2f15b8d",
    "artist_name": "Bobby Brown",
    "image_url": "https://i.scdn.co/image/0d013c5053902f89c17dc0d63628ea6e48153de7",
    "artist_id": "62sPt3fswraiEPnKQpAbdE",
    "year": 1989
  },
  {
    "song_id": "5fLNSn0KurYAS8O1gyiSkL",
    "name": "Hangin' Tough",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/c8bef85a20c1017aeced247d47f72bfd7d435c67",
    "artist_name": "New Kids On The Block",
    "image_url": "https://i.scdn.co/image/4c5f6934676937b3417cf1277548657fbfc17d9d",
    "artist_id": "55qiaow2sDYtjqu1mwRua6",
    "year": 1989
  },
  {
    "song_id": "5nJXgY6kDVt7PGt1SmjLVi",
    "name": "What You Don't Know - Radio Mix",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/a6f26ec80bf27cf304064220482a7b26196915ac",
    "artist_name": "Exposé",
    "image_url": "https://i.scdn.co/image/8d9d2d252b25ae7bbd4214b42ef511cf32fddbe8",
    "artist_id": "5nPz5R1FDPTMI3Dw4JnTGb",
    "year": 1989
  },
  {
    "song_id": "6kaH3id4glNuxGXP0iQQdR",
    "name": "All This Time",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/ad154d367ccfc9ddac87f4e2e2bbdecf3a901146",
    "artist_name": "Tiffany",
    "image_url": "https://i.scdn.co/image/6fe776b7c87e28d65323fa829d1e3c247dbb37db",
    "artist_id": "4C3uGP8vRDzxrhJxZiOjTe",
    "year": 1989
  },
  {
    "song_id": "2rwzSujXzpdNH9Nq9cFSg8",
    "name": "After All (Love Theme From Chances Are)",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/1b74a01984302ca60f37a9caec8c8ac260f894fd",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/f3510cce8da3ed7873e759fb0d3021d35d9a88b4",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1989
  },
  {
    "song_id": "1I0HPyA358wezY1aCpuLBS",
    "name": "Soldier Of Love - 12\" Version",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/de29d02044dc8c45ce3706ecd1dc8061152344a4",
    "artist_name": "Donny Osmond",
    "image_url": "https://i.scdn.co/image/68d32ac0f8802929aaee47fb9c2a5b9790b742b4",
    "artist_id": "5ZEAzHE2TzAwUcOj6jMIgf",
    "year": 1989
  },
  {
    "song_id": "544nMYm62rC7moa26JrZI4",
    "name": "Close My Eyes Forever",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/c3f73ee8fcfb84ee2275f6dac1ebe599b13545c3",
    "artist_name": "Lita Ford",
    "image_url": "https://i.scdn.co/image/b66a1309f57ed469c9ca70e7e869fa1b9c8a6882",
    "artist_id": "77tBvvyd6SD4Y9Um1xcbxP",
    "year": 1989
  },
  {
    "song_id": "5NFbzd8K2VHwrydjKVetIv",
    "name": "Don't Wanna Lose You",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/2a14574a976c83c3888bc5cea9fe38da9bde5148",
    "artist_name": "Gloria Estefan",
    "image_url": "https://i.scdn.co/image/9054db910d181beacef347f0cacae7030343977a",
    "artist_id": "5IFCkqu9J6xdWeYMk5I889",
    "year": 1989
  },
  {
    "song_id": "4N4iWVwNNXIH0qT5KUCIsp",
    "name": "The Look",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/d728d3fb95f264106731c8503b3b610a4c2f60cb",
    "artist_name": "Roxette",
    "image_url": "https://i.scdn.co/image/a767d4820859faa5a8e199499516dd03c34829b5",
    "artist_id": "2SHhfs4BiDxGQ3oxqf0UHY",
    "year": 1989
  },
  {
    "song_id": "4XlMPyTNmxbVfFfOd85SKz",
    "name": "I Don't Want Your Love",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/d377db38d0874eb2f1ef055fd6f177046673e731",
    "artist_name": "Duran Duran",
    "image_url": "https://i.scdn.co/image/cd57b046fc35be033730f9d55d9bc746e1b43b67",
    "artist_id": "0lZoBs4Pzo7R89JM9lxwoT",
    "year": 1989
  },
  {
    "song_id": "0tGALvAFKFqbqG4AoM33pS",
    "name": "Shower Me With Your Love",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/8dec040ec33aa7737244eace3c89e5248f7eaf07",
    "artist_name": "Surface",
    "image_url": "https://i.scdn.co/image/a4b7e08a1354c47a94951c9adea03e5597d76727",
    "artist_id": "7dXeJ8kAqTqtvNWQNV3sdU",
    "year": 1989
  },
  {
    "song_id": "2MLtib2ulZ6eeu4zTToYr7",
    "name": "I Wanna Have Some Fun",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/9972c4049294e378da216c20261605ec615b87dd",
    "artist_name": "Samantha Fox",
    "image_url": "https://i.scdn.co/image/96181d8039d2c56f2d531aa0add0e71fe6bf345e",
    "artist_id": "0ym94xKp2PIOJtTZKpxbAa",
    "year": 1989
  },
  {
    "song_id": "1B75hgRqe7A4fwee3g3Wmu",
    "name": "U Can't Touch This",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/39ee9dfdd69ec5f121cf89e8cfefc1fedbe072e6",
    "artist_name": "MC Hammer",
    "image_url": "https://i.scdn.co/image/71f4f43b387b5c017f106d31209207a62ce12241",
    "artist_id": "2rblp9fJo16ZPTcKDtlmKW",
    "year": 1990
  },
  {
    "song_id": "3wNXqTMimmuTEj1iEPftC7",
    "name": "Another Day In Paradise",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/e3f1a5da25b2674b18c0c4fc236232d7fef5d208",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/ce8e3737e3b1db926d2f45e92ee75051b5ef60aa",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1990
  },
  {
    "song_id": "3XVozq1aeqsJwpXrEZrDJ9",
    "name": "Ice Ice Baby",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/8db0a1200e7d66681e6085f792d18a94af77d155",
    "artist_name": "Vanilla Ice",
    "image_url": "https://i.scdn.co/image/6eec6073edac02a3aade31426b1aa91642b08e28",
    "artist_id": "7GXXMm3DB1VswVcuGyInUd",
    "year": 1990
  },
  {
    "song_id": "38bDGWuyYdSdNfrFfbCiVS",
    "name": "We Didn't Start the Fire",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/9b80ac1ed89ea06d470af38125296a6a75c385ac",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/dc82b8076e92803f3ff7b469015f2b4540478d5c",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1990
  },
  {
    "song_id": "3yNVfRQlUPViUh8O2V9SQn",
    "name": "Just A Friend",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/ffd6ac49ff70d022b48472db4f87fe67fdbbef20",
    "artist_name": "Biz Markie",
    "image_url": "https://i.scdn.co/image/e76907e6b341da80b1facd384701ccff4d5b07e9",
    "artist_id": "22282KfMxDo2PMjnBc82I4",
    "year": 1990
  },
  {
    "song_id": "27QvYgBk0CHOVHthWnkuWt",
    "name": "Vogue",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/5dff890c52390543d456fa262ae167b79c6cd389",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1990
  },
  {
    "song_id": "4VZDv8sASBS8UruUBGTFdk",
    "name": "Hold On",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/a0f9d5bccdb02912f77704befa8313741b352d6b",
    "artist_name": "Wilson Phillips",
    "image_url": "https://i.scdn.co/image/a2ba2c32640886b04977df7de7006659bda52daf",
    "artist_id": "1yMYjh77WgOVafRkI50mim",
    "year": 1990
  },
  {
    "song_id": "5YjKdeES9QRJ8NmF4Xc8pV",
    "name": "All I Wanna Do Is Make Love To You",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/00133b13890b6fac4975e729d540c079bdd39db3",
    "artist_name": "Heart",
    "image_url": "https://i.scdn.co/image/ec4c23ac1e5d078c13c9adfe23f4b4dc3397aae4",
    "artist_id": "34jw2BbxjoYalTp8cJFCPv",
    "year": 1990
  },
  {
    "song_id": "4WxQGUmWBRNovxlF2g8Xdv",
    "name": "The Humpty Dance",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/bb87828eebc5c094ed7323e6d72ceee21365ae37",
    "artist_name": "Digital Underground",
    "image_url": "https://i.scdn.co/image/ab687441aa777530c7991ef5f16c40900898e2a7",
    "artist_id": "7jocoSCuCtpCxCI6IbP8ye",
    "year": 1990
  },
  {
    "song_id": "64B4UbGRLtGRBtiN2m8OvF",
    "name": "Love Shack [Edit] (45 Version)",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/c7d10ea5617dc406a516bff4e9dae7398d61941d",
    "artist_name": "The B-52's",
    "image_url": "https://i.scdn.co/image/26ceba057c25dec8c20ca6e71c82960bf7902b68",
    "artist_id": "3gdbcIdNypBsYNu3iiCjtN",
    "year": 1990
  },
  {
    "song_id": "6J6RWKCPN5RFKHUKEUFjxS",
    "name": "Step by Step",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/83495e0c2902ef2ee10cafd7be997492561dfe1b",
    "artist_name": "New Kids On The Block",
    "image_url": "https://i.scdn.co/image/bbee3f0174bfc1b5b5bfb1160467abda1270050b",
    "artist_id": "55qiaow2sDYtjqu1mwRua6",
    "year": 1990
  },
  {
    "song_id": "0U0mMRtYifdTaRltbemBee",
    "name": "It Must Have Been Love",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/edc2b5737a1854f78f3245345f67709eaf35e698",
    "artist_name": "Roxette",
    "image_url": "https://i.scdn.co/image/005ca8573267e40349812f34d2647bb6beed1f7d",
    "artist_id": "2SHhfs4BiDxGQ3oxqf0UHY",
    "year": 1990
  },
  {
    "song_id": "04Mh4OWSBUBn5Vpna4DXrA",
    "name": "The Power",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/089af5d49d8ae853768faad19ab5c4c8361c4136",
    "artist_name": "SNAP!",
    "image_url": "https://i.scdn.co/image/ec174b3f452dd2f00f6b90816adec41875d0cd16",
    "artist_id": "2FrKQPjJe4pVMZOgm0ESOx",
    "year": 1990
  },
  {
    "song_id": "0gLQ6jhJsyYfl7PrD1RZ7X",
    "name": "Vision of Love",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/032ce86eb62c943d2db74428553eeaab3623139a",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/3995e37a04f999d4e74fefa295fc0eecfa7154eb",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1990
  },
  {
    "song_id": "4iiIHK46Csj8W7dDEviJf5",
    "name": "The King Of Wishful Thinking",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/5ba702f41f68b6dc7eba50d3e1363571ada02f7b",
    "artist_name": "Go West",
    "image_url": "https://i.scdn.co/image/f312502594eec25ba01970050524dcc6d6b61489",
    "artist_id": "7bKupnlF7XOfR1En3K8oAL",
    "year": 1990
  },
  {
    "song_id": "680kMGV6szhTiZbHRr3vLi",
    "name": "Close To You",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/8876a5bd51a331c025e0ecb28a83e90c6b6e0015",
    "artist_name": "Maxi Priest",
    "image_url": "https://i.scdn.co/image/5c754d4de78ffd0ce6a98aca1fbc35bee669fbbc",
    "artist_id": "3aTuTR5Nf6pVW3837q2ZL7",
    "year": 1990
  },
  {
    "song_id": "7pJLv39T2zogTmKRJbx2lf",
    "name": "I Wish It Would Rain Down",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/e8fc1d9ca4d92c471c7f465e37ab816821f943fa",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/ce8e3737e3b1db926d2f45e92ee75051b5ef60aa",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1990
  },
  {
    "song_id": "7ba7mwoleFlwmOiuqw8XNf",
    "name": "Unskinny Bop - 2006 - Remaster",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/17fb9c4b88e3ce0624c9b2f03116618bda88ab86",
    "artist_name": "Poison",
    "image_url": "https://i.scdn.co/image/a2966cb4b30ee24e490ba2fb4f09e15fdf480ea6",
    "artist_id": "1fBCIkoPOPCDLUxGuWNvyo",
    "year": 1990
  },
  {
    "song_id": "4x9y3JSvsjBTbm4uEnBrdT",
    "name": "Don't Know Much [with Aaron Neville]",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/0e6bf163a45602d8144339d440f009a493d05f08",
    "artist_name": "Linda Ronstadt",
    "image_url": "https://i.scdn.co/image/8d5d0b62bc5889ce7bb6a18e6017d29c0ff641b2",
    "artist_id": "1sXbwvCQLGZnaH0Jp2HTVc",
    "year": 1990
  },
  {
    "song_id": "7z38bideBRvGAgjXe2SECm",
    "name": "Opposites Attract",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/c927db9b465984418bbd0f903d304e7fec8c3fbf",
    "artist_name": "Paula Abdul",
    "image_url": "https://i.scdn.co/image/ccf0628d71d82b2b1e2bce83dab6044a98e632ef",
    "artist_id": "4PpmBoqphQusNFsxuVKb6j",
    "year": 1990
  },
  {
    "song_id": "0jfONt56vckywtWwm8wjki",
    "name": "Roam [Edit] (45 Version)",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/4072cc7d398a427af0b90b8147984120b448937d",
    "artist_name": "The B-52's",
    "image_url": "https://i.scdn.co/image/f90e5f34e57ef323b8834217485372297479f56f",
    "artist_id": "3gdbcIdNypBsYNu3iiCjtN",
    "year": 1990
  },
  {
    "song_id": "1gmSaCrVZBjK8Na7dsThXv",
    "name": "Cradle of Love - 2001 - Remaster",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/7b4b407c0e96f56d3df9fc7de43d21f309a8a7f2",
    "artist_name": "Billy Idol",
    "image_url": "https://i.scdn.co/image/ae4358812edea60bdecbcec4d4508e7f7e2c0da6",
    "artist_id": "7lzordPuZEXxwt9aoVZYmG",
    "year": 1990
  },
  {
    "song_id": "0ygHFKdqHqikl2mOFtGeRQ",
    "name": "Free Fallin'",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/023e77797b85b43c3e11062f6e61e09c3aa5d92b",
    "artist_name": "Tom Petty And The Heartbreakers",
    "image_url": "https://i.scdn.co/image/98f6f5e34b9630e40e38dc8e8480227afb2e89d1",
    "artist_id": "4tX2TplrkIP4v05BNC903e",
    "year": 1990
  },
  {
    "song_id": "4ZpXHlV2vQVfPXUvbDSZ92",
    "name": "I Remember You",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/b57998f1b1647aaf2c7f79f0c14a5ffaf847601e",
    "artist_name": "Skid Row",
    "image_url": "https://i.scdn.co/image/096be4f0519a15387627bb19ec12ae363743b48b",
    "artist_id": "4opTS86dN9uO313J9CE8xg",
    "year": 1990
  },
  {
    "song_id": "064ZylFGeddMC08XdMoNyj",
    "name": "Nothing Compares 2 U",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/e9cf92752ba4885224131ee13dc950246d5bbd2a",
    "artist_name": "Sinead O'Connor",
    "image_url": "https://i.scdn.co/image/493fd79e6aaa6eb7c25fe7fc6cd9d012d189dded",
    "artist_id": "4sD9znwiVFx9cgRPZ42aQ1",
    "year": 1990
  },
  {
    "song_id": "4CyvKTtdLUiKAYnmMMPNIl",
    "name": "Love Will Lead You Back",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/9a97b55d7f84f04e47c2e385cdbecac2d457eab8",
    "artist_name": "Taylor Dayne",
    "image_url": "https://i.scdn.co/image/55f065a07bca72bb125f5296d4a3ffff38c3c825",
    "artist_id": "32lVGr0fSRGT6okLKHiP68",
    "year": 1990
  },
  {
    "song_id": "3U1KpX2tBUYDSfNEulybcN",
    "name": "Do You Remember",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/62ecb0db5930a9733f2ae7df8ce5bbc60978e653",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/a669e2d6aa5c1073831a0e6fe0c3f5c507a6cbc4",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1990
  },
  {
    "song_id": "6AgDFWLbbAt2migXMbrhXT",
    "name": "Ready Or Not",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/c265d429d56ef3d5bd3488018690e99df3d02d7d",
    "artist_name": "After 7",
    "image_url": "https://i.scdn.co/image/160b3a40dfc4d0fbded3d4de1cad91f4b883670e",
    "artist_id": "4UPcJIhr5K5fPsm4itqT7E",
    "year": 1990
  },
  {
    "song_id": "6BjqF9DTiAubeE34grUKVq",
    "name": "Hold On",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/2d94b16569939c7502dbc11d3269527c3ed4c4bb",
    "artist_name": "En Vogue",
    "image_url": "https://i.scdn.co/image/ea7fbbdaba1f8a5ddff981d847d79bad544e3195",
    "artist_id": "5fikk4h5qbEebqK2Fc6e48",
    "year": 1990
  },
  {
    "song_id": "5HAv1Ckfe50DUjv8ghwTrz",
    "name": "Escapade",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/045979b9a1a0df8dafea471f2f195a845f571861",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/1e663cb95fcf0004fca211b845c8f23294d9e415",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1990
  },
  {
    "song_id": "1rIy3lkFJnMsTLZpxFmYU8",
    "name": "Release Me",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/2a6514928c00531f6063382722625748042aef37",
    "artist_name": "Wilson Phillips",
    "image_url": "https://i.scdn.co/image/a2ba2c32640886b04977df7de7006659bda52daf",
    "artist_id": "1yMYjh77WgOVafRkI50mim",
    "year": 1990
  },
  {
    "song_id": "4nTYxxF8iWBq54LO3dBUie",
    "name": "Rhythm Nation",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/8ef93189fe302c8c3c879f903dd70e92d957df6e",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/1e663cb95fcf0004fca211b845c8f23294d9e415",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1990
  },
  {
    "song_id": "2WE3PjAPesu0SCV6B0jT4Y",
    "name": "Something Happened On The Way To Heaven",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/d80044ec8cb4701604f8d451fe3ed10cac4f014b",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/ce8e3737e3b1db926d2f45e92ee75051b5ef60aa",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1990
  },
  {
    "song_id": "6eozUVzr02EXMji1vuxgiP",
    "name": "Don't Wanna Fall In Love",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/8a61237cdd62ecaa114b0c76ba48e5d0645005bc",
    "artist_name": "Jane Child",
    "image_url": "https://i.scdn.co/image/f96ce2e3c06ba5ffc534dd19df4ae1530b65eeb3",
    "artist_id": "5JBif5ahOKFFVuEpHhrp8Y",
    "year": 1990
  },
  {
    "song_id": "53t0qoKIstEnyoqUFKicpH",
    "name": "Everybody Everybody - Le Freak Album Mix",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/ac5b59dd43adef532dfad48b28582e8c1cbaec62",
    "artist_name": "Black Box",
    "image_url": "https://i.scdn.co/image/254f4cf0629e80083983741e9239ad11eaa4e037",
    "artist_id": "6tsRo8ErXzpHk3tQeH6GBW",
    "year": 1990
  },
  {
    "song_id": "1a6VLZ3BplAosOWmCcFDAW",
    "name": "What It Takes",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/ab23969f42758ec9bd37d5ca7db69e6787bd9559",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/3208f60d9b33a29d4ffa63618b3771a018015f85",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1990
  },
  {
    "song_id": "0NSSqYISjnav81CEn6EgBY",
    "name": "Come Back To Me",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/ee449f39aa0a91f5636ab704754463f9e56c068f",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/1e663cb95fcf0004fca211b845c8f23294d9e415",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1990
  },
  {
    "song_id": "4cPQjnRFb2qP52XmMLzg8F",
    "name": "Janie's Got A Gun",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/92baecd18b189d132a13bd261f385ddd603376cc",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/3208f60d9b33a29d4ffa63618b3771a018015f85",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1990
  },
  {
    "song_id": "1KmGMot8eJCgEPVjTpkkVG",
    "name": "Thieves In The Temple",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/80e8c4f099d4462ce91002fcc81e61f689f880a4",
    "artist_name": "Prince",
    "image_url": "https://i.scdn.co/image/49db086c1d635a44cc1662c5ca9fe99a5ed3d1f7",
    "artist_id": "5a2EaR3hamoenG9rDuVn8j",
    "year": 1990
  },
  {
    "song_id": "5cLsk5E7Au4hkTvCQFWHMJ",
    "name": "Back To Life (However Do You Want Me) [feat. Caron Wheeler] - 2003 - Remaster",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/b0f617719a6775d5d121f4df5d41d157c4f0aeb3",
    "artist_name": "Soul II Soul",
    "image_url": "https://i.scdn.co/image/89c560e5a56ce8370f45a3a5b6693b850586854f",
    "artist_id": "2sIx6SmAMw9IBySG3Uj0jf",
    "year": 1990
  },
  {
    "song_id": "2xudmLTZqwDNiDGMuOqSRD",
    "name": "I Go to Extremes",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/058b8f3b0c71cb55a39cea7620cd12692e01672e",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/dc82b8076e92803f3ff7b469015f2b4540478d5c",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1990
  },
  {
    "song_id": "2lvk6CUSHz4j8huQ25gH54",
    "name": "Have You Seen Her",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/fda3a585926d044fe6ed8dfe2986a0b86a53eead",
    "artist_name": "MC Hammer",
    "image_url": "https://i.scdn.co/image/71f4f43b387b5c017f106d31209207a62ce12241",
    "artist_id": "2rblp9fJo16ZPTcKDtlmKW",
    "year": 1990
  },
  {
    "song_id": "6zNPPFFrxmhpTQejOn1Wm1",
    "name": "Sending All My Love",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/487f9831e2569579844ac8da4cefccf7639a6bd2",
    "artist_name": "Linear",
    "image_url": "https://i.scdn.co/image/ddd2fb92ad9a24d4e4eb2d5dad3e264832cdd1d6",
    "artist_id": "22i8GlNyvBRj7b93jmXWT7",
    "year": 1990
  },
  {
    "song_id": "0c639r11FQKDFMmGfTRqOU",
    "name": "Downtown Train (Remastered Album Version)",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/9c69e987ee659d4439bf5c455bfa4bd7b827894c",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/79fa6c9588f7bec80db5db5d06bae424a118e57b",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1990
  },
  {
    "song_id": "1TYt5etb427hvOod3EKJA5",
    "name": "Can't Stop",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/eac854ed81c1247184f45caca21b22ce5da39ec7",
    "artist_name": "After 7",
    "image_url": "https://i.scdn.co/image/160b3a40dfc4d0fbded3d4de1cad91f4b883670e",
    "artist_id": "4UPcJIhr5K5fPsm4itqT7E",
    "year": 1990
  },
  {
    "song_id": "3Aimpqxpvmz7jhJlNnEd5c",
    "name": "Swing The Mood",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/9b1857634b8348c2e22dba630262f2c87a3d092a",
    "artist_name": "Jive Bunny And The Mastermixers",
    "image_url": "https://i.scdn.co/image/e441092678a4123fd3a844a3f3c4f339bb7db1a1",
    "artist_id": "4GzIiGnHYrOnq2LUiD6zdo",
    "year": 1990
  },
  {
    "song_id": "18QpSu4VH85xAVms1cBoRO",
    "name": "How Am I Supposed to Live Without You",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/f8c96d97ed1485402f5e623f187977a677079368",
    "artist_name": "Michael Bolton",
    "image_url": "https://i.scdn.co/image/2b0a8783ff4d216534a1f01244520dd8c4166722",
    "artist_id": "6YHEMoNPbcheiWS2haGzkn",
    "year": 1990
  },
  {
    "song_id": "63W4sfeRTTiNKFLAzUJHFe",
    "name": "Alright",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/42b29e650ec5e47b717ce94985bf1f6f98825ed2",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/1e663cb95fcf0004fca211b845c8f23294d9e415",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1990
  },
  {
    "song_id": "3P6fS71hWhZvOnnxQojJ0Z",
    "name": "Mentirosa",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/23edb955d692077495ccfe60b480e2597499409e",
    "artist_name": "Mellow Man Ace",
    "image_url": "https://i.scdn.co/image/41fad3468a4a19802b193a11b0213ea06ec9ace9",
    "artist_id": "37YcGi7z4aXgzWu4jGRe4H",
    "year": 1990
  },
  {
    "song_id": "27Y9xH05ojEaS3fYCrp8PM",
    "name": "(Can’t Live Without Your) Love And Affection",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/10daedbce24bc1357010555a4f28ce9caa0f8dab",
    "artist_name": "Nelson",
    "image_url": "https://i.scdn.co/image/5dfc65057126178df5003e30de418d4361b0a4e6",
    "artist_id": "5jJcbGPjjyEhAoU02ynHaA",
    "year": 1990
  },
  {
    "song_id": "2MOOJh1xUgND4rLjB6h4jY",
    "name": "Get Up (Before The Night Is Over)",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/8077a23a9b1a4aa9f7c27f1fab6f2a31f63e948d",
    "artist_name": "Technotronic",
    "image_url": "https://i.scdn.co/image/4a3f878761eee5948634e56dddf7eb5525f404d7",
    "artist_id": "2Cd98zHVdZeOCisc6Gi2sB",
    "year": 1990
  },
  {
    "song_id": "1sUTfgduT0WIQO8kXKXxLC",
    "name": "Blaze Of Glory",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/1de670c96e4727eb299c564ed26daa85ab8b6cae",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/348fe616ede82c07870538e67be27065f57ce666",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1990
  },
  {
    "song_id": "3oCUfoqrkQiapnJk7UL8IE",
    "name": "What Kind Of Man Would I Be? - Remastered Single/",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/9f77b6a428ed508492abf9d0e83a6d863d47e6cc",
    "artist_name": "Chicago",
    "image_url": "https://i.scdn.co/image/13e99c93468f6e338ac1f018d97503896c01989c",
    "artist_id": "3iDD7bnsjL9J4fO298r0L0",
    "year": 1990
  },
  {
    "song_id": "51i1QRIaCdQat7PZflmpW9",
    "name": "Black Cat",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/edebfcdb8a1c343754f9c0ad37e375c19a92bd2b",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/659cc7aa4b07deae907d85c531863f4969e1ba3c",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1990
  },
  {
    "song_id": "5xJkY5PiDpInkBPtaArVax",
    "name": "Poison",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/79995258190ab6af1d2c90ebb0081e8893ca80b4",
    "artist_name": "Bell Biv DeVoe",
    "image_url": "https://i.scdn.co/image/c6ba757b55103e150d165897949f38fd890a99f6",
    "artist_id": "2zFZiWQJFFshzojycnXoTL",
    "year": 1990
  },
  {
    "song_id": "1fMvhGvNGrumP5MKKDf61e",
    "name": "Just Between You And Me",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/b88dab2a93c29003c9cdf8a5ad2952cf7657aa06",
    "artist_name": "Lou Gramm",
    "image_url": "https://i.scdn.co/image/2a707dd77aeb34d0a68d9fc87c2dae422e348093",
    "artist_id": "7CnmC3gRgLev4I609BrSxj",
    "year": 1990
  },
  {
    "song_id": "56y9EmjGAAejQ6QX9blIGI",
    "name": "Price Of Love",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/80addddb99ec607ca057a9a644ddb3f0d4f05af6",
    "artist_name": "Bad English",
    "image_url": "https://i.scdn.co/image/b5924bbdc8636c49cef09ff4a9514eb790cacf8d",
    "artist_id": "5fhMbh4PVSLSODF2fhWwqt",
    "year": 1990
  },
  {
    "song_id": "3pVJdvuKppX2YVwYsfXZpq",
    "name": "Whip Appeal",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/e99fcc9023c69ac450c08f95c68497531d00f94b",
    "artist_name": "Babyface",
    "image_url": "https://i.scdn.co/image/d32b87896f934b0edf847d2b400edce9ac6b735a",
    "artist_id": "3aVoqlJOYx31lH1gibGDt3",
    "year": 1990
  },
  {
    "song_id": "1Oz5bcZyzhiaEa3F06EiPY",
    "name": "No More Lies",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/bb946fc359d3d347deb166925e1b8c6ca533ed64",
    "artist_name": "Michel'le",
    "image_url": "https://i.scdn.co/image/22026b442962e96924748c286ecd3ff85c6d39b8",
    "artist_id": "4D0WfOUqTzqKysXt33VL3j",
    "year": 1990
  },
  {
    "song_id": "78bDGxqn2KF2VU1G3Kz6J0",
    "name": "This One's for the Children",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/dca8ccb21b39b2149ec89af5f0f7b96ee7ca08e4",
    "artist_name": "New Kids On The Block",
    "image_url": "https://i.scdn.co/image/ba8c5b5dd4d5fb36e2c972cf18ac37db16000036",
    "artist_id": "55qiaow2sDYtjqu1mwRua6",
    "year": 1990
  },
  {
    "song_id": "57ysLbmFhxX9WL9e5QTAmz",
    "name": "The Way That You Love Me",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/032c98a2f605c281a4a7e6cbbd2ab282f1ee4a54",
    "artist_name": "Paula Abdul",
    "image_url": "https://i.scdn.co/image/ccf0628d71d82b2b1e2bce83dab6044a98e632ef",
    "artist_id": "4PpmBoqphQusNFsxuVKb6j",
    "year": 1990
  },
  {
    "song_id": "2kESL3CtcNIxrTNCDqesPI",
    "name": "I'll Be Your Shelter",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/29b5c22ff09fb36305632ef917f35df49091d2d5",
    "artist_name": "Taylor Dayne",
    "image_url": "https://i.scdn.co/image/55f065a07bca72bb125f5296d4a3ffff38c3c825",
    "artist_id": "32lVGr0fSRGT6okLKHiP68",
    "year": 1990
  },
  {
    "song_id": "6Bv2bQvYZDJLVlMPbmuFQm",
    "name": "Everything",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/e7e9377475e3dce83701693c96e4538c362d543c",
    "artist_name": "Jody Watley",
    "image_url": "https://i.scdn.co/image/5fa631c49b10eba706352346a98147251c680303",
    "artist_id": "71aKjsWKYqASAffyIQaocZ",
    "year": 1990
  },
  {
    "song_id": "6yhwKpKqhBHaP2EC7Q277N",
    "name": "With Every Beat Of My Heart",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/0e8a7b9b28d71b7dda7522536d3ab79d464d6094",
    "artist_name": "Taylor Dayne",
    "image_url": "https://i.scdn.co/image/8e983f88ebcfc700869f724d726611bfe57847ef",
    "artist_id": "32lVGr0fSRGT6okLKHiP68",
    "year": 1990
  },
  {
    "song_id": "40JdsAUNCjvHfXzin6UPIN",
    "name": "How Can We Be Lovers",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/a8ac84a80e0b5c84af9498351fb24ae872db10eb",
    "artist_name": "Michael Bolton",
    "image_url": "https://i.scdn.co/image/f6755f2663cc76b19d98d24bc4e86e740983e60d",
    "artist_id": "6YHEMoNPbcheiWS2haGzkn",
    "year": 1990
  },
  {
    "song_id": "4pEcgZ7nZtrQWqDZrO5XPN",
    "name": "Just Like Jesse James",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/a77cded2dec3f1e22e194fa69adf3ea0d0fe8d49",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/f3510cce8da3ed7873e759fb0d3021d35d9a88b4",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1990
  },
  {
    "song_id": "6GS6uE1vDmtzxO8GztAR2O",
    "name": "Two To Make It Right",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/f774e2cdc8fbfa54b19f1edc7b592da6650560a5",
    "artist_name": "Seduction",
    "image_url": "https://i.scdn.co/image/f7ff1b957be0b6e4540af982448543820dae36f0",
    "artist_id": "66pXn4ZrPf6yzk5PHGkUZA",
    "year": 1990
  },
  {
    "song_id": "2nNKXDFRz6cog1kolC3efq",
    "name": "Giving You The Benefit",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/0dc3c2956ac188b6dfb7eb2aa37ab853af666f63",
    "artist_name": "Pebbles",
    "image_url": "https://i.scdn.co/image/688938b4f8ce63b1dc26388b87d2f2fa7823bd6c",
    "artist_id": "702AvACMk5mieXIYlC2Gxx",
    "year": 1990
  },
  {
    "song_id": "2d1RPaZmz5fTUGK3ughMo7",
    "name": "She Ain't Worth It",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/5599a4c686fd703eb48666d34bea877d289ba603",
    "artist_name": "Glenn Medeiros",
    "image_url": "https://i.scdn.co/image/dcf5918c83fa04fb4f3a54c506151538cf64a6fa",
    "artist_id": "0bByarMN8ryEFQsRo6iCUN",
    "year": 1990
  },
  {
    "song_id": "6oOV3BQc3KYgxBaVZI4nRs",
    "name": "Epic [Radio Remix Edit] (45 Version)",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/aa75d9a3ddf27e1cd89336079d842fe56b73695f",
    "artist_name": "Faith No More",
    "image_url": "https://i.scdn.co/image/2ce3de8614afe9299625c020b22106bf8ce48976",
    "artist_id": "6GbCJZrI318Ybm8mY36Of5",
    "year": 1990
  },
  {
    "song_id": "6pNvKPvOknI5zI4o9TB43f",
    "name": "Do Me!",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/49ac9d86f71b453e464622d63bbc53c1831d8db2",
    "artist_name": "Bell Biv DeVoe",
    "image_url": "https://i.scdn.co/image/c6ba757b55103e150d165897949f38fd890a99f6",
    "artist_id": "2zFZiWQJFFshzojycnXoTL",
    "year": 1990
  },
  {
    "song_id": "1DTLJp3baHKyTYZMWD4zgp",
    "name": "We Can't Go Wrong",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/744327063dbd70a041953c94f98f6d216f7d34d8",
    "artist_name": "The Cover Girls",
    "image_url": "https://i.scdn.co/image/fcaa3f1433dce2f94940b09c8de58d7d36c30bd5",
    "artist_id": "6D01mATo26VaiiCfZzpWAm",
    "year": 1990
  },
  {
    "song_id": "1QMmvLfNQOQ2OFtvn3psmj",
    "name": "Rub You The Right Way - Radio Edit",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/0e6f134b6e0acaaf29545261de5ecb5386d23d38",
    "artist_name": "Johnny Gill",
    "image_url": "https://i.scdn.co/image/9c42c77b0822200edd3782710082562a44cabc44",
    "artist_id": "7oHzn7edwmrYClrPRINkbn",
    "year": 1990
  },
  {
    "song_id": "2PBqZxkVJ7Y1gLzflSQWWG",
    "name": "When I'm Back On My Feet Again",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/ac14d8450dc6302c7c2a9fddf351563aaac9cca9",
    "artist_name": "Michael Bolton",
    "image_url": "https://i.scdn.co/image/f6755f2663cc76b19d98d24bc4e86e740983e60d",
    "artist_id": "6YHEMoNPbcheiWS2haGzkn",
    "year": 1990
  },
  {
    "song_id": "2sC4Tq54NymKt6z4PPVqJo",
    "name": "Dangerous - 2003 Remastered Version",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/4fc455ad85a2c0f520d206accd11a7eea0dfe531",
    "artist_name": "Roxette",
    "image_url": "https://i.scdn.co/image/a767d4820859faa5a8e199499516dd03c34829b5",
    "artist_id": "2SHhfs4BiDxGQ3oxqf0UHY",
    "year": 1990
  },
  {
    "song_id": "6NtDQURC7nQhB7fnwd4Lct",
    "name": "Here We Are",
    "popularity": 17,
    "preview_url": "https://p.scdn.co/mp3-preview/30bc026c7e232413523f7cbacb95e5ea87a068ec",
    "artist_name": "Gloria Estefan",
    "image_url": "https://i.scdn.co/image/0bf6096ec60db1a3393c27114a12d3663731f884",
    "artist_id": "5IFCkqu9J6xdWeYMk5I889",
    "year": 1990
  },
  {
    "song_id": "4ksfkISFQWPR9j0nxyG2eK",
    "name": "Forever - Single Version",
    "popularity": 12,
    "preview_url": "https://p.scdn.co/mp3-preview/d2e8fbda389b2a94c751cb1f26f3c45b36bb1d64",
    "artist_name": "KISS",
    "image_url": "https://i.scdn.co/image/281d97e1c35dcba2c78dd8d4d27eb2c3f68e5d25",
    "artist_id": "07XSN3sPlIlB2L2XNcTwJw",
    "year": 1990
  },
  {
    "song_id": "4er8NyQ8cFnZ2b643Tjc44",
    "name": "Shiny Happy People",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/1f976c83c5a9b7d78fb672837aa412023cb7eafd",
    "artist_name": "R.E.M.",
    "image_url": "https://i.scdn.co/image/28fa38aae0a64222cc8f03d1f96c950fb6889afc",
    "artist_id": "4KWTAlx2RvbpseOGMEmROg",
    "year": 1991
  },
  {
    "song_id": "2UVLuddklEVak5PXgC7baA",
    "name": "Groove Is In The Heart",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/984c73b1dd692ccbcb723559de97f4d83c0118fb",
    "artist_name": "Deee-Lite",
    "image_url": "https://i.scdn.co/image/1a3aa6c214cc599a6d4cc1b512c86b1018ccc110",
    "artist_id": "4eQJIXFEujzhTVVS1gIfu5",
    "year": 1991
  },
  {
    "song_id": "1gVgkQFOKa8Wc1HYsJtPdH",
    "name": "More Than Words",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/eac4c8a43ec1462a8bfbd4f965ffc28ae9e03a9a",
    "artist_name": "Extreme",
    "image_url": "https://i.scdn.co/image/7ce9d396c8e3ab3d5c6871ae2a213a5b58a01057",
    "artist_id": "6w7j5wQ5AI5OQYlcM15s2L",
    "year": 1991
  },
  {
    "song_id": "3aZPzF7Sr0zy3K0EkKyEzk",
    "name": "It Ain't Over 'Til It's Over",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/264ca5691a09af568a2779aa4a68a5338464a47b",
    "artist_name": "Lenny Kravitz",
    "image_url": "https://i.scdn.co/image/d7dab70b4163c390399f85b2f27932f5554e4fe0",
    "artist_id": "5gznATMVO85ZcLTkE9ULU7",
    "year": 1991
  },
  {
    "song_id": "2F9jMklOJOl65znIH4kc8C",
    "name": "Crazy",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/5043f65fc310ccc0d5053f7a639fc8a89298cec8",
    "artist_name": "Seal",
    "image_url": "https://i.scdn.co/image/d5d2e03fb4d4d71eb17034f52e4f29eee4dade73",
    "artist_id": "5GtMEZEeFFsuHY8ad4kOxv",
    "year": 1991
  },
  {
    "song_id": "07efOAoPwcRdd4W6gi8BRF",
    "name": "I Wanna Sex You Up",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/ebb98aebf7389318269f31beb6dc1094c36104b1",
    "artist_name": "Color Me Badd",
    "image_url": "https://i.scdn.co/image/791925e776a618e374a70c88e4c7c9d19d393fd1",
    "artist_id": "1QtIfAa6y7w2JhxYJhYeUG",
    "year": 1991
  },
  {
    "song_id": "5hWdgGVcfTeLPAiHM6EZG9",
    "name": "Good Vibrations",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/a74d9ce554a88b4b32092a83ad2360d052f969a7",
    "artist_name": "Marky Mark And The Funky Bunch",
    "image_url": "https://i.scdn.co/image/b851a8583410f12e84f47f30b75241f6b88142c7",
    "artist_id": "4046bnFxFJdLEtG7F2qXaV",
    "year": 1991
  },
  {
    "song_id": "42HaJ7QlInzQ5vHKWb5Ixa",
    "name": "The Way You Do The Things You Do",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/05f32ca7a6bb8cd5ef1575984aef2bca189db7f6",
    "artist_name": "UB40",
    "image_url": "https://i.scdn.co/image/58a1a0ced12c24d0c4636b31744ab6024dcf8394",
    "artist_id": "69MEO1AADKg1IZrq2XLzo5",
    "year": 1991
  },
  {
    "song_id": "015qd1I4v00JIoK7yOUgKC",
    "name": "Rush, Rush",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/6079afaff1e9656bb224261196b4ea52d55625b5",
    "artist_name": "Paula Abdul",
    "image_url": "https://i.scdn.co/image/c00eb4bfb1961942cf7c93ff6f1b74e6b9471109",
    "artist_id": "4PpmBoqphQusNFsxuVKb6j",
    "year": 1991
  },
  {
    "song_id": "6oNvmplQGUkmAh441Teows",
    "name": "I Touch Myself",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/d08a21c9e151a50d1f43204cc7d67d319244151e",
    "artist_name": "Divinyls",
    "image_url": "https://i.scdn.co/image/214175c217eac4cce839cf5d6b56eb273534eb32",
    "artist_id": "5t06MTkDD3yr5LVs3YFLQC",
    "year": 1991
  },
  {
    "song_id": "6Ceejf3zBXvAhIY9DbP1Pr",
    "name": "Something To Talk About",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/59abe0e2582f430befb74667018e3a15c5796f21",
    "artist_name": "Bonnie Raitt",
    "image_url": "https://i.scdn.co/image/680fb76654ebb4d1b3148df1741839957cd2634c",
    "artist_id": "4KDyYWR7IpxZ7xrdYbKrqY",
    "year": 1991
  },
  {
    "song_id": "2ENmkocNNewTRbuNd6WJpR",
    "name": "Unbelievable - LP - EMF",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/cbd12a7c9210b0e2c45b76c46bca3ed41c233cc6",
    "artist_name": "EMF",
    "image_url": "https://i.scdn.co/image/11261a8d84ea756f170d92c2637bf74eeb4341af",
    "artist_id": "39oSLGo3HkaeYXzUEGgAGQ",
    "year": 1991
  },
  {
    "song_id": "7f9sC9fvtjYSZYOLSmKJlq",
    "name": "Tom's Diner",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/7e23223a50c64d42070bdfb242726febca8bde89",
    "artist_name": "Suzanne Vega",
    "image_url": "https://i.scdn.co/image/2c41d2a56be7cdeb8457cbde5200cf5a2a2315e3",
    "artist_id": "3X0tJzVYoWlfjLYI0Ridsw",
    "year": 1991
  },
  {
    "song_id": "4wDSLkTUIpRsn3UbCzW9wV",
    "name": "Motownphilly - Original Version",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/98a54a9a4ccba67555f21726cd37279272cdea42",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/030ad8ee793d05227a3d58af683b31d65df98d28",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1991
  },
  {
    "song_id": "5t5rCnsgRBtcKqTB7SbD1Q",
    "name": "High Enough",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/f1a40744920bfde84f7925af2d56b0fbd7f9e1b1",
    "artist_name": "Damn Yankees",
    "image_url": "https://i.scdn.co/image/bd5b6ad8444c29312da8b134276b71a4417724a3",
    "artist_id": "7ihLzUpuNecU5VBkvOUDNq",
    "year": 1991
  },
  {
    "song_id": "6YR2MkzMhojHBmW1TbXtZO",
    "name": "Here I Am - Come And Take Me",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/e5e41fef6d5a3dda786d29ff4df8aeafe23291e6",
    "artist_name": "UB40",
    "image_url": "https://i.scdn.co/image/58a1a0ced12c24d0c4636b31744ab6024dcf8394",
    "artist_id": "69MEO1AADKg1IZrq2XLzo5",
    "year": 1991
  },
  {
    "song_id": "5EcQ53R86rMZ5WnfbVvQEh",
    "name": "Silent Lucidity - 2000 Digital Remaster",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/708b93bb8bdb5797b9fda5688476691988bbe329",
    "artist_name": "Queensrÿche",
    "image_url": "https://i.scdn.co/image/a145fdce3d39fcd0723932a3432adbfa679f3cca",
    "artist_id": "2OgUPVlWYgGBGMefZgGvCO",
    "year": 1991
  },
  {
    "song_id": "0flZtKtmkZMNqFF7V7Yfmu",
    "name": "I Like the Way (The Kissing Game)",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/fed652fc49154e5909bcba3ff3e486f8df53785c",
    "artist_name": "Hi-Five",
    "image_url": "https://i.scdn.co/image/0cd7ff9cc859ff202ed95892ed85747591aea1e5",
    "artist_id": "0EVUivUkugMtNF09L4QBMH",
    "year": 1991
  },
  {
    "song_id": "6BWRvw630R8z2vNMok6quI",
    "name": "Justify My Love",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/caddd4472a5c4556a25c4c643c0b257dae019d06",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1991
  },
  {
    "song_id": "1Ckd4d6vkhvOp5HlAc5Ie2",
    "name": "Summertime - Single Edit",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/d82e2657bdbc8aacbf480f40e39062d480afef19",
    "artist_name": "DJ Jazzy Jeff & The Fresh Prince",
    "image_url": "https://i.scdn.co/image/d5d3de56ff78e5efc99f34a0a0801881b1fb4ccc",
    "artist_id": "1mG23iQeR29Ojhq89D5gbh",
    "year": 1991
  },
  {
    "song_id": "2kDH08boXJyhKMl5hnZbrE",
    "name": "Love of a Lifetime",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/e2f3f1c83692f6ffa983e8fd798a780565003764",
    "artist_name": "Firehouse",
    "image_url": "https://i.scdn.co/image/b944d3f2102b862d8fb1049c8b7d220dc04ef580",
    "artist_id": "28pS8WVbFstY0o1SrqCf8I",
    "year": 1991
  },
  {
    "song_id": "6eBK3edMW7bEzecF1eCezc",
    "name": "(Everything I Do) I Do It For You",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/6b6f66915a33aaee3acd8726053fce869170b591",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/78f8d0610a34585f621232ba15265316024b28d0",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1991
  },
  {
    "song_id": "3fcGGP62sllcNEhuFJVYeC",
    "name": "Right Here Right Now",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/1b71a9a0db7d27af1c42ae42ec3a4c0d08627a34",
    "artist_name": "Jesus Jones",
    "image_url": "https://i.scdn.co/image/72ffbbac564e6079482d22a95d4cc561899ec9b8",
    "artist_id": "0roeI3yPusDWwWRzAqTopw",
    "year": 1991
  },
  {
    "song_id": "1RYSbUnasW12klBZ9lxWIM",
    "name": "Losing My Religion",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/18064632c5e1d7d58c0a4db986a4626c3710834f",
    "artist_name": "R.E.M.",
    "image_url": "https://i.scdn.co/image/0df06d2f6add5874ece4857217e376051e7bda1e",
    "artist_id": "4KWTAlx2RvbpseOGMEmROg",
    "year": 1991
  },
  {
    "song_id": "4L9VA69sfZKK5HAs5zP22I",
    "name": "I'll Give All My Love To You - Remastered Single Version",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/30afea24959a0d3a352500af9fe0fcf2d3c379c4",
    "artist_name": "Keith Sweat",
    "image_url": "https://i.scdn.co/image/64ea3daaa45e3aa14c73f247df79a592f1f04101",
    "artist_id": "2r09Inibex3C4ZNTUVSG3m",
    "year": 1991
  },
  {
    "song_id": "2Bdzf6aG9MlmKtAgve3XLJ",
    "name": "Gonna Make You Sweat (Everybody Dance Now)",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/add1be116bfa6d2074db12e90254f7943a52681a",
    "artist_name": "C & C Music Factory",
    "image_url": "https://i.scdn.co/image/b1edf6d454c958f30d81a020406b0b8aaad0c54f",
    "artist_id": "7krx6UBDKLwE0q3s3fesqF",
    "year": 1991
  },
  {
    "song_id": "3IDsegNBHC4pjGCOMTQYlU",
    "name": "Baby, Baby",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/b87b5fc55648784f5d3ff1b1cdf754549de15949",
    "artist_name": "Amy Grant",
    "image_url": "https://i.scdn.co/image/7ce5211fef7ac211d76626ed9fd290e79ba59a42",
    "artist_id": "72Nhcx7prNk2ZCxhx0Y5es",
    "year": 1991
  },
  {
    "song_id": "6TSM5vkz0WzyZsNAKKYDcw",
    "name": "Someday",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/7cdb0935d17b53ab654398f950b6a01a90441555",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/3995e37a04f999d4e74fefa295fc0eecfa7154eb",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1991
  },
  {
    "song_id": "3lHJkG9kcsgsfjlEH2tkxi",
    "name": "All The Man That I Need",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/28d4ee9534f9760bd64a96668dda5453ad78574e",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/1a45a2b6266e337b71d72d120800184ab2138657",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1991
  },
  {
    "song_id": "1hFtJ5rV3aAm58ErijHdFO",
    "name": "I Don't Wanna Cry",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/75af45d4cab710aadc64599992f338a9c652f6b9",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/3995e37a04f999d4e74fefa295fc0eecfa7154eb",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1991
  },
  {
    "song_id": "1KT3ndndHOGXePgC4o0Jq9",
    "name": "Get Here",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/1f4f6701a79ecefd10a0f96baa70adf831a8dab3",
    "artist_name": "Oleta Adams",
    "image_url": "https://i.scdn.co/image/3138e1ccd8858648df473598565561ab743a0187",
    "artist_id": "1BMb6sQJVkmUyCuodmRs2g",
    "year": 1991
  },
  {
    "song_id": "7KVrjDjOJiIHwGagvIt3cA",
    "name": "Because I Love You (The Postman Song)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/26c48bd6c81f66cf851cddf52f5f7b4c7d521817",
    "artist_name": "Stevie B",
    "image_url": "https://i.scdn.co/image/5ccfc9d28648fa288ba7661041ccd3faaf482c87",
    "artist_id": "6V7pNWhlJpD0s0bMdB1PU9",
    "year": 1991
  },
  {
    "song_id": "5EZJ2mJ2KTNs01n0cBR6lC",
    "name": "Things That Make You Go Hmmmm....",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/619ee537f2e41ac2284456952c4270f5b68999c7",
    "artist_name": "C & C Music Factory",
    "image_url": "https://i.scdn.co/image/2b9493c23674c86b45c40a160a80571b76a3de68",
    "artist_id": "7krx6UBDKLwE0q3s3fesqF",
    "year": 1991
  },
  {
    "song_id": "2vAbKDdqA6cEBHU9zQiA8g",
    "name": "Around The Way Girl",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/9c0c810ac3af12ded66442cbe96f82b00a51a8c0",
    "artist_name": "LL Cool J",
    "image_url": "https://i.scdn.co/image/3f063f9d9b2e667924aa8c65bd55252885a2430a",
    "artist_id": "1P8IfcNKwrkQP5xJWuhaOC",
    "year": 1991
  },
  {
    "song_id": "3lRgzwd3g1TewI4zjgbh9V",
    "name": "Sensitivity",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/db01ed966c03fbb238a2a6a34877c8ad4f29f729",
    "artist_name": "Ralph Tresvant",
    "image_url": "https://i.scdn.co/image/9e0a33069f017becb51a34d8fe98aa2c581a6dcf",
    "artist_id": "6MLDcHrNh4OqxDZAjMt5pt",
    "year": 1991
  },
  {
    "song_id": "1CeGBbkJeKPojRmlo2gIwq",
    "name": "I Can't Wait Another Minute",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/94fec1f0e00683142e2f3cbdc6199325c7a161bf",
    "artist_name": "Hi-Five",
    "image_url": "https://i.scdn.co/image/0cd7ff9cc859ff202ed95892ed85747591aea1e5",
    "artist_id": "0EVUivUkugMtNF09L4QBMH",
    "year": 1991
  },
  {
    "song_id": "6oYjESDcQAQrRh4SKMH7sW",
    "name": "Signs",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/c5a601c9af5e32b272c961b0c336cc6df8edc298",
    "artist_name": "Tesla",
    "image_url": "https://i.scdn.co/image/76f9d4ead093e2cc9a57b811d5789fb3cf4cc755",
    "artist_id": "0dgnITyIAN4NrmUVisvxHU",
    "year": 1991
  },
  {
    "song_id": "5KNbCF0FpIWuPE8x6bhGgw",
    "name": "You're In Love",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/e60c2151a7964aa5022c760061b9f01e5657545b",
    "artist_name": "Wilson Phillips",
    "image_url": "https://i.scdn.co/image/a2ba2c32640886b04977df7de7006659bda52daf",
    "artist_id": "1yMYjh77WgOVafRkI50mim",
    "year": 1991
  },
  {
    "song_id": "6m8umBymc4gJQUDq8fFKgH",
    "name": "Strike It Up",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/f6a1e83d356767c80e0064b64d1273f867a1c95f",
    "artist_name": "Black Box",
    "image_url": "https://i.scdn.co/image/254f4cf0629e80083983741e9239ad11eaa4e037",
    "artist_id": "6tsRo8ErXzpHk3tQeH6GBW",
    "year": 1991
  },
  {
    "song_id": "1cItNpcJskTVETprljc7HV",
    "name": "Hole Hearted",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/4dc3c61f494b412db34abf0f1651829bdef11afd",
    "artist_name": "Extreme",
    "image_url": "https://i.scdn.co/image/7ce9d396c8e3ab3d5c6871ae2a213a5b58a01057",
    "artist_id": "6w7j5wQ5AI5OQYlcM15s2L",
    "year": 1991
  },
  {
    "song_id": "3MByCefcd6Y2S0VKw13e2B",
    "name": "Humpin' Around - Radio Edit",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/46661b60c75350a066a02e1b30151be9ec784db5",
    "artist_name": "Bobby Brown",
    "image_url": "https://i.scdn.co/image/4a33a0da119619c814576f290b8a0a56a4683df0",
    "artist_id": "62sPt3fswraiEPnKQpAbdE",
    "year": 1991
  },
  {
    "song_id": "55G6KjatHN4CkiT47Omlpz",
    "name": "Wind Of Change",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/ca1b8174ca722868869ade80826bf679b372e492",
    "artist_name": "Scorpions",
    "image_url": "https://i.scdn.co/image/99201546a1be299e7f448b3c9d8c68ec8e796dcd",
    "artist_id": "27T030eWyCQRmDyuvr1kxY",
    "year": 1991
  },
  {
    "song_id": "0fLgxhfATkNRJGca4rCu9X",
    "name": "Rhythm Of My Heart (Remastered Album Version)",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/50619265dce3f2640838a38421218a59061dbbfa",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/79fa6c9588f7bec80db5db5d06bae424a118e57b",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1991
  },
  {
    "song_id": "1o0ixPgBmT2O6g8tAl1aAc",
    "name": "Sadeness - Part I - Radio Edit",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/1f55b1cbf414c62a0b576246aafaebc21793caba",
    "artist_name": "Enigma",
    "image_url": "https://i.scdn.co/image/93b69a3b17ecc0851fe64fc1f3d85ebbcbc6330e",
    "artist_id": "3DmG65yHQsMms7WAvrZOdt",
    "year": 1991
  },
  {
    "song_id": "55lX3vm1G35mUpawXHK5Te",
    "name": "Every Heartbeat",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/088d7de87d5891ca5af8fa1cf02debd922cf6b6c",
    "artist_name": "Amy Grant",
    "image_url": "https://i.scdn.co/image/7ce5211fef7ac211d76626ed9fd290e79ba59a42",
    "artist_id": "72Nhcx7prNk2ZCxhx0Y5es",
    "year": 1991
  },
  {
    "song_id": "0PKTBeN87JYJ1mRzrq5Aog",
    "name": "Place In This World",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/34d20adba0ddb689a0a9cabd43ce7c36ca43ac32",
    "artist_name": "Michael W. Smith",
    "image_url": "https://i.scdn.co/image/5e6b5a4bbb5ecfc5e3502b6da67a1704fdb0673e",
    "artist_id": "5aBxFPaaGk9204ssHUvXWN",
    "year": 1991
  },
  {
    "song_id": "2fXIpPInm70t1vMRm82Kar",
    "name": "Love Will Never Do (Without You)",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/823381eb547fc7d0b3d2bf04b1631fd2944737da",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/2f15d571d8c535ab30318e2557d1b8a5a7d86e34",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1991
  },
  {
    "song_id": "2lxfnTyWxW381PXDm9Mg77",
    "name": "Impulsive",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/a1056c6d92aac5d7443fa1763733a3e4a1ecc1e7",
    "artist_name": "Wilson Phillips",
    "image_url": "https://i.scdn.co/image/a2ba2c32640886b04977df7de7006659bda52daf",
    "artist_id": "1yMYjh77WgOVafRkI50mim",
    "year": 1991
  },
  {
    "song_id": "1Ezs8eYxuZjhlgyoI1Bo76",
    "name": "Play That Funky Music",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/5f61c356c30c482080be739092b9f8ff7d7c99de",
    "artist_name": "Vanilla Ice",
    "image_url": "https://i.scdn.co/image/b4f7bfa0052fb39e94cf4953d17d93a8535a6221",
    "artist_id": "7GXXMm3DB1VswVcuGyInUd",
    "year": 1991
  },
  {
    "song_id": "5vz2J2kP3vV7mZE9UxnFcG",
    "name": "Cream",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/cfb86afe4667c244dce0f0d7d9e8c918d6225952",
    "artist_name": "Prince & The New Power Generation",
    "image_url": "https://i.scdn.co/image/588c35579f04607bb65624ad9afd5dbcb2ddb2da",
    "artist_id": "2T9xH19b4sNPqfwjPGzejt",
    "year": 1991
  },
  {
    "song_id": "31ixRkE8fxhP6yA3aDhjr4",
    "name": "Show Me The Way",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/12307acb9fb7d28f6a72807bc808101db0e32fbb",
    "artist_name": "Styx",
    "image_url": "https://i.scdn.co/image/fbaa93c80441950bc77d2344fff83db911b8e3ab",
    "artist_id": "4salDzkGmfycRqNUbyBphh",
    "year": 1991
  },
  {
    "song_id": "6rKQoV8NBoW6gXrWGpoCv7",
    "name": "Round and Round",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/3e2b144b3dc4af631adef8a245508368319fd8a2",
    "artist_name": "Prince & Tevin Campbell",
    "image_url": "https://i.scdn.co/image/403747f3cbd14008a50110c15f6319b344f2825c",
    "artist_id": "5LwbTYGhuGr75GM9IC7fHS",
    "year": 1991
  },
  {
    "song_id": "6FfdQJ9HJcJAvsPVIyoGZ5",
    "name": "Rico Suave",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/d98ef78080eab1e210059a12f16cf73bc7af20f4",
    "artist_name": "Gerardo",
    "image_url": "https://i.scdn.co/image/37ab31bd9e820b75069636ffa0ec132c5dddb3b5",
    "artist_id": "6ZccA6Up3OSLUY7m8NNLUs",
    "year": 1991
  },
  {
    "song_id": "53Ttk5sOEtlJvOZmv16aQn",
    "name": "The Motown Song (Remastered Album Version)",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/c0f701084f82a881a6d128204d1c99eb49108dfa",
    "artist_name": "Rod Stewart with the Temptations",
    "image_url": "https://i.scdn.co/image/79fa6c9588f7bec80db5db5d06bae424a118e57b",
    "artist_id": "7yuYcumheOYNLmAaGRU2XP",
    "year": 1991
  },
  {
    "song_id": "0whwYFXgGNg14O8hdHguzV",
    "name": "Here We Go, Let's Rock & Roll",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/ae415d03c1daefd80cfed7630f75733c6912927d",
    "artist_name": "C & C Music Factory",
    "image_url": "https://i.scdn.co/image/2b9493c23674c86b45c40a160a80571b76a3de68",
    "artist_id": "7krx6UBDKLwE0q3s3fesqF",
    "year": 1991
  },
  {
    "song_id": "4d5XCOm90B4Gs3ZGeFVaUX",
    "name": "Something To Believe In - Digitally Remastered 96",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/3c1d15aaf10c33080070dd2cd95de951648448c6",
    "artist_name": "Poison",
    "image_url": "https://i.scdn.co/image/1b16e590ac08ec8521de9934276bbf517ad6aea4",
    "artist_id": "1fBCIkoPOPCDLUxGuWNvyo",
    "year": 1991
  },
  {
    "song_id": "5m8xVZhlM7E2mL9uuxZpF6",
    "name": "The Promise Of A New Day",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/90e05eaa21b50e4b3f16c20ef43994b5a7ea4280",
    "artist_name": "Paula Abdul",
    "image_url": "https://i.scdn.co/image/c00eb4bfb1961942cf7c93ff6f1b74e6b9471109",
    "artist_id": "4PpmBoqphQusNFsxuVKb6j",
    "year": 1991
  },
  {
    "song_id": "6ILvjEWvikRHgsoKgfFc7C",
    "name": "After The Rain",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/8b86a39c6b608225306cfb0dc45a6b3bb5c57783",
    "artist_name": "Nelson",
    "image_url": "https://i.scdn.co/image/5dfc65057126178df5003e30de418d4361b0a4e6",
    "artist_id": "5jJcbGPjjyEhAoU02ynHaA",
    "year": 1991
  },
  {
    "song_id": "6HQjeXaQl2lctjLOFR0ZEH",
    "name": "All This Time",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/1d4f93e3d88ffed6d4d14e7f257e98b7a5564b07",
    "artist_name": "Sting",
    "image_url": "https://i.scdn.co/image/92ccc381e02fbe3387d072b0a3830341c554794f",
    "artist_id": "0Ty63ceoRnnJKVEYP0VQpk",
    "year": 1991
  },
  {
    "song_id": "6WCJyL4KetAqEZYhRq5S3f",
    "name": "Temptation",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/c7c1c32328fddab07cd56a712449ab9f730f656d",
    "artist_name": "Corina",
    "image_url": "https://i.scdn.co/image/e649a4230d156a81440f37b42308bc315ec064d2",
    "artist_id": "5IU8A4qXB5oCx6YFdIqxI8",
    "year": 1991
  },
  {
    "song_id": "3zX2ZUPo0M8EULGmYJ87C6",
    "name": "I'll Be There",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/d0f3e6bb2babaa2b9703bcf1dc49f0d89a5d2468",
    "artist_name": "The Escape Club",
    "image_url": "https://i.scdn.co/image/6ccc4ab977412982276ed5c3b48082e8be85026a",
    "artist_id": "6mRev5KzKOgQcZjWanwBdd",
    "year": 1991
  },
  {
    "song_id": "0LdOVtg4WgJNeQ9D95tdvP",
    "name": "Everybody Plays The Fool",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/9b2e503fcdf2ca98755d4ac5b3d911c428328043",
    "artist_name": "Aaron Neville",
    "image_url": "https://i.scdn.co/image/e9a55177261588b9c08aca1d61b9a1227b93efa4",
    "artist_id": "57ALvbCBaCkNlgTOSiUPdT",
    "year": 1991
  },
  {
    "song_id": "6ixo9JA1iCxGVJKTOS2NZv",
    "name": "I`ve Been Thinking About You (Original Mix 2007)",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/eed5d3b395514d8aa1d7c3efc03bfb10ecc89918",
    "artist_name": "Londonbeat",
    "image_url": "https://i.scdn.co/image/2f0763801c4e3bcdc125e7904f1e0e24488e736c",
    "artist_id": "0gcMPgunYh4rX1UOdvZKBn",
    "year": 1991
  },
  {
    "song_id": "3uJBcTWsHWoxnUkrLOx4JP",
    "name": "Too Many Walls",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/d746b8a1dd4b57e117014c6ef7bbeccb267261ce",
    "artist_name": "Cathy Dennis",
    "image_url": "https://i.scdn.co/image/0eb9580af8b4688b89332a6844aa3e44cd1c8fe5",
    "artist_id": "2zVsfeSyFbCey7rq7PasHp",
    "year": 1991
  },
  {
    "song_id": "5gbPzqSC9MFfkeM3L06Imy",
    "name": "Real Real Real",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/2f1e75385e56fdfc0b4a84b8ee7b7bbc7aeb86b7",
    "artist_name": "Jesus Jones",
    "image_url": "https://i.scdn.co/image/72ffbbac564e6079482d22a95d4cc561899ec9b8",
    "artist_id": "0roeI3yPusDWwWRzAqTopw",
    "year": 1991
  },
  {
    "song_id": "7f0tp6MJoOVBlij3XM2hMK",
    "name": "Just Another Dream",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/efcee4fc6ae694bf7a0e9a00ca73a95f20c904a5",
    "artist_name": "Cathy Dennis",
    "image_url": "https://i.scdn.co/image/0eb9580af8b4688b89332a6844aa3e44cd1c8fe5",
    "artist_id": "2zVsfeSyFbCey7rq7PasHp",
    "year": 1991
  },
  {
    "song_id": "10JIQ4VbdRMlqvSJGvi7El",
    "name": "This House",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/29e2ac592175815f3baf55fa0b9e240f59b578c4",
    "artist_name": "Tracie Spencer",
    "image_url": "https://i.scdn.co/image/19e9400e837df0e214206260d0d14ae63dea10c2",
    "artist_id": "2SwRWWKpFqktihz7o2rfYX",
    "year": 1991
  },
  {
    "song_id": "6O83X8zjAETDfBkHYvpWcA",
    "name": "Can't Stop This Thing We Started",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/7bcb930262bef9339c410a390b31f223e9f50598",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/78f8d0610a34585f621232ba15265316024b28d0",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1991
  },
  {
    "song_id": "46L7QPDXvEPoTLCHZwmZms",
    "name": "Cry For Help",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/2beb476a94d0736b363aa8cebbaa8528f95f150b",
    "artist_name": "Rick Astley",
    "image_url": "https://i.scdn.co/image/70883b6ef18109db20137841af5019c1f89675fb",
    "artist_id": "0gxyHStUsqpMadRV0Di1Qt",
    "year": 1991
  },
  {
    "song_id": "5KW5exONmWLybCyc8ogMCn",
    "name": "The One and Only",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/be22accb39c63acc474b05bf2e14b618a29700fa",
    "artist_name": "Chesney Hawkes",
    "image_url": "https://i.scdn.co/image/377bde504f805f866f08f86dda00e50e1341be05",
    "artist_id": "3dipm6p2uAgitwQsOZU5oY",
    "year": 1991
  },
  {
    "song_id": "25YKAZ3ZbLRbjg2JqsdatF",
    "name": "I Saw Red",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/7131444590b44465f21cbb65981d7ef1ee3b6cad",
    "artist_name": "Warrant",
    "image_url": "https://i.scdn.co/image/f6834fb743af5365e0862a0c3b1e4fac10c92e27",
    "artist_id": "7HLvzuM9p11k9lUQfSM4Rq",
    "year": 1991
  },
  {
    "song_id": "3QNo5oDTOSI23CDDpDUE9Q",
    "name": "Now That We Found Love - 7\" Radio",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/c7dda1368cc23f719a40c59134231a4c925f36a8",
    "artist_name": "Heavy D & The Boyz",
    "image_url": "https://i.scdn.co/image/40a73e488559b3bcd419cb0bcdf74614c91c0a46",
    "artist_id": "4KHdmkq99PXA6QEJ2lKpA3",
    "year": 1991
  },
  {
    "song_id": "5x9MJycRilBm2SNavzt9yF",
    "name": "Coming Out of the Dark",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/91dcf99c46427f521828379116c1fb2540270e71",
    "artist_name": "Gloria Estefan",
    "image_url": "https://i.scdn.co/image/3771aecee94547a6b3863bc09acd33cfeb0a1c56",
    "artist_id": "5IFCkqu9J6xdWeYMk5I889",
    "year": 1991
  },
  {
    "song_id": "5Ex5UHv1C0nCDkZDTJRWfW",
    "name": "Time, Love And Tenderness - Single Version",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/7deeaa4f380746a117e5d517ffbc6459add4bf2d",
    "artist_name": "Michael Bolton",
    "image_url": "https://i.scdn.co/image/f6755f2663cc76b19d98d24bc4e86e740983e60d",
    "artist_id": "6YHEMoNPbcheiWS2haGzkn",
    "year": 1991
  },
  {
    "song_id": "2q9xZPaOxWr3n250HpKExl",
    "name": "Joyride - Single Version",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/4929ffb6d04ca7048716be72827383b20720f897",
    "artist_name": "Roxette",
    "image_url": "https://i.scdn.co/image/a767d4820859faa5a8e199499516dd03c34829b5",
    "artist_id": "2SHhfs4BiDxGQ3oxqf0UHY",
    "year": 1991
  },
  {
    "song_id": "4aocMNrPxcJ3OQChTDhVPj",
    "name": "The First Time",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/cbc7a0e820d247d750949974a4c5dade986863eb",
    "artist_name": "Surface",
    "image_url": "https://i.scdn.co/image/c844f930679c55b2ef2ac3059cfe8ae31f6f0bce",
    "artist_id": "7dXeJ8kAqTqtvNWQNV3sdU",
    "year": 1991
  },
  {
    "song_id": "2oTDOIAdsxPTE7yAp4YOcv",
    "name": "Jump Around",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/c262f1e5803a306d36187957613cd50ddc249e0f",
    "artist_name": "House Of Pain",
    "image_url": "https://i.scdn.co/image/443f7f3365178fb5a4050a516ab3221388daaf64",
    "artist_id": "0AuW7OCyKfFrsMbtHrYgIV",
    "year": 1992
  },
  {
    "song_id": "27AHAtAirQapVldIm4c9ZX",
    "name": "Jump",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/ce0334cf67c1634af5429037c5cccfa9b5a6c039",
    "artist_name": "Kris Kross",
    "image_url": "https://i.scdn.co/image/957d79d39c3b67d9f45eb7efa1757eacfc9283be",
    "artist_id": "2zrZfs23sjuHDv4E6YRmNf",
    "year": 1992
  },
  {
    "song_id": "0Yi128S6QgQDnAKg5A0OZw",
    "name": "To Be With You (Remastered LP Version)",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/7c2cb2fc8366516a9db4eb3c2ca07b9bec842ef4",
    "artist_name": "Mr. Big",
    "image_url": "https://i.scdn.co/image/fdfc20a5ac8e9c3cc9ec35096da9d59e8e182494",
    "artist_id": "5OfhOoKunSnuubxxRML8J3",
    "year": 1992
  },
  {
    "song_id": "2PdFSWL1Zpg0lRnujLnNJJ",
    "name": "Black or White - Remastered Version",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/4f0a3fddb87f023df3961830f3fec98f9ce8eb5b",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/b51d252d914b4e1becfe7ae425697f170e2f769a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1992
  },
  {
    "song_id": "1c48FSKfpobDF3VzopU10V",
    "name": "End Of The Road",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/c0958d703fd360ee3c514063cbd3e4da10679dc3",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/030ad8ee793d05227a3d58af683b31d65df98d28",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1992
  },
  {
    "song_id": "2WElktskrNJEwgpp5Vouxk",
    "name": "I'm Too Sexy",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/5f0a84aa760151ed0f22ecebdee7c3dd4c2c458f",
    "artist_name": "Right Said Fred",
    "image_url": "https://i.scdn.co/image/9ff19468417afcce31c82d5fba7487c5e938ed99",
    "artist_id": "15ajdFAi5bjj5pS9laBfBL",
    "year": 1992
  },
  {
    "song_id": "5AoBNJQDXXEnIJGjJjZu3y",
    "name": "Just Another Day",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/4b321d53a19ecc38d1fdbf932b68ff55da5e982e",
    "artist_name": "Jon Secada",
    "image_url": "https://i.scdn.co/image/4cbbe05807d70e8abefa8001b9fcb19488eb61ac",
    "artist_id": "10n1KB2sjTrGdyuC83y8jW",
    "year": 1992
  },
  {
    "song_id": "11C3oTzP4WJ2UlU3TzHtCK",
    "name": "November Rain",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/e3d778f509f104652b26d4eb7facadc24b7636b9",
    "artist_name": "Guns N' Roses",
    "image_url": "https://i.scdn.co/image/93ebbf2621c9264f2398bd6ea6f10be611196367",
    "artist_id": "3qm84nBOXUEQ2vnTfUTTFC",
    "year": 1992
  },
  {
    "song_id": "5dle7lDSqNS2AcFtbvgkud",
    "name": "Rhythm Is A Dancer - Original 12\"",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/46f96ed42fcec7e0837e713322671e5a5cca0c3b",
    "artist_name": "SNAP!",
    "image_url": "https://i.scdn.co/image/23167e35a02c8e232d32c016b87af82251bf8559",
    "artist_id": "2FrKQPjJe4pVMZOgm0ESOx",
    "year": 1992
  },
  {
    "song_id": "69uJi5QsBtqlYkGURTBli8",
    "name": "I Can't Make You Love Me",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/525083ad738ccb9519fca156b6957dd9b2266166",
    "artist_name": "Bonnie Raitt",
    "image_url": "https://i.scdn.co/image/680fb76654ebb4d1b3148df1741839957cd2634c",
    "artist_id": "4KDyYWR7IpxZ7xrdYbKrqY",
    "year": 1992
  },
  {
    "song_id": "0hKF8N8aflF1uDzEEnPr2j",
    "name": "Life Is A Highway",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/ddbff92cf51d41efacc57cd4613ce9b0411beb08",
    "artist_name": "Tom Cochrane",
    "image_url": "https://i.scdn.co/image/431a821eee4369b172dcf03d38b3c1b625163c17",
    "artist_id": "5Jj4mqGYiplyowPLKkJLHt",
    "year": 1992
  },
  {
    "song_id": "0ZEXLvA1oGRMZCbbfRNdky",
    "name": "Friday I'm In Love",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/ef4d1b7a56833c1e2af3e1dfdf1d9a1901042cfb",
    "artist_name": "The Cure",
    "image_url": "https://i.scdn.co/image/f0ea5da96856463367ac139fbb9e473d1614b6e2",
    "artist_id": "7bu3H8JO7d0UbMoVzbo70s",
    "year": 1992
  },
  {
    "song_id": "0RmEn2jcbyrLV4em3bnDZJ",
    "name": "Damn I Wish I Was Your Lover",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/cb9b9868d3a52f00b664a8b0b97019023a42ab63",
    "artist_name": "Sophie B. Hawkins",
    "image_url": "https://i.scdn.co/image/fbb9b04304f0ef2beede09fdeb48da1f34024aca",
    "artist_id": "3gdIwZY6Q3RXhDteYr4ZvC",
    "year": 1992
  },
  {
    "song_id": "2GHYJ0dhVds3PODKnTdiGE",
    "name": "All I Want",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/2ea5fbf046202bf29fa19e62de5f903d5ec7c440",
    "artist_name": "Toad The Wet Sprocket",
    "image_url": "https://i.scdn.co/image/597eeabf40c9289fa3ea43379e8be8c56f8e2e50",
    "artist_id": "4j7EVY3kuDwLPfD2jfC7LC",
    "year": 1992
  },
  {
    "song_id": "6aILdORvFPD0mO7y5q8O1t",
    "name": "Hazard",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/9eb88cf1ebf0479c73834e585fa8057eca028509",
    "artist_name": "Richard Marx",
    "image_url": "https://i.scdn.co/image/2938b360915e17922e349d6c67cdc217a67f7057",
    "artist_id": "0grdhNhiRLFBaFVyybqsj6",
    "year": 1992
  },
  {
    "song_id": "5kVSEpbyuLIVuPM1i2KivC",
    "name": "Ain't 2 Proud 2 Beg",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/6d31d7cbc4f3eafba377573b28b2be4ad2fc8551",
    "artist_name": "TLC",
    "image_url": "https://i.scdn.co/image/bc379e0ba1663b3d79263d9d808983590c978a4e",
    "artist_id": "0TImkz4nPqjegtVSMZnMRq",
    "year": 1992
  },
  {
    "song_id": "1bHwmGAPsDShzHi4b23TBY",
    "name": "Free Your Mind",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/3ed9ac6a604c9fb0139b196ef2b4760051da7f7e",
    "artist_name": "En Vogue",
    "image_url": "https://i.scdn.co/image/ea7fbbdaba1f8a5ddff981d847d79bad544e3195",
    "artist_id": "5fikk4h5qbEebqK2Fc6e48",
    "year": 1992
  },
  {
    "song_id": "5K8qwhQQSAj9ebIvlNJY8q",
    "name": "Tennessee - 2001 - Remaster",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/d0b1068754d0662cbd7c08ddaff62325c7afb073",
    "artist_name": "Arrested Development",
    "image_url": "https://i.scdn.co/image/345f9780825d6d80b5aea43e86e4b6d54f16af94",
    "artist_id": "5Va9LuEmaZxnbk1gMnjMD7",
    "year": 1992
  },
  {
    "song_id": "70aBlCLalffIq1c1cng6zO",
    "name": "Giving Him Something He Can Feel",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/d82483331ff9ea633e4ad97e614b2bf2ab8c757f",
    "artist_name": "En Vogue",
    "image_url": "https://i.scdn.co/image/ea7fbbdaba1f8a5ddff981d847d79bad544e3195",
    "artist_id": "5fikk4h5qbEebqK2Fc6e48",
    "year": 1992
  },
  {
    "song_id": "4TK1YzdHTJp3RKxJpllBa9",
    "name": "Set Adrift On Memory Bliss - Radio Edit",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/af015696b921c6ca14752cb258176e0add735ae1",
    "artist_name": "P.M. Dawn",
    "image_url": "https://i.scdn.co/image/6fbc0993ce85405b06410f4dc1a716f00208c168",
    "artist_id": "5DgjOwTN6o76J5Gf8MzEoL",
    "year": 1992
  },
  {
    "song_id": "0No5Hr1LxZjJHGsQSTy2bm",
    "name": "I Can't Dance - 2007 Remastered Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/746e48d6296de276b9fc0861cf46aadb6c27dd94",
    "artist_name": "Genesis",
    "image_url": "https://i.scdn.co/image/ea7a8016b2de2d8534af0df3d3775c9e2a6fb001",
    "artist_id": "3CkvROUTQ6nRi9yQOcsB50",
    "year": 1992
  },
  {
    "song_id": "2rQDtzREtofoyhDNYk95jf",
    "name": "They Want EFX",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/2884666a1fe3f4cd1162a4c17ae92516d11237a7",
    "artist_name": "Das EFX",
    "image_url": "https://i.scdn.co/image/97544624f892bc35dcfd6fc6590d25525f1bda1b",
    "artist_id": "0auu2itHTxEdAMRHvx7CyG",
    "year": 1992
  },
  {
    "song_id": "1zTuB57LYZa7xu7KUH8kF0",
    "name": "Baby-Baby-Baby",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/b5f9db498758e2a29fbdcf80cf618b5882ce5ebb",
    "artist_name": "TLC",
    "image_url": "https://i.scdn.co/image/bc379e0ba1663b3d79263d9d808983590c978a4e",
    "artist_id": "0TImkz4nPqjegtVSMZnMRq",
    "year": 1992
  },
  {
    "song_id": "6FyfOXMpEkiIV6cuVx5PgH",
    "name": "Smells Like Teen Spirit",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/e55d7e2f2c2c95989ef7ee6824c60d90cb128140",
    "artist_name": "Nirvana",
    "image_url": "https://i.scdn.co/image/5c2302c65fd86da654a91c139dddda7c1c722ab3",
    "artist_id": "6olE6TJLqED3rqDCT0FyPh",
    "year": 1992
  },
  {
    "song_id": "1yJWqYYDQge60nZKdLyiZU",
    "name": "When I Look into Your Eyes",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/8f7ec83fd75ea2c4b7adaea41407505dd5167cd8",
    "artist_name": "Firehouse",
    "image_url": "https://i.scdn.co/image/3f4219d91f50d72cce9f05454be9eb1194056a2d",
    "artist_id": "28pS8WVbFstY0o1SrqCf8I",
    "year": 1992
  },
  {
    "song_id": "63PIgPkDf3rswlU4ZWCYAM",
    "name": "What About Your Friends",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/701d14e73bf21639aacb538a2f038b4f72c08b2c",
    "artist_name": "TLC",
    "image_url": "https://i.scdn.co/image/bc379e0ba1663b3d79263d9d808983590c978a4e",
    "artist_id": "0TImkz4nPqjegtVSMZnMRq",
    "year": 1992
  },
  {
    "song_id": "4yRUjOBsj0cpjabtum0fML",
    "name": "Save The Best For Last",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/bb056a40dcaf9d5749ced788989b097fca10852e",
    "artist_name": "Vanessa Williams",
    "image_url": "https://i.scdn.co/image/514f606f96965acd83c4850b24f3e9b00aee6638",
    "artist_id": "75L9s8KVrhCNtBUkZFnDFW",
    "year": 1992
  },
  {
    "song_id": "0uqvSVhGgQTIdj9G51vhvv",
    "name": "Finally",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/7753f930f82418cf2ec1b1a19aa17b8a5b05ee7a",
    "artist_name": "CeCe Peniston",
    "image_url": "https://i.scdn.co/image/579c4e7a9462113cc5d05b332794935ef48eba6b",
    "artist_id": "5UoVLCWzOKMIJ9iioof9OD",
    "year": 1992
  },
  {
    "song_id": "5lpv6SbsynNEvUVpLW74t0",
    "name": "Diamonds And Pearls [Edit]",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/27f61d950bdecfc5ee52dac2c2a9623ba4fa4d34",
    "artist_name": "Prince & The New Power Generation",
    "image_url": "https://i.scdn.co/image/49db086c1d635a44cc1662c5ca9fe99a5ed3d1f7",
    "artist_id": "2T9xH19b4sNPqfwjPGzejt",
    "year": 1992
  },
  {
    "song_id": "5W9bQBePJYAJg6i9g75qHN",
    "name": "Beauty And The Beast",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/f82dadb18d84e1dd8923fa9b95ed2076df8cbc97",
    "artist_name": "Peabo Bryson",
    "image_url": "https://i.scdn.co/image/ef6b8f0cf134abae7c13396da525aec71e9b6947",
    "artist_id": "49iKbKGqgn8OESkW5WduX0",
    "year": 1992
  },
  {
    "song_id": "637V2ymBt4Ljlbz4EmRys8",
    "name": "This Used To Be My Playground",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/e1a59d3a87074be92b8da04bf3011c544ef387c7",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/666bff95fac1424ab1957aaecf1a82ede5616e41",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1992
  },
  {
    "song_id": "7kFAwFdZcvvqOggZ2dY5jg",
    "name": "I'd Die Without You",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/ff46fe4ddb7dabc029b4384a6622cec2419183d4",
    "artist_name": "P.M. Dawn",
    "image_url": "https://i.scdn.co/image/44ecf7b59dcfbe40dc385f607030604cbbc44bd9",
    "artist_id": "5DgjOwTN6o76J5Gf8MzEoL",
    "year": 1992
  },
  {
    "song_id": "2q6D2o8qRY5vcOC4jut0ku",
    "name": "All 4 Love",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/d52990a548e9b27d55cabe2e7b83f0e2261ce49d",
    "artist_name": "Color Me Badd",
    "image_url": "https://i.scdn.co/image/791925e776a618e374a70c88e4c7c9d19d393fd1",
    "artist_id": "1QtIfAa6y7w2JhxYJhYeUG",
    "year": 1992
  },
  {
    "song_id": "7zAgg3PU31pQAnzEY9dJPr",
    "name": "I'll Be There",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/654bab85eed49ab9598a1cadff0d81e802ecf001",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/c279ede2adc5a99fd06c96964e0fdd469561d3ac",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1992
  },
  {
    "song_id": "3xRbymJj98leHL7K8Y94WS",
    "name": "She's Playing Hard To Get",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/2d15b429d17b86173d6509838af2b43083f33322",
    "artist_name": "Hi-Five",
    "image_url": "https://i.scdn.co/image/0cd7ff9cc859ff202ed95892ed85747591aea1e5",
    "artist_id": "0EVUivUkugMtNF09L4QBMH",
    "year": 1992
  },
  {
    "song_id": "1O2ACd0zg4GYgQRhLBGYDV",
    "name": "Everything About You",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/e3f11b70dbed9cd7181c3bb91b932b04c4d9deaa",
    "artist_name": "Ugly Kid Joe",
    "image_url": "https://i.scdn.co/image/5dc47fe8af3253e3ea3c741f81f29cc8471ed084",
    "artist_id": "3XsgWn63EnA4wYZBjVyxjf",
    "year": 1992
  },
  {
    "song_id": "3E9wmxhFXGLFJJEooJR3Dn",
    "name": "The Best Things In Life Are Free - Classic Club Edit",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/fd5d0bc931d70a40098041d57eef5067e7816ad0",
    "artist_name": "Luther Vandross and Janet Jackson",
    "image_url": "https://i.scdn.co/image/dd983ce469baf2406722f99a8bc91d57e4382498",
    "artist_id": "7L35ced94MUndh8GRtehXF",
    "year": 1992
  },
  {
    "song_id": "6JvodgYcsthsORxPsEYUoe",
    "name": "People Everyday",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/790a6f0b13c7fda84b320a8bf9e151838b84932e",
    "artist_name": "Arrested Development",
    "image_url": "https://i.scdn.co/image/6ed09096a0f0f6984dad441c1290556917959d4e",
    "artist_id": "5Va9LuEmaZxnbk1gMnjMD7",
    "year": 1992
  },
  {
    "song_id": "46jqxETXUwgLRsoMGuwFLa",
    "name": "Addams Groove",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/c098196dc018a5658c28d71d7c7bff8211e29055",
    "artist_name": "MC Hammer",
    "image_url": "https://i.scdn.co/image/b4833d9b96890f6f7f7b71b9ddbc004ef6388b93",
    "artist_id": "2rblp9fJo16ZPTcKDtlmKW",
    "year": 1992
  },
  {
    "song_id": "21MLFi0ISlqDBp7DQHPi7p",
    "name": "Would I Lie To You?",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/3f7c6e210c75a9e217da0dade61f7ece2d1a1fc5",
    "artist_name": "Charles & Eddie",
    "image_url": "https://i.scdn.co/image/3d2fefc274ec94836016d92c7d82c80b76a97c0f",
    "artist_id": "5rIhaCHkbFVvLJpKHWwOJD",
    "year": 1992
  },
  {
    "song_id": "264JdkdtNRAGL4M4PnDYHC",
    "name": "It's So Hard To Say Goodbye To Yesterday",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/3a11131f6277a0dcdaeea8e9a4cd9c3b24a55448",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/4087e6a8c34321039140c1e13d69614bb1c8f200",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1992
  },
  {
    "song_id": "0PAGmTNpVASXq2tegMxSOs",
    "name": "Move This (Shake That Body)",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/5dbe33b4c1868cca4ddae5779d596085960df50b",
    "artist_name": "Technotronic",
    "image_url": "https://i.scdn.co/image/cc2ab7b15248cd70e1b6095f7838d6e4e44a0e9f",
    "artist_id": "2Cd98zHVdZeOCisc6Gi2sB",
    "year": 1992
  },
  {
    "song_id": "6v5O3M2svc11Z9TMr6yVxI",
    "name": "Remember the Time",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/be5c57f3d5698666efda946544483f05a066a2d6",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1992
  },
  {
    "song_id": "4ZnKSrukEcPuMxGrcZEEh1",
    "name": "2 Legit 2 Quit - feat. Saja",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/1b3a70d6acb5fbd275930b1d2f1157bff7b748d6",
    "artist_name": "MC Hammer",
    "image_url": "https://i.scdn.co/image/1ae2dfcaa824180ffe64614a06025087d0769c0d",
    "artist_id": "2rblp9fJo16ZPTcKDtlmKW",
    "year": 1992
  },
  {
    "song_id": "4Gv46S98aguDKuKTrbRBus",
    "name": "Warm It Up",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/b73e3221d55e7a91fee5654e5d300bd65fe70d32",
    "artist_name": "Kris Kross",
    "image_url": "https://i.scdn.co/image/957d79d39c3b67d9f45eb7efa1757eacfc9283be",
    "artist_id": "2zrZfs23sjuHDv4E6YRmNf",
    "year": 1992
  },
  {
    "song_id": "5FFD1qfYfW3b4zM2vYlMOx",
    "name": "Tell Me What You Want Me To Do",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/337aef8ec6c68b424252e5d087b9fae99b34751d",
    "artist_name": "Tevin Campbell",
    "image_url": "https://i.scdn.co/image/20a76532591fa120acf9a64c22506b70382a9f08",
    "artist_id": "5VfqJBmXcf6ZqXoGij5qTE",
    "year": 1992
  },
  {
    "song_id": "5miTDKGqE3V9iI1NFpNEtB",
    "name": "One",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/f9840ab49902555247f90d504f2e958600cb3fc1",
    "artist_name": "U2",
    "image_url": "https://i.scdn.co/image/2c4345489d284b8ed7e41138b81ed05ce5d21bda",
    "artist_id": "51Blml2LZPmy7TTiAg47vQ",
    "year": 1992
  },
  {
    "song_id": "00r8TWNSkGnMDLqpxd4wia",
    "name": "In The Closet - Single Version",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/c82cbdec65cf3f2fe88188869d497e25df3293e0",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1992
  },
  {
    "song_id": "3CUV8wsoTTKznGpEovrnZw",
    "name": "Sometimes Love Just Ain't Enough",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/e80384c9640d816dac94764f683d651356c07e88",
    "artist_name": "Patty Smyth",
    "image_url": "https://i.scdn.co/image/d062f820baa30d1b93acbc45b2d5b0d7e6beb787",
    "artist_id": "2dgfCEMSVETFp29mRpiFpz",
    "year": 1992
  },
  {
    "song_id": "6LmiFEbuHqw8xWJfVBEJyV",
    "name": "Masterpiece",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/833ea9863056b6b046f419b8d14ad7c8a4843d51",
    "artist_name": "Atlantic Starr",
    "image_url": "https://i.scdn.co/image/1652dc062bbd72fe377ff2072fd4327d5b3b206a",
    "artist_id": "2YdVmtVBpIrv0N6WiBzSqm",
    "year": 1992
  },
  {
    "song_id": "5ldINBZXmoh6qoAzwXl5wX",
    "name": "Uhh Ahh - Original Version",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/ea2fb6d1090b5411fab134935ce35a3e9572af68",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/030ad8ee793d05227a3d58af683b31d65df98d28",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1992
  },
  {
    "song_id": "5PuY53BL7j4zjTFPRRA2zL",
    "name": "Achy Breaky Heart",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/c240761b21c407a9fdbd7f3daeb53b07b8f89133",
    "artist_name": "Billy Ray Cyrus",
    "image_url": "https://i.scdn.co/image/d949ee87a444b5542d2952af3bd5e922b79a99e8",
    "artist_id": "60rpJ9SgigSd16DOAG7GSa",
    "year": 1992
  },
  {
    "song_id": "367dmPOxuMP8FsrZHenNnq",
    "name": "That's What Love Is For",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/664fdbb955839f1f8966700d386fdf7d665db77c",
    "artist_name": "Amy Grant",
    "image_url": "https://i.scdn.co/image/7ce5211fef7ac211d76626ed9fd290e79ba59a42",
    "artist_id": "72Nhcx7prNk2ZCxhx0Y5es",
    "year": 1992
  },
  {
    "song_id": "5BE3DOk0C26VCMxxT84bec",
    "name": "I Love Your Smile",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/02f4741d3b93c70f0ee08fb756dcf523e725d85e",
    "artist_name": "Shanice",
    "image_url": "https://i.scdn.co/image/fb49306a2f7b5d603361e1e45750711413d52484",
    "artist_id": "0Ttph0pOZiPNTD3y2wUUb6",
    "year": 1992
  },
  {
    "song_id": "5QXNPpRqJuDKTi297tfjh2",
    "name": "The One",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/0d1a113a80f4853dd44bf27ffd8b7f5d4a00b133",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/020bd22f85b638d30d785e1f913e97d64dfed7e1",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1992
  },
  {
    "song_id": "76crWZqaiFzlTPGzNtT4w1",
    "name": "I Will Remember You",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/3761fa895d7ba5a009ba5e46d48156d40d751460",
    "artist_name": "Amy Grant",
    "image_url": "https://i.scdn.co/image/7ce5211fef7ac211d76626ed9fd290e79ba59a42",
    "artist_id": "72Nhcx7prNk2ZCxhx0Y5es",
    "year": 1992
  },
  {
    "song_id": "4ScNzmyZZId9kGK9iXuqjn",
    "name": "Live And Learn",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/00676b5b443279889173118c3de4f5297cb6f9d5",
    "artist_name": "Joe Public",
    "image_url": "https://i.scdn.co/image/3e60de8a11e3e8941a68c5ba40a6a51592a87733",
    "artist_id": "3RnBzCfr8bQeLQQMGF6IeE",
    "year": 1992
  },
  {
    "song_id": "0s2AuUkqr6BN5GjLoTbjaw",
    "name": "Keep On Walkin'",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/e99bff0c06118bef7eb1653cd954c784563855f7",
    "artist_name": "CeCe Peniston",
    "image_url": "https://i.scdn.co/image/e4ec4a1f35ec3c36d8a68bfff3bb66ca01c94464",
    "artist_id": "5UoVLCWzOKMIJ9iioof9OD",
    "year": 1992
  },
  {
    "song_id": "7EE5va6hu88TSdtbXrEl0J",
    "name": "Wishing On a Star",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/4482c0a61627da49e61e1819f9fc69a67406b15f",
    "artist_name": "The Cover Girls",
    "image_url": "https://i.scdn.co/image/a4b7e08a1354c47a94951c9adea03e5597d76727",
    "artist_id": "6D01mATo26VaiiCfZzpWAm",
    "year": 1992
  },
  {
    "song_id": "4mWqDrhS85tk5MMV3eCQ9A",
    "name": "Mysterious Ways",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/a817572b1256d6eca4cbd1bf2e528e0879623827",
    "artist_name": "U2",
    "image_url": "https://i.scdn.co/image/31c5501555a758fed872dd78259baf6264dc3c54",
    "artist_id": "51Blml2LZPmy7TTiAg47vQ",
    "year": 1992
  },
  {
    "song_id": "1aHCn2d62m0Gghu0nLeaw3",
    "name": "Take This Heart",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/bed6d1b7721677f1fa6a6994e7259dc5ea6046e0",
    "artist_name": "Richard Marx",
    "image_url": "https://i.scdn.co/image/2938b360915e17922e349d6c67cdc217a67f7057",
    "artist_id": "0grdhNhiRLFBaFVyybqsj6",
    "year": 1992
  },
  {
    "song_id": "2BDRHFw5170t2kRHD3uEMU",
    "name": "I Wanna Love You",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/d8c3fb7ca3f44ac71cf5e233471cf547b8baac3f",
    "artist_name": "Jade",
    "image_url": "https://i.scdn.co/image/f6c4720e0414056c5e3f0dfd421fd017c791ec63",
    "artist_id": "7ypgj95aGInvJMJbMv78wB",
    "year": 1992
  },
  {
    "song_id": "2U25Ne3teRZLF1wsRnjcfW",
    "name": "Wildside",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/4a5a7c945f5f04231919d62970a2964606117a61",
    "artist_name": "Marky Mark And The Funky Bunch",
    "image_url": "https://i.scdn.co/image/b851a8583410f12e84f47f30b75241f6b88142c7",
    "artist_id": "4046bnFxFJdLEtG7F2qXaV",
    "year": 1992
  },
  {
    "song_id": "1uhsVQi7IADIxZXN1xJnk5",
    "name": "Blowing Kisses In The Wind",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/7d44b95aa51984103f6870cfd523ea167ef4d9bc",
    "artist_name": "Paula Abdul",
    "image_url": "https://i.scdn.co/image/c00eb4bfb1961942cf7c93ff6f1b74e6b9471109",
    "artist_id": "4PpmBoqphQusNFsxuVKb6j",
    "year": 1992
  },
  {
    "song_id": "223kkcMeZ8v5VwzJDkaPR0",
    "name": "Let's Get Rocked - Live",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/edd6961efa7ed00f844afb7b8d47a73185105135",
    "artist_name": "Def Leppard",
    "image_url": "https://i.scdn.co/image/cf8be16805a78c4d7cf8b3f71ea7bdef4a4b3d31",
    "artist_id": "6H1RjVyNruCmrBEWRbD0VZ",
    "year": 1992
  },
  {
    "song_id": "2GwDKcTJlvoRDaJXkA7ULJ",
    "name": "Good For Me",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/2e685352770140295e492cf95bdec3d6c7ae678a",
    "artist_name": "Amy Grant",
    "image_url": "https://i.scdn.co/image/7ce5211fef7ac211d76626ed9fd290e79ba59a42",
    "artist_id": "72Nhcx7prNk2ZCxhx0Y5es",
    "year": 1992
  },
  {
    "song_id": "3BiOCElA3ESRyMsefIgA0L",
    "name": "Back To The Hotel - Original",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/111c9aa9f54585181aabe218157e5b87a549f5d1",
    "artist_name": "N2DEEP",
    "image_url": "https://i.scdn.co/image/0cc0f3750447f927dce2e7ca8d4106c11b0b71b5",
    "artist_id": "7zgtJuRm0JcJpPQRjCgXT7",
    "year": 1992
  },
  {
    "song_id": "0GMFC1osUTm2qKycgHdwWD",
    "name": "Keep Coming Back",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/c31b57874288324f29908cc39bd4b7a97cf1dad5",
    "artist_name": "Richard Marx",
    "image_url": "https://i.scdn.co/image/2938b360915e17922e349d6c67cdc217a67f7057",
    "artist_id": "0grdhNhiRLFBaFVyybqsj6",
    "year": 1992
  },
  {
    "song_id": "3gSQhilNTA4DpPzD632CJF",
    "name": "Stay [ Feat. Rachel Whitbread & Laura Harding ]",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/1d73d0fafb3fa1d280e21ba3c87f15505387afdd",
    "artist_name": "Shakespeare Sister",
    "image_url": "https://i.scdn.co/image/3fb51784f7dc0248f0e9744ce11778243a716338",
    "artist_id": "4W7FC42zDwq6O2XWdayoAb",
    "year": 1992
  },
  {
    "song_id": "68Qv4S2uJ0nLvdtWPrlOVt",
    "name": "Thought I'd Died And Gone To Heaven",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/9a715053a7de92b4dfaf633c579fe02c874d594a",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/78f8d0610a34585f621232ba15265316024b28d0",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1992
  },
  {
    "song_id": "2jstyP5wqWuPfH8GCRUrwr",
    "name": "Do I Have To Say The Words?",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/5e30120d83fd7d68bb8f0808944c6b2d3cd61e0a",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/78f8d0610a34585f621232ba15265316024b28d0",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1992
  },
  {
    "song_id": "0zFNXLSVDKoHB6NV584U4l",
    "name": "No Son Of Mine - 2007 Remastered Version",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/a292a83c8c92274a0088e19d40bc59a50b5ca26d",
    "artist_name": "Genesis",
    "image_url": "https://i.scdn.co/image/8187e51eeb002a516491aec7e5e024fdd5d8d307",
    "artist_id": "3CkvROUTQ6nRi9yQOcsB50",
    "year": 1992
  },
  {
    "song_id": "4O8G0ANVI4ec7KKmcamrCM",
    "name": "Missing You Now",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/850838f264e1d5508a1c2a28e79eb6c3ddd86452",
    "artist_name": "Michael Bolton",
    "image_url": "https://i.scdn.co/image/f6755f2663cc76b19d98d24bc4e86e740983e60d",
    "artist_id": "6YHEMoNPbcheiWS2haGzkn",
    "year": 1992
  },
  {
    "song_id": "6l3FFojHwLpo4W2Vmpy0Ox",
    "name": "Real Love",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/88bd2f706de63164c13dd4abf32bdd66a043bd38",
    "artist_name": "Mary J. Blige",
    "image_url": "https://i.scdn.co/image/729f6d60941bb68153aff76b45735aea2c5cc78c",
    "artist_id": "1XkoF8ryArs86LZvFOkbyr",
    "year": 1992
  },
  {
    "song_id": "5OI4XbIHmAGDNnmP01sD82",
    "name": "Thinkin' Back",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/3e9ee107bbc7528c237533f71a73506942c77503",
    "artist_name": "Color Me Badd",
    "image_url": "https://i.scdn.co/image/5971458e7e4feea483048cdabfd0b93e5962fa39",
    "artist_id": "1QtIfAa6y7w2JhxYJhYeUG",
    "year": 1992
  },
  {
    "song_id": "1w837FKxs4YJJ2zp7W1pB6",
    "name": "Come & Talk To Me",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/7832475a710bc8245e1e454398579590b4c39aa6",
    "artist_name": "Jodeci",
    "image_url": "https://i.scdn.co/image/dc2fd70d4cbc9e31f265432f9e7aeec19c68862c",
    "artist_id": "1eNkUXHPaXyuyC8NAgzykK",
    "year": 1992
  },
  {
    "song_id": "5LLRxnEQAbnQNzLK0mAC2R",
    "name": "The Way I Feel About You",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/165ea3ec8f55848c01a3c69b5f2b10f8ff78d517",
    "artist_name": "Karyn White",
    "image_url": "https://i.scdn.co/image/292e15199f10bf77ab049a451993bac62acea93c",
    "artist_id": "5lJBrQQ88JjskJmJeVKX4F",
    "year": 1992
  },
  {
    "song_id": "6EpkaTVcNIXtP0yRJB4avh",
    "name": "Everything Changes",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/bf765afdce102a0c81b148dfb60aa709c42f9ef4",
    "artist_name": "Kathy Troccoli",
    "image_url": "https://i.scdn.co/image/940d2da355405bf3172c453dc83337509a951e80",
    "artist_id": "73CmW7Heo8CxnYXydAs6qc",
    "year": 1992
  },
  {
    "song_id": "7l2xCa0dkGV3eiERFEXupj",
    "name": "Don't Let the Sun Go Down On Me",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/469f12fa7d3b6e027f5fc57b13c09d8b3666fbe8",
    "artist_name": "George Michael",
    "image_url": "https://i.scdn.co/image/f9e5eb2ab0bec884158fde4bbe13270b69b46c86",
    "artist_id": "19ra5tSw0tWufvUp8GotLo",
    "year": 1992
  },
  {
    "song_id": "6ujN3aI6Rip9InBqJTUUK9",
    "name": "How Do You Talk To An Angel",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/aa4a347f588b241d9da2b2a5cb0474a29e955864",
    "artist_name": "Jamie Walters & The Heights",
    "image_url": "https://i.scdn.co/image/fd039116bd8c9c14056e4beecd678d07c07e9423",
    "artist_id": "12MasKVPdBEYOPCXzrlMii",
    "year": 1992
  },
  {
    "song_id": "1MyBPvZABdqqvCDYcS9TjF",
    "name": "Hold On My Heart - 2007 Remastered Version",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/cc893098f169644d63366a4eddcf07799c3d2d12",
    "artist_name": "Genesis",
    "image_url": "https://i.scdn.co/image/8187e51eeb002a516491aec7e5e024fdd5d8d307",
    "artist_id": "3CkvROUTQ6nRi9yQOcsB50",
    "year": 1992
  },
  {
    "song_id": "03GzYByXsXHcoEAxjsWv9G",
    "name": "Slow Motion",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/978b56e43b55acc0a46d98ff45ad9e4dbad1e252",
    "artist_name": "Color Me Badd",
    "image_url": "https://i.scdn.co/image/5971458e7e4feea483048cdabfd0b93e5962fa39",
    "artist_id": "1QtIfAa6y7w2JhxYJhYeUG",
    "year": 1992
  },
  {
    "song_id": "459Ld3Od21fp65HwcYNwuv",
    "name": "We Got A Love Thang - Silky 7\"",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/6233f2380beb44c3ed0bcc9971e0dd04c3a26ded",
    "artist_name": "Four Tops",
    "image_url": "https://i.scdn.co/image/406fcd2ab9a3fc1636cd7e34c83edec9cf2120da",
    "artist_id": "7fIvjotigTGWqjIz6EP1i4",
    "year": 1992
  },
  {
    "song_id": "66S14BkJDxgkYxLl5DCqOz",
    "name": "I'm Gonna Be (500 Miles)",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/db41e854a3551d9d415bd8eff467cc44c4d029b5",
    "artist_name": "The Proclaimers",
    "image_url": "https://i.scdn.co/image/3e2be694511b058cbdd7bd75334fcd1db17607a1",
    "artist_id": "1A92IAcd7A6npCA33oGM5i",
    "year": 1993
  },
  {
    "song_id": "4ePP9So5xRzspjLFVVbj90",
    "name": "Two Princes",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/4c8d40a911e19f24c30bf9edb2a94e792fe762b5",
    "artist_name": "Spin Doctors",
    "image_url": "https://i.scdn.co/image/119083bcbac38e68e9446c31694bdafe64979693",
    "artist_id": "2PSiyldxmJze7xiqbz658m",
    "year": 1993
  },
  {
    "song_id": "1SssFw74DdHVjRa6ADggdD",
    "name": "Sweat (A La La La La Long)",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/5d16ea6e218c983f39cc190b14c87b13ab0c55cd",
    "artist_name": "Inner Circle",
    "image_url": "https://i.scdn.co/image/8b1a0f0fc5ad052c82921e10cad0f2a150cbcb46",
    "artist_id": "5os0Ltvz8Q8BvXOPOd1frx",
    "year": 1993
  },
  {
    "song_id": "31er9IGsfFbwqy1pH4aiTP",
    "name": "I Have Nothing",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/6baabe3e8ea2ffb11340decbb8aadd600fa9ec13",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/7bcb439989b592287aeac1599aaa36be17672b73",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1993
  },
  {
    "song_id": "0jWgAnTrNZmOGmqgvHhZEm",
    "name": "What's Up?",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/3c131b85219f72ad8ec2adc7fb4586b7a8b61d6d",
    "artist_name": "4 Non Blondes",
    "image_url": "https://i.scdn.co/image/f1f92faede0e1122a0b1257f90e1fc3a9d4c97e2",
    "artist_id": "0Je74SitssvJg1w4Ra2EK7",
    "year": 1993
  },
  {
    "song_id": "7aR1QwbQa26sjDXiVMLik4",
    "name": "(I Can't Help) Falling in Love With You",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/ebf1ab19a093a782219853e480370af4f1e871cb",
    "artist_name": "UB40",
    "image_url": "https://i.scdn.co/image/58a1a0ced12c24d0c4636b31744ab6024dcf8394",
    "artist_id": "69MEO1AADKg1IZrq2XLzo5",
    "year": 1993
  },
  {
    "song_id": "29rQJydAlO0uMyWvRIZxQg",
    "name": "That's The Way Love Goes",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/35d9b89ff09ea1d2e53efec7b7e5cb5af6f98b0f",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/28a2abc053e4b7a0d8434d09265a892930ebdd76",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1993
  },
  {
    "song_id": "4t0UsYzmmmZRMTWn77jiGF",
    "name": "Show Me Love",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/8bc8eb6f375c0592fd86c177a7954efc0677207a",
    "artist_name": "Robin S",
    "image_url": "https://i.scdn.co/image/e6e3675bd1e6d334768c454f0cf3efd3911e75bb",
    "artist_id": "2WvLeseDGPX1slhmxI59G3",
    "year": 1993
  },
  {
    "song_id": "2LjiPAQOVazT8sRyXL3XRs",
    "name": "Informer",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/7da66f75a44b368755b9e9d4f70b306516f8c8cb",
    "artist_name": "Snow",
    "image_url": "https://i.scdn.co/image/d89cfd14c9b227c19561be2653c2a2c9efbff19f",
    "artist_id": "3uZFBSsMiooimnprFL9jD1",
    "year": 1993
  },
  {
    "song_id": "0yfNXxlyXdmP0ue1iJijx1",
    "name": "Come Undone",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/01345032e63326f9d2377cfa79bdb5837a3a1d39",
    "artist_name": "Duran Duran",
    "image_url": "https://i.scdn.co/image/302845d6d3a5b8764f8c19d1c229b93a4fd2ab3a",
    "artist_id": "0lZoBs4Pzo7R89JM9lxwoT",
    "year": 1993
  },
  {
    "song_id": "0wokCRaKD0zPNhMRXAgVsr",
    "name": "Ordinary World",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/4e8e7bca2769185228d33cf166b29954df9fd2be",
    "artist_name": "Duran Duran",
    "image_url": "https://i.scdn.co/image/302845d6d3a5b8764f8c19d1c229b93a4fd2ab3a",
    "artist_id": "0lZoBs4Pzo7R89JM9lxwoT",
    "year": 1993
  },
  {
    "song_id": "1cJssTcMltUxhvUZLpn3Ci",
    "name": "I Will Always Love You",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/1f524b77610a01769cb7a8f4b0022cea624b2e39",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/1a45a2b6266e337b71d72d120800184ab2138657",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1993
  },
  {
    "song_id": "5dle7lDSqNS2AcFtbvgkud",
    "name": "Rhythm Is A Dancer - Original 12\"",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/46f96ed42fcec7e0837e713322671e5a5cca0c3b",
    "artist_name": "SNAP!",
    "image_url": "https://i.scdn.co/image/23167e35a02c8e232d32c016b87af82251bf8559",
    "artist_id": "2FrKQPjJe4pVMZOgm0ESOx",
    "year": 1993
  },
  {
    "song_id": "1ZoHRnS5U4m7V5pgvFiK1N",
    "name": "Hey Jealousy",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/24b8fc29272b8abb60a4318df4207035ed00ab62",
    "artist_name": "Gin Blossoms",
    "image_url": "https://i.scdn.co/image/eb06f675be8a1686ecc82c416ae4f931c8852a1a",
    "artist_id": "6kXp61QMZFPcKMcRPqoiVj",
    "year": 1993
  },
  {
    "song_id": "3fwUpUntnrvggfHhvyQq6D",
    "name": "Hip Hop Hooray",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/7c75fa3e4666e5f91d06fd7ef66679da209ced5e",
    "artist_name": "Naughty By Nature",
    "image_url": "https://i.scdn.co/image/7a75270c4e66ab4439c7bee07fc6fb199177121b",
    "artist_id": "4Otx4bRLSfpah5kX8hdgDC",
    "year": 1993
  },
  {
    "song_id": "5CdJveJgiGXoGwDFqF6afp",
    "name": "Freak Me",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/581b83ed6a28a90481124050203274779ef7f533",
    "artist_name": "Silk",
    "image_url": "https://i.scdn.co/image/20d41254d9a872266aa30cd5c44262b7dfd72052",
    "artist_id": "3wVllS5itPK6rpHwfvNce4",
    "year": 1993
  },
  {
    "song_id": "1jCsCYgzQQHk3bDJDuFbNi",
    "name": "If I Ever Fall In Love",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/4a827e923c52065b676a0f3a8ac4ab35ab971afb",
    "artist_name": "Shai",
    "image_url": "https://i.scdn.co/image/e63bf353ba70cf5cd2789b3b44ad75b8443be56d",
    "artist_id": "72y3ZI95ctkQC2O4mjBaU3",
    "year": 1993
  },
  {
    "song_id": "2LP2NrdZlsabtHT0GUCwUa",
    "name": "Knockin' Da' Boots",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/1a15554c5dac5f066cc6a627d4ff52d8de64a0bf",
    "artist_name": "H-Town",
    "image_url": "https://i.scdn.co/image/0b4d82bc60091d311e53612617bbbd912f98e78f",
    "artist_id": "21gGD9dUby5ls0belA1wqZ",
    "year": 1993
  },
  {
    "song_id": "1CShgAvkBtZUy8YO1cNIuC",
    "name": "Cryin'",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/2d6d442a59d960ee076ac5738bb067507cf41cf4",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/3208f60d9b33a29d4ffa63618b3771a018015f85",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1993
  },
  {
    "song_id": "1d2O0FAl7OrNNu7Ed3bGrK",
    "name": "What Is Love - Radio Edit",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/3a803ce4e9f4fd8937cc239f63c7f4871bb325be",
    "artist_name": "Haddaway",
    "image_url": "https://i.scdn.co/image/85349efd2ca1f7cc202e6d8d4a49b587135a42f4",
    "artist_id": "0Suv0tRrNrUlRzAy8aXjma",
    "year": 1993
  },
  {
    "song_id": "7tsRp7QKUBp6hc9bth0h7x",
    "name": "Don't Walk Away",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/c6d2be54477bfd7dd666fecc1cc6029dd25bf427",
    "artist_name": "Jade",
    "image_url": "https://i.scdn.co/image/f6c4720e0414056c5e3f0dfd421fd017c791ec63",
    "artist_id": "7ypgj95aGInvJMJbMv78wB",
    "year": 1993
  },
  {
    "song_id": "0t414ewhjcIiuPVF3QECz2",
    "name": "If",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/71b4b2bba310337e7bc3285160c2d261c8336b73",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/28a2abc053e4b7a0d8434d09265a892930ebdd76",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1993
  },
  {
    "song_id": "70iDnqYvxCPl3mPd3ddxgD",
    "name": "Another Sad Love Song",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/4272b2d54cec6a7dd8d0d28a59364b0bfcd332a4",
    "artist_name": "Toni Braxton",
    "image_url": "https://i.scdn.co/image/167024af691eabd9cecc022ba460e4dbc3ea1309",
    "artist_id": "3X458ddYA2YcVWuVIGGOYe",
    "year": 1993
  },
  {
    "song_id": "0IaMMHVbpJ0LrRAeigWOXr",
    "name": "Again",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/747ef2afe4be240c51a14d6c5ff6419458439431",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/28a2abc053e4b7a0d8434d09265a892930ebdd76",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1993
  },
  {
    "song_id": "2mu2oL9gdgHGtlg9poer2u",
    "name": "Rebirth of Slick (Cool Like Dat) - 2005 Digital Remaster",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/beb907abb339ab35e14677836ce9c3d99500a44a",
    "artist_name": "Digable Planets",
    "image_url": "https://i.scdn.co/image/c0e546aa8290f32ab1c029fda597340cabfa1d25",
    "artist_id": "0gqIrDRL7CEPBWMmkuZPdQ",
    "year": 1993
  },
  {
    "song_id": "6juIzbXd7ERNLhYCcOpi7u",
    "name": "Check Yo Self",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/02dc97a8c6f26007fbf3c46ad2db6f360c1fde5c",
    "artist_name": "Ice Cube",
    "image_url": "https://i.scdn.co/image/0477a78fbbb6623f43dffcd56eb12d7a5562bec1",
    "artist_id": "3Mcii5XWf6E0lrY3Uky4cA",
    "year": 1993
  },
  {
    "song_id": "6LSTAQ6r7OxsOOlIIYY47Q",
    "name": "Mr Wendal - 2002 - Remaster",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/4c45cd594ff0fb08493634bd50d55d05221ff204",
    "artist_name": "Arrested Development",
    "image_url": "https://i.scdn.co/image/345f9780825d6d80b5aea43e86e4b6d54f16af94",
    "artist_id": "5Va9LuEmaZxnbk1gMnjMD7",
    "year": 1993
  },
  {
    "song_id": "51RvCRzeTqoQgdOBgcpHtT",
    "name": "Just Kickin' It",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/7d9759d85f2e907b07a0356734994dddc437646c",
    "artist_name": "Xscape",
    "image_url": "https://i.scdn.co/image/b1edf6d454c958f30d81a020406b0b8aaad0c54f",
    "artist_id": "5z2cHsOmmmWcAPNG1oEhw0",
    "year": 1993
  },
  {
    "song_id": "7fLkHVJAJLENmSvhrEHZxV",
    "name": "A Whole New World (Aladdin's Theme)",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/54fcb8aa645146f3d7f4a458e6dfeaf5598300ad",
    "artist_name": "Peabo Bryson",
    "image_url": "https://i.scdn.co/image/82c5e85948dc7cc5cf8966fb6de7ad3194b98e72",
    "artist_id": "49iKbKGqgn8OESkW5WduX0",
    "year": 1993
  },
  {
    "song_id": "6mIY6O7uNGgVqOoX70UAYh",
    "name": "Have I Told You Lately - 2008 Remastered",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/8e5564c3d516c535608a059ae3e955be11095063",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/5597129da19aa0863623aa41826f0edc4488a4c4",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1993
  },
  {
    "song_id": "3l9OCOG3Vt6c2lxeMyGSIY",
    "name": "One Last Cry",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/85ec0877f9442c591acd8b2d6aaa5b7b7e29aa55",
    "artist_name": "Brian McKnight",
    "image_url": "https://i.scdn.co/image/a210141fc65f1ee0b950c3329a8372de6d3db6c5",
    "artist_id": "6k0IBR0lU42s2GYpNX7kA9",
    "year": 1993
  },
  {
    "song_id": "5xka4Z5fOoXt0v9Qxpzh6t",
    "name": "Hey Mr. D.J. - Pronounced Jah-Nay Album Version",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/b8ab058ef9669336305148fc6c29e9afce9520cc",
    "artist_name": "Zhané",
    "image_url": "https://i.scdn.co/image/e852ef840fdf6ddb5dc20ea18ccc8ef7b65d61a7",
    "artist_id": "6cjSmkVvMvyE6tCAo1M9Is",
    "year": 1993
  },
  {
    "song_id": "74kHlIr01X459gqsSdNilW",
    "name": "I Get Around",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/08d5bfff0e208cf4d79355eb96dea6919703a0a7",
    "artist_name": "2Pac",
    "image_url": "https://i.scdn.co/image/e5f9416ad5a6d2a5aa04c4b52f228066b9188bc0",
    "artist_id": "1ZwdS5xdxEREPySFridCfh",
    "year": 1993
  },
  {
    "song_id": "63PIgPkDf3rswlU4ZWCYAM",
    "name": "What About Your Friends",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/701d14e73bf21639aacb538a2f038b4f72c08b2c",
    "artist_name": "TLC",
    "image_url": "https://i.scdn.co/image/bc379e0ba1663b3d79263d9d808983590c978a4e",
    "artist_id": "0TImkz4nPqjegtVSMZnMRq",
    "year": 1993
  },
  {
    "song_id": "2vgCM9swUTBS73jS5FmNQE",
    "name": "Right Here - Human Nature Radio Mix",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/b301e63571e56f6f18aaf257ee477ffd04954f22",
    "artist_name": "SWV",
    "image_url": "https://i.scdn.co/image/3b8f18adf20955c6d62a1d51bc0564d51beac9e7",
    "artist_id": "2NmK5FyrQ18HOPXq1UBzqa",
    "year": 1993
  },
  {
    "song_id": "36O7qmyKLHpUY6WdWh8M5g",
    "name": "Will You Be There - Single Version",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/11d6259949ec088c4368b0acdd02d64ba6a291cb",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1993
  },
  {
    "song_id": "6DiPkm7C3LD9XsJWTGPFgv",
    "name": "Three Little Pigs",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/8f754723bd07faa8472e8a39beb565344e7d7920",
    "artist_name": "Green Jelly",
    "image_url": "https://i.scdn.co/image/49320a22ce2a99f2f9ce542a46525f3304fd64e4",
    "artist_id": "6dyxkRwYuTw6ezj1tjC2rY",
    "year": 1993
  },
  {
    "song_id": "2MEcbc3bTXn4U2LcVttooC",
    "name": "If I Ever Lose My Faith In You",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/7cce8c33cbdc132ce1fb22efefdcca06ac1ba20e",
    "artist_name": "Sting",
    "image_url": "https://i.scdn.co/image/8fc44fccbd1c5e9d91273a2bb87e8579c3152e4f",
    "artist_id": "0Ty63ceoRnnJKVEYP0VQpk",
    "year": 1993
  },
  {
    "song_id": "7B46V9G3rBQryzZVtAaWAR",
    "name": "Dreamlover",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/8f5797008dec9144a5482248ceebffa487e79842",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/c279ede2adc5a99fd06c96964e0fdd469561d3ac",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1993
  },
  {
    "song_id": "1PYjOMp27slDQczgZRTwmt",
    "name": "Forever in Love",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/5f1986fa0ed8efa31dc1f7d3bfbeb3f0929e3feb",
    "artist_name": "Kenny G",
    "image_url": "https://i.scdn.co/image/372adcd06c820b48cb5d9b87b7c9382252d8e9ad",
    "artist_id": "6I3M904Y9IwgDjrQ9pANiB",
    "year": 1993
  },
  {
    "song_id": "6jwlBPt6FlhIlHAdEN1JME",
    "name": "More And More - Original Single Version",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/ce84e6dbb86554c7a94e519d3199acce79c53b4c",
    "artist_name": "Captain Hollywood Project",
    "image_url": "https://i.scdn.co/image/af97c7940808e791c6ed81462c42b98efab3135e",
    "artist_id": "3El2sJgqTWkg1kkyHHAEu4",
    "year": 1993
  },
  {
    "song_id": "7kFAwFdZcvvqOggZ2dY5jg",
    "name": "I'd Die Without You",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/ff46fe4ddb7dabc029b4384a6622cec2419183d4",
    "artist_name": "P.M. Dawn",
    "image_url": "https://i.scdn.co/image/44ecf7b59dcfbe40dc385f607030604cbbc44bd9",
    "artist_id": "5DgjOwTN6o76J5Gf8MzEoL",
    "year": 1993
  },
  {
    "song_id": "6l2haiCg21JT5KS1j0gUoH",
    "name": "Boom! Shake the Room",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/b20d5cde4bdde763dbe8f1177956b0b55cc1ec85",
    "artist_name": "DJ Jazzy Jeff & The Fresh Prince",
    "image_url": "https://i.scdn.co/image/d5d3de56ff78e5efc99f34a0a0801881b1fb4ccc",
    "artist_id": "1mG23iQeR29Ojhq89D5gbh",
    "year": 1993
  },
  {
    "song_id": "4rcK1t8KTbetfO3n8CDyyW",
    "name": "I'm Every Woman (Album Version)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/d6d5f344f2d2bafefafa902e891e3a514ac66268",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/9db2c56439a04b5175fa2b39e595593a24ef0204",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1993
  },
  {
    "song_id": "6vHJpgX1QTYa9SVXankGVR",
    "name": "Comforter",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/526be6566d6fbc1bec12dc9e669bf4c300e4db35",
    "artist_name": "Shai",
    "image_url": "https://i.scdn.co/image/e63bf353ba70cf5cd2789b3b44ad75b8443be56d",
    "artist_id": "72y3ZI95ctkQC2O4mjBaU3",
    "year": 1993
  },
  {
    "song_id": "3b3PnE1ROIZzILjmCGjVib",
    "name": "Rain",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/d82a97bede7ae73b9a6e396f4ad5056218c3951f",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/29fb00f06d7bd481352203cdbdc9ae0c274f1ab5",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1993
  },
  {
    "song_id": "543d68hZ4dzVePFfdVc0Ki",
    "name": "Angel",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/ec42af760a51d235ab5add25ec067dc86d1dce8b",
    "artist_name": "Jon Secada",
    "image_url": "https://i.scdn.co/image/4cbbe05807d70e8abefa8001b9fcb19488eb61ac",
    "artist_id": "10n1KB2sjTrGdyuC83y8jW",
    "year": 1993
  },
  {
    "song_id": "61EQfJXizp4MIpLYWyLoQI",
    "name": "When She Cries",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/82e7fef45168d128590794410b4588b97576fe8a",
    "artist_name": "Restless Heart",
    "image_url": "https://i.scdn.co/image/9589ba01e4797b8d98859e79e7f70147f83e86d9",
    "artist_id": "0FcmRWJUfnCuwoXE1H2b0H",
    "year": 1993
  },
  {
    "song_id": "16dbGMVeJg15mLFktHX5LJ",
    "name": "In The Still Of The Nite (I'll Remember)",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/11c855cf286f7c6f715d894f3b3da27cff3a0311",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/030ad8ee793d05227a3d58af683b31d65df98d28",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1993
  },
  {
    "song_id": "1ndqYQyhyv0kT13ZhSkoh1",
    "name": "The River of Dreams",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/9b418f7b7b79e0f88ce6fa84d396dea237ff4197",
    "artist_name": "Billy Joel",
    "image_url": "https://i.scdn.co/image/dc82b8076e92803f3ff7b469015f2b4540478d5c",
    "artist_id": "6zFYqv1mOsgBRQbae3JJ9e",
    "year": 1993
  },
  {
    "song_id": "5jnf5Lv2TJX9uYHsFKfrEh",
    "name": "Baby I'm Yours",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/7b7b757a4ca90348ef0a30b6c22985038f74a429",
    "artist_name": "Shai",
    "image_url": "https://i.scdn.co/image/03bbbdc82723e7dba18bfbc7bd35fc16d5a4b44a",
    "artist_id": "72y3ZI95ctkQC2O4mjBaU3",
    "year": 1993
  },
  {
    "song_id": "4M21CAQGZgBlSwen5VPHa8",
    "name": "I Don't Wanna Fight - Single Edit",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/a48eebdfb82b3f3d2c44371b10aea47efa25bdfa",
    "artist_name": "Tina Turner",
    "image_url": "https://i.scdn.co/image/a906ec1ce04456b99cb23a2344e1f392ebf635a1",
    "artist_id": "1zuJe6b1roixEKMOtyrEak",
    "year": 1993
  },
  {
    "song_id": "6qhl8yRJtpqlJD10VPOQtd",
    "name": "Bed Of Roses",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/f62528f321a12c7e95046fcadb64b06cef0a57fa",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/7fb09dbf5d9c68b70ba288611fbaa532622e2c38",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1993
  },
  {
    "song_id": "6KU8g98m1tcrUXRNjPQeOI",
    "name": "Deeper And Deeper",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/0dd109a45ec1062c0f72765e902e1d10b6ff6760",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/29fb00f06d7bd481352203cdbdc9ae0c274f1ab5",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1993
  },
  {
    "song_id": "0m9ul5FMXzR2k2BRZKamcn",
    "name": "It Was A Good Day",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/78dff5b5a13a02e381d0985a4184131f471ea96c",
    "artist_name": "Ice Cube",
    "image_url": "https://i.scdn.co/image/638150567f878f6830db79dc009539bcee523ea5",
    "artist_id": "3Mcii5XWf6E0lrY3Uky4cA",
    "year": 1993
  },
  {
    "song_id": "44qy6ywloZIkMPOMOZzm05",
    "name": "I'll Never Get Over You Getting Over Me - 7\" Version",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/6b0561f139f0a4f09571c2a418b313e82f30eb55",
    "artist_name": "Exposé",
    "image_url": "https://i.scdn.co/image/8d9d2d252b25ae7bbd4214b42ef511cf32fddbe8",
    "artist_id": "5nPz5R1FDPTMI3Dw4JnTGb",
    "year": 1993
  },
  {
    "song_id": "1Z0QdPM5YcMIAwhVD6wBBM",
    "name": "Anniversary",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/b2ea60eebb2f85f6b61b7fdc11041b1c49b8bfdb",
    "artist_name": "Tony! Toni! Toné!",
    "image_url": "https://i.scdn.co/image/860517f1e146a41d72d44148ffe0c66f8b17677a",
    "artist_id": "7vWlb4pM85jCHvV771qZZW",
    "year": 1993
  },
  {
    "song_id": "5hIljtQ1fRvDRc1aHdhwWK",
    "name": "Lately",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/ecc069a1ce5cd4391f4be7c5b68e432686b7299a",
    "artist_name": "Jodeci",
    "image_url": "https://i.scdn.co/image/b9207434c2f5c132bd75200a61cc51aac725cd15",
    "artist_id": "1eNkUXHPaXyuyC8NAgzykK",
    "year": 1993
  },
  {
    "song_id": "1XRWHVXbXpqRWYpHOaNsQr",
    "name": "Rump Shaker - Radio Mix",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/7babc6f0a76a0abb596b1241d51e1f2496dd966d",
    "artist_name": "Wreckx-N-Effect",
    "image_url": "https://i.scdn.co/image/d3e8674661ecbf0a5749bb0820db4a75b97e0483",
    "artist_id": "6lLYZf9GpSq6Pf09g2N2xK",
    "year": 1993
  },
  {
    "song_id": "7ntO2GqG4NcID6snH3sZNw",
    "name": "7",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/92acd0d249b33dfb7043893e7b073594f3702a01",
    "artist_name": "Prince & The New Power Generation",
    "image_url": "https://i.scdn.co/image/43343cc02a8688fa8d034e011fc50122ab54b79f",
    "artist_id": "2T9xH19b4sNPqfwjPGzejt",
    "year": 1993
  },
  {
    "song_id": "6pVU9bk1Tvbqlq719vJooz",
    "name": "Saving Forever For You",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/4ccbfb155f58753a05fd2d1f2da8a3bce9c5b7bc",
    "artist_name": "Shanice",
    "image_url": "https://i.scdn.co/image/4a1fbc6eb5177a200519df062a8b621e536f616b",
    "artist_id": "0Ttph0pOZiPNTD3y2wUUb6",
    "year": 1993
  },
  {
    "song_id": "2SfBzxh1MQSYKQeuhCzHub",
    "name": "Looking Through Patient Eyes",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/0c0db7351eec19f820f99eae52b59375a2bdff6b",
    "artist_name": "P.M. Dawn",
    "image_url": "https://i.scdn.co/image/6fbc0993ce85405b06410f4dc1a716f00208c168",
    "artist_id": "5DgjOwTN6o76J5Gf8MzEoL",
    "year": 1993
  },
  {
    "song_id": "3u5e3ovyvQLoauwUzWezvX",
    "name": "Do You Believe In Us",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/845d0a6f2083caabe82fcb37b2f74dc2be6f37ad",
    "artist_name": "Jon Secada",
    "image_url": "https://i.scdn.co/image/4cbbe05807d70e8abefa8001b9fcb19488eb61ac",
    "artist_id": "10n1KB2sjTrGdyuC83y8jW",
    "year": 1993
  },
  {
    "song_id": "4gvqVvTyYC6iaS51Ux3LIe",
    "name": "Fields Of Gold",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/b711afe9ef95ab71754690ee5dcfae932109752c",
    "artist_name": "Sting",
    "image_url": "https://i.scdn.co/image/92ccc381e02fbe3387d072b0a3830341c554794f",
    "artist_id": "0Ty63ceoRnnJKVEYP0VQpk",
    "year": 1993
  },
  {
    "song_id": "5rIr1MOh4cWwpdln1QqTPS",
    "name": "Here We Go Again! - Radio Edit",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/9c79176c39458e747c360aade300896412ba14d4",
    "artist_name": "Portrait",
    "image_url": "https://i.scdn.co/image/6246ba7c52b15d7525eb2bb80554fd8a1b7419db",
    "artist_id": "08fM8wx6uuEwagFsUvQp35",
    "year": 1993
  },
  {
    "song_id": "0ly3gd4q8s8xZlTpVXANKs",
    "name": "Two Steps Behind - Live",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/06749b72548578417d382992e5c77290c260f0c7",
    "artist_name": "Def Leppard",
    "image_url": "https://i.scdn.co/image/cf8be16805a78c4d7cf8b3f71ea7bdef4a4b3d31",
    "artist_id": "6H1RjVyNruCmrBEWRbD0VZ",
    "year": 1993
  },
  {
    "song_id": "7z8NZEvwZU6PzYAzkw3DWE",
    "name": "Love Is",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/4ca91cdef344180d2ebd8d8e2705ca1b69059f4a",
    "artist_name": "Vanessa Williams And Brian McKnight",
    "image_url": "https://i.scdn.co/image/4a1fbc6eb5177a200519df062a8b621e536f616b",
    "artist_id": "3N57Gx6Q8V0qxpK4n8aAFt",
    "year": 1993
  },
  {
    "song_id": "3uGjLZ5abCyCfYXBVfpf8X",
    "name": "Alright",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/9ab5080e1d33ea410e736e1cfc68f1534ee274c3",
    "artist_name": "Kriss Kross",
    "image_url": "https://i.scdn.co/image/773c71240a6c360bf0da89ad9094c63d2f1fc4c9",
    "artist_id": "0Ny6N3az94RDhyTUNTY2iV",
    "year": 1993
  },
  {
    "song_id": "6dCWWQ7qK2EE1lL6ySxFba",
    "name": "Reason To Believe",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/5b8188870c652c67081cf555f273c771046d9591",
    "artist_name": "Rod Stewart",
    "image_url": "https://i.scdn.co/image/fd310676e325e00ad2947d042cad58804a083b60",
    "artist_id": "2y8Jo9CKhJvtfeKOsYzRdT",
    "year": 1993
  },
  {
    "song_id": "3hAbXQFmubmbwyXTxYalpT",
    "name": "Bad Boys",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/743548db8577b7db401406fa4c2260703ea1c15b",
    "artist_name": "Inner Circle",
    "image_url": "https://i.scdn.co/image/2c1bbe02e1d419f09ccf407f65cf1f6977eb7a36",
    "artist_id": "5os0Ltvz8Q8BvXOPOd1frx",
    "year": 1993
  },
  {
    "song_id": "0BdAjgQqEb12CyH19xBU2N",
    "name": "Give It Up, Turn It Loose",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/c58706e2b92a226856af1d9b04e8ab153e89354c",
    "artist_name": "En Vogue",
    "image_url": "https://i.scdn.co/image/ea7fbbdaba1f8a5ddff981d847d79bad544e3195",
    "artist_id": "5fikk4h5qbEebqK2Fc6e48",
    "year": 1993
  },
  {
    "song_id": "4gTvgAPqPDj2fL5B7EpLC0",
    "name": "Faithful",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/21fd7af3a7b1fb069c6596f03fb7e8d8a9a5584b",
    "artist_name": "Go West",
    "image_url": "https://i.scdn.co/image/f312502594eec25ba01970050524dcc6d6b61489",
    "artist_id": "7bKupnlF7XOfR1En3K8oAL",
    "year": 1993
  },
  {
    "song_id": "6X3J3uYxbkPwxCneLkPVd7",
    "name": "Break It Down Again",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/c9e8da288378829052aaacc1ae43455055728a2a",
    "artist_name": "Tears For Fears",
    "image_url": "https://i.scdn.co/image/752a08f143d7b1838c99f67d5c771cd8e13d7533",
    "artist_id": "4bthk9UfsYUYdcFyqxmSUU",
    "year": 1993
  },
  {
    "song_id": "0EnFaSWSkj45lktHxHtcXb",
    "name": "One Woman",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/d831c6c73521e0795df909f8a3e0fac23036bbf5",
    "artist_name": "Jade",
    "image_url": "https://i.scdn.co/image/f6c4720e0414056c5e3f0dfd421fd017c791ec63",
    "artist_id": "7ypgj95aGInvJMJbMv78wB",
    "year": 1993
  },
  {
    "song_id": "57YCtunW2zzriKuLysMe9e",
    "name": "Cats In The Cradle",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/ef09637c42e082d5d24307e5afeb1ae7476fbe38",
    "artist_name": "Ugly Kid Joe",
    "image_url": "https://i.scdn.co/image/5dc47fe8af3253e3ea3c741f81f29cc8471ed084",
    "artist_id": "3XsgWn63EnA4wYZBjVyxjf",
    "year": 1993
  },
  {
    "song_id": "7J61aFBXNNtgWKZ3WV7rLf",
    "name": "I Got a Man",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/a5bb53575cce6c0d9db9714d526f52bb109b58e5",
    "artist_name": "Positive K",
    "image_url": "https://i.scdn.co/image/15cd8393d1af31513aa16441fecc1a6401039356",
    "artist_id": "5NtJpNZgRqBvRCq6OE08ZU",
    "year": 1993
  },
  {
    "song_id": "6WTwlLmhaq935X1jCq19wT",
    "name": "The Right Kind Of Love",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/d3b831da9334c3ab9b804c3711bf2fc2e17f1637",
    "artist_name": "Jeremy Jordan",
    "image_url": "https://i.scdn.co/image/4a1fbc6eb5177a200519df062a8b621e536f616b",
    "artist_id": "1eAsLHhN9eQnxU446lhCkR",
    "year": 1993
  },
  {
    "song_id": "391CwgcBxvUHmEKda2b5In",
    "name": "I'd Do Anything For Love (But I Won't Do That) - Single Edit",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/a6fcc3eec0a551894ae5c14ebdecaf99a39711ce",
    "artist_name": "Meat Loaf",
    "image_url": "https://i.scdn.co/image/f48e191e8f711a7e030001cb5928e8e3e7ddc16f",
    "artist_id": "7dnB1wSxbYa8CejeVg98hz",
    "year": 1993
  },
  {
    "song_id": "6l3FFojHwLpo4W2Vmpy0Ox",
    "name": "Real Love",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/88bd2f706de63164c13dd4abf32bdd66a043bd38",
    "artist_name": "Mary J. Blige",
    "image_url": "https://i.scdn.co/image/729f6d60941bb68153aff76b45735aea2c5cc78c",
    "artist_id": "1XkoF8ryArs86LZvFOkbyr",
    "year": 1993
  },
  {
    "song_id": "3g2gE5LtNn4P4kPeZVxQW8",
    "name": "Insane in the Brain",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/6193096490bd6f8314d57965bc0d4485f7c572d8",
    "artist_name": "Cypress Hill",
    "image_url": "https://i.scdn.co/image/58b19b5e1d50602d4daf07fc2b2d3af93cd62823",
    "artist_id": "4P0dddbxPil35MNN9G2MEX",
    "year": 1993
  },
  {
    "song_id": "6ujN3aI6Rip9InBqJTUUK9",
    "name": "How Do You Talk To An Angel",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/aa4a347f588b241d9da2b2a5cb0474a29e955864",
    "artist_name": "Jamie Walters & The Heights",
    "image_url": "https://i.scdn.co/image/fd039116bd8c9c14056e4beecd678d07c07e9423",
    "artist_id": "12MasKVPdBEYOPCXzrlMii",
    "year": 1993
  },
  {
    "song_id": "7gxUDSHHsu3q69qb4SQzVN",
    "name": "Weak",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/ec9d1c4fae17e31c428509924009a006224efed7",
    "artist_name": "SWV",
    "image_url": "https://i.scdn.co/image/3b8f18adf20955c6d62a1d51bc0564d51beac9e7",
    "artist_id": "2NmK5FyrQ18HOPXq1UBzqa",
    "year": 1993
  },
  {
    "song_id": "5O6rmxKClCySXM6VM5w754",
    "name": "Good Enough - Single Edit",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/5a63f778e772cef463ba56287086d485c7fe9be9",
    "artist_name": "Bobby Brown",
    "image_url": "https://i.scdn.co/image/78bc49a95a48c8c3275cb6aa4cea49bd518ce963",
    "artist_id": "62sPt3fswraiEPnKQpAbdE",
    "year": 1993
  },
  {
    "song_id": "4On14uJ6lcGIFEcQfZDldy",
    "name": "I'm so Into You",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/6057a22da934704e953627d845fa9a85d48d0b45",
    "artist_name": "SWV",
    "image_url": "https://i.scdn.co/image/3b8f18adf20955c6d62a1d51bc0564d51beac9e7",
    "artist_id": "2NmK5FyrQ18HOPXq1UBzqa",
    "year": 1993
  },
  {
    "song_id": "6z3lO80S46Qba0hdH185NF",
    "name": "Dazzey Duks (Made Famous by Duice)",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/106b7329d4dad8ce85786406629cb9c052aadec0",
    "artist_name": "Dazzey Duks",
    "image_url": "https://i.scdn.co/image/488d33dc73b664f4c0bfa0d5edb10186738458d8",
    "artist_id": "71FSCmqqxPGNxPKUQhqRbn",
    "year": 1993
  },
  {
    "song_id": "5Eh3XUgiRyOqtV2oVVT3Hl",
    "name": "F**k Wit Dre Day (Guide Vocal) - Originally performed by Dr. Dre",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/4b5b7371ae5bd8623f6f97e4d1bc206b2b7efa92",
    "artist_name": "Karaoke Beatbox Gang",
    "image_url": "https://i.scdn.co/image/0b0480b3e5a5f303a7493f82d395d917b7d0be9b",
    "artist_id": "5rWLJlOsDrHMUV99zWRdUB",
    "year": 1993
  },
  {
    "song_id": "0WKYRFtH6KKbaNWjsxqm70",
    "name": "Gin And Juice",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/74fcffc278690ac23efd87a674e61ab9a02b01d0",
    "artist_name": "Snoop Dogg",
    "image_url": "https://i.scdn.co/image/45580553bcd3a4f0201cab8dbacce16fe612085e",
    "artist_id": "7hJcb9fa4alzcOq3EaNPoG",
    "year": 1994
  },
  {
    "song_id": "2OeSwSbwlmn9u8SsutfxPO",
    "name": "Bump n' Grind",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/efdbe13d132556b2e8eb9fff451b8253be5667f7",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/37145297a059445de8ce1cdd846132d8fb3f7803",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 1994
  },
  {
    "song_id": "3DYVWvPh3kGwPasp7yjahc",
    "name": "The Sign",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/c2a09aa3e2408f13c0192f960e235de8e2a80cba",
    "artist_name": "Ace of Base",
    "image_url": "https://i.scdn.co/image/704ab1797786e1c0c5e94efc4e8c645cc93dfb4f",
    "artist_id": "5ksRONqssB7BR161NTtJAm",
    "year": 1994
  },
  {
    "song_id": "5XhkV07Vou38wnrzwURUOC",
    "name": "Who Am I (What's My Name)?",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/3786bde31fae90dfc69ccd5ff7c1b00f717959bf",
    "artist_name": "Snoop Dogg",
    "image_url": "https://i.scdn.co/image/45580553bcd3a4f0201cab8dbacce16fe612085e",
    "artist_id": "7hJcb9fa4alzcOq3EaNPoG",
    "year": 1994
  },
  {
    "song_id": "5eYwDBLucWfWI5KsV7oYX2",
    "name": "Mary Jane's Last Dance",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/0856c91b7088f4800345f5ee4959af351d3c1ef1",
    "artist_name": "Tom Petty And The Heartbreakers",
    "image_url": "https://i.scdn.co/image/98f6f5e34b9630e40e38dc8e8480227afb2e89d1",
    "artist_id": "4tX2TplrkIP4v05BNC903e",
    "year": 1994
  },
  {
    "song_id": "4nuPrKithHbRJXuA9spwu4",
    "name": "December, 1963 [Oh What A Night!] (45 Version)",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/6b62f2b15811bf2626ca1eda2728b20efc720ac1",
    "artist_name": "Frankie Valli & The Four Seasons",
    "image_url": "https://i.scdn.co/image/78afbfe74228a2f535b9fcfc7aef46c6bbef307b",
    "artist_id": "6mcrZQmgzFGRWf7C0SObou",
    "year": 1994
  },
  {
    "song_id": "2UKkLqST2xWpK8n1b0Xr5Z",
    "name": "Return To Innocence",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/098dca2f85610714c8d577165602622c3781b3bd",
    "artist_name": "Enigma",
    "image_url": "https://i.scdn.co/image/a0d1dd9d8a455817b0c84085a3bb3ad8a7d00d75",
    "artist_id": "3DmG65yHQsMms7WAvrZOdt",
    "year": 1994
  },
  {
    "song_id": "3V0PeMg2mhbYRtk9bioAwF",
    "name": "I Swear",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/45ad47d5cd85b7447c608b22eecfa53e4f40c459",
    "artist_name": "All-4-One",
    "image_url": "https://i.scdn.co/image/8c49a75ec96e83a6b2fda97bc6474e75bac56fbe",
    "artist_id": "1B8ySGDAiXTCvnJNH4HSCG",
    "year": 1994
  },
  {
    "song_id": "45wneA2j0Z0WMnYNDO6Ad0",
    "name": "Back & Forth",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/48ca16a8c05dbfcbe3e765355bebc576fe513b7b",
    "artist_name": "Aaliyah",
    "image_url": "https://i.scdn.co/image/eefe47920414815f13362e1bbacfce1920112b4d",
    "artist_id": "0urTpYCsixqZwgNTkPJOJ4",
    "year": 1994
  },
  {
    "song_id": "1Rvl8qsKJurfFTyWLBI9ib",
    "name": "Baby, I Love Your Way",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/9ec91059568d6ec315b9607a78b5bc4d2ef7a33a",
    "artist_name": "Big Mountain",
    "image_url": "https://i.scdn.co/image/8d3eff0862d15b7c802e29ed865f8bb2ab651519",
    "artist_id": "0Jeckitay8SbvKwqAzWuYH",
    "year": 1994
  },
  {
    "song_id": "4lRrk574qIHpoEEKC1zuam",
    "name": "At Your Best (You Are Love)",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/093758b7a7d73d137e8d074df98705488e0db2a0",
    "artist_name": "Aaliyah",
    "image_url": "https://i.scdn.co/image/eefe47920414815f13362e1bbacfce1920112b4d",
    "artist_id": "0urTpYCsixqZwgNTkPJOJ4",
    "year": 1994
  },
  {
    "song_id": "6N9uyMZf9pbNOuomveWscp",
    "name": "I Wanna Be Down",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/664babb41ef16f1b26161fc94db60c8cf89df4cc",
    "artist_name": "Brandy",
    "image_url": "https://i.scdn.co/image/7a991a7e23297e91efae3b3cce98ef2e680b612e",
    "artist_id": "05oH07COxkXKIMt6mIPRee",
    "year": 1994
  },
  {
    "song_id": "5vXEj2R1LZFG4D5U70jXnz",
    "name": "Can We Talk",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/7483141fd089d09a8d6453eb895788a9bd43e322",
    "artist_name": "Tevin Campbell",
    "image_url": "https://i.scdn.co/image/20a76532591fa120acf9a64c22506b70382a9f08",
    "artist_id": "5VfqJBmXcf6ZqXoGij5qTE",
    "year": 1994
  },
  {
    "song_id": "1CJbLAkUiPXrttszpQhnO6",
    "name": "Mr. Vain Recall - Radio Edit",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/78a7b8482eb913eb6b1252af60c5aa9dbda1e964",
    "artist_name": "Culture Beat",
    "image_url": "https://i.scdn.co/image/d9d57e4153dfaff408e18f2eee65f05cd302e1ed",
    "artist_id": "0BZ3BHzfYwpd3k5TDnvAz8",
    "year": 1994
  },
  {
    "song_id": "0wfuwgtWKZYRYAONxXPjPO",
    "name": "Tootsee Roll",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/fedd6bf4453e018763277f9884bcf61cb3794e7a",
    "artist_name": "69 Boyz",
    "image_url": "https://i.scdn.co/image/0ebc88ab94b04aaeead38905ea0015cdb2c954e0",
    "artist_id": "7qeBsFLeNEuzrDhDMbnDwF",
    "year": 1994
  },
  {
    "song_id": "0jlgrHvssAloyAidZ1aJzs",
    "name": "Stay",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/a65d2c4f50241913559340ad85657a8b7013dbde",
    "artist_name": "Lisa Loeb & Nine Stories",
    "image_url": "https://i.scdn.co/image/3736ce4765e6db13339cae26f534864d98b40c78",
    "artist_id": "4a7CDXcRCXi4kp5z7SEXtg",
    "year": 1994
  },
  {
    "song_id": "2yOm4lN7aTygtXanJFNFWU",
    "name": "Any Time, Any Place",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/32b22a25b5300858825d8fb1aa88d641b32e83ec",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/28a2abc053e4b7a0d8434d09265a892930ebdd76",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1994
  },
  {
    "song_id": "4veE1jrMbVSy9FRCiYDGkD",
    "name": "I'm The Only One",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/1a836b1a38892ddfe83b3a37259c9b6e99605249",
    "artist_name": "Melissa Etheridge",
    "image_url": "https://i.scdn.co/image/fabf1fed7c9b36e62fe0c5915606209205641491",
    "artist_id": "01Ppu7N8uYJI8SAONo2YZA",
    "year": 1994
  },
  {
    "song_id": "0AWpG6NUEaRZvS3eMvXkBg",
    "name": "Don't Turn Around",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/888d84084803be67d72c7c7aca61d312b0be7aa0",
    "artist_name": "Ace of Base",
    "image_url": "https://i.scdn.co/image/4bc3fa03ea66c557fa889ee9684ddc8b2ed94a5e",
    "artist_id": "5ksRONqssB7BR161NTtJAm",
    "year": 1994
  },
  {
    "song_id": "3pEuO9J2MTEmec8kUfYYvl",
    "name": "Another Night",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/37d31184e36a8a376ee049315e3d59156af2a44f",
    "artist_name": "Real McCoy",
    "image_url": "https://i.scdn.co/image/a9edf5d547b52990cf1a36e45fb0b2324b4d690c",
    "artist_id": "2vRfKzjQYJQd67X8x49MOh",
    "year": 1994
  },
  {
    "song_id": "0piH1OeWGJSiN8FqMqj4vb",
    "name": "Your Body's Callin'",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/f9da5690e5937d50e12f3c70dc258eaa44ab1125",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/37145297a059445de8ce1cdd846132d8fb3f7803",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 1994
  },
  {
    "song_id": "0IaMMHVbpJ0LrRAeigWOXr",
    "name": "Again",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/747ef2afe4be240c51a14d6c5ff6419458439431",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/28a2abc053e4b7a0d8434d09265a892930ebdd76",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1994
  },
  {
    "song_id": "2V2pxcEfn5zs79sE7qcewA",
    "name": "Now And Forever",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/71d9d8fd03b322bc9986404efe68b53704f99806",
    "artist_name": "Richard Marx",
    "image_url": "https://i.scdn.co/image/1a66b83fbb7511eafd9af13d0efc828ad172a650",
    "artist_id": "0grdhNhiRLFBaFVyybqsj6",
    "year": 1994
  },
  {
    "song_id": "31v2AQlx4pDI7kmnLxBkem",
    "name": "Mmm Mmm Mmm Mmm",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/861e163e0e7b772727f681d31e19ee99daf3faf1",
    "artist_name": "Crash Test Dummies",
    "image_url": "https://i.scdn.co/image/a2604434e0f11bce6fa988b1899eea3dc450fbb3",
    "artist_id": "1YEGETLT2p8k97LIo3deHL",
    "year": 1994
  },
  {
    "song_id": "5kK1Iru9ogP3Iy1zsANU1n",
    "name": "The Power of Love",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/041239e2b4c8838c8bebb9acaf2dc3ae0efb6462",
    "artist_name": "Céline Dion",
    "image_url": "https://i.scdn.co/image/c661be77761ee058487f35c4c3c237036e8ae025",
    "artist_id": "4S9EykWXhStSc15wEx8QFK",
    "year": 1994
  },
  {
    "song_id": "7yQsd17sVkOLaDwa4tz9dT",
    "name": "Whatta Man",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/8a25e57a8cfc3153e229607ed53ac84bab476414",
    "artist_name": "Salt-N-Pepa",
    "image_url": "https://i.scdn.co/image/38be1c45a6f1ab6e716c05ffed6c8db1f978bd49",
    "artist_id": "7wqtxqI3eo7Gn1P7SpP6cQ",
    "year": 1994
  },
  {
    "song_id": "3MMgcPP6temZXIgMhQlyDU",
    "name": "When Can I See You",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/c24d8aca7e1d7bc8a48a26736aa98479c6ccb4be",
    "artist_name": "Babyface",
    "image_url": "https://i.scdn.co/image/acdde5fbc1da79e4a89196c8714f05d919f67e7d",
    "artist_id": "3aVoqlJOYx31lH1gibGDt3",
    "year": 1994
  },
  {
    "song_id": "4IquPezkhJHBDrFdH5AnVH",
    "name": "Shine [Radio Edit] (45 Version)",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/efe6f4b238fb07a6e8d3f7bc552c7d06684a3cca",
    "artist_name": "Collective Soul",
    "image_url": "https://i.scdn.co/image/52c6909f96a5647a43d42cb5d87a4e32ba68f1c0",
    "artist_id": "4e5V1Q2dKCzbLVMQ8qbTn6",
    "year": 1994
  },
  {
    "song_id": "4Tttv4p2xuAq1LpQ7LI95E",
    "name": "Keep Ya Head Up",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/8e7cecaec90fd5c1224ee1b148e99d1a068efcc4",
    "artist_name": "2Pac",
    "image_url": "https://i.scdn.co/image/e5f9416ad5a6d2a5aa04c4b52f228066b9188bc0",
    "artist_id": "1ZwdS5xdxEREPySFridCfh",
    "year": 1994
  },
  {
    "song_id": "51RvCRzeTqoQgdOBgcpHtT",
    "name": "Just Kickin' It",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/7d9759d85f2e907b07a0356734994dddc437646c",
    "artist_name": "Xscape",
    "image_url": "https://i.scdn.co/image/b1edf6d454c958f30d81a020406b0b8aaad0c54f",
    "artist_id": "5z2cHsOmmmWcAPNG1oEhw0",
    "year": 1994
  },
  {
    "song_id": "61sQYdFNS6sEBYCyr1q5gn",
    "name": "I'll Make Love To You",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/58a6e7977b5dfe03f8733b6ab9c64f75b5601b09",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/623b5ff427f2cd203d57ea5962a92c799f3a3b2f",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1994
  },
  {
    "song_id": "5h92ib6UGUe0BkUvNz8AtP",
    "name": "All For Love - The Three Musketeers/Soundtrack Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/d10f4d3533bb696ddfd0bbba4b2c35d4fc6cc5a5",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/0600158bbf6cf233cf0f60a5c27911ae91850f06",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1994
  },
  {
    "song_id": "2LQktBKYD12TpS38YOZJH4",
    "name": "Please Forgive Me",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/07b3158156e8318077228d42985a87d6cea8793c",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/0600158bbf6cf233cf0f60a5c27911ae91850f06",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1994
  },
  {
    "song_id": "3vQQ6sNWRLfWXrnL04bIe0",
    "name": "I'm Ready",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/7ab4112039b212ab50144a5f43c24a9a6a40288b",
    "artist_name": "Tevin Campbell",
    "image_url": "https://i.scdn.co/image/20a76532591fa120acf9a64c22506b70382a9f08",
    "artist_id": "5VfqJBmXcf6ZqXoGij5qTE",
    "year": 1994
  },
  {
    "song_id": "6WgBIPHwkQhCqMNnR5hQCN",
    "name": "Streets of Philadelphia",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/edbf4fbbaf46d2b19bf8ca63bfaf6f18bdaa2eba",
    "artist_name": "Bruce Springsteen",
    "image_url": "https://i.scdn.co/image/901bb4358b208e8098787a7f1746a9027dfb1b8b",
    "artist_id": "3eqjTLE0HfPfh78zjh6TqT",
    "year": 1994
  },
  {
    "song_id": "4yKbbM56fLCxwR5CTH6QkP",
    "name": "Understanding",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/485e4911fa2293fc5e714753502a07db4f9f67b2",
    "artist_name": "Xscape",
    "image_url": "https://i.scdn.co/image/8c488acfc9e7a42f089addbf1d8c0ce3c68b0102",
    "artist_id": "5z2cHsOmmmWcAPNG1oEhw0",
    "year": 1994
  },
  {
    "song_id": "29VrHhpC5AS9b7XJYDPhqb",
    "name": "Funkdafied",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/c1cc95b2ea2eb24a6a7bf33caabc734775eae594",
    "artist_name": "Da Brat",
    "image_url": "https://i.scdn.co/image/2a75eaf29118d52087849e65e7749ad6d07c6638",
    "artist_id": "2I1bnmb9VQEQGKHxvr0gSf",
    "year": 1994
  },
  {
    "song_id": "5QQCp4fQiU9I2FU9FvJVV2",
    "name": "Always",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/a305dd5400c2438342648e81bd12e0a077f99c80",
    "artist_name": "Erasure",
    "image_url": "https://i.scdn.co/image/5a73b5d6b8a87a8dd87037ccf071cd3a2404db3b",
    "artist_id": "0z5DFXmhT4ZNzWElsM7V89",
    "year": 1994
  },
  {
    "song_id": "0yv6BYFfviMImtUPwzEXQp",
    "name": "Secret",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/63f90c2c84b8a1080cc4dd76d9fa86de15973a1e",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1994
  },
  {
    "song_id": "3nOfSqMgLPVA95N85CO6u9",
    "name": "Dreams",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/be4b460f95f73e5b0dab11a7c8b8289dfcaaccc1",
    "artist_name": "Gabrielle",
    "image_url": "https://i.scdn.co/image/837402a12fd183b15eb513bebd00f7c6029d3fca",
    "artist_id": "7rftfGIYEeZ79sLb58ZBDi",
    "year": 1994
  },
  {
    "song_id": "00U0pedRUMEzREpyRqbVT6",
    "name": "I'll Remember - Theme From The Motion Picture \"With Honors\"",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/dcc2d0ce1360f4f9d8ca45a3d267d31c882cc8ea",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/666bff95fac1424ab1957aaecf1a82ede5616e41",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1994
  },
  {
    "song_id": "4ijyALTCWwDqWgfkfFrhtx",
    "name": "Linger",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/30bf381ac0f6202df0e75f664016dbce0da24845",
    "artist_name": "The Cranberries",
    "image_url": "https://i.scdn.co/image/2af47551d217dc645acf74a17e1f054ab73a01bd",
    "artist_id": "7t0rwkOPGlDPEhaOcVtOt9",
    "year": 1994
  },
  {
    "song_id": "1TWhpLaqdToKoHHpFnCxsd",
    "name": "I Miss You",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/861864c9bd8a36fd703c54809d0bed01c442fe36",
    "artist_name": "Aaron Hall",
    "image_url": "https://i.scdn.co/image/cfc2e0b0e4ef0e61746aab5e81e4389fedbf0b31",
    "artist_id": "772SIFJQiXTCfxncTK1UMn",
    "year": 1994
  },
  {
    "song_id": "2ImuQo1g14CTR9hZAZD3aQ",
    "name": "This D.J.",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/218bd3bbd966b2c7ed0e23d919e4dcee949749cd",
    "artist_name": "Warren G",
    "image_url": "https://i.scdn.co/image/d861f60cb03b458db61aa3fc20666696c7505bd9",
    "artist_id": "2B4ZHz4QDWJTXPFPgO5peE",
    "year": 1994
  },
  {
    "song_id": "2ydd6zSB14excgAawdjjoi",
    "name": "Never Keeping Secrets",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/3361b9ecf042a4e0217e54272bde180936e6b8ba",
    "artist_name": "Babyface",
    "image_url": "https://i.scdn.co/image/acdde5fbc1da79e4a89196c8714f05d919f67e7d",
    "artist_id": "3aVoqlJOYx31lH1gibGDt3",
    "year": 1994
  },
  {
    "song_id": "2CD8UvBi01dwnriCM8TivL",
    "name": "Always In My Heart",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/bf29d72267916758753d2eecd77b6b23a8dcf7ea",
    "artist_name": "Tevin Campbell",
    "image_url": "https://i.scdn.co/image/fbd75550579142a548716646a0577b435314aedb",
    "artist_id": "5VfqJBmXcf6ZqXoGij5qTE",
    "year": 1994
  },
  {
    "song_id": "0BQblMoALRAlYA7YykYGVl",
    "name": "What Is Love - Single Mix",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/159cd01d5eac15cacc8e9f13e6e6fd800b8c69ad",
    "artist_name": "Haddaway",
    "image_url": "https://i.scdn.co/image/ea0d48c959666ccb85f81396df16757580658744",
    "artist_id": "0Suv0tRrNrUlRzAy8aXjma",
    "year": 1994
  },
  {
    "song_id": "2iWXhMiXeaHxac5Wom16As",
    "name": "Amazing",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/afddfdea25da95e16f4fab7774c43c0c5487e52d",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/3208f60d9b33a29d4ffa63618b3771a018015f85",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1994
  },
  {
    "song_id": "5o8gWPZxr4GZFslU85YMmu",
    "name": "Beautiful In My Eyes",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/33d1617f9d79ce9e3b0327b5ea20838f58792a48",
    "artist_name": "Joshua Kadison",
    "image_url": "https://i.scdn.co/image/81df4f0df6fcd92bb22c920dd1243d83006458f1",
    "artist_id": "2VrP5BkyzDhY43dWMlaYjD",
    "year": 1994
  },
  {
    "song_id": "0oZiK5HCMPn241PbUCslkF",
    "name": "Bop Gun (One Nation) - Radio Edit",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/5e815c2a1b1430c71a81c0aebf2df2bef563ad41",
    "artist_name": "Ice Cube",
    "image_url": "https://i.scdn.co/image/5b9701e119ec408fb29b9976097f2dbfdd90ce79",
    "artist_id": "3Mcii5XWf6E0lrY3Uky4cA",
    "year": 1994
  },
  {
    "song_id": "6h6p4WuDlZVdBfmGqT0dcR",
    "name": "Loser",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/c9f43545c958890c0f9ba71a70df3649f72d6a1e",
    "artist_name": "Beck",
    "image_url": "https://i.scdn.co/image/8a6d5f0da212981ed53600ce6c954919ab3a85b1",
    "artist_id": "3vbKDsSS70ZX9D2OcvbZmS",
    "year": 1994
  },
  {
    "song_id": "5C1nbFH5yZl1jm7h7ScLBO",
    "name": "Always",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/2f0670818064e4d22c051b5c81b64f0534244fba",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/efff863dfbfe40cc97f25bbb51e837c36fe4510e",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1994
  },
  {
    "song_id": "4oGhmx44KuEslzELC1Qogw",
    "name": "Wild Night",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/f63a33b4aa48dacfbd41b91e5eb759b76542a726",
    "artist_name": "John Mellencamp",
    "image_url": "https://i.scdn.co/image/2252c94914f263d66846123192aae9ce9ade7082",
    "artist_id": "3lPQ2Fk5JOwGWAF3ORFCqH",
    "year": 1994
  },
  {
    "song_id": "7MSwmEXgsHzFPkCW1ZaPjo",
    "name": "Found Out About You",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/9bfb3b90e4c6e43089db00ca7eb7432f424d0fe4",
    "artist_name": "Gin Blossoms",
    "image_url": "https://i.scdn.co/image/fb4ad76300a43572b4c969d620b1b6736c73632e",
    "artist_id": "6kXp61QMZFPcKMcRPqoiVj",
    "year": 1994
  },
  {
    "song_id": "62utntNdsx80hraXA5ha0Q",
    "name": "Never Lie",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/06e30a37d439be851ad4ee612dff876d29c02839",
    "artist_name": "Immature",
    "image_url": "https://i.scdn.co/image/8698156e5a73bed2ecca5f19229373beebccf0bd",
    "artist_id": "4XDxFeclKBCFPb30yQlRUH",
    "year": 1994
  },
  {
    "song_id": "3ZbrIs9xvGTLtHDcOel91B",
    "name": "Anything",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/70dbe0aec90b9d58c0bfbfcc15712784687fd0a0",
    "artist_name": "SWV",
    "image_url": "https://i.scdn.co/image/087c6568fcf144c5dd74dbe6ef7d9c9d565ebd23",
    "artist_id": "2NmK5FyrQ18HOPXq1UBzqa",
    "year": 1994
  },
  {
    "song_id": "4VcV0t8VN6AZcWTznJOUUN",
    "name": "Stroke You Up",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/0e8b41ba742435002f59ed8b2f84a36684043056",
    "artist_name": "Changing Faces",
    "image_url": "https://i.scdn.co/image/3720c9b5c6cc3121685131963b68545608a06ff7",
    "artist_id": "3lJ6jvOiNY46L2juqXufpf",
    "year": 1994
  },
  {
    "song_id": "7mwZitiXFBqbWoa0C1wmRd",
    "name": "Crazy",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/eddfa224b54a256276a9bfb02170f76ca2ffe231",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/3208f60d9b33a29d4ffa63618b3771a018015f85",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1994
  },
  {
    "song_id": "7i5rgcLujd8GZo50Xhmesb",
    "name": "So Much In Love",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/4cbf5d46fdd10db146d83a4b555e1a4b10a963b8",
    "artist_name": "All-4-One",
    "image_url": "https://i.scdn.co/image/8c49a75ec96e83a6b2fda97bc6474e75bac56fbe",
    "artist_id": "1B8ySGDAiXTCvnJNH4HSCG",
    "year": 1994
  },
  {
    "song_id": "40kJxdeSmQieEgTgShXumm",
    "name": "Because Of Love",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/20ff5292b346582df58dc45a1c3a86c14c787199",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/28a2abc053e4b7a0d8434d09265a892930ebdd76",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1994
  },
  {
    "song_id": "0TxFZthE81cgf5zZvpnkPP",
    "name": "All I Wanna Do",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/881ddc53ee4af3e8ef3bba9b30758abbef5a89f1",
    "artist_name": "Sheryl Crow",
    "image_url": "https://i.scdn.co/image/116021f02d582e1b338c2e1583524b1ce5888eb3",
    "artist_id": "4TKTii6gnOnUXQHyuo9JaD",
    "year": 1994
  },
  {
    "song_id": "5G0es3LsTx5a3ZeGGVKUXY",
    "name": "Can You Feel The Love Tonight",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/f5b2a3a3d1d17b63df53bf6ec659da8638565791",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/020bd22f85b638d30d785e1f913e97d64dfed7e1",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1994
  },
  {
    "song_id": "2SPFC3GXog3hBb24Ww9sFV",
    "name": "Stay",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/e6aa979d4ad21c5fc5fcdf9add4840f1693d7c97",
    "artist_name": "Eternal",
    "image_url": "https://i.scdn.co/image/e10b1375db7d05c42861e875bb7ff11d793066f2",
    "artist_id": "7zYGAXxAaq15C9eM29M8Fj",
    "year": 1994
  },
  {
    "song_id": "4HkIzBgEyNr8MogcWVnbqJ",
    "name": "Here Comes the Hotstepper - Heartical Mix",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/2a590cd1a5b51892925775f73d5fa69e2194b096",
    "artist_name": "Ini Kamoze",
    "image_url": "https://i.scdn.co/image/e13ed0b372e28cafc23674029eac77595d0eb315",
    "artist_id": "1VJspRsoC6c0bvqhnSiFCs",
    "year": 1994
  },
  {
    "song_id": "2OGBDP8x4tAmljSGAQb8BG",
    "name": "Cantaloop (Flip Fantasia)",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/9544e99cfbacdfa9efe079c9ca673caa5b31d9ca",
    "artist_name": "Us3",
    "image_url": "https://i.scdn.co/image/b728114936cbf33daffbd87ebd44b6513763708e",
    "artist_id": "4LtIfuFeAamus9OOItuWDD",
    "year": 1994
  },
  {
    "song_id": "5NpchUK2r1fNGdf2rOY5ml",
    "name": "Flava In Ya Ear - Greatest Hits Version",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/420fe3e7fa1b82abec06a3e75d4ce007692daf87",
    "artist_name": "Craig Mack featuring The Notorious B.I.G. and Rampage",
    "image_url": "https://i.scdn.co/image/4f94ecd77ff7d25e942ea4cdabf81c291efb5715",
    "artist_id": "5OngafALvnUxFMAUKNYEVs",
    "year": 1994
  },
  {
    "song_id": "1UtxIyLkTt7iR5TcWLt8IT",
    "name": "Love Sneakin' Up On You",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/c2a94b5b28a265e245584764da766d257f476649",
    "artist_name": "Bonnie Raitt",
    "image_url": "https://i.scdn.co/image/7baff1229d2fa58f20d9254b555dbccf0351cbee",
    "artist_id": "4KDyYWR7IpxZ7xrdYbKrqY",
    "year": 1994
  },
  {
    "song_id": "27sYHmgfRvHFyQ5vrm5nQp",
    "name": "And Our Feelings",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/cf882f67a7dfa8f597f20851582373fdf3bcf968",
    "artist_name": "Babyface",
    "image_url": "https://i.scdn.co/image/92a102c0516ca2cae7516e2629adf9533331bb1b",
    "artist_id": "3aVoqlJOYx31lH1gibGDt3",
    "year": 1994
  },
  {
    "song_id": "5WcpqJMDqpIf7U3guPS9hR",
    "name": "Come To My Window - Single Edit",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/a1c3cf998711385096f6ed87649c85faadcd0751",
    "artist_name": "Melissa Etheridge",
    "image_url": "https://i.scdn.co/image/a7a8c1473f8de17bfe017e89be889a6d36fdbe73",
    "artist_id": "01Ppu7N8uYJI8SAONo2YZA",
    "year": 1994
  },
  {
    "song_id": "3p4qjN0XgvY9CV7vsH93pE",
    "name": "Endless Love",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/6baf841ee9106f57114c6ab279c35bcfa5180e08",
    "artist_name": "Luther Vandross",
    "image_url": "https://i.scdn.co/image/ad2d1c3c349979ba96669d3bf8c1b46e8a4f2421",
    "artist_id": "19y5MFBH7gohEdGwKM7QsP",
    "year": 1994
  },
  {
    "song_id": "2QtzFBlRh4V1jiL3RjbOqg",
    "name": "Regulate - Album Version (Edited)",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/f5f5cfaa870bdf54d3c104f34f9de1ad0074383b",
    "artist_name": "Warren G",
    "image_url": "https://i.scdn.co/image/3f063f9d9b2e667924aa8c65bd55252885a2430a",
    "artist_id": "2B4ZHz4QDWJTXPFPgO5peE",
    "year": 1994
  },
  {
    "song_id": "5cfjHBdMjf0x07VZD70MlE",
    "name": "U.N.I.T.Y. - Album Version (Edited)",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/dda765cd86997a79b9416c7fd2a77f2812a4b027",
    "artist_name": "Queen Latifah",
    "image_url": "https://i.scdn.co/image/a282fad986faad4b7b16f047e7fdbe92602e2df4",
    "artist_id": "5m7wCUhYhBh7A3A3YMxrbt",
    "year": 1994
  },
  {
    "song_id": "3ir0DLMVxshKd7KlVGUWuQ",
    "name": "Everyday",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/eacb1a976cba111decad7814c68d15b7cdc99387",
    "artist_name": "Phil Collins",
    "image_url": "https://i.scdn.co/image/743ccdea6f58df688c60410b264a48fcc8e506d7",
    "artist_id": "4lxfqrEsLX6N1N4OCSkILp",
    "year": 1994
  },
  {
    "song_id": "5Wk92Up9XYyRiCppqtLDqD",
    "name": "Cry For You",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/a0d9f4b097cd2463ead8685896a6559b6624aece",
    "artist_name": "Jodeci",
    "image_url": "https://i.scdn.co/image/5035272f9828da38bc6044582222d258ba8bbd7f",
    "artist_id": "1eNkUXHPaXyuyC8NAgzykK",
    "year": 1994
  },
  {
    "song_id": "391CwgcBxvUHmEKda2b5In",
    "name": "I'd Do Anything For Love (But I Won't Do That) - Single Edit",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/a6fcc3eec0a551894ae5c14ebdecaf99a39711ce",
    "artist_name": "Meat Loaf",
    "image_url": "https://i.scdn.co/image/f48e191e8f711a7e030001cb5928e8e3e7ddc16f",
    "artist_id": "7dnB1wSxbYa8CejeVg98hz",
    "year": 1994
  },
  {
    "song_id": "1yxGbG8BCdctZnMtAULXIg",
    "name": "Got Me Waiting - Remix",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/fd5c1c63670d5263c4d85f4f78f052abed882cc8",
    "artist_name": "Heavy D & The Boyz",
    "image_url": "https://i.scdn.co/image/8503be09f7429bb45e210faa50708b00153889b5",
    "artist_id": "4KHdmkq99PXA6QEJ2lKpA3",
    "year": 1994
  },
  {
    "song_id": "06igE8VkBF90AOa5XoAIrS",
    "name": "Back In The Day",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/d6b67024fe6809d6337ed4fa789146b6a2d8a155",
    "artist_name": "Ahmad Lewis",
    "image_url": "https://i.scdn.co/image/64f7a0303b9f8824effafe41566b7fd2c3bf80ce",
    "artist_id": "4X3nIh5dynzfAQmy1Z5hXI",
    "year": 1994
  },
  {
    "song_id": "7czpT3vsR3ONw7JFVXC5dD",
    "name": "Gangsta Lean - Radio Edit",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/b512ff54d433a8e5efd16ded5393e3ba96e3a809",
    "artist_name": "D.R.S.",
    "image_url": "https://i.scdn.co/image/377bde504f805f866f08f86dda00e50e1341be05",
    "artist_id": "1MOKcwn9U2PyjjxOFOtCTV",
    "year": 1994
  },
  {
    "song_id": "7kJUkIhw7crh4dNHbbUh8M",
    "name": "Because The Night [ Feat. Jo Francis ]",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/0a7c493fa57b0ee62df6ed990eb37daecff10c3c",
    "artist_name": "10,000 Maniacs",
    "image_url": "https://i.scdn.co/image/3fb51784f7dc0248f0e9744ce11778243a716338",
    "artist_id": "0MBIKH9DjtBkv8O3nS6szj",
    "year": 1994
  },
  {
    "song_id": "5NsFyPVJSHh1aQsHdoFN6N",
    "name": "If You Go",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/dbc731b87b9f612e9622e504dd225d53e37c998f",
    "artist_name": "Jon Secada",
    "image_url": "https://i.scdn.co/image/9e5f6f5a8d569d279b35758762ea41d1f299a60f",
    "artist_id": "10n1KB2sjTrGdyuC83y8jW",
    "year": 1994
  },
  {
    "song_id": "3G4AmR8pxRVXkMyAvcHCws",
    "name": "Breathe Again - Radio Edit",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/bcfa0ca5fb7121623cea17de7002d1b45a45930b",
    "artist_name": "Toni Braxton",
    "image_url": "https://i.scdn.co/image/0ea43823d42a2c075ad61985333802ff6800a0eb",
    "artist_id": "3X458ddYA2YcVWuVIGGOYe",
    "year": 1994
  },
  {
    "song_id": "0qy7yJoYzOVqUmMGm06V8r",
    "name": "Rock And Roll Dreams Come Through",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/e5c52cb59522c6141888d86455a9465ad27b9fe2",
    "artist_name": "Meat Loaf",
    "image_url": "https://i.scdn.co/image/46f6ab91f6858aae9099c729977e7b444f530340",
    "artist_id": "7dnB1wSxbYa8CejeVg98hz",
    "year": 1994
  },
  {
    "song_id": "66fYpWITdebA4QRyB0VUim",
    "name": "Prayer For The Dying",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/5075703219313ab800dcf7120b8e54d132974a7e",
    "artist_name": "Seal",
    "image_url": "https://i.scdn.co/image/a8966e239c09e630c32b0d2cad5d872d6a256725",
    "artist_id": "5GtMEZEeFFsuHY8ad4kOxv",
    "year": 1994
  },
  {
    "song_id": "3FynxqEL6cRa0qjEwkSbx9",
    "name": "Groove Thang",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/1563f704602bba813a08b8bcf40e7ff573d317c2",
    "artist_name": "Zhané",
    "image_url": "https://i.scdn.co/image/528e52cc438862f046ad67ea9215e7508e486b0f",
    "artist_id": "6cjSmkVvMvyE6tCAo1M9Is",
    "year": 1994
  },
  {
    "song_id": "4Cx3bvSoTRBu4CNw3yDBwP",
    "name": "You Mean The World To Me - Radio Edit",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/17233e19a6a6c7a2e944a2a715e3ab1805407f45",
    "artist_name": "Toni Braxton",
    "image_url": "https://i.scdn.co/image/0ea43823d42a2c075ad61985333802ff6800a0eb",
    "artist_id": "3X458ddYA2YcVWuVIGGOYe",
    "year": 1994
  },
  {
    "song_id": "2adVNXrXovy54XcsqT2XdD",
    "name": "Stay ( I Missed You )",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/3d0b9708ed0cf9393c4f451c134a465a51603584",
    "artist_name": "Party All Night",
    "image_url": "https://i.scdn.co/image/0a2826c59896d1373254732fa29c9d7d4755ee75",
    "artist_id": "0XecTKS50ioFDto75yY9TM",
    "year": 1994
  },
  {
    "song_id": "2g8HN35AnVGIk7B8yMucww",
    "name": "Big Poppa",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/10101eda13218f94fbcca0e9986fd42560b56fbe",
    "artist_name": "The Notorious B.I.G.",
    "image_url": "https://i.scdn.co/image/466e26ee134bd7bc4b454d6ada1f70eb6a71103b",
    "artist_id": "5me0Irg2ANcsgc93uaYrpb",
    "year": 1995
  },
  {
    "song_id": "6qspW4YKycviDFjHBOaqUY",
    "name": "Waterfalls",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/e4e924e5d46df431c9475aa7929b154446d0fc00",
    "artist_name": "TLC",
    "image_url": "https://i.scdn.co/image/64301eccca52fad26b9c416b756766df6c198f03",
    "artist_id": "0TImkz4nPqjegtVSMZnMRq",
    "year": 1995
  },
  {
    "song_id": "1aWV3uY3SIEZVbmv45oFWS",
    "name": "Creep",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/b19d7bcd599629cbe31ec1363bb167e80668a69e",
    "artist_name": "TLC",
    "image_url": "https://i.scdn.co/image/64301eccca52fad26b9c416b756766df6c198f03",
    "artist_id": "0TImkz4nPqjegtVSMZnMRq",
    "year": 1995
  },
  {
    "song_id": "6xkryXuiZU360Lngd4sx13",
    "name": "Fantasy",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/b1a12ae8b2d2cb39926494c5b8538ecba7898b7e",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/ac0afd64e904c7eff0895b49b23adfb886bf6484",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1995
  },
  {
    "song_id": "0Jg602cHeMCnPez9baacIe",
    "name": "Cotton Eye Joe - Original Version",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/b797d4c257106b09fdd7f52ac8b59bba05065258",
    "artist_name": "Rednex",
    "image_url": "https://i.scdn.co/image/942abbfa65e2c2e5e35b56ebb65ece7632c8e067",
    "artist_id": "22Zqu1yyebVnbve8FxbJ2g",
    "year": 1995
  },
  {
    "song_id": "3Nf8oGn1okobzjDcFCvT6n",
    "name": "I'll Stand By You",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/e44a8603ceeec5d5e8d00421d7b9a796332cbeef",
    "artist_name": "Pretenders",
    "image_url": "https://i.scdn.co/image/9c0454548520a4dea0d7fa2feafbab64c79f51b2",
    "artist_id": "0GByy3DcfbQwDvXGCWmzv9",
    "year": 1995
  },
  {
    "song_id": "6uQKuonTU8VKBz5SHZuQXD",
    "name": "This Is How We Do It",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/1d2a5023dd858e68a3445bfc5ece1a3db61848d7",
    "artist_name": "Montell Jordan",
    "image_url": "https://i.scdn.co/image/9dfa96fd43a9cd9fc67b6ce3957159cfd26640ec",
    "artist_id": "0iVrCROxeyon7MZUW3MfzT",
    "year": 1995
  },
  {
    "song_id": "6CU0zWIWVTEnmWpSjVHM93",
    "name": "You Gotta Be",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/7f01c19932cca51b782a81a8ac4a0331676c1124",
    "artist_name": "Des'ree",
    "image_url": "https://i.scdn.co/image/da78671b1403d295ccf8fa7ece54301b5d2388be",
    "artist_id": "73ZPfpfg1LBVvDEArK4l5B",
    "year": 1995
  },
  {
    "song_id": "7vfvZp6KZJpxiaW6rI9ieM",
    "name": "I Got 5 On It",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/916092616aebdd245d11cd553488f0f4de01e3fd",
    "artist_name": "Luniz",
    "image_url": "https://i.scdn.co/image/f5dabd40ec203ff6063344982fc2a979fa13b52f",
    "artist_id": "3z3g65U7mmyyBmmDfsQK9x",
    "year": 1995
  },
  {
    "song_id": "3L9RIFcNF9IDGPRE96EO7N",
    "name": "The Rhythm Of The Night",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/efa12f2db8e8c6371191d77593d69be5c7339173",
    "artist_name": "Corona",
    "image_url": "https://i.scdn.co/image/533ff946180b2dae0c9ab42c360134066dfe8d48",
    "artist_id": "26T6b8maqEVltcmE4kSDUl",
    "year": 1995
  },
  {
    "song_id": "7MooGz4ZPE4bNxjFegR6Jx",
    "name": "You Don't Know How It Feels",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/b5967fde6fb36eb1239611d777df1513cf4e46fc",
    "artist_name": "Tom Petty",
    "image_url": "https://i.scdn.co/image/c2f3bdadd474c24ce9fb0f19952b925a44bf98b5",
    "artist_id": "2UZMlIwnkgAEDBsw1Rejkn",
    "year": 1995
  },
  {
    "song_id": "3y8rEUEUdmAcPCpPoLotnO",
    "name": "Run-Around",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/f6342bbfdef329a47a939b800fd284e1e6b50792",
    "artist_name": "Blues Traveler",
    "image_url": "https://i.scdn.co/image/afeac998e8f16d2fc0c9970af7826de1e0fb5e72",
    "artist_id": "3pHeBYl1yujXcZqqfF1UyQ",
    "year": 1995
  },
  {
    "song_id": "0z5E34e7ZT3XKMYQNXh6tH",
    "name": "Kiss From A Rose",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/e92dbed031db0c53fbacae8a6c210aab50a30c69",
    "artist_name": "Seal",
    "image_url": "https://i.scdn.co/image/a8966e239c09e630c32b0d2cad5d872d6a256725",
    "artist_id": "5GtMEZEeFFsuHY8ad4kOxv",
    "year": 1995
  },
  {
    "song_id": "6Vas6h9lB5mmeVDXdFqlQA",
    "name": "Freak Like Me",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/be6d487f1fa43c1afa1fcbe657f35205ddabf2cc",
    "artist_name": "Adina Howard",
    "image_url": "https://i.scdn.co/image/0373d328b2dda469ff30fd89303b0b5304f48750",
    "artist_id": "266SmBZTt4zRzaKEANWbfQ",
    "year": 1995
  },
  {
    "song_id": "4wx38D1Y9vv9WG3T0J6lTA",
    "name": "December (45 Version)",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/1ea3f3ab984119af367554b944eb3a5dac6bbcbe",
    "artist_name": "Collective Soul",
    "image_url": "https://i.scdn.co/image/8a2ae1faba834578f3f16167723fd41f550c9fc0",
    "artist_id": "4e5V1Q2dKCzbLVMQ8qbTn6",
    "year": 1995
  },
  {
    "song_id": "6N9uyMZf9pbNOuomveWscp",
    "name": "I Wanna Be Down",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/664babb41ef16f1b26161fc94db60c8cf89df4cc",
    "artist_name": "Brandy",
    "image_url": "https://i.scdn.co/image/7a991a7e23297e91efae3b3cce98ef2e680b612e",
    "artist_id": "05oH07COxkXKIMt6mIPRee",
    "year": 1995
  },
  {
    "song_id": "6iXcPsToMyTheci6e1L2ya",
    "name": "Dear Mama",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/8cdecf87c4580fde3d83f817548c1b97b4f27e69",
    "artist_name": "2Pac",
    "image_url": "https://i.scdn.co/image/e5f9416ad5a6d2a5aa04c4b52f228066b9188bc0",
    "artist_id": "1ZwdS5xdxEREPySFridCfh",
    "year": 1995
  },
  {
    "song_id": "6dW75XbJ1WAMljOXWjTK46",
    "name": "Red Light Special",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/d54a26e558d676e9035baa4c7ee77a7f3673d9b6",
    "artist_name": "TLC",
    "image_url": "https://i.scdn.co/image/64301eccca52fad26b9c416b756766df6c198f03",
    "artist_id": "0TImkz4nPqjegtVSMZnMRq",
    "year": 1995
  },
  {
    "song_id": "2BIBtKMUnHyYs3BsEiILTU",
    "name": "Back for Good - Radio Mix",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/c31d3d6b39ea792f8bd70dff6676355cab0287bc",
    "artist_name": "Take That",
    "image_url": "https://i.scdn.co/image/c855f000184a3fc1b85a4306ed22d3fcf268343c",
    "artist_id": "1XgFuvRd7r5g0h844A5ZUQ",
    "year": 1995
  },
  {
    "song_id": "4OnZR9U5JRFGF1Yza9j579",
    "name": "I Can Love You Like That",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/2901134a2e8fd75e2faa929a9bb7a3a1479d2c1c",
    "artist_name": "All-4-One",
    "image_url": "https://i.scdn.co/image/8c49a75ec96e83a6b2fda97bc6474e75bac56fbe",
    "artist_id": "1B8ySGDAiXTCvnJNH4HSCG",
    "year": 1995
  },
  {
    "song_id": "3ldG6XCLDXxWg1N6XhrSKo",
    "name": "Shy Guy",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/a9cc47c76b68b6bf003a310de2be57fe319e4d9c",
    "artist_name": "Diana King",
    "image_url": "https://i.scdn.co/image/1511a893364d42663345f9238e0679e393f5af79",
    "artist_id": "221iMiF62DFPnVuCLJakP1",
    "year": 1995
  },
  {
    "song_id": "0wfuwgtWKZYRYAONxXPjPO",
    "name": "Tootsee Roll",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/fedd6bf4453e018763277f9884bcf61cb3794e7a",
    "artist_name": "69 Boyz",
    "image_url": "https://i.scdn.co/image/0ebc88ab94b04aaeead38905ea0015cdb2c954e0",
    "artist_id": "7qeBsFLeNEuzrDhDMbnDwF",
    "year": 1995
  },
  {
    "song_id": "43sNw9nvGYGLYnNiwI8oMZ",
    "name": "Freek'n You",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/038e2e7e1533e8feac912f00025a98dd6e7e0807",
    "artist_name": "Jodeci",
    "image_url": "https://i.scdn.co/image/1107434d8c7119b6193e5b30b54dd7b25154bec3",
    "artist_id": "1eNkUXHPaXyuyC8NAgzykK",
    "year": 1995
  },
  {
    "song_id": "2lp8xjq0WTm3HZKHuDEweg",
    "name": "Tell Me",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/c7fddf0004ecd94cbe1f19f3bb3d598f3fce7759",
    "artist_name": "Groove Theory",
    "image_url": "https://i.scdn.co/image/db29bf370b914a8256361d8a0db936847e3b1174",
    "artist_id": "7opRqRgF9lvnVraBFCMvIj",
    "year": 1995
  },
  {
    "song_id": "0JCAezTC5IlK1a9o0YBEsD",
    "name": "Don't Take It Personal (Just One of Dem Days)",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/f475d06afdf5ec33099418a788bb35b5fdd3fec8",
    "artist_name": "Monica",
    "image_url": "https://i.scdn.co/image/43e11b42861404a1cdfdd44eeca452a8559f18f5",
    "artist_id": "6nzxy2wXs6tLgzEtqOkEi2",
    "year": 1995
  },
  {
    "song_id": "0BTGqPIW9acmhhUmENkq5r",
    "name": "If You Love Me",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/1477bb48626c15d849985f59a806335fe9e03ad0",
    "artist_name": "Brownstone",
    "image_url": "https://i.scdn.co/image/94ebadca6c2a31d215ae54088e328fd2714d2d23",
    "artist_id": "6dQgkiJm1fRkcqKOvPzysr",
    "year": 1995
  },
  {
    "song_id": "4veE1jrMbVSy9FRCiYDGkD",
    "name": "I'm The Only One",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/1a836b1a38892ddfe83b3a37259c9b6e99605249",
    "artist_name": "Melissa Etheridge",
    "image_url": "https://i.scdn.co/image/fabf1fed7c9b36e62fe0c5915606209205641491",
    "artist_id": "01Ppu7N8uYJI8SAONo2YZA",
    "year": 1995
  },
  {
    "song_id": "5u3rsmRYRSKJpFHXPT4p07",
    "name": "Every Little Thing I Do",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/7f45f36ee5dbfacff46464d87f091b0443098894",
    "artist_name": "Soul For Real",
    "image_url": "https://i.scdn.co/image/976c2e58bf940880b7a083e3cd343068975e63b4",
    "artist_id": "296LotOmbj7eOX7r9l46fK",
    "year": 1995
  },
  {
    "song_id": "3LklW07tvdx2AHsgfi1Mng",
    "name": "I Wish",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/cb62789a7ab4310b0a1ef49c4c24f2b76d81de63",
    "artist_name": "Skee-Lo",
    "image_url": "https://i.scdn.co/image/31c7cdf28b6a99519868b6e4b1c42df7eafec575",
    "artist_id": "55Pp4Ns5VfTSFsBraW7MQy",
    "year": 1995
  },
  {
    "song_id": "1poK64Wt7p7VwrAbFgD5hS",
    "name": "Can't You See",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/2e24ee4be442d5eec29326a6e48b90bfa2cd25f1",
    "artist_name": "Total",
    "image_url": "https://i.scdn.co/image/3802e4320b5e7a7893095e7e700624777e14e5a1",
    "artist_id": "1urjDGTd4iBze91Z1W1gu7",
    "year": 1995
  },
  {
    "song_id": "5cKQS9890SpoU2DyqclNWG",
    "name": "Feel Me Flow",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/f94b998584f04cd5ed0a5ff2a206e6a38690f5e7",
    "artist_name": "Naughty By Nature",
    "image_url": "https://i.scdn.co/image/f36a53eb6401ff8f827518170143df42f4e7e822",
    "artist_id": "4Otx4bRLSfpah5kX8hdgDC",
    "year": 1995
  },
  {
    "song_id": "6ILtPjGYb3mvprfsCaJRTA",
    "name": "Good",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/92739aee9c21a6f789194f2c20b095cca25590ff",
    "artist_name": "Better Than Ezra",
    "image_url": "https://i.scdn.co/image/1da885c780c3f2335278f4b6c1d6b60cb34b0cfe",
    "artist_id": "4UuoJfJ9UybJft7a8E6UHX",
    "year": 1995
  },
  {
    "song_id": "3pEuO9J2MTEmec8kUfYYvl",
    "name": "Another Night",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/37d31184e36a8a376ee049315e3d59156af2a44f",
    "artist_name": "Real McCoy",
    "image_url": "https://i.scdn.co/image/a9edf5d547b52990cf1a36e45fb0b2324b4d690c",
    "artist_id": "2vRfKzjQYJQd67X8x49MOh",
    "year": 1995
  },
  {
    "song_id": "5jcczlLcG8iAHCFazLinKk",
    "name": "One More Chance/Stay With Me Remix - Amended",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/8a29acbba6a5181ca25ae25981bf2182dd3dad86",
    "artist_name": "The Notorious B.I.G.",
    "image_url": "https://i.scdn.co/image/78b455349c628bd8711b1ea3f0e8ee472286f53c",
    "artist_id": "5me0Irg2ANcsgc93uaYrpb",
    "year": 1995
  },
  {
    "song_id": "7MYmo0JJJDmu4MZTSAF9y3",
    "name": "On Bended Knee",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/7607ebe56808bc628c170187a0a5837ab00637ad",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/623b5ff427f2cd203d57ea5962a92c799f3a3b2f",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1995
  },
  {
    "song_id": "7qnNnQTGxdzI2wZWpa0vDD",
    "name": "I Believe",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/c54c6d4ed46c97cdd801cf3b239934d69c5ca51b",
    "artist_name": "Blessid Union Of Souls",
    "image_url": "https://i.scdn.co/image/8aea0687fb9a50cc26a9a79b1c97414875f29650",
    "artist_id": "4W4gYpQ2AQq7US2HZS13Qw",
    "year": 1995
  },
  {
    "song_id": "1iDYoLIMqZjp7CCaI6uFTr",
    "name": "Strong Enough",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/b02f7c01b887f3efb0b83da7509817a035d02458",
    "artist_name": "Sheryl Crow",
    "image_url": "https://i.scdn.co/image/116021f02d582e1b338c2e1583524b1ce5888eb3",
    "artist_id": "4TKTii6gnOnUXQHyuo9JaD",
    "year": 1995
  },
  {
    "song_id": "7izFzcBTx3DVXFeFYDeZKB",
    "name": "Take A Bow",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/ec52ebaa4da321bc5ee3954a11b47da4f2c64329",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1995
  },
  {
    "song_id": "17aVK3oQdgwVG2l0HpP67W",
    "name": "Baby",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/4898bd96fb016e206626968ead9cf1027b60a0ca",
    "artist_name": "Brandy",
    "image_url": "https://i.scdn.co/image/7a991a7e23297e91efae3b3cce98ef2e680b612e",
    "artist_id": "05oH07COxkXKIMt6mIPRee",
    "year": 1995
  },
  {
    "song_id": "3KtIVDOChQMVi3SxHc6RrK",
    "name": "You Are Not Alone - Single Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/ed63f4a1f8cf51613c237ab42f06290ad2223c60",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/bca7ab412002bb01a75039aa65408faa2fbf028a",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 1995
  },
  {
    "song_id": "61sQYdFNS6sEBYCyr1q5gn",
    "name": "I'll Make Love To You",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/58a6e7977b5dfe03f8733b6ab9c64f75b5601b09",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/623b5ff427f2cd203d57ea5962a92c799f3a3b2f",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1995
  },
  {
    "song_id": "4GAGWckW9edK3zB6ZS7Tz5",
    "name": "Candy Rain",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/80ff3c2f207e783d0609d43da22e1980d1a098d5",
    "artist_name": "Soul For Real",
    "image_url": "https://i.scdn.co/image/4a33a0da119619c814576f290b8a0a56a4683df0",
    "artist_id": "296LotOmbj7eOX7r9l46fK",
    "year": 1995
  },
  {
    "song_id": "4x6gUDeNzAB273nhsNIeuG",
    "name": "As I Lay Me Down",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/85b4b433665ae1675dbe16a9684afdcd0d9c8c5e",
    "artist_name": "Sophie B. Hawkins",
    "image_url": "https://i.scdn.co/image/fbb9b04304f0ef2beede09fdeb48da1f34024aca",
    "artist_id": "3gdIwZY6Q3RXhDteYr4ZvC",
    "year": 1995
  },
  {
    "song_id": "4QXhRjZacZ1gZUeL7Ukktw",
    "name": "Carnival",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/03510eeffd6a1e21ba66d2cf651f6a967e3a1cf3",
    "artist_name": "Natalie Merchant",
    "image_url": "https://i.scdn.co/image/6336db639e40170ebeb5861076da3a07f0e61222",
    "artist_id": "73JEBdDEFeVaOLg3y0HhBD",
    "year": 1995
  },
  {
    "song_id": "4mKbb9uvBMlVvOUv8fdBt8",
    "name": "Someone to Love",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/7e4a75ea4ef9d950ff1581b49ffaf7de7b49cb16",
    "artist_name": "Jon B.",
    "image_url": "https://i.scdn.co/image/155bde27b13fdf5a8fdf71fe624da749b60d7dce",
    "artist_id": "3SRJWVa6lZnqRHOyAHmDrX",
    "year": 1995
  },
  {
    "song_id": "0yv6BYFfviMImtUPwzEXQp",
    "name": "Secret",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/63f90c2c84b8a1080cc4dd76d9fa86de15973a1e",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1995
  },
  {
    "song_id": "0YwUJOHKYxlm5gVfIkl8Ym",
    "name": "Best Friend",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/83cc5ca6285ca1072c74190ccf73852b01e39eb5",
    "artist_name": "Brandy",
    "image_url": "https://i.scdn.co/image/7a991a7e23297e91efae3b3cce98ef2e680b612e",
    "artist_id": "05oH07COxkXKIMt6mIPRee",
    "year": 1995
  },
  {
    "song_id": "5TsHqbDKaSs4ECZX0045YS",
    "name": "Hold Me, Thrill Me, Kiss Me, Kill Me",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/ad8d57fea769482ecd4dac783e04a84a0d1c68ae",
    "artist_name": "U2",
    "image_url": "https://i.scdn.co/image/31c5501555a758fed872dd78259baf6264dc3c54",
    "artist_id": "51Blml2LZPmy7TTiAg47vQ",
    "year": 1995
  },
  {
    "song_id": "1QJDgtV8L8jCnpM1RIauAp",
    "name": "'Til You Do Me Right",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/6592f8abb0bb5a443a2c388024e0566d4c815b28",
    "artist_name": "After 7",
    "image_url": "https://i.scdn.co/image/e643fe6b3765bbdab79c2fac83c2efbf62a7ced2",
    "artist_id": "4UPcJIhr5K5fPsm4itqT7E",
    "year": 1995
  },
  {
    "song_id": "0lZWjBImx6kNe3WtOA09Xu",
    "name": "Sugar Hill",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/74f3b3eac9fadc8f6904837607f10d0ba925a910",
    "artist_name": "AZ",
    "image_url": "https://i.scdn.co/image/ff443038ddcd216c963a6c68773feb5ba3380e6c",
    "artist_id": "7HqrSDuI9lHuH1CDismTFg",
    "year": 1995
  },
  {
    "song_id": "7yhmvQh1JOzbNiNFQo4Tfc",
    "name": "Water Runs Dry",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/1a1a25881a6e18d2290aa8c995cdf9d42965620f",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/623b5ff427f2cd203d57ea5962a92c799f3a3b2f",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1995
  },
  {
    "song_id": "2kQLJexCx8wPTAqK0x0ijO",
    "name": "Hold On",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/acb0d4c9065ef8ca966275e779d314368e8415a9",
    "artist_name": "Jamie Walters",
    "image_url": "https://i.scdn.co/image/27065738425c79fe43df398fd021ee94e67dc62f",
    "artist_id": "4JRaryUoRSFiZt04ReUD8N",
    "year": 1995
  },
  {
    "song_id": "52kDYmU0aE3txkZBKjl0nP",
    "name": "You Want This",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/170e3ef2680a96afe49035434b2d68cc35736c1d",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/28a2abc053e4b7a0d8434d09265a892930ebdd76",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1995
  },
  {
    "song_id": "0GuCN3VT2d2N1cqjPlqsxz",
    "name": "You Used To Love Me - Greatest Hits Version",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/0c8411be9d8d74f8ba7cf60612aaf15008dfab43",
    "artist_name": "Faith Evans",
    "image_url": "https://i.scdn.co/image/4f94ecd77ff7d25e942ea4cdabf81c291efb5715",
    "artist_id": "5NDMothbpdpq2xHqSjrrWn",
    "year": 1995
  },
  {
    "song_id": "4vHp893kV7HksfB5FN1Nk5",
    "name": "I Live My Life For You",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/38c20df8c0cb818ecd36a30ec4882361119eb10f",
    "artist_name": "Firehouse",
    "image_url": "https://i.scdn.co/image/e392240167c1e472c7145161883fd28e9ceb3833",
    "artist_id": "28pS8WVbFstY0o1SrqCf8I",
    "year": 1995
  },
  {
    "song_id": "0ibYVniVLMJWvc3eNfYk8T",
    "name": "Run Away",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/8e128ecd45a93306dd86c9e16be3e0a5455ad4af",
    "artist_name": "Real McCoy",
    "image_url": "https://i.scdn.co/image/a9edf5d547b52990cf1a36e45fb0b2324b4d690c",
    "artist_id": "2vRfKzjQYJQd67X8x49MOh",
    "year": 1995
  },
  {
    "song_id": "3zeyP0JnzXEx3uZLmpOdtP",
    "name": "Brokenhearted",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/5dfa4fccd1ed7a1334a6226382923052c7058fdd",
    "artist_name": "Brandy",
    "image_url": "https://i.scdn.co/image/7a991a7e23297e91efae3b3cce98ef2e680b612e",
    "artist_id": "05oH07COxkXKIMt6mIPRee",
    "year": 1995
  },
  {
    "song_id": "5kKaNe8f5rR8cA3WE7Ol5N",
    "name": "Misery - Single Version",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/cce1b852d126d1b1ea158b157c450545d1b30478",
    "artist_name": "Soul Asylum",
    "image_url": "https://i.scdn.co/image/831ccc56fc9c2e6b8aa3357147b583380582dbec",
    "artist_id": "02da1vDJ2hWqfK7aJL6SJm",
    "year": 1995
  },
  {
    "song_id": "5C1nbFH5yZl1jm7h7ScLBO",
    "name": "Always",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/2f0670818064e4d22c051b5c81b64f0534244fba",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/efff863dfbfe40cc97f25bbb51e837c36fe4510e",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1995
  },
  {
    "song_id": "6gp5reJaprmqjZnRFbpqK2",
    "name": "This Lil' Game We Play",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/c2ec4b6a18075bdf143774177777ac5c5111ba10",
    "artist_name": "Subway",
    "image_url": "https://i.scdn.co/image/e604f1b7f948658bf6693983496cea4e5aff0d17",
    "artist_id": "3Fvbvyqm3GBJKSqf1UwHKQ",
    "year": 1995
  },
  {
    "song_id": "7xG1fakElLbxwyr9eyGEK6",
    "name": "Colors Of The Wind - End Title",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/522145aa876226e2c65d69b36e783becbea7bd07",
    "artist_name": "Vanessa Williams",
    "image_url": "https://i.scdn.co/image/873d0b7bbc5cfcb7646f58160bacf38c7688d16a",
    "artist_id": "75L9s8KVrhCNtBUkZFnDFW",
    "year": 1995
  },
  {
    "song_id": "1YMn9N6JbLfefxmzSWm2jA",
    "name": "Brown Sugar - Radio Edit",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/cadc2619c02546e795ea8f3ac910e9507f09179e",
    "artist_name": "D'Angelo",
    "image_url": "https://i.scdn.co/image/d0ea13206b9425976d91912fc5e930d224a51d22",
    "artist_id": "336vr2M3Va0FjyvB55lJEd",
    "year": 1995
  },
  {
    "song_id": "5EExhZiAXIqw6M5FFGAiUb",
    "name": "Turn the Beat Around",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/f05fca391029b1e6fc546437d7545d66b87ce140",
    "artist_name": "Gloria Estefan",
    "image_url": "https://i.scdn.co/image/edf135df16f8a2ab4d3a2b9bd9497a10c7ca4da9",
    "artist_id": "5IFCkqu9J6xdWeYMk5I889",
    "year": 1995
  },
  {
    "song_id": "6PhOzQShyz2vjELsXax7TQ",
    "name": "Player's Anthem (LP-CleanVersion)",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/d34c78c7b529d34810c2b94694080536dabc0bed",
    "artist_name": "Junior M.A.F.I.A.",
    "image_url": "https://i.scdn.co/image/0737ae1fea09c92d8c21dc17347d8d0fb834c6b8",
    "artist_id": "3r0OeTcOw37N9juMYyyw39",
    "year": 1995
  },
  {
    "song_id": "7B0yMYCJO8adAUIY6COxQb",
    "name": "1st Of Tha Month",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/45cf92bbbb6e3fd0a97540b990159e620ef56465",
    "artist_name": "Bone Thugs-N-Harmony",
    "image_url": "https://i.scdn.co/image/36507bde23bc2e538bb00da46f2d3b22767d7962",
    "artist_id": "5spEJXLwD1sKUdC2bnOHPg",
    "year": 1995
  },
  {
    "song_id": "0TxFZthE81cgf5zZvpnkPP",
    "name": "All I Wanna Do",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/881ddc53ee4af3e8ef3bba9b30758abbef5a89f1",
    "artist_name": "Sheryl Crow",
    "image_url": "https://i.scdn.co/image/116021f02d582e1b338c2e1583524b1ce5888eb3",
    "artist_id": "4TKTii6gnOnUXQHyuo9JaD",
    "year": 1995
  },
  {
    "song_id": "0XVikcHfkoBvJ5ZU8xQBWn",
    "name": "Come and Get Your Love - Radio Edit",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/71851633c87c6f13b74942e02e5db8d7eed87d1e",
    "artist_name": "Real McCoy",
    "image_url": "https://i.scdn.co/image/a9edf5d547b52990cf1a36e45fb0b2324b4d690c",
    "artist_id": "2vRfKzjQYJQd67X8x49MOh",
    "year": 1995
  },
  {
    "song_id": "4Q1UwAB4nL3l4k41vkZoyg",
    "name": "This Ain't A Love Song",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/6ef564430c194068ef01ccd8dd8054ead1d4f7a8",
    "artist_name": "Bon Jovi",
    "image_url": "https://i.scdn.co/image/7fb09dbf5d9c68b70ba288611fbaa532622e2c38",
    "artist_id": "58lV9VcRSjABbAbfWS6skp",
    "year": 1995
  },
  {
    "song_id": "4HkIzBgEyNr8MogcWVnbqJ",
    "name": "Here Comes the Hotstepper - Heartical Mix",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/2a590cd1a5b51892925775f73d5fa69e2194b096",
    "artist_name": "Ini Kamoze",
    "image_url": "https://i.scdn.co/image/e13ed0b372e28cafc23674029eac77595d0eb315",
    "artist_id": "1VJspRsoC6c0bvqhnSiFCs",
    "year": 1995
  },
  {
    "song_id": "2Ht2XbOKEay5ogJbDk3i9q",
    "name": "Constantly",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/15bb9ff18cc1f02ecf97d7bf52c8c027e79e7e7b",
    "artist_name": "Immature",
    "image_url": "https://i.scdn.co/image/8698156e5a73bed2ecca5f19229373beebccf0bd",
    "artist_id": "4XDxFeclKBCFPb30yQlRUH",
    "year": 1995
  },
  {
    "song_id": "7asNwQI80A6OnlHRPwQdal",
    "name": "I'll Be There For You/You're All I Need To Get By",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/eaf8bc287160d330f2ce398fc982d0d6ea0e0f54",
    "artist_name": "Method Man",
    "image_url": "https://i.scdn.co/image/31084432bc5657f0186db4001efb13417e04d1ad",
    "artist_id": "4VmEWwd8y9MCLwexFMdpwt",
    "year": 1995
  },
  {
    "song_id": "4Cmpnt9GsAoF60tEdmpG3j",
    "name": "No More \"I Love You's\"",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/8b16ef934d1d9ca76d3310d6bb0211510df809c0",
    "artist_name": "Annie Lennox",
    "image_url": "https://i.scdn.co/image/1e773ac4d01cb641fb6dc42ce1ff47685a5abee8",
    "artist_id": "5MspMQqdVbdwP6ax3GXqum",
    "year": 1995
  },
  {
    "song_id": "0cBQbqp5jInL73ZxLBSGlX",
    "name": "Sukiyaki",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/60711d02fd56f9922cf6b5cc37844a4b0e73937a",
    "artist_name": "4 P.M.",
    "image_url": "https://i.scdn.co/image/51e2efcf86de5f7c86b141ca637a60f403cb36ba",
    "artist_id": "5t5SLR66rZDxwuVHTSATOt",
    "year": 1995
  },
  {
    "song_id": "6z3JD6IqVvu6TUBtCfQPbe",
    "name": "Can't Stop Lovin' You",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/7e9d57eea8ccf310285340d9c31a2fc3f4106951",
    "artist_name": "Van Halen",
    "image_url": "https://i.scdn.co/image/ad911c477ac13e13e03806a98e78097b17630170",
    "artist_id": "2cnMpRsOVqtPMfq7YiFE6K",
    "year": 1995
  },
  {
    "song_id": "7sa9hvmSywCFdxAJ9zjCPZ",
    "name": "Believe - Radio Edit",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/e5ef488677ff81877e327451901bda9e0c0c3853",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/020bd22f85b638d30d785e1f913e97d64dfed7e1",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1995
  },
  {
    "song_id": "7K1TyzMSR0s2iVlepOh75L",
    "name": "Give It 2 You",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/21cc31bd8852e1f002a4d2d6258075ad4b965e86",
    "artist_name": "Da Brat",
    "image_url": "https://i.scdn.co/image/2a75eaf29118d52087849e65e7749ad6d07c6638",
    "artist_id": "2I1bnmb9VQEQGKHxvr0gSf",
    "year": 1995
  },
  {
    "song_id": "3Fe3L3N5pGaa0BBT4eVypZ",
    "name": "Like The Way I Do",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/bbaaad67f56bd7107eea9d70eda2ebffea68a190",
    "artist_name": "Melissa Etheridge",
    "image_url": "https://i.scdn.co/image/b7947d3af9c87ef468e82bf509083df5d1c081c8",
    "artist_id": "01Ppu7N8uYJI8SAONo2YZA",
    "year": 1995
  },
  {
    "song_id": "6a5Gi94rOk6cpK5NDk1s1x",
    "name": "Have You Ever Really Loved A Woman?",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/197f1bbdf8085ebec98d569587903e1c0e82194d",
    "artist_name": "Bryan Adams",
    "image_url": "https://i.scdn.co/image/b572da21091e72a84255e0c445d937a25d5eb0e6",
    "artist_id": "3Z02hBLubJxuFJfhacLSDc",
    "year": 1995
  },
  {
    "song_id": "1QlIB0pkLKR8vEDSW7r42t",
    "name": "In The House Of Stone And Light",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/a219fc1279d2448a9acac628473547084b777039",
    "artist_name": "Martin Page",
    "image_url": "https://i.scdn.co/image/9e34450fc48f5ec2679d393c1ece1366d1e17563",
    "artist_id": "3NS6XjRa7OyZv6KjfkH5qC",
    "year": 1995
  },
  {
    "song_id": "6EVfqtvyixYMCmxo7JHAwu",
    "name": "I Got Him All The Time - He's Mine Remix / Album Version",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/c35e3cef951db5b90d2c92b61d30981b49ad89b5",
    "artist_name": "MoKenStef",
    "image_url": "https://i.scdn.co/image/3f063f9d9b2e667924aa8c65bd55252885a2430a",
    "artist_id": "3uklrkyCGBBg5PoFTbEoim",
    "year": 1995
  },
  {
    "song_id": "7gRPARHLsDZaf3QxSTuKT8",
    "name": "Every Day Of The Week",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/541f107ea6f0e45571449a0eae1385723961b3d7",
    "artist_name": "Jade",
    "image_url": "https://i.scdn.co/image/99d28e994523f4a1d9514e97cae5d9b42a758ff7",
    "artist_id": "7ypgj95aGInvJMJbMv78wB",
    "year": 1995
  },
  {
    "song_id": "2aw72RcR4WQvmA9bICK8QZ",
    "name": "The Sweetest Days",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/07d9233bd862eb77469a8aa6446e7e2f43eff552",
    "artist_name": "Vanessa Williams",
    "image_url": "https://i.scdn.co/image/de958fcd7cf81bf3c89f6c60d7ecf21c6d0c4b22",
    "artist_id": "75L9s8KVrhCNtBUkZFnDFW",
    "year": 1995
  },
  {
    "song_id": "66QsugNQ8z40vjxtQQwV1z",
    "name": "Dream About You",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/033a187a9f9c79d6aae4c0c4b34d6dbe84667f88",
    "artist_name": "Stevie B",
    "image_url": "https://i.scdn.co/image/c40e8a3209b054ec6cdd25489e6e2c1d6b6c967c",
    "artist_id": "6V7pNWhlJpD0s0bMdB1PU9",
    "year": 1995
  },
  {
    "song_id": "1QoJK5Xf8I9r2sjXdxa9Fq",
    "name": "Roll To Me",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/34962a7d33fb9e406af76e5c6c5808f962572c9d",
    "artist_name": "Del Amitri",
    "image_url": "https://i.scdn.co/image/d8c613dc8527c30d5d773902913c7014d619cfe4",
    "artist_id": "2Q4FnG5T6NTUcAAZwuMV5K",
    "year": 1995
  },
  {
    "song_id": "4L6f9fMqAg5Ity54TId0OM",
    "name": "Short Dick Man - Max Fabian & DJ Kirillich Radio Mix",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/2c07259f0f8fca571bae599b992069a5a0d2c8c6",
    "artist_name": "20 Fingers",
    "image_url": "https://i.scdn.co/image/0ff8caba98fe195d305735c0fac3921d21e02cf2",
    "artist_id": "0OarfYzJPCi3lAVZ4nhTTd",
    "year": 1995
  },
  {
    "song_id": "10H384Dptw1zuGuWsMWpZ8",
    "name": "In The Summertime - Feat. Rayvon",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/de1eb5097a2fb2464495ac595c01418e6170fbb6",
    "artist_name": "Shaggy",
    "image_url": "https://i.scdn.co/image/7c32968893ef0d35e6721164ec306bea74768187",
    "artist_id": "5EvFsr3kj42KNv97ZEnqij",
    "year": 1995
  },
  {
    "song_id": "48j6bDfkuJNfWRXwMbipGY",
    "name": "Thank You",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/541551acac989592820df661570adc45441f23b8",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/1e28ec2a432d3f71f55c5e4078a9128822a82484",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1995
  },
  {
    "song_id": "4QAEx1QTsrl0fPpa8Fk2Rz",
    "name": "Keep Their Heads Ringin’",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/269464fdc0d0a17eeb445d7db6eeaf6f38d04f26",
    "artist_name": "Various Artists - Dr. Dre Tribute",
    "image_url": "https://i.scdn.co/image/0186e4ecb99b7de0ada208ff82a91f9496c90cf4",
    "artist_id": "3isZ3DVPajq4dyoFhZW20i",
    "year": 1995
  },
  {
    "song_id": "6mz1fBdKATx6qP4oP1I65G",
    "name": "Pony",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/31202dce09f5af5a60249a62510ac818c10f42fb",
    "artist_name": "Ginuwine",
    "image_url": "https://i.scdn.co/image/b1edf6d454c958f30d81a020406b0b8aaad0c54f",
    "artist_id": "7r8RF1tN2A4CiGEplkp1oP",
    "year": 1996
  },
  {
    "song_id": "29YBihzQOmat0U74k4ukdx",
    "name": "Ironic",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/f4c76cf2d3637a184b969eb61049477a63764f00",
    "artist_name": "Alanis Morissette",
    "image_url": "https://i.scdn.co/image/2a996c920d96d5926050336b5f5296b2d3a41a7d",
    "artist_id": "6ogn9necmbUdCppmNnGOdi",
    "year": 1996
  },
  {
    "song_id": "2aBxt229cbLDOvtL7Xbb9x",
    "name": "Always Be My Baby",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/646209c8d0095e1f233ca27444a937fb36b887be",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/ac0afd64e904c7eff0895b49b23adfb886bf6484",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1996
  },
  {
    "song_id": "6hRcrByB9i6rYRiHNh170N",
    "name": "Because You Loved Me",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/bfb6c43741f13a18cabd8db38bb0a58900bab944",
    "artist_name": "Céline Dion",
    "image_url": "https://i.scdn.co/image/7b2f36e5a8be3d02dc1a3424d38502220786f559",
    "artist_id": "4S9EykWXhStSc15wEx8QFK",
    "year": 1996
  },
  {
    "song_id": "6xkryXuiZU360Lngd4sx13",
    "name": "Fantasy",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/b1a12ae8b2d2cb39926494c5b8538ecba7898b7e",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/ac0afd64e904c7eff0895b49b23adfb886bf6484",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1996
  },
  {
    "song_id": "62WlC9unRXGdRIULjishS0",
    "name": "It's All Coming Back to Me Now",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/f5496cdc984e2cdf3429249fa86b430c9633a138",
    "artist_name": "Céline Dion",
    "image_url": "https://i.scdn.co/image/7b2f36e5a8be3d02dc1a3424d38502220786f559",
    "artist_id": "4S9EykWXhStSc15wEx8QFK",
    "year": 1996
  },
  {
    "song_id": "1wyNvV8uXr35T8ChEypf8n",
    "name": "Un-Break My Heart",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/8d51a64a5fef14b44b4346a62b95ed064f9068aa",
    "artist_name": "Toni Braxton",
    "image_url": "https://i.scdn.co/image/63607d32726082b02dfa97e727659e7fa91574fb",
    "artist_id": "3X458ddYA2YcVWuVIGGOYe",
    "year": 1996
  },
  {
    "song_id": "69QIVIjPMnI1ABLBqh9P1b",
    "name": "Twisted",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/e82c6043e345c1602a6d6929f88672380707054f",
    "artist_name": "Keith Sweat",
    "image_url": "https://i.scdn.co/image/7025c1da51f7d437e1892b94aad733b97b019c3d",
    "artist_id": "2r09Inibex3C4ZNTUVSG3m",
    "year": 1996
  },
  {
    "song_id": "6BQNJ0JFKh8sWjQLI6Zudi",
    "name": "Change The World",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/d1d91b836ba52a319ec02051799d1c9530f21633",
    "artist_name": "Eric Clapton",
    "image_url": "https://i.scdn.co/image/120ccb8e38e0518c603842c4a01a5090f59a53cd",
    "artist_id": "6PAt558ZEZl0DmdXlnjMgD",
    "year": 1996
  },
  {
    "song_id": "1G8jae4jD8mwkXdodqHsBM",
    "name": "Name",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/bdbf577c66ebcc3cedd015e4d3de666d8d0f8fd5",
    "artist_name": "The Goo Goo Dolls",
    "image_url": "https://i.scdn.co/image/620de46505bdc1ec36e6596df8b3478467c9cbdb",
    "artist_id": "2sil8z5kiy4r76CRTXxBCA",
    "year": 1996
  },
  {
    "song_id": "0UUSebzzrC8hKiVcX8fBsz",
    "name": "Tha Crossroads",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/6bb7897a97da6ef45fb145c0f124be6c6a507525",
    "artist_name": "Bone Thugs-N-Harmony",
    "image_url": "https://i.scdn.co/image/b1edf6d454c958f30d81a020406b0b8aaad0c54f",
    "artist_id": "5spEJXLwD1sKUdC2bnOHPg",
    "year": 1996
  },
  {
    "song_id": "1PEqh7awkpuepLBSq8ZwqD",
    "name": "I Love You Always Forever",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/233c71c80639cb75b4d95539bcae6415dfe461b3",
    "artist_name": "Donna Lewis",
    "image_url": "https://i.scdn.co/image/b9dc3d6eae1ce723e767fe2fc62a262def22ea4d",
    "artist_id": "2EfG2EoT8GFJrMiilbTVl2",
    "year": 1996
  },
  {
    "song_id": "4wtR6HB3XekEengMX17cpc",
    "name": "Children",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/499dcd6b726d7c3eb14d3498e09c268d40e1bf22",
    "artist_name": "Robert Miles",
    "image_url": "https://i.scdn.co/image/35205854112e666134b5a54d21560a4d05d2b321",
    "artist_id": "2YVF0Ou5zIc4mpgtLIlGN0",
    "year": 1996
  },
  {
    "song_id": "0ZhSQIDIv0CWVc0Vt1aiBm",
    "name": "Hook",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/7dc6e91806bf28170172a9745d19c126dcffa4c8",
    "artist_name": "Blues Traveler",
    "image_url": "https://i.scdn.co/image/afeac998e8f16d2fc0c9970af7826de1e0fb5e72",
    "artist_id": "3pHeBYl1yujXcZqqfF1UyQ",
    "year": 1996
  },
  {
    "song_id": "1mU0es3QUBRAxKkYvTANpG",
    "name": "Doin' It",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/c46f3bbd7de7d8767b221772a049c4ff1754998c",
    "artist_name": "LL Cool J",
    "image_url": "https://i.scdn.co/image/39eaf7a23faf0a12c5dc165785be507a79852249",
    "artist_id": "1P8IfcNKwrkQP5xJWuhaOC",
    "year": 1996
  },
  {
    "song_id": "6mfkHv00I6jhHIn7EWHJ2N",
    "name": "Just A Girl",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/57b965ea4b54600683e8dd934e590f8e68e350d3",
    "artist_name": "No Doubt",
    "image_url": "https://i.scdn.co/image/bb7c12b57024944f5a54572577f853b30c2453c7",
    "artist_id": "0cQbJU1aAzvbEmTuljWLlF",
    "year": 1996
  },
  {
    "song_id": "32VygCu5knUb9ZmC5acyKZ",
    "name": "You're Makin' Me High",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/c06ca9f4e6e26dbf8f767230d5ca7cd13d4b2cc6",
    "artist_name": "Toni Braxton",
    "image_url": "https://i.scdn.co/image/63607d32726082b02dfa97e727659e7fa91574fb",
    "artist_id": "3X458ddYA2YcVWuVIGGOYe",
    "year": 1996
  },
  {
    "song_id": "2BIBtKMUnHyYs3BsEiILTU",
    "name": "Back for Good - Radio Mix",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/c31d3d6b39ea792f8bd70dff6676355cab0287bc",
    "artist_name": "Take That",
    "image_url": "https://i.scdn.co/image/c855f000184a3fc1b85a4306ed22d3fcf268343c",
    "artist_id": "1XgFuvRd7r5g0h844A5ZUQ",
    "year": 1996
  },
  {
    "song_id": "411Ecgzht1ky1mKDSl1N7R",
    "name": "Missing",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/1bf968da325146802e33d3c1e6287a937a05fc6e",
    "artist_name": "Everything But The Girl",
    "image_url": "https://i.scdn.co/image/53ceb1a9f086727b36cfd28768d4a64b56ceb69f",
    "artist_id": "13ccXrK7AmXb4TddMkE7jy",
    "year": 1996
  },
  {
    "song_id": "1SYXw0U7vE9BpUw5bEFvKw",
    "name": "Who Will Save Your Soul",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/9735a6fef8148c3a56a3162ba58501794da91020",
    "artist_name": "Jewel",
    "image_url": "https://i.scdn.co/image/6648739cae162d312c9b8554a654753cabf40d88",
    "artist_id": "6FbDoZnMBTdhhhLuJBOOqP",
    "year": 1996
  },
  {
    "song_id": "2W2vmNmTLERMWT9XBxm35E",
    "name": "Diggin' on You",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/5760b3552bb3d29545bce1f968834c5e2cdbe654",
    "artist_name": "TLC",
    "image_url": "https://i.scdn.co/image/64301eccca52fad26b9c416b756766df6c198f03",
    "artist_id": "0TImkz4nPqjegtVSMZnMRq",
    "year": 1996
  },
  {
    "song_id": "1GwdUrz7DujSedNxnzVfqI",
    "name": "Beautiful Life",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/8d78995f03d81ddf33d6447a1cc50f142b606097",
    "artist_name": "Ace of Base",
    "image_url": "https://i.scdn.co/image/4bc3fa03ea66c557fa889ee9684ddc8b2ed94a5e",
    "artist_id": "5ksRONqssB7BR161NTtJAm",
    "year": 1996
  },
  {
    "song_id": "6xrvubhznLOcEO3AM6XBN2",
    "name": "Before You Walk Out Of My Life",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/70510ef63761cd068714b4583d7d50cbc84fc921",
    "artist_name": "Monica",
    "image_url": "https://i.scdn.co/image/43e11b42861404a1cdfdd44eeca452a8559f18f5",
    "artist_id": "6nzxy2wXs6tLgzEtqOkEi2",
    "year": 1996
  },
  {
    "song_id": "7DufxStaLzlKtFvG6VkdBU",
    "name": "All The Things (Your Man Won't Do)",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/dc80a1b41ea4b566f33515a56ab893cf2cbe3658",
    "artist_name": "Joe",
    "image_url": "https://i.scdn.co/image/10389ad5ea381aa0564a03617f037bc347539942",
    "artist_id": "3zTOe1BtyTkwNvYZOxXktX",
    "year": 1996
  },
  {
    "song_id": "2lp8xjq0WTm3HZKHuDEweg",
    "name": "Tell Me",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/c7fddf0004ecd94cbe1f19f3bb3d598f3fce7759",
    "artist_name": "Groove Theory",
    "image_url": "https://i.scdn.co/image/db29bf370b914a8256361d8a0db936847e3b1174",
    "artist_id": "7opRqRgF9lvnVraBFCMvIj",
    "year": 1996
  },
  {
    "song_id": "2oLwDI8tZUOh6SmTMRRfKs",
    "name": "Elevators (Me & You)",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/681c4fbc52b6f3cfb5658a646e9fafb97adf3c7e",
    "artist_name": "OutKast",
    "image_url": "https://i.scdn.co/image/c09111290b1c299845abfb333561dc674d22f2a7",
    "artist_id": "1G9G7WwrXka3Z1r7aIDjI7",
    "year": 1996
  },
  {
    "song_id": "4330XmgVWlx98Y3Naswycg",
    "name": "Sittin' Up In My Room",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/72fb34f746e818567559474e148632fb381afb44",
    "artist_name": "Brandy",
    "image_url": "https://i.scdn.co/image/5d7d9b13b798673675c8e15358fbf99afc852910",
    "artist_id": "05oH07COxkXKIMt6mIPRee",
    "year": 1996
  },
  {
    "song_id": "51HuI43LXE4oNUSKMPZFWf",
    "name": "The World I Know (45 version)",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/7f5796c4295510cfb843f1c861c288d112e30676",
    "artist_name": "Collective Soul",
    "image_url": "https://i.scdn.co/image/0a3372fd7ef3a4556e01e7e3701a38a8ed3bef0d",
    "artist_id": "4e5V1Q2dKCzbLVMQ8qbTn6",
    "year": 1996
  },
  {
    "song_id": "1hp5TYhnsH4FBQSsR7RpJK",
    "name": "Lady - Radio Edit",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/afeb24ca13b2b4b1ba401caff5566b1acfced8b2",
    "artist_name": "D'Angelo",
    "image_url": "https://i.scdn.co/image/d0ea13206b9425976d91912fc5e930d224a51d22",
    "artist_id": "336vr2M3Va0FjyvB55lJEd",
    "year": 1996
  },
  {
    "song_id": "4oaj36KzXRgDg4McgcTsZK",
    "name": "Macarena",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/df6eb4cbb9638c6923a3976c3d409fc415a4829e",
    "artist_name": "Los Del Rio",
    "image_url": "https://i.scdn.co/image/3d17cdbd4b8617296074429c49b5ebec9cfc7397",
    "artist_id": "2JXn03fudjyRkQ1Ye9f5rk",
    "year": 1996
  },
  {
    "song_id": "09WxJCWFMWAxTHBLLelpDS",
    "name": "Woo Hah!! Got You All In Check",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/0c43bc3b79f77f353d3a73a580e930363f091d15",
    "artist_name": "Busta Rhymes",
    "image_url": "https://i.scdn.co/image/39c5a76ee1866a815a6573c62f9033b50779180c",
    "artist_id": "1YfEcTuGvBQ8xSD1f53UnK",
    "year": 1996
  },
  {
    "song_id": "4oaj36KzXRgDg4McgcTsZK",
    "name": "Macarena",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/df6eb4cbb9638c6923a3976c3d409fc415a4829e",
    "artist_name": "Los Del Rio",
    "image_url": "https://i.scdn.co/image/3d17cdbd4b8617296074429c49b5ebec9cfc7397",
    "artist_id": "2JXn03fudjyRkQ1Ye9f5rk",
    "year": 1996
  },
  {
    "song_id": "7EKvTUo567BRNzZ37clvlf",
    "name": "Why I Love You So Much",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/07f6684660b6973eedebbbfd5127349b808688b7",
    "artist_name": "Monica",
    "image_url": "https://i.scdn.co/image/43e11b42861404a1cdfdd44eeca452a8559f18f5",
    "artist_id": "6nzxy2wXs6tLgzEtqOkEi2",
    "year": 1996
  },
  {
    "song_id": "7obdw7ZGr6l1GqSBkFiY11",
    "name": "Macarena - Bayside Boys Remix",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/9cef0e9fdff5bf60738afc43a1adf94c51ebde36",
    "artist_name": "Los Del Rio",
    "image_url": "https://i.scdn.co/image/3d17cdbd4b8617296074429c49b5ebec9cfc7397",
    "artist_id": "2JXn03fudjyRkQ1Ye9f5rk",
    "year": 1996
  },
  {
    "song_id": "1xNmF1Uep5OGutizZSbKvd",
    "name": "One Of Us",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/cc32f43d6e9f1b8ba91d8d77d3ef6ba483a1f8b2",
    "artist_name": "Joan Osborne",
    "image_url": "https://i.scdn.co/image/92013357c758f9d7086f6beaec452e481d6b3866",
    "artist_id": "0djV4iaxhNfYWpH60ia85o",
    "year": 1996
  },
  {
    "song_id": "0TYZXljs3TAVQHwIDyALgD",
    "name": "Breakfast At Tiffany's",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/214642825c5109e3b81b3993ab9220943d5295b8",
    "artist_name": "Deep Blue Something",
    "image_url": "https://i.scdn.co/image/bf3b966b83f90362e502ca3fa0a1a7919759ac96",
    "artist_id": "5N5RfI8FFXk4WQ8kkjE407",
    "year": 1996
  },
  {
    "song_id": "5eyIfIp6IafrdAjRnZF6ql",
    "name": "No Diggity",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/0db29c89932ad0bd4dffc267d760543c7ef117f7",
    "artist_name": "Blackstreet",
    "image_url": "https://i.scdn.co/image/30b5feda36f583b4772709babe690eee17fc99ec",
    "artist_id": "2P3cjUru4H3fhSXXNxE9kA",
    "year": 1996
  },
  {
    "song_id": "2PsHIonOTmX6bSrzIJSjyS",
    "name": "Nobody Knows",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/ec7672f13f5077b0e557014856a99c3d0a89ed04",
    "artist_name": "The Tony Rich Project",
    "image_url": "https://i.scdn.co/image/7690d720ae6478d91e53b3e38f2d6387acb7d475",
    "artist_id": "7uwPrVdjss0FyCCTzpLx5A",
    "year": 1996
  },
  {
    "song_id": "3V5qEaGZr52PrvHQ1n9JLI",
    "name": "You Learn",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/78324888a6d7e605ba6e97a95af0ec9fda6adebb",
    "artist_name": "Alanis Morissette",
    "image_url": "https://i.scdn.co/image/2a996c920d96d5926050336b5f5296b2d3a41a7d",
    "artist_id": "6ogn9necmbUdCppmNnGOdi",
    "year": 1996
  },
  {
    "song_id": "2mj1Z5bqu0UfW4o4a7UxpW",
    "name": "Last Night",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/2c17051d936e5525ee684ba0e529cd8252d24bfb",
    "artist_name": "Az Yet",
    "image_url": "https://i.scdn.co/image/804bbef29d9f13121c0b58777c7efff77c201122",
    "artist_id": "4UGMQyNcbGHYg5CDMKkSw3",
    "year": 1996
  },
  {
    "song_id": "4x6gUDeNzAB273nhsNIeuG",
    "name": "As I Lay Me Down",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/85b4b433665ae1675dbe16a9684afdcd0d9c8c5e",
    "artist_name": "Sophie B. Hawkins",
    "image_url": "https://i.scdn.co/image/fbb9b04304f0ef2beede09fdeb48da1f34024aca",
    "artist_id": "3gdIwZY6Q3RXhDteYr4ZvC",
    "year": 1996
  },
  {
    "song_id": "40Jt7Gswodpx1WNddCpBDQ",
    "name": "Exhale - Shoop Shoop",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/8f9c20bfb2369639e3b19854f64182ef82081dbc",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/1a45a2b6266e337b71d72d120800184ab2138657",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1996
  },
  {
    "song_id": "78wi1g772j8eJPEnD8rr9z",
    "name": "Not Gon' Cry",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/cff4a5f5b8ad4b0fe9a4e1223a43b46892398c9d",
    "artist_name": "Mary J. Blige",
    "image_url": "https://i.scdn.co/image/5d7d9b13b798673675c8e15358fbf99afc852910",
    "artist_id": "1XkoF8ryArs86LZvFOkbyr",
    "year": 1996
  },
  {
    "song_id": "06jYyAmf4jhb7nxmUO1I6V",
    "name": "How Do U Want It - (Explicit)",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/d481e741ede4bd500dafd724197f517710329176",
    "artist_name": "2Pac",
    "image_url": "https://i.scdn.co/image/be930a2af793309d05d17869a857ec2250583b01",
    "artist_id": "1ZwdS5xdxEREPySFridCfh",
    "year": 1996
  },
  {
    "song_id": "5Hz6aGrZZizNBoJ6kE6Rqi",
    "name": "Wonder",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/145dea47508362465bd06b97a65c4bcfdad5f9eb",
    "artist_name": "Natalie Merchant",
    "image_url": "https://i.scdn.co/image/6336db639e40170ebeb5861076da3a07f0e61222",
    "artist_id": "73JEBdDEFeVaOLg3y0HhBD",
    "year": 1996
  },
  {
    "song_id": "0Fb8sdyNluLawg08N1zs48",
    "name": "You'll See",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/9369792667ae80143a43a0287e362b367a64ae69",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/666bff95fac1424ab1957aaecf1a82ede5616e41",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1996
  },
  {
    "song_id": "6iX9C7Cqgn0St5a6L25udL",
    "name": "Touch Me Tease Me",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/4920c27a19b9d3275aef48bfa610ef86a1f5e520",
    "artist_name": "Case",
    "image_url": "https://i.scdn.co/image/1cf4653fe88c8ea1e253b51f4830bd01604b87eb",
    "artist_id": "5aEWnrN8h3MhuFUPRfaVuy",
    "year": 1996
  },
  {
    "song_id": "02UYYPOGSBXxGEMce927XV",
    "name": "Nobody - Remastered Single Version",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/237e147b6d43eb35584b640e3a1c3957c75ebc19",
    "artist_name": "Keith Sweat Featuring Athena Cage",
    "image_url": "https://i.scdn.co/image/64ea3daaa45e3aa14c73f247df79a592f1f04101",
    "artist_id": "51O0x9tsil6O7g0KojAjYb",
    "year": 1996
  },
  {
    "song_id": "2AtBXRPCZhtIqSGF5ap33B",
    "name": "Anything",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/092cfa02ca9f28f212b02ef391987bef9c95b66a",
    "artist_name": "3T",
    "image_url": "https://i.scdn.co/image/0bb5dd3fee7894c231a9bb44a9c7a57ef72a1b86",
    "artist_id": "5rAaG3OuMuWvPWYji9TDgh",
    "year": 1996
  },
  {
    "song_id": "1eRRcsUU6ItJmKA3YXoiy5",
    "name": "Counting Blue Cars",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/f81651402e88cef0db912488e22e595115f7d2dd",
    "artist_name": "Dishwalla",
    "image_url": "https://i.scdn.co/image/24629d8c5824f24fbc6704a20a481f207005e231",
    "artist_id": "6F3NW2aC9Sni1FH7QPSLvw",
    "year": 1996
  },
  {
    "song_id": "0yqwZSgCWsmfhpGlulQKpZ",
    "name": "You're The One",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/5c08c00d2db1beaa7c508b2d3f44054b7c267365",
    "artist_name": "SWV",
    "image_url": "https://i.scdn.co/image/9a63bb59f7044e1fa98e63093726844d26d20dd2",
    "artist_id": "2NmK5FyrQ18HOPXq1UBzqa",
    "year": 1996
  },
  {
    "song_id": "1uUnAtPzMEHpkeodSVMcmL",
    "name": "Get Money (LP-Clean Version)",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/ef686a26fdcecc0b6fad1f78ba2e4065e2d1d816",
    "artist_name": "Junior M.A.F.I.A.",
    "image_url": "https://i.scdn.co/image/0737ae1fea09c92d8c21dc17347d8d0fb834c6b8",
    "artist_id": "3r0OeTcOw37N9juMYyyw39",
    "year": 1996
  },
  {
    "song_id": "24s6yppvGO5he6wRwCaGrr",
    "name": "That Girl - feat. Shaggy",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/24420c72c4af79a98489479e3450e5e9ef8c1b04",
    "artist_name": "Maxi Priest",
    "image_url": "https://i.scdn.co/image/1c4805a4b6c8e7b47c6046c70d1323e3bdc5defd",
    "artist_id": "3aTuTR5Nf6pVW3837q2ZL7",
    "year": 1996
  },
  {
    "song_id": "7o7rrKyfqGo5xw318JNFUb",
    "name": "Only You - Greatest Hits Version",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/3bddaccae39977be878573865b4a3ae71c9917cc",
    "artist_name": "112 featuring The Notorious B.I.G. and Mase",
    "image_url": "https://i.scdn.co/image/4f94ecd77ff7d25e942ea4cdabf81c291efb5715",
    "artist_id": "2QQOWVxfjrKzMaW9l6Tvaq",
    "year": 1996
  },
  {
    "song_id": "7mVV7fepIMUAE4FDyihupV",
    "name": "Count On Me",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/89e8a848cac251571e7d8c90e97b00ea9c4dab4c",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/5d7d9b13b798673675c8e15358fbf99afc852910",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1996
  },
  {
    "song_id": "42aZFX1bcCDj75ue0KoNSd",
    "name": "Keep On, Keepin' On - Explicit - feat. Xscape",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/059dda41987add0ca1a762ac0ffb69707ca72c3a",
    "artist_name": "MC Lyte",
    "image_url": "https://i.scdn.co/image/1bbad4a1fb4afe051848118e841a4fea26fd4300",
    "artist_id": "2ZbWlThDW0qSbI3hinpl0w",
    "year": 1996
  },
  {
    "song_id": "6uNJKTW6p5VyaCMsdZ616L",
    "name": "I Want To Come Over",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/af6acaf4a5bc8d22ee232d8057292646fbecb596",
    "artist_name": "Melissa Etheridge",
    "image_url": "https://i.scdn.co/image/a7a8c1473f8de17bfe017e89be889a6d36fdbe73",
    "artist_id": "01Ppu7N8uYJI8SAONo2YZA",
    "year": 1996
  },
  {
    "song_id": "2PAUsCMYNVfyw6imxFA71H",
    "name": "My Boo - LP Radio Edit",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/75215b9567409dd99676f1602e5f3cec15610d90",
    "artist_name": "Ghostown DJs",
    "image_url": "https://i.scdn.co/image/ff60e555d712b7bb406adf0d553cec94f4f59396",
    "artist_id": "6b25GpGuhXxZ85fTDUV3JF",
    "year": 1996
  },
  {
    "song_id": "2OqkSQe1kA8oRgEbw064dS",
    "name": "Insensitive",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/1a421dca9b8fa93203e934f35d8fd73291df7191",
    "artist_name": "Jann Arden",
    "image_url": "https://i.scdn.co/image/ca796ff3a5aba09e6b4e546be167d2fbf19adefb",
    "artist_id": "1aftUCES5zD5xXI7O9ZF9F",
    "year": 1996
  },
  {
    "song_id": "4h1iaLi1bn9x5xRWGHT3TS",
    "name": "I Can't Sleep Baby (If I)",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/359176ed2712ffb75f3478ad1963e71af4983161",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/cd4b2b732a72dfd8153e705325d29897a6e9e13f",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 1996
  },
  {
    "song_id": "1i7Gv6yN5aCyygveod0QDH",
    "name": "Where Do You Go",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/b9a57068ce10ecc02db4044d8ddad16823b42fe0",
    "artist_name": "No Mercy",
    "image_url": "https://i.scdn.co/image/52112fe1feee61f09bec07fedc7e702f70097593",
    "artist_id": "2tUGlReCZRMoRgl0IS79i3",
    "year": 1996
  },
  {
    "song_id": "0xw22cJECqEQ8RlH0H4sHr",
    "name": "Closer To Free",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/16c7c540d395e6e36491d54c6712ae3efc26de1b",
    "artist_name": "Bodeans",
    "image_url": "https://i.scdn.co/image/9ca8bc910aa3c472fe79fec8eb43895818d74518",
    "artist_id": "2D2qwEatKbBSKhBCfP6lyn",
    "year": 1996
  },
  {
    "song_id": "2vaF3iStySjdbbIJBe04P7",
    "name": "Til I Hear It From You",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/9a8f91984900f734693cb1767fbcb55c0e19239a",
    "artist_name": "Gin Blossoms",
    "image_url": "https://i.scdn.co/image/7cbd999df4ec634c35b89b153819bea1f6583572",
    "artist_id": "6kXp61QMZFPcKMcRPqoiVj",
    "year": 1996
  },
  {
    "song_id": "20lzW8sXHa20GVDyIwJihy",
    "name": "The Earth, The Sun, The Rain",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/f10ac3559677cd3cd7ac66267e4450bf0b6d6201",
    "artist_name": "Color Me Badd",
    "image_url": "https://i.scdn.co/image/f981f2e50ac2abe5e54e9c52644cadb535379e85",
    "artist_id": "1QtIfAa6y7w2JhxYJhYeUG",
    "year": 1996
  },
  {
    "song_id": "1eQZwiUWnnrem5a7sSexZd",
    "name": "Hey Lover",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/41fc33bd753fab90722503a3fd2008c0fcce53fa",
    "artist_name": "LL Cool J",
    "image_url": "https://i.scdn.co/image/29a816f7cd188f438a6372e880a6c22fe72a02ad",
    "artist_id": "1P8IfcNKwrkQP5xJWuhaOC",
    "year": 1996
  },
  {
    "song_id": "5OnnCaaLL6ooi2JBWI2Y7t",
    "name": "One Sweet Day - w / Mariah Carey",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/91f9a83acc7512564e0a69c279ead9a0da03a43a",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/64ff51acada4c19652f9edd63c8b8c9f04b33620",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1996
  },
  {
    "song_id": "11Ki2bHWXTEug0DAfKcDDD",
    "name": "Mouth",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/034f82f041a88bc7cf44f22ec30ed685a0d9f1fc",
    "artist_name": "Merril Bainbridge",
    "image_url": "https://i.scdn.co/image/aab6094f311f3365193583235a8f9fea5c77702d",
    "artist_id": "0lSDlT2Z5EvUGNIl7WQ7k0",
    "year": 1996
  },
  {
    "song_id": "08kM4SluoXVyIcmiPN6WqT",
    "name": "Jealousy - Remastered Single Version",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/2d8e154dd83ec805e43a1ffa13bcb3990a8c4a78",
    "artist_name": "Natalie Merchant",
    "image_url": "https://i.scdn.co/image/1fcb7f5decfd10cc5dcd736937ac81775b661d1f",
    "artist_id": "73JEBdDEFeVaOLg3y0HhBD",
    "year": 1996
  },
  {
    "song_id": "5j8pEVMOPjLpUdAxrmZqq8",
    "name": "Down Low (Nobody Has to Know)",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/7adafab7abcf755eaab6fa71e1838d16e7b852ef",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/cd4b2b732a72dfd8153e705325d29897a6e9e13f",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 1996
  },
  {
    "song_id": "4hiP73O5neL2rKZ4tfdxpc",
    "name": "Key West Intermezzo (I Saw You First)",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/b42b4b2df4f6f9b97f9dcbf3ce79aa1a6c381ac0",
    "artist_name": "John Mellencamp",
    "image_url": "https://i.scdn.co/image/2252c94914f263d66846123192aae9ce9ade7082",
    "artist_id": "3lPQ2Fk5JOwGWAF3ORFCqH",
    "year": 1996
  },
  {
    "song_id": "43vQRRXiQWU05gIHZ5hCX7",
    "name": "You Remind Me of Something",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/fbcc058ab6809c112892a0c428f71a298bcb9d7c",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/cd4b2b732a72dfd8153e705325d29897a6e9e13f",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 1996
  },
  {
    "song_id": "38bTjekNUk3TMLeJbQmQ4H",
    "name": "Tonite'S Tha Night - Kris Kross Redman Remix",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/aa5e5a51d2a74e8f1a4f455328d20b88b1fe2dfc",
    "artist_name": "Kris Kross",
    "image_url": "https://i.scdn.co/image/ccf12351f4d8f00755acdb553cad6761690b5e6f",
    "artist_id": "2zrZfs23sjuHDv4E6YRmNf",
    "year": 1996
  },
  {
    "song_id": "4YRamrl8lDNnBGA9rcKsM2",
    "name": "Hit Me Off",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/48181adbf2bdfbb898ec8c6bbf952b7669d6ac1f",
    "artist_name": "New Edition",
    "image_url": "https://i.scdn.co/image/94191154091f9e66af19334ad7c46902bf0289d5",
    "artist_id": "1mFX1QlezK1lNPKQJkhwWb",
    "year": 1996
  },
  {
    "song_id": "6im5Ts56ewHHJFu8qAE8X3",
    "name": "If It Makes You Happy",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/cc80d9d7956fe2a35754cdb626ec510ce72d6b61",
    "artist_name": "Sheryl Crow",
    "image_url": "https://i.scdn.co/image/868cded4378a32a84f1d2d0d46b85b0f738b0ed7",
    "artist_id": "4TKTii6gnOnUXQHyuo9JaD",
    "year": 1996
  },
  {
    "song_id": "331NqDIshmegDM8HJW9YhH",
    "name": "Who Do U Love - Album Mix",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/e213aecadb2cf99b374ef48830377f9181245948",
    "artist_name": "Deborah Cox",
    "image_url": "https://i.scdn.co/image/185bfbd2fc58e955fceb9649a6d3421309a5f2a0",
    "artist_id": "601893mmW5hl1FBOykWZHG",
    "year": 1996
  },
  {
    "song_id": "1Q6oJGLbh2s1OocPyMKkJr",
    "name": "Set U Free - Fever Mix",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/627a0dba1d917f61332f1ee67248a1f978fc1aa4",
    "artist_name": "Planet Soul",
    "image_url": "https://i.scdn.co/image/72ddf5ebc7091e4b4ed8bca272ff1d20e40c7d75",
    "artist_id": "5E0lNHVTNdbK9pcUmQm1S1",
    "year": 1996
  },
  {
    "song_id": "3vlSmM6N3P4nkLVwmO5eoD",
    "name": "Don't Cry",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/d966fff130452c9a6ce0c1eead25e6a46959843a",
    "artist_name": "Seal",
    "image_url": "https://i.scdn.co/image/a8966e239c09e630c32b0d2cad5d872d6a256725",
    "artist_id": "5GtMEZEeFFsuHY8ad4kOxv",
    "year": 1996
  },
  {
    "song_id": "1Je1IMUlBXcx1Fz0WE7oPT",
    "name": "Wannabe - Radio Edit",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/640b1a73f6bf6d3367a3c393b7dc3529837f34af",
    "artist_name": "Spice Girls",
    "image_url": "https://i.scdn.co/image/5ca35e4b6f956888d8e0ad1a22f36d3bc76c7a49",
    "artist_id": "0uq5PttqEjj3IH1bzwcrXF",
    "year": 1997
  },
  {
    "song_id": "6mz1fBdKATx6qP4oP1I65G",
    "name": "Pony",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/31202dce09f5af5a60249a62510ac818c10f42fb",
    "artist_name": "Ginuwine",
    "image_url": "https://i.scdn.co/image/b1edf6d454c958f30d81a020406b0b8aaad0c54f",
    "artist_id": "7r8RF1tN2A4CiGEplkp1oP",
    "year": 1997
  },
  {
    "song_id": "3QHONiXGMGU3z68mQInncF",
    "name": "I'll Be Missing You (feat. 112)",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/4c42079e04d68b01346c87fa51b992608252a93f",
    "artist_name": "Diddy",
    "image_url": "https://i.scdn.co/image/3802e4320b5e7a7893095e7e700624777e14e5a1",
    "artist_id": "59wfkuBoNyhDMQGCljbUbA",
    "year": 1997
  },
  {
    "song_id": "0AIfr4oi192dWLRJZrRz1O",
    "name": "I Believe I Can Fly",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/bffef7eb2fa2b435cbcab3ee73292cf7b4d64216",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/37145297a059445de8ce1cdd846132d8fb3f7803",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 1997
  },
  {
    "song_id": "1wyNvV8uXr35T8ChEypf8n",
    "name": "Un-Break My Heart",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/8d51a64a5fef14b44b4346a62b95ed064f9068aa",
    "artist_name": "Toni Braxton",
    "image_url": "https://i.scdn.co/image/63607d32726082b02dfa97e727659e7fa91574fb",
    "artist_id": "3X458ddYA2YcVWuVIGGOYe",
    "year": 1997
  },
  {
    "song_id": "62WlC9unRXGdRIULjishS0",
    "name": "It's All Coming Back to Me Now",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/f5496cdc984e2cdf3429249fa86b430c9633a138",
    "artist_name": "Céline Dion",
    "image_url": "https://i.scdn.co/image/7b2f36e5a8be3d02dc1a3424d38502220786f559",
    "artist_id": "4S9EykWXhStSc15wEx8QFK",
    "year": 1997
  },
  {
    "song_id": "3i6qNxyVgIdUZTTi5m25EM",
    "name": "Bitch",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/584b5e06d2fa6b86ffde687aea19996377017f42",
    "artist_name": "Meredith Brooks",
    "image_url": "https://i.scdn.co/image/fdc319e479fc5c302e249138c84a0e43edcb8106",
    "artist_id": "2QmLFuIDtNDmmJY3OtvinN",
    "year": 1997
  },
  {
    "song_id": "6BQNJ0JFKh8sWjQLI6Zudi",
    "name": "Change The World",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/d1d91b836ba52a319ec02051799d1c9530f21633",
    "artist_name": "Eric Clapton",
    "image_url": "https://i.scdn.co/image/120ccb8e38e0518c603842c4a01a5090f59a53cd",
    "artist_id": "6PAt558ZEZl0DmdXlnjMgD",
    "year": 1997
  },
  {
    "song_id": "69QIVIjPMnI1ABLBqh9P1b",
    "name": "Twisted",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/e82c6043e345c1602a6d6929f88672380707054f",
    "artist_name": "Keith Sweat",
    "image_url": "https://i.scdn.co/image/7025c1da51f7d437e1892b94aad733b97b019c3d",
    "artist_id": "2r09Inibex3C4ZNTUVSG3m",
    "year": 1997
  },
  {
    "song_id": "1nRwyxNsqCLeA17qR8Nfxx",
    "name": "Quit Playing Games (With My Heart)",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/07ce6043c256216e400d44fca15d37c49d67c776",
    "artist_name": "Backstreet Boys",
    "image_url": "https://i.scdn.co/image/91d62ebe855ad57ff570ae029ad22f57d82667bb",
    "artist_id": "5rSXSAkZ67PYJSvpUpkOr7",
    "year": 1997
  },
  {
    "song_id": "4n9e8Nx2FijSDVoumQwXPW",
    "name": "All By Myself",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/fbd1b7bea26a4b23237923f150454b95b016cb3f",
    "artist_name": "Céline Dion",
    "image_url": "https://i.scdn.co/image/7b2f36e5a8be3d02dc1a3424d38502220786f559",
    "artist_id": "4S9EykWXhStSc15wEx8QFK",
    "year": 1997
  },
  {
    "song_id": "1PEqh7awkpuepLBSq8ZwqD",
    "name": "I Love You Always Forever",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/233c71c80639cb75b4d95539bcae6415dfe461b3",
    "artist_name": "Donna Lewis",
    "image_url": "https://i.scdn.co/image/b9dc3d6eae1ce723e767fe2fc62a262def22ea4d",
    "artist_id": "2EfG2EoT8GFJrMiilbTVl2",
    "year": 1997
  },
  {
    "song_id": "4ACxa9buEUnOdYEoPcnMpi",
    "name": "Your Woman",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/b82db916c2ee3f62cad2b8ee61ae9821078178f0",
    "artist_name": "White Town",
    "image_url": "https://i.scdn.co/image/f904531656951a7e327f6d03f3397c7bf5c36218",
    "artist_id": "1MPcILKoMCJym9KscdYxuM",
    "year": 1997
  },
  {
    "song_id": "5YScXJKtefsgdskIy60N7A",
    "name": "Tubthumping",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/741430637f2e802ba48f3e43221189e98744adea",
    "artist_name": "Chumbawamba",
    "image_url": "https://i.scdn.co/image/e69cbed58994d20ac27f2e5f32487c3b39c451d5",
    "artist_id": "0TcYeHEK9sBtv7xPbKhzHz",
    "year": 1997
  },
  {
    "song_id": "6rQ9qJPvInEqSzlh89LsPJ",
    "name": "Coco Jamboo - Radio Version",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/1e7a5394e6318e0055ebcb7101c30f6ae0f4f744",
    "artist_name": "Mr. President",
    "image_url": "https://i.scdn.co/image/64f9628ca02f7a01421aa39b5d815b6bf5e271a5",
    "artist_id": "7KBkgunlONG7LPxs93pgpp",
    "year": 1997
  },
  {
    "song_id": "36AWdhZIGLUTkWpJDhe7va",
    "name": "2 Become 1",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/a298eb205d4d35273f4a72f294d68b19a67fe575",
    "artist_name": "Spice Girls",
    "image_url": "https://i.scdn.co/image/5ca35e4b6f956888d8e0ad1a22f36d3bc76c7a49",
    "artist_id": "0uq5PttqEjj3IH1bzwcrXF",
    "year": 1997
  },
  {
    "song_id": "1oLIBuedMjAbHlO4WTGJi3",
    "name": "Hypnotize",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/6fa1d41f8b1ef0a2287f52d8afc24ccf89c3b97b",
    "artist_name": "The Notorious B.I.G.",
    "image_url": "https://i.scdn.co/image/63d9a8ee9b2203ef0090236df54233ce29950f31",
    "artist_id": "5me0Irg2ANcsgc93uaYrpb",
    "year": 1997
  },
  {
    "song_id": "6Ee5Y6kCdlZfoFhyR8xPVt",
    "name": "Foolish Games",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/9cf5d99c5b25588ce7a0f198bfa75074416fa19e",
    "artist_name": "Jewel",
    "image_url": "https://i.scdn.co/image/6648739cae162d312c9b8554a654753cabf40d88",
    "artist_id": "6FbDoZnMBTdhhhLuJBOOqP",
    "year": 1997
  },
  {
    "song_id": "1EM23A621Z0QyTlWKu7T1l",
    "name": "Can't Nobody Hold Me Down (feat. Mase)",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/47f5e8e2f32a5af21a0d80a63ee36c65b26f0687",
    "artist_name": "Diddy",
    "image_url": "https://i.scdn.co/image/8387344b7e7affbaaef3faba758e708b75b712fe",
    "artist_id": "59wfkuBoNyhDMQGCljbUbA",
    "year": 1997
  },
  {
    "song_id": "21jEuMn2lf37715rwjow2M",
    "name": "The Freshmen",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/c75e436ba9a82bdeddf2e0bd7706c06330c765ba",
    "artist_name": "The Verve Pipe",
    "image_url": "https://i.scdn.co/image/7014935cc28db0287cb6a283005df3883d1a33f2",
    "artist_id": "242iqFnwNhlidVBMI9GYKp",
    "year": 1997
  },
  {
    "song_id": "1yTQ39my3MoNROlFw3RDNy",
    "name": "Say You'll Be There - Single Mix",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/cac37bb107fcfcfa996674fe70bb37d6ae1b15ec",
    "artist_name": "Spice Girls",
    "image_url": "https://i.scdn.co/image/5ca35e4b6f956888d8e0ad1a22f36d3bc76c7a49",
    "artist_id": "0uq5PttqEjj3IH1bzwcrXF",
    "year": 1997
  },
  {
    "song_id": "32VygCu5knUb9ZmC5acyKZ",
    "name": "You're Makin' Me High",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/c06ca9f4e6e26dbf8f767230d5ca7cd13d4b2cc6",
    "artist_name": "Toni Braxton",
    "image_url": "https://i.scdn.co/image/63607d32726082b02dfa97e727659e7fa91574fb",
    "artist_id": "3X458ddYA2YcVWuVIGGOYe",
    "year": 1997
  },
  {
    "song_id": "3kVIFDE3G89I2RPVkiRaRj",
    "name": "Cupid",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/cb96e21d8c98fea5ad7ae0966da17e5a275af260",
    "artist_name": "112",
    "image_url": "https://i.scdn.co/image/bdb4c3b50309acdbcd8d37e9135623b766b51e8f",
    "artist_id": "7urq0VfqxEYEEiZUkebXT4",
    "year": 1997
  },
  {
    "song_id": "5OBGoVvCDEL63OLl6BlHa3",
    "name": "No Diggity (Re-Recorded / Remastered)",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/8ca5dd8e841befa02625fc8d2eed688baecfa68a",
    "artist_name": "Blackstreet",
    "image_url": "https://i.scdn.co/image/da370ce49f5f607365333def804bcc19a32af3d3",
    "artist_id": "2P3cjUru4H3fhSXXNxE9kA",
    "year": 1997
  },
  {
    "song_id": "2DLfVnmabQqX3g3dnfvBe2",
    "name": "Every Time I Close My Eyes",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/710a682b733d73375496a6bc7e9f5369d4bd7a6d",
    "artist_name": "Babyface",
    "image_url": "https://i.scdn.co/image/acdde5fbc1da79e4a89196c8714f05d919f67e7d",
    "artist_id": "3aVoqlJOYx31lH1gibGDt3",
    "year": 1997
  },
  {
    "song_id": "4mOxpj82q6n3EO7HBZCelX",
    "name": "Sunny Came Home",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/9b1b5a6f63a8fb10573f9908925c822ae3d32f9e",
    "artist_name": "Shawn Colvin",
    "image_url": "https://i.scdn.co/image/5f4275dc127e0a0d468f5f1ff3938510110f7734",
    "artist_id": "0K7VN4aHxHcEb7PqkfoIVA",
    "year": 1997
  },
  {
    "song_id": "7eBky33UQEUKp0sNhcU6rW",
    "name": "Barely Breathing (45 Version)",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/4fe872b94e6a803a4236e88e8bbc6c120f2b0e40",
    "artist_name": "Duncan Sheik",
    "image_url": "https://i.scdn.co/image/c820f204a84b951b2b749cfbc3621efd937eacb9",
    "artist_id": "3AO6RTDA16mg8czQI5Y9K0",
    "year": 1997
  },
  {
    "song_id": "2XncSBceg7BwEpbWVI6FLi",
    "name": "Mo Money Mo Problems (feat. Mase & Puff Daddy)",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/6ca89ec3edc95a33c0e7d9c107dcf97c6d690991",
    "artist_name": "The Notorious B.I.G.",
    "image_url": "https://i.scdn.co/image/63d9a8ee9b2203ef0090236df54233ce29950f31",
    "artist_id": "5me0Irg2ANcsgc93uaYrpb",
    "year": 1997
  },
  {
    "song_id": "2wxO3ZydJTZRi4Bbc02q7i",
    "name": "Smile",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/742ca75c15f541e9c979cc821fb1a881050112d0",
    "artist_name": "Scarface",
    "image_url": "https://i.scdn.co/image/1f998e51da3a36ac51971dc6a1692a7e2a4ec40f",
    "artist_id": "19KwjzvIL92r29IINtlPNP",
    "year": 1997
  },
  {
    "song_id": "4JyMoznkRseonxgmyrbkuN",
    "name": "Don't Wanna Be a Player",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/0edf16b511399433a26cd7e531187c43448be465",
    "artist_name": "Joe",
    "image_url": "https://i.scdn.co/image/10389ad5ea381aa0564a03617f037bc347539942",
    "artist_id": "3zTOe1BtyTkwNvYZOxXktX",
    "year": 1997
  },
  {
    "song_id": "2v9Obh8z41hUXDqnfdhVON",
    "name": "In My Bed",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/de354d1cd9c4cfe7394838f2904f1c3493e593c4",
    "artist_name": "Dru Hill",
    "image_url": "https://i.scdn.co/image/e5af5fe9651023b339209c48ca14fd9bb15b8898",
    "artist_id": "1255GTUKNCLCTvH9ctD4cT",
    "year": 1997
  },
  {
    "song_id": "7obdw7ZGr6l1GqSBkFiY11",
    "name": "Macarena - Bayside Boys Remix",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/9cef0e9fdff5bf60738afc43a1adf94c51ebde36",
    "artist_name": "Los Del Rio",
    "image_url": "https://i.scdn.co/image/3d17cdbd4b8617296074429c49b5ebec9cfc7397",
    "artist_id": "2JXn03fudjyRkQ1Ye9f5rk",
    "year": 1997
  },
  {
    "song_id": "2RSOzvKUnfDDrb2nQPfpQU",
    "name": "Barbie Girl",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/50845f5ee9dc3e60ca48566b3a5b6da3958d390c",
    "artist_name": "Aqua",
    "image_url": "https://i.scdn.co/image/4a589f44ef1c9988ef3441b8b40fa1ea66b7b730",
    "artist_id": "6kBjAFKyd0he7LiA5GQ3Gz",
    "year": 1997
  },
  {
    "song_id": "1IPG1ph2URqWSDGVPMW2Xg",
    "name": "I Want You",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/9621c058e57fa3af369ee46a4ec4d15f1ebf3273",
    "artist_name": "Savage Garden",
    "image_url": "https://i.scdn.co/image/02b354c7dd459ecda2ebc4459af82605feef4b78",
    "artist_id": "3NRFinRTEqUCfaTTZmk8ek",
    "year": 1997
  },
  {
    "song_id": "33SWpfAzf61K2lk4pwth92",
    "name": "Honey",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/93da54a716a8b961365305b0c067edd9b8575e7f",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/c279ede2adc5a99fd06c96964e0fdd469561d3ac",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1997
  },
  {
    "song_id": "46LEQkbfPT5x2ZanS3STg5",
    "name": "I'm Still In Love With You",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/0e44cd215ab8b5afa080c02080322bd340b37b84",
    "artist_name": "New Edition",
    "image_url": "https://i.scdn.co/image/fde04cb82e0ebfd7c691e6e555a86a910d8ad8c1",
    "artist_id": "1mFX1QlezK1lNPKQJkhwWb",
    "year": 1997
  },
  {
    "song_id": "0tNuJpBgtE65diL6Q8Q7fI",
    "name": "On & On",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/20bdf6aeb3be92cf59ee93abe0cc769863224aea",
    "artist_name": "Erykah Badu",
    "image_url": "https://i.scdn.co/image/d3cafaa6fe30940d9dcb220577f56ad925360365",
    "artist_id": "7IfculRW2WXyzNQ8djX8WX",
    "year": 1997
  },
  {
    "song_id": "2WthqeuPLwn0CHwCMrlt6L",
    "name": "I Finally Found Someone (Duet with Bryan Adams)",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/d8ccd423dbc8dbf32e521edf50f523bbe3ef7d49",
    "artist_name": "Barbra Streisand",
    "image_url": "https://i.scdn.co/image/130de11cf9ca13d9a1d535aeff98b20fb62fac88",
    "artist_id": "7jmTilWYlKOuavFfmQAcu6",
    "year": 1997
  },
  {
    "song_id": "2mj1Z5bqu0UfW4o4a7UxpW",
    "name": "Last Night",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/2c17051d936e5525ee684ba0e529cd8252d24bfb",
    "artist_name": "Az Yet",
    "image_url": "https://i.scdn.co/image/804bbef29d9f13121c0b58777c7efff77c201122",
    "artist_id": "4UGMQyNcbGHYg5CDMKkSw3",
    "year": 1997
  },
  {
    "song_id": "0bLRXQaWzmSXRXPmP6JnEF",
    "name": "Don't Cry For Me Argentina",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/2240b17b913f1527407ee0136d8eeb20bdb1bc26",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/789937a0964ebe960405069c15dcc3a0476bd05f",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1997
  },
  {
    "song_id": "1Ddf5oGQwHnRoacJIr8M1j",
    "name": "You Make Me Wanna...",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/2224e325541d43a4702a3abc9010a4d71498a1a6",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/118d5117f8bbda8403bced2589515ad3a08b1b45",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 1997
  },
  {
    "song_id": "2OWzR8QoH7KaLtiN65v32x",
    "name": "Hard To Say I'm Sorry",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/c41629dd3b6c1e485d3f8bb85470d118d95bb2f0",
    "artist_name": "Az Yet",
    "image_url": "https://i.scdn.co/image/804bbef29d9f13121c0b58777c7efff77c201122",
    "artist_id": "4UGMQyNcbGHYg5CDMKkSw3",
    "year": 1997
  },
  {
    "song_id": "1nP6feYWJVpbO41aezFtm2",
    "name": "I Belong To You (Every Time I See Your Face)",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/f71f26f6467fabcd06bf6d9d975e28e18b5a80b8",
    "artist_name": "Rome",
    "image_url": "https://i.scdn.co/image/7e13f3b39dc682fbbbb7f1a49f8c24d06e319887",
    "artist_id": "6BvltIR3D11htiOg84sbVX",
    "year": 1997
  },
  {
    "song_id": "2wnqgQ1gEDeD9QW6i6EqLV",
    "name": "Where Have All the Cowboys Gone ?",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/357714b3e10aac8fb0d4bfad0ccf6ad0990e90fe",
    "artist_name": "Paula Cole",
    "image_url": "https://i.scdn.co/image/b6d7cb99ccf7b39f2cfd5b263740933fa85b1aa4",
    "artist_id": "08DLZqQd6XDAVteF9nSEJ1",
    "year": 1997
  },
  {
    "song_id": "4eDCsyIHTSu3cQZ5wDIyP7",
    "name": "Get It Together",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/be0e44ed941257dd3582b477d9129380fe231d4a",
    "artist_name": "702",
    "image_url": "https://i.scdn.co/image/68e941885fbea22f58d04f24010f8d781b4859f6",
    "artist_id": "2S8UlyXW4JjjHcbeg8ddIo",
    "year": 1997
  },
  {
    "song_id": "6fRLVZ4jKzuqyDPlIdbq9q",
    "name": "Butta Love",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/26194627926b26d9b35c99c838db8e17ca4fee79",
    "artist_name": "Next",
    "image_url": "https://i.scdn.co/image/8f558bf523726bcc3d1cb5008bda3d6aea6167da",
    "artist_id": "2MGT7CYlixSPxAnnkhLpaA",
    "year": 1997
  },
  {
    "song_id": "02UYYPOGSBXxGEMce927XV",
    "name": "Nobody - Remastered Single Version",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/237e147b6d43eb35584b640e3a1c3957c75ebc19",
    "artist_name": "Keith Sweat Featuring Athena Cage",
    "image_url": "https://i.scdn.co/image/64ea3daaa45e3aa14c73f247df79a592f1f04101",
    "artist_id": "51O0x9tsil6O7g0KojAjYb",
    "year": 1997
  },
  {
    "song_id": "6Ag4SYLUGFdnJrCwUIb4mT",
    "name": "I'll Be",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/54c8edf9ce85b8774e7243f473e98f7082c0239b",
    "artist_name": "Foxy Brown",
    "image_url": "https://i.scdn.co/image/bd0959999e707250ba667b15bae8fbf93402b9c6",
    "artist_id": "1wvlC6NwleHt1iRD6d5X2C",
    "year": 1997
  },
  {
    "song_id": "1ZvS1DmW1cF0oknYGCVNK7",
    "name": "Candle In The Wind",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/dab5c538fd451a8ab566b43282db55ea2f18ff12",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/90104614b4f215c8acd203e9d8d82d6a1c1ab8cf",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1997
  },
  {
    "song_id": "3WEdk4BoEaQCSpqEA3JKXM",
    "name": "Ooh Aah (Just A Little Bit)",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/7b7284f724b90cec8e9ec9fff3de009ebb2a092b",
    "artist_name": "Gina G",
    "image_url": "https://i.scdn.co/image/9b1d69e6ba2485dfc67155aa685d160451b6e3c4",
    "artist_id": "25tu0d8Po5c4IVXsZnXUU8",
    "year": 1997
  },
  {
    "song_id": "4LX1UlvBCZhwVMFeM2kSgA",
    "name": "Cold Rock A Party",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/33c2b691bef1dc641c676d250fe4a8c4facadf61",
    "artist_name": "MC Lyte",
    "image_url": "https://i.scdn.co/image/9c799f256a4c92cf5e96783e3ad0c798be22c7fa",
    "artist_id": "2ZbWlThDW0qSbI3hinpl0w",
    "year": 1997
  },
  {
    "song_id": "0RwDPdfoitpL7Uk2pN7l1K",
    "name": "4 Seasons Of Loneliness",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/7b50bbd625c721c07d8c714debf29bf5a68ff11d",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/030ad8ee793d05227a3d58af683b31d65df98d28",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1997
  },
  {
    "song_id": "3TS25nchaYIPb2Y2fw6cbb",
    "name": "My Love Is The Shhh!",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/36a2a6100ad9d852f4fd89cd2da3d55d52a3c417",
    "artist_name": "Somethin' For The People",
    "image_url": "https://i.scdn.co/image/e6293a61b4bb9e1e38188a2fff37b7478d0b4b0d",
    "artist_id": "1Hu56IprUt6GltqTkpvguI",
    "year": 1997
  },
  {
    "song_id": "6OcupDJfgXbC0JR8iIbBrP",
    "name": "When You Love a Woman",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/cb4f7448244e03f382d0a9fd841877a4a4d85bc5",
    "artist_name": "Journey",
    "image_url": "https://i.scdn.co/image/63e47b9e1420e2effd4d4537c464c27e6e598f2e",
    "artist_id": "0rvjqX7ttXeg3mTy8Xscbt",
    "year": 1997
  },
  {
    "song_id": "1iNufwx3I4wskqlCjIt2Y1",
    "name": "G.H.E.T.T.O.U.T.",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/9e113e22f384e1e979e9beb9d274f563f5df14c2",
    "artist_name": "Changing Faces",
    "image_url": "https://i.scdn.co/image/9416fa4c1bdf2b2fdb9b87234adcf1dcf64f86c5",
    "artist_id": "3lJ6jvOiNY46L2juqXufpf",
    "year": 1997
  },
  {
    "song_id": "47ydIiJ8DUEorIzYA7cDya",
    "name": "I Believe In You And Me - Film Version",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/562505d5ffc3e95cdcf799a34fa71579d89a0dfd",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/1a45a2b6266e337b71d72d120800184ab2138657",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1997
  },
  {
    "song_id": "2gw6Rk1Lr3lgN5GUXQBKrF",
    "name": "All For You - Original",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/25a8810f5e5012e8c545af3f9fc1c7ede44e5369",
    "artist_name": "Sister Hazel",
    "image_url": "https://i.scdn.co/image/0ebfa4ad7d2e1fa1d60339a6b39b49ad41405f5a",
    "artist_id": "7m60UAnbgFFNuJbmS6OxTk",
    "year": 1997
  },
  {
    "song_id": "6YtkOhiHmf46w1noltVnF2",
    "name": "Naked Eye",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/12bb2f436264253e5b0335cf39c9253159d3fdd8",
    "artist_name": "Luscious Jackson",
    "image_url": "https://i.scdn.co/image/383ebe616e9e9be4ba1b433b7608f254c0a4c53a",
    "artist_id": "1EcRE2Nnqg2spdn2otakhg",
    "year": 1997
  },
  {
    "song_id": "0lnxrQAd9ZxbhBBe7d8FO8",
    "name": "MMMBop",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/c634f0da675ad9d8ce9d61f953a5fddeb164d392",
    "artist_name": "Hanson",
    "image_url": "https://i.scdn.co/image/1f6a583a647d08a285f8538884e7ae9c69c2ed6e",
    "artist_id": "0SdiiPkr02EUdekHZJkt58",
    "year": 1997
  },
  {
    "song_id": "4oQgsytVrNH5dOdiLRVKCx",
    "name": "Look into My Eyes",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/496ca80250313aeb449dc8d5ee3f5aa23543265a",
    "artist_name": "Bone Thugs-N-Harmony",
    "image_url": "https://i.scdn.co/image/16affb729b8bd9c9754d892599c99289498f43cc",
    "artist_id": "5spEJXLwD1sKUdC2bnOHPg",
    "year": 1997
  },
  {
    "song_id": "7HP2lzaK7F55mj7J79bjf4",
    "name": "You Should Be Mine (Don't Waste Your Time)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/4cc2c6685000b7b5d52fbb1cc6905f6100da2aa5",
    "artist_name": "Brian McKnight",
    "image_url": "https://i.scdn.co/image/5e7895ed3a91d3fe6f80903343a72d0c27a4e2f6",
    "artist_id": "6k0IBR0lU42s2GYpNX7kA9",
    "year": 1997
  },
  {
    "song_id": "1vMdYJe6OweG41UjDzEYe8",
    "name": "Not Tonight",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/3feb423a3f158d98b5d9ac028dc8573490efd375",
    "artist_name": "Lil' Kim",
    "image_url": "https://i.scdn.co/image/bc53a2dc79de7c0323dd1229aec1221c918b15fa",
    "artist_id": "5tth2a3v0sWwV1C7bApBdX",
    "year": 1997
  },
  {
    "song_id": "1Y5IKAs7tuIqLHxUVjphup",
    "name": "Fly Like An Eagle",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/6b14fd1f0bbcc2e9a24ff936cf2e21cd788ad976",
    "artist_name": "Seal",
    "image_url": "https://i.scdn.co/image/a8966e239c09e630c32b0d2cad5d872d6a256725",
    "artist_id": "5GtMEZEeFFsuHY8ad4kOxv",
    "year": 1997
  },
  {
    "song_id": "5Pe9H08rkbraYpP3dllcfe",
    "name": "No Time",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/82c8d09520632b719d331bce8a27f6747469cfb6",
    "artist_name": "Lil' Kim",
    "image_url": "https://i.scdn.co/image/bc53a2dc79de7c0323dd1229aec1221c918b15fa",
    "artist_id": "5tth2a3v0sWwV1C7bApBdX",
    "year": 1997
  },
  {
    "song_id": "78JPL7uIfs6Imm57r1QETk",
    "name": "You Must Love Me",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/cb5b313714a3295a4ac2d2d1ffdb38d6a40735f2",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/789937a0964ebe960405069c15dcc3a0476bd05f",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1997
  },
  {
    "song_id": "5EOGZmuU56m3fFhqr4HCLe",
    "name": "Invisible Man",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/5a5df674dffb760bb7a8df7e0fcc78b75adfdde1",
    "artist_name": "98º",
    "image_url": "https://i.scdn.co/image/62843f7530f969c7c63b9bc996de61c4214169da",
    "artist_id": "6V03b3Y36lolYP2orXn8mV",
    "year": 1997
  },
  {
    "song_id": "1i7Gv6yN5aCyygveod0QDH",
    "name": "Where Do You Go",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/b9a57068ce10ecc02db4044d8ddad16823b42fe0",
    "artist_name": "No Mercy",
    "image_url": "https://i.scdn.co/image/52112fe1feee61f09bec07fedc7e702f70097593",
    "artist_id": "2tUGlReCZRMoRgl0IS79i3",
    "year": 1997
  },
  {
    "song_id": "0hvittV6XhveSZLTbFFE58",
    "name": "Da Dip",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/2775faf6ce9107d2c21c567bef805d9635be88e9",
    "artist_name": "Freaknasty",
    "image_url": "https://i.scdn.co/image/ac3b3bb798ff3926779072b6509d559575a47edf",
    "artist_id": "4YN800xgkpK7ctcQKzrYid",
    "year": 1997
  },
  {
    "song_id": "1q3C05jegoKRuU1biZtVxv",
    "name": "What's On Tonight",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/2eda4672c217da8a5620fada411ba5a0d74013fd",
    "artist_name": "Montell Jordan",
    "image_url": "https://i.scdn.co/image/c58d6e4061518a9e649d32567b7f9b30d1921616",
    "artist_id": "0iVrCROxeyon7MZUW3MfzT",
    "year": 1997
  },
  {
    "song_id": "6zP4MrlpNtGRKgecJ0B36p",
    "name": "Gotham City - Remix",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/f21c20264f82eb97e01a2913ef8a85cd0c12b1d1",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/37145297a059445de8ce1cdd846132d8fb3f7803",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 1997
  },
  {
    "song_id": "11Ki2bHWXTEug0DAfKcDDD",
    "name": "Mouth",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/034f82f041a88bc7cf44f22ec30ed685a0d9f1fc",
    "artist_name": "Merril Bainbridge",
    "image_url": "https://i.scdn.co/image/aab6094f311f3365193583235a8f9fea5c77702d",
    "artist_id": "0lSDlT2Z5EvUGNIl7WQ7k0",
    "year": 1997
  },
  {
    "song_id": "7GW6sIjwySPDVaLLV3w46J",
    "name": "Big Daddy",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/d28d77f52bd59383bc5cd16425f59aa77aa440c3",
    "artist_name": "Heavy D",
    "image_url": "https://i.scdn.co/image/0d9e139149bbfa54d3c4b3c95f3bb83b2144731d",
    "artist_id": "4AwNnh5SdnB3VGSZ0GPG4H",
    "year": 1997
  },
  {
    "song_id": "7fCLfDo3os5Q7UDPQV5fMZ",
    "name": "Someone",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/6b6417bb12fe90038f04759641ef1719de10ee15",
    "artist_name": "SWV",
    "image_url": "https://i.scdn.co/image/9a63bb59f7044e1fa98e63093726844d26d20dd2",
    "artist_id": "2NmK5FyrQ18HOPXq1UBzqa",
    "year": 1997
  },
  {
    "song_id": "5uC1sC1OmVKfU0m5opiY25",
    "name": "I Shot The Sheriff",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/2d33b0525060329295ab442524124fbaba68a586",
    "artist_name": "Warren G",
    "image_url": "https://i.scdn.co/image/b2944b9a71a19c101eb1072879061dbb2fde33f9",
    "artist_id": "2B4ZHz4QDWJTXPFPgO5peE",
    "year": 1997
  },
  {
    "song_id": "13vDH7LlES2hajU7yR07Mz",
    "name": "One More Time",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/923c4d879294a9d6ca9bad401dbf53505e55c509",
    "artist_name": "Real McCoy",
    "image_url": "https://i.scdn.co/image/92ecf8c12fe980e180730517cbabd6b3692f9e90",
    "artist_id": "2vRfKzjQYJQd67X8x49MOh",
    "year": 1997
  },
  {
    "song_id": "6im5Ts56ewHHJFu8qAE8X3",
    "name": "If It Makes You Happy",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/cc80d9d7956fe2a35754cdb626ec510ce72d6b61",
    "artist_name": "Sheryl Crow",
    "image_url": "https://i.scdn.co/image/868cded4378a32a84f1d2d0d46b85b0f738b0ed7",
    "artist_id": "4TKTii6gnOnUXQHyuo9JaD",
    "year": 1997
  },
  {
    "song_id": "5Pzv0z9cnnUSaiKMUHtYVh",
    "name": "Do You Know (What It Takes)",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/8c0aabf614cbdcfbc6bc30d6d40d80853e99f5d7",
    "artist_name": "Robyn",
    "image_url": "https://i.scdn.co/image/abc418f9a62f4f1d4199d6732f86ad0edfb05291",
    "artist_id": "6UE7nl9mha6s8z0wFQFIZ2",
    "year": 1997
  },
  {
    "song_id": "5pgZYnIqLXfAuzCajPJafv",
    "name": "Never Make A Promise - Radio Edit",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/6e138b8c7d5ad5c46a8ed58d8c8b7ddd9dcde14d",
    "artist_name": "Dru Hill",
    "image_url": "https://i.scdn.co/image/3ab6ac0b4a10d3b1d7e8f33a52dfe14fc78e19c2",
    "artist_id": "1255GTUKNCLCTvH9ctD4cT",
    "year": 1997
  },
  {
    "song_id": "5597gOdXYiVjyIo9MosRTm",
    "name": "Everyday Is A Winding Road",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/0a330edc9410ba0af701ef6626ca7266e4e4b85b",
    "artist_name": "Sheryl Crow",
    "image_url": "https://i.scdn.co/image/5bf5209eda65d0a86c9e7ddefd6365a96c8c05f6",
    "artist_id": "4TKTii6gnOnUXQHyuo9JaD",
    "year": 1997
  },
  {
    "song_id": "6igXETl1zKir06CA7NWYo2",
    "name": "Let It Go - Explicit Soundtrack",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/f579a041dcfb924d907da47b53f52e8d3137ae4d",
    "artist_name": "Ray J",
    "image_url": "https://i.scdn.co/image/807a67c21d41969d7121f8c03741c8501da21d0b",
    "artist_id": "6gbGGM0E8Q1hE511psqxL0",
    "year": 1997
  },
  {
    "song_id": "6NEzgZRfoHWIyAaJMxUlXl",
    "name": "Go the Distance",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/87c6a739d64b4072812eb03321c17fda7e8c9063",
    "artist_name": "Michael Bolton",
    "image_url": "https://i.scdn.co/image/293f974cbf38d9fc10ca07dcce6d2e280461409d",
    "artist_id": "6YHEMoNPbcheiWS2haGzkn",
    "year": 1997
  },
  {
    "song_id": "1Ju1n9V6nkCaT1ZepextXi",
    "name": "Free To Decide",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/157deba0aeb0fa2e89c182366c658c312a737364",
    "artist_name": "The Cranberries",
    "image_url": "https://i.scdn.co/image/fc10eebd3c9e9f5043a3fe5c543050eb2a74a531",
    "artist_id": "7t0rwkOPGlDPEhaOcVtOt9",
    "year": 1997
  },
  {
    "song_id": "2TIk4bAao8yA3hQ4G9kcsQ",
    "name": "I Don't Want To - Classic Radio Mix",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/c54a8d1fe271eac8c9f5e495bc705aa9aed117f6",
    "artist_name": "Toni Braxton",
    "image_url": "https://i.scdn.co/image/0ea43823d42a2c075ad61985333802ff6800a0eb",
    "artist_id": "3X458ddYA2YcVWuVIGGOYe",
    "year": 1997
  },
  {
    "song_id": "225xvV8r1yKMHErSWivnow",
    "name": "I Don't Want to Miss a Thing",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/496ef6af1c78774d7a3e92e2d74b9f55c255cebd",
    "artist_name": "Aerosmith",
    "image_url": "https://i.scdn.co/image/1c72184d21037dd1a0cc148a21303fbcc98c1e9a",
    "artist_id": "7Ey4PD4MYsKc5I2dolUwbH",
    "year": 1998
  },
  {
    "song_id": "1WYEDcS7WGjv0rG7rmMX3o",
    "name": "Bitter Sweet Symphony - 2004 Digital Remaster",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/78005d0f3dd46c36eaedafea9e4fbd409ef3c371",
    "artist_name": "The Verve",
    "image_url": "https://i.scdn.co/image/e5d5c4e55eed4ab2f0778721f6aa52c1cce5f8b9",
    "artist_id": "2cGwlqi3k18jFpUyTrsR84",
    "year": 1998
  },
  {
    "song_id": "1C0pmryC2MdXfa7MZ9uIrU",
    "name": "One Week",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/889f0af9e390ff0c1c17eafa6eaa7f41409a0016",
    "artist_name": "Barenaked Ladies",
    "image_url": "https://i.scdn.co/image/6b69a2f5a5265a1cb35525598e44fbe3d4e7fe0f",
    "artist_id": "0dEvJpkqhrcn64d3oI8v79",
    "year": 1998
  },
  {
    "song_id": "5WTxbyWTpoqhdxEN2szOnl",
    "name": "Everybody (Backstreet's Back) - Extended Version",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/03aef905fffd556bb1156343f07da9b3eed33e9d",
    "artist_name": "Backstreet Boys",
    "image_url": "https://i.scdn.co/image/91d62ebe855ad57ff570ae029ad22f57d82667bb",
    "artist_id": "5rSXSAkZ67PYJSvpUpkOr7",
    "year": 1998
  },
  {
    "song_id": "0weAUscowxeqDtpCgtbpgp",
    "name": "Gettin' Jiggy Wit It",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/32b1664b65b102ab59f1a6585e876355680b5c2f",
    "artist_name": "Will Smith",
    "image_url": "https://i.scdn.co/image/5784cad94dab5562462c4a3714b5b6979ecd5eff",
    "artist_id": "41qil2VaGbD194gaEcmmyx",
    "year": 1998
  },
  {
    "song_id": "3Uvx1TO0Kg5HgGPk58lHXv",
    "name": "How's It Going To Be",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/da0581323fb7aa6a2902648a479ff45330cec361",
    "artist_name": "Third Eye Blind",
    "image_url": "https://i.scdn.co/image/b2d702ebf5b8a148869f95a7895d48ebcf337601",
    "artist_id": "6TcnmlCSxihzWOQJ8k0rNS",
    "year": 1998
  },
  {
    "song_id": "31bf9SEOppLU6lQ85d8om6",
    "name": "Ghetto Supastar (That is What You Are)",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/9672b6b74850b9320be7577849f37a6931394bdd",
    "artist_name": "Pras",
    "image_url": "https://i.scdn.co/image/e1c728b8ec361298fa7125f997803e92bb7a699d",
    "artist_id": "0kJMPTXq7h3ztpDukSx5iD",
    "year": 1998
  },
  {
    "song_id": "2fyTojfPYs67KBWN4WYRX7",
    "name": "Too Close",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/5ab7c9fb3214a2b5ae4f6be5e9d58dac3aad1d30",
    "artist_name": "Next",
    "image_url": "https://i.scdn.co/image/7433313c5921731df38907118b14b7101c15dec7",
    "artist_id": "2MGT7CYlixSPxAnnkhLpaA",
    "year": 1998
  },
  {
    "song_id": "1nRwyxNsqCLeA17qR8Nfxx",
    "name": "Quit Playing Games (With My Heart)",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/07ce6043c256216e400d44fca15d37c49d67c776",
    "artist_name": "Backstreet Boys",
    "image_url": "https://i.scdn.co/image/91d62ebe855ad57ff570ae029ad22f57d82667bb",
    "artist_id": "5rSXSAkZ67PYJSvpUpkOr7",
    "year": 1998
  },
  {
    "song_id": "5YScXJKtefsgdskIy60N7A",
    "name": "Tubthumping",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/741430637f2e802ba48f3e43221189e98744adea",
    "artist_name": "Chumbawamba",
    "image_url": "https://i.scdn.co/image/e69cbed58994d20ac27f2e5f32487c3b39c451d5",
    "artist_id": "0TcYeHEK9sBtv7xPbKhzHz",
    "year": 1998
  },
  {
    "song_id": "2skmOCFU64Bg7Ytkgwliwe",
    "name": "All For You",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/7325ffa3fda0f865e8866e8eea6546b9a19cee72",
    "artist_name": "Sister Hazel",
    "image_url": "https://i.scdn.co/image/eb61357154ad85e0ef69e21801ff964b300b46ce",
    "artist_id": "7m60UAnbgFFNuJbmS6OxTk",
    "year": 1998
  },
  {
    "song_id": "27ncbKwESFYzgBo9RN9IXe",
    "name": "My Heart Will Go On - Love Theme from \"Titanic\"",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/553237518023c060f868a978e27baede2246e63c",
    "artist_name": "Céline Dion",
    "image_url": "https://i.scdn.co/image/2fe8ddf18096771fb7093acbe8368b2862979bcc",
    "artist_id": "4S9EykWXhStSc15wEx8QFK",
    "year": 1998
  },
  {
    "song_id": "0YT8MKXuaRdhOnDG9Jm1Dv",
    "name": "Frozen",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/897cfdcc5e3a314db769998c4d04e9e515402c3f",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1998
  },
  {
    "song_id": "5qGwqO0lkbBXw4xNfzT7SF",
    "name": "Spice Up Your Life",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/a727e42544303d5ae7cdfbfda008e62cc824d3e1",
    "artist_name": "Spice Girls",
    "image_url": "https://i.scdn.co/image/69095c23ba085fb906ae6c37496280bb40bbf486",
    "artist_id": "0uq5PttqEjj3IH1bzwcrXF",
    "year": 1998
  },
  {
    "song_id": "7wCH9DbgRs7ujHeqNy6oAS",
    "name": "You're Still The One",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/a31e9ca3ef8f842dcef9026092ecd137e22963d1",
    "artist_name": "Shania Twain",
    "image_url": "https://i.scdn.co/image/cc5293a9bf15d998061cab8127d13f6acb7f3937",
    "artist_id": "5e4Dhzv426EvQe3aDb64jL",
    "year": 1998
  },
  {
    "song_id": "1GrikfH0jDejDvrxo84n4P",
    "name": "Together Again",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/f8674cea8f294e79689c13144ce4d9e1342eba40",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/45cf79abc444aeb77e1a640847c17661fac31282",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1998
  },
  {
    "song_id": "5EYWGM3Ns3iYew8ws0FatB",
    "name": "This Kiss",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/d759220d5bd145a307ae1084a4dbec789cb9d4f4",
    "artist_name": "Faith Hill",
    "image_url": "https://i.scdn.co/image/10aa0417d58a27e4b0b5f41d1fb4bf6ff8d88093",
    "artist_id": "25NQNriVT2YbSW80ILRWJa",
    "year": 1998
  },
  {
    "song_id": "2ntj6zoYOXfdQDzxo2kHrD",
    "name": "Still Not A Player",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/7e17e8df2b36ece7822ca68b176f5dd448a8a95e",
    "artist_name": "Big Pun",
    "image_url": "https://i.scdn.co/image/51b26a10c6425522e0fb4c0998e704f186d15a92",
    "artist_id": "2Xu7q46Hf02xOoEIm4E1Qs",
    "year": 1998
  },
  {
    "song_id": "6Ee5Y6kCdlZfoFhyR8xPVt",
    "name": "Foolish Games",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/9cf5d99c5b25588ce7a0f198bfa75074416fa19e",
    "artist_name": "Jewel",
    "image_url": "https://i.scdn.co/image/6648739cae162d312c9b8554a654753cabf40d88",
    "artist_id": "6FbDoZnMBTdhhhLuJBOOqP",
    "year": 1998
  },
  {
    "song_id": "5xLDmkobOw674TLTBBmnuN",
    "name": "I Get Lonely",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/53c0b34e8955531e47de72234906c9545ea4e253",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/45cf79abc444aeb77e1a640847c17661fac31282",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 1998
  },
  {
    "song_id": "1qJemJSZsdqH2DEqXAZWNJ",
    "name": "I Don't Want To Wait",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/d65b13fe0105d11fa16a90a174ea62e34a035ef1",
    "artist_name": "Paula Cole",
    "image_url": "https://i.scdn.co/image/b6d7cb99ccf7b39f2cfd5b263740933fa85b1aa4",
    "artist_id": "08DLZqQd6XDAVteF9nSEJ1",
    "year": 1998
  },
  {
    "song_id": "6YdEfXtj0YI5IbiJqVyT1c",
    "name": "They Don't Know",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/d9d4172df7671e6c47163e0df3a51a7dded3f085",
    "artist_name": "Jon B.",
    "image_url": "https://i.scdn.co/image/155bde27b13fdf5a8fdf71fe624da749b60d7dce",
    "artist_id": "3SRJWVa6lZnqRHOyAHmDrX",
    "year": 1998
  },
  {
    "song_id": "0jyyCvDkMCEza2SpBSvllh",
    "name": "Feel So Good",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/5e3ba91d09ef9759e4a8e32771bcba63ced8d558",
    "artist_name": "Mase",
    "image_url": "https://i.scdn.co/image/3802e4320b5e7a7893095e7e700624777e14e5a1",
    "artist_id": "1wiBLzTI7z9RUwEpNPdFT6",
    "year": 1998
  },
  {
    "song_id": "2B9aYvlGefTIgKLhM6ia8t",
    "name": "Victory (feat. The Notorious B.I.G. & Busta Rhymes)",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/3f135c30fd814a4d9ad13bc887e2d8806348deb4",
    "artist_name": "Diddy",
    "image_url": "https://i.scdn.co/image/8387344b7e7affbaaef3faba758e708b75b712fe",
    "artist_id": "59wfkuBoNyhDMQGCljbUbA",
    "year": 1998
  },
  {
    "song_id": "6wAV49hRfOiCTHztORMy8Y",
    "name": "Crush",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/5cd301a233ef9df4182c94e14a0488a40350a58d",
    "artist_name": "Jennifer Paige",
    "image_url": "https://i.scdn.co/image/19d1f6b795e559d438503c540bd3ac984d9e5788",
    "artist_id": "26PDtWYDJ1KD0brukKsJH1",
    "year": 1998
  },
  {
    "song_id": "6SluaPiV04KOaRTOIScoff",
    "name": "Show Me Love - Radio Version",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/d2800a34b1751f6bfeb6bad31946d032e337f993",
    "artist_name": "Robyn",
    "image_url": "https://i.scdn.co/image/542f13ebacc23e02efc64ba4355a944a27ff92d6",
    "artist_id": "6UE7nl9mha6s8z0wFQFIZ2",
    "year": 1998
  },
  {
    "song_id": "5YTMRAT4yKgFrepF8Hi3mY",
    "name": "I Want You Back - Radio Edit",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/3716498c251b1cb94dc7913e589e53672d05e37c",
    "artist_name": "N Sync",
    "image_url": "https://i.scdn.co/image/b03198d474d164620a6afb6effeeaaa64b85ef93",
    "artist_id": "6Ff53KvcvAj5U7Z1vojB5o",
    "year": 1998
  },
  {
    "song_id": "69MmmKaqTGd2lBh1qNVpeP",
    "name": "Truly Madly Deeply",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/f78a1b213cbe5664e677811e4ad608c2f0f14f74",
    "artist_name": "Savage Garden",
    "image_url": "https://i.scdn.co/image/d4d404ab3d6aff0017841341a088becdb5460eb6",
    "artist_id": "3NRFinRTEqUCfaTTZmk8ek",
    "year": 1998
  },
  {
    "song_id": "2nqVVwUI2MJ7MaphpCF1P3",
    "name": "What You Want (feat. Total)",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/17b249dd5ce6d45de5738009d5184ebe62c77a31",
    "artist_name": "Mase",
    "image_url": "https://i.scdn.co/image/592403c38524862fc9c498b115620e283b2b7a5b",
    "artist_id": "1wiBLzTI7z9RUwEpNPdFT6",
    "year": 1998
  },
  {
    "song_id": "0jOXmNowR2j1hVpZMqnz9P",
    "name": "Gone Till November",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/a8dab3fca9c8e557925fa969997f47116f86125b",
    "artist_name": "Wyclef Jean",
    "image_url": "https://i.scdn.co/image/29007212e993c795748e80665df99a0cbe4ef666",
    "artist_id": "7aBzpmFXB4WWpPl2F7RjBe",
    "year": 1998
  },
  {
    "song_id": "3rR0XoCFCtPkGr1SOJGABW",
    "name": "I'll Be (45 Version)",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/7d6ee2b787b90e3181db15cc901c480642e411ec",
    "artist_name": "Edwin McCain",
    "image_url": "https://i.scdn.co/image/d4ad2b6f2ec584d0af6f4c3217275e98373e22a0",
    "artist_id": "0L6Gwm0JDrgIQJfjarWSUR",
    "year": 1998
  },
  {
    "song_id": "6I3LsZncKrJG9mwv12FCEg",
    "name": "Westside",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/c25f9597fc388ded8e4ed3e590f1f33fcb93f1c3",
    "artist_name": "TQ",
    "image_url": "https://i.scdn.co/image/f8f1e4399e04fbab773df6f5876f49f20d3a7735",
    "artist_id": "5a6nqHiBS9v3CxYOs7ttET",
    "year": 1998
  },
  {
    "song_id": "6W04EMrXtUum6yhcxkUGJJ",
    "name": "When the Lights Go Out - Radio Edit",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/84787469b8342cad206d871301fc6a3c46f09a01",
    "artist_name": "Five",
    "image_url": "https://i.scdn.co/image/adaa1b97dda32eeab335acc4ebd654f3ca77b77b",
    "artist_id": "6rEzedK7cKWjeQWdAYvWVG",
    "year": 1998
  },
  {
    "song_id": "4AE7Lj39VnSZNOmGH2iZaq",
    "name": "Do For Love",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/f1d8bc3e6f05ebfef8af255628812a7c26c24c56",
    "artist_name": "2Pac",
    "image_url": "https://i.scdn.co/image/280ae8368b0bda39fd9ed3de9582e3c34483380e",
    "artist_id": "1ZwdS5xdxEREPySFridCfh",
    "year": 1998
  },
  {
    "song_id": "51QxenFmXlJXUN9mpvxlaL",
    "name": "Nobody's Supposed to Be Here",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/84f87c5e80cee2751a88f70dee859a23fc52d0db",
    "artist_name": "Deborah Cox",
    "image_url": "https://i.scdn.co/image/05cd7ab79ea5fdde31e5da10da1eb4733d8faeb8",
    "artist_id": "601893mmW5hl1FBOykWZHG",
    "year": 1998
  },
  {
    "song_id": "1siDRjAa8MdxNoCHTiiQ7B",
    "name": "Been Around The World (feat. The Notorious B.I.G. & Mase)",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/3de3f39e43713643db5a852e4624388778e0b436",
    "artist_name": "Diddy",
    "image_url": "https://i.scdn.co/image/8387344b7e7affbaaef3faba758e708b75b712fe",
    "artist_id": "59wfkuBoNyhDMQGCljbUbA",
    "year": 1998
  },
  {
    "song_id": "0vVchT7htyhheP8anAnLMR",
    "name": "Ray Of Light",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/0cea6efcadba1525c93096d728bd4b8d74d784fd",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 1998
  },
  {
    "song_id": "2KVVKbrg81ipix7moSsfzI",
    "name": "Nice & Slow",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/b42841967fb6d118618210859a4b6873dab02dd9",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/118d5117f8bbda8403bced2589515ad3a08b1b45",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 1998
  },
  {
    "song_id": "5kHgrRO3hMDPehtH9VhYQc",
    "name": "No, No, No Part 2 (featuring Wyclef Jean)",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/12e7db8b202544c6bfe2da8ec507e56dbed890b1",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/ee1c1d8fbd0aa2d1adb3813080812c62c769a3af",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 1998
  },
  {
    "song_id": "2LuubKHueqlJbx0ylKGFmy",
    "name": "All My Life",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/41e2e2cb3590e1c45b32b27f06967e88806f49a0",
    "artist_name": "K-Ci & JoJo",
    "image_url": "https://i.scdn.co/image/23ee940bfc379756e9bb424377fc3eab34e7af0a",
    "artist_id": "05RZIdfz59ZW2FvFuwnmNK",
    "year": 1998
  },
  {
    "song_id": "1Ddf5oGQwHnRoacJIr8M1j",
    "name": "You Make Me Wanna...",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/2224e325541d43a4702a3abc9010a4d71498a1a6",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/118d5117f8bbda8403bced2589515ad3a08b1b45",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 1998
  },
  {
    "song_id": "4r0cvovFtVTXWtWgR1F4kd",
    "name": "Cruel Summer",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/6ff30a804fec83eca5bcf0c11c4f9267f2523e02",
    "artist_name": "Ace of Base",
    "image_url": "https://i.scdn.co/image/fea309dd06c549bdcb49bf74ffda7954eefb12d0",
    "artist_id": "5ksRONqssB7BR161NTtJAm",
    "year": 1998
  },
  {
    "song_id": "6fRLVZ4jKzuqyDPlIdbq9q",
    "name": "Butta Love",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/26194627926b26d9b35c99c838db8e17ca4fee79",
    "artist_name": "Next",
    "image_url": "https://i.scdn.co/image/8f558bf523726bcc3d1cb5008bda3d6aea6167da",
    "artist_id": "2MGT7CYlixSPxAnnkhLpaA",
    "year": 1998
  },
  {
    "song_id": "4oJEy4qDPwDk5ac26yEMeL",
    "name": "I Don't Ever Want To See You Again",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/7ac346fd31de857c54698d47e0a34a757e403c1c",
    "artist_name": "Uncle Sam",
    "image_url": "https://i.scdn.co/image/284701f776dc32b319b0e144cb1417e230aa6d0c",
    "artist_id": "6hs28hHY3L7zsfC44xszAj",
    "year": 1998
  },
  {
    "song_id": "1ZvS1DmW1cF0oknYGCVNK7",
    "name": "Candle In The Wind",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/dab5c538fd451a8ab566b43282db55ea2f18ff12",
    "artist_name": "Elton John",
    "image_url": "https://i.scdn.co/image/90104614b4f215c8acd203e9d8d82d6a1c1ab8cf",
    "artist_id": "3PhoLpVuITZKcymswpck5b",
    "year": 1998
  },
  {
    "song_id": "2dieHErJLwG7fOzD8WAtFO",
    "name": "Lookin' At Me [feat. Puff Daddy]",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/c27b361fb1709a2bf588e0b89582696e91a068df",
    "artist_name": "Mase",
    "image_url": "https://i.scdn.co/image/b325d6b224d4b22d2ec6930da74599e42804d97a",
    "artist_id": "1wiBLzTI7z9RUwEpNPdFT6",
    "year": 1998
  },
  {
    "song_id": "6xGAe69Vn07PnQQPQI083T",
    "name": "A Song For Mama",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/9061e3ce0fc789789d810d33c68eb532234c9568",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/030ad8ee793d05227a3d58af683b31d65df98d28",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1998
  },
  {
    "song_id": "3TS25nchaYIPb2Y2fw6cbb",
    "name": "My Love Is The Shhh!",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/36a2a6100ad9d852f4fd89cd2da3d55d52a3c417",
    "artist_name": "Somethin' For The People",
    "image_url": "https://i.scdn.co/image/e6293a61b4bb9e1e38188a2fff37b7478d0b4b0d",
    "artist_id": "1Hu56IprUt6GltqTkpvguI",
    "year": 1998
  },
  {
    "song_id": "6VWFrHAnLDyNUEJbJvpkZA",
    "name": "Are You Jimmy Ray?",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/fc5b3641f8d57faa71ac3223ad06f8afb5968a09",
    "artist_name": "Jimmy Ray",
    "image_url": "https://i.scdn.co/image/e29892036cc82fe352810f72b42c52e789afe01a",
    "artist_id": "19mELXwQE3LGemD3ioO0lO",
    "year": 1998
  },
  {
    "song_id": "34D19QbdMD2SL1pivaJzRX",
    "name": "Turn It Up/Fire It Up",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/b6af5526167e4c8586e978fbcca5fbcffb28783a",
    "artist_name": "Busta Rhymes",
    "image_url": "https://i.scdn.co/image/90b459622538f2f8a1063270bd1bf981312782b0",
    "artist_id": "1YfEcTuGvBQ8xSD1f53UnK",
    "year": 1998
  },
  {
    "song_id": "7xoN94HgqWTerSUbG5Hf9O",
    "name": "Because Of You",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/67135b1b4f6c12af20c9baededd8fb07270535c6",
    "artist_name": "98º",
    "image_url": "https://i.scdn.co/image/491c00ff173609b9dac1f234e351f6f783ac4d41",
    "artist_id": "6V03b3Y36lolYP2orXn8mV",
    "year": 1998
  },
  {
    "song_id": "4bDXOwif9Io5Ai62Pokzg7",
    "name": "Love You Down",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/ef694c999262aaf8861f61cb02bc5682b5fdcd04",
    "artist_name": "INOJ",
    "image_url": "https://i.scdn.co/image/ff60e555d712b7bb406adf0d553cec94f4f59396",
    "artist_id": "5IUvXYeMtVmp8YJWf0eqvf",
    "year": 1998
  },
  {
    "song_id": "0RwDPdfoitpL7Uk2pN7l1K",
    "name": "4 Seasons Of Loneliness",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/7b50bbd625c721c07d8c714debf29bf5a68ff11d",
    "artist_name": "Boyz II Men",
    "image_url": "https://i.scdn.co/image/030ad8ee793d05227a3d58af683b31d65df98d28",
    "artist_id": "6O74knDqdv3XaWtkII7Xjp",
    "year": 1998
  },
  {
    "song_id": "5kh2oynEmiwMl13WzxqLIR",
    "name": "Sex And Candy",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/12c7f94e64e7485844180a5e15e4c5c523d9ff72",
    "artist_name": "Marcy Playground",
    "image_url": "https://i.scdn.co/image/b728114936cbf33daffbd87ebd44b6513763708e",
    "artist_id": "7AQzXO3NPNQsI7oNu5rC3r",
    "year": 1998
  },
  {
    "song_id": "0ZWyPcjHsy6C3RrqPNAx9k",
    "name": "My Body - Remastered Single Version",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/22b9f8c19f9a71047264bea3bbf1976699fb91d8",
    "artist_name": "LSG",
    "image_url": "https://i.scdn.co/image/64ea3daaa45e3aa14c73f247df79a592f1f04101",
    "artist_id": "1J3nCyJ2KtGhh9JuywOrVD",
    "year": 1998
  },
  {
    "song_id": "33i1NxhGadaXhPgJaiD6ad",
    "name": "Déjà Vu (Uptown Baby)",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/2f31a22d90fd7f7aac4338716667acc714710abc",
    "artist_name": "Lord Tariq",
    "image_url": "https://i.scdn.co/image/8b18c788ab788ec7225ee0c57fe92f9db97d4d80",
    "artist_id": "5EC3MUN6vECf8sxUU9IVw6",
    "year": 1998
  },
  {
    "song_id": "6dAcEOlAWwczHIEoWZJXpO",
    "name": "Let's Ride",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/87607e2dbc72d9288dc1992536b4293602c4fe13",
    "artist_name": "Montell Jordan",
    "image_url": "https://i.scdn.co/image/d8f0458cec324d6d32be1a485cfaec1e3023298c",
    "artist_id": "0iVrCROxeyon7MZUW3MfzT",
    "year": 1998
  },
  {
    "song_id": "5919i3Sp9U2IE5m7hhlgSw",
    "name": "Say It",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/c326e2be1b46b387e292d2336f80753a45b16d1d",
    "artist_name": "Voices of Theory",
    "image_url": "https://i.scdn.co/image/2eb3a51e98fe70001ae9c66f99abdd7e20fe2597",
    "artist_id": "7xojBGlLd0SGscMIL31gdV",
    "year": 1998
  },
  {
    "song_id": "4DDhR1Zd6WYbNiCd13Uu4i",
    "name": "Thinkin' Bout It",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/9e3dbb505a28604ee277b96fb16549ccab73ef47",
    "artist_name": "Gerald Levert",
    "image_url": "https://i.scdn.co/image/1989a1013dbee772396314a3e1c0a14afccbe33e",
    "artist_id": "7BLOa6FxCbMnowidkQfLIA",
    "year": 1998
  },
  {
    "song_id": "4wtHbIS26FOtpjMkaQQ8Zk",
    "name": "Kiss The Rain",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/9b19fc2b7e06dc37547e955282acaf4f7717ddb5",
    "artist_name": "Billie Myers",
    "image_url": "https://i.scdn.co/image/d6f71cf7e10b88f58b42cf1ca939e3c10c83177f",
    "artist_id": "2ZqXK2gIDbfE7aOvkNObPW",
    "year": 1998
  },
  {
    "song_id": "2NXZJ3qWUbAnoJA2Be1Xlj",
    "name": "I Do",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/33fa20ae6732fda7c55f6c00ef315fa355ba69d0",
    "artist_name": "Lisa Loeb",
    "image_url": "https://i.scdn.co/image/3736ce4765e6db13339cae26f534864d98b40c78",
    "artist_id": "1TMMyiSsNzmRiZCAkVLVb2",
    "year": 1998
  },
  {
    "song_id": "6zZJkvtu3N9GhbSQdVzsKT",
    "name": "I Still Love You",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/493d49068f4b06554d2faafab6c77ade17c8b3b9",
    "artist_name": "Next",
    "image_url": "https://i.scdn.co/image/8f558bf523726bcc3d1cb5008bda3d6aea6167da",
    "artist_id": "2MGT7CYlixSPxAnnkhLpaA",
    "year": 1998
  },
  {
    "song_id": "6Zyk31Yyk7AUU1TTJn7plb",
    "name": "The Arms of the One Who Loves You",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/242a56ab05878427b17845b726e50fc76d64d333",
    "artist_name": "Xscape",
    "image_url": "https://i.scdn.co/image/454af1abeaa92e5600bf30b4b78c01b419e81d76",
    "artist_id": "5z2cHsOmmmWcAPNG1oEhw0",
    "year": 1998
  },
  {
    "song_id": "4eCPhyxoEVR5NiDEjBYPvH",
    "name": "How Deep Is Your Love - Single Version",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/47774ad1da83665a7627f025745cfb8c7d140af7",
    "artist_name": "Dru Hill",
    "image_url": "https://i.scdn.co/image/4938c694b88ccd7fe74823f64f67c70290bc2858",
    "artist_id": "1255GTUKNCLCTvH9ctD4cT",
    "year": 1998
  },
  {
    "song_id": "797yAHy8UMEiPJC9bc0H6k",
    "name": "My All",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/8e404e37074bfc827a808f3685231daad6d62bf7",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/a068cb73dd0360c9639cdec25e3ae1c7dd4adaaa",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1998
  },
  {
    "song_id": "0tmmvIUPYYP3W1Z06bvwkF",
    "name": "Sock It 2 Me - Amended Version",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/d6befb9a059cbcc71bc95a09fe916c6dd19608d6",
    "artist_name": "Missy Elliott & Da Brat",
    "image_url": "https://i.scdn.co/image/5c623322ee9d2fd5beb128f78589d74b00879193",
    "artist_id": "4rqjq6UghlBoP2LLjrLN6G",
    "year": 1998
  },
  {
    "song_id": "4bDkk5cXzzRnxC2iDLussL",
    "name": "Adia",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/184c5210f1a40da036dffa01e681fdb06b7cef96",
    "artist_name": "Sarah McLachlan",
    "image_url": "https://i.scdn.co/image/b01268bba8311f86de9517cf9e3e653d8351e2fe",
    "artist_id": "4NgNsOXSwIzXlUIJcpnNUp",
    "year": 1998
  },
  {
    "song_id": "5I0Pt0YFaShP1OoXPyUnX8",
    "name": "Time After Time",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/88404fc36153f0710637b81f4933ab3e44d0b829",
    "artist_name": "INOJ",
    "image_url": "https://i.scdn.co/image/0638e9fa1b2206cb677616eeb8b61715c77f2edb",
    "artist_id": "5IUvXYeMtVmp8YJWf0eqvf",
    "year": 1998
  },
  {
    "song_id": "2cXWKSIbNwqxz1TEKdOupS",
    "name": "Money, Power and Respect - Greatest Hits Version",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/022fff8e2585a62fd348915e62a6d7bc23fe6b40",
    "artist_name": "The Lox featuring DMX and Lil' Kim",
    "image_url": "https://i.scdn.co/image/4f94ecd77ff7d25e942ea4cdabf81c291efb5715",
    "artist_id": "29apiatujisitrDSUwySQ3",
    "year": 1998
  },
  {
    "song_id": "7v3IfbATd71qapVuZ190L5",
    "name": "My Way",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/60fde5db64e5110e233a0f36c7b522a477228a32",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/118d5117f8bbda8403bced2589515ad3a08b1b45",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 1998
  },
  {
    "song_id": "35KcXRWo7BTYdJiyMOy2Zu",
    "name": "Swing My Way - Remix",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/efacd285adca3c6dfe1f5fbd7faaaad729da828c",
    "artist_name": "K.P. & Envyi",
    "image_url": "https://i.scdn.co/image/90b459622538f2f8a1063270bd1bf981312782b0",
    "artist_id": "2YNgNtNUJlJpCmWgz3kHh5",
    "year": 1998
  },
  {
    "song_id": "6nC75SPjkzY3CGvBckFD0w",
    "name": "Never Ever - All Star Remix",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/a4ad807d667a04f13206e081e969fda989fbbcf1",
    "artist_name": "All Saints",
    "image_url": "https://i.scdn.co/image/6604cd35cb0c11a86807e53b6a83f16d446f62fb",
    "artist_id": "5TDVKqW9uhqGjwwwKGuma4",
    "year": 1998
  },
  {
    "song_id": "51lBC5ABJMVg7UClbowjki",
    "name": "I Got The Hook Up - Edited",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/e7556d114c4ccd87d17240692d0ef99ea32e47ca",
    "artist_name": "Master P",
    "image_url": "https://i.scdn.co/image/4e0248192c481919bc8da16249edeca25b9a74e2",
    "artist_id": "7zICaxnDB9ZprDSiFpvbbW",
    "year": 1998
  },
  {
    "song_id": "0zSaR3TpVlqfQzbSCbhWfU",
    "name": "The Party Continues - Video Version -Clean",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/2912acaf59518f69c75e50cc256b76adf308fcd2",
    "artist_name": "Jermaine Dupri featuring Da Brat & Usher",
    "image_url": "https://i.scdn.co/image/c9ffa261eeab8c7ec4001139eb7caeab3eccb270",
    "artist_id": "4s7fAqPTZSM29V2IVCFT6L",
    "year": 1998
  },
  {
    "song_id": "6ZIuoDs3i1KjKurJf9miSs",
    "name": "I Will Come To You",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/b845befdfc820e6523cfda1648eedc1a2055532b",
    "artist_name": "Hanson",
    "image_url": "https://i.scdn.co/image/1f6a583a647d08a285f8538884e7ae9c69c2ed6e",
    "artist_id": "0SdiiPkr02EUdekHZJkt58",
    "year": 1998
  },
  {
    "song_id": "6pGMEaR9abfB03jcoFSQID",
    "name": "Make 'Em Say Uhh - Edited;2005 Digital Remaster",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/c482e3e450a96627cd9fa815d56157f60e9a8915",
    "artist_name": "Master P",
    "image_url": "https://i.scdn.co/image/c7511e4248e198949b745c0a3947c6a5858db38c",
    "artist_id": "7zICaxnDB9ZprDSiFpvbbW",
    "year": 1998
  },
  {
    "song_id": "5xzJNGSd8AcyDO5HAwqjwc",
    "name": "It's All About Me",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/7b1156fc8dc57dc68b30bf34ffcac8cbd71961ff",
    "artist_name": "Mya",
    "image_url": "https://i.scdn.co/image/7b728b182ff5d6c1b1b91dcdc2e19d594364ef80",
    "artist_id": "6lHL3ubAMgSasKjNqKb8HF",
    "year": 1998
  },
  {
    "song_id": "3hA1SpBM4kiw5Qn0QVT6Nb",
    "name": "Dangerous - Amended Version",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/68c771d90c894e80e35c97a0495dc6bf4c2a8d00",
    "artist_name": "Busta Rhymes",
    "image_url": "https://i.scdn.co/image/7ceed2c65a131955dcb28cbe53cee47795515b82",
    "artist_id": "1YfEcTuGvBQ8xSD1f53UnK",
    "year": 1998
  },
  {
    "song_id": "5e9YtPAN6hnCQKwHkKaOgd",
    "name": "In Da Street - feat. Missy \"Misdemeanor\" Elliott & Mocha",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/99958b8466b1f251ad4d13016f48ce1013ad7195",
    "artist_name": "Nicole",
    "image_url": "https://i.scdn.co/image/0be421997d803cc1ffddd0e7ecf7359557169bc6",
    "artist_id": "0aBAAnXItgKnXdccW1INw5",
    "year": 1998
  },
  {
    "song_id": "3l5xmQanfESQMy31erDoJF",
    "name": "A Rose Is Still a Rose",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/1db6afa90bdcfcf1227a92d1942f72f9c970e7cb",
    "artist_name": "Aretha Franklin",
    "image_url": "https://i.scdn.co/image/dc1997aeeaf79b4dc73ea6dc1c22540d948f1b2a",
    "artist_id": "7nwUJBm0HE4ZxD3f5cy5ok",
    "year": 1998
  },
  {
    "song_id": "0mVLmRNJyloNRTKZ1bdoJR",
    "name": "Friend Of Mine",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/79a3969324428cd5763e47501479e93abb82d756",
    "artist_name": "Kelly Price",
    "image_url": "https://i.scdn.co/image/73020a6bf18a2f19713878bd0e3df7bb17acc0a0",
    "artist_id": "49FeZO3eSrJs7oH7lYLU1r",
    "year": 1998
  },
  {
    "song_id": "7C6SAjOGDoARWqNZjDCAUP",
    "name": "Father",
    "popularity": 3,
    "preview_url": "https://p.scdn.co/mp3-preview/17e1fdd068a1b23e23fe080194da0e0c54dcf0eb",
    "artist_name": "LL Cool J",
    "image_url": "https://i.scdn.co/image/aee13ff064d523a416d3eb15d57fbed4844cc82d",
    "artist_id": "1P8IfcNKwrkQP5xJWuhaOC",
    "year": 1998
  },
  {
    "song_id": "6vrUTGn5p8IrfTZ0J6sIVM",
    "name": "Iris",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/573eb166d227c4a834c66024242dec2146c16bc1",
    "artist_name": "The Goo Goo Dolls",
    "image_url": "https://i.scdn.co/image/f66195f98d32ffb0f1fcca0ea9e69e2794ec6742",
    "artist_id": "2sil8z5kiy4r76CRTXxBCA",
    "year": 1999
  },
  {
    "song_id": "1KGi9sZVMeszgZOWivFpxs",
    "name": "No Scrubs",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/6d59fcf920644e827b2575d58c19f2087e85df6d",
    "artist_name": "TLC",
    "image_url": "https://i.scdn.co/image/10a389adebe698fef21f58d3c2d86f8b14458a28",
    "artist_id": "0TImkz4nPqjegtVSMZnMRq",
    "year": 1999
  },
  {
    "song_id": "6e40mgJiCid5HRAGrbpGA6",
    "name": "I Want It That Way",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/8b8e07f6566d960cb853cc5de37777de677c20c5",
    "artist_name": "Backstreet Boys",
    "image_url": "https://i.scdn.co/image/72fa1290350b648028c634a98d5644167361e5b2",
    "artist_id": "5rSXSAkZ67PYJSvpUpkOr7",
    "year": 1999
  },
  {
    "song_id": "2goLsvvODILDzeeiT4dAoR",
    "name": "Believe",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/5a418e2efeaeb2c013685c1c148f09769ff0cd95",
    "artist_name": "Cher",
    "image_url": "https://i.scdn.co/image/3bf0990d84ba7429e47b6077ad81379144eb4b7d",
    "artist_id": "72OaDtakiy6yFqkt4TsiFt",
    "year": 1999
  },
  {
    "song_id": "3MjUtNVVq3C8Fn0MP3zhXa",
    "name": "...Baby One More Time",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/f4c3182b6dba991391d3d5d5ba712b3d340b0a92",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/0b0e010c769b4926a0a0ed3193fb8771d1fb9cf9",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 1999
  },
  {
    "song_id": "75fuzn623bYyKISKlFHdCw",
    "name": "Kiss Me",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/1a80c4ac10af50afb76210830bbc76dcbb6ea2cd",
    "artist_name": "Sixpence None The Richer",
    "image_url": "https://i.scdn.co/image/f9597739e2a6cd3f49cab0e3e78ec8b2ad419172",
    "artist_id": "0lJlKQvuM2Sd9DPPyUXcHg",
    "year": 1999
  },
  {
    "song_id": "0Ph6L4l8dYUuXFmb71Ajnd",
    "name": "Livin' la Vida Loca",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/6c41a412f7a528d0ea298a9641fcb8f52fe47b31",
    "artist_name": "Ricky Martin",
    "image_url": "https://i.scdn.co/image/7bb4ce8b35d12a1d4c071d2586cc65899e950198",
    "artist_id": "7slfeZO9LsJbWgpkIoXBUJ",
    "year": 1999
  },
  {
    "song_id": "3i3GeK0qLQybu4ah42YmCY",
    "name": "Smooth",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/50e8d558d5a75301bdd23d2b814e60615b1aeb59",
    "artist_name": "Santana",
    "image_url": "https://i.scdn.co/image/94bd261f86af0e9ded540c77373290f5845448ce",
    "artist_id": "6GI52t8N5F02MxU0g5U69P",
    "year": 1999
  },
  {
    "song_id": "0n0eMsX7WgEtc7dlscbuKU",
    "name": "Fly Away",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/478625cefec78f52fa52d1d98f963853c526e4e1",
    "artist_name": "Lenny Kravitz",
    "image_url": "https://i.scdn.co/image/d7dab70b4163c390399f85b2f27932f5554e4fe0",
    "artist_id": "5gznATMVO85ZcLTkE9ULU7",
    "year": 1999
  },
  {
    "song_id": "7mnGQesk1TzQLzQ9bYWZPR",
    "name": "She's so High",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/daf4ecfbba3c5e7600bd744108d2a40a1a1e4e2c",
    "artist_name": "Tal Bachman",
    "image_url": "https://i.scdn.co/image/bf7c58f426b748ca5facb7dc7104e0774abf2805",
    "artist_id": "3KEb1kbIZN5jumsjFEWgSW",
    "year": 1999
  },
  {
    "song_id": "0eG5CqXwJpa5MBjYyg1lRm",
    "name": "Slide",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/267510f9ee1f007ffe8df789b3ff7c33e5ea4d2a",
    "artist_name": "The Goo Goo Dolls",
    "image_url": "https://i.scdn.co/image/f66195f98d32ffb0f1fcca0ea9e69e2794ec6742",
    "artist_id": "2sil8z5kiy4r76CRTXxBCA",
    "year": 1999
  },
  {
    "song_id": "2Uu8IiLkLY0UXhCHka4Dlr",
    "name": "Doo Wop (That Thing)",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/29b1f240c4a100953b4bec02832b828353376239",
    "artist_name": "Lauryn Hill",
    "image_url": "https://i.scdn.co/image/43660a1f9fd70e3463a782e5f7948a54f4e4cc99",
    "artist_id": "3elP7hppV9gTjSKucnXELy",
    "year": 1999
  },
  {
    "song_id": "1t2Hq2WIBcZINOEnK6mdJG",
    "name": "Save Tonight",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/d7529b05ff32735d51284d9e5aece6dc089772ef",
    "artist_name": "Eagle-Eye Cherry",
    "image_url": "https://i.scdn.co/image/f545741f9d9406f79767c56164c4bf165c0eb9b1",
    "artist_id": "3ngKsDXZAssmljeXCvEgOe",
    "year": 1999
  },
  {
    "song_id": "6e8Ou0wiqAzIpWb2eSxll8",
    "name": "Miami",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/8c3b09e12747667e58e112c41715bbad0ea6fb22",
    "artist_name": "Will Smith",
    "image_url": "https://i.scdn.co/image/5784cad94dab5562462c4a3714b5b6979ecd5eff",
    "artist_id": "41qil2VaGbD194gaEcmmyx",
    "year": 1999
  },
  {
    "song_id": "5Tl0HJvynZtKdSUMKbFVVX",
    "name": "Hard Knock Life (Ghetto Anthem)",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/5d2f84cfd2481d601dee726145547c405060357b",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/b89504f4f922a5f645a4a9fc72fcde8011cca1e4",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 1999
  },
  {
    "song_id": "0jsANwwkkHyyeNyuTFq2XO",
    "name": "Heartbreaker - Album Version (Featuring Jay-Z)",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/3d0fe22c9df0e17af7a6c5434c0f22d288ea99e4",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/6e32c77ac837c107375dcbba8cd4b55ad094c47d",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 1999
  },
  {
    "song_id": "6Neq7tjnknt4URNI8txFL4",
    "name": "Every Morning - Remastered",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/1a359d0925356f1c2d60bb66df15f89f41646445",
    "artist_name": "Sugar Ray",
    "image_url": "https://i.scdn.co/image/b3cd281121c54cd413b1ee03a99cfa289321dfaf",
    "artist_id": "4uN3DsfENc7dp0OLO0FEIb",
    "year": 1999
  },
  {
    "song_id": "1Oi2zpmL81Q0yScF1zxaC0",
    "name": "Bills, Bills, Bills",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/6c02f3fdab157ae0b74adb83ae14a1f7f767ae04",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/8d4b328d0a3375410665e362a725dc3576e7dfb3",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 1999
  },
  {
    "song_id": "19fm0eNKTeg1JbHCHwPhLe",
    "name": "All Star",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/c5beb7c266f63d5b5de9112807160ae68f939118",
    "artist_name": "Smash Mouth",
    "image_url": "https://i.scdn.co/image/4b8f6e28008bf89fc697627b5c634c2a48038897",
    "artist_id": "2iEvnFsWxR0Syqu2JNopAd",
    "year": 1999
  },
  {
    "song_id": "1Dx8rIZaXzZPvUQRF2j9hB",
    "name": "Sweet Lady",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/25fbe659c3d67df16070515e77c1743e568cf847",
    "artist_name": "Tyrese",
    "image_url": "https://i.scdn.co/image/2422a7e933f0a863dec55f2589cd6a75f9209318",
    "artist_id": "08p7B5OtcUuVblvkQIlBhJ",
    "year": 1999
  },
  {
    "song_id": "2XCTU3DJ9W6glWSkM2uQTS",
    "name": "Ex-Factor",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/72acbf0f481aa559239a42360bdae406934412ac",
    "artist_name": "Lauryn Hill",
    "image_url": "https://i.scdn.co/image/43660a1f9fd70e3463a782e5f7948a54f4e4cc99",
    "artist_id": "3elP7hppV9gTjSKucnXELy",
    "year": 1999
  },
  {
    "song_id": "0KvLsZYwodakWxOQUYAR5I",
    "name": "That Don't Impress Me Much",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/dd33bae7aa870b5a5343d715366033265805b018",
    "artist_name": "Shania Twain",
    "image_url": "https://i.scdn.co/image/cc5293a9bf15d998061cab8127d13f6acb7f3937",
    "artist_id": "5e4Dhzv426EvQe3aDb64jL",
    "year": 1999
  },
  {
    "song_id": "3kI8fSpOQmL5ax63lBOuH3",
    "name": "Black Balloon",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/351a13a9e95918676a51f83f320aa9c1e37b81e9",
    "artist_name": "The Goo Goo Dolls",
    "image_url": "https://i.scdn.co/image/f66195f98d32ffb0f1fcca0ea9e69e2794ec6742",
    "artist_id": "2sil8z5kiy4r76CRTXxBCA",
    "year": 1999
  },
  {
    "song_id": "0BUoLE4o9eVahDHvTqak67",
    "name": "Unpretty",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/376c547de123d1f50eb660871aa21166e02af8a5",
    "artist_name": "TLC",
    "image_url": "https://i.scdn.co/image/10a389adebe698fef21f58d3c2d86f8b14458a28",
    "artist_id": "0TImkz4nPqjegtVSMZnMRq",
    "year": 1999
  },
  {
    "song_id": "5AMvBCtX2rspUdoeJ9IsPN",
    "name": "Wild Wild West - Album Version With Intro",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/537469c1c1a73c7355e435439eedd17fae25fda2",
    "artist_name": "Will Smith",
    "image_url": "https://i.scdn.co/image/83df80350bc09f5c5b0f560b890c198fc63cc80c",
    "artist_id": "41qil2VaGbD194gaEcmmyx",
    "year": 1999
  },
  {
    "song_id": "7ffwRz8lZyDOE4Vj58Lo72",
    "name": "I Need to Know",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/0d0a57db4e9e109a580d7a51479770228c97d6f5",
    "artist_name": "Marc Anthony",
    "image_url": "https://i.scdn.co/image/1c92865f6a838bd5b958a3deb7bf20e621b369ea",
    "artist_id": "4wLXwxDeWQ8mtUIRPxGiD6",
    "year": 1999
  },
  {
    "song_id": "2zbjlcLi1VQWqDNYdhyx1l",
    "name": "So Anxious",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/38162fe9fc71fbb96ee6c5b120ba9c0b59eb9901",
    "artist_name": "Ginuwine",
    "image_url": "https://i.scdn.co/image/523e8bd81c0d53408f76f72718df7b3f0e0c585a",
    "artist_id": "7r8RF1tN2A4CiGEplkp1oP",
    "year": 1999
  },
  {
    "song_id": "6NcHJ23mLQKnVxQcOmT7JW",
    "name": "Someday - Remastered",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/c963e2f905e3a359ba2b5d80bb4bff16ea2e4d73",
    "artist_name": "Sugar Ray",
    "image_url": "https://i.scdn.co/image/b3cd281121c54cd413b1ee03a99cfa289321dfaf",
    "artist_id": "4uN3DsfENc7dp0OLO0FEIb",
    "year": 1999
  },
  {
    "song_id": "4LGMSdeKOUoy5Wf75Je0Hl",
    "name": "Can I Get A...",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/26a04dde8c76bf647ceabd24d6b6cb59ed12dbf7",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/b89504f4f922a5f645a4a9fc72fcde8011cca1e4",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 1999
  },
  {
    "song_id": "5dHTF27CQvA7ktMvytr0Qc",
    "name": "Write This Down",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/67f05ecf0abf03cfef52b1ae083fed2ae82a8b9c",
    "artist_name": "George Strait",
    "image_url": "https://i.scdn.co/image/eb756842800fb3574f0822add0ac86df1900cb3d",
    "artist_id": "5vngPClqofybhPERIqQMYd",
    "year": 1999
  },
  {
    "song_id": "2bbeNsFmjZqdoDhjLsKNWe",
    "name": "Bailamos - Wild Wild West/Soundtrack Version",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/56ea9c8de9ce7e9d102cd19bb8237c729fb83a79",
    "artist_name": "Enrique Iglesias",
    "image_url": "https://i.scdn.co/image/45791cf4aa06a1a90b13c4648dbc0bf87aa35af1",
    "artist_id": "7qG3b048QCHVRO5Pv1T5lw",
    "year": 1999
  },
  {
    "song_id": "7uYroF9RRrhRJBrigRvB6H",
    "name": "Summer Girls",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/d15486df69f34a65a9e96243a8fb8bcaa00414d1",
    "artist_name": "Lyte Funkie Ones",
    "image_url": "https://i.scdn.co/image/f49283039e4687efb5808c1e37ae4b3b6f8e773a",
    "artist_id": "3sjPSxiGGNVoioDgWASynD",
    "year": 1999
  },
  {
    "song_id": "70L4pGAmYv0pTADrgsIxpI",
    "name": "Lullaby",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/270483fa64979d6f67e2b78ee03524fe39e17d1a",
    "artist_name": "Shawn Mullins",
    "image_url": "https://i.scdn.co/image/f910450aed201d1f993e57604d76a51076cc488c",
    "artist_id": "4TROLx8HvrnfGGNo2ngL2N",
    "year": 1999
  },
  {
    "song_id": "6Agiwgwq5BtflpNynGYsUv",
    "name": "Man! I Feel Like A Woman!",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/1c881234c100f10d85b77ef49c7110a30943b54f",
    "artist_name": "Shania Twain",
    "image_url": "https://i.scdn.co/image/f5bb081814ccb7580030b681ffdb6bb724d9111a",
    "artist_id": "5e4Dhzv426EvQe3aDb64jL",
    "year": 1999
  },
  {
    "song_id": "4agp6oHofabdUedr0B1krj",
    "name": "Steal My Sunshine - Single Version",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/15aeeaac04ce1a9ddd070a386f92e8cf1b9e72bf",
    "artist_name": "Len",
    "image_url": "https://i.scdn.co/image/133b7ebf7d3c14e0d0b6e98d2bb55499b1be8af7",
    "artist_id": "0nyc9SZGLITSOJASmTZsnZ",
    "year": 1999
  },
  {
    "song_id": "4VSyH8AkIt3kaR5xIPFVVi",
    "name": "Where My Girls At",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/79b0f3c251008769a6d5d5e9098d2843065f6785",
    "artist_name": "702",
    "image_url": "https://i.scdn.co/image/665c6d1f4365300434578c40276bb42e6bf34b2a",
    "artist_id": "2S8UlyXW4JjjHcbeg8ddIo",
    "year": 1999
  },
  {
    "song_id": "58s4iqgXFzXhBndBkg2AaD",
    "name": "Spend My Life With You",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/6c3919ae75cd963924fb31a6f14d22deeca2e308",
    "artist_name": "Eric Benét",
    "image_url": "https://i.scdn.co/image/095f88f82c70cc318aa1c024b745266306605c8f",
    "artist_id": "1kjO72M26jZkv0aaGxJaov",
    "year": 1999
  },
  {
    "song_id": "5wDPLdzR7eivEl4Dkd7tST",
    "name": "My Love Is Your Love",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/fb482d18dd3711b8e6c2b5ee9bffaafafbddd7ab",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/1a45a2b6266e337b71d72d120800184ab2138657",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1999
  },
  {
    "song_id": "3hlhefxgyp4MDnN6C2dQ5H",
    "name": "All I Have to Give",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/e49f192d6a3f85a00cac0edcbc429e23704bab97",
    "artist_name": "Backstreet Boys",
    "image_url": "https://i.scdn.co/image/91d62ebe855ad57ff570ae029ad22f57d82667bb",
    "artist_id": "5rSXSAkZ67PYJSvpUpkOr7",
    "year": 1999
  },
  {
    "song_id": "0CjrIEPmqIKnBHy99hun3v",
    "name": "Back At One",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/63ff04e1de6713e488623f80ac7a147739d1dd20",
    "artist_name": "Brian McKnight",
    "image_url": "https://i.scdn.co/image/48b9518470274f350de4d3750fb4f811fe26ce42",
    "artist_id": "6k0IBR0lU42s2GYpNX7kA9",
    "year": 1999
  },
  {
    "song_id": "1UI0l2L66HJ9AtoEOlHzv4",
    "name": "Sometimes",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/935de0cd7bf69b5fb405a991201b28006f704dfb",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/0b0e010c769b4926a0a0ed3193fb8771d1fb9cf9",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 1999
  },
  {
    "song_id": "4ekUwwCPIKT1ef5GllLSk1",
    "name": "Last Kiss",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/78aac94108c5696eee853d0aafeec0a8a1672e1b",
    "artist_name": "Pearl Jam",
    "image_url": "https://i.scdn.co/image/7cf2cd95cc61707a626b157495fdbde4af7ea415",
    "artist_id": "1w5Kfo2jwwIPruYS2UWh56",
    "year": 1999
  },
  {
    "song_id": "1Nchgvmv9DUrB8X1n442EH",
    "name": "Hands",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/c7b5774b26d2ba098c715933c878121c3308d158",
    "artist_name": "Jewel",
    "image_url": "https://i.scdn.co/image/2537094cecac488c82498cddcfa3fc58b925a280",
    "artist_id": "6FbDoZnMBTdhhhLuJBOOqP",
    "year": 1999
  },
  {
    "song_id": "51QxenFmXlJXUN9mpvxlaL",
    "name": "Nobody's Supposed to Be Here",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/84f87c5e80cee2751a88f70dee859a23fc52d0db",
    "artist_name": "Deborah Cox",
    "image_url": "https://i.scdn.co/image/05cd7ab79ea5fdde31e5da10da1eb4733d8faeb8",
    "artist_id": "601893mmW5hl1FBOykWZHG",
    "year": 1999
  },
  {
    "song_id": "0OMmiWMwsCNtpQ5aP6fdp9",
    "name": "These Are The Times",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/719a7bb75ebdce6d1752d1d03a9751358c6a2c53",
    "artist_name": "Dru Hill",
    "image_url": "https://i.scdn.co/image/46185056e38a0496fe8cb126d28fb8a1bcbec063",
    "artist_id": "1255GTUKNCLCTvH9ctD4cT",
    "year": 1999
  },
  {
    "song_id": "5it4Zopso9OdoirmTqGZA4",
    "name": "I Do (Cherish You)",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/127e696401d7d1b19a0c1931ec4e6689a9a5108d",
    "artist_name": "98º",
    "image_url": "https://i.scdn.co/image/491c00ff173609b9dac1f234e351f6f783ac4d41",
    "artist_id": "6V03b3Y36lolYP2orXn8mV",
    "year": 1999
  },
  {
    "song_id": "5tOjRq5shlEdm15nmJBq7Z",
    "name": "Out Of My Head",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/d8d23a79f1a5f429d1fd59cf0b516c2a8f39e267",
    "artist_name": "Fastball",
    "image_url": "https://i.scdn.co/image/287754a39c0bd16e95c530d1f941c5bcc46f87e9",
    "artist_id": "7FtVJzRtpQpU61nBwB7cKN",
    "year": 1999
  },
  {
    "song_id": "00FROhC5g4iJdax5US8jRr",
    "name": "Satisfy You",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/e107ffe34a586fab257d8683e6e47ff4cb48d117",
    "artist_name": "Diddy",
    "image_url": "https://i.scdn.co/image/fc3b44d0b72a9b2125fdec59cb6f3b406b85f9fe",
    "artist_id": "59wfkuBoNyhDMQGCljbUbA",
    "year": 1999
  },
  {
    "song_id": "6a12yrkah2Fs6FQ8QCdFfP",
    "name": "Angel",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/30fc6d26da3d32ecdbe851004abdb520308849b3",
    "artist_name": "Sarah McLachlan",
    "image_url": "https://i.scdn.co/image/b01268bba8311f86de9517cf9e3e653d8351e2fe",
    "artist_id": "4NgNsOXSwIzXlUIJcpnNUp",
    "year": 1999
  },
  {
    "song_id": "21fXrzSxu2y51Yqf57QycI",
    "name": "Happily Ever After",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/3b73a3e4b5c1663c53ec8ebcc2c7c67eba0ce736",
    "artist_name": "Case",
    "image_url": "https://i.scdn.co/image/941e7c6c28a921058991eab0c578ffddc06c64cc",
    "artist_id": "5aEWnrN8h3MhuFUPRfaVuy",
    "year": 1999
  },
  {
    "song_id": "015A6ZcNAWSSuwY7iDcD1b",
    "name": "When a Woman's Fed Up",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/8270f67207f2cc8d9262beeb22086dded08d3a76",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/459803c889bb01099c0c1ccd12c2f3c7d7def673",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 1999
  },
  {
    "song_id": "7861kBrZ5aw7o4NfggcyYW",
    "name": "It's Not Right But It's Okay",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/f36d68d971891f4140b038a140c577e6cd3f57d3",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/e546c1122bbb4183667b8a1923afbd377c60fabb",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1999
  },
  {
    "song_id": "2HmMklpgK8vGCpJmPpz1yG",
    "name": "How Forever Feels",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/809fb1895d0c699f53461f86f4eb112682a45a4f",
    "artist_name": "Kenny Chesney",
    "image_url": "https://i.scdn.co/image/ab040630e1e7c77d05b8bc1fc559b767cedac5a1",
    "artist_id": "3grHWM9bx2E9vwJCdlRv9O",
    "year": 1999
  },
  {
    "song_id": "3qrUiGsAC0ZXKP6uOtikOY",
    "name": "Mambo No. 5 (A Little Bit of...)",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/ea2babada111ab0e5bc64e969c9c1cb5b27eabab",
    "artist_name": "Lou Bega",
    "image_url": "https://i.scdn.co/image/e7cb9ffeb75678b4cf555909f4a7ef481392b2cb",
    "artist_id": "46lnlnlU0dXTDpoAUmH6Qx",
    "year": 1999
  },
  {
    "song_id": "1CAw3ethNuSDPf3E7luSFF",
    "name": "Faded Pictures",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/3618cab3874e942f78f0e95d4e526c12376d84c6",
    "artist_name": "Case",
    "image_url": "https://i.scdn.co/image/941e7c6c28a921058991eab0c578ffddc06c64cc",
    "artist_id": "5aEWnrN8h3MhuFUPRfaVuy",
    "year": 1999
  },
  {
    "song_id": "0hcW7qPqWWJc8189OaqQvX",
    "name": "You",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/4c1b0cee577f2bd962347316753daae2425362b9",
    "artist_name": "Jesse Powell",
    "image_url": "https://i.scdn.co/image/df9ed46aeef08b82930e8d17173e6e75f26397a6",
    "artist_id": "4ryyCNxkhfBSXHx4k2lo3T",
    "year": 1999
  },
  {
    "song_id": "5DwP705ZZWaTQ8olnVtD0b",
    "name": "If You",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/6e70b765cb972ca818bc34689d21d1d5a9d1a8a3",
    "artist_name": "Silk",
    "image_url": "https://i.scdn.co/image/fac1c20e926bbb8d6985902f4c10e56bc2cc9a62",
    "artist_id": "3wVllS5itPK6rpHwfvNce4",
    "year": 1999
  },
  {
    "song_id": "3tlbDkMFlKerAgmKESoIYP",
    "name": "Ghetto Cowboy",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/b01ea869a7c1f7591cb59c32680a96075efb3be0",
    "artist_name": "Mo Thugs",
    "image_url": "https://i.scdn.co/image/09db5b15b5c802763ea1bb30394913b6d89cb17e",
    "artist_id": "73zx64RsUoERRD2dPhdCEy",
    "year": 1999
  },
  {
    "song_id": "460Wn6Dq2uMviG5nPXtPnb",
    "name": "Better Days (And The Bottom Drops Out)",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/bc9bdddad652d511caaa1f8ed34867ca3b72b8c7",
    "artist_name": "Citizen King",
    "image_url": "https://i.scdn.co/image/1fc29f45b9db510d43171db18e813b9e8f10b297",
    "artist_id": "2Ca9nb6MJ3lwyF7dRNJm3M",
    "year": 1999
  },
  {
    "song_id": "3EIzVtVW78E6qJqYTa0WUx",
    "name": "Heartbreak Hotel",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/e7d433b0f44a49b236347fd7bab7b9c1352ce081",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/1a45a2b6266e337b71d72d120800184ab2138657",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 1999
  },
  {
    "song_id": "7EEdDKopE2INq8y1sOZuhU",
    "name": "The Hardest Thing",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/76fab8dbb4f4dec2461ee1053e7824a86326b7f1",
    "artist_name": "98º",
    "image_url": "https://i.scdn.co/image/491c00ff173609b9dac1f234e351f6f783ac4d41",
    "artist_id": "6V03b3Y36lolYP2orXn8mV",
    "year": 1999
  },
  {
    "song_id": "4mljNnZ2eaXKSbEAOzLlLE",
    "name": "(God Must Have Spent) A Little More Time On You",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/d00c934cfc744184760b485be181bef71d260747",
    "artist_name": "N Sync",
    "image_url": "https://i.scdn.co/image/b03198d474d164620a6afb6effeeaaa64b85ef93",
    "artist_id": "6Ff53KvcvAj5U7Z1vojB5o",
    "year": 1999
  },
  {
    "song_id": "0IBOeJ70qf1l7CjSslj0FT",
    "name": "Amazed",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/ff228cbe583dfad24b4044244c4295ea570cc8d4",
    "artist_name": "Lonestar",
    "image_url": "https://i.scdn.co/image/677ef47db5997d6e3ff1c913274d867eff1e2baf",
    "artist_id": "3qbnxnvUqR14MJ9g8QwZJK",
    "year": 1999
  },
  {
    "song_id": "0M3ZIWNcizkhYFvn6RuCEz",
    "name": "Music of My Heart",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/fcaacb90c80323ad668eb20ca27980153f6485c2",
    "artist_name": "N Sync",
    "image_url": "https://i.scdn.co/image/11aa8a004c2b3b298067a19ed00df77505f25e7b",
    "artist_id": "6Ff53KvcvAj5U7Z1vojB5o",
    "year": 1999
  },
  {
    "song_id": "4gW7mm93XKmqdPduQ3bpqS",
    "name": "Genie In A Bottle (Album Version)",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/9eb95c07a0a7d76031b41bdbcc5a5be052a335ab",
    "artist_name": "Christina Aguilera",
    "image_url": "https://i.scdn.co/image/09ded5c15a42e33461185d141276d8fb770a079c",
    "artist_id": "1l7ZsJRRS8wlW3WfJfPfNS",
    "year": 1999
  },
  {
    "song_id": "5NrQjJX3SfWlK6bXGVSTHt",
    "name": "Back That Thang Up",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/8102340298fec7dd3fad721c85857577bb62a06b",
    "artist_name": "Juvenile",
    "image_url": "https://i.scdn.co/image/fd5952b3e7e3a720512ad8608f76b4997b2f6c34",
    "artist_id": "0rG0AZBscc8S8q1ahIsasI",
    "year": 1999
  },
  {
    "song_id": "0FpLfrOyHblbDlHUsrPN1N",
    "name": "What's It Gonna Be?!",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/4b71c080824ff468987bd9cce0b8eb0a30e8e01d",
    "artist_name": "Busta Rhymes featuring Janet",
    "image_url": "https://i.scdn.co/image/ddf23a849294af013452f1d7e2d858fd06da4a08",
    "artist_id": "7iBvlzaVjkR3xCww4XyIsi",
    "year": 1999
  },
  {
    "song_id": "7xoN94HgqWTerSUbG5Hf9O",
    "name": "Because Of You",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/67135b1b4f6c12af20c9baededd8fb07270535c6",
    "artist_name": "98º",
    "image_url": "https://i.scdn.co/image/491c00ff173609b9dac1f234e351f6f783ac4d41",
    "artist_id": "6V03b3Y36lolYP2orXn8mV",
    "year": 1999
  },
  {
    "song_id": "4TQOC2AhsD96oPxAC6NB6w",
    "name": "Take Me There",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/c9a6c0f5b0090a78ae24cf5ee38fccc6d470c57a",
    "artist_name": "Blackstreet",
    "image_url": "https://i.scdn.co/image/f6f9cc89e63fe8847e1a427c5644abb873810024",
    "artist_id": "2P3cjUru4H3fhSXXNxE9kA",
    "year": 1999
  },
  {
    "song_id": "02nsNxmxmRKR4Tbv3Fcbfh",
    "name": "I'm Your Angel (Duet with R. Kelly)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/b7d4a83b67a48263eaecb0c3a96b009d3dd9b2c4",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/2fe8ddf18096771fb7093acbe8368b2862979bcc",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 1999
  },
  {
    "song_id": "4Bza6algEDbY8yrm8qMeg7",
    "name": "She's All I Ever Had",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/69f44184087b44414def7ac69bdf2cc24a917514",
    "artist_name": "Ricky Martin",
    "image_url": "https://i.scdn.co/image/7bb4ce8b35d12a1d4c071d2586cc65899e950198",
    "artist_id": "7slfeZO9LsJbWgpkIoXBUJ",
    "year": 1999
  },
  {
    "song_id": "0rBre07bXfVxg9m7H0YzNa",
    "name": "I Will Remember You - Live",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/044bd8ab2cff3b13f7ac66d98acbb3be11dc420b",
    "artist_name": "Sarah McLachlan",
    "image_url": "https://i.scdn.co/image/b01268bba8311f86de9517cf9e3e653d8351e2fe",
    "artist_id": "4NgNsOXSwIzXlUIJcpnNUp",
    "year": 1999
  },
  {
    "song_id": "2siv7IrEa2khUf70k7vp49",
    "name": "Chante's Got A Man",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/06c85cdf1433d5e9124df7cef6d8af7f14b58efe",
    "artist_name": "Chanté Moore",
    "image_url": "https://i.scdn.co/image/bf958622033406bf99252d0ed7b8f21b506504b3",
    "artist_id": "77OlE8SkEHDkJczfswzDFo",
    "year": 1999
  },
  {
    "song_id": "3MPKTMJIxlORqcxw542HHK",
    "name": "Jamboree",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/32291df7372e1abf807c79a1eedccc417026b6e4",
    "artist_name": "Naughty By Nature",
    "image_url": "https://i.scdn.co/image/1b4226cc403764ad27c783293fb796aab52af604",
    "artist_id": "4Otx4bRLSfpah5kX8hdgDC",
    "year": 1999
  },
  {
    "song_id": "6miou5rcSI3TqJWTKizJJI",
    "name": "Give It To You",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/447f88c71d380ebb44c92f46c981032bd28f7a25",
    "artist_name": "Jordan Knight",
    "image_url": "https://i.scdn.co/image/74eee89f762bd79d4ce8846e885f21a64dd25d26",
    "artist_id": "2AEcSFddxmvXT0A7rBwyVL",
    "year": 1999
  },
  {
    "song_id": "1Z3BhlpRdSIWlPuuvVIOg2",
    "name": "Stay The Same",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/e7c61e8246cc99579fa39a921eb5c51029f1f61e",
    "artist_name": "Joey McIntyre",
    "image_url": "https://i.scdn.co/image/0a48b5a8feda778e24cac3d257d790b586f003e7",
    "artist_id": "3SIW6eVIZSz9V05WsaKIr3",
    "year": 1999
  },
  {
    "song_id": "2rbb3lOMI71NIMbJCS1wQQ",
    "name": "Who Dat",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/4aee18d1d74404d193a041d5f00bc3c7ec1b4c1e",
    "artist_name": "JT Money",
    "image_url": "https://i.scdn.co/image/adb80e389a18d5f337fe2a003d90eb7d85777ce2",
    "artist_id": "1pJhCUTEZhR4nYeemYlkVz",
    "year": 1999
  },
  {
    "song_id": "3k7OAfk7OD2Witi93PbJpa",
    "name": "Tell Me It's Real",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/840b4f9c10f9efac763c87368fa0ab678d6af823",
    "artist_name": "K-Ci & JoJo",
    "image_url": "https://i.scdn.co/image/7f60bac9690bf5b3f4c53f21e916154acf030410",
    "artist_id": "05RZIdfz59ZW2FvFuwnmNK",
    "year": 1999
  },
  {
    "song_id": "7bxPZzHLdimWJWLOtdFecF",
    "name": "I Don't Want To Miss A Thing",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/3aa3a3ef69efdbf9dfe66ad5071ac074aa4e7f5e",
    "artist_name": "Mark Chesnutt",
    "image_url": "https://i.scdn.co/image/8d021f1a477f45badf1c1780ad05ff338345928f",
    "artist_id": "2cNV8vNe2fPFf6OfGOBbSS",
    "year": 1999
  },
  {
    "song_id": "5yhAgqhBrE6xd0nuJvlnTR",
    "name": "God Must Have Spent a Little More Time on You",
    "popularity": 24,
    "preview_url": "https://p.scdn.co/mp3-preview/bca5bbf594a15d26c79f7e3ac578d8082a0b01c3",
    "artist_name": "Alabama",
    "image_url": "https://i.scdn.co/image/58fc5f2830a40481c890268a6e242c432ac8c20b",
    "artist_id": "6rJqqRce0Kvo2dJUXoHleC",
    "year": 1999
  },
  {
    "song_id": "3wvg3Jpee5NzpSBZhBqFdE",
    "name": "If You Had My Love",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/3180584db7ae5c3499aaf5400f07ee67cbf4982c",
    "artist_name": "Jennifer Lopez",
    "image_url": "https://i.scdn.co/image/20717aeb8e24bc71e87500ddc3aca816ef413ce9",
    "artist_id": "2DlGxzQSjYe5N6G9nkYghR",
    "year": 1999
  },
  {
    "song_id": "4LwU4Vp6od3Sb08CsP99GC",
    "name": "The Next Episode",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/e38dee97476a674dd7b8b8fadb194bfbe5639b3d",
    "artist_name": "Dr. Dre",
    "image_url": "https://i.scdn.co/image/9c37cfe8125864774ccb36f076cba3fcfcaa2db3",
    "artist_id": "6DPYiyq5kWVQS4RGwxzPC7",
    "year": 2000
  },
  {
    "song_id": "3i3GeK0qLQybu4ah42YmCY",
    "name": "Smooth",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/50e8d558d5a75301bdd23d2b814e60615b1aeb59",
    "artist_name": "Santana",
    "image_url": "https://i.scdn.co/image/94bd261f86af0e9ded540c77373290f5845448ce",
    "artist_id": "6GI52t8N5F02MxU0g5U69P",
    "year": 2000
  },
  {
    "song_id": "7iXF2W9vKmDoGAhlHdpyIa",
    "name": "Forgot About Dre",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/7ae466cd2d54e3e26d8bf1e0339eddcc3bc4cd33",
    "artist_name": "Dr. Dre",
    "image_url": "https://i.scdn.co/image/9c37cfe8125864774ccb36f076cba3fcfcaa2db3",
    "artist_id": "6DPYiyq5kWVQS4RGwxzPC7",
    "year": 2000
  },
  {
    "song_id": "62bOmKYxYg7dhrC6gH9vFn",
    "name": "Bye Bye Bye",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/d124a3e0702afbef8dfa39799f990a1fb04eb992",
    "artist_name": "N Sync",
    "image_url": "https://i.scdn.co/image/229de277b232ffb225ee5699954490e97c0dc753",
    "artist_id": "6Ff53KvcvAj5U7Z1vojB5o",
    "year": 2000
  },
  {
    "song_id": "4ocbH5ZJqajZIpUrqRUoue",
    "name": "Meet Virginia",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/677c7e05ec628b2e5208b19beb8226e29b900dc6",
    "artist_name": "Train",
    "image_url": "https://i.scdn.co/image/780f6ab97095c70771afd761bf975802f7c93097",
    "artist_id": "3FUY2gzHeIiaesXtOAdB7A",
    "year": 2000
  },
  {
    "song_id": "6ctr1K7KzKErxbfGAynL8A",
    "name": "I Wanna Know",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/bdbdcab7280938bbd489b86890d0864bffbd0df9",
    "artist_name": "Joe",
    "image_url": "https://i.scdn.co/image/10389ad5ea381aa0564a03617f037bc347539942",
    "artist_id": "3zTOe1BtyTkwNvYZOxXktX",
    "year": 2000
  },
  {
    "song_id": "3fucYASejkSMwtAYjFRKlF",
    "name": "Maria Maria - Radio Mix",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/d22fb32dfca23e3f68978f5a77217340fe280ac1",
    "artist_name": "Santana",
    "image_url": "https://i.scdn.co/image/94bd261f86af0e9ded540c77373290f5845448ce",
    "artist_id": "6GI52t8N5F02MxU0g5U69P",
    "year": 2000
  },
  {
    "song_id": "3mNecsYFb6LQg7822DPXCP",
    "name": "Absolutely (Story Of A Girl) - Radio Mix",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/9625467330068148a23569f61f3d07238dbe9672",
    "artist_name": "Nine Days",
    "image_url": "https://i.scdn.co/image/efbf567c0d3f725d48136c007777d3bebbf9c23b",
    "artist_id": "306JHpIRGVaQd6OltnOzUO",
    "year": 2000
  },
  {
    "song_id": "3rXCZRMiMZp0feGcYXpwYX",
    "name": "Cowboy Take Me Away",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/4225997687fe3df781b301fcbade6d2390992e0e",
    "artist_name": "Dixie Chicks",
    "image_url": "https://i.scdn.co/image/706742a1ce675397afe28c901d637b1a0c8128af",
    "artist_id": "25IG9fa7cbdmCIy3OnuH57",
    "year": 2000
  },
  {
    "song_id": "4cKGldbhGJniI8BrB3K6tb",
    "name": "Everything You Want",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/e7412cf41d7e1d68d532405dad0e49a3767bfd62",
    "artist_name": "Vertical Horizon",
    "image_url": "https://i.scdn.co/image/1f21ce0df5c6e95c9bbefbdb6351459419587a4a",
    "artist_id": "6Hizgjo92FnMp8wGaRUNTn",
    "year": 2000
  },
  {
    "song_id": "0S0d7Qad5v7ZLQiAuwQxab",
    "name": "Kryptonite",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/ef462040ef8014b67af80d16a473377bb1698c63",
    "artist_name": "3 Doors Down",
    "image_url": "https://i.scdn.co/image/0af6372226f295e89d5b540193f0d548f9f16532",
    "artist_id": "2RTUTCvo6onsAnheUk3aL9",
    "year": 2000
  },
  {
    "song_id": "3seflYvRN3gmh50Iih5yMd",
    "name": "U Know What's Up - Album Version w/o Left Eye",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/a6d927996b94da46062fce585177a0f00cc2ef16",
    "artist_name": "Donell Jones",
    "image_url": "https://i.scdn.co/image/0a0eb23c628a4eb64e4cdc8ee3c04d21ccfc884b",
    "artist_id": "5KNqYrivNgVCHBssEUSu5B",
    "year": 2000
  },
  {
    "song_id": "3y4LxiYMgDl4RethdzpmNe",
    "name": "Breathe",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/4d497f0c0b3a3d3913071ae493a14e09418e0bf6",
    "artist_name": "Faith Hill",
    "image_url": "https://i.scdn.co/image/f45f88dd3641f883e64204ba218ab205db071cec",
    "artist_id": "25NQNriVT2YbSW80ILRWJa",
    "year": 2000
  },
  {
    "song_id": "2AW37v0bDyuOzGP3XnmFuA",
    "name": "It's Gonna Be Me",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/88600296f13a544d9d30416d11b05a8448fe9574",
    "artist_name": "N Sync",
    "image_url": "https://i.scdn.co/image/229de277b232ffb225ee5699954490e97c0dc753",
    "artist_id": "6Ff53KvcvAj5U7Z1vojB5o",
    "year": 2000
  },
  {
    "song_id": "7ffwRz8lZyDOE4Vj58Lo72",
    "name": "I Need to Know",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/0d0a57db4e9e109a580d7a51479770228c97d6f5",
    "artist_name": "Marc Anthony",
    "image_url": "https://i.scdn.co/image/1c92865f6a838bd5b958a3deb7bf20e621b369ea",
    "artist_id": "4wLXwxDeWQ8mtUIRPxGiD6",
    "year": 2000
  },
  {
    "song_id": "65GZzBP1qyk4zH7c8RIJYD",
    "name": "Come On Over Baby (All I Want Is You) - Radio Version",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/0b50c37512566a6b566949315b75b3a4e0bea079",
    "artist_name": "Christina Aguilera",
    "image_url": "https://i.scdn.co/image/830079591dde46c02a4ffb33ddf40fb73c203fc4",
    "artist_id": "1l7ZsJRRS8wlW3WfJfPfNS",
    "year": 2000
  },
  {
    "song_id": "2c5wml8wsBnPfNSbCZ574T",
    "name": "Take A Picture",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/ad416dcfa8e8f924eb74e78731ed65d0ce199935",
    "artist_name": "Filter",
    "image_url": "https://i.scdn.co/image/5b5366daf0604f42385ec9040b6997f871b51637",
    "artist_id": "01WjpKiWVNurV5hjIadB8C",
    "year": 2000
  },
  {
    "song_id": "5siee1e4lz8uF51OokjglA",
    "name": "Shackles (Praise You)",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/e31387cb61f381f95df2c3076bc0ae46ae198acd",
    "artist_name": "Mary Mary",
    "image_url": "https://i.scdn.co/image/3d1250e20bc5f5e8918b0d8d055eff9cc05f1071",
    "artist_id": "12Kgt2eahvxNWhD5PnSUde",
    "year": 2000
  },
  {
    "song_id": "3NEzjZbeSCy72Kx1clSYgC",
    "name": "Wifey",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/f912425a592b047ede3374129de299c4971b6b3f",
    "artist_name": "Next",
    "image_url": "https://i.scdn.co/image/7433313c5921731df38907118b14b7101c15dec7",
    "artist_id": "2MGT7CYlixSPxAnnkhLpaA",
    "year": 2000
  },
  {
    "song_id": "2dwhMQsFeHr2S787WxqAqW",
    "name": "You Sang To Me",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/50efec536cea3a2ed86fd5cd9fb75475f5364709",
    "artist_name": "Marc Anthony",
    "image_url": "https://i.scdn.co/image/1c92865f6a838bd5b958a3deb7bf20e621b369ea",
    "artist_id": "4wLXwxDeWQ8mtUIRPxGiD6",
    "year": 2000
  },
  {
    "song_id": "1PmHkalaUHhh0fz23SBHDL",
    "name": "Let's Get Married",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/1b4b4d64b145f9df0267c75d431812995aaec666",
    "artist_name": "Jagged Edge",
    "image_url": "https://i.scdn.co/image/54e37d9b82b5c69fea884d4eca312b01d4a49f49",
    "artist_id": "7Aq8lpLMSt1Zxu56pe9bmp",
    "year": 2000
  },
  {
    "song_id": "65B1tEOv5W294uCKbmEcFV",
    "name": "I Hope You Dance",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/c5975d2be71a0970200ee0949418a20214ef7b5d",
    "artist_name": "Lee Ann Womack",
    "image_url": "https://i.scdn.co/image/36f2dbf2afd31cd260452a2170045404ca182f18",
    "artist_id": "738OS3zrCO782uDiUN9pet",
    "year": 2000
  },
  {
    "song_id": "6WAhLCL1XchQzYjl15rnFp",
    "name": "Say My Name",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/163c9d33c81b7fa4fae2d1fccab39f5b080e2496",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/b3c65b2c24548261cf4842ce787df764c1b2483b",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 2000
  },
  {
    "song_id": "3hRwcwpkOenKHjOsyIwTxf",
    "name": "Get It On Tonite",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/78cb23a4cf19139f9c6e1b5a48ceb8508c46d264",
    "artist_name": "Montell Jordan",
    "image_url": "https://i.scdn.co/image/dfb6a418bcd80a81b5b0adef66cf7998e12c9784",
    "artist_id": "0iVrCROxeyon7MZUW3MfzT",
    "year": 2000
  },
  {
    "song_id": "2WfhlEjoUII31H6imnQdvF",
    "name": "No More",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/b1255a5578cb6cf9a7cb869f73a2f1e8aca9abba",
    "artist_name": "Ruff Endz",
    "image_url": "https://i.scdn.co/image/64d2c3e7db3dfc30a57b6b72db8b6baeb19419d5",
    "artist_id": "7liFhc0PDIx8etigqd2WhW",
    "year": 2000
  },
  {
    "song_id": "6vgN6KGstss0GnBbKlMNK9",
    "name": "The Way You Love Me",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/5c4abbeb5445f9281f0497368e51da6426d8c173",
    "artist_name": "Faith Hill",
    "image_url": "https://i.scdn.co/image/f45f88dd3641f883e64204ba218ab205db071cec",
    "artist_id": "25NQNriVT2YbSW80ILRWJa",
    "year": 2000
  },
  {
    "song_id": "5wDPLdzR7eivEl4Dkd7tST",
    "name": "My Love Is Your Love",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/fb482d18dd3711b8e6c2b5ee9bffaafafbddd7ab",
    "artist_name": "Whitney Houston",
    "image_url": "https://i.scdn.co/image/1a45a2b6266e337b71d72d120800184ab2138657",
    "artist_id": "6XpaIBNiVzIetEPCWDvAFP",
    "year": 2000
  },
  {
    "song_id": "2aPbkXxYRTQ1cQrscHswuM",
    "name": "Blue (Da Ba Dee) - Radio Edit",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/7261e64c5678e204cd66d2e25aff90410f228c8d",
    "artist_name": "Eiffel 65",
    "image_url": "https://i.scdn.co/image/78ffba915fa42435db6fc1faa3bf7403c4c51d69",
    "artist_id": "64rxQRJsLgZwHHyWKB8fiF",
    "year": 2000
  },
  {
    "song_id": "4Oek4Sft4I8s5vHZK6OJnB",
    "name": "Where I Wanna Be",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/927b6e48425d1193476a48b594ef349ba5a48299",
    "artist_name": "Donell Jones",
    "image_url": "https://i.scdn.co/image/0a0eb23c628a4eb64e4cdc8ee3c04d21ccfc884b",
    "artist_id": "5KNqYrivNgVCHBssEUSu5B",
    "year": 2000
  },
  {
    "song_id": "0CjrIEPmqIKnBHy99hun3v",
    "name": "Back At One",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/63ff04e1de6713e488623f80ac7a147739d1dd20",
    "artist_name": "Brian McKnight",
    "image_url": "https://i.scdn.co/image/48b9518470274f350de4d3750fb4f811fe26ce42",
    "artist_id": "6k0IBR0lU42s2GYpNX7kA9",
    "year": 2000
  },
  {
    "song_id": "6znv7i4Wif5fLwI6OjKHZ4",
    "name": "Back Here",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/685c8bb93ac870154f1b9800e4ca2f2123bbb84b",
    "artist_name": "BBMak",
    "image_url": "https://i.scdn.co/image/885ecb6ea0966b05e1c89fbea7e5045cf6f42b3f",
    "artist_id": "7y1GT7SdgGiFLWoktv2TSw",
    "year": 2000
  },
  {
    "song_id": "4NlLZ2WkhJamPaeD9MfL5c",
    "name": "Desert Rose",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/10979511c469497b99d3b6633177588e4b39248a",
    "artist_name": "Sting",
    "image_url": "https://i.scdn.co/image/8fc44fccbd1c5e9d91273a2bb87e8579c3152e4f",
    "artist_id": "0Ty63ceoRnnJKVEYP0VQpk",
    "year": 2000
  },
  {
    "song_id": "5xcSi6kSAq1MfPXVuzC5gD",
    "name": "Wonderful - 2004 Digital Remaster",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/604fd59f3b1cfb42e1e9bba6326e0d91ab9ab572",
    "artist_name": "Everclear",
    "image_url": "https://i.scdn.co/image/85aaac046d5974854e630530256b1d48eb4646bd",
    "artist_id": "694QW15WkebjcrWgQHzRYF",
    "year": 2000
  },
  {
    "song_id": "1ak0S3NhwWrUgNlQhJ1412",
    "name": "Case Of The Ex (Whatcha Gonna Do)",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/fb527c388a50c5ff4a7cdf90854f2878ee8b546e",
    "artist_name": "Mya",
    "image_url": "https://i.scdn.co/image/ae8dfcd0a020101e3cf08b0ae2767df909c93e04",
    "artist_id": "6lHL3ubAMgSasKjNqKb8HF",
    "year": 2000
  },
  {
    "song_id": "0dQ8sFlmQakH1r2r9IqlYH",
    "name": "I Knew I Loved You",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/53300bb2f38db44ccaebc3b024d019d594d762d8",
    "artist_name": "Savage Garden",
    "image_url": "https://i.scdn.co/image/02b354c7dd459ecda2ebc4459af82605feef4b78",
    "artist_id": "3NRFinRTEqUCfaTTZmk8ek",
    "year": 2000
  },
  {
    "song_id": "3PdiRnD8aIC3ljfqMhq93B",
    "name": "The Real Slim Shady - Album Version (Edited)",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/c23cc7fcec1bb6876f2c6d47daa0ca98ccf8e5e6",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/fc4802a97fe9cfaecf0215f6a26a6befb6419371",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2000
  },
  {
    "song_id": "3VEZvzr84WVnoorZ4tlBSw",
    "name": "He Can't Love U",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/cb1c5cb87f76a3b55b74a00b4a0ebb77a37bad68",
    "artist_name": "Jagged Edge",
    "image_url": "https://i.scdn.co/image/54e37d9b82b5c69fea884d4eca312b01d4a49f49",
    "artist_id": "7Aq8lpLMSt1Zxu56pe9bmp",
    "year": 2000
  },
  {
    "song_id": "68jXxSRBO6lM4Ot6aBzhsS",
    "name": "Shake Ya Ass",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/aa069c3428e0b4ab51ff31b2352e2283407a4309",
    "artist_name": "Mystikal",
    "image_url": "https://i.scdn.co/image/589e70f9f4b7bec3d9cd0b277214c0572b10b627",
    "artist_id": "3LIJJJkO7R5RasRwt7xIn5",
    "year": 2000
  },
  {
    "song_id": "48gBfTRel9fajeev7tmLpo",
    "name": "Better Off Alone (Remastered) - 1999 Original Hit Radio",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/892dc567d0793913c010986cdb72ecd84c7e1054",
    "artist_name": "Alice Deejay",
    "image_url": "https://i.scdn.co/image/df9ac459bcfe3456deda79e93d8f24f547005191",
    "artist_id": "0aNCjE72yyrhKQB1qfPBpi",
    "year": 2000
  },
  {
    "song_id": "0mPN2OcVFiANaRuSeli6Np",
    "name": "Waiting for Tonight",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/9ce3c5aa4a0b442329c379f6bbab448bcb685725",
    "artist_name": "Jennifer Lopez",
    "image_url": "https://i.scdn.co/image/76b5b64ec34281cbb5ce4a483b2368b9ddb329fc",
    "artist_id": "2DlGxzQSjYe5N6G9nkYghR",
    "year": 2000
  },
  {
    "song_id": "1lZ4j5WBURBwZ0M5TT46wP",
    "name": "Be With You",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/b30561f81285bbb8d419269eb6930670e2f4277b",
    "artist_name": "Enrique Iglesias",
    "image_url": "https://i.scdn.co/image/45791cf4aa06a1a90b13c4648dbc0bf87aa35af1",
    "artist_id": "7qG3b048QCHVRO5Pv1T5lw",
    "year": 2000
  },
  {
    "song_id": "4mhIgm1TXUmI9prcSvV1w6",
    "name": "I Wish",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/5ad27302813c0ff04780b95c956bc0cb8310800f",
    "artist_name": "Carl Thomas",
    "image_url": "https://i.scdn.co/image/3802e4320b5e7a7893095e7e700624777e14e5a1",
    "artist_id": "6I6t21SFbxZ7RbQgD5dN7U",
    "year": 2000
  },
  {
    "song_id": "2iHjjXPZQHrIGXv9jhUzjy",
    "name": "Crash And Burn",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/39d05f56163d8c16e62426ab766074977d6ad986",
    "artist_name": "Savage Garden",
    "image_url": "https://i.scdn.co/image/02b354c7dd459ecda2ebc4459af82605feef4b78",
    "artist_id": "3NRFinRTEqUCfaTTZmk8ek",
    "year": 2000
  },
  {
    "song_id": "5rlHyx0qh8xnwCZlyTklKT",
    "name": "Country Grammar (Hot...) - Album Version (Edited)",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/1d1312cf76e855881b261bab701dd22d62fc78fa",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/21156fd967f0de2bf024f95a966d2b21d9ab76e3",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2000
  },
  {
    "song_id": "6btHdKjLcF97GoGtO0My7F",
    "name": "Oops!...I Did It Again",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/a2071be324ef2fd84abe4971d16372d8d0a20c08",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/6ceb80502d302e1f56eb0e718a0175d00f19ab6b",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2000
  },
  {
    "song_id": "3dbywb1zxwI3h1m9o304eY",
    "name": "Broadway",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/98fa52c473dcf3d76b1e91afbd0a743a03b52e7f",
    "artist_name": "The Goo Goo Dolls",
    "image_url": "https://i.scdn.co/image/f66195f98d32ffb0f1fcca0ea9e69e2794ec6742",
    "artist_id": "2sil8z5kiy4r76CRTXxBCA",
    "year": 2000
  },
  {
    "song_id": "533wHLqC8eK5x5PN5aJ2x6",
    "name": "Show Me the Meaning of Being Lonely",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/61163ad5a8979bca94ca9b7dd4d148069c95c94e",
    "artist_name": "Backstreet Boys",
    "image_url": "https://i.scdn.co/image/72fa1290350b648028c634a98d5644167361e5b2",
    "artist_id": "5rSXSAkZ67PYJSvpUpkOr7",
    "year": 2000
  },
  {
    "song_id": "4DJwdQDai9DLYBL9TNrEDo",
    "name": "Girl On TV",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/33dbc935f293d6deb2b7c88c7ef489464967dcd6",
    "artist_name": "Lyte Funkie Ones",
    "image_url": "https://i.scdn.co/image/f49283039e4687efb5808c1e37ae4b3b6f8e773a",
    "artist_id": "3sjPSxiGGNVoioDgWASynD",
    "year": 2000
  },
  {
    "song_id": "2tZZGKQDPIk37SkmNm0Od7",
    "name": "Independent Women, Pt. I - #1's Edit",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/4fc4823a48d7f4836cf3be6167dad8af923471de",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/dae0edc92ff769dc9fe7af7b484578f709a724b1",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 2000
  },
  {
    "song_id": "7ccz9p1ErD6Kq5ZSQmyvLm",
    "name": "Gotta Tell You",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/3df6f7d626e507b8f3d640663fc57ccc9a0e9cc1",
    "artist_name": "Samantha Mumba",
    "image_url": "https://i.scdn.co/image/9d48f50e539d3d72e544e4c9cf1b00f1cf577882",
    "artist_id": "7L12TqJ0fbwtFljTbwfwRI",
    "year": 2000
  },
  {
    "song_id": "1wfkeS9zmTwosrxsjZJ0w0",
    "name": "I Try",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/26766f4d05baacfd40756c62385d01573ae52b8d",
    "artist_name": "Macy Gray",
    "image_url": "https://i.scdn.co/image/01b1f57f874bdaa6d8adc4b49689d13e37c834a9",
    "artist_id": "4ylR3zwA0zaapAu94fktwa",
    "year": 2000
  },
  {
    "song_id": "2ypwlOO0Z3NfammOQgOey3",
    "name": "Bent - Remastered Version",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/980c9c721eb508779e205ee14056f8db8c11c8ec",
    "artist_name": "Matchbox Twenty",
    "image_url": "https://i.scdn.co/image/c32c828af2b4f107b333814cc0e9dc729f8f72da",
    "artist_id": "3Ngh2zDBRPEriyxQDAMKd1",
    "year": 2000
  },
  {
    "song_id": "7mYvtEeBdMqRSyj1Qpv6my",
    "name": "Hot Boyz",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/a6bce7fc480e16ec702d6152f16c759a4534158c",
    "artist_name": "Missy Elliott",
    "image_url": "https://i.scdn.co/image/2f789e825bc6acf85b24457b727b14139cfbbcf1",
    "artist_id": "2wIVse2owClT7go1WT98tk",
    "year": 2000
  },
  {
    "song_id": "7rDcULv8vV16vetBjPJhuE",
    "name": "How Do You Like Me Now?!",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/449a87a3e49f70aff411acaad526d7ef12e637c1",
    "artist_name": "Toby Keith",
    "image_url": "https://i.scdn.co/image/e94430a48673742a20b7b18598c3eeca84725971",
    "artist_id": "2bA6fzP0lMAQ4kz6CF61w8",
    "year": 2000
  },
  {
    "song_id": "1vkrNCD54x9p4ZQdojjNyE",
    "name": "Faded",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/ac2bd57d562b73d064ce209276307c4bf10c6f0f",
    "artist_name": "SoulDecision",
    "image_url": "https://i.scdn.co/image/616569a7b8d46d0fef9ccb9d18d59c8879096dfb",
    "artist_id": "6hYrBYtoVGArYJcqvYxN4r",
    "year": 2000
  },
  {
    "song_id": "1G6fZu8udnnkJgg8NUoHak",
    "name": "Doesn't Really Matter",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/b6f71af32b1065c7799cead03e061646a0c19c59",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/da80896411fb5140efdb1fedce8fc61c2a914e9b",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 2000
  },
  {
    "song_id": "5XyPRKhXUalFpS2I5kLQBF",
    "name": "Then The Morning Comes",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/7efa5c1899230779bda12887f2d5b0910bbf43cc",
    "artist_name": "Smash Mouth",
    "image_url": "https://i.scdn.co/image/4b8f6e28008bf89fc697627b5c634c2a48038897",
    "artist_id": "2iEvnFsWxR0Syqu2JNopAd",
    "year": 2000
  },
  {
    "song_id": "5d8woPn6sHRA6yPnnHnqSt",
    "name": "What'chu Like",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/f297f41d61b45b8990debacc258c719ae88cc2a7",
    "artist_name": "Da Brat featuring Tyrese",
    "image_url": "https://i.scdn.co/image/815a6bb8d9befb426ec51cb5f056d164300b87bd",
    "artist_id": "0V1x5nsgVnTX0LJitPNNFO",
    "year": 2000
  },
  {
    "song_id": "2e8Kt9CAOECrIVUVTZl9ST",
    "name": "From The Bottom Of My Broken Heart",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/4129914c6a803a949e6d81fdc5190d952104723d",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/7a680ee7862b2fad3eb46e01f4fbe90594529c52",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2000
  },
  {
    "song_id": "2Fsg9at6oxnR7h6PhNcOmE",
    "name": "What About Now",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/bc62848a3bef076fc8c0faad4d0b94e93165384b",
    "artist_name": "Lonestar",
    "image_url": "https://i.scdn.co/image/08d18f67fe34bbcb5d0a2a35f8e21f286c26b02b",
    "artist_id": "3qbnxnvUqR14MJ9g8QwZJK",
    "year": 2000
  },
  {
    "song_id": "0IvTGWbNnCiUi9IOF7ye3F",
    "name": "I Like It",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/781d3ad0aca80da4117ccfbdc76d90dc4e6cd6fe",
    "artist_name": "Sammie",
    "image_url": "https://i.scdn.co/image/17ed4dfc4341b36e3ce1c381e57a5e800c32691a",
    "artist_id": "4p07QU02SrLsaORo25h2Lg",
    "year": 2000
  },
  {
    "song_id": "5o5eMUBTsxvxgNMPJ8WneO",
    "name": "Big Pimpin'",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/45b58743659a89764e8cf7b5d3aa1ad04020c325",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/3f900c73ca098d2a2bac967e45290d3841c7ac0e",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2000
  },
  {
    "song_id": "4r5fUnFvQGJrlpx0lSFNUc",
    "name": "What a Girl Wants",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/db49cf9fd2d69c14f146a561cccd6826405e8794",
    "artist_name": "Christina Aguilera",
    "image_url": "https://i.scdn.co/image/fe3177247f2ed3a345c434071a5f20516a6b0f48",
    "artist_id": "1l7ZsJRRS8wlW3WfJfPfNS",
    "year": 2000
  },
  {
    "song_id": "1C2Q3AymKS17bw7T5gfq6p",
    "name": "Dance With Me",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/cca14e5373aad6cdee9b9b5b0c2388967353f9fc",
    "artist_name": "Debelah Morgan",
    "image_url": "https://i.scdn.co/image/fd156efad78c660da078ba93b09d790ea68f892a",
    "artist_id": "0tRRrNLQY5rejEDVSjy4w3",
    "year": 2000
  },
  {
    "song_id": "4rZB2G955dQMcjlb7e3VNB",
    "name": "24/7",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/a1d72345b62b900b73904804743e489615330c19",
    "artist_name": "Kevon Edmonds",
    "image_url": "https://i.scdn.co/image/e32d0215f6d19d98e2a084411c76834e8d77ab92",
    "artist_id": "3an8oUQQJYhvsqS59GQrqR",
    "year": 2000
  },
  {
    "song_id": "1LuqK6QiebvOmpkRP1f8Jf",
    "name": "Yes!",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/7e3ff68487d04ad78ed04e6b21eaddeaad10385c",
    "artist_name": "Chad Brock",
    "image_url": "https://i.scdn.co/image/628f3e08d5647f9f222d58a5f0760643a79f9aa4",
    "artist_id": "24VF2kZrBFn1WvJ5oF1SRf",
    "year": 2000
  },
  {
    "song_id": "2pUnZcjdZNhRPh3Zlr2vFP",
    "name": "Bag Lady - Radio Edit",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/2976e11800fded2e8889862a0bc3fe44e47ea77e",
    "artist_name": "Erykah Badu",
    "image_url": "https://i.scdn.co/image/f95d2a59942d2b9807d02bf81b98a4d31a50f9e1",
    "artist_id": "7IfculRW2WXyzNQ8djX8WX",
    "year": 2000
  },
  {
    "song_id": "6zi3bRwxHkIe3lw69rHvZs",
    "name": "I Turn to You",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/b324a55ef63dbe6ebced1d0f9c87f592f26dfb94",
    "artist_name": "Christina Aguilera",
    "image_url": "https://i.scdn.co/image/fe3177247f2ed3a345c434071a5f20516a6b0f48",
    "artist_id": "1l7ZsJRRS8wlW3WfJfPfNS",
    "year": 2000
  },
  {
    "song_id": "0O633SHXZAb88agwApdnmW",
    "name": "Swear It Again - Radio Edit",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/7ba8aea0060e0098772fa0022d06c5913ddf2b0c",
    "artist_name": "Westlife",
    "image_url": "https://i.scdn.co/image/d576a980dd6f2744f668c6542750ba56b867c7d9",
    "artist_id": "5Z1CCuBsyhEHngq3U5IraY",
    "year": 2000
  },
  {
    "song_id": "4v4j9qwtlgmkpaNxruZa0T",
    "name": "That's the Way It Is",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/d4a19f70ec0110c0c8d6f19c53e318d99d69a6bd",
    "artist_name": "Céline Dion",
    "image_url": "https://i.scdn.co/image/1ab643939f3d8a60ef408ecea8a0ff27a23f283b",
    "artist_id": "4S9EykWXhStSc15wEx8QFK",
    "year": 2000
  },
  {
    "song_id": "3aVGAg0RKYzGuJeLu9bvCw",
    "name": "Bounce With Me - Edited Album Version",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/d9b62cb980e6a5824241c0bb27483d768dc1bbda",
    "artist_name": "Lil Bow Wow featuring XSCAPE",
    "image_url": "https://i.scdn.co/image/2bad2412e8cf3eaaef97d00beda1267efe9ad287",
    "artist_id": "1UVISibK3jtQacCbBCevh6",
    "year": 2000
  },
  {
    "song_id": "4kRSAzM74Gb0iTBiM3NM40",
    "name": "Party Up (Up In Here) (Re-recorded / Remastered)",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/6a59c1fe4c01c1c112a6ce25ade6799052f713fe",
    "artist_name": "DMX",
    "image_url": "https://i.scdn.co/image/38b03bf95de399134c1809fd4c3e801c7012221a",
    "artist_id": "1HwM5zlC5qNWhJtM00yXzG",
    "year": 2000
  },
  {
    "song_id": "5oQx6XZyrOOkGbme8DA9GO",
    "name": "Amazed",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/ff228cbe583dfad24b4044244c4295ea570cc8d4",
    "artist_name": "Lonestar",
    "image_url": "https://i.scdn.co/image/b7426a640764466c4e5368c97a19c74dae9dc77e",
    "artist_id": "3qbnxnvUqR14MJ9g8QwZJK",
    "year": 2000
  },
  {
    "song_id": "0kJxeg2XTqf3pxpaB2fbPw",
    "name": "Thong Song - Radio Edit",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/2135adce1470859f2ce8ae6e9bd5f5711e4fdc29",
    "artist_name": "Sisqo",
    "image_url": "https://i.scdn.co/image/7b728b182ff5d6c1b1b91dcdc2e19d594364ef80",
    "artist_id": "6x9QLdzo6eBZxJ1bHsDkjg",
    "year": 2000
  },
  {
    "song_id": "4RLTvoObapQSV9fyjhWB29",
    "name": "Jumpin', Jumpin'",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/99f2dc02956c3edf4429d8b68e1bc5ca3e2ee6d8",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/1c8707f6ef938c2daeb7a78e22f6e52a61fee84a",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 2000
  },
  {
    "song_id": "3XxjFPYZ1xCO2ITd6E56mE",
    "name": "He Wasn't Man Enough - Radio Edit",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/4c91a10539497f26db15727c2a3b56a5817500d1",
    "artist_name": "Toni Braxton",
    "image_url": "https://i.scdn.co/image/0ea43823d42a2c075ad61985333802ff6800a0eb",
    "artist_id": "3X458ddYA2YcVWuVIGGOYe",
    "year": 2000
  },
  {
    "song_id": "4A9pKBTxtgzrgndSi6wycK",
    "name": "I Wanna Love You Forever",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/cb5785c6151396797624213477175c0131d00b2b",
    "artist_name": "Jessica Simpson",
    "image_url": "https://i.scdn.co/image/1dba72359abf2f70e9c674410e8e51f15d4b366c",
    "artist_id": "2tFN9ubMXEhdAQvdQxcsma",
    "year": 2000
  },
  {
    "song_id": "7jGbrJ1AgGdAe6zb4hz5eJ",
    "name": "Separated",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/dcb15fb1a562b2e6793612c3f0607d709717ff34",
    "artist_name": "Avant",
    "image_url": "https://i.scdn.co/image/7deadf07f45dd696f95bb04d55c9e20eb974ed96",
    "artist_id": "0AunegKKH2ys98aLxq8G1A",
    "year": 2000
  },
  {
    "song_id": "6RUa3ZwZYnJnNgZxtIYJR9",
    "name": "Purest of Pain (A Puro Dolor) - Martinee's Radio (Spanish)",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/b6d82514a870458809ded5eb1efca3f28ff5280c",
    "artist_name": "Son By Four",
    "image_url": "https://i.scdn.co/image/10f34033899915593b000c19db6cfa7d39e2a458",
    "artist_id": "1qyAl8T6GTcaOViaI7fs1j",
    "year": 2000
  },
  {
    "song_id": "3V6f981tGCvwKhLEYqBIUP",
    "name": "Give Me Just One Night (Una Noche)",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/4be0ec7b7d409ca4d99ff6bf850fa9362cfbc89e",
    "artist_name": "98º",
    "image_url": "https://i.scdn.co/image/e1fc68138add1c62184a709e878089053a29ad31",
    "artist_id": "6V03b3Y36lolYP2orXn8mV",
    "year": 2000
  },
  {
    "song_id": "1uqsrjaeuU8c4lW9PzoJJe",
    "name": "Don't Think I'm Not - Radio Edit",
    "popularity": 9,
    "preview_url": "https://p.scdn.co/mp3-preview/8fc43297ceb5e49905e7e1ed50f3e2a3e5364703",
    "artist_name": "Kandi",
    "image_url": "https://i.scdn.co/image/817a3e0c6109a2fb14b665c01c20e894c4af5607",
    "artist_id": "0ThrQemMA2B8xxusTUl8IM",
    "year": 2000
  },
  {
    "song_id": "6CQGz0thcARyeQNGwz8Pm6",
    "name": "Incomplete - Album Version / Edited",
    "popularity": 1,
    "preview_url": "https://p.scdn.co/mp3-preview/cc92edfeb1aabf862b30e4d38b704edfe56c6459",
    "artist_name": "Sisqo",
    "image_url": "https://i.scdn.co/image/7b728b182ff5d6c1b1b91dcdc2e19d594364ef80",
    "artist_id": "6x9QLdzo6eBZxJ1bHsDkjg",
    "year": 2000
  },
  {
    "song_id": "0I3q5fE6wg7LIfHGngUTnV",
    "name": "Ms. Jackson",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/d1ccc50e31c6b6ab1d1cbe7aab66e88e86d586b4",
    "artist_name": "OutKast",
    "image_url": "https://i.scdn.co/image/e22454bac541aae05981a648afc407d9452ac52a",
    "artist_id": "1G9G7WwrXka3Z1r7aIDjI7",
    "year": 2001
  },
  {
    "song_id": "0OaunKfsxkgBvPv68jBbmm",
    "name": "It Wasn't Me",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/17fdf6125cb51499d2d1fe6b322a259534351c99",
    "artist_name": "Shaggy",
    "image_url": "https://i.scdn.co/image/248525ce1c1e5d2e635ebcc17549bbce0960cd20",
    "artist_id": "5EvFsr3kj42KNv97ZEnqij",
    "year": 2001
  },
  {
    "song_id": "3Otjx9ULpmWdUbkDTYDXHc",
    "name": "Only Time - Original Version",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/4164aefbe2c3a06e0c341b3b00ef82724c293f49",
    "artist_name": "Enya",
    "image_url": "https://i.scdn.co/image/d60f39f30cc78c648c41254deec80c8677b8ab5b",
    "artist_id": "6uothxMWeLWIhsGeF7cyo4",
    "year": 2001
  },
  {
    "song_id": "2QsZVnbWVSjKMXK6K3uRBL",
    "name": "U Remind Me",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/3cb57aa110d2166928b0bed9e6013914afbc1067",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/bbb3213481d62d681d856669c448ebc53025cf8d",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2001
  },
  {
    "song_id": "4KoecuyOpZaNFZ0UqVsllc",
    "name": "Follow Me",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/757e3d39106d7ab357ccd0561b9c109dfbd1456e",
    "artist_name": "Uncle Kracker",
    "image_url": "https://i.scdn.co/image/b338a25c17ea4a0ca3ef0e969cda0c7b5dd792c6",
    "artist_id": "2DnqqkzzDKm3vAoyHtn8So",
    "year": 2001
  },
  {
    "song_id": "6glsMWIMIxQ4BedzLqGVi4",
    "name": "So Fresh, So Clean",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/e04c566948179b526f8deb5a2f834b66a128bcd0",
    "artist_name": "OutKast",
    "image_url": "https://i.scdn.co/image/e22454bac541aae05981a648afc407d9452ac52a",
    "artist_id": "1G9G7WwrXka3Z1r7aIDjI7",
    "year": 2001
  },
  {
    "song_id": "3RmKpob8xzv1pzHEQrMJah",
    "name": "Let Me Blow Ya Mind",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/670b90f62c3306eb738fb3bd4d5d34d2c1cdf356",
    "artist_name": "Eve",
    "image_url": "https://i.scdn.co/image/dea7ef2c2a2bfeb01864cc3edb7760937f6c0e4a",
    "artist_id": "4d3yvTptO48nOYTPBcPFZC",
    "year": 2001
  },
  {
    "song_id": "0d2YnyIPgmPQqNSzDg8s8Q",
    "name": "It's Been Awhile",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/0ba974146f1a9bc20fb5658163ea9ca8a3b96f25",
    "artist_name": "Staind",
    "image_url": "https://i.scdn.co/image/80db37070b51400c8a693c9f9da9294c705e5418",
    "artist_id": "5KDIH2gF0VpelTqyQS7udb",
    "year": 2001
  },
  {
    "song_id": "0O5b1927V6FbLsZRtf7idi",
    "name": "Differences",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/42416bd19859621dbf98802449eecb4be0a1f8f8",
    "artist_name": "Ginuwine",
    "image_url": "https://i.scdn.co/image/8f6013c7c86bd8f00b3dbb1bb6f55b0124a3d5da",
    "artist_id": "7r8RF1tN2A4CiGEplkp1oP",
    "year": 2001
  },
  {
    "song_id": "5t514bZ4eZPtKJbKfuZJCA",
    "name": "Beautiful Day",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/9745a38c00be7acb32ba7c6ccf506c97320c86b0",
    "artist_name": "U2",
    "image_url": "https://i.scdn.co/image/31c5501555a758fed872dd78259baf6264dc3c54",
    "artist_id": "51Blml2LZPmy7TTiAg47vQ",
    "year": 2001
  },
  {
    "song_id": "16gvJsVyauI16RqmOrwJJk",
    "name": "Again",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/b4702bfe9cfdbc2fe0648982a0c38ecb400f504c",
    "artist_name": "Lenny Kravitz",
    "image_url": "https://i.scdn.co/image/d7dab70b4163c390399f85b2f27932f5554e4fe0",
    "artist_id": "5gznATMVO85ZcLTkE9ULU7",
    "year": 2001
  },
  {
    "song_id": "5mNV8Mz59bzyuQ53gTw0c0",
    "name": "Where The Party At",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/71100bc3c2cf2314824ac8d3ebe1a07a0cc3bea3",
    "artist_name": "Jagged Edge",
    "image_url": "https://i.scdn.co/image/4818f013d0fe4adcc39fffa3fd89738fb75b2ae9",
    "artist_id": "7Aq8lpLMSt1Zxu56pe9bmp",
    "year": 2001
  },
  {
    "song_id": "0S0d7Qad5v7ZLQiAuwQxab",
    "name": "Kryptonite",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/ef462040ef8014b67af80d16a473377bb1698c63",
    "artist_name": "3 Doors Down",
    "image_url": "https://i.scdn.co/image/0af6372226f295e89d5b540193f0d548f9f16532",
    "artist_id": "2RTUTCvo6onsAnheUk3aL9",
    "year": 2001
  },
  {
    "song_id": "2WQAknZHrJAEQOFXe0F44P",
    "name": "Austin",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/8b4ca89c0f8edcbba33d2d79cd627b5bd4507dca",
    "artist_name": "Blake Shelton",
    "image_url": "https://i.scdn.co/image/f0264d0abd5d237a49f797cfe1752a69d28a9d1e",
    "artist_id": "1UTPBmNbXNTittyMJrNkvw",
    "year": 2001
  },
  {
    "song_id": "3oW6SWwGqiZSPTiAp7ZQoH",
    "name": "It's A Great Day To Be Alive",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/02884f57c30ad4ef3a33553333f35d5f150a2934",
    "artist_name": "Travis Tritt",
    "image_url": "https://i.scdn.co/image/c2aa89c676e57d38b7cf1a48b4cdb8f424a38846",
    "artist_id": "2M4Yt7oKGoYd0wqU44k4i2",
    "year": 2001
  },
  {
    "song_id": "3unsLiH5FXmaDWtT5Imolu",
    "name": "Fallin'",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/d08be4606279cf6b73a8afd262d814584dd9c108",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/188258f60cc0ac926d89bfeebaf6a7035b7a78dc",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2001
  },
  {
    "song_id": "46n2EGFnPC3tzWCN1Aqe26",
    "name": "This I Promise You",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/76d039a62912f44a6e7ca4da655d3ce30c4f93f8",
    "artist_name": "N Sync",
    "image_url": "https://i.scdn.co/image/229de277b232ffb225ee5699954490e97c0dc753",
    "artist_id": "6Ff53KvcvAj5U7Z1vojB5o",
    "year": 2001
  },
  {
    "song_id": "3E8eSqBoqLs8ELbrK3NJHs",
    "name": "Hemorrhage (In My Hands)",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/c028197f49269eca258c2b02300fbacbaf10b4e3",
    "artist_name": "Fuel",
    "image_url": "https://i.scdn.co/image/51eec498385b3c7e3c0f7c0834acd1733b9097e8",
    "artist_id": "0EyuKHE1AeE9lWUF8mzKVp",
    "year": 2001
  },
  {
    "song_id": "6iajHa34cSiD5s42Cq9miJ",
    "name": "Peaches & Cream (feat. P. Diddy)",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/53e3a68c25775d798a23dc41cb0933a4a7a0502a",
    "artist_name": "112",
    "image_url": "https://i.scdn.co/image/592403c38524862fc9c498b115620e283b2b7a5b",
    "artist_id": "7urq0VfqxEYEEiZUkebXT4",
    "year": 2001
  },
  {
    "song_id": "2jZESuj23z92RqbooSsMqV",
    "name": "Bootylicious",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/fee980494144d77189d3018f48f29a9fa7ef674f",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/dae0edc92ff769dc9fe7af7b484578f709a724b1",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 2001
  },
  {
    "song_id": "1gaUODQzPR6eNcJtNe0cyZ",
    "name": "I'm Real - Murder Remix featuring Ja Rule-Clean Version",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/cc73d88700631762fca26a1cb2e9bce77d4a3353",
    "artist_name": "Jennifer Lopez",
    "image_url": "https://i.scdn.co/image/722427cfd9fddf45319347c64ba976d1bca2369b",
    "artist_id": "2DlGxzQSjYe5N6G9nkYghR",
    "year": 2001
  },
  {
    "song_id": "1X1KDnhTnb28tpJlgOV3lD",
    "name": "Promise",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/1af5d6a61f5900da0b3974b1806494359ae538f9",
    "artist_name": "Jagged Edge",
    "image_url": "https://i.scdn.co/image/54e37d9b82b5c69fea884d4eca312b01d4a49f49",
    "artist_id": "7Aq8lpLMSt1Zxu56pe9bmp",
    "year": 2001
  },
  {
    "song_id": "4h5KBcltGefua73AUPYps7",
    "name": "Izzo (H.O.V.A.)",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/b2a3468186680d3f45f404bfd011e54d7efab1e1",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/1bc858f180093a371a4a2e7d8eabf6959bc5d561",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2001
  },
  {
    "song_id": "65KzDXZm9aQt8sA1k0ZVG3",
    "name": "The Space Between",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/b816044f5445d3efdd5e13ec4008c1cc8cb66474",
    "artist_name": "Dave Matthews Band",
    "image_url": "https://i.scdn.co/image/d808724d4f588f1a00fd88f81703edf63ff455ce",
    "artist_id": "2TI7qyDE0QfyOlnbtfDo7L",
    "year": 2001
  },
  {
    "song_id": "23rc0FT9mCa12LjYSeDXlW",
    "name": "Drops of Jupiter",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/9db370dbf1bcca059048ad8e05652bc95cc0eab6",
    "artist_name": "Train",
    "image_url": "https://i.scdn.co/image/d06409e2189baef75c4ffa520296dfdbe47b657b",
    "artist_id": "3FUY2gzHeIiaesXtOAdB7A",
    "year": 2001
  },
  {
    "song_id": "65B1tEOv5W294uCKbmEcFV",
    "name": "I Hope You Dance",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/c5975d2be71a0970200ee0949418a20214ef7b5d",
    "artist_name": "Lee Ann Womack",
    "image_url": "https://i.scdn.co/image/36f2dbf2afd31cd260452a2170045404ca182f18",
    "artist_id": "738OS3zrCO782uDiUN9pet",
    "year": 2001
  },
  {
    "song_id": "6CkydK3NOAMScIhViRzXoB",
    "name": "Ain't Nothing 'Bout You",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/41d290f0bf45f1e89d5b592a3f3f969c12109d82",
    "artist_name": "Brooks & Dunn",
    "image_url": "https://i.scdn.co/image/aec75b35408f3d3371d183f94adf872c39d04ba3",
    "artist_id": "0XKOBt59crntr7HQXXO8Yz",
    "year": 2001
  },
  {
    "song_id": "6j2aNuhJJUnRj6UHcvn5PI",
    "name": "I'm A Thug",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/8779436d7a36d9f3e89136d46a533a42d658653b",
    "artist_name": "Trick Daddy",
    "image_url": "https://i.scdn.co/image/96f75e120fd7e85eeeeaa243502c7be29ea85155",
    "artist_id": "12FHARd9fY0Tu0ila4Ua25",
    "year": 2001
  },
  {
    "song_id": "0OvwzS8FX73Bw7ykMRmffU",
    "name": "Put It On Me",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/900db0f12b86d99c477f201a3ccc6a23f912a1a6",
    "artist_name": "Ja Rule",
    "image_url": "https://i.scdn.co/image/dacd78f5ae49f7d66d38eaf47c6e40833571240c",
    "artist_id": "1J2VVASYAamtQ3Bt8wGgA6",
    "year": 2001
  },
  {
    "song_id": "3eWxADd1CLwJVhyPzrCpl8",
    "name": "When It's Over - Remastered",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/d3aa68b1e425a685a748f4dcfebe161eba11ad1e",
    "artist_name": "Sugar Ray",
    "image_url": "https://i.scdn.co/image/b3cd281121c54cd413b1ee03a99cfa289321dfaf",
    "artist_id": "4uN3DsfENc7dp0OLO0FEIb",
    "year": 2001
  },
  {
    "song_id": "34Vn9nKfztyLco9lJazy4j",
    "name": "I'm Already There",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/61a7210e07b3d7bc425055a988c676202f9a8366",
    "artist_name": "Lonestar",
    "image_url": "https://i.scdn.co/image/f5df5a273bf472985a1640bec4d7a28019f8c189",
    "artist_id": "3qbnxnvUqR14MJ9g8QwZJK",
    "year": 2001
  },
  {
    "song_id": "3PbHle8KhJp6GuO1jLdEmQ",
    "name": "Be Like That",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/7d3d6345a6011eecf77b5205f23381f93ddd8717",
    "artist_name": "3 Doors Down",
    "image_url": "https://i.scdn.co/image/ff04d03992ef9aee287b57796c36510fdd1f970c",
    "artist_id": "2RTUTCvo6onsAnheUk3aL9",
    "year": 2001
  },
  {
    "song_id": "5k3sanOGourYTSWNvphUnz",
    "name": "Hit 'Em up Style (Oops!)",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/54b55bedb23653dc55355512f47936ab3a5d74ad",
    "artist_name": "Blu Cantrell",
    "image_url": "https://i.scdn.co/image/6dc8cb691927438027fea7d932e7613f2619ea21",
    "artist_id": "6vytZ677lz4LzCrUDcDokM",
    "year": 2001
  },
  {
    "song_id": "6vgN6KGstss0GnBbKlMNK9",
    "name": "The Way You Love Me",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/5c4abbeb5445f9281f0497368e51da6426d8c173",
    "artist_name": "Faith Hill",
    "image_url": "https://i.scdn.co/image/f45f88dd3641f883e64204ba218ab205db071cec",
    "artist_id": "25NQNriVT2YbSW80ILRWJa",
    "year": 2001
  },
  {
    "song_id": "50aYYSNIEfwUjCvjDgkrQe",
    "name": "Fiesta - Remix",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/2cdbb517d9dd13c0c9a5fb0865a0be37ef6c0039",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/37145297a059445de8ce1cdd846132d8fb3f7803",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 2001
  },
  {
    "song_id": "2g4SumkFZpHOypfF5xng9Z",
    "name": "Music - feat. Marvin Gaye",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/cb7a57149fd73adbe206e5ea36c0a2e651a8e65c",
    "artist_name": "Erick Sermon",
    "image_url": "https://i.scdn.co/image/4c299c1db3d4d11b5cb480675218f1ff83a9da0c",
    "artist_id": "2VX0o9LDIVmKIgpnwdJpOJ",
    "year": 2001
  },
  {
    "song_id": "7C5XOHS3FXH8PWbAB9WdWS",
    "name": "Stutter - Double Take Remix",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/6be5c2efaad5a26722d5e4b260195070bb3d9e36",
    "artist_name": "Joe",
    "image_url": "https://i.scdn.co/image/10389ad5ea381aa0564a03617f037bc347539942",
    "artist_id": "3zTOe1BtyTkwNvYZOxXktX",
    "year": 2001
  },
  {
    "song_id": "1ak0S3NhwWrUgNlQhJ1412",
    "name": "Case Of The Ex (Whatcha Gonna Do)",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/fb527c388a50c5ff4a7cdf90854f2878ee8b546e",
    "artist_name": "Mya",
    "image_url": "https://i.scdn.co/image/ae8dfcd0a020101e3cf08b0ae2767df909c93e04",
    "artist_id": "6lHL3ubAMgSasKjNqKb8HF",
    "year": 2001
  },
  {
    "song_id": "5zn1hpm9N0ylKB7kOtpCw2",
    "name": "No More (Baby I'ma Do Right)",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/4b9ff8137875a6439392bee896d1b4345aea5d55",
    "artist_name": "3LW",
    "image_url": "https://i.scdn.co/image/5b7da526e6bbc93277cd93091d03e1799d0b6428",
    "artist_id": "2lFHVcUeJ9Gq6AZiU3ZAOa",
    "year": 2001
  },
  {
    "song_id": "4tgeQrPu5xkrnwErym2JsJ",
    "name": "Here's To The Night",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/b6149d8e69f5e32c5748d34d72a828fbc30e152d",
    "artist_name": "Eve 6",
    "image_url": "https://i.scdn.co/image/0711f23f7996c2155cacad663d396c9a9f60eaa1",
    "artist_id": "4Eqd24yS5YcxI8b6Xfuwr8",
    "year": 2001
  },
  {
    "song_id": "5rdRPZnY9B2pxJNWImbsFn",
    "name": "If You're Gone - Remastered Version",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/adaf3f21ad4640f567edcacb81eaa253334b3faa",
    "artist_name": "Matchbox Twenty",
    "image_url": "https://i.scdn.co/image/c32c828af2b4f107b333814cc0e9dc729f8f72da",
    "artist_id": "3Ngh2zDBRPEriyxQDAMKd1",
    "year": 2001
  },
  {
    "song_id": "1tJkic0TURTCXw3rBhYgWu",
    "name": "What Would You Do?",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/3e70ffb1a023b3924d09761eda59162cbda9836a",
    "artist_name": "City High",
    "image_url": "https://i.scdn.co/image/32158ecb56e99ab5e188d23962594631985c4a63",
    "artist_id": "0LlPal5iJGcCWwZp8VefVs",
    "year": 2001
  },
  {
    "song_id": "13KEdaWIc7T6ozEmnJjHy1",
    "name": "Get Ur Freak On",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/7ef4193029d5be25d99a3fd68c43b643440558e7",
    "artist_name": "Missy Elliott",
    "image_url": "https://i.scdn.co/image/846a84cd2b26eb04e3b64dd0682d4ab043216b70",
    "artist_id": "2wIVse2owClT7go1WT98tk",
    "year": 2001
  },
  {
    "song_id": "0xikWgPgYN9BEes0ieZ8Co",
    "name": "Heard It All Before",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/019c65fc024d27df11142a99a77733f49053870f",
    "artist_name": "Sunshine Anderson",
    "image_url": "https://i.scdn.co/image/9991aa1ec3c10bfc98eaf0d24f08f871ec08fdc0",
    "artist_id": "0hnmRa5ahunapQbPjKUbMK",
    "year": 2001
  },
  {
    "song_id": "703b51gh4ah7GYTYwKphj5",
    "name": "E.I.",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/0b085d6a39b9aab808156777424aa79a39f047fc",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/ce9a1f6f0cbd783144cfe61cf493d4f6872eeefc",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2001
  },
  {
    "song_id": "2LJ58vWuLhFQ2bJPqzhveL",
    "name": "Fill Me In",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/6a01068dfebeef508d2f914821b6cf73d5bcfa00",
    "artist_name": "Craig David",
    "image_url": "https://i.scdn.co/image/bea8aa5a06764437610173059d0cbc5e87f09eed",
    "artist_id": "2JyWXPbkqI5ZJa3gwqVa0c",
    "year": 2001
  },
  {
    "song_id": "0WdaRmHBMhUoLPqHLdb9yg",
    "name": "All or Nothing",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/671f123852716ed34319c9c7950fecd1ffa3d107",
    "artist_name": "O-Town",
    "image_url": "https://i.scdn.co/image/6dc8cb691927438027fea7d932e7613f2619ea21",
    "artist_id": "4zKhX6hYCQPNs406umP9Fx",
    "year": 2001
  },
  {
    "song_id": "0CmXXEwul09Kf8X68lPA5l",
    "name": "Missing You",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/911748ef62b1356ac658b01c2d70cf1c16a690ea",
    "artist_name": "Case",
    "image_url": "https://i.scdn.co/image/cfee455c237b7e0b32c06554f887652087805838",
    "artist_id": "5aEWnrN8h3MhuFUPRfaVuy",
    "year": 2001
  },
  {
    "song_id": "3opZewONQQhh90M75Qrm83",
    "name": "Crazy",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/a07dca628eaacbb8957f5b5125761707a1ad170c",
    "artist_name": "K-Ci & JoJo",
    "image_url": "https://i.scdn.co/image/7f60bac9690bf5b3f4c53f21e916154acf030410",
    "artist_id": "05RZIdfz59ZW2FvFuwnmNK",
    "year": 2001
  },
  {
    "song_id": "08GOEVXupC6ECbulhPGQKm",
    "name": "Stranger In My House",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/da17606ff6032db9966dba60e99bfff47bc8d493",
    "artist_name": "Tamia",
    "image_url": "https://i.scdn.co/image/016ab4179ed538a1825039aff0d6708ff62b2508",
    "artist_id": "0le01dl1WllSHhjEXRl4in",
    "year": 2001
  },
  {
    "song_id": "1kEruIJPLnM1agV8bnCmSZ",
    "name": "Livin' It Up",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/75d5e6b1501f5b9c6dda1d68e9fca52108b41ddd",
    "artist_name": "Ja Rule",
    "image_url": "https://i.scdn.co/image/a8fbcba14369f20286cb4450cee28874f38edc7f",
    "artist_id": "1J2VVASYAamtQ3Bt8wGgA6",
    "year": 2001
  },
  {
    "song_id": "1D70EbZoedMGzZWYZvfQIY",
    "name": "Ride Wit Me - Album Version (Edited)",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/e8378021b4f931de3ce4662f24b9e3ba7da74b55",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/21156fd967f0de2bf024f95a966d2b21d9ab76e3",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2001
  },
  {
    "song_id": "5sC4GlhZ9zCb6gPxSIbX5j",
    "name": "Don't Tell Me",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/ae56b244dfb448951d28233cb4c3054ba95bb3f7",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 2001
  },
  {
    "song_id": "0RYs96jdB4c3yXSaJ6h1Ca",
    "name": "Crazy For This Girl",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/e13c914e65f0197421f913a2b51fe8e0a3b80754",
    "artist_name": "Evan And Jaron",
    "image_url": "https://i.scdn.co/image/0682acca4918f2e6d6b58b0a3247e1e61a944d34",
    "artist_id": "1xeZcY93inQY14LWwnxd5G",
    "year": 2001
  },
  {
    "song_id": "4cNhx6OO1XpvwT6xZnb83B",
    "name": "Southern Hospitality (Featuring Pharrell)",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/e9fde791c7dd3e6b341a51f42b0c1c632b2c605e",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/e000ac442b5b07daafe65f564d5120bab8951c33",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2001
  },
  {
    "song_id": "7HjJQunSPwkXXVnGeItUEH",
    "name": "Contagious",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/dcbbd958e2bde2c33e4870d3add5762f50e81776",
    "artist_name": "The Isley Brothers",
    "image_url": "https://i.scdn.co/image/d050d26d0491f59bf61bab5f7da7567c3f0a746c",
    "artist_id": "53QzNeFpzAaXYnrDBbDrIp",
    "year": 2001
  },
  {
    "song_id": "2tZZGKQDPIk37SkmNm0Od7",
    "name": "Independent Women, Pt. I - #1's Edit",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/4fc4823a48d7f4836cf3be6167dad8af923471de",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/dae0edc92ff769dc9fe7af7b484578f709a724b1",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 2001
  },
  {
    "song_id": "2D5QhvAWddOJTvk1XawrTU",
    "name": "Family Affair",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/7f051f279ea504faafe917ebf36acd40cca8b264",
    "artist_name": "Mary J. Blige",
    "image_url": "https://i.scdn.co/image/749a50e1789a9e5dadc6beadfada46313aed889a",
    "artist_id": "1XkoF8ryArs86LZvFOkbyr",
    "year": 2001
  },
  {
    "song_id": "1rzssdgfZiGs7VAMsYN9SF",
    "name": "Never Had A Dream Come True",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/363d179314ef11943f896e8f0fe53424177b37ca",
    "artist_name": "S Club 7",
    "image_url": "https://i.scdn.co/image/c70dfe9d7cc9aa3b53cc0a8575969bd93c0e4244",
    "artist_id": "1kM5rgJvkiDMOoKX56H6pX",
    "year": 2001
  },
  {
    "song_id": "2TVSa07y6g3HRn4oLWv57M",
    "name": "Irresistible",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/a877a4fe3c61f7258e928744503f477c92a2c25e",
    "artist_name": "Jessica Simpson",
    "image_url": "https://i.scdn.co/image/57fc55ef4d39e704c63fb9140efd11af11648cad",
    "artist_id": "2tFN9ubMXEhdAQvdQxcsma",
    "year": 2001
  },
  {
    "song_id": "3tEYaYV2rrsAcL6AcPxetH",
    "name": "Who I Am",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/ad642a14576562219ade1fc180a8675e8f7d5405",
    "artist_name": "Jessica Andrews",
    "image_url": "https://i.scdn.co/image/743f98d165a7ca1724ea8d57d6c79a36119e08eb",
    "artist_id": "5rNhVm2CnqiO5Lv4Dt2lck",
    "year": 2001
  },
  {
    "song_id": "6GQ3WTaNHMFlVmN4burGat",
    "name": "Butterfly",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/d9c0f8b89e230c0f85d6e3280d3b2bf9e40c471d",
    "artist_name": "Crazy Town",
    "image_url": "https://i.scdn.co/image/5c934379ad8f9e1748487a25e5cfa9b71f37c772",
    "artist_id": "4iSKnRZAxkmqNok6tv10Se",
    "year": 2001
  },
  {
    "song_id": "4v6V17Q3pypLu7zayg8vMa",
    "name": "One Minute Man - feat. Ludacris Amended",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/ac5e11e85c79b0a100c84ae992a0104298e4bba3",
    "artist_name": "Missy Elliott",
    "image_url": "https://i.scdn.co/image/846a84cd2b26eb04e3b64dd0682d4ab043216b70",
    "artist_id": "2wIVse2owClT7go1WT98tk",
    "year": 2001
  },
  {
    "song_id": "1UdsBQTJOqpXXr13su0uI3",
    "name": "I Just Wanna Love U (Give It 2 Me)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/2611a2a043732b233394bfeac9888a29011d6b31",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/3f900c73ca098d2a2bac967e45290d3841c7ac0e",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2001
  },
  {
    "song_id": "4F8FfoCCovgRfFMpQRKPMb",
    "name": "Oochie Wally - Remix",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/bd75429b766a9ae358aee0cc2e2340f53246fa6d",
    "artist_name": "Nas",
    "image_url": "https://i.scdn.co/image/806bd3fa91de18e9abc9396a5267c2d55b4d6715",
    "artist_id": "20qISvAhX20dpIbOOzGK3q",
    "year": 2001
  },
  {
    "song_id": "0s9A4pUBLw68E76vY8LU9K",
    "name": "My Baby",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/8c249618f69ed272f29843329a0814809a4de6b7",
    "artist_name": "Lil' Romeo",
    "image_url": "https://i.scdn.co/image/0b3c2359cfc27695af4be6056cdbec60c431aea6",
    "artist_id": "2AXQzJRDvlJrq0fiVAnRy8",
    "year": 2001
  },
  {
    "song_id": "1C2Q3AymKS17bw7T5gfq6p",
    "name": "Dance With Me",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/cca14e5373aad6cdee9b9b5b0c2388967353f9fc",
    "artist_name": "Debelah Morgan",
    "image_url": "https://i.scdn.co/image/fd156efad78c660da078ba93b09d790ea68f892a",
    "artist_id": "0tRRrNLQY5rejEDVSjy4w3",
    "year": 2001
  },
  {
    "song_id": "0ERtLLDn1sSl4hbA8VSGG7",
    "name": "I Wish - Clean Radio Edit",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/91275939bbcd0768310925c4648165f92e684a11",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/37145297a059445de8ce1cdd846132d8fb3f7803",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 2001
  },
  {
    "song_id": "54PVf8d9j84aJXj4ZTssBr",
    "name": "Thank You",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/bf82b6ce601f7889b7a8b94b2c8abc40313580c8",
    "artist_name": "Dido",
    "image_url": "https://i.scdn.co/image/7f8c36efb0d1a0b5024ea54d3e0d4f29447849c5",
    "artist_id": "2mpeljBig2IXLXRAFO9AAs",
    "year": 2001
  },
  {
    "song_id": "5taqvRgn1DdZ117p2DhPXQ",
    "name": "You Shouldn't Kiss Me Like This",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/929b65a3dbffd0e5fd73055287b92006a68cd790",
    "artist_name": "Toby Keith",
    "image_url": "https://i.scdn.co/image/e94430a48673742a20b7b18598c3eeca84725971",
    "artist_id": "2bA6fzP0lMAQ4kz6CF61w8",
    "year": 2001
  },
  {
    "song_id": "4LhtNlYHIEZYAAE4UN5x6b",
    "name": "Angel",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/c614161f6b5fda0ad9ed1c2103e61c7aff801b90",
    "artist_name": "Shaggy",
    "image_url": "https://i.scdn.co/image/666eddd9c4749a4cdc4b30422bc70cc034d8ba68",
    "artist_id": "5EvFsr3kj42KNv97ZEnqij",
    "year": 2001
  },
  {
    "song_id": "1ZlkTsDGC9SMF3oXSXJLFY",
    "name": "Someone To Call My Lover",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/10c5a239272e7ddbaa30561a08d341bc2e5582d9",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/2f15d571d8c535ab30318e2557d1b8a5a7d86e34",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 2001
  },
  {
    "song_id": "610sx23crS0FOQISsn2e49",
    "name": "I Do!!",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/482d64c3966cdc9619b6516b90836f6b18aa9c7a",
    "artist_name": "Toya",
    "image_url": "https://i.scdn.co/image/6dc8cb691927438027fea7d932e7613f2619ea21",
    "artist_id": "6cStgiv1qH5z6GYrFsjuGj",
    "year": 2001
  },
  {
    "song_id": "5E5BK9zDVTsntNcU3euxk2",
    "name": "I Wanna Be Bad - No rap with vocorder",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/b41e5318d8896affcfd1dbf6b88a09db133a97c2",
    "artist_name": "Willa Ford",
    "image_url": "https://i.scdn.co/image/cde2c79af54d5973d77bb91919b132bcb8d15db3",
    "artist_id": "0kuEP1E9k3WBEwMKwEmp9u",
    "year": 2001
  },
  {
    "song_id": "40ZViEcg2JVcLysJc627Rz",
    "name": "I'm Like A Bird",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/a5de5818da282297b6b65958bc52fa066ebe38a5",
    "artist_name": "Nelly Furtado",
    "image_url": "https://i.scdn.co/image/9a7587bc1450cea3b65de7cc5200c067e12af8b9",
    "artist_id": "2jw70GZXlAI8QzWeY2bgRc",
    "year": 2001
  },
  {
    "song_id": "67ZwG9JhGMFKOojZrwZKLT",
    "name": "Danger (Been So Long) featuring Nivea - Clean LP Version",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/feccf6a29b19e51e07240b2cdc2a31095592f9b7",
    "artist_name": "Mystikal",
    "image_url": "https://i.scdn.co/image/870454fd7e6de56bb1a5030cf190098e9d03cac0",
    "artist_id": "3LIJJJkO7R5RasRwt7xIn5",
    "year": 2001
  },
  {
    "song_id": "63j3B2fEUNlPy964K5PVTO",
    "name": "Lady Marmalade",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/3ca193eab4a10280651542f6d7cbcd3d19e865b4",
    "artist_name": "Various Artists",
    "image_url": "https://i.scdn.co/image/022914a697a2c21292408a443a8e80b0661556f4",
    "artist_id": "0LyfQWJT6nXafLPZqxe9Of",
    "year": 2001
  },
  {
    "song_id": "3TNoNv8Z80M3pfm6CcODsh",
    "name": "One More Day",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/8ef46c1f57490a594500666b3a2dbb3861a425c6",
    "artist_name": "Diamond Rio",
    "image_url": "https://i.scdn.co/image/9d83ef8f1d5a8e6a36b264dee83208061a49ac40",
    "artist_id": "0svyIX7Xu3DVMkrOtB88i6",
    "year": 2001
  },
  {
    "song_id": "4FeiRHc70lZuOslNE5iYbp",
    "name": "Play",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/b5f3b2507c55191c909286acedc17261c28f646d",
    "artist_name": "Jennifer Lopez",
    "image_url": "https://i.scdn.co/image/f7f3d01ab94d40f2354c64c1c4d6fcd122a81096",
    "artist_id": "2DlGxzQSjYe5N6G9nkYghR",
    "year": 2001
  },
  {
    "song_id": "4WOjFgYXA9UtYAwdwSTYZ6",
    "name": "Turn Off The Light",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/e0b4817f35b3b084428d44b75c9c2f769394806d",
    "artist_name": "Nelly Furtado",
    "image_url": "https://i.scdn.co/image/9a7587bc1450cea3b65de7cc5200c067e12af8b9",
    "artist_id": "2jw70GZXlAI8QzWeY2bgRc",
    "year": 2001
  },
  {
    "song_id": "0maGllQEuiHk3UbOBLC05d",
    "name": "Hanging By A Moment",
    "popularity": 20,
    "preview_url": "https://p.scdn.co/mp3-preview/3334de8a7812c713c595ac46e3781e42989926f5",
    "artist_name": "Lifehouse",
    "image_url": "https://i.scdn.co/image/9a12b417ab9376fc26566608014d99bffe73cf7c",
    "artist_id": "5PokPZn11xzZXyXSfnvIM3",
    "year": 2001
  },
  {
    "song_id": "7tlQ2lyasHeorLuWkJljUc",
    "name": "Don't Happen Twice",
    "popularity": 19,
    "preview_url": "https://p.scdn.co/mp3-preview/2607a94b41ba0ff3fafec8ed6b542d9c8f1ccc6f",
    "artist_name": "Kenny Chesney",
    "image_url": "https://i.scdn.co/image/51a992c631c038bbf9a1cf3eecc141b9a1c52e76",
    "artist_id": "3grHWM9bx2E9vwJCdlRv9O",
    "year": 2001
  },
  {
    "song_id": "3ckAfCo4YxRXU8CwvF9BIK",
    "name": "Survivor",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/0068b638a17731130064785cf811767bb1fb775d",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/9a12b417ab9376fc26566608014d99bffe73cf7c",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 2001
  },
  {
    "song_id": "4ktd4eFTNwfBft4zQxSCqO",
    "name": "Love Don't Cost A Thing",
    "popularity": 15,
    "preview_url": "https://p.scdn.co/mp3-preview/bddf0a30ee98dc7f94a6f569acfcb4394e7f26a3",
    "artist_name": "Jennifer Lopez",
    "image_url": "https://i.scdn.co/image/9a12b417ab9376fc26566608014d99bffe73cf7c",
    "artist_id": "2DlGxzQSjYe5N6G9nkYghR",
    "year": 2001
  },
  {
    "song_id": "21Hb463GK8hP890bsNVCfg",
    "name": "All For You",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/87ef2912cbf0c147374741fef019ffbfc6866434",
    "artist_name": "Janet Jackson",
    "image_url": "https://i.scdn.co/image/9a12b417ab9376fc26566608014d99bffe73cf7c",
    "artist_id": "4qwGe91Bz9K2T8jXTZ815W",
    "year": 2001
  },
  {
    "song_id": "2YGm6IyhHutoxJDUTow1Fg",
    "name": "He Loves U Not",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/1e30f4f32e43d8f757304dffa846d5f4ae561271",
    "artist_name": "Various Artists",
    "image_url": "https://i.scdn.co/image/94dd239cd6803a9f55e23a02356a6150329a220d",
    "artist_id": "0LyfQWJT6nXafLPZqxe9Of",
    "year": 2001
  },
  {
    "song_id": "60a0Rd6pjrkxjPbaKzXjfq",
    "name": "In The End",
    "popularity": 81,
    "preview_url": "https://p.scdn.co/mp3-preview/d88f74748d7ca16715e78fc82ba455dc925a9a17",
    "artist_name": "Linkin Park",
    "image_url": "https://i.scdn.co/image/265c0eab7914bcd19da90fde628ef5579d2b1a32",
    "artist_id": "6XyY86QOPPrYVGvF9ch6wz",
    "year": 2002
  },
  {
    "song_id": "0j5X6DrzkgY64Mm8NQp5mi",
    "name": "Lose Yourself - Soundtrack Version",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/5a9da4605959338f2363079af5895e74fba8a479",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/af7a8977e61479d27177ef37b5c0101af5b54a36",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2002
  },
  {
    "song_id": "7J41dYQolQJEtj3UmKLu5r",
    "name": "U Got It Bad",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/5e76473e1ffaf09f739451cd09973f4b1172aa75",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/bbb3213481d62d681d856669c448ebc53025cf8d",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2002
  },
  {
    "song_id": "5pBvNeOAJ54zgd5lEOmM3b",
    "name": "Without Me",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/2771ceed50bf37af9a3f678fc0f8acc3e5007089",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/a89a0716ece38c0a06286dd2bbd4f575306c3a1c",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2002
  },
  {
    "song_id": "3jagJCUbdqhDSPuxP8cAqF",
    "name": "Work It - Promo",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/8d51886641129eee613a8f0ee94d361fdf1403a5",
    "artist_name": "Missy Elliott",
    "image_url": "https://i.scdn.co/image/9093be4c2737d8e27a3ceb89a3b2f10e04c8e435",
    "artist_id": "2wIVse2owClT7go1WT98tk",
    "year": 2002
  },
  {
    "song_id": "2JFEZO2ZxzffY0wd9MYEu3",
    "name": "A Thousand Miles",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/e23393806ced145a83d61f8e1d7d0aab740faceb",
    "artist_name": "Vanessa Carlton",
    "image_url": "https://i.scdn.co/image/21fed67a180a496a36ef460dbc370391ea1e6908",
    "artist_id": "5ILrArfIV0tMURcHJN8Q07",
    "year": 2002
  },
  {
    "song_id": "5kD9T7GForh8LnRz5ClbL8",
    "name": "The Middle",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/b666253de566aa6ced7c2a25978d29ac2c1fc6fc",
    "artist_name": "Jimmy Eat World",
    "image_url": "https://i.scdn.co/image/0d245a5311d92429e499a1195d5ec1e21e113a3c",
    "artist_id": "3Ayl7mCk0nScecqOzvNp6s",
    "year": 2002
  },
  {
    "song_id": "2mKouqwAIdQnMP43zxR89r",
    "name": "What's Luv? - feat. Ja-Rule & Ashanti Explicit",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/caf8aabc3ceffb4ff313774afb8cbe5ad1dccb90",
    "artist_name": "Fat Joe",
    "image_url": "https://i.scdn.co/image/ed4d938eec4ce2c0d1e1e94d919e244e0900abda",
    "artist_id": "3ScY9CQxNLQei8Umvpx5g6",
    "year": 2002
  },
  {
    "song_id": "0ARK753YaiJbpLUk7z5yIM",
    "name": "Dilemma",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/51bfd1456bd9d3e02d13427594d4cb7185f6caca",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/71c0881e2fceca76333e7c53fdee09d10a2ed287",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2002
  },
  {
    "song_id": "37qI0mchgzUSeUhPiwUWPY",
    "name": "Wherever You Will Go",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/dd01abdfb647f656962bd1e86d069512e16aabfe",
    "artist_name": "The Calling",
    "image_url": "https://i.scdn.co/image/f1e806e5d2da4fa25d06443e3d881799dabff760",
    "artist_id": "5aMmmNxw4vgpc5XC6hK0zp",
    "year": 2002
  },
  {
    "song_id": "6W2Ef5Ph6ILTUAedoQ3QIv",
    "name": "Like I Love You",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/c942a7bfecca7d55e88afb37d2e3a1dcdac1701c",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/b4096aab2a49b2f2fe2d0f1b483ac51183dba760",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2002
  },
  {
    "song_id": "5q4I6I0ytvTKuYe1Fquxnx",
    "name": "Family Affair",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/ac2719aa761e41ee6514e011bd16c09bb86f360d",
    "artist_name": "Mary J. Blige",
    "image_url": "https://i.scdn.co/image/5b691805c41346d31d50058cb195b8c8456c060c",
    "artist_id": "1XkoF8ryArs86LZvFOkbyr",
    "year": 2002
  },
  {
    "song_id": "5q4I6I0ytvTKuYe1Fquxnx",
    "name": "Family Affair",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/ac2719aa761e41ee6514e011bd16c09bb86f360d",
    "artist_name": "Mary J. Blige",
    "image_url": "https://i.scdn.co/image/5b691805c41346d31d50058cb195b8c8456c060c",
    "artist_id": "1XkoF8ryArs86LZvFOkbyr",
    "year": 2002
  },
  {
    "song_id": "7wFmQutRb7XUDo2mlftXa2",
    "name": "Cleanin' Out My Closet",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/641b9b3d22bd147b130352ff984faaaf0843bed3",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/a89a0716ece38c0a06286dd2bbd4f575306c3a1c",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2002
  },
  {
    "song_id": "7lVNTXkI3cHFvcXiI8damb",
    "name": "I Need A Girl (Part One) (feat. Usher & Loon)",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/6497ab1d8a0601244f9dc5e0fd4e4573e18d1905",
    "artist_name": "Diddy",
    "image_url": "https://i.scdn.co/image/592403c38524862fc9c498b115620e283b2b7a5b",
    "artist_id": "59wfkuBoNyhDMQGCljbUbA",
    "year": 2002
  },
  {
    "song_id": "6Wt5QBzHp9vdSLAFlvdnDQ",
    "name": "I Need A Girl Part 2",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/261e18cdb08efa0f4fa78716e85e80a30ac1ab25",
    "artist_name": "P. Diddy feat. Loon, Ginuwine, Mario Winans",
    "image_url": "https://i.scdn.co/image/3802e4320b5e7a7893095e7e700624777e14e5a1",
    "artist_id": "0tzaUCdsWgc2yC3wTbtQyH",
    "year": 2002
  },
  {
    "song_id": "5PCJldueshnwqQVjS16543",
    "name": "U Don't Have To Call",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/f598c4c8b8e1740c124c2fa4aab7a6ed3aceb8f9",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/bbb3213481d62d681d856669c448ebc53025cf8d",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2002
  },
  {
    "song_id": "4gdQ8QShlC3toP9Mkc8DwB",
    "name": "Oh Boy",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/45877c37a2af7f17557b082e228e4f574ae4eff6",
    "artist_name": "Cam'Ron",
    "image_url": "https://i.scdn.co/image/9df0d57e81128b329211a13f27a4d6546489198b",
    "artist_id": "7iMvwE8qANp3aIfAGKEAwS",
    "year": 2002
  },
  {
    "song_id": "5o4cq6gKFAbR1V7wITwB7n",
    "name": "Complicated",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/dc146fa45a423b11e75365eedb4fcddf15fb7404",
    "artist_name": "Avril Lavigne",
    "image_url": "https://i.scdn.co/image/07348c536e67a88dd873112ffa483c6c87957401",
    "artist_id": "0p4nmQO2msCgU4IF37Wi3j",
    "year": 2002
  },
  {
    "song_id": "0kr6d8wHDQPlzuET33NVn6",
    "name": "All You Wanted",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/e88b5935bee1f95f477f68deda2a5d12fe919560",
    "artist_name": "Michelle Branch",
    "image_url": "https://i.scdn.co/image/f116de45ce43d598b56cad927d6d630b2088d645",
    "artist_id": "5rScKX1Sh1U67meeUyTGwk",
    "year": 2002
  },
  {
    "song_id": "7MoKXLQeI0Bh2QodCjDO4K",
    "name": "Can't Get You Out Of My Head",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/80881ff1c47493adc1e5ebe39059ecf88007273d",
    "artist_name": "Kylie Minogue",
    "image_url": "https://i.scdn.co/image/f08745e3f2543a26874ba8909d8ece64d085c0c4",
    "artist_id": "4RVnAU35WRWra6OZ3CbbMA",
    "year": 2002
  },
  {
    "song_id": "1EKiQ1InQXuodp4M1idrng",
    "name": "The Good Stuff",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/8d313ac4fe46a2e6a6eb0cf1d54dd7c6d263be5f",
    "artist_name": "Kenny Chesney",
    "image_url": "https://i.scdn.co/image/a1206652d5f379cfb939a493347c4007874656ac",
    "artist_id": "3grHWM9bx2E9vwJCdlRv9O",
    "year": 2002
  },
  {
    "song_id": "7uz2Rk1sow6wLj3PCXfDeb",
    "name": "Just Like a Pill",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/aae2cce9f9251845518aa0a1c4b7461187d17b6e",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/97e041f47c76b22e2276f9553b829912128e19fd",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2002
  },
  {
    "song_id": "4qZ73jhiSZIfH9QfAZWIwo",
    "name": "Differences",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/a9f493ee1058799614e20bf607eeca977e119345",
    "artist_name": "Ginuwine",
    "image_url": "https://i.scdn.co/image/eab0aef398bde7cdb35f6a517e94114db25b5dd9",
    "artist_id": "7r8RF1tN2A4CiGEplkp1oP",
    "year": 2002
  },
  {
    "song_id": "7p1PhtGLjq0ISncRXBHqXY",
    "name": "Here Is Gone",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/2751d9c6d42224a9fe9b47da02a958b95de91909",
    "artist_name": "The Goo Goo Dolls",
    "image_url": "https://i.scdn.co/image/f66195f98d32ffb0f1fcca0ea9e69e2794ec6742",
    "artist_id": "2sil8z5kiy4r76CRTXxBCA",
    "year": 2002
  },
  {
    "song_id": "4pUqtEXcZiQxpP5FPciLmb",
    "name": "Turn Off The Light",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/17bf34ef6675f7439e32fcaf974d96e0cc6e2433",
    "artist_name": "Nelly Furtado",
    "image_url": "https://i.scdn.co/image/1df322a0ab9d4ca578e137cc2dcf399d861f56c3",
    "artist_id": "2jw70GZXlAI8QzWeY2bgRc",
    "year": 2002
  },
  {
    "song_id": "150QiRJd7uPtylrfFHGyCO",
    "name": "Hands Clean",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/87651d5f636118aeb593ef93a1497b4ba3659cca",
    "artist_name": "Alanis Morissette",
    "image_url": "https://i.scdn.co/image/2a996c920d96d5926050336b5f5296b2d3a41a7d",
    "artist_id": "6ogn9necmbUdCppmNnGOdi",
    "year": 2002
  },
  {
    "song_id": "6wFemJ1Oh4gnTalNKgtvBg",
    "name": "Underneath It All",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/957522159f7502ec2f0d6deef1010a86a5d8953d",
    "artist_name": "No Doubt",
    "image_url": "https://i.scdn.co/image/bb7c12b57024944f5a54572577f853b30c2453c7",
    "artist_id": "0cQbJU1aAzvbEmTuljWLlF",
    "year": 2002
  },
  {
    "song_id": "779ooI3rBd0CLqCiiJmtVo",
    "name": "Hero (feat. Josey Scott)",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/6a72f2250b5066239c3ac6fb2010549c51c20dfd",
    "artist_name": "Chad Kroeger",
    "image_url": "https://i.scdn.co/image/250e203897feb830a04866fa0119a9384c7b8fd6",
    "artist_id": "7fJYw1vK9yWb8o51I8qHin",
    "year": 2002
  },
  {
    "song_id": "1Q9b6CeMcDuO0uq5OJCrqu",
    "name": "Move Bitch",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/85a271e003f3cf54c948d01de2630e9630abfe5f",
    "artist_name": "Disturbing Tha Peace",
    "image_url": "https://i.scdn.co/image/3fbfc6798ad7cee686de74467e7ec45b7c55b1de",
    "artist_id": "3FsQX7FsrZAzTU9ah45P7O",
    "year": 2002
  },
  {
    "song_id": "1lkC6oC7SbwgnEBEC05r64",
    "name": "Get the Party Started",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/350d5030db60cf726e2e971af6707e171aed147b",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/97e041f47c76b22e2276f9553b829912128e19fd",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2002
  },
  {
    "song_id": "0aWVhnjyPm8xxBqNBCsHHw",
    "name": "Don't Let Me Get Me - Radio Edit",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/d477e6516af8f8331c7b8c1f6cd18f9120951c0f",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/97e041f47c76b22e2276f9553b829912128e19fd",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2002
  },
  {
    "song_id": "3vgKD50C8hWlQq8HX0Jf8k",
    "name": "Sk8er Boi",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/bf29925a02ef54d3b95295aa8408b637afa6ac5a",
    "artist_name": "Avril Lavigne",
    "image_url": "https://i.scdn.co/image/07348c536e67a88dd873112ffa483c6c87957401",
    "artist_id": "0p4nmQO2msCgU4IF37Wi3j",
    "year": 2002
  },
  {
    "song_id": "0ySdXfvOy7zCc59yz2Dx8q",
    "name": "Hey Baby",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/98e4d38ed8899d70bd4351683803a1e5e59f9844",
    "artist_name": "No Doubt",
    "image_url": "https://i.scdn.co/image/bb7c12b57024944f5a54572577f853b30c2453c7",
    "artist_id": "0cQbJU1aAzvbEmTuljWLlF",
    "year": 2002
  },
  {
    "song_id": "5Y0E3dOeynKuLDAXu0t0Bg",
    "name": "Oops (Oh My) - feat. Missy Elliott",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/1d66538703d0a806bfc4f7570b25b4734105fefe",
    "artist_name": "Tweet",
    "image_url": "https://i.scdn.co/image/d74f84a8bee999eb005da51aada6d240a07b5bf1",
    "artist_id": "6zDBeei6hHRiZdAJ6zoTCo",
    "year": 2002
  },
  {
    "song_id": "5dpAN1mjFPL38kh9kWsCiw",
    "name": "Wasting My Time",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/99cafe9739bfa80a96ac1d5614c45cbb68005241",
    "artist_name": "Default",
    "image_url": "https://i.scdn.co/image/1a3969ee4ee5a8c5ae2e6cd88b5b27eae4a20061",
    "artist_id": "4nCzT3o7lMgCWBcmdwCMsT",
    "year": 2002
  },
  {
    "song_id": "1NRorokmYEtr1koWybvaE4",
    "name": "A Woman's Worth",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/e3d73052ffc16ed6814908f7280865bf1f14e15e",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/188258f60cc0ac926d89bfeebaf6a7035b7a78dc",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2002
  },
  {
    "song_id": "64NrL8pIXLdpFyHQ92DX5l",
    "name": "Hella Good",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/235845fea3017b1b1b8165dac85a189763149fad",
    "artist_name": "No Doubt",
    "image_url": "https://i.scdn.co/image/1dd08d519a3fc4298605d532a964686052c057cc",
    "artist_id": "0cQbJU1aAzvbEmTuljWLlF",
    "year": 2002
  },
  {
    "song_id": "3eSwvMfSA0Fyku6Ak5JEw0",
    "name": "Where Were You (When the World Stopped Turning)",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/f5546a8223eaf21a799c9a3e9cc716dd086db279",
    "artist_name": "Alan Jackson",
    "image_url": "https://i.scdn.co/image/1e57733de7c217deda986db07a0bf1ac182b52f5",
    "artist_id": "4mxWe1mtYIYfP040G38yvS",
    "year": 2002
  },
  {
    "song_id": "6u5flhVFxKZrl9AApvf2SL",
    "name": "Girlfriend",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/2f1c57209ed30d5252eac267261db137800c6a8f",
    "artist_name": "N Sync",
    "image_url": "https://i.scdn.co/image/38a46dc8b8b9c84b4763c6de679bab597baa2d46",
    "artist_id": "6Ff53KvcvAj5U7Z1vojB5o",
    "year": 2002
  },
  {
    "song_id": "7F1BUnd7mjogQZHZ2FcME1",
    "name": "Soak Up The Sun",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/681a3da5a9974f810b8f81a4f25b8b2b4654ceca",
    "artist_name": "Sheryl Crow",
    "image_url": "https://i.scdn.co/image/eecbeed5b9c62aa77b7a06b718cb874ec53d383e",
    "artist_id": "4TKTii6gnOnUXQHyuo9JaD",
    "year": 2002
  },
  {
    "song_id": "1V1Zit6cgKzpewBwMjm3zB",
    "name": "Standing Still",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/9f7fbc53b0b9553bad4468dddda85180b00d5ea0",
    "artist_name": "Jewel",
    "image_url": "https://i.scdn.co/image/1a510d8bba962477afb209568854762d4e783231",
    "artist_id": "6FbDoZnMBTdhhhLuJBOOqP",
    "year": 2002
  },
  {
    "song_id": "0WgLpgUdThEpA5L5K0SKq7",
    "name": "Gangsta Lovin'",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/9d90b829e8d97000229fa98f85e948be1afa23b0",
    "artist_name": "Eve",
    "image_url": "https://i.scdn.co/image/374f06fcecb91342b1549360b2d96979f580874b",
    "artist_id": "4d3yvTptO48nOYTPBcPFZC",
    "year": 2002
  },
  {
    "song_id": "1kEruIJPLnM1agV8bnCmSZ",
    "name": "Livin' It Up",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/75d5e6b1501f5b9c6dda1d68e9fca52108b41ddd",
    "artist_name": "Ja Rule",
    "image_url": "https://i.scdn.co/image/a8fbcba14369f20286cb4450cee28874f38edc7f",
    "artist_id": "1J2VVASYAamtQ3Bt8wGgA6",
    "year": 2002
  },
  {
    "song_id": "6Far9mixuLOSu0Y5ectjfA",
    "name": "A Moment Like This",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/e9c0bf24a05dbf1c09ca6efc8f1d375d4b44bc3b",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/80e574b9104bb80c98607c82f732a28e961f134a",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2002
  },
  {
    "song_id": "6gsmFgUiSTuinJlScwFODv",
    "name": "I Love You",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/302e3ae15446c20a7db9352dba7fd2d0e4866b2c",
    "artist_name": "Faith Evans",
    "image_url": "https://i.scdn.co/image/4a9de0c9674cce9d48a31083072a4b205afd4210",
    "artist_id": "5NDMothbpdpq2xHqSjrrWn",
    "year": 2002
  },
  {
    "song_id": "3iJRy19QMmYIr3mtGY7Hml",
    "name": "Baby",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/05b93becb6aa4fc4ee30e42240f1a25aad3293d9",
    "artist_name": "Ashanti",
    "image_url": "https://i.scdn.co/image/0e70ff25d6c544d8ddd906be464058e504ba2022",
    "artist_id": "5rkVyNGXEgeUqKkB5ccK83",
    "year": 2002
  },
  {
    "song_id": "4bJ02dcoGEkcSJOhNbx0tp",
    "name": "Uh Huh",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/8634a364d2353bec55859d7a11a9c638626aa45a",
    "artist_name": "B2K",
    "image_url": "https://i.scdn.co/image/dc79dd6dafff3f693c4f2cfeeba77ed72d614662",
    "artist_id": "51d3rUlLuMh7EW09aMe7yj",
    "year": 2002
  },
  {
    "song_id": "6JjYbaUFBYW7bXfMYXQeUo",
    "name": "A New Day Has Come - Radio Remix",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/c241017a8cc44f89a0b8ac4d098f034c49f78ed0",
    "artist_name": "Céline Dion",
    "image_url": "https://i.scdn.co/image/3f4e57a824d3976730129dcfbb0c3504d657712e",
    "artist_id": "4S9EykWXhStSc15wEx8QFK",
    "year": 2002
  },
  {
    "song_id": "3U6kC4Ko4VT1ZvEKjEZsC1",
    "name": "Days Go By - Radio Edit",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/926c9318899bea3f4b8e3f0dafd9baf578ffc1ad",
    "artist_name": "Dirty Vegas",
    "image_url": "https://i.scdn.co/image/784bc1603420ca7604b1774d11e4b3448bbd7d92",
    "artist_id": "2IkHcHKErbWa0TA14yHkbl",
    "year": 2002
  },
  {
    "song_id": "7FnfygSSspSVpitq0ZctYQ",
    "name": "Gimme The Light - Edited Version",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/ee2a0f3118528ca206b4da2eacca7c5473787a2e",
    "artist_name": "Sean Paul",
    "image_url": "https://i.scdn.co/image/45edb1fa71a7af32adeb4f73f739464c0476cfb0",
    "artist_id": "3Isy6kedDrgPYoTS1dazA9",
    "year": 2002
  },
  {
    "song_id": "5dXmPzPDTMWmxmpzxAV1JZ",
    "name": "Caramel",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/da9200e69eccf3c0fbcec95ee8faa3256bfda956",
    "artist_name": "City High",
    "image_url": "https://i.scdn.co/image/32158ecb56e99ab5e188d23962594631985c4a63",
    "artist_id": "0LlPal5iJGcCWwZp8VefVs",
    "year": 2002
  },
  {
    "song_id": "2Nu0P68oruhup6YcAi8On3",
    "name": "Happy",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/4d692c1b77aacecbb081f7a09119d9242cafd263",
    "artist_name": "Ashanti",
    "image_url": "https://i.scdn.co/image/0e70ff25d6c544d8ddd906be464058e504ba2022",
    "artist_id": "5rkVyNGXEgeUqKkB5ccK83",
    "year": 2002
  },
  {
    "song_id": "1F1XjwRzMh8KT2BvH6ISkG",
    "name": "Rainy Dayz",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/e84b87b33004b2379e8ef165a491dc6f305fa4d1",
    "artist_name": "Mary J. Blige",
    "image_url": "https://i.scdn.co/image/6c9ffe5177379ad2b70a2c64551dc25fa4fac323",
    "artist_id": "1XkoF8ryArs86LZvFOkbyr",
    "year": 2002
  },
  {
    "song_id": "5xkaSuEOfZvHIEI6uwtS1T",
    "name": "Rollout (My Business)",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/4dd3c5dff9d54f7072823260957bd445230d7862",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/d035b71b6010528f784869af7a10dbf266ae33c3",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2002
  },
  {
    "song_id": "32qiMwmCESGPYtjfnIkIqC",
    "name": "Drive (For Daddy Gene)",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/83e8c4dde9bfacaed6cce8323342e0d4e9ba880e",
    "artist_name": "Alan Jackson",
    "image_url": "https://i.scdn.co/image/1fed9df96f5ef2f0add6f9cf4f663e55145c5b2b",
    "artist_id": "4mxWe1mtYIYfP040G38yvS",
    "year": 2002
  },
  {
    "song_id": "5ZKVNTG7yu7gbX4vryqS2X",
    "name": "Nothin'",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/44f75f37f9bfa5e0a8216702174acbad347677e6",
    "artist_name": "N.O.R.E.",
    "image_url": "https://i.scdn.co/image/020d4ecaea57365b4454b2194293db217f67cb64",
    "artist_id": "4GMgdB3vwbBOc42hbXEi9p",
    "year": 2002
  },
  {
    "song_id": "6QxuZMdsElzjC1NnXUfl8c",
    "name": "Full Moon",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/5a988f285815bfe911965c93f2074ba6de48a261",
    "artist_name": "Brandy",
    "image_url": "https://i.scdn.co/image/9cf1fdccd9e45c046627eaf83467352d4335d2f9",
    "artist_id": "05oH07COxkXKIMt6mIPRee",
    "year": 2002
  },
  {
    "song_id": "1C0wnugsGFwt2RRkitpUmK",
    "name": "Gone",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/358645c877a83a178d8a5cd6ad681cdecb00e1cb",
    "artist_name": "N Sync",
    "image_url": "https://i.scdn.co/image/d13ee12f839d3fa54bc07e041c821989d30a6ba9",
    "artist_id": "6Ff53KvcvAj5U7Z1vojB5o",
    "year": 2002
  },
  {
    "song_id": "0MVMYjIk1N3nO8omHZRaOd",
    "name": "Lights, Camera, Action!",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/c0740d9a5777ca2f823038549eead2978266dae3",
    "artist_name": "Mr. Cheeks",
    "image_url": "https://i.scdn.co/image/893cac852eb3a3cd9a02ab90951e555668303d21",
    "artist_id": "0UeVxCKyBypjQvDCT889zt",
    "year": 2002
  },
  {
    "song_id": "6dN429DyfNQkDQJwBUFWrJ",
    "name": "7 Days",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/8c172dbe32a402126c0626d671dd8fe02499cd33",
    "artist_name": "Craig David",
    "image_url": "https://i.scdn.co/image/bea8aa5a06764437610173059d0cbc5e87f09eed",
    "artist_id": "2JyWXPbkqI5ZJa3gwqVa0c",
    "year": 2002
  },
  {
    "song_id": "5L10F9RikmCvAVOsNmFslE",
    "name": "What About Us?",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/72947086b5c160ac2f363bd21dfeb4af6579eee9",
    "artist_name": "Brandy",
    "image_url": "https://i.scdn.co/image/9cf1fdccd9e45c046627eaf83467352d4335d2f9",
    "artist_id": "05oH07COxkXKIMt6mIPRee",
    "year": 2002
  },
  {
    "song_id": "3NT4deyIUzzqL63rNHgYNM",
    "name": "Foolish - Mainstream Edit",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/54c14a8b5b9fa0fdc8721b8385d429e255c85cb1",
    "artist_name": "Ashanti",
    "image_url": "https://i.scdn.co/image/d103ecdbfdb46baca5c9ae825c1cc2d50af6436f",
    "artist_id": "5rkVyNGXEgeUqKkB5ccK83",
    "year": 2002
  },
  {
    "song_id": "3R9qiUjvIuLYm1KWzh6E0L",
    "name": "Still Fly",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/8f62c05393389316660788d88c1114a6599a1f2e",
    "artist_name": "Big Tymers",
    "image_url": "https://i.scdn.co/image/29853fb0882935ba8dbda610741de0ef208d8167",
    "artist_id": "4jWGfUCFeTu5e0wprRhHXR",
    "year": 2002
  },
  {
    "song_id": "610sx23crS0FOQISsn2e49",
    "name": "I Do!!",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/482d64c3966cdc9619b6516b90836f6b18aa9c7a",
    "artist_name": "Toya",
    "image_url": "https://i.scdn.co/image/6dc8cb691927438027fea7d932e7613f2619ea21",
    "artist_id": "6cStgiv1qH5z6GYrFsjuGj",
    "year": 2002
  },
  {
    "song_id": "2EcW94ak7kYH4mDMvI7iWg",
    "name": "We Thuggin' - feat. R. Kelly Explicit",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/87259b44bc891abb900d020272a2974d0bdd0323",
    "artist_name": "Fat Joe",
    "image_url": "https://i.scdn.co/image/ed4d938eec4ce2c0d1e1e94d919e244e0900abda",
    "artist_id": "3ScY9CQxNLQei8Umvpx5g6",
    "year": 2002
  },
  {
    "song_id": "3ttEQBpVcb0Im3RovYPl3K",
    "name": "No Such Thing - Demo Version",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/e011c875b942d2ca5983c514b4b97f706bee0fb2",
    "artist_name": "John Mayer",
    "image_url": "https://i.scdn.co/image/fbd8cdadcb1caa34e8c7baae1901f6de7613aba3",
    "artist_id": "0hEurMDQu99nJRq8pTxO14",
    "year": 2002
  },
  {
    "song_id": "4Wy0raF0R98yNz18GR9ho0",
    "name": "Luv U Better",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/ad7ccf74e0f667b82abeedcf60ded9744799ffae",
    "artist_name": "LL Cool J",
    "image_url": "https://i.scdn.co/image/dbc84331ef214b6855ecbd0f2e4539fc76cecaac",
    "artist_id": "1P8IfcNKwrkQP5xJWuhaOC",
    "year": 2002
  },
  {
    "song_id": "5ADSOampCIetIqVvg4sawk",
    "name": "Butterflies",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/f7b254527c0a6970f7d499c29b877e6325ba9d2d",
    "artist_name": "Michael Jackson",
    "image_url": "https://i.scdn.co/image/c2b98e647a922372397b5dfd434f34546fb21446",
    "artist_id": "3fMbdgg4jU18AjLCKBhRSm",
    "year": 2002
  },
  {
    "song_id": "6mMbySRoIhJ5rsYSWSO2AS",
    "name": "My List",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/d1eb696c767b6ee7b4c945fe05fc82144fc4d804",
    "artist_name": "Toby Keith",
    "image_url": "https://i.scdn.co/image/5903c43e8c30afe7f7a77d74ea4291ada5194fd6",
    "artist_id": "2bA6fzP0lMAQ4kz6CF61w8",
    "year": 2002
  },
  {
    "song_id": "45E1IBsdnZ4LqIDRORyjti",
    "name": "Blurry",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/501daa60786a60a795cafc7ed0d44918c3c62837",
    "artist_name": "Puddle Of Mudd",
    "image_url": "https://i.scdn.co/image/f2639feea9ccca15881d237d349a9ad94bb6cb8e",
    "artist_id": "3dXaa6jwM7B52GZpaJEIr5",
    "year": 2002
  },
  {
    "song_id": "0IvsLOZL3rhP57RXcXHzcD",
    "name": "If I Could Go!",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/c95fc8e054c4a951a6d347a612cf8b824099c988",
    "artist_name": "Angie Martinez",
    "image_url": "https://i.scdn.co/image/95dfd96b4b52c0ded9409a8ad9f853c48a7acab7",
    "artist_id": "6VJqy1YyzrK4ZhgGs9cNTy",
    "year": 2002
  },
  {
    "song_id": "0oWmL6PMYbPDa10yWQ6zbA",
    "name": "Always On Time",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/076e7ed96f99c9e8414e59d4900bf01e1b107a03",
    "artist_name": "Ja Rule",
    "image_url": "https://i.scdn.co/image/dfb6a418bcd80a81b5b0adef66cf7998e12c9784",
    "artist_id": "1J2VVASYAamtQ3Bt8wGgA6",
    "year": 2002
  },
  {
    "song_id": "1xhgmhUBlwn4PbRzc3uyDO",
    "name": "Just A Friend",
    "popularity": 26,
    "preview_url": "https://p.scdn.co/mp3-preview/c886242498f6aae57376140078bccad1c28714b9",
    "artist_name": "Mario Vazquez",
    "image_url": "https://i.scdn.co/image/ea8d5865b27b6484e52b487ea9137b492f50198d",
    "artist_id": "7iMjWsjqbmYLobomSh4B64",
    "year": 2002
  },
  {
    "song_id": "2221t83il80TwtMqcBEddH",
    "name": "Living And Living Well",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/5bf7ce920973d995fe3fc4d16e8c913b9da326b1",
    "artist_name": "George Strait",
    "image_url": "https://i.scdn.co/image/59bb6fc54289e6673e7c6d772499e74b6743e37c",
    "artist_id": "5vngPClqofybhPERIqQMYd",
    "year": 2002
  },
  {
    "song_id": "6BnLVX9hRn1ToxyQ2RwGXv",
    "name": "Halfcrazy",
    "popularity": 14,
    "preview_url": "https://p.scdn.co/mp3-preview/1281678b6966a6566ba02327126f73c3f4873778",
    "artist_name": "Smooth Jazz All-Stars",
    "image_url": "https://i.scdn.co/image/6e82ee5fa5ee01c6cb1f652295270ed143019aaa",
    "artist_id": "2ThUOWiiWP3YdZqs4WYNOi",
    "year": 2002
  },
  {
    "song_id": "36jSIOSE72neBbKntCthqw",
    "name": "Addictive",
    "popularity": 6,
    "preview_url": "https://p.scdn.co/mp3-preview/f84394929d71b026150a997791946de2d4a6bcf9",
    "artist_name": "Truth Hurts",
    "image_url": "https://i.scdn.co/image/dc166e084a4793470ea11c65ca23af74ee7dd3e7",
    "artist_id": "0HCeK90YUyjWi0utTPYXw1",
    "year": 2002
  },
  {
    "song_id": "0BCPKOYdS2jbQ8iyB56Zns",
    "name": "Clocks",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/8811ca045a82554932c188ea84504ffc9363b502",
    "artist_name": "Coldplay",
    "image_url": "https://i.scdn.co/image/1bdf88a14e02969b30edb37140441dd19cb44216",
    "artist_id": "4gzpq5DPGxSnKTe4SA8HAU",
    "year": 2003
  },
  {
    "song_id": "5IVuqXILoxVWvWEPm82Jxr",
    "name": "Crazy in Love",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/b9f7b7cd553c4aee3cd5146cfb068539047f5219",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/dc6f04be7adf3ed5355ef230ea4fa06ec80ccc8b",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2003
  },
  {
    "song_id": "0nmxH6IsSQVT1YEsCB9UMi",
    "name": "Ignition (Remix)",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/4c50bf000404d52d50eb56d39c559dc952629973",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/37145297a059445de8ce1cdd846132d8fb3f7803",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 2003
  },
  {
    "song_id": "7Lf7oSEVdzZqTA0kEDSlS5",
    "name": "Cry Me a River",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/45c15ade06ab827e282e86c53b24bd223320cc1e",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/b4096aab2a49b2f2fe2d0f1b483ac51183dba760",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2003
  },
  {
    "song_id": "6ybViy2qrO9sIi41EgRJgx",
    "name": "Don't Know Why",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/830a58aba8f7c7fa9bdf176b3c12dc8b0b5228f3",
    "artist_name": "Norah Jones",
    "image_url": "https://i.scdn.co/image/dbbec299844faf0b4d905a84cda75d7afd20c891",
    "artist_id": "2Kx7MNY7cI1ENniW7vT30N",
    "year": 2003
  },
  {
    "song_id": "0r2Bul2NuCViraT2zX1l5j",
    "name": "Get Low",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/7b04a0047ec4807f03d9e6efef09421190d300e6",
    "artist_name": "Lil Jon",
    "image_url": "https://i.scdn.co/image/c17fd70ad1934257391375b3d9cc740040f687b5",
    "artist_id": "7sfl4Xt5KmfyDs2T3SVSMK",
    "year": 2003
  },
  {
    "song_id": "7iL6o9tox1zgHpKUfh9vuC",
    "name": "In Da Club",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/f3d799cd8f70e28fc31d5fe7ac69b804d1c83f4a",
    "artist_name": "50 Cent",
    "image_url": "https://i.scdn.co/image/24c959c15bf37e527679f43c6c31cf3f522da759",
    "artist_id": "3q7HBObVc0L8jNeTe5Gofh",
    "year": 2003
  },
  {
    "song_id": "0j5X6DrzkgY64Mm8NQp5mi",
    "name": "Lose Yourself - Soundtrack Version",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/5a9da4605959338f2363079af5895e74fba8a479",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/af7a8977e61479d27177ef37b5c0101af5b54a36",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2003
  },
  {
    "song_id": "1AWQoqb9bSvzTjaLralEkT",
    "name": "Rock Your Body",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/cadda8bd71a9a80403e528f8a88acbd909419a7f",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/b4096aab2a49b2f2fe2d0f1b483ac51183dba760",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2003
  },
  {
    "song_id": "1AEYT6VxrxXPMoQUxsY0E4",
    "name": "Headstrong",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/f7c0fe907d8133cbc937f3e0b6ed7244a68d0a41",
    "artist_name": "Trapt",
    "image_url": "https://i.scdn.co/image/f6995d1b907d1f19dc409d2f11e366ffe410b77b",
    "artist_id": "1UdQqCUR7RwB9YYJONwbdM",
    "year": 2003
  },
  {
    "song_id": "4675yUu8AUbE72T94BkLCD",
    "name": "Beautiful - Feat. Pharrell, Uncle Charlie Wilson",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/189651a76381e993cbc70afe98750c520fdca0b1",
    "artist_name": "Snoop Dogg",
    "image_url": "https://i.scdn.co/image/57498df56b1b008c99d17648cff5faa313c87d6d",
    "artist_id": "7hJcb9fa4alzcOq3EaNPoG",
    "year": 2003
  },
  {
    "song_id": "3jagJCUbdqhDSPuxP8cAqF",
    "name": "Work It - Promo",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/8d51886641129eee613a8f0ee94d361fdf1403a5",
    "artist_name": "Missy Elliott",
    "image_url": "https://i.scdn.co/image/9093be4c2737d8e27a3ceb89a3b2f10e04c8e435",
    "artist_id": "2wIVse2owClT7go1WT98tk",
    "year": 2003
  },
  {
    "song_id": "3YuaBvuZqcwN3CEAyyoaei",
    "name": "Like a Stone",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/35d3b206063a98052626c79c48a0c1fa600a1922",
    "artist_name": "Audioslave",
    "image_url": "https://i.scdn.co/image/c922bf9489534598274415089c8f2664a624e139",
    "artist_id": "2ziB7fzrXBoh1HUPS6sVFn",
    "year": 2003
  },
  {
    "song_id": "5voKHxbIqgBU7fZMX8Lu0T",
    "name": "Superman",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/73aa0a4569c40c403e81fb9b711faec3ea5b4f66",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/1f881110ff752c80069da0308b1de30196d4f8a1",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2003
  },
  {
    "song_id": "0r8iDf65NHgFgZOGLwj5r8",
    "name": "What Was I Thinkin'",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/6ce3e63d3596978acec95ec75b4a21a103d01e12",
    "artist_name": "Dierks Bentley",
    "image_url": "https://i.scdn.co/image/67f3650f1160c9d5e1fa0eaba787d54efc23f8f0",
    "artist_id": "7x8nK0m0cP2ksQf0mjWdPS",
    "year": 2003
  },
  {
    "song_id": "3C7RbG9Co0zjO7CsuEOqRa",
    "name": "Sing For The Moment",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/0c01d2143696aea727c2f96665fd8af7da8791c8",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/a89a0716ece38c0a06286dd2bbd4f575306c3a1c",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2003
  },
  {
    "song_id": "14B2bUopOga5V3ypld7d6n",
    "name": "Suga Suga",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/8365dfcb8d886c3fe74ab49c879af06f51ecf73d",
    "artist_name": "Baby Bash",
    "image_url": "https://i.scdn.co/image/91d4087c09c9ae2c15038f6049003d498ed03330",
    "artist_id": "12PSlydMSjEHzSCj9X5qv7",
    "year": 2003
  },
  {
    "song_id": "7C86DVl6REN8VPeGEMOf9q",
    "name": "Get Busy",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/015a0847a9a6d3071308df736b339062189b1862",
    "artist_name": "Sean Paul",
    "image_url": "https://i.scdn.co/image/45edb1fa71a7af32adeb4f73f739464c0476cfb0",
    "artist_id": "3Isy6kedDrgPYoTS1dazA9",
    "year": 2003
  },
  {
    "song_id": "1IRNjfivqiDhzey02C11ge",
    "name": "P.I.M.P.",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/f603ca3b87ed7fa37c51fead84ab9b3520ccc2de",
    "artist_name": "50 Cent",
    "image_url": "https://i.scdn.co/image/767c8068bf3ddbcb80abb2ab78dc42c35abeab48",
    "artist_id": "3q7HBObVc0L8jNeTe5Gofh",
    "year": 2003
  },
  {
    "song_id": "5ojJNnX5ND2gMGojPd3NiO",
    "name": "In Those Jeans",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/b31391090aa3d11c416ad7fa95236abcb9d1fae2",
    "artist_name": "Ginuwine",
    "image_url": "https://i.scdn.co/image/9784214444840d3bb0e5ca59db31b081b041992b",
    "artist_id": "7r8RF1tN2A4CiGEplkp1oP",
    "year": 2003
  },
  {
    "song_id": "7qQfI2F80t2MZcLLVuH6M2",
    "name": "Here Without You",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/ef536636dc6618d7054e31f0821bbf4150db2b9a",
    "artist_name": "3 Doors Down",
    "image_url": "https://i.scdn.co/image/f7f4ac87474fd7027379ad5fdb8211ac1ddd100a",
    "artist_id": "2RTUTCvo6onsAnheUk3aL9",
    "year": 2003
  },
  {
    "song_id": "4b5aJdOgxps6z0dPe6wCHF",
    "name": "Into You (feat. Ashanti) - feat. Ashanti",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/fa647e61868266bf80c961a92de5b1d9a030694f",
    "artist_name": "Fabolous",
    "image_url": "https://i.scdn.co/image/933b9df8356e35be4b1a9f0d5b3af883b5e8bb50",
    "artist_id": "0YWxKQj2Go9CGHCp77UOyy",
    "year": 2003
  },
  {
    "song_id": "0l3GEbH3CviUytD6iX4wzg",
    "name": "Right Thurr",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/a184cf34c4834b2ed58ff0e804958c413aa9c61f",
    "artist_name": "Chingy",
    "image_url": "https://i.scdn.co/image/e3afa7cad5f345b645a127d31b73cb4a0a8b9b79",
    "artist_id": "3s2wTjWxK8NOX09dmsvVOh",
    "year": 2003
  },
  {
    "song_id": "5QHElgktygMkgRdpPhXfn2",
    "name": "Red Dirt Road",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/b06c6b348372a101dc009311c2bbb7a6787b0df4",
    "artist_name": "Brooks & Dunn",
    "image_url": "https://i.scdn.co/image/aec75b35408f3d3371d183f94adf872c39d04ba3",
    "artist_id": "0XKOBt59crntr7HQXXO8Yz",
    "year": 2003
  },
  {
    "song_id": "0PXWpNBYg52gABrn1qL1bD",
    "name": "Unwell - Remastered Version",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/52b3d4c840d0e6eb267f6649dfcfbba040dbf1df",
    "artist_name": "Matchbox Twenty",
    "image_url": "https://i.scdn.co/image/c32c828af2b4f107b333814cc0e9dc729f8f72da",
    "artist_id": "3Ngh2zDBRPEriyxQDAMKd1",
    "year": 2003
  },
  {
    "song_id": "440JHgcmlgu65MbKBg20ba",
    "name": "I Know What You Want",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/015b8fa522bef939ab707c4d9facadad9f622f7e",
    "artist_name": "Busta Rhymes",
    "image_url": "https://i.scdn.co/image/702adb872d32581a3d4821b358c3695a5c7a6312",
    "artist_id": "1YfEcTuGvBQ8xSD1f53UnK",
    "year": 2003
  },
  {
    "song_id": "3OUApyz3jDB3syGUNGqB1d",
    "name": "How You Gonna Act Like That",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/7eb91e3d177f743ad420d8c7af539e86d3fa1e86",
    "artist_name": "Tyrese",
    "image_url": "https://i.scdn.co/image/061f8b9c702610651cba97a7be315b2bf97df4b6",
    "artist_id": "08p7B5OtcUuVblvkQIlBhJ",
    "year": 2003
  },
  {
    "song_id": "0RFgvrhkf9FiDRLA0BhzpZ",
    "name": "Drift Away",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/d896d0bd9faa1fb02d5d403917df17a48df97e2e",
    "artist_name": "Uncle Kracker",
    "image_url": "https://i.scdn.co/image/4f29631a07d391e4d259632aa0033c473a3a150e",
    "artist_id": "2DnqqkzzDKm3vAoyHtn8So",
    "year": 2003
  },
  {
    "song_id": "0FmCLEHfINGqwlyzvklNqy",
    "name": "Holidae In",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/cd66339918f7cf5f0f2937442c71044679d1a610",
    "artist_name": "Chingy",
    "image_url": "https://i.scdn.co/image/e3afa7cad5f345b645a127d31b73cb4a0a8b9b79",
    "artist_id": "3s2wTjWxK8NOX09dmsvVOh",
    "year": 2003
  },
  {
    "song_id": "29LHe8kG3PraghUZOZYsw4",
    "name": "Baby Boy [feat. Beyonce]",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/e30d6a39d7374ba4a863c41e9dd762c42eb02b2e",
    "artist_name": "Sean Paul",
    "image_url": "https://i.scdn.co/image/45edb1fa71a7af32adeb4f73f739464c0476cfb0",
    "artist_id": "3Isy6kedDrgPYoTS1dazA9",
    "year": 2003
  },
  {
    "song_id": "0Ri6sPso4CNTnHn7RroCt4",
    "name": "Beautiful",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/df9b3da3f03b9490e822e650909fff585b4b1650",
    "artist_name": "Christina Aguilera",
    "image_url": "https://i.scdn.co/image/281df57df2ee110cd59bf03e354093a630a3ceeb",
    "artist_id": "1l7ZsJRRS8wlW3WfJfPfNS",
    "year": 2003
  },
  {
    "song_id": "5tKbrFMvQJlG1reK5aDmkc",
    "name": "Bump, Bump, Bump (B2K and P. Diddy)",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/d6cb6ba16672f8de11af8fd98e48518b7796cc54",
    "artist_name": "B2K",
    "image_url": "https://i.scdn.co/image/dc79dd6dafff3f693c4f2cfeeba77ed72d614662",
    "artist_id": "51d3rUlLuMh7EW09aMe7yj",
    "year": 2003
  },
  {
    "song_id": "0E29BKKGCJHcb7b9oIWgGB",
    "name": "Calling All Angels",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/0e7d1dab6137e7c01799e1ad73e66c1479b3f7e7",
    "artist_name": "Train",
    "image_url": "https://i.scdn.co/image/1cedcb14c055befe08e743f21b70708eb6c8f1ba",
    "artist_id": "3FUY2gzHeIiaesXtOAdB7A",
    "year": 2003
  },
  {
    "song_id": "6lrRIfQDcikJ5y5DtyZPuJ",
    "name": "Can't Let You Go (feat. Mike Shorey & Lil' Mo)",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/2cec5399ba333f8831e45bfa8d4ddd05abd46b32",
    "artist_name": "Fabolous",
    "image_url": "https://i.scdn.co/image/933b9df8356e35be4b1a9f0d5b3af883b5e8bb50",
    "artist_id": "0YWxKQj2Go9CGHCp77UOyy",
    "year": 2003
  },
  {
    "song_id": "1Nuqm2gavvcmZtEFWvbDbK",
    "name": "When I'm Gone",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/b3538bdb414f9c208634f22d21a52b05b7aec0a1",
    "artist_name": "3 Doors Down",
    "image_url": "https://i.scdn.co/image/0cd5e46acddacf4cebe1ea7391aad9e4697212b6",
    "artist_id": "2RTUTCvo6onsAnheUk3aL9",
    "year": 2003
  },
  {
    "song_id": "2CtCwQhY0ZLvr8L2l8Bo6e",
    "name": "Stand Up",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/9d6e335aad290685fdf83cb639c30005e0a9a24d",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/a99a9303799a1b883a28271c6ab0a5ebb0d7c23a",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2003
  },
  {
    "song_id": "7kAFSrTIOiVZ0gnHBuAH3l",
    "name": "Air Force Ones",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/94b4c7563fa453299d5e6543dd425600a6470eb3",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/71c0881e2fceca76333e7c53fdee09d10a2ed287",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2003
  },
  {
    "song_id": "75DjPjiIp2fvJDjtt41Jfs",
    "name": "Gossip Folks (feat. Ludacris)",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/875635770b5dafcdfab61b6ccb6cf4b628462eb0",
    "artist_name": "Missy Elliott",
    "image_url": "https://i.scdn.co/image/9093be4c2737d8e27a3ceb89a3b2f10e04c8e435",
    "artist_id": "2wIVse2owClT7go1WT98tk",
    "year": 2003
  },
  {
    "song_id": "6wFemJ1Oh4gnTalNKgtvBg",
    "name": "Underneath It All",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/957522159f7502ec2f0d6deef1010a86a5d8953d",
    "artist_name": "No Doubt",
    "image_url": "https://i.scdn.co/image/bb7c12b57024944f5a54572577f853b30c2453c7",
    "artist_id": "0cQbJU1aAzvbEmTuljWLlF",
    "year": 2003
  },
  {
    "song_id": "4DH9KwQ31wVFUsNFM47bEb",
    "name": "Where Is The Love?",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/16673a5ade603185da048cdc3d66fe089c9ac584",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/31153792174579640822f56ba965007fbc02283f",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2003
  },
  {
    "song_id": "6BIcjFZIVDZxNAcofRFPx4",
    "name": "So Gone",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/c2ff1358ba844736d6518da764322dc8d755d22b",
    "artist_name": "Monica",
    "image_url": "https://i.scdn.co/image/bb4f66f7de70be07d91f76b8d14312c9e2ad34fe",
    "artist_id": "6nzxy2wXs6tLgzEtqOkEi2",
    "year": 2003
  },
  {
    "song_id": "0cv1Mnb47oMvjVKMc2cyZw",
    "name": "Can't Hold Us Down (featuring Lil' Kim)",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/837b01722eae95d91ac572f143ef3ea3a20ae7f3",
    "artist_name": "Christina Aguilera",
    "image_url": "https://i.scdn.co/image/aefebb394801edf2edcda884b1467f5aa48d1db5",
    "artist_id": "1l7ZsJRRS8wlW3WfJfPfNS",
    "year": 2003
  },
  {
    "song_id": "2thQ8Zi2V8z6XJuCvxHm7V",
    "name": "Like Glue",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/73e9817023551de82ddd5eb2beb1b13c3f30d857",
    "artist_name": "Sean Paul",
    "image_url": "https://i.scdn.co/image/45edb1fa71a7af32adeb4f73f739464c0476cfb0",
    "artist_id": "3Isy6kedDrgPYoTS1dazA9",
    "year": 2003
  },
  {
    "song_id": "4LZqNiZHiQvGLlt0RkXtqt",
    "name": "No Shoes, No Shirt, No Problems",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/a6d114668e9ac15c8ce04ee93a9ca4fc540e28fd",
    "artist_name": "Kenny Chesney",
    "image_url": "https://i.scdn.co/image/a1206652d5f379cfb939a493347c4007874656ac",
    "artist_id": "3grHWM9bx2E9vwJCdlRv9O",
    "year": 2003
  },
  {
    "song_id": "1ePm7iM48W0ayPCQLAjoUN",
    "name": "Thoia Thoing",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/f8b9b0850546d1d0fb65c9d07be80e01931cd150",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/37145297a059445de8ce1cdd846132d8fb3f7803",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 2003
  },
  {
    "song_id": "2jHujamO9UuZv8im9eLdOB",
    "name": "I'm with You",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/be1074057a4c08706c8d44361f95e522a50bb020",
    "artist_name": "Avril Lavigne",
    "image_url": "https://i.scdn.co/image/07348c536e67a88dd873112ffa483c6c87957401",
    "artist_id": "0p4nmQO2msCgU4IF37Wi3j",
    "year": 2003
  },
  {
    "song_id": "5B7XIcS5T76NJZFOHX30Io",
    "name": "Are You Happy Now?",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/5712e23d99229083018e9e42191d163c1b4a9d4d",
    "artist_name": "Michelle Branch",
    "image_url": "https://i.scdn.co/image/cdc3db6c341a92953c3a14b346a3a5cab93e6909",
    "artist_id": "5rScKX1Sh1U67meeUyTGwk",
    "year": 2003
  },
  {
    "song_id": "2eXlwgFPe6HgFaPJO8xQHK",
    "name": "Wanksta - Soundtrack Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/7f509fc264e569a460e1dc421e9a017a5b34ca7d",
    "artist_name": "50 Cent",
    "image_url": "https://i.scdn.co/image/24c959c15bf37e527679f43c6c31cf3f522da759",
    "artist_id": "3q7HBObVc0L8jNeTe5Gofh",
    "year": 2003
  },
  {
    "song_id": "2T4rXDppGlcy2lLuF4IyHq",
    "name": "Angel",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/3bb199b32586cb6f62637ee83bb877f1f8089fcc",
    "artist_name": "Amanda Perez",
    "image_url": "https://i.scdn.co/image/80bbda339089681e1849e20e6a077ca27303b0e0",
    "artist_id": "5DqmNLPM1kAbSBQk2FMm6b",
    "year": 2003
  },
  {
    "song_id": "2iclfHORMqpR3TxuhwVRhD",
    "name": "Mesmerize",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/435ad86189834b48f37e4ea7771d872b0ce3da9f",
    "artist_name": "Ja Rule",
    "image_url": "https://i.scdn.co/image/eafa53055b41cdbbfb0e5743e4e3067d3310cf8d",
    "artist_id": "1J2VVASYAamtQ3Bt8wGgA6",
    "year": 2003
  },
  {
    "song_id": "3kgutGd839IOYRl6ekeq6V",
    "name": "All I Have",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/44ec32e64001152163d8648d4ef9e3448a7d8220",
    "artist_name": "Jennifer Lopez",
    "image_url": "https://i.scdn.co/image/29037bd3681d35b7b92faa6bd70c176b0d0233b2",
    "artist_id": "2DlGxzQSjYe5N6G9nkYghR",
    "year": 2003
  },
  {
    "song_id": "3wmQ8VhzARrFQj7GcnhtBM",
    "name": "Your Body Is a Wonderland",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/5457eda4c2e98cd93720693a3301e1b87726d857",
    "artist_name": "John Mayer",
    "image_url": "https://i.scdn.co/image/700c52b772dc484f572b5ee382af37148ca5f178",
    "artist_id": "0hEurMDQu99nJRq8pTxO14",
    "year": 2003
  },
  {
    "song_id": "02y3FRLRlmzIpi2y8ZbN2w",
    "name": "Don't Wanna Try",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/8a9fa800a40f95702cf36e5fbb991b06c7f3d848",
    "artist_name": "Frankie J",
    "image_url": "https://i.scdn.co/image/3a5bd5c8cd3eda953ecb151b453e89315844423c",
    "artist_id": "3sMYEBy0CZFxedcnm9i9hf",
    "year": 2003
  },
  {
    "song_id": "22HX8moAHWULPAu6t5CivV",
    "name": "Step In The Name Of Love - Remix",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/a6223b76a8ae49ed811767c5ee873e2bdbe35d7a",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/da271670355c7ca8962bc3d95875534511774285",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 2003
  },
  {
    "song_id": "08cqYeYWTjDUqNLVOiJMUF",
    "name": "The Jump Off",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/d813224332f91f7f525be0966e5c323b79fbc366",
    "artist_name": "Lil' Kim",
    "image_url": "https://i.scdn.co/image/57e6076acb2ba79c4cdea7be0d1f7e85452110c5",
    "artist_id": "5tth2a3v0sWwV1C7bApBdX",
    "year": 2003
  },
  {
    "song_id": "2Xc8yEoFXNdjE9tqb6myCI",
    "name": "If You're Not The One",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/e8e3aa5a9cc5c407269ec36f29064974dc0d91aa",
    "artist_name": "Daniel Bedingfield",
    "image_url": "https://i.scdn.co/image/d103ecdbfdb46baca5c9ae825c1cc2d50af6436f",
    "artist_id": "11hIqBsGRPztdjBHCSLClX",
    "year": 2003
  },
  {
    "song_id": "0qbNSBQ0oKt55pUsO6Mw7A",
    "name": "Fighter",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/ab1c63f6f67b80f8bab66e6f0b6f198a42227110",
    "artist_name": "Christina Aguilera",
    "image_url": "https://i.scdn.co/image/281df57df2ee110cd59bf03e354093a630a3ceeb",
    "artist_id": "1l7ZsJRRS8wlW3WfJfPfNS",
    "year": 2003
  },
  {
    "song_id": "3v4yDypfo94dachEvmjQdj",
    "name": "Miss Independent",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/ee4e958c68b8dd8e5e5d2edf95e800b9b8408d3f",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/80e574b9104bb80c98607c82f732a28e961f134a",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2003
  },
  {
    "song_id": "2TBR4T0kFjlE8YtwZzBVvw",
    "name": "19 Somethin’",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/328a7f28f9bcf01c0ffd480799f196b01ca7106c",
    "artist_name": "Mark Wills",
    "image_url": "https://i.scdn.co/image/3c39e50fd277b4560f87b1a73b121c51ec5fbce5",
    "artist_id": "0dlwjJe4kg9TgOMN184bJF",
    "year": 2003
  },
  {
    "song_id": "3Noa5kppo2crIEMpwOmsHf",
    "name": "Say Yes",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/877986f7709975f85a7adae821f31afc403202cd",
    "artist_name": "Floetry",
    "image_url": "https://i.scdn.co/image/d4422305db215ebd5c9e5643a47c7c7e55ed0172",
    "artist_id": "0un6YenPxWZ2VW4aFGMupM",
    "year": 2003
  },
  {
    "song_id": "3Hv5C8sbNd8i6LhfrIY3Yo",
    "name": "My Love Is Like...Wo - Main Mix/Album Version",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/99654a324f1b0f5a061e8bcab5500562c330f177",
    "artist_name": "Mya",
    "image_url": "https://i.scdn.co/image/575e252d5f3a006ccad7277ce9fca49625c91e1e",
    "artist_id": "6lHL3ubAMgSasKjNqKb8HF",
    "year": 2003
  },
  {
    "song_id": "0mI5eE0IuermT57lb0Szas",
    "name": "Rock Wit U (Awww Baby)",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/0c04a47b394bf46eabd8bf3b9e16756f3cabc44d",
    "artist_name": "Ashanti",
    "image_url": "https://i.scdn.co/image/365b8a1877eca179a9631c0e2722e5e079e6dce4",
    "artist_id": "5rkVyNGXEgeUqKkB5ccK83",
    "year": 2003
  },
  {
    "song_id": "6nRoGs2NWVeYO1pGSxoDLY",
    "name": "The Remedy - Live On Earth Version",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/3e60781932752335c202f42f49a7b0983f18cdde",
    "artist_name": "Jason Mraz",
    "image_url": "https://i.scdn.co/image/4b02c57fb54a27f20df7d46485bf94043c18c237",
    "artist_id": "4phGZZrJZRo4ElhRtViYdl",
    "year": 2003
  },
  {
    "song_id": "5igi8eXou8md6Hpdo4TQm0",
    "name": "Excuse Me Miss",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/7635b15c59a0ed85989897f6644f9027a4e7b0ab",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/29133ed2c4bbc89d8fc9d488e8a2e6d9b9470ff8",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2003
  },
  {
    "song_id": "5qJPRg0Ph69qE6E64zAMmG",
    "name": "Thugz Mansion - 2Pac Original/Acoustic (Explicit)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/52c3ad4ee77fbfc2f1216e9120bd14326d205dd5",
    "artist_name": "2Pac",
    "image_url": "https://i.scdn.co/image/8c122be6f0033da15f3ebff6cb9d4abd37286b89",
    "artist_id": "1ZwdS5xdxEREPySFridCfh",
    "year": 2003
  },
  {
    "song_id": "7xX9mZmjCVzNeAcceLuUiD",
    "name": "A Good Day To Run",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/c7b0f1201395c02e04b1aba0e56bce0cb80b4479",
    "artist_name": "Darryl Worley",
    "image_url": "https://i.scdn.co/image/8802917a8d306e0b4ec64ecbfaa732d0431601c0",
    "artist_id": "4zIBkSJcqRp4ADuFI94WvO",
    "year": 2003
  },
  {
    "song_id": "1HCPjfa02m5JwcO37LHfYb",
    "name": "I Want You",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/8f50e54f59a7bfba1a32ec9dad77b9b73e95d73a",
    "artist_name": "Thalia",
    "image_url": "https://i.scdn.co/image/1b84b2a31eca2aeff74ce8ded03d1cf3a125e46e",
    "artist_id": "23wEWD21D4TPYiJugoXmYb",
    "year": 2003
  },
  {
    "song_id": "0FPuNzimOamZvt7ysiP7Pt",
    "name": "Put That Woman First",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/e773e2953a3e299653b027fedb9751584851f6d5",
    "artist_name": "Jaheim",
    "image_url": "https://i.scdn.co/image/669f052cda7affecd6f5a2f01c6fc16dbfc3e2d0",
    "artist_id": "4sbB4Yy6Qig51pKCIKSLw3",
    "year": 2003
  },
  {
    "song_id": "1p149YJfXkHkSzYp4UZUxV",
    "name": "Hell Yeah",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/3497fa505ce8cdb18eea721fbb222426c04739b3",
    "artist_name": "Ginuwine",
    "image_url": "https://i.scdn.co/image/9784214444840d3bb0e5ca59db31b081b041992b",
    "artist_id": "7r8RF1tN2A4CiGEplkp1oP",
    "year": 2003
  },
  {
    "song_id": "7E2DqvnVtbIrFrL5X6YH9Q",
    "name": "Beer For My Horses",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/cab19bf5af25e02ccf6da5cbdb8dc39ef33ea20b",
    "artist_name": "Toby Keith",
    "image_url": "https://i.scdn.co/image/a695dc4d41703d14b10802356360acffc41448d8",
    "artist_id": "2bA6fzP0lMAQ4kz6CF61w8",
    "year": 2003
  },
  {
    "song_id": "21CCDnq8ROJEnQ7CL72xFA",
    "name": "Intuition",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/9e2bfdd2b9504fdf3fe5cdd6a4e956a29a9c0add",
    "artist_name": "Jewel",
    "image_url": "https://i.scdn.co/image/a8fd205546e0a7241f3736253708d2d92519b19d",
    "artist_id": "6FbDoZnMBTdhhhLuJBOOqP",
    "year": 2003
  },
  {
    "song_id": "0a0HVlMAWI25EDo9Hr7aR7",
    "name": "Can't Stop, Won't Stop - Album Version Remix (Explicit)",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/d6f79c5075b2521c7f257f83e9dec9839a7572e5",
    "artist_name": "Young Gunz",
    "image_url": "https://i.scdn.co/image/e96fbf8c7a964a4e2ff106e8f540a8ae381ca354",
    "artist_id": "2sBv30RzdeMVPoBXbzjIo5",
    "year": 2003
  },
  {
    "song_id": "4vzYcDxBXAUPYDUna4BVeE",
    "name": "Flying Without Wings",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/d64ed443f44fb8714e3bf5985f795307fd547f80",
    "artist_name": "Ruben Studdard",
    "image_url": "https://i.scdn.co/image/0be73405000f5783449a56cc50e24f36ffc77166",
    "artist_id": "0H0yrKDty3I7pPrNUSH3wW",
    "year": 2003
  },
  {
    "song_id": "0gNWux0dyHOx6iRNAFev3I",
    "name": "Never Leave You - Uh Ooh, Uh Oooh! - Main",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/cec3eb17777fc910e77264089ae071f50c7ff981",
    "artist_name": "Lumidee",
    "image_url": "https://i.scdn.co/image/aee6c982caa761577fa942f7b261db65736de797",
    "artist_id": "1IO0GVa1xjkFMXsg66FiE9",
    "year": 2003
  },
  {
    "song_id": "3infEr3WNjesDtV7JqDPwY",
    "name": "My Front Porch Looking In",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/e37737a11456ccb312e00190c3f0a158b1c4c8ec",
    "artist_name": "Lonestar",
    "image_url": "https://i.scdn.co/image/b7426a640764466c4e5368c97a19c74dae9dc77e",
    "artist_id": "3qbnxnvUqR14MJ9g8QwZJK",
    "year": 2003
  },
  {
    "song_id": "4v5WnO30GpzdHasUOY3UsH",
    "name": "Rain On Me",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/c46c925a12b99bd7542da52c73324c4ab702c209",
    "artist_name": "Ashanti",
    "image_url": "https://i.scdn.co/image/365b8a1877eca179a9631c0e2722e5e079e6dce4",
    "artist_id": "5rkVyNGXEgeUqKkB5ccK83",
    "year": 2003
  },
  {
    "song_id": "6QP46GKcggdxLbVLWilLVc",
    "name": "This Is The Night",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/911f3495b344cce2578df221d40c2b9b6dc56826",
    "artist_name": "Clay Aiken",
    "image_url": "https://i.scdn.co/image/08d29faea1462e96b5e0e02dc3cb210ef09ba4d8",
    "artist_id": "13aBWT8LN4oNWWGFOu96MA",
    "year": 2003
  },
  {
    "song_id": "7kScbS865xisYyLJUUHwY6",
    "name": "Sick Of Being Lonely",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/cfeaa2be3c4788a1d5e0b1ec5704903b059f0fdc",
    "artist_name": "Field Mob",
    "image_url": "https://i.scdn.co/image/2291c132b4ee8c94b8b67b21cbe47520838a6f6b",
    "artist_id": "0Uo6kyjrbQoZBlcXsMb8Vm",
    "year": 2003
  },
  {
    "song_id": "3AszgPDZd9q0DpDFt4HFBy",
    "name": "Hey Ya! - Radio Mix / Club Mix",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/5a3e7820638d2a0b04d02ade9c2724bcda66821c",
    "artist_name": "OutKast",
    "image_url": "https://i.scdn.co/image/628cdd8bb4088141e470090993da0e266daf89e6",
    "artist_id": "1G9G7WwrXka3Z1r7aIDjI7",
    "year": 2004
  },
  {
    "song_id": "5rb9QrpfcKFHM1EUbSIurX",
    "name": "Yeah!",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/50a55b6e349edfad35b2064472a491c74a55fd15",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/e86c60bde9dd62f70bd23336cc8475f6f728a511",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2004
  },
  {
    "song_id": "0r2Bul2NuCViraT2zX1l5j",
    "name": "Get Low",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/7b04a0047ec4807f03d9e6efef09421190d300e6",
    "artist_name": "Lil Jon",
    "image_url": "https://i.scdn.co/image/c17fd70ad1934257391375b3d9cc740040f687b5",
    "artist_id": "7sfl4Xt5KmfyDs2T3SVSMK",
    "year": 2004
  },
  {
    "song_id": "3XVBdLihbNbxUwZosxcGuJ",
    "name": "If I Ain't Got You",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/231f836b4f0f4601ac9427e6e99bb4726549c3d3",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/e2ae18bcfdf91acfb321e01a0f0e80bd8e27d9f8",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2004
  },
  {
    "song_id": "305WCRhhS10XUcH6AEwZk6",
    "name": "Are You Gonna Be My Girl",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/4be4756dcb22f4e441c31319e9ed8f0760d082f9",
    "artist_name": "Jet",
    "image_url": "https://i.scdn.co/image/3fc4405980c46734721bdae828e39cd544041535",
    "artist_id": "5ypxebeHEIXjMtJb17uJlI",
    "year": 2004
  },
  {
    "song_id": "5zF2zGPkL6Ipsn9AfAK6RP",
    "name": "This Love",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/b468088acab5eca2cd097a88f7d9bab3d3a15727",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/159c8f1c21ec1a94364b847b2e85767091fa414c",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2004
  },
  {
    "song_id": "68vgtRHr7iZHpzGpon6Jlo",
    "name": "My Boo - Main",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/5653540055de46c1e5a14c8aaa4290f6ab78f667",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/e86c60bde9dd62f70bd23336cc8475f6f728a511",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2004
  },
  {
    "song_id": "2cMTIlktg3M9mXYqCPqw1J",
    "name": "Milkshake",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/7841ca8783bc7bff256c93839f3c21b0d70a0157",
    "artist_name": "Kelis",
    "image_url": "https://i.scdn.co/image/19f8e829cdda4f620315511daf19e978cbcdafa4",
    "artist_id": "0IF46mUS8NXjgHabxk2MCM",
    "year": 2004
  },
  {
    "song_id": "3PHYPaguCDKLK1a9cp3uXZ",
    "name": "Because of You",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/61b81ae2e21a6050583f7c454d211029047acab3",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/245da235a63d0b2526732ad9c884fefeb033fc72",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2004
  },
  {
    "song_id": "4PkJ7c9y1CwpuVOiJODnCZ",
    "name": "Someday",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/f21db1e1926bea7845ada5522c472d1d7122005b",
    "artist_name": "Nickelback",
    "image_url": "https://i.scdn.co/image/edf58e9b2ff0a5e2948c0f4d4fc7a0b9cacc15e0",
    "artist_id": "6deZN1bslXzeGvOLaLMOIF",
    "year": 2004
  },
  {
    "song_id": "0kO3njY9N1Rxgv27Ha1lLh",
    "name": "Numb",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/5dccedd7181d1e77844a34c8c98e3af491803200",
    "artist_name": "Linkin Park",
    "image_url": "https://i.scdn.co/image/5436fbe62ffb1e8989d344ffd7ae32e9c8bcfd26",
    "artist_id": "6XyY86QOPPrYVGvF9ch6wz",
    "year": 2004
  },
  {
    "song_id": "0RmXtDH1cBMGImRrmn5xL6",
    "name": "Turn Me On",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/6499bde66afd23d257f63a896aea4212ca1f85e6",
    "artist_name": "Kevin Lyttle",
    "image_url": "https://i.scdn.co/image/66eb9f5dd0c9b12e7ddf71d70bef01a41626663d",
    "artist_id": "1GaBsp1ICIp1e6udgE7fba",
    "year": 2004
  },
  {
    "song_id": "0YGQ3hZcRLC5YX7o0hdmHg",
    "name": "Naughty Girl",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/f50eeb699b2ca44bd055648da7ec9f57dfdf95e4",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/dc6f04be7adf3ed5355ef230ea4fa06ec80ccc8b",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2004
  },
  {
    "song_id": "2cYZpcIV39X48RnOFM7w2V",
    "name": "All Falls Down",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/dc8d5cdac120fb2aab134f7fac85794d9fa362ca",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/f943995df11b742c8f4d129e4685f9b87966424c",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2004
  },
  {
    "song_id": "14B2bUopOga5V3ypld7d6n",
    "name": "Suga Suga",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/8365dfcb8d886c3fe74ab49c879af06f51ecf73d",
    "artist_name": "Baby Bash",
    "image_url": "https://i.scdn.co/image/91d4087c09c9ae2c15038f6049003d498ed03330",
    "artist_id": "12PSlydMSjEHzSCj9X5qv7",
    "year": 2004
  },
  {
    "song_id": "7qQfI2F80t2MZcLLVuH6M2",
    "name": "Here Without You",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/ef536636dc6618d7054e31f0821bbf4150db2b9a",
    "artist_name": "3 Doors Down",
    "image_url": "https://i.scdn.co/image/f7f4ac87474fd7027379ad5fdb8211ac1ddd100a",
    "artist_id": "2RTUTCvo6onsAnheUk3aL9",
    "year": 2004
  },
  {
    "song_id": "1nCSr7oGeUv7sUEoYuAJFF",
    "name": "Remember When",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/9935e738e52f862bd37fe0ca77d6cf4caa2786b6",
    "artist_name": "Alan Jackson",
    "image_url": "https://i.scdn.co/image/1e57733de7c217deda986db07a0bf1ac182b52f5",
    "artist_id": "4mxWe1mtYIYfP040G38yvS",
    "year": 2004
  },
  {
    "song_id": "79cAPxneo5AvbNgR6ch3Cj",
    "name": "Meant to Live",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/2584f18a644b3a144b7e2f6e58f0c0de26916038",
    "artist_name": "Switchfoot",
    "image_url": "https://i.scdn.co/image/44e64252dcdf52186711dc2304c83399fa9bd0b8",
    "artist_id": "6S58b0fr8TkWrEHOH4tRVu",
    "year": 2004
  },
  {
    "song_id": "4HcDu9IGRCvSTJE4yW5Ugv",
    "name": "Breaking The Habit",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/b711a6d9543609290bb5deca070162d43e8bccc3",
    "artist_name": "Linkin Park",
    "image_url": "https://i.scdn.co/image/5436fbe62ffb1e8989d344ffd7ae32e9c8bcfd26",
    "artist_id": "6XyY86QOPPrYVGvF9ch6wz",
    "year": 2004
  },
  {
    "song_id": "6v6WjsyLdY189IewecL2x7",
    "name": "Just Lose It",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/e3aae35a9c4105932b22f576241b6f9cff812eb1",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/a89a0716ece38c0a06286dd2bbd4f575306c3a1c",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2004
  },
  {
    "song_id": "6XsT5UGfpaFeHQf5LRIy4W",
    "name": "Me, Myself And I",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/a908e3d1e58856a889eade120c60a0be33001e31",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/dc6f04be7adf3ed5355ef230ea4fa06ec80ccc8b",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2004
  },
  {
    "song_id": "3dFwpxh2yH7C7p9BGEKLVB",
    "name": "Goodies - feat. Petey Pablo",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/c86811a1bb8838d9f1ae5dda08c42c4513cdeced",
    "artist_name": "Ciara",
    "image_url": "https://i.scdn.co/image/e95de79ad972298abd2dd416174592e1023b88c7",
    "artist_id": "2NdeV5rLm47xAvogXrYhJX",
    "year": 2004
  },
  {
    "song_id": "5Kh4cypbGZnhxlopwh0Cd6",
    "name": "Dirt Off Your Shoulder",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/c2278fad98e5ae0ba702e8d938bf59337cf33b4a",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/db2eb39e7d8635f7e46f4ae4e40d18d98fc274ea",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2004
  },
  {
    "song_id": "2mQ1RBK7s87mdPCzh97RSH",
    "name": "Heaven",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/b36b2ff06f7568767e6b2068f071e1d3b72f3b33",
    "artist_name": "Los Lonely Boys",
    "image_url": "https://i.scdn.co/image/e9095fd91fa5b5dcfb2e5071662b6b459fcbd956",
    "artist_id": "4aSEmLLxLX9wR5aLMllOKj",
    "year": 2004
  },
  {
    "song_id": "26nr9XnFCYWxBTIP7HyWXg",
    "name": "Toxic",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/24bc011663d2a4be6e74611353790136bfb3418e",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/81276ec57c69d8ad6960a6d018ec34c3d20f2b26",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2004
  },
  {
    "song_id": "5uA4ZpS2DTLwbHpKHlsSqd",
    "name": "My Happy Ending",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/0bdd02c399556cd5f2a0f6c096cd42cdab9f0c35",
    "artist_name": "Avril Lavigne",
    "image_url": "https://i.scdn.co/image/ca1a41e8f004dc72662d415c3a59b7c2f9bf4a82",
    "artist_id": "0p4nmQO2msCgU4IF37Wi3j",
    "year": 2004
  },
  {
    "song_id": "0Ox458KpvZQ1Kuj9mUNPLL",
    "name": "I Go Back",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/c0fbffd4387c51913895ddc4d31182e5866e69fb",
    "artist_name": "Kenny Chesney",
    "image_url": "https://i.scdn.co/image/a1206652d5f379cfb939a493347c4007874656ac",
    "artist_id": "3grHWM9bx2E9vwJCdlRv9O",
    "year": 2004
  },
  {
    "song_id": "29LHe8kG3PraghUZOZYsw4",
    "name": "Baby Boy [feat. Beyonce]",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/e30d6a39d7374ba4a863c41e9dd762c42eb02b2e",
    "artist_name": "Sean Paul",
    "image_url": "https://i.scdn.co/image/45edb1fa71a7af32adeb4f73f739464c0476cfb0",
    "artist_id": "3Isy6kedDrgPYoTS1dazA9",
    "year": 2004
  },
  {
    "song_id": "4Kd0FzFpOgIGxlBl4HXuFn",
    "name": "Salt Shaker",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/447cd5c760a581173ef9cef7ad7ba107290241aa",
    "artist_name": "Ying Yang Twins",
    "image_url": "https://i.scdn.co/image/aeb0302777fb6b2b442d37b63f0f656055384c75",
    "artist_id": "44PA0rCQXikgOWbfY7Fq7m",
    "year": 2004
  },
  {
    "song_id": "0FmCLEHfINGqwlyzvklNqy",
    "name": "Holidae In",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/cd66339918f7cf5f0f2937442c71044679d1a610",
    "artist_name": "Chingy",
    "image_url": "https://i.scdn.co/image/e3afa7cad5f345b645a127d31b73cb4a0a8b9b79",
    "artist_id": "3s2wTjWxK8NOX09dmsvVOh",
    "year": 2004
  },
  {
    "song_id": "2vICzUUia22chV8mQKk1Pt",
    "name": "She Will Be Loved",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/63b5072eb574910deeec0dc072d1139319893c76",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/c8f808d8df9241e2352c31f2e31dd506d05a0107",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2004
  },
  {
    "song_id": "79ek2oolIYyGUO4aVK5IY2",
    "name": "Jesus Walks",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/8aac4d3a74d1747568022007fbacda225bf1222c",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/102504bd8952b26857974a3dab5884430245e228",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2004
  },
  {
    "song_id": "06ZDLodo1UDVavc3MXREZ3",
    "name": "Walked Outta Heaven",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/4259ba579eb9a682e290ca1d48d3dacf4a090f09",
    "artist_name": "Jagged Edge",
    "image_url": "https://i.scdn.co/image/b51cb09381c7ff676e2a39800a74e656e854a340",
    "artist_id": "7Aq8lpLMSt1Zxu56pe9bmp",
    "year": 2004
  },
  {
    "song_id": "5r55eZvOijyYZRPCdQbu85",
    "name": "Everytime",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/6bf6804ed7967e7a103fb3d8da5393d10bf9cdca",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/81276ec57c69d8ad6960a6d018ec34c3d20f2b26",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2004
  },
  {
    "song_id": "4PzPvpE63ttEbKyMDRWB6q",
    "name": "The First Cut Is The Deepest - Acoustic Version",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/1252575d9a816ee69b52fc8b7cc842532ffd5890",
    "artist_name": "Sheryl Crow",
    "image_url": "https://i.scdn.co/image/06b3030cf521b5680d2c6cd0b1adee389c8afb09",
    "artist_id": "4TKTii6gnOnUXQHyuo9JaD",
    "year": 2004
  },
  {
    "song_id": "19EQUqnUXEeiBrY10JhPEF",
    "name": "It's My Life",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/38b2d289cd2f2f7ebd90e555fddd900de38d1c3b",
    "artist_name": "No Doubt",
    "image_url": "https://i.scdn.co/image/1dd08d519a3fc4298605d532a964686052c057cc",
    "artist_id": "0cQbJU1aAzvbEmTuljWLlF",
    "year": 2004
  },
  {
    "song_id": "2n4uOdMHzEvcZ0KP7iQnay",
    "name": "Lose My Breath",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/7b7767b8c8d5f6df843b7baf99fb243bb382a3a4",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/48a771f5f78b64690c3aaae7fb0f9d5c2276d1b4",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 2004
  },
  {
    "song_id": "2CtCwQhY0ZLvr8L2l8Bo6e",
    "name": "Stand Up",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/9d6e335aad290685fdf83cb639c30005e0a9a24d",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/a99a9303799a1b883a28271c6ab0a5ebb0d7c23a",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2004
  },
  {
    "song_id": "6KbpvmsV6L8JsON1C3JHAV",
    "name": "When the Sun Goes Down",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/507e9444c22206f6afa28b6e3887ada466538945",
    "artist_name": "Kenny Chesney",
    "image_url": "https://i.scdn.co/image/a1206652d5f379cfb939a493347c4007874656ac",
    "artist_id": "3grHWM9bx2E9vwJCdlRv9O",
    "year": 2004
  },
  {
    "song_id": "7bchsHDaf9CRoe64ceIDYQ",
    "name": "Fuck It (I Don't Want You Back) - Dirty",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/58bc139161c14b06d888aee4adbad64c1105a9be",
    "artist_name": "Eamon",
    "image_url": "https://i.scdn.co/image/ce0220afaec3ec46effba5b7451011aea973e755",
    "artist_id": "1y20PpXw0yeuJ1avCD0Ob9",
    "year": 2004
  },
  {
    "song_id": "2ndnVdR8si7CW9T8vJRHyW",
    "name": "Move Ya Body",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/ee37da58bb907ed200da15faa81180d847b8da8c",
    "artist_name": "Nina Sky",
    "image_url": "https://i.scdn.co/image/de37782f6e043eae197bc25cc224cd59661f5be8",
    "artist_id": "6eBYLQONaYZhZNAVK061t6",
    "year": 2004
  },
  {
    "song_id": "0YUrjFy4qFKOO5NhM9tYdV",
    "name": "Locked Up",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/9456ae5722c001885b29b85299d6b7dcc0545018",
    "artist_name": "Akon",
    "image_url": "https://i.scdn.co/image/8fe3194064af629faf9e730baf5f4f9c29934eef",
    "artist_id": "0z4gvV4rjIZ9wHck67ucSV",
    "year": 2004
  },
  {
    "song_id": "5dxHF4lrDimKqnI3GaZUmC",
    "name": "Diary",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/1fb0ed9750d19aac1072af653d5b93b103047af3",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/e2ae18bcfdf91acfb321e01a0f0e80bd8e27d9f8",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2004
  },
  {
    "song_id": "7pNJ1T9TGbVbNHKaLhhPri",
    "name": "With You",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/9a0a922ccb49b676f7135107f6c22ab027dbdb8d",
    "artist_name": "Jessica Simpson",
    "image_url": "https://i.scdn.co/image/049ff8b9ddfc596738f6f197caf38a30397c400b",
    "artist_id": "2tFN9ubMXEhdAQvdQxcsma",
    "year": 2004
  },
  {
    "song_id": "1pHmmvnRsCqI56r8NZecvq",
    "name": "Through The Wire",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/690a6e98abf167168dedfce4a66addb53c005fef",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/102504bd8952b26857974a3dab5884430245e228",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2004
  },
  {
    "song_id": "6H7w7e8zC8QYJyqe7fZHVa",
    "name": "Lean Back",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/27227d48983e04bae3451d95da350f56fbb0cd9d",
    "artist_name": "Terror Squad",
    "image_url": "https://i.scdn.co/image/db3e44dd51ed8890d8b7c74cea9c4fbf4d4f3f3e",
    "artist_id": "2BvzbqWWwLN11XGBYgDZzx",
    "year": 2004
  },
  {
    "song_id": "15cGdiAHmezm0P04jVAjMo",
    "name": "Letters From Home",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/8cce982692bfc6d0c1c185e7d2fe0ce54f25b8f4",
    "artist_name": "John Michael Montgomery",
    "image_url": "https://i.scdn.co/image/a3feb80d1fd31c4e9cdce30ef2deb5d9cd56e7de",
    "artist_id": "7mPcfx7PhnBh6n4HMCd61A",
    "year": 2004
  },
  {
    "song_id": "6mU3a8GcwOt1FiT06TiTFF",
    "name": "Wanna Get To Know You",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/8a84b3d0bfe2d8eb5758d17c24a0b37f00a6ff20",
    "artist_name": "G-Unit",
    "image_url": "https://i.scdn.co/image/fdcf1d62f2698ae32e81447e34aab75eeab4044f",
    "artist_id": "6evKD5JWJON3qPBJtUEmtY",
    "year": 2004
  },
  {
    "song_id": "64qxxqjUynL5vjkXP104I9",
    "name": "100 Years",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/0ec0c0408ed0ef4687ba46aee4cee6392424ee34",
    "artist_name": "Five For Fighting",
    "image_url": "https://i.scdn.co/image/0dd8720252e3ad278f28ceb4ac74c897c9f0514a",
    "artist_id": "7FgMLbnZVrEnir95O0YujA",
    "year": 2004
  },
  {
    "song_id": "5XWjx5tHMsOI7BZh8wjeSu",
    "name": "Dude",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/ee2d86d742f6dd317390f1a065497fa219575fd0",
    "artist_name": "Beenie Man",
    "image_url": "https://i.scdn.co/image/0bd08ec736805f8f7d2ffa162e32414dfa48f528",
    "artist_id": "4L3GTE04bW5N7azA9QPhjA",
    "year": 2004
  },
  {
    "song_id": "05qXhd5dgfDS5mDEf7dbfN",
    "name": "Let's Get It Started",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/acbd6fb4e19c0d8264e3e8e82ddb3e9fa77b155e",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/90954977c5db4139d2797a5fa80d10ef521e70fd",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2004
  },
  {
    "song_id": "6fqqAsCYfDOtXAD8Ydo06K",
    "name": "Tipsy - Club Mix",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/adde343313952c1b60a46a45ee7419d8a003e926",
    "artist_name": "J-Kwon",
    "image_url": "https://i.scdn.co/image/88d43cbb767b29702c2aa81a0c545a71f4b4b41c",
    "artist_id": "1Zz5UxfKSSqc6hpa3xJPCw",
    "year": 2004
  },
  {
    "song_id": "5IIQ7vg9HvA4QSNtFDGJVT",
    "name": "Pieces Of Me",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/93a018e42da85bc57df28073154e6dc86850d0e4",
    "artist_name": "Ashlee Simpson",
    "image_url": "https://i.scdn.co/image/abcf1aaa19e8ccbd7943119aa42457f24c4a0371",
    "artist_id": "4hqDqHtBlgxXpLXVYf7c8L",
    "year": 2004
  },
  {
    "song_id": "0EQuu6hyNACWI7LT7N5pRv",
    "name": "U Should've Known Better",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/669390eb8ed22b730a9089e095a4a53ebd57e8fb",
    "artist_name": "Monica",
    "image_url": "https://i.scdn.co/image/bb4f66f7de70be07d91f76b8d14312c9e2ad34fe",
    "artist_id": "6nzxy2wXs6tLgzEtqOkEi2",
    "year": 2004
  },
  {
    "song_id": "22HX8moAHWULPAu6t5CivV",
    "name": "Step In The Name Of Love - Remix",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/a6223b76a8ae49ed811767c5ee873e2bdbe35d7a",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/da271670355c7ca8962bc3d95875534511774285",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 2004
  },
  {
    "song_id": "2TG5eOKa1PpurB3Z6KOTmJ",
    "name": "Don't Tell Me",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/fe3438c8bb0911ed21be6e25835aafac48f25a3f",
    "artist_name": "Avril Lavigne",
    "image_url": "https://i.scdn.co/image/4c78d50dcfb1a37874047d8d33cb2d665ae6dc40",
    "artist_id": "0p4nmQO2msCgU4IF37Wi3j",
    "year": 2004
  },
  {
    "song_id": "1CDjORoMxLHFURZhH8n0TS",
    "name": "Drop It Like It's Hot",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/41b8ef9353a194f3cdf38cf6ef4814694c2f4ea6",
    "artist_name": "Snoop Dogg",
    "image_url": "https://i.scdn.co/image/39a8b885c205d0cb479fd4af0415a96226444986",
    "artist_id": "7hJcb9fa4alzcOq3EaNPoG",
    "year": 2004
  },
  {
    "song_id": "1GwVi2ceNb8dMFA3DZGnnY",
    "name": "On The Way Down",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/802df646a9f2cb6a496c706c501310721a4d38f3",
    "artist_name": "Ryan Cabrera",
    "image_url": "https://i.scdn.co/image/0ebdf242cbd0045bc6806600a620b9569d3d18a9",
    "artist_id": "3C4t1usFGoKHCSRdujm2GN",
    "year": 2004
  },
  {
    "song_id": "6XVwF0Q3wYJPMMQj81CQlD",
    "name": "On Fire",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/5f3749703b66580e385fab9a36a9f13130bafb8a",
    "artist_name": "Lloyd Banks",
    "image_url": "https://i.scdn.co/image/cbf82d35ba180726b8cf79fc009b670a71817a27",
    "artist_id": "3vDUJHQtqT3jFRZ2ECXDTi",
    "year": 2004
  },
  {
    "song_id": "7FH0VxmvgfPJWfvillVzfE",
    "name": "Sunshine",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/d60966df5e4576be8ac16abd3a35831d33b36117",
    "artist_name": "Lil' Flip",
    "image_url": "https://i.scdn.co/image/6ced27fb89d8fb4707ebc95b5ce8c6baa6ae5ff4",
    "artist_id": "4Q5sPmM8j4SpMqL4UA1DtS",
    "year": 2004
  },
  {
    "song_id": "7KViJE5WtqHzztR9APTDl0",
    "name": "Roses - Dirty Version",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/e5c56704429a9bff128ac7fefbeb71bac30dc7bb",
    "artist_name": "OutKast",
    "image_url": "https://i.scdn.co/image/70f65b66f5138f0693c653870a9b240741c472f4",
    "artist_id": "1G9G7WwrXka3Z1r7aIDjI7",
    "year": 2004
  },
  {
    "song_id": "1T1ZUKX4X87tVLaBGjwFv4",
    "name": "Gigolo",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/8370bad18e4b569513000f0bb071be5eb52103f9",
    "artist_name": "Nick Cannon",
    "image_url": "https://i.scdn.co/image/abde90db376eedded3fa68dcba2210adbe2df77e",
    "artist_id": "480i0t8YiGc2aoOR6BeLAb",
    "year": 2004
  },
  {
    "song_id": "62RQawqgBUqWcPHmGGxZyw",
    "name": "Change Clothes",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/770f5ca16a35036359ba2adc733c81ac70c502fa",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/6d52787d6c6a838c81b6a5133bae4c07ef5e9b7a",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2004
  },
  {
    "song_id": "7K60xqY6EZrN7nHBZNNLK1",
    "name": "Hey Mama",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/6ad29a70837371ed35810e1212c6355715af7801",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/31153792174579640822f56ba965007fbc02283f",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2004
  },
  {
    "song_id": "1tcUtrQt5LyGZ3Gcu8LUTH",
    "name": "You'll Think Of Me",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/827808e9d82197da16cc836168d00a0c80707cab",
    "artist_name": "Keith Urban",
    "image_url": "https://i.scdn.co/image/970948bfde72a6ff8dd4bb577f22e9a0a5e3b95f",
    "artist_id": "0u2FHSq3ln94y5Q57xazwf",
    "year": 2004
  },
  {
    "song_id": "4vey3VC7ZROIESUu0kgiBc",
    "name": "Sorry 2004",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/1d6022c055cf1d0440e4866939161f4363439da0",
    "artist_name": "Ruben Studdard",
    "image_url": "https://i.scdn.co/image/fd85da21abb8bb067d7d94f220365e6d6f29db99",
    "artist_id": "0H0yrKDty3I7pPrNUSH3wW",
    "year": 2004
  },
  {
    "song_id": "1Ya9CafQ0UKGk1Vhy66U5b",
    "name": "Headsprung",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/cd5eda81cb25e008d389b6dda7064fa5913a9f60",
    "artist_name": "LL Cool J",
    "image_url": "https://i.scdn.co/image/dbc84331ef214b6855ecbd0f2e4539fc76cecaac",
    "artist_id": "1P8IfcNKwrkQP5xJWuhaOC",
    "year": 2004
  },
  {
    "song_id": "6EverpxjXS71qTCGVxbPDh",
    "name": "American Soldier",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/4c78dd003006cf1fbd081671bca18cdd2af12186",
    "artist_name": "Toby Keith",
    "image_url": "https://i.scdn.co/image/473d59a779e93fab1b3e143296293f0477372142",
    "artist_id": "2bA6fzP0lMAQ4kz6CF61w8",
    "year": 2004
  },
  {
    "song_id": "6LiDErJMV0ZK3sHEAw92XC",
    "name": "The Way You Move",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/f44005684ea0331f8fc1c278e80f72544e3acbe0",
    "artist_name": "OutKast",
    "image_url": "https://i.scdn.co/image/48756dff9757e6270a7bf962a42788a2ec8b3c3e",
    "artist_id": "1G9G7WwrXka3Z1r7aIDjI7",
    "year": 2004
  },
  {
    "song_id": "48alwd8sAqkRUmfCF7GFue",
    "name": "Splash Waterfalls",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/489897a9ca64105e1c3e1b70527dd3de3bc442b1",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/9df1a0bd42aefd8276e8733785392ccadd382c83",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2004
  },
  {
    "song_id": "41uWkGOCpz0CA5vJ1nIKI6",
    "name": "Happy People",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/b72106953994ad4c8ae61166a61eba371397b58e",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/25e552b5f8731e513db2d4d0a1aab327e3d949bc",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 2004
  },
  {
    "song_id": "6vCu5rwYDkSo9tFZdwf3uP",
    "name": "Redneck Woman",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/67e2cbea7874477e534952598438ab6ee1b7c512",
    "artist_name": "Gretchen Wilson",
    "image_url": "https://i.scdn.co/image/7dad06a508747508910486531eddd0bc04132d9a",
    "artist_id": "0IdYRFTswLdsGwSnzOaGNF",
    "year": 2004
  },
  {
    "song_id": "2b8QQqf8Vwb7bfz22ja8Fk",
    "name": "Over And Over - Album Version / Explicit",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/a60327ad5ff8588504803c518aa19f57d85120d4",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/ee74c0b97e06e4b756fc266f95976cb5a7f1c5f9",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2004
  },
  {
    "song_id": "61S6qpVRNxRrnGvjWfce2i",
    "name": "Hotel",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/126eadb6d33b1107c9c8895388cbc3eed06ae1b3",
    "artist_name": "Cassidy",
    "image_url": "https://i.scdn.co/image/b78f9325c8c0aea15c59246326094d75e5f57165",
    "artist_id": "3WilJWdCNLSU7fpYjiKb7v",
    "year": 2004
  },
  {
    "song_id": "7E0JWvn76JdtJKfUfkBvfy",
    "name": "My Place - Album Version / Explicit",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/08c44b9d010a73f9fb5ce27d657c7b1f95d24493",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/dfea3272ced6139dd694ce1e927bb77aa038fb87",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2004
  },
  {
    "song_id": "3tV32imVw91bmmgGMEvSnr",
    "name": "My Band",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/52d23d523167177c7ad4a0d9875d7e211f1ed19a",
    "artist_name": "The Allstars Cover D12",
    "image_url": "https://i.scdn.co/image/3fd73b5cc52979f5edeee0e4b0a3396f06bdfba0",
    "artist_id": "00Ev4GJpwTFOtjZZkYahUE",
    "year": 2004
  },
  {
    "song_id": "6OxlaGRzSQ42foonOTlrKz",
    "name": "You Don't Know My Name",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/d8002aa4fdd7df25d9b0b7a51fb56466521a10fa",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/fb7ad8c260dc47776ec82bc62067afaf67f0535f",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2004
  },
  {
    "song_id": "1897ys2ueZMgGUSmoP90CJ",
    "name": "Dip It Low",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/76433d419edef2a4a928bdba5368f2f24c801ccb",
    "artist_name": "Christina Milian",
    "image_url": "https://i.scdn.co/image/53d6b3f0539f1838d81c3164c699b5d67d356d04",
    "artist_id": "4eAOcbAXIF4BmbN6E1QIlw",
    "year": 2004
  },
  {
    "song_id": "3X8JREdtkxxOFIyeIHekUm",
    "name": "Southside",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/0a639204774e1291c11da4a0518aaa99a0d6b910",
    "artist_name": "Lloyd",
    "image_url": "https://i.scdn.co/image/9bcd36e9329da36643e8ece1d4c69be6a13fa56a",
    "artist_id": "1Xfmvd48oOhEWkscWyEbh9",
    "year": 2004
  },
  {
    "song_id": "6KSNouHU95rpfqO4i0ca8s",
    "name": "Why - Album Version (Edited)",
    "popularity": 21,
    "preview_url": "https://p.scdn.co/mp3-preview/629fdb4e2f85899941b9544ef34c201c6cbb6d26",
    "artist_name": "Jadakiss",
    "image_url": "https://i.scdn.co/image/f4399cd819e682f66132f5ce566dfa4464f69129",
    "artist_id": "5pnbUBPifNnlusY8kTBivi",
    "year": 2004
  },
  {
    "song_id": "4tNuZJbMukwqC4IG7lDEk3",
    "name": "Game Over (Flip) - Clean Album Version",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/3a8066e16a488b521e068cf25a9a95a950fb4bfc",
    "artist_name": "Lil' Flip",
    "image_url": "https://i.scdn.co/image/6ef736e1e421a3a82b7b7367762f396adae40e80",
    "artist_id": "4Q5sPmM8j4SpMqL4UA1DtS",
    "year": 2004
  },
  {
    "song_id": "0d28khcov6AiegSCpG5TuT",
    "name": "Feel Good Inc",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/67c4a09cdb95529efc4eabcc11de337cabc9c3a8",
    "artist_name": "Gorillaz",
    "image_url": "https://i.scdn.co/image/b7db62f7b4c5c3baff08ad876f41e0b8662206be",
    "artist_id": "3AA28KZvwAUcZuOKwyblJQ",
    "year": 2005
  },
  {
    "song_id": "0eGsygTp906u18L0Oimnem",
    "name": "Mr. Brightside",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/f454c8224828e21fa146af84916fd22cb89cedc6",
    "artist_name": "The Killers",
    "image_url": "https://i.scdn.co/image/8e13218039f81b000553e25522a7f0d7a0600f2e",
    "artist_id": "0C0XlULifJtAgn6ZNCW2eu",
    "year": 2005
  },
  {
    "song_id": "5rwq6R0Uq0BngM3rdmCeNg",
    "name": "Collide - Acoustic Version",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/42416e7e1b8c05ab3ddc78d61e659187df810dd6",
    "artist_name": "Howie Day",
    "image_url": "https://i.scdn.co/image/c4f29fe7180ba45e8970a8f834ca65210cf65542",
    "artist_id": "0ekbDNE2eOq8QiaXM34wer",
    "year": 2005
  },
  {
    "song_id": "3ZffCQKLFLUvYM59XKLbVm",
    "name": "Wake Me Up When September Ends",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/220fcadf78fafdbce596c566e15e0cb9e75ea9bb",
    "artist_name": "Green Day",
    "image_url": "https://i.scdn.co/image/650afb8a0b8660462fa707c2bec4a427b17f329f",
    "artist_id": "7oPftvlwr6VrsViSDV7fJY",
    "year": 2005
  },
  {
    "song_id": "3ibKnFDaa3GhpPGlOUj7ff",
    "name": "Let Me Love You",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/e43e778e58079bd9a28a871f7322836d0daae284",
    "artist_name": "Mario",
    "image_url": "https://i.scdn.co/image/8bc60e0bfcdb963022043738d120575df984f309",
    "artist_id": "20s0P9QLxGqKuCsGwFsp7w",
    "year": 2005
  },
  {
    "song_id": "17baAghWcrewNOcc9dCewx",
    "name": "Mockingbird",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/d661c2542e53525c444d266cf2c3c066ea531141",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/a89a0716ece38c0a06286dd2bbd4f575306c3a1c",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2005
  },
  {
    "song_id": "0MsrWnxQZxPAcov7c74sSo",
    "name": "Holiday/Boulevard Of Broken Dreams",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/0489393fa319127c64a4510eef73d950ce647e0c",
    "artist_name": "Green Day",
    "image_url": "https://i.scdn.co/image/650afb8a0b8660462fa707c2bec4a427b17f329f",
    "artist_id": "7oPftvlwr6VrsViSDV7fJY",
    "year": 2005
  },
  {
    "song_id": "6ich2xMH5AR39V85miIAN8",
    "name": "I Don't Want to Be",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/7193b6147edc357dd63cd46e732462dcc4b73ca5",
    "artist_name": "Gavin DeGraw",
    "image_url": "https://i.scdn.co/image/814d5847cb472e82330a5fe4775cdc97ea6b7cee",
    "artist_id": "5DYAABs8rkY9VhwtENoQCz",
    "year": 2005
  },
  {
    "song_id": "3PHYPaguCDKLK1a9cp3uXZ",
    "name": "Because of You",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/61b81ae2e21a6050583f7c454d211029047acab3",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/245da235a63d0b2526732ad9c884fefeb033fc72",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2005
  },
  {
    "song_id": "3PHYPaguCDKLK1a9cp3uXZ",
    "name": "Because of You",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/61b81ae2e21a6050583f7c454d211029047acab3",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/245da235a63d0b2526732ad9c884fefeb033fc72",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2005
  },
  {
    "song_id": "4T2SmVJPtDdugk5j5xV1d5",
    "name": "You And Me",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/9b55361ebe94215872f4fc2fdf789eb2038f4bda",
    "artist_name": "Lifehouse",
    "image_url": "https://i.scdn.co/image/b9af2f13b54bf540757500f2aff1f97de21da722",
    "artist_id": "5PokPZn11xzZXyXSfnvIM3",
    "year": 2005
  },
  {
    "song_id": "7clUVcSOtkNWa58Gw5RfD4",
    "name": "Speed Of Sound",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/077de813b92664327fa20f8da64bea90d1f04762",
    "artist_name": "Coldplay",
    "image_url": "https://i.scdn.co/image/7b85f1d6c0830d543e625d959c4a61bb889e164d",
    "artist_id": "4gzpq5DPGxSnKTe4SA8HAU",
    "year": 2005
  },
  {
    "song_id": "1hwJKpe0BPUsq6UUrwBWTw",
    "name": "Boulevard Of Broken Dreams",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/1bd9d0ae0db1fa078659c1d1cf7ba4d52c0b66a8",
    "artist_name": "Green Day",
    "image_url": "https://i.scdn.co/image/7437c7bb4bd4691a3410d6b7f6923116e9e38267",
    "artist_id": "7oPftvlwr6VrsViSDV7fJY",
    "year": 2005
  },
  {
    "song_id": "6GC6lcKgjCdsdIz5OiCUw5",
    "name": "Pon De Replay",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/5fbb1e00470e1c0d185664dc69ca47789827563e",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/0957ce7afd798dd975b36fb7345f9db8be1ed99d",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2005
  },
  {
    "song_id": "4zjFqN9fXAw91GNgJOCYX6",
    "name": "Sugar, We're Goin Down",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/ec1a224283bb9343ee0c3cb68cfbd243a180effa",
    "artist_name": "Fall Out Boy",
    "image_url": "https://i.scdn.co/image/d6b93e2ca8ee1ad46344da2d22cdffdcb66c2ef7",
    "artist_id": "4UXqAaa6dQYAk18Lv7PEgX",
    "year": 2005
  },
  {
    "song_id": "08kTO4EW0jb07zNsCNM83w",
    "name": "Chariot",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/ee402e02da742a4854400d7c64d55aee27c77645",
    "artist_name": "Gavin DeGraw",
    "image_url": "https://i.scdn.co/image/814d5847cb472e82330a5fe4775cdc97ea6b7cee",
    "artist_id": "5DYAABs8rkY9VhwtENoQCz",
    "year": 2005
  },
  {
    "song_id": "6Bo7elbKiRfaHBMJGEBHqF",
    "name": "Since U Been Gone",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/3447e1fc09df887a3c0b4ac20354084e4b68bcdd",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/245da235a63d0b2526732ad9c884fefeb033fc72",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2005
  },
  {
    "song_id": "1HwpWwa6bnqqRhK8agG4RS",
    "name": "Beautiful Soul",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/0d08122058c92cc6450971e8f88286acb0e04cd4",
    "artist_name": "Jesse McCartney",
    "image_url": "https://i.scdn.co/image/b29c0005db785550789caec11f394361a9edcd5e",
    "artist_id": "2Hjj68yyUPiC0HKEOigcEp",
    "year": 2005
  },
  {
    "song_id": "2wGSgTmgSF3xjRrHkTc25R",
    "name": "Hate It Or Love It",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/a3c466940a047c370b2211088188b79deab50ab7",
    "artist_name": "The Game",
    "image_url": "https://i.scdn.co/image/072aa7b2bc02406fe643894892578ebe7ce5f99a",
    "artist_id": "0NbfKEOTQCcwd6o7wSDOHI",
    "year": 2005
  },
  {
    "song_id": "2tVouQintSo2ffjVgnv6Sh",
    "name": "My Humps",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/6b36e6744924347b7327c850ec8dfbb2c62083a2",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/6e4cbaa35450a219f9fd2a9e54cd4bb48e6301fe",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2005
  },
  {
    "song_id": "2tD8PDBMlo9xTo3JC8kz90",
    "name": "Photograph",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/981d58a77c5f6919c85c138ed54222b1237e7b1d",
    "artist_name": "Nickelback",
    "image_url": "https://i.scdn.co/image/6c8bdc77156559d718ddb449a9536891d5c1ad61",
    "artist_id": "6deZN1bslXzeGvOLaLMOIF",
    "year": 2005
  },
  {
    "song_id": "5Y78yINSOCW6xbOfGWNBg4",
    "name": "Wait (The Whisper Song)",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/9455ad02efcb371054adf549daf74c2a3a9ab504",
    "artist_name": "Ying Yang Twins",
    "image_url": "https://i.scdn.co/image/0406bcd2cb1720e91ec7cf0376c3449d3a185886",
    "artist_id": "44PA0rCQXikgOWbfY7Fq7m",
    "year": 2005
  },
  {
    "song_id": "39qcvV4f0uqDMHxIkSb7tE",
    "name": "Rich Girl",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/01adf54500dc02e248ac792e680aa9afe819d5ce",
    "artist_name": "Gwen Stefani",
    "image_url": "https://i.scdn.co/image/c50178f2e66fdbd9ba76a6dd17f86e23c9e08d90",
    "artist_id": "4yiQZ8tQPux8cPriYMWUFP",
    "year": 2005
  },
  {
    "song_id": "0Ss50OU9tCozI7JIywkv14",
    "name": "Soul Survivor",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/a9a6f5310e01c5985c0f7e31c50c9a714f83ff0a",
    "artist_name": "Jeezy",
    "image_url": "https://i.scdn.co/image/94679d3011e7a8945b31acc7aceb2379eb9e26f8",
    "artist_id": "4yBK75WVCQXej1p04GWqxH",
    "year": 2005
  },
  {
    "song_id": "1OmxpKVSvn0LGcM3SQS4OV",
    "name": "I Just Had Sex",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/91ae70bbdb112f67f27005c40afa46c6db9ada74",
    "artist_name": "The Lonely Island",
    "image_url": "https://i.scdn.co/image/f8bfb12073003f3f5404544ec3668eb30ba8d7d2",
    "artist_id": "1f5GqyOPo0CkotzzRwviBu",
    "year": 2005
  },
  {
    "song_id": "6ZyNjT2hYvfV5PuW84dp1E",
    "name": "1 Thing",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/4fce584383161a67daeca23f447ad683f0f6c749",
    "artist_name": "Amerie",
    "image_url": "https://i.scdn.co/image/abba41ddb76fe6289f5b89f4d14d9fbb0344518c",
    "artist_id": "08rMCq2ek1YjdDBsCPVH2s",
    "year": 2005
  },
  {
    "song_id": "2vICzUUia22chV8mQKk1Pt",
    "name": "She Will Be Loved",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/63b5072eb574910deeec0dc072d1139319893c76",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/c8f808d8df9241e2352c31f2e31dd506d05a0107",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2005
  },
  {
    "song_id": "6Kh4FkETk9i3EUVTerZEuY",
    "name": "Incomplete",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/c7f2df18a24b070f12dd4ed84d4afdccba3f79ca",
    "artist_name": "Backstreet Boys",
    "image_url": "https://i.scdn.co/image/0129e28396b710a1fd108996aad21895b2bd6670",
    "artist_id": "5rSXSAkZ67PYJSvpUpkOr7",
    "year": 2005
  },
  {
    "song_id": "41PWz0hAiU9FqsmjR9Wh62",
    "name": "Lovers And Friends",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/e55ba513c68b7c043d07105ffe7fa99132ed366d",
    "artist_name": "Lil Jon & The East Side Boyz",
    "image_url": "https://i.scdn.co/image/d388f849dd4e4896600a2ad77ac21c52a6377d65",
    "artist_id": "3ciRvbBIVz9fBoPbtSYq4x",
    "year": 2005
  },
  {
    "song_id": "1j8iydbPBXcqOhNkJex7Vx",
    "name": "O",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/c89bda168c08639bda7fb2478a0ec041af00ba9f",
    "artist_name": "Omarion",
    "image_url": "https://i.scdn.co/image/dc79dd6dafff3f693c4f2cfeeba77ed72d614662",
    "artist_id": "0f5nVCcR06GX8Qikz0COtT",
    "year": 2005
  },
  {
    "song_id": "75fscz2R984pg4bmyP1Q53",
    "name": "Don't Phunk With My Heart",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/51389a29c2af990f313c37dca2d40ea70d30fc6d",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/6e4cbaa35450a219f9fd2a9e54cd4bb48e6301fe",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2005
  },
  {
    "song_id": "2n4uOdMHzEvcZ0KP7iQnay",
    "name": "Lose My Breath",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/7b7767b8c8d5f6df843b7baf99fb243bb382a3a4",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/48a771f5f78b64690c3aaae7fb0f9d5c2276d1b4",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 2005
  },
  {
    "song_id": "1JURww012QnWAw0zZXi6Aa",
    "name": "Just The Girl",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/d6738fc267d6bf7b83a9d9f513ebb3cef73d586a",
    "artist_name": "The Click Five",
    "image_url": "https://i.scdn.co/image/01fb5fd155076c9ccad85064c829d8065f5438c6",
    "artist_id": "01lz5VBfkMFDteSA9pKJuP",
    "year": 2005
  },
  {
    "song_id": "3Iw2G3jHDpcIjKAkkXQtr7",
    "name": "Behind These Hazel Eyes",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/154cf62ecc46e05394e5ff4af194fdc6403d55c7",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/245da235a63d0b2526732ad9c884fefeb033fc72",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2005
  },
  {
    "song_id": "4nUM7pGcTUK2pY1d2LybrT",
    "name": "Best Of You",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/bc0dd0ad334e6a923df463ff673679da3715886e",
    "artist_name": "Foo Fighters",
    "image_url": "https://i.scdn.co/image/ceddbfc0f0b490eda84ee13dcb9b22d1d3d908d5",
    "artist_id": "7jy3rLJdDQY21OgRLCZ9sD",
    "year": 2005
  },
  {
    "song_id": "1yroKBwU3gJEeGq1qnxYJO",
    "name": "Scars - Acoustic Version",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/4082536f9a30b1292c57cc827110e4de67a7bfd9",
    "artist_name": "Papa Roach",
    "image_url": "https://i.scdn.co/image/dc27000b3ff5610a0ee1ab44c5a7c05856aa9f63",
    "artist_id": "4RddZ3iHvSpGV4dvATac9X",
    "year": 2005
  },
  {
    "song_id": "0UaMYEvWZi0ZqiDOoHU3YI",
    "name": "Lose Control (feat. Ciara & Fat Man Scoop)",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/d79e811aea8ab84baa3482bb06c0a9eb1e56d978",
    "artist_name": "Missy Elliott",
    "image_url": "https://i.scdn.co/image/755a1b7bd26b7ffbd27bb42bbad9149d810fac95",
    "artist_id": "2wIVse2owClT7go1WT98tk",
    "year": 2005
  },
  {
    "song_id": "7i7UIbm5E0DD7aSOYvwp2v",
    "name": "Oh",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/69688da542534c42856252fc6bb788aa38357a96",
    "artist_name": "Ciara",
    "image_url": "https://i.scdn.co/image/e95de79ad972298abd2dd416174592e1023b88c7",
    "artist_id": "2NdeV5rLm47xAvogXrYhJX",
    "year": 2005
  },
  {
    "song_id": "4rvSqlJCW4CvLFPRF9YH4c",
    "name": "Like You",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/432150d0571dff669ae276fd72533204f6a5f6fc",
    "artist_name": "Bow Wow",
    "image_url": "https://i.scdn.co/image/2666c2034204d1cf3b0af7561243679de104c8f0",
    "artist_id": "7352aRY2mqSxBZwzUb6LmA",
    "year": 2005
  },
  {
    "song_id": "0ahRDzkKq0KEi7FRoHb0qk",
    "name": "U Already Know",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/46e3e8b59ff93f549720d50e68e69929db7a4396",
    "artist_name": "112",
    "image_url": "https://i.scdn.co/image/adfb82e95e43f62746b4c109fba5645972d74d2d",
    "artist_id": "7urq0VfqxEYEEiZUkebXT4",
    "year": 2005
  },
  {
    "song_id": "1ILtQzEsKALgFVVlbeqCGS",
    "name": "Grind With Me - Amended Version",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/03a48b0fb82eab890d76c758e412e12083e663b8",
    "artist_name": "Pretty Ricky",
    "image_url": "https://i.scdn.co/image/a569a53a41e790cc9287ca9b342b8ba158f33604",
    "artist_id": "6XwcepfAsPhrvwziGxhwju",
    "year": 2005
  },
  {
    "song_id": "5KjhrsPiaDtefxGFfTUmaO",
    "name": "As Good As I Once Was",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/75478fb7ded0809527c1784173d6de9857cd2690",
    "artist_name": "Toby Keith",
    "image_url": "https://i.scdn.co/image/99b8439bf9f3c482fb8a10533afb07a7e43439c3",
    "artist_id": "2bA6fzP0lMAQ4kz6CF61w8",
    "year": 2005
  },
  {
    "song_id": "6VixoRqnikN4QqegoKUSbH",
    "name": "Pimpin' All Over The World",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/e7b210eb95d0880795e4c7a34014c3912188430d",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/c47b2acebc117bf6d0754032fac9c3f59ff5f8dc",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2005
  },
  {
    "song_id": "13oiYgOtpjRa4kWxE04jHC",
    "name": "Just A Lil Bit",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/d09f0b28546f4a8dd38eab5fb21871a72f83bc9b",
    "artist_name": "50 Cent",
    "image_url": "https://i.scdn.co/image/a7b274f8fb7c2854745a5d63f212b54ecc8f9e3d",
    "artist_id": "3q7HBObVc0L8jNeTe5Gofh",
    "year": 2005
  },
  {
    "song_id": "24tVLjWlmZo5rAuyMp1pxo",
    "name": "Don't Lie",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/6cdb07b7ce78ec692b6f56d88efab0b06e914a3c",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/6e4cbaa35450a219f9fd2a9e54cd4bb48e6301fe",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2005
  },
  {
    "song_id": "294DpgHLbYJBL8jFUUBvWZ",
    "name": "Daughters",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/110dd8d1dbfdb90871f036c9d5eb079801fb76ba",
    "artist_name": "John Mayer",
    "image_url": "https://i.scdn.co/image/2f1715aa49e647658a8a7aeedd664cda0ddad3d2",
    "artist_id": "0hEurMDQu99nJRq8pTxO14",
    "year": 2005
  },
  {
    "song_id": "588RGyR1f4Plc8nm7SrdCa",
    "name": "Karma",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/4249f63bf3428dcaa67b9a820de0fe4dd2459e18",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/e2ae18bcfdf91acfb321e01a0f0e80bd8e27d9f8",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2005
  },
  {
    "song_id": "3QfsB9tPwAjMb5b9P7r3Z5",
    "name": "Truth Is",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/d0927ebb4dd0419d1ab9d27168a0323a12422caa",
    "artist_name": "Fantasia",
    "image_url": "https://i.scdn.co/image/72a4c9c7c2882714eeb6318a5779448dfb87828f",
    "artist_id": "7xAcVHPiirnUqfdqo0USb1",
    "year": 2005
  },
  {
    "song_id": "6RbNxKgjvKs8SgW5dS4pI6",
    "name": "Don't Cha",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/bfbe5006b34cb32815dbba60d5fbb9f9c05429b0",
    "artist_name": "The Pussycat Dolls",
    "image_url": "https://i.scdn.co/image/129e46edda23c3b31624fdc8fba88af2e87d5e08",
    "artist_id": "6wPhSqRtPu1UhRCDX5yaDJ",
    "year": 2005
  },
  {
    "song_id": "71mDjdeVK9sqXmamMHaAfX",
    "name": "Some Cut - feat. Cutty Explicit Version",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/a4c0a5997c79a7c96ec90a5e9a386152a7d2e653",
    "artist_name": "Trillville",
    "image_url": "https://i.scdn.co/image/1ac6e90ca115d908f786f1697543818e1685ad20",
    "artist_id": "3jahzTuztFVegfKXChatkY",
    "year": 2005
  },
  {
    "song_id": "0jdeV5dSB3kUBRqe1xQJbh",
    "name": "True",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/131188811e392715733637f1f86e6112aa32a3c4",
    "artist_name": "Ryan Cabrera",
    "image_url": "https://i.scdn.co/image/0ebdf242cbd0045bc6806600a620b9569d3d18a9",
    "artist_id": "3C4t1usFGoKHCSRdujm2GN",
    "year": 2005
  },
  {
    "song_id": "3VBLBcal4M8BP8XV3l8QDD",
    "name": "Baby, I'm Back",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/8e7f26bc550c36494bd32164462baf55094912a9",
    "artist_name": "Baby Bash",
    "image_url": "https://i.scdn.co/image/4a5966a8037003610a614cda0cc97769318f2841",
    "artist_id": "12PSlydMSjEHzSCj9X5qv7",
    "year": 2005
  },
  {
    "song_id": "0MGxfGHgECCGU5xqHD1uc8",
    "name": "Shake It Off",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/5d8a61c7d28de4edcd21e99e953c93d6349b0df4",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/8390a25dd3f26c12e1b8f251df0ba11b1fcd532e",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 2005
  },
  {
    "song_id": "2FLi5qKmz0tgiSBPvW1jKg",
    "name": "Slow Down - 12\" Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/4c5c993d614b37d7e7762d7163f8a7b18d84ece3",
    "artist_name": "Bobby V.",
    "image_url": "https://i.scdn.co/image/575e252d5f3a006ccad7277ce9fca49625c91e1e",
    "artist_id": "4HgF4KnohByNElYid7iCNb",
    "year": 2005
  },
  {
    "song_id": "0AwL1CLIfKxguVKMb8eQoV",
    "name": "Your Body - Amended Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/0e7484a023007774cf1fe1a1f4ad2acf76ad0741",
    "artist_name": "Pretty Ricky",
    "image_url": "https://i.scdn.co/image/a569a53a41e790cc9287ca9b342b8ba158f33604",
    "artist_id": "6XwcepfAsPhrvwziGxhwju",
    "year": 2005
  },
  {
    "song_id": "5h3JktAM1OCwoKm4KxS9pk",
    "name": "I'm Sprung - Original",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/a0ca9619715ba9735eaf3bfc09515b64b27a8ba1",
    "artist_name": "T-Pain",
    "image_url": "https://i.scdn.co/image/32870ad2b68b73d20821a5a55edb7c6132bbb58d",
    "artist_id": "3aQeKQSyrW4qWr35idm0cy",
    "year": 2005
  },
  {
    "song_id": "79YeQbd1p7MsuT7wHQLRYY",
    "name": "Mississippi Girl",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/474eafd12ddb7f58598d00b9d0422a8fb46768e7",
    "artist_name": "Faith Hill",
    "image_url": "https://i.scdn.co/image/2f8168e0fcb08662eb60b55435c36de432a9fbac",
    "artist_id": "25NQNriVT2YbSW80ILRWJa",
    "year": 2005
  },
  {
    "song_id": "3JTzGbTpfCoczeRBAgUAWt",
    "name": "Bring Em Out - Amended",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/dc056439c458523a671793998863de1bd075acba",
    "artist_name": "T.I.",
    "image_url": "https://i.scdn.co/image/08d8dec88c92981d99bf9c239accc87d0d224418",
    "artist_id": "4OBJLual30L7gRl5UkeRcT",
    "year": 2005
  },
  {
    "song_id": "62thQWVLBfguFm1MM4iQjP",
    "name": "Cool",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/4f08a2d726558d29c14683f2d22932ed3ab9ae31",
    "artist_name": "Gwen Stefani",
    "image_url": "https://i.scdn.co/image/c8f808d8df9241e2352c31f2e31dd506d05a0107",
    "artist_id": "4yiQZ8tQPux8cPriYMWUFP",
    "year": 2005
  },
  {
    "song_id": "1CDjORoMxLHFURZhH8n0TS",
    "name": "Drop It Like It's Hot",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/41b8ef9353a194f3cdf38cf6ef4814694c2f4ea6",
    "artist_name": "Snoop Dogg",
    "image_url": "https://i.scdn.co/image/39a8b885c205d0cb479fd4af0415a96226444986",
    "artist_id": "7hJcb9fa4alzcOq3EaNPoG",
    "year": 2005
  },
  {
    "song_id": "3w8xlZi49MQuQkyyB5bi5e",
    "name": "Inside Your Heaven",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/a6b95236e149ca7846dd2eee98d227ceae352b2e",
    "artist_name": "Carrie Underwood",
    "image_url": "https://i.scdn.co/image/37dfb225eaa304fbf35801a7073336417381fb84",
    "artist_id": "4xFUf1FHVy696Q1JQZMTRj",
    "year": 2005
  },
  {
    "song_id": "2mGFZPUsk6e8Zjd61jaXdI",
    "name": "Hollaback Girl",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/a2447ab84e3ade13018d571df99c2ee0f3633ada",
    "artist_name": "Gwen Stefani",
    "image_url": "https://i.scdn.co/image/90954977c5db4139d2797a5fa80d10ef521e70fd",
    "artist_id": "4yiQZ8tQPux8cPriYMWUFP",
    "year": 2005
  },
  {
    "song_id": "3zdDxhDo5SycC7XSAPfdjv",
    "name": "Get It Poppin'",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/9e4c5659ad88612115032ab20fa41b91739c164b",
    "artist_name": "Fat Joe",
    "image_url": "https://i.scdn.co/image/d7adab886d4c29ef5900dc7fa22f41fa20cc4a91",
    "artist_id": "3ScY9CQxNLQei8Umvpx5g6",
    "year": 2005
  },
  {
    "song_id": "02iimT2BIX1psZjJHIldd4",
    "name": "Get Right",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/1b71c5ab41b748ebd47e9f6566b043adf913cb77",
    "artist_name": "Jennifer Lopez",
    "image_url": "https://i.scdn.co/image/6feb6df340188dd051e4d2f94ca98bcd8afde712",
    "artist_id": "2DlGxzQSjYe5N6G9nkYghR",
    "year": 2005
  },
  {
    "song_id": "0MtFUqaaEIqImt50RgfqZ4",
    "name": "We Belong Together",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/1775e5239fdcfdf89037868df56046a54f9520aa",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/175061346d806b353767facea9a8899473c8dd1c",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 2005
  },
  {
    "song_id": "5D2mYZuzcgjpchVY1pmTPh",
    "name": "Candy Shop",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/e701346f1161cacf6cc87ada6e050e14de80f06c",
    "artist_name": "50 Cent",
    "image_url": "https://i.scdn.co/image/9ebb9d5c49bc042ed2946e3b9b7cbf598314e716",
    "artist_id": "3q7HBObVc0L8jNeTe5Gofh",
    "year": 2005
  },
  {
    "song_id": "6dWNtdXVsT4t5oqN4rpN2U",
    "name": "Outta Control",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/323b8f46b082db85d270097ebf4a00400a290ecd",
    "artist_name": "50 Cent",
    "image_url": "https://i.scdn.co/image/9ebb9d5c49bc042ed2946e3b9b7cbf598314e716",
    "artist_id": "3q7HBObVc0L8jNeTe5Gofh",
    "year": 2005
  },
  {
    "song_id": "0FZvjrHpAmLKj574M4VwrF",
    "name": "Cater 2 U",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/91bd532b59b5a79097140c666ffd50c3a95fe677",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/48a771f5f78b64690c3aaae7fb0f9d5c2276d1b4",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 2005
  },
  {
    "song_id": "6e5kjwABBy57SPFnGocfRH",
    "name": "Back Then - Amended Version",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/11dfb6731cdb36569c25025bddb67e0e7c1ee133",
    "artist_name": "Mike Jones",
    "image_url": "https://i.scdn.co/image/7ea2b12e79eaa96a41ebaf3767d247e649b8aee8",
    "artist_id": "07VmOvmuBp9G0gb8BTrpn0",
    "year": 2005
  },
  {
    "song_id": "3FaS5pwUOfrhr366NERG5X",
    "name": "Disco Inferno - Album Version (Edited)",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/f69a2f3f41a959a49792abbba47f7dec3b8360b5",
    "artist_name": "50 Cent",
    "image_url": "https://i.scdn.co/image/6aa860ad6e2c0d48b17a9af4be0779724b7a57eb",
    "artist_id": "3q7HBObVc0L8jNeTe5Gofh",
    "year": 2005
  },
  {
    "song_id": "6JnlWnDLpweWHrGOjBQJEB",
    "name": "How We Do",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/049f1bd3b9449523a710c4d298ef8f0e9c9c757c",
    "artist_name": "The Game",
    "image_url": "https://i.scdn.co/image/a0acc778e4aa6c801aa5b51f05ae5b9e135c04fd",
    "artist_id": "0NbfKEOTQCcwd6o7wSDOHI",
    "year": 2005
  },
  {
    "song_id": "3O1kLXCskDrzo9rIYweSVA",
    "name": "Switch",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/69c1920c08eb943521dcefc7150cd77f587c87a3",
    "artist_name": "Will Smith",
    "image_url": "https://i.scdn.co/image/575e252d5f3a006ccad7277ce9fca49625c91e1e",
    "artist_id": "41qil2VaGbD194gaEcmmyx",
    "year": 2005
  },
  {
    "song_id": "5lXn5jltHYohkBphDxYsHs",
    "name": "Let Me Go - Acoustic Version",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/c1ebc4ce8f93da83cc6d2cad2a51231ca4848411",
    "artist_name": "3 Doors Down",
    "image_url": "https://i.scdn.co/image/7f9b5f0ca0b2801bcf18e4a66f3b64d1234f815d",
    "artist_id": "2RTUTCvo6onsAnheUk3aL9",
    "year": 2005
  },
  {
    "song_id": "2b8QQqf8Vwb7bfz22ja8Fk",
    "name": "Over And Over - Album Version / Explicit",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/a60327ad5ff8588504803c518aa19f57d85120d4",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/ee74c0b97e06e4b756fc266f95976cb5a7f1c5f9",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2005
  },
  {
    "song_id": "61gdV080ZA5U16H2L1aTRz",
    "name": "Play",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/2027a5128fcc312948ccc59af57c7c6f1bb53419",
    "artist_name": "David Banner",
    "image_url": "https://i.scdn.co/image/ff6878a1aa499ff8177d23b056c7a3ed720bf64b",
    "artist_id": "7jFaoqWPhYLrKzjzlpXmUO",
    "year": 2005
  },
  {
    "song_id": "3lapBUarXrq3pEUKMsHNFT",
    "name": "U Don't Know Me",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/4e1f018a665d4cb6141f9c12f07e277511cbaf0f",
    "artist_name": "T.I.",
    "image_url": "https://i.scdn.co/image/08d8dec88c92981d99bf9c239accc87d0d224418",
    "artist_id": "4OBJLual30L7gRl5UkeRcT",
    "year": 2005
  },
  {
    "song_id": "6qEl8WN6jluCFpzufVl38D",
    "name": "Goin' Crazy",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/cd636c765b249bb062eb4aadb644fe6e8e1fd05a",
    "artist_name": "Natalie",
    "image_url": "https://i.scdn.co/image/708a2f4f8f8223b405a9e94c624ce60c9b8672e0",
    "artist_id": "4lFOyLUXnV2Emuus9X8xpA",
    "year": 2005
  },
  {
    "song_id": "2rtBy1uuf8ktw8dtzJs9D1",
    "name": "Soldier",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/47ef05a2fe5bb54e5217fb0960e16f13291e1024",
    "artist_name": "Destiny's Child",
    "image_url": "https://i.scdn.co/image/b749599771694718a6166d5ff28ffeeea76f1c2c",
    "artist_id": "1Y8cdNmUJH7yBTd9yOvr5i",
    "year": 2005
  },
  {
    "song_id": "28hcjKZ9xAXZ63ZzwceQAe",
    "name": "Let Me Hold You",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/2363beb5c189f9ac067a46f2b6d57318ee9fb2fe",
    "artist_name": "Bow Wow",
    "image_url": "https://i.scdn.co/image/38cc004b3729e53b7f24b165bd94a496c871dc02",
    "artist_id": "7352aRY2mqSxBZwzUb6LmA",
    "year": 2005
  },
  {
    "song_id": "4fB1fCSwu5CVRms3BWxkvT",
    "name": "Lonely No More",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/09173bd12f76cd84cd6b26d20e8420a3921655d3",
    "artist_name": "Rob Thomas",
    "image_url": "https://i.scdn.co/image/1cdfc0c8ce8167e303b039c151e80ec473e62c57",
    "artist_id": "3aBkeBhwadnWMWoVJ2CxJC",
    "year": 2005
  },
  {
    "song_id": "4tUYhIIjUoL1i41fqZaaBM",
    "name": "Beverly Hills",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/33f5038f4c18e33e3c6ede44bd50111cc5b9ea28",
    "artist_name": "Weezer",
    "image_url": "https://i.scdn.co/image/f031938a250d6d1fa5b5bc3747494b5080baf64a",
    "artist_id": "3jOstUTkEu2JkjvRdBA5Gu",
    "year": 2005
  },
  {
    "song_id": "4Y5g6UDNapKOlt12WMkREG",
    "name": "It's Like That",
    "popularity": 25,
    "preview_url": "https://p.scdn.co/mp3-preview/afb7e583e3f54d1f19af549465b4f6010f4dc596",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/9fa61225235909065e9312a17cd07ed265f00ba5",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 2005
  },
  {
    "song_id": "1HdvFDMTNOcwyKjdUK44KN",
    "name": "Gold Digger - Originally Performed By Kanye West and Jamie Foxx",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/a0509c6fe73f57b19dbc5aa9f8ed8b5d886dcfdf",
    "artist_name": "Sing Karaoke Sing",
    "image_url": "https://i.scdn.co/image/d58158dc9b6f126fd0c1cdb91eb9f95abdc39afc",
    "artist_id": "2bzICNq3gKanSdWuzTF9VS",
    "year": 2005
  },
  {
    "song_id": "75y56kC0qGLuOvNj2VY6N9",
    "name": "La Tortura (Made Famous by Shakira and Alejandro Sanz)",
    "popularity": 10,
    "preview_url": "https://p.scdn.co/mp3-preview/4a82a3e0b3ee5a73681336eb11c39c0825030b7e",
    "artist_name": "Icons Of Latin Pop",
    "image_url": "https://i.scdn.co/image/c74719fb2c039db2dacf99d6b90c298955ebd08f",
    "artist_id": "3m3rxBxMWtUeM8WH0rGP5m",
    "year": 2005
  },
  {
    "song_id": "6Vf3BiEWYk3g42K6pcTbmg",
    "name": "Numb,Encore In the style of Jay-Z and Linkin Park",
    "popularity": 8,
    "preview_url": "https://p.scdn.co/mp3-preview/6b95530e6725ab4df866ff1c47085fe35b3bf0b8",
    "artist_name": "Sunfly Karaoke",
    "image_url": "https://i.scdn.co/image/7b485efd2b45629fb0e0ac36d36344f60ee97e40",
    "artist_id": "3tCGqGQclabrpQfcHRmDtK",
    "year": 2005
  },
  {
    "song_id": "7j87H1gMwcz6TYU6kF5yY1",
    "name": "Run It!",
    "popularity": 4,
    "preview_url": "https://p.scdn.co/mp3-preview/9771355485ec4fa708df7cec39a5ead431292de1",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/1e220599dc21be7227b602c50ed50e3f517aaa49",
    "artist_id": "0CfpZBbSZ7gkKOy80SVwrN",
    "year": 2005
  },
  {
    "song_id": "5fVZC9GiM4e8vu99W0Xf6J",
    "name": "How to Save a Life - New Album Version",
    "popularity": 80,
    "preview_url": "https://p.scdn.co/mp3-preview/9f5bcac28b42a9813dd9cf24838ed80bc449aaaf",
    "artist_name": "The Fray",
    "image_url": "https://i.scdn.co/image/554162e6fe5ae6ada9ec418113ad176ef6bd8de3",
    "artist_id": "0zOcE3mg9nS6l3yxt1Y0bK",
    "year": 2006
  },
  {
    "song_id": "0d28khcov6AiegSCpG5TuT",
    "name": "Feel Good Inc",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/67c4a09cdb95529efc4eabcc11de337cabc9c3a8",
    "artist_name": "Gorillaz",
    "image_url": "https://i.scdn.co/image/b7db62f7b4c5c3baff08ad876f41e0b8662206be",
    "artist_id": "3AA28KZvwAUcZuOKwyblJQ",
    "year": 2006
  },
  {
    "song_id": "0vg4WnUWvze6pBOJDTq99k",
    "name": "You're Beautiful",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/e4aa8818fe52b932fd79ddbad21ddabc02409c0d",
    "artist_name": "James Blunt",
    "image_url": "https://i.scdn.co/image/7710e11f09e24840ab1b6a6a49f5cacbc4689367",
    "artist_id": "7KMqksf0UMdyA0UCf4R3ux",
    "year": 2006
  },
  {
    "song_id": "0O45fw2L5vsWpdsOdXwNAR",
    "name": "SexyBack",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/c75b7d307d92886984ea4f9d6083112a21e1fbc9",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/cc738b63aa61812950829aea52e173ff527784b5",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2006
  },
  {
    "song_id": "4bPQs0PHn4xbipzdPfn6du",
    "name": "I Write Sins Not Tragedies",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/86844bfd565cf8341b5974ade47b27940a6b0b13",
    "artist_name": "Panic! At The Disco",
    "image_url": "https://i.scdn.co/image/4a0fed07d8c7fa793c4c014c4478c17bbab5fc91",
    "artist_id": "20JZFwl6HVl6yg8a4H3ZqK",
    "year": 2006
  },
  {
    "song_id": "1PS1QMdUqOal0ai3Gt7sDQ",
    "name": "Gold Digger",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/145cff69cbb53297b15296cb7b8f72b6ed95f8d5",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/901a0af6765c05a59e8bd4d203945e340628a9dd",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2006
  },
  {
    "song_id": "2TfSHkHiFO4gRztVIkggkE",
    "name": "Sugar, We're Goin Down",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/acd665b0f415de4f1ae0b425e8399f4f42107058",
    "artist_name": "Fall Out Boy",
    "image_url": "https://i.scdn.co/image/2c92816cf81bcfc71e00ceca25f94656a0d1ecb2",
    "artist_id": "4UXqAaa6dQYAk18Lv7PEgX",
    "year": 2006
  },
  {
    "song_id": "11bD1JtSjlIgKgZG2134DZ",
    "name": "Chasing Cars",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/c50076baf3525de6a457903ac1a9934e4b275789",
    "artist_name": "Snow Patrol",
    "image_url": "https://i.scdn.co/image/e29b9a288d12719ee6a762f5ddc5ad78b2346050",
    "artist_id": "3rIZMv9rysU7JkLzEaC5Jp",
    "year": 2006
  },
  {
    "song_id": "0k2GOhqsrxDTAbFFSdNJjT",
    "name": "Temperature",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/6c159d782139812b31d8d9b41048752d6c514dcf",
    "artist_name": "Sean Paul",
    "image_url": "https://i.scdn.co/image/244013ca80cb50dcdd73acb1b942147f92e55283",
    "artist_id": "3Isy6kedDrgPYoTS1dazA9",
    "year": 2006
  },
  {
    "song_id": "2N5zMZX7YeL1tico8oQxa9",
    "name": "Crazy",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/b45bdf0be9f0efab8e1ce9e1af33231d64721819",
    "artist_name": "Gnarls Barkley",
    "image_url": "https://i.scdn.co/image/f28ec0cdaeac4a5b5e08b53a25ff9442f08b2b1e",
    "artist_id": "5SbkVQYYzlw1kte75QIabH",
    "year": 2006
  },
  {
    "song_id": "7k6IzwMGpxnRghE7YosnXT",
    "name": "Me & U",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/74545f18cd8aab0433369dce63da0c6853a84cac",
    "artist_name": "Cassie",
    "image_url": "https://i.scdn.co/image/515d00e59a4bc3154addb4c7dde1c16782824869",
    "artist_id": "27FGXRNruFoOdf1vP8dqcH",
    "year": 2006
  },
  {
    "song_id": "13X42np3KJr0o2LkK1MG76",
    "name": "My Love",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/9bf23b9b2f0ada4fb03ccbd7e50c8f25a331ad35",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/151931e02746e16f64aad4a2b664e59c71578c84",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2006
  },
  {
    "song_id": "0a7BloCiNzLDD9qSQHh5m7",
    "name": "Dance, Dance",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/8e86e5721d870d3dc7c09c3f8d6562d0509082d3",
    "artist_name": "Fall Out Boy",
    "image_url": "https://i.scdn.co/image/2c92816cf81bcfc71e00ceca25f94656a0d1ecb2",
    "artist_id": "4UXqAaa6dQYAk18Lv7PEgX",
    "year": 2006
  },
  {
    "song_id": "6c8QH9FMFKj0ZgNeiyOx4v",
    "name": "Over My Head (Cable Car)",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/2b13ee8cf474271d7cf8f6e51bd703df8f74829f",
    "artist_name": "The Fray",
    "image_url": "https://i.scdn.co/image/dd074fd8d7400bea3738344fbe6150aa07c0b4d8",
    "artist_id": "0zOcE3mg9nS6l3yxt1Y0bK",
    "year": 2006
  },
  {
    "song_id": "4bVuIlGQBMWS7vIhcx8Ae4",
    "name": "What Hurts The Most",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/c91d3df6fb653480938eccd3b68b1399f4da2343",
    "artist_name": "Rascal Flatts",
    "image_url": "https://i.scdn.co/image/de0a732b661aa4f41d2dc483ad5440c496e5ff6d",
    "artist_id": "0a1gHP0HAqALbEyxaD5Ngn",
    "year": 2006
  },
  {
    "song_id": "6brl7bwOHmGFkNw3MBqssT",
    "name": "So Sick",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/87119ccd3139e1e448c0bf6d63057fedac8533c5",
    "artist_name": "Ne-Yo",
    "image_url": "https://i.scdn.co/image/fa8a567fb5a27d7f823ed2d6f91275ed65e748cb",
    "artist_id": "21E3waRsmPlU7jZsS13rcj",
    "year": 2006
  },
  {
    "song_id": "2l57cfmCnOkwNX1tky02n1",
    "name": "Move Along",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/1003b86c6615384cc5ffb2e8d1ab1d7b840ca832",
    "artist_name": "The All-American Rejects",
    "image_url": "https://i.scdn.co/image/93a2d82094c9af6d4a2fd0b1d35e386f8a1499bf",
    "artist_id": "3vAaWhdBR38Q02ohXqaNHT",
    "year": 2006
  },
  {
    "song_id": "5lDriBxJd22IhOH9zTcFrV",
    "name": "Dirty Little Secret",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/026f8fc1a028ee2f1806086eda8238422b62898b",
    "artist_name": "The All-American Rejects",
    "image_url": "https://i.scdn.co/image/93a2d82094c9af6d4a2fd0b1d35e386f8a1499bf",
    "artist_id": "3vAaWhdBR38Q02ohXqaNHT",
    "year": 2006
  },
  {
    "song_id": "7L605WhF5EGf34ggj87yK6",
    "name": "You And Me",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/41f0509a1ac110b6705f89398f410401d51adafa",
    "artist_name": "Lifehouse",
    "image_url": "https://i.scdn.co/image/ebeee9b3101014e9450db0256e201a409df5de4c",
    "artist_id": "5PokPZn11xzZXyXSfnvIM3",
    "year": 2006
  },
  {
    "song_id": "6o3s08kk2fQI37vxGZDrJ1",
    "name": "Snap Yo Fingers",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/2801d6839b8b7356e301d1838805af16f8819d5d",
    "artist_name": "Lil Jon",
    "image_url": "https://i.scdn.co/image/868844a5a4a1943bcffbfa1bd59a7ce5dee1ce01",
    "artist_id": "7sfl4Xt5KmfyDs2T3SVSMK",
    "year": 2006
  },
  {
    "song_id": "40LQiUUUKXVGyNs09lHVjW",
    "name": "Lips Of An Angel",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/4f409cf19adf06df714c63d0328fd3e4318970b9",
    "artist_name": "Hinder",
    "image_url": "https://i.scdn.co/image/053f3a20cffb24f2545e1c2e42e97c5f414a1f31",
    "artist_id": "6BMhCQJYHxxKAeqYS1p5rY",
    "year": 2006
  },
  {
    "song_id": "6A71EmfdTryZxflaVpuXy6",
    "name": "What You Know",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/955a44da69de217c95bb3c56c6265fda931d6222",
    "artist_name": "T.I.",
    "image_url": "https://i.scdn.co/image/e309f036713b0d6e86d61422a7b43078382ffae5",
    "artist_id": "4OBJLual30L7gRl5UkeRcT",
    "year": 2006
  },
  {
    "song_id": "6btyEL6NwUa97Nex9cZFvo",
    "name": "Pump It",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/6a18e770e13e313528b18863d65b23088e7c6342",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/97ec40da20dce5649037d355f7f4b674fd02a84e",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2006
  },
  {
    "song_id": "10aWGOqSDBqvNzJ9NeKDbK",
    "name": "Sexy Love",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/0e58d31c2c2eed01cbfe0ad5e1e63c4d10eaad7a",
    "artist_name": "Ne-Yo",
    "image_url": "https://i.scdn.co/image/fa8a567fb5a27d7f823ed2d6f91275ed65e748cb",
    "artist_id": "21E3waRsmPlU7jZsS13rcj",
    "year": 2006
  },
  {
    "song_id": "3lec3CzDPAxsZokPph5w87",
    "name": "Jesus, Take the Wheel",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/4cae89ea88517718bf86cbb31c4cf3b4cd78f3ab",
    "artist_name": "Carrie Underwood",
    "image_url": "https://i.scdn.co/image/37dfb225eaa304fbf35801a7073336417381fb84",
    "artist_id": "4xFUf1FHVy696Q1JQZMTRj",
    "year": 2006
  },
  {
    "song_id": "7huo2wvrCgRucUsjdSDLQV",
    "name": "Ain't No Other Man",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/2883f437171f4ab88e2b9fccb02c89b98639acb8",
    "artist_name": "Christina Aguilera",
    "image_url": "https://i.scdn.co/image/a97601d16fe111fa3d6ca3fabcccf8cf6240cf52",
    "artist_id": "1l7ZsJRRS8wlW3WfJfPfNS",
    "year": 2006
  },
  {
    "song_id": "6EEeAog1VBZ6FrYpJ2q3WF",
    "name": "Too Little, Too Late",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/a08dbafe72d5db39e12030dfb769f87c06d21c5e",
    "artist_name": "JoJo",
    "image_url": "https://i.scdn.co/image/6671ac0206646ed98f30db8d934f7a56056e4cd8",
    "artist_id": "5xuNBZoM7z1Vv8IQ6uM0p6",
    "year": 2006
  },
  {
    "song_id": "70VjMtwhm3Q2HBQeJnPfmh",
    "name": "Hung Up",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/201bd08d8cdf147fdb7f4fd1183056484e056f3e",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 2006
  },
  {
    "song_id": "5x45ULRgVLL68exMoRCcGt",
    "name": "Unwritten",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/9037759c7e628b1a39d5c3ea366f2d16adaef42d",
    "artist_name": "Natasha Bedingfield",
    "image_url": "https://i.scdn.co/image/8bb6692378f9d2026f450e56e9c99320ac28701d",
    "artist_id": "7o95ZoZt5ZYn31e9z1Hc0a",
    "year": 2006
  },
  {
    "song_id": "3BxWKCI06eQ5Od8TY2JBeA",
    "name": "Buttons",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/043917608465ef0660fe0233bdbf7270f36e3e5e",
    "artist_name": "The Pussycat Dolls",
    "image_url": "https://i.scdn.co/image/c6963993e632c62ba1cdcb84deedccb33d73df99",
    "artist_id": "6wPhSqRtPu1UhRCDX5yaDJ",
    "year": 2006
  },
  {
    "song_id": "13xxBnXOuiBxVxJI458B0i",
    "name": "Unfaithful",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/f97c9bdafcea169b7cc9f83da62f70ccd2d5a77b",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/d1550f4e7d53aefdbe8a32f9798f7ad66b931cea",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2006
  },
  {
    "song_id": "19JdbdEwQI7bCxefMokZO8",
    "name": "My Humps",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/43b15edf09b24c9a2ae8201f89aee7d30fd1b9ab",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/97ec40da20dce5649037d355f7f4b674fd02a84e",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2006
  },
  {
    "song_id": "1zpu1PZ8ecmcX525Z3X8cl",
    "name": "Yo (Excuse Me Miss) - Main",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/02de6c6816e4053b91fbfe059825bc934648304f",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/e91a78eabf4491742fba2a31b0652ea874c0027a",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2006
  },
  {
    "song_id": "5YbeJyTQkdSAWe1Ie4sLAl",
    "name": "Life is a Highway",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/586f9b8491abfb4b340e8ab0a980467bdc74399a",
    "artist_name": "Rascal Flatts",
    "image_url": "https://i.scdn.co/image/56c20eb89c543564c29e44c9d70db0f92f2ebc40",
    "artist_id": "0a1gHP0HAqALbEyxaD5Ngn",
    "year": 2006
  },
  {
    "song_id": "0Ss50OU9tCozI7JIywkv14",
    "name": "Soul Survivor",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/a9a6f5310e01c5985c0f7e31c50c9a714f83ff0a",
    "artist_name": "Jeezy",
    "image_url": "https://i.scdn.co/image/94679d3011e7a8945b31acc7aceb2379eb9e26f8",
    "artist_id": "4yBK75WVCQXej1p04GWqxH",
    "year": 2006
  },
  {
    "song_id": "5FGs2enRe23RODlTmGYbX6",
    "name": "Stay Fly - Explicit Album Version",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/310dcb55135fd1a3407627c5b2900fd992562e59",
    "artist_name": "Three 6 Mafia",
    "image_url": "https://i.scdn.co/image/f08370930c62bb5514a4d041f0b82370f0530890",
    "artist_id": "26s8LSolLfCIY88ysQbIuT",
    "year": 2006
  },
  {
    "song_id": "6SPD3JwwRejBhCDiQAQ4he",
    "name": "Run It!",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/c9a934dd10ad8a48a1d1a40acdc8389382eb70ec",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/e91a78eabf4491742fba2a31b0652ea874c0027a",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2006
  },
  {
    "song_id": "75KdMdPqZGO3FGNtpByM1p",
    "name": "Say Goodbye",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/049b9d3d0d8007ecc98031932db54797f906f797",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/e91a78eabf4491742fba2a31b0652ea874c0027a",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2006
  },
  {
    "song_id": "0k6HUzaRHpQ3eEWr1C7Esh",
    "name": "Ms. New Booty - feat. Ying Yang Twins and Mr. ColliPark",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/94a6fecc569ee20ae2676a292cb11b5096e26a53",
    "artist_name": "Bubba Sparxxx",
    "image_url": "https://i.scdn.co/image/b17cc066f2fbe3552e9661448a6cd68a8267cf3a",
    "artist_id": "3RPYHNSwe3w0mtfJwKIN9P",
    "year": 2006
  },
  {
    "song_id": "423EcxblW9F4nnQkqcqMlK",
    "name": "Deja Vu",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/37d76fa60776cade596fa294e7d8f48894d451fd",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/5c58c8a08cbb43f26a56b58f8461c74f892103e8",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2006
  },
  {
    "song_id": "2YegxR5As7BeQuVp2U6pek",
    "name": "Be Without You",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/26b4274558065641e63ceec864390e333b63d56d",
    "artist_name": "Mary J. Blige",
    "image_url": "https://i.scdn.co/image/ec8acbe7d4dc0b7487b7bbe2ff56d048a4574886",
    "artist_id": "1XkoF8ryArs86LZvFOkbyr",
    "year": 2006
  },
  {
    "song_id": "2JpUkUR0OsOlUUfm6iS8ic",
    "name": "Money Maker",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/1f55c33798c781438ade62b361018c939cf1dfdf",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/8e572c35ba7a6241bdde1611c5b6038479d8b695",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2006
  },
  {
    "song_id": "4NjR63Up0uMkQBlKvlZd1l",
    "name": "Stickwitu",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/eaaef8a1c9a96ded79a0a614fb7d726fb10e6c50",
    "artist_name": "The Pussycat Dolls",
    "image_url": "https://i.scdn.co/image/c6963993e632c62ba1cdcb84deedccb33d73df99",
    "artist_id": "6wPhSqRtPu1UhRCDX5yaDJ",
    "year": 2006
  },
  {
    "song_id": "1KVPQ3XQnmhjPbyXs6AcAS",
    "name": "Where'd You Go - feat. Holly Brook & Jonah Matranga",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/ce07fe8393cdfff1d224d946ef25425919167860",
    "artist_name": "Fort Minor [Featuring Holly Brook And Jonah Matranga]",
    "image_url": "https://i.scdn.co/image/87016cdcc58ec007ffbdd7bb6bbbffb80e21131e",
    "artist_id": "59l7YFKMoeMDzMLdMALGAd",
    "year": 2006
  },
  {
    "song_id": "0G53JYumT2vVxRGOtROlXk",
    "name": "Far Away",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/c05138c9a1fdbccef746ef87ab7ffd73eb81fc99",
    "artist_name": "Nickelback",
    "image_url": "https://i.scdn.co/image/6c8bdc77156559d718ddb449a9536891d5c1ad61",
    "artist_id": "6deZN1bslXzeGvOLaLMOIF",
    "year": 2006
  },
  {
    "song_id": "1tL40tfdtUaSLek4DvDDr3",
    "name": "Hate Me",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/0c7ea40d0f54d75621200f79b67e42ef4b4fef02",
    "artist_name": "Blue October",
    "image_url": "https://i.scdn.co/image/36956aa5c48507e6569e496fbda778720dda163b",
    "artist_id": "1TJbmc7jTpw78GKCiMpvDh",
    "year": 2006
  },
  {
    "song_id": "2ijMU9lYFCvGBzgwJ8Sd8q",
    "name": "For You I Will (Confidence)",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/1b9852a6781c0bbe140d1aabd17e259ac5586822",
    "artist_name": "Teddy Geiger",
    "image_url": "https://i.scdn.co/image/c28375c72c2dc27fb22403d380aa2667a554d1a0",
    "artist_id": "1YIpZOfyHXMUgUaxxxgbaC",
    "year": 2006
  },
  {
    "song_id": "2Pwm2YtneLSWi7vyUpT5fs",
    "name": "Bad Day",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/b4f99a232e739294beeee2bd8f414b45eea9419e",
    "artist_name": "Daniel Powter",
    "image_url": "https://i.scdn.co/image/e0d35f202bd7363f788eb03719caaaeeb772083f",
    "artist_id": "7xTcuBOIAAIGDOSvwYFPzk",
    "year": 2006
  },
  {
    "song_id": "2wdEXKLcuU7B0M2gqFmABH",
    "name": "Photograph",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/981d58a77c5f6919c85c138ed54222b1237e7b1d",
    "artist_name": "Nickelback",
    "image_url": "https://i.scdn.co/image/6c8bdc77156559d718ddb449a9536891d5c1ad61",
    "artist_id": "6deZN1bslXzeGvOLaLMOIF",
    "year": 2006
  },
  {
    "song_id": "50UkypyQJbSQLaXHke5Yep",
    "name": "There It Go (The Whistle Song)",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/163d9c8b05d502ed3f034332b7eb4df0403531ac",
    "artist_name": "Juelz Santana",
    "image_url": "https://i.scdn.co/image/e9de3ddf58c0e3bec8ae8cee604831263022ae5e",
    "artist_id": "6Uh8uJyN9g7oFjDK16nJgb",
    "year": 2006
  },
  {
    "song_id": "5hF2LnbkpnXCkksMSeWcxZ",
    "name": "Pullin' Me Back",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/fe58fd360318da982fc5e13deba5f50f9bc39fad",
    "artist_name": "Chingy",
    "image_url": "https://i.scdn.co/image/0260f50f00868a35763113fb995a2103daf254f1",
    "artist_id": "3s2wTjWxK8NOX09dmsvVOh",
    "year": 2006
  },
  {
    "song_id": "30cSNer6TV8x2utjULVeQ5",
    "name": "SOS",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/9c06c6bcc1648660d625e427ca08956058610ff1",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/d1550f4e7d53aefdbe8a32f9798f7ad66b931cea",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2006
  },
  {
    "song_id": "1lih9AUGlOI2qbAkptdBsr",
    "name": "I'm N Luv (Wit a Stripper)",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/f4a074ca9951bc82c2565cf8ad7a8697e3a0e1f2",
    "artist_name": "T-Pain",
    "image_url": "https://i.scdn.co/image/c400d99b96617e240501341a7d58c71b60db3baf",
    "artist_id": "3aQeKQSyrW4qWr35idm0cy",
    "year": 2006
  },
  {
    "song_id": "7BWXAPR8TxPR3ppVUqvotE",
    "name": "Gimme That",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/7ea486295cb88dc2ecf64795916ac6aff2377625",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/e91a78eabf4491742fba2a31b0652ea874c0027a",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2006
  },
  {
    "song_id": "4PrquuUN34hswtM71OMvxS",
    "name": "Get Up",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/a6942709b0f92faa8bb4bfa3f4147421b00d3c29",
    "artist_name": "Ciara",
    "image_url": "https://i.scdn.co/image/9aa64ab1371469ce9c9deae2b3169b9621043405",
    "artist_id": "2NdeV5rLm47xAvogXrYhJX",
    "year": 2006
  },
  {
    "song_id": "3kZoay4ANo86ehb6s4RwS9",
    "name": "Ridin'",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/c0db79460787cced309f456dca361ba299b316d7",
    "artist_name": "Chamillionaire",
    "image_url": "https://i.scdn.co/image/17d88d6e6185e58f7d5d4aa9c04bf07f762ac8d5",
    "artist_id": "6vdMPayKk8YJxxeNP5oMCb",
    "year": 2006
  },
  {
    "song_id": "0n2RMFCf3wfKJ9h3E3BKO8",
    "name": "Savin' Me",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/500f936b6d146a567c390e8e39f5815f62dc9dd3",
    "artist_name": "Nickelback",
    "image_url": "https://i.scdn.co/image/6c8bdc77156559d718ddb449a9536891d5c1ad61",
    "artist_id": "6deZN1bslXzeGvOLaLMOIF",
    "year": 2006
  },
  {
    "song_id": "7g7M96UGiA7OR79H86gV4m",
    "name": "U And Dat - feat. T. Pain & Kandi Girl",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/5e9d6860429b0c6a0e068d21ebfd75723a70d290",
    "artist_name": "E-40 (Featuring T-Pain & Kandi Girl)",
    "image_url": "https://i.scdn.co/image/cc9fd59ba9820cb78dd13c2a36c410e4712c7550",
    "artist_id": "2mG71xyR6RCxDvYRV9jmLF",
    "year": 2006
  },
  {
    "song_id": "5FXOEdfNW7nYQrBWtW49Cl",
    "name": "When You're Mad",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/0ed51ea45621f6925df35013c2503ffd827c1685",
    "artist_name": "Ne-Yo",
    "image_url": "https://i.scdn.co/image/fa8a567fb5a27d7f823ed2d6f91275ed65e748cb",
    "artist_id": "21E3waRsmPlU7jZsS13rcj",
    "year": 2006
  },
  {
    "song_id": "4kOTDj4SiZPkW1p96WMDNv",
    "name": "We Be Burnin' - Recognize It - Amended",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/59f885528ac5c670e2b633f8eae4e5e7754443fa",
    "artist_name": "Sean Paul",
    "image_url": "https://i.scdn.co/image/244013ca80cb50dcdd73acb1b942147f92e55283",
    "artist_id": "3Isy6kedDrgPYoTS1dazA9",
    "year": 2006
  },
  {
    "song_id": "49YeEoXjdJBaVOjghMPEdR",
    "name": "Check On It",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/34cb967cd1e7a977530cb9cbbd8be7810467c348",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/c97c18d4c242ab1e1288943c63f013e86c7d6c7d",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2006
  },
  {
    "song_id": "3UNrtRxBBlWE70SjxnOo02",
    "name": "Unpredictable - Main",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/fb4aebd0e404b1cad0b6a61b27593466d49aea56",
    "artist_name": "Jamie Foxx",
    "image_url": "https://i.scdn.co/image/f9fada5bb7ad5de13c1a7f6051361d74e3cb8d70",
    "artist_id": "7LnaAXbDVIL75IVPnndf7w",
    "year": 2006
  },
  {
    "song_id": "4dPsNgPv3ne2A3map82xhK",
    "name": "Bossy Featuring Too $hort",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/1de8743316167b3f0ac317130e3a32db27031737",
    "artist_name": "Kelis",
    "image_url": "https://i.scdn.co/image/b7f824b5207fb4fa796db960d0f705ee4180b67d",
    "artist_id": "0IF46mUS8NXjgHabxk2MCM",
    "year": 2006
  },
  {
    "song_id": "0tYPj0NYa7vjlJaqlRSnJb",
    "name": "Chain Hang Low",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/58be60dd286d921ca173ccda28ac130321a938b0",
    "artist_name": "Jibbs",
    "image_url": "https://i.scdn.co/image/91af4d98d8d5103da18db6aa53ac97b3b0b70bff",
    "artist_id": "4USNIVeRwXIAdbVSTqelbh",
    "year": 2006
  },
  {
    "song_id": "5TXNLJ2EENAt3Tf9MX4kT2",
    "name": "Beep",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/8bedf286cdc72b132698182f16f40a27b2e63717",
    "artist_name": "The Pussycat Dolls",
    "image_url": "https://i.scdn.co/image/c6963993e632c62ba1cdcb84deedccb33d73df99",
    "artist_id": "6wPhSqRtPu1UhRCDX5yaDJ",
    "year": 2006
  },
  {
    "song_id": "7n37dToRMRqUhdOnh9sEhB",
    "name": "Why You Wanna",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/f73785d0863eefd7c616e224a50839a62f5abe62",
    "artist_name": "T.I.",
    "image_url": "https://i.scdn.co/image/e309f036713b0d6e86d61422a7b43078382ffae5",
    "artist_id": "4OBJLual30L7gRl5UkeRcT",
    "year": 2006
  },
  {
    "song_id": "0bRh7pc1nfIv2edfcIUlKh",
    "name": "Promiscuous",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/df7d81256b9ea7e716a7be05c501551cd42d86df",
    "artist_name": "Nelly Furtado",
    "image_url": "https://i.scdn.co/image/9a7587bc1450cea3b65de7cc5200c067e12af8b9",
    "artist_id": "2jw70GZXlAI8QzWeY2bgRc",
    "year": 2006
  },
  {
    "song_id": "6YB6CK4Tsb0BgtxCEL9KlI",
    "name": "Give It Up To Me",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/c45caaa4164be93ce141d84aa7b54fca5f94a1dd",
    "artist_name": "Sean Paul",
    "image_url": "https://i.scdn.co/image/244013ca80cb50dcdd73acb1b942147f92e55283",
    "artist_id": "3Isy6kedDrgPYoTS1dazA9",
    "year": 2006
  },
  {
    "song_id": "2renBQKATbkVYjfSuvYWw8",
    "name": "What's Left Of Me",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/0b07321f6bd671b3c29d4a4555cea759f72fe6bf",
    "artist_name": "Nick Lachey",
    "image_url": "https://i.scdn.co/image/cf0adfb05de67b878cf8238eeccbb65e819cd3b7",
    "artist_id": "6ewMqjrF31lC8ywRsvm073",
    "year": 2006
  },
  {
    "song_id": "4Xtlw8oXkIOvzV7crUBKeZ",
    "name": "Rompe",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/51a335d41e2fb30d7c3fcbe81c283a6144444575",
    "artist_name": "Daddy Yankee",
    "image_url": "https://i.scdn.co/image/3ca1bdfd33d1dca98a4903c08ff335dd566a6112",
    "artist_id": "4VMYDCV2IEDYJArk749S6m",
    "year": 2006
  },
  {
    "song_id": "18TtVZgD09HpU7lUXR0kPI",
    "name": "When I'm Gone",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/60ef669475573e642a275c28f9b41cf9c03fcbd7",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/659db8ccef40443374e1b91bc640457a40d18c49",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2006
  },
  {
    "song_id": "6JPfeRnl5XJa9jIv4kbjr9",
    "name": "Laffy Taffy - Amended",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/215b4e521a88bc5549950db16b0ef576c25c36b2",
    "artist_name": "D4L",
    "image_url": "https://i.scdn.co/image/e2c4c1195009e03d51bd9578b233c4c36828d100",
    "artist_id": "4AllEJE7mVkhhyUV6DjqPz",
    "year": 2006
  },
  {
    "song_id": "6MBEjJlXQYXr8dfxDvZuoA",
    "name": "Do It To It - Feat. Sean Paul Of YoungBloodZ",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/d4020f08d3a6b0fc39b3fd635d56de00029122fd",
    "artist_name": "Cherish",
    "image_url": "https://i.scdn.co/image/1d6293dd94bb3e6419892e41d6fb05dc5b8b274b",
    "artist_id": "1c70yCa8sRgIiQxl3HOEFo",
    "year": 2006
  },
  {
    "song_id": "64649mwn5MP4gz1eIOAerx",
    "name": "Shake That",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/a1f721e619dc11cbd04f74627d1db561b1f61bf5",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/659db8ccef40443374e1b91bc640457a40d18c49",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2006
  },
  {
    "song_id": "5a4AQWs84xeEyYUd1iC2Pn",
    "name": "I Think They Like Me - feat. Jermaine Dupri, Da Brat & Bow Wow",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/b0d9b9494187c3d5ef45f9cbe7dda4608e439c1d",
    "artist_name": "Dem Franchize Boyz",
    "image_url": "https://i.scdn.co/image/1bf32afb6c26a988e38a9d85e83c7be8d894348d",
    "artist_id": "0VKTLKamj4IH8OfQbUL0kq",
    "year": 2006
  },
  {
    "song_id": "6AeuCzii0wNPI333OhXHEs",
    "name": "Show Stopper",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/90ea30f1460ffa801883c74b8499ce30a91040ca",
    "artist_name": "Danity Kane",
    "image_url": "https://i.scdn.co/image/5713432e029c53ffb58716f61cf56e290159c554",
    "artist_id": "3FD9VAd0ppjmKbfxF4LzUh",
    "year": 2006
  },
  {
    "song_id": "0Uc706myy6Th7I6KQ9xA1x",
    "name": "So What",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/c2fa1a590507d6b915bdbbdd6ce5f27a0a2514b8",
    "artist_name": "Field Mob",
    "image_url": "https://i.scdn.co/image/b69447a70542622fdb69c94c5f6892679d370954",
    "artist_id": "0Uo6kyjrbQoZBlcXsMb8Vm",
    "year": 2006
  },
  {
    "song_id": "5eLovQzx9cm6RkQq388JyN",
    "name": "Smack That - Dirty",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/59bd2019969a73f1155d8eb9d7b77f6a6c07b967",
    "artist_name": "Akon",
    "image_url": "https://i.scdn.co/image/7e356b089634d887e32c768755e68ea94da7ae57",
    "artist_id": "0z4gvV4rjIZ9wHck67ucSV",
    "year": 2006
  },
  {
    "song_id": "3HVJbdz0V402U5YggQK4Ao",
    "name": "Touch It - Album Version/Vocal Up (Explicit)",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/04a0aa73e7f45a6c9ae9afaf84c7592b2b91cbbc",
    "artist_name": "Busta Rhymes",
    "image_url": "https://i.scdn.co/image/dfc41e71a507e3b6966f1424a6e7f8ce01ec1766",
    "artist_id": "1YfEcTuGvBQ8xSD1f53UnK",
    "year": 2006
  },
  {
    "song_id": "7g7o2dAUn0Dz4h4CLeJSxh",
    "name": "It's Goin' Down - feat. Nitti Amended",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/9b73bd496945c5d8bd861d37f8dd15da989f2594",
    "artist_name": "Yung Joc",
    "image_url": "https://i.scdn.co/image/a2e404ae1e817b1dfc21bc7b13f719a3f48b6583",
    "artist_id": "23LbwefIODbyGdRbAz3urj",
    "year": 2006
  },
  {
    "song_id": "1RFqosG3YHFMOoyMalp6DJ",
    "name": "I'm Sprung - Original",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/fa5d58560fa46803b39039dda11eacd5ffb512d3",
    "artist_name": "T-Pain",
    "image_url": "https://i.scdn.co/image/534b84c6c7043b649d24fde7d7fda6832f652668",
    "artist_id": "3aQeKQSyrW4qWr35idm0cy",
    "year": 2006
  },
  {
    "song_id": "5daJNU6dISW25fY2Eq3vcD",
    "name": "Waiting on the World to Change",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/000e8fd5b21dad8cdf5213cf206c279e1726ac2b",
    "artist_name": "John Mayer",
    "image_url": "https://i.scdn.co/image/5720fb6af3bb8d106703f2eab029a882f816a493",
    "artist_id": "0hEurMDQu99nJRq8pTxO14",
    "year": 2006
  },
  {
    "song_id": "2Mmid8aKghvsPgaQIFMrPJ",
    "name": "Love",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/9a43fc73e41cab29e35816a7cae75f5bccfa5c8a",
    "artist_name": "Keyshia Cole",
    "image_url": "https://i.scdn.co/image/e616f780bbe3557c179ecb169cb336513f045edb",
    "artist_id": "1vfezMIyCr4XUdYRaKIKi3",
    "year": 2006
  },
  {
    "song_id": "42zwhiID3ydpTlyMoyeB3F",
    "name": "Stupid Girls",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/9d5f5ac896ae7c9c9bfcd84f94b0c925767f8488",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/d1ce47c8bf608fe5c573d2b2f6a6fbd30cb3d500",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2006
  },
  {
    "song_id": "5tXEx6JhF9FkP1fBlgFlCl",
    "name": "Grillz - Dirty",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/788b72a6eba8e75d3116a1ff379ccd2ea2d23de9",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/a8223024e679ed23484c6700e13c457b149ca2f3",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2006
  },
  {
    "song_id": "5wp9LO99JbSKDdegSGuGui",
    "name": "Don't Forget About Us (feat. Mariah Carey & Juelz Santana)",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/4aafbbc6d59f0b0b4e68095fff6b6d33f70d41f5",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/5c383056e25a3e3ec858151afb70afe763c00f9b",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 2006
  },
  {
    "song_id": "2jv9Ppwz9u3lqWfXV0q2sW",
    "name": "Do I Make You Proud",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/2aaab20a3974fa54c525f040c72c00f3a2b6e60c",
    "artist_name": "Taylor Hicks",
    "image_url": "https://i.scdn.co/image/35ca36d39bfbc26a8ffbae2bf652271818617f13",
    "artist_id": "3E3tW69eMfAffLGgsmP3GG",
    "year": 2006
  },
  {
    "song_id": "4AnYLZrC9uHsBXxx4lOlZ6",
    "name": "Shoulder Lean - feat. T.I. Same as Explicit",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/2923bfee81530feb301d06ae54849e68449aa0d4",
    "artist_name": "Young Dro (Featuring T.I.)",
    "image_url": "https://i.scdn.co/image/37d94eefd8bfe55d6b8a065bbf1eabce44e6e384",
    "artist_id": "01Z5aJQV0coqueWZVZzfh2",
    "year": 2006
  },
  {
    "song_id": "3Hy4QQEowJx4eVhFGtadOi",
    "name": "Walk Away",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/58afcc604ebd7824cbf43cc8dab17e77e7df2b6f",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/7d928a3ba94dede4413be617b8ef2db4d25e5e86",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2006
  },
  {
    "song_id": "5QUvrg3gdR2dO6qzcJgGrc",
    "name": "I Know You See It - feat. Brandy \"Ms. B\" Hambrick Amended",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/b7fa992f026776ae20fce38b2f11f97e765322fc",
    "artist_name": "Yung Joc",
    "image_url": "https://i.scdn.co/image/a2e404ae1e817b1dfc21bc7b13f719a3f48b6583",
    "artist_id": "23LbwefIODbyGdRbAz3urj",
    "year": 2006
  },
  {
    "song_id": "5Ngy0foEsuq9a9byZGtTwm",
    "name": "Because of You",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/f232b6fac2b506b3890dc622ca4524fdb5f0dd2e",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/87dfd28de6b8d3305eb9a4f3affca3704d4bca03",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2006
  },
  {
    "song_id": "0O45fw2L5vsWpdsOdXwNAR",
    "name": "SexyBack",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/c75b7d307d92886984ea4f9d6083112a21e1fbc9",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/cc738b63aa61812950829aea52e173ff527784b5",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2007
  },
  {
    "song_id": "6C7RJEIUDqKkJRZVWdkfkH",
    "name": "Stronger",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/867fa52f9c3e65bb677c83137752178900be1a7a",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/ccfa4ba15970ede67ed434f37a0fcbeaba49ac1c",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2007
  },
  {
    "song_id": "5wQnmLuC1W7ATsArWACrgW",
    "name": "Welcome To The Black Parade",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/f3a333d69c34bc472232905e7ce2ac7775dd76e2",
    "artist_name": "My Chemical Romance",
    "image_url": "https://i.scdn.co/image/04938b298316877d546dc5a6402e6bd07a13d5cd",
    "artist_id": "7FBcuc1gsnv6Y1nwFtNRCb",
    "year": 2007
  },
  {
    "song_id": "0bD5aCBb46dEtEVkv9hd1m",
    "name": "Thnks fr th Mmrs",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/ecb75624b9d5d04444bc0966c75ea4f68b3c23db",
    "artist_name": "Fall Out Boy",
    "image_url": "https://i.scdn.co/image/15a004eb5c823c9e1b3529e0948c1171ea08bfee",
    "artist_id": "4UXqAaa6dQYAk18Lv7PEgX",
    "year": 2007
  },
  {
    "song_id": "11bD1JtSjlIgKgZG2134DZ",
    "name": "Chasing Cars",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/c50076baf3525de6a457903ac1a9934e4b275789",
    "artist_name": "Snow Patrol",
    "image_url": "https://i.scdn.co/image/e29b9a288d12719ee6a762f5ddc5ad78b2346050",
    "artist_id": "3rIZMv9rysU7JkLzEaC5Jp",
    "year": 2007
  },
  {
    "song_id": "6RX5iL93VZ5fKmyvNXvF1r",
    "name": "Irreplaceable",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/ca730740091cebea7fdafbb0b30fdbf6142ca9e4",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/5c58c8a08cbb43f26a56b58f8461c74f892103e8",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2007
  },
  {
    "song_id": "3pD0f7hSJg2XdQ6udw5Tey",
    "name": "What Goes Around.../...Comes Around Interlude",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/18b304c6ea155f896a810529bb01e0c3972af4c7",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/cc738b63aa61812950829aea52e173ff527784b5",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2007
  },
  {
    "song_id": "13X42np3KJr0o2LkK1MG76",
    "name": "My Love",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/9bf23b9b2f0ada4fb03ccbd7e50c8f25a331ad35",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/151931e02746e16f64aad4a2b664e59c71578c84",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2007
  },
  {
    "song_id": "2aIdVb8v9KTpEZnftkz2mD",
    "name": "Buy U a Drank (Shawty Snappin')",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/574b0245d038c3f18bcbeb526f327dbcf7063bc1",
    "artist_name": "T-Pain",
    "image_url": "https://i.scdn.co/image/4633755d4c860b9522c54bc48feb6e1840465060",
    "artist_id": "3aQeKQSyrW4qWr35idm0cy",
    "year": 2007
  },
  {
    "song_id": "0ZUo4YjG4saFnEJhdWp9Bt",
    "name": "Before He Cheats",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/2525d051a5abf1413dd149bdb870a71aa9614b85",
    "artist_name": "Carrie Underwood",
    "image_url": "https://i.scdn.co/image/37dfb225eaa304fbf35801a7073336417381fb84",
    "artist_id": "4xFUf1FHVy696Q1JQZMTRj",
    "year": 2007
  },
  {
    "song_id": "0rFOs9paloAvEtzwDX1Kmc",
    "name": "Bubbly",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/72612b5052c1756565612e68a25ed3f79d2b0767",
    "artist_name": "Colbie Caillat",
    "image_url": "https://i.scdn.co/image/ff6c1f1b6b4d365787bc2fb9f1bcd2f8dff0b59e",
    "artist_id": "6aZyMrc4doVtZyKNilOmwu",
    "year": 2007
  },
  {
    "song_id": "49FYlytm3dAAraYgpoJZux",
    "name": "Umbrella",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/fb8c775365c72702cce2588ab61eda9d2a348aeb",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/07077803ac9099f2af00179d9da1acbde71dc092",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2007
  },
  {
    "song_id": "6TlRNJaezOdzdECnQeRuMM",
    "name": "Bed",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/98cf537ad4705b3144f650b0191c2d70589e90d3",
    "artist_name": "J. Holiday",
    "image_url": "https://i.scdn.co/image/5f11772463dd33e1c76af2761050789b04ed1b46",
    "artist_id": "7G6hXrjGpi6I7waNl4wxAk",
    "year": 2007
  },
  {
    "song_id": "1EXAqr4fceDPYlL6XeMi2g",
    "name": "Rehab",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/fca0eebad2905a9042c8896491966cf5f6d0bf12",
    "artist_name": "Amy Winehouse",
    "image_url": "https://i.scdn.co/image/5e1ba69da69799426793faed56725ea799fa4084",
    "artist_id": "6Q192DXotxtaysaqNPy5yR",
    "year": 2007
  },
  {
    "song_id": "0JEqGkvUiMTQmFY6sgL9kg",
    "name": "No One",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/af2ce9a7d8b09491969ff8d419b083fb385e716b",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/900da831f84a0666f9d1b480e41b04ad54ed048e",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2007
  },
  {
    "song_id": "1ElXBG9yJcgSFUJr19sotM",
    "name": "Ice Box",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/d5023369f996a703ac2e752c619d2183d3a64e19",
    "artist_name": "Omarion",
    "image_url": "https://i.scdn.co/image/dc79dd6dafff3f693c4f2cfeeba77ed72d614662",
    "artist_id": "0f5nVCcR06GX8Qikz0COtT",
    "year": 2007
  },
  {
    "song_id": "40LQiUUUKXVGyNs09lHVjW",
    "name": "Lips Of An Angel",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/4f409cf19adf06df714c63d0328fd3e4318970b9",
    "artist_name": "Hinder",
    "image_url": "https://i.scdn.co/image/053f3a20cffb24f2545e1c2e42e97c5f414a1f31",
    "artist_id": "6BMhCQJYHxxKAeqYS1p5rY",
    "year": 2007
  },
  {
    "song_id": "6CFPFnS9EcLs2I0nWqtWci",
    "name": "Because Of You",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/92bd88ceb8480c4220098999950933f042e76b52",
    "artist_name": "Ne-Yo",
    "image_url": "https://i.scdn.co/image/5e3c5f97a8dd3a0f1629ae950fdbb712a42bf572",
    "artist_id": "21E3waRsmPlU7jZsS13rcj",
    "year": 2007
  },
  {
    "song_id": "358bOvBiZCS9fRzNYosw6c",
    "name": "Like A Boy",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/31458b482b2f94a7ed564b892988f361ca79d2e3",
    "artist_name": "Ciara",
    "image_url": "https://i.scdn.co/image/79aed74970845ebec7d02841fac7a7a3f163d6e5",
    "artist_id": "2NdeV5rLm47xAvogXrYhJX",
    "year": 2007
  },
  {
    "song_id": "1NarGDteHRuDA9OfGyQ2MG",
    "name": "Hey There Delilah",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/42a390488dafd888cc0ec0b0f05bd8f5f6e73086",
    "artist_name": "Plain White T's",
    "image_url": "https://i.scdn.co/image/04927b04210ba21e6e77ad178d7bc1d6db5de8fe",
    "artist_id": "1g1yxsNVPhMUl9GrMjEb2o",
    "year": 2007
  },
  {
    "song_id": "5HbCnVLXRyZVxnreOPgJCK",
    "name": "Girlfriend",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/c74b946c20d4c3414fda399ae2d872ff6e435c26",
    "artist_name": "Avril Lavigne",
    "image_url": "https://i.scdn.co/image/2097f102077d7c5b4ce171c08548de9e0ed9201b",
    "artist_id": "0p4nmQO2msCgU4IF37Wi3j",
    "year": 2007
  },
  {
    "song_id": "5ybUdN3DmQL8T3eqg4XjmQ",
    "name": "This Ain't A Scene, It's An Arms Race",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/6aecb60a2576dcdf60cd71e660e7b15daaf9e3ff",
    "artist_name": "Fall Out Boy",
    "image_url": "https://i.scdn.co/image/15a004eb5c823c9e1b3529e0948c1171ea08bfee",
    "artist_id": "4UXqAaa6dQYAk18Lv7PEgX",
    "year": 2007
  },
  {
    "song_id": "0wIhWLNLIOmzQ89B3rtTd3",
    "name": "It's Not Over",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/40f32e01350e252d8f51108d390705d700100daf",
    "artist_name": "Daughtry",
    "image_url": "https://i.scdn.co/image/8a18d29456ca7a3e387b89f0d00e342a82150310",
    "artist_id": "5P5FTygHyx2G57oszR3Wot",
    "year": 2007
  },
  {
    "song_id": "2nXWKf0GXbFby4posGqEht",
    "name": "Home",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/8af3c239e1a22b6270a0481b68b67f792d37dae8",
    "artist_name": "Daughtry",
    "image_url": "https://i.scdn.co/image/8a18d29456ca7a3e387b89f0d00e342a82150310",
    "artist_id": "5P5FTygHyx2G57oszR3Wot",
    "year": 2007
  },
  {
    "song_id": "6GIrIt2M39wEGwjCQjGChX",
    "name": "The Great Escape",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/24475b67e9de051a7e514b4cb1f930b1156f4330",
    "artist_name": "Boys Like Girls",
    "image_url": "https://i.scdn.co/image/2dbf88333cd726de7eaa804aafda5449dbc6be1b",
    "artist_id": "0vWCyXMrrvMlCcepuOJaGI",
    "year": 2007
  },
  {
    "song_id": "4V2F0DZrAXOWq9hkwMMG3x",
    "name": "Lost Without U",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/1d5b18914b48869d334b57b4d2a4625b58619185",
    "artist_name": "Robin Thicke",
    "image_url": "https://i.scdn.co/image/d4dbf9e58b9fa9bb20656918bace5908728c1c64",
    "artist_id": "0ZrpamOxcZybMHGg1AYtHP",
    "year": 2007
  },
  {
    "song_id": "1FMHNVeJ9s1x1l1WlaRs2I",
    "name": "It Ends Tonight",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/18f3abccade5dd5293ce826162505fa8687c6a96",
    "artist_name": "The All-American Rejects",
    "image_url": "https://i.scdn.co/image/93a2d82094c9af6d4a2fd0b1d35e386f8a1499bf",
    "artist_id": "3vAaWhdBR38Q02ohXqaNHT",
    "year": 2007
  },
  {
    "song_id": "5kxddRG1RZaZROadk7iC4D",
    "name": "Bartender",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/eeec2dfef54a14058fc1447b49b42132d1bb6727",
    "artist_name": "T-Pain",
    "image_url": "https://i.scdn.co/image/4633755d4c860b9522c54bc48feb6e1840465060",
    "artist_id": "3aQeKQSyrW4qWr35idm0cy",
    "year": 2007
  },
  {
    "song_id": "5Y77SQxEr1eiofPeUTPHxM",
    "name": "You - Album Version (Edited)",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/247ea10fae2a80c671c13ab6c5033b5adb1df1b5",
    "artist_name": "Lloyd",
    "image_url": "https://i.scdn.co/image/ab4ac32e9e45163fb3145867d13d6d3eb1d1c1c6",
    "artist_id": "1Xfmvd48oOhEWkscWyEbh9",
    "year": 2007
  },
  {
    "song_id": "4pSPMXaCjbaV3VSzZQYC7H",
    "name": "Who Knew",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/a35f26b3c289fcc5aab063332c8a69fa7675037e",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/9f51e9ef71ca7073707d77f4780fd3ca62baf107",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2007
  },
  {
    "song_id": "1MBM7CyZbwJpVbbZJnHHRg",
    "name": "Summer Love - Main Version - Clean",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/461ee03d33ca5f5088980d7ee5437e7d995ef08f",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/cc738b63aa61812950829aea52e173ff527784b5",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2007
  },
  {
    "song_id": "2P5cIXejqLpHDQeCHAbbBG",
    "name": "Beautiful Liar - Main Version / Album Version",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/47f85a3e758cda3de0e8eaa88363bcd5fcb80319",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/e7fda36ee273b819e4aa12dd1d362c04fe1ec087",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2007
  },
  {
    "song_id": "10fddGyyeUquZZ2uPTjD7P",
    "name": "Better Than Me",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/67a216aa875b316125661068cb41e1a3c0070f5e",
    "artist_name": "Hinder",
    "image_url": "https://i.scdn.co/image/053f3a20cffb24f2545e1c2e42e97c5f414a1f31",
    "artist_id": "6BMhCQJYHxxKAeqYS1p5rY",
    "year": 2007
  },
  {
    "song_id": "4xAk8Lw82G3YoVSOdVAsBx",
    "name": "I Wanna Love You",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/cee2acfd6c8c5968c8a1362f3ed655f4f5f69bac",
    "artist_name": "Akon",
    "image_url": "https://i.scdn.co/image/7e356b089634d887e32c768755e68ea94da7ae57",
    "artist_id": "0z4gvV4rjIZ9wHck67ucSV",
    "year": 2007
  },
  {
    "song_id": "7I6DceMT3utDOHjcYCbrr4",
    "name": "Don't Matter",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/5cd2b47783af6a0f43dcdb3dee407c54a51fe518",
    "artist_name": "Akon",
    "image_url": "https://i.scdn.co/image/5ecf862ab311910f2d1f6b7921afbf3b2961db7d",
    "artist_id": "0z4gvV4rjIZ9wHck67ucSV",
    "year": 2007
  },
  {
    "song_id": "4F55RCGuM477OjznpYGhYz",
    "name": "Shortie Like Mine",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/0df82298b6c2fbe24a1a0f5d1971315af398f04a",
    "artist_name": "Bow Wow",
    "image_url": "https://i.scdn.co/image/2bad2412e8cf3eaaef97d00beda1267efe9ad287",
    "artist_id": "7352aRY2mqSxBZwzUb6LmA",
    "year": 2007
  },
  {
    "song_id": "27mhCGdAA8gM7b33KIiB3k",
    "name": "Cyclone",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/c97e6d5af00e066c2da3d444692a020727dfb09c",
    "artist_name": "Baby Bash",
    "image_url": "https://i.scdn.co/image/1cef2536e666b1ad93a334ba28a62644833ad15b",
    "artist_id": "12PSlydMSjEHzSCj9X5qv7",
    "year": 2007
  },
  {
    "song_id": "4Tn2llBm1g0UlWctmgPL8Z",
    "name": "Shut Up and Drive",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/a5acaaa25586fdc4ecdd8f1fca80da94b8609813",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/07077803ac9099f2af00179d9da1acbde71dc092",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2007
  },
  {
    "song_id": "6OSmeoh8kXhCfz9WqvzDoJ",
    "name": "Same Girl",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/b3e701b4577c3b4660b59df387ec5c4c00cb84d7",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/fa64863f5ca08e87633c493866bc05a3a76eb888",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 2007
  },
  {
    "song_id": "66ZcOcouenzZEnzTJvoFmH",
    "name": "The Sweet Escape",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/293629bb286e06ec90df6794c0db4fb5bcc4fb0c",
    "artist_name": "Gwen Stefani",
    "image_url": "https://i.scdn.co/image/fb48c980349e21cef868286c0ceecf9a5f45771e",
    "artist_id": "4yiQZ8tQPux8cPriYMWUFP",
    "year": 2007
  },
  {
    "song_id": "2Lhdl74nwwVGOE2Gv35QuK",
    "name": "Cupid's Chokehold / Breakfast In America",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/ea2fe4ac0f07c33d1fb7682e2fe8ba0874831840",
    "artist_name": "Gym Class Heroes",
    "image_url": "https://i.scdn.co/image/8158bd0fb3713cfe971618b1ad32ac1fd8e47333",
    "artist_id": "4IJczjB0fJ04gs4uvP0Fli",
    "year": 2007
  },
  {
    "song_id": "58Mmq9tDlN7jiUqmvugggD",
    "name": "Keep Holding On",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/4390ba82c9cfbd022240d19a692008b99c875132",
    "artist_name": "Avril Lavigne",
    "image_url": "https://i.scdn.co/image/39004c40618e30d16f935110a7875a4a5db5c92d",
    "artist_id": "0p4nmQO2msCgU4IF37Wi3j",
    "year": 2007
  },
  {
    "song_id": "4z1gmF4S1YsTj1pxiU47Qx",
    "name": "Here (In Your Arms)",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/44481c9a42bc98ece74f393d9f3791bb4d1bdf46",
    "artist_name": "Hellogoodbye",
    "image_url": "https://i.scdn.co/image/4921b07fb4cb989da4efab1f3f3b0f602886c50f",
    "artist_id": "6GH0NzpthMGxu1mcfAkOde",
    "year": 2007
  },
  {
    "song_id": "36Hwcla06Q5BCr59CL0sBp",
    "name": "Ayo Technology",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/ba24a128e445aa1ef7f29b8c949141096dd972d0",
    "artist_name": "50 Cent",
    "image_url": "https://i.scdn.co/image/8fdfee6d6e1a84a7423b88278d94a911e65a1573",
    "artist_id": "3q7HBObVc0L8jNeTe5Gofh",
    "year": 2007
  },
  {
    "song_id": "2JpUkUR0OsOlUUfm6iS8ic",
    "name": "Money Maker",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/1f55c33798c781438ade62b361018c939cf1dfdf",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/8e572c35ba7a6241bdde1611c5b6038479d8b695",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2007
  },
  {
    "song_id": "0G53JYumT2vVxRGOtROlXk",
    "name": "Far Away",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/c05138c9a1fdbccef746ef87ab7ffd73eb81fc99",
    "artist_name": "Nickelback",
    "image_url": "https://i.scdn.co/image/6c8bdc77156559d718ddb449a9536891d5c1ad61",
    "artist_id": "6deZN1bslXzeGvOLaLMOIF",
    "year": 2007
  },
  {
    "song_id": "6n9yCXvLhnYMgJIiIcMu7D",
    "name": "Rockstar",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/b4e2ade98dabf62b85dde64229efd0566507f5fd",
    "artist_name": "Nickelback",
    "image_url": "https://i.scdn.co/image/6c8bdc77156559d718ddb449a9536891d5c1ad61",
    "artist_id": "6deZN1bslXzeGvOLaLMOIF",
    "year": 2007
  },
  {
    "song_id": "3MYwiHsnNjSfaacdLUHNcz",
    "name": "Wait For You",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/3779909966b7faba2b6e283ceb1224f70306545a",
    "artist_name": "Elliott Yamin",
    "image_url": "https://i.scdn.co/image/a5776988b6876e66174b429755fa148a38f8e9b4",
    "artist_id": "4am1I89OWXUzFh4ctRLkdd",
    "year": 2007
  },
  {
    "song_id": "2a03afRiyAbNAleOByVju4",
    "name": "Get It Shawty - Main",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/a8e8afc23a99431f9482fcfde7a5c10ec4f1c8f1",
    "artist_name": "Lloyd",
    "image_url": "https://i.scdn.co/image/ab4ac32e9e45163fb3145867d13d6d3eb1d1c1c6",
    "artist_id": "1Xfmvd48oOhEWkscWyEbh9",
    "year": 2007
  },
  {
    "song_id": "2elA6JLRaQ6iB7hxiuTKN4",
    "name": "I Tried",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/32243fabd093b9224e1c4e9dfa99be2f7f074608",
    "artist_name": "Bone Thugs-N-Harmony",
    "image_url": "https://i.scdn.co/image/45d1f756ecd1791b7088ff2bc9c3a7882a146019",
    "artist_id": "5spEJXLwD1sKUdC2bnOHPg",
    "year": 2007
  },
  {
    "song_id": "2RqZFOLOnzVmHUX7ZMcaES",
    "name": "Let It Go",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/da43950fc843c8ebb9b7496209a5a787430a0118",
    "artist_name": "Keyshia Cole",
    "image_url": "https://i.scdn.co/image/dcfd822030258ead49f07b413f01195535e3cc6f",
    "artist_id": "1vfezMIyCr4XUdYRaKIKi3",
    "year": 2007
  },
  {
    "song_id": "7aBVQ8dcHytEddV0SlDrcQ",
    "name": "U + Ur Hand",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/8e53e6c74d52c7519ccfb4da667030085770b17d",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/9f51e9ef71ca7073707d77f4780fd3ca62baf107",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2007
  },
  {
    "song_id": "1pLdjo3lOBbMaoR4ZpybFH",
    "name": "Promise",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/d540e24d8d7bc365c2c30e4126c4720abead56fe",
    "artist_name": "Ciara",
    "image_url": "https://i.scdn.co/image/79aed74970845ebec7d02841fac7a7a3f163d6e5",
    "artist_id": "2NdeV5rLm47xAvogXrYhJX",
    "year": 2007
  },
  {
    "song_id": "5iyE2d4U3Vc5cqY9mPTlpy",
    "name": "LoveStoned/I Think She Knows Interlude - Main Version - Explicit",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/ed4f57348f4b487d4b5dc5cc88411337ae416dca",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/cc738b63aa61812950829aea52e173ff527784b5",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2007
  },
  {
    "song_id": "0wbDgMuAoy7O7pL3a69uZx",
    "name": "Give It To Me",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/b52dab62d08871db11cd8f918f631ee7391350b5",
    "artist_name": "Timbaland",
    "image_url": "https://i.scdn.co/image/1d7dcf505338d7c897becfa753315133dfd1aaf8",
    "artist_id": "5Y5TRrQiqgUO4S36tzjIRZ",
    "year": 2007
  },
  {
    "song_id": "1qKEApj9DTrcXikqeo7S0k",
    "name": "I'm a Flirt Remix",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/d25ae206b07fdc5ccb922f23ddb85866f2cfb28e",
    "artist_name": "R. Kelly",
    "image_url": "https://i.scdn.co/image/b27899020ef4e6a78db27dce4ad468337423679b",
    "artist_id": "2mxe0TnaNL039ysAj51xPQ",
    "year": 2007
  },
  {
    "song_id": "544wx7ulaLbPEecrnYr5uZ",
    "name": "Kiss Kiss",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/39d0fc4a19c9662f1cc83366d9a8884b2939aa8c",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/5aa69e803293fd3b4a88c346511e69ff6207391a",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2007
  },
  {
    "song_id": "1vJ00sqL2EvSO6wKGktYB4",
    "name": "What I've Done",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/1b82ee3a74571e0e7084034903bcda58d824ad18",
    "artist_name": "Linkin Park",
    "image_url": "https://i.scdn.co/image/4ca11303031ca1c36ebc75aa32f52f752de00a2d",
    "artist_id": "6XyY86QOPPrYVGvF9ch6wz",
    "year": 2007
  },
  {
    "song_id": "44w63XqGr3sATAzOnOySgF",
    "name": "If Everyone Cared",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/fe4ca27c4f4d3397d8d9a2488c632b038abe5759",
    "artist_name": "Nickelback",
    "image_url": "https://i.scdn.co/image/6c8bdc77156559d718ddb449a9536891d5c1ad61",
    "artist_id": "6deZN1bslXzeGvOLaLMOIF",
    "year": 2007
  },
  {
    "song_id": "5kGCh49aqeRMkEOhQ9mcrW",
    "name": "Party Like A Rock Star",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/24da05bb86807262c029cdaa026a71b1f7ca44e5",
    "artist_name": "Shop Boyz",
    "image_url": "https://i.scdn.co/image/b9f8b010132246e3c2bf7a2f9bab6bc81fd5afc4",
    "artist_id": "3v3clM1KQgVfpPjKJFPAmx",
    "year": 2007
  },
  {
    "song_id": "5WeiB5JDBy6T5QQxtj09zb",
    "name": "Crank That (Soulja Boy) [Travis Barker Remix]",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/b3853aa6f4f0f9a29e4edfe0f60bc57569679084",
    "artist_name": "Soulja Boy",
    "image_url": "https://i.scdn.co/image/8b58c39ea861160e34f88776cdb4f4862ff058ff",
    "artist_id": "6GMYJwaziB4ekv1Y6wCDWS",
    "year": 2007
  },
  {
    "song_id": "0YkJU6TjjwzsVXUPRIO0DB",
    "name": "Make Me Better",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/141b1740751e46978b29d7d5eaf4090c1ce42248",
    "artist_name": "Fabolous",
    "image_url": "https://i.scdn.co/image/073754f8753f88f1dc7f04c36695f268975a3fbb",
    "artist_id": "0YWxKQj2Go9CGHCp77UOyy",
    "year": 2007
  },
  {
    "song_id": "6olIWF9LZgO0Eij4CeORQV",
    "name": "Shawty (feat. T Pain)",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/caa3f090d6f9d9e038758a6d29b359406630ce2e",
    "artist_name": "Plies",
    "image_url": "https://i.scdn.co/image/36e22d16542fc7ab52310bd8e563a1fea6290906",
    "artist_id": "3jksrX4oBklxR78ft8gv3j",
    "year": 2007
  },
  {
    "song_id": "1GeECVH4Msg1zLm8DExkbH",
    "name": "Wind It Up",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/2ee8458b8641a42702c3b42fea2ce0798608d1d1",
    "artist_name": "Gwen Stefani",
    "image_url": "https://i.scdn.co/image/fb48c980349e21cef868286c0ceecf9a5f45771e",
    "artist_id": "4yiQZ8tQPux8cPriYMWUFP",
    "year": 2007
  },
  {
    "song_id": "29TwAJfQ7rR7peyr6eZpL0",
    "name": "Walk It Out",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/ccf0cdc1b63e85a8179ce5cbb69e9dba914937db",
    "artist_name": "Unk",
    "image_url": "https://i.scdn.co/image/b770368dab2c42e633005b969ce2e02fdd52b6e9",
    "artist_id": "0PGtMx1bsqoCHCy3MB3gXA",
    "year": 2007
  },
  {
    "song_id": "77rlwv75irCcg50920G04u",
    "name": "Apologize",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/b42afb544adbd80e28d62eb73f0a8cc1079dd44f",
    "artist_name": "Timbaland",
    "image_url": "https://i.scdn.co/image/474fc080abc6c1f4abed666ea3ea0d8b83dd4c7d",
    "artist_id": "5Y5TRrQiqgUO4S36tzjIRZ",
    "year": 2007
  },
  {
    "song_id": "6SmPPtaMnfxgz5duA6t9Cu",
    "name": "Throw Some D's",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/e384cd0745f8f7e94943e1a181693d149ca165df",
    "artist_name": "Rich Boy",
    "image_url": "https://i.scdn.co/image/645f6af0afec68e7234c33b71ca7f57e9f709859",
    "artist_id": "6mXlDbi03T8wXYwWYew0Ut",
    "year": 2007
  },
  {
    "song_id": "0Sc9UwBpChiRKRF8DKvWKZ",
    "name": "Walk Away (Remember Me)",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/75d270d989be2406cd94ebfcb83a95692a67ebbb",
    "artist_name": "Paula DeAnda",
    "image_url": "https://i.scdn.co/image/ef58b636eddde31fe3014f5f6733685cd05d4a5b",
    "artist_id": "54kWB5z9SbenPFQXtBDUBK",
    "year": 2007
  },
  {
    "song_id": "3bsoEOP4NHEoeVbAAYdbaU",
    "name": "On The Hotline",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/905567a212adba45b063935bd48f170f82ca528e",
    "artist_name": "Pretty Ricky",
    "image_url": "https://i.scdn.co/image/10eef29e566d91465546d583495d1bde74c8e430",
    "artist_id": "6XwcepfAsPhrvwziGxhwju",
    "year": 2007
  },
  {
    "song_id": "3J58iVk8OXYAGG4YvOKG8u",
    "name": "That's That Shit",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/c5d46e4b232f4a4ce207437af65506f9f17f223a",
    "artist_name": "Snoop Dogg",
    "image_url": "https://i.scdn.co/image/54b8ffe8df653d7915caee72c4e6ce672b75bab6",
    "artist_id": "7hJcb9fa4alzcOq3EaNPoG",
    "year": 2007
  },
  {
    "song_id": "4PgtB6Sul8WDTRZCe5e8St",
    "name": "Break It Off",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/e92d9bfbe433b0619f84ed63a8df354e860ff9e1",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/d1550f4e7d53aefdbe8a32f9798f7ad66b931cea",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2007
  },
  {
    "song_id": "40Fp6pxcS9l08kL1R50mR4",
    "name": "First Time",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/64636e81c1ba65a477fa5297dbe782195aa2b1da",
    "artist_name": "Lifehouse",
    "image_url": "https://i.scdn.co/image/f0c3152ecef6c582f50a5c1f4fcac8a5c11b8c8d",
    "artist_id": "5PokPZn11xzZXyXSfnvIM3",
    "year": 2007
  },
  {
    "song_id": "5RM4iGrNOyeKLTcMv2FPc9",
    "name": "The Way I Are",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/3d4dc50ce1ffcd968e532406c57844032b6b1bf4",
    "artist_name": "Timbaland",
    "image_url": "https://i.scdn.co/image/1d7dcf505338d7c897becfa753315133dfd1aaf8",
    "artist_id": "5Y5TRrQiqgUO4S36tzjIRZ",
    "year": 2007
  },
  {
    "song_id": "3sNAeRQQ9FKeMv9nETVIt0",
    "name": "Go Getta",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/9b3b277d15cada923fb8f66dcc8806fe8d012f56",
    "artist_name": "Jeezy",
    "image_url": "https://i.scdn.co/image/5b187557ba454b081191ab10ae5cad82e4612a87",
    "artist_id": "4yBK75WVCQXej1p04GWqxH",
    "year": 2007
  },
  {
    "song_id": "6MnFB3qurhmGs1xdjrz5Bg",
    "name": "A Bay Bay",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/f59b8c8f3b823b62f12d58ad86fbcefec912dea9",
    "artist_name": "Hurricane Chris",
    "image_url": "https://i.scdn.co/image/d2c53625c44cc44bd44b9f49d975887655206e13",
    "artist_id": "1fQ46lgoUCz8FVXdTVNk0a",
    "year": 2007
  },
  {
    "song_id": "5eLovQzx9cm6RkQq388JyN",
    "name": "Smack That - Dirty",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/59bd2019969a73f1155d8eb9d7b77f6a6c07b967",
    "artist_name": "Akon",
    "image_url": "https://i.scdn.co/image/7e356b089634d887e32c768755e68ea94da7ae57",
    "artist_id": "0z4gvV4rjIZ9wHck67ucSV",
    "year": 2007
  },
  {
    "song_id": "2FCrjpcEh2cMrqPWD5SNxp",
    "name": "Rock Yo Hips",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/abf484b4896560946a346794f0dd3d25da98d91d",
    "artist_name": "Crime Mob",
    "image_url": "https://i.scdn.co/image/f08c0a90ba58c5271669d8d7f704b704f68c61c5",
    "artist_id": "5A7d4sfe5ZY1RRf90zlUeo",
    "year": 2007
  },
  {
    "song_id": "3Tpez8fvZfCMSODg543Ss2",
    "name": "The Way I Live",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/87f4451d431b2582971cd6db7a078c9f63ee0896",
    "artist_name": "Baby Boy",
    "image_url": "https://i.scdn.co/image/3acf84763f4e89fd872574268b9b33d83eadd830",
    "artist_id": "5cjAMJ0mdgOJvbZBmJeq0A",
    "year": 2007
  },
  {
    "song_id": "0VPTq1UBP8fcWKYgLAtesN",
    "name": "Runaway Love - (Explicit)",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/a1be716a4f952b50a3fb8c76da1cfb49e8daf9ed",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/1c397c09c04a3dc8d3eb1ba7acadbbb9ea6b665d",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2007
  },
  {
    "song_id": "1Z9k2TkEdY1jQYlADzZa3Q",
    "name": "2 Step",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/f56f5ac5711b5982456ce6298f3e5851093aec34",
    "artist_name": "Unk",
    "image_url": "https://i.scdn.co/image/b770368dab2c42e633005b969ce2e02fdd52b6e9",
    "artist_id": "0PGtMx1bsqoCHCy3MB3gXA",
    "year": 2007
  },
  {
    "song_id": "5gfPJ45gpn3ThswDyeW0Qc",
    "name": "Last Night feat. Keyshia Cole",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/03667ede7b7b283ade89db8f1a1aff79f82e76c0",
    "artist_name": "Diddy Featuring Keyshia Cole",
    "image_url": "https://i.scdn.co/image/3bce4c523b6ec6a45b16ff2113204bb2e5514530",
    "artist_id": "455ntbvzeFL1bIcEKhlebq",
    "year": 2007
  },
  {
    "song_id": "5daJNU6dISW25fY2Eq3vcD",
    "name": "Waiting on the World to Change",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/000e8fd5b21dad8cdf5213cf206c279e1726ac2b",
    "artist_name": "John Mayer",
    "image_url": "https://i.scdn.co/image/5720fb6af3bb8d106703f2eab029a882f816a493",
    "artist_id": "0hEurMDQu99nJRq8pTxO14",
    "year": 2007
  },
  {
    "song_id": "12MTkBZUoOC1BySWtVKLKr",
    "name": "Say It Right",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/5496e079711f9defa7b6c9dfd9ba040525549ebb",
    "artist_name": "Nelly Furtado",
    "image_url": "https://i.scdn.co/image/9a7587bc1450cea3b65de7cc5200c067e12af8b9",
    "artist_id": "2jw70GZXlAI8QzWeY2bgRc",
    "year": 2007
  },
  {
    "song_id": "2M6An7pyyNwEObR7c7uCVp",
    "name": "We Fly High",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/25c6f74b66b6ca0630e0e06cc4bbca850b52f915",
    "artist_name": "Jim Jones",
    "image_url": "https://i.scdn.co/image/3e4cefb183b6ab5c8abfb67369675c59455611a9",
    "artist_id": "6AMa1VFQ7qCi61tCRtVWXe",
    "year": 2007
  },
  {
    "song_id": "4cXjW4R2BOhKLuFJ6ACZQz",
    "name": "Never Again",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/b2936677d04e2f0440a899d5a149ac11409abb14",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/effac31c59f4dd9cd072b3d366613e6bc75c2178",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2007
  },
  {
    "song_id": "1EzrEOXmMH3G43AXT1y7pA",
    "name": "I'm Yours",
    "popularity": 82,
    "preview_url": "https://p.scdn.co/mp3-preview/2fee94f72527b42f3af8cb003a14f2ac16a97c5e",
    "artist_name": "Jason Mraz",
    "image_url": "https://i.scdn.co/image/da78aa2fadacfabdc80addc724f6c01c9752aa02",
    "artist_id": "4phGZZrJZRo4ElhRtViYdl",
    "year": 2008
  },
  {
    "song_id": "1mea3bSkSGXuIRvnydlB5b",
    "name": "Viva La Vida",
    "popularity": 80,
    "preview_url": "https://p.scdn.co/mp3-preview/2cfb0509541df344f3096ad8fbfcf79a9dd155b4",
    "artist_name": "Coldplay",
    "image_url": "https://i.scdn.co/image/0e522bf592ab4a7dafdf72cef035e3515208e4e3",
    "artist_id": "4gzpq5DPGxSnKTe4SA8HAU",
    "year": 2008
  },
  {
    "song_id": "3tvWMBIblzT5FSjKtIeRR1",
    "name": "Whatever You Like",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/07db047e7c61116268064109c4e2043c0826011e",
    "artist_name": "T.I.",
    "image_url": "https://i.scdn.co/image/e6227cd8e3deae7d073cc1e261bfd6214fafab61",
    "artist_id": "4OBJLual30L7gRl5UkeRcT",
    "year": 2008
  },
  {
    "song_id": "4E6cwWJWZw2zWf7VFbH7wf",
    "name": "Love Song",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/19158a744e4f02162c90f4c02b893f8074fcb4a2",
    "artist_name": "Sara Bareilles",
    "image_url": "https://i.scdn.co/image/2ba5eda7a37fbbf37fd97219276cfe6cd2d0531e",
    "artist_id": "2Sqr0DXoaYABbjBo9HaMkM",
    "year": 2008
  },
  {
    "song_id": "7tAXHZdp9UpcYrHn7MZqfo",
    "name": "Forever",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/7cd919d142944f1e1f914f1016a8a190d532b201",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/93efa2a4724ff18a0e2789bccd7a1b09184d732d",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2008
  },
  {
    "song_id": "16GHcGtW9Io7AuVdNmTjv3",
    "name": "Live Your Life - feat. Rihanna",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/1c1b9890a7e58bad59c3270fc54b00cbaa10d0b0",
    "artist_name": "T.I.",
    "image_url": "https://i.scdn.co/image/e6227cd8e3deae7d073cc1e261bfd6214fafab61",
    "artist_id": "4OBJLual30L7gRl5UkeRcT",
    "year": 2008
  },
  {
    "song_id": "6ScJMrlpiLfZUGtWp4QIVt",
    "name": "A Milli",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/93f77578033d2bc689929bca46fe723ca3262626",
    "artist_name": "Lil Wayne",
    "image_url": "https://i.scdn.co/image/1e95bd64e9bb56969ee86b5eb82e10573aade640",
    "artist_id": "55Aa2cqylxrFIXC767Z865",
    "year": 2008
  },
  {
    "song_id": "0CAfXk7DXMnon4gLudAp7J",
    "name": "Low - feat T-Pain",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/0a0af7aeb7b480d843ee8b87abf994e7ec59f90e",
    "artist_name": "Flo Rida",
    "image_url": "https://i.scdn.co/image/8af608406ecfa5ac1a2afc9fdb0f3b7c75c2fd3c",
    "artist_id": "0jnsk9HBra6NMjO2oANoPY",
    "year": 2008
  },
  {
    "song_id": "4P7VFiaZb3xrXoqGwZXC3J",
    "name": "Lollipop",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/2c2d58c9027ec59f8c0589dfbdf73547fa9f433e",
    "artist_name": "Lil Wayne",
    "image_url": "https://i.scdn.co/image/1e95bd64e9bb56969ee86b5eb82e10573aade640",
    "artist_id": "55Aa2cqylxrFIXC767Z865",
    "year": 2008
  },
  {
    "song_id": "0rFOs9paloAvEtzwDX1Kmc",
    "name": "Bubbly",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/72612b5052c1756565612e68a25ed3f79d2b0767",
    "artist_name": "Colbie Caillat",
    "image_url": "https://i.scdn.co/image/ff6c1f1b6b4d365787bc2fb9f1bcd2f8dff0b59e",
    "artist_id": "6aZyMrc4doVtZyKNilOmwu",
    "year": 2008
  },
  {
    "song_id": "1kusepF3AacIEtUTYrw4GV",
    "name": "Paper Planes",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/068d0d6783c01f30f427a9ecfb7a7efaeac8eb54",
    "artist_name": "M.I.A.",
    "image_url": "https://i.scdn.co/image/fc4bb2255f4c6ec0e355c186cc65cad3ecb9e39c",
    "artist_id": "0QJIPDAEDILuo8AIq3pMuU",
    "year": 2008
  },
  {
    "song_id": "41on8RwRh22IHcChAN2gm8",
    "name": "American Boy - feat. Kanye West",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/81ba33351474fa08ec2485dd15b340935744e38d",
    "artist_name": "Estelle",
    "image_url": "https://i.scdn.co/image/be9c7926f5f62c03af6adfff054ec8fabd1f8f8e",
    "artist_id": "5T0MSzX9RC5NA6gAI6irSn",
    "year": 2008
  },
  {
    "song_id": "0JEqGkvUiMTQmFY6sgL9kg",
    "name": "No One",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/af2ce9a7d8b09491969ff8d419b083fb385e716b",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/900da831f84a0666f9d1b480e41b04ad54ed048e",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2008
  },
  {
    "song_id": "1j3H54xdahpAkNI0q6S89q",
    "name": "With You",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/5d6e272de880d8fcb566c32af76efc6142491b5b",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/5aa69e803293fd3b4a88c346511e69ff6207391a",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2008
  },
  {
    "song_id": "3goSVuTt3fDYDP6kRnFwuL",
    "name": "Take A Bow",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/764f26cbd776c96c48e323ce1a3ffb0dbcadd5ba",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/07077803ac9099f2af00179d9da1acbde71dc092",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2008
  },
  {
    "song_id": "2iXBZ32Fz5VDCLeE0JIdX5",
    "name": "Better in Time",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/30697e68b9eb758cc3a8628a809ecc9a099be373",
    "artist_name": "Leona Lewis",
    "image_url": "https://i.scdn.co/image/399851482fe6b4a9cb8366c31d30db577acf09da",
    "artist_id": "5lKZWd6HiSCLfnDGrq9RAm",
    "year": 2008
  },
  {
    "song_id": "34ceTg8ChN5HjrqiIYCn9Q",
    "name": "Miss Independent",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/cc3184b672799fb469a763f03ed16643493cf4f2",
    "artist_name": "Ne-Yo",
    "image_url": "https://i.scdn.co/image/c3a1587bd94940db36b0d0529c6dbf384d87cfe7",
    "artist_id": "21E3waRsmPlU7jZsS13rcj",
    "year": 2008
  },
  {
    "song_id": "7lWF2mVr1KKbVnaT2nSlPo",
    "name": "Fall For You",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/03147af7cbd43837a82a5ec0f6dec15fbcc3b945",
    "artist_name": "Secondhand Serenade",
    "image_url": "https://i.scdn.co/image/0380b7eac4e2e30695de0c29696b02386ec33d3d",
    "artist_id": "6eouuqqGggqDlYRV63cgPo",
    "year": 2008
  },
  {
    "song_id": "1fLdeDTrJWNkwOeFyAVLvF",
    "name": "Good Life",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/a457865529dd36190d34bb6d71433868dc2525d9",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/ccfa4ba15970ede67ed434f37a0fcbeaba49ac1c",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2008
  },
  {
    "song_id": "5k3VjTwIsOjQ2woGz3Yx71",
    "name": "Can't Help But Wait",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/3a993e00de3013ca7311de3c8829aea17213ee2d",
    "artist_name": "Trey Songz",
    "image_url": "https://i.scdn.co/image/73baf789fae1fa0a8830233d4e08d8ce9c01be49",
    "artist_id": "2iojnBLj0qIMiKPvVhLnsH",
    "year": 2008
  },
  {
    "song_id": "0ua0Go4NN4Td7l0Zre6Ce3",
    "name": "Flashing Lights",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/8c7fe7afda3722bc34fd59673081a7930322dfe4",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/ccfa4ba15970ede67ed434f37a0fcbeaba49ac1c",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2008
  },
  {
    "song_id": "5j0McHPthKpOXRr3fBq8M0",
    "name": "Love in This Club",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/97b9273e77507f908ad8e641ab2ced7cee7ac1c1",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/fae4de96cc550e7f79c3c566283eed623292fc23",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2008
  },
  {
    "song_id": "2VOomzT6VavJOGBeySqaMc",
    "name": "Disturbia",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/934e2a2d9830a83c170e93ac968c1cd2cc537651",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/07077803ac9099f2af00179d9da1acbde71dc092",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2008
  },
  {
    "song_id": "1oHClQEgDmmbcEx12Kc5nZ",
    "name": "4 Minutes - feat. Justin Timberlake And Timbaland",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/8e58d4fdaf32e59d4ae59d2178eb049d624b8deb",
    "artist_name": "Madonna",
    "image_url": "https://i.scdn.co/image/006c443e86ad04f6ce8f9ade1da24e9b6f2e2132",
    "artist_id": "6tbjWDEIzxoDsBA1FuhfPW",
    "year": 2008
  },
  {
    "song_id": "2UNedS0c7DmhV7q6CnwcmT",
    "name": "Love Lockdown",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/7a6e2cb8d17f5b6cbe5e9e61de48e75e81f3e8cd",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/fde3a72cfc294d810ef0839777888984b8ea81ed",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2008
  },
  {
    "song_id": "6jaHYV4no6PAbyo89bybrx",
    "name": "Piece Of Me",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/f4c37438aa26a126d8a501de4faab0bd5b8e6a1f",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/c792ff23160ddd23636c482cf658da661a1bd017",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2008
  },
  {
    "song_id": "005lwxGU1tms6HGELIcUv9",
    "name": "I Kissed a Girl",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/7476e453ea5c2ae7d017d88dbd41280e4135f1aa",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/474bbdc16a4bc426e14b02a0bce87ae6b26343e8",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2008
  },
  {
    "song_id": "0ByMNEPAPpOR5H69DVrTNy",
    "name": "Don't Stop The Music",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/cc2059a6a2f45fd7b6e9584bf0d98856e64888c8",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/07077803ac9099f2af00179d9da1acbde71dc092",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2008
  },
  {
    "song_id": "5619Ojc6t9evEEs3B7Drhe",
    "name": "Shake It",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/3442660e4c0e7dd64718b98a347b2b3ab635354e",
    "artist_name": "Metro Station",
    "image_url": "https://i.scdn.co/image/941395e181a0601b735670fa8c6ee4eb1271d6f4",
    "artist_id": "7vXwfZyDp3spzIVNXDaTPN",
    "year": 2008
  },
  {
    "song_id": "4liGjmhCupa7RP9JaQELYx",
    "name": "Sweetest Girl (Dollar Bill)",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/43100de7fb83ab6767304d3002eb39129b2e32f6",
    "artist_name": "Wyclef Jean",
    "image_url": "https://i.scdn.co/image/1c0a551869e51abf4d3cff770154e903b58d7d3e",
    "artist_id": "7aBzpmFXB4WWpPl2F7RjBe",
    "year": 2008
  },
  {
    "song_id": "7dltD9eEX7X1zk8JJ9BS0e",
    "name": "Superstar (feat. Matthew Santos)",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/f5e64002a17122b87bdeb594e1b84e34013b55c4",
    "artist_name": "Lupe Fiasco",
    "image_url": "https://i.scdn.co/image/2864d84993ea213fb62ba2bac8c7424e3d75c43b",
    "artist_id": "01QTIT5P1pFP3QnnFSdsJf",
    "year": 2008
  },
  {
    "song_id": "7bprRkhvOXWmWpqOrEWbXu",
    "name": "So What",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/4acc6b8ec1c9068c9af69c0593a6bc01a443a4ad",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/9f51e9ef71ca7073707d77f4780fd3ca62baf107",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2008
  },
  {
    "song_id": "48s0QHJgmiNsth3WEO6U4M",
    "name": "Stop And Stare",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/9c974e5f718d807a4112f4c269093bf70b6cff34",
    "artist_name": "OneRepublic",
    "image_url": "https://i.scdn.co/image/474fc080abc6c1f4abed666ea3ea0d8b83dd4c7d",
    "artist_id": "5Pwc4xIPtQLFEnJriah9YJ",
    "year": 2008
  },
  {
    "song_id": "66TRwr5uJwPt15mfFkzhbi",
    "name": "Crank That (Soulja Boy)",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/eae0033ca17ef3c9bdcc058395062a85d6f858a2",
    "artist_name": "Soulja Boy",
    "image_url": "https://i.scdn.co/image/9a823bdad02e7416faa652ad04f1ef6476886623",
    "artist_id": "6GMYJwaziB4ekv1Y6wCDWS",
    "year": 2008
  },
  {
    "song_id": "5dANgSy7v091dhiPnEXNrf",
    "name": "Put On",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/e74d4e328881639d46a750138abd7da0c990a04d",
    "artist_name": "Jeezy",
    "image_url": "https://i.scdn.co/image/2c46a5db2c0f7700500511b24cdef808fee433c9",
    "artist_id": "4yBK75WVCQXej1p04GWqxH",
    "year": 2008
  },
  {
    "song_id": "1CrxDKnx34D9zoAIvf7B9H",
    "name": "Womanizer",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/b0aff58f3f696208d57c04416aa2852df735034d",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/7d1d31562dc63790dd784414c1b0d6b13c5ea7da",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2008
  },
  {
    "song_id": "3pxCXv0NACYfYnLjFcQW5D",
    "name": "Addicted",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/c659a53ad8f5e808972ce7eb4ff97bbe56905013",
    "artist_name": "Saving Abel",
    "image_url": "https://i.scdn.co/image/aa67a0f00a30af816abf9bbe8be535c97c04ebd9",
    "artist_id": "2AVVj8ezW2mJ0v8u7XydiF",
    "year": 2008
  },
  {
    "song_id": "0DdpxWfVvUGgkJv5536tiF",
    "name": "Sexy Can I feat. Yung Berg",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/97aa12a3fbed22ffc199e9c3c604c3c95bbd2a19",
    "artist_name": "Ray J",
    "image_url": "https://i.scdn.co/image/b1034f729af224ee03c54576c4dc79515b9464a5",
    "artist_id": "6gbGGM0E8Q1hE511psqxL0",
    "year": 2008
  },
  {
    "song_id": "1y4eb6hmAvsqlDOl3fx9kk",
    "name": "Hot N Cold",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/3fe5d21a98feb4637cdd1ef38f03555f2eb471f8",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/474bbdc16a4bc426e14b02a0bce87ae6b26343e8",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2008
  },
  {
    "song_id": "3nJjIYchBNkYm7B8XfJf5N",
    "name": "Suffocate",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/16c8f7c8cfd3802d6e2c36449d271f29881baf92",
    "artist_name": "J. Holiday",
    "image_url": "https://i.scdn.co/image/87db06e4745135412d98f1f6f250f4d4bb3dea40",
    "artist_id": "7G6hXrjGpi6I7waNl4wxAk",
    "year": 2008
  },
  {
    "song_id": "0OYcEfskah1egYHjYRvbg1",
    "name": "Shadow Of The Day",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/35319a6f5e4d4f3ce5fe9b6750e570ff13978fe4",
    "artist_name": "Linkin Park",
    "image_url": "https://i.scdn.co/image/de68225c52e9646e82d9ce05f588b23878324a52",
    "artist_id": "6XyY86QOPPrYVGvF9ch6wz",
    "year": 2008
  },
  {
    "song_id": "6wZI4mdT8JwXgkGURnBQiq",
    "name": "Say",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/950c16d8c62231e1123cb1752d6f3953aa84bfa2",
    "artist_name": "John Mayer",
    "image_url": "https://i.scdn.co/image/7860c813e3ec5cc974a39479e282f21f7841fbaa",
    "artist_id": "0hEurMDQu99nJRq8pTxO14",
    "year": 2008
  },
  {
    "song_id": "68Q1r5zDnnYaHkh80yal4A",
    "name": "You're Gonna Miss This",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/03585849d29954f270959cef0d7257492740e662",
    "artist_name": "Trace Adkins",
    "image_url": "https://i.scdn.co/image/5e0ef0d17dfbb6af17485ce56d4cc6c99169545e",
    "artist_id": "79FMDwzZQxHgSkIYBl3ODU",
    "year": 2008
  },
  {
    "song_id": "1uigwk5hNV84zRd5YQQRTk",
    "name": "Pocketful of Sunshine",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/00c17d884ab8dd4957e6405a81274d0094c35aae",
    "artist_name": "Natasha Bedingfield",
    "image_url": "https://i.scdn.co/image/1d011f9e10eb142f5e2245e1f556113a8f7ade33",
    "artist_id": "7o95ZoZt5ZYn31e9z1Hc0a",
    "year": 2008
  },
  {
    "song_id": "20ORwCJusz4KS2PbTPVNKo",
    "name": "Leavin'",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/2bb09fd509770312ce064382cf677dcf2ec6d28a",
    "artist_name": "Jesse McCartney",
    "image_url": "https://i.scdn.co/image/70e1d8d621464ebd1e04bcd9e2c882d6ccda1c36",
    "artist_id": "2Hjj68yyUPiC0HKEOigcEp",
    "year": 2008
  },
  {
    "song_id": "0uybt73QFXaLCoxuVf6fhm",
    "name": "It's Not My Time",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/9182f38b167c1c64634ecf0e600c9fe967ad48d4",
    "artist_name": "3 Doors Down",
    "image_url": "https://i.scdn.co/image/736b210803a96508f3921d5a0fdef4208815e7d8",
    "artist_id": "2RTUTCvo6onsAnheUk3aL9",
    "year": 2008
  },
  {
    "song_id": "7iu0WYLdo4yksKf3seaxzI",
    "name": "Hate That I Love You",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/6ab10bc1e2fcd7f3194d5c56c1a7dd894200ef8c",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/07077803ac9099f2af00179d9da1acbde71dc092",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2008
  },
  {
    "song_id": "4wCD0qOFNRu1Q6O3N6ycm4",
    "name": "Crush",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/f784cf497ef75fa3dc47308c0d0b6a96fee638bf",
    "artist_name": "David Archuleta",
    "image_url": "https://i.scdn.co/image/aa09d883d01f05fc1027e7a8a3975b22e9bec9bf",
    "artist_id": "2C9n4tQgNLhHPhSCmdsQnk",
    "year": 2008
  },
  {
    "song_id": "3zcmWxxUA7Em7X3IBqZGUo",
    "name": "In Love With a Girl",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/bca2c54c891623a69aee1a198e4fa355c640dfbb",
    "artist_name": "Gavin DeGraw",
    "image_url": "https://i.scdn.co/image/92ecccc0006acecfd1ca38c4ab69bc11c709c5d0",
    "artist_id": "5DYAABs8rkY9VhwtENoQCz",
    "year": 2008
  },
  {
    "song_id": "0EHR9OObFtjlhQB8wSt1m7",
    "name": "Mrs. Officer",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/54b8355f9ee999a9632e2a0c051210061728ee20",
    "artist_name": "Lil Wayne",
    "image_url": "https://i.scdn.co/image/1e95bd64e9bb56969ee86b5eb82e10573aade640",
    "artist_id": "55Aa2cqylxrFIXC767Z865",
    "year": 2008
  },
  {
    "song_id": "2H1l4JczUDbiWfQtQElB05",
    "name": "Touch My Body",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/1ba0a37dc982e7ee2d51f6272d66663015c4accd",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/5b7be58c09b9f770e2d0f17f293b41e6f050f0ae",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 2008
  },
  {
    "song_id": "46lIt6GmL0VkkbuUyGDbCs",
    "name": "Got Money",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/644dad639dd6461f7aa5e679004f3da5d0146932",
    "artist_name": "Lil Wayne",
    "image_url": "https://i.scdn.co/image/1e95bd64e9bb56969ee86b5eb82e10573aade640",
    "artist_id": "55Aa2cqylxrFIXC767Z865",
    "year": 2008
  },
  {
    "song_id": "0dQQWHlXvuw4UyHcAvhfhB",
    "name": "When I Grow Up",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/415bfa4593644e8c3b80ecc3cd573b942a1b35ce",
    "artist_name": "The Pussycat Dolls",
    "image_url": "https://i.scdn.co/image/0803ea30633af1594717392fca77acb33873ec7e",
    "artist_id": "6wPhSqRtPu1UhRCDX5yaDJ",
    "year": 2008
  },
  {
    "song_id": "2vVc2G9w2JteBgxpSUVwX5",
    "name": "Let It Rock",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/d7081d0afbb89c01811ebe34286f85771865e3d5",
    "artist_name": "Kevin Rudolf",
    "image_url": "https://i.scdn.co/image/91459473442d011b2ef60caecd697a69c3c4ceaf",
    "artist_id": "0Chxmm4XMM87mJOHvyiUzL",
    "year": 2008
  },
  {
    "song_id": "5OGkKx8jP0A5KSULEc6XYZ",
    "name": "Realize",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/565cc8ee03b2d442745cf4b62fddfa1010bdcc56",
    "artist_name": "Colbie Caillat",
    "image_url": "https://i.scdn.co/image/ff6c1f1b6b4d365787bc2fb9f1bcd2f8dff0b59e",
    "artist_id": "6aZyMrc4doVtZyKNilOmwu",
    "year": 2008
  },
  {
    "song_id": "27mhCGdAA8gM7b33KIiB3k",
    "name": "Cyclone",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/c97e6d5af00e066c2da3d444692a020727dfb09c",
    "artist_name": "Baby Bash",
    "image_url": "https://i.scdn.co/image/1cef2536e666b1ad93a334ba28a62644833ad15b",
    "artist_id": "12PSlydMSjEHzSCj9X5qv7",
    "year": 2008
  },
  {
    "song_id": "32rHadAy6b8xYgSq22C0r8",
    "name": "All Summer Long",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/cf7b69d2015b50969a52db105435a7a7a6411fc5",
    "artist_name": "Kid Rock",
    "image_url": "https://i.scdn.co/image/eb249b265209e6c86076c5a5ee9640ed54f3ec84",
    "artist_id": "7dOBabd5O4CvKrg4iriHTM",
    "year": 2008
  },
  {
    "song_id": "7LLouD7gdDYmmar1bfaF8V",
    "name": "Bleeding Love",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/e459e42de529dfca7298f2769e6158b7621b2ea8",
    "artist_name": "Leona Lewis",
    "image_url": "https://i.scdn.co/image/f1939c40b74667c4938e7f8295117f46e3e37f96",
    "artist_id": "5lKZWd6HiSCLfnDGrq9RAm",
    "year": 2008
  },
  {
    "song_id": "2E90KUsor4U2abOJGFKtfx",
    "name": "Heaven Sent",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/a89f6647dde788d62322b70bc3dbd214e97fb2e4",
    "artist_name": "Keyshia Cole",
    "image_url": "https://i.scdn.co/image/dcfd822030258ead49f07b413f01195535e3cc6f",
    "artist_id": "1vfezMIyCr4XUdYRaKIKi3",
    "year": 2008
  },
  {
    "song_id": "4yHFxB8kzzeuNvoouR8N0N",
    "name": "Damaged",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/75c40c15476d542b7b4c11befa50718eeba02eff",
    "artist_name": "Danity Kane",
    "image_url": "https://i.scdn.co/image/97bed067fa23ef7565495e501b03db25a10f8648",
    "artist_id": "3FD9VAd0ppjmKbfxF4LzUh",
    "year": 2008
  },
  {
    "song_id": "4GXH9A6fzuE0MNtqtzjhLg",
    "name": "My Life",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/0100f9d1be4d88b77993030e92349d95ff4d54c4",
    "artist_name": "The Game",
    "image_url": "https://i.scdn.co/image/bcf926319a3e758c6514c230e3c449a99395a999",
    "artist_id": "0NbfKEOTQCcwd6o7wSDOHI",
    "year": 2008
  },
  {
    "song_id": "1dObJGQUgJ7tWxJ0byON6W",
    "name": "I Remember",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/48dd7b337452e50b2d55fdf7920d5fc1f0e9def9",
    "artist_name": "Keyshia Cole",
    "image_url": "https://i.scdn.co/image/dcfd822030258ead49f07b413f01195535e3cc6f",
    "artist_id": "1vfezMIyCr4XUdYRaKIKi3",
    "year": 2008
  },
  {
    "song_id": "6ucR4KfvsBFWCMVFDvyKKl",
    "name": "Apologize",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/c56179c950e847b23fc42e34f1de4427ca37e66c",
    "artist_name": "Timbaland",
    "image_url": "https://i.scdn.co/image/1d7dcf505338d7c897becfa753315133dfd1aaf8",
    "artist_id": "5Y5TRrQiqgUO4S36tzjIRZ",
    "year": 2008
  },
  {
    "song_id": "5ecZWU5uQOiCVSnPxBZNmT",
    "name": "7 Things - Single Version",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/4cb0a330f54f859f1ffdb3d9895f34c1f14fbfb4",
    "artist_name": "Miley Cyrus",
    "image_url": "https://i.scdn.co/image/f5758f7d3fe86d9db5073fd96691794ed6da6d68",
    "artist_id": "5YGY8feqx7naU7z4HrwZM6",
    "year": 2008
  },
  {
    "song_id": "544wx7ulaLbPEecrnYr5uZ",
    "name": "Kiss Kiss",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/39d0fc4a19c9662f1cc83366d9a8884b2939aa8c",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/5aa69e803293fd3b4a88c346511e69ff6207391a",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2008
  },
  {
    "song_id": "6AdLyvisRKHLAwARaZMGr6",
    "name": "Take You There",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/cec20424e09c6226e2f5a9821c2843beb0e203cc",
    "artist_name": "Sean Kingston",
    "image_url": "https://i.scdn.co/image/15f0c6aaf606784a9f5858a0e06dfa20721bc8ba",
    "artist_id": "6S0dmVVn4udvppDhZIWxCr",
    "year": 2008
  },
  {
    "song_id": "3wkNK8tJZ2stygNJm8RCRD",
    "name": "I Luv Your Girl",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/d0c7421241eec3264ab06e37e46a411c1cfda711",
    "artist_name": "The-Dream",
    "image_url": "https://i.scdn.co/image/fb258fc9576a27d20db2af4d5b41edff48324d0d",
    "artist_id": "1W3FSF1BLpY3hlVIgvenLz",
    "year": 2008
  },
  {
    "song_id": "7qpDqr5TxcTlOHG0k7moGt",
    "name": "Sorry",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/73cd65e860467484b8fbef3eb2be5001fde1963f",
    "artist_name": "Buckcherry",
    "image_url": "https://i.scdn.co/image/ca64a076ee9e91b6716287ecd910c37f764abcca",
    "artist_id": "0yN7xI1blow9nYIK0R8nM7",
    "year": 2008
  },
  {
    "song_id": "0NWJPV3utX0J4tfwzyotNB",
    "name": "Like You'll Never See Me Again - Main",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/6324366100990bd997ca301c14e8a7c8a8011541",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/900da831f84a0666f9d1b480e41b04ad54ed048e",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2008
  },
  {
    "song_id": "6m1sJMWeAkCG5eECKaGFew",
    "name": "Can't Believe It",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/19e5f58e8a3794cbdd24236bca32c83c1a1e9872",
    "artist_name": "T-Pain",
    "image_url": "https://i.scdn.co/image/09dab9181029a1c0fabf5cb0c57fb2b15fa3b37d",
    "artist_id": "3aQeKQSyrW4qWr35idm0cy",
    "year": 2008
  },
  {
    "song_id": "0G71WvIkF2K6HViJeFvOcM",
    "name": "See You Again",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/9454a44f6fd7dd1f9d1f78c7f6e41eeb18c72470",
    "artist_name": "Miley Cyrus",
    "image_url": "https://i.scdn.co/image/a396ca63588a38be5a56b6b086405736089ff92a",
    "artist_id": "5YGY8feqx7naU7z4HrwZM6",
    "year": 2008
  },
  {
    "song_id": "2IBF9lQ9lgVai8LkskI5L7",
    "name": "Get Like Me",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/a93152911c468976733b1eaea689603d7ed7d4c2",
    "artist_name": "David Banner",
    "image_url": "https://i.scdn.co/image/44d59886bf736187b7bf1974f313f0b864e9b2db",
    "artist_id": "7jFaoqWPhYLrKzjzlpXmUO",
    "year": 2008
  },
  {
    "song_id": "33vzOPcd9FRirYGlCu32x4",
    "name": "Just Fine",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/823d5635d64f9732d9f810889211e0351f4f96cb",
    "artist_name": "Mary J. Blige",
    "image_url": "https://i.scdn.co/image/b16cd3ceec3781a754d05cbc133a9badc427b2d4",
    "artist_id": "1XkoF8ryArs86LZvFOkbyr",
    "year": 2008
  },
  {
    "song_id": "2tVCFmn2zKg2wGXS1TCIIA",
    "name": "Feels Like Tonight",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/3a025bc17119da2a3edff946ef869d45fe0845db",
    "artist_name": "Daughtry",
    "image_url": "https://i.scdn.co/image/8a18d29456ca7a3e387b89f0d00e342a82150310",
    "artist_id": "5P5FTygHyx2G57oszR3Wot",
    "year": 2008
  },
  {
    "song_id": "4NOZ35Dhucr6UlVyLOtktd",
    "name": "Dangerous",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/68ac88739f9735fd4d9616e33edd4d0888b9777f",
    "artist_name": "Kardinal Offishall",
    "image_url": "https://i.scdn.co/image/d96f0f1aef4d822c6e56e2f984af89c3bebe5a15",
    "artist_id": "5P2rwRBgIN450RaJxdjYdA",
    "year": 2008
  },
  {
    "song_id": "0eIfBjs1pMiRNkbngEATQi",
    "name": "Tattoo",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/c26f9d10eb420fb010372c93a0b1a439cbe07899",
    "artist_name": "Jordin Sparks",
    "image_url": "https://i.scdn.co/image/e3edd1c44f4ad0ec1b88aafb5b84c57d84c57f2b",
    "artist_id": "2AQjGvtT0pFYfxR3neFcvz",
    "year": 2008
  },
  {
    "song_id": "0FzNsTG8naVP0O4HTnM7Bp",
    "name": "Lolli Lolli (Pop That Body) - Explicit Album Version",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/2f5a98d601d89a6026e4bd722d32b3c2c3c3877f",
    "artist_name": "Three 6 Mafia",
    "image_url": "https://i.scdn.co/image/50a57b259274a7a3a7d7611af3e793bcbb666584",
    "artist_id": "26s8LSolLfCIY88ysQbIuT",
    "year": 2008
  },
  {
    "song_id": "49ppqF73rOz5Nyt1paRxIF",
    "name": "Love Like This",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/d0306f00146a0483d7a3552ebdef324e90813ff0",
    "artist_name": "Natasha Bedingfield",
    "image_url": "https://i.scdn.co/image/1d011f9e10eb142f5e2245e1f556113a8f7ade33",
    "artist_id": "7o95ZoZt5ZYn31e9z1Hc0a",
    "year": 2008
  },
  {
    "song_id": "3MldzywZOH4Uci6bq9noyJ",
    "name": "What You Got - Main",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/beacdbb6ab63738c58a89a51df68e5959e07bc2d",
    "artist_name": "Colby O'Donis",
    "image_url": "https://i.scdn.co/image/8fef6aa8262df83c0f3bb1be8e4c49e212d4e57d",
    "artist_id": "7fObcBw9VM3x7ntWKCYl0z",
    "year": 2008
  },
  {
    "song_id": "6avyV3xlz2UFeiAUZ0SqyT",
    "name": "The Time Of My Life",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/d4925c34b56b6919ae7696e0283d49d25dc8ef12",
    "artist_name": "David Cook",
    "image_url": "https://i.scdn.co/image/51f91a8680e6aa1f996ea831afd1db058972456d",
    "artist_id": "4SQxI8xg6LcSWL3KuGVHLa",
    "year": 2008
  },
  {
    "song_id": "3n5qF6aprwEIjHWuH5jECM",
    "name": "The Boss",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/df532cdb6ae4c4939eba55b24718c42326fb8232",
    "artist_name": "Rick Ross",
    "image_url": "https://i.scdn.co/image/31b0b3c123b26450fbc3fcafe1ed068bcb6cb891",
    "artist_id": "1sBkRIssrMs1AbVkOJbc7a",
    "year": 2008
  },
  {
    "song_id": "6fXzclKMWcYFIvRPREufPf",
    "name": "Hypnotized",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/3475b7eb1bbb44090794c8ccbf7e00940cfa22b3",
    "artist_name": "Plies",
    "image_url": "https://i.scdn.co/image/939d5d82dc28c7ebd4b40e4799e321efde0c91b8",
    "artist_id": "3jksrX4oBklxR78ft8gv3j",
    "year": 2008
  },
  {
    "song_id": "5RM4iGrNOyeKLTcMv2FPc9",
    "name": "The Way I Are",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/3d4dc50ce1ffcd968e532406c57844032b6b1bf4",
    "artist_name": "Timbaland",
    "image_url": "https://i.scdn.co/image/1d7dcf505338d7c897becfa753315133dfd1aaf8",
    "artist_id": "5Y5TRrQiqgUO4S36tzjIRZ",
    "year": 2008
  },
  {
    "song_id": "4dOXc4Suk5eig0jiCqCN5Y",
    "name": "Love Remains The Same",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/78127ca561e6d38657ec44d01f3af9490b2a5a59",
    "artist_name": "Gavin Rossdale",
    "image_url": "https://i.scdn.co/image/e4f1877891159eccc588a1cd1ce0e198da00dc8f",
    "artist_id": "6lBam1B1t8wpnH7pJHWnj6",
    "year": 2008
  },
  {
    "song_id": "11WuptzKtcVfPMYaf5aook",
    "name": "She Got It",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/3df64e509f98cdaf1294d00e7f36549d4fc97afc",
    "artist_name": "2 Pistols",
    "image_url": "https://i.scdn.co/image/88bc77bc3da2a94abe4640cbea251cfe5ba42882",
    "artist_id": "7JBMlJmJpOk87BZTdbYSHD",
    "year": 2008
  },
  {
    "song_id": "65D95SmygxGCQGiI64eaZf",
    "name": "Independent - feat. Lil' Boosie and Lil' Phat Explicit",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/6993b71c71a5f5889a1e3fe2cc99edb73b081b05",
    "artist_name": "Webbie",
    "image_url": "https://i.scdn.co/image/fb526119ceead01a8d7da70e4663da351c4dfcc7",
    "artist_id": "6aIm51fHkokqlJn2vzNTH8",
    "year": 2008
  },
  {
    "song_id": "5o4W6yWSJD9e9Ea8YC9WjF",
    "name": "One Step at a Time",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/f790e2b560a071d1c3755e34b6a35f8103d124c2",
    "artist_name": "Jordin Sparks",
    "image_url": "https://i.scdn.co/image/b573369f81d798da82b8d34f97208064b2cbb8e5",
    "artist_id": "2AQjGvtT0pFYfxR3neFcvz",
    "year": 2008
  },
  {
    "song_id": "0GX5oOKtU0nnx5OkhenW2i",
    "name": "Closer",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/b7dd4bc264752d10f798c33122883fcb6e932cb2",
    "artist_name": "Ne-Yo",
    "image_url": "https://i.scdn.co/image/5f7d0e7f1ff0880ec51f5d657772e6928e7616fb",
    "artist_id": "21E3waRsmPlU7jZsS13rcj",
    "year": 2008
  },
  {
    "song_id": "1wva1IlWLDNLgwipUWcmjy",
    "name": "Sensual Seduction",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/905eb3b505af6ccb06402186d98becca3e37f3fa",
    "artist_name": "Snoop Dogg",
    "image_url": "https://i.scdn.co/image/5e290a843496d1549b5fc2dbe3cc453f826ca98c",
    "artist_id": "7hJcb9fa4alzcOq3EaNPoG",
    "year": 2008
  },
  {
    "song_id": "5rVExY4YVH3gs7k4ZHkqag",
    "name": "No Air",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/124f55e3b5258add14b5c48679375cdb2ead1a1d",
    "artist_name": "Jordin Sparks",
    "image_url": "https://i.scdn.co/image/0c0f5258444f48b2cffc5858834010b39f683235",
    "artist_id": "2AQjGvtT0pFYfxR3neFcvz",
    "year": 2008
  },
  {
    "song_id": "1hB8nXYxQg5v5nsFDwKRmE",
    "name": "In The Ayer - feat. will.I.am",
    "popularity": 29,
    "preview_url": "https://p.scdn.co/mp3-preview/68f08cc14154981727f69adea23d52f1eff11fda",
    "artist_name": "Flo Rida",
    "image_url": "https://i.scdn.co/image/02da9023d4faccb8cc7d10dad6252c7508afe568",
    "artist_id": "0jnsk9HBra6NMjO2oANoPY",
    "year": 2008
  },
  {
    "song_id": "1JeCCDNpTAryVeUddyuD3N",
    "name": "Clumsy - Collipark Remix",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/0e4673f70644f8a99824da671046849ca7023e08",
    "artist_name": "Fergie",
    "image_url": "https://i.scdn.co/image/0613d1754ad98c09674b08e10ba247b4b7358a00",
    "artist_id": "3r17AfJCCUqC9Lf0OAc73G",
    "year": 2008
  },
  {
    "song_id": "1EzrEOXmMH3G43AXT1y7pA",
    "name": "I'm Yours",
    "popularity": 82,
    "preview_url": "https://p.scdn.co/mp3-preview/2fee94f72527b42f3af8cb003a14f2ac16a97c5e",
    "artist_name": "Jason Mraz",
    "image_url": "https://i.scdn.co/image/da78aa2fadacfabdc80addc724f6c01c9752aa02",
    "artist_id": "4phGZZrJZRo4ElhRtViYdl",
    "year": 2009
  },
  {
    "song_id": "4JehYebiI9JE8sR8MisGVb",
    "name": "Halo",
    "popularity": 80,
    "preview_url": "https://p.scdn.co/mp3-preview/cc825e9d472a1734d5b1130d176e6cfa03145dab",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/12092a0558433cbaccd51301daeb6b31d9f70f9e",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2009
  },
  {
    "song_id": "1mea3bSkSGXuIRvnydlB5b",
    "name": "Viva La Vida",
    "popularity": 80,
    "preview_url": "https://p.scdn.co/mp3-preview/2cfb0509541df344f3096ad8fbfcf79a9dd155b4",
    "artist_name": "Coldplay",
    "image_url": "https://i.scdn.co/image/0e522bf592ab4a7dafdf72cef035e3515208e4e3",
    "artist_id": "4gzpq5DPGxSnKTe4SA8HAU",
    "year": 2009
  },
  {
    "song_id": "5VGlqQANWDKJFl0MBG3sg2",
    "name": "Use Somebody",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/40ffde5ac406c5086d41a873dbfaaefecd72fc01",
    "artist_name": "Kings Of Leon",
    "image_url": "https://i.scdn.co/image/da146220392638a94a05d41c73db72c1957a00e0",
    "artist_id": "2qk9voo8llSGYcZ6xrBzKx",
    "year": 2009
  },
  {
    "song_id": "6HSqyfGnsHYw9MmIpa9zlZ",
    "name": "Forever",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/aaf8ff66888beea73922c83d643eca4d6c50d697",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/a6b8a73eadfad92e0f799b1e01194659478b4212",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2009
  },
  {
    "song_id": "5R9a4t5t5O0IsznsrKPVro",
    "name": "Single Ladies (Put a Ring on It)",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/e589deb76167b3d447d0c9d22ba3c8abae7c8fb5",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/12092a0558433cbaccd51301daeb6b31d9f70f9e",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2009
  },
  {
    "song_id": "4IoYz8XqqdowINzfRrFnhi",
    "name": "You Found Me",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/c23076c9b884871d312de60644acc955309a1346",
    "artist_name": "The Fray",
    "image_url": "https://i.scdn.co/image/66d9f5b888987a86d1108c5ca8a7712280bd0363",
    "artist_id": "0zOcE3mg9nS6l3yxt1Y0bK",
    "year": 2009
  },
  {
    "song_id": "64yrDBpcdwEdNY9loyEGbX",
    "name": "21 Guns",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/0448d746abab9886f0c49a50787c42ed15978132",
    "artist_name": "Green Day",
    "image_url": "https://i.scdn.co/image/52574f1a6e167ebc9806c5ff90be72366ca513d6",
    "artist_id": "7oPftvlwr6VrsViSDV7fJY",
    "year": 2009
  },
  {
    "song_id": "4dGJf1SER1T6ooX46vwzRB",
    "name": "Chicken Fried",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/ef3120e9e49db6b4e3e986fddf496da1b6538c84",
    "artist_name": "Zac Brown Band",
    "image_url": "https://i.scdn.co/image/6e8b8292dd62fb6daf174b5d02232c8700e445b2",
    "artist_id": "6yJCxee7QumYr820xdIsjo",
    "year": 2009
  },
  {
    "song_id": "5M8nEsWqUkwUwCp58ydE4W",
    "name": "Run This Town [Jay-Z + Rihanna + Kanye West]",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/46a66e742790b773a62daa7eb1813e2ecba50ae3",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/9b4c33de835b334219a91721898435706675ac40",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2009
  },
  {
    "song_id": "3tvWMBIblzT5FSjKtIeRR1",
    "name": "Whatever You Like",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/07db047e7c61116268064109c4e2043c0826011e",
    "artist_name": "T.I.",
    "image_url": "https://i.scdn.co/image/e6227cd8e3deae7d073cc1e261bfd6214fafab61",
    "artist_id": "4OBJLual30L7gRl5UkeRcT",
    "year": 2009
  },
  {
    "song_id": "4QJLKU75Rg4558f4LbDBRi",
    "name": "If Today Was Your Last Day",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/b7ffb6e50c7fddd0b0ab8d9a6442edd19bc87d07",
    "artist_name": "Nickelback",
    "image_url": "https://i.scdn.co/image/adaef99d19f8532744e155d8bf7cc679969661dc",
    "artist_id": "6deZN1bslXzeGvOLaLMOIF",
    "year": 2009
  },
  {
    "song_id": "57uX2vR9j9DNiANDYfXw1i",
    "name": "Never Say Never",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/9eb1582a540de7d27d562042ce8b5e9ad1591d29",
    "artist_name": "The Fray",
    "image_url": "https://i.scdn.co/image/66d9f5b888987a86d1108c5ca8a7712280bd0363",
    "artist_id": "0zOcE3mg9nS6l3yxt1Y0bK",
    "year": 2009
  },
  {
    "song_id": "16GHcGtW9Io7AuVdNmTjv3",
    "name": "Live Your Life - feat. Rihanna",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/1c1b9890a7e58bad59c3270fc54b00cbaa10d0b0",
    "artist_name": "T.I.",
    "image_url": "https://i.scdn.co/image/e6227cd8e3deae7d073cc1e261bfd6214fafab61",
    "artist_id": "4OBJLual30L7gRl5UkeRcT",
    "year": 2009
  },
  {
    "song_id": "6Wx88Mv6b9ofjKMKkdwOJd",
    "name": "New Divide",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/9416a244ad42fc797e60c88cdf291faae09c7203",
    "artist_name": "Linkin Park",
    "image_url": "https://i.scdn.co/image/1d04f922a50d843fbfeccf52ca76926e5af02f2f",
    "artist_id": "6XyY86QOPPrYVGvF9ch6wz",
    "year": 2009
  },
  {
    "song_id": "5h9pB5af60LWJBrG1HCHsU",
    "name": "Heartless",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/1760ca4f5d1af22d5247b9c23e2c4ece34d5c2da",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/fde3a72cfc294d810ef0839777888984b8ea81ed",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2009
  },
  {
    "song_id": "26NX1wPt1TRCH536yocd6i",
    "name": "If I Were a Boy",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/9f7d31713b7558bd9553cd4d28dcf6a9a32b4ad0",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/6cad233151a2b6e87166ae0c5dfc28491322c3ea",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2009
  },
  {
    "song_id": "0CwYG1UnRmOx8Q1EzElCIL",
    "name": "Second Chance",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/5e260828a66a0c3ff02290aefb6a0989377cb957",
    "artist_name": "Shinedown",
    "image_url": "https://i.scdn.co/image/675580573a9810ef2e7c588a05498e6f8a8833e8",
    "artist_id": "70BYFdaZbEKbeauJ670ysI",
    "year": 2009
  },
  {
    "song_id": "5Q0Nhxo0l2bP3pNjpGJwV1",
    "name": "Party In The U.S.A.",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/b0a8120cf679d65200d1fc584434c3facdf0c896",
    "artist_name": "Miley Cyrus",
    "image_url": "https://i.scdn.co/image/bf45af545628768f08bb53eab6da54a15dbf841f",
    "artist_id": "5YGY8feqx7naU7z4HrwZM6",
    "year": 2009
  },
  {
    "song_id": "2zJZwWF7BTGIIvrAlgzJEx",
    "name": "Whatcha Say",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/8053542b10bd44660ba25e413a5981331f02d9c5",
    "artist_name": "Jason Derulo",
    "image_url": "https://i.scdn.co/image/c9adfaf5822f8b03d33d9912109440c892119ac8",
    "artist_id": "07YZf4WDAMNwqr4jfgOZ8y",
    "year": 2009
  },
  {
    "song_id": "06T10fEzN8ZCcqzQZYA184",
    "name": "Gotta Be Somebody",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/fb22b33d70bd1f6e2b6a00058431f590698271c8",
    "artist_name": "Nickelback",
    "image_url": "https://i.scdn.co/image/adaef99d19f8532744e155d8bf7cc679969661dc",
    "artist_id": "6deZN1bslXzeGvOLaLMOIF",
    "year": 2009
  },
  {
    "song_id": "1FKxKGONukVFXWVJxAKmlz",
    "name": "Sweet Dreams",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/ca4ab61ec7f43d33afde3851cf16f702a72c511c",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/6cad233151a2b6e87166ae0c5dfc28491322c3ea",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2009
  },
  {
    "song_id": "6ihL9TjfRjadfEePzXXyVF",
    "name": "Gives You Hell",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/8702491bf29ebcac29839605e6dee8a084ab2473",
    "artist_name": "The All-American Rejects",
    "image_url": "https://i.scdn.co/image/76f9eb48b5d88e54cff15bdbcf0c098ebd0b6b75",
    "artist_id": "3vAaWhdBR38Q02ohXqaNHT",
    "year": 2009
  },
  {
    "song_id": "1k2NovMHKHhgGsHxg0aYAS",
    "name": "Dead And Gone - feat. Justin Timberlake",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/81563d51eb110156756d4c181af1b6f8096d2775",
    "artist_name": "T.I.",
    "image_url": "https://i.scdn.co/image/e6227cd8e3deae7d073cc1e261bfd6214fafab61",
    "artist_id": "4OBJLual30L7gRl5UkeRcT",
    "year": 2009
  },
  {
    "song_id": "5RzFJd6W40SDTyZkX6xx45",
    "name": "I Know You Want Me (Calle Ocho)",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/6a84664e7e428dbc8c5c835294a35a5ec7f8b6b3",
    "artist_name": "Pitbull",
    "image_url": "https://i.scdn.co/image/5f0cd403ceabdcbcec35e002c16e7e4c7eb8212e",
    "artist_id": "0TnOYISbd1XYRBk9myaseg",
    "year": 2009
  },
  {
    "song_id": "4NpDZPwSXmL0cCTaJuVrCw",
    "name": "Birthday Sex",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/67bf4e548396e7215dc5d5a6e84b4c5269ce3354",
    "artist_name": "Jeremih",
    "image_url": "https://i.scdn.co/image/efa4111c1d91fbd3706136793e8706dcf2fe9143",
    "artist_id": "3KV3p5EY4AvKxOlhGHORLg",
    "year": 2009
  },
  {
    "song_id": "34ceTg8ChN5HjrqiIYCn9Q",
    "name": "Miss Independent",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/cc3184b672799fb469a763f03ed16643493cf4f2",
    "artist_name": "Ne-Yo",
    "image_url": "https://i.scdn.co/image/c3a1587bd94940db36b0d0529c6dbf384d87cfe7",
    "artist_id": "21E3waRsmPlU7jZsS13rcj",
    "year": 2009
  },
  {
    "song_id": "5Ssv6DaKrW0HczVqx6zXdl",
    "name": "Diva",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/2614f376b68ccc747c28f0f84cbd6845ad69f50e",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/6cad233151a2b6e87166ae0c5dfc28491322c3ea",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2009
  },
  {
    "song_id": "5vlEg2fT4cFWAqU5QptIpQ",
    "name": "Replay",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/042d28fa7ab0c5f9c217429421b6232ef8e58693",
    "artist_name": "Iyaz",
    "image_url": "https://i.scdn.co/image/9d69508263ce12612f062a547e46e67070ed8d56",
    "artist_id": "5tKXB9uuebKE34yowVaU3C",
    "year": 2009
  },
  {
    "song_id": "3E5XrOtqMAs7p2wKhwgOjf",
    "name": "Just Dance",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/b143232fe527afb7b28f640d835e8c081a41422a",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/4fcf0b973323f378ebe9a24390d07b463736e130",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2009
  },
  {
    "song_id": "3GpbwCm3YxiWDvy29Uo3vP",
    "name": "Right Round - US",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/7c8195930e9f26264e599fcfda3bd814d927726e",
    "artist_name": "Flo Rida",
    "image_url": "https://i.scdn.co/image/2f31525d1255589af90d85a3407202317f4a3bee",
    "artist_id": "0jnsk9HBra6NMjO2oANoPY",
    "year": 2009
  },
  {
    "song_id": "58FUwmyGUqkt53YPUcm7cJ",
    "name": "Knock You Down",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/502c49fc98ed5964986e02305e3d36cf34eeb277",
    "artist_name": "Keri Hilson",
    "image_url": "https://i.scdn.co/image/85a0397687d5eec5139ce915f94da4239b1758b1",
    "artist_id": "63wjoROpeh5f11Qm93UiJ1",
    "year": 2009
  },
  {
    "song_id": "2UNedS0c7DmhV7q6CnwcmT",
    "name": "Love Lockdown",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/7a6e2cb8d17f5b6cbe5e9e61de48e75e81f3e8cd",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/fde3a72cfc294d810ef0839777888984b8ea81ed",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2009
  },
  {
    "song_id": "1akgiRM3mN2nxu2AX6ACCW",
    "name": "I Love College",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/be7e2e1290bcca59ad3cb638abfc84c110f612a1",
    "artist_name": "Asher Roth",
    "image_url": "https://i.scdn.co/image/353f531b38f4f3f1390ac7d8a8222fc093c56b71",
    "artist_id": "1wgY8huggK60P5nvYrwcYT",
    "year": 2009
  },
  {
    "song_id": "2VOomzT6VavJOGBeySqaMc",
    "name": "Disturbia",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/934e2a2d9830a83c170e93ac968c1cd2cc537651",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/07077803ac9099f2af00179d9da1acbde71dc092",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2009
  },
  {
    "song_id": "4vp2J1l5RD4gMZwGFLfRAu",
    "name": "I Gotta Feeling",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/e8facb52c44ea0affa9b939e9b9af19d65f3c3f9",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/8bffee9e32259044ae0561bc58c65929939f0cc3",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2009
  },
  {
    "song_id": "3fyMH1t6UPeR5croea9PrR",
    "name": "Best I Ever Had",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/695e6a7f59348f7c7fc648989c926d00e627b11d",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/f6bd790874706ac7f89f0a01a10f0eb2af71407d",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2009
  },
  {
    "song_id": "2YVCkTYJpv5jelYrHYoEG8",
    "name": "I Run to You",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/ac730058494a7691a0a6be92d066b8c0694b3624",
    "artist_name": "Lady Antebellum",
    "image_url": "https://i.scdn.co/image/b27fb37a210174c5a0d515c2f0455645dbf70d3b",
    "artist_id": "32WkQRZEVKSzVAAYqukAEA",
    "year": 2009
  },
  {
    "song_id": "4o8STNAL91xc7EvuvqSKk1",
    "name": "Whatever It Is",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/8b898a6bb360e058665e48a2539d95710face5b6",
    "artist_name": "Zac Brown Band",
    "image_url": "https://i.scdn.co/image/6e8b8292dd62fb6daf174b5d02232c8700e445b2",
    "artist_id": "6yJCxee7QumYr820xdIsjo",
    "year": 2009
  },
  {
    "song_id": "22NLm3IIR9NLG0cUYtmHMW",
    "name": "Pretty Wings - uncut",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/30ceb60923b910ed4ca13ae143f66c33cf7d3a09",
    "artist_name": "Maxwell",
    "image_url": "https://i.scdn.co/image/dcbb346de44552c00a2ba7c67fcdbdfdadf74542",
    "artist_id": "2AOt5htsbtyaHd5Eq3kl3j",
    "year": 2009
  },
  {
    "song_id": "1CrxDKnx34D9zoAIvf7B9H",
    "name": "Womanizer",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/b0aff58f3f696208d57c04416aa2852df735034d",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/7d1d31562dc63790dd784414c1b0d6b13c5ea7da",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2009
  },
  {
    "song_id": "7bprRkhvOXWmWpqOrEWbXu",
    "name": "So What",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/4acc6b8ec1c9068c9af69c0593a6bc01a443a4ad",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/9f51e9ef71ca7073707d77f4780fd3ca62baf107",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2009
  },
  {
    "song_id": "5sC1qOC9QclptSIE2Zx7fz",
    "name": "People Are Crazy",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/2998e02517ca5f493acfbbee4a620085236e4c9b",
    "artist_name": "Billy Currington",
    "image_url": "https://i.scdn.co/image/35f81b6ae467e004ef459e76b57dd7ae2809dd7d",
    "artist_id": "1By9QBFnjZAoI83BZppHlt",
    "year": 2009
  },
  {
    "song_id": "7LP4Es66zdY7CyjepqmvAg",
    "name": "Down",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/0fb805e7ae4093f0d909cc0ab2a7e071405103cf",
    "artist_name": "Jay Sean",
    "image_url": "https://i.scdn.co/image/845e50fa9ca26abf0fee11220d33e9e7f3045b3f",
    "artist_id": "4pADjHPWyrlAF0FA7joK2H",
    "year": 2009
  },
  {
    "song_id": "1y4eb6hmAvsqlDOl3fx9kk",
    "name": "Hot N Cold",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/3fe5d21a98feb4637cdd1ef38f03555f2eb471f8",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/474bbdc16a4bc426e14b02a0bce87ae6b26343e8",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2009
  },
  {
    "song_id": "6Hn9Uc1mMNfqChXU3txNke",
    "name": "Mad",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/315ab908ec432cea52cc3b11a88bf84efdec5695",
    "artist_name": "Ne-Yo",
    "image_url": "https://i.scdn.co/image/c3a1587bd94940db36b0d0529c6dbf384d87cfe7",
    "artist_id": "21E3waRsmPlU7jZsS13rcj",
    "year": 2009
  },
  {
    "song_id": "7rv0UuxMX7CpUUwEE1jVrd",
    "name": "Beautiful",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/cb938f46c9ca77ee3a430ac950b9c6afa343021c",
    "artist_name": "Akon",
    "image_url": "https://i.scdn.co/image/820083948ef7fbdcf71efa8e44729d5e911cd5f1",
    "artist_id": "0z4gvV4rjIZ9wHck67ucSV",
    "year": 2009
  },
  {
    "song_id": "1ARJhjuI6TNYZCxYygFQ4F",
    "name": "Right Now (Na Na Na)",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/18f3fafa364667193982531cd6a0dd98d7c68e6e",
    "artist_name": "Akon",
    "image_url": "https://i.scdn.co/image/820083948ef7fbdcf71efa8e44729d5e911cd5f1",
    "artist_id": "0z4gvV4rjIZ9wHck67ucSV",
    "year": 2009
  },
  {
    "song_id": "0KlahhzNb98zyN83Vwae0l",
    "name": "Untouched",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/faf491df4f6c3ca66643d80603e54abe27f1ef5e",
    "artist_name": "The Veronicas",
    "image_url": "https://i.scdn.co/image/75f4361b09bf80f2271e6abbb6e56667feae3601",
    "artist_id": "1dIdBZaaHRW2bDTkHNfWln",
    "year": 2009
  },
  {
    "song_id": "20X9OeC606XNwEtDBOym5u",
    "name": "Love Drunk",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/c4a676525c0b65e4434543f8ce9be8e1d9212f43",
    "artist_name": "Boys Like Girls",
    "image_url": "https://i.scdn.co/image/e1be7f7d9f93f1cf7525d991349d42029c141474",
    "artist_id": "0vWCyXMrrvMlCcepuOJaGI",
    "year": 2009
  },
  {
    "song_id": "7gDKfEMIhyyO4asgEXwcrs",
    "name": "Fire Burning",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/324e74798e5e72f7d507297e7519cb1836c4b9e1",
    "artist_name": "Sean Kingston",
    "image_url": "https://i.scdn.co/image/e3c3ac961acdeabf890bf6802854392f7bc9ab65",
    "artist_id": "6S0dmVVn4udvppDhZIWxCr",
    "year": 2009
  },
  {
    "song_id": "3XKbdb9GB6u3hsnUklQTav",
    "name": "Then",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/33311fc7a4a77efae03ac7322e7ac52957b9c442",
    "artist_name": "Brad Paisley",
    "image_url": "https://i.scdn.co/image/444f9ff149b0c11696ff84cdec157e257a6ecf38",
    "artist_id": "13YmWQJFwgZrd4bf5IjMY4",
    "year": 2009
  },
  {
    "song_id": "08uGhvS5MfBk7crUCpnjva",
    "name": "Blame It",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/43e28f2ec188673a0210fc073a0e9a8754382cf2",
    "artist_name": "Jamie Foxx",
    "image_url": "https://i.scdn.co/image/52b5ec904290207ac54dca524de3418b97d9fb8a",
    "artist_id": "7LnaAXbDVIL75IVPnndf7w",
    "year": 2009
  },
  {
    "song_id": "0V8FYVlBFuXXTIvRnMbZyS",
    "name": "The Climb",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/c1507a87568001a0310a9be4db952ecbf4e8b394",
    "artist_name": "Miley Cyrus",
    "image_url": "https://i.scdn.co/image/6f27a09c09285829e24ed14240205d2f86381e62",
    "artist_id": "5YGY8feqx7naU7z4HrwZM6",
    "year": 2009
  },
  {
    "song_id": "3IcIIZMMS7UArJJPtEHXG8",
    "name": "Obsessed",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/abe8286bb806d0b50d85bb3eaad651afe49becba",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/13ebb91c1fa5244bff9c1e3e75f0e8ad0139e9a6",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 2009
  },
  {
    "song_id": "7ceIquYtiTYlgSSm7PqUf9",
    "name": "Already Gone",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/c189b9905b22be8b84774cc3f24e9f6ea550f57a",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/a8d4ee67f32497f3fc395c7457108a9bb031fed9",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2009
  },
  {
    "song_id": "2SwkHQpyK3Tq11RtJjQhJY",
    "name": "Say Hey (I Love You)",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/988e11dcce284e3aa14ee742425e9cd8cc13ab9b",
    "artist_name": "Michael Franti & Spearhead",
    "image_url": "https://i.scdn.co/image/b5eee52035cd9012b8b35eb3dfe1d3ad2570d292",
    "artist_id": "1mHuZMOP8FG5ip4yAb1vrB",
    "year": 2009
  },
  {
    "song_id": "4p3WcdhfXbDDX0fcxp0wr1",
    "name": "Circus",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/3351c93c3431b71c8f125a049ce7e385e7bd8fc0",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/7d1d31562dc63790dd784414c1b0d6b13c5ea7da",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2009
  },
  {
    "song_id": "2vVc2G9w2JteBgxpSUVwX5",
    "name": "Let It Rock",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/d7081d0afbb89c01811ebe34286f85771865e3d5",
    "artist_name": "Kevin Rudolf",
    "image_url": "https://i.scdn.co/image/91459473442d011b2ef60caecd697a69c3c4ceaf",
    "artist_id": "0Chxmm4XMM87mJOHvyiUzL",
    "year": 2009
  },
  {
    "song_id": "72by3Re4C3eVEBXvsUo0zV",
    "name": "Green Light",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/61db0c6b8d11c1eabd73a743c2a44500940ff9bc",
    "artist_name": "John Legend",
    "image_url": "https://i.scdn.co/image/0149795ce63254d7d7597d70c2bf74fb35ccb99c",
    "artist_id": "5y2Xq6xcjJb2jVM54GHK3t",
    "year": 2009
  },
  {
    "song_id": "6li2D8wQPvgwI2QIrGPhAF",
    "name": "My Life Would Suck Without You",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/3e6d7689ecc3681aa30b5c552b7526cdab1da20f",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/eee24a81e5fc5514d0469b9f236dc196835efa21",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2009
  },
  {
    "song_id": "1Nme6K1PYAh7p7uygyuRdf",
    "name": "Battlefield",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/c3709a00cf8a47990b235ee0f404d53ddeca1be9",
    "artist_name": "Jordin Sparks",
    "image_url": "https://i.scdn.co/image/17adf0878b722d53b1e03b773e1545e2f0ac43be",
    "artist_id": "2AQjGvtT0pFYfxR3neFcvz",
    "year": 2009
  },
  {
    "song_id": "1le5KVGTF1xWf2aUj7ruLy",
    "name": "Fallin' For You",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/823ffbe5e47ef03740eec3cbdc25e68f4061754b",
    "artist_name": "Colbie Caillat",
    "image_url": "https://i.scdn.co/image/532059b53fddb19319f7098b0680ff37731548db",
    "artist_id": "6aZyMrc4doVtZyKNilOmwu",
    "year": 2009
  },
  {
    "song_id": "6fvxos1qSHrIgOkKw4dhWS",
    "name": "Waking Up In Vegas",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/323f196bdd6613583d617341d9f9f376e19dd42c",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/474bbdc16a4bc426e14b02a0bce87ae6b26343e8",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2009
  },
  {
    "song_id": "32yIEFS62uS5ryhr2Xlooj",
    "name": "Hotel Room Service",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/95084cf460a797452231c6b9410e0cadbf4563c5",
    "artist_name": "Pitbull",
    "image_url": "https://i.scdn.co/image/fdc255fb0664c942c2f83b50f84b9ba9a07aeabd",
    "artist_id": "0TnOYISbd1XYRBk9myaseg",
    "year": 2009
  },
  {
    "song_id": "3FF6jZY1wDHXJUUN18qsLx",
    "name": "Paparazzi",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/6dc832725206ece9810cb3eaf6b44f1b95577924",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/4fcf0b973323f378ebe9a24390d07b463736e130",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2009
  },
  {
    "song_id": "7xRNsqOQOgWbHV1nbXnfXN",
    "name": "Boom Boom Pow",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/8e0c53b4ead4487125cd7306c852ee54502ef122",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/8bffee9e32259044ae0561bc58c65929939f0cc3",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2009
  },
  {
    "song_id": "3EuoUivJpZ3sArzWT6XfPn",
    "name": "Please Don't Leave Me",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/519a305af6e5378c149fa78137a21b282a432f79",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/9f51e9ef71ca7073707d77f4780fd3ca62baf107",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2009
  },
  {
    "song_id": "7eusnHDVdvqjUGAigj77XC",
    "name": "3",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/d75cb6c0da5c6206080910ec3fb30cb9f095935d",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/ce32719dac612fd6fed20940023ade3f62f0758a",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2009
  },
  {
    "song_id": "0f0lWRAs9kdAFZu8mAI9K2",
    "name": "If U Seek Amy",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/af23be98759a56cfb7d1f96e4da33c044719bf51",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/7d1d31562dc63790dd784414c1b0d6b13c5ea7da",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2009
  },
  {
    "song_id": "3LtwmK1OfN0dGs9jf3qSjn",
    "name": "Her Diamonds",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/30df9d31cebebfb8e644ef6d3d6848b14aadcf77",
    "artist_name": "Rob Thomas",
    "image_url": "https://i.scdn.co/image/e6a11cbadc373c39b47182d6be8a5daaa270ea37",
    "artist_id": "3aBkeBhwadnWMWoVJ2CxJC",
    "year": 2009
  },
  {
    "song_id": "6eDApnV9Jdb1nYahOlbbUh",
    "name": "One Time",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/ed37456c7b90b7e011f157a8978bc083af9333a9",
    "artist_name": "Justin Bieber",
    "image_url": "https://i.scdn.co/image/8a447449978c48a98bf1285fb6f7baf6b1b9665a",
    "artist_id": "1uNFoZAHBGtllmzznpCI3s",
    "year": 2009
  },
  {
    "song_id": "0WfKDYeUAoLA3vdvLKKWMW",
    "name": "Poker Face",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/c42debcc18026d7f66d849f6f4be02d4b05f99c4",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/194658ae9ff541807df5025576b0c8c23d882782",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2009
  },
  {
    "song_id": "6ouTGbETM7ZdID1eMXZJde",
    "name": "Day 'N' Nite - Crookers Remix",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/0b9acdd16e4f29d5c8430ae1fcc925c96046e22c",
    "artist_name": "Kid Cudi",
    "image_url": "https://i.scdn.co/image/b376952b1332896d2f062d1eb2f41cb5cfb700c3",
    "artist_id": "0fA0VVWsXO9YnASrzqfmYu",
    "year": 2009
  },
  {
    "song_id": "2nP53cGOPLojPkoWVZo4sY",
    "name": "LoveGame",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/8348a858540d433a65441ab912ea7a0aacb317e9",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/4fcf0b973323f378ebe9a24390d07b463736e130",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2009
  },
  {
    "song_id": "6ATrsVaZT7XjkCynxM8cTS",
    "name": "Turn My Swag On",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/431bd431fb0fd40c9b18bd3e14b0349ba80e3894",
    "artist_name": "Soulja Boy",
    "image_url": "https://i.scdn.co/image/37fd58b70dda73499d0f44965e5b4dc754285447",
    "artist_id": "6GMYJwaziB4ekv1Y6wCDWS",
    "year": 2009
  },
  {
    "song_id": "3FgxTW3HiEqSvR9h1H4q5J",
    "name": "Sober",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/522b43cbd964224dba80bb031365c71d1290d106",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/9f51e9ef71ca7073707d77f4780fd3ca62baf107",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2009
  },
  {
    "song_id": "4UtHw3YzKUVxouN9zOG0ly",
    "name": "No Surprise",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/418b4f213d7db60cf28af3bd270490156acfcb81",
    "artist_name": "Daughtry",
    "image_url": "https://i.scdn.co/image/40ae2410a8f2cbf92b7f2ff9425a5380cdc74a73",
    "artist_id": "5P5FTygHyx2G57oszR3Wot",
    "year": 2009
  },
  {
    "song_id": "4shgvM4ClrZeDiflNJwlGd",
    "name": "Sugar - feat. Wynter",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/4bde09784cd4111c5ebda33a21a4fe3defdf3391",
    "artist_name": "Flo Rida",
    "image_url": "https://i.scdn.co/image/2f31525d1255589af90d85a3407202317f4a3bee",
    "artist_id": "0jnsk9HBra6NMjO2oANoPY",
    "year": 2009
  },
  {
    "song_id": "3jkdQNkDTxxXtjSO4l0o1H",
    "name": "How Do You Sleep? - Featuring Ludacris - Radio Edit Remix",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/200e28ed502b575e5575c294ac02122111c5f18f",
    "artist_name": "Jesse McCartney",
    "image_url": "https://i.scdn.co/image/70e1d8d621464ebd1e04bcd9e2c882d6ccda1c36",
    "artist_id": "2Hjj68yyUPiC0HKEOigcEp",
    "year": 2009
  },
  {
    "song_id": "6ZULTiMcrmGeuj6GilRgod",
    "name": "Pop Champagne - Explicit Album Version",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/ff1b33ebc42f72a5442bfbd1bebc99e500bf37f3",
    "artist_name": "Jim Jones",
    "image_url": "https://i.scdn.co/image/ca11a9b8c2bf578fa4ff47a3081b6572d650d7a1",
    "artist_id": "6AMa1VFQ7qCi61tCRtVWXe",
    "year": 2009
  },
  {
    "song_id": "1jEtDqwYCKpR0mlrMrrITx",
    "name": "Turnin Me On - Original Clean",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/221b43656caf74a79bf490dddc4ff56296de4968",
    "artist_name": "Keri Hilson",
    "image_url": "https://i.scdn.co/image/85a0397687d5eec5139ce915f94da4239b1758b1",
    "artist_id": "63wjoROpeh5f11Qm93UiJ1",
    "year": 2009
  },
  {
    "song_id": "4UP5HP7IAnNdxD01g1lzQp",
    "name": "Break Up",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/a4aa737c2c5aab254ca2a735f4083d2084cb6efb",
    "artist_name": "Mario",
    "image_url": "https://i.scdn.co/image/2d173c978026d7f305b60156143a8b80e7b8fc8c",
    "artist_id": "20s0P9QLxGqKuCsGwFsp7w",
    "year": 2009
  },
  {
    "song_id": "3oJJ1dQBxiFYGMQSe2SluG",
    "name": "Rockin' That Thang - Album Version (Edited)",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/ad12459cc1910a215e8a32e762af8e0137305603",
    "artist_name": "The-Dream",
    "image_url": "https://i.scdn.co/image/59467a404e00713b7c289c19dc5fe0d20b056eb8",
    "artist_id": "1W3FSF1BLpY3hlVIgvenLz",
    "year": 2009
  },
  {
    "song_id": "2I5CPUnMIKjEXeg61OI9uV",
    "name": "I Do Not Hook Up",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/ace59821dc11a0d9f5ac0d4b35b137f5ab2e3484",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/2a5fc7389d46daa2fba188e581395659b7b78b6f",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2009
  },
  {
    "song_id": "6Zw0FM6wTrDPdMzk69x1LL",
    "name": "Kiss Me Thru The Phone",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/2dd819d1002e2a0913a0d2badc212e26b9cfdf62",
    "artist_name": "D-Money",
    "image_url": "https://i.scdn.co/image/c571c1e2af3261a81758cc381dd8a45d0cf93586",
    "artist_id": "2MVJ6LQcUHdZY95sCePcy1",
    "year": 2009
  },
  {
    "song_id": "6u8txb92XRmzfURmKEdZcr",
    "name": "Empire State Of Mind",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/900af407e0615e3ff484746630640dacac6a052d",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/3f900c73ca098d2a2bac967e45290d3841c7ac0e",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2009
  },
  {
    "song_id": "5myYDbAurm1CW038qd4gL2",
    "name": "Goodbye",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/11473f6ef4b8c8b6476e8f68db4a35c08894496f",
    "artist_name": "Kristinia DeBarge",
    "image_url": "https://i.scdn.co/image/493f55144bb911bf4c4436705c54468617a86734",
    "artist_id": "5Z22vlK4DCgDrQgBEqfc8w",
    "year": 2009
  },
  {
    "song_id": "360TPORPNBas8DeQueJe67",
    "name": "Crack A Bottle",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/780de1b023262ec35b57bbf24bc444699cf0b441",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/788c0f5a8c83d6234ec8736690fbd6fa0253d179",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2009
  },
  {
    "song_id": "3a9dUZcUd6vcUHnYFbfBcq",
    "name": "Good Girls Go Bad [feat. Leighton Meester]",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/75d7aed179c153db6e52afb0410c9b1fc9775778",
    "artist_name": "Cobra Starship",
    "image_url": "https://i.scdn.co/image/fa90f844b7b050cd7065441ca2c4b78f3fefab16",
    "artist_id": "2aYJ5LAta2ScCdfLhKgZOY",
    "year": 2009
  },
  {
    "song_id": "19238ddr9wNQADMi8nLXG0",
    "name": "Fireflies",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/4d3ca6493718f8f551fec6708b33df47c44269f9",
    "artist_name": "Owl City",
    "image_url": "https://i.scdn.co/image/2f8aabc36ce16c61bd75389340c6c37102ef288e",
    "artist_id": "07QEuhtrNmmZ0zEcqE9SF6",
    "year": 2009
  },
  {
    "song_id": "24lMtPOCzP5g4hrg3NklLa",
    "name": "Just The Way You Are",
    "popularity": 80,
    "preview_url": "https://p.scdn.co/mp3-preview/348ddef4fa3cdee2cdd6b69c7dbf5e6da359002a",
    "artist_name": "Bruno Mars",
    "image_url": "https://i.scdn.co/image/fa2226a645e903791ce6cf6f315de00c24afa6ea",
    "artist_id": "0du5cEVh5yTK9QJze8zA0C",
    "year": 2010
  },
  {
    "song_id": "6HSqyfGnsHYw9MmIpa9zlZ",
    "name": "Forever",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/aaf8ff66888beea73922c83d643eca4d6c50d697",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/a6b8a73eadfad92e0f799b1e01194659478b4212",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2010
  },
  {
    "song_id": "0KpfYajJVVGgQ32Dby7e9i",
    "name": "Hey, Soul Sister",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/92d163f65e07a73c622fdf2f240befeeb7a0be67",
    "artist_name": "Train",
    "image_url": "https://i.scdn.co/image/c4599431d775bd7008e50cf6c16af8277418b912",
    "artist_id": "3FUY2gzHeIiaesXtOAdB7A",
    "year": 2010
  },
  {
    "song_id": "0RIHDrcRAXtUlnkvTYPW1a",
    "name": "All I Do Is Win - Feat. T-Pain, Ludacris, Snoop Dogg & Rick Ross",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/e83528d7e8074f040af602650989bdd8b319c713",
    "artist_name": "DJ Khaled",
    "image_url": "https://i.scdn.co/image/732df44d4c3d6cfd3c6f9e18b8cb8e6e249ef528",
    "artist_id": "0QHgL1lAIqAw0HtD7YldmP",
    "year": 2010
  },
  {
    "song_id": "38xWaVFKaxZlMFvzNff2aW",
    "name": "Breakeven",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/32c0f008180a263402cd8219390ee5f1667b38f5",
    "artist_name": "The Script",
    "image_url": "https://i.scdn.co/image/b0c61ffa01751e59abfdbac7c0bdea0760ebba2a",
    "artist_id": "3AQRLZ9PuTAozP28Skbq8V",
    "year": 2010
  },
  {
    "song_id": "4fIWvT19w9PR0VVBuPYpWA",
    "name": "Haven't Met You Yet",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/476ae3590f656b89987e9bbed52763a5b333684a",
    "artist_name": "Michael Bublé",
    "image_url": "https://i.scdn.co/image/7a89094f4a43c252637cfe256b8930d73baae133",
    "artist_id": "1GxkXlMwML1oSg5eLPiAz3",
    "year": 2010
  },
  {
    "song_id": "7nmArpOotJ431XYlJXI1vy",
    "name": "Young Forever [Jay-Z + Mr Hudson]",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/66d33c51772dbc8aac027fec80137f2cf2c99247",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/ed72320cc6d2911a417675d250b42157feaa1540",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2010
  },
  {
    "song_id": "1NhPKVLsHhFUHIOZ32QnS2",
    "name": "Secrets",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/ddf8e17182515aba75a3db703e4a6b85eebc1469",
    "artist_name": "OneRepublic",
    "image_url": "https://i.scdn.co/image/7f03713f3a167bc8eea3ce016416386bd4dda80c",
    "artist_id": "5Pwc4xIPtQLFEnJriah9YJ",
    "year": 2010
  },
  {
    "song_id": "15JINEqzVMv3SvJTAXAKED",
    "name": "Love The Way You Lie",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/a7b91f895ea5ae3b326ed18af3bce857ff33609f",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/16e09afc96e10e5dc780864d0a8ac1ea9247b126",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2010
  },
  {
    "song_id": "7JIuqL4ZqkpfGKQhYlrirs",
    "name": "The Only Exception",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/126090c98cd83eeac97b2b4e8e94609bc430d281",
    "artist_name": "Paramore",
    "image_url": "https://i.scdn.co/image/64e57b9a4af780d226ab54bc5227dc84aa694b9f",
    "artist_id": "74XFHRwlV6OrjEM0A2NCMF",
    "year": 2010
  },
  {
    "song_id": "4srpHYFHKjVGRDNHqRpWxi",
    "name": "TiK ToK",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/ee1e857d5939dc36904d66e1cb3bcadb97b7d932",
    "artist_name": "Ke$ha",
    "image_url": "https://i.scdn.co/image/a03f39f6ae96bde56c4814a90d012d099987b6c2",
    "artist_id": "6LqNN22kT3074XbTVUrhzX",
    "year": 2010
  },
  {
    "song_id": "7Ie9W94M7OjPoZVV216Xus",
    "name": "Not Afraid",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/5a377953da4f5877caeeb1f1bf3861024e8ecd9d",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/16e09afc96e10e5dc780864d0a8ac1ea9247b126",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2010
  },
  {
    "song_id": "3DamFFqW32WihKkTVlwTYQ",
    "name": "Fireflies",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/291bdafb33eb5b55c714cfd13a07f973dce11307",
    "artist_name": "Owl City",
    "image_url": "https://i.scdn.co/image/8c5b59b47d65206ecb310fddea9222a8780a39d2",
    "artist_id": "07QEuhtrNmmZ0zEcqE9SF6",
    "year": 2010
  },
  {
    "song_id": "1kMuU3TNQvHbqvXCWBodmP",
    "name": "Right Above It",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/4184403fdc709f5cd76c1853bebcb3193f46530d",
    "artist_name": "Lil Wayne",
    "image_url": "https://i.scdn.co/image/a5fb0f9c08c39c235b09226bae313a47d9e11071",
    "artist_id": "55Aa2cqylxrFIXC767Z865",
    "year": 2010
  },
  {
    "song_id": "1yK9LISg5uBOOW5bT2Wm0i",
    "name": "Try Sleeping with a Broken Heart",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/c44c2fa0432d6a00ed868986dc7b4736f6a3ab55",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/6df1aeacd14bcb3e5d583a42ee20361fd72facf6",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2010
  },
  {
    "song_id": "2IpGdrWvIZipmaxo1YRxw5",
    "name": "Bottoms Up - feat. Nicki Minaj",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/f4def5109aa8dfeb57ca7a7a45d2bfc8d5155b97",
    "artist_name": "Trey Songz",
    "image_url": "https://i.scdn.co/image/f234ff7ad80533554d95f291b93e72bf9d23de03",
    "artist_id": "2iojnBLj0qIMiKPvVhLnsH",
    "year": 2010
  },
  {
    "song_id": "1fBl642IhJOE5U319Gy2Go",
    "name": "Animal",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/0137c8caa17422dc37f9c127efd6d5f4f7792694",
    "artist_name": "Neon Trees",
    "image_url": "https://i.scdn.co/image/339b5c3e25856afa71b6a7c890c97246efeb0784",
    "artist_id": "0RpddSzUHfncUWNJXKOsjy",
    "year": 2010
  },
  {
    "song_id": "1FKxKGONukVFXWVJxAKmlz",
    "name": "Sweet Dreams",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/ca4ab61ec7f43d33afde3851cf16f702a72c511c",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/6cad233151a2b6e87166ae0c5dfc28491322c3ea",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2010
  },
  {
    "song_id": "4ZC8hXXqu2hPcDLw9QTdtQ",
    "name": "Smile",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/e9f8ae08f0cfa5d440292abeefe0a8181b5e5aa2",
    "artist_name": "Uncle Kracker",
    "image_url": "https://i.scdn.co/image/b64e10b95bfdf5969622c7eb3649ef6b9e3b0be7",
    "artist_id": "2DnqqkzzDKm3vAoyHtn8So",
    "year": 2010
  },
  {
    "song_id": "3r04p85xiJh9Wqk59YDYdc",
    "name": "OMG",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/6e7a14a6efc143e89f610b3ad554323a70fe2585",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/840d9b649b9600b072bc5c8e995d695ad53a198b",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2010
  },
  {
    "song_id": "2M9ULmQwTaTGmAdXaXpfz5",
    "name": "Billionaire - feat. Bruno Mars",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/c5a95dd97f51b45d1c9388f8a9270025420a1c18",
    "artist_name": "Travie McCoy",
    "image_url": "https://i.scdn.co/image/80ba6ed0dcbb94061abf090eadde8c9e9b224c97",
    "artist_id": "7o9Nl7K1Al6NNAHX6jn6iG",
    "year": 2010
  },
  {
    "song_id": "11EX5yhxr9Ihl3IN1asrfK",
    "name": "Need You Now",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/26806db8109a13ae4bf024d9294c491a7526eb0b",
    "artist_name": "Lady Antebellum",
    "image_url": "https://i.scdn.co/image/36ba791d0a6739ca107dfe3eeb5c552ffe5d9cbe",
    "artist_id": "32WkQRZEVKSzVAAYqukAEA",
    "year": 2010
  },
  {
    "song_id": "5vlEg2fT4cFWAqU5QptIpQ",
    "name": "Replay",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/042d28fa7ab0c5f9c217429421b6232ef8e58693",
    "artist_name": "Iyaz",
    "image_url": "https://i.scdn.co/image/9d69508263ce12612f062a547e46e67070ed8d56",
    "artist_id": "5tKXB9uuebKE34yowVaU3C",
    "year": 2010
  },
  {
    "song_id": "0xcl9XT60Siji6CSG4y6nb",
    "name": "Club Can't Handle Me - feat. David Guetta",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/0e936de46183818dd4a33bdc20dca741ba985761",
    "artist_name": "Flo Rida",
    "image_url": "https://i.scdn.co/image/e1e286ae799e845870159531c4f479cd6ff3568c",
    "artist_id": "0jnsk9HBra6NMjO2oANoPY",
    "year": 2010
  },
  {
    "song_id": "3QkXEz9TxApuk1JpDmeWnS",
    "name": "Deuces",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/cbdb62bec1e39b49b16489bd1d63d350307f066b",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/51dbdb951e3b21af21fc99797d15da98514ec46e",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2010
  },
  {
    "song_id": "1MaqkdFNIKPdpQGDzme5ss",
    "name": "Two Is Better Than One",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/15f64e6da7fd5a94dc5a3d80e79d627a2b326e88",
    "artist_name": "Boys Like Girls",
    "image_url": "https://i.scdn.co/image/e1be7f7d9f93f1cf7525d991349d42029c141474",
    "artist_id": "0vWCyXMrrvMlCcepuOJaGI",
    "year": 2010
  },
  {
    "song_id": "3VA8T3rNy5V24AXxNK5u9E",
    "name": "King Of Anything",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/9f2583f44167a0a30cf54f1a52a7782a1c2a9fa3",
    "artist_name": "Sara Bareilles",
    "image_url": "https://i.scdn.co/image/1ca25cebe47e292d3b94ef576aced229f92dcd36",
    "artist_id": "2Sqr0DXoaYABbjBo9HaMkM",
    "year": 2010
  },
  {
    "song_id": "2bnDTGikdoO8CwzlAPyBHR",
    "name": "Airplanes, Part II (feat Eminem & Hayley Williams of Paramore)",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/12f09cfd66b1bc08b192c38f1ac23e17744e47f9",
    "artist_name": "B.o.B",
    "image_url": "https://i.scdn.co/image/566feb8757d3f841811c941ecaf904163e912165",
    "artist_id": "5ndkK3dpZLKtBklKjxNQwT",
    "year": 2010
  },
  {
    "song_id": "4DvhkX2ic4zWkQeWMwQ2qf",
    "name": "Like A G6",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/be360b1aa77f49e17df1079dd7802e97cad3190a",
    "artist_name": "Far East Movement",
    "image_url": "https://i.scdn.co/image/e021b336e833c0a9076f9fb879ec206892d764d6",
    "artist_id": "698hF4vcwHwPy8ltmXermq",
    "year": 2010
  },
  {
    "song_id": "6lUY6MoqGgPnA27PHYxem5",
    "name": "Bulletproof",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/00baaa0daafc846e6781ac7b4bffd17348765b68",
    "artist_name": "La Roux",
    "image_url": "https://i.scdn.co/image/2587f68c74ab896473f4ff395e8b3dc3028fc2f3",
    "artist_id": "3K2zB87GZv1krx031en5VA",
    "year": 2010
  },
  {
    "song_id": "02eD9ymfJOJOhM97HYp5R9",
    "name": "The House That Built Me",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/940ddcde0771e006c16b12273ec2fcc7bcbd4788",
    "artist_name": "Miranda Lambert",
    "image_url": "https://i.scdn.co/image/569d1bd8c913f9369b9653dc948c163f2051cfda",
    "artist_id": "66lH4jAE7pqPlOlzUKbwA0",
    "year": 2010
  },
  {
    "song_id": "6pL7puHxh1psrLzrAlobxQ",
    "name": "Sexy Bitch (feat. Akon) - Featuring Akon;explicit",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/eab68c1c106fd7442f9c53444272ac46bd9ddda2",
    "artist_name": "David Guetta",
    "image_url": "https://i.scdn.co/image/ef490ab83905781ff2c692bff173b456f641d194",
    "artist_id": "1Cs0zKBU1kc0i8ypK3B9ai",
    "year": 2010
  },
  {
    "song_id": "4vp2J1l5RD4gMZwGFLfRAu",
    "name": "I Gotta Feeling",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/e8facb52c44ea0affa9b939e9b9af19d65f3c3f9",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/8bffee9e32259044ae0561bc58c65929939f0cc3",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2010
  },
  {
    "song_id": "123NAAjn1RMY2WeoZH8pVN",
    "name": "Whatcha Say",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/8053542b10bd44660ba25e413a5981331f02d9c5",
    "artist_name": "Jason Derulo",
    "image_url": "https://i.scdn.co/image/012c3625be55cd446c220e5d55ac98bc5fb06c4c",
    "artist_id": "07YZf4WDAMNwqr4jfgOZ8y",
    "year": 2010
  },
  {
    "song_id": "4JOP8ELK6AaeySe7sKe996",
    "name": "Un-thinkable (I'm Ready)",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/c3c54746927a84bd29c43a7589c104000e44242b",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/6df1aeacd14bcb3e5d583a42ee20361fd72facf6",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2010
  },
  {
    "song_id": "5UzU0qw21okODBNUnxptVo",
    "name": "Lover, Lover",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/7a02db7bd903e8f50f2252bc07a32d66c1353644",
    "artist_name": "Jerrod Niemann",
    "image_url": "https://i.scdn.co/image/508a6f00cb035e59629d17197a9eb800a373c887",
    "artist_id": "0RKP52f5PNDCRZ9Luq2Sr7",
    "year": 2010
  },
  {
    "song_id": "2V4bv1fNWfTcyRJKmej6Sj",
    "name": "Cooler Than Me - Single Mix",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/6b294f07a888f3c2603d7bbe918e86af73312c8b",
    "artist_name": "Mike Posner",
    "image_url": "https://i.scdn.co/image/c955fc53f38c10cb0ed4babb2ef1af903936581e",
    "artist_id": "2KsP6tYLJlTBvSUxnwlVWa",
    "year": 2010
  },
  {
    "song_id": "6BdgtqiV3oXNqBikezwdvC",
    "name": "Over",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/9c29825eb69049f2401f7888de104118fdf94587",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/54e8b28f9d2cf69b62938690e33a460cd2341456",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2010
  },
  {
    "song_id": "3dJ1OgvUKWi46HcRF4hiSq",
    "name": "If I Die Young",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/63aed26f1ccae0ee2c36cf841d1b84d66d0d98b5",
    "artist_name": "The Band Perry",
    "image_url": "https://i.scdn.co/image/13f0be2f3478fd6eb6dd061e77584627ca2185d8",
    "artist_id": "75FnCoo4FBxH5K1Rrx0k5A",
    "year": 2010
  },
  {
    "song_id": "1DqdF42leyFIzqNDv9CjId",
    "name": "Dynamite",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/e5515b1dc9015185ac938d22b4eacccd539e1991",
    "artist_name": "Taio Cruz",
    "image_url": "https://i.scdn.co/image/5ff597f6fef881b469fd900c906f48b57d132ee5",
    "artist_id": "6MF9fzBmfXghAz953czmBC",
    "year": 2010
  },
  {
    "song_id": "6WTfLaLQsrVzLwYigoGv3W",
    "name": "Rude Boy",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/5de1cde24a98e40ba8a7b51e18430792d7cf5b50",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/2a081e3de9057fca3056a2332a85e1a29f146d6a",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2010
  },
  {
    "song_id": "1IaYWv32nFFMdljBIjMY5T",
    "name": "Telephone",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/b5ccc1e429a5b35a78bdd2d276b232865779f8fa",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/4fcf0b973323f378ebe9a24390d07b463736e130",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2010
  },
  {
    "song_id": "6BaxqcoEM9r3LXisTyJjST",
    "name": "Ridin' Solo",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/54fbb44c243654ee413aca862fa1876c8819e5a9",
    "artist_name": "Jason Derulo",
    "image_url": "https://i.scdn.co/image/c9adfaf5822f8b03d33d9912109440c892119ac8",
    "artist_id": "07YZf4WDAMNwqr4jfgOZ8y",
    "year": 2010
  },
  {
    "song_id": "7LP4Es66zdY7CyjepqmvAg",
    "name": "Down",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/0fb805e7ae4093f0d909cc0ab2a7e071405103cf",
    "artist_name": "Jay Sean",
    "image_url": "https://i.scdn.co/image/845e50fa9ca26abf0fee11220d33e9e7f3045b3f",
    "artist_id": "4pADjHPWyrlAF0FA7joK2H",
    "year": 2010
  },
  {
    "song_id": "2rDwdvBma1O1eLzo29p2cr",
    "name": "Whataya Want from Me",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/05a2891fbcb368aeda103a12821a0b9f36f9d2ac",
    "artist_name": "Adam Lambert",
    "image_url": "https://i.scdn.co/image/d0f79148745c72c997b8db155b6036d76241fce5",
    "artist_id": "6prmLEyn4LfHlD9NnXWlf7",
    "year": 2010
  },
  {
    "song_id": "5XRHGXut00SrJUFmcn2lQF",
    "name": "All The Right Moves",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/0f816c8c248dbd0c097011272ab88251be5426c9",
    "artist_name": "OneRepublic",
    "image_url": "https://i.scdn.co/image/7f03713f3a167bc8eea3ce016416386bd4dda80c",
    "artist_id": "5Pwc4xIPtQLFEnJriah9YJ",
    "year": 2010
  },
  {
    "song_id": "6mjDmlsMxnRDati2rC1QFL",
    "name": "Hey Daddy (Daddy's Home)",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/6624c3075f2ae4a1b2a6bc8195d940d46bc93222",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/840d9b649b9600b072bc5c8e995d695ad53a198b",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2010
  },
  {
    "song_id": "6tp0eVhr6SI7FWPJasG86O",
    "name": "There Goes My Baby",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/b2af1df4ef34de5819e0cc6869c1969f755f0870",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/840d9b649b9600b072bc5c8e995d695ad53a198b",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2010
  },
  {
    "song_id": "1iiEQ7XN33cFEwAJm7w1Yv",
    "name": "BedRock",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/19cd770c6e740cddb5529c46a6c8b88ca3eb5200",
    "artist_name": "Young Money",
    "image_url": "https://i.scdn.co/image/f0c07669ad5fb1b40807acccfc8ddce10cb67d14",
    "artist_id": "5OrB6Jhhrl9y2PK0pSV4VP",
    "year": 2010
  },
  {
    "song_id": "6St9lR6dhV2tpCNVz7qfrS",
    "name": "In My Head",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/bb8614a2ae5d9cedb25c8ca367c9a5e48b91c7ec",
    "artist_name": "Jason Derulo",
    "image_url": "https://i.scdn.co/image/c9adfaf5822f8b03d33d9912109440c892119ac8",
    "artist_id": "07YZf4WDAMNwqr4jfgOZ8y",
    "year": 2010
  },
  {
    "song_id": "1WtTLtofvcjQM3sXSMkDdX",
    "name": "How Low",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/1c5c4dd3565ad21b321a4eb48d67923edba4983d",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/28acfe3363e6743c4046c9d604d54b9819946294",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2010
  },
  {
    "song_id": "4gWTBq5Jftq5CEKYpLXD8R",
    "name": "Life After You",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/0078a0b15dc78c4d3c7b32b3de2ca143b5247bf5",
    "artist_name": "Daughtry",
    "image_url": "https://i.scdn.co/image/c14acba55484ffaf93107f774168a658b4511cae",
    "artist_id": "5P5FTygHyx2G57oszR3Wot",
    "year": 2010
  },
  {
    "song_id": "4BycRneKmOs6MhYG9THsuX",
    "name": "Find Your Love",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/854c010fe18801204df373672b1447ac76a34677",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/54e8b28f9d2cf69b62938690e33a460cd2341456",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2010
  },
  {
    "song_id": "7ceIquYtiTYlgSSm7PqUf9",
    "name": "Already Gone",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/c189b9905b22be8b84774cc3f24e9f6ea550f57a",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/a8d4ee67f32497f3fc395c7457108a9bb031fed9",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2010
  },
  {
    "song_id": "7mrxvbw9psnpDcQ8WkkFBZ",
    "name": "Undo It",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/ce413c9989432bbd48662441c63cb8cc7763cc65",
    "artist_name": "Carrie Underwood",
    "image_url": "https://i.scdn.co/image/eec04367539c7867c0b6e2a8b97e9ee0122b7fd4",
    "artist_id": "4xFUf1FHVy696Q1JQZMTRj",
    "year": 2010
  },
  {
    "song_id": "17tDv8WA8IhqE8qzuQn707",
    "name": "My First Kiss - feat. Ke$ha",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/7709775ec97da768298d8ffe93cb78b72650006b",
    "artist_name": "3OH!3",
    "image_url": "https://i.scdn.co/image/7cfafbbe5c0a28803baa3b25e584a5089c936b4a",
    "artist_id": "0FWzNDaEu9jdgcYTbcOa4F",
    "year": 2010
  },
  {
    "song_id": "4xInIiKipU1mtUogJ3ZdYr",
    "name": "American Honey",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/e995cf3f972234ba3e7e85fa90a9a6f5b4ededbb",
    "artist_name": "Lady Antebellum",
    "image_url": "https://i.scdn.co/image/36ba791d0a6739ca107dfe3eeb5c552ffe5d9cbe",
    "artist_id": "32WkQRZEVKSzVAAYqukAEA",
    "year": 2010
  },
  {
    "song_id": "3ZdJffjzJWFimSQyxgGIxN",
    "name": "Just A Dream - Main",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/d85cec8f026aa8e59c68714167da87b4d9ee5213",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/97fa0448c1f031bffe3d2693343ca8245f23abc6",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2010
  },
  {
    "song_id": "2DHc2e5bBn4UzY0ENVFrUl",
    "name": "Carry Out (Featuring Justin Timberlake)",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/a3150becce918a05e7d58c03f53e14ca90a02ec0",
    "artist_name": "Timbaland",
    "image_url": "https://i.scdn.co/image/a8e1b6862f51b51b684de3bea05cef92ed8c8247",
    "artist_id": "5Y5TRrQiqgUO4S36tzjIRZ",
    "year": 2010
  },
  {
    "song_id": "6epn3r7S14KUqlReYr77hA",
    "name": "Baby",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/17010681db6f52a8192bddff40efa7419ceb2762",
    "artist_name": "Justin Bieber",
    "image_url": "https://i.scdn.co/image/ebc4de0afdb9d3126dea124538f5a7479fcac70b",
    "artist_id": "1uNFoZAHBGtllmzznpCI3s",
    "year": 2010
  },
  {
    "song_id": "7yws3pF3FFguwT2Psi6c15",
    "name": "Do You Remember",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/61019620191219be82006ff4b014cd0131d71fbf",
    "artist_name": "Jay Sean",
    "image_url": "https://i.scdn.co/image/845e50fa9ca26abf0fee11220d33e9e7f3045b3f",
    "artist_id": "4pADjHPWyrlAF0FA7joK2H",
    "year": 2010
  },
  {
    "song_id": "45EDI3rk0f4cAMt9f8b56R",
    "name": "Impossible - Main",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/e46f16c94907805f4189646343356a3d7504230d",
    "artist_name": "Shontelle",
    "image_url": "https://i.scdn.co/image/6132b91d001ee8b20e8340f1a8002d0fa9ca7869",
    "artist_id": "4M6Z1wubeKtwPqbjJygTOc",
    "year": 2010
  },
  {
    "song_id": "5OiLJ8tjUPFiPX2gVM8fxJ",
    "name": "Imma Be",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/f5d2496f747e38bfe16877e26e7a993da1d9695b",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/8bffee9e32259044ae0561bc58c65929939f0cc3",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2010
  },
  {
    "song_id": "4zVl2Hv9qesl5GkdaThyKl",
    "name": "Say Aah - feat. Fabolous",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/6a86c9a35c4d74798417488161d4ef620ed6931c",
    "artist_name": "Trey Songz",
    "image_url": "https://i.scdn.co/image/8a05c1fcedc84c5e52c9aceb104b42f4fa17ceea",
    "artist_id": "2iojnBLj0qIMiKPvVhLnsH",
    "year": 2010
  },
  {
    "song_id": "15pu8u4n3q4BKl4tF20c5v",
    "name": "Alejandro",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/3732c8a62a482aa0fbf14a46ba0083c53430f877",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/4fcf0b973323f378ebe9a24390d07b463736e130",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2010
  },
  {
    "song_id": "3FF6jZY1wDHXJUUN18qsLx",
    "name": "Paparazzi",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/6dc832725206ece9810cb3eaf6b44f1b95577924",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/4fcf0b973323f378ebe9a24390d07b463736e130",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2010
  },
  {
    "song_id": "5jzKL4BDMClWqRguW5qZvh",
    "name": "Teenage Dream",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/1452bdbdb2471c8124d74c38a05b09ddd89d88f3",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/ce8f207440681b989f02c20129b0b627036fd71c",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2010
  },
  {
    "song_id": "4AboqNl74jNDpJhPfqYDmj",
    "name": "Your Love Is My Drug",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/a7047f7d454804529d731be1ed0b0e624ff50d37",
    "artist_name": "Ke$ha",
    "image_url": "https://i.scdn.co/image/81d363558a969b831e1bdc0fe088a4001ea1df69",
    "artist_id": "6LqNN22kT3074XbTVUrhzX",
    "year": 2010
  },
  {
    "song_id": "4faiJXyBflUVVOOE9fxbeg",
    "name": "Tie Me Down [feat. Ray J]",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/e2fc54e944f2c523d2e745c1894349bcdc1a70ae",
    "artist_name": "New Boyz",
    "image_url": "https://i.scdn.co/image/65d18cbe571bd49912fc7d51378e000b73f0b9c4",
    "artist_id": "1ZKqrja0WvbSssYNFO3lzs",
    "year": 2010
  },
  {
    "song_id": "1YaVmBh7EAeR54FIjuFcb5",
    "name": "Naturally",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/50bbae1c7e7f5e2f7d884a4c89888244f59c0de2",
    "artist_name": "Selena Gomez & The Scene",
    "image_url": "https://i.scdn.co/image/de1fc09f53bfef2e0c500bece09aa67903d8029b",
    "artist_id": "6dJeKm76NjfXBNTpHmOhfO",
    "year": 2010
  },
  {
    "song_id": "75vEuMA15pb8JmgrGugHEs",
    "name": "Eenie Meenie",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/0cffa19aa579b44c0db767f7a131f7f76d8b8dd2",
    "artist_name": "Justin Bieber",
    "image_url": "https://i.scdn.co/image/ebc4de0afdb9d3126dea124538f5a7479fcac70b",
    "artist_id": "1uNFoZAHBGtllmzznpCI3s",
    "year": 2010
  },
  {
    "song_id": "51YhN4y2tOvfI0Sv1hoBRo",
    "name": "Live Like We're Dying",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/bf64bf320e48f03a063521ee2cd851b0231c07a5",
    "artist_name": "Kris Allen",
    "image_url": "https://i.scdn.co/image/3d8dc8631d7d2479e95253c157d6e6a859385320",
    "artist_id": "2zwHaEmXxX6DTv4i8ajNCM",
    "year": 2010
  },
  {
    "song_id": "7jmqSz6yTrGWI1kNqNYI28",
    "name": "Blah Blah Blah",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/dbd93c4cfbe2134f108bbeda14837e42026fda64",
    "artist_name": "Ke$ha",
    "image_url": "https://i.scdn.co/image/81d363558a969b831e1bdc0fe088a4001ea1df69",
    "artist_id": "6LqNN22kT3074XbTVUrhzX",
    "year": 2010
  },
  {
    "song_id": "5AkZntfhC6IBpNtWyWTWhk",
    "name": "Nothin' On You (feat. Bruno Mars)",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/816752da568083fb5b3d6182e54956b28d4676f7",
    "artist_name": "B.o.B",
    "image_url": "https://i.scdn.co/image/4307950fb30a8c6dcc4d9043b2ef6d36fde257eb",
    "artist_id": "5ndkK3dpZLKtBklKjxNQwT",
    "year": 2010
  },
  {
    "song_id": "5LLTVSXK5qzEguVHcUNddw",
    "name": "Magic (feat. Rivers Cuomo)",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/0269e47df7739cd98c806c56c14ddd0d69e67c41",
    "artist_name": "B.o.B",
    "image_url": "https://i.scdn.co/image/4307950fb30a8c6dcc4d9043b2ef6d36fde257eb",
    "artist_id": "5ndkK3dpZLKtBklKjxNQwT",
    "year": 2010
  },
  {
    "song_id": "6H2wnX7ytNeCKERIVqCwgs",
    "name": "I Made It (Cash Money Heroes)",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/a7643a19f2afcb636d0add3db783dbe23a142c2a",
    "artist_name": "Kevin Rudolf",
    "image_url": "https://i.scdn.co/image/aa650f1b5974cf67e7d5eb7d10a0d31dd4b6550f",
    "artist_id": "0Chxmm4XMM87mJOHvyiUzL",
    "year": 2010
  },
  {
    "song_id": "7qje9qxLncMESiPeI27SAn",
    "name": "Say Something (Featuring Drake)",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/a594f35e78c33948fa5ba760b9e3cfb36593a0af",
    "artist_name": "Timbaland",
    "image_url": "https://i.scdn.co/image/a8e1b6862f51b51b684de3bea05cef92ed8c8247",
    "artist_id": "5Y5TRrQiqgUO4S36tzjIRZ",
    "year": 2010
  },
  {
    "song_id": "1WP9BnIr6SbcneveMTedPj",
    "name": "Bad Romance",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/826c73e4d439f1550725dd1e44d6871a21e07cf1",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/4fcf0b973323f378ebe9a24390d07b463736e130",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2010
  },
  {
    "song_id": "28AUzRo0nQLESL55WDV0um",
    "name": "DJ Got Us Fallin' in Love",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/f6a68d535d1b9e84449c86afc854ff0cbf0f0253",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/95ac460e42a58525b35bc21ae50fadd84668b65f",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2010
  },
  {
    "song_id": "0xfpx7AiwgSKEtjotea2L6",
    "name": "Meet Me Halfway",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/bde6d3cbd0a080069635d5c5c738331a94af5786",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/8bffee9e32259044ae0561bc58c65929939f0cc3",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2010
  },
  {
    "song_id": "3ubtdCmWZIuX8FuRv3S3KX",
    "name": "Take It Off",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/10b13691ddb3ea5310a86f7425723f54eb4a68c6",
    "artist_name": "Ke$ha",
    "image_url": "https://i.scdn.co/image/81d363558a969b831e1bdc0fe088a4001ea1df69",
    "artist_id": "6LqNN22kT3074XbTVUrhzX",
    "year": 2010
  },
  {
    "song_id": "3YJkAQNEhmCZGLdmPsu6Ye",
    "name": "Hard",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/b30401aa5b1ccd384f2ab150641c55738d83fd6c",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/6575cc84cf90bccecadf6b87bb6c3c4b53f7f6bf",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2010
  },
  {
    "song_id": "5K5LbSTVuKKe1KGMNfBgIW",
    "name": "Rock That Body",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/709794316cdcb9883f32098abc66f723ed3ca681",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/8bffee9e32259044ae0561bc58c65929939f0cc3",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2010
  },
  {
    "song_id": "72phhQtfwZcRweweRxxkmU",
    "name": "According To You",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/341f983e41c55302b3744bf2f1ccd0c198a2ab48",
    "artist_name": "Orianthi",
    "image_url": "https://i.scdn.co/image/a617c43e54357b30e9ad995b5724e66111687b43",
    "artist_id": "0yNy8fi1yBBq526E6mx4Zs",
    "year": 2010
  },
  {
    "song_id": "4ic3ILo8iILDWjzWYr9tnW",
    "name": "3",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/572593ea7dda393ba5c24919fb67b8506e07e323",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/1090f6ba99e8ade8c3c78b36892d4d6475aad880",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2010
  },
  {
    "song_id": "4l2GX4zReMCjzMXZBaZHCc",
    "name": "I Like It",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/f106d65ec33e0ef681f6355211313ba74ba27c59",
    "artist_name": "Enrique Iglesias feat. Pitbull",
    "image_url": "https://i.scdn.co/image/994db4806d7dc33e87303bf6810732169c11c12c",
    "artist_id": "2KC232QzznUIYsnVvW9pEv",
    "year": 2010
  },
  {
    "song_id": "2BvGMF8oEhvsa5YK8gfv4g",
    "name": "My Chick Bad",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/acc6db6ce58477d2855cf6d17696162294d2275f",
    "artist_name": "Ludacris",
    "image_url": "https://i.scdn.co/image/274ccb2bb22782e81e9037b02fa5a9c5fddfd7c1",
    "artist_id": "3ipn9JLAPI5GUEo4y4jcoi",
    "year": 2010
  },
  {
    "song_id": "3dit6MnKGOFMzhOtceOkXt",
    "name": "Empire State Of Mind [Jay-Z + Alicia Keys] - Amended",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/c847909c99498b591fb20a6a6a282435c6b639a8",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/fbad2d80e2586a7dfac2da9ff92febff18c3d5a3",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2010
  },
  {
    "song_id": "41oCEDvpkeiIoSEamNFTDr",
    "name": "Party In The U.S.A.",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/163080f5142dffa0bd4c3f2a4847014ad1ad60e0",
    "artist_name": "Miley Cyrus",
    "image_url": "https://i.scdn.co/image/118d2c6c2bceaa2825c2df56557a825005414099",
    "artist_id": "5YGY8feqx7naU7z4HrwZM6",
    "year": 2010
  },
  {
    "song_id": "5cSmRaAcl4JXXKNgtNJop7",
    "name": "Misery",
    "popularity": 40,
    "preview_url": "https://p.scdn.co/mp3-preview/b553467454e4cbcdd8c181d645c4f0076f62d299",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/a6c3c368e92c2a8ac4c46707469a86c5908cefc0",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2010
  },
  {
    "song_id": "0q9qUT8oT7IoFgHo9ZPYmu",
    "name": "Stuck Like Glue",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/a3fe78c6d7b84e53a59e6acb9191c480b5d184d9",
    "artist_name": "Sugarland",
    "image_url": "https://i.scdn.co/image/5b29d62fc35582a40097cec3cfff8773f0c15338",
    "artist_id": "0hYxQe3AK5jBPCr5MumLHD",
    "year": 2010
  },
  {
    "song_id": "1w6kG4Rc29m5EherbvpkF5",
    "name": "Your Love",
    "popularity": 23,
    "preview_url": "https://p.scdn.co/mp3-preview/34dbc0967bd04342f11e294fe2781d07f45f4a1d",
    "artist_name": "Nicki Minaj",
    "image_url": "https://i.scdn.co/image/8cd33bc91469bcdb1f2e2c29fc9fd31dd958c15f",
    "artist_id": "0hCNtLu0JehylgoiP8L4Gh",
    "year": 2010
  },
  {
    "song_id": "7w87IxuO7BDcJ3YUqCyMTT",
    "name": "Pumped up Kicks",
    "popularity": 83,
    "preview_url": "https://p.scdn.co/mp3-preview/9ad4c827627e12f05ca45b942897ad79303c4e3b",
    "artist_name": "Foster The People",
    "image_url": "https://i.scdn.co/image/6fce0e6034d622a11f05ab497bfdf0ba91da38d3",
    "artist_id": "7gP3bB2nilZXLfPHJhMdvc",
    "year": 2011
  },
  {
    "song_id": "386RUes7n1uM1yfzgeUuwp",
    "name": "The Lazy Song",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/607bd3d86e791956da654bb84cba5f5f11e3efc3",
    "artist_name": "Bruno Mars",
    "image_url": "https://i.scdn.co/image/3217429ca3b72ea697358f8e598d1ee44b6e018e",
    "artist_id": "0du5cEVh5yTK9QJze8zA0C",
    "year": 2011
  },
  {
    "song_id": "0JXXNGljqupsJaZsgSbMZV",
    "name": "Sure Thing",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/f32c5f693c5dcc2fc7ad64e6ef0a92691e33c699",
    "artist_name": "Miguel",
    "image_url": "https://i.scdn.co/image/171ac206ecbbb8cba8e524df7de44db5cb733c8d",
    "artist_id": "360IAlyVv4PCEVjgyMZrxK",
    "year": 2011
  },
  {
    "song_id": "4lLtanYk6tkMvooU0tWzG8",
    "name": "Grenade",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/d700c010c8acc822ae6839c5af713ec41bea8b5c",
    "artist_name": "Bruno Mars",
    "image_url": "https://i.scdn.co/image/3217429ca3b72ea697358f8e598d1ee44b6e018e",
    "artist_id": "0du5cEVh5yTK9QJze8zA0C",
    "year": 2011
  },
  {
    "song_id": "0yD66650JxhqKbW76C2qCo",
    "name": "Country Girl (Shake It For Me)",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/da724086f54cb8898b006481c613a9570156a46f",
    "artist_name": "Luke Bryan",
    "image_url": "https://i.scdn.co/image/09f82f6d6fd88861f2ccf4a8754efe52c8a90aa7",
    "artist_id": "0BvkDsjIUla7X0k6CSWh1I",
    "year": 2011
  },
  {
    "song_id": "47Slg6LuqLaX0VodpSCvPt",
    "name": "Just The Way You Are",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/348ddef4fa3cdee2cdd6b69c7dbf5e6da359002a",
    "artist_name": "Bruno Mars",
    "image_url": "https://i.scdn.co/image/3217429ca3b72ea697358f8e598d1ee44b6e018e",
    "artist_id": "0du5cEVh5yTK9QJze8zA0C",
    "year": 2011
  },
  {
    "song_id": "47hs3xNT3iOGvgmC4eXBAi",
    "name": "Coming Home",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/400968868e8ef173d1dda6f8754f305b75d35ab4",
    "artist_name": "Diddy - Dirty Money",
    "image_url": "https://i.scdn.co/image/6765452e256dd153343838f9a7ba42b9adea3450",
    "artist_id": "2QYEvpsWUOjqaYuxDPTCmV",
    "year": 2011
  },
  {
    "song_id": "3ts6xK5GzfMAAriT9AIBmP",
    "name": "Barefoot Blue Jean Night",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/3d662ecce7779b1f7cc08879708270983dde1d7f",
    "artist_name": "Jake Owen",
    "image_url": "https://i.scdn.co/image/52399a50e318b2c28d17f752e5f7942ceeb2338c",
    "artist_id": "1n2pb9Tsfe4SwAjmUac6YT",
    "year": 2011
  },
  {
    "song_id": "1CkvWZme3pRgbzaxZnTl5X",
    "name": "Rolling in the Deep",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/3b0e47b99012cc633d9b7da36e43276442aedcd6",
    "artist_name": "Adele",
    "image_url": "https://i.scdn.co/image/8fa409e337ce4d3056bb957bc1d0586ba99629b9",
    "artist_id": "4dpARuHxo51G3z768sgnrY",
    "year": 2011
  },
  {
    "song_id": "4NTWZqvfQTlOMitlVn6tew",
    "name": "The Show Goes On",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/60f75c73d241d8d9c7a439ef3076eb6ecefafc3d",
    "artist_name": "Lupe Fiasco",
    "image_url": "https://i.scdn.co/image/2600479bbaf87bb3fe02832281f2e9d431b2e2e0",
    "artist_id": "01QTIT5P1pFP3QnnFSdsJf",
    "year": 2011
  },
  {
    "song_id": "4u26EevCNXMhlvE1xFBJwX",
    "name": "If I Die Young",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/3a9d740fe24dc1d8c8ee4d96b9474f2027b22ac3",
    "artist_name": "The Band Perry",
    "image_url": "https://i.scdn.co/image/9436f6c84edccae31e442dee948425a53dfe8a10",
    "artist_id": "75FnCoo4FBxH5K1Rrx0k5A",
    "year": 2011
  },
  {
    "song_id": "1yEwEiTpsaPhQi9lb5EVV4",
    "name": "Knee Deep (feat. Jimmy Buffett)",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/32666ee6614364c7fabb28076b22d002e814374a",
    "artist_name": "Zac Brown Band",
    "image_url": "https://i.scdn.co/image/9eed11a24cded7307f6ca1f5503cf7ec7ee66180",
    "artist_id": "6yJCxee7QumYr820xdIsjo",
    "year": 2011
  },
  {
    "song_id": "2IpGdrWvIZipmaxo1YRxw5",
    "name": "Bottoms Up - feat. Nicki Minaj",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/f4def5109aa8dfeb57ca7a7a45d2bfc8d5155b97",
    "artist_name": "Trey Songz",
    "image_url": "https://i.scdn.co/image/f234ff7ad80533554d95f291b93e72bf9d23de03",
    "artist_id": "2iojnBLj0qIMiKPvVhLnsH",
    "year": 2011
  },
  {
    "song_id": "1fBl642IhJOE5U319Gy2Go",
    "name": "Animal",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/0137c8caa17422dc37f9c127efd6d5f4f7792694",
    "artist_name": "Neon Trees",
    "image_url": "https://i.scdn.co/image/339b5c3e25856afa71b6a7c890c97246efeb0784",
    "artist_id": "0RpddSzUHfncUWNJXKOsjy",
    "year": 2011
  },
  {
    "song_id": "0gY2iq0xJPRoIB1PScKSw4",
    "name": "Honey Bee",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/8f78b5270daef5e6cc32a99ae1c80301c212ee2c",
    "artist_name": "Blake Shelton",
    "image_url": "https://i.scdn.co/image/1205d44da9c7bcd700a6909048b24c59e2b976f9",
    "artist_id": "1UTPBmNbXNTittyMJrNkvw",
    "year": 2011
  },
  {
    "song_id": "1nVLAEzhBXMJLR5zAl90Nl",
    "name": "Crazy Girl",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/64b81046445416104d57f257e61762da53b5eb54",
    "artist_name": "Eli Young Band",
    "image_url": "https://i.scdn.co/image/cdf2ccfdb8221c36041004a953a9b1c9cacad86f",
    "artist_id": "6eKWqnckwdIlSnjaYgIyxv",
    "year": 2011
  },
  {
    "song_id": "0w9LJae3sVlZlH2CnxTInF",
    "name": "God Gave Me You",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/80d004eac22bb732ffe458b37a7b1984e219816a",
    "artist_name": "Blake Shelton",
    "image_url": "https://i.scdn.co/image/1205d44da9c7bcd700a6909048b24c59e2b976f9",
    "artist_id": "1UTPBmNbXNTittyMJrNkvw",
    "year": 2011
  },
  {
    "song_id": "2i0AUcEnsDm3dsqLrFWUCq",
    "name": "Tonight Tonight",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/74140957e631349b8ccd7bbaee70b121cd6796f9",
    "artist_name": "Hot Chelle Rae",
    "image_url": "https://i.scdn.co/image/a3566f5aaedbac5629d5eeedeec5d28bb48a7166",
    "artist_id": "6jTnHxhb6cDCaCu4rdvsQ0",
    "year": 2011
  },
  {
    "song_id": "2nRvKHh2mtOfIQ4jcAkP9q",
    "name": "Just a Kiss",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/71c94ab246bf59d5eb6c5fe70a0deb4b0dc3ac79",
    "artist_name": "Lady Antebellum",
    "image_url": "https://i.scdn.co/image/741eaa5945c5d26eb7e1268970cbe57f97f14f3c",
    "artist_id": "32WkQRZEVKSzVAAYqukAEA",
    "year": 2011
  },
  {
    "song_id": "09ZcYBGFX16X8GMDrvqQwt",
    "name": "For the First Time",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/5a995f060ff50c406cc8977e937443c978580421",
    "artist_name": "The Script",
    "image_url": "https://i.scdn.co/image/4285e42015c2184a58ebc2e49fd6765b30db8aff",
    "artist_id": "3AQRLZ9PuTAozP28Skbq8V",
    "year": 2011
  },
  {
    "song_id": "1t2hJFgJyUPD2crOafMUEk",
    "name": "You and Tequila",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/d898e997ce8dfd70d8266a5f2d7e002a5306e996",
    "artist_name": "Kenny Chesney",
    "image_url": "https://i.scdn.co/image/b7ee15bd58a98b56d204b39978672ff33a40be69",
    "artist_id": "3grHWM9bx2E9vwJCdlRv9O",
    "year": 2011
  },
  {
    "song_id": "5xZtUAVUVfjBtVtMOgMkyy",
    "name": "What's My Name?",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/93857413839c0387b8d9f3c49cb232c45ef0c1b3",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/43c4ffbd79d41c4f9093f451ddc543b29046cfb8",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2011
  },
  {
    "song_id": "4DvhkX2ic4zWkQeWMwQ2qf",
    "name": "Like A G6",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/be360b1aa77f49e17df1079dd7802e97cad3190a",
    "artist_name": "Far East Movement",
    "image_url": "https://i.scdn.co/image/e021b336e833c0a9076f9fb879ec206892d764d6",
    "artist_id": "698hF4vcwHwPy8ltmXermq",
    "year": 2011
  },
  {
    "song_id": "5OrX4PR74Ttezdj5soO1BV",
    "name": "Best Thing I Never Had",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/cfd9861b67cac0f98e0a7ef0494d1c43f41d7627",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/e30905697414f09e542c42a5aea4305e4df2933a",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2011
  },
  {
    "song_id": "4ABua0yuWcpTotImEEJTaw",
    "name": "Remind Me (Duet with Carrie Underwood)",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/be4d227a8d2c5e3783851212919f244fbda2e08a",
    "artist_name": "Brad Paisley",
    "image_url": "https://i.scdn.co/image/e5bbf08ad7bb309c84814b071eb9b6ef77b24b18",
    "artist_id": "13YmWQJFwgZrd4bf5IjMY4",
    "year": 2011
  },
  {
    "song_id": "08Bfk5Y2S5fCxgxk371Eel",
    "name": "S&M",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/fd855b5d9866c8a063ea7ce0b9b1f7498276a8f5",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/43c4ffbd79d41c4f9093f451ddc543b29046cfb8",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2011
  },
  {
    "song_id": "0obBFrPYkSoBJbvHfUIhkv",
    "name": "Sexy And I Know It",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/0de3f56eb493ca153059e7c6facb2a9b8e3b59fd",
    "artist_name": "LMFAO",
    "image_url": "https://i.scdn.co/image/c2eb976acf7f5600efd9eb062c2ca8db234ca868",
    "artist_id": "3sgFRtyBnxXD5ESfmbK4dl",
    "year": 2011
  },
  {
    "song_id": "4356Typ82hUiFAynbLYbPn",
    "name": "DJ Got Us Fallin' in Love",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/f6a68d535d1b9e84449c86afc854ff0cbf0f0253",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/4116a514ff02e70ae30c9b21b51c38131387d694",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2011
  },
  {
    "song_id": "6xtXdS8ALTfK0g9hOG1PSX",
    "name": "Are You Gonna Kiss Me Or Not",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/bb30676f459d479db7964610cf950c78fd104889",
    "artist_name": "Thompson Square",
    "image_url": "https://i.scdn.co/image/cde466ee75fe685ffcfe9c883245dd13d89ea168",
    "artist_id": "0Bvs8yPjrQSbmVIRqSg1Sp",
    "year": 2011
  },
  {
    "song_id": "6r2BECwMgEoRb5yLfp0Hca",
    "name": "Born This Way",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/a774c5628ac3616475a5068651736ab1370399f8",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/e259bd90356a9d2e55dd4210757b84889622eb5a",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2011
  },
  {
    "song_id": "5zTzDqrEmseqL2G8ElgBu7",
    "name": "What the Hell",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/d6adeb025ea770b819c2b9d4679f9e796bb0006a",
    "artist_name": "Avril Lavigne",
    "image_url": "https://i.scdn.co/image/dd73b94034fc99a3dd556e4026d205e2199e66f9",
    "artist_id": "0p4nmQO2msCgU4IF37Wi3j",
    "year": 2011
  },
  {
    "song_id": "0IkKz2J93C94Ei4BvDop7P",
    "name": "Party Rock Anthem",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/78203cbd64fc1453045a92c7eab0274f55d22d0a",
    "artist_name": "LMFAO",
    "image_url": "https://i.scdn.co/image/5ae057d2f9fadbbaa5482fc22b35a5e520b44433",
    "artist_id": "3sgFRtyBnxXD5ESfmbK4dl",
    "year": 2011
  },
  {
    "song_id": "46MDLc0Yip6xCMSsdePOAU",
    "name": "Cheers (Drink To That)",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/e97395cd69fc2b231b5e9e72335055cc55bf9606",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/43c4ffbd79d41c4f9093f451ddc543b29046cfb8",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2011
  },
  {
    "song_id": "3djPwpsQAnp8B8gC81e7fk",
    "name": "Where Them Girls At - feat. Nicki Minaj & Flo Rida",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/fec0bffdc0a65ebbd1da4e88f6fc1c003ac9afb2",
    "artist_name": "David Guetta",
    "image_url": "https://i.scdn.co/image/70ebea3bb8f4517174fce02354cda4fe95b55625",
    "artist_id": "1Cs0zKBU1kc0i8ypK3B9ai",
    "year": 2011
  },
  {
    "song_id": "45sDIKapDyxPl307QpEAwl",
    "name": "Rocketeer",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/d799744416d7203084be02f931aef27700cf42ab",
    "artist_name": "Far East Movement",
    "image_url": "https://i.scdn.co/image/e021b336e833c0a9076f9fb879ec206892d764d6",
    "artist_id": "698hF4vcwHwPy8ltmXermq",
    "year": 2011
  },
  {
    "song_id": "61HQWI1Woxup7CnGwVUsdI",
    "name": "Written In The Stars - feat. Eric Turner",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/1377951762e9c34172447839cf19fcc9fcc2a4e8",
    "artist_name": "Tinie Tempah",
    "image_url": "https://i.scdn.co/image/e9afc9799ec88367323490a9f43d9a77f2dd7bac",
    "artist_id": "0Tob4H0FLtEONHU1MjpUEp",
    "year": 2011
  },
  {
    "song_id": "7AqISujIaWcY3h5zrOqt5v",
    "name": "Forget You",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/041d673722a8052975c83015deaa3f5281bd87d1",
    "artist_name": "Cee Lo Green",
    "image_url": "https://i.scdn.co/image/4df999f5dda9c54a66047a4b42f76b93b607433e",
    "artist_id": "5nLYd9ST4Cnwy6NHaCxbj8",
    "year": 2011
  },
  {
    "song_id": "1VDXQhu7YGdbM6UeEIfsaX",
    "name": "Only Girl (In The World)",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/757d99233ab152eba84dcc55463b5a61ab039901",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/43c4ffbd79d41c4f9093f451ddc543b29046cfb8",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2011
  },
  {
    "song_id": "3ZdJffjzJWFimSQyxgGIxN",
    "name": "Just A Dream - Main",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/d85cec8f026aa8e59c68714167da87b4d9ee5213",
    "artist_name": "Nelly",
    "image_url": "https://i.scdn.co/image/97fa0448c1f031bffe3d2693343ca8245f23abc6",
    "artist_id": "2gBjLmx6zQnFGQJCAQpRgw",
    "year": 2011
  },
  {
    "song_id": "3pVYaDSQ4z0RIrxXQOzbR9",
    "name": "Headlines - Edited Version",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/5b3bd25894ce34dd8884bbed9e244ba78722a211",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/6fa8350fb6fe1f55506c2bc0735702532d1a4066",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2011
  },
  {
    "song_id": "1r3myKmjWoOqRip99CmSj1",
    "name": "Don't Wanna Go Home",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/5a784910a6535c53db085ddabc84628e844c4f38",
    "artist_name": "Jason Derulo",
    "image_url": "https://i.scdn.co/image/a5a3ed927818cda44e7f86838818e657c285ead6",
    "artist_id": "07YZf4WDAMNwqr4jfgOZ8y",
    "year": 2011
  },
  {
    "song_id": "7q7YfXDqbqteY6BZFZr577",
    "name": "She Ain't You",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/0a80a9d0d0ba5f92def8116e2df1900ab4779ab8",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/e7fcb6fab6265c59513d2c5341521af22da6896c",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2011
  },
  {
    "song_id": "2708Nq8H3EEBcahVCNsfTe",
    "name": "Super Bass",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/00062060bdb5e058bdbbf1da709117712d7bfd8f",
    "artist_name": "Nicki Minaj",
    "image_url": "https://i.scdn.co/image/aaf93c28a643621b5b3c3540db61d260074bb817",
    "artist_id": "0hCNtLu0JehylgoiP8L4Gh",
    "year": 2011
  },
  {
    "song_id": "7oOfd5BP16mS0Vrh1PdRHt",
    "name": "Good Life",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/a9bc597b0df158cefe6e0433540442e7d1ff9441",
    "artist_name": "OneRepublic",
    "image_url": "https://i.scdn.co/image/0f3bf8d4acaebc9867b83efdbed7a39643a0cb0d",
    "artist_id": "5Pwc4xIPtQLFEnJriah9YJ",
    "year": 2011
  },
  {
    "song_id": "7rGMKCgeYXpBecQ1FPb3oc",
    "name": "Without You (feat. Usher)",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/d03ed239a5e152b79a6fde85a1381f4987704d98",
    "artist_name": "David Guetta",
    "image_url": "https://i.scdn.co/image/70ebea3bb8f4517174fce02354cda4fe95b55625",
    "artist_id": "1Cs0zKBU1kc0i8ypK3B9ai",
    "year": 2011
  },
  {
    "song_id": "4270XtiZeN9yYPR81dn8Ow",
    "name": "Tonight (I'm Lovin' You)",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/ba01847f9f69b39c1c3cdf13c615192630d4d49c",
    "artist_name": "Enrique Iglesias",
    "image_url": "https://i.scdn.co/image/4f498b95a0075e57dcc9cd3bad8b707d0cdffb42",
    "artist_id": "7qG3b048QCHVRO5Pv1T5lw",
    "year": 2011
  },
  {
    "song_id": "7gdGdDgpCDy2kACTqvXbYa",
    "name": "6 Foot 7 Foot - Edited Version",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/73d8ebf8ce96da63aa56d6e0d413d8f4900f0149",
    "artist_name": "Lil Wayne",
    "image_url": "https://i.scdn.co/image/0aae57dd35b1a4f2b43a8d065ad038e7a781e9d7",
    "artist_id": "55Aa2cqylxrFIXC767Z865",
    "year": 2011
  },
  {
    "song_id": "32eXkt1gAj4J9Dyav2bvSZ",
    "name": "How To Love",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/54c6165fd44cbcca70506686c423c738d5329ae0",
    "artist_name": "Lil Wayne",
    "image_url": "https://i.scdn.co/image/0aae57dd35b1a4f2b43a8d065ad038e7a781e9d7",
    "artist_id": "55Aa2cqylxrFIXC767Z865",
    "year": 2011
  },
  {
    "song_id": "7CELQKrSCYpEi9OXWCBDek",
    "name": "Roll Up",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/2fff9a6436da3001a201d025f4f281b9462928c6",
    "artist_name": "Wiz Khalifa",
    "image_url": "https://i.scdn.co/image/920b51b24e5a438442c122beb48fa44136c487dc",
    "artist_id": "137W8MRPWKqSmrBGDBFSop",
    "year": 2011
  },
  {
    "song_id": "1WH1mGKDXbvV3EApLMOaXn",
    "name": "She Will",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/75dc90adccaed85a16f0dc73d39eb9cd0be7c1ce",
    "artist_name": "Lil Wayne",
    "image_url": "https://i.scdn.co/image/0aae57dd35b1a4f2b43a8d065ad038e7a781e9d7",
    "artist_id": "55Aa2cqylxrFIXC767Z865",
    "year": 2011
  },
  {
    "song_id": "3VP78k3jzm0Q5OM08E383k",
    "name": "Black And Yellow",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/ed529f0e7bee5541a6add30f4f2ed3e0d2d5bc21",
    "artist_name": "Wiz Khalifa",
    "image_url": "https://i.scdn.co/image/920b51b24e5a438442c122beb48fa44136c487dc",
    "artist_id": "137W8MRPWKqSmrBGDBFSop",
    "year": 2011
  },
  {
    "song_id": "6XUIbizGHk6EJiUDWEJtpY",
    "name": "Blow",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/6e1360f74a50e9553b2cc610391cb1dfd178086a",
    "artist_name": "Ke$ha",
    "image_url": "https://i.scdn.co/image/a03f39f6ae96bde56c4814a90d012d099987b6c2",
    "artist_id": "6LqNN22kT3074XbTVUrhzX",
    "year": 2011
  },
  {
    "song_id": "5ryMR3slEKQXaGYGdZVSSw",
    "name": "Motivation",
    "popularity": 51,
    "preview_url": "https://p.scdn.co/mp3-preview/d91605ec1d0068e6df1407d80117c0c04f480821",
    "artist_name": "Kelly Rowland",
    "image_url": "https://i.scdn.co/image/2ec9cf37eb3b79507fcb2f27c1c5075d99477ef0",
    "artist_id": "3AuMNF8rQAKOzjYppFNAoB",
    "year": 2011
  },
  {
    "song_id": "7JkSoesJ9odqqYuzXkwEGD",
    "name": "Pretty Girl Rock",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/2aaac34f93153e0874f6ca899ea69267fe351632",
    "artist_name": "Keri Hilson",
    "image_url": "https://i.scdn.co/image/09b37f19912f8cc1b16ff78bc11e0ca5910b8b79",
    "artist_id": "63wjoROpeh5f11Qm93UiJ1",
    "year": 2011
  },
  {
    "song_id": "6szOfaZn6H3rZXnUqgJ4R7",
    "name": "You And I",
    "popularity": 50,
    "preview_url": "https://p.scdn.co/mp3-preview/6e52b71fffb50e6905f3438804bfaffe6dcf7ff3",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/e259bd90356a9d2e55dd4210757b84889622eb5a",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2011
  },
  {
    "song_id": "3hsBI1UxLFrIfYgfl56Nih",
    "name": "The Time (Dirty Bit)",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/98af9cb2ae36cd50b708486d1e41fb5c9d291d45",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/9ecc18208806b9c43c9d0d251875fd102afb2f0e",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2011
  },
  {
    "song_id": "5MtbCiKeHd86jvJm5B0P4q",
    "name": "Hold It Against Me",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/e66351f3c658ab4c84101b7e97fe032628cb1810",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/e2dce16a9de62d41de4d084c4cbe6b04d22cd860",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2011
  },
  {
    "song_id": "737WLYKWrI76tlunnexOsq",
    "name": "Till the World Ends",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/24ba41fb6d468ea4e2f4c37dd3e8650369810a42",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/e2dce16a9de62d41de4d084c4cbe6b04d22cd860",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2011
  },
  {
    "song_id": "6uxYfENm7zw050dNUi031W",
    "name": "The Edge Of Glory",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/41ccd2da438a9589f0886c3f59b66dc3e3d07094",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/e259bd90356a9d2e55dd4210757b84889622eb5a",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2011
  },
  {
    "song_id": "0pSIJCxYqZNIZBTAnIXOkv",
    "name": "Lighters",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/63874a8076617b8a169f6f8ffe616b6c186e6ae7",
    "artist_name": "Bad Meets Evil",
    "image_url": "https://i.scdn.co/image/e390c3079e57a78156ddaf4bbcc41bce15cf3918",
    "artist_id": "77IURH5NC56Jn09QHi76is",
    "year": 2011
  },
  {
    "song_id": "4ywvnllm7kpaXDsa1VNNpY",
    "name": "Stereo Hearts - feat. Adam Levine",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/fd12dc3da666f49441a79c2370ca6de8868a929e",
    "artist_name": "Gym Class Heroes",
    "image_url": "https://i.scdn.co/image/3588337ab02096b49ae5a3a4e691439827ead796",
    "artist_id": "4IJczjB0fJ04gs4uvP0Fli",
    "year": 2011
  },
  {
    "song_id": "7xnp3wEx1gVT3xofhAVgwg",
    "name": "Hey Baby (Drop It To The Floor)",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/c98d08147740ed6bad32988794617c673c5bc0f6",
    "artist_name": "Pitbull",
    "image_url": "https://i.scdn.co/image/3d397071d0e97f18471ebe45852d14681773da0b",
    "artist_id": "0TnOYISbd1XYRBk9myaseg",
    "year": 2011
  },
  {
    "song_id": "3t4HeVfURZ5DuqMWz0Cmqg",
    "name": "We Found Love",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/02539e22523852f5073b1d746358b07adc8916e2",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/1ed07c2ace7df8b3f501b592ed4949e5a47216b9",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2011
  },
  {
    "song_id": "4zzL7ATa95g88MwzuQ1v8G",
    "name": "Raise Your Glass",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/9d03e5fe5d3bc952c41d07ff4a8b4c7700f85feb",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/ffa134e9f14044db723e87cbde4d0b5327302f72",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2011
  },
  {
    "song_id": "18qk1M9s2xtu53fEQtM3bE",
    "name": "I Wanna Go",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/de9d424c1cb4845aff436804de1a47b768eb5723",
    "artist_name": "Britney Spears",
    "image_url": "https://i.scdn.co/image/e2dce16a9de62d41de4d084c4cbe6b04d22cd860",
    "artist_id": "26dSoYclwsYLMAKD3tpOr4",
    "year": 2011
  },
  {
    "song_id": "6ezyEFqZDgQUvxpaDv4jKO",
    "name": "Price Tag",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/232cd3addc9826b6bffd7094fa2b5da64a83b5bc",
    "artist_name": "Jessie J",
    "image_url": "https://i.scdn.co/image/a4bd24160c11f80a00f15d5dc80bd0a48f9ed89f",
    "artist_id": "2gsggkzM5R49q6jpPvazou",
    "year": 2011
  },
  {
    "song_id": "5oBPpIwObzAbBwdINug5I6",
    "name": "Give Me Everything",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/9fe9dcb0f62a9d3072aae0e77ad3330c7fad2505",
    "artist_name": "Pitbull",
    "image_url": "https://i.scdn.co/image/3d397071d0e97f18471ebe45852d14681773da0b",
    "artist_id": "0TnOYISbd1XYRBk9myaseg",
    "year": 2011
  },
  {
    "song_id": "4etypRtRn8IgsxZu6BhRS6",
    "name": "Someone Like You",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/2904625be93766c2f455d862eabe06245e3d46f8",
    "artist_name": "Adele",
    "image_url": "https://i.scdn.co/image/8fa409e337ce4d3056bb957bc1d0586ba99629b9",
    "artist_id": "4dpARuHxo51G3z768sgnrY",
    "year": 2011
  },
  {
    "song_id": "1nPPh0CiKqVvYl4Mx1aZ21",
    "name": "Just Can’t Get Enough",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/a5c44cfaae0a72f024c6ab58d1bb04619746eb30",
    "artist_name": "The Black Eyed Peas",
    "image_url": "https://i.scdn.co/image/9ecc18208806b9c43c9d0d251875fd102afb2f0e",
    "artist_id": "1yxSLGMDHlW21z4YXirZDS",
    "year": 2011
  },
  {
    "song_id": "3STTVKfJGTdhbg5aLppEzX",
    "name": "On The Floor",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/f17720bc08124c887d4c216446420fffb583c549",
    "artist_name": "Jennifer Lopez",
    "image_url": "https://i.scdn.co/image/8c9e9d29f0b0900d3b2fb0bc2e01f607334fbee9",
    "artist_id": "2DlGxzQSjYe5N6G9nkYghR",
    "year": 2011
  },
  {
    "song_id": "5ZxCk43qwHrzVUahCyRLEm",
    "name": "Down On Me",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/c44377ebc640db5323e9ffdfbab723daace95bd0",
    "artist_name": "Jeremih",
    "image_url": "https://i.scdn.co/image/048fa2332e02bf2ef94b64e9fc22d29e2311bae5",
    "artist_id": "3KV3p5EY4AvKxOlhGHORLg",
    "year": 2011
  },
  {
    "song_id": "70Wb2xKOAOte8iNkBpezQs",
    "name": "No Hands [feat. Roscoe Dash and Wale] - Amended",
    "popularity": 44,
    "preview_url": "https://p.scdn.co/mp3-preview/513c874cefd4233c664603ee46d78df7a769f64f",
    "artist_name": "Waka Flocka Flame",
    "image_url": "https://i.scdn.co/image/0414bd62b69d75579234f318bc5429bdf53eb849",
    "artist_id": "6f4XkbvYlXMH0QgVRzW0sM",
    "year": 2011
  },
  {
    "song_id": "3G1Na74xxuAhiWLyXDPCFN",
    "name": "I Need A Doctor - Edited Version",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/f561f05dfad5fb05fb9048aa2d8ad5ca46e39884",
    "artist_name": "Dr. Dre",
    "image_url": "https://i.scdn.co/image/502d6cf4370232772bd5640608b32cfc05ae57d7",
    "artist_id": "6DPYiyq5kWVQS4RGwxzPC7",
    "year": 2011
  },
  {
    "song_id": "7zbDSQelDmlaEhUDnLMViZ",
    "name": "All Of The Lights - Album Version (Edited)",
    "popularity": 43,
    "preview_url": "https://p.scdn.co/mp3-preview/34fffb6b881e3b49bfdbbd913c5215bb68d623c1",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/8ec327bb0d8453aff35d1e10a8d6257a3aaab3d4",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2011
  },
  {
    "song_id": "6lCTs5NDYctSQutADkeEsA",
    "name": "We R Who We R",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/2c31a0d817463b116c246171dfff672bcd747757",
    "artist_name": "Ke$ha",
    "image_url": "https://i.scdn.co/image/a03f39f6ae96bde56c4814a90d012d099987b6c2",
    "artist_id": "6LqNN22kT3074XbTVUrhzX",
    "year": 2011
  },
  {
    "song_id": "4Fmwzj3lxLrIVsLyEt4p8G",
    "name": "I'm On One - Edited Version",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/e94e37ddf73629c90cd075cbcecdd7d5979a5907",
    "artist_name": "DJ Khaled",
    "image_url": "https://i.scdn.co/image/66968de4f0c4f3aa1f1f9cb83b9118969357839c",
    "artist_id": "0QHgL1lAIqAw0HtD7YldmP",
    "year": 2011
  },
  {
    "song_id": "74yEgTOgJIohfN1zh52AjN",
    "name": "Stereo Love - Original Version",
    "popularity": 42,
    "preview_url": "https://p.scdn.co/mp3-preview/ca45b732ce5dcc1d59563dc20fa66c7bcd1b1a84",
    "artist_name": "Edward Maya",
    "image_url": "https://i.scdn.co/image/86552f97b79e0e6f7ff90745e55330ca23dde24a",
    "artist_id": "6XwwFnewNgWp81MYMK8zLq",
    "year": 2011
  },
  {
    "song_id": "2p3ygTLthnqH1XmE8b7mtS",
    "name": "jar of hearts",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/d41873849e028c74361c8b456d3638270674aeb7",
    "artist_name": "Christina Perri",
    "image_url": "https://i.scdn.co/image/ce396b319ff6bf8a23ef09ad21752b541646c237",
    "artist_id": "7H55rcKCfwqkyDFH9wpKM6",
    "year": 2011
  },
  {
    "song_id": "4VTErodIk9Z2rof8g4AqaK",
    "name": "In The Dark",
    "popularity": 41,
    "preview_url": "https://p.scdn.co/mp3-preview/5ecbab41f11b7558f6b575f39c0897111c3755dd",
    "artist_name": "DEV",
    "image_url": "https://i.scdn.co/image/71cf5215e9e7ceefb135e3c81cee44adbc10e392",
    "artist_id": "7Ip2u3e5Nv6fFb5xyIHxEE",
    "year": 2011
  },
  {
    "song_id": "1H9AbpMAShkCVuaPAR6CHf",
    "name": "F**kin' Perfect",
    "popularity": 39,
    "preview_url": "https://p.scdn.co/mp3-preview/bda17bc936a0b702b6d4bffa70cc584adc0fe09b",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/15f056a96a752a3d0f6b37d6081ef98822735e0d",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2011
  },
  {
    "song_id": "3wkKkFAtYSTRwqOydW6T0I",
    "name": "Please Don't Go",
    "popularity": 38,
    "preview_url": "https://p.scdn.co/mp3-preview/30ea3fc83e88b7b9126606d093b3dde5887fa7fa",
    "artist_name": "Mike Posner",
    "image_url": "https://i.scdn.co/image/9dfaf595756c6b9cde3e45a43fba82bf6aa41f5f",
    "artist_id": "2KsP6tYLJlTBvSUxnwlVWa",
    "year": 2011
  },
  {
    "song_id": "3XqdUqkc3t3klNV0ykFQu3",
    "name": "You Make Me Feel... - feat. Sabi",
    "popularity": 37,
    "preview_url": "https://p.scdn.co/mp3-preview/01db32a0cb4a67eb4af313afcd239f961b745164",
    "artist_name": "Cobra Starship",
    "image_url": "https://i.scdn.co/image/02753ef2fbed28f647c5882572c80341d5c4a2bf",
    "artist_id": "2aYJ5LAta2ScCdfLhKgZOY",
    "year": 2011
  },
  {
    "song_id": "1VENmdw9mA1R8qGKTHwjqk",
    "name": "Who Says",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/9f262d7b7aef2638cd8b81bc98152fb578675579",
    "artist_name": "Selena Gomez & The Scene",
    "image_url": "https://i.scdn.co/image/164825b6baaae0094bf366f96cdac7054d1ff71f",
    "artist_id": "6dJeKm76NjfXBNTpHmOhfO",
    "year": 2011
  },
  {
    "song_id": "0tDElYeVQUlbGdTHf7S0bK",
    "name": "Colder Weather",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/7107dc4ae43d970100dfa45d737ffb144b968a6d",
    "artist_name": "Zac Brown Band",
    "image_url": "https://i.scdn.co/image/9eed11a24cded7307f6ca1f5503cf7ec7ee66180",
    "artist_id": "6yJCxee7QumYr820xdIsjo",
    "year": 2011
  },
  {
    "song_id": "2SsYUdeL0WBlL6CipICSN5",
    "name": "Moment 4 Life - Album Version (Edited)",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/5bbc8d18d88f8b4f5110d1329041dc0e2b1bb102",
    "artist_name": "Nicki Minaj",
    "image_url": "https://i.scdn.co/image/aaf93c28a643621b5b3c3540db61d260074bb817",
    "artist_id": "0hCNtLu0JehylgoiP8L4Gh",
    "year": 2011
  },
  {
    "song_id": "3qgZzdpfU8wXeUFV6Jxuzi",
    "name": "Backseat - feat. The Cataracs & Dev",
    "popularity": 35,
    "preview_url": "https://p.scdn.co/mp3-preview/8f63c3fa7ee584384c758e15fe76dfeedff62dbd",
    "artist_name": "New Boyz",
    "image_url": "https://i.scdn.co/image/bef8092e092eccd076a0ab15a1fc47726b6c4d95",
    "artist_id": "1ZKqrja0WvbSssYNFO3lzs",
    "year": 2011
  },
  {
    "song_id": "6Uj0DygqlzJqduyny4lMA6",
    "name": "My Last - Album Version (Edited)",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/3981e708de8540a9623d969566171434b2519bc2",
    "artist_name": "Big Sean",
    "image_url": "https://i.scdn.co/image/6f0ab30ed20a160d830c963322487b0a3da5cc45",
    "artist_id": "0c173mlxpT3dSFRgMO8XPh",
    "year": 2011
  },
  {
    "song_id": "4wIjXMeLH3MrXEiaXNfYwC",
    "name": "Firework",
    "popularity": 33,
    "preview_url": "https://p.scdn.co/mp3-preview/f68ce986b0d793d9deba7200b3b1557d8c4807ef",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/8495de819ee52a11c1d428e9b56109bdf637b0d4",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2011
  },
  {
    "song_id": "3wllvxhwIbGYY5rte1bZFn",
    "name": "Last Friday Night (T.G.I.F.)",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/6b419ceb20e3ca3ea800832b1ab3010d744b2c26",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/8495de819ee52a11c1d428e9b56109bdf637b0d4",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2011
  },
  {
    "song_id": "7Ee0JfcUAJq7pDFrnx5WYF",
    "name": "Yeah 3x",
    "popularity": 31,
    "preview_url": "https://p.scdn.co/mp3-preview/ee668d6b81503b73c915b8ce036e7dc3d6f68b79",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/e7fcb6fab6265c59513d2c5341521af22da6896c",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2011
  },
  {
    "song_id": "0yjf2c8T4Nvbkw6GwamHPS",
    "name": "Look At Me Now",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/13e958ef413464425b1d59344cba2cc217be037b",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/e7fcb6fab6265c59513d2c5341521af22da6896c",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2011
  },
  {
    "song_id": "0RSvhappnRHZYXeEBgXHHz",
    "name": "Teenage Dream",
    "popularity": 30,
    "preview_url": "https://p.scdn.co/mp3-preview/1452bdbdb2471c8124d74c38a05b09ddd89d88f3",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/8495de819ee52a11c1d428e9b56109bdf637b0d4",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2011
  },
  {
    "song_id": "4S1qeuIO9X6oOW38r3O0R3",
    "name": "E.T.",
    "popularity": 28,
    "preview_url": "https://p.scdn.co/mp3-preview/c8110b75cb90fb45097854f465cb4ab1cef3c1f1",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/8495de819ee52a11c1d428e9b56109bdf637b0d4",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2011
  },
  {
    "song_id": "6nek1Nin9q48AVZcWs9e9D",
    "name": "Paradise",
    "popularity": 83,
    "preview_url": "https://p.scdn.co/mp3-preview/326efc42921be1410c4527255be6fbd7e6d6df99",
    "artist_name": "Coldplay",
    "image_url": "https://i.scdn.co/image/a370c04deca9f796749e6d07fc6af5bbf69ab9a7",
    "artist_id": "4gzpq5DPGxSnKTe4SA8HAU",
    "year": 2012
  },
  {
    "song_id": "5HQVUIKwCEXpe7JIHyY734",
    "name": "Young, Wild & Free - feat. Bruno Mars",
    "popularity": 81,
    "preview_url": "https://p.scdn.co/mp3-preview/462220ce7f0e27be2ecec50bebf975ba006b6c52",
    "artist_name": "Snoop Dogg",
    "image_url": "https://i.scdn.co/image/d4e1be09af57961ff630662daa5e44e75a99f18b",
    "artist_id": "7hJcb9fa4alzcOq3EaNPoG",
    "year": 2012
  },
  {
    "song_id": "3ehrxAhYms24KLPG8FZe0W",
    "name": "We Are Young (feat. Janelle Monáe)",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/d54847d421fb73d2cfef7fa0d980ca1a6ab0b7dd",
    "artist_name": "Fun.",
    "image_url": "https://i.scdn.co/image/d74fec030b9450ecbee94c39b253c3915c8a0208",
    "artist_id": "5nCi3BB41mBaMH9gfr6Su0",
    "year": 2012
  },
  {
    "song_id": "2Zb7wnGUnNPCas2E0wWSQ5",
    "name": "Ni**as In Paris",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/49010cbd7f1976919b2fc3c772e3364d5f008481",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/d794460520c3ace23242ab5dd2be938695723848",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2012
  },
  {
    "song_id": "6t6oULCRS6hnI7rm0h5gwl",
    "name": "Some Nights",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/92471fcfdf9cbf9c8a14a913eb2ade912f2efadf",
    "artist_name": "Fun.",
    "image_url": "https://i.scdn.co/image/a04961a66c132aaf5628b8b90d6d73ca0ae73ad8",
    "artist_id": "5nCi3BB41mBaMH9gfr6Su0",
    "year": 2012
  },
  {
    "song_id": "6mnjcTmK8TewHfyOp3fC9C",
    "name": "Die Young",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/6e0b1e46a207ef26b50ea2e3adcaa2cfc380a870",
    "artist_name": "Ke$ha",
    "image_url": "https://i.scdn.co/image/9a7afeac61fa2293412d3dc7ee0d03e82ba9631f",
    "artist_id": "6LqNN22kT3074XbTVUrhzX",
    "year": 2012
  },
  {
    "song_id": "6qOEjO2IUD7PjtpsXawq0d",
    "name": "Girl on Fire",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/be98c86a5593a263de95d8318ab488630f79c0cc",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/2b19defeeeeaf47b3921f8cdc354a10778755283",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2012
  },
  {
    "song_id": "7oVEtyuv9NBmnytsCIsY5I",
    "name": "BURN IT DOWN",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/b7c053bec433f29295e26aab6e6d8e0f4a85d3d9",
    "artist_name": "Linkin Park",
    "image_url": "https://i.scdn.co/image/c16123c8441b4a5cb423322d5a8727042af8e6fd",
    "artist_id": "6XyY86QOPPrYVGvF9ch6wz",
    "year": 2012
  },
  {
    "song_id": "7DfFc7a6Rwfi3YQMRbDMau",
    "name": "Thinkin Bout You",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/12d783d8fb8db8c94add736eab5e9ff6e504e0a5",
    "artist_name": "Frank Ocean",
    "image_url": "https://i.scdn.co/image/3012f6af84d128b2261b54b40dae836d0102a553",
    "artist_id": "2h93pZq0e7k5yf4dywlkpM",
    "year": 2012
  },
  {
    "song_id": "0i5el041vd6nxrGEU8QRxy",
    "name": "Cruise",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/717c4fe4b02afcf0031c622b5608b36e0bf936f8",
    "artist_name": "Florida Georgia Line",
    "image_url": "https://i.scdn.co/image/0c70fb80136b36a63011423fe4633a1bcfbafaa5",
    "artist_id": "3b8QkneNDz4JHKKKlLgYZg",
    "year": 2012
  },
  {
    "song_id": "4wCmqSrbyCgxEXROQE6vtV",
    "name": "Somebody That I Used To Know",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/a8c3312f5318bbdddecb3003f28f4cdc4d3c2e94",
    "artist_name": "Gotye",
    "image_url": "https://i.scdn.co/image/d3ed6fbd6b76e65a31536c0de87713c4a624d2ff",
    "artist_id": "2AsusXITU8P25dlRNhcAbG",
    "year": 2012
  },
  {
    "song_id": "5Z7ygHQo02SUrFmcgpwsKW",
    "name": "Wild Ones (feat. Sia)",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/a0fc9225bfe426d6b1681d6e0079cba7148aa80e",
    "artist_name": "Flo Rida",
    "image_url": "https://i.scdn.co/image/66295d55ff3852e7d4f4ab89cae18bf0205c7e28",
    "artist_id": "0jnsk9HBra6NMjO2oANoPY",
    "year": 2012
  },
  {
    "song_id": "5JLv62qFIS1DR3zGEcApRt",
    "name": "Wide Awake",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/f619f4bbf509141d23649fa1c7a28b7edbfecdf4",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/eaa88bf0154395e6d9d03cd7935d308d141aba7d",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2012
  },
  {
    "song_id": "4kflIGfjdZJW4ot2ioixTB",
    "name": "Someone Like You",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/223cdaebf4d9317edfb5ff521784fa788e05c7ba",
    "artist_name": "Adele",
    "image_url": "https://i.scdn.co/image/8fa409e337ce4d3056bb957bc1d0586ba99629b9",
    "artist_id": "4dpARuHxo51G3z768sgnrY",
    "year": 2012
  },
  {
    "song_id": "0ltBH1JNzSvQJPjJpvTu9B",
    "name": "Whistle",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/173132b6d606fb7341ff11dd10063f74d2571a27",
    "artist_name": "Flo Rida",
    "image_url": "https://i.scdn.co/image/d309692757e83535c1b185ecc8429a19361c9207",
    "artist_id": "0jnsk9HBra6NMjO2oANoPY",
    "year": 2012
  },
  {
    "song_id": "12Ns5IphkblPmHxpRILG9t",
    "name": "Don't Wake Me Up",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/06f562b9838e0791b87efeffd28611dd7c799cc8",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/14235be136016bd986eae90c7460e8a686c2b0ce",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2012
  },
  {
    "song_id": "1XpYodsD36XN7ygcdF7mJJ",
    "name": "The A Team",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/6a95b8e2a270c75871d78afa87ae1ed95653ed34",
    "artist_name": "Ed Sheeran",
    "image_url": "https://i.scdn.co/image/46cf3a720f007177b73e17ec2be18490a0229de4",
    "artist_id": "6eUKZXaKkcviH0Ku9w2n3V",
    "year": 2012
  },
  {
    "song_id": "4wTMBYRE6xVTIUQ6fEudsJ",
    "name": "Take Care",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/5a6d9243023af2830698f58174dd807c6051dee9",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/8b2febcc39b4fa12f7d2e90a7eda20228158057c",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2012
  },
  {
    "song_id": "3b7CDTKB0SRTmQ6ytYi5vZ",
    "name": "Drunk On You",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/0affe67618de8d3a892895a0bba0c99145b3568d",
    "artist_name": "Luke Bryan",
    "image_url": "https://i.scdn.co/image/09f82f6d6fd88861f2ccf4a8754efe52c8a90aa7",
    "artist_id": "0BvkDsjIUla7X0k6CSWh1I",
    "year": 2012
  },
  {
    "song_id": "62zFEHfAYl5kdHYOivj4BC",
    "name": "International Love",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/a18306c3c41bf080662c5e92d6a4ab6e7a97ecf8",
    "artist_name": "Pitbull",
    "image_url": "https://i.scdn.co/image/1dcac421f3ddb49cb12a007929bbc4733d5c3b96",
    "artist_id": "0TnOYISbd1XYRBk9myaseg",
    "year": 2012
  },
  {
    "song_id": "6cNajGUxKP53LIg0LzlfQt",
    "name": "Climax",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/d3a51fedb8d84401ca538971c9509a7a924ccac3",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/9d9eba7aaf3ad667a6c2e934784a20932946640d",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2012
  },
  {
    "song_id": "49ySwzAyvxcNXOkOP6ZB1L",
    "name": "The Motto",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/e6179eecf0b6e346371f62355deaeb475756505d",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/8b2febcc39b4fa12f7d2e90a7eda20228158057c",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2012
  },
  {
    "song_id": "4qikXelSRKvoCqFcHLB2H2",
    "name": "Mercy",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/0dcc6521130556a3978ffcf1d5581a80ae4fee0a",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/c9c66e6b6357c2462783781db3843a62f57ee9ae",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2012
  },
  {
    "song_id": "05ZACkzW8YbRq3eFgIfSNB",
    "name": "Springsteen",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/1dc93d1ed81f2408127d9bea6036702b88fd8a13",
    "artist_name": "Eric Church",
    "image_url": "https://i.scdn.co/image/927f51f5c33a7ae031d09c8ea024c522ea8fae55",
    "artist_id": "2IvkS5MXK0vPGnwyJsrEyV",
    "year": 2012
  },
  {
    "song_id": "5sAlmL7Qp9N9BJTADkwEt9",
    "name": "Scream",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/7e8980437c11363094d1f68d8eeb8fdc994aab43",
    "artist_name": "Usher",
    "image_url": "https://i.scdn.co/image/9d9eba7aaf3ad667a6c2e934784a20932946640d",
    "artist_id": "23zg3TcAtWQy7J6upgbUnj",
    "year": 2012
  },
  {
    "song_id": "3CKCZ9pfwAfoMZlMncA1Nc",
    "name": "Set Fire to the Rain",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/6b7f04c5e017ec0b65373a2062b8bcd8edf69a14",
    "artist_name": "Adele",
    "image_url": "https://i.scdn.co/image/8fa409e337ce4d3056bb957bc1d0586ba99629b9",
    "artist_id": "4dpARuHxo51G3z768sgnrY",
    "year": 2012
  },
  {
    "song_id": "7LBohG6plAhWaHjuzi4CpY",
    "name": "Rack City",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/896a88cbd2d713b470458a7588141904084fb0f3",
    "artist_name": "Tyga",
    "image_url": "https://i.scdn.co/image/1a3784687c969b966374228688301bc37400b71d",
    "artist_id": "5LHRHt1k9lMyONurDHEdrp",
    "year": 2012
  },
  {
    "song_id": "6j7hih15xG2cdYwIJnQXsq",
    "name": "Not Over You",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/ceeaefb9c20f2e38787f5ca645896ee7bd776c61",
    "artist_name": "Gavin DeGraw",
    "image_url": "https://i.scdn.co/image/8ff85a2a668cdb3d637240d0820ddeb5c0ccaf9d",
    "artist_id": "5DYAABs8rkY9VhwtENoQCz",
    "year": 2012
  },
  {
    "song_id": "0vFMQi8ZnOM2y8cuReZTZ2",
    "name": "Blown Away",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/4856045ce22d11ee3ccd105c9dfcb986d2260889",
    "artist_name": "Carrie Underwood",
    "image_url": "https://i.scdn.co/image/97dc3cbb3da7dfdff8462d8bdb4ddf9d5a7cd04a",
    "artist_id": "4xFUf1FHVy696Q1JQZMTRj",
    "year": 2012
  },
  {
    "song_id": "6rbeWjEavBHvX2kr6lSogS",
    "name": "Work Out",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/08ca83350dbae11b8df5b6b101dec7743cff57cd",
    "artist_name": "J. Cole",
    "image_url": "https://i.scdn.co/image/83adb988728b73940336ed9bdd52153200cb8bb4",
    "artist_id": "6l3HvQ5sa6mXTsMTB19rO5",
    "year": 2012
  },
  {
    "song_id": "4VySpxhRGy32u5zPCprzDn",
    "name": "Too Close",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/aefe19b4604d3f370fe269db8860fc248fa6f9e2",
    "artist_name": "Alex Clare",
    "image_url": "https://i.scdn.co/image/7a363ee5414a746a7b705636770dd7461ebb5d59",
    "artist_id": "5Tf4EH8tDvznnjULcFxkIl",
    "year": 2012
  },
  {
    "song_id": "3trS6e40JCVUOpPVt5OdHj",
    "name": "Adorn",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/f9cd66d2f8d79b81845c61b6d70bc32d4a1e1643",
    "artist_name": "Miguel",
    "image_url": "https://i.scdn.co/image/9969e2916ab3e6a29d2eb9c49836031109e59e16",
    "artist_id": "360IAlyVv4PCEVjgyMZrxK",
    "year": 2012
  },
  {
    "song_id": "1QUpqu8865jfasDr8M3IKN",
    "name": "A Thousand Years",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/b739ba5402699dcefd63b3edd3da01bdbaac9e58",
    "artist_name": "Christina Perri",
    "image_url": "https://i.scdn.co/image/494cb6a23a5199759015c99a03eaf423a785ec0e",
    "artist_id": "7H55rcKCfwqkyDFH9wpKM6",
    "year": 2012
  },
  {
    "song_id": "0cV4xwUA4ue2deqq4CZFko",
    "name": "I Don't Want This Night to End",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/54ccd306163d343d408d6ff967a7925b27dea483",
    "artist_name": "Luke Bryan",
    "image_url": "https://i.scdn.co/image/09f82f6d6fd88861f2ccf4a8754efe52c8a90aa7",
    "artist_id": "0BvkDsjIUla7X0k6CSWh1I",
    "year": 2012
  },
  {
    "song_id": "3zrYNl1aMdFrQkcOjKVr5u",
    "name": "I Cry",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/40ded851548938f56e54f670226c68b7700fe815",
    "artist_name": "Flo Rida",
    "image_url": "https://i.scdn.co/image/e76cb354bed1e20877e52c912f47d4cd44ddf1a3",
    "artist_id": "0jnsk9HBra6NMjO2oANoPY",
    "year": 2012
  },
  {
    "song_id": "3tyPOhuVnt5zd5kGfxbCyL",
    "name": "Where Have You Been",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/30c35d1441903e6918d9cc7862691ca747912eb2",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/776649c75b9d6f54e4fbbf92c66db5021a0f1cfe",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2012
  },
  {
    "song_id": "6D60klaHqbCl9ySc8VcRss",
    "name": "Stronger (What Doesn't Kill You)",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/488449f105f55ecc781bde74d7869fd1f02b682c",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/fc4b62fe2b08789f4dffa4dfe57ccdb69c1ac299",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2012
  },
  {
    "song_id": "7gUpO6td4OOnu0Lf9vhcIV",
    "name": "Lights - Single Version",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/be5732467b8504daa714f827f3e8587ba3f08868",
    "artist_name": "Ellie Goulding",
    "image_url": "https://i.scdn.co/image/e5f1d1701dd7ab929b0aacf5b56814e9c95457e2",
    "artist_id": "0X2BH1fck6amBIoJhDVmmJ",
    "year": 2012
  },
  {
    "song_id": "20DfkHC5grnKNJCzZQB6KC",
    "name": "Good Girl",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/600f59b81044d8f4299e827abff67426c66191bb",
    "artist_name": "Carrie Underwood",
    "image_url": "https://i.scdn.co/image/97dc3cbb3da7dfdff8462d8bdb4ddf9d5a7cd04a",
    "artist_id": "4xFUf1FHVy696Q1JQZMTRj",
    "year": 2012
  },
  {
    "song_id": "76N7FdzCI9OsiUnzJVLY2m",
    "name": "Rumour Has It",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/b2160a56d1791fdbf394062afe806ac546543ba1",
    "artist_name": "Adele",
    "image_url": "https://i.scdn.co/image/8fa409e337ce4d3056bb957bc1d0586ba99629b9",
    "artist_id": "4dpARuHxo51G3z768sgnrY",
    "year": 2012
  },
  {
    "song_id": "3m8CQnnfJJp4eQMWWl3zay",
    "name": "Drank In My Cup",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/94b5b58db2ae27e64a40deb05b567ea0abbb611a",
    "artist_name": "Kirko Bangz",
    "image_url": "https://i.scdn.co/image/5c18ce1cd7e64fb3d17af1d3ccc8b29381da05b5",
    "artist_id": "2r8r62VGJKGi463aH1HJUZ",
    "year": 2012
  },
  {
    "song_id": "0Nu9WA8kEbBWEsay2s8Q0U",
    "name": "Even If It Breaks Your Heart",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/56daaaf0e6f9eb6b8ab3b66ad9eacc5e493a7c34",
    "artist_name": "Eli Young Band",
    "image_url": "https://i.scdn.co/image/cdf2ccfdb8221c36041004a953a9b1c9cacad86f",
    "artist_id": "6eKWqnckwdIlSnjaYgIyxv",
    "year": 2012
  },
  {
    "song_id": "6BCrbWBpb8d6KWmEqZ41tr",
    "name": "Come Over",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/cb2d1aed3211b68d5f4079ed50a8fc51ca3779dd",
    "artist_name": "Kenny Chesney",
    "image_url": "https://i.scdn.co/image/0cce36dccb7de4c749af543db1172653989d313e",
    "artist_id": "3grHWM9bx2E9vwJCdlRv9O",
    "year": 2012
  },
  {
    "song_id": "03UrZgTINDqvnUMbbIMhql",
    "name": "Gangnam Style (강남스타일)",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/2dfce9d202db4707811d712f63e507502d99205f",
    "artist_name": "PSY",
    "image_url": "https://i.scdn.co/image/5a6149a92c512e0ac8b478a03c373dc14e3402b1",
    "artist_id": "2dd5mrQZvg6SmahdgVKDzh",
    "year": 2012
  },
  {
    "song_id": "3sP3c86WFjOzHHnbhhZcLA",
    "name": "Give Your Heart A Break",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/df3391bd4b21c6d5072844740f66fff75eae485c",
    "artist_name": "Demi Lovato",
    "image_url": "https://i.scdn.co/image/2f8b43bd63f73b34e33e572a112b61830de0570a",
    "artist_id": "6S2OmqARrzebs0tKUEyXyp",
    "year": 2012
  },
  {
    "song_id": "6Ymvlzom4TQeoKqAWsZRD8",
    "name": "Somethin' 'Bout A Truck",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/09e814b64e743136a2f136dcf0dc33a585f1e8a3",
    "artist_name": "Kip Moore",
    "image_url": "https://i.scdn.co/image/a914d4a8d1e5ffc5792d95a00974789e33c5ead4",
    "artist_id": "2hJPr4lk7Q8SSvCVBl9fWM",
    "year": 2012
  },
  {
    "song_id": "0pwYLVXVknPSGUQb39cePC",
    "name": "As Long As You Love Me",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/810222a31b9945b722ed672c9b3dfefef334d96e",
    "artist_name": "Justin Bieber",
    "image_url": "https://i.scdn.co/image/aff148fa356c0b1f792572b52904674292305a63",
    "artist_id": "1uNFoZAHBGtllmzznpCI3s",
    "year": 2012
  },
  {
    "song_id": "4urcG6Nfubqsuqy3juMjBi",
    "name": "Brokenhearted",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/865a47d89fcf5b1ba5990c4365d4969381609ba7",
    "artist_name": "Karmin",
    "image_url": "https://i.scdn.co/image/32d645ad32106c7f08eb515b46010107ef443567",
    "artist_id": "4M0DLz8te9Q1lNIXBBwvfG",
    "year": 2012
  },
  {
    "song_id": "6MAdEUilV2p9RQUqE5bMAK",
    "name": "Domino",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/041fa1fb48f29f64a764f42fe5d2b1b4c1e864e0",
    "artist_name": "Jessie J",
    "image_url": "https://i.scdn.co/image/5ce1189ea9edf1b9584cbf2329279bc3b4a673c6",
    "artist_id": "2gsggkzM5R49q6jpPvazou",
    "year": 2012
  },
  {
    "song_id": "3bfqkspKABT4pPicm6wC9F",
    "name": "Ass Back Home (feat. Neon Hitch)",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/66b1e7721d564b5bd76bea803d59298fc9c6dc28",
    "artist_name": "Gym Class Heroes",
    "image_url": "https://i.scdn.co/image/4ab35c5ef0e95de0c90953a850176e536475d53d",
    "artist_id": "4IJczjB0fJ04gs4uvP0Fli",
    "year": 2012
  },
  {
    "song_id": "4TyY4vah59nsckspWdSGPn",
    "name": "No Lie",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/e69b16e7ca1cc75026dead6a1ab5854ba2cdbf19",
    "artist_name": "2 Chainz",
    "image_url": "https://i.scdn.co/image/7396a944c2106e29eadab7e16754397803a66e79",
    "artist_id": "17lzZA2AlOHwCwFALHttmp",
    "year": 2012
  },
  {
    "song_id": "2NniAhAtkRACaMeYt48xlD",
    "name": "50 Ways to Say Goodbye",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/e27b3199f0c14f56e9c5872f83b786a7e1001ad4",
    "artist_name": "Train",
    "image_url": "https://i.scdn.co/image/88b394c9b01d55ae1759e1c7d708d4c437bb9c9f",
    "artist_id": "3FUY2gzHeIiaesXtOAdB7A",
    "year": 2012
  },
  {
    "song_id": "0BgRaFy7qnwT1PuUmSTFkf",
    "name": "Stereo Hearts - feat. Adam Levine",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/fd12dc3da666f49441a79c2370ca6de8868a929e",
    "artist_name": "Gym Class Heroes",
    "image_url": "https://i.scdn.co/image/dc375bcb76bfb3c1aa3f42423dfc3111a4d9d6e2",
    "artist_id": "4IJczjB0fJ04gs4uvP0Fli",
    "year": 2012
  },
  {
    "song_id": "1POAx4NMLOBPVKZUSsBh92",
    "name": "Cashin' Out",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/334edd7ff64dc9a15faa870ad48a7954a7faae93",
    "artist_name": "Ca$h Out",
    "image_url": "https://i.scdn.co/image/25496ca961cf3b9d33a28385a73c6ce5ed72f94e",
    "artist_id": "7Kp7SzuRuNiPFCy7JIwnLx",
    "year": 2012
  },
  {
    "song_id": "4hIqZEgvWw9Pn3kucNtq2T",
    "name": "Over You",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/00008099f536fd7934e783387fbf525328d71e36",
    "artist_name": "Miranda Lambert",
    "image_url": "https://i.scdn.co/image/2c82e3c5199a56ab4e4c5071d3feb98db047d5ae",
    "artist_id": "66lH4jAE7pqPlOlzUKbwA0",
    "year": 2012
  },
  {
    "song_id": "47nm8czanMUzIqHsnr5x61",
    "name": "Red Solo Cup",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/9900e9f89d35fb38cac62e25504ae1c1b5aa8409",
    "artist_name": "Toby Keith",
    "image_url": "https://i.scdn.co/image/551002e79f4b70a7aa28dcb2c8ace32f788dd90c",
    "artist_id": "2bA6fzP0lMAQ4kz6CF61w8",
    "year": 2012
  },
  {
    "song_id": "6LS6pltO7YBgjwNVhxMwtp",
    "name": "Blow Me (One Last Kiss)",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/9a73780633c6d1c2b9f85928dc5744780ad2ad1a",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/380fd3773e7f1bef6556371223f6a0e6e619067e",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2012
  },
  {
    "song_id": "0iyEaciAmtiv8xMkBg97Fy",
    "name": "Payphone",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/4adb9a5f0a7b5c8e728cfda3a2b933d4b4626137",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/649079fba8a413bde5fc946dee0e10b59f4e76b5",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2012
  },
  {
    "song_id": "0laYHRpNTS6i8FXdupHkJ4",
    "name": "Love You Like A Love Song",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/13a279158c64b855f3c61121c7d624a0fe389fe9",
    "artist_name": "Selena Gomez & The Scene",
    "image_url": "https://i.scdn.co/image/639689b02c70444023f008c24911d4355491ec4f",
    "artist_id": "6dJeKm76NjfXBNTpHmOhfO",
    "year": 2012
  },
  {
    "song_id": "3TGRqZ0a2l1LRblBkJoaDx",
    "name": "Call Me Maybe",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/d870cf797c2de0363b095a28d7a35245fc577a49",
    "artist_name": "Carly Rae Jepsen",
    "image_url": "https://i.scdn.co/image/b85ca26487198159970adf804ef4bbdc8078b1ef",
    "artist_id": "6sFIWsNpZYqfjUpaCgueju",
    "year": 2012
  },
  {
    "song_id": "0obBFrPYkSoBJbvHfUIhkv",
    "name": "Sexy And I Know It",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/0de3f56eb493ca153059e7c6facb2a9b8e3b59fd",
    "artist_name": "LMFAO",
    "image_url": "https://i.scdn.co/image/c2eb976acf7f5600efd9eb062c2ca8db234ca868",
    "artist_id": "3sgFRtyBnxXD5ESfmbK4dl",
    "year": 2012
  },
  {
    "song_id": "70dWrqAp30TmWeibQkn0i7",
    "name": "Party Rock Anthem",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/6d7357b27bf0a6d72c1316df7f54ab1924892221",
    "artist_name": "LMFAO",
    "image_url": "https://i.scdn.co/image/c1463622e2b46e3fdcffa877b6a9d5e1bf606bc6",
    "artist_id": "3sgFRtyBnxXD5ESfmbK4dl",
    "year": 2012
  },
  {
    "song_id": "62csqLJbdvAKb38rYijV1R",
    "name": "Work Hard, Play Hard",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/05452cbdeb20183b7311d9d3257047f597d3038e",
    "artist_name": "Wiz Khalifa",
    "image_url": "https://i.scdn.co/image/3d3d1fbd81bb4fceac8bb72b135b1ef899965aad",
    "artist_id": "137W8MRPWKqSmrBGDBFSop",
    "year": 2012
  },
  {
    "song_id": "3icobpUUcKEbt2zQUqRpvM",
    "name": "One More Night",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/294e69ed84623689b7f00c30be740a05cf43b9c9",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/8a7896c605656e3c9498e20cc4fa65534bd4b663",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2012
  },
  {
    "song_id": "2U2ONBrf1HJCDxQlynpD6J",
    "name": "Back in Time - featured in \"Men In Black 3\"",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/0e9b079f49252a244c003f371d5b54b9938ef22f",
    "artist_name": "Pitbull",
    "image_url": "https://i.scdn.co/image/41d70dddebca436c87bf8472a9c41ae6ba80f329",
    "artist_id": "0TnOYISbd1XYRBk9myaseg",
    "year": 2012
  },
  {
    "song_id": "0KAiuUOrLTIkzkpfpn9jb9",
    "name": "Drive By",
    "popularity": 61,
    "preview_url": "https://p.scdn.co/mp3-preview/1481b4a14cd3543232a48c3547667f8bf55e4632",
    "artist_name": "Train",
    "image_url": "https://i.scdn.co/image/88b394c9b01d55ae1759e1c7d708d4c437bb9c9f",
    "artist_id": "3FUY2gzHeIiaesXtOAdB7A",
    "year": 2012
  },
  {
    "song_id": "5xPazRvyrkVootu8pM9vUG",
    "name": "Rolling in the Deep",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/f5c88f8b413e4a4b642dbbfca425715dc2ced256",
    "artist_name": "Adele",
    "image_url": "https://i.scdn.co/image/ae0368a7a6f0521d98f0269599e7703b905dcecf",
    "artist_id": "4dpARuHxo51G3z768sgnrY",
    "year": 2012
  },
  {
    "song_id": "0mtPNQl5vePVwiI9Vykp7L",
    "name": "We Run The Night",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/6166e6e350f04209c745412ef945b1e752853f1c",
    "artist_name": "Havana Brown",
    "image_url": "https://i.scdn.co/image/2cf95c2e9ec4d23f8d8ea26dcae2d1ca275f25cb",
    "artist_id": "1EVWYRr2obCRDoSoD6KSuM",
    "year": 2012
  },
  {
    "song_id": "65YsalQBmQCzIPaay72CzQ",
    "name": "Diamonds",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/cbc653e9d8e2bb432e5f5d03ede2f327f3f86eed",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/4b880129277465f9c92913a757f10fe033a9c4b0",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2012
  },
  {
    "song_id": "7eW9cHOAHmYqphs5tWXETw",
    "name": "Clique",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/43a1d5c12ef089643324e873139eaa4ae73d05f8",
    "artist_name": "Kanye West",
    "image_url": "https://i.scdn.co/image/63a433bd88cbcbd0535e39021c0888d918651906",
    "artist_id": "5K4W6rqBFWDnAN6FQUkS6x",
    "year": 2012
  },
  {
    "song_id": "4dMfcca0nQNXXj1etzeME4",
    "name": "Dance (A$$)",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/64b35d6cd020db5a9176576becace1871cb5cba0",
    "artist_name": "Big Sean",
    "image_url": "https://i.scdn.co/image/ec8f546a9498d8a209a45bdd90774b10591b4873",
    "artist_id": "0c173mlxpT3dSFRgMO8XPh",
    "year": 2012
  },
  {
    "song_id": "1LbhI0PgXF5kyJEE88xoUU",
    "name": "Starships",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/c0731aeb8b90b9c10ea5f5ec6744f14a39d4b053",
    "artist_name": "Nicki Minaj",
    "image_url": "https://i.scdn.co/image/155f655e37d8c61318eda604a30201dceda7fb32",
    "artist_id": "0hCNtLu0JehylgoiP8L4Gh",
    "year": 2012
  },
  {
    "song_id": "6hkOqJ5mE093AQf2lbZnsG",
    "name": "The One That Got Away",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/7aebfd2f3254af6d28214ef84761ae2360847ca3",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/ce8f207440681b989f02c20129b0b627036fd71c",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2012
  },
  {
    "song_id": "2Rea6PxenESVsJfEaGBGsD",
    "name": "5 O'Clock",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/30a6a03ff4a008efe7908b06f2cd5a799105d6ab",
    "artist_name": "T-Pain",
    "image_url": "https://i.scdn.co/image/2ebb9abecba24aed66ae80a3a8d0d7718719ae38",
    "artist_id": "3aQeKQSyrW4qWr35idm0cy",
    "year": 2012
  },
  {
    "song_id": "2L7rZWg9RLxIwnysmxm4xk",
    "name": "Boyfriend",
    "popularity": 54,
    "preview_url": "https://p.scdn.co/mp3-preview/b8326575c5ca1fcc94c9975feb78cd4f0d74766f",
    "artist_name": "Justin Bieber",
    "image_url": "https://i.scdn.co/image/aff148fa356c0b1f792572b52904674292305a63",
    "artist_id": "1uNFoZAHBGtllmzznpCI3s",
    "year": 2012
  },
  {
    "song_id": "2LNbLA2JSQlv6NRz1xdZRj",
    "name": "Moves Like Jagger - Studio Recording From The Voice Performance",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/c6f445879bd2bef49b8f565698df678bb296fc4c",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/9fdc2291845d5b4970e001dc3da87ffce7e4f255",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2012
  },
  {
    "song_id": "3FjPRoxtACgDhIK3u60CsP",
    "name": "So Good",
    "popularity": 48,
    "preview_url": "https://p.scdn.co/mp3-preview/e0b2a72de5f124435b01e57de936c80d857a5900",
    "artist_name": "B.o.B",
    "image_url": "https://i.scdn.co/image/855273495589988657cc5782d4acab6b77ea7d95",
    "artist_id": "5ndkK3dpZLKtBklKjxNQwT",
    "year": 2012
  },
  {
    "song_id": "3t4HeVfURZ5DuqMWz0Cmqg",
    "name": "We Found Love",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/02539e22523852f5073b1d746358b07adc8916e2",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/1ed07c2ace7df8b3f501b592ed4949e5a47216b9",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2012
  },
  {
    "song_id": "2tSFTvHVaLxkqXHaofPKQk",
    "name": "You Da One",
    "popularity": 47,
    "preview_url": "https://p.scdn.co/mp3-preview/80bcf376c98d6c5f674e12293834993e05bde374",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/1ed07c2ace7df8b3f501b592ed4949e5a47216b9",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2012
  },
  {
    "song_id": "0uZ0orE84drZZzPKxCWSmR",
    "name": "Little Talks",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/a7a26fde085771c2ac4f99fcf2a7669073c05b4c",
    "artist_name": "Of Monsters And Men",
    "image_url": "https://i.scdn.co/image/93f57259582e67a22c0cddbe0bfb0ae9fa81eb29",
    "artist_id": "4dwdTW1Lfiq0cM8nBAqIIz",
    "year": 2012
  },
  {
    "song_id": "53A20GH734aXpOF4cNakr4",
    "name": "Pop That",
    "popularity": 27,
    "preview_url": "https://p.scdn.co/mp3-preview/695d6f5bf89b286fd877be61e8d6fbb34048600e",
    "artist_name": "French Montana",
    "image_url": "https://i.scdn.co/image/a18a141dae1194bc6be3f008ed6d03e018efb47f",
    "artist_id": "6vXTefBL93Dj5IqAWq6OTv",
    "year": 2012
  },
  {
    "song_id": "47Z5890IcjSed81ldeLgqc",
    "name": "Titanium (feat. Sia)",
    "popularity": 22,
    "preview_url": "https://p.scdn.co/mp3-preview/9facced7d93d9763c8e60030ca5134b52f1b8111",
    "artist_name": "David Guetta",
    "image_url": "https://i.scdn.co/image/a2871d0a5a04c3fe96812d00bbec5b06b409cdc9",
    "artist_id": "1Cs0zKBU1kc0i8ypK3B9ai",
    "year": 2012
  },
  {
    "song_id": "4x4RbsTuuqrmRoDFMyrhnA",
    "name": "Turn Up the Music",
    "popularity": 16,
    "preview_url": "https://p.scdn.co/mp3-preview/e21f6fae7b6aa85518703593dd12549012b37225",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/42abc8e3d7590fefe4dc2cac0ca85c3b06469f19",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2012
  },
  {
    "song_id": "1T07kafGtJbDvosN0nu4Q2",
    "name": "What Makes You Beautiful",
    "popularity": 13,
    "preview_url": "https://p.scdn.co/mp3-preview/5e7d4ed2f1b7ad6b6e5c73f68f3860c96cae0d27",
    "artist_name": "One Direction",
    "image_url": "https://i.scdn.co/image/19c915645067397b403e3a628e1891655b453a95",
    "artist_id": "4AK6F7OLvEQ5QYCBNiQWHq",
    "year": 2012
  },
  {
    "song_id": "226rLMKyxPvLzEAP1oUMXj",
    "name": "Pound The Alarm",
    "popularity": 11,
    "preview_url": "https://p.scdn.co/mp3-preview/67ec6b7bb0d7cfc9bba4a5183ea05b2d42cefa68",
    "artist_name": "Nicki Minaj",
    "image_url": "https://i.scdn.co/image/4d2639c111b6316fd97a1a946bcb02b0585688a0",
    "artist_id": "0hCNtLu0JehylgoiP8L4Gh",
    "year": 2012
  },
  {
    "song_id": "3bidbhpOYeV4knp8AIu8Xn",
    "name": "Can't Hold Us - feat. Ray Dalton",
    "popularity": 88,
    "preview_url": "https://p.scdn.co/mp3-preview/8a692b37e7a4dbee9967d156c5fa5c22bbaae719",
    "artist_name": "Macklemore & Ryan Lewis",
    "image_url": "https://i.scdn.co/image/17bf689d7edfb141a404da1899890075f11ccad2",
    "artist_id": "5BcAKTbp20cv7tC5VqPFoC",
    "year": 2013
  },
  {
    "song_id": "0nJW01T7XtvILxQgC5J7Wh",
    "name": "When I Was Your Man",
    "popularity": 84,
    "preview_url": "https://p.scdn.co/mp3-preview/a1cbeac22a19ef6e74b9b0bc3c893a4f9d1d3185",
    "artist_name": "Bruno Mars",
    "image_url": "https://i.scdn.co/image/a878cea3109affaf05b10c39bc19b8def042f127",
    "artist_id": "0du5cEVh5yTK9QJze8zA0C",
    "year": 2013
  },
  {
    "song_id": "2Foc5Q5nqNiosCNqttzHof",
    "name": "Get Lucky - Radio Edit",
    "popularity": 84,
    "preview_url": "https://p.scdn.co/mp3-preview/0c298ced47b2282e9d6cdd75623cae74ebda72a8",
    "artist_name": "Daft Punk",
    "image_url": "https://i.scdn.co/image/7c6ff75c4d254da552e6337cba4908c5fbe1a588",
    "artist_id": "4tZwfgrHOc3mvqYlEYSvVi",
    "year": 2013
  },
  {
    "song_id": "05SBRd4fXgn8FX7bf8BCAE",
    "name": "I Need Your Love",
    "popularity": 84,
    "preview_url": "https://p.scdn.co/mp3-preview/c062cd8e7ef3be6374f41dd1bf0d6410a0606376",
    "artist_name": "Calvin Harris",
    "image_url": "https://i.scdn.co/image/ae9bbe8085200b8a722592550abb439d79a0e062",
    "artist_id": "7CajNmpbOovFoOoasH2HaY",
    "year": 2013
  },
  {
    "song_id": "1CmUZGtH29Kx36C1Hleqlz",
    "name": "Thrift Shop - feat. Wanz",
    "popularity": 83,
    "preview_url": "https://p.scdn.co/mp3-preview/e97380a70f148b7d55f58d9f99884f9dc0588c64",
    "artist_name": "Macklemore & Ryan Lewis",
    "image_url": "https://i.scdn.co/image/48c7952ccda706097e688db08164cd8e43e6e72f",
    "artist_id": "5BcAKTbp20cv7tC5VqPFoC",
    "year": 2013
  },
  {
    "song_id": "7ueP5u2qkdZbIPN2YA6LR0",
    "name": "Sail",
    "popularity": 82,
    "preview_url": "https://p.scdn.co/mp3-preview/b126f31916ccf6d035d078a9a0e9cec27ec4078c",
    "artist_name": "AWOLNATION",
    "image_url": "https://i.scdn.co/image/301ddf22f750f972d9a3cf30f8bcba152b0da055",
    "artist_id": "4njdEjTnLfcGImKZu1iSrz",
    "year": 2013
  },
  {
    "song_id": "55h7vJchibLdUkxdlX3fK7",
    "name": "Treasure",
    "popularity": 82,
    "preview_url": "https://p.scdn.co/mp3-preview/4e9ff67343c7748fd49dcba1fbc649a5a3a79cd9",
    "artist_name": "Bruno Mars",
    "image_url": "https://i.scdn.co/image/a878cea3109affaf05b10c39bc19b8def042f127",
    "artist_id": "0du5cEVh5yTK9QJze8zA0C",
    "year": 2013
  },
  {
    "song_id": "24LS4lQShWyixJ0ZrJXfJ5",
    "name": "Sweet Nothing",
    "popularity": 81,
    "preview_url": "https://p.scdn.co/mp3-preview/325a385ad415e11614c581e9c7644ea10fe29b1c",
    "artist_name": "Calvin Harris",
    "image_url": "https://i.scdn.co/image/ae9bbe8085200b8a722592550abb439d79a0e062",
    "artist_id": "7CajNmpbOovFoOoasH2HaY",
    "year": 2013
  },
  {
    "song_id": "4pGqFOfzvfe6avb9kbZicC",
    "name": "Sweater Weather",
    "popularity": 81,
    "preview_url": "https://p.scdn.co/mp3-preview/b274e6dcee4fbc238f3ca09fde3a70511a54389d",
    "artist_name": "The Neighbourhood",
    "image_url": "https://i.scdn.co/image/a51e57dc564aa7c69351f58265aa347d8310174d",
    "artist_id": "77SW9BnxLY8rJ0RciFqkHh",
    "year": 2013
  },
  {
    "song_id": "6GmUVqe73u5YRfUUynZK6I",
    "name": "Let Her Go",
    "popularity": 80,
    "preview_url": "https://p.scdn.co/mp3-preview/e5244b066d49bafea1f95e2fd0d9ba3c04557fd4",
    "artist_name": "Passenger",
    "image_url": "https://i.scdn.co/image/36cb18c0357f3f5b40783272db386736b7bd0bf7",
    "artist_id": "0gadJ2b9A4SKsB1RFkBb66",
    "year": 2013
  },
  {
    "song_id": "6oDPg7fXW3Ug3KmbafrXzA",
    "name": "Wrecking Ball",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/9aac28c0309c2203ba36d50bd7085c113109e4da",
    "artist_name": "Miley Cyrus",
    "image_url": "https://i.scdn.co/image/40a96e848394e93b32bd84ded8e66a6ceab8be1d",
    "artist_id": "5YGY8feqx7naU7z4HrwZM6",
    "year": 2013
  },
  {
    "song_id": "6qOEjO2IUD7PjtpsXawq0d",
    "name": "Girl on Fire",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/be98c86a5593a263de95d8318ab488630f79c0cc",
    "artist_name": "Alicia Keys",
    "image_url": "https://i.scdn.co/image/2b19defeeeeaf47b3921f8cdc354a10778755283",
    "artist_id": "3DiDSECUqqY1AuBP8qtaIa",
    "year": 2013
  },
  {
    "song_id": "01uqI4H13Gsd8Lyl1EYd8H",
    "name": "Same Love - feat. Mary Lambert",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/ab513536dc1d2bb3043d4a625aa3177cc307d980",
    "artist_name": "Macklemore & Ryan Lewis",
    "image_url": "https://i.scdn.co/image/17bf689d7edfb141a404da1899890075f11ccad2",
    "artist_id": "5BcAKTbp20cv7tC5VqPFoC",
    "year": 2013
  },
  {
    "song_id": "6PUIzlqotEmPuBfjbwYWOB",
    "name": "Summertime Sadness [Lana Del Rey vs. Cedric Gervais] - Cedric Gervais Remix",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/b3411e9f548d551ef6154c605df8f8da970832a5",
    "artist_name": "Lana Del Rey",
    "image_url": "https://i.scdn.co/image/ddbcfc91ef3d62b42a6e50468b4964c28dfc8355",
    "artist_id": "00FQb4jTyendYWaN8pK0wa",
    "year": 2013
  },
  {
    "song_id": "1yjY7rpaAQvKwpdUliHx0d",
    "name": "Still Into You",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/3f4e624f8643cf6ae68c1a6b99eb2d76b18be380",
    "artist_name": "Paramore",
    "image_url": "https://i.scdn.co/image/6c41a31021b9ce126768d6ed51bb76bf0673c4b2",
    "artist_id": "74XFHRwlV6OrjEM0A2NCMF",
    "year": 2013
  },
  {
    "song_id": "37jTPJgwCCmIGMPB45jrPV",
    "name": "Holy Grail",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/ceab06962417dc97e1e63ed6dc073288b00eb5d9",
    "artist_name": "JAY Z",
    "image_url": "https://i.scdn.co/image/46760042d9b1d6bf066ec2c6359a17d2c6c74f9e",
    "artist_id": "3nFkdlSjzX9mRTtwJOzDYB",
    "year": 2013
  },
  {
    "song_id": "3LlAyCYU26dvFZBDUIMb7a",
    "name": "Demons",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/558801add13f0fff754260ed5fac6c77ec8b7fb2",
    "artist_name": "Imagine Dragons",
    "image_url": "https://i.scdn.co/image/bb9a2da55ab31e91dbc47970e419195761a99caa",
    "artist_id": "53XhwfbYqKCa1cC15pYq2q",
    "year": 2013
  },
  {
    "song_id": "5HGibWoxnkYSkl6mHmAlOE",
    "name": "That's My Kind Of Night",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/94445aa0c5429dc2cb3ce475557d9069427b2aa9",
    "artist_name": "Luke Bryan",
    "image_url": "https://i.scdn.co/image/ca93252350b8819ac51252783e1431af9dabd59f",
    "artist_id": "0BvkDsjIUla7X0k6CSWh1I",
    "year": 2013
  },
  {
    "song_id": "5xGIqIiGr1do6RRpIviwGG",
    "name": "Love More",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/a92530c6c08319ea3b01e5d59c78d2ed122019c6",
    "artist_name": "Chris Brown",
    "image_url": "https://i.scdn.co/image/07821f00928fd6872cdf467782cd76175ef7b383",
    "artist_id": "7bXgB6jMjp9ATFy66eO08Z",
    "year": 2013
  },
  {
    "song_id": "1EbWiEra7ovNsMq3DeLSDy",
    "name": "Power Trip",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/adca2867bc4e149addd627c78aeca7c49dbf7a4f",
    "artist_name": "J. Cole",
    "image_url": "https://i.scdn.co/image/ecc81f8483c80bb309c44fac862b0bc9fda0b1c6",
    "artist_id": "6l3HvQ5sa6mXTsMTB19rO5",
    "year": 2013
  },
  {
    "song_id": "60wwxj6Dd9NJlirf84wr2c",
    "name": "Clarity",
    "popularity": 74,
    "preview_url": "https://p.scdn.co/mp3-preview/be9f229556ccf9aff62cd2a30e0827f166c69f3b",
    "artist_name": "Zedd",
    "image_url": "https://i.scdn.co/image/067a5d9e1f6a538e5af35d972dc4e815b6a9a981",
    "artist_id": "2qxJFvFYMEDqd7ui6kSAcq",
    "year": 2013
  },
  {
    "song_id": "4lWJjtWbDDALpK9tVG8Dho",
    "name": "Love Me",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/84f3fae07f014c10d40d0e27c4e5a8fff54e09bb",
    "artist_name": "Lil Wayne",
    "image_url": "https://i.scdn.co/image/7cee1234c29a8a1e3cc50e8bbaa6621168ac11a5",
    "artist_id": "55Aa2cqylxrFIXC767Z865",
    "year": 2013
  },
  {
    "song_id": "39FwE8edwuyiaa4PrGBkP7",
    "name": "Boys 'Round Here (feat. Pistol Annies & Friends)",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/ce645e9f5c7df2df4e20209d195648e014937234",
    "artist_name": "Blake Shelton",
    "image_url": "https://i.scdn.co/image/2be2efecc3066e6667e0aa06f3eddae5fc6d6d3a",
    "artist_id": "1UTPBmNbXNTittyMJrNkvw",
    "year": 2013
  },
  {
    "song_id": "3XSczvk4MRteOw4Yx3lqMU",
    "name": "Roar",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/c692a8fbad5ebed29438007a11b0f37aa7422a2b",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/b0560ee4b0a7a4ed376de01c8bc44ff8d9633f5c",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2013
  },
  {
    "song_id": "6s9ICeczYOfbHHIaSMq9jd",
    "name": "Get Your Shine On",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/0781cd4c79de70a2f45490dc0cd720bf6c05c56b",
    "artist_name": "Florida Georgia Line",
    "image_url": "https://i.scdn.co/image/0c70fb80136b36a63011423fe4633a1bcfbafaa5",
    "artist_id": "3b8QkneNDz4JHKKKlLgYZg",
    "year": 2013
  },
  {
    "song_id": "3Eq7yD58dIXqOgw1j7NFhY",
    "name": "Rich As Fuck",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/04782a0facbdadb9d03bec04277f2d927fad6df9",
    "artist_name": "Lil Wayne",
    "image_url": "https://i.scdn.co/image/225501989cd66237648841aa467776bb7072b04d",
    "artist_id": "55Aa2cqylxrFIXC767Z865",
    "year": 2013
  },
  {
    "song_id": "5T6DM9qjjngWnukcw0svkX",
    "name": "Round Here",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/5af05083a5284ff7ef7a06452c927b5838611e71",
    "artist_name": "Florida Georgia Line",
    "image_url": "https://i.scdn.co/image/0c70fb80136b36a63011423fe4633a1bcfbafaa5",
    "artist_id": "3b8QkneNDz4JHKKKlLgYZg",
    "year": 2013
  },
  {
    "song_id": "6JG0qhINKVwiHxqN85j7RG",
    "name": "Crooked Smile",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/54900642776522fa12dd7207a5b017285e3bcb64",
    "artist_name": "J. Cole",
    "image_url": "https://i.scdn.co/image/e76153b6e249c331f2156361fcf7965bc6088643",
    "artist_id": "6l3HvQ5sa6mXTsMTB19rO5",
    "year": 2013
  },
  {
    "song_id": "03gqZZavMKhWbSjdQvpF6O",
    "name": "Mirrors",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/b8645ddfa2a348b67ab531d0a5d1cd0c4cf332e7",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/5e061e81bfc577aa0f0aa70f8de50932c3e12d3d",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2013
  },
  {
    "song_id": "2tC3NZsLgXr9CXvOKon19c",
    "name": "I Want Crazy - Encore",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/4a428811d7e4ed5b3eb7f4814c35582abd6a31a5",
    "artist_name": "Hunter Hayes",
    "image_url": "https://i.scdn.co/image/9aa55a9031402eab976c7c62c8b81fe41102163c",
    "artist_id": "7H6dkUChT5EoOQtUVMg4cN",
    "year": 2013
  },
  {
    "song_id": "4QRZirU4s1f30iDHzuKncj",
    "name": "Wake Me Up",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/50e50f8d9db542175107b62cf254d760279b3444",
    "artist_name": "Avicii",
    "image_url": "https://i.scdn.co/image/96f63ea8dddf9fab9c8d039fba79d7b21e586c69",
    "artist_id": "1vCWHaC5f2uS3yhpwWbIA6",
    "year": 2013
  },
  {
    "song_id": "2ZQyksYO4zzhyHNcueL0CP",
    "name": "Home",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/6336323fbd903a96e4d856f0fc181748e0326ad7",
    "artist_name": "Phillip Phillips",
    "image_url": "https://i.scdn.co/image/4a983e1620bd52520253f88ab84aba32ec43bb29",
    "artist_id": "6p5JxpTc7USNnBnLzctyd4",
    "year": 2013
  },
  {
    "song_id": "7gpy7sfWPNuOKmUNs3XQYE",
    "name": "Carry On",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/7e0945c9f9fef3e3a1f6319be0785dedb42e06f5",
    "artist_name": "Fun.",
    "image_url": "https://i.scdn.co/image/a04961a66c132aaf5628b8b90d6d73ca0ae73ad8",
    "artist_id": "5nCi3BB41mBaMH9gfr6Su0",
    "year": 2013
  },
  {
    "song_id": "7CL6oaDC9d0QXFMQRkNBmy",
    "name": "Runnin' Outta Moonlight",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/3621c1ba77a9fe491df75f17b4187b2fdaba6cc5",
    "artist_name": "Randy Houser",
    "image_url": "https://i.scdn.co/image/963c5b417a813293e459ed855e8c97936fb1b39b",
    "artist_id": "56x8mYvS3cyDGAi8N2FxbB",
    "year": 2013
  },
  {
    "song_id": "1S1u0ausWGi2msWnuQgTCY",
    "name": "It Goes Like This",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/7bb76a42ee65470db0763a8461c505f640ed7ff9",
    "artist_name": "Thomas Rhett",
    "image_url": "https://i.scdn.co/image/50f85fbd3fbc7f6660ee6b5a962094b3c85a1f03",
    "artist_id": "6x2LnllRG5uGarZMsD4iO8",
    "year": 2013
  },
  {
    "song_id": "0Nf3vtVBfiayZL7c8r4KBO",
    "name": "Gas Pedal",
    "popularity": 70,
    "preview_url": "https://p.scdn.co/mp3-preview/a75c2f42ee21745ba190c26bdfc8d52180d56569",
    "artist_name": "Sage The Gemini",
    "image_url": "https://i.scdn.co/image/487b4c0bed352458520e05bbdd03aaf1962a9fa1",
    "artist_id": "6d47Z08T4snK50HgTEHo5Z",
    "year": 2013
  },
  {
    "song_id": "01DSvNTUsVROjnSF5j3UbD",
    "name": "Try",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/87c8f6c230642466f40caffb271535d82d4d2b8f",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/63a13a013fc25d3f6031abbc7d9a4760c82bf47a",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2013
  },
  {
    "song_id": "25n4NECujqOxQHdXVIVc9I",
    "name": "Heart Attack",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/cc5b66bc34573ad53c295e7aa5f085781c9ea338",
    "artist_name": "Demi Lovato",
    "image_url": "https://i.scdn.co/image/77a90cfa93af77355d7036dbad50221c9d07c4a1",
    "artist_id": "6S2OmqARrzebs0tKUEyXyp",
    "year": 2013
  },
  {
    "song_id": "0v1xRYqHE5N5bBi4db5RNR",
    "name": "Mama's Broken Heart",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/c7aac572cfcb807bfe30111ce1982f3b2b694422",
    "artist_name": "Miranda Lambert",
    "image_url": "https://i.scdn.co/image/2c82e3c5199a56ab4e4c5071d3feb98db047d5ae",
    "artist_id": "66lH4jAE7pqPlOlzUKbwA0",
    "year": 2013
  },
  {
    "song_id": "2byuMQoY8GFXEQ5z3GnXyW",
    "name": "Sure Be Cool If You Did",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/d94f9ba9158e2be46638d28a884b8125b95f368a",
    "artist_name": "Blake Shelton",
    "image_url": "https://i.scdn.co/image/672b74cf566deb1e356ea754e5712bd4e8087729",
    "artist_id": "1UTPBmNbXNTittyMJrNkvw",
    "year": 2013
  },
  {
    "song_id": "7Gkphzdh5ouurEt7JT49Yu",
    "name": "Come & Get It",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/1722b79ac4184dbf59fde55e37b5ff628a7f9783",
    "artist_name": "Selena Gomez",
    "image_url": "https://i.scdn.co/image/e3122be4253aa7aace1a6b16ad8040b9da52a498",
    "artist_id": "0C8ZW7ezQVs4URX5aX7Kqx",
    "year": 2013
  },
  {
    "song_id": "0HnaqUxvHP5LaWdmWwJpiS",
    "name": "Love Somebody",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/ebc05c8b795713e775d0aae57de1e3e025c1c64a",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/8a7896c605656e3c9498e20cc4fa65534bd4b663",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2013
  },
  {
    "song_id": "3dkKXwgAmYTf2TwYds2E12",
    "name": "Berzerk",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/bdc24b8be11a5a04823fd39424b8b60a0c7c5aaf",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/238a22531ac9d10413cd57686dfd34031c39bfa1",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2013
  },
  {
    "song_id": "1zvQt99d5oTkEQLmSoO1yu",
    "name": "Mine Would Be You",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/440a5425a94ef15bc8c7ee57c72dc2cdf6a3d284",
    "artist_name": "Blake Shelton",
    "image_url": "https://i.scdn.co/image/2be2efecc3066e6667e0aa06f3eddae5fc6d6d3a",
    "artist_id": "1UTPBmNbXNTittyMJrNkvw",
    "year": 2013
  },
  {
    "song_id": "2OsEJFTfzfjG4oC92cuP2c",
    "name": "Slow Down",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/98dfac2f336a83103a18042f2829a42b80369898",
    "artist_name": "Selena Gomez",
    "image_url": "https://i.scdn.co/image/6d24b62a18208430adf5ac946d2ff7d06fc07468",
    "artist_id": "0C8ZW7ezQVs4URX5aX7Kqx",
    "year": 2013
  },
  {
    "song_id": "190jyVPHYjAqEaOGmMzdyk",
    "name": "Beauty And A Beat",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/7bd68ee4b3001837221afda2fc362527c4df6b14",
    "artist_name": "Justin Bieber",
    "image_url": "https://i.scdn.co/image/aff148fa356c0b1f792572b52904674292305a63",
    "artist_id": "1uNFoZAHBGtllmzznpCI3s",
    "year": 2013
  },
  {
    "song_id": "03UrZgTINDqvnUMbbIMhql",
    "name": "Gangnam Style (강남스타일)",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/2dfce9d202db4707811d712f63e507502d99205f",
    "artist_name": "PSY",
    "image_url": "https://i.scdn.co/image/5a6149a92c512e0ac8b478a03c373dc14e3402b1",
    "artist_id": "2dd5mrQZvg6SmahdgVKDzh",
    "year": 2013
  },
  {
    "song_id": "2sCl0FFOzZSmYZs90zOpd1",
    "name": "Cups (Pitch Perfect’s “When I’m Gone”)",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/4a8f0c628ce11915851b762abf20d2b22debc808",
    "artist_name": "Anna Kendrick",
    "image_url": "https://i.scdn.co/image/5bcc96f2f3ab68a761dcfeebe13a18981c4abf08",
    "artist_id": "6xfqnpe2HnLVUaYXs2F8YS",
    "year": 2013
  },
  {
    "song_id": "2aD522ogF60nzLlju8mfb7",
    "name": "Crash My Party",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/dcd6a1e1d39a04aab416b51292be6e7b50aa59da",
    "artist_name": "Luke Bryan",
    "image_url": "https://i.scdn.co/image/6ebd48c05d4999e0e51a1c131e3696a9873e6f4d",
    "artist_id": "0BvkDsjIUla7X0k6CSWh1I",
    "year": 2013
  },
  {
    "song_id": "6XXCvIN94wOQBnyfcx8q3I",
    "name": "#Beautiful",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/affb7afae6dc6d20a97229b89a88645ff38aced2",
    "artist_name": "Mariah Carey",
    "image_url": "https://i.scdn.co/image/036b1e6b8184c3989582573b22cc1ca36eec9ea1",
    "artist_id": "4iHNK0tOyZPYnBU7nGAgpQ",
    "year": 2013
  },
  {
    "song_id": "4CLZ3D2RCI5ZCfB51D6NwB",
    "name": "The Other Side",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/92b0ed4c1b26bc38ce09a463067edaa986a7eaf6",
    "artist_name": "Jason Derulo",
    "image_url": "https://i.scdn.co/image/4249ae905b3100d446a560384184f3a91ff3bfcb",
    "artist_id": "07YZf4WDAMNwqr4jfgOZ8y",
    "year": 2013
  },
  {
    "song_id": "1AoKQqqkNTpmWqW8HKs8oL",
    "name": "Stay",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/062c7e78c90318aa6e335d4315d1ff02c1979567",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/aeef100780dee6acaa935011d3009ccf76c10c44",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2013
  },
  {
    "song_id": "3pKQIwxQBKO2In4l5EZmBO",
    "name": "Pour It Up",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/00c0ef9889f337ab0ee7e973ee8fb923409da477",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/aeef100780dee6acaa935011d3009ccf76c10c44",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2013
  },
  {
    "song_id": "63LAR8TGAGvpfLLM8Z1weo",
    "name": "Applause",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/bc22bc19c504486e3781b47e1a24f41ce37fa3cf",
    "artist_name": "Lady Gaga",
    "image_url": "https://i.scdn.co/image/4605e81ae6b6cbaad097cf90bf6d61837922b185",
    "artist_id": "1HY2Jd0NmPuamShAr6KMms",
    "year": 2013
  },
  {
    "song_id": "1qHaLcG3LOeh6ZB8vii5ZZ",
    "name": "Just Give Me a Reason",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/077f44996e1e04a36b907e49ac2153d6c380e947",
    "artist_name": "P!nk",
    "image_url": "https://i.scdn.co/image/380fd3773e7f1bef6556371223f6a0e6e619067e",
    "artist_id": "1KCSPY1glIKqW2TotWuXOR",
    "year": 2013
  },
  {
    "song_id": "5PUvinSo4MNqW7vmomGRS7",
    "name": "Blurred Lines",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/661902cfe0186cb7df67d71dde11b79376de6940",
    "artist_name": "Robin Thicke",
    "image_url": "https://i.scdn.co/image/3d44f9d9356e0ea754772d8c596544c3cff3e724",
    "artist_id": "0ZrpamOxcZybMHGg1AYtHP",
    "year": 2013
  },
  {
    "song_id": "0HFrCOmhCYXlv4NdEwRAuj",
    "name": "U.O.E.N.O. (feat. Rick Ross, Future)",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/e3712c93e82c73e2b5b8745d4bdc15fc8a71c472",
    "artist_name": "Rocko",
    "image_url": "https://i.scdn.co/image/73d8d42f5b48c7055588435a49cbf8e84e5713c4",
    "artist_id": "0T5OJgMVjKIX3b3W3ekqOl",
    "year": 2013
  },
  {
    "song_id": "2z8OXShNGUgRitHyO3GuYG",
    "name": "Cruise - Remix",
    "popularity": 67,
    "preview_url": "https://p.scdn.co/mp3-preview/cb63816235af5cb9f12fff1aeceb93f1fb936dd5",
    "artist_name": "Florida Georgia Line",
    "image_url": "https://i.scdn.co/image/86cce9a3e7608c2898fa9567c814d5f92909e2b4",
    "artist_id": "3b8QkneNDz4JHKKKlLgYZg",
    "year": 2013
  },
  {
    "song_id": "3wJIAMuPdEoBddWlovWXCX",
    "name": "One More Night",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/3cb254a3fcbc1e5150cd5fc368498fb25668d222",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/b8836e0672071aaa7a98c4cef5ff1b1fb2fb9c5a",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2013
  },
  {
    "song_id": "1Xsxp1SEOxuMzjrFZhtw8u",
    "name": "Next to Me",
    "popularity": 66,
    "preview_url": "https://p.scdn.co/mp3-preview/5ba6337e648f5d45339d4c756d6f08649450ee3f",
    "artist_name": "Emeli Sandé",
    "image_url": "https://i.scdn.co/image/9456ced28d9279acdbba885e50f6b8e0075fa4c7",
    "artist_id": "7sfgqEdoeBTjd8lQsPT3Cy",
    "year": 2013
  },
  {
    "song_id": "6TwfdLbaxTKzQi3AgsZNzx",
    "name": "Daylight",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/e0c0fbee7169d85d2858d96d8d94aec3de11f216",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/8a7896c605656e3c9498e20cc4fa65534bd4b663",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2013
  },
  {
    "song_id": "64Nbnw22f8adeMuLd1nSBD",
    "name": "Bad - feat. Tiara Thomas",
    "popularity": 65,
    "preview_url": "https://p.scdn.co/mp3-preview/94422e275e44bddd2ba609e268d47a6ba1aa7f9b",
    "artist_name": "Wale",
    "image_url": "https://i.scdn.co/image/f7e56d99238cdd1078387571d4ad1360ec7fb94b",
    "artist_id": "67nwj3Y5sZQLl72VNUHEYE",
    "year": 2013
  },
  {
    "song_id": "4if4AqzRQgEtTF1xXddmoc",
    "name": "23",
    "popularity": 64,
    "preview_url": "https://p.scdn.co/mp3-preview/41bfe60871b2acf19f208e0ec96c016e9c18f603",
    "artist_name": "Mike Will Made It",
    "image_url": "https://i.scdn.co/image/92c8971c7b6ce30a285d23d52170038327d26e53",
    "artist_id": "0NWbwDZY1VkRqFafuQm6wk",
    "year": 2013
  },
  {
    "song_id": "5ve0BYRZZ2aoHFqZMxqYgt",
    "name": "We Can't Stop",
    "popularity": 63,
    "preview_url": "https://p.scdn.co/mp3-preview/d72fac0aeda1f1a6187e7a9e3283b4c038a10f1c",
    "artist_name": "Miley Cyrus",
    "image_url": "https://i.scdn.co/image/acd930f0dd4b996b5b262a751f3f026e82752e25",
    "artist_id": "5YGY8feqx7naU7z4HrwZM6",
    "year": 2013
  },
  {
    "song_id": "1Q8ys0130rNeEE162pmsQa",
    "name": "I Love It - feat. Charli XCX",
    "popularity": 62,
    "preview_url": "https://p.scdn.co/mp3-preview/7955798f3f371c5a60b4b4aace73bfcc544994d4",
    "artist_name": "Icona Pop",
    "image_url": "https://i.scdn.co/image/20b291027833c7325bdc79943a6f7ef927fa967e",
    "artist_id": "1VBflYyxBhnDc9uVib98rw",
    "year": 2013
  },
  {
    "song_id": "7ed5xBtyYAcDWFCDVZb25M",
    "name": "My Songs Know What You Did In The Dark (Light Em Up)",
    "popularity": 60,
    "preview_url": "https://p.scdn.co/mp3-preview/28a4318b3033fc090b2f3f1943b139978cfd9e35",
    "artist_name": "Fall Out Boy",
    "image_url": "https://i.scdn.co/image/3a90fec2fff8b81029d02355eda5f77c49d4c705",
    "artist_id": "4UXqAaa6dQYAk18Lv7PEgX",
    "year": 2013
  },
  {
    "song_id": "65YsalQBmQCzIPaay72CzQ",
    "name": "Diamonds",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/cbc653e9d8e2bb432e5f5d03ede2f327f3f86eed",
    "artist_name": "Rihanna",
    "image_url": "https://i.scdn.co/image/4b880129277465f9c92913a757f10fe033a9c4b0",
    "artist_id": "5pKCCKE2ajJHZ9KAiaK11H",
    "year": 2013
  },
  {
    "song_id": "1lb0sQGUjzJhkNNU6srjeZ",
    "name": "Feel This Moment",
    "popularity": 59,
    "preview_url": "https://p.scdn.co/mp3-preview/446140863ddf18c4338a23950020c6d4e27830a4",
    "artist_name": "Pitbull",
    "image_url": "https://i.scdn.co/image/9b535d1040f9512ba0c6554938251c01d5ddfbfc",
    "artist_id": "0TnOYISbd1XYRBk9myaseg",
    "year": 2013
  },
  {
    "song_id": "7kAYyzVhWr3nSqAC7CUOWK",
    "name": "Catch My Breath",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/5f41ce499f80e9170b3bf255c8d238ae39e8727f",
    "artist_name": "Kelly Clarkson",
    "image_url": "https://i.scdn.co/image/d18285c4520f0085066dfab1bb7650ef443cc04a",
    "artist_id": "3BmGtnKgCSGYIUhmivXKWX",
    "year": 2013
  },
  {
    "song_id": "054ue8u2erwwGNgSIZLEPq",
    "name": "Scream & Shout",
    "popularity": 57,
    "preview_url": "https://p.scdn.co/mp3-preview/b20dce0396283764673f25db9e014faf1ec382c2",
    "artist_name": "will.i.am",
    "image_url": "https://i.scdn.co/image/4073af380abf0a39309e047f38d17ff8ddfb486f",
    "artist_id": "085pc2PYOi8bGKj0PNjekA",
    "year": 2013
  },
  {
    "song_id": "4c7mwtU8HGuLUVqRX5HU7F",
    "name": "F**kin' Problems",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/2d8127a1795450d02ac399c83199324d6fb281c6",
    "artist_name": "A$AP Rocky",
    "image_url": "https://i.scdn.co/image/c8b47782517607d1e7439c24246cc7256bf62f29",
    "artist_id": "13ubrt8QOOCPljQ2FL1Kca",
    "year": 2013
  },
  {
    "song_id": "6Bb5aUjzUT5ozbbr0VpnZ8",
    "name": "Highway Don't Care",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/8eb2a682a5f944f0c09e4473fb8168a0d03e66c6",
    "artist_name": "Tim McGraw",
    "image_url": "https://i.scdn.co/image/895dacd23a414b6cf8c236399503556370a3bbc3",
    "artist_id": "6roFdX1y5BYSbp60OTJWMd",
    "year": 2013
  },
  {
    "song_id": "4uernaqrGGvb2ncMd4wibP",
    "name": "Brave",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/039acfbc605a5a8a9fb6bb574a4fd8e1fb45747d",
    "artist_name": "Sara Bareilles",
    "image_url": "https://i.scdn.co/image/fd7b8c3e4e19d371f1f2163255ffba1c5dd3da64",
    "artist_id": "2Sqr0DXoaYABbjBo9HaMkM",
    "year": 2013
  },
  {
    "song_id": "16C3J2WNhVYSNkpIQRtG2A",
    "name": "Body Party",
    "popularity": 49,
    "preview_url": "https://p.scdn.co/mp3-preview/d84ce67008c5dee963292269659b781ce61a7675",
    "artist_name": "Ciara",
    "image_url": "https://i.scdn.co/image/80a4eadd87a9d8aba0a89299c63b291d10c3263e",
    "artist_id": "2NdeV5rLm47xAvogXrYhJX",
    "year": 2013
  },
  {
    "song_id": "6MiqdbagcOqDMxhovDOIpF",
    "name": "Troublemaker",
    "popularity": 46,
    "preview_url": "https://p.scdn.co/mp3-preview/ed052c7458c25f0c0c1497174bea1a251523d809",
    "artist_name": "Olly Murs",
    "image_url": "https://i.scdn.co/image/2bea656b9a1a02fccbcd1e69bdf60e0da18b1252",
    "artist_id": "3whuHq0yGx60atvA2RCVRW",
    "year": 2013
  },
  {
    "song_id": "6csiA8EczbxAHDewDL7QQl",
    "name": "The Way",
    "popularity": 45,
    "preview_url": "https://p.scdn.co/mp3-preview/0fd905767318ee93fb71758d507b49e0a0b75de9",
    "artist_name": "Ariana Grande",
    "image_url": "https://i.scdn.co/image/94ad57b9aa4ddac9695f8acdd0dc946c71894e64",
    "artist_id": "66CXWjxzNUsdJxJ2JdwvnR",
    "year": 2013
  },
  {
    "song_id": "0uZ0orE84drZZzPKxCWSmR",
    "name": "Little Talks",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/a7a26fde085771c2ac4f99fcf2a7669073c05b4c",
    "artist_name": "Of Monsters And Men",
    "image_url": "https://i.scdn.co/image/93f57259582e67a22c0cddbe0bfb0ae9fa81eb29",
    "artist_id": "4dwdTW1Lfiq0cM8nBAqIIz",
    "year": 2013
  },
  {
    "song_id": "2XDdHuoRmBeOaBIOSclj2k",
    "name": "Started From The Bottom",
    "popularity": 36,
    "preview_url": "https://p.scdn.co/mp3-preview/a47f93bec95eaa67fae636a70ef2c2d683a2470f",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/ed70c1f044a94e8b0db59b40842bfcc1dc7cdeb0",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2013
  },
  {
    "song_id": "4DIWRIrMWDFdtSIeEo1gou",
    "name": "Suit & Tie featuring JAY Z",
    "popularity": 34,
    "preview_url": "https://p.scdn.co/mp3-preview/be722f1e1075afb1a40a86ff54b48934944ad03f",
    "artist_name": "Justin Timberlake",
    "image_url": "https://i.scdn.co/image/2f02066a013cf243c07d358a36acc4cb21471639",
    "artist_id": "31TPClRtHm23RisEBtV3X7",
    "year": 2013
  },
  {
    "song_id": "2axd59iRySNyY8JlmHcmFn",
    "name": "Hold On, We're Going Home",
    "popularity": 32,
    "preview_url": "https://p.scdn.co/mp3-preview/488e5fb585a35565612e80260a405c9e60939b7a",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/09825b071b188cf9926360939f3c133a4da03f5d",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2013
  },
  {
    "song_id": "3U4isOIWM3VvDubwSI3y7a",
    "name": "All of Me",
    "popularity": 94,
    "preview_url": "https://p.scdn.co/mp3-preview/09baa3c7d924d600d5782ce403d1eaaa2c703a2a",
    "artist_name": "John Legend",
    "image_url": "https://i.scdn.co/image/cfc4dd997d3e84b7214517ea749ac37877513449",
    "artist_id": "5y2Xq6xcjJb2jVM54GHK3t",
    "year": 2014
  },
  {
    "song_id": "4kgsK0fftHtg9gZOzkU5T2",
    "name": "Dark Horse",
    "popularity": 87,
    "preview_url": "https://p.scdn.co/mp3-preview/e5394f5065ece57488b651492461e7bbc100e057",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/df9d3d065b5bd2f5e629cfa475a35aaa4de9a7ab",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2014
  },
  {
    "song_id": "1zHlj4dQ8ZAtrayhuDDmkY",
    "name": "Timber",
    "popularity": 85,
    "preview_url": "https://p.scdn.co/mp3-preview/18d0a45538122fbe33f22604d0e5608789c10ae4",
    "artist_name": "Pitbull",
    "image_url": "https://i.scdn.co/image/c171113a197828a6ee8017d1ede2e78c9a7df654",
    "artist_id": "0TnOYISbd1XYRBk9myaseg",
    "year": 2014
  },
  {
    "song_id": "5EmCpD8tUj78VW3kgaEjME",
    "name": "Drunk in Love",
    "popularity": 85,
    "preview_url": "https://p.scdn.co/mp3-preview/d2b4141db025b4e8db53ba5f014007b8d94ed5c5",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/85d715856ef91e8d6bde6a23eec81eecb2836834",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2014
  },
  {
    "song_id": "5U8hKxSaDXB8cVeLFQjvwx",
    "name": "The Monster",
    "popularity": 83,
    "preview_url": "https://p.scdn.co/mp3-preview/c9977497a420e0e250269ba8a2cda4678d52e7f7",
    "artist_name": "Eminem",
    "image_url": "https://i.scdn.co/image/1b3240b2085c032f819dbbec7395ecab34b5e8b2",
    "artist_id": "7dGJo4pcD2V6oG8kP0tJRR",
    "year": 2014
  },
  {
    "song_id": "4pGqFOfzvfe6avb9kbZicC",
    "name": "Sweater Weather",
    "popularity": 81,
    "preview_url": "https://p.scdn.co/mp3-preview/b274e6dcee4fbc238f3ca09fde3a70511a54389d",
    "artist_name": "The Neighbourhood",
    "image_url": "https://i.scdn.co/image/a51e57dc564aa7c69351f58265aa347d8310174d",
    "artist_id": "77SW9BnxLY8rJ0RciFqkHh",
    "year": 2014
  },
  {
    "song_id": "4eLSCSELtKxZwXnFbNLXT5",
    "name": "On Top Of The World",
    "popularity": 81,
    "preview_url": "https://p.scdn.co/mp3-preview/523c53c65e65b209d40623863f4a749b8a6107e9",
    "artist_name": "Imagine Dragons",
    "image_url": "https://i.scdn.co/image/16b7019d190de47507ec0fed8c7a6076bce2e31a",
    "artist_id": "53XhwfbYqKCa1cC15pYq2q",
    "year": 2014
  },
  {
    "song_id": "04cxAqa9ZgLwvEskosNVsB",
    "name": "XO",
    "popularity": 81,
    "preview_url": "https://p.scdn.co/mp3-preview/a81c1245c041a095226f642b0a57de90f97548d0",
    "artist_name": "Beyoncé",
    "image_url": "https://i.scdn.co/image/85d715856ef91e8d6bde6a23eec81eecb2836834",
    "artist_id": "6vWDO969PvNqNYHIOW5v0m",
    "year": 2014
  },
  {
    "song_id": "4zztp85oS36ijgAWwyhX8p",
    "name": "Royals",
    "popularity": 80,
    "preview_url": "https://p.scdn.co/mp3-preview/ac4ee0fef5a910ac39e626985df6b4c461912f9a",
    "artist_name": "Lorde",
    "image_url": "https://i.scdn.co/image/eddc6651cdebc83b09bbfa03032663ce172e834b",
    "artist_id": "163tK9Wjr9P9DmM0AVK7lm",
    "year": 2014
  },
  {
    "song_id": "7Fw5i56my24ZBnGS7hFX2n",
    "name": "Pompeii",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/8e52d1ba575573c2cf087b29e1909d88853059c5",
    "artist_name": "Bastille",
    "image_url": "https://i.scdn.co/image/a4590cd03aacbceb047b33ea4981eaa717ecfd2f",
    "artist_id": "7EQ0qTo7fWT7DPxmxtSYEc",
    "year": 2014
  },
  {
    "song_id": "3G6hD9B2ZHOsgf4WfNu7X1",
    "name": "Team",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/6275a9c7668ec1c387ddc2fb34ca37d6cb2a192d",
    "artist_name": "Lorde",
    "image_url": "https://i.scdn.co/image/5bca76a430b03c65cf6118f7c1fc5d0cdf4bc8b8",
    "artist_id": "163tK9Wjr9P9DmM0AVK7lm",
    "year": 2014
  },
  {
    "song_id": "0xMd5bcWTbyXS7wPrBtZA6",
    "name": "Burn",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/8aa9feae388d503bb896323ca9e33b260b6b5a03",
    "artist_name": "Ellie Goulding",
    "image_url": "https://i.scdn.co/image/c649d891ee6e0b86bf460bca264bd66715bd87f4",
    "artist_id": "0X2BH1fck6amBIoJhDVmmJ",
    "year": 2014
  },
  {
    "song_id": "0XzkemXSiXJa7VgDFPfU4S",
    "name": "Blurred Lines",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/e25bb5a7f5cd38d3baabadf82d5961ccf593cf87",
    "artist_name": "Robin Thicke",
    "image_url": "https://i.scdn.co/image/473c3ee4793380fe7a4ddab2dfa6d61355e968a9",
    "artist_id": "0ZrpamOxcZybMHGg1AYtHP",
    "year": 2014
  },
  {
    "song_id": "5AnCLGg35ziFOloEnXK4uu",
    "name": "Adore You",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/b5fdc85fbaccba2aaa9c41acc2ddd5f82b2c65f0",
    "artist_name": "Miley Cyrus",
    "image_url": "https://i.scdn.co/image/641bd4b037edd64cf3984898097074fba5c02008",
    "artist_id": "5YGY8feqx7naU7z4HrwZM6",
    "year": 2014
  },
  {
    "song_id": "0tJGzJjUVlEsn8s3Mn32Jb",
    "name": "Maps",
    "popularity": 79,
    "preview_url": "https://p.scdn.co/mp3-preview/c6735cc6d68e98dba27bb134a64b8dec85e3b6eb",
    "artist_name": "Maroon 5",
    "image_url": "https://i.scdn.co/image/a02f9696100ecc985f5bf1688989e98942b5ee33",
    "artist_id": "04gDigrS5kc9YWfZHwBETP",
    "year": 2014
  },
  {
    "song_id": "6oDPg7fXW3Ug3KmbafrXzA",
    "name": "Wrecking Ball",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/9aac28c0309c2203ba36d50bd7085c113109e4da",
    "artist_name": "Miley Cyrus",
    "image_url": "https://i.scdn.co/image/40a96e848394e93b32bd84ded8e66a6ceab8be1d",
    "artist_id": "5YGY8feqx7naU7z4HrwZM6",
    "year": 2014
  },
  {
    "song_id": "1zVhMuH7agsRe6XkljIY4U",
    "name": "human",
    "popularity": 78,
    "preview_url": "https://p.scdn.co/mp3-preview/72e3676b464912686a548ba4e483020e22d006cf",
    "artist_name": "Christina Perri",
    "image_url": "https://i.scdn.co/image/adabea39123f7b298544829e5166330f3f845b2c",
    "artist_id": "7H55rcKCfwqkyDFH9wpKM6",
    "year": 2014
  },
  {
    "song_id": "0qcr5FMsEO85NAQjrlDRKo",
    "name": "Let It Go",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/b1715ce30b50ee770c0b832507eae27e883d29c1",
    "artist_name": "Idina Menzel",
    "image_url": "https://i.scdn.co/image/37019113a43313ef1625fa3b6d437bbb87182820",
    "artist_id": "73Np75Wv2tju61Eo9Zw4IR",
    "year": 2014
  },
  {
    "song_id": "57PjISfyOyDcvTPipz16nf",
    "name": "Love Me Again",
    "popularity": 77,
    "preview_url": "https://p.scdn.co/mp3-preview/83b3f72056111d1a85f6ad5657b6d8c07505beaa",
    "artist_name": "John Newman",
    "image_url": "https://i.scdn.co/image/726bddedbd5e51be60f51b83d13268a684250fc7",
    "artist_id": "34v5MVKeQnIo0CWYMbbrPf",
    "year": 2014
  },
  {
    "song_id": "294vBlXfZYspeI29SXZaON",
    "name": "Wake Me Up",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/af11e78d758a85d526e42ca9c50036e79bfca592",
    "artist_name": "Avicii",
    "image_url": "https://i.scdn.co/image/2996fa3483f894fb95a194fa5794d31d145e1bc6",
    "artist_id": "1vCWHaC5f2uS3yhpwWbIA6",
    "year": 2014
  },
  {
    "song_id": "5k31cAfP8yymw8v0AYQIjr",
    "name": "Best Day Of My Life",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/bbf85e6b82194347664ab831c3536c558ceae6cd",
    "artist_name": "American Authors",
    "image_url": "https://i.scdn.co/image/35a162a947e905a997051acc881a87d277d363cb",
    "artist_id": "0MlOPi3zIDMVrfA9R04Fe3",
    "year": 2014
  },
  {
    "song_id": "2Oj5BXOY01EDuJhVYNlEoq",
    "name": "Safe And Sound",
    "popularity": 76,
    "preview_url": "https://p.scdn.co/mp3-preview/41a018ba2b8c112ee0e4018a60e76058e0e093af",
    "artist_name": "Capital Cities",
    "image_url": "https://i.scdn.co/image/c554132e60dc0d26d08cdb778a826f016c6ac051",
    "artist_id": "4gwpcMTbLWtBUlOijbVpuu",
    "year": 2014
  },
  {
    "song_id": "3G5iN5QBqMeXx3uZPy8tgB",
    "name": "Young Girls",
    "popularity": 75,
    "preview_url": "https://p.scdn.co/mp3-preview/417eb8daef6441070d1bf52640a0f6619c08dda3",
    "artist_name": "Bruno Mars",
    "image_url": "https://i.scdn.co/image/a878cea3109affaf05b10c39bc19b8def042f127",
    "artist_id": "0du5cEVh5yTK9QJze8zA0C",
    "year": 2014
  },
  {
    "song_id": "3XSczvk4MRteOw4Yx3lqMU",
    "name": "Roar",
    "popularity": 73,
    "preview_url": "https://p.scdn.co/mp3-preview/c692a8fbad5ebed29438007a11b0f37aa7422a2b",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/b0560ee4b0a7a4ed376de01c8bc44ff8d9633f5c",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2014
  },
  {
    "song_id": "4zTa4w2IK9VxftxJL3RmvJ",
    "name": "Let Her Go",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/e5244b066d49bafea1f95e2fd0d9ba3c04557fd4",
    "artist_name": "Passenger",
    "image_url": "https://i.scdn.co/image/87995626d9ef54ec4c2c26a3cf17b549c7d3af35",
    "artist_id": "0gadJ2b9A4SKsB1RFkBb66",
    "year": 2014
  },
  {
    "song_id": "60jI0JgMLLj1VggHJVhtRs",
    "name": "Glory And Gore",
    "popularity": 72,
    "preview_url": "https://p.scdn.co/mp3-preview/f95a6bf436bdf7722e3601fb0f1e982458d80c7e",
    "artist_name": "Lorde",
    "image_url": "https://i.scdn.co/image/5bca76a430b03c65cf6118f7c1fc5d0cdf4bc8b8",
    "artist_id": "163tK9Wjr9P9DmM0AVK7lm",
    "year": 2014
  },
  {
    "song_id": "519uJbE3zyKLlToVA65PrP",
    "name": "Counting Stars",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/c29c61abc606b3575397c2246a8d7a06d4ba4133",
    "artist_name": "OneRepublic",
    "image_url": "https://i.scdn.co/image/a01690a660137efcc1c5c0440127354de87bd259",
    "artist_id": "5Pwc4xIPtQLFEnJriah9YJ",
    "year": 2014
  },
  {
    "song_id": "5Mw9bXG1dLNhbjofkVS2oR",
    "name": "Oceans (Where Feet May Fail)",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/29600e56fe263b1078c096b82ed23a32e7f52425",
    "artist_name": "Hillsong United",
    "image_url": "https://i.scdn.co/image/abd132a372a8f1d8cf5f4ce16d777b9a4efaab00",
    "artist_id": "74cb3MG0x0BOnYNW1uXYnM",
    "year": 2014
  },
  {
    "song_id": "4sn0Ck12hSxDc5sUvQkcTN",
    "name": "Neon Lights",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/a9dccce1b4377c78cadac8a01ed6edcd2bbfd944",
    "artist_name": "Demi Lovato",
    "image_url": "https://i.scdn.co/image/149a53887971babfef12845e125617886588642b",
    "artist_id": "6S2OmqARrzebs0tKUEyXyp",
    "year": 2014
  },
  {
    "song_id": "4sn0Ck12hSxDc5sUvQkcTN",
    "name": "Neon Lights",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/a9dccce1b4377c78cadac8a01ed6edcd2bbfd944",
    "artist_name": "Demi Lovato",
    "image_url": "https://i.scdn.co/image/149a53887971babfef12845e125617886588642b",
    "artist_id": "6S2OmqARrzebs0tKUEyXyp",
    "year": 2014
  },
  {
    "song_id": "4fwbGKNExPtPHbor1TBSY4",
    "name": "Unconditionally",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/10ceafd28e7d4468e37088d44d68989e66efc92f",
    "artist_name": "Katy Perry",
    "image_url": "https://i.scdn.co/image/96fb8b4d972bba2ac566820985437aa39109b512",
    "artist_id": "6jJ0s89eD6GaHleKKya26X",
    "year": 2014
  },
  {
    "song_id": "2yFh7Oz9g0bpHp3iis2Iu2",
    "name": "Talk Dirty (feat. 2 Chainz)",
    "popularity": 71,
    "preview_url": "https://p.scdn.co/mp3-preview/cd1431273402691f0c1ef9380e3a7ebf2b016ac1",
    "artist_name": "Jason Derulo",
    "image_url": "https://i.scdn.co/image/9a9a822e61257fd608ae77d69e910370d91ac418",
    "artist_id": "07YZf4WDAMNwqr4jfgOZ8y",
    "year": 2014
  },
  {
    "song_id": "1MDogqDrStFSO9cdUTUFem",
    "name": "Doin' What She Likes",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/51aa2b4a44c04fff08b9286c017333b289a8be7f",
    "artist_name": "Blake Shelton",
    "image_url": "https://i.scdn.co/image/2be2efecc3066e6667e0aa06f3eddae5fc6d6d3a",
    "artist_id": "1UTPBmNbXNTittyMJrNkvw",
    "year": 2014
  },
  {
    "song_id": "1A2PVHd6kWaMlaJVkMfXmz",
    "name": "Let It Go - Single Version",
    "popularity": 69,
    "preview_url": "https://p.scdn.co/mp3-preview/5da28be9d680fd13c524da63416e7cc088b6d9ce",
    "artist_name": "Demi Lovato",
    "image_url": "https://i.scdn.co/image/37019113a43313ef1625fa3b6d437bbb87182820",
    "artist_id": "6S2OmqARrzebs0tKUEyXyp",
    "year": 2014
  },
  {
    "song_id": "0VKYHUrAXZnzLpNh9YFckH",
    "name": "Waiting for Superman",
    "popularity": 68,
    "preview_url": "https://p.scdn.co/mp3-preview/29b86293d439b84cc2c72004eed0635698b92316",
    "artist_name": "Daughtry",
    "image_url": "https://i.scdn.co/image/0a4fba04550165d20f3fba96ec65274ef7351962",
    "artist_id": "5P5FTygHyx2G57oszR3Wot",
    "year": 2014
  },
  {
    "song_id": "328vwrjOdWDdsU3Ekod8Kf",
    "name": "Love Don't Die",
    "popularity": 58,
    "preview_url": "https://p.scdn.co/mp3-preview/92bb40d8f33325e7b42a8da7d7056bac3581aba9",
    "artist_name": "The Fray",
    "image_url": "https://i.scdn.co/image/949798b62e32b6a5204942bbd355c60cff71ca3c",
    "artist_id": "0zOcE3mg9nS6l3yxt1Y0bK",
    "year": 2014
  },
  {
    "song_id": "5Wd2bfQ7wc6GgSa32OmQU3",
    "name": "Story of My Life",
    "popularity": 56,
    "preview_url": "https://p.scdn.co/mp3-preview/eb0d74437a5584bd35e66836b3f27952ce6a5a15",
    "artist_name": "One Direction",
    "image_url": "https://i.scdn.co/image/e35c85e710f98d2d462b33ef72039a41bd0a25e9",
    "artist_id": "4AK6F7OLvEQ5QYCBNiQWHq",
    "year": 2014
  },
  {
    "song_id": "6zZWk6l9e4HPx2DNrHnC5M",
    "name": "Demons",
    "popularity": 55,
    "preview_url": "https://p.scdn.co/mp3-preview/b12738bb2b77ad61aaf4b617ebc7a3a660b4cd71",
    "artist_name": "Imagine Dragons",
    "image_url": "https://i.scdn.co/image/44f886bb0b1064d2f00cd997b9088f7f99803c98",
    "artist_id": "53XhwfbYqKCa1cC15pYq2q",
    "year": 2014
  },
  {
    "song_id": "1NrmiJQFkH5ageJoqeeW2v",
    "name": "Hey Brother - Extended Version",
    "popularity": 53,
    "preview_url": "https://p.scdn.co/mp3-preview/c163e603526adb2b0bcfaa8fece6b6e5a03d755b",
    "artist_name": "Avicii",
    "image_url": "https://i.scdn.co/image/11c3f51bc77cc7520710f48c4efb53441a9f03e0",
    "artist_id": "1vCWHaC5f2uS3yhpwWbIA6",
    "year": 2014
  },
  {
    "song_id": "4uernaqrGGvb2ncMd4wibP",
    "name": "Brave",
    "popularity": 52,
    "preview_url": "https://p.scdn.co/mp3-preview/039acfbc605a5a8a9fb6bb574a4fd8e1fb45747d",
    "artist_name": "Sara Bareilles",
    "image_url": "https://i.scdn.co/image/fd7b8c3e4e19d371f1f2163255ffba1c5dd3da64",
    "artist_id": "2Sqr0DXoaYABbjBo9HaMkM",
    "year": 2014
  },
  {
    "song_id": "4FoFvI1lA3ws6GeXB9T56l",
    "name": "Hold On, We're Going Home",
    "popularity": 18,
    "preview_url": "https://p.scdn.co/mp3-preview/9000fe3ac97a1a2a1e1c205c7590f1bcb61952b8",
    "artist_name": "Drake",
    "image_url": "https://i.scdn.co/image/37ac98b5921fb27190a9b34ff4e6e72d3aea51ea",
    "artist_id": "3TVXtAsR1Inumwj472S9r4",
    "year": 2014
  }
];